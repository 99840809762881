import { UserRoles } from "./utils";

export enum ResearchStatus {
  OnGoing,
  Delayed,
  OnHold,
  Completed,
  All,
}

export enum TeamType {
  MainTeam = 20,
  SubTeam = 21,
  Task = 22,
  SubTask = 23,
}
enum equipmentStatus {
  Operational,
  NotOperational,
}
export enum equipmentFilterStatus {
  Operational=31,
  NotOperational=32,
}

export enum discussionFilterStatus {
  UnreadMessage=41,
  NoNewMessage=42,
}

export enum ticketPriorityFilter {
  High=51,
  Low=52,
  Medium=53,
}

export enum ticketFilterStatus {
  open=61,
  closed=62,
}

export enum Priority {
  Low = 0,
  Medium = 1,
  High = 2,
}
enum mediaType {
  Image,
  Svg,
  Video,
  Pdf,
  Link,
  Word,
  PowerPoint,
  Compressed,
  Other,
}
export enum TopicType {
  Project,
  GeneralTopic,
  Task,
  Data,
  Equipment,
  Laboratory,
  Team,
  Publication,
  Expense,
  Ticket,
  Broadcast,
  All,
}
export enum SortType {
  ASC = "ASC",
  DESC = "DESC",
  AutoSelect = "AutoSelect",
}
export const ProfileTypes = {
  RESEARCH: 1,
  TASK: 2,
  SUBTASK: 3,
  DATA: 4,
  USERPROFILE: 5,
  TEAMPROFILE: 6,
  SUBTEAMPROFILE: 7,
  EQUIPMENTPROFILE: 8,
  LABORTARYPROFILE: 9,
  DISCUSSIONPROFILE: 10,
  PUBLICATIONFLOW: 11,
  TICKETS: 12,
  RESEARCHSELECTOR: 13,
  DRAFT: 14
};
declare global {
  interface Number {
    isStatus(): string;
    isPriority(): string;
    isMedia(): string;
    isRole(): string;
    isTopic(): string;
    isStatusColor(): string;
    isStatusTextColor(): string;
    isStatusEquipment(): string;
  }
}
Number.prototype.isStatus = function (): string {
  switch (Number(this)) {
    case TeamType.MainTeam:
      return "Main Team";
    case TeamType.SubTeam:
      return "Sub Team";
    case TeamType.Task:
      return "Task";
    case TeamType.SubTask:
      return "Sub Task";
    case ResearchStatus.OnGoing:
      return "On Going";
    case ResearchStatus.Delayed:
      return "Delayed";
    case ResearchStatus.OnHold:
      return "On Hold";
    case ResearchStatus.Completed:
      return "Completed";
      case equipmentFilterStatus.Operational:
      return "Operational";
    case equipmentFilterStatus.NotOperational:
      return "Under Maintenance";
      case discussionFilterStatus.UnreadMessage:
      return "Unread Message";
    case discussionFilterStatus.NoNewMessage:
      return "No New Message";
      case ticketFilterStatus.closed:
      return "Closed";
      case ticketFilterStatus.open:
      return "Open";
    case ticketPriorityFilter.High:
      return "High";
      case ticketPriorityFilter.Medium:
      return "Medium";
    case ticketPriorityFilter.Low:
      return "Low";
    default:
      return "";
  }
};
Number.prototype.isStatusEquipment = function (): string {
  switch (Number(this)) {
    case equipmentStatus.Operational:
      return "Operational";
    case equipmentStatus.NotOperational:
      return "Under Maintenance";
    default:
      return "";
  }
};

Number.prototype.isStatusTextColor = function (): string {
  switch (Number(this)) {
    case ResearchStatus.OnHold || ResearchStatus.Completed:
      return "#FFFFFF";
    case TeamType.MainTeam:
      return "#4a4a4a";
    case TeamType.SubTeam:
      return "#4a4a4a";
    case TeamType.Task:
      return "#4a4a4a";
    case TeamType.SubTask:
      return "#4a4a4a";
      case discussionFilterStatus.UnreadMessage:
        return "#4a4a4a";
      case discussionFilterStatus.NoNewMessage:
        return "#4a4a4a";
        case ticketFilterStatus.closed:
        return "#000000";
        case ticketFilterStatus.open:
        return "#000000";
      case ticketPriorityFilter.High:
        return "#FFFFFF";
        case ticketPriorityFilter.Medium:
        return "#000000";
      case ticketPriorityFilter.Low:
        return "#000000";
    default:
      return "#fff";
  }
};
Number.prototype.isStatusColor = function (): string {
  switch (Number(this)) {
    case ResearchStatus.OnGoing:
      return "#40c3f4";
    // return "#8EE1FF";
    case ResearchStatus.Delayed:
      return "#FE861F";
    case ResearchStatus.OnHold:
      return "#E044FF";
    case ResearchStatus.Completed:
      return "#53A501";
    case TeamType.MainTeam:
      return "#c3c3c3";
    case TeamType.SubTeam:
      return "#dddddd";
    case TeamType.Task:
      return "#c3c3c3";
    case TeamType.SubTask:
      return "#dddddd";
      case equipmentFilterStatus.Operational:
      return "#006EA8";
    case equipmentFilterStatus.NotOperational:
      return "#FFC962";
      case discussionFilterStatus.UnreadMessage:
      return "#c3c3c3";
    case discussionFilterStatus.NoNewMessage:
      return "#dddddd";
      case ticketFilterStatus.closed:
        return "#CCCCCC";
        case ticketFilterStatus.open:
        return "#FFBA00";
      case ticketPriorityFilter.High:
        return "#096BFF";
        case ticketPriorityFilter.Medium:
        return "#40C0F4";
      case ticketPriorityFilter.Low:
        return "#9CFBFF";
    default:
      return "#474747";
  }
};
Number.prototype.isPriority = function (): string {
  switch (Number(this)) {
    case Priority.High:
      return "High";
    case Priority.Medium:
      return "Medium";
    case Priority.Low:
      return "Low";
    default:
      return "";
  }
};
Number.prototype.isRole = function (): string {
  switch (Number(this)) {
    case UserRoles.Admin:
      return "Admin";
    case UserRoles.L1Client:
      return "Level 1 Client";
    case UserRoles.L1User:
      return "Level 1 ";
    case UserRoles.L2User:
      return "Level 2 ";
    case UserRoles.L3User:
      return "Level 3 ";
    default:
      return "";
  }
};
Number.prototype.isMedia = function (): string {
  switch (Number(this)) {
    case mediaType.Image:
      return "file_png.svg";
    case mediaType.Pdf:
      return "file_pdf.svg";
    case mediaType.Svg:
      return "file_svg.svg";
    case mediaType.Video:
      return "file_mp4.svg";
    case mediaType.Link:
      return "file_other.svg";
    case mediaType.Other:
      return "file_other.svg";
    case mediaType.PowerPoint:
      return "file_ppt.svg";
    case mediaType.Word:
      return "file_word.svg";
    case mediaType.Compressed:
      return "file_zip.svg";
    default:
      return "file_other.svg";
  }
};
Number.prototype.isTopic = function (): string {
  switch (Number(this)) {
    case TopicType.Data:
      return "Data";
    case TopicType.Equipment:
      return "Equipment";
    case TopicType.Expense:
      return "Expense";
    case TopicType.GeneralTopic:
      return "General";
    case TopicType.Laboratory:
      return "Laboratory";
    case TopicType.Publication:
      return "Publication";
    case TopicType.Project:
      return "Project";
    case TopicType.Task:
      return "Task";
    case TopicType.Team:
      return "Team";
    case TopicType.Ticket:
      return "Ticket";
    case TopicType.Broadcast:
     return "Broadcast";
    default:
      return "";
  }
};
export {};
