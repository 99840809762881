import React from "react";
import { BiRefresh } from "react-icons/bi";
import { store } from "../../../data/storeMain";
import { InputComponent, InputType } from "../../components/inputs";
import "react-datepicker/dist/react-datepicker.css";
import { MainButton, MainButtonType } from "../../components/button";
import { SelectComponent } from "../../components/select_input";
import { ButtonGroup } from "../../components/botton_group";
import { RouteComponentProps, withRouter } from "react-router";
import { MemberController } from "../../../controllers/member/member_controller";
import { LocalDataSources } from "../../../data/local_datasources";
import SimpleReactValidator from "simple-react-validator";
import { Equipment } from "../../../data/models/responses/member/get_member_by_id_res";
import {
  ResendInvatationReq,
  UpdateMemberReq,
} from "../../../data/models/requests/member/update_member_req";
import {
  $ReplaceRoute,
  AccessPermition,
  // convertEquipsIdsToIds,
  convertEquipsToUncategorized,
  convertLabEquipsToNewTree,
  convertLabEquipToTree,
  filterEqipmetsNotExistInLabs,
  getUserTitle,
  isTokenInHttpAuth,
  UserRoles,
} from "../../../core/utils";
import { UserController } from "../../../controllers/user/user_controller";
import { AppConstants, AppRoutes } from "../../../core/constants";
import { toast } from "react-toastify";
import { HTTP } from "../../../core/http_common";
// import { InputLabel } from "../../components/input_label";
import { ITreeMenuData } from "../../components/TreeMenu";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import NewTreeMenu, { TreeOptionType } from "../../components/NewTreeMenu";
import { Button } from "@radix-ui/themes";
import { BaseButton } from "@fluentui/react";
type StateType = {
  userEmail: string;
  invitationNote: string;
  currentResearchId: number;
  infoInvite?: {
    id: number;
    receiverUserId: string;
    senderUser: {
      id: string;
    };
  };
  addedTeamsId: number[];
  addedLaboratoriesId: number[];
  addedEquipmentsId: number[];
  addedResearchesId: number[];
  deletedTeamsId: number[];
  deletedLaboratoriesId: number[];
  deletedEquipmentsId: number[];
  deletedResearchesId: number[];
  role: number;
  listTeams: Array<{ label: string; value: number } | {}>;
  listProjects: Array<{ label: string; value: number } | {}>;
  loading: boolean;
  userRoleEnum: Array<{ label: string; value: number } | {}>;
  Teams: Equipment[];
  Researches: Equipment[];
  userTypeId: number;
  invitationStatus: number;
  listUserTypes: Array<{ label: string; value: number; role: number }>;
  selectedTeams: Array<{ label: string; value: number }>;
  selectedResearches: Array<{ label: string; value: number }>;
  types: number[];
  listLaboratories: ITreeMenuData[];
  unCheckedValues: string[];
  selectedLabsEquips: string[];
  mainLabsEquips: string[];
  listLabsEquips: TreeOptionType[];
  defaultLabs: TreeOptionType[];
  defaultEquips: TreeOptionType[];
  mainLabs: number[];
  mainEquips: number[];
  selectedEquipsId: number[];
  selectedLabsId: number[]
  loadingMode: string;
  searchLoading: boolean
  isLoggedInUserLabManager: boolean
  allowedToEdit: boolean;
  disableRole: boolean;
};
interface RouteParams {
  id: string;
}
class MemberPageUseEdit extends React.Component<
  RouteComponentProps<RouteParams>
> {
  RoleUser = store.getState().userRole;
  controller: MemberController = new MemberController();
  userController = new UserController();
  local = new LocalDataSources();
  validator = new SimpleReactValidator({
    className: "text-danger",
  });
  state: StateType = {
    listLaboratories: [],
    mainLabsEquips: [],
    selectedLabsEquips: [],
    unCheckedValues: [],
    searchLoading: false,
    isLoggedInUserLabManager: false,
    allowedToEdit: false,
    disableRole: false,
    userEmail: "",
    invitationNote: "",
    invitationStatus: -1,
    currentResearchId: 0,
    addedTeamsId: [],
    selectedTeams: [],
    addedLaboratoriesId: [],
    addedEquipmentsId: [],
    addedResearchesId: [],
    deletedTeamsId: [],
    deletedLaboratoriesId: [],
    deletedEquipmentsId: [],
    deletedResearchesId: [],
    role: 0,
    listTeams: [],
    listProjects: [],
    loading: false,
    userRoleEnum: [],
    Teams: [],
    Researches: [],
    selectedResearches: [],
    userTypeId: 0,
    listUserTypes: [],
    types: [],
    listLabsEquips: [],
    defaultEquips: [],
    defaultLabs: [],
    mainEquips: [],
    mainLabs: [],
    selectedEquipsId: [],
    loadingMode: '',
    selectedLabsId: [],
  };

  componentDidMount() {
    if (!isTokenInHttpAuth(HTTP)) return;
    this.setState({
      searchLoading: true
    })
    this.controller.SearchMember(
      (res) => {

        if (!res) {
          this.getUserTypes().then(() => {
            this.handleGetMemberDetail()
          });
        } else {
          const __labs = convertLabEquipsToNewTree(res.laboratories);
          const _uncategorizedEquips: TreeOptionType | false = res.equipments.length > 0 ? convertEquipsToUncategorized(res.equipments) : false
          const __listLabsEquips: TreeOptionType[] = _uncategorizedEquips ? [_uncategorizedEquips, ...__labs] : __labs



          const _labs = res.laboratories
            ? convertLabEquipToTree(res.laboratories as any)
            : [];

          console.log("res 2");
          const _equipmentOnlyAccess = filterEqipmetsNotExistInLabs(
            res.laboratories as any,
            res.equipments
          );

          if (_equipmentOnlyAccess !== null) {
            _labs.unshift(_equipmentOnlyAccess);
          }

          this.setState({
            listLabsEquips: __listLabsEquips,
            listTeams: res.teams
              ? res.teams.map((item) => {
                return { label: item.title, value: item.id };
              })
              : res.subTeams
                ? res.subTeams.map((item) => {
                  return { label: item.title, value: item.id };
                })
                : [],
            listLaboratories: _labs,
            unCheckedValues: _equipmentOnlyAccess?.value
              ? [_equipmentOnlyAccess.value]
              : [],
            listProjects: res.researches?.map((item) => {
              return { label: item.title, value: item.id };
            }),
          });

          this.getUserTypes().then(() => {
            this.handleGetMemberDetail(res)
          });
        }



      },
      (err) => {
        this.setState({
          searchLoading: false
        })
      }
    );
    this.setState({
      userRoleEnum: this.local.getSetting().userRoleEnum.map((item) => {
        return { label: item.title, value: item.id };
      }),
    });

  }
  handleChange(target: string, val: any) {
    this.setState({
      [target]: val,
    });
  }
  handelChangeSelect(
    target: string,
    e: Array<{ label: string; value: number }>
  ) {
    this.setState({ [target]: e.map((item) => item.value) });
  }
  handelChangeSelectType(e: any) {
    // console.log(e, this.state.userTypeId)
    this.setState({ userTypeId: e.value });
  }
  handeldeletedTeams(id: number) {
    this.setState({
      deletedTeamsId: [...this.state.deletedTeamsId, id],
      Teams: this.state.Teams.filter((item) => item.id !== id),
    });
  }

  handeldeletedResearches(id: number) {
    this.setState({
      deletedResearchesId: [...this.state.deletedResearchesId, id],
      Researches: this.state.Researches.filter((item) => item.id !== id),
    });
  }

  handleGetMemberDetail(searchDetail?: any) {
    this.controller.getMember(
      {
        userId: this.props.match.params.id,
        token: localStorage.getItem("token") ?? "",
      },
      (userDetail) => {
        const _userTitle = getUserTitle(userDetail)
        document.title = `Edit User Access | ${_userTitle} | Radvix`
        
        const _allSearchEquipsIncludeLabsEquips = [...searchDetail?.equipments, ...searchDetail.laboratories?.map((_lab: any) => _lab?.equipments).flat()]
        const _userLabs = userDetail.laboratories.filter(__l => searchDetail.laboratories?.some((_ll: any) => _ll.id === __l.id));
        const _userEquips = userDetail.equipments.filter(__eq => _allSearchEquipsIncludeLabsEquips.some(_eq => _eq.id === __eq.id))
        const __userEquips = _userEquips.filter(_eq => {
          const _isEqInAnyLabsEquips = userDetail.laboratories.some(_lab => _lab.equipments.some(_eqId => _eqId.id === _eq.id))
          return !_isEqInAnyLabsEquips
        })
        const _defaultLabs = convertLabEquipsToNewTree(_userLabs)
        const _defaultEquips = _userEquips.map(_eq => {
          return {
            label: _eq.title,
            value: _eq.id,
          }
        })

        if (userDetail.invitationStatus === 1) {
          this.controller.getInviteInfo(
            {
              userId: this.props.match.params.id,
              token: localStorage.getItem("token") ?? "",
            },
            (infoInvite) => {
              this.setState({
                infoInvite: infoInvite,
                invitationNote: infoInvite.invitationNote,
              });
            },
            () => { }
          );
        }
        const _selectedTeams = userDetail.teams.map((item) => {
          return {
            label: item.title,
            value: item.id,
          };
        });
        const _selectedResearches = userDetail.researches.map((_research) => {
          return {
            label: _research.title,
            value: _research.id,
          };
        });

        console.log("res selected", { _userEquips, _userLabs });

        const _selectedLabs = userDetail.laboratories.map((item) =>
          item.id.toString()
        );
        const _selectedEquips = userDetail.equipments.map(
          (_item) => `Equip${AppConstants.divide}${_item.id.toString()}`
        );

        this.setState({
          mainLabs: _userLabs.map(_l => _l.id),
          // mainEquips: _userEquips.map(___eq => ___eq.id),
          mainEquips: __userEquips.map(___eq => ___eq.id),
          // mainEquips: _res.equipments.map(_eq => _eq.id),
          defaultLabs: _defaultLabs,
          defaultEquips: _defaultEquips,
          userEmail: userDetail.userEmail,
          isLoggedInUserLabManager: userDetail.isLoggedInUserLabManager,
          allowedToEdit: userDetail.allowedToEdit,
          disableRole: userDetail.disableRole,
          invitationNote:
            userDetail.invitationStatus === 1
              ? this.state.invitationNote
              : userDetail.institution,
          role: userDetail.role,
          Teams: userDetail.teams,
          selectedTeams: _selectedTeams,
          selectedResearches: _selectedResearches,
          Researches: userDetail.researches,
          userTypeId: userDetail.userTypeId,
          invitationStatus: userDetail.invitationStatus,

          mainLabsEquips: [..._selectedLabs, ..._selectedEquips],
          searchLoading: false
        });
        // setTimeout(() => {
        //   console.log(this.state);

        // }, 100);
      },
      (err) => {
        this.setState({
          searchLoading: false
        })
      }
    );
  }
  UpdateMember() {
    if (this.validator.allValid()) {
      console.log(this.state.mainEquips, this.state.selectedEquipsId)
      // 
      this.setState({
        loading: true,
        loadingMode: 'Updating user..'
      });
      const __addedEquips = this.state.selectedEquipsId.filter(_eqId => !this.state.mainEquips.includes(_eqId))
      const __removedEquips = this.state.mainEquips.filter(_eqId => !this.state.selectedEquipsId.includes(_eqId))
      const __addedLabs = this.state.selectedLabsId.filter(_labId => !this.state.mainLabs.includes(_labId))
      const __removedLabs = this.state.mainLabs.filter(_labId => !this.state.selectedLabsId.includes(_labId))
      console.log(this.state.mainEquips, this.state.mainLabs, { __addedEquips, __removedEquips, __removedLabs, __addedLabs });



      let _removedTeams = this.state.Teams.filter((_team) => {
        return !this.state.selectedTeams.find(
          (s_team) => s_team.value === _team.id
        );
      }).map((item) => item.id);
      let _addedTeams = this.state.selectedTeams
        .filter((s_team) => {
          return !this.state.Teams.find(
            //@ts-ignore
            (_team) => _team.id === s_team.value
          );
        })
        .map((_item) => _item.value);
      let _removedResearches = this.state.Researches.filter((_team) => {
        return !this.state.selectedResearches.find(
          (s_team) => s_team.value === _team.id
        );
      }).map((item) => item.id);
      let _addedResearches = this.state.selectedResearches
        .filter((s_team) => {
          return !this.state.Researches.find(
            //@ts-ignore
            (_team) => _team.id === s_team.value
          );
        })
        .map((_item) => _item.value);

      const _removedLabsEquips = this.state.mainLabsEquips.filter((_team) => {
        return (
          this.state.selectedLabsEquips.findIndex(
            (s_team) => s_team === _team
          ) === -1
        );
      });

      // const _addedLabsEquips = this.state.selectedLabsEquips.filter((_team) => {
      //   return (
      //     this.state.mainLabsEquips.findIndex((s_team) => s_team === _team) ===
      //     -1
      //   );
      // });

      // const _deletedLaboratoriesId = _removedLabsEquips
      //   .filter((item) => !isNaN(Number(item)))
      //   .map((item) => +item);

      // const _addedLaboratoriesId = _addedLabsEquips
      //   .filter((item) => !isNaN(Number(item)))
      //   .map((item) => +item);

      // let _deletedEquipmentsId: any[] = _removedLabsEquips.filter((item) =>
      //   isNaN(Number(item))
      // );
      // _deletedEquipmentsId = convertEquipsIdsToIds(_deletedEquipmentsId);
      // _deletedEquipmentsId = _deletedEquipmentsId.map((item) => +item);

      // let _addedEquipmentsId: any[] = _addedLabsEquips.filter((item) =>
      //   isNaN(Number(item))
      // );
      // _addedEquipmentsId = convertEquipsIdsToIds(_addedEquipmentsId);
      // _addedEquipmentsId = _addedEquipmentsId.map((item) => +item);

      const body: UpdateMemberReq = {
        addedResearchesId: _addedResearches,
        deletedResearchesId: _removedResearches,
        deletedLabEquipmentId: _removedLabsEquips.map(id => parseInt(id, 10)),

        addedTeamsId: _addedTeams,
        deletedTeamsId: _removedTeams,

        userEmail: this.state.userEmail,
        invitationNote: this.state.invitationNote
          ? this.state.invitationNote
          : "",
        currentResearchId: this.state.Researches.length
          ? this.state.Researches[this.state.Researches.length - 1].id
          : this.state.addedResearchesId[
          this.state.addedResearchesId.length - 1
          ],
        role: this.state.role ? this.state.role : 0,
        userTypeId: this.state.userTypeId,

        // -----------------------------------------

        // addedLaboratoriesId: _addedLaboratoriesId,
        // deletedLaboratoriesId: _deletedLaboratoriesId,

        // addedEquipmentsId: _addedEquipmentsId,
        // deletedEquipmentsId: _deletedEquipmentsId,
        addedLaboratoriesId: __addedLabs,
        deletedLaboratoriesId: __removedLabs,

        addedEquipmentsId: __addedEquips,
        deletedEquipmentsId: __removedEquips,
      };
      
      this.controller.updateMember(
        body,
        (res) => {
          this.props.history.push(
            $ReplaceRoute(`${AppRoutes.member_profile.replace(
              ":id",
              this.props.match.params.id ?? ""
            )}`)
          );
          // this.props.history.push($ReplaceRoute(AppRoutes.member));
          this.setState({
            userEmail: "",
            invitationNote: "",
            currentResearchId: 0,
            addedTeamsId: [],
            addedLaboratoriesId: [],
            addedEquipmentsId: [],
            addedResearchesId: [],
            deletedTeamsId: [],
            deletedLaboratoriesId: [],
            deletedEquipmentsId: [],
            deletedResearchesId: [],
            role: 0,
            userTypeId: 0,
            listTeams: [],
            listProjects: [],
            loading: false,
            userRoleEnum: [],
            Teams: [],
            loadingMode: ''
          });
        },
        (err) => {
          this.setState({
            loading: false,
            loadingMode: ''
          });
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  getUserTypes = () => {
    return new Promise((resolve, reject) => {
      this.userController.getUserType(
        (res) => {
          const _listUserTypes = res.map((item) => {
            return {
              label: item.title,
              value: item.id,
              role: item.userRoleEnum,
            };
          })
          this.setState({
            listUserTypes: _listUserTypes,
          });
          resolve(_listUserTypes)
        },
        (err) => {
          reject(err)
        }
      );
    });

  };

  ResendInvitation() {
    if (this.validator.allValid()) {
      this.setState({
        loading: true,
      });
      const body: ResendInvatationReq = {
        id: this.state.infoInvite?.id || 0,
        senderUserId: this.state.infoInvite?.senderUser.id || "0",
        receiverUserId: this.state.infoInvite?.receiverUserId || "0",
        invitationNote: this.state.invitationNote,
        receiverEmail: this.state.userEmail,
        teamId: 0,
        researchId: 0,
        equipmentId: 0,
        laboratoryId: 0,
      };
      this.controller.remote.resendInvatation(
        body,
        (res) => {
          this.setState({
            userEmail: "",
            invitationNote: "",
            currentResearchId: 0,
            addedTeamsId: [],
            addedLaboratoriesId: [],
            addedEquipmentsId: [],
            addedResearchesId: [],
            deletedTeamsId: [],
            deletedLaboratoriesId: [],
            deletedEquipmentsId: [],
            deletedResearchesId: [],
            role: 0,
            userTypeId: 0,
            listTeams: [],
            listProjects: [],
            loading: false,
            userRoleEnum: [],
            Teams: [],
          });
          toast.success("Your invitation has been resent successfully");
          this.props.history.push(
            $ReplaceRoute(`${AppRoutes.member_profile.replace(
              ":id",
              this.props.match.params.id ?? ""
            )}`)
          );
          // this.props.history.push(AppRoutes.member);
        },
        (err) => {
          this.setState({
            loading: false,
          });
        }
      );
    }
  }
  render() {
    const _myRole = this.local.getUserInfo()?.role;
    console.log("myrole " + _myRole);
    console.log("staterole " + this.state.role);
    const L2EditL2 = !(
      this.state.role === UserRoles.L2User && _myRole === UserRoles.L2User
    );
  
;
    const canIEditAccessLabel = L2EditL2;
    const canIEditAddMemberTeams = L2EditL2;
    // const canIEditMemberToProject = L2EditL2;
    const canEditOnlyLabEquip = this.state.isLoggedInUserLabManager && !this.state.allowedToEdit
    const canEditOnlyInvitedUser = !this.state.allowedToEdit

    const enableRole = () => {
      if (_myRole === UserRoles.L1User) {
        if (this.state.role === UserRoles.L1User)
          return false;
        else if (this.state.role === UserRoles.L1Client)
          return false;
        else if (this.state.role === UserRoles.L2User)
          return false;
        else if (this.state.role === UserRoles.L3User)
          return false;
      } else if (_myRole === UserRoles.L1Client) {
        if (this.state.role === UserRoles.L1User)
          return false;
        else if (this.state.role === UserRoles.L1Client)
          return false;
        else if (this.state.role === UserRoles.L2User)
          return false;
        else if (this.state.role === UserRoles.L3User)
          return false;
      } else if (_myRole === UserRoles.L2User) {
        if (this.state.role === UserRoles.L1User)
          return true;
        else if (this.state.role === UserRoles.L1Client)
          return true;
        else if (this.state.role === UserRoles.L2User)
          return true;
        else if (this.state.role === UserRoles.L3User)
          return false;
      } else {
        return true;
      }
    };
    

    return (
      <div className="profileCard responsive-div">
        <div
          className="d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
        border-gray-400"
        >
          <div className="d-flex align-items-center">
          <BaseButton
              className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button"
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className="fw-medium fs-15 mb-0 color-gray-1000">
              Edit user access
            </h2>
          </div>
        </div>
        <div className="p-3 overflow-auto">
          <div className="row">
            <div className="">
              <div className="row">
                <div className="col-lg-12 mb-3">
                <div className="item">
                  <InputComponent
                    className="form-control"
                    type={InputType.text}
                    value={this.state.userEmail}
                    disabled={true}
                    label="User email"
                    popQuestion="This email will be used to send a secured invitation link to the user. User will be update to change their emails on the registration page."
                    onChange={(e) => {
                      this.handleChange("userEmail", e.target.value);
                    }}
                    inValid={this.validator.message(
                      "User Email*",
                      this.state.userEmail,
                      "required|email"
                    )}
                  ></InputComponent>
                </div>
                {this.state.invitationStatus === 2 ? (
                ""
              ) : (
                <>
                <div>
                  <div className="col-lg-12 mb-3">
                    <div
                      className="text-primary cursor-pointer mb-1"
                      title="Info"
                    >
                      <label className="color-gray-600 fs-15 me-2">
                        Invitation message*
                      </label>
                      <img
                        className="text-primary cursor-pointer"
                        src="/Images/icons/info-circled.svg"
                        alt="info"
                        title="You can write a personalized message to this user. The message will show on the email as well as the registration page."
                      />
                    </div>
                    <textarea
                      className="form-control"
                      disabled={this.state.searchLoading}
                      //placeholder="Enter your team description"
                      style={{ height: "132px", resize: "none" }}
                      value={this.state.invitationNote}
                      onChange={(e) => {
                        this.handleChange("invitationNote", e.target.value);
                      }}
                    ></textarea>
                  </div>
                </div>
                {/* <div className="item">
                  <InputComponent
                    disabled={this.state.searchLoading}
                    value={this.state.invitationNote}
                    type={InputType.textarea}
                    height="132px"
                    label="Invitation message"
                    popQuestion="You can write a personalized message to this user. The message will show on the email as well as the registration page."
                    optional="optional"
                    className="mt-1"
                    onChange={(e) => {
                      this.handleChange("invitationNote", e.target.value);
                    }}
                  ></InputComponent>
                </div> */}
                <MainButton
                    type={MainButtonType.dark}
                    disabled={this.state.searchLoading || canEditOnlyInvitedUser}
                    children={
                      <div className="d-flex align-items-center justify-content-center">
                        <BiRefresh size="1.4em" className="me-1" />
                        Resend Invitation
                      </div>
                    }
                    onClick={() => {
                      this.ResendInvitation();
                    }}
                    borderRadius="0.8em"
                    fontSize="1rem"
                    className="btn btn-primary fs-15 mb-3 py-px-7 d-flex align-items-center gap-2 responsive-Button-width"
                    minHeight="2em"
                    minWidth="10.5em"
                    loading={this.state.loading}
                  ></MainButton>
                </>)}
                  <div className="item">
                    <SelectComponent
                      disabled={this.state.disableRole || enableRole() || !canIEditAccessLabel || this.state.searchLoading || this.state.invitationStatus !== 1 }
                      items={this.state.listUserTypes}
                      defaultValue={this.state.listUserTypes.find(
                                      (item) => item.value === this.state.userTypeId
                                    )}
                      TextItem="label"
                      ValueItem="value"
                      labelClass="color-gray-600 fs-15 me-2"
                      className="form-select"
                      label="Role name*"
                      placeholder="Click to see the list…"
                      onChange={(e) => {
                        this.setState({
                          userTypeId: e?.value,
                        });
                      }}                      
                      // isMulti
                    ></SelectComponent>
                  </div>
                </div>
                
                <div className="row mt-1">
                  <div className="col-lg-12 mb-3">
                    <label className="color-gray-600 fs-15 me-2 mb-1">
                      {AccessPermition(this.RoleUser, [
                        UserRoles.Admin,
                        UserRoles.L1Client,
                        UserRoles.L1User,
                      ])
                        ? "Assign to teams*"
                        : "Assign to subteams*"}
                    </label>
                    {/* <img
                    className="text-primary cursor-pointer"
                    src="/Images/icons/info-circled.svg"
                    alt="info"
                    title="You can add this user to your existing teams. If you are creating a Level 2 user, you will need to add them to a team to be able to send an invitation. You can always add users to more teams in the future."
                  /> */}
                    <div className="item">
                <SelectComponent
                  disabled={enableRole() || !canIEditAddMemberTeams || this.state.searchLoading}
                  items={
                    this.state.listTeams
                    // .filter(item => this.state.Teams.findIndex(team => team.id === +((item as any).value)) === -1)
                  }
                  TextItem="name"
                  ValueItem="id"
                  // className="my-2"
                  placeholder="Click to see the list…"
                  // label={
                  //   AccessPermition(this.RoleUser, [
                  //     UserRoles.Admin,
                  //     UserRoles.L1Client,
                  //     UserRoles.L1User,
                  //   ])
                  //     ? "Assign to teams"
                  //     : "Assign to subteams"
                  // }
                  popQuestion="You can add this user to your existing teams. If you are creating a Level 2 user, you will need to add them to a team to be able to send an invitation. You can always add users to more teams in the future."

                  onChange={(e, _action) => {
                    if (_action.action === "remove-value") {
                      toast.warning(`Can't remove team`)
                      return
                    }
                    // @ts-ignore
                    const _option = _action.action === 'remove-value' ? _action.removedValue : _action.option
                    const _isInOptions = this.state.listTeams.some((_t: any) => _t.value === _option.value)
                    console.log({ _option, listTeam: this.state.listTeams, e })
                    // this.handelChangeSelect("addedTeamsId", e);
                    // console.log(e, this.state.listTeams, _action)
                    // let _teams = [...e]
                    // if(_action.action === 'remove-value') {
                    //   _teams.push(_action.removedValue)
                    // }

                    // console.log(_teams, this.state.listTeams)
                    // const _isInOptions = this.state.listTeams.filter((item: any) => e.length === 0
                    //   ? true
                    //   : _teams?.some((_e: any) => _e.value === item.value)).length > 0

                    if (!_isInOptions) return toast.warning('You can not modify this team')
                    this.setState({
                      selectedTeams: e,
                    });
                  }}
                  defaultValue={this.state.selectedTeams}
                  isMulti
                ></SelectComponent>
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-lg-12 mb-3">
                    <label className="color-gray-600 fs-15 me-2 mb-1">
                       Assign this user to laboratories (Equipment)*                        
                    
                    {/* <img
                    className="text-primary cursor-pointer"
                    src="/Images/icons/info-circled.svg"
                    alt="info"
                    style={{paddingLeft:"5px"}}
                    title="You can select the laboratories for full access to individual equipment for selective access. You can always edit this in the future."
                  /> */}
                  </label>
                    <div className="item">
                    <NewTreeMenu
                  disabled={this.state.searchLoading}
                  //label="Assign this user to laboratories (Equipment)"
                  onChange={(_labs, _equips) => {
                    this.setState({
                      selectedLabsId: _labs,
                      selectedEquipsId: _equips,
                      selectedLabsEquips: [..._labs, ..._equips]
                    })
                  }}
                  loading={this.state.loading}
                  // onSele ctedItems={() => {
                  popQuestion="You can select the laboratories for full access to individual equipment for selective access. You can always edit this in the future."

                  // }}
                  options={this.state.listLabsEquips}
                  defaultChilds={this.state.defaultEquips}
                  defaultParents={this.state.defaultLabs}
                // forceSelectedChilds={[this.state.creatorUser, _userId]}
                />
                      
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="p-3"> */}
                <MainButton
                  className="btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2 responsive-Button-width"
                  icon={
                    <img
                      src="/images/icons/pencil-2.svg"
                      className="w-px-20"
                      style={{paddingBottom:"2px"}}
                    />
                  }
                  onClick={() => {
                    this.UpdateMember();
                  }}
                  loading={this.state.loading || this.state.searchLoading}
                  disabled={this.state.loading || this.state.searchLoading}
                >Update User
                </MainButton>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      // <div className="container-fluid research new-research">
      //   <div className="row"></div>
      //   <div className="col-12 box-content p-3">
      //     <div className="d-flex align-items-center justify-content-between">
      //       <h5 className="b-title d-flex align-items-center">
      //         <IoIosArrowDropleftCircle
      //           className="me-3 textSecondary2 cursorPointer"
      //           size="1.5em"
      //           onClick={() => {
      //             window.history.back();
      //           }}
      //         />{" "}
      //         Edit user access
      //       </h5>

      //       {/* {this.state.invitationStatus === 2 ? (
      //         ""
      //       ) : (
              
      //       )} */}
      //     </div>
      //     <div className="form row">
      //       <div className="col-md-6 left beforeNoneMd">
      //         <div className="item">
      //           <InputComponent
      //             type={InputType.text}
      //             disabled={this.state.invitationStatus === 2 || this.state.searchLoading || canEditOnlyInvitedUser}
      //             label="User email"
      //             popQuestion="This email will be used to send a secured invitation link to the user. User will be update to change their emails on the registration page."

      //             onChange={(e) => {
      //               this.handleChange("userEmail", e.target.value);
      //             }}
      //             inValid={this.validator.message(
      //               "User email",
      //               this.state.userEmail,
      //               "required|email"
      //             )}
      //             value={this.state.userEmail}
      //           ></InputComponent>
      //         </div>
      //         {this.state.invitationStatus === 2 ? (
      //           ""
      //         ) : (
      //           <>
      //             <div className="item">
      //               <InputComponent
      //                 type={InputType.textarea}
      //                 disabled={this.state.searchLoading || canEditOnlyInvitedUser}
      //                 label="Invitation message"
      //                 popQuestion="You can write a personalized message to this user. The message will show on the email as well as the registration page."
      //                 optional="optional"
      //                 className="mt-2"
      //                 onChange={(e) => {
      //                   this.handleChange("invitationNote", e.target.value);
      //                 }}
      //                 value={this.state.invitationNote}
      //               ></InputComponent>
      //             </div>
      //             <MainButton
      //               type={MainButtonType.dark}
      //               disabled={this.state.searchLoading || canEditOnlyInvitedUser}
      //               children={
      //                 <div className="d-flex align-items-center justify-content-center">
      //                   <BiRefresh size="1.4em" className="me-1" />
      //                   Resend Invitation
      //                 </div>
      //               }
      //               onClick={() => {
      //                 this.ResendInvitation();
      //               }}
      //               borderRadius="0.8em"
      //               fontSize="1rem"
      //               className="mt-3 mb-5 align-self-end"
      //               minHeight="2em"
      //               minWidth="10.5em"
      //               loading={this.state.loading}
      //             ></MainButton>
      //           </>
      //         )}
      //         <div className="item">
      //           <ButtonGroup
      //             label="Access level"
      //             popQuestion="Based on the access level you select in here, user will have limited control over their dashboard. You won't be able to change the access level for the user after invitation in this release of Radvix."

      //             name="AccessLevel"
      //             disabled={this.state.searchLoading || canEditOnlyLabEquip}
      //             items={
      //               AccessPermition(this.RoleUser, [
      //                 UserRoles.Admin,
      //                 UserRoles.L1Client,
      //                 UserRoles.L1User,
      //               ])
      //                 ? [
      //                   {
      //                     name: "Research admin",
      //                     value: 2,
      //                     disable: (this.state.role === 2 || canEditOnlyLabEquip) ? false : true,
      //                   },
      //                   {
      //                     name: "Research manager",
      //                     value: 3,
      //                     disable: (this.state.role === 3 || canEditOnlyLabEquip) ? false : true,
      //                   },
      //                   {
      //                     name: "Researcher",
      //                     value: 4,
      //                     disable: (this.state.role === 4 || canEditOnlyLabEquip) ? false : true,
      //                   },
      //                 ]
      //                 : [
      //                   {
      //                     name: "Research manager",
      //                     value: 3,
      //                     disable: (this.state.role || canEditOnlyLabEquip) === 3 ? false : true,
      //                   },
      //                   {
      //                     name: "Researcher",
      //                     value: 4,
      //                     disable: (this.state.role || canEditOnlyLabEquip) === 4 ? false : true,
      //                   },
      //                 ]
      //             }
      //             TextItem="name"
      //             ValueItem="value"
      //             onChange={(e) => {
      //               this.handleChange("role", parseInt(e.target.value));
      //             }}
      //             selected={this.state.role}
      //           ></ButtonGroup>
      //         </div>
      //         <div className="item">
      //           <SelectComponent
      //             items={this.state.listUserTypes.filter((item) => {
      //               if (this.state.role === 1) return item.role === 2;
      //               return item.role === this.state.role;
      //             })}
      //             disabled={this.state.disableRole || enableRole() || !canIEditAccessLabel || this.state.searchLoading }
      //             // label="Select Access"
      //             TextItem="label"
      //             ValueItem="value"
      //             className="my-1"
      //             // popQuestion="Set "
      //             placeholder="Click to see the list…"
      //             onChange={(e) => {
      //               this.handelChangeSelectType(e);
      //             }}
      //             defaultValue={this.state.listUserTypes.find(
      //               (item) => item.value === this.state.userTypeId
      //             )}
      //           ></SelectComponent>
      //         </div>
      //       </div>
      //       <div className="col-md-6 right">
      //         <div className="item">
      //           <SelectComponent
      //             disabled={enableRole() || !canIEditAddMemberTeams || this.state.searchLoading}
      //             items={
      //               this.state.listTeams
      //               // .filter(item => this.state.Teams.findIndex(team => team.id === +((item as any).value)) === -1)
      //             }
      //             TextItem="name"
      //             ValueItem="id"
      //             // className="my-2"
      //             placeholder="Click to see the list…"
      //             label={
      //               AccessPermition(this.RoleUser, [
      //                 UserRoles.Admin,
      //                 UserRoles.L1Client,
      //                 UserRoles.L1User,
      //               ])
      //                 ? "Assign to teams"
      //                 : "Assign to subteams"
      //             }
      //             popQuestion="You can add this user to your existing teams. If you are creating a Level 2 user, you will need to add them to a team to be able to send an invitation. You can always add users to more teams in the future."

      //             onChange={(e, _action) => {
      //               if (_action.action === "remove-value") {
      //                 toast.warning(`Can't remove team`)
      //                 return
      //               }
      //               // @ts-ignore
      //               const _option = _action.action === 'remove-value' ? _action.removedValue : _action.option
      //               const _isInOptions = this.state.listTeams.some((_t: any) => _t.value === _option.value)
      //               console.log({ _option, listTeam: this.state.listTeams, e })
      //               // this.handelChangeSelect("addedTeamsId", e);
      //               // console.log(e, this.state.listTeams, _action)
      //               // let _teams = [...e]
      //               // if(_action.action === 'remove-value') {
      //               //   _teams.push(_action.removedValue)
      //               // }

      //               // console.log(_teams, this.state.listTeams)
      //               // const _isInOptions = this.state.listTeams.filter((item: any) => e.length === 0
      //               //   ? true
      //               //   : _teams?.some((_e: any) => _e.value === item.value)).length > 0

      //               if (!_isInOptions) return toast.warning('You can not modify this team')
      //               this.setState({
      //                 selectedTeams: e,
      //               });
      //             }}
      //             defaultValue={this.state.selectedTeams}
      //             isMulti
      //           ></SelectComponent>
      //         </div>
      //         <div className="item">
      //           <NewTreeMenu
      //             disabled={this.state.searchLoading}
      //             label="Assign this user to laboratories (Equipment)"
      //             onChange={(_labs, _equips) => {
      //               this.setState({
      //                 selectedLabsId: _labs,
      //                 selectedEquipsId: _equips,
      //                 selectedLabsEquips: [..._labs, ..._equips]
      //               })
      //             }}
      //             loading={this.state.loading}
      //             // onSele ctedItems={() => {
      //             popQuestion="You can select the laboratories for full access to individual equipment for selective access. You can always edit this in the future."

      //             // }}
      //             options={this.state.listLabsEquips}
      //             defaultChilds={this.state.defaultEquips}
      //             defaultParents={this.state.defaultLabs}
      //           // forceSelectedChilds={[this.state.creatorUser, _userId]}
      //           />

      //           {/* <InputLabel
      //             popQuestion="Assign Member To Labs (Equips)"
      //             label="Assign Member To Labs (Equips)"
      //           >
      //             <TreeMenu
      //               data={this.state.listLaboratories}
      //               defaultValues={this.state.mainLabsEquips}
      //               unCheckedValues={this.state.unCheckedValues}
      //               parentLabel="Lab"
      //               childLabel="Equip"
      //               onSelectedItems={(values) => {
      //                 this.setState({
      //                   selectedLabsEquips: values,
      //                 });
      //               }}
      //             />
      //           </InputLabel> */}

      //           {/* <SelectComponent
      //             items={this.state.listEquipsAndLabs}
      //             TextItem="name"
      //             ValueItem="id"
      //             className="my-2"
      //             placeholder="Click to see the list…"
      //             label="Assign Member To Labs (Equips)"
      //             popQuestion="Assign Member To Labs (Equips)"
      //             optional="optional"
      //             onChange={(e) => {
      //               this.handelChangeSelectEqupiAndLab(e);
      //             }}
      //             isMulti
      //           ></SelectComponent> */}
      //         </div>

      //         {/* <div className="item">
      //           <SelectComponent
      //             items={this.state.listProjects}
      //             defaultValue={this.state.selectedResearches}
      //             TextItem="name"
      //             ValueItem="id"
      //             className="my-2"
      //             placeholder="Click to see the list…"
      //             label="Assign Member To Projects"
      //             popQuestion="Assign Member To Projects"
      //             disabled={!canIEditMemberToProject}
      //             optional="optional"
      //             onChange={(e) => {
      //               this.setState({
      //                 selectedResearches: e
      //               })
      //               // this.handelChangeSelect("addedResearchesId", e);
      //             }}
      //             isMulti
      //           ></SelectComponent>
      //         </div> */}
      //       </div>
      //       <div className="col-12 d-flex flex-column justify-content-center align-items-center my-4">
      //         <MainButton
      //           type={MainButtonType.dark}
      //           disabled={this.state.loading || this.state.searchLoading}
      //           children={"Update User"}
      //           onClick={() => {
      //             this.UpdateMember();
      //           }}
      //           borderRadius="50px"
      //           fontSize="18px"
      //           className="mx-2 px-3"
      //           minHeight="43px"
      //           minWidth="136px"
      //           loading={this.state.loading || this.state.searchLoading}
      //         ></MainButton>
      //         <span className="mt-2 textSecondary">
      //           {this.state.loadingMode}
      //         </span>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}
export default withRouter(MemberPageUseEdit);
