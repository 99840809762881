import React from 'react';
import { store } from '../../../data/storeMain';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
import { InputComponent, InputType } from '../../components/inputs';
import 'react-datepicker/dist/react-datepicker.css';
import Dropzone from 'react-dropzone';
import { SelectComponent } from '../../components/select_input';
// import { BoxAlert } from "../../components/box_alert";
import { MainButton, MainButtonType } from '../../components/button';
import { RouteComponentProps, withRouter } from 'react-router';
import { DataController } from '../../../controllers/data/data_controller';
import { UploadController } from '../../../controllers/upload_media/upload_media';
import SimpleReactValidator from 'simple-react-validator';
import {
  $ReplaceRoute,
  // $truncate,
  AccessPermition,
  isTokenInHttpAuth,
  uploadAllFiles,
  uploadAllLinks,
  UserRoles,
} from '../../../core/utils';
import { AddDataReq } from '../../../data/models/requests/data/add_data_req';
// import { RadioGroup } from "../../components/radio_group";
import { AppRoutes, LOADING_MODE } from '../../../core/constants';
import { HTTP } from '../../../core/http_common';
import { ButtonGroup } from '../../components/botton_group';
import { toast } from 'react-toastify';
import { BoxListScroll } from '../../components/box_list_scroll';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import CircleIconTooltip from '../../components/CircleIconTooltip';
import { Checkbox } from '../setting/component/new_notification_tbl';
import DOMPurify from 'dompurify';
import { BaseButton } from '@fluentui/react';
import { ResearchController } from '../../../controllers/research/research_controller';
import {
  HeaderTitleAction,
  SetHeaderTitle,
} from '../../../data/storeMain/actions/header_title_action';

// const CreateNewTaskToast = () => {
//   return (
//     <div className="d-flex justify-content-center align-items-center flex-column">
//       <p>"There is no task in this research, please create a task first"</p>
//       <button>Create new task</button>
//     </div>
//   );
// };
type StateType = {
  isDiscussionForSubTask: number;
  researchId: number;
  equipmentsId: number[];
  selectedEquipment: [];
  appTasksId: number;
  subAppTasksId: number;
  title: string;
  description: string;
  listTasks: Array<{ label: string; value: number } | {}>;
  listSubTasks: Array<{ label: string; value: number } | {}>;
  listEquipment: Array<{ label: string; value: number } | {}>;
  selectedTask: { label: string; value: number } | null;
  selectedSubTask: { label: string; value: number } | null;
  files: Array<File>;
  loading: boolean;
  ExternalUrl: Array<string>;
  External: string;
  Only: number;
  realDataOfTasks: [];
  suggestedEquipmentsList: [];
  acceptTerms: boolean;
  searchLoading: boolean;
  loadingMode: string;
  typeId: number;
  inputValue: string;
  researhList: any[];
  selectedProject: any;
  TaskId: string;
};
let unsubscribeMeData: any = undefined;
class DataPageNew extends React.Component<RouteComponentProps> {
  RoleUser = store.getState().userRole;
  controller = new DataController();
  researchController = new ResearchController();
  UploadController = new UploadController();
  validator = new SimpleReactValidator({
    className: 'text-danger',
  });
  state: StateType = {
    files: [],
    acceptTerms: false,
    searchLoading: false,
    loadingMode: '',
    appTasksId: 0,
    selectedTask: null,
    description: '',
    equipmentsId: [],
    listTasks: [],
    selectedEquipment: [],
    listEquipment: [],
    researchId: 0,
    isDiscussionForSubTask: 0,
    subAppTasksId: 0,
    title: '',
    External: '',
    ExternalUrl: [],
    loading: false,
    Only: 0,
    listSubTasks: [],
    realDataOfTasks: [],
    suggestedEquipmentsList: [],
    typeId: 2,
    inputValue: '',
    selectedSubTask: null,
    researhList: [],
    selectedProject: store.getState().ResearchId,
    TaskId: '',
  };
  componentDidMount() {
    document.title = "New Data | Radvix";
    if (!isTokenInHttpAuth(HTTP)) return;
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const queryParams = new URLSearchParams(url.search);
    const taskId = queryParams.get('taskId');
    this.setState({ TaskId: taskId });
    this.SearchData();
    this.loadResearches();
    unsubscribeMeData = store.subscribe(() => {
      this.setState({
        selectedTask: null,
        suggestedEquipmentsList: [],
        selectedEquipment: [],
      });
      this.SearchData();
    });
  }
  // clean up event listener
  componentWillUnmount() {
    document.title = `Attach Data | Radvix`;
    // dispatch(SetHeaderTitle('Data'))
    if (unsubscribeMeData) unsubscribeMeData();
  }

  loadResearches() {
    this.researchController.getResearches(
      {
        PageNumber: 1,
        PageSize: 100,
        SearchParameter: '',
        columnHeaderName: '',
      },
      (res) => {
        const filteredResearches = res.researchesList?.filter(
          (research) => research.title !== 'All Projects'
        );
        const researches = filteredResearches?.map(({ id, title }) => ({
          value: id,
          label: title,
        }));
        this.setState({ researhList: researches });
      },
      (err) => {}
    );
  }

  SearchData(researchId?: number) {
    this.setState({
      searchLoading: true,
    });
    researchId = researchId !== undefined ? researchId : store.getState().ResearchId.value;
    this.controller.SearchData(
      { researchId },
      (res) => {
        // if (!res) {
        //   toast.info(
        //     "There is no task in this research, please create a task first"
        //   );
        // }
        let _listTasks: any = [];
        let _realeDataOfTasks: any = [];

        if (this.RoleUser === UserRoles.L3User || this.RoleUser === UserRoles.L2User) {
          _listTasks = res?.appTasks?.map((item: any) => {
            return { label: item.title, value: item.id };
          });
          _realeDataOfTasks = res?.subAppTasks;
        } else {
          _listTasks = res?.appTasks?.map((item) => {
            return { label: item.title, value: item.id };
          });
          _realeDataOfTasks = res?.appTasks;
        }
        this.setState({
          realDataOfTasks: _realeDataOfTasks,
          listTasks: _listTasks,
          listEquipment: res?.accessableEquipments?.map((item) => {
            return { label: item.title, value: item.id };
          }),
          searchLoading: false,
          listSubTasks: res.subAppTasks?.map((item) => {
            return { label: item.title, value: item.id };
          }),
        });
        const _urlParams = this.props.location?.search;
        if (_urlParams) {
          const _splitiedParams = _urlParams.split('?')[1].split('&');
          const _taskIdParam = _splitiedParams[0];
          const _taskId = Number(_splitiedParams[0].split('=')[1]);
          if (_taskIdParam.includes('subTaskId')) {
            const _findedTopicFromList = this.state.listSubTasks.find(
              (_d: any) => _d.value === _taskId
            );
            if (_findedTopicFromList) {
              this.setState({
                selectedTask: _findedTopicFromList,
                typeId: 3,
              });
            }
          } else {
            const _findedTopicFromList = this.state.listTasks.find(
              (_d: any) => _d.value === _taskId
            );
            if (_findedTopicFromList) {
              this.setState({
                selectedTask: _findedTopicFromList,
                typeId: 2,
              });
            }
          }
        }
      },
      (err) => {
        this.setState({
          searchLoading: false,
        });
      }
    );
  }

  bytesToMB(bytes: number) {
    return (bytes / (1024 * 1024)).toFixed(3);
  }
  onDrop = (fileList: any) => {
    fileList.map((item: any, index: number) => {
      const found = this.state.files.find((element: any) => element.name === item.name);
      if (found === undefined) {
        this.setState({ files: [fileList[index], ...this.state.files] });
      }
    });
  };
  handelDeleteFile(arg: File) {
    this.setState({
      files: this.state.files.filter((file) => file.name !== arg.name),
    });
  }
  addExternalUrl() {
    if (this.validator.fields.input) {
      let Url = [...this.state.ExternalUrl];
      if (this.state.External.length > 2) {
        Url.push(this.state.External);
      }
      this.setState({
        ExternalUrl: Url,
        External: '',
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handleChangeProject(e: any) {
    if (e && e.value != 0) {
      this.setState({
        selectedProject: e,
      });
      this.SearchData(e.value);
    }
  }

  handleChange(target: string, val: any) {
    const sanitizedValue = DOMPurify.sanitize(val);
    this.setState({
      [target]: sanitizedValue,
    });
  }
  handelChangeSelectMultiple(e: Array<{ label: string; value: number }>, target: string) {
    const newLabId = e.map((item) => item.value);
    this.setState({
      [target]: newLabId,
    });
  }
  handelChangeSelect(e: { label: string; value: number }, target: string) {
    const newLabId = e.value;
    this.setState({
      [target]: newLabId,
    });
  }
  handelDeleteExternalLink(link: string) {
    this.setState({
      ExternalUrl: this.state.ExternalUrl.filter((item) => item !== link),
    });
  }
  async handelUpload(id: number, _res?: any) {
    try {
      this.setState({
        loadingMode: LOADING_MODE.links,
      });
      await uploadAllLinks(id, this.state.ExternalUrl, 8);
      this.setState({
        loadingMode: LOADING_MODE.files,
      });
      await uploadAllFiles(id, this.state.files, 8);
      this.setState({
        loadingMode: '',
      });

      if (_res) {
        // if (this.state.isDiscussionForSubTask === 1) {
        this.props.history.push(
          $ReplaceRoute(
            `${AppRoutes.data_profile.replace(':dataid', _res.data.id?.toString() ?? '').replace(
              ':appTaskId',
              // @ts-ignore
              this.state.TaskId != null ? this.state.TaskId : _res.taskId?.toString() ?? ''
            )}?researchId=${_res.researchId ?? ''}`
          )
        );
        // } else {
        //   this.props.history.push(
        //     $ReplaceRoute(
        //       `${AppRoutes.data_profile.replace(':dataid', _res.data.id?.toString() ?? '').replace(
        //         ':appTaskId',
        //         this.state.typeId === 3
        //           ? // @ts-ignore
        //             _res.subTaskId
        //           : _res.taskId?.toString() ?? ''
        //       )}?researchId=${_res.researchId ?? ''}`
        //     )
        //   );
        // }
      }
    } catch (e) {
      this.setState({
        loading: false,
        loadingMode: '',
      });
    }

    // const _userRole = store.getState().userRole;
    // const formData = new FormData();
    // for (let i = 0; i < this.state.files.length; i++) {
    //   const file = this.state.files[i];
    //   formData.append("Files", file);
    // }
    // for (let i = 0; i < this.state.ExternalUrl.length; i++) {
    //   const file = this.state.ExternalUrl[i];
    //   formData.append("ExternalUrls", file);
    // }

    // formData.append("UseCase", "8");
    // formData.append("SectionId", id.toString());

    // await this.UploadController.UloadMedia(
    //   formData,
    //   (res) => {
    //     this.setState({
    //       loading: false,
    //     });
    //     // this.props.history.push($ReplaceRoute(AppRoutes.data_mydata));
    //     if (_res) {
    //       if (this.state.isDiscussionForSubTask === 1) {
    //         this.props.history.push(
    //           $ReplaceRoute(
    //             `${AppRoutes.data_profile
    //               .replace(":dataid", _res.data.id?.toString() ?? "")
    //               .replace(
    //                 ":appTaskId",
    //                 // @ts-ignore
    //                 _res.subTaskId?.toString() ?? ""
    //               )}?researchId=${_res.researchId ?? ""}`
    //           )
    //         );
    //       } else {
    //         this.props.history.push(
    //           $ReplaceRoute(
    //             `${AppRoutes.data_profile
    //               .replace(":dataid", _res.data.id?.toString() ?? "")
    //               .replace(
    //                 ":appTaskId",
    //                 _userRole === UserRoles.L3User
    //                   ? // @ts-ignore
    //                     _res.subTaskId
    //                   : _res.taskId?.toString() ?? ""
    //               )}?researchId=${_res.researchId ?? ""}`
    //           )
    //         );
    //       }
    //     }
    //   },
    //   () => {
    //     this.setState({
    //       loading: false,
    //     });
    //   }
    // );
  }
  handelCreateData() {
    const _userRole = store.getState().userRole;
    const attachedFileNames = this.state.files.map((file) => file.name);
    if (this.validator.allValid() && this.state.selectedTask) {
      this.setState({
        loading: true,
        loadingMode: 'Creating data..',
      });
      const body: AddDataReq = {
        researchId: this.state.selectedProject.value,
        equipmentsId: this.state.selectedEquipment.map((item: any) => item.value),
        appTasksId: 0,
        title: this.state.title,
        description: this.state.description,
        fileName: attachedFileNames,
      };
      if (this.state.typeId === 3) {
        body.subAppTasksId = this.state.selectedTask.value;
      } else {
        body.appTasksId = this.state.selectedTask.value;
      }

      this.controller.createData(
        body,
        async (res) => {
          if (this.state.files.length > 0 || this.state.ExternalUrl.length > 0) {
            await this.handelUpload(res.data.id, res);
          } else {
            // if (this.state.isDiscussionForSubTask === 1) {
            //   this.props.history.push(
            //     $ReplaceRoute(
            //       `${AppRoutes.data_profile
            //         .replace(':dataid', res.data.id?.toString() ?? '')
            //         .replace(
            //           ':appTaskId',
            //           // @ts-ignore
            //           res.subTaskId?.toString() ?? ''
            //         )}?researchId=${res.researchId ?? ''}`
            //     )
            //   );
            // } else {
            this.props.history.push(
              $ReplaceRoute(
                `${AppRoutes.data_profile.replace(':dataid', res.data.id?.toString() ?? '').replace(
                  ':appTaskId',
                  // @ts-ignore
                  // _userRole === UserRoles.L3User
                  //   ? // @ts-ignore
                  //     res.subTaskId
                  this.state.TaskId != null ? this.state.TaskId : res.taskId?.toString() ?? ''
                )}?researchId=${res.researchId ?? ''}`
              )
            );
            //}
          }

          this.setState({
            files: [],
            appTasksId: 0,
            description: '',
            equipmentsId: [],
            listTasks: [],
            listEquipment: [],
            researchId: 0,
            subAppTasksId: 0,
            title: '',
            External: '',
            ExternalUrl: [],
            loading: false,
            loadingMode: '',
          });
        },
        (err) => {
          this.setState({
            loading: false,
            loadingMode: '',
          });
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  onSelectTask(_selectedTask: any) {
    const _userRole = store.getState().userRole;
    const _selectedTaskData: any = this.state.realDataOfTasks.find(
      (item: any) => item.id === _selectedTask.value
    );
    if (_userRole === UserRoles.L2User || _userRole === UserRoles.L3User) {
      if (this.state.typeId === 3) {
        this.setState({
          listSubTasks: _selectedTaskData?.subTasks?.map((item: any) => {
            return { label: item.title, value: item.id };
          }),
          selectedSubTask: null,
        });
      } else {
        this.setState({
          listSubTasks: _selectedTaskData?.Tasks?.map((item: any) => {
            return { label: item.title, value: item.id };
          }),
          selectedSubTask: null,
        });
      }
    }

    if (_selectedTaskData?.suggestedEquipments?.length > 0) {
      this.setState({
        suggestedEquipmentsList: _selectedTaskData?.suggestedEquipments?.map((item: any) => {
          return { label: item.title, value: item.id };
        }),
      });
    }

    this.setState({
      selectedTask: _selectedTask,
      selectedEquipment: [],
      suggestedEquipmentsList: [],
    });
  }

  formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 MB';

    const m = 1024 * 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizeInMB = bytes / m;

    return `${sizeInMB.toFixed(dm)} MB`;
  }

  onSelectSubTask(_selectedSubTask: any) {
    const _selectedTaskData: any = this.state.realDataOfTasks.find(
      (item: any) => item.id === this.state.selectedTask?.value
    );

    const _selectedSubTaskData = _selectedTaskData?.subTasks.find(
      (_sub: any) => _sub.id === _selectedSubTask.value
    );
    // const _taskDataSuggestedEquip =
    //   _selectedTaskData?.suggestedEquipments?.map((_su: any) => ({
    //     label: _su.title,
    //     value: _su.id,
    //   })) || [];
    const _subTaskDataSuggestedEquip =
      _selectedSubTaskData?.suggestedEquipments?.map((_su: any) => ({
        label: _su.title,
        value: _su.id,
      })) || [];
    this.setState({
      selectedSubTask: _selectedSubTask,
      // suggestedEquipmentsList: [
      //   ..._taskDataSuggestedEquip,
      //   ..._subTaskDataSuggestedEquip,
      // ],
      suggestedEquipmentsList: _subTaskDataSuggestedEquip,
    });
  }
  onChangeIsDiscusstionForSubTask(_val: number) {
    if (_val === 0) {
      if (this.state.selectedSubTask) {
        this.setState({
          selectedSubTask: null,
        });
        this.onSelectTask(this.state.selectedTask);
      }
    }
    this.setState({
      isDiscussionForSubTask: _val,
    });
  }
  render() {
    const _userRole = store.getState().userRole;
    const files = this.state.files.map((file: any) => (
      <li
        key={file.name}
        className='d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team'
        style={{ marginBottom: '3px' }}
      >
        <span
          className='text-truncate col-11 mx-2'
          style={{ fontFamily: 'Sanfransisco', textAlign: 'start' }}
        >
          {file.name} - {this.formatBytes(file.size)}
        </span>
        <CircleIcon
          type={ThemeCircleIcon.dark}
          width='22px'
          padding='0'
          height='22px'
          onClick={() => {
            this.handelDeleteFile(file);
          }}
          style={{ cursor: 'pointer', display: 'contents' }}
        >
          <img src='/images/icons/cross-1.svg' alt='radvix' width={15} height={15} />
        </CircleIcon>
      </li>
    ));
    return (
      <div className='card w-100 new-data-card'>
        <div
          className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
        border-gray-400'
        >
          <div className='d-flex align-items-center'>
            <BaseButton
              className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
              style={{ fontWeight: '400', height: '19px' }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>Attach data to my task</h2>
          </div>
        </div>
        <div className='p-3 overflow-auto'>
          <div className='row'>
            <div className='col-lg-12 mb-3'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='row mt-1'>
                    <div className='col-lg-12 mb-3'>
                      <SelectComponent
                        disabled={this.state.searchLoading}
                        items={this.state.researhList}
                        defaultValue={this.state.selectedProject}
                        TextItem='title'
                        ValueItem='id'
                        labelClass='color-gray-600 fs-15 me-2'
                        className='form-select control-border-color'
                        label='Projects*'
                        labelIcon
                        placeholder='Select project'
                        onChange={(e) => {
                          this.handleChangeProject(e);
                        }}
                        isMulti={false}
                      ></SelectComponent>
                    </div>
                  </div>
                  <div className='row mt-1'>
                    <div className='col-lg-12 mb-3'>
                      <SelectComponent
                        disabled={this.state.searchLoading}
                        items={this.state.listTasks}
                        defaultValue={this.state.selectedTask}
                        TextItem='name'
                        ValueItem='id'
                        labelClass='color-gray-600 fs-15 me-2'
                        className='form-select control-border-color'
                        label='Task*'
                        labelIcon
                        placeholder='Select Task'
                        onChange={this.onSelectTask.bind(this)}
                        isMulti={false}
                        inValid={this.validator.message(
                          'Task',
                          this.state.selectedTask,
                          'required'
                        )}
                      ></SelectComponent>
                    </div>
                  </div>

                  <div className='item'>
                    <InputComponent
                      className='form-control'
                      disabled={this.state.searchLoading}
                      type={InputType.text}
                      label='Dataset name*'
                      labelIcon
                      placeholder='Enter Dateset name'
                      popQuestion="Please select the task that you would like to attach data to it. You won't be able to change the task after attaching the data."
                      onChange={(e) => {
                        this.handleChange('title', e.target.value);
                      }}
                      inValid={this.validator.message('Dateset Name', this.state.title, 'required')}
                    ></InputComponent>
                    {/* <input className="form-control" placeholder="Enter your project name" /> */}
                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='d-flex align-items-center mb-1'>
                        <label className='color-gray-600 fs-15 me-2'>
                          Upload or link dataset files*{' '}
                          <img
                            src='/images/icons/label-icon.svg'
                            width={15}
                            height={15}
                            alt='icon'
                          />
                        </label>
                      </div>
                      <div className='p-px-12 bg-gray-200 border-gray-400 border rounded-px-4 gap-2'>
                        <div className='justify-content-sm-between justify-content-center align-items-center flex-wrap row-gap-px-8'>
                          <Dropzone onDrop={this.onDrop}>
                            {({ getRootProps, getInputProps }) => (
                              // <section
                              //   className="container fileUploadBox"
                              //   style={{ marginTop: "0.3em" }}
                              // >
                              <div {...getRootProps({ className: '' })}>
                                <input {...getInputProps()} />

                                <div className='d-flex justify-content-between align-items-center flex-wrap row-gap-px-8'>
                                  <button className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'>
                                    <div className='d-flex align-items-center'>
                                      <div className='me-1 mb-1'>
                                        <svg
                                          width='15'
                                          height='15'
                                          viewBox='0 0 15 15'
                                          fill='none'
                                          xmlns='http://www.w3.org/2000/svg'
                                        >
                                          <rect
                                            width='15'
                                            height='15'
                                            fill='white'
                                            fill-opacity='0.01'
                                          />
                                          <path
                                            fill-rule='evenodd'
                                            clip-rule='evenodd'
                                            d='M7.81825 1.18197C7.64251 1.00624 7.35759 1.00624 7.18185 1.18197L4.18185 4.18197C4.00611 4.35771 4.00611 4.64263
              4.18185 4.81837C4.35759 4.9941 4.64251 4.9941 4.81825 4.81837L7.05005 2.58657V9.50005C7.05005 9.74858 7.25152 9.95005
              7.50005 9.95005C7.74858 9.95005 7.95005 9.74858 7.95005 9.50005V2.58657L10.1819 4.81837C10.3576 4.9941 10.6425 4.9941
              10.8182 4.81837C10.994 4.64263 10.994 4.35771 10.8182 4.18197L7.81825 1.18197ZM2.5 10.0001C2.77614 10.0001 3 10.2239 3
              10.5001V12.0001C3 12.5539 3.44565 13.0001 3.99635 13.0001H11.0012C11.5529 13.0001 12 12.5529 12 12.0001V10.5001C12
              10.2239 12.2239 10.0001 12.5 10.0001C12.7761 10.0001 13 10.2239 13 10.5001V12.0001C13 13.1041 12.1062 14.0001 11.0012
              14.0001H3.99635C2.89019 14.0001 2 13.1031 2 12.0001V10.5001C2 10.2239 2.22386 10.0001 2.5 10.0001Z'
                                            fill='currentColor'
                                          />
                                        </svg>
                                      </div>
                                      Browse local file
                                    </div>
                                  </button>
                                  <h3 className='fw-normal color-gray-700 fs-13 mb-0 flex-grow-1 text-center'>
                                    {' '}
                                    Or drag and drop files here - Max 128MB{' '}
                                  </h3>
                                </div>
                                {files.length > 0 && (
                                  <div style={{ paddingTop: '10px' }}>
                                    <ul className='list-unstyled fw-normal'>{files}</ul>
                                  </div>
                                )}
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </div>

                      <ul
                        className='file-list mt-1'
                        style={{
                          marginBottom: this.state.ExternalUrl.length > 0 ? '0px' : '0px',
                        }}
                      >
                        {this.state.ExternalUrl.map((item) => (
                          <li className='d-flex align-items-center w-100'>
                            <InputComponent
                              type={InputType.text}
                              value={item}
                              className='mx-2'
                              counter={false}
                              disabled={true}
                              readOnly={true}
                              rowWidth='98%'
                              isExternalURL={true}
                            ></InputComponent>
                            <div
                              style={{
                                paddingBottom: '0px',
                                paddingRight: '0px',
                                paddingTop: '0px',
                                paddingLeft: '5px',
                              }}
                            >
                              <button
                                className='btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0'
                                disabled={this.state.searchLoading}
                                style={{ background: '#F3AEAF' }}
                                onClick={() => {
                                  this.handelDeleteExternalLink(item);
                                }}
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='15'
                                  height='15'
                                  viewBox='0 0 15 15'
                                  fill='none'
                                >
                                  <rect width='15' height='15' fill='white' fill-opacity='0.01' />
                                  <path
                                    fill-rule='evenodd'
                                    clip-rule='evenodd'
                                    d='M5.5 1.00009C5.22386 1.00009 5 1.22395 5 1.50009C5 1.77623 5.22386 2.00009 5.5 2.00009H9.5C9.77614 2.00009 10 1.77623 10 1.50009C10 1.22395 9.77614 1.00009 9.5 1.00009H5.5ZM3 3.50009C3 3.22395 3.22386 3.00009 3.5 3.00009H5H10H11.5C11.7761 3.00009 12 3.22395 12 3.50009C12 3.77623 11.7761 4.00009 11.5 4.00009H11V12.0001C11 12.5524 10.5523 13.0001 10 13.0001H5C4.44772 13.0001 4 12.5524 4 12.0001V4.00009H3.5C3.22386 4.00009 3 3.77623 3 3.50009ZM5 4.00009H10V12.0001H5V4.00009Z'
                                    fill='#D93D42'
                                  />
                                </svg>
                              </button>
                            </div>
                          </li>
                        ))}
                      </ul>
                      <div className='d-flex'>
                        <InputComponent
                          type={InputType.text}
                          placeholder='https://'
                          disabled={this.state.searchLoading}
                          className='control-border-color'
                          value={this.state.External}
                          counter={false}
                          onChange={(e) => {
                            this.handleChange('External', e.target.value);
                          }}
                          rowWidth='98%'
                          onEnter={(e) => {
                            this.addExternalUrl();
                          }}
                        ></InputComponent>
                        <div style={{ padding: '5px', paddingRight: '0px' }}>
                          <button
                            className='btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0'
                            disabled={this.state.searchLoading}
                            onClick={() => {
                              this.addExternalUrl();
                            }}
                          >
                            <i className='fa fa-plus'></i>
                          </button>
                        </div>
                      </div>
                      <p className='text-error mb-0'>
                        {this.validator.message('input', this.state.External, 'url')}
                      </p>
                    </div>
                  </div>
                  <div className='row mt-1'>
                    <div className='col-lg-12'>
                      <div className='text-primary cursor-pointer mb-1' title='Info'>
                        <label className='color-gray-600 fs-15 me-2'>
                          Description*{' '}
                          <img
                            src='/images/icons/label-icon.svg'
                            width={15}
                            height={15}
                            alt='icon'
                          />
                        </label>
                      </div>
                      <InputComponent
                        type={InputType.textarea}
                        disabled={this.state.searchLoading}
                        //label="Project description"
                        //optional="optional"
                        placeholder='Enter dataset description'
                        height='132px'
                        popQuestion='You can write a description for your team. For example, what is the main focus of this team and what are the key objectives. You can always edit this later.'
                        className='mt-1'
                        onChange={(e) => {
                          this.handleChange('description', e.target.value);
                        }}
                      ></InputComponent>
                    </div>
                  </div>
                  <div className='row mt-1'>
                    <div className='col-lg-12 mb-3'>
                      <SelectComponent
                        disabled={this.state.searchLoading}
                        items={this.state.listEquipment}
                        defaultValue={this.state.selectedEquipment}
                        TextItem='name'
                        ValueItem='id'
                        labelClass='color-gray-600 fs-15 me-2'
                        className='form-select control-border-color'
                        label='Assign dataset to my equipment*'
                        labelIcon
                        placeholder='Select equipment'
                        onChange={(e) => {
                          this.setState({
                            selectedEquipment: e,
                          });
                        }}
                        isMulti
                      ></SelectComponent>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <MainButton
                className='btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2'
                icon={<img src='/images/icons/file-plus.svg' />}
                onClick={() => {
                  this.handelCreateData();
                }}
                loading={this.state.loading || this.state.searchLoading}
                disabled={this.state.loading || this.state.searchLoading}
              >
                Attach Data
              </MainButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(DataPageNew);
function dispatch(arg0: HeaderTitleAction) {
  throw new Error('Function not implemented.');
}
