import { CircleIcon, ThemeCircleIcon } from '../../../components/circle_icon';

function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return '0 MB';

  const m = 1024 * 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizeInMB = bytes / m;

  return `${sizeInMB.toFixed(dm)} MB`;
}

type FormattedFilesProps = {
  files: any;
  deleteFile: (arg: File) => void;
  removeMedia?: (id: number) => void;
};

export default function FormattedFiles({ files, deleteFile, removeMedia }: FormattedFilesProps) {
  return (
    <ul className='list-unstyled fw-normal'>
      {files.map((file: any) =>
        file.id !== null && file.id > 0 ? (
          <li
            key={file.title}
            className='d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team'
            style={{ marginBottom: '3px' }}
          >
            <span
              className='text-truncate col-11 mx-2'
              style={{ fontFamily: 'Sanfransisco', textAlign: 'start' }}
            >
              {file.title} - {formatBytes(file.size)}
            </span>
            <CircleIcon
              type={ThemeCircleIcon.dark}
              width='22px'
              height='22px'
              padding='0'
              onClick={() => {
                if (removeMedia) {
                  removeMedia(file.id);
                }
                deleteFile(file);
              }}
              style={{ cursor: 'pointer', display: 'contents' }}
            >
              <img src='/images/icons/cross-1.svg' alt='radvix' width={15} height={15} />
            </CircleIcon>
          </li>
        ) : (
          <li
            key={file.name}
            className='d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team'
            style={{ marginBottom: '3px' }}
          >
            <span
              className='text-truncate col-11 mx-2'
              style={{ fontFamily: 'Sanfransisco', textAlign: 'start' }}
            >
              {file.name} - {formatBytes(file.size)}
            </span>
            <CircleIcon
              type={ThemeCircleIcon.light}
              width='22px'
              height='22px'
              padding='0'
              onClick={() => {
                deleteFile(file);
              }}
              style={{ cursor: 'pointer', display: 'contents' }}
            >
              <img src='/images/icons/cross-1.svg' alt='radvix' width={15} height={15} />
            </CircleIcon>
          </li>
        )
      )}
    </ul>
  );
}
