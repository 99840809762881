import React, { useEffect, useRef, useState } from "react";
import {
  Stack,
  TextField,
  PrimaryButton,
  DefaultButton,
  IStackTokens,
  Separator,
  CommandBar,
  ICommandBarItemProps,
  IconButton,
  Label,
  IIconProps,
} from "@fluentui/react";
import { Text } from "@fluentui/react/lib/Text";
import { HTTP, HTTP_H } from "../../../../../core/http_common";
import { useMsal } from "@azure/msal-react";
import { InputComponent, InputType } from "../../../../components/inputs";
import { MainButton, MainButtonType } from "../../../../components/button";
import { toast } from "react-toastify";
import EmailToField from "./EmailToField";
import { EditorState } from "draft-js";
//import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML, convertToHTML } from "draft-convert";
import "./App.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "@tinymce/tinymce-react";
import { EmailIntegrationController } from "../../../../../controllers/EmailIntegration/EmailIntegration_Controller";
//import { Attachment } from './Attachment'; // Assume you have an Attachment component for handling attachments

// Stack tokens for consistent spacing between elements
const stackTokens: IStackTokens = { childrenGap: 15 };

const EmailComposeComponent = ({
  onDiscardClick,
  OnSendClick,
  email,
  replyall,
  setComposeEmail,
}: any) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [to, setTo] = useState<any[]>([]);
  const [cc, setCc] = useState<any[]>([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState<any>();
  const { instance } = useMsal();
  const [loading, setloading] = useState(false);
  const [attachments, setAttachments] = useState<any>([]);
  const controller = new EmailIntegrationController();
  const handleAttachmentChange = (event: any) => {
    setAttachments([...attachments, ...event.target.files]);
  };
  const [profileId, setProfileId] = useState<any>();
  const [profileType, setProfileType] = useState<any>();
  const removeAttachment = (index: any) => {
    setAttachments((current: any) =>
      current.filter((_: any, i: any) => i !== index)
    );
  };
  
  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "sendEmail",
      text: "Send",
      iconProps: { iconName: "Mail" },
      onClick: () => handleSend(),
      buttonStyles: { root: { background: "rgb(78, 0, 87)", color: "#fff" } },
      className: "",
    },
    {
      key: "DiscardEmail",
      text: "Discard",
      iconProps: { iconName: "Mail" },
      onClick: () => handleDiscard(),
    },
  ];

  const editorRef = useRef<any>();
  useEffect(() => {   
const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const queryParams = new URLSearchParams(url.search);
  const profileId = queryParams.get("profileId");
  const profileType = queryParams.get("profileType");
  setProfileId(profileId);
  setProfileType(profileType);
    if (email) {
      let toValues: any = [];
      let ccValues: any = [];
      if (replyall != 3 && email?.emailAddress) {
        toValues.push({ name: email?.emailAddress, key: email?.emailAddress });
      }
      if (replyall == 2 || replyall == 4) {
        const registeredEmailAddress = localStorage.getItem(
          "registeredEmailAddress"
        );

        email?.toRecipients.forEach((element: any) => {
          if (registeredEmailAddress != element.emailAddress.address) {
            toValues.push({
              name: element.emailAddress.address,
              key: element.emailAddress.address,
            });
          }
        });
        email?.ccRecipients.forEach((element: any) => {
          if (element?.emailAddress?.address) {
            ccValues.push({
              name: element.emailAddress.address,
              key: element.emailAddress.address,
            });
          }
        });
      }
      const originalContent = `<blockquote>${email?.body}</blockquote>`;
      //const toString: any = toValues.join(";");
      //const ccString: any = ccValues.join(";");
      setTo(toValues || []);
      setCc(ccValues || []);
      setSubject(email?.subject);
      let bodyWithLineBreaks = "";
      if (email?.body && replyall != 4) {
        const lineBreaks = Array(5).fill("<p></p>").join("");
        bodyWithLineBreaks = `${lineBreaks}
					${originalContent}`;
      } else {
        const inlineImagesBody = replaceCidWithInlineImages(
          email.body,
          email.attachments
        );
        bodyWithLineBreaks = inlineImagesBody;
      }

      if (email?.attachments && (replyall == 3 || replyall == 4)) {
        const newAttachments = email?.attachments
          .filter((x: any) => x.isInline !== true)
          .map((attachment: any) => {
          const file = new File([attachment.contentBytes], attachment.name, {
            type: attachment.contentType,
          });
          return file;
        });
        setAttachments(newAttachments);
      } else {
        setAttachments([]);
      }
      setBody(bodyWithLineBreaks);
      //setBody(convertFromHTML("<div class='elementToProof' style='font-family:Aptos,Aptos_EmbeddedFont,Aptos_MSFontService,Calibri,Helvetica,sans-serif; font-size:12pt; color:rgb(0,0,0)'>this is the message from outllook application.</div>"))
    }
  }, [email]);
  // Placeholder function to handle sending the email
  const handleSend = () => {
    setloading(true);
    if (email && email.key) {
      let html = editorRef?.current?.getContent();
      const emailData = {
        Body: html,
        Id: email.key,
      };
      const formData = new FormData();
      formData.append("ToEmail", to.map((t) => t.name).join(";"));
      formData.append("CcEmail", cc.map((c) => c.name).join(";"));
      formData.append("EmailSubject", subject);
      formData.append("Body", html);
      formData.append("Id", email.key);
      if(profileId && profileType){
        formData.append("ProfileId", profileId);
        formData.append("ProfileType", profileType);
      }
      

      // Append each file to the formData
      attachments?.forEach((file: any) => {
        formData.append("attachments", file);
      });
      try {
        controller.SendReply(
          formData,
          (res) => {
            if (res) {
              setloading(false);
              toast.info("Email sent successfully.");
              setComposeEmail(0);
            } else {
              setloading(false);
              toast.error("Failed to send email.");
            }
          },
          (err: any) => {
            setloading(false);
            //toast.info("Failed to send email.");
            console.error("Failed to send email:", err);
          }
        );
      } catch (error) {
        setloading(false);
        console.error("Failed to send email:", error);
        //toast.info("Failed to send email.");
      }
    } else {
      let html = editorRef?.current?.getContent();
      const formData = new FormData();
      formData.append("ToEmail", to.map((t) => t.name).join(";"));
      formData.append("CcEmail", cc.map((c) => c.name).join(";"));
      formData.append("EmailSubject", subject);
      formData.append("Body", html);
      if(profileId && profileType){
        formData.append("ProfileId", profileId);
        formData.append("ProfileType", profileType);
      }

      // Append each file to the formData
      attachments.forEach((file: any) => {
        formData.append("attachments", file);
      });
      let _tmpToken = localStorage.getItem("token");

      try {
        let _tmpToken = localStorage.getItem("token");

        controller.SendAsync(
          formData,
          (res) => {
            if (res) {
              toast.info("Email sent successfully.");
              onDiscardClick();
            } else {
              toast.error("Failed to send email.");
            }
            setloading(false);
          },
          (err) => {
            setloading(false);
            console.error("Failed to send email:", err);
            //toast.error("Failed to send email.");
          }
        );
      } catch (error) {
        setloading(false);
        console.error("Failed to send email:", error);
        toast.error("Failed to send email.");
      } finally {
        //setloading(false);
      }
    }
  };

  // Placeholder function to handle discarding the composed email
  const handleDiscard = () => {
    setTo([]);
    setCc([]);
    setSubject("");
    setBody("");
    setAttachments([]);
    setComposeEmail(0);
  };

  const replaceCidWithInlineImages = (htmlContent: any, attachments: any) => {
    let modifiedHtmlContent = htmlContent;

    attachments?.forEach((attachment: any) => {
      const { contentBytes, contentType, contentId } = attachment; // Adjust these property names based on your actual API response
      const dataUrl = base64ToDataUrl(contentBytes, contentType);
      const cidReference = `cid:${contentId}`;
      modifiedHtmlContent = modifiedHtmlContent.replace(
        new RegExp(cidReference, "gi"),
        dataUrl
      );
    });

    return modifiedHtmlContent;
  };
  const base64ToDataUrl = (base64: any, contentType: any) => {
    return `data:${contentType};base64,${base64}`;
  };
  const crossIcon: IIconProps = { iconName: "Cancel" };
  return (
    // <Stack tokens={stackTokens} styles={{ root: { margin: 20, maxWidth: '600px' } }}>
    //   <TextField label="To:" required value={to} onChange={(e, newValue) => setTo(newValue || '')} />
    //   <TextField label="Cc:" value={cc} onChange={(e, newValue) => setCc(newValue || '')} />
    //   <TextField label="Subject:" value={subject} onChange={(e, newValue) => setSubject(newValue || '')} />

    //   {/* Assuming Attachment is a component you created to handle attachments */}
    //   {/* <Attachment /> */}

    //   <TextField label="Body:" multiline autoAdjustHeight value={body} onChange={(e, newValue) => setBody(newValue || '')} />

    //   <Separator />

    //   <Stack horizontal tokens={{ childrenGap: 10 }}>
    //     <PrimaryButton text="Send" onClick={handleSend} />
    //     <DefaultButton text="Discard" onClick={handleDiscard} />
    //   </Stack>
    // </Stack>
    <div className="email-container">
      {(
        <IconButton
          iconProps={crossIcon}
          title="Close"
          ariaLabel="Close"
          onClick={() => setComposeEmail(0)}
        />
      )}
      <div className="recipient-container">
        <div className="recipient-details">
          <div className="recipient-name-container">
            {/* <InputComponent
              type={InputType.text}
              //disabled={this.state.searchLoading}
              label="To"
              //popQuestion="Briefly explain what this task is about. This will provide clarity for yourself and other users that will be working on this task. You can still have documents, discussions within this task or edit this description later."
              //value={this.state.description}
              onChange={(e) => setTo(e.target.value || "")}
              className="recipient-text"
            ></InputComponent> */}
            <EmailToField
              onClick={(e: any) => {
                setTo(e);
              }}
              label={"To"}
              setitem={to}
            />
          </div>
        </div>
      </div>
      <div className="recipient-container">
        <div className="recipient-details">
          <div className="recipient-name-container">
            {/* <InputComponent
              type={InputType.text}
              //disabled={this.state.searchLoading}
              label="Cc"
              //popQuestion="Briefly explain what this task is about. This will provide clarity for yourself and other users that will be working on this task. You can still have documents, discussions within this task or edit this description later."
              //value={this.state.description}
              onChange={(e) => setCc(e.target.value || "")}
              className="recipient-text"
            ></InputComponent> */}
            <EmailToField
              onClick={(e: any) => {
                setCc(e);
              }}
              label={"Cc"}
              setitem={cc}
            />
          </div>
        </div>
      </div>
      <div className="recipient-container">
        <div className="recipient-details">
          <div className="recipient-name-container">
            <InputComponent
              type={InputType.text}
              //disabled={this.state.searchLoading}
              label="Subject"
              //popQuestion="Briefly explain what this task is about. This will provide clarity for yourself and other users that will be working on this task. You can still have documents, discussions within this task or edit this description later."
              value={subject}
              onChange={(e) => setSubject(e.target.value || "")}
              className="recipient-text"
            ></InputComponent>
          </div>
        </div>
      </div>
      <div className="email-body">
        {/* <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}                    
          wrapperClassName="wrapper-class"
          editorClassName="editor-class InputComponentStyle form-control recipient-text"
          toolbarClassName="toolbar-class"
          initialContentState={body}
        /> */}        
        <Editor
          apiKey="vtxs4cqgipml40l04rgm6c2eb0juybvavoagc0u4ylk4a28r"
          init={{
            plugins:
              "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker",
            toolbar:
              "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
            tinycomments_mode: "embedded",
            tinycomments_author: "Author name",
            mergetags_list: [
              { value: "First.Name", title: "First Name" },
              { value: "Email", title: "Email" },
            ],
            content_style: "body { line-height: 1; }",
            ai_request: (
              request: any,
              respondWith: { string: (arg0: () => Promise<never>) => any }
            ) =>
              respondWith.string(() =>
                Promise.reject("See docs to implement AI Assistant")
              ),
          }}
          onInit={(_evt, editor: any) => (editorRef.current = editor)}
          initialValue={body}
        />
        <style>
          {`
          .tox-statusbar { display: none !important; }          
        `}
        </style>
      </div>
      <Stack tokens={{ childrenGap: 10 }} style={{ paddingLeft: "13px" }}>
        <Label htmlFor="fileInput">Add attachments:</Label>
        <input
          id="fileInput"
          type="file"
          multiple
          style={{ display: "none" }} // Hide the default file input
          onChange={handleAttachmentChange}
        />
        <Stack horizontal>
          <TextField
            readOnly
            placeholder="Select file..."
            styles={{ root: { flexGrow: 1 } }}
            onClick={() => document?.getElementById("fileInput")?.click()}
          />
          <IconButton
            iconProps={{ iconName: "Attach" }}
            title="Add attachments"
            ariaLabel="Add attachments"
            onClick={() => document?.getElementById("fileInput")?.click()}
          />
        </Stack>

        <Text variant="medium" block>
          Attachments:
        </Text>
        {attachments?.length > 0 ? (
          <Stack tokens={{ childrenGap: 5 }}>
            {attachments.map(
              (
                file: {
                  name:
                    | boolean
                    | React.ReactChild
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined;
                },
                index: React.Key | null | undefined
              ) => (
                <Stack horizontal key={index} verticalAlign="center">
                  <Text
                    variant="small"
                    styles={{ root: { flexGrow: 1, wordBreak: "break-all" } }}
                  >
                    {file.name}
                  </Text>
                  <IconButton
                    iconProps={{ iconName: "Cancel" }}
                    onClick={() => removeAttachment(index)}
                    title="Remove attachment"
                    ariaLabel="Remove attachment"
                  />
                </Stack>
              )
            )}
          </Stack>
        ) : (
          <Text variant="small">No attachments added.</Text>
        )}
      </Stack>
      <br />
      <div className="">
        <div className="action-buttons">
          <div className="send-button-container">
            <MainButton
              type={MainButtonType.dark}
              children={
                email && email.key && replyall != 3 && replyall != 4
                  ? "Reply"
                  : "Send"
              }
              onClick={() => {
                handleSend();
              }}
              borderRadius="50px"
              fontSize="18px"
              className="mx-2"
              minHeight="29px"
              minWidth="80px"
              loading={loading}
            ></MainButton>
            <MainButton
              type={MainButtonType.dark}
              children={"Discard"}
              onClick={() => {
                handleDiscard();
              }}
              borderRadius="50px"
              fontSize="18px"
              className="mx-2"
              minHeight="29px"
              minWidth="80px"
            ></MainButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailComposeComponent;
