import "./style.css";
import { Inbox } from "../MicrosoftInbox/Inbox";
import React, { useState, useEffect } from "react";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
//import { msalConfig } from './authConfig';
import {
  Client,
  GraphRequestOptions,
  PageCollection,
  PageIterator,
} from "@microsoft/microsoft-graph-client";
import { HTTP } from "../../../core/http_common";
import ReactLoading from "react-loading";
import { EmailOutlook } from "../../pages/dashboard/component/EmailIntegration/EmailOutlook";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { EmailIntegrationController } from "../../../controllers/EmailIntegration/EmailIntegration_Controller";
import { base_url_image } from "../../../core/localVariables";
import { MainButton } from "../button";
import { CheckCircledIcon } from "@radix-ui/react-icons";

export const ConnectMicrosoft = ({ selectedProfileType, filterEmail }: any) => {
  initializeIcons();
  const [inbox, setInbox] = useState(false);
  const [emailData, setEmailData] = useState<any>();
  const [loading, setloading] = useState(false);
  const msal = useMsal();
  const [userEmail, setUserEmail] = useState(null);
  let graphClient: Client | undefined = undefined;
  const controller = new EmailIntegrationController();

  useEffect(() => {
    const fetchData = async () => {
      const emailData = {
        folderNameId: "",
        folderName: "",
      };
      setloading(true);
      try {
        controller.GetUserEmailData(
          emailData,
          (res) => {
            if (res) {
              setEmailData(res.result);
              setloading(false);
              setInbox(true);
            }
          },
          (err: any) => {
            setEmailData([]);
            setloading(false);
            setInbox(false);
          }
        );
      } catch (err) {
        setEmailData([]);
        setloading(false);
        setInbox(false);
      }
    };
    fetchData();
  }, []);
  const { instance } = useMsal();
  const initializeSignIn = () => {
    setloading(true);
    window.location.href = `${base_url_image}/auth/microsoft?token=${localStorage.getItem(
      "token"
    )}`;
  };
  return (
    <>
      {inbox && (
        <EmailOutlook
          Emails={emailData}
          selectedProfileType={selectedProfileType}
          filterEmail={filterEmail}
        />
      )}

      {!inbox && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="microsoftbox">
            <div className="microsoftBoxHeaderWrapper">
              <div className="microsoftboxheader">
                <span className="microsoftBoxContentRightRowText">
                  Easy to integrate with Microsoft
                </span>
                <div>
                  <MainButton
                    className="btn-sm fs-13 px-2 h-px-29 py-0 mt-2"
                    style={{}}
                  >
                    {" "}
                    Connect Now
                  </MainButton>
                </div>
              </div>
            </div>
            <div className="microsoftBoxContent">
              <div className="microsoftBoxContentLeft">
                <img
                  src="/images/images/microsoft-logo.svg"
                  className="microsoftBigLogo"
                />
              </div>
              <div className="microsoftBoxContentRight">
                <div className="microsoftBoxContentRightRow">
                  <CheckCircledIcon color="green"></CheckCircledIcon>
                  <span className="microsoftBoxContentRightRowText">
                    Easily connect your Microsoft email to Radvix
                  </span>
                </div>
                <div className="microsoftBoxContentRightRow">
                  <CheckCircledIcon color="green"></CheckCircledIcon>
                  <span className="microsoftBoxContentRightRowText">
                    Easily connect your Microsoft email to Radvix
                  </span>
                </div>
                <div className="microsoftBoxContentRightRow">
                  <CheckCircledIcon color="green"></CheckCircledIcon>
                  <span className="microsoftBoxContentRightRowText">
                    Easily connect your Microsoft email to Radvix
                  </span>
                </div>
                <div className="microsoftBoxContentRightRow">
                  <CheckCircledIcon color="green"></CheckCircledIcon>
                  <span className="microsoftBoxContentRightRowText">
                    Easily connect your Microsoft email to Radvix
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="group">
              <div className="overlap">
                <div className="rectangle" />
                <div className="div">
                  <div className="connect-button">
                    <div className="overlap-group-wrapper">
                      <div className="overlap-group">
                        <img
                          className="plug-outlet"
                          alt="Plug outlet"
                          src="/Images/images/plug-outlet.svg"
                        />
                        <div
                          className="text-wrapper"
                          style={{ cursor: "pointer" }}
                          onClick={initializeSignIn}
                        >
                          {loading ? (
                            <ReactLoading
                              className="d-flex"
                              type="spin"
                              height="auto"
                              width={20}
                            />
                          ) : (
                            "Connect Now"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="microsoft-svgrepo"
                    alt="Microsoft svgrepo"
                    src="/Images/images/microsoft-icon.svg"
                  />
                </div>
                <div className="group-2">
                  <div className="overlap-2">
                    <p className="p">
                      Easily connect your Microsoft email to Radvix
                    </p>
                    <p className="text-wrapper-2">
                      Track all of your research related emails within their
                      topics.
                    </p>
                    <p className="text-wrapper-3">
                      Reply to your emails directly from Radvix dashboard.
                    </p>
                    <p className="text-wrapper-4">
                      Access emails directly from research components like
                      tasks, datasets and never spend time searching.
                    </p>
                  </div>
                  <img
                    className="check-circle-svgrepo"
                    alt="Check circle svgrepo"
                    src="/Images/images/check-circle.svg"
                  />
                  <img
                    className="img"
                    alt="Check circle svgrepo"
                    src="/Images/images/check-circle.svg"
                  />
                  <img
                    className="check-circle-svgrepo-2"
                    alt="Check circle svgrepo"
                    src="/Images/images/check-circle.svg"
                  />
                  <img
                    className="check-circle-svgrepo-3"
                    alt="Check circle svgrepo"
                    src="/Images/images/check-circle.svg"
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};
