import React from 'react';
import { store } from '../../../data/storeMain';
import { InputComponent, InputType } from '../../components/inputs';
import 'react-datepicker/dist/react-datepicker.css';
import { MainButton, MainButtonType } from '../../components/button';
import { SelectComponent } from '../../components/select_input';
import { ButtonGroup } from '../../components/botton_group';
import SimpleReactValidator from 'simple-react-validator';
import { MemberController } from '../../../controllers/member/member_controller';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppRoutes } from '../../../core/constants';
import { LocalDataSources } from '../../../data/local_datasources';
import {
  $ReplaceRoute,
  AccessPermition,
  // convertEquipsIdsToIds,
  convertEquipsToUncategorized,
  convertLabEquipsToNewTree,
  convertLabEquipToTree,
  filterEqipmetsNotExistInLabs,
  getUserRole,
  isTokenInHttpAuth,
  UserRoles,
} from '../../../core/utils';
import { UserController } from '../../../controllers/user/user_controller';
import { HTTP } from '../../../core/http_common';
// import { InputLabel } from "../../components/input_label";
import { ITreeMenuData } from '../../components/TreeMenu';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import NewTreeMenu, { TreeOptionType } from '../../components/NewTreeMenu';
import { Checkbox } from '../setting/component/new_notification_tbl';
import DOMPurify from 'dompurify';
import { Button } from '@radix-ui/themes';
import { BaseButton } from '@fluentui/react';
import { SetHeaderTitle } from '../../../data/storeMain/actions/header_title_action';
type StateType = {
  isGuestInvited: boolean;
  listLaboratories: ITreeMenuData[];
  selectedLabEquip: string[];
  unCheckedValues: string[];

  userEmail: string;
  invitationNote: string;
  accessLevel: number;
  currentResearchId: number;
  teamsId: number[];
  subTeamsId: number[];
  researchesId: number[];
  role: number;
  typeId: { label: string; value: number; role: number };
  listUserTypes: Array<{ label: string; value: number; role: number }>;
  listTeams: Array<{ label: string; value: number } | {}>;
  listProjects: Array<{ label: string; value: number } | {}>;
  loading: boolean;
  searchLoading: boolean;
  loadingMode: string;
  userRoleEnum: Array<{ label: string; value: number } | {}>;
  listLabsEquips: TreeOptionType[];
  selectedLabsId: number[];
  selectedEquipsId: number[];
  readOnly: boolean;
};
class MemberPageNew extends React.Component<RouteComponentProps> {
  RoleUser = store.getState().userInfo?.role as UserRoles;
  validator = new SimpleReactValidator({
    className: 'text-danger',
    messages: {
      min: 'This field is required.',
    },
  });
  controller = new MemberController();
  userController = new UserController();
  local = new LocalDataSources();
  state: StateType = {
    selectedLabsId: [],
    selectedEquipsId: [],
    listLabsEquips: [],
    unCheckedValues: [],
    listLaboratories: [],
    selectedLabEquip: [],
    accessLevel: -1,
    currentResearchId: 0,
    invitationNote: '',
    listProjects: [],
    listTeams: [],
    researchesId: [],
    role: 0,
    subTeamsId: [],
    teamsId: [],
    userEmail: '',
    loading: false,
    userRoleEnum: [],
    loadingMode: '',
    searchLoading: false,
    listUserTypes: [],
    typeId: {
      label: 'Select role name',
      value: -1,
      role: -1,
    },
    isGuestInvited: false,
    readOnly: false,
  };
  componentDidMount() {
    document.title = `Invite User | Radvix`;
    store.dispatch(SetHeaderTitle('Team'));
    if (!isTokenInHttpAuth(HTTP)) return;
    this.setState({
      searchLoading: true,
    });
    this.controller.SearchMember(
      (res) => {
        const __labs = convertLabEquipsToNewTree(res.laboratories);
        const _uncategorizedEquips: TreeOptionType | false =
          res.equipments.length > 0 ? convertEquipsToUncategorized(res.equipments) : false;
        const __listLabsEquips: TreeOptionType[] = _uncategorizedEquips
          ? [_uncategorizedEquips, ...__labs]
          : __labs;

        const _labs = convertLabEquipToTree(res.laboratories as any);

        const _equipmentOnlyAccess = filterEqipmetsNotExistInLabs(
          res.laboratories as any,
          res.equipments
        );

        if (_equipmentOnlyAccess !== null) {
          _labs.unshift(_equipmentOnlyAccess);
        }
        const _userRole = getUserRole();

        this.setState({
          listLabsEquips: __listLabsEquips,
          listTeams: AccessPermition(_userRole, [UserRoles.L2User])
            ? res.subTeams.map((item) => {
                return { label: item.title, value: item.id };
              })
            : res.teams
            ? res.teams.map((item) => {
                return { label: item.title, value: item.id };
              })
            : [],
          listLaboratories: _labs,
          unCheckedValues: _equipmentOnlyAccess?.value ? [_equipmentOnlyAccess.value] : [],
          listProjects: res.researches.map((item) => {
            return { label: item.title, value: item.id };
          }),
        });

        this.getUserTypes();
      },
      (err) => {
        this.setState({
          searchLoading: false,
        });
      }
    );
    this.setState({
      userRoleEnum: this.local.getSetting()?.userRoleEnum?.map((item) => {
        return { label: item.title, value: item.id };
      }),
    });
  }
  handleChange(target: string, val: any) {
    if (target == 'accessLevel') {
      this.setState({
        [target]: val,
      });
    } else {
      const sanitizedValue = DOMPurify.sanitize(val);
      this.setState({
        [target]: sanitizedValue,
      });
    }
  }
  getUserTypes = () => {
    this.userController.getUserType(
      (res) => {
        this.setState({
          listUserTypes: res.map((item) => {
            return {
              label: item.title,
              value: item.id,
              role: item.userRoleEnum,
            };
          }),
          searchLoading: false,
        });
      },
      (err) => {
        this.setState({
          searchLoading: false,
        });
      }
    );
  };
  handelChangeSelect(target: string, e: Array<{ label: string; value: number }>) {
    this.setState({ [target]: e.map((item) => item.value) });
  }
  handelChangeSelectSingle(target: string, e: { label: string; value: number }) {
    this.setState({ [target]: e.value });
  }

  handelCreateMember() {
    if (this.state.accessLevel !== 2) {
      if (this.state.isGuestInvited) {
        this.createMember();
      } else {
        if (this.validator.allValid()) {
          this.createMember();
        } else {
          this.validator.showMessages();
          this.forceUpdate();
        }
      }
    } else {
      if (this.validator.fieldValid('User Email') && this.validator.fieldValid('Type')) {
        this.createMember();
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }
  }
  createMember() {
    this.setState({
      loadingMode: 'Sending invitation..',
      loading: true,
    });
    // const _labs = this.state.selectedLabEquip
    //   .filter((item) => !isNaN(Number(item)))
    //   .map((item) => +item);

    // let _equips: any[] = this.state.selectedLabEquip.filter((item) =>
    //   isNaN(Number(item))
    // );
    // _equips = convertEquipsIdsToIds(_equips);
    // _equips = _equips.map((item) => +item);

    const body = {
      laboratoriesId: this.state.selectedLabsId,
      equipmentsId: this.state.selectedEquipsId,
      readonly: this.state.readOnly,
      accessLevel: this.state.accessLevel - 1,
      currentResearchId:
        this.state.researchesId.length > 0
          ? this.state.researchesId[this.state.researchesId.length - 1]
          : 0,
      invitationNote: this.state.invitationNote,
      researchesId: this.state.researchesId,
      subTeamsId: this.state.subTeamsId,
      teamsId: this.state.teamsId,
      userEmail: this.state.userEmail,
      role: this.state.isGuestInvited ? UserRoles.ExternalUser : UserRoles.L1User,
      userTypeId: this.state.typeId.value,
    };

    this.controller.createMember(
      body,
      (res) => {
        this.setState({
          accessLevel: 1,
          currentResearchId: 0,
          invitationNote: '',
          listProjects: [],
          listTeams: [],
          researchesId: [],
          role: 0,
          subTeamsId: [],
          teamsId: [],
          userEmail: '',
          loading: false,
          loadingMode: '',
        });
        this.props.history.push(
          $ReplaceRoute(`${AppRoutes.member_profile.replace(':id', res.id)}`)
        );
      },
      (err) => {
        this.setState({
          loading: false,
          loadingMode: '',
        });
      }
    );
  }
  render() {
    const _userRole = getUserRole();

    return (
      <div className='card w-100 new-data-card'>
        <div
          className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
        border-gray-400'
        >
          <div className='d-flex flex-wrap align-items-center gap-px-12'>
            <BaseButton
              className='btn btn-outline-primary fs-15 py-px-4 px-3 d-sm-block d-none back-button'
              style={{ fontWeight: '400', height: '19px' }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>Invite users to Radvix</h2>
          </div>
        </div>
        <div className='p-3 overflow-auto'>
          <div className='row'>
            <div className=''>
              <div className='row'>
                <div className='col-lg-12 mb-3'>
                  <div className='item'>
                    <SelectComponent
                      disabled={this.state.searchLoading}
                      items={this.state.listUserTypes}
                      // defaultValue={this.state.typeId}
                      TextItem='label'
                      ValueItem='value'
                      labelClass='color-gray-600 fs-15 me-2'
                      className='form-select'
                      label='Role name*'
                      placeholder='Click to see the list…'
                      onChange={(e) => {
                        this.setState({
                          typeId: e,
                        });
                      }}
                      inValid={
                        !this.state.isGuestInvited
                          ? this.validator.message('Type', this.state.typeId.value, 'min:0,num')
                          : undefined
                      }
                      // isMulti
                    ></SelectComponent>
                  </div>
                </div>
                <div className='item'>
                  <InputComponent
                    className='form-control'
                    type={InputType.text}
                    disabled={this.state.searchLoading}
                    placeholder='Enter user’s email address'
                    label='User email'
                    popQuestion='This email will be used to send a secured invitation link to the user. User will be update to change their emails on the registration page.'
                    onChange={(e) => {
                      this.handleChange('userEmail', e.target.value);
                    }}
                    inValid={this.validator.message(
                      'User Email*',
                      this.state.userEmail,
                      'required|email'
                    )}
                  ></InputComponent>
                </div>
                <div className='item mb-2' style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={this.state.readOnly}
                    onChange={(e) => {
                      this.setState({ readOnly: !this.state.readOnly });
                    }}
                  />
                  <div style={{ marginLeft: '8px' }}>Read-Only</div>
                </div>
                <div className='item'>
                  <div className='col-lg-12'>
                    <InputComponent
                      type={InputType.textarea}
                      disabled={this.state.searchLoading}
                      label='Invitation message*'
                      placeholder='Enter invitation message for the user'
                      //optional="optional"
                      height='132px'
                      className='mt-1'
                      onChange={(e) => {
                        this.handleChange('invitationNote', e.target.value);
                      }}
                    ></InputComponent>
                  </div>
                </div>
                <div className='item'>
                  <div className='col-lg-12 mb-3'>
                    <label className='color-gray-600 fs-15 me-2 mb-1'>
                      {AccessPermition(_userRole, [
                        UserRoles.Admin,
                        UserRoles.L1Client,
                        UserRoles.L1User,
                      ])
                        ? 'Assign to teams*'
                        : 'Assign to subteams*'}
                    </label>
                    {/* <img
                      className="text-primary cursor-pointer"
                      src="/Images/icons/info-circled.svg"
                      alt="info"
                      title="You can add this user to your existing teams. If you are creating a Level 2 user, you will need to add them to a team to be able to send an invitation. You can always add users to more teams in the future."
                    /> */}

                    <div className='item'>
                      <SelectComponent
                        disabled={this.state.searchLoading || this.state.isGuestInvited}
                        items={this.state.listTeams}
                        TextItem='name'
                        ValueItem='id'
                        className='mt-1'
                        placeholder='Click to see the list…'
                        // label={AccessPermition(_userRole, [
                        //   UserRoles.Admin,
                        //   UserRoles.L1Client,
                        //   UserRoles.L1User,
                        // ])
                        //   ? "Assign to teams"
                        //   : "Assign to subteams"}
                        popQuestion='You can add this user to your existing teams. If you are creating a Level 2 user, you will need to add them to a team to be able to send an invitation. You can always add users to more teams in the future.'
                        onChange={(e) => {
                          this.handelChangeSelect(
                            AccessPermition(_userRole, [
                              UserRoles.Admin,
                              UserRoles.L1Client,
                              UserRoles.L1User,
                            ])
                              ? 'teamsId'
                              : 'subTeamsId',
                            e
                          );
                        }}
                        isMulti
                        inValid={
                          this.state.accessLevel >= 0
                            ? AccessPermition(_userRole, [
                                UserRoles.Admin,
                                UserRoles.L1Client,
                                UserRoles.L1User,
                              ])
                              ? this.validator.message(
                                  'Add Member To Teams',
                                  this.state.teamsId,
                                  'required'
                                )
                              : this.validator.message(
                                  'Add Member To Subteams',
                                  this.state.subTeamsId,
                                  'required'
                                )
                            : undefined
                        }
                      ></SelectComponent>
                    </div>
                  </div>
                </div>
                <div className='item'>
                  <div className='col-lg-12 mb-3'>
                    <label className='color-gray-600 fs-15 me-2 mb-1'>
                      {AccessPermition(_userRole, [
                        UserRoles.Admin,
                        UserRoles.L1Client,
                        UserRoles.L1User,
                      ])
                        ? 'Assign this team to laboratories (Equipment)*'
                        : 'Assign this subteam to laboratories (Equipment)*'}

                      {/* <img
                      className="text-primary cursor-pointer"
                      src="/Images/icons/info-circled.svg"
                      alt="info"
                      style={{paddingLeft:"5px"}}
                      title="When you assign a team to a laboratory or only individual equipment, all of the existing or future members and managers of that team will have access to those laboratories and selected equipment."
                    /> */}
                    </label>
                    <div className='item'>
                      <NewTreeMenu
                        disabled={this.state.searchLoading}
                        placeholder='Select laboratories or equipment'
                        popQuestion='When you assign a team to a laboratory or only individual equipment, all of the existing or future members and managers of that team will have access to those laboratories and selected equipment.'
                        onChange={(_labs, _equips) => {
                          // console.log({ _teams, _users })
                          this.setState({
                            selectedLabsId: _labs,
                            selectedEquipsId: _equips,
                            selectedLabsEquips: [..._labs, ..._equips],
                          });
                        }}
                        loading={this.state.searchLoading}
                        // onSele ctedItems={() => {

                        // }}
                        options={this.state.listLabsEquips}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="p-3"> */}
              <div style={{ paddingTop: '5px' }}>
                <MainButton
                  className='btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2 responsive-Button-width'
                  icon={
                    <img
                      src='/images/icons/paper-plane.svg'
                      className='w-px-20'
                      style={{ paddingBottom: '2px' }}
                    />
                  }
                  onClick={() => {
                    this.handelCreateMember();
                  }}
                  loading={this.state.loading || this.state.searchLoading}
                  disabled={this.state.loading || this.state.searchLoading}
                >
                  Send Invitation
                </MainButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      // <div className="container-fluid research new-research">
      //   <div className="row"></div>
      //   <div className="col-12 box-content p-3">
      //     <h5 className="b-title d-flex align-items-center">
      //       <IoIosArrowDropleftCircle
      //         className="me-3 textSecondary2 cursorPointer"
      //         size="1.5em"
      //         onClick={() => {
      //           window.history.back();
      //         }} />{" "}
      //       Invite users to Radvix
      //     </h5>
      //     <div className="form row">
      //       <div className="col-md-6 left beforeNoneMd">
      //         <div className="item">
      //           <ButtonGroup
      //             disabled={this.state.searchLoading || this.state.isGuestInvited}
      //             label="Access level"
      //             popQuestion="Based on the access level you select in here, user will have limited control over their dashboard. You won't be able to change the access level for the user after invitation in this release of Radvix."
      //             name="AccessLevel"
      //             items={
      //               AccessPermition(_userRole, [
      //                 UserRoles.Admin,
      //                 UserRoles.L1Client,
      //                 UserRoles.L1User,
      //               ])
      //                 ? [
      //                   { name: "Research admin", value: 2 },
      //                 ]
      //                 : [{ name: "Researcher", value: 4 }]
      //             }
      //             inValid={!this.state.isGuestInvited ? (this.validator.message(
      //               "Access Level",
      //               this.state.accessLevel,
      //               "min:1,num"
      //             )) : undefined}
      //             TextItem="name"
      //             ValueItem="value"
      //             selected={this.state.accessLevel}
      //             onChange={(e) => {

      //               // if (this.state.typeId ) {
      //               this.setState({
      //                 typeId: {
      //                   label: "Select access label",
      //                   value: -1,
      //                   role: -1,
      //                 },
      //               });
      //               // }
      //               this.handleChange("accessLevel", parseInt(e.target.value));
      //             }}
      //           ></ButtonGroup>
      //         </div>

      //         <div className="item" style={{marginTop:"0px"}}>
      //           <SelectComponent
      //             disabled={this.state.searchLoading || this.state.isGuestInvited}
      //             items={this.state.listUserTypes.filter(
      //               (item) => item.role === this.state.accessLevel
      //             )}
      //             TextItem="label"
      //             ValueItem="value"
      //             className="my-2"
      //             placeholder="Click to see the list…"
      //             onChange={(e) => {

      //               this.setState({
      //                 typeId: e,
      //               });
      //               // this.handelChangeSelectSingle("typeId", e);
      //             }}
      //             defaultValue={this.state.typeId}
      //             inValid={!this.state.isGuestInvited ? (this.validator.message(
      //               "Type",
      //               this.state.typeId.value,
      //               "min:0,num"
      //             )) : undefined}
      //           ></SelectComponent>
      //         </div>
      //         <div className="item">
      //           <InputComponent
      //             type={InputType.text}
      //             disabled={this.state.searchLoading}
      //             label="User email"
      //             popQuestion="This email will be used to send a secured invitation link to the user. User will be update to change their emails on the registration page."
      //             onChange={(e) => {
      //               this.handleChange("userEmail", e.target.value);
      //             }}
      //             inValid={this.validator.message(
      //               "User Email",
      //               this.state.userEmail,
      //               "required|email"
      //             )}
      //           ></InputComponent>
      //         </div>
      //         <div className="item">
      //           <InputComponent
      //             disabled={this.state.searchLoading}
      //             type={InputType.textarea}
      //             label="Invitation message"
      //             popQuestion="You can write a personalized message to this user. The message will show on the email as well as the registration page."
      //             optional="optional"
      //             className="mt-1"
      //             onChange={(e) => {
      //               this.handleChange("invitationNote", e.target.value);
      //             }}
      //           ></InputComponent>
      //         </div>

      //       </div>
      //       <div className="col-md-6 right">
      //         <div className="item">
      //           <SelectComponent
      //             disabled={this.state.searchLoading || this.state.isGuestInvited}
      //             items={this.state.listTeams}
      //             TextItem="name"
      //             ValueItem="id"
      //             className="mt-1"
      //             placeholder="Click to see the list…"
      //             label={AccessPermition(_userRole, [
      //               UserRoles.Admin,
      //               UserRoles.L1Client,
      //               UserRoles.L1User,
      //             ])
      //               ? "Assign to teams"
      //               : "Assign to subteams"}
      //             popQuestion="You can add this user to your existing teams. If you are creating a Level 2 user, you will need to add them to a team to be able to send an invitation. You can always add users to more teams in the future."
      //             onChange={(e) => {
      //               this.handelChangeSelect(
      //                 AccessPermition(_userRole, [
      //                   UserRoles.Admin,
      //                   UserRoles.L1Client,
      //                   UserRoles.L1User,
      //                 ])
      //                   ? "teamsId"
      //                   : "subTeamsId",
      //                 e
      //               );
      //             }}
      //             isMulti
      //             inValid={
      //               this.state.accessLevel >= 0
      //                 ? AccessPermition(_userRole, [
      //                   UserRoles.Admin,
      //                   UserRoles.L1Client,
      //                   UserRoles.L1User,
      //                 ])
      //                   ? this.validator.message(
      //                     "Add Member To Teams",
      //                     this.state.teamsId,
      //                     "required"
      //                   )
      //                   : this.validator.message(
      //                     "Add Member To Subteams",
      //                     this.state.subTeamsId,
      //                     "required"
      //                   )
      //                 : undefined
      //             }
      //           ></SelectComponent>
      //         </div>

      //         <div className="item">
      //           <NewTreeMenu
      //             label="Assign this user to laboratories (Equipment)"
      //             onChange={(_labs, _equips) => {
      //               this.setState({
      //                 selectedLabsId: _labs,
      //                 selectedEquipsId: _equips,
      //                 selectedLabsEquips: [..._labs, ..._equips]
      //               })
      //             }}
      //             disabled={this.state.searchLoading || this.state.isGuestInvited}
      //             popQuestion="You can select the laboratories for full access to individual equipment for selective access. You can always edit this in the future."
      //             loading={this.state.loading || this.state.searchLoading}
      //             // onSele ctedItems={() => {

      //             // }}
      //             options={this.state.listLabsEquips}
      //           // forceSelectedChilds={[this.state.creatorUser, _userId]}
      //           />

      //           {/* <InputLabel
      //             popQuestion="Assign Member To Labs (Equips)"
      //             label="Assign Member To Labs (Equips)"
      //           >
      //             <TreeMenu
      //               data={this.state.listLaboratories}
      //               parentLabel="Lab"
      //               childLabel="Equip"
      //               unCheckedValues={this.state.unCheckedValues}
      //               onSelectedItems={(values) => {

      //                 this.setState({
      //                   selectedLabEquip: values,
      //                 });
      //               }}
      //             />
      //           </InputLabel> */}
      //         </div>
      //         {/* <div className="item">
      //           <SelectComponent
      //             items={this.state.listProjects}
      //             TextItem="name"
      //             ValueItem="id"
      //             className="my-2"
      //             placeholder="Click to see the list…"
      //             label="Assign Member To Projects"
      //             popQuestion="Assign Member To Projects"
      //             optional="optional"
      //             onChange={(e) => {
      //               this.handelChangeSelect("researchesId", e);
      //             }}
      //             isMulti
      //           ></SelectComponent>
      //         </div> */}
      //       </div>
      //       <div className="col-12 d-flex flex-column justify-content-center align-items-center my-4">
      //         <MainButton
      //           type={MainButtonType.dark}
      //           children={"Send Invitation"}
      //           borderRadius="50px"
      //           fontSize="18px"
      //           className="mx-2"
      //           minHeight="43px"
      //           minWidth="136px"
      //           onClick={() => {
      //             this.handelCreateMember();
      //           }}
      //           loading={this.state.loading || this.state.searchLoading}
      //           disabled={this.state.loading || this.state.searchLoading}
      //         ></MainButton>
      //         <span className="mt-2 textSecondary">
      //           {this.state.loadingMode}
      //         </span>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}
export default withRouter(MemberPageNew);
