import { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { InputIcon } from '../../../components/search_box';
import { getUserTitle, isArrayDataEqual } from '../../../../core/utils';
import { store } from '../../../../data/storeMain';
import TippyFooter from '../../../components/TippyFooter';
import Member from './member';

type AddMemeberContentProps = {
  allMembers: any[];
  onSave: (list: any[]) => void;
  onClose: () => void;
  currentMemebers: any[];
  updatingDiscuss: boolean;
  creatorId: string;
};

const AddMemberContent = ({
  allMembers,
  onSave,
  currentMemebers,
  onClose,
  updatingDiscuss,
  creatorId,
}: AddMemeberContentProps) => {
  const [members, setMembers] = useState<any[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<any[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setMembers(allMembers);
  }, [allMembers]);

  useEffect(() => {
    setSelectedMembers(currentMemebers);
  }, [currentMemebers]);
  return (
    <div className='d-flex flex-column' style={{ minWidth: 302 }}>
      {/* <TippyHeader
        className="inviteUsersTippy"
        title=""
        onClose={() => {
          setSelectedMembers(currentMemebers);
          onClose();
        }}
      /> */}
      {/* <div
        className="d-flex justify-content-center py-2 pt-1"
        style={{
          borderBottom: "1px solid #E5E5E5",
          position: "relative",
        }}
      >
        <span className="textSecondary">Members</span>
        <span
          onClick={() => {
            
          }}
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            cursor: "pointer",
            transform: "translateY(-64%)",
          }}
        >
          <MdClose />
        </span>
      </div> */}
      <InputIcon
        className='inviteUsersTippySearch'
        parentClassName='inviteUsersTippyUsers'
        chilren={
          // search.length > 0 ? (
          <i
            className=''
            aria-hidden='true'
            onClick={() => {
              setSearch('');
            }}
            style={{ marginTop: '4px' }}
          ></i>
          // ) : (
          //   <img
          //     alt=""
          //     src="/images/icons/search_box_icon.svg"
          //     style={{ marginTop: "5px" }}
          //   />
          // )
        }
        placeholder='Search...'
        onChange={(e) => {
          const inputValue = e.target.value;
          const sanitizedValue = DOMPurify.sanitize(inputValue);
          setSearch(sanitizedValue);
        }}
        value={search}
      ></InputIcon>
      <div
        className='d-grid flex-column align-items-start inviteUsersTippyUsers'
        style={{
          maxHeight: '300px',
          overflowY: 'auto',
          maxWidth: '320px',
        }}
      >
        {members
          .filter((item) => {
            const _fullName = getUserTitle(item);
            if (search === '') return true;
            else if (_fullName.toLowerCase().includes(search.toLowerCase())) return true;
            else return false;
          })
          .map((item, index) => {
            return (
              <div className='max1LineText w-100'>
                <Member
                  key={index}
                  detail={item}
                  searchValue={search}
                  isMe={item.id === store.getState().userInfo.id}
                  locked={item.id === store.getState().userInfo.id || item.id === creatorId}
                  onClick={() => {
                    if (currentMemebers.find((i) => i.id === item.id)) {
                      return;
                    }
                    if (selectedMembers.find((m) => m.id === item.id)) {
                      setSelectedMembers(selectedMembers.filter((m) => m.id !== item.id));
                    } else {
                      setSelectedMembers([...selectedMembers, item]);
                    }
                  }}
                  selected={selectedMembers.find((m) => m.id === item.id)}
                />
              </div>
            );
          })}
      </div>
      <TippyFooter
        onCancel={() => {
          setSelectedMembers(currentMemebers);
          onClose();
        }}
        cancelDisabled={updatingDiscuss}
        onConfirm={() => {
          onSave(selectedMembers);
        }}
        confirmDisabled={updatingDiscuss || isArrayDataEqual(selectedMembers, currentMemebers)}
        closeText='Close'
        confirmText='Save'
      />
      {/* <div
        className="d-flex align-items-center mt-2 justify-content-end pt-1"
        style={{
          borderTop: "1px solid #E5E5E5",
        }}
      >
        <MainButton
          children="Close"
          type={MainButtonType.light}
          borderRadius="8px"
          fontSize="16px"
          className="px-3 py-1 me-1"
          disabled={updatingDiscuss}
          onClick={() => {}}
        ></MainButton>
        <MainButton
          children="Save"
          type={MainButtonType.dark}
          disabled={
            updatingDiscuss ||
            isArrayDataEqual(selectedMembers, currentMemebers)
          }
          loading={updatingDiscuss}
          borderRadius="8px"
          fontSize="16px"
          className="px-3 py-1"
          onClick={() => {
            onSave(selectedMembers);
          }}
        ></MainButton>
      </div> */}
    </div>
  );
};

export default AddMemberContent;
