import moment from "moment";
// import React from "react";
import { Link } from "react-router-dom";
import { AppRoutes, MOMENT_DATE_FORMAT } from "../../../../core/constants";
import { $ReplaceRoute, DiscussionTopic } from "../../../../core/utils";
import { CircleIcon, ThemeCircleIcon } from "../../../components/circle_icon";
// import File from "../../../components/File";
import NewTable from "../../../components/NewTable/NewTable";
import { useHistory } from "react-router-dom";
import ShowFilesInTable from "../../../components/ShowFilesInTable";
import { MdCalendarMonth, MdFolder } from "react-icons/md";
import { BsFillChatLeftDotsFill } from "react-icons/bs";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import UserWithAvatar from "../../../components/UserWithAvatar";
// import { RiTimerLine } from "react-icons/ri";
import CustomTooltip from "../../../components/CustomTooltip";
import { ProfileTypes } from "../../../../core/number_extentions";

type Props = {
  fullData: any;
  data: any;
  researchId?: number;
  onBack: () => void;
  taskId?: number;
  loading: boolean;
};

export default function NewDataCollectionTable({
  // fullData,
  data,
  onBack,
  researchId,
  taskId,
  loading,
}: Props) {
  const history = useHistory();
  // if(loading) return (

  // )
  // if (!data && !loading) return null;
  if (data?.appTaskData) {
    return (
      <div className="container-fluid research task">
        <div className="row"></div>
        <div className="col-12">
          <div className="TableBox">
            <div className="TopTableBox d-flex flex-column flex-md-row justify-content-between align-items-md-start mb-3">
              <div className="left d-flex align-items-center">
                <h5 className="mb-0 d-flex align-items-center b-title">
                  <IoIosArrowDropleftCircle
                    className="me-3 textSecondary2 cursorPointer"
                    size="1.5em"
                    style={{
                      minWidth: '1.5em'
                    }}
                    onClick={() => {
                      window.history.back();
                    }}
                  />{" "}
                  Data Collection - {data?.appTaskTitle}
                </h5>
                {/* <InputIcon
                chilren={<img src="/images/icons/search_box_icon.svg" alt="" />}
                width="100%"
                placeholder="Search..."
                TopPosition="15%"
                onChange={(e) => {
                  this.setState({
                    Search: e.target.value,
                  });
                  this.GetDatas(this.state.PageNumber, this.state.PageSize);
                }}
              ></InputIcon> */}
              </div>
              <div className="right  d-flex justify-content-between align-items-baseline">
                {/* <MainButton
                children="New Data"
                type={MainButtonType.dark}
                borderRadius="24px"
                fontSize="16px"
                minWidth="100px"
                onClick={() => {
                  this.props.history.push($ReplaceRoute(AppRoutes.data_new));
                }}
              ></MainButton>
              <MainButton
                children="My Data"
                type={MainButtonType.dark}
                borderRadius="24px"
                fontSize="16px"
                minWidth="100px"
                onClick={() => {
                  this.props.history.push($ReplaceRoute(AppRoutes.data_mydata));
                }}
              ></MainButton>
              <SelectComponent
                width="90px"
                height="44px"
                items={[
                  { label: "10", value: 10 },
                  { label: "15", value: 15 },
                  { label: "20", value: 20 },
                ]}
                TextItem="item"
                ValueItem="id"
                isMulti={false}
                placeholder={this.state.PageSize.toString()}
                onChange={(e) => {
                  this.handelChangePageSize(e);
                }}
              ></SelectComponent> */}
              </div>
            </div>

            <NewTable
              loading={loading}
              // rowClassName="align-items-center"
              headerRowClassName="d-flex d-sm-grid"
              rowClassName="newTableRowPhone relative"
              customGridTemplate="2.3fr 0.8fr 0.8fr 0.6fr 4em"
              data={data?.appTaskData || []}
              onHeaderClick={(columnHeaderName, sortDirection) => headerClick(columnHeaderName, sortDirection)}
              columns={[
                {
                  Header: "Dataset Name",
                  accessor: "title",
                  cellClassName: 'fullRow',
                  ellipsis: true,
                  Cell: (_row) => (
                    <Link
                      className="linkOnHover fontBoldSm"
                      to={$ReplaceRoute(
                        `${AppRoutes.data_profile
                          .replace(":dataid", _row.id?.toString() ?? "")
                          .replace(
                            ":appTaskId",
                            data.appTaskId?.toString() ?? ""
                          )}?researchId=${data.researchId}`
                      )}
                    >
                      {_row.title}
                    </Link>
                  ),
                },
                {
                  Header: "Files",
                  accessor: "medias",
                  cellClassName: 'dNoneSm',
                  headerCellClassName: 'dNoneSm',
                  ellipsis: true,
                  Cell: (_row) => {
                    return <ShowFilesInTable medias={_row.medias} profileId={taskId ?? 0} profileType={ProfileTypes.TASK} />;
                  },
                },
                {
                  Header: "Added By",
                  accessor: "creatorFirstName",
                  headerCellClassName: 'dNoneSm',
                  ellipsis: true,
                  Cell: (_row) => {
                    return (
                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                          <UserWithAvatar user={_row.creatorUser} />
                          <CustomTooltip content='Created date' className="dNone dFlexSm align-items-center ms-3">
                            <MdCalendarMonth size="1.3em" className="me-1 textSecondary" />
                            {moment(_row.createdDate).format(MOMENT_DATE_FORMAT)}
                          </CustomTooltip>

                        </div>
                        <div className="dNone dFlexSm align-items-center mt-2">
                          <ShowFilesInTable medias={_row.medias} profileId={taskId ?? 0} profileType={ProfileTypes.TASK} />
                        </div>
                      </div>
                    );
                    // return (
                    //   <Link
                    //     className="linkOnHover"
                    //     to={$ReplaceRoute(
                    //       `${AppRoutes.member_profile.replace(
                    //         ":id",
                    //         _row.creatorUserId ?? ""
                    //       )}`
                    //     )}
                    //   >
                    //     {_row.creatorFirstName + " " + _row.creatorLastName}
                    //   </Link>
                    // );
                  },
                },
                {
                  Header: "Date",
                  accessor: "createdDate",
                  headerCellClassName: 'dNoneSm',
                  cellClassName: 'dNoneSm',
                  align: "center",
                  Cell: (_row) => moment(_row.createdDate).format(MOMENT_DATE_FORMAT),
                },
                {
                  Header: "Actions",
                  align: 'center',
                  accessor: "id",
                  cellClassName: 'dNoneSm',
                  headerCellClassName: 'dNoneSm',
                  Cell: (_row) => {
                    return (
                      <div className="d-flex align-items-center justify-content-center">
                        <CircleIcon
                          width="2em"
                          height="2em"
                          padding="0"

                          type={ThemeCircleIcon.dark}
                          backgroundColor="#474747"
                          // fontSize="10px"
                          color="#ffff"
                          className="mx-1 pointer"
                          title={
                            _row.haveDiscussion
                              ? "Check Discussion"
                              : "Start Discussion"
                          }
                          onClick={() => {
                            if (_row.haveDiscussion) {
                              history.push(
                                $ReplaceRoute(
                                  // topicId 1 0 => setting.discussionTopicEnum[0] : 0, for research
                                  `${AppRoutes.discussion_list}?topicId=${DiscussionTopic.Data}&sectionId=${_row.id}&parentTask=${data.appTaskId}`
                                )
                              );
                            } else {
                              history.push(
                                $ReplaceRoute(
                                  // topicId 1 0 => setting.discussionTopicEnum[0] : 0, for research
                                  `${AppRoutes.discussion_new}?topicId=${DiscussionTopic.Data}&sectionId=${_row.id}&parentTask=${data.appTaskId}`
                                )
                              );
                            }
                          }}
                        >
                          <BsFillChatLeftDotsFill />
                        </CircleIcon>
                        <CircleIcon
                          width="2em"
                          height="2em"
                          padding="0"
                          type={ThemeCircleIcon.dark}
                          backgroundColor="#474747"
                          // fontSize="10px"
                          color="#ffff"
                          className="pointer"
                          title="View Data"
                          onClick={() => {
                            history.push(
                              $ReplaceRoute(
                                `${AppRoutes.data_profile
                                  .replace(":dataid", _row.id?.toString() ?? "")
                                  .replace(
                                    ":appTaskId",
                                    data.appTaskId?.toString() ?? ""
                                  )}?researchId=${data.researchId}`
                              )
                            );
                          }}
                        >
                          <MdFolder />
                        </CircleIcon>
                        {/* <CircleIcon
                          width="22px"
                          height="22px"
                          type={ThemeCircleIcon.dark}
                          backgroundColor="#474747"
                          fontSize="10px"
                          color="#ffff"
                          className="mx-1 pointer"
                        >
                           <img
                            src="/images/icons/download.svg"
                            alt="radvix"
                            width={15}
                            height={15}
                          />
                        </CircleIcon> */}
                      </div>
                    );
                  },
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
  const headerClick = (columnHeaderName?: string, sortDirection?: string) => {
  };
  // this is for subtask datasets
  return (
    <div className="container-fluid research task">
      <div className="row"></div>
      <div className="col-12">
        <div className="TableBox">
          <div className="TopTableBox d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-3">
            <div className="left d-flex align-items-center">
              <IoIosArrowDropleftCircle
                style={{
                  minWidth: '1.5em'
                }}
                className="me-3 textSecondary2 cursorPointer"
                size="1.5em"
                onClick={() => {
                  //   window.history.back();
                  onBack();
                }}
              />
              <h6 className="mb-0">
                Data Collection - {data?.subAppTaskTitle}
              </h6>
              {/* <InputIcon
                chilren={<img src="/images/icons/search_box_icon.svg" alt="" />}
                width="100%"
                placeholder="Search..."
                TopPosition="15%"
                onChange={(e) => {
                  this.setState({
                    Search: e.target.value,
                  });
                  this.GetDatas(this.state.PageNumber, this.state.PageSize);
                }}
              ></InputIcon> */}
            </div>
            <div className="right  d-flex justify-content-between align-items-baseline">
              {/* <MainButton
                children="New Data"
                type={MainButtonType.dark}
                borderRadius="24px"
                fontSize="16px"
                minWidth="100px"
                onClick={() => {
                  this.props.history.push($ReplaceRoute(AppRoutes.data_new));
                }}
              ></MainButton>
              <MainButton
                children="My Data"
                type={MainButtonType.dark}
                borderRadius="24px"
                fontSize="16px"
                minWidth="100px"
                onClick={() => {
                  this.props.history.push($ReplaceRoute(AppRoutes.data_mydata));
                }}
              ></MainButton>
              <SelectComponent
                width="90px"
                height="44px"
                items={[
                  { label: "10", value: 10 },
                  { label: "15", value: 15 },
                  { label: "20", value: 20 },
                ]}
                TextItem="item"
                ValueItem="id"
                isMulti={false}
                placeholder={this.state.PageSize.toString()}
                onChange={(e) => {
                  this.handelChangePageSize(e);
                }}
              ></SelectComponent> */}
            </div>
          </div>

          <NewTable
            loading={loading}
            headerRowClassName="d-flex d-sm-grid"
            rowClassName="newTableRowPhone relative"
            // rowClassName="align-items-start"
            customGridTemplate="2.3fr 0.8fr 0.8fr 0.6fr 4em"
            data={data?.subAppTaskData || []}
            onHeaderClick={(columnHeaderName,sortDirection) => headerClick(columnHeaderName,sortDirection)}
            columns={[
              {
                Header: "Dataset Name",
                accessor: "title",
                cellClassName: 'fullRow',
                ellipsis: true,
                Cell: (_row) => (
                  <Link
                    className="linkOnHover fontBoldSm"
                    to={$ReplaceRoute(
                      `${AppRoutes.data_profile
                        .replace(":dataid", _row.id?.toString() ?? "")
                        .replace(
                          ":appTaskId",
                          data.appTaskId?.toString() ?? ""
                        )}?researchId=${data.researchId}`
                    )}
                  >
                    {_row.title}
                  </Link>
                ),
              },
              {
                Header: "Files",
                accessor: "medias",
                cellClassName: 'dNoneSm',
                headerCellClassName: 'dNoneSm',
                ellipsis: true,
                Cell: (_row) => {
                  return <ShowFilesInTable medias={_row.medias} profileId={taskId ?? 0} profileType={ProfileTypes.TASK} />;
                },
              },
              {
                Header: "Added By",
                accessor: "creatorFirstName",
                headerCellClassName: 'dNoneSm',
                ellipsis: true,
                Cell: (_row) => {
                  return (
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center">
                        <UserWithAvatar user={_row.creatorUser} />
                        <CustomTooltip content='Created date' className="dNone dFlexSm align-items-center ms-3">
                          <MdCalendarMonth size="1.3em" className="me-1 textSecondary" />
                          {moment(_row.createdDate).format(MOMENT_DATE_FORMAT)}
                        </CustomTooltip>

                      </div>
                      <div className="dNone dFlexSm align-items-center mt-2">
                        <ShowFilesInTable medias={_row.medias} profileId={taskId ?? 0} profileType={ProfileTypes.TASK} />
                      </div>
                    </div>
                  );
                  // return (
                  //   <Link
                  //     className="linkOnHover"
                  //     to={$ReplaceRoute(
                  //       `${AppRoutes.member_profile.replace(
                  //         ":id",
                  //         _row.creatorUserId ?? ""
                  //       )}`
                  //     )}
                  //   >
                  //     {_row.creatorFirstName + " " + _row.creatorLastName}
                  //   </Link>
                  // );
                },
              },
              {
                Header: "Date",
                accessor: "createdDate",
                headerCellClassName: 'dNoneSm',
                cellClassName: 'dNoneSm',
                align: "center",
                Cell: (_row) => moment(_row.createdDate).format(MOMENT_DATE_FORMAT),
              },
              {
                Header: "Actions",
                align: 'center',
                accessor: "id",
                cellClassName: 'dNoneSm',
                headerCellClassName: 'dNoneSm',
                Cell: (_row) => {
                  return (
                    <div className="d-flex align-items-center justify-content-center">
                      <CircleIcon
                        width="2em"
                        height="2em"
                        padding="0"
                        type={ThemeCircleIcon.dark}
                        backgroundColor="#474747"
                        // fontSize="10px"
                        color="#ffff"
                        className="mx-1 pointer"
                        title={
                          _row.haveDiscussion
                            ? "Check Discussion"
                            : "Start Discussion"
                        }
                        onClick={() => {
                          if (_row.haveDiscussion) {
                            history.push(
                              $ReplaceRoute(
                                `${AppRoutes.discussion_list}?topicId=${DiscussionTopic.Data}&sectionId=${_row.id}&parentTask=${taskId}&subTaskId=${data.subAppTaskId}`
                              )
                            );
                          } else {
                            history.push(
                              $ReplaceRoute(
                                `${AppRoutes.discussion_new}?topicId=${DiscussionTopic.Data}&sectionId=${_row.id}&parentTask=${taskId}&subTaskId=${data.subAppTaskId}`
                              )
                            );
                          }
                        }}
                      >
                        <BsFillChatLeftDotsFill />
                      </CircleIcon>
                      <CircleIcon
                        width="2em"
                        height="2em"
                        padding="0"
                        type={ThemeCircleIcon.dark}
                        backgroundColor="#474747"
                        // fontSize="10px"
                        title="View Data"
                        color="#ffff"
                        className="pointer"
                        onClick={() => {
                          history.push(
                            $ReplaceRoute(
                              `${AppRoutes.data_profile
                                .replace(":dataid", _row.id?.toString() ?? "")
                                .replace(
                                  ":appTaskId",
                                  data.subAppTaskId?.toString() ?? ""
                                )}?researchId=${researchId}`
                            )
                          );
                        }}
                      >
                        <MdFolder />
                      </CircleIcon>

                      {/* <CircleIcon
                        width="22px"
                        height="22px"
                        type={ThemeCircleIcon.dark}
                        backgroundColor="#474747"
                        fontSize="10px"
                        color="#ffff"
                        className="mx-1 pointer"
                      >
                        <img
                          src="/images/icons/download.svg"
                          alt="radvix"
                          width={15}
                          height={15}
                        />
                      </CircleIcon> */}
                    </div>
                  );
                },
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
