import Tippy from "@tippyjs/react";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
// import SimpleReactValidator from "simple-react-validator";
import { MemberController } from "../../../../controllers/member/member_controller";
import { AppRoutes } from "../../../../core/constants";
import { HTTP } from "../../../../core/http_common";
import { $ReplaceRoute, $truncate } from "../../../../core/utils";
import { ResendInvatationReq } from "../../../../data/models/requests/member/update_member_req";
import { GetMemberByIDResResult } from "../../../../data/models/responses/member/get_member_by_id_res";
import { MainButton, MainButtonType } from "../../../components/button";
import { InputComponent, InputType } from "../../../components/inputs";
import { InputIcon } from "../../../components/search_box";
import SpinnerLoader from "../../../components/spinnerLoader";

const subscriptionColors: any = {
  Free: "#a3a3a3",
  Essential: "#00a598",
  Standard: "#0020a5",
  Enterprise: "#ffba00",
};

type AdminUserDetailModalProps = {
  selectedUser: string | null;
};
const AdminUserDetail = ({ selectedUser }: AdminUserDetailModalProps) => {
  const history = useHistory();
  const memberController = new MemberController();
  const [memberDetail, setMemberDetail] =
    useState<GetMemberByIDResResult | null>(null);
  const [editLoginInfoVisible, setEditLoginInfoVisible] =
    useState<boolean>(false);
  const [invitationDetail, setInvitationDetail] = useState<{
    id: number;
    invitationNote: string;
    receiverUserId: string;
    senderUser: {
      firstName: string;
      id: string;
      lastName: string;
      profileImage: string | null;
    };
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  
  const isJustEmailInContact =
    !memberDetail?.institution &&
    !memberDetail?.addressLine1 &&
    !memberDetail?.addressLine2 &&
    !memberDetail?.city &&
    !memberDetail?.location;
  const _userTitle =
    memberDetail?.firstName === " "
      ? memberDetail?.userEmail
        ? memberDetail?.userEmail
        : `* No Email ...${memberDetail?.id.toString().slice(-4)}`
      : memberDetail?.firstName + " " + memberDetail?.lastName;

  // const _selectedDegreeTitle = local
  //   .getSetting()
  //   ?.degree.find((_deg) => _deg.id === memberDetail?.degree)?.title;
  useEffect(() => {
    // console.log("selectedClient", selectedClient);

    if (selectedUser) {
      handleGetUserDetail();
    }
    // use this api to get member detail Team/MemberById?userId=selectedClient
  }, [selectedUser]);

  const handleGetUserDetail = () => {
    if (!selectedUser) return;
    setLoading(true);
    memberController.getMember(
      {
        userId: selectedUser,
        token: localStorage.getItem("token") ?? "",
      },
      (res) => {
        setMemberDetail(res);
        if (res.invitationStatus === 1) {
          memberController.getInviteInfo(
            {
              userId: selectedUser,
              token: localStorage.getItem("token") ?? "",
            },
            (infoInvite) => {
              setInvitationDetail(infoInvite);
            },
            () => {}
          );
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };
  const handleResendInvitation = () => {
    if (!invitationDetail) return;
    setLoading(true);

    const body: ResendInvatationReq = {
      id: invitationDetail?.id || 0,
      senderUserId: invitationDetail?.senderUser.id || "0",
      receiverUserId: invitationDetail?.receiverUserId || "0",
      invitationNote: invitationDetail?.invitationNote,
      receiverEmail: memberDetail?.userEmail || "",
      teamId: 0,
      researchId: 0,
      equipmentId: 0,
      laboratoryId: 0,
    };
    memberController.remote.resendInvatation(
      body,
      (res) => {
        // this.setState({
        //   userEmail: "",
        //   invitationNote: "",
        //   currentResearchId: 0,
        //   addedTeamsId: [],
        //   addedLaboratoriesId: [],
        //   addedEquipmentsId: [],
        //   addedResearchesId: [],
        //   deletedTeamsId: [],
        //   deletedLaboratoriesId: [],
        //   deletedEquipmentsId: [],
        //   deletedResearchesId: [],
        //   role: 0,
        //   userTypeId: 0,
        //   listTeams: [],
        //   listProjects: [],
        //   loading: false,
        //   userRoleEnum: [],
        //   Teams: [],
        // });
        setLoading(false);
        toast.success("Your invitation has been resent successfully");
        // this.props.history.push(AppRoutes.member);
      },
      (err) => {
        setLoading(false);
      }
    );
  };
  return (
    <div
      className="modal-dialog"
      style={{
        width: 460,
        maxWidth: "100%",
        borderRadius: "1em",
        overflow: "visible",
      }}
    >
      <div
        className="modal-content"
        style={{
          backgroundColor: "#ddd",
        }}
      >
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              backgroundColor: "rgba(0,0,0,0.2)",
            }}
          >
            <SpinnerLoader />
          </div>
        ) : null}
        {/* <div className="modal-header">
            <h5 className="mb-0">Invite a new client</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close_modal_Type"
            ></button>
          </div>
          sub Detail - {selectedClient} */}

        <div className="d-flex flex-column p-3">
          <div
            className="d-grid admin-sub-modal-detail-wrapper"
            style={{
              gridTemplateColumns: "0.8fr 1.2fr",
              // backgroundColor: "#fff",
            }}
          >
            <div>Client Name</div>
            <div>{_userTitle}</div>

            <div>Instutaion</div>
            <div>{memberDetail?.institution || "-"}</div>

            <div>Subscription</div>
            <div>
              <span
                className="pendingChip"
                style={{
                  backgroundColor:
                    subscriptionColors[
                      memberDetail?.subscriptionSettingTitle?.split(" ")[1] ||
                        "Free"
                    ],
                  color:
                    memberDetail?.subscriptionSettingTitle === "Radvix Standard"
                      ? "white"
                      : "black",
                }}
              >
                {memberDetail?.subscriptionSettingTitle?.split(" ")[1]}
              </span>
            </div>

            <div>Joined at</div>
            <div>{moment(memberDetail?.joinedDate).format("ll")}</div>

            <div>Email</div>
            <div>{memberDetail?.userEmail}</div>

            <div>Contacts</div>
            <div className="d-flex flex-column">
              {memberDetail?.institution ? (
                <span title="Institution">{memberDetail?.institution}</span>
              ) : (
                ""
              )}
              {memberDetail?.addressLine1 !== " " ? (
                <span title="Address 1, Address 2">
                  {memberDetail?.addressLine1}
                  {memberDetail?.addressLine2
                    ? ", " + memberDetail?.addressLine2
                    : ""}
                </span>
              ) : (
                ""
              )}
              {memberDetail?.city ? (
                <span title="City - Province - Zipcode">
                  {memberDetail?.city} - {memberDetail?.province} -{" "}
                  {memberDetail?.zipCode}
                </span>
              ) : (
                ""
              )}

              {memberDetail?.location ? (
                <span title="Location">{memberDetail?.location.title}</span>
              ) : (
                ""
              )}

              {memberDetail?.userEmail ? (
                <span
                  title="Click to send email"
                  className={isJustEmailInContact ? "" : "mt-3"}
                >
                  <a href={`mailto:${memberDetail?.userEmail}`}>
                    {$truncate(memberDetail?.userEmail, 32)}
                  </a>
                </span>
              ) : (
                ""
              )}
              {memberDetail?.phoneNumber ? (
                <span title="Phone number">{memberDetail?.phoneNumber}</span>
              ) : (
                ""
              )}
            </div>
          </div>

          <MainButton
            children="Resend Invitation"
            // disabled
            type={MainButtonType.dark}
            borderRadius="24px"
            fontSize="14px"
            minWidth="100px"
            minHeight="34px"
            className="mt-2"
            disabled={memberDetail?.invitationStatus === 2}
            onClick={() => {
              handleResendInvitation();
            }}
          ></MainButton>

          <MainButton
            onClick={() => {
              const _dimmer = document.getElementById("modal-user-detail");
              _dimmer?.click();
              history.push(
                $ReplaceRoute(
                  // topicId 1 0 => setting.discussionTopicEnum[0] : 0, for research
                  `${AppRoutes.admin_tickets}?userId=${selectedUser}`
                )
              );
            }}
            children="Check Ticketing History"
            type={MainButtonType.dark}
            borderRadius="24px"
            fontSize="14px"
            minWidth="100px"
            minHeight="34px"
            className="mt-2"
          ></MainButton>
          <Tippy
            placement="bottom"
            // append to html
            // appendTo={document.getElementsByTagName('html')[0]}
            interactive={true}
            maxWidth="none"
            arrow={false}
            visible={editLoginInfoVisible}
            onClickOutside={() => {
              setEditLoginInfoVisible(false);
            }}
            content={
              <EditUserLoginInfo
                memberDetail={memberDetail}
                memberController={memberController}
                onSave={() => {
                  setEditLoginInfoVisible(false);
                  handleGetUserDetail();
                }}
                onCancel={() => {
                  setEditLoginInfoVisible(false);
                }}
              />
            }
          >
            <span className="mt-2 w-100">
              <MainButton
                onClick={() => {
                  setEditLoginInfoVisible(true);
                }}
                children="Edit Login Information"
                type={MainButtonType.dark}
                borderRadius="24px"
                fontSize="14px"
                minWidth="100px"
                minHeight="34px"
                className="w-100"
              ></MainButton>
            </span>
          </Tippy>
        </div>
      </div>
    </div>
  );
};

type Props = {
  onSave: () => void;
  onCancel: () => void;
  //   userId: string;
  memberDetail?: any;
  memberController?: MemberController;
};

const EditUserLoginInfo = ({
  onSave,
  onCancel,
  memberDetail,
  memberController,
}: Props) => {
  const [showPw, setShowPw] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const isEmailCorrect = useMemo(() => {
    return userEmail.length > 0 && userEmail.includes("@");
  }, [userEmail]);
  //   const Validator = useRef(
  //     new SimpleReactValidator({
  //       className: "text-danger",
  //     })
  //   );

  useEffect(() => {
    if (memberDetail?.userEmail) setUserEmail(memberDetail?.userEmail);
  }, [memberDetail]);
  if (!memberDetail) return <></>;

  function changePassword(oldPw: string, newPw: string, email: string) {
    setLoading(true);
    return new Promise((resolve, reject) => {
      HTTP.post("/User/ChangePassword", {
        oldPassword: oldPw,
        newPassword: newPw,
        email: email,
      })
        .then((res) => {
          setLoading(false);
          toast.success(
            "Password changed successfully, the password copied to clipboard"
          );
          // copy to clipboard new password
          navigator?.clipboard?.writeText(newPw);

          resolve(res.data);
        })
        .catch((err) => {
          setLoading(false);
          reject(err);
        });
    });
  }
  async function handleUpdateUserPassword() {
    // setLoading(true);

    await changePassword(userPassword, userPassword, memberDetail?.userEmail);
    onSave();

    // memberController?.updateUserPassword(
    //   {
    //     email: memberDetail?.userEmail,
    //     newPassword: userPassword,
    //   },
    //   (res) => {
    //     setLoading(false);
    //     onSave();
    //     
    //   },
    //   (err) => {
    //     setLoading(false);
    //     
    //   }
    // );
  }
  
  function handleUpdateUserEmail() {
    setLoading(true);
    try {
      HTTP.post(
        // `/User/Account/UpdateUserEmail/${memberDetail?.id}/${userEmail}`, {
        `/User/UpdateUserEmail`,
        {
          userId: memberDetail?.id,
          email: userEmail,
        }
      )
        .then((res) => {
          setLoading(false);
          toast.success(
            "Email changed successfully, the new email copied to clipboard"
          );
          // copy to clipboard new password
          navigator?.clipboard?.writeText(userEmail);

          // resolve(res.data);
        })
        .catch((err) => {
          
          setLoading(false);
          // reject(err);
        });
    } catch (error) {
      
    }

    // memberController?.updateUserEmail(
    //   {
    //     email: userEmail,
    //     userId: memberDetail?.id,
    //   },
    //   (res) => {
    //     
    //     setLoading(false);
    //     onSave();
    //   },
    //   (err) => {
    //     
    //     setLoading(false);
    //   }
    // );
  }
  function handleSendResetPasswordLink() {
    setLoading(true);
    // HTTP.post(`/User/Account/ResetPassword/${memberDetail?.userEmail}`)
    HTTP.post(`/User/RequestResetPassword?email=${memberDetail?.userEmail}`)
      .then((res) => {
        toast.success(
          `Reset password link send to ${memberDetail?.userEmail}.`
        );
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setLoading(false);
      });
  }
  //   console.log(userEmail, userPassword);
  return (
    <div
      className="d-flex flex-column align-items-start p-1"
      style={{ minWidth: 320 }}
    >
      <div
        className="d-flex align-items-center justify-content-between w-100 pb-1"
        style={{
          borderBottom: "1px solid #ccc",
        }}
      >
        <label className="textSecondary">Edit user login information</label>
        <MdClose
          size="1.4em"
          className="pointer"
          onClick={() => {
            onCancel();
          }}
        />
      </div>

      <h6 className="mt-3">User email</h6>
      <InputComponent
        autoComplete={"off"}
        type={InputType.text}
        // chilren={<MdEmail />}
        // width="100%"
        className=""
        placeholder="Enter new email"
        value={userEmail}
        onChange={(e) => {
          setUserEmail(e.target.value);
        }}
        // inValid={Validator.current.message(
        //   "email",
        //   userEmail,
        //   "email"
        // )}
      ></InputComponent>
      <MainButton
        children="Update Email"
        type={MainButtonType.dark}
        disabled={
          userEmail === "" ||
          !isEmailCorrect ||
          loading ||
          userEmail === memberDetail?.userEmail
        }
        loading={loading}
        borderRadius="8px"
        fontSize="14px"
        className="px-3 py-1 mt-2 mb-2"
        onClick={handleUpdateUserEmail}
      ></MainButton>

      <h6 className="mt-2">User password</h6>
      <InputIcon
        type={showPw ? "text" : "password"}
        chilren={
          showPw ? (
            <IoMdEyeOff
              size="1.4em"
              className="pointer"
              onClick={() => {
                setShowPw(!showPw);
              }}
            />
          ) : (
            <IoMdEye
              size="1.4em"
              className="pointer textSecondary2"
              onClick={() => {
                setShowPw(!showPw);
              }}
            />
          )
        }
        // width="100%"
        parentClassName="w-100"
        placeholder="Enter new password"
        TopPosition="15%"
        onChange={(e) => {
          // get new password
          setUserPassword(e.target.value);
        }}
        value={userPassword}
      ></InputIcon>
      <MainButton
        disabled={userPassword.length < 3 || loading}
        children="Update Password"
        type={MainButtonType.dark}
        borderRadius="8px"
        fontSize="14px"
        className="px-3 py-1 mt-2"
        onClick={handleUpdateUserPassword}
        loading={loading}
      ></MainButton>

      <h6 className="mt-3 mb-0">Send reset password email to user</h6>
      <MainButton
        disabled={!memberDetail?.userEmail}
        children="Send reset password email"
        type={MainButtonType.dark}
        borderRadius="8px"
        fontSize="14px"
        className="px-3 py-1 mt-2"
        onClick={handleSendResetPasswordLink}
        loading={loading}
      ></MainButton>

      {/* <div className="mt-4 w-100 d-flex align-items-center justify-content-end">
        <MainButton
          children="Cancel"
          type={MainButtonType.light}
          borderRadius="8px"
          fontSize="14px"
          className="px-3 py-1"
          onClick={onCancel}
        ></MainButton>
        <MainButton
          children="Save Changes"
          type={MainButtonType.dark}
          borderRadius="8px"
          fontSize="14px"
          className="px-3 py-1 ms-2"
          onClick={handleSaveChanges}
        ></MainButton>
      </div> */}
    </div>
  );
};
export default AdminUserDetail;
