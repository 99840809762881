// import axios from "axios";
// import { setupCache } from "axios-cache-adapter";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { AppRoutes } from "./constants";

import {
  base_url_api,
  base_url_image,
  base_url_redirect,
} from "./localVariables";
import { handleClearStorageAndTokens, handleLogoutApi } from "./utils";

// import { setupCache } from 'axios-cache-interceptor';

// export const cache = setupCache({
//   maxAge: 15 * 60 * 1000,
//   exclude: { query: false,
//     methods: [

//     ]
//   },
//   clearOnStale: true,
//   // invalidate: (_any) => {
//   //   console.log(_any)
//   // }
//   // onRequest method

//   // invalidate: async (config, request) => {
//   //   console.log({config, request})
//   //   // if (request.clearCacheEntry) {
//   //   //   await config.store.removeItem(config.uuid)
//   //   // }
//   // }
// });
export const BASE_URL_REDIRECT = base_url_redirect;
export const HTTP_IMG = axios.create();
export const IMAGE_BASE_URL = base_url_image;
export const HTTP = axios.create({
  // baseURL: AppConstants.base_url_api,
  baseURL: base_url_api,
  headers: {
    "content-type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "'http://localhost:3000'",
    // 'Cache-Control': 'max-age=3600'
  },
  // adapter: cache.adapter,
});
export const HTTP_H = axios.create({
  // baseURL: AppConstants.base_url_api,
  baseURL: base_url_api,
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: "multipart/form-data",
    "Access-Control-Allow-Origin": "'http://localhost:3000'",
    
    // 'Cache-Control': 'max-age=3600'
  },
  // adapter: cache.adapter,
});
export const PUBLIC_HTTP = axios.create({
  // baseURL: AppConstants.base_url_api,
  baseURL: base_url_api,
  headers: {
    "content-type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "'http://localhost:3000'",
    // 'Cache-Control': 'max-age=3600'
  },
  // adapter: cache.adapter,
});

// let token = localStorage.getItem("token");
// if (token) {
//   HTTP.defaults.headers.common["authorization"] = `Bearer ${token}`;
// }
HTTP.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    
    return Promise.reject(error);
  }
);
HTTP_H.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    
    return Promise.reject(error);
  }
);

const refreshToken = async () => {
  try {
    // Make a request to the refresh token API endpoint
    const _refreshToken = localStorage.getItem("refreshToken");
    
    if (!_refreshToken) {
      toast.error(
        "Your session has expired. Please logout and login again."
        //"Your session has expired. Please login again."
        // `StatusError:${error.response.status} : ${error.response.data.message}`,
      );
      logout(); //remove the code
      return;
    }
    const _res = await HTTP.post("/User/Refresh", _refreshToken);
    
    const { accessToken, refreshToken } = _res.data.result;
    if (_res.data && _res.data.result) {
      localStorage.setItem("token", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
    }

    // Extract the new access token from the response
    // const { accessToken } = _res.data;

    // // Update the access token in the HTTP headers
    // HTTP.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    // Return the new access token
    return accessToken;
  } catch (e: any) {
    if (e.response.data.status === 403) {
      handleClearStorageAndTokens();
      window.location.replace(AppRoutes.login);
      toast.error("Your session has expired. Please login again.");
      return;
    }
    
    logout(); //remove the code
    toast.error(
      "Your session has expired. Please login again."
      // `StatusError:${error.response.status} : ${error.response.data.message}`,
    );
  }
};

// Function to handle 401 errors and retry request
export const handleUnauthorizedRequest = async (
  requestConfig: AxiosRequestConfig
) => {
  try {
    
    if (!requestConfig.headers) return;
    

    // Call the refresh token function to get a new access token
    const accessToken = await refreshToken();

    // Update the access token in the headers of the original request
    HTTP.defaults.headers.common["authorization"] = `Bearer ${accessToken}`;
    HTTP_H.defaults.headers.common["authorization"] = `Bearer ${accessToken}`;
    requestConfig.headers["authorization"] = `Bearer ${accessToken}`;

    // Retry the original request with the new access token
    return axios(requestConfig);
  } catch (refreshError) {
    // Handle any errors that occur during the token refresh
    console.error("Error refreshing token:", refreshError);
    logout(); //remove the code
    toast.error(
      "Your session has expired. Please login again."
      // `StatusError:${error.response.status} : ${error.response.data.message}`,
    );

    throw refreshError; // Throw the error to be handled elsewhere
  }
};
const logout = () => {
  // handleClearStorageAndTokens()
  handleLogoutApi()
    .then((res) => {
      handleClearStorageAndTokens();
        window.location.replace(AppRoutes.login);
    })
    .catch((err) => {
      if (err === "Device Id not found") {
        handleClearStorageAndTokens();        
        window.location.replace(AppRoutes.login);        
        return;
      }
      const _tokenInStorage = localStorage.getItem("token");
      if (!_tokenInStorage) {
        handleClearStorageAndTokens();
        window.location.replace(AppRoutes.login);
      } else {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config).then((_res) => {
            handleClearStorageAndTokens();
            window.location.replace(AppRoutes.login);
          });
          // .catch((e) => error(e));
        } else {
          handleClearStorageAndTokens();
          window.location.replace(AppRoutes.login);
          // error(err);
        }
      }
    });

  // HTTP.post("/User/Logout")
  //   .then((res) => {
  //     handleClearStorageAndTokens();
  //     window.location.replace(AppRoutes.login);
  //   })
  //   .catch((err) => {
  //     if (err.response && err.response.status === 401) {
  //       // Retry the request with refreshed token
  //       return handleUnauthorizedRequest(err.config).then((_res) => {
  //         handleClearStorageAndTokens();
  //         window.location.replace(AppRoutes.login);
  //       });
  //       // .catch((e) => error(e));
  //     } else {
  //       // error(err);
  //     }
  //   });
};
HTTP.interceptors.response.use(
  async function (response) {
    // console.log(response)
    if (response.status >= 200 || response.status <= 299) {
      if (response.request.fromCache) {
        // const cachedData = cache.store.store[`${response.config.baseURL}${response.config.url}`]
        // const freshData = await HTTP.get(response.config.url || '')
        // console.log(freshData)
        // const freshData = await getNewFreshData(response.config.url)
        // if(freshData){
        //   const _strFreshData =JSON.stringify(freshData.data);
        //   const _strCachedData = JSON.stringify(response.data)
        //   console.log(_strCachedData === _strFreshData, freshData.data, response.data)
        //   if(_strCachedData !== _strFreshData){
        //     // response.data = freshData.data
        //   }
        // }
        // console.log({ response, cache, cachedData: response.data });
        // const _reqUrl = `https://api.radvix.com/api${response.config.url}`;
        // const cachedDataStringify = JSON.stringify(response.data.data)
        // const freshDataStringify = JSON.stringify(freshData.data);
        // if(cachedDataStringify !== freshDataStringify){
        // console.log({cachedData: response.data.data, freshData})
        // response.data = freshData.data;
        // return response;
        // }
        // console.log(freshData, cachedDataStringify, freshDataStringify, cachedDataStringify !== freshDataStringify)
        // return response;
      }

      // if (response.config.method !== "get") {
      //   if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      //     // dev code
      //   } else {
      //     // production code
      //     return response;
      //   }
      // }
    }

    return response;
  },
  function (error: AxiosError) {
    // const originalRequest = error.config;

    if (JSON.parse(JSON.stringify(error)).message === "Network Error") {
      // toast.error('Network Error')
      // if (window) window.dispatchEvent(new CustomEvent("NETWORK_ERROR"));
    }
    if (error.response) {
      if (error.response.status) {
        

        if (error.response.status === 401) {
          // localStorage.clear();
          // window.location.replace(AppRoutes.login);
        }
        // toast.error(
        //   error.response?.data?.message
        // );
      }
    }
    
    return Promise.reject(error);
  }
);

HTTP_H.interceptors.response.use(
  async function (response) {
    // console.log(response)
    if (response.status >= 200 || response.status <= 299) {
      if (response.request.fromCache) {
        // const cachedData = cache.store.store[`${response.config.baseURL}${response.config.url}`]
        // const freshData = await HTTP.get(response.config.url || '')
        // console.log(freshData)
        // const freshData = await getNewFreshData(response.config.url)
        // if(freshData){
        //   const _strFreshData =JSON.stringify(freshData.data);
        //   const _strCachedData = JSON.stringify(response.data)
        //   console.log(_strCachedData === _strFreshData, freshData.data, response.data)
        //   if(_strCachedData !== _strFreshData){
        //     // response.data = freshData.data
        //   }
        // }
        // console.log({ response, cache, cachedData: response.data });
        // const _reqUrl = `https://api.radvix.com/api${response.config.url}`;
        // const cachedDataStringify = JSON.stringify(response.data.data)
        // const freshDataStringify = JSON.stringify(freshData.data);
        // if(cachedDataStringify !== freshDataStringify){
        // console.log({cachedData: response.data.data, freshData})
        // response.data = freshData.data;
        // return response;
        // }
        // console.log(freshData, cachedDataStringify, freshDataStringify, cachedDataStringify !== freshDataStringify)
        // return response;
      }

      // if (response.config.method !== "get") {
      //   if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      //     // dev code
      //   } else {
      //     // production code
      //     return response;
      //   }
      // }
    }

    return response;
  },
  function (error: AxiosError) {
    // const originalRequest = error.config;

    if (JSON.parse(JSON.stringify(error)).message === "Network Error") {
      // toast.error('Network Error')
      // if (window) window.dispatchEvent(new CustomEvent("NETWORK_ERROR"));
    }
    if (error.response) {
      if (error.response.status) {
        

        if (error.response.status === 401) {
          // localStorage.clear();
          // window.location.replace(AppRoutes.login);
        }
        // toast.error(
        //   error.response?.data?.message
        // );
      }
    }
    
    return Promise.reject(error);
  }
);

// async function getNewFreshData(_url: string | undefined) {
//   try {
//     const _token = localStorage.getItem("token");
//     if (!_url || !_token) return;
//     const _HTTP = axios.create({
//       baseURL: AppConstants.base_url_api,
//       headers: {
//         "content-type": "application/json",
//         Accept: "application/json",
//         "Access-Control-Allow-Origin": "'http://localhost:3000'",
//         "Access-Control-Allow-Credentials": "true",
//         // 'Cache-Control': 'max-age=3600'
//         authorization: `Bearer ${_token}`,
//       },
//     });
//     return await _HTTP.get(_url);
//   } catch (e) {
//     
//   }
// }
