import React from "react";
import { MainButton, MainButtonType } from "./button";

type Props = {
  closeText?: string;
  confirmText?: string;
  onCancel: () => void;
  onConfirm: () => void;
  cancelDisabled?: boolean;
  confirmDisabled?: boolean;
  confirmLoading?: boolean;
  className?: string
};

export default function TippyFooter({
  closeText = "Cancel",
  confirmText = "Confirm",
  className = "",
  onCancel,
  onConfirm,
  cancelDisabled = false,
  confirmDisabled = false,
  confirmLoading = false,
}: Props) {
  return (
    <div
      className={`d-flex align-items-center mt-2 justify-content-end pt-1 ${className}`}
      style={{
        borderTop: "1px solid #E5E5E5",
      }}
    >
      <MainButton
        children={closeText}
        type={MainButtonType.light}
        borderRadius="8px"
        fontSize="14px"
        className=" btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2 responsive-Button-width rt-r-size-2 rt-variant-solid rt-Button px-3 py-1 me-1 lightButton"
        disabled={cancelDisabled}
        onClick={onCancel}
      ></MainButton>
      <MainButton
        children={confirmText}
        type={MainButtonType.dark}
        disabled={confirmDisabled}
        loading={confirmLoading}
        borderRadius="8px"
        fontSize="14px"
        className=" btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2 responsive-Button-width rt-r-size-2 rt-variant-solid rt-Button px-3 py-1 me-1 lightButton"
        onClick={onConfirm}
      ></MainButton>
    </div>
  );
}
