import { FC, memo, useState } from "react";
import AuthLayout from "./auth_layout";
import Stepper from "../../../components/Steper/Stepper";
import RegisterInformationType, {
  IRegisterInformationTypeInputs,
} from "./register_information_type";
import { ILabelValue } from "../../../../core/interfaces";
import RegisterInformationUser, {
  RegisterInformationUserInputs,
} from "./register_information_user";
import { IRegisterForm } from "../register";

interface IRegisterInformation {
  timezones: ILabelValue[];
  onSubmitForm: (values: IRegisterForm) => void;
  isLoading?: boolean;
}

enum FormSteps {
  First,
  Second,
}

export type RegisterTypes = "google" | "manual";

const RegisterInformation: FC<IRegisterInformation> = ({
  timezones,
  onSubmitForm,
  isLoading,
}) => {
  const [step, setStep] = useState(FormSteps.First);
  const [infoType, setInfoType] = useState<
    IRegisterInformationTypeInputs | undefined
  >();
  const [registerType, setRegisterType] = useState<RegisterTypes>("manual");

  const onSubmit = (values: RegisterInformationUserInputs) => {
    if (infoType === undefined) {
      changeStep(FormSteps.First);
      return;
    }
    onSubmitForm({
      ...infoType,
      ...values,
    });
  };

  const changeStep = (step: number) => {
    setStep(step);
  };

  return (
    <AuthLayout>
      <Stepper step={step}>
        <RegisterInformationType
          registerType={registerType}
          changeRegisterType={(value) => {
            setRegisterType(value);
          }}
          onSubmit={(value) => {
            setInfoType(value);
            changeStep(FormSteps.Second);
          }}
          defaultValues={infoType}
        />
        <RegisterInformationUser
          timezones={timezones}
          onSubmit={onSubmit}
          onPrev={() => {
            changeStep(FormSteps.First);
          }}
          defaultValues={infoType}
          isLoading={isLoading}
        />
      </Stepper>
    </AuthLayout>
  );
};

export default memo(RegisterInformation);
