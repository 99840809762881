import { toast } from "react-toastify";
import { TaskReq } from "../../data/models/requests/task/task_req";
import { TaskStatusUpdate, UpdateTaskReq } from "../../data/models/requests/task/update_task_req";
import { GetAllTasksResult } from "../../data/models/responses/task/get_all_tasks_res";
import { GetTaskByIDResult } from "../../data/models/responses/task/get_task_by_id_res";
import { SearchTaskResResult } from "../../data/models/responses/task/search_task_res";
import { TaskResResult } from "../../data/models/responses/task/task_res";
import { UpdateTaskResResult } from "../../data/models/responses/task/update_task_req";
import { RemoteTask } from "../../data/remotes/task/remote_task";
import { store } from "../../data/storeMain";
import { SortType } from "../../core/number_extentions";
import { IFilterOptions } from "../../views/components/FilterSection/IFilterOptions";
export class TaskController {
  remote = new RemoteTask();

  createTask(
    body: TaskReq,
    action: (res: TaskResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.createTask(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        // toast.error(`${err.message}`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  SearchTask(
    body: { taskId?: number | null, },
    action: (res: SearchTaskResResult) => any,
    error: (res: any) => any
  ) {
    // console.log(store.getState())
    if (store.getState().ResearchId.value > 0) {
      // console.log('moshkel injiye',store.getState().ResearchId.value);
      this.remote.SearchTask(
        { researchId: store.getState().ResearchId.value, taskId: body?.taskId },
        (res) => {
          action(res.result!);
        },
        (err) => {
          if (err.response?.data.message)
            toast.error(`${err.response?.data.message}`);
          // toast.error(`${err.message}`, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          error(err);
        }
      );
    } else {
      // console.log(`please select research in search task`)
      // toast.error(`please select research in search task`, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }
  }
  getTasks(
    body: {
      researchId: number;
      PageSize: number;
      PageNumber: number;
      SearchParameter: string;
      columnHeaderName?: string;
      sortDirection?: SortType;
      filterOptions?: IFilterOptions;
    },
    action: (res: GetAllTasksResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getTasks(
      body,
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  getTaskById(
    body: {
      TaskId: number;
      ResearchId: number;
      columnHeaderName?: string;
      sortDirection?: SortType;
    },
    action: (res: GetTaskByIDResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getTaskById(
      body,
      (res) => {
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  updateTask(
    body: UpdateTaskReq,
    action: (res: UpdateTaskResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.updateTask(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  getSuggestedEquipment(
    body: {
      usersId: string[];
      teamsId: number[];
      researchId: number;
    },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.getSuggestedEquipment(
      body,
      (res) => {
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  deleteTask(
    body: { taskId: number; isSubTask: boolean },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.deleteTask(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  markAsCompleted(
    body: TaskStatusUpdate,
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.markAsCompleted(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

}
