import React, { memo, ReactElement } from 'react'

interface IVisible {
    visible?: boolean
    children: ReactElement
}

function Visible({
    visible = true,
    children
}: IVisible): ReactElement {

    if (!visible) return <></>
    return (
        <>
            {children}
        </>
    )
}

export default memo(Visible)