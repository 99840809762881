import React, { useEffect, useState } from 'react';
import { TagPicker, ITag } from '@fluentui/react';

const EmailToField = ({ onClick, label, setitem }: any) => {
  const [selectedItems, setSelectedItems] = useState<ITag[]>(setitem);

  useEffect(() => {
    setSelectedItems(setitem);
  }, [setitem]);

  const getTextFromItem = (item: ITag): string => item.name;

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onResolveSuggestions = (filterText: string, selectedItems?: ITag[]): ITag[] => {
    if (!filterText || !isValidEmail(filterText)) return [];
    const existingKeys = new Set(selectedItems?.map(item => item.key));
    const filteredSuggestions = [{ key: filterText, name: filterText }];
    return filteredSuggestions.filter(suggestion => !existingKeys.has(suggestion.key.toLowerCase()));
  };

  const onItemSelected = (item?: ITag): ITag | null => {
    if (item && isValidEmail(item.name)) {
      return item;
    }
    return null;
  };

  const onChange = (items?: ITag[]) => {
    setSelectedItems(items || []);
    onClick(items);
  };

  return (
    <>
      <span className="label d-flex align-items-center mb-1">
        {label}
      </span>
      <TagPicker
        removeButtonAriaLabel="Remove"
        onResolveSuggestions={onResolveSuggestions}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={{
          suggestionsHeaderText: 'Suggested Emails',
          noResultsFoundText: 'No Email Found'
        }}
        itemLimit={undefined} // Set undefined for unlimited tags
        onChange={onChange}
        selectedItems={selectedItems}
        inputProps={{
          'aria-label': 'Email To field'
        }}
        className='width-100 recipient-text'
      />
    </>
  );
};

export default EmailToField;
