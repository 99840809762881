/* eslint-disable no-useless-concat */
import React, { Fragment, useEffect } from "react";
// import { AppConstants } from "../../core/constants";
import { Theme } from "../../core/utils";
import { CircleIcon, ThemeCircleIcon } from "./circle_icon";
import { EmptyState } from "./empty_state";
import { IconTextRow } from "./icon_text_horizontal";
// import { TfiUnlink } from "react-icons/tfi";
import Skeleton from "react-loading-skeleton";
import { IMAGE_BASE_URL } from "../../core/http_common";
import { AnyMxRecord } from "dns";
// import axios from "axios";
interface IBoxListScroll {
  items: any[];
  TextItem: string;
  TextItem2?: string;
  ValueItem: string;
  ImageItem: string;
  Deletabel?: boolean;
  DeleteFunc?: (e: any, value: any) => void;
  className?: string;
  onClick?: (e: any, value: any) => void;
  default_photo: string;
  emptyText: string;
  parentClassName?: string;
  loading?: boolean;
}

// export const ImageWithToken = ({ hasImage,
//   src,
//   alt,
//   className,
//   // profileImage,
//   originalImage,
//   ...rest }: ImageInterface) => {
//   const [image, setImage] = useState('');

//   useEffect(() => {
//     const _primUrl = originalImage?.split('?')[0];
//     if(!_primUrl) return;
//     const cachedImage = localStorage.getItem(_primUrl);
//     if (cachedImage) {
//       setImage(cachedImage);
//     } else {
//       fetch(originalImage)
//         .then(response => response.blob())
//         .then(blob => {
//           const objectUrl = URL.createObjectURL(blob);
//           setImage(objectUrl);
//           localStorage.setItem(_primUrl, objectUrl);
//         }).catch(e => {

//         });
//     }
//   }, [originalImage]);

//   if (!image) {
//     return <div>Loading...</div>;
//   }

//   return <img src={image} className={`${!image ? "imageLoading" : ""} ${className}`} alt={alt} {...rest} />;
// };
interface ImageInterface {
  src: string;
  alt: string;
  className: string;
  originalImage?: string | null | undefined;
  hasImage?: boolean;
  textInAvatar?: string;
  textClassName?: any
  [key: string]: any;
}
export const ImageWithToken: React.FC<ImageInterface> = ({
  hasImage,
  src,
  alt,
  className,
  originalImage,
  textInAvatar,
  textClassName,
  ...rest
}) => {
  // const [noImage, setNoImage] = React.useState(false);
  // const [imageLoading, setImageLoading] = React.useState(false);
  const [pulsing, setPulsing] = React.useState(true);
  const _cookie = localStorage.getItem('USER_COOKIES')
  const [subscriptionCookiee, setSubscriptionCookiee] = React.useState<any>({});
  const imageLoaded = () => {
    // setImageLoading(false);
    setPulsing(false);
  };

  useEffect(() => {
  //   const listcookie: any[] = _cookie ? JSON.parse(_cookie) : [];
  // const _subscriptionCookiee = listcookie.find((x) => x.subscriptionId == rest.subscriptionid);
  // setSubscriptionCookiee(_subscriptionCookiee)
  }, []);

  const getInitials = (name: string): string => {
    const words = name.split(" ");
    if(words.length == 1 && name.length > 1)
      {
        return words
            .slice(0, 2)
            .map(word => word.slice(0, 2))
            .join(" ");
      }
      else{
        return words
        .slice(0, 2)
        .map((word) => word[0])
        .join("");
      }
    
  };
  if (!hasImage && textInAvatar) {
    const initials = getInitials(textInAvatar);
    // const {backgroundColor, textColor} = generateColorFromName(textInAvatar)
    return (
      <span
        title={textInAvatar}
        className={`avatar w-px-25 h-px-25 rounded-circle bg-primary ${textClassName ? textClassName : ''}`}
      >
        <span className={`fw-medium text-white fs-10`}
        >{initials}</span>
      </span>
    );
  }
  // if (noImage)
  //   return (
  //     <div
  //       title="Image not load"
  //       className={`${className} d-flex align-items-center justify-content-center`}
  //       style={{
  //         backgroundColor: "#d3d3d3",
  //         height: rest.style?.height || 35,
  //         width: rest.style?.width || 35,
  //       }}
  //     >
  //       <TfiUnlink />
  //     </div>
  //   );
  return (
    <img
      onError={(_err) => {
        setPulsing(false);
        // console.log(_err, "img ERRRRRORRRRRRRRRRRRRRR");
      }}
      alt={pulsing ? "" : alt}
      className={`${pulsing ? "imageLoading" : ""} ${className}`}
      onLoad={imageLoaded}
      src={originalImage ? `${originalImage}${_cookie}` : src}
      {...rest}
    />
  );
};
export const BoxListScroll: React.FC<IBoxListScroll> = ({
  items,
  TextItem,
  TextItem2,
  ValueItem,
  ImageItem,
  Deletabel,
  DeleteFunc,
  className,
  onClick,
  default_photo,
  emptyText,
  loading,
  parentClassName = "",
}) => {
  let IsclassName;
  if (className !== undefined) {
    IsclassName = className;
  } else {
    IsclassName = "";
  }
  return (
    <div className={`parent-box-list-scroll ${parentClassName}`}>
      {loading ? (
        <div className="box-list-scroll">
          {[0, 1, 2, 3, 4].map((idx) => (
            <div className="d-flex align-items-center my-2">
              <Skeleton className="avatar" borderRadius="50%" />
              <Skeleton
                containerClassName="w-100"
                width={idx % 2 === 0 ? "60%" : "74%"}
                height="2em"
              />
            </div>
          ))}
        </div>
      ) : (
        <>
          {items.length > 0 ? (
            <div className={`${IsclassName + " " + "box-list-scroll"}`}>
              {items.map((item, index) => {
                // console.log(item, item[`${ImageItem}`]);
                return (
                  <Fragment key={index}>
                    <div
                      onClick={(e) => {
                        if (onClick !== undefined) {
                          onClick(e, item[`${ValueItem}`]);
                        }
                      }}
                      // title={`Go to ${
                      //   item[`${TextItem}`] +
                      //   " " +
                      //   `${
                      //     item[`${TextItem2}`] !== undefined
                      //       ? item[`${TextItem2}`]
                      //       : ""
                      //   }`
                      // }`}
                      className="d-flex justify-content-between align-items-center"
                      key={index}
                    >
                      <IconTextRow
                        theme={Theme.light}
                        parentStyle={{
                          borderBottom: items.length - 1 === index ? "none" : "1px solid #e9e9e9",
                        }}
                        className="py-2"
                        children={
                          <ImageWithToken
                            // hasImage={item[`${ImageItem}`] ? true : false}
                            src={
                              item[`${ImageItem}`] === null ||
                                item[`${ImageItem}`] === "" ||
                                item[`${ImageItem}`] === undefined
                                ? default_photo
                                : IMAGE_BASE_URL +
                                item[`${ImageItem}`]
                            }
                            alt="Avatar"
                            className="rounded-circle avatar"
                            originalImage={item[`${ImageItem}`]}
                          />
                        }
                        text={
                          <div className="d-flex flex-wrap gap-1">
                            {item[`${TextItem}`] +
                              " " +
                              `${item[`${TextItem2}`] !== undefined
                                ? item[`${TextItem2}`]
                                : ""
                              }`}

                            {item.secondary ? (
                              <div className="pendingChip">
                                {item.secondary}
                              </div>
                            ) : null}
                          </div>
                        }
                      // className="my-2"
                      ></IconTextRow>
                      {Deletabel && DeleteFunc !== undefined ? (
                        <CircleIcon
                          type={ThemeCircleIcon.dark}
                          width="22px"
                          height="22px"
                          className="mx-3"
                          padding="0"
                          onClick={(e) => {
                            DeleteFunc(e, item[`${ValueItem}`]);
                          }}
                        >
                          <img
                            src="/images/icons/garbage_can.svg"
                            alt="radvix"
                            width={15}
                            height={15}
                          />
                        </CircleIcon>
                      ) : null}
                    </div>
                  </Fragment>
                );
              })}
            </div>
          ) : (
            <EmptyState
              backgroundColor="#c7c7c7"
              text={emptyText}
              fontSize="15px"
              color="#474747"
              minHeight="100px"
            />
          )}
        </>
      )}
    </div>
  );
};
