import { toast } from "react-toastify";
// import { getUserTitle } from "../../core/utils";
import { ResearchReq } from "../../data/models/requests/research/research_req";
import { UpdateResearchReq } from "../../data/models/requests/research/update_research_req";
import { ResearchesResResult, TrialResResult } from "../../data/models/responses/research/researches_res";
import { GetResearchByidResResult } from "../../data/models/responses/research/research_by_id_res";
import { ResearchResResult } from "../../data/models/responses/research/research_res";
import { TimelineResResult } from "../../data/models/responses/research/timeline_res";
import { UpdateResearchResResult } from "../../data/models/responses/research/update_research_req";
import { RemoteResearch } from "../../data/remotes/research/remote_research";
import { store } from "../../data/storeMain";
import { SortType } from "../../core/number_extentions";
import { IFilterOptions } from "../../views/components/FilterSection/IFilterOptions";
export class ResearchController {
  remote = new RemoteResearch();

  createResearch(
    body: ResearchReq,
    action: (res: ResearchResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.createResearch(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  updateResearch(
    body: UpdateResearchReq,
    action: (res: UpdateResearchResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.updateResearch(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  researchSearch(
    action: (
      // res: Array<{ label: string; value: number; isUser: boolean } | {}>
      res: { teams: any[]; users: any[]; disabledUsers: any[]; workspaces: any[]; groups?: any[]; mySubscriptionId: any; }
    ) => any,
    _researchId?: number
  ) {
    this.remote.researchSearch((res) => {
      ;
      // const teams = res.result.teams.map((_team) => {
      //   return {
      //     label: _team.title,
      //     value: _team.id,
      //   };
      // });
      // const users = res.result.users.map((_user: any) => {
      //   const _userTitle = getUserTitle(_user);
      //   return {
      //     label: _userTitle,
      //     value: _user.id,
      //     secondary: _user.invitationStatus === 1 ? "Pending" : null,
      //   };
      // });
      action({
        teams: [...res.result.teams],
        users: [...res.result.users],
        disabledUsers: res.result.disabledUsers ? res.result.disabledUsers : [],
        workspaces: [...res.result.workspaces],
        mySubscriptionId: res.result.mySubscriptionId,
        groups: res.result.groups,
      });
    }, _researchId);
  }
  getResearches(
    body: { PageNumber: number; PageSize: number; SearchParameter: string; columnHeaderName?: string; sortDirection?: SortType, filterOptions?: IFilterOptions },
    action: (res: ResearchesResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getResearches(
      body,
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  getGroupResearches(
    body: { PageNumber: number; PageSize: number; SearchParameter: string; columnHeaderName?: string; sortDirection?: SortType, filterOptions?: IFilterOptions },
    action: (res: ResearchesResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getGroupResearches(
      body,
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }


  getResearchById(
    body: { id: number },
    action: (res: GetResearchByidResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getResearchById(
      body,
      (res) => {
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  getBreadCrumbs(
    body: { profileType: number, profileId: number },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.getBreadCrumbs(
      body,
      (res) => {
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  duplicateResearch(
    body: { id: number },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.duplicateResearch(
      body,
      (res) => {
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  getTimeline(
    body: { id: number },
    action: (res: TimelineResResult[]) => any,
    error: (res: any) => any
  ) {
    this.remote.getTimeline(
      body,
      (res) => {
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  deleteResearch(
    body: { researchId: number },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.deleteResearch(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  GetWorkspacesTeams(
    action: (
      res: { teams: any[]; users: any[]; disabledUsers: any[]; workspaces: any[]; mySubscriptionId: any }
    ) => any,
    error: (res: any) => any,
    SelectedWorkSpace?: any[],

  ) {
    this.remote.GetWorkspaceTeams((res) => {
      const _teams = res.result.teams;
      action({
        teams: [_teams],
        users: [res.result.users],
        mySubscriptionId: null,
        disabledUsers: [],
        workspaces: []
      });
    }, (err) => {
      if (err.response?.data.message)
        toast.error(`${err.response?.data.message}`);
      error(err);
    }, SelectedWorkSpace);
  }

  exportData(
    body: any,
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.exportData(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  getTrialInfo(
    action: (res: TrialResResult) => any, error: (res: any) => any) {
    
    this.remote.getTrialInfo(
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }


}
