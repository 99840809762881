import React from "react";

type Props = {
  size?: string;
};

export default function SpinnerLoader({
  size = "2rem",
}: Props) {
  return (
    <div className="spinner-border" role="status" style={{
      width: size,
      height: size,
    }}>
      <span className="sr-only">Loading...</span>
    </div>
  );
}
