import React from "react";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { InputComponent, InputType } from "../../components/inputs";
import { MainButton, MainButtonType } from "../../components/button";
import { RouteComponentProps } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import SimpleReactValidator from "simple-react-validator";
import { LocalDataSources } from "../../../data/local_datasources";
import { isTokenInHttpAuth } from "../../../core/utils";
import { UserController } from "../../../controllers/user/user_controller";
import { HTTP } from "../../../core/http_common";
import { SearchlearningResResult } from "../../../data/models/responses/learning/search_learning_res";
import { learningController } from "../../../controllers/learning_section/learning_controller";
import { toast } from "react-toastify";
import {
  HelpLearningDetails,
  HelpLearningSectionDetails,
} from "../../../data/models/responses/learning/help_learningSection_res";
import NewTable from "../../components/NewTable/NewTable";
import { SortType } from "../../../core/number_extentions";
import Dropzone from "react-dropzone";

type StateType = {
  SectionIFrame: string;
  selectedSectionId: number;
  sections: SearchlearningResResult[];
  SectionOrder: number;
  SectionDescription: string;
  SectionName: string;
  loading: boolean;
  inValid?: string;
  SectionLongDescription: string;
};

class LearningCenter extends React.Component<RouteComponentProps> {
  handleHeaderClick(
    columnHeaderName: string | undefined,
    sortDirection: SortType | undefined
  ): void {
    throw new Error("Method not implemented.");
  }
  validator = new SimpleReactValidator({
    className: "text-danger",
    messages: {
      min: "this field is required.",
    },
  });

  controller = new learningController();
  userController = new UserController();
  local = new LocalDataSources();
  state: StateType = {
    sections: [],
    SectionOrder: 0,
    SectionIFrame: "",
    selectedSectionId: 0,
    SectionDescription: "",
    SectionLongDescription: "",
    inValid: "",
    SectionName: "",
    loading: false,
  };
  componentDidMount() {
    document.title = `Learning Center | Radvix`;
    if (!isTokenInHttpAuth(HTTP)) return;
    this.getSections();
    this.setState({
      searchLoading: true,
      isSectionSelected: false,
    });
  }

  getSections = () => {
    this.setState({
      searchLoading: true,
      isSectionSelected: false,
      sections: [],
    });
    this.controller.SearchLearningSection(
      "",
      (res) => {
        this.setState({
          sections: res,
          searchLoading: false,
          loading: false,
          loadingMode: "",
          isSectionSelected: false,
        });
      },
      (err) => {
        this.setState({
          searchLoading: false,
          loading: false,
          loadingMode: "",
          isSectionSelected: false,
        });
      }
    );
  };

  handleChange(target: string, val: any) {
    if (target === "SectionName") {
      this.setState({ SectionName: val });
    } else if (target === "SectionOrder") {
      this.setState({ SectionOrder: val !== "" ? parseInt(val, 10) : null });
    } else if (target === "SectionDescription") {
      this.setState({ SectionDescription: val });
    } else if (target === "SectionLongDescription") {
      this.setState({ SectionLongDescription: val });
    } else if (target === "SectionIFrame") {
      this.setState({ SectionIFrame: val });
    }
  }

  handleExportSection(searchquery: string) {
    this.setState({
      loading: true,
      loadingMode: "Searching...",
      isSectionSelected: false,
      section: [],
    });
    this.controller.GetLearningSectionData(
      searchquery,
      (res) => {
        this.exportDataAsJson(res);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  onDrop = (fileList: any) => {
    let fileContent = "";
    fileList.forEach((file: any) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          fileContent = event.target.result as string;
          this.uploadSections(fileContent);
          
        } else {
          console.error("Event target is null");
        }
      };
      reader.readAsText(file);
    });
  };
  uploadSections = (fileContents: string) => {
    if (fileContents) {
      this.setState({
        searchLoading: true,
        isSectionSelected: false,
      });
      const learningCenter = JSON.parse(
        fileContents
      ) as HelpLearningSectionDetails[];
      this.controller.uploadLearningSection(
        learningCenter,
        (res) => {
          this.setState({
            searchLoading: false,
            loading: false,
            loadingMode: "",
          });
          this.getSections();
        },
        (err) => {
          this.setState({
            searchLoading: false,
            loading: false,
            loadingMode: "",
          });
        }
      );
    }
  };
  exportDataAsJson(dataToExport: HelpLearningSectionDetails[]) {
    const jsonContent = JSON.stringify(dataToExport, null, 2);
    const blob = new Blob([jsonContent], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Sections_Exported_Data.json";
    document.body.appendChild(a);
    a.click();

    // Clean up by revoking the object URL
    URL.revokeObjectURL(url);

    // Reset loading state or perform any other necessary actions
    this.setState({
      loading: false,
      loadingMode: "",
    });
  }
  handleDeleteSection = (Id: number) => {
    this.setState({
      searchLoading: true,
      loading: true,
      loadingMode: "",
    });

    this.controller.DeleteLearningSection(
      Id,
      () => {
        this.getSections();
        this.setState({
          loading: false,
          loadingMode: "",
          searchLoading: false,
        });
      },
      (err) => {
        this.setState({
          searchLoading: false,
          loading: false,
          loadingMode: "",
        });
        toast.error(err);
      }
    );
  };
  handleAddUpdateLearningSection = () => {
    if (this.validator.allValid()) {
      if (this.state.selectedSectionId && this.state.selectedSectionId > 0) {
        this.handleUpdateLearningSection();
      } else {
        this.createLearningSection();
      }
    } else {
      this.validator.showMessages();

      this.forceUpdate();
    }
  };

  createLearningSection() {
    this.setState({
      loadingMode: "Creating..",
      loading: true,
    });

    const body = {
      Name: this.state.SectionName,
      ShortDescription: this.state.SectionDescription,
      LongDescription: this.state.SectionLongDescription,
      EmbeddedCode: this.state.SectionIFrame,
    };

    this.controller.createLearningSection(
      body,
      (res) => {
        // check if res return true
        this.getSections();
        this.setState({
          loading: false,
          showModal: false,
        });
        this.handleClose();
      },
      (err) => {
        this.setState({
          searchLoading: false,
          loading: false,
          loadingMode: "",
          isSectionSelected: false,
        });
        toast.error(err);
      }
    );
  }
  handleUpdateLearningSection() {
    const Id = this.state.selectedSectionId;
    if (Id) {
      this.setState({
        loading: true,
        loadingMode: "Updating...",
        isSectionSelected: false,
      });
      const body = {
        Id: Id,
        Name: this.state.SectionName,
        ShortDescription: this.state.SectionDescription,
        LongDescription: this.state.SectionLongDescription,
        EmbeddedCode: this.state.SectionIFrame,
      };

      this.controller.updateLearningSection(
        body,
        (res) => {
          // check if res return true
          this.getSections();
          this.handleClose();
          this.setState({
            SectionName: "",
            SectionOrder: 0,
            SectionDescription: "",
            SectionLongDescription: "",
            SectionIFrame: "",
            loading: false,
            loadingMode: "",
          });
        },
        (err) => {
          this.setState({
            searchLoading: false,
            loading: false,
            loadingMode: "",
            isSectionSelected: false,
          });
        }
      );
    }
  }
  renderSections = () => {
    return (
      <NewTable
        className="TableBox"
        loading={false} //{this.state.loading || this.state.searchLoading}
        data={this.state.sections}
        customGridTemplate="300px 300px 300px"
        columns={[
          {
            Header: "Title",
            accessor: "name",
            ellipsis: true,
            removeSort: true,
          },
          {
            Header: "Short Description",
            accessor: "shortDescription",
            removeSort: true,
          },
          {
            Header: "Actions",
            accessor: "id",
            Cell: (_props: any) => (
              <div className="d-flex align-items-center">
                <span
                  id="invite-client-span"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-invite-client"
                >
                  <CircleIcon
                    width="2em"
                    height="2em"
                    padding="0"
                    type={ThemeCircleIcon.dark}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                    onClick={() => this.handleRenameSection(_props.id)}
                    className="pointer me-1"
                  >
                    <MdModeEdit />
                  </CircleIcon>
                </span>
                <CircleIcon
                  width="2em"
                  height="2em"
                  padding="0"
                  type={ThemeCircleIcon.dark}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                  onClick={() => this.handleDeleteSection(_props.id)}
                  className="pointer me-1"
                >
                  <MdDelete />
                </CircleIcon>
              </div>
            ),
          },
        ]}
        onHeaderClick={(columnHeaderName, sortDirection) =>
          this.handleHeaderClick(columnHeaderName, sortDirection)
        }
      />
    );
  };

  handleRenameSection = (Id: any) => {
    const selectedsection = this.state.sections.find((x) => x.id === Id);
    this.setState({
      SectionName: selectedsection?.name,
      SectionLongDescription: selectedsection?.longDescription,
      SectionDescription: selectedsection?.shortDescription,
      SectionIFrame: selectedsection?.embeddedCode,
      selectedSectionId: Id,
    });
  };
  render() {
    return (
      <div className="container-fluid research">
        <div className="row"></div>
        <div className="col-12">
          <div className="TableBox">
            <div className="TopTableBox d-flex justify-content-between align-items-center mb-3 col-12">
              <div className="left d-flex align-items-baseline">
                <h4 className="b-title d-flex align-items-center">
                  Learning Center
                </h4>
              </div>
              <div className="right d-flex justify-content-end align-items-center col-10">
                <Dropzone onDrop={this.onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section
                      style={{ marginTop: "0.35em" }}
                      className="container fileUploadBox"
                    >
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <MainButton
                          type={MainButtonType.light}
                          disabled={false}
                          minHeight="30px"
                          minWidth="179px"
                          fontSize="14px"
                          borderRadius="50px"
                          backgroundColor="#fff"
                          border="1px solid #707070"
                          color="#707070"
                          style={{ float: "right" }}
                          className="mt-4 learning_center_import"
                          children={
                            <div className="d-flex justify-content-between align-items-center">
                              <img
                                src="/Images/icons/cloud_computing.svg"
                                alt="sssss"
                                height="20"
                              />{" "}
                              <span className="flex-fill">
                                Browse Local Files
                              </span>
                            </div>
                          }
                        ></MainButton>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <MainButton
                  type={MainButtonType.dark}
                  children={"Export"}
                  borderRadius="50px"
                  loading={this.state.loading}
                  disabled={this.state.loading}
                  fontSize="15px"
                  className="mx-2 px-3"
                  minHeight="39px"
                  minWidth="135px"
                  onClick={() => this.handleExportSection("")}
                ></MainButton>
                <span
                  id="invite-client-span"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-invite-client"
                >
                  <MainButton
                    type={MainButtonType.dark}
                    children={"Add"}
                    borderRadius="50px"
                    loading={this.state.loading}
                    disabled={this.state.loading}
                    fontSize="15px"
                    className="mx-2 px-3"
                    minHeight="39"
                    minWidth="135px"
                    onClick={() =>
                      this.setState({
                        selectedSectionId: 0,
                        SectionName: "",
                        SectionLongDescription: "",
                        SectionDescription: "",
                        SectionIFrame: "",
                      })
                    }
                  ></MainButton>
                </span>
              </div>
            </div>
            {this.renderSections()}
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-start mb-3">
          <div className="col-md-6 mb-3"></div>
        </div>
        <div
          className="modal fade"
          id="modal-invite-client"
          tabIndex={-1}
          aria-labelledby="modal-invite-client"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            style={{ width: 800, maxWidth: "100%" }}
          >
            <div className="modal-content bg-light">
              <button
                style={{ alignSelf: "flex-end" }}
                type="button"
                className="btn-close"
                //data-bs-dismiss="modal"
                aria-label="Close"
                //id="close_modal_Type"
                onClick={() => this.handleClose()}
              ></button>
              <div className="modal-header">
                {this.state.selectedSectionId &&
                this.state.selectedSectionId > 0 ? (
                  <h5 className="mb-0">Update Section</h5>
                ) : (
                  <h5 className="mb-0">Add Section</h5>
                )}
              </div>
              <div>
                <div className="col-md-12 mb-2">
                  <InputComponent
                    type={InputType.text}
                    placeholder="Section Name "
                    value={this.state.SectionName}
                    inValid={this.validator.message(
                      "Name",
                      this.state.SectionName,
                      "required"
                    )}
                    onChange={(e) => {
                      this.handleChange("SectionName", e.target.value);
                    }}
                  ></InputComponent>
                </div>
                <div className="col-md-12 mb-2">
                  <InputComponent
                    type={InputType.textarea}
                    placeholder="Short Description"
                    value={this.state.SectionDescription}
                    inValid={this.validator.message(
                      "Description",
                      this.state.SectionDescription,
                      "required"
                    )}
                    onChange={(e) => {
                      this.handleChange("SectionDescription", e.target.value);
                    }}
                  ></InputComponent>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <InputComponent
                      type={InputType.textarea}
                      placeholder="Video Long Description"
                      inValid={this.validator.message(
                        "Long Description",
                        this.state.SectionLongDescription,
                        "required"
                      )}
                      value={this.state.SectionLongDescription}
                      onChange={(e) => {
                        this.handleChange(
                          "SectionLongDescription",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <InputComponent
                    type={InputType.textarea}
                    value={this.state.SectionIFrame}
                    placeholder="Iframe URL"
                    inValid={this.validator.message(
                      "Iframe",
                      this.state.SectionIFrame,
                      "required"
                    )}
                    onChange={(e) => {
                      this.handleChange("SectionIFrame", e.target.value);
                    }}
                  />
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-end">
                      <MainButton
                        type={MainButtonType.dark}
                        children={
                          this.state.selectedSectionId > 0 ? "Update" : "Add"
                        }
                        borderRadius="50px"
                        fontSize="15px"
                        className="mx-2 px-3"
                        loading={this.state.loading}
                        disabled={this.state.loading}
                        minHeight="43px"
                        minWidth="136px"
                        onClick={() => {
                          this.handleAddUpdateLearningSection();
                        }}
                      ></MainButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }
  handleClose() {
    this.setState({
      showModal: false,
      SectionName: "",
      SectionOrder: null,
      SectionDescription: "",
      SectionLongDescription: "",
      SectionIFrame: "",
    });

    const _dimmer = document.getElementById("modal-invite-client");
    _dimmer?.click();
  }
}

export default LearningCenter;
