import * as Dialog from '@radix-ui/react-dialog';
import { BaseButton } from '@fluentui/react';
import { MainButton } from '../../../components/button';

type DeleteConfirmModalProps = {
  deleteTitle: string;
  show: boolean;
  loading: boolean;
  onHide: () => void;
  onConfirm: () => void;
};

export default function DeleteConfirmModal({
  deleteTitle,
  show,
  loading,
  onHide,
  onConfirm,
}: DeleteConfirmModalProps) {
  return (
    <Dialog.Root open={show}>
      <Dialog.Portal>
        <Dialog.Overlay className='DialogOverlay' />
        <Dialog.Content className='DialogContent'>
          <div
            className='d-flex justify-content-between align-items-center  row-gap-3 flex-wrap column-gap-3 border-bottom
       border-gray-400'
            style={{ paddingBottom: '10px' }}
          >
            <div className='d-flex align-items-center'>
              <BaseButton
                className='btn btn-outline-primary fs-15 py-px-4 me-px-12 px-3 d-sm-block d-none back-button'
                style={{ fontWeight: '400', height: '19px' }}
                onClick={onHide}
              >
                Close
              </BaseButton>
              <h2 className='fw-medium fs-15 mb-0 color-gray-1000 modalHeader'>
                Delete {deleteTitle}
              </h2>
            </div>
          </div>
          <div className='modal-body' style={{ paddingTop: '15px' }}>
            <div className='d-flex align-items-center gap-px-12'>
              <div className='flex-grow-1'>
                <p>Are you sure you want to delete this {deleteTitle.toLowerCase()}?</p>
              </div>
            </div>
            <div className='d-flex gap-3'>
              <MainButton
                className='btn btn-primary resrvationButton mt-2'
                loading={loading}
                onClick={onConfirm}
              >
                Yes, Delete
              </MainButton>
              <MainButton
                className='btn btn-primary resrvationButton mt-2'
                loading={loading}
                onClick={onHide}
              >
                Reject
              </MainButton>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
