import React from "react";
import "./signup.css";
import {SignUpPage} from "./component/signup_page";
import { SignupBackground } from "./component/signup_background";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

interface SignupProps {
  history?: any;
  location?: any;
  match?: any;
}
export const Signup: React.FC<SignupProps> = (props) => {
  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey="6LdNPskpAAAAAO8lgIMlFaiBoRJs5p956ZgFo8Ne">
        <SignUpPage props={props}/>
        </GoogleReCaptchaProvider>
    </>
  );
};
