import React from "react";
import { store } from "../../../data/storeMain";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import { IconEnum, InputComponent, InputType } from "../../components/inputs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MainButton, MainButtonType } from "../../components/button";
import Dropzone from "react-dropzone";
import {
  $ReplaceRoute,
  // $truncate,
  convertTeamUserToNewTree,
  convertTeamUserToTree,
  // convertUsersIdsToIds,
  convertUsersToUncategorized,
  filterUsersNotExistInTeam,
  getUserRole,
  getUserTitle,
  isTokenInHttpAuth,
  // removeDuplicat,
  // removeTeamUsersFromSelectedUsers,
  uploadAllFiles,
  uploadAllLinks,
  UserRoles,
} from "../../../core/utils";
import { RouteComponentProps, withRouter } from "react-router";
import { AppConstants, AppRoutes, LOADING_MODE } from "../../../core/constants";
// import { RadioGroup } from "../../components/radio_group";
import { ButtonGroup } from "../../components/botton_group";
import { LocalDataSources } from "../../../data/local_datasources";
import { ResearchController } from "../../../controllers/research/research_controller";
import { UploadController } from "../../../controllers/upload_media/upload_media";
import SimpleReactValidator from "simple-react-validator";
import { Media } from "../../../data/models/responses/research/researches_res";
// import { InputLabel } from "../../components/input_label";
import { HTTP } from "../../../core/http_common";
// import { TreeMenu } from "../../components/TreeMenu";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import File from "../../components/File";
import NewTreeMenu, { TreeOptionType } from "../../components/NewTreeMenu";
import CircleIconTooltip from "../../components/CircleIconTooltip";
import { SelectComponent } from "../../components/select_input";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import { Button } from "@radix-ui/themes";
import { BaseButton } from "@fluentui/react";

type StateType = {
  mainWorkspace: number[];
  defaultWorkspace: any;
  listWorkspaces: any[];
  selectedTeamsId: number[];
  selectedUsersId: string[];
  selectedTeamsMembers: Array<string | number>;
  mainTeamsMembers: Array<string>;
  listTeams: Array<any>;
  unCheckedValues: string[];
  forceSelectedMembers: string[];
  forceSelectedUsersIds: string[];
  Teams: Array<any>;
  files: Array<File>;
  ListPriority: Array<{ id: number; title: string }>;
  ListStatus: Array<{ id: number; title: string }>;
  ListCurrency: Array<{ id: number; title: string }>;
  title: string;
  creatorUser: string;
  description: string;
  startDate: Date;
  endDate: Date;
  currency: number;
  priority: number;
  status: number;
  listMembers: Array<any>;
  loading: boolean;
  ExternalUrl: Array<string>;
  External: string;
  medias: Array<Media>;
  removedMediasId: number[];
  addedTeamsId: number[];
  removedTeamsId: number[];
  addedUsersId: string[];
  removedUsersId: string[];
  searchLoading: boolean;
  selectedAssignedTeams: [];
  listTeamMembers: TreeOptionType[];
  defaultTeams: TreeOptionType[];
  defaultUsers: TreeOptionType[];
  mainTeams: number[];
  mainUsers: string[];
  loadingMode: string;
  researchResult: any;
  loginWorkSpace: any;
  listGroups: Array<any>;
  selectedGroup: number | null;
  selectedIcon: IconEnum | null;
};
interface RouteParams {
  id: string;
}

class ResearchPageEdit extends React.Component<
  RouteComponentProps<RouteParams>
> {
  RoleUser = store.getState().userRole;
  controller = new ResearchController();
  UploadController = new UploadController();
  validator = new SimpleReactValidator({
    className: "text-danger",
  });
  local: LocalDataSources = new LocalDataSources();
  state: StateType = {
    defaultUsers: [],
    selectedTeamsId: [],
    mainUsers: [],
    mainTeams: [],
    selectedUsersId: [],
    defaultTeams: [],
    listTeamMembers: [],
    unCheckedValues: [],
    forceSelectedMembers: [],
    Teams: [],
    selectedTeamsMembers: [],
    files: [],
    ListPriority: [],
    ListStatus: [],
    ListCurrency: [],
    title: "",
    description: "",
    startDate: new Date(),
    endDate: new Date(new Date().getTime() + AppConstants.one_day_milis),
    currency: 0,
    priority: 2,
    status: 0,
    listMembers: [],
    mainTeamsMembers: [],
    listTeams: [],
    loading: false,
    ExternalUrl: [],
    External: "",
    medias: [],
    removedMediasId: [],
    addedTeamsId: [],
    addedUsersId: [],
    removedTeamsId: [],
    removedUsersId: [],
    selectedAssignedTeams: [],
    forceSelectedUsersIds: [],
    searchLoading: false,
    creatorUser: "",
    loadingMode: "",
    defaultWorkspace: [],
    listWorkspaces: [],
    researchResult: {},
    mainWorkspace: [],
    loginWorkSpace: [],
    listGroups:[],
    selectedGroup: null,
    selectedIcon: null,
  };
  componentDidMount() {
    if (!isTokenInHttpAuth(HTTP)) return;
    const _listStatus = this.local
      .getSetting()
      .status.filter((_st) => _st.id !== 1)
      .map((_st) => {
        if (_st.id === 0) return { id: _st.id, title: "On Going" };
        if (_st.id === 2) return { id: _st.id, title: "On Hold" };
        return _st;
      });
    this.setState({
      ListPriority: this.local.getSetting().priority,
      ListStatus: _listStatus,
      searchLoading: true,
    });
    this.controller.researchSearch((res) => {
      const __teams = convertTeamUserToNewTree(res.teams);
      const _uncategorizedTeam: TreeOptionType | false =
        res.users.length > 0 ? convertUsersToUncategorized(res.users) : false;
      const __listTeamMembers: TreeOptionType[] = _uncategorizedTeam
        ? [_uncategorizedTeam, ...__teams]
        : __teams;

      const _teams = convertTeamUserToTree(res.teams);
      const _uncategorized = filterUsersNotExistInTeam(res.teams, res.users);
      console.log({ _uncategorized });
      if (_uncategorized !== null) {
        _teams.unshift(_uncategorized);
      }
      // console.log({ _teams, _uncategorized });
      const _workSpaces = res.workspaces;
      const _workSpaceOptions = _workSpaces?.map((workspace, index) => ({
        label: workspace.name,
        value: workspace.workspaceId,
        subscriptionId: workspace.subscriptionId,
        workspaceId: workspace.workspaceId,
      }));
      const _listGroups = res.groups?.map((groups, index) => ({
        label: groups.title,
        value: groups.id,
      }));
      this.setState({
        listTeamMembers: __listTeamMembers,
        forceSelectedUsersIds: res.disabledUsers.map((_du) => _du.id),
        listTeams: _teams,
        unCheckedValues: _uncategorized?.value ? [_uncategorized.value] : [],
        forceSelectedMembers: [
          `Member${AppConstants.divide}${this.local.getUserId()}`,
        ],
        listMembers: res.users,
        Teams: res.teams,
        listWorkspaces: _workSpaceOptions,
        listGroups: _listGroups,
      });
      this.getResearchbyId(res);
    }, parseInt(this.props.match.params.id));
  }
  getResearchbyId = (_res: {
    teams: any[];
    users: any[];
    disabledUsers: any[];
    workspaces: any[];
    mySubscriptionId: any;
  }) => {
    this.setState({ loading: true });
    this.controller.getResearchById(
      { id: parseInt(this.props.match.params.id) },
      (res) => {
        ;
        document.title = `Edit Research | ${res.title} | Radvix`;
        
        const _defaultTeams = convertTeamUserToNewTree(res.teams);
        const _defaultUsers = res.users.map((_user) => {
          const _userTitle = getUserTitle(_user);
          return {
            label: _userTitle,
            value: _user.id,
          };
        });
        const _defaultDisabledUsers = _res.disabledUsers.map((_u) => {
          const _uTitle = getUserTitle(_u);
          return {
            label: _uTitle,
            value: _u.id,
            extraData: (
              <p className="textSecondary">
                This user has given access to users on his Subteam.
              </p>
            ),
          };
        });
        // const _allSearchEquipsIncludeLabsEquips = [...res?.equipments, ...res.laboratories.map(_lab => _lab?.equipments).flat()]
        const __mainUsers = res.users
          .filter((_rU) => {
            const isInSearch =
              _res.users.some((_sU) => _sU.id === _rU.id) ||
              _res.teams.some((_sT) =>
                _sT.users.some((_sTU: any) => _sTU.id === _rU.id)
              );

            return isInSearch;
          })
          .map((_u) => _u.id);
        let _workSpaceOptions = res?.workSpaces?.map((workspace, index) => ({
          label: workspace.name,
          value: workspace.id,
          subscriptionId: workspace.subscriptionId,
          workspaceId: workspace.id,
        }));
this.setState({selectedIcon: res.icon})
        const _mainWorkspace = res?.workSpaces
          ?.filter((_rT) => {
            const isInSearch = _res.workspaces.some(
              (_sT) => _sT.workspaceId === _rT.id
            );
            return isInSearch;
          })
          .map((_t) => _t.id);

        const loginWorkSpace = _workSpaceOptions.filter(
          (x) => x.subscriptionId == _res.mySubscriptionId
        );

        const loginUserWorkSpace = _res?.workspaces
          ?.filter((x) => x.subscriptionId == _res.mySubscriptionId)
          ?.map((workspace, index) => ({
            label: workspace.name,
            value: workspace.workspaceId,
            subscriptionId: workspace.subscriptionId,
            workspaceId: workspace.workspaceId,
          }));

        if (!loginWorkSpace || loginWorkSpace.length == 0) {
          _workSpaceOptions = [..._workSpaceOptions, ...loginUserWorkSpace];
        }
        this.setState({
          loginWorkSpace:
            loginWorkSpace && loginWorkSpace.length > 0
              ? loginUserWorkSpace
              : loginUserWorkSpace,
          mainWorkspace: _mainWorkspace,
          mainTeams: res.teams
            .filter((_rT) => {
              const isInSearch = _res.teams.some((_sT) => _sT.id === _rT.id);
              return isInSearch;
            })
            .map((_t) => _t.id),
          mainUsers: __mainUsers,
          creatorUser: res.creatorUser?.id,
          title: res.title,
          startDate: new Date(res.startDate),
          endDate: new Date(res.endDate),
          currency: res.currency,
          priority: res.priority,
          description: res.description,
          status: res.status,
          medias: res.medias,
          defaultTeams: _defaultTeams,
          searchLoading: false,
          defaultUsers: [..._defaultUsers, ..._defaultDisabledUsers],
          loading: false,
          defaultWorkspace: [..._workSpaceOptions],
          researchResult: res,
          selectedGroup: res.projectGroupId,
          
        });
      },

      (err) => {
        this.setState({
          loading: false,
          searchLoading: false,
        });
      }
    );
  };

  onDrop = (fileList: any) => {
    fileList.map((item: any, index: number) => {
      const found = this.state.files.find(
        (element: any) => element.name === item.name
      );
      if (found === undefined) {
        this.setState({ files: [fileList[index], ...this.state.files] });
      }
    });
  };
  handelChangeDate(target: string, params: any): void {
    this.setState({
      [target]: params,
    });
  }
  handleChange(target: string, val: any) {
    if (target == "priority" || target == "status") {
      this.setState({
        [target]: val,
      });
    } else {
      const sanitizedValue = DOMPurify.sanitize(val);
      this.setState({
        [target]: sanitizedValue,
      });
    }
  }
  handelDeleteFile(arg: File) {
    this.setState({
      files: this.state.files.filter((file) => file.name !== arg.name),
    });
  }
  handelRemoveMedia(id: number) {
    this.setState({
      removedMediasId: [...this.state.removedMediasId, id],
      medias: this.state.medias.filter((item) => item.id !== id),
    });
  }

  addExternalUrl() {
    if (this.validator.fields.input) {
      let Url = [...this.state.ExternalUrl];
      if (this.state.External.length > 2) {
        Url.push(this.state.External);
      }
      this.setState({
        ExternalUrl: Url,
        External: "",
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 MB';
  
    const m = 1024 * 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizeInMB = bytes / m;
  
    return `${sizeInMB.toFixed(dm)} MB`;
  }

  handelDeleteExternalLink(link: string) {
    this.setState({
      ExternalUrl: this.state.ExternalUrl.filter((item) => item !== link),
    });
  }
  UpdateResearch() {
    this.setState({
      loading: true,
      loadingMode: "Updating research..",
    });

    console.log(this.state.selectedUsersId, this.state.mainUsers);
    const _myId = localStorage.getItem("userId");

    let _selectedUsers = this.state.selectedUsersId;
    let selectedDefaultUsersRecords: any[] = [];
    _selectedUsers.forEach((element) => {
      this.state.listTeamMembers.forEach((element1) => {
        element1.children?.forEach((element2) => {
          if (element == element2.value) {
            selectedDefaultUsersRecords.push(element);
          }
        });
        // if (element == element1.value) {
        //   selectedDefaultUsers.push(element);
        // }
      });
    });
    const selectedDefaultUsers: string[] = selectedDefaultUsersRecords.filter(
      (value, index, self) => {
        return self.indexOf(value) === index;
      }
    );

    //const selectedDefaultUsers: string[] = [...new Set(selectedDefaultUsersRecords)];
    const __addedUsers = selectedDefaultUsers.filter(
      (_uId) => !this.state.mainUsers.includes(_uId)
    );
    //const __removedUsers = this.state.selectedUsersId.filter(_uId => !this.state.mainUsers.includes(_uId) && this.state.creatorUser !== _uId)
    const __removedUsers = this.state.mainUsers.filter(
      (_uId) =>
        !selectedDefaultUsers.includes(_uId) && this.state.creatorUser !== _uId
    );
    let _selectedTeams = this.state.selectedTeamsId;
    let selectedDefaultTeams: any[] = [];
    _selectedTeams.forEach((element) => {
      this.state.listTeamMembers.forEach((element1) => {
        if (element == element1.value) {
          selectedDefaultTeams.push(element);
        }
      });
    });

    const __addedTeams = selectedDefaultTeams.filter(
      (_tId) => !this.state.mainTeams.includes(_tId)
    );
    const __removedTeams = this.state.mainTeams.filter(
      (_tId) => !selectedDefaultTeams.includes(_tId)
    );
    const __WorkspaceIds = this.state.defaultWorkspace?.map(
      (x: any) => x.workspaceId
    );

    const __addedWorkSpace = __WorkspaceIds.filter(
      (_tId: number) => !this.state.mainWorkspace.includes(_tId)
    );
    const __removedWorkSpace = this.state.mainWorkspace.filter(
      (_tId) => !__WorkspaceIds.includes(_tId)
    );
    console.log(this.state.mainUsers, this.state.mainTeams, {
      __addedUsers,
      __removedUsers,
      __removedTeams,
      __addedTeams,
    });
;
    console.log({ __addedUsers });
    const body = {
      id: parseInt(this.props.match.params.id),
      title: this.state.title,
      description: this.state.description,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      currency: this.state.currency,
      priority: this.state.priority,
      projectGroupId: this.state.selectedGroup,
      removedMediasId: this.state.removedMediasId,
      addedTeamsId: __addedTeams,
      removedTeamsId: __removedTeams,
      addedWorkspaces: __addedWorkSpace,
      removedWorkspaces: __removedWorkSpace,
      addedUsersId: __addedUsers.filter(
        (_u) => !this.state.forceSelectedUsersIds.includes(_u)
      ),
      removedUsersId: __removedUsers.filter((_u) => _u !== _myId),
      status: this.state.status,
      icon: this.state.selectedIcon === IconEnum.Default ? null : this.state.selectedIcon,
    };
    this.controller.updateResearch(
      body,
      (res) => {
        if (this.state.files.length || this.state.ExternalUrl.length) {
          this.handelUpload(res.id);
        } else {
          this.setState({
            loading: false,
            loadingMode: "",
          });

          this.props.history.push(
            $ReplaceRoute(
              `${AppRoutes.profile_research.replace(
                ":id",
                res.id?.toString() ?? ""
              )}`
            )
          );
        }
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
    if (this.validator.allValid()) {
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  async handelUpload(id: number) {
    this.setState({
      loadingMode: LOADING_MODE.links,
    });
    await uploadAllLinks(id, this.state.ExternalUrl, 0);
    this.setState({
      loadingMode: LOADING_MODE.files,
    });
    await uploadAllFiles(id, this.state.files, 0);
    this.setState({
      loading: false,
      loadingMode: "",
    });
    this.props.history.push(
      $ReplaceRoute(
        `${AppRoutes.profile_research.replace(":id", id?.toString() ?? "")}`
      )
    );
  }

  handleIconSelect = (icon: any) => {;
    this.setState({ selectedIcon: icon });
  };

  changeTreeDrop(val: any) {
    let teams = val.filter((item: any) => item.split("-").length === 2);
    let teamsValue: any = [];

    let users = val.filter((item: any) => item.split("-").length === 3);
    let usersValue: any = [];
    // let _tmpVal: any = [];

    teams.map((item: any) => {
      const _team = this.state.listMembers.find(
        (element: any) => element.refKey === item
      );
      return teamsValue.push(_team?.value);
    });
    users.map((item: any) => {
      const _splited = item.split("-");
      const _teamIndex = _splited[1];
      const _userIndex = _splited[2];
      const _user = this.state.listMembers[_teamIndex].children[_userIndex];
      if (usersValue.includes(_user.value) === false) {
        return usersValue.push(_user?.value);
      }
    });

    this.setState({
      addedTeamsId: val.filter((item: any) => typeof item !== "string"),
      addedUsersId: val.filter((item: any) => typeof item === "string"),
      selectedAssignedTeams: val,
    });
  }

  oneDayMillis = 24 * 60 * 60 * 1000;
  maxStartDate = new Date(this.state.endDate.getTime() - this.oneDayMillis)
    .toISOString()
    .split("T")[0];
  minEndDate = new Date(this.state.startDate.getTime() + this.oneDayMillis)
    .toISOString()
    .split("T")[0];

  bytesToMB(bytes: number) {
    return (bytes / (1024 * 1024)).toFixed(3);
  }

  render() {
    const files = this.state.files.map((file: any, index) => (
      <li
        key={index}
        className="d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team"
        style={{ marginBottom: "3px" }}
      >
        <span
          className="text-truncate col-10 mx-2"
          style={{ fontFamily: "Sanfransisco", textAlign: "start" }}
        >
          {file.name} - {this.formatBytes(file.size)}
        </span>
        <CircleIcon
          type={ThemeCircleIcon.dark}
          width="22px"
          height="22px"
          onClick={() => {
            this.handelDeleteFile(file);
          }}
          style={{ cursor: "pointer", display: "contents" }}
          padding="0"
        >
          <img
            src="/images/icons/cross-1.svg"
            alt="radvix"
            width={15}
            height={15}
          />
        </CircleIcon>
      </li>
    ));
    const _userRole = getUserRole();
    const _userId = this.local.getUserId();
    const _disabledAll =
      _userRole === UserRoles.L2User || this.state.searchLoading;
    return (
      <div className="card">
        <div
          className="d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
        border-gray-400"
        >
          <div className="d-flex align-items-center">
            {/* <button className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none"> Back</button> */}
            <BaseButton
              className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button"
              style={{ fontWeight: "400", height: "19px" }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className="fw-medium fs-15 mb-0 color-gray-1000">
              Edit my project
            </h2>
          </div>
        </div>
        <div className="p-3 overflow-auto">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div>
                    <div className="d-flex align-items-center mb-1">
                      <label className="color-gray-600 fs-15 me-2">
                        Workspaces*
                      </label>
                    </div>
                    <SelectComponent
                      disabled={this.state.searchLoading}
                      items={this.state.listWorkspaces}
                      defaultValue={this.state.defaultWorkspace}
                      TextItem="title"
                      ValueItem="id"
                      // className="mb-2"
                      //label="Workspaces"
                      placeholder="Select workspaces"
                      onChange={(e) => {
                        this.handleWorkspaceClick(e);
                      }}
                      isMulti
                    ></SelectComponent>
                  </div>
                  <div className="item" style={{ paddingTop: "15px" }}>
                    <SelectComponent
                      disabled={this.state.searchLoading}
                      items={this.state.listGroups}
                      defaultValue={this.state.listGroups.find(x => x.value == this.state.selectedGroup)}
                      TextItem="title"
                      ValueItem="id"
                      labelClass="color-gray-600 fs-15 me-2"
                      className="form-select control-border-color"
                      label="Group*"
                      placeholder="Select group"
                      onChange={(e) => {;
                        this.setState({selectedGroup : e.value})
                      }}
                      isMulti={false}
                    ></SelectComponent>
                  </div>
                  <div className="item" style={{ paddingTop: "15px" }}>
                    <InputComponent
                      type={InputType.text}
                      label="Project name*"
                      popQuestion="This will be the name of your research project. You can always edit this later."
                      onChange={(e) => {
                        this.handleChange("title", e.target.value);
                      }}
                      addIcon={true}
                      selectedIcons={this.state.selectedIcon}
                      onIconSelect={this.handleIconSelect}
                      inValid={this.validator.message(
                        "Project Name",
                        this.state.title,
                        "required"
                      )}
                      disabled={_disabledAll}
                      value={this.state.title}
                    ></InputComponent>
                    {/* <input className="form-control" placeholder="Enter your project name" /> */}
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="text-primary cursor-pointer">
                        <span className="color-gray-600 fs-15 me-2">
                          Scheduled timeline*
                          {/* <img
                            className="text-primary cursor-pointer"
                            src="/Images/icons/info-circled.svg"
                            alt="info"
                            style={{ paddingLeft: "5px" }}
                            title="Please select the start and anticipated deadline for this research project. This will help you track the progress. You can always modify these dates and no restrictions will be applied if a deadline is passed."
                          /> */}
                        </span>
                      </div>
                      <div className="row mt-1">
                        <div className="col-lg-6 mb-3">
                          <div className="date-picker-wrapper">
                            <DatePicker
                              disabled={_disabledAll}
                              selected={this.state.startDate}
                              onChange={(e) => {
                                this.handelChangeDate("startDate", e);
                              }}
                              maxDate={
                                new Date(
                                  this.state.endDate?.getTime() -
                                    AppConstants.one_day_milis
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                          <div className="date-picker-wrapper">
                            <DatePicker
                              disabled={_disabledAll}
                              selected={this.state.endDate}
                              onChange={(e) => {
                                this.handelChangeDate("endDate", e);
                              }}
                              minDate={
                                new Date(
                                  this.state.startDate?.getTime() +
                                    AppConstants.one_day_milis
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-1" style={{ paddingTop: "15px" }}>
                    <div className="col-lg-12 mb-3">
                      <label className="color-gray-600 fs-15 me-2 mb-1">
                        Priority*
                      </label>
                      {/* <img
                        className="text-primary cursor-pointer"
                        src="/Images/icons/info-circled.svg"
                        alt="info"
                        title="Priority will color tag your research projects and will help you identify or sort them easier based on their priority level."
                      /> */}
                      <div className="d-flex border-gray-400 border soft-radios p-1 rounded-px-4 w-100">
                        {this.state.ListPriority.map((priority) => (
                          <div key={priority.id} className="w-33_3">
                            <input
                              type="radio"
                              className="btn-check"
                              name="ResearchPriority"
                              id={`priority-${priority.id}`}
                              autoComplete="off"
                              disabled={_disabledAll}
                              checked={this.state.priority === priority.id}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.handleChange("priority", priority.id);
                                }
                              }}
                            />
                            <label
                              className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                              htmlFor={`priority-${priority.id}`}
                            >
                              {priority.title}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-lg-12 mb-3">
                      <div
                        className="text-primary cursor-pointer mb-1"
                        title="Info"
                      >
                        <label className="color-gray-600 fs-15 me-2">
                          Project description*
                        </label>
                        {/* <img
                          className="text-primary cursor-pointer"
                          src="/Images/icons/info-circled.svg"
                          alt="info"
                          title="You can write a brief description for this research project. This information will be shown on the project profile and everyone who is assigned to this research, will be able to read it."
                        /> */}
                      </div>
                      <textarea
                        className="form-control control-border-color"
                        disabled={this.state.searchLoading}
                        placeholder="Enter your project description"
                        style={{ height: "132px", resize: "none" }}
                        value={this.state.description}
                        onChange={(e) => {
                          this.handleChange("description", e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-lg-12 mb-3">
                      <label className="color-gray-600 fs-15 me-2 mb-1">
                        Status*
                      </label>
                      {/* <img
                        className="text-primary cursor-pointer"
                        src="/Images/icons/info-circled.svg"
                        alt="info"
                        title="Research status."
                      /> */}
                      <div className="d-flex border-gray-400 border soft-radios p-1 rounded-px-4 w-100">
                        {this.state.ListStatus.map((status) => (
                          <div key={status.id} className="w-33_3">
                            <input
                              type="radio"
                              className="btn-check"
                              name="ResearchsStatus"
                              id={`status-${status.id}`}
                              autoComplete="off"
                              disabled={_disabledAll}
                              checked={this.state.status === status.id}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.handleChange("status", status.id);
                                }
                              }}
                            />
                            <label
                              className="btn text-primary textWrapLabel py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                              htmlFor={`status-${status.id}`}
                            >
                              {status.title}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="col-lg-12 mb-3">
                <div className="d-flex align-items-center mb-1">
                  <label className="color-gray-600 fs-15 me-2">
                    Team and members*
                  </label>
                  {/* <img
                    className="text-primary cursor-pointer"
                    src="/Images/icons/info-circled.svg"
                    alt="info"
                    title="If you already have the teams or individual members for this project invited to Radvix, you can select and assign them here. You can always go back later and add members or teams in the future. Once you create this project, they will get a notification."
                  /> */}
                </div>
                {this.state.listTeamMembers && (
                  <NewTreeMenu
                    //label="Teams and members"
                    onChange={(_teams, _users) => {
                      this.setState({
                        selectedTeamsId: _teams,
                        selectedUsersId: _users,
                        selectedTeamsMembers: [..._teams, ..._users],
                      });
                    }}
                    loading={this.state.searchLoading}
                    popQuestion="If you already have the teams or individual members for this project invited to Radvix, you can select and assign them here. You can always go back later and add members or teams in the future. Once you create this project, they will get a notification."
                    options={this.state.listTeamMembers}
                    defaultChilds={this.state.defaultUsers}
                    defaultParents={this.state.defaultTeams}
                    forceSelectedChilds={[
                      this.state.creatorUser,
                      _userId,
                      ...this.state.forceSelectedUsersIds,
                    ]}
                  />
                )}
              </div>
              <div className="col-lg-12 mb-3">
                <div className="d-flex align-items-center mb-1">
                  <label className="color-gray-600 fs-15 me-2">
                    Attachment
                  </label>
                  {/* <img
                    className="text-primary cursor-pointer"
                    src="/Images/icons/info-circled.svg"
                    alt="info"
                    title="Research attachments are generic documents that you would like anyone who has access to this research project read and have access to them. You can either upload them or Insert the link."
                  /> */}
                </div>
                <div className="p-px-12 bg-gray-200 border-gray-400 border rounded-px-4 gap-2 attachment-paddingleft">
                  <div className="justify-content-sm-between justify-content-center align-items-center flex-wrap row-gap-px-8">
                    <Dropzone onDrop={this.onDrop}>
                      {({ getRootProps, getInputProps }) => (
                        // <section
                        //   className="container fileUploadBox"
                        //   style={{ marginTop: "0.3em" }}
                        // >
                        <div {...getRootProps({ className: "" })}>
                          <input {...getInputProps()} />

                          <div className="d-flex justify-content-between align-items-center flex-wrap row-gap-px-8 responsive-justify-content">
                            <button className="btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0">
                              <div className="d-flex align-items-center">
                                <div className="me-1 mb-1">
                                  <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="15"
                                      height="15"
                                      fill="white"
                                      fill-opacity="0.01"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.81825 1.18197C7.64251 1.00624 7.35759 1.00624 7.18185 1.18197L4.18185 4.18197C4.00611 4.35771 4.00611 4.64263
              4.18185 4.81837C4.35759 4.9941 4.64251 4.9941 4.81825 4.81837L7.05005 2.58657V9.50005C7.05005 9.74858 7.25152 9.95005
              7.50005 9.95005C7.74858 9.95005 7.95005 9.74858 7.95005 9.50005V2.58657L10.1819 4.81837C10.3576 4.9941 10.6425 4.9941
              10.8182 4.81837C10.994 4.64263 10.994 4.35771 10.8182 4.18197L7.81825 1.18197ZM2.5 10.0001C2.77614 10.0001 3 10.2239 3
              10.5001V12.0001C3 12.5539 3.44565 13.0001 3.99635 13.0001H11.0012C11.5529 13.0001 12 12.5529 12 12.0001V10.5001C12
              10.2239 12.2239 10.0001 12.5 10.0001C12.7761 10.0001 13 10.2239 13 10.5001V12.0001C13 13.1041 12.1062 14.0001 11.0012
              14.0001H3.99635C2.89019 14.0001 2 13.1031 2 12.0001V10.5001C2 10.2239 2.22386 10.0001 2.5 10.0001Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </div>
                                Browse local file
                              </div>
                            </button>
                            <h3 className="fw-normal color-gray-700 fs-13 mb-0 flex-grow-1 text-center">
                              {" "}
                              Or drag and drop files here - Max 128MB{" "}
                            </h3>
                          </div>
                          {this.state.medias.length > 0 && (
                            <div style={{ paddingTop: "10px" }}>
                              <ul
                                className="list-unstyled fw-normal"
                                
                              >
                                {this.state.medias
                                  .filter((item) => item.externalUrl === null)
                                  .map((item, index) => (
                                    <li
                                      key={index}
                                      className="d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team align-items-center"
        style={{marginBottom:'3px'}}
                                    >
                                      <span
                                        className="text-truncate col-11 mx-2 font-family-San"
                                        style={{ textAlign:'start' }}
                                      >
                                        <File file={item} />
                                      </span>
                                      <CircleIcon
                                        type={ThemeCircleIcon.dark}
                                        width="22px"
                                        height="22px"
                                        padding="0"
                                        disabled={_disabledAll}
                                        onClick={() => {
                                          this.handelRemoveMedia(item.id!);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          display: "contents",
                                        }}
                                      >
                                        <img
                                          src="/images/icons/cross-1.svg"
                                          alt="radvix"
                                          width={15}
                                          height={15}
                                        />
                                      </CircleIcon>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          )}
                          {files.length > 0 && (
                            <div style={{ paddingTop: "10px" }}>
                              <ul className="list-unstyled fw-normal">
                                {files}
                              </ul>
                            </div>
                          )}
                          {/* <div style={{ paddingTop: "10px" }}>
                            <span
                              className="badge bg-danger text-white py-px-6 rounded-pill fw-normal px-3"
                              style={{ display: "flex", height: "22px" }}
                            >
                              {files}
                            </span>
                          </div> */}
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>
                {this.state.medias.length > 0 && (
                  <ul
                    className="file-list mt-1"
                    style={{
                      marginBottom:
                        this.state.medias.length > 0 ? "0px" : "0px",
                    }}
                  >
                    {this.state.medias
                      .filter((item) => item.externalUrl)
                      .map((item, index) => (
                        <li
                          key={index}
                          className="d-flex align-items-center w-100"
                        >
                          <InputComponent
                            type={InputType.text}
                            value={item.externalUrl!}
                            className="mx-2"
                            counter={false}
                            disabled={true}
                            readOnly={true}
                            rowWidth="98%"
                            isExternalURL={true}
                          ></InputComponent>
                          <div
                            style={{
                              paddingBottom: "0px",
                              paddingRight: "0px",
                              paddingTop: "0px",
                              paddingLeft: "5px",
                            }}
                          >
                            <button
                              className="btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0"
                              disabled={this.state.searchLoading}
                              style={{ background: "#F3AEAF" }}
                              onClick={() => {
                                this.handelDeleteExternalLink(
                                  item.externalUrl!
                                );
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <rect
                                  width="15"
                                  height="15"
                                  fill="white"
                                  fill-opacity="0.01"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M5.5 1.00009C5.22386 1.00009 5 1.22395 5 1.50009C5 1.77623 5.22386 2.00009 5.5 2.00009H9.5C9.77614 2.00009 10 1.77623 10 1.50009C10 1.22395 9.77614 1.00009 9.5 1.00009H5.5ZM3 3.50009C3 3.22395 3.22386 3.00009 3.5 3.00009H5H10H11.5C11.7761 3.00009 12 3.22395 12 3.50009C12 3.77623 11.7761 4.00009 11.5 4.00009H11V12.0001C11 12.5524 10.5523 13.0001 10 13.0001H5C4.44772 13.0001 4 12.5524 4 12.0001V4.00009H3.5C3.22386 4.00009 3 3.77623 3 3.50009ZM5 4.00009H10V12.0001H5V4.00009Z"
                                  fill="#D93D42"
                                />
                              </svg>
                            </button>
                          </div>
                        </li>
                      ))}
                  </ul>
                )}

                {this.state.ExternalUrl && (
                  <ul className="file-list mt-1">
                    {this.state.ExternalUrl.map((item, index) => (
                      <li
                        key={index}
                        className="my-1 d-flex align-items-center w-100"
                        style={{gap:'6px'}}
                      >
                        <InputComponent
                        isExternalURL={true}
                          type={InputType.text}
                          value={item}
                          className="mx-2"
                          counter={false}
                          disabled={true}
                          readOnly={true}
                          rowWidth="100%"
                        ></InputComponent>
                        <div
                        >
                          <button
                            className="btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0"
                            disabled={this.state.searchLoading}
                            style={{ background: "#F3AEAF" }}
                            onClick={() => {
                              this.handelDeleteExternalLink(item);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <rect
                                width="15"
                                height="15"
                                fill="white"
                                fill-opacity="0.01"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5.5 1.00009C5.22386 1.00009 5 1.22395 5 1.50009C5 1.77623 5.22386 2.00009 5.5 2.00009H9.5C9.77614 2.00009 10 1.77623 10 1.50009C10 1.22395 9.77614 1.00009 9.5 1.00009H5.5ZM3 3.50009C3 3.22395 3.22386 3.00009 3.5 3.00009H5H10H11.5C11.7761 3.00009 12 3.22395 12 3.50009C12 3.77623 11.7761 4.00009 11.5 4.00009H11V12.0001C11 12.5524 10.5523 13.0001 10 13.0001H5C4.44772 13.0001 4 12.5524 4 12.0001V4.00009H3.5C3.22386 4.00009 3 3.77623 3 3.50009ZM5 4.00009H10V12.0001H5V4.00009Z"
                                fill="#D93D42"
                              />
                            </svg>
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}

                <div className="d-flex" style={{gap:'6px'}}>
                  <InputComponent
                    type={InputType.text}
                    placeholder="https://"
                    disabled={this.state.searchLoading}
                    className="mx-2"
                    value={this.state.External}
                    counter={false}
                    onChange={(e) => {
                      this.handleChange("External", e.target.value);
                    }}
                    rowWidth="100%"
                    onEnter={(e) => {
                      this.addExternalUrl();
                    }}
                    isExternalURL={true}
                  ></InputComponent>
                  <div style={{ padding: "0px", paddingRight: "0px", alignSelf:'center' }}>
                    <button
                      className="btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0"
                      disabled={this.state.searchLoading}
                      onClick={() => {
                        this.addExternalUrl();
                      }}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
                <p className="text-error mb-0">
                  {this.validator.message("input", this.state.External, "url")}
                </p>
              </div>
            </div>
            <div>
              <MainButton
                className="btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2 responsive-Button-width"
                svg={<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                <rect width="15" height="15" fill="white" fill-opacity="0.01"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 2.00006C3.22386 2.00006 3 2.22392 3 2.50006V12.5001C3 12.7762 3.22386 13.0001 3.5 13.0001H11.5C11.7761 13.0001 12 12.7762 12 12.5001V4.70717L9.29289 2.00006H3.5ZM2 2.50006C2 1.67163 2.67157 1.00006 3.5 1.00006H9.5C9.63261 1.00006 9.75979 1.05274 9.85355 1.14651L12.7803 4.07328C12.921 4.21394 13 4.4047 13 4.60361V12.5001C13 13.3285 12.3284 14.0001 11.5 14.0001H3.5C2.67157 14.0001 2 13.3285 2 12.5001V2.50006ZM4.75 7.50006C4.75 7.22392 4.97386 7.00006 5.25 7.00006H7V5.25006C7 4.97392 7.22386 4.75006 7.5 4.75006C7.77614 4.75006 8 4.97392 8 5.25006V7.00006H9.75C10.0261 7.00006 10.25 7.22392 10.25 7.50006C10.25 7.7762 10.0261 8.00006 9.75 8.00006H8V9.75006C8 10.0262 7.77614 10.2501 7.5 10.2501C7.22386 10.2501 7 10.0262 7 9.75006V8.00006H5.25C4.97386 8.00006 4.75 7.7762 4.75 7.50006Z" fill="white"/>
              </svg>}
                onClick={() => {
                  this.UpdateResearch();
                }}
                loading={this.state.loading || this.state.searchLoading}
                disabled={this.state.loading || this.state.searchLoading}
              >
                Update Now
              </MainButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
  handleWorkspaceClick(selectedWorkspaceId: any) {
    {
      const _cantFindYourSelf = !selectedWorkspaceId.find(
        (item: any) => item.value === this.state.loginWorkSpace[0].value
      );

      if (_cantFindYourSelf) {
        return toast.error("You can't remove yourself from workspace.");
      }
      this.setState({
        defaultWorkspace: [],
      });
      this.setState({
        searchLoading: true,
      });
      var a = this.state.defaultWorkspace;
      this.controller.GetWorkspacesTeams(
        (res) => {
          this.setState({
            defaultWorkspace: selectedWorkspaceId,
          });
          console.log("Workspace teams:", res);
          const _myId = this.local.getUserId();
          const _teams = convertTeamUserToNewTree(res.teams[0], [_myId]);
          const _uncategorizedTeam: TreeOptionType | false =
            res.users.length > 0
              ? convertUsersToUncategorized(res.users[0], [_myId])
              : false;

          const __listTeamMembers: TreeOptionType[] =
            _uncategorizedTeam &&
            _uncategorizedTeam.children &&
            _uncategorizedTeam.children.length > 0
              ? [_uncategorizedTeam, ..._teams]
              : _teams;

          const _defaultTeams = convertTeamUserToNewTree(
            this.state.researchResult.teams
          );
          const _defaultUsers = this.state.researchResult.users.map(
            (_user: any) => {
              const _userTitle = getUserTitle(_user);
              return {
                label: _userTitle,
                value: _user.id,
              };
            }
          );

          let selectedDefaultTeams: any[] = [];
          this.state.defaultTeams.forEach((element) => {
            __listTeamMembers.forEach((element1) => {
              if (element.value == element1.value) {
                element1.checked = true;
                selectedDefaultTeams.push(element1);
              }
            });
          });
          let selectedDefaultUsers: any[] = [];
          this.state.defaultUsers.forEach((elementUser: any) => {
            __listTeamMembers.forEach((elementUser1) => {
              if (elementUser.value == elementUser1.value) {
                elementUser1.checked = true;
                selectedDefaultUsers.push(elementUser);
              }
            });
          });
          this.setState((prevState) => ({
            listTeamMembers: __listTeamMembers,
            searchLoading: false,
            //defaultTeams: selectedDefaultTeams,
            //defaultUsers: selectedDefaultUsers
          }));
          console.log("new teammmmmmmmmmmmmm: ", this.state.listTeamMembers);
        },
        (err) => {
          this.setState({
            searchLoading: false,
            defaultWorkspace: a,
          });
        },
        selectedWorkspaceId
      );
    }
  }
}
export default withRouter(ResearchPageEdit);
