import { toast } from "react-toastify";
import { ClientsResResult } from "../../data/models/responses/admin/clients_res";
import { DashboardReportsResult } from "../../data/models/responses/admin/dashboard_report_res";
import { ListMemberUserResResult } from "../../data/models/responses/admin/list_member_user_res";
import { PaymentsResResult } from "../../data/models/responses/admin/payments_res";
import { GetAllDiscusstionResResult } from "../../data/models/responses/discussion/get_all_discusstion_res";
import { RemoteAdmin } from "../../data/remotes/admin/remote_admin";
import { SortType } from "../../core/number_extentions";
export class AdminController {
  remote = new RemoteAdmin();

  getDashboardReport(
    body: {
      fromDate: Date;
      untilDate: Date;
      excludedClients?: any[];
    },
    action: (res: DashboardReportsResult) => any
  ) {
    this.remote.getDashboardReport(
      body,
      (res) => action(res.result!),
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
      }
    );
  }
  getClients(
    body: {
      searchParameter: string;
      pageSize: number;
      pageNumber: number;
      selectedClients: string | null;
    },
    action: (res: ClientsResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getClients(
      body,
      (res) => action(res.result!),
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  getTotalSubscription(
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.getTotalSubscription(
      (res) => action(res.result!),
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  ListL1Clients(
  action: (res: ClientsResResult) => any,
  error: (res: any) => any
) {
  this.remote.listL1Clients(
    (res) => action(res.result),
    (err) => {
      if (err.response?.data.message) {
        toast.error(`${err.response?.data.message}`);
      }
      error(err);
    }
  );
}

  getAllDiscusstion(
    body: {
      PageNumber: number;
      PageSize: number;
      ticket: boolean;
      UserId?: string | null;
      Search?: string;
      columnHeaderName?: string;
      sortDirection?: SortType;
    },
    action: (res: GetAllDiscusstionResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getAllDiscussion(
      body,
      (res) => {
        // toast.success(`${res.message}`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  getListMembers(
    body: {
      // searchParameter: string;
      // userId: string;
      // pageSize: number;
      // pageNumber: number;

      searchParameter: string;
      pageSize: number;
      pageNumber: number;
      subscriptionId: number | null;
      role: number | null;
      id: string | null;
    },
    action: (res: ListMemberUserResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getListMember(
      body,
      (res) => action(res.result!),
      (err) => {
        error(err);
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
      }
    );
  }
  getPayments(
    body: {
      pageNumber: number;
      pageSize: number;
      // searchParameter: string;
      userId?: string;
    },
    action: (res: PaymentsResResult) => any,
    error: (err: any) => any
  ) {
    this.remote.getPayments(
      body,
      (res) => action(res.result!),
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  updateTicket(
    body: {
      id: number;
      categoryId?: number | null;
      subject: string;
      priority: number;
      ticketStatus: number; //1 | 2;
    },
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.updateTicket(
      body,
      (res) => action(res.result!),
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);

        error(err);
      }
    );
  }

  createClientUser(
    body: {
      firstName: string;
      lastName: string;
      email: string;
      invitationNote: string;
      subscriptionPlanId: number;
      expirationDate: Date;
    },
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.createClientUser(
      body,
      (res) => action(res.result!),
      (err) => {
        error(err);
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
      }
    );
  }
  updateUserSubscription(
    body: {
      userId: string;
      subscriptionSettingId: number;
      trialExpiration? : Date;
      provideTrial?: boolean;
      newSeats?: number;
      notTrial?: boolean;
    },
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.updateUserSubscription(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  updateEnterprizeSubscription(
    body: {
      userId: string;
      subscriptionSettingId: number;
      billingDate? : Date;
      provideTrial?: boolean;
      newSeats?: number;
      notTrial?: boolean;
    },
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.updateEnterprizeSubscription(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  deleteClient(
    body: { Id: string | null },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.deleteClient(
      body,
      (res) => action(res.result!),
      (err) => {
        error(err);
      }
    );
  }
}
