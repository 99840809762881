import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { MdEdit } from "react-icons/md";
import {
  changeReservationStatus,
  updateReservation,
} from "../../../controllers/equipmentReservation";
import {
  AppConstants,
  AppRoutes,
  MOMENT_DATE_TIME_FORMAT,
  RESERVATION_STATUS_LABELS,
  RESERVATION_STATUS_STYLES,
} from "../../../core/constants";
import { $ReplaceRoute, getUserTitle } from "../../../core/utils";
import { ButtonGroup } from "../../components/botton_group";
import { MainButton, MainButtonType } from "../../components/button";
import TippyHeader from "../../components/TippyHeader";
import DatePicker from "react-datepicker";
import TippyFooter from "../../components/TippyFooter";
// import Tippy from "@tippyjs/react";
import { ImageWithToken } from "../../components/box_list_scroll";
import { Swiper, SwiperSlide } from "swiper/react";
// import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import CustomTooltip from "../../components/CustomTooltip";
import { IMAGE_BASE_URL } from "../../../core/http_common";
import { BaseButton } from "@fluentui/react";
import RadixModal from "../../components/RadixModal/RadixModal";
// import { IMAGE_BASE_URL } from "../../../core/http_common";

const EquipmentReservationContent = (props: any) => {
  const {
    onChangeReservationStatus,
    // CategoryColor,
    equipment,
    laboratories,
    user,
    StartTime,
    EndTime,
    manager,
    // reservationId,
    Subject,
    isUserManagerOfEquipmentsLaboratory,
    status,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const swiperObjRef = useRef<any>(null);
  // const [loading, setLoading] = useState(false);
  const __userTitle = getUserTitle(user);
  const isUserCreator = user.id === localStorage.getItem("userId");
  // const _localSetting = JSON.parse(localStorage.getItem("setting") || "[]");
  // const _statusObj = _localSetting.equipmentReservationStatusEnum?.find(
  //   (item: any) => item.id === status
  // );
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const _managerUsername = manager ? getUserTitle(manager) : "";

  return (
    <div
      id="special-root-portal"
      className="d-flex flex-column"
      style={{
        maxWidth: "100%",
        // width: "24em",
      }}
    >
      <Swiper
        allowTouchMove={false}
        preventClicks={false}
        className="w-100"
        autoHeight
        slidesPerView="auto"
        onSwiper={(_swiper) => (swiperObjRef.current = _swiper)}
      >
        <SwiperSlide>
          <div className="d-flex flex-column">
            <TippyHeader
              className="px-2 pt-2"
              closeStyle={{ right: "1em" }}
              title=""
              style={{borderBottom: "none"}}
            />
            <div
              className="d-flex justify-content-between align-items-center px-2 row-gap-3 flex-wrap column-gap-3 border-bottom
      border-gray-400"
              style={{ paddingBottom: "10px" }}
            >
              <div className="d-flex align-items-center">
                {/* <button className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none"> Back</button> */}
                <BaseButton
                  className="btn btn-outline-primary fs-15 py-px-4 me-px-12 px-3 d-sm-block d-none back-button"
                  style={{ fontWeight: "400", height: "19px" }}
                  onClick={() => {
                    props.closeQuickInfoPopup();
                  }}
                >
                  Close
                </BaseButton>
                <h2 className="fw-medium fs-15 mb-0 color-gray-1000">
                  Review Booking Request
                </h2>
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-lg-8 mb-1 px-4">
                <label className="color-gray-600 fs-15 me-2 mb-1">
                  Update booking status
                </label>
                <img
                  className="text-primary cursor-pointer"
                  src="/Images/icons/info-circled.svg"
                  alt="info"
                  title="Priority will color tag your research projects and will help you identify or sort them easier based on their priority level."
                />
              </div>
              <div className="pe-3 px-4 border-gray-400 d-sm-block d-flex gap-3 min-h-px-25 border-end-sm-1">
                <div className="d-flex align-items-center text-nowrap fs-15">
                  <span className="badge text-white py-px-6 rounded-pill fw-normal px-3 bg-success">
                    {RESERVATION_STATUS_LABELS[status].text}
                  </span>
                </div>
              </div>
            </div>
            <div className="px-3" style={{paddingTop:"10px"}}>
              <label className="color-gray-600 fs-15 me-2">
                Booking Time{" "}
              </label>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
              >
                <path
                  d="M0 8C0 3.85786 3.35786 0.5 7.5 0.5C11.6421 0.5 15 3.85786 15 8C15 12.1421 11.6421 15.5 7.5 15.5C3.35786 15.5 0 12.1421 0 8Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.49979 1.37695C3.8421 1.37695 0.876953 4.3421 0.876953 7.99978C0.876953 11.6575 3.8421 14.6227 7.49979 14.6227C11.1575 14.6227 14.1226 11.6575 14.1226 7.99978C14.1226 4.3421 11.1575 1.37695 7.49979 1.37695ZM1.82695 7.99978C1.82695 4.86677 4.36677 2.32695 7.49979 2.32695C10.6328 2.32695 13.1726 4.86677 13.1726 7.99978C13.1726 11.1328 10.6328 13.6727 7.49979 13.6727C4.36677 13.6727 1.82695 11.1328 1.82695 7.99978ZM8.2498 5.00005C8.2498 5.41426 7.91401 5.75005 7.4998 5.75005C7.08559 5.75005 6.7498 5.41426 6.7498 5.00005C6.7498 4.58583 7.08559 4.25005 7.4998 4.25005C7.91401 4.25005 8.2498 4.58583 8.2498 5.00005ZM5.99991 6.50005H6.49991H7.49991C7.77606 6.50005 7.99991 6.7239 7.99991 7.00005V10.5001H8.49991H8.99991V11.5001H8.49991H7.49991H6.49991H5.99991V10.5001H6.49991H6.99991V7.50005H6.49991H5.99991V6.50005Z"
                  fill="#644FC1"
                />
              </svg>
              <div
                className="col-12 d-flex"
                style={{ alignItems: "center", paddingTop: "10px" }}
              >
                <div className="col-6">
                  <label className="color-gray-600 fs-15 me-2">From</label>
                  <div className="col-8 fs-13">
                    {moment(StartTime).format(MOMENT_DATE_TIME_FORMAT)}
                  </div>
                </div>
                <div className="col-6">
                  <label className="color-gray-600 fs-15 me-2">Until</label>
                  <div className="col-8 fs-13">
                    {moment(EndTime).format(MOMENT_DATE_TIME_FORMAT)}
                  </div>
                </div>
              </div>
            </div>
            <div className="px-3 row py-3">
              <div className="col-6">
              <label className="color-gray-600 fs-15 me-2">
                Booked by{" "}
              </label>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
              >
                <path
                  d="M0 8C0 3.85786 3.35786 0.5 7.5 0.5C11.6421 0.5 15 3.85786 15 8C15 12.1421 11.6421 15.5 7.5 15.5C3.35786 15.5 0 12.1421 0 8Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.49979 1.37695C3.8421 1.37695 0.876953 4.3421 0.876953 7.99978C0.876953 11.6575 3.8421 14.6227 7.49979 14.6227C11.1575 14.6227 14.1226 11.6575 14.1226 7.99978C14.1226 4.3421 11.1575 1.37695 7.49979 1.37695ZM1.82695 7.99978C1.82695 4.86677 4.36677 2.32695 7.49979 2.32695C10.6328 2.32695 13.1726 4.86677 13.1726 7.99978C13.1726 11.1328 10.6328 13.6727 7.49979 13.6727C4.36677 13.6727 1.82695 11.1328 1.82695 7.99978ZM8.2498 5.00005C8.2498 5.41426 7.91401 5.75005 7.4998 5.75005C7.08559 5.75005 6.7498 5.41426 6.7498 5.00005C6.7498 4.58583 7.08559 4.25005 7.4998 4.25005C7.91401 4.25005 8.2498 4.58583 8.2498 5.00005ZM5.99991 6.50005H6.49991H7.49991C7.77606 6.50005 7.99991 6.7239 7.99991 7.00005V10.5001H8.49991H8.99991V11.5001H8.49991H7.49991H6.49991H5.99991V10.5001H6.49991H6.99991V7.50005H6.49991H5.99991V6.50005Z"
                  fill="#644FC1"
                />
              </svg>
              <div className="col-12">
              <Link
                  to={$ReplaceRoute(
                    `${AppRoutes.member_profile.replace(
                      ":id",
                      user.id?.toString() ?? ""
                    )}`
                  )}
                  title="Go to user profile"
                >
                  <div className="d-flex align-items-center">
                    <ImageWithToken
                      hasImage={user.image ? true : false}
                      originalImage={user.image}
                      src={
                        user.image
                          ? IMAGE_BASE_URL + user.image
                          : "/images/images/img_avatar.png"
                      }
                      alt="Avatar"
                      className="rounded-circle avatar"
                      style={{
                        height: "2em",
                        width: "2em",
                      }}
                      // width="1.75em"
                      // height="1.75em"
                    />

                    <p className="mb-0 ms-2 fs-13">{__userTitle}</p>
                  </div>
                </Link>
                </div>
                </div>
                <div className="col-6">
                <label className="color-gray-600 fs-15 me-2">
                Reviewed by{" "}
              </label>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
              >
                <path
                  d="M0 8C0 3.85786 3.35786 0.5 7.5 0.5C11.6421 0.5 15 3.85786 15 8C15 12.1421 11.6421 15.5 7.5 15.5C3.35786 15.5 0 12.1421 0 8Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.49979 1.37695C3.8421 1.37695 0.876953 4.3421 0.876953 7.99978C0.876953 11.6575 3.8421 14.6227 7.49979 14.6227C11.1575 14.6227 14.1226 11.6575 14.1226 7.99978C14.1226 4.3421 11.1575 1.37695 7.49979 1.37695ZM1.82695 7.99978C1.82695 4.86677 4.36677 2.32695 7.49979 2.32695C10.6328 2.32695 13.1726 4.86677 13.1726 7.99978C13.1726 11.1328 10.6328 13.6727 7.49979 13.6727C4.36677 13.6727 1.82695 11.1328 1.82695 7.99978ZM8.2498 5.00005C8.2498 5.41426 7.91401 5.75005 7.4998 5.75005C7.08559 5.75005 6.7498 5.41426 6.7498 5.00005C6.7498 4.58583 7.08559 4.25005 7.4998 4.25005C7.91401 4.25005 8.2498 4.58583 8.2498 5.00005ZM5.99991 6.50005H6.49991H7.49991C7.77606 6.50005 7.99991 6.7239 7.99991 7.00005V10.5001H8.49991H8.99991V11.5001H8.49991H7.49991H6.49991H5.99991V10.5001H6.49991H6.99991V7.50005H6.49991H5.99991V6.50005Z"
                  fill="#644FC1"
                />
              </svg>
              <div className="col-12">
              {manager ? (
                    <Link
                      title="Go to manager profile"
                      to={$ReplaceRoute(
                        `${AppRoutes.member_profile.replace(
                          ":id",
                          manager.id?.toString() ?? ""
                        )}`
                      )}
                    >
                      <span className="fs-13">{_managerUsername}</span>
                    </Link>
                  ) : (
                    <label className="textSecondary fs-13">Not assigned</label>
                  )}
                </div>
                </div>
            </div>
            <div className="d-flex align-items-center" style={{paddingLeft:"10px", paddingBottom:"10px"}}>
                  <MainButton
                  svg={<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                    <rect width="15" height="15" fill="white" fill-opacity="0.01"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1464 1.14645C12.3417 0.951183 12.6583 0.951183 12.8535 1.14645L14.8535 3.14645C15.0488 3.34171 15.0488 3.65829 14.8535 3.85355L10.9109 7.79618C10.8349 7.87218 10.7471 7.93543 10.651 7.9835L6.72359 9.94721C6.53109 10.0435 6.29861 10.0057 6.14643 9.85355C5.99425 9.70137 5.95652 9.46889 6.05277 9.27639L8.01648 5.34897C8.06455 5.25283 8.1278 5.16507 8.2038 5.08907L12.1464 1.14645ZM12.5 2.20711L8.91091 5.79618L7.87266 7.87267L8.12731 8.12732L10.2038 7.08907L13.7929 3.5L12.5 2.20711ZM9.99998 2L8.99998 3H4.9C4.47171 3 4.18056 3.00039 3.95552 3.01877C3.73631 3.03668 3.62421 3.06915 3.54601 3.10899C3.35785 3.20487 3.20487 3.35785 3.10899 3.54601C3.06915 3.62421 3.03669 3.73631 3.01878 3.95552C3.00039 4.18056 3 4.47171 3 4.9V11.1C3 11.5283 3.00039 11.8194 3.01878 12.0445C3.03669 12.2637 3.06915 12.3758 3.10899 12.454C3.20487 12.6422 3.35785 12.7951 3.54601 12.891C3.62421 12.9309 3.73631 12.9633 3.95552 12.9812C4.18056 12.9996 4.47171 13 4.9 13H11.1C11.5283 13 11.8194 12.9996 12.0445 12.9812C12.2637 12.9633 12.3758 12.9309 12.454 12.891C12.6422 12.7951 12.7951 12.6422 12.891 12.454C12.9309 12.3758 12.9633 12.2637 12.9812 12.0445C12.9996 11.8194 13 11.5283 13 11.1V6.99998L14 5.99998V11.1V11.1207C14 11.5231 14 11.8553 13.9779 12.1259C13.9549 12.407 13.9057 12.6653 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.6653 13.9057 12.407 13.9549 12.1259 13.9779C11.8553 14 11.5231 14 11.1207 14H11.1H4.9H4.87934C4.47686 14 4.14468 14 3.87409 13.9779C3.59304 13.9549 3.33469 13.9057 3.09202 13.782C2.7157 13.5903 2.40973 13.2843 2.21799 12.908C2.09434 12.6653 2.04506 12.407 2.0221 12.1259C1.99999 11.8553 1.99999 11.5231 2 11.1207V11.1V4.9V4.87935C1.99999 4.47689 1.99999 4.14468 2.0221 3.87409C2.04506 3.59304 2.09434 3.33469 2.21799 3.09202C2.40973 2.71569 2.7157 2.40973 3.09202 2.21799C3.33469 2.09434 3.59304 2.04506 3.87409 2.0221C4.14468 1.99999 4.47685 1.99999 4.87932 2H4.9H9.99998Z" fill="white"/>
                  </svg>}
                    children={"Review Request"}
                    type={MainButtonType.dark}
                    borderRadius="8px"
                    fontSize="14px"
                    className="px-3 py-1 ms-1 ps-2"
                    onClick={openModal}
                  ></MainButton>
                </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <EditReservation
            {...props}
            swiperObjRef={swiperObjRef}
            onChangeReservationStatus={onChangeReservationStatus}
          />
        </SwiperSlide>
      </Swiper>
      <RadixModal
        isOpen={isModalOpen} // Pass isOpen state to control modal visibility
        onClose={closeModal} // Pass onClose function to close the modal
        equipmentId={props.equipmentId}
        newRequest={false}
        data={props}
      />
    </div>
  );
};

const EditReservation = (props: any) => {
  const {
    equipment,
    laboratories,
    // user,
    StartTime,
    EndTime,
    // manager,
    status,
    isUserManagerOfEquipmentsLaboratory,
    swiperObjRef,
    onChangeReservationStatus,
    reservationId,
  } = props;
  // const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState<{
    start: Date;
    end: Date;
    status: 1 | 2 | 3 | 4 | 5;
  }>({
    start: new Date(),
    end: new Date(),
    status: 1,
  });

  // async function handleUpdateReservation() {}
  async function handleChangeReservationStatus(_status: 1 | 2 | 3 | 4 | 5) {
    try {
      if (status !== _status) {
        setLoading(true);
        const _res = await changeReservationStatus({
          equipmentId: equipment.id,
          id: reservationId,
          status: _status,
          laboratoriesIds: laboratories.map((_lab: any) => _lab.id),
        });

        if (_res) {
          setForm((_prev) => ({ ..._prev, status: _status }));
          // toast.success("Status changed successfully");
          onChangeReservationStatus && onChangeReservationStatus();
        }
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }

    // props.closeQuickInfoPopup();
  }

  async function handleSaveChanges() {
    setLoading(true);
    try {
      const _res = await updateReservation({
        start: form.start.toISOString(),
        end: form.end.toISOString(),
        id: reservationId,
        equipmentId: equipment.id,
        laboratoriesIds: laboratories.map((_lab: any) => _lab.id),
      });

      if (_res) {
        // toast.success("Changes saved successfully");
        onChangeReservationStatus && onChangeReservationStatus();
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  useEffect(() => {
    setForm({
      start: StartTime,
      end: EndTime,
      status: status === 2 ? 1 : status,
    });
  }, [props]);

  const _isChangedHappend =
    form.start !== StartTime || form.end !== EndTime || form.status !== status;

  return (
    <div className="d-flex flex-column date-picker-styles w-100">
      <TippyHeader
        title={
          <div className="d-flex align-items-center ">
            <MdEdit size="1.4em" className="me-2" />
            Review booking request
          </div>
        }
        className="px-2 pt-2"
        closeStyle={{ right: "1em" }}
        onClose={() => {
          props.closeQuickInfoPopup();
        }}
      />

      <div className="d-flex flex-column align-items-start mt-2 mb-2 px-2">
        {isUserManagerOfEquipmentsLaboratory && (
          <>
            <label className="textSecondary">Update booking status</label>
            <ButtonGroup
              name="ChangeStatus"
              // label="Send To:"
              items={[
                {
                  label: "Approved",
                  value: 1,
                },
                {
                  label: "Pending",
                  value: 3,
                },
                {
                  label: "Declined",
                  value: 4,
                },
                {
                  label: "Canceled",
                  value: 5,
                },
              ]}
              TextItem="label"
              ValueItem="value"
              inputType={"checkbox"}
              selected={form.status}
              onChange={(e) => {
                // const {value: 1 | 2 | 3|4|5} = e.target;
                // const {value:any} = e.target
                // @ts-ignore
                handleChangeReservationStatus(Number(e.target.value));
                // setForm({
                //   ...form,
                //   // @ts-ignore
                //   status: Number(e.target.value),
                // });
              }}
            ></ButtonGroup>
          </>
        )}
        <label className="mt-3 textSecondary">Update booking time</label>
        <div className="d-flex align-items-center mt-2">
          <div className="d-flex flex-column me-2 align-items-start">
            <label className="ps-1 pb-1">Booked from</label>
            <DatePicker
              // popperClassName="special-date-picker"
              showTimeSelect
              selected={form.start}
              onChange={(e: any) => {
                setForm({ ...form, start: e });
              }}
              filterTime={(time) => {
                const currentDate = new Date();
                const selectedDate = new Date(time);

                return currentDate.getTime() < selectedDate.getTime();
              }}
              minDate={new Date()}
              portalId="special-root-portal"
            />
          </div>
          <div className="d-flex flex-column align-items-start">
            <label className="ps-1 pb-1">Until</label>
            <DatePicker
              portalId="special-root-portal"
              // popperClassName="special-date-picker"
              showTimeSelect
              selected={form.end}
              onChange={(e: any) => {
                setForm({ ...form, end: e });
              }}
              minDate={
                new Date(form.start.getTime() + AppConstants.one_day_milis)
              }
            />
          </div>
        </div>
      </div>

      <TippyFooter
        className="p-1"
        onCancel={() => {
          swiperObjRef.current?.slidePrev();
        }}
        onConfirm={handleSaveChanges}
        confirmLoading={loading}
        closeText="Back"
        confirmText="Submit review"
        confirmDisabled={
          loading || form.start >= form.end || !_isChangedHappend
        }
      />
    </div>
  );
};

export default EquipmentReservationContent;
