import React from "react";
import ReactPaginate from "react-paginate";
import { expenseController } from "../../../controllers/expense/expense_controller";
import { AppRoutes, EXPENSE_STATUS, MOMENT_DATE_FORMAT } from "../../../core/constants";
import { store } from "../../../data/storeMain";
import { MainButton, MainButtonType } from "../../components/button";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import { InputIcon } from "../../components/search_box";
import { SelectComponent } from "../../components/select_input";
// import ExpenseArchiveTable from "./component/expense_archive_tbl";
import { RouteComponentProps, withRouter } from "react-router";
// import { EmptyState } from "../../components/empty_state";
import { $ReplaceRoute, isTokenInHttpAuth } from "../../../core/utils";
import { HTTP } from "../../../core/http_common";
import NewTable from "../../components/NewTable/NewTable";
import moment from "moment";
import EmptyChip from "../../components/EmptyChip";
import debounce from "lodash.debounce";
import { MdAdd, MdFolder } from "react-icons/md";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { RxMagnifyingGlass } from "react-icons/rx";
import UserWithAvatar from "../../components/UserWithAvatar";
import PhoneSearch from "../../components/PhoneSearch";

interface RouteParams { }
let unsubscribeMeData: any = undefined;
class ExpenseArchive extends React.Component<RouteComponentProps<RouteParams>> {
  RoleUser = store.getState().userRole;
  controller = new expenseController();
  state = {
    Expenses: [],
    PageNumber: 1,
    PageSize: { label: "10", value: 10 },
    PageCount: 0,
    TotalCount: 0,
    ResearchId: store.getState().ResearchId.value,
    Search: "",
    loading: true,
  };
  constructor(props: any) {
    super(props);
    this.handleChangeExpenseSearch = debounce(
      this.handleChangeExpenseSearch.bind(this),
      500
    );
  }
  getExpense(
    PageNumber: number,
    PageSize: number,
    ResearchId: number,
    Search?: string
  ) {
    const _currentResearch = store.getState().ResearchId;
    if (_currentResearch.value === -1) return;
    this.setState({
      loading: true,
    });
    this.controller.getExpenses(
      {
        PageNumber: PageNumber,
        PageSize: PageSize,
        ResearchId: ResearchId,
        SearchParameter: this.state.Search,
      },
      (res) => {
        if (res === null) {
          this.setState({
            Expenses: [],
            PageCount: 0,
            TotalCount: 0,
            loading: false,
          });
          return
        }
        this.setState({
          Expenses: res.expenses,
          PageCount: Math.ceil(res.count! / this.state.PageSize.value),
          TotalCount: res.count,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
  }

  handelChangePageNumber(e: { selected: number }) {
    this.setState({
      PageNumber: e.selected,
    });
    this.getExpense(
      e.selected + 1,
      this.state.PageSize.value,
      store.getState().ResearchId.value
    );
  }
  handelChangePageSize(e: { label: string; value: number }) {
    this.setState({
      PageSize: e.value,
    });
    this.getExpense(
      this.state.PageNumber,
      e.value,
      store.getState().ResearchId.value
    );
  }
  headerClick = (columnHeaderName?: string, sortDirection?: string) => {
  };
  componentDidMount() {
    document.title = `Expense Archive | Radvix`
    if (!isTokenInHttpAuth(HTTP)) return;


    unsubscribeMeData = store.subscribe(() => {
      if (store.getState().ResearchId.value > 0) {
        this.getExpense(
          this.state.PageNumber,
          this.state.PageSize.value,
          store.getState().ResearchId.value
        );
      }
    });
    if (store.getState().ResearchId.value > 0) {

      this.getExpense(
        this.state.PageNumber,
        this.state.PageSize.value,
        store.getState().ResearchId.value
      );
    } else {
      this.setState({
        loading: false,
      })
    }
  }
  // clean up event listener
  componentWillUnmount() {
    // console.log('called removeEvent')
    if (unsubscribeMeData) unsubscribeMeData();
  }
  handleChangeExpenseSearch(e: any) {
    this.getExpense(
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.ResearchId,
      e.target.value
    );
  }
  render() {
    return (
      <div className="container-fluid research">
        <div className="row"></div>
        <div className="col-12">
          <div className="TableBox">
            <div className="TopTableBox d-flex relative flex-md-row justify-content-between align-items-md-start mb-0 mb-sm-3">
              <div className="left d-flex align-items-center">
                <h5 className="b-title d-flex align-items-center">
                  <IoIosArrowDropleftCircle
                    className="me-3 textSecondary2 cursorPointer"
                    size="1.5em"
                    onClick={() => {
                      window.history.back();
                    }} />{" "}
                  Expense Archive
                </h5>
              </div>
              <div className="dNone dFlexSm align-items-center">
                <CircleIcon
                  padding="0"
                  height="2em"
                  // type={ThemeCircleIcon.dark}
                  onClick={() => {
                    this.props.history.push(
                      $ReplaceRoute(AppRoutes.expense_new)
                    );
                  }}
                  className="me-1"
                  width="2em"
                  children={<MdAdd size="1.4em" />}
                ></CircleIcon>
                <PhoneSearch
                  searchIcon={this.state.Search.length > 0 ? (
                    <i
                      className="fa fa-times pointer"
                      aria-hidden="true"
                      onClick={() => {
                        this.setState(
                          {
                            Search: "",
                          },
                          () => {
                            this.getExpense(
                              this.state.PageNumber,
                              this.state.PageSize.value,
                              this.state.ResearchId
                            );
                          }
                        );
                      }}
                    ></i>
                  ) : (
                    <RxMagnifyingGlass size="1.25em" className="textSecondary" />
                  )}
                  onChange={(e) => {
                    this.setState({
                      Search: e.target.value,
                    });
                    this.handleChangeExpenseSearch(e);
                  }}
                  value={this.state.Search}
                />
              </div>
              <div className="right dNoneSm d-flex align-items-center justify-content-between">
                <MainButton
                  children="New Expense"
                  type={MainButtonType.dark}
                  borderRadius="24px"
                  fontSize="14px"
                  minHeight="2em"
                  className="mx-0 my-0 px-3"
                  onClick={() => {
                    this.props.history.push(
                      $ReplaceRoute(AppRoutes.expense_new)
                    );
                  }}
                ></MainButton>
                <InputIcon
                  parentClassName="mx-2"
                  chilren={
                    this.state.Search.length > 0 ? (
                      <i
                        className="fa fa-times pointer"
                        aria-hidden="true"
                        onClick={() => {
                          this.setState(
                            {
                              Search: "",
                            },
                            () => {
                              this.getExpense(
                                this.state.PageNumber,
                                this.state.PageSize.value,
                                this.state.ResearchId
                              );
                            }
                          );
                        }}
                      ></i>
                    ) : (
                      <RxMagnifyingGlass size="1.25em" className="textSecondary" />
                    )
                  }
                  value={this.state.Search}
                  width="100%"
                  placeholder="Search..."
                  TopPosition="15%"
                  onChange={(e) => {
                    this.setState({
                      Search: e.target.value,
                    });
                    this.handleChangeExpenseSearch(e);
                  }}
                ></InputIcon>
                <SelectComponent
                  width="63px"
                  defaultValue={this.state.PageSize}
                  height="44px"
                  items={[
                    { label: "10", value: 10 },
                    { label: "15", value: 15 },
                    { label: "20", value: 20 },
                  ]}
                  TextItem="item"
                  ValueItem="id"
                  isMulti={false}
                  placeholder={this.state.PageSize.toString()}
                  onChange={(e) => {
                    this.handelChangePageSize(e);
                  }}
                ></SelectComponent>
              </div>
            </div>
            <NewTable
              searchValue={this.state.Search}
              loadingCount={this.state.PageSize.value}
              loading={this.state.loading}
              headerRowClassName="d-flex d-sm-grid"
              rowClassName="newTableRowPhone relative"
              customGridTemplate="2.5fr 1fr 1fr 0.7fr 0.8fr 0.6fr 4em"
              data={this.state.Expenses}
              onHeaderClick={(columnHeaderName, sortDirection) => this.headerClick(columnHeaderName, sortDirection)}
              columns={[
                {
                  Header: "Expense Name",
                  accessor: "title",
                  cellClassName: 'fullRow',
                  ellipsis: true,
                  Cell: (_row) => (
                    <span className="max1LineText whiteSpaceNoneSm fontBoldSm linkOnHover pointer"
                      onClick={() => {
                        this.props.history.push(
                          $ReplaceRoute(
                            `${AppRoutes.expense_profile.replace(
                              ":id",
                              _row.id?.toString()
                            )}`
                          )
                        )
                      }}
                    >
                      {_row.title}
                    </span>
                  )
                },
                {
                  Header: "Category",
                  accessor: "categoryTitle",
                  headerCellClassName: 'dNoneSm',
                  ellipsis: true,
                  Cell: (_row) =>
                    _row.categoryTitle ? (
                      _row.categoryTitle
                    ) : (
                      <div className="d-flex">
                        <EmptyChip />
                      </div>
                    ),
                },
                {
                  Header: "Submitted by",
                  accessor: "creatorFirstName",
                  headerCellClassName: 'dNoneSm',
                  ellipsis: true,
                  Cell: (_row) => (
                    <UserWithAvatar user={_row.creatorUser} />
                  ),
                },
                {
                  Header: "Amount",
                  accessor: "amount",
                  headerCellClassName: 'dNoneSm',
                  align: "center",
                  // Cell: (_row) => _row.amount + " " + _row.currency,
                  Cell: (_row) => `$${_row.amount.toFixed(2)}`,
                },
                {
                  Header: "Receipt Date",
                  accessor: "date",
                  headerCellClassName: 'dNoneSm',
                  Cell: (_row) => moment(_row.date).format(MOMENT_DATE_FORMAT),
                },
                {
                  Header: "Status",
                  align: "center",
                  headerCellClassName: 'dNoneSm',
                  accessor: "status",
                  Cell: (_row) => {
                    return (
                      <div className="s-chip"
                        style={{
                          backgroundColor: EXPENSE_STATUS[_row.status!].bg,
                          color: EXPENSE_STATUS[_row.status!].color
                        }}
                      >
                        {
                          EXPENSE_STATUS[_row.status!].text
                        }
                      </div>
                    );
                  },
                },
                {
                  Header: "Actions",
                  align: 'center',
                  accessor: "id",
                  headerCellClassName: 'dNoneSm',
                  cellClassName: 'dNoneSm',
                  Cell: (_row) => {
                    return (
                      <div className="d-flex justify-content-center">
                        <CircleIcon
                          width="26px"
                          height="26px"
                          padding="0"
                          type={ThemeCircleIcon.dark}
                          onClick={() =>
                            this.props.history.push(
                              $ReplaceRoute(
                                `${AppRoutes.expense_profile.replace(
                                  ":id",
                                  _row.id?.toString()
                                )}`
                              )
                            )
                          }
                          className="pointer"
                        >
                          <MdFolder />
                        </CircleIcon>
                      </div>
                    );
                  },
                },
              ]}
            />
            {/* {this.state.Expenses.length > 0 ? (
              <ExpenseArchiveTable
                Items={this.state.Expenses}
                Heading={[
                  "Expense Name",
                  "Category",
                  "Requested By",
                  "Amount",
                  "Receipt Date",
                  "Status",
                ]}
              ></ExpenseArchiveTable>
            ) : (
              <EmptyState
                backgroundColor="#fff"
                text="No Expense Archive"
                fontSize="15px"
                color="#474747"
                minHeight="100px"
              />
            )} */}

            <div className="d-flex justify-content-between align-items-center px-3 mt-3">
              {this.state.PageCount > 1 ? (
                <div className="d-flex justify-content-end flex-fill">
                  <ReactPaginate
                    previousLabel={
                      <CircleIcon
                        width="24px"
                        backgroundColor="#ADADAD"
                        padding="0"
                        height="24px"
                        type={ThemeCircleIcon.dark}
                      >
                        <i className="fas fa-chevron-left"></i>
                      </CircleIcon>
                    }
                    nextLabel={
                      <CircleIcon
                        width="24px"
                        backgroundColor="#ADADAD"
                        padding="0"
                        height="24px"
                        type={ThemeCircleIcon.dark}
                      >
                        <i className="fas fa-angle-right"></i>
                      </CircleIcon>
                    }
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.PageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(e) => {
                      this.handelChangePageNumber(e);
                    }}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                </div>
              ) : null}
              {this.state.Expenses.length > 0 ? (
                <div className="d-flex justify-content-end flex-fill">
                  <p className="text-right mb-0 ">
                    Total Results: {this.state.TotalCount}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ExpenseArchive);
