import React, { useEffect, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import './style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { changeReservationStatus, createEquipmentReservation, updateReservation } from '../../../controllers/equipmentReservation';
import { BaseButton } from '@fluentui/react';
import { MainButton } from '../button';
import { toast } from 'react-toastify';

interface RadixModalProps {
  isOpen: boolean;
  equipmentId: number;
  onClose: () => void;
  newRequest: boolean;
  data?: any[];
}

const RadixModal: React.FC<RadixModalProps> = ({ isOpen, equipmentId, onClose, newRequest, data }) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<any>();
  const [initialStatus, setInitialStatus] = useState<any>(null);
  const [reservationId, setReservationId] = useState<number>(0);
  const [laboratories, setLaboratories] = useState<any>([]);

  useEffect(() => {
    if (data) {
      const normalizedData = Array.isArray(data) ? data : [data];
      const mappedData = normalizedData.map((item: any) => {
        return {
          status: item.Status,
          StartDate: new Date(item.start),
          EndDate: new Date(item.end),
          ReservationId: item.reservationId,
          Laboratories: item.laboratories,
        };
      });
      if (mappedData && mappedData.length > 0) {
        const Status = mappedData[0].status;
        const StartDate = mappedData[0].StartDate;
        const EndDate = mappedData[0].EndDate;
        const reservationId = mappedData[0].ReservationId;
        const laboratories = mappedData[0].Laboratories;
        setStatus(Status);
        setInitialStatus(Status); // Set initial status here
        setStartDate(StartDate);
        setEndDate(EndDate);
        setReservationId(reservationId);
        setLaboratories(laboratories);
      }
    }
  }, [data]);

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  const handleChange = (val: any) => {
    setStatus(val);
  };

  const updateStatus = async () => {
    try {
      setLoading(true);
      const _res = await changeReservationStatus({
        equipmentId: equipmentId,
        id: reservationId,
        status: status,
        laboratoriesIds: laboratories.map((_lab: any) => _lab.id),
      });

      if (_res) {
        toast.success("Status changed successfully");
      }
      setLoading(false);
      onClose();
    } catch (e) {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    if (!startDate || !endDate) {
      return;
    }
    try {
      const _res = updateReservation({
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        id: reservationId,
        equipmentId: equipmentId,
        laboratoriesIds: laboratories.map((_lab: any) => _lab.id),
      });

      if (await _res) {
        toast.success("Changes saved successfully");
      }

      setLoading(false);
      onClose();
    } catch (e) {
      setLoading(false);
    }
  };

  const handleUpdateBooking = async () => {
    if (status !== initialStatus) {
      await updateStatus();
    } else {
      await handleUpdate();
    }
  };

  const handleReserveEquipment = async () => {
    if (!startDate || !endDate) {
      return;
    }

    setLoading(true);
    try {
      const res = await createEquipmentReservation({
        equipmentId,
        start: startDate.toISOString(),
        end: endDate.toISOString(),
      });
      if (res) {
        setLoading(false);
        onClose();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <div
            className="d-flex justify-content-between align-items-center  row-gap-3 flex-wrap column-gap-3 border-bottom border-gray-400"
            style={{ paddingBottom: "10px" }}
          >
            <div className="d-flex align-items-center">
              <BaseButton
                className="btn btn-outline-primary fs-15 py-px-4 me-px-12 px-3 d-sm-block d-none back-button"
                style={{ fontWeight: "400", height: "19px" }}
                onClick={onClose}
              >
                Close
              </BaseButton>
              <h2 className="fw-medium fs-15 mb-0 color-gray-1000 modalHeader">
                {newRequest ? "Book Equipment" : "Review Booking Request"}
              </h2>
            </div>
          </div>
          <div className="modal-body" style={{ paddingTop: "15px" }}>
            <div className="date-picker-wrapper">
              {!newRequest && (
                <div className="row mt-1">
                  <div className="col-lg-12 mb-3">
                    <label className="color-gray-600 fs-15 me-2 mb-1">
                      Update booking status
                    </label>
                    <img
                      className="text-primary cursor-pointer"
                      src="/Images/icons/info-circled.svg"
                      alt="info"
                      title="Priority will color tag your research projects and will help you identify or sort them easier based on their priority level."
                    />
                    <div className="d-flex border-gray-400 border soft-radios p-1 rounded-px-4 w-100">
                      <div style={{ width: "25%" }}>
                        <input
                          type="radio"
                          className="btn-check"
                          name="options"
                          id="approved"
                          autoComplete="off"
                          checked={status === "Status 1" || status === 1}
                          onClick={() => handleChange(1)}
                        />
                        <label
                          className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                          htmlFor="approved"
                        >
                          Approved
                        </label>
                      </div>
                      <div style={{ width: "25%" }}>
                        <input
                          type="radio"
                          className="btn-check"
                          name="options"
                          id="pending"
                          autoComplete="off"
                          checked={status === "Status 3" || status === 3}
                          onClick={() => handleChange(3)}
                        />
                        <label
                          className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                          htmlFor="pending"
                        >
                          Pending
                        </label>
                      </div>
                      <div style={{ width: "25%" }}>
                        <input
                          type="radio"
                          className="btn-check"
                          name="options"
                          id="declined"
                          autoComplete="off"
                          checked={status === "Status 4" || status === 4}
                          onClick={() => handleChange(4)}
                        />
                        <label
                          className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                          htmlFor="declined"
                        >
                          Declined
                        </label>
                      </div>
                      <div style={{ width: "25%" }}>
                        <input
                          type="radio"
                          className="btn-check"
                          name="options"
                          id="cancelled"
                          autoComplete="off"
                          checked={status === "Status 5" || status === 5}
                          onClick={() => handleChange(5)}
                        />
                        <label
                          className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                          htmlFor="cancelled"
                        >
                          Canceled
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <label className="color-gray-600 fs-15 me-2">
                {newRequest ? "Booking Time*" : "Update booking time*"}
              </label>
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                <path
                  d="M0 8C0 3.85786 3.35786 0.5 7.5 0.5C11.6421 0.5 15 3.85786 15 8C15 12.1421 11.6421 15.5 7.5 15.5C3.35786 15.5 0 12.1421 0 8Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.49979 1.37695C3.8421 1.37695 0.876953 4.3421 0.876953 7.99978C0.876953 11.6575 3.8421 14.6227 7.49979 14.6227C11.1575 14.6227 14.1226 11.6575 14.1226 7.99978C14.1226 4.3421 11.1575 1.37695 7.49979 1.37695ZM1.82695 7.99978C1.82695 4.86677 4.36677 2.32695 7.49979 2.32695C10.6328 2.32695 13.1726 4.86677 13.1726 7.99978C13.1726 11.1328 10.6328 13.6727 7.49979 13.6727C4.36677 13.6727 1.82695 11.1328 1.82695 7.99978ZM8.2498 5.00005C8.2498 5.41426 7.91401 5.75005 7.4998 5.75005C7.08559 5.75005 6.7498 5.41426 6.7498 5.00005C6.7498 4.58583 7.08559 4.25005 7.4998 4.25005C7.91401 4.25005 8.2498 4.58583 8.2498 5.00005ZM5.99991 6.50005H6.49991H7.49991C7.77606 6.50005 7.99991 6.7239 7.99991 7.00005V10.5001H8.49991H8.99991V11.5001H8.49991H7.49991H6.49991H5.99991V10.5001H6.49991H6.99991V7.50005H6.49991H5.99991V6.50005Z"
                  fill="#644FC1"
                />
              </svg>
              <div className="col-12 d-flex" style={{ alignItems: "center", paddingTop: "10px" }}>
                <div className="col-6">
                  <label className="color-gray-600 fs-15 me-2">From</label>
                  <div className="col-12">
                    <DatePicker
                      selected={startDate}
                      onChange={handleStartDateChange}
                      placeholderText="Start Date"
                      className="form-control"
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeSelect={true}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <label className="color-gray-600 fs-15 me-2">Until</label>
                  <div className="col-12">
                    <DatePicker
                      selected={endDate}
                      onChange={handleEndDateChange}
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeSelect={true}
                      placeholderText="End Date"
                      className="form-control mx-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', marginTop: 25, justifyContent: 'flex-start' }}>
            <MainButton
              className="btn btn-primary resrvationButton"
              onClick={newRequest ? handleReserveEquipment : handleUpdateBooking}
              loading={loading}
              disabled={loading}
            >
              {newRequest ? "Create Booking" : "Update Booking"}
            </MainButton>
          </div>
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close" onClick={onClose}>
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default RadixModal;
