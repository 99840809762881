import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { EquipmentController } from '../../../controllers/equipment/equipment_controller';
import { LocalDataSources } from '../../../data/local_datasources';
import { Equipment } from '../../../data/models/responses/equipment/get_all_equipment_res';
import { store } from '../../../data/storeMain';
import { MainButton, MainButtonType } from '../../components/button';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
import { InputIcon } from '../../components/search_box';
import { SelectComponent } from '../../components/select_input';
// import EquipmentList from "./component/equipment_list";
import { RouteComponentProps, withRouter } from 'react-router';
import { AppRoutes } from '../../../core/constants';
import {
  $ReplaceRoute,
  AccessPermition,
  isTokenInHttpAuth,
  // Theme,
  UserRoles,
} from '../../../core/utils';
// import { EmptyState } from "../../components/empty_state";
import { HTTP, IMAGE_BASE_URL } from '../../../core/http_common';
import NewTable from '../../components/NewTable/NewTable';
// import { IconTextRow } from "../../components/icon_text_horizontal";
import { ImageWithToken } from '../../components/box_list_scroll';
import Highlighter from 'react-highlight-words';
import debounce from 'lodash.debounce';
import { FaBuilding, FaEdit } from 'react-icons/fa';
import { MdAdd, MdFolder, MdGroup } from 'react-icons/md';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import { RxMagnifyingGlass } from 'react-icons/rx';
// import UseQuery from "../../components/UseQuery";
import PhoneSearch from '../../components/PhoneSearch';
import { SortType, equipmentFilterStatus } from '../../../core/number_extentions';
import FilterSection from '../../components/FilterSection/filterSection';
import DOMPurify from 'dompurify';
import { BaseButton } from '@fluentui/react';
import MultiSelect from '../../components/SelectRadix/radixMultiSelect';
import RadixTable from '../../components/NewRadixTable/RadixTable';
import { DropdownMenu, Table } from '@radix-ui/themes';
import Pagination from '../../components/NewRadixTable/RadixPagination';
import Tippy from '@tippyjs/react';
import { SetHeaderTitle } from '../../../data/storeMain/actions/header_title_action';
type StateType = {
  Equipments: Equipment[];
  PageNumber: number;
  PageSize: { value: number; label: string };
  PageCount: number;
  TotalCount: number;
  Search: string;
  loading: boolean;
  HeaderName: string;
  SortDirection: SortType;
  showFilterOptions: boolean;
  status: number[];
  PageNumberInput: string;
  listLaboratory: Array<{ label: string; value: number }>;
  selectedStatus: any[];
  selectedlab: any[];
  visibleTippy: boolean;
};

class EquipPage extends React.Component<RouteComponentProps> {
  RoleUser = store.getState().userRole;
  controller = new EquipmentController();
  local = new LocalDataSources();
  state: StateType = {
    selectedStatus: [],
    selectedlab: [],
    listLaboratory: [],
    Equipments: [],
    PageNumber: 1,
    PageSize: { value: 10, label: '10' },
    PageCount: 0,
    TotalCount: 0,
    Search: '',
    loading: true,
    HeaderName: '',
    SortDirection: SortType.ASC,
    showFilterOptions: false,
    status: [],
    PageNumberInput: '',
    visibleTippy: false,
  };
  constructor(props: any) {
    super(props);
    this.handleChangeEqipmentSearch = debounce(this.handleChangeEqipmentSearch.bind(this), 500);
  }
  handelChagePageSize(e: any) {
    this.setState({
      PageSize: e,
    });
    this.GetEquipments(
      this.state.PageNumber,
      parseInt(e.value),
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection
      // this.state.filterOptions
    );
  }
  handlePageNumberInput(event: { target: { value: any } }) {
    if (event.target.value > 0) {
      this.setState({ PageNumberInput: event.target.value });
    } else {
      this.setState({ PageNumberInput: '' });
    }
  }
  handleGoToPage() {
    const pageNumber = parseInt(this.state.PageNumberInput, 10);
    if (pageNumber > 0 && pageNumber <= this.state.PageCount) {
      this.handelChangePageNumber({ selected: pageNumber - 1 });
    } else {
      console.log('Invalid page number');
    }
  }
  componentDidMount() {
    document.title = `Equipment List | Radvix`;
    store.dispatch(SetHeaderTitle('Laboratory'));
    if (!isTokenInHttpAuth(HTTP)) return;
    this.controller.EquipmentsSearch(
      (res) => {
        this.setState({
          searchLoading: false,
          listLaboratory: [
            { label: 'All Laboratory', value: -1 }, // Add the "Select all" option
            ...res.map((item) => {
              return { label: item.title, value: item.id };
            }),
          ],
        });
      },
      (err) => {
        this.setState({
          searchLoading: false,
        });
      }
    );
    this.GetEquipments(this.state.PageNumber, this.state.PageSize.value);
  }
  handleStatusChange = (selectedStatus: any[]) => {
    const allValue = selectedStatus.find((x) => x.value == -1);
    const value = allValue ? null : selectedStatus;
    this.setState({ selectedStatus: value }, () => {
      this.GetEquipments(
        this.state.PageNumber,
        this.state.PageSize.value,
        this.state.Search,
        this.state.HeaderName,
        this.state.SortDirection,
        selectedStatus
      );
    });
  };
  handlelabChange = (selectedlab: any[]) => {
    const allValue = selectedlab.find((x) => x.value == -1);
    const value = allValue ? null : selectedlab;
    this.setState({ selectedlab: value }, () => {
      this.GetEquipments(
        this.state.PageNumber,
        this.state.PageSize.value,
        this.state.Search,
        this.state.HeaderName,
        this.state.SortDirection,
        this.state.selectedStatus,
        selectedlab
      );
    });
  };
  statusOptions: any[] = [
    { label: 'All Status', value: -1, svg: '' },
    {
      label: 'Operational',
      value: equipmentFilterStatus.Operational,
      backgroundColor: 'bg-success',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#299764"></circle></svg>',
    },
    {
      label: 'Maintenance',
      value: equipmentFilterStatus.NotOperational,
      backgroundColor: 'bg-warning',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#ED5F00"></circle></svg>',
    },
  ];
  GetEquipments(
    PageNumber: number,
    PageSize: number,
    Search?: string,
    columnHeaderName?: string,
    sortDirection?: SortType,
    selectedStatus?: number[],
    selectedLab?: number[]
  ) {
    this.setState({
      loading: true,
    });
    if (sortDirection == undefined || sortDirection == null) {
      sortDirection = SortType.AutoSelect;
    }
    this.controller.getAllEquipments(
      {
        PageNumber: PageNumber,
        PageSize: PageSize,
        SearchParameter: Search ? Search : this.state.Search,
        columnHeaderName,
        sortDirection,
        selectedStatus,
        selectedLab,
      },
      (res) => {
        this.setState({
          Equipments: res?.equipments || [],
          PageCount: Math.ceil(res?.count! / this.state.PageSize.value),
          TotalCount: res?.count,
          status: selectedStatus,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
  }
  handelChangePageNumber(e: { selected: number }) {
    this.setState({
      PageNumber: e.selected,
    });
    this.GetEquipments(
      e.selected + 1,
      this.state.PageSize.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      this.state.status
    );
  }
  handelChangePageSize(e: { label: string; value: number }) {
    this.setState({
      PageSize: e,
    });
    this.GetEquipments(
      this.state.PageNumber,
      e.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      this.state.status
    );
  }
  handleChangeEqipmentSearch(e: any) {
    const inputValue = e.target.value;
    const sanitizedValue = DOMPurify.sanitize(inputValue);
    this.GetEquipments(this.state.PageNumber, this.state.PageSize.value, sanitizedValue);
  }
  headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    this.setState({ HeaderName: columnHeaderName });
    this.setState({ SortDirection: sortDirection });
    this.GetEquipments(
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.Search,
      columnHeaderName,
      sortDirection
    );
  };

  handleToggleTippy = (e: any) => {
    e.stopPropagation();
    this.setState({ visibleTippy: !this.state.visibleTippy });
  };

  handleToggle = () => {
    this.setState({ showFilterOptions: !this.state.showFilterOptions });
  };
  filterClick = (
    selectedFromDate?: Date,
    selectedToDate?: Date,
    status?: any[],
    teamType?: number[]
  ) => {
    this.GetEquipments(
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      status
    );
  };
  render() {
    const _userRole = store.getState().userRole;
    return (
      <div className='card'>
        <div className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3'>
          <div className='d-flex align-items-center'>
            <BaseButton
              className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
              style={{ fontWeight: '400', height: '19px' }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>My Equipment</h2>
          </div>
          <MainButton
            className='btn btn-primary fs-15 py-px-7 new-project-button'
            svg={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='15'
                height='15'
                viewBox='0 0 15 15'
                fill='none'
              >
                <rect width='15' height='15' fill='white' fill-opacity='0.01'></rect>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z'
                  fill='white'
                ></path>
              </svg>
            }
            onClick={() => {
              this.props.history.push($ReplaceRoute(`${AppRoutes.equip_new}`));
            }}
            // style={{ height: "35px" }}
          >
            Add Equipment
          </MainButton>
          {/* <a className="btn btn-primary fs-15 py-px-7" href="create-new-proj.html">
        <i className="fa fa-plus me-1"></i> New Project</a> */}
        </div>
        <div
          className='d-flex flex-column flex-lg-row aling-items-sm-center gap-3 px-3 pb-3 filters'
          id='showFilter'
        >
          <div className='equipments-search-box flex-grow-1 d-flex align-items-center gap-px-12 d-md-block'>
            <InputIcon
              className='form-control h-px-35 w-100 flex-grow-1'
              placeholder='Search Equipment'
              chilren={undefined}
              onChange={(e) => {
                this.setState(
                  {
                    Search: e.target.value,
                  },
                  () => {
                    this.GetEquipments(this.state.PageNumber, this.state.PageSize.value);
                  }
                );
              }}
            />
            <button
              className='btn btn-secondary d-sm-none h-px-35 w-px-35 p-0'
              id='filterToggler'
              // onClick={this.filterToggle}
            >
              <img src='/images/icons/mixer-horizontal.svg' />
              <i className='fa fa-times d-none color-gray-1000'></i>
            </button>
          </div>

          <div className='equipments-filters'>
            <div className='equipments-filters-options'>
              <MultiSelect options={this.statusOptions} onChange={this.handleStatusChange} />
            </div>
            <div className='equipments-filters-options'>
              <MultiSelect options={this.state.listLaboratory} onChange={this.handlelabChange} />
            </div>
          </div>
        </div>
        <RadixTable
          data={this.state.Equipments}
          loading={this.state.loading}
          onHeaderClick={(columnHeaderName: string, sortDirection: SortType) =>
            this.headerClick(columnHeaderName, sortDirection)
          }
          columns={[
            {
              Header: 'Equipment Name',
              accessor: 'title',
              Cell: (_row: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <div className='d-flex align-items-center text-nowrap'>
                      <ImageWithToken
                        originalImage={_row.image}
                        hasImage={_row.image ? true : false}
                        alt='Avatar'
                        className='rounded-circle avatar  dFlexSm me-2'
                        style={{
                          width: '3em',
                          height: '3em',
                        }}
                        src={
                          _row.image
                            ? IMAGE_BASE_URL + _row.image
                            : '/Images/images/equipment_picture.svg'
                        }
                      />
                      <span
                        onClick={(e) =>
                          this.props.history.push(
                            $ReplaceRoute(
                              `${AppRoutes.equip_profile.replace(':id', _row.id?.toString() ?? '')}`
                            )
                          )
                        }
                        title={_row.title}
                        className='max1LineText whiteSpaceNoneSm fontBoldSm linkOnHover pointer pe-6 pe-sm-0'
                      >
                        {_row.title}
                      </span>
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Laboratory',
              accessor: 'laboratories',
              Cell: (_row: any) => {
                // const [visibleTippy, setVisibleTippy] = useState(false);

                if (!_row.laboratories || _row.laboratories.length === 0) return null;

                const firstLab = _row.laboratories[0];
                const otherLabs = _row.laboratories.slice(1);

                return (
                  <Table.Cell className='p-3'>
                    <div className='d-flex'>
                      {_row.laboratories.length > 0 && (
                        <div className='d-flex'>
                          <span className='me-2 px-3'>{firstLab.title}</span>
                          {otherLabs.length > 0 && (
                            <Tippy
                              content={
                                <div className='d-flex flex-column'>
                                  {otherLabs.map((team: any) => (
                                    <div
                                      key={team.id}
                                      style={{
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <span
                                        className='me-2 badge bg-teams text-teams py-px-6 rounded-pill fs-13 fw-normal px-3'
                                        key={team.id}
                                      >
                                        {team.title}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              }
                              visible={this.state.visibleTippy}
                              onClickOutside={() => this.setState({ visibleTippy: false })}
                              interactive
                              appendTo={document.body}
                              placement='bottom-start'
                            >
                              <div
                                className='pointer bg-white border rounded-circle d-flex justify-content-center align-items-center'
                                style={{
                                  width: '2em',
                                  height: '2em',
                                  cursor: 'pointer',
                                  backgroundColor: '#D3D4DB',
                                }}
                                onClick={this.handleToggleTippy}
                              >
                                +{otherLabs.length}
                              </div>
                            </Tippy>
                          )}
                        </div>
                      )}
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Users',
              accessor: 'company',
              Cell: (_row: any) => {
                return <Table.Cell className='p-3'> {_row.usersCount}</Table.Cell>;
              },
            },
            {
              Header: 'Status',
              accessor: 'Status',
              Cell: (_row: any) => {
                return (
                  <Table.Cell className='p-3'>
                    {' '}
                    <div
                      className='badge bg-default text-white py-px-6 rounded-pill fs-13 fw-normal px-3'
                      style={{
                        color:
                          _row.status.isStatusEquipment() === 'Operational' ? '#FFFFFF' : '#000',
                        backgroundColor:
                          _row.status.isStatusEquipment() === 'Operational' ? '#006EA8' : '#FFC962',
                      }}
                    >
                      {_row.status.isStatusEquipment()}
                    </div>
                  </Table.Cell>
                );
              },
            },

            {
              Header: '',
              Cell: (_row: any) => {
                return (
                  <Table.Cell className='p-3 text-nowrap'>
                    <div className='d-flex align-items-center gap-3'>
                      <div className='btn-group'>
                        <button
                          type='button'
                          className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                          onClick={(e) =>
                            this.props.history.push(
                              $ReplaceRoute(
                                `${AppRoutes.equip_profile.replace(
                                  ':id',
                                  _row.id?.toString() ?? ''
                                )}`
                              )
                            )
                          }
                        >
                          <i className='fa fa-eye me-px-6'></i>View Details
                        </button>
                        <DropdownMenu.Root>
                          <DropdownMenu.Trigger>
                            <button
                              type='button'
                              className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                            >
                              <span className='visually-hidden'>Toggle Dropdown</span>
                            </button>
                          </DropdownMenu.Trigger>
                          <DropdownMenu.Content>
                            <DropdownMenu.Item className='px-0'>
                              <button
                                className='dropdown-item fs-14'
                                onClick={(e) =>
                                  this.props.history.push(
                                    $ReplaceRoute(
                                      `${AppRoutes.equip_profile.replace(
                                        ':id',
                                        _row.id?.toString() ?? ''
                                      )}`
                                    )
                                  )
                                }
                              >
                                View Details
                              </button>
                            </DropdownMenu.Item>
                            <DropdownMenu.Item className='px-0'>
                              <button
                                className='dropdown-item fs-14'
                                onClick={(e) =>
                                  this.props.history.push(
                                    $ReplaceRoute(
                                      `${AppRoutes.equip_edit.replace(
                                        ':id',
                                        _row.id?.toString() ?? ''
                                      )}`
                                    )
                                  )
                                }
                              >
                                Edit Equipment
                              </button>
                            </DropdownMenu.Item>
                          </DropdownMenu.Content>
                        </DropdownMenu.Root>
                      </div>
                    </div>
                  </Table.Cell>
                );
              },
            },
          ]}
        />
        {this.state.PageCount > 0 && (
          <Pagination
            pageCount={this.state.PageCount}
            onPageChange={this.handelChangePageNumber.bind(this)}
            onPageSizeChange={(e: any) => {
              this.handelChagePageSize(e);
            }}
            pageInput={this.state.PageNumberInput}
            handlePageNumberInput={this.handlePageNumberInput}
          />
        )}
      </div>
    );
  }
}
export default withRouter(EquipPage);
