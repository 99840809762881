import { HTTP, handleUnauthorizedRequest } from "../../../core/http_common";
import { ResearchReq } from "../../models/requests/research/research_req";
import { UpdateResearchReq } from "../../models/requests/research/update_research_req";
import { ResearchesRes, TrialResResult } from "../../models/responses/research/researches_res";
import { GetResearchByidRes } from "../../models/responses/research/research_by_id_res";
import { ResearchRes } from "../../models/responses/research/research_res";
import {
  ResearchSearchRes,
  WorkspaceTeamsRes,
} from "../../models/responses/research/research_search_res";
import { TimelineRes } from "../../models/responses/research/timeline_res";
import { UpdateResearchRes } from "../../models/responses/research/update_research_req";
import { SortType } from "../../../core/number_extentions";
import { IFilterOptions } from "../../../views/components/FilterSection/IFilterOptions";

export class RemoteResearch {
  createResearch(
    body: ResearchReq,
    action: (res: ResearchRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/Research", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  updateResearch(
    body: UpdateResearchReq,
    action: (res: UpdateResearchRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.put("/Research", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  researchSearch(
    action: (res: ResearchSearchRes) => any,
    _researchId?: number
  ) {
    return HTTP.get(
      `/Research/Search${_researchId ? `?researchId=${_researchId}` : ""}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config).then((_res) => {
            action(_res?.data);
          });
          // .catch((e) => error(e));
        } else {
          // error(err);
        }
      });
  }
  getResearches(
    body: {
      PageNumber: number;
      PageSize: number;
      SearchParameter: string;
      columnHeaderName?: string;
      sortDirection?: SortType;
      filterOptions?: IFilterOptions;
    },
    action: (res: ResearchesRes) => any,
    error: (res: any) => any
  ) {
    const ColumnName = body.columnHeaderName ? body.columnHeaderName : "";
    const sort = body.sortDirection ? body.sortDirection : SortType.AutoSelect;

    const FromDate = body?.filterOptions?.selectedFromDate
      ? body.filterOptions.selectedFromDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      : "";
    const ToDate = body?.filterOptions?.selectedToDate
      ? body.filterOptions.selectedToDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      : "";
    const status = body?.filterOptions?.status ? body.filterOptions.status : "";
    const statusId = Array.isArray(status)
      ? status.map((obj: { value: any }) => obj.value)
      : [];

    const priority = body?.filterOptions?.priority ? body.filterOptions.priority : "";
    const priorityId = Array.isArray(priority)
      ? priority.map((obj: { value: any }) => obj.value)
      : [];

    return HTTP.get(
      `/Research?PageNumber=${body.PageNumber}&PageSize=${body.PageSize}&SearchParameter=${body.SearchParameter}&ColumnName=${ColumnName}&SortDirection=${sort}&SelectedFromDate=${FromDate}&SelectedToDate=${ToDate}&SelectedStatus=${statusId}&SelectedPriority=${priorityId}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  getGroupResearches(
    body: {
      PageNumber: number;
      PageSize: number;
      SearchParameter: string;
      columnHeaderName?: string;
      sortDirection?: SortType;
      filterOptions?: IFilterOptions;
    },
    action: (res: ResearchesRes) => any,
    error: (res: any) => any
  ) {
    const ColumnName = body.columnHeaderName ? body.columnHeaderName : "";
    const sort = body.sortDirection ? body.sortDirection : SortType.AutoSelect;

    const FromDate = body?.filterOptions?.selectedFromDate
      ? body.filterOptions.selectedFromDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      : "";
    const ToDate = body?.filterOptions?.selectedToDate
      ? body.filterOptions.selectedToDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      : "";
    const status = body?.filterOptions?.status ? body.filterOptions.status : "";
    const statusId = Array.isArray(status)
      ? status.map((obj: { value: any }) => obj.value)
      : [];

    const priority = body?.filterOptions?.priority ? body.filterOptions.priority : "";
    const priorityId = Array.isArray(priority)
      ? priority.map((obj: { value: any }) => obj.value)
      : [];

    return HTTP.get(
      `/Research/GetGroupList/?PageNumber=${body.PageNumber}&PageSize=${body.PageSize}&SearchParameter=${body.SearchParameter}&ColumnName=${ColumnName}&SortDirection=${sort}&SelectedFromDate=${FromDate}&SelectedToDate=${ToDate}&SelectedStatus=${statusId}&SelectedPriority=${priorityId}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  getResearchById(
    body: { id: number },
    action: (res: GetResearchByidRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(`/Research/${body.id}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  getBreadCrumbs(
    body: { profileType: number, profileId: number },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    const config = {
      params: {
        profileType: body.profileType
      }
    };
    return HTTP.post(`/Messages/FetchBreadCrumps`, body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  duplicateResearch(
    body: { id: number },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(`/Research/DuplicateResearch?projectId=${body.id}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getTimeline(
    body: { id: number },
    action: (res: TimelineRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(`/Research/TasksTimeline?ResearchId=${body.id}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  deleteResearch(
    body: { researchId: number },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.delete(`/Research?researchId=${body.researchId}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  GetWorkspaceTeams(
    action: (res: WorkspaceTeamsRes) => any,
    error: (res: any) => any,
    SelectedWorkSpace?: any[]
  ) {
    return HTTP.post(`/Research/GetSelectedWorkspaceTeams`, SelectedWorkSpace)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config).then((_res) => {
            action(_res?.data);
          });
        } else {
          error(err);
        }
      });
  }

  exportData(
    body: any,
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("Dashboard/RadvixProcessExcelFile", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getTrialInfo(
    action: (res: TrialResResult) => any,
    error: (res: any) => any
  ) {
    
    return HTTP.get(`/Subscription/GetUserTrial`)
      .then((res) =>
        action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }


}
