/* eslint-disable no-useless-concat */
import React from "react";
import SpinnerLoader from "./spinnerLoader";
export enum ThemeCircleIcon {
  light,
  dark,
  error,
  Selected,
}
interface CircleIconInterface {
  type?: ThemeCircleIcon;
  backgroundColor?: string;
  color?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  className?: string;
  onClick?: (e: any) => void;
  border?: string;
  style?: React.CSSProperties;
  title?: string;
  disabled?: boolean;
  loading?: boolean;
  padding?: string;
  float?: 'left' | 'right' | 'none' | 'inherit' | 'initial' | 'unset' | undefined;
  buttonType?: "button" | "submit" | "reset" | undefined
}
export const CircleIcon: React.FC<CircleIconInterface> = (props) => {
  let Theme: string;
  if (props.type === ThemeCircleIcon.dark) {
    Theme = "darkCircleIcon";
  } else if (props.type === ThemeCircleIcon.error) {
    Theme = "errorCircleIcon";
  } else if (props.type === ThemeCircleIcon.Selected) {
    Theme = "selectedCircleIcon";
  } else {
    Theme = "lightCircleIcon";
  }

  let IsclassName;
  if (props.className !== undefined) {
    IsclassName = props.className;
  } else {
    IsclassName = "";
  }
  return (
    <button
      title={props.title}
      disabled={props.disabled}
      tabIndex={-1}
      // role="button"
      className={`${Theme + " " + IsclassName + " " + "CircleIcon"}`}
      style={{
        backgroundColor: props.backgroundColor,
        color: props.color,
        width: props.width,
        height: props.height,
        fontSize: props.fontSize,
        border: props.border,
        minWidth: props.width,
        minHeight: props.height,
        padding: props?.padding,
        float: props?.float,
        ...props.style,
      }}
      onClick={props.onClick}
      type={props?.buttonType}
    >
      {props.loading ? <SpinnerLoader size="1.25rem" /> : props.children}
    </button>
  );
};
