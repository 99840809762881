import { HTTP, handleUnauthorizedRequest } from "../../../core/http_common";
import { SortType } from "../../../core/number_extentions";
import { IFilterOptions } from "../../../views/components/FilterSection/IFilterOptions";
import { TaskReq } from "../../models/requests/task/task_req";
import { TaskStatusUpdate, UpdateTaskReq } from "../../models/requests/task/update_task_req";
import { GetAllTasks } from "../../models/responses/task/get_all_tasks_res";
import { GetTaskByID } from "../../models/responses/task/get_task_by_id_res";
import { SearchTaskRes } from "../../models/responses/task/search_task_res";
import { TaskRes } from "../../models/responses/task/task_res";
import { UpdateTaskRes } from "../../models/responses/task/update_task_req";

export class RemoteTask {
  createTask(
    body: TaskReq,
    action: (res: TaskRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/AppTask", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  SearchTask(
    body: { researchId: number; taskId?: number | null },
    action: (res: SearchTaskRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(
      `/AppTask/Search?researchId=${body.researchId}${body.taskId ? `&taskId=${body.taskId}` : ""
      }`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getTasks(
    body: {
      researchId: number;
      PageSize: number;
      PageNumber: number;
      SearchParameter: string;
      columnHeaderName?: string; 
      sortDirection?: SortType;
      filterOptions?: IFilterOptions
    },
    action: (res: GetAllTasks) => any,
    error: (res: any) => any
  ) {
    const ColumnName = body.columnHeaderName ? body.columnHeaderName : '';
    const sort = body.sortDirection ? body.sortDirection : SortType.AutoSelect;
    const FromDate = body?.filterOptions?.selectedFromDate ? body.filterOptions.selectedFromDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '';
    const ToDate = body?.filterOptions?.selectedToDate ? body.filterOptions.selectedToDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '';
    const status = body?.filterOptions?.status ? body.filterOptions.status : '';
    const StatusId = Array.isArray(status) ? status.map((obj: { value: any }) => obj.value) : [];
    const type = body.filterOptions?.teamType ? body.filterOptions?.teamType : '';
    const typeList = Array.isArray(type) ? type.map((obj: { Id: any }) => obj.Id) : [];
    return HTTP.get(
      `/AppTask?ResearchId=${body.researchId}&PageSize=${body.PageSize}&PageNumber=${body.PageNumber}&SearchParameter=${body.SearchParameter}&ColumnName=${ColumnName}&SortDirection=${sort}&SelectedFromDate=${FromDate}&SelectedToDate=${ToDate}&SelectedStatus=${StatusId}&Type=${typeList}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getTaskById(
    body: { TaskId: number; ResearchId: number; columnHeaderName?: string; sortDirection?: SortType },
    action: (res: GetTaskByID) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(
      `/AppTask/GetProfile?appTaskId=${body.TaskId}&researchId=${body.ResearchId}&ColumnName=${body.columnHeaderName}&SortDirection=${body.sortDirection}`
    )
      .then((res) => {
        action(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  updateTask(
    body: UpdateTaskReq,
    action: (res: UpdateTaskRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.put("/AppTask", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getSuggestedEquipment(
    body: {
      usersId: string[];
      teamsId: number[];
      researchId: number;
    },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    // const _myUserStrings = body.usersId.length > 0 ? `${body.usersId.map((_u) => )}`
    const _userQueries =
      body.usersId.length > 0
        ? `${body.usersId
          .map((_userId) => {
            return `UsersId=${_userId}&`;
          })
          .join("")}`
        : `UsersId=''&`;
    const _teamQueries =
      body.teamsId.length > 0
        ? `${body.teamsId
          .map((_teamId) => `TeamsId=${_teamId}&`)
          .join("")
          .slice(0, -1)}`
        : `TeamsId=0`;
    const _query = `${_userQueries}${_teamQueries}`;

    // return HTTP.get("/AppTask/SuggestedEquipments", body)
    return HTTP.get(
      `/AppTask/SuggestedEquipments?ResearchId=${body.researchId}&${_query}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  deleteTask(
    body: { taskId: number; isSubTask: boolean },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.delete(
      `/AppTask?taskId=${body.taskId}&isSubTask=${body.isSubTask}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  markAsCompleted(
    body: TaskStatusUpdate,
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.put("/AppTask/MarkAsCompleted", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
}
