import React, { useState, useEffect } from 'react';
import { HTTP } from '../../../core/http_common';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import ReactLoading from 'react-loading';
import { useHistory } from 'react-router';
import { $ReplaceRoute } from '../../../core/utils';
import { AppRoutes } from '../../../core/constants';
import { AnyNaptrRecord } from 'dns';
import { BaseButton } from '@fluentui/react';
import { base_url_site } from '../../../core/localVariables';
import { toast } from 'react-toastify';

export const PaymentIntent = ({ updateParentState }: { updateParentState: any }) => {
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!stripe || !elements) return;
    setloading(true);
    try {
      const result = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
      });
      if (result.error) {
        updateParentState(1);
      } else {
        if (result.setupIntent && result.setupIntent.status === 'succeeded') {
          var formData = {
            paymentMethodId: result.setupIntent.payment_method,
          };
          const storedFormData = localStorage.getItem('formData');
          if (storedFormData) {
            const storeformData = JSON.parse(storedFormData);
            if (formData) {
              await HTTP.post(`/User/UserSubscription`, storeformData)
                .then((res) => {
                  console.log('res', res);
                })
                .catch((err) => {
                  if (err.response?.data.message) {
                    toast.error(`${err.response?.data.message}`);
                  }
                });
            }
          }
          await HTTP.post(`/Subscription/UpdatePaymentMethod`, formData)
            .then((response) => {
              setloading(false);
              console.log('response', response);
              updateParentState(2);
            })
            .catch((error) => {
              setloading(false);
              updateParentState(1);
            });
          // You might want to send this payment method ID to your server here.
        } else {
          updateParentState(1);
          setloading(false);
        }
      }
    } catch (error) {
      updateParentState(1);
      setloading(false);
    }
  };
  const paymentElementOptions: any = {
    layout: 'tabs',
  };
  return (
    <>
      {
        <>
          <div className='container'>
            <div className='max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center vh-100'>
              <div className='text-center mb-3'>
                <img
                  src='images/icons/new-radvix-logo.png'
                  width={111}
                  height={49}
                  // src='images/icons/radvix-logo.svg'
                  // srcSet='images/icons/radvix-logo.svg 111w'
                  // sizes='(max-width: 768px) 20vw, 10vw'
                  alt='Company logo'
                />
              </div>
              <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column loginDivBorder'>
                <div className='d-flex w-100'>
                  <BaseButton
                    className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
                    style={{ fontWeight: '400', height: '19px' }}
                    onClick={() => {
                      window.history.back();
                    }}
                  >
                    Back
                  </BaseButton>
                  <div className='verifyingAccount'>
                    <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>Payment</h1>
                  </div>
                </div>
                <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                <PaymentElement id='payment-element' options={paymentElementOptions} />
                <button
                  className='d-flex justify-content-center align-items-center btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton'
                  disabled={loading || !stripe || !elements}
                  id='submit'
                  onClick={handleSubmit}
                >
                  <span id='button-text'>
                    {loading || !stripe || !elements ? (
                      <ReactLoading className='d-flex' type='spin' height='auto' width={20} />
                    ) : (
                      'Continue'
                    )}
                  </span>
                </button>
                <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                <div className='fs-14 fw-normal'>
                  <a
                    className='color-gray-600 cursor-pointer'
                    href={`${base_url_site}/terms-of-services`}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Terms of Service |{' '}
                  </a>
                  <a
                    className='color-gray-600 cursor-pointer'
                    href={`${base_url_site}/privacy-policy`}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement
              id="payment-element"
              options={paymentElementOptions}
            />
            <button
              className="Payment_Button mt-4 w-100"
              disabled={loading || !stripe || !elements}
              id="submit"
            >
              <span id="button-text">
                {loading ? (
                  <ReactLoading
                    className="d-flex"
                    type="spin"
                    height="auto"
                    width={20}
                  />
                ) : (
                  "Add Payment Method"
                )}
              </span>
            </button>
          </form>{" "} */}
        </>
      }
    </>
  );
};
