import { ChangeEvent, useCallback, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { FaGoogle } from "react-icons/fa";
import { LogosMicrosoftIcon } from "../../login/login";
import { ToastContentProps, toast } from "react-toastify";
import { UserController } from "../../../../controllers/user/user_controller";
import GoogleLogin from "react-google-login";
import { AppRoutes, clientId } from "../../../../core/constants";
import { useMsal } from "@azure/msal-react";
import DOMPurify from "dompurify";
import { worSpaceController } from "../../../../controllers/workSpace/workSpace";
import { store } from "../../../../data/storeMain";
import {
  SetUserInfo,
  SetUserRole,
} from "../../../../data/storeMain/actions/user_action";
import { UserRoles } from "../../../../core/utils";
import { SignUpCheckMail } from "./signup_checkmail";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Checkbox } from "../../setting/component/new_notification_tbl";
import { CheckIcon } from "@radix-ui/react-icons";
import { base_url_site } from "../../../../core/localVariables";
import { BASE_URL_REDIRECT } from "../../../../core/http_common";
interface SignupPageProps {
  history?: any;
  userInvited?: boolean;
  emailConfirmed?: boolean;
}
export const SignUpPage = (props: any) => {
  const token = props?.props?.location?.search?.split("&")[0]?.split("=")[1];
  const [Email, setEmail] = useState<string>("");
  const [showAdditionalForm, setShowAdditionalForm] = useState<boolean>(false);
  const [OnsignUpSuccess, setOnsignup] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const [validationErrors, setValidationErrors] = useState({
    FirstName: false,
    LastName: false,
    Email: false,
    Password: false,
    ConfirmPassword: false,
    AgreeTerms: false,
  });
  const [loading, setloading] = useState(false);
  const [emailValidation, setEmailValidation] = useState(0);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const controller: UserController = new UserController();
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    formData.Email = Email;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(event.target.value));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const sanitizedValue = DOMPurify.sanitize(value);
    setFormData({
      ...formData,
      [name]: sanitizedValue,
    });
    setValidationErrors({
      ...validationErrors,
      [name]: false,
    });
  };

  useEffect(() => {
    if (formData.Email) {
      setShowAdditionalForm(true);
    }
  }, [formData]);
  const handleRegisterWithGoogle = async ({
    email,
    familyName,
    givenName,
    tokenId,
  }: {
    email: string;
    familyName: string;
    givenName: string;
    tokenId: string;
  }) => {
    setloading(true);
    try {
      setloading(true);
      if (!executeRecaptcha) {
        toast.error("Execute recaptcha not yet available");
        setloading(false);
        return;
      }
      if (window.grecaptcha) {
        window.grecaptcha.enterprise.ready(async () => {
          const token = await window.grecaptcha.enterprise.execute(
            "6Lfz3MYpAAAAAF3_USPchIlN_ECBUwwYeqEK2fzB",
            { action: "signup" }
          );
          await controller.isUserExist(
            { email: email, token: token },
            (userExists) => {
              setloading(false);
              if (userExists) {
                toast.error("User Already Exists");
              } else {
                setloading(false);
                setEmail(email);
                setFormData({
                  FirstName: givenName,
                  LastName: familyName,
                  Email: email,
                  LoginMethod: 1,
                  Token: tokenId,
                });
                setShowAdditionalForm(true);
              }
            },
            (err) => {
              setloading(false);
            }
          );
          // Here you can handle the token, for example, sending it to the backend
        });
      }
      executeRecaptcha("SignUp")
        .then((gReCaptchaToken) => {
          console.log(gReCaptchaToken, "response Google reCaptcha server");
        })
        .catch((x) => {});
    } catch (error) {
      setloading(false);
    }
  };

  const handleRegisterWithMicrosoft = async ({
    email,
    familyName,
    givenName,
    tokenId,
  }: {
    email: string;
    familyName: string;
    givenName: string;
    tokenId: string;
  }) => {
    setloading(true);
    try {
      setloading(true);
      if (window.grecaptcha) {
        window.grecaptcha.enterprise.ready(async () => {
          const token = await window.grecaptcha.enterprise.execute(
            "6Lfz3MYpAAAAAF3_USPchIlN_ECBUwwYeqEK2fzB",
            { action: "signup" }
          );
          await controller.isUserExist(
            { email: email, token: token },
            (userExists) => {
              setloading(false);
              if (userExists) {
                toast.error("User Already Exists");
              } else {
                setloading(false);
                setEmail(email);
                const [firstname, middleName, lastName] = givenName.split(" ");
                setFormData({
                  FirstName: firstname + (lastName ? " " + middleName : " "),
                  LastName: lastName ? lastName : middleName,
                  Email: email,
                  LoginMethod: 2,
                  Token: tokenId,
                });
                setShowAdditionalForm(true);
              }
            },
            (err) => {
              setloading(false);
            }
          );
          // Here you can handle the token, for example, sending it to the backend
        });
      }
    } catch (error) {
      setloading(false);
    }
  };
  const { instance } = useMsal();
  const microsftSignup = localStorage.getItem("MicrosoftSignup");

  useEffect(() => {
    const microsftSignup = localStorage.getItem("MicrosoftSignup");
    if (microsftSignup) {
      setloading(true);
      const authResult = JSON.parse(microsftSignup);
      const username = authResult.account.username;
      const tokenId = authResult.idToken;
      localStorage.removeItem("MicrosoftSignup");
      handleRegisterWithMicrosoft({
        email: username,
        familyName: "",
        givenName: authResult.account.name.split(" ")[0],
        tokenId: tokenId,
      });
    }
  }, [instance, microsftSignup]);
  const initializeSignIn = () => {
    localStorage.setItem("clickedOn", "signup");
    // instance
    //   .acquireTokenPopup({
    //     scopes: ['user.read'],
    //   })
    //   .then((res: any) => {
    //     const { username, name } = res.account;
    //     handleRegisterWithMicrosoft({
    //       email: username,
    //       familyName: '',
    //       givenName: name,
    //       tokenId: res.idToken,
    //     });
    //   })
    //   .catch((err: any) => {
    //     throw err;
    //   });
    instance.loginRedirect({
      scopes: ["user.read"],
      redirectUri: BASE_URL_REDIRECT + "/Signup/",
    });
  };
  const handleSubmit = useCallback(async () => {
    try {
      if (!Email) {
        setEmailValidation(2);
        return;
      }
      if (!isValidEmail) {
        toast.error("Please enter a valid email address.");
        return;
      }
      // if (!executeRecaptcha) {
      //   setEmailValidation(2);
      //   return;
      // }
      setloading(true);
      if (window.grecaptcha) {
        window.grecaptcha.enterprise.ready(async () => {
          const token = await window.grecaptcha.enterprise.execute(
            "6Lfz3MYpAAAAAF3_USPchIlN_ECBUwwYeqEK2fzB",
            { action: "signup" }
          );
          await controller.isUserExist(
            { email: Email, token: token },
            (userExists) => {
              setloading(false);
              if (userExists) {
                toast.error("User already exists.");
              } else {
                setEmailValidation(0);
                setloading(false);
                setFormData({
                  Email: Email,
                  LoginMethod: 0,
                });
                setShowAdditionalForm(true);
              }
            },
            (err) => {
              if (err.response?.data.message)
                toast.error(`${err.response?.data.message}`);
              setloading(false);
            }
          );
          // Here you can handle the token, for example, sending it to the backend
        });
      }
    } catch (error) {
      setloading(false);
    }
  }, [Email]);
  const handleSignUp = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setloading(true);
    const errors = {
      FirstName: formData.FirstName === "",
      FirstNameNumeric: formData.FirstNameNumeric === "",
      LastName: formData.LastName === "",
      LastNameNumeric: formData.LastNameNumeric === "",
      Email: formData.Email === "",
      Password: formData.Password === "",
      ConfirmPassword: formData.ConfirmPassword === "",
      AgreeTerms: false,
    };
    debugger;
    setValidationErrors(errors);
    if (Object.values(errors).some((error) => error)) {
      setloading(false);
      return;
    } else {
      if (!formData.FirstName || /^\d+$/.test(formData.FirstName)) {
        setValidationErrors({ ...validationErrors, FirstName: true });
        setloading(false);
        return;
      } else {
        setValidationErrors({ ...validationErrors, FirstName: false });
      }

      if (!formData.LastName || /^\d+$/.test(formData.LastName)) {
        setValidationErrors({ ...validationErrors, LastName: true });
        setloading(false);
        return;
      } else {
        setValidationErrors({ ...validationErrors, LastName: false });
      }

      if (formData.LoginMethod != 1 && formData.LoginMethod != 2) {
        const passwordPattern =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
        if (!passwordPattern.test(formData.Password)) {
          setValidationErrors({ ...validationErrors, Password: true });
          setloading(false);
          return;
        } else {
          setValidationErrors({ ...validationErrors, Password: false });
        }
        if (formData.Password !== formData.ConfirmPassword) {
          setValidationErrors({ ...validationErrors, ConfirmPassword: true });
          setloading(false);
          return;
        } else {
          setValidationErrors({ ...validationErrors, ConfirmPassword: false });
        }
      }
      if (!formData.AgreeTerms) {
        setValidationErrors({ ...validationErrors, AgreeTerms: true });
        setloading(false);
        return;
      } else {
        setValidationErrors({ ...validationErrors, AgreeTerms: false });
      }
      try {
        await controller.Signup(
          {
            Email: formData.Email,
            FirstName: formData.FirstName,
            LastName: formData.LastName,
            Password: formData.Password,
            ConfirmPassword: formData.ConfirmPassword,
            LoginMethod: formData.LoginMethod,
            IsL1Client: props?.userInvited ? false : true,
            Token: token,
          },
          async (res) => {
            if (formData.LoginMethod == 1) {
              await controller.connectGoogleAccount(
                true,
                formData.Token,
                res.token
              );
              await controller.GoogleSignin(
                { token: formData.Token },
                (res) => {
                  if (res) {
                    if (!res) return;
                    store.dispatch(SetUserRole(res.role ?? 0));
                    store.dispatch(SetUserInfo(res));
                    if (res.role == UserRoles.L1Client) {
                      props.props?.history.push(AppRoutes.workSpace);
                    } else {
                      props.props?.history.push(AppRoutes.dashboard);
                    }
                    window.dispatchEvent(
                      new CustomEvent("loggedIn", {
                        detail: {
                          token: res.token,
                          userId: res.id,
                        },
                      })
                    );
                  }
                },
                (err) => {
                  setloading(false);
                }
              );
            } else if (formData.LoginMethod == 2) {
              await controller.connectMicrosoftAccount(
                (res) => {},
                (err) => {},
                true,
                formData.Token,
                res.token
              );
              controller.MicrosoftSignin(
                { token: formData.Token },
                (res) => {
                  if (res) {
                    setloading(false);
                    if (!res) return;
                    store.dispatch(SetUserRole(res.role ?? 0));
                    store.dispatch(SetUserInfo(res));
                    if (res.role == UserRoles.L1Client) {
                      props?.props?.history.push(AppRoutes.workSpace);
                    } else {
                      props?.props?.history.push(AppRoutes.dashboard);
                    }
                    window.dispatchEvent(
                      new CustomEvent("loggedIn", {
                        detail: {
                          token: res.token,
                          userId: res.id,
                        },
                      })
                    );
                  }
                },
                (err: {
                  response: {
                    data: {
                      message:
                        | boolean
                        | React.ReactChild
                        | React.ReactFragment
                        | React.ReactPortal
                        | ((props: ToastContentProps<{}>) => React.ReactNode)
                        | null
                        | undefined;
                    };
                  };
                }) => {
                  setloading(false);
                  if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                  }
                }
              );
            } else {
              setOnsignup(true);
              setShowAdditionalForm(false);
            }
          },
          () => {
            setloading(false);
            toast.error(
              "Oops! There was a problem with your request. Please check and try again."
            );
          }
        );
      } catch (error) {
        toast.error(
          "Oops! There was a problem with your request. Please check and try again."
        );
        setloading(false);
      }
    }
  };
  // const handleResendEmail = async (event: { preventDefault: () => void }) => {
  //   event.preventDefault();
  //   controller.resendEmailVerfication(
  //     {
  //       email: Email,
  //     },
  //     (res) => {
  //       toast.info("Your email verification email has been sent.");
  //     },
  //     (err) => {}
  //   );
  // };
  function handleAgreeTermsChange(): void {
    const isChecked = !formData.AgreeTerms;
    setFormData({ ...formData, AgreeTerms: isChecked });
    setValidationErrors({ ...validationErrors, AgreeTerms: !isChecked });
  }
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  return (
    <>
      <div style={{ maxHeight: "100vh", overflowY: "auto" }}>
        <div className="container d-flex align-items-center justify-content-center vh-100 pt-4">
          {OnsignUpSuccess && <SignUpCheckMail Email={Email} />}

          {!showAdditionalForm && !OnsignUpSuccess && (
            <div className="max-w-rem-23 w-100 m-auto d-flex flex-column align-items-center justify-content-center pb-4">
              <div className="text-center mb-3">
                <img
                  src="/images/icons/new-radvix-logo.png"
                  width={111}
                  height={49}
                  alt="Company logo"
                />
              </div>
              <div className="bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column loginDivBorder">
                <h1 className="fs-19 color-gray-1000 fw-bold text-center mb-0">
                  Sign Up
                </h1>
                <hr className="border-gray-200 border w-100 opacity-100 my-px-20" />
                <button
                  className="btn btn-transparent d-flex align-items-center gap-2 w-100 microsoftButtonPadding justify-content-center mb-px-20"
                  onClick={initializeSignIn}
                >
                  {/* <img src="../assets/img/microsoft.png" className="w-px-16 h-px-16" />
                <span className="fs-15 color-gray-1000 fw-medium">Login with Microsoft</span> */}
                  <LogosMicrosoftIcon style={{ marginRight: "10px" }} />
                  <span className="fs-15 color-gray-1000 fw-medium">
                    Sign Up with Microsoft
                  </span>
                </button>
                <button className="btn btn-transparent d-flex align-items-center gap-2 w-100 justify-content-center googleButtonPadding">
                  {/* <img src="../assets/img/google.png" className="w-px-18 h-px-18" /> */}
                  {/* <span className="fs-15 color-gray-1000 fw-medium">Login with Google</span> */}
                  <GoogleLogin
                    disabled={false}
                    clientId={clientId}
                    buttonText="Google"
                    onSuccess={(res: any) => {
                      const { email, familyName, givenName } = res.profileObj;
                      handleRegisterWithGoogle({
                        email,
                        familyName,
                        givenName,
                        tokenId: res.tokenId,
                      });
                    }}
                    onFailure={(err) => {}}
                    cookiePolicy={"single_host_origin"}
                    className="GoogleButton"
                    render={(renderProps) => (
                      <button
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        className="SignupButton"
                        style={{ height: "38px" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          style={{ marginBottom: "3px" }}
                        >
                          <path
                            d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                            fill="#FFC107"
                          />
                          <path
                            d="M3.15308 7.3455L6.43858 9.755C7.32758 7.554 9.48058 6 12.0001 6C13.5296 6 14.9211 6.577 15.9806 7.5195L18.8091 4.691C17.0231 3.0265 14.6341 2 12.0001 2C8.15908 2 4.82808 4.1685 3.15308 7.3455Z"
                            fill="#FF3D00"
                          />
                          <path
                            d="M11.9999 22C14.5829 22 16.9299 21.0115 18.7044 19.404L15.6094 16.785C14.5717 17.5742 13.3036 18.001 11.9999 18C9.39891 18 7.19041 16.3415 6.35841 14.027L3.09741 16.5395C4.75241 19.778 8.11341 22 11.9999 22Z"
                            fill="#4CAF50"
                          />
                          <path
                            d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                            fill="#1976D2"
                          />
                        </svg>{" "}
                        <span
                          className="fs-15 color-gray-1000 fw-medium"
                          style={{ paddingLeft: "2px" }}
                        >
                          Sign Up with Google
                        </span>
                      </button>
                    )}
                  />
                </button>
                <div className="line-before line-after mt-px-20 mb-px-16">
                  <span className="color-gray-600 px-px-20 fs-15">OR</span>
                </div>
                <div className="w-100 mb-px-20">
                  <div className="mb-1 text-start">
                    <label className="color-gray-600 fs-15 me-2">Email</label>
                  </div>
                  <input
                    className="form-control h-px-45"
                    placeholder="Enter your email address"
                    onChange={handleEmailChange}
                  />
                </div>
                <button
                  className="d-flex justify-content-center align-items-center btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {" "}
                  {loading ? (
                    <ReactLoading
                      className="d-flex"
                      type="spin"
                      height="auto"
                      width={20}
                    />
                  ) : (
                    "Continue"
                  )}{" "}
                </button>
                <div className="fs-14 fw-normal color-gray-600 mb-px-30">
                  {" "}
                  Already have a Radvix account?{" "}
                  <a
                    className="text-primary fw-semibold cursor-pointer"
                    onClick={() => {
                      props?.props?.history.push(AppRoutes.login);
                    }}
                  >
                    Login
                  </a>
                </div>
                <hr className="border-gray-200 border w-100 opacity-100 my-px-20" />
                <div className="fs-14 fw-normal">
                  <a
                    className="color-gray-600 cursor-pointer"
                    href={`${base_url_site}/terms-of-services`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Terms of Service |{" "}
                  </a>
                  <a
                    className="color-gray-600 cursor-pointer"
                    href={`${base_url_site}/privacy-policy`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          )}
          {showAdditionalForm && !OnsignUpSuccess && (
            <div className="max-w-rem-23 w-100 m-auto d-flex flex-column align-items-center justify-content-center pb-4">
              <div className="text-center mb-3">
                <img
                  src="/images/icons/new-radvix-logo.png"
                  width={111}
                  height={49}
                  // src='images/icons/radvix-logo.svg'
                  // srcSet='images/icons/radvix-logo.svg 111w'
                  // sizes='(max-width: 768px) 20vw, 10vw'
                  alt="Company logo"
                />
              </div>
              <div className="bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column loginDivBorder">
                <h1 className="fs-19 color-gray-1000 fw-bold text-center mb-0">
                  Sign Up
                </h1>
                <hr className="border-gray-200 border w-100 opacity-100 my-px-20" />
                <div
                  className="col-12 mb-px-20"
                  style={{ display: "flex", gap: "7px" }}
                >
                  <div className="col-6">
                    <div className="mb-1 text-start">
                      <label className="color-gray-600 fs-15 me-2">
                        First name
                      </label>
                    </div>
                    <input
                      className={`form-control h-px-45 ${
                        validationErrors.FirstName ? "invalid" : ""
                      }`}
                      type="text"
                      id="FirstName"
                      name="FirstName"
                      value={formData.FirstName}
                      onChange={handleChange}
                    />
                    {validationErrors.FirstName && formData.FirstName == "" ? (
                      <div className="error-text">
                        Please enter your first name.
                      </div>
                    ) : (validationErrors.FirstName && /^\d+$/.test(formData.FirstName) ? (
                      <div className="error-text">
                       Name cannot be numeric only.
                      </div>
                    ): null)}
                  </div>
                  <div className="col-6">
                    <div className="mb-1 text-start">
                      <label className="color-gray-600 fs-15 me-2">
                        Last name
                      </label>
                    </div>
                    <input
                      className={`form-control h-px-45 ${
                        validationErrors.LastName ? "invalid" : ""
                      }`}
                      type="text"
                      id="LastName"
                      name="LastName"
                      value={formData.LastName}
                      onChange={handleChange}
                    />
                    {validationErrors.LastName && formData.LastName == "" ? (
                      <div className="error-text">
                        Please enter your last name.
                      </div>
                    ) : (validationErrors.LastName && /^\d+$/.test(formData.LastName) ? (
                      <div className="error-text">
                       Name cannot be numeric only.
                      </div>
                    ): null)}
                  </div>
                </div>
                <div className="w-100 mb-px-20">
                  <div className="mb-1 text-start">
                    <label className="color-gray-600 fs-15 me-2">Email</label>
                  </div>
                  <input
                    className="form-control h-px-45"
                    placeholder="Enter your email address"
                    onChange={handleEmailChange}
                    readOnly={true}
                    value={Email}
                  />
                </div>
                {formData.LoginMethod !== 1 && formData.LoginMethod !== 2 && (
                  <>
                    <div className="w-100 mb-px-20">
                      <div className="mb-1 text-start">
                        <label className="color-gray-600 fs-15 me-2">
                          Password
                        </label>
                      </div>
                      <input
                        className={`form-control h-px-45 ${
                          validationErrors.Password ? "invalid" : ""
                        }`}
                        type="password"
                        id="Password"
                        name="Password"
                        value={formData.Password}
                        onChange={handleChange}
                        placeholder="Enter your password"
                      />
                      {validationErrors.Password && (
                        <div className="error-text">
                          Password must contain at least one lowercase letter,
                          one uppercase letter, one numeric digit, one special
                          character, and be at least 8 characters long.
                        </div>
                      )}
                    </div>
                    <div className="w-100 mb-px-20">
                      <div className="mb-1 text-start">
                        <label className="color-gray-600 fs-15 me-2">
                          Confirm password
                        </label>
                      </div>
                      <input
                        className={`form-control h-px-45 ${
                          validationErrors.ConfirmPassword ? "invalid" : ""
                        }`}
                        type="password"
                        id="ConfirmPassword"
                        name="ConfirmPassword"
                        value={formData.ConfirmPassword}
                        onChange={handleChange}
                        placeholder="Re-type your password"
                      />
                      {validationErrors.ConfirmPassword && (
                        <div className="error-text">
                          The password does not match.
                        </div>
                      )}
                    </div>
                  </>
                )}
                <button
                  className="d-flex justify-content-center align-items-center btn btn-primary fs-15 w-100 h-px-42 continurButton"
                  type="submit"
                  onClick={handleSignUp}
                >
                  {" "}
                  {loading ? (
                    <ReactLoading
                      className="d-flex"
                      type="spin"
                      height="auto"
                      width={20}
                    />
                  ) : (
                    "Continue"
                  )}{" "}
                </button>
                {/* <div className="SignUpform">
                     <div className="sign-up-checkbox d-flex w-100">
                     <Checkbox
                        onClick={handleAgreeTermsChange}
                        checked={formData.AgreeTerms}
                        className="me-2"
                      />
                      <span className="termsAgree">
                        I agree to the Radvix{" "}
                        <a className="privacyLink">Privacy Policy</a> and{" "}
                        <a className="privacyLink">Terms of Service</a>
                      </span>
                    </div>
                    {validationErrors.AgreeTerms && (
                      <div className="error-text">
                        You must agree to the Terms of Service and Privacy
                        Policy.
                      </div>
                    )}
                  </div> */}
                <div className="mb-px-20 SignUpform">
                  <div className="sign-up-checkbox pt-px-20">
                    <span className="d-block pt-px-2 flex-shrink-0">
                      <Checkbox
                        onChange={handleAgreeTermsChange}
                        aria-checked={formData.AgreeTerms}
                        checked={formData.AgreeTerms}
                      />
                    </span>
                    <span className="color-gray-600">
                      I agree to the Radvix{" "}
                      <a
                        className="privacyLink cursor-pointer"
                        href={`${base_url_site}/privacy-policy`}
                      >
                        Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a
                        className="privacyLink cursor-pointer"
                        href={`${base_url_site}/terms-of-services`}
                      >
                        Terms of Service
                      </a>
                    </span>
                  </div>
                  {validationErrors.AgreeTerms && (
                    <div className="error-text mt-1">
                      You must agree to the Terms of Service and Privacy Policy.
                    </div>
                  )}
                </div>
                <hr className="border-gray-200 border w-100 opacity-100 my-px-20" />
                <div className="fs-14 fw-normal">
                  <a
                    className="color-gray-600 cursor-pointer"
                    href={`${base_url_site}/terms-of-services`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Terms of Service |{" "}
                  </a>
                  <a
                    className="color-gray-600 cursor-pointer"
                    href={`${base_url_site}/privacy-policy`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>

            // <div className="sign-up-page">
            //   <div
            //     className=""
            //     style={{
            //       display: "flex",
            //       justifyContent: "center",
            //       alignItems: "center",
            //     }}
            //   ></div>
            //   <div
            //     className="details-group"
            //     style={{
            //       background:
            //         "linear-gradient(360deg, rgba(212, 15, 237, 0) 0%, rgba(212, 15, 237, 1) 100%)",
            //       height: validationErrors.Password ? "675px" : "619px",
            //     }}
            //   >
            //     <div className="overlap-group">
            //       <h2 className="mt-2 headingText">Almost There!</h2>
            //       <form onSubmit={handleSignUp}>
            //         <div
            //           className="col-12"
            //           style={{ display: "flex", gap: "7px", paddingTop: "15px" }}
            //         >
            //           <div className="col-6">
            //             <label htmlFor="firstName" className="sign-up-label">
            //               First Name:
            //             </label>
            //             <input
            //               className={`SignupInput ${
            //                 validationErrors.FirstName ? "invalid" : ""
            //               }`}
            //               type="text"
            //               id="FirstName"
            //               name="FirstName"
            //               value={formData.FirstName}
            //               onChange={handleChange}
            //             />
            //             {validationErrors.FirstName && (
            //               <div className="error-text">
            //                 Please enter your first name.
            //               </div>
            //             )}
            //           </div>
            //           <div className="col-6">
            //             <label htmlFor="LastName" className="sign-up-label">
            //               Last Name:
            //             </label>
            //             <input
            //               className={`SignupInput ${
            //                 validationErrors.LastName ? "invalid" : ""
            //               }`}
            //               type="text"
            //               id="LastName"
            //               name="LastName"
            //               value={formData.LastName}
            //               onChange={handleChange}
            //             />
            //             {validationErrors.LastName && (
            //               <div className="error-text">
            //                 Please enter your last name.
            //               </div>
            //             )}
            //           </div>
            //         </div>
            //         {/* <div className="SignUpform">

            //     </div> */}
            //         <div className="SignUpform">
            //           <label htmlFor="email" className="sign-up-label">
            //             Email:
            //           </label>
            //           <input
            //             className={`SignupInput-Email ${
            //               validationErrors.Email ? "invalid" : ""
            //             }`}
            //             type="text"
            //             id="Email"
            //             name="Email"
            //             value={formData.Email}
            //             disabled={true}
            //             onChange={handleChange}
            //           />
            //           {validationErrors.Email && (
            //             <div className="error-text">Please enter your email.</div>
            //           )}
            //         </div>
            //         {formData.LoginMethod !== 1 && formData.LoginMethod !== 2 && (
            //           <>
            //             <div className="SignUpform">
            //               <label htmlFor="password" className="sign-up-label">
            //                 Password:
            //               </label>
            //               <input
            //                 className={`SignupInput-Email ${
            //                   validationErrors.Password ? "invalid" : ""
            //                 }`}
            //                 type="password"
            //                 id="Password"
            //                 name="Password"
            //                 value={formData.Password}
            //                 onChange={handleChange}
            //               />
            //               {validationErrors.Password && (
            //                 <div className="error-text">
            //                   Password must contain at least one lowercase letter,
            //                   one uppercase letter, one numeric digit, one special
            //                   character, and be at least 8 characters long.
            //                 </div>
            //               )}
            //             </div>

            //             <div className="SignUpform">
            //               <label
            //                 htmlFor="confirmPassword"
            //                 className="sign-up-label"
            //               >
            //                 Confirm Password:
            //               </label>
            //               <input
            //                 className={`SignupInput-Email ${
            //                   validationErrors.ConfirmPassword ? "invalid" : ""
            //                 }`}
            //                 type="password"
            //                 id="ConfirmPassword"
            //                 name="ConfirmPassword"
            //                 value={formData.ConfirmPassword}
            //                 onChange={handleChange}
            //               />
            //               {validationErrors.ConfirmPassword && (
            //                 <div className="error-text">
            //                   The password does not match.
            //                 </div>
            //               )}
            //             </div>
            //           </>
            //         )}
            //         <div className="SignUpform">
            //           <div className="sign-up-checkbox">
            //             <Checkbox
            //               onClick={handleAgreeTermsChange}
            //               checked={formData.AgreeTerms}
            //             />
            //             <span className="termsAgree">
            //               I agree to the Radvix{" "}
            //               <a className="privacyLink">Privacy Policy</a> and{" "}
            //               <a className="privacyLink">Terms of Service</a>
            //             </span>
            //           </div>
            //           {validationErrors.AgreeTerms && (
            //             <div className="error-text">
            //               You must agree to the Terms of Service and Privacy
            //               Policy.
            //             </div>
            //           )}
            //         </div>
            //         <div>
            //           <button className="submitButton w-100" type="submit">
            //             {loading ? (
            //               <ReactLoading
            //                 className="d-flex"
            //                 type="spin"
            //                 height="auto"
            //                 width={20}
            //               />
            //             ) : (
            //               "Create Account"
            //             )}
            //           </button>
            //         </div>
            //       </form>
            //     </div>
            //   </div>
            // </div>
          )}
        </div>
      </div>
    </>
  );
};
