import React, { useRef, useState } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { MainButton, MainButtonType } from "../../../components/button";
import { InputComponent, InputType } from '../../../components/inputs';
import { toast } from 'react-toastify';
import { HTTP } from '../../../../core/http_common';
import Loading from 'react-loading';

interface AddPaymentProps {
    usersId: string;
    showCloseButton: boolean;
}

const AddPayment: React.FC<AddPaymentProps> = ({ usersId, showCloseButton }) => {
    const [loading, setloading] = useState(false);
    const [cvc, setCvc] = useState('');
    const [expiry, setExpiry] = useState('');
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [errors, setErrors] = useState({
        cvc: '',
        expiry: '',
        name: '',
        number: ''
    });
    const initialState = {
        cvc: '',
        expiry: '',
        name: '',
        number: ''
    };
    const closeButtonRef = useRef<HTMLButtonElement | null>(null);

    const handleInputChange = (name: string, value: string) => {
        let error = '';
        if (name === "cvc") {
            if (!/^[0-9]{3,4}$/.test(value)) {
                error = 'Invalid CVC';
            }
            setCvc(value);
        }
        else if (name === "number") {
            if (!/^[0-9]{16}$/.test(value)) {
                error = 'Invalid Card Number';
            }
            setNumber(value);
        }
        else if (name === "name") {
            if (!/^[a-zA-Z ]+$/.test(value)) {
                error = 'Invalid Name';
            }
            setName(value);
        }
        setErrors({ ...errors, [name]: error });
    };
    const handleSave = () => {
        const paymentData = { usersId, cvc, expiry, name, number };
        console.log(paymentData);
        setloading(true);
        HTTP.post(`/Subscription/UpdatePaymentMethod`, paymentData)
            .then((res) => {
                
                toast.success("Payment Method Added Successfully");
                resetInputs();
                setloading(false);
                if (closeButtonRef.current) {
                    closeButtonRef.current.click();
                }
            })
            .catch((err) => {
                
                if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
                setloading(false);
            });
    };


    const handleCardExpirationDateChange = (e: { target: { value: any; }; }) => {
        const input = e.target.value;
        const formattedInput = input.replace(/\D/g, '');

        if (formattedInput.length > 4) {
            return;
        }

        let expiry = '';
        if (formattedInput.length > 2) {
            const month = formattedInput.substring(0, 2);
            const year = formattedInput.substring(2, 4);
            expiry = `${month}/${year}`;
        } else if (formattedInput.length > 0) {
            expiry = formattedInput;
        }

        setExpiry(expiry);
    };
    const resetInputs = () => {
        setCvc(initialState.cvc);
        setExpiry(initialState.expiry);
        setName(initialState.name);
        setNumber(initialState.number);
        setErrors({
            cvc: '',
            expiry: '',
            name: '',
            number: ''
        });
    };
    const ValidityCheck = () => {
        const hasError = Object.values(errors).some((error) => error !== '');
        const isAnyFieldEmpty = !(cvc && expiry && number);
        return hasError || isAnyFieldEmpty;
    };

    return (
        <div className="modal-dialog" style={{ width: 800, maxWidth: "100%" }}>
            <div className="modal-content bg-light">
                <div className="modal-header">
                    <h5 className="mb-0">Add Payment Method</h5>
                    {showCloseButton && (
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="close_modal_Type"
                            onClick={resetInputs}
                            ref={closeButtonRef}
                        ></button>
                    )}
                </div>
                <div className="d-flex flex-column flex-sm-row p-3 gap-3">
                    <form className="row g-3">
                        <div className="col">
                            <div className="col-12">
                                <InputComponent
                                    className="mb-3"
                                    fontSize="12px"
                                    inputType="number"
                                    type={InputType.text}
                                    label="Card Number"
                                    value={number}
                                    name={"number"}
                                    onChange={(e) => handleInputChange("number", e.target.value)}
                                    placeholder="1234 1234 1234 1234"
                                />
                                {errors.number && <p style={{ color: 'red' }}>{errors.number}</p>}

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <InputComponent
                                    className="mb-3"
                                    fontSize="12px"
                                    inputType="text"
                                    label="Expiry"
                                    name="expiry"
                                    type={InputType.text}
                                    value={expiry}
                                    onChange={handleCardExpirationDateChange}
                                    placeholder="MM/YY"
                                />
                                {errors.expiry && <p style={{ color: 'red' }}>{errors.expiry}</p>}
                            </div>
                            <div className="col-6">
                                <InputComponent
                                    className="mb-3"
                                    fontSize="12px"
                                    inputType="number"
                                    label="CVC"
                                    name="cvc"
                                    type={InputType.text}
                                    value={cvc}
                                    onChange={(e) => handleInputChange("cvc", e.target.value)}
                                    placeholder="123"
                                />
                                {errors.cvc && <p style={{ color: 'red' }}>{errors.cvc}</p>}
                            </div>
                        </div>
                        <div className="col-12">
                            <MainButton
                                children="Save"
                                type={MainButtonType.dark}
                                borderRadius="24px"
                                fontSize="14px"
                                minWidth="100px"
                                minHeight="2em"
                                className="px-3 mx-0"
                                loading={loading}
                                onClick={handleSave}
                                disabled={ValidityCheck()}
                            />
                        </div>

                    </form>
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                         <Cards
                            cvc={cvc}
                            expiry={expiry}
                            number={number}
                            name={""}
                        />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default AddPayment;
