import React, { useState, useEffect, useRef } from 'react';
import * as Popover from '@radix-ui/react-popover';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import './radixMultiSelect.css'; // Import the styles for the component

const MultiSelect = ({ options, onChange }: any) => {
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAllText, setSelectedAllText] = useState<string>();
  const isInitializing = useRef(true);

  const handleSelect = (option: any) => {
    let newSelectedItems;
    if (option.value === -1) {
      // Handle "select all"
      if (selectedItems.length === options.length - 1) {
        newSelectedItems = [];
      } else {
        newSelectedItems = options.filter((opt: any) => opt.value !== -1);
      }
    } else {
      newSelectedItems = selectedItems.includes(option)
        ? selectedItems.filter((item: any) => item !== option)
        : [...selectedItems, option];
    }
    setSelectedItems(newSelectedItems);
  };

  useEffect(() => {
    if (!isInitializing.current) {
      onChange(selectedItems);
    }
  }, [selectedItems, onChange]);

  useEffect(() => {
    const autoSelectedItems = options.find((option: any) => option.value === -1);
    if (autoSelectedItems) {
      setSelectedAllText(autoSelectedItems?.label);
      handleSelect(autoSelectedItems);
      isInitializing.current = false; // Set to false after initialization
    }
  }, [options]);

  const allSelected = selectedItems.length === options.length - 1;
  const renderItems = (selectedItem: any[]) => {
    {
      if (selectedItem.length == 1) {
        return selectedItems.map((item: any) => (
          <div
            className={`radix-select-multi-selected-label ${
              item.backgroundColor ? item.backgroundColor : 'background-status'
            }`}
          >
            <span key={item.value} className='radix-select-multi-item'>
              {item.label}
            </span>
          </div>
        ));
      } else if (selectedItem.length > 1) {
        return (
          <>
            <div className='radix-select-with-number'>
              <div
                className={`radix-select-multi-selected-label ${
                  selectedItem[0].backgroundColor
                    ? selectedItem[0].backgroundColor
                    : 'background-status'
                }`}
              >
                <span key={selectedItem[0].value} className='radix-select-multi-item'>
                  {selectedItem[0].label}
                </span>
              </div>
              <div className={`radix-multiSelect-number`}>
                <span className='radix-multiSelect-number-text'>
                  {`+${selectedItems.length - 1}`}
                </span>
              </div>
            </div>
          </>
        );
      }
    }
  };
  return (
    <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
      <Popover.Trigger asChild>
        <button className='multi-select-trigger radix-select-popup'>
          {allSelected ? (
            <span className='radix-select-multi-item-label'>{selectedAllText}</span>
          ) : selectedItems.length > 0 ? (
            renderItems(selectedItems)
          ) : (
            <span className='radix-select-multi-item-label'>Select items</span>
          )}
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='14'
            height='14'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='dropdown-icon'
          >
            <polyline points='6 9 12 15 18 9'></polyline>
          </svg>
        </button>
      </Popover.Trigger>
      <Popover.Content className='multi-select-content' style={{ zIndex: '9999999' }}>
        {options.map((option: any) => (
          <div key={option.value} className='multi-select-item'>
            <Checkbox.Root
              className='checkbox-root'
              id={`checkbox-${option.value}`}
              checked={option.value === -1 ? allSelected : selectedItems.includes(option)}
              onCheckedChange={() => handleSelect(option)}
            >
              <Checkbox.Indicator className='checkbox-indicator'>
                <CheckIcon />
              </Checkbox.Indicator>
            </Checkbox.Root>
            <span dangerouslySetInnerHTML={{ __html: option.svg }} />
            <label className='checkbox-label radix-select-multi-label'>{option.label}</label>
          </div>
        ))}
      </Popover.Content>
    </Popover.Root>
  );
};

export default MultiSelect;
