import { HTTP, handleUnauthorizedRequest } from "../../../core/http_common";
import { LaboratoryCreateReq } from "../../models/requests/laboratory/laboratory_create_req";
import { UpdateLaboratoryReq } from "../../models/requests/laboratory/laboratory_update_req";
import {
  AnswerRemovalLaboratoryReq,
  GetLaboratoryByID,
} from "../../models/responses/laboratory/laboratory_by_id_res";
import { LaboratoryCreateRes } from "../../models/responses/laboratory/laboratory_create_res";
import { LaboratoryGetAllRes } from "../../models/responses/laboratory/laboratory_get_all_res";
import { UpdateLaboratoryRes } from "../../models/responses/laboratory/laboratory_update_res";
import { LboratorySearchRes } from "../../models/responses/laboratory/laboratory_search_res";
import { SortType } from "../../../core/number_extentions";
import { toDate } from "date-fns";
export class RemoteLaboratory {
  createLaboratory(
    body: LaboratoryCreateReq,
    action: (res: LaboratoryCreateRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/Laboratory", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getLaboratorySearch(
    action: (res: LboratorySearchRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.get("/Laboratory/Search")
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getLaboratoryGetAll(
    body: { PageNumber: number; PageSize: number; SearchParameter: string; columnHeaderName?: string; sortDirection?: SortType,selectedFromDate?: string, selectedToDate?: string, },
    action: (res: LaboratoryGetAllRes) => any,
    error: (res: any) => any
  ) {
    const FromDate = body.selectedFromDate ? body.selectedFromDate : '';
    const ToDate = body.selectedToDate ? body.selectedToDate : '';
    return HTTP.get(
      `/Laboratory?PageSize=${body.PageSize}&PageNumber=${body.PageNumber}&SearchParameter=${body.SearchParameter}&ColumnName=${body.columnHeaderName}&SortDirection=${body.sortDirection}&SelectedFromDate=${FromDate}&SelectedToDate=${ToDate}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  updateLaboratory(
    body: UpdateLaboratoryReq,
    action: (res: UpdateLaboratoryRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.put("/Laboratory", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  answerRemovalFromLab(
    body: AnswerRemovalLaboratoryReq,
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/Laboratory/ManagerRemoval", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getLaboratoryById(
    body: { id: number },
    action: (res: GetLaboratoryByID) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(`/Laboratory/${body.id}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  deleteLaboratory(
    body: { laboratoryId: number },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.delete(`/Laboratory?laboratoryId=${body.laboratoryId}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
}
