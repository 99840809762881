import React, { useEffect, useState } from 'react'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { MainButton, MainButtonType } from '../../components/button';
import { UserController } from '../../../controllers/user/user_controller';
import { MfaInput } from '../mfa';
import { securePhoneNumber } from '../../../core/utils';
import Countdown from 'react-countdown';
import { MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
interface MfaSectionTypes {
    userMobileNumber: string | null;
    isMobileNumberVerified: boolean;
    onCodeSend: () => void;
};

const MfaSection = ({
    userMobileNumber,
    isMobileNumberVerified,
    onCodeSend
}: MfaSectionTypes) => {
    const controller: UserController = new UserController();
    const [phoneNumber, setPhoneNumber] = useState('')
    const [editablePhoneNumber, setEditablePhoneNumber] = useState('')
    const [mfaCode, setMfaCode] = useState('')
    const [sendingCode, setSendingCode] = useState(false)
    const [canResend, setCanResend] = useState(false)
    const [resendCountDownDate, setResendCountDownDate] = useState(Date.now() + 60000)
    const [resendingCode, setResendingCode] = useState(false)
    const [editPhoneNumberMode, setEditPhoneNumberMode] = useState(false)
    const [confirmingCode, setConfirmingCode] = useState(false)



    function handleSendCode(_phoneNumber: string) {

        setSendingCode(true)
        controller.sendCodeBySms({
            mobileNumber: _phoneNumber
        }, (res) => {
            setSendingCode(false)
            setEditPhoneNumberMode(false)
            toast.info('Regenrated the code')
            onCodeSend()
        }, (err) => {
            setSendingCode(false)
        })
    }
    function handleConfirmCode(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        setConfirmingCode(true)
        controller.verifyMobileNumber({
            code: Number(mfaCode)
        }, (res) => {
            toast.info('Mobile number verified')

            setConfirmingCode(false)
            onCodeSend()
        }, (err) => {

            setConfirmingCode(false)
        })
    }
    function handleResendCode() {
        setResendingCode(true)
        controller.resendMfaCodeForMobile((res) => {
            setResendCountDownDate(Date.now() + 60000)
            setCanResend(false)
            setResendingCode(false)
            toast.info('Regenrated the code')
        }, (err) => {
            setResendingCode(false)
        })
    }

    const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
        if (completed) {
            
            // Render a completed state
            // setCanResend(true)
            return '';
        } else {
            // Render a countdown
            return <span className="ms-2">{minutes}:{seconds}</span>;
        }
    };


    useEffect(() => {
        if (userMobileNumber) {
            setPhoneNumber(userMobileNumber)
            setEditablePhoneNumber(userMobileNumber)
        }
    }, [userMobileNumber])


    return (
        <>
            {
                userMobileNumber ? (
                    <>
                        {
                            editPhoneNumberMode ? (

                                <PhoneNumberSection
                                    loading={sendingCode}
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        handleSendCode(editablePhoneNumber)
                                    }}
                                    phoneNumber={editablePhoneNumber}
                                    setPhoneNumber={setEditablePhoneNumber}
                                    header={<>
                                        <label className='label mb-1'>Edit mobile number</label>
                                    </>}
                                    action={<MainButton
                                        children="Go back"
                                        type={MainButtonType.light}
                                        tabIndex={-1}
                                        borderRadius="24px"
                                        fontSize="14px"
                                        minHeight="2em"
                                        className="px-3 me-2"
                                        onClick={() => {
                                            setEditPhoneNumberMode(false)
                                            setEditablePhoneNumber(phoneNumber)
                                        }}
                                    ></MainButton>}
                                    confirmText="Update mobile number"
                                />
                            ) : (
                                <form autoComplete="off" onSubmit={handleConfirmCode}>
                                    <div className="d-flex flex-column p-2" style={{
                                      
                                        borderRadius: '8px',
                                        // height: '4em'
                                    }}>
                                        {
                                            isMobileNumberVerified ? '' : (
                                                <MfaInput
                                                    labelStyle={{
                                                        color: '#000'
                                                    }}
                                                    inputStyle={{
                                                        padding: '0.25em',
                                                        width: '2.25em',
                                                        height: '2.5em',
                                                    }}
                                                    containerStyle={{
                                                        gap: '0.25em'
                                                    }}
                                                    code={mfaCode}
                                                    onChange={setMfaCode}
                                                />
                                            )
                                        }
                                        <div className={`d-flex ${isMobileNumberVerified ? '' : 'mt-2'} align-items-center`}>
                                            <p className='mb-0 textSecondary fs-100'>
                                                Mobile number: {securePhoneNumber(phoneNumber || '')}
                                            </p>
                                            <MdEdit
                                                size='1.25em'
                                                className="ms-2 pointer"
                                                onClick={() => {
                                                    setEditPhoneNumberMode(true)
                                                }}
                                            />
                                        </div>
                                        {
                                            isMobileNumberVerified ? '' : (

                                                <p className="mt-2 mb-0 fs-200">
                                                    <span className="textSecondary">Didn't get a code?</span>
                                                    <strong className={canResend ? "pointer" : 'textSecondary'}
                                                        onClick={() => {
                                                            if (canResend)
                                                                handleResendCode()
                                                        }}
                                                        style={{
                                                            marginLeft: '0.32em',
                                                        }}>Click to resend.
                                                        {
                                                            canResend ? '' : (


                                                                <Countdown
                                                                    renderer={renderer}
                                                                    date={resendCountDownDate}
                                                                    autoStart
                                                                    onComplete={() => {
                                                                        setCanResend(true)
                                                                        // setResendCountDownDate(COUNTDOWN_TIME)
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                    </strong>
                                                </p>
                                            )
                                        }
                                    </div>
                                    {
                                        isMobileNumberVerified ? '' : (

                                            <MainButton
                                                disabled={confirmingCode || mfaCode.length !== 6 || !phoneNumber || sendingCode || resendingCode}
                                                loading={confirmingCode}
                                                children="Verify code"
                                                style={{borderRadius: "5px", padding:"10px"}}
                                                className="btn btn-primary fs-15 sendCodeStyle py-px-7 d-flex align-items-center gap-2"
                                                buttonType='submit'
                                            ></MainButton>
                                        )
                                    }
                                </form>
                            )
                        }

                    </>
                ) : (
                    <PhoneNumberSection
                        loading={sendingCode}
                        onSubmit={(e) => {
                            e.preventDefault()
                            handleSendCode(phoneNumber)
                        }}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                    />
                )
            }

        </>
    )
};


const PhoneNumberSection = ({
    onSubmit,
    phoneNumber,
    setPhoneNumber,
    loading,
    confirmText = 'Send code',
    header,
    action
}: {
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    phoneNumber: string;
    setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
    loading: boolean;
    confirmText?: string;
    header?: React.ReactNode;
    action?: React.ReactNode;
}) => {
    return (
        <form onSubmit={onSubmit}>
            <div className="d-flex flex-column p-2" style={{
                borderRadius: '8px',
                // height: '4em'
            }}>
                {header}
                <PhoneInput
                    addInternationalOption={false}
                    defaultCountry='US'
                    countryCallingCodeEditable={false}
                    countries={['US']}
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={(e) => {
                        // isValidPhoneNumber(e as string || '')
                        setPhoneNumber(e as string)
                    }}
                    className={`phoneNumberInput ${(!isValidPhoneNumber(phoneNumber as string || '') && phoneNumber?.length > 0) ? 'notValid' : ''}`}
                />
            </div>
            <div className='d-flex align-items-center mt-2'>
                {action}
                <MainButton
                    buttonType='submit'
                    disabled={!phoneNumber || loading}
                    loading={loading}
                    style={{borderRadius: "5px", padding:"10px"}}
                    children={confirmText}
                    type={MainButtonType.dark}
                    fontSize="14px"
                    className="btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2"
                ></MainButton>
            </div>
        </form>
    )
}
export default MfaSection