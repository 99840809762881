import { HTTP, handleUnauthorizedRequest } from "../../../core/http_common";
import { ChangePasswordReq } from "../../models/requests/user/change_password_req";
import { UserSigninReq } from "../../models/requests/user/signin_req";
import { UserAccountHandleReq } from "../../models/requests/user/account_req";
import { UpdateMyProfileReq } from "../../models/requests/user/update_myprofile_req";
import { ChangePasswordRes } from "../../models/responses/user/change_password_res";
import { DashboardMyReport } from "../../models/responses/user/dashboard_report";
import { UserSigninRes } from "../../models/responses/user/signin_res";
import { UserAccountResetResult } from "../../models/responses/user/account_reset_res";
import { UpdateMyProfileRes } from "../../models/responses/user/update_myprofile_req";
import { UserTypeGet } from "../../models/responses/user/user_type_res";
import { RegisterReq } from "../../models/requests/register/register_req";
import { RegisterRes } from "../../models/responses/register/register_res";
import { SortType } from "../../../core/number_extentions";
import { signUpReq } from "../../models/requests/user/sign_up_req";
import { BooleanLiteral } from "@babel/types";

export class RemoteEmailIntegration {
    GetAllMessages(
    body: any,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    
    return HTTP.post("/Messages/GetAllMessages", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  MarkasRead(
    body: any,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    
    return HTTP.post("/Messages/MarkasRead", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  SendReply(
    body: any,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    
    return HTTP.post("/Messages/SendReply", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  SendAsync(
    body: any,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    
    return HTTP.post("/Messages/SendAsync", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  

  DeleteEmail(
    body: any,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    
    return HTTP.get(`/Messages/DeleteEmail?emailId=${body}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  GetUserEmailData(
    body: any,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    
    return HTTP.post("/Messages/GetUserEmailData", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  AccountDataInsert(
    body: any,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    
    return HTTP.post("/Messages/AccountDataInsert", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

}
