import { toast } from 'react-toastify';
import { SortType } from '../../core/number_extentions';
import { EditExpensesReq } from '../../data/models/requests/financials/update_expense_req';
import { CreateExpensesResResult } from '../../data/models/responses/financials/expenses/create_expenses_res';
import {
  CreateExpense,
  CreateStateExpensesResResult,
} from '../../data/models/responses/financials/expenses/create_state_expenses_res';
import { GetExpensesByIDResult } from '../../data/models/responses/financials/expenses/expenses_by_id_res';
import { GetAllExpensesResult } from '../../data/models/responses/financials/expenses/expenses_res';
import { SearchExpensesResResult } from '../../data/models/responses/financials/expenses/search_expenses_res';
import { EditExpensesResResult } from '../../data/models/responses/financials/expenses/update_publish_res';
import { RemoteExpenses } from '../../data/remotes/financials/remote_expense';
import { store } from '../../data/storeMain';

export class expensesController {
  remote = new RemoteExpenses();

  createExpense(
    body: CreateExpense,
    action: (res: CreateExpensesResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.createExpense(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  SearchExpense(action: (res: SearchExpensesResResult) => any, error: (res: any) => any) {
    if (store.getState().ResearchId.value > 0) {
      this.remote.SearchExpense(
        { researchId: store.getState().ResearchId.value },
        (res) => {
          action(res.result!);
        },
        (err) => {
          if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
          error(err);
        }
      );
    } else {
      // toast.error(`please select research in search expense`, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }
  }
  getExpenses(
    body: {
      PageNumber: number;
      PageSize: number;
      ResearchId: number;
      SearchParameter: string;
      columnHeaderName?: string;
      sortDirection?: SortType;
      statusOption?: any[] | null;
    },
    action: (res: GetAllExpensesResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getExpenses(
      body,
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  createState(
    body: { expenseId: number; isApproved: boolean },
    action: (res: CreateStateExpensesResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.createState(
      body,
      (res) => {
        action(res.result!);
        toast.success(`${res.message}`);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  getExpenseById(
    body: { id: number },
    action: (res: GetExpensesByIDResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getExpenseById(
      body,
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  updateExpense(
    body: EditExpensesReq,
    action: (res: EditExpensesResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.updateExpense(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  deleteExpense(
    body: { expenseId: number },
    action: (res: EditExpensesResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.deleteExpense(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
}
