import React from "react";
import { store } from "../../../data/storeMain";
import { InputComponent, InputType } from "../../components/inputs";
import "react-datepicker/dist/react-datepicker.css";
import { MainButton, MainButtonType } from "../../components/button";
import { SelectComponent } from "../../components/select_input";
import { RouteComponentProps, withRouter } from "react-router";
import { AppRoutes } from "../../../core/constants";
import { UploadController } from "../../../controllers/upload_media/upload_media";
import SimpleReactValidator from "simple-react-validator";
import { TeamController } from "../../../controllers/team/team_controller";
import { LocalDataSources } from "../../../data/local_datasources";
import { toast } from "react-toastify";
import {
  AppTask,
  Manager,
} from "../../../data/models/responses/team/get_by_id_res";
import { User } from "../../../data/models/responses/team/team_search_res";
import { UpdateTeamReq } from "../../../data/models/requests/team/update_team_req";
import {
  $ReplaceRoute,
  AccessPermition,
  UserRoles,
  getUserTitle,
  isTokenInHttpAuth,
  convertLabEquipToTree,
  // convertEquipsIdsToIds,
  filterEqipmetsNotExistInLabs,
  convertLabEquipsToNewTree,
  convertEquipsToUncategorized,
} from "../../../core/utils";
import { HTTP } from "../../../core/http_common";
// import { InputLabel } from "../../components/input_label";
import { ITreeMenuData } from "../../components/TreeMenu";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import NewTreeMenu, { TreeOptionType } from "../../components/NewTreeMenu";
import DOMPurify from "dompurify";
import { Button } from "@radix-ui/themes";
import { BaseButton } from "@fluentui/react";
type StateType = {
  id: number;
  title: string;
  description: string;
  addedManagersId: string[];
  addedUsersId: string[];
  addedResearchesId: number[];
  deletedManagersId: string[];
  deletedUsersId: string[];
  deletedResearchesId: number[];
  selectedResearchs: Array<{ label: string; value: number }>;
  selectedManagers: Array<{ label: string; value: string }>;
  selectedUsers: Array<{ label: string; value: string }>;
  listManagers: Array<{ label: string; value: string } | {}>;
  listUsers: Array<{ label: string; value: string } | {}>;
  listResearch: Array<{ label: string; value: number } | {}>;
  loading: boolean;
  searchLoading: boolean;
  loadingMode: string;
  Managers: Manager[];
  User: User[];
  researches: AppTask[];

  listLaboratories: ITreeMenuData[];
  unCheckedValues: string[];
  selectedLabsEquips: string[];
  selectedLabEquipsId: string[];
  mainLabsEquips: string[];

  listLabsEquips: TreeOptionType[];
  defaultLabs: TreeOptionType[];
  defaultEquips: TreeOptionType[];
  mainLabs: number[];
  mainEquips: number[];
  selectedEquipsId: number[];
  selectedLabsId: number[];
};
interface RouteParams {
  id: string;
}
class TeamPageEdit extends React.Component<RouteComponentProps<RouteParams>> {
  RoleUser = store.getState().userRole;
  date = new Date();
  UploadController = new UploadController();
  validator = new SimpleReactValidator({
    className: "text-danger",
  });
  controller = new TeamController();
  local: LocalDataSources = new LocalDataSources();
  state: StateType = {
    mainEquips: [],
    selectedLabsId: [],
    selectedEquipsId: [],
    mainLabs: [],
    unCheckedValues: [],
    addedManagersId: [],
    addedResearchesId: [],
    addedUsersId: [],
    deletedManagersId: [],
    id: 0,
    title: "",
    description: "",
    loadingMode: "",
    deletedUsersId: [],
    deletedResearchesId: [],
    selectedManagers: [],
    selectedUsers: [],
    listManagers: [],
    listUsers: [],
    listResearch: [],
    loading: false,
    searchLoading: false,
    Managers: [],
    User: [],
    researches: [],
    selectedResearchs: [],

    listLaboratories: [],
    selectedLabsEquips: [],
    selectedLabEquipsId: [],
    mainLabsEquips: [],

    defaultLabs: [],
    defaultEquips: [],
    listLabsEquips: [],
  };
  componentDidMount() {
    if (!isTokenInHttpAuth(HTTP)) return;
    this.setState({
      searchLoading: true,
    });
    this.controller.TeamSearch(
      (res) => {
        const __labs = convertLabEquipsToNewTree(res.laboratories);
        const _uncategorizedEquips: TreeOptionType | false =
          res.equipments.length > 0
            ? convertEquipsToUncategorized(res.equipments)
            : false;
        const __listLabsEquips: TreeOptionType[] = _uncategorizedEquips
          ? [_uncategorizedEquips, ...__labs]
          : __labs;

        const _labs = res.laboratories
          ? convertLabEquipToTree(res.laboratories as any)
          : [];

        const _equipmentOnlyAccess = filterEqipmetsNotExistInLabs(
          res.laboratories as any,
          res.equipments
        );

        if (_equipmentOnlyAccess !== null) {
          _labs.unshift(_equipmentOnlyAccess);
        }

        this.setState({
          listLabsEquips: __listLabsEquips,
          listLaboratories: _labs,
          unCheckedValues: _equipmentOnlyAccess?.value
            ? [_equipmentOnlyAccess.value]
            : [],
          listManagers: res.managers
            ? res.managers.map((item: any) => {
                const _userTitle = getUserTitle(item);
                return {
                  label: _userTitle,
                  value: item.userId,
                  secondary: item.invitationStatus === 1 ? "Pending" : null,
                };
              })
            : [],
          listResearch: res.researches
            ? res.researches.map((item) => {
                return { label: item.title, value: item.id };
              })
            : [],
          listUsers: res.users
            ? res.users.map((item: any) => {
                const _userTitle = getUserTitle(item);
                return {
                  label: _userTitle,
                  value: item.id,
                  secondary: item.invitationStatus === 1 ? "Pending" : null,
                };
              })
            : [],
        });
        this.controller.getByIdTeam(
          {
            teamId: parseInt(this.props.match.params.id),
          },
          (_res) => {
            document.title = `Edit Team | ${_res.title} | Radvix`;
            const _teamLabs = _res.laboratories.filter((__l) =>
              res.laboratories.some((_ll) => _ll.id === __l.id)
            );
            const _teamEquips = _res.equipments.filter((__eq) =>
              res.equipments.some((_eq) => _eq.id === __eq.id)
            );
            const __teamEquips = _teamEquips.filter((_eq) => {
              const _isEqInAnyLabsEquips = _res.laboratories.some((_lab) =>
                _lab.equipments.some((_eqId) => _eqId.id === _eq.id)
              );
              return !_isEqInAnyLabsEquips;
            });

            const _defaultLabs = convertLabEquipsToNewTree(_res.laboratories);
            const _defaultEquips = _res.equipments.map((_eq) => {
              return {
                label: _eq.title,
                value: _eq.id,
              };
            });

            const _selectedManagers = _res.managers.map((item: any) => {
              const _userTitle = getUserTitle(item);
              return {
                label: _userTitle,
                value: item.userId,
                secondary: item.invitationStatus === 1 ? "Pending" : null,
              };
            });

            const _selectedUsers = _res.users.map((item: any) => {
              const _userTitle = getUserTitle(item);
              return {
                label: _userTitle,
                value: item.id,
                secondary: item.invitationStatus === 1 ? "Pending" : null,
              };
            });
            const _selectedResearchs = _res.researches.map((item: any) => {
              return {
                label: item.title,
                value: item.id,
              };
            });
            // const _selectedEquips = _res.equipments.map((_item) => `Equip${AppConstants.divide}${_item.id.toString()}`);

            this.setState({
              mainLabs: _teamLabs.map((_l) => _l.id),
              mainEquips: __teamEquips.map((_eq) => _eq.id),
              defaultLabs: _defaultLabs,
              defaultEquips: _defaultEquips,
              title: _res.title,
              description: _res.description,
              Managers: _res.managers,
              selectedManagers: _selectedManagers,
              selectedResearchs: _selectedResearchs,
              researches: _res.researches,
              selectedUsers: _selectedUsers,
              User: _res.users,

              mainLabsEquips: __teamEquips,
              searchLoading: false,
            });
          },
          (err) => {
            this.setState({
              searchLoading: false,
            });
          }
        );
      },
      (err) => {
        this.setState({
          searchLoading: false,
        });
      }
    );
  }
  handleChange(target: string, val: any) {
    const sanitizedValue = DOMPurify.sanitize(val);
    this.setState({
      [target]: sanitizedValue,
    });
  }
  handelChangeSelect(
    target: string,
    e: Array<{ label: string; value: number }>
  ) {
    this.setState({ [target]: e.map((item) => item.value) });
  }

  UpdateTeam() {
    if (this.validator.allValid()) {
      this.setState({
        loading: true,
        loadingMode: "Updating..",
      });
      const __addedEquips = this.state.selectedEquipsId.filter(
        (_eqId) => !this.state.mainEquips.includes(_eqId)
      );
      const __removedEquips = this.state.mainEquips.filter(
        (_eqId) => !this.state.selectedEquipsId.includes(_eqId)
      );
      const __addedLabs = this.state.selectedLabsId.filter(
        (_labId) => !this.state.mainLabs.includes(_labId)
      );
      const __removedLabs = this.state.mainLabs.filter(
        (_labId) => !this.state.selectedLabsId.includes(_labId)
      );
      const _removedLabEquips = this.state.mainLabsEquips.filter(
        (_labeqip) => !this.state.selectedLabEquipsId.includes(_labeqip)
      );

      let _removedManager = this.state.Managers.filter((_manager) => {
        // aya in manager to selectedManagers hast? agar hast return false
        return !this.state.selectedManagers.find(
          (s_manager) => s_manager.value === _manager.userId
        );
      }).map((item) => item.userId);
      let _addedManager = this.state.selectedManagers
        .filter((s_manager) => {
          // aya in selectedManager to Managers hast? agar nist return true
          return !this.state.Managers.find(
            (_manager) => _manager.userId === s_manager.value
          );
        })
        .map((_item) => _item.value);

      let _removedUsers = this.state.User.filter((_user) => {
        return !this.state.selectedUsers.find(
          (s_user) => s_user.value === _user.id
        );
      }).map((item) => item.id);
      let _addedUsers = this.state.selectedUsers
        .filter((s_user) => {
          return !this.state.User.find((_user) => _user.id === s_user.value);
        })
        .map((_item) => _item.value);

      let _removedResearchs = this.state.researches
        .filter((_re) => {
          return !this.state.selectedResearchs.find(
            (s_re) => s_re.value === _re.id
          );
        })
        .map((item) => item.id);
      let _addedResearchs = this.state.selectedResearchs
        .filter((s_re) => {
          return !this.state.researches.find(
            //@ts-ignore
            (_re) => _re.id === s_re.value
          );
        })
        .map((_item) => _item.value);

      // const _removedLabsEquips = this.state.mainLabsEquips
      //   .filter((_team) => {
      //     return this.state.selectedLabsEquips.findIndex((s_team) => s_team === _team) === -1
      //   })

      // const _addedLabsEquips = this.state.selectedLabsEquips
      //   .filter((_team) => {
      //     return this.state.mainLabsEquips.findIndex((s_team) => s_team === _team) === -1
      //   })

      // const _deletedLaboratoriesId = _removedLabsEquips
      //   .filter((item) => !isNaN(Number(item)))
      //   .map(item => +item)

      // const _addedLaboratoriesId = _addedLabsEquips
      //   .filter((item) => !isNaN(Number(item)))
      //   .map(item => +item)

      // let _deletedEquipmentsId: any[] = _removedLabsEquips
      //   .filter((item) => isNaN(Number(item)))
      // _deletedEquipmentsId = convertEquipsIdsToIds(_deletedEquipmentsId)
      // _deletedEquipmentsId = _deletedEquipmentsId.map(item => +item)

      // let _addedEquipmentsId: any[] = _addedLabsEquips
      //   .filter((item) => isNaN(Number(item)))
      // _addedEquipmentsId = convertEquipsIdsToIds(_addedEquipmentsId)
      // _addedEquipmentsId = _addedEquipmentsId.map(item => +item)

      const body: UpdateTeamReq = {
        id: parseInt(this.props.match.params.id),
        title: this.state.title,
        description: this.state.description,

        addedUsersId: _addedUsers,
        deletedUsersId: _removedUsers,

        addedManagersId: _addedManager,
        deletedManagersId: _removedManager,

        addedResearchesId: _addedResearchs,
        deletedResearchesId: _removedResearchs,

        // -----------------------------------

        addedLaboratoriesId: __addedLabs,
        deletedLaboratoriesId: __removedLabs,

        addedEquipmentsId: __addedEquips,
        deletedEquipmentsId: __removedEquips,
        deletedLabEquipmentId: _removedLabEquips,
      };

      this.controller.updateTeam(
        body,
        (res) => {
          this.setState({
            loading: false,
            loadingMode: "",
          });
          this.props.history.push(
            $ReplaceRoute(
              `${AppRoutes.team_profile.replace(
                ":id",
                res.id?.toString() ?? ""
              )}`
            )
          );
        },
        (err) => {
          this.setState({
            loading: false,
            loadingMode: "",
          });
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  render() {
    return (
      <div className="card responsive-div">
        <div
          className="d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
        border-gray-400"
        >
          <div className="d-flex align-items-center">
          <BaseButton
              className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button"
              style={{fontWeight:"400", height:"19px"}}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className="fw-medium fs-15 mb-0 color-gray-1000">
              {AccessPermition(this.RoleUser, [
                UserRoles.Admin,
                UserRoles.L1Client,
                UserRoles.L1User,
              ])
                ? "Edit Team"
                : "Edit Subteam"}
            </h2>
          </div>
        </div>
        <div className="p-3 overflow-auto">
          <div className="row">
            <div className="">
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div className="item">
                    <InputComponent
                      className="form-control"
                      disabled={this.state.searchLoading}
                      type={InputType.text}
                      label={
                                      AccessPermition(this.RoleUser, [
                                        UserRoles.Admin,
                                        UserRoles.L1Client,
                                        UserRoles.L1User,
                                      ])
                                        ? "Team name*"
                                        : "Subteam name*"
                                    }
                      placeholder="Enter your team name"
                      popQuestion="You can type in a name of your team. You can also edit this at anytime."
                      onChange={(e) => {
                        const value = e.target.value.replace(
                          /[^A-Za-z0-9\s]/g,
                          ""
                        );
                        this.handleChange("title", value);
                      }}
                      inValid={this.validator.message(
                        "Team Name",
                        this.state.title,
                        "required"
                      )}
                      value={this.state.title}
                    ></InputComponent>
                  </div>
                  <div>
                    <div className="col-lg-12 mb-3">                     
                      <InputComponent
                      type={InputType.textarea}
                      disabled={this.state.searchLoading}
                      label="Team description"
                      //optional="optional"
                      height="132px"
                      popQuestion="You can write a description for your team. For example, what is the main focus of this team and what are the key objectives. You can always edit this later."
                      className="mt-1"
                      value={this.state.description}
                      onChange={(e) => {
                        this.handleChange("description", e.target.value);
                      }}
                    ></InputComponent>
                    </div>
                  </div>
                  {/* <div className="item">
                    <InputComponent
                      type={InputType.textarea}
                      disabled={this.state.searchLoading}
                      label={
                                      AccessPermition(this.RoleUser, [
                                        UserRoles.Admin,
                                        UserRoles.L1Client,
                                        UserRoles.L1User,
                                      ])
                                        ? "Team description"
                                        : "Subteam description"
                                    }
                      //optional="optional"
                      height="132px"
                      popQuestion="You can write a description for your team. For example, what is the main focus of this team and what are the key objectives. You can always edit this later."
                      className="mt-1"
                      onChange={(e) => {
                        this.handleChange("description", e.target.value);
                      }}
                      value={this.state.description}
                    ></InputComponent>
                  </div> */}
                  {AccessPermition(this.RoleUser, [
                UserRoles.Admin,
                UserRoles.L1Client,
                UserRoles.L1User,
              ]) ? (
                <>
                  <div className="row mt-1">
                    <div className="col-lg-12 mb-3">
                      <label className="color-gray-600 fs-15 me-2 mb-1">
                        Assign managers*
                      </label>
                      {/* <img
                    className="text-primary cursor-pointer"
                    src="/Images/icons/info-circled.svg"
                    alt="info"
                    title="Team managers have the power to add more members to a team as well as updating team information on this page."
                  />   */}
                      <div className="item">
                      <div className="item">
                 <SelectComponent
                      items={this.state.listManagers}
                      disabled={this.state.searchLoading}
                      TextItem="name"
                      ValueItem="id"
                      className="my-1"
                      placeholder="Click to see the list…"
                      //label="Assign managers"
                      popQuestion="Team managers have the power to add more members to a team as well as updating team information on this page."

                      optional="optional"
                      defaultValue={this.state.selectedManagers}
                      onChange={(e) => {
                        const _cantFindYourSelf = !e.find(
                          (item: { label: string; value: string }) =>
                            item.value === this.local.getUserId()
                        );

                        if (_cantFindYourSelf) {
                          return toast.error(
                            "You can't remove yourself from team"
                          );
                        }

                        const currentSelectedManagers = this.state.selectedManagers || [];
                        const currentSelectedUsers = this.state.selectedUsers || [];

                        const removedManagers = currentSelectedManagers.filter(
                          (manager) =>
                            !e.some(
                              (selectedManager: { value: string }) =>
                                selectedManager.value === manager.value
                            )
                        );

                        const updatedUsers = currentSelectedUsers.filter(
                          (user) =>
                            !removedManagers.some(
                              (manager) => manager.value === user.value
                            )
                        );

                        const selectedManagers = e.filter((selectedManager: { value: string; }) =>
                          !currentSelectedManagers.some(
                            (manager) => manager.value === selectedManager.value
                          )
                        );

                        const updatedUsersWithManagers = [
                          ...updatedUsers,
                          ...selectedManagers.map((manager: { label: any; value: any; }) => ({
                            label: manager.label,
                            value: manager.value,
                          })),
                        ];

                        this.setState({
                          selectedManagers: e,
                          selectedUsers: updatedUsersWithManagers,
                        });
                      }}
                      isMulti
                    ></SelectComponent>
                  </div>
                
                      </div>
                    </div>
                  </div>
                  </>
              ) : (
                ""
              )}
                  <div className="row mt-1">
                    <div className="col-lg-12 mb-3">
                      <label className="color-gray-600 fs-15 me-2 mb-1">
                        Assign members*
                      </label>
                      {/* <img
                    className="text-primary cursor-pointer"
                    src="/Images/icons/info-circled.svg"
                    alt="info"
                    title="Here you can select the members that you would like to be added to this subteam. Please note that you can add more members later."
                  />   */}
                      <div className="item">
                      <SelectComponent
                  items={this.state.listUsers}
                  defaultValue={this.state.selectedUsers}
                  disabled={this.state.searchLoading}
                  TextItem="name"
                  ValueItem="id"
                  className="my-1"
                  placeholder="Click to see the list…"
                  // label={
                  //   "Assign members"
                  // }
                  popQuestion={"Here you can select the members that you would like to be added to this subteam. Please note that you can add more members later."}

                  onChange={(e, _actionMeta) => {
                    if (_actionMeta.action === "remove-value") {
                      toast.warning(`Can't remove member`)
                      return
                    }

                    const _cantFindYourSelf = !e.find(
                      (item: { label: string; value: string }) =>
                        item.value === this.local.getUserId()
                    );

                    if (_cantFindYourSelf) {
                      return toast.error("You can't remove yourself from team");
                    }
                    this.setState({
                      selectedUsers: e,
                    });
                    // this.handelChangeSelect("addedUsersId", e);
                  }}
                  isMulti
                ></SelectComponent>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-lg-12 mb-3">
                      <label className="color-gray-600 fs-15 me-2 mb-1">
                        {AccessPermition(this.RoleUser, [
                          UserRoles.Admin,
                          UserRoles.L1Client,
                          UserRoles.L1User,
                        ])
                          ? "Assign this team to laboratories (Equipment)*"
                          : "Assign this subteam to laboratories (Equipment)*"}
                      
                      {/* <img
                    className="text-primary cursor-pointer"
                    src="/Images/icons/info-circled.svg"
                    alt="info"
                    style={{paddingLeft:"5px"}}
                    title="When you assign a team to a laboratory or only individual equipment, all of the existing or future members and managers of that team will have access to those laboratories and selected equipment."
                  /> */}
                  </label> 
                      <div className="item">
                        <NewTreeMenu
                          disabled={this.state.searchLoading}
                          placeholder="Select laboratories or equipment"
                          popQuestion="When you assign a team to a laboratory or only individual equipment, all of the existing or future members and managers of that team will have access to those laboratories and selected equipment."
                          // label={AccessPermition(_userRole, [
                          //   UserRoles.Admin,
                          //   UserRoles.L1Client,
                          //   UserRoles.L1User,
                          // ])
                          //   ? "Assign this team to laboratories (Equipment)"
                          //   : "Assign this subteam to laboratories (Equipment)"}
                          onChange={(_labs, _equips) => {
                            // console.log({ _teams, _users })
                            this.setState({
                              selectedLabsId: _labs,
                              selectedEquipsId: _equips,
                              selectedLabsEquips: [..._labs, ..._equips],
                            });
                          }}
                          loading={this.state.searchLoading}
                          // onSele ctedItems={() => {

                          // }}
                          
                          options={this.state.listLabsEquips}
                          defaultChilds={this.state.defaultEquips}
                  defaultParents={this.state.defaultLabs}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="row mt-1">
                    <div className="col-lg-12 mb-3">
                      <label className="color-gray-600 fs-15 me-2 mb-1">
                        Priority*
                      </label>
                      <div className="d-flex border-gray-400 border soft-radios p-1 rounded-px-4 w-100">
                        <div className="w-33_3">
                          <input
                            type="radio"
                            className="btn-check"
                            name="options"
                            id="low"
                            autoComplete="off"
                          />
                          <label
                            className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                            htmlFor="low"
                          >
                            Low
                          </label>
                        </div>
                        <div className="w-33_3">
                          <input
                            type="radio"
                            className="btn-check"
                            name="options"
                            id="medium"
                            autoComplete="off"
                            checked
                          />
                          <label
                            className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                            htmlFor="medium"
                          >
                            Medium
                          </label>
                        </div>
                        <div className="w-33_3">
                          <input
                            type="radio"
                            className="btn-check"
                            name="options"
                            id="high"
                            autoComplete="off"
                          />
                          <label
                            className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                            htmlFor="high"
                          >
                            High
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="p-3">
                  <MainButton
                    className="btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2 responsive-Button-width"
                    icon={
                      <img
                        src="/images/icons/file-plus.svg"
                        className="w-px-13"
                      />
                    }
                    onClick={() => {
                      this.UpdateTeam();
                    }}
                    loading={this.state.loading || this.state.searchLoading}
                    disabled={this.state.loading || this.state.searchLoading}
                  >
                    {AccessPermition(this.RoleUser, [
                      UserRoles.Admin,
                      UserRoles.L1Client,
                      UserRoles.L1User,
                    ])
                      ? "Update my team"
                      : "Update my subteam"}
                  </MainButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      // <div className="container-fluid research new-research">
      //   <div className="row"></div>
      //   <div className="col-12 box-content p-3">
      //     <h5 className="b-title d-flex align-items-center">
      //       <IoIosArrowDropleftCircle
      //         className="me-3 textSecondary2 cursorPointer"
      //         size="1.5em"
      //         onClick={() => {
      //           window.history.back();
      //         }}
      //       />{" "}
      //       {AccessPermition(this.RoleUser, [
      //         UserRoles.Admin,
      //         UserRoles.L1Client,
      //         UserRoles.L1User,
      //       ])
      //         ? "Edit my research team"
      //         : "Edit my research subteam"}
      //     </h5>
      //     <div className="form row">
      //       <div className="col-md-6 left beforeNoneMd">
      //         <div className="item">
      //           <InputComponent
      //             type={InputType.text}
      //             disabled={this.state.searchLoading}
      //             label={
      //               AccessPermition(this.RoleUser, [
      //                 UserRoles.Admin,
      //                 UserRoles.L1Client,
      //                 UserRoles.L1User,
      //               ])
      //                 ? "Team name"
      //                 : "Subteam name"
      //             }
      //             popQuestion="You can type in a name of your team. You can also edit this at anytime."

      //             onChange={(e) => {
      //               const value = e.target.value.replace(/[^A-Za-z0-9\s]/g, '');
      //               this.handleChange("title", value);
      //             }}
      //             inValid={this.validator.message(
      //               "Team Name",
      //               this.state.title,
      //               "required"
      //             )}
      //             value={this.state.title}
      //           ></InputComponent>
      //         </div>

      //         <div className="item">
      //           <InputComponent
      //             type={InputType.textarea}
      //             disabled={this.state.searchLoading}
      //             label={
      //               AccessPermition(this.RoleUser, [
      //                 UserRoles.Admin,
      //                 UserRoles.L1Client,
      //                 UserRoles.L1User,
      //               ])
      //                 ? "Team description"
      //                 : "Subteam description"
      //             }
      //             optional="optional"
      //             popQuestion="You can write a description for your team. For example, what is the main focus of this team and what are the key objectives. You can always edit this later."

      //             className="mt-1"
      //             onChange={(e) => {
      //               const value = e.target.value.replace(/[^A-Za-z0-9\s.]/g, '');
      //               this.handleChange("description", value);
      //             }}
      //             value={this.state.description}
      //           ></InputComponent>
      //         </div>
      //         {AccessPermition(this.RoleUser, [
      //           UserRoles.Admin,
      //           UserRoles.L1Client,
      //           UserRoles.L1User,
      //         ]) ? (
      //           <>
      //             <div className="item">
      //               <SelectComponent
      //                 items={this.state.listManagers}
      //                 disabled={this.state.searchLoading}
      //                 TextItem="name"
      //                 ValueItem="id"
      //                 className="my-1"
      //                 placeholder="Click to see the list…"
      //                 label="Assign managers"
      //                 popQuestion="Team managers have the power to add more members to a team as well as updating team information on this page."

      //                 optional="optional"
      //                 defaultValue={this.state.selectedManagers}
      //                 onChange={(e) => {
      //                   const _cantFindYourSelf = !e.find(
      //                     (item: { label: string; value: string }) =>
      //                       item.value === this.local.getUserId()
      //                   );

      //                   if (_cantFindYourSelf) {
      //                     return toast.error(
      //                       "You can't remove yourself from team"
      //                     );
      //                   }

      //                   const currentSelectedManagers = this.state.selectedManagers || [];
      //                   const currentSelectedUsers = this.state.selectedUsers || [];

      //                   const removedManagers = currentSelectedManagers.filter(
      //                     (manager) =>
      //                       !e.some(
      //                         (selectedManager: { value: string }) =>
      //                           selectedManager.value === manager.value
      //                       )
      //                   );

      //                   const updatedUsers = currentSelectedUsers.filter(
      //                     (user) =>
      //                       !removedManagers.some(
      //                         (manager) => manager.value === user.value
      //                       )
      //                   );

      //                   const selectedManagers = e.filter((selectedManager: { value: string; }) =>
      //                     !currentSelectedManagers.some(
      //                       (manager) => manager.value === selectedManager.value
      //                     )
      //                   );

      //                   const updatedUsersWithManagers = [
      //                     ...updatedUsers,
      //                     ...selectedManagers.map((manager: { label: any; value: any; }) => ({
      //                       label: manager.label,
      //                       value: manager.value,
      //                     })),
      //                   ];

      //                   this.setState({
      //                     selectedManagers: e,
      //                     selectedUsers: updatedUsersWithManagers,
      //                   });
      //                 }}
      //                 isMulti
      //               ></SelectComponent>
      //             </div>
      //           </>
      //         ) : (
      //           ""
      //         )}
      //       </div>
      //       <div className="col-md-6 right">
      //         <div className="item">
      //           <SelectComponent
      //             items={this.state.listUsers}
      //             defaultValue={this.state.selectedUsers}
      //             disabled={this.state.searchLoading}
      //             TextItem="name"
      //             ValueItem="id"
      //             className="my-1"
      //             placeholder="Click to see the list…"
      //             label={
      //               "Assign members"
      //             }
      //             popQuestion={"Here you can select the members that you would like to be added to this subteam. Please note that you can add more members later."}

      //             onChange={(e, _actionMeta) => {
      //               if (_actionMeta.action === "remove-value") {
      //                 toast.warning(`Can't remove member`)
      //                 return
      //               }

      //               const _cantFindYourSelf = !e.find(
      //                 (item: { label: string; value: string }) =>
      //                   item.value === this.local.getUserId()
      //               );

      //               if (_cantFindYourSelf) {
      //                 return toast.error("You can't remove yourself from team");
      //               }
      //               this.setState({
      //                 selectedUsers: e,
      //               });
      //               // this.handelChangeSelect("addedUsersId", e);
      //             }}
      //             isMulti
      //           ></SelectComponent>
      //         </div>
      //         <div className="item">
      //           <NewTreeMenu
      //             label={AccessPermition(this.RoleUser, [
      //               UserRoles.Admin,
      //               UserRoles.L1Client,
      //               UserRoles.L1User,
      //             ])
      //               ? "Assign this team to laboratories (Equipment)"
      //               : "Assign this subteam to laboratories (Equipment)"}
      //             onChange={(_labs, _equips) => {
      //               this.setState({
      //                 selectedLabsId: _labs,
      //                 selectedEquipsId: _equips,
      //                 selectedLabsEquips: [..._labs, ..._equips]
      //               })
      //             }}
      //             disabled={this.state.searchLoading}
      //             loading={this.state.loading || this.state.searchLoading}
      //             popQuestion="When you assign a team to a laboratory or only individual equipment, all of the existing or future members and managers of that team will have access to those laboratories and selected equipment."
      //             // onSele ctedItems={() => {
      //             placeholder="Select laboratories or equipment"
      //             // }}
      //             options={this.state.listLabsEquips}
      //             defaultChilds={this.state.defaultEquips}
      //             defaultParents={this.state.defaultLabs}
      //           // forceSelectedChilds={[this.state.creatorUser, _userId]}
      //           />
      //         </div>

      //         {/* <div className="item">

      //           <InputLabel
      //             popQuestion={
      //               AccessPermition(this.RoleUser, [
      //                 UserRoles.Admin,
      //                 UserRoles.L1Client,
      //                 UserRoles.L1User,
      //               ])
      //                 ? "Assign Team To Labs (Equips):"
      //                 : "Assign Subteam To Labs (Equips):"
      //             }
      //             label={
      //               AccessPermition(this.RoleUser, [
      //                 UserRoles.Admin,
      //                 UserRoles.L1Client,
      //                 UserRoles.L1User,
      //               ])
      //                 ? "Assign Team To Labs (Equips):"
      //                 : "Assign Subteam To Labs (Equips):"
      //             }
      //           >
      //             <TreeMenu
      //               data={this.state.listLaboratories}
      //               defaultValues={this.state.mainLabsEquips}
      //               unCheckedValues={this.state.unCheckedValues}
      //               parentLabel="Lab"
      //               childLabel="Equip"
      //               onSelectedItems={(values) => {
      //                 this.setState({
      //                   selectedLabsEquips: values
      //                 })
      //               }}
      //             />
      //           </InputLabel>

      //         </div> */}
      //         {/* <div className="item">
      //           <SelectComponent
      //             items={this.state.listResearch}
      //             defaultValue={this.state.selectedResearchs}
      //             TextItem="name"
      //             ValueItem="id"
      //             className="my-2"
      //             placeholder="Click to see the list…"
      //             label={
      //               AccessPermition(this.RoleUser, [
      //                 UserRoles.Admin,
      //                 UserRoles.L1Client,
      //                 UserRoles.L1User,
      //               ])
      //                 ? "Assign Team To Projects:"
      //                 : "Assign Subteam To Projects:"
      //             }
      //             popQuestion={
      //               AccessPermition(this.RoleUser, [
      //                 UserRoles.Admin,
      //                 UserRoles.L1Client,
      //                 UserRoles.L1User,
      //               ])
      //                 ? "Assign Team To Projects:"
      //                 : "Assign Subteam To Projects:"
      //             }
      //             optional="optional"
      //             onChange={(e) => {
      //               this.setState({
      //                 selectedResearchs: e,
      //               });
      //               // this.handelChangeSelect("addedResearchesId", e);
      //             }}
      //             isMulti
      //           ></SelectComponent>
      //         </div> */}
      //       </div>
      //       <div className="col-12 d-flex flex-column justify-content-center align-items-center my-4">
      //         <MainButton
      //           type={MainButtonType.dark}
      //           children={AccessPermition(this.RoleUser, [
      //             UserRoles.Admin,
      //             UserRoles.L1Client,
      //             UserRoles.L1User,
      //           ])
      //             ? "Update my team"
      //             : "Update my subteam"}
      //           borderRadius="50px"
      //           fontSize="18px"
      //           className="mx-2 px-3"
      //           minHeight="43px"
      //           minWidth="136px"
      //           onClick={() => {
      //             this.UpdateTeam();
      //           }}
      //           loading={this.state.loading}
      //           disabled={this.state.loading}
      //         ></MainButton>
      //         <span className="mt-2 textSecondary">
      //           {this.state.loadingMode}
      //         </span>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}
export default withRouter(TeamPageEdit);
