import { toast } from "react-toastify";
import { RemoteGroup } from "../../data/remotes/group/remote_group";
import { AnyAaaaRecord } from "dns";
export class GroupController {
  remote = new RemoteGroup();

  createGroup(
    body: any,
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.createGroup(
      body,
      (res) => {
        toast.success(`${res.data.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
 
  
  groupSearch(action: (res: any) => any, error: (err:any) => any) {
    this.remote.groupSearch(
      (res) => {
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err)
      }
    );
  }

  updateGroup(
    body: any,
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.updateGroup(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
 
  getGroupById(
    body: { id: number },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.getGroupById(
      body,
      (res) => {
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  deleteGroup(
    body: { groupId: number },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.deleteGroup(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
}
