import React, { useState } from 'react';
// import { BiChevronRight, BiChevronsRight } from "react-icons/bi";
import ReactPaginate from 'react-paginate';
import { RouteComponentProps, withRouter } from 'react-router';
import { DataController } from '../../../controllers/data/data_controller';
import { AppRoutes } from '../../../core/constants';
import { HTTP } from '../../../core/http_common';
import {
  $ReplaceRoute,
  // AccessPermition,
  getUserRole,
  isTokenInHttpAuth,
  UserRoles,
} from '../../../core/utils';
import { DataList } from '../../../data/models/responses/data/get_all_data_res';
import { store } from '../../../data/storeMain';
import { MainButton, MainButtonType } from '../../components/button';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
import NewTable from '../../components/NewTable/NewTable';
import { InputIcon } from '../../components/search_box';
import { SelectComponent } from '../../components/select_input';
// import SpinnerLoader from "../../components/spinnerLoader";
// import DataCollectionTable from "./component/data_collection_table";
import { Collapse } from 'react-collapse';
import NewDataCollectionTable from './component/NewDataCollectionTable';
import Skeleton from 'react-loading-skeleton';
import { nanoid } from 'nanoid';
import debounce from 'lodash.debounce';
import Highlighter from 'react-highlight-words';
import { MdAdd } from 'react-icons/md';
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io';
import { RxMagnifyingGlass } from 'react-icons/rx';
import PhoneSearch from '../../components/PhoneSearch';
import { Link } from 'react-router-dom';
import { SortType } from '../../../core/number_extentions';
import FilterSection from '../../components/FilterSection/filterSection';
import DOMPurify from 'dompurify';
import RadixTable from '../../components/NewRadixTable/RadixTable';
import { Table } from '@radix-ui/themes';
import Pagination from '../../components/NewRadixTable/RadixPagination';
import DashboardProjectDropdown from '../../components/ActionComponent/DashboardProjectDropdown';
import { ResearchController } from '../../../controllers/research/research_controller';
import { SetResearchId } from '../../../data/storeMain/actions/research_action';
import {
  HeaderTitleAction,
  SetHeaderTitle,
} from '../../../data/storeMain/actions/header_title_action';
type StateType = {
  Datas: DataList[];
  PageNumber: number;
  PageSize: { value: number; label: string };
  PageCount: number;
  TotalCount: number;
  Search: string;
  loading: boolean;
  HeaderName: string;
  SortDirection: SortType;
  showFilterOptions: boolean;
  PageNumberInput: string;
  researhList: any[];
  selectedProject: any;
};
const LEFT_BLOCK_SIZE = '3em';
let unsubscribeMeData: any = undefined;
class DataCollection extends React.Component<RouteComponentProps> {
  RoleUser = store.getState().userRole;
  controller = new DataController();
  researchController = new ResearchController();
  state: StateType = {
    Datas: [],
    PageNumber: 1,
    PageSize: { value: 10, label: '10' },
    PageCount: 0,
    TotalCount: 0,
    Search: '',
    loading: true,
    HeaderName: '',
    SortDirection: SortType.AutoSelect,
    showFilterOptions: false,
    PageNumberInput: '',
    researhList: [],
    selectedProject: { value: 0, label: 'All Projects' },
  };

  constructor(props: any) {
    super(props);
    this.handelChangePageNumber = this.handelChangePageNumber.bind(this);
    this.handleChangeDataSearch = debounce(this.handleChangeDataSearch.bind(this), 500);
    this.handlePageNumberInput = this.handlePageNumberInput.bind(this);
    this.handleGoToPage = this.handleGoToPage.bind(this);
  }
  componentDidMount() {
    document.title = `Data Collection | Radvix`;
    dispatch(SetHeaderTitle('Data'));
    if (!isTokenInHttpAuth(HTTP)) return;
    //
    this.loadResearches();
    this.GetDatas(
      store.getState().ResearchId.value,
      this.state.PageNumber,
      this.state.PageSize.value
    );
    unsubscribeMeData = store.subscribe(() => {
      this.GetDatas(
        store.getState().ResearchId.value,
        this.state.PageNumber,
        this.state.PageSize.value
      );
    });
  }
  // clean up event listener
  componentWillUnmount() {
    if (unsubscribeMeData) unsubscribeMeData();
  }

  loadResearches() {
    this.researchController.getResearches(
      {
        PageNumber: 1,
        PageSize: 100,
        SearchParameter: '',
        columnHeaderName: '',
        sortDirection: SortType.AutoSelect,
      },
      (res) => {
        const researches = res.researchesList?.map(({ id, title }) => ({
          value: id,
          label: title,
        }));
        this.setState({ researhList: researches });
      },
      (err) => {}
    );
  }

  GetDatas(
    researchId: number,
    PageNumber: number,
    PageSize: number,
    Search?: string,
    columnHeaderName?: string,
    sortDirection?: SortType
  ) {
    this.setState({
      loading: true,
    });
    if (sortDirection == undefined || sortDirection == null) {
      sortDirection = SortType.AutoSelect;
    }
    if (columnHeaderName == undefined || columnHeaderName == null) {
      columnHeaderName = '';
    }
    this.controller.getAllData(
      {
        researchId,
        PageNumber,
        PageSize,
        SearchParameter: Search ? Search : this.state.Search,
        columnHeaderName,
        sortDirection,
      },
      (res) => {
        // let _tasks:any[] = [];
        // if(res.dataLists && res.dataLists.length > 0) {
        //   const _userRole = getUserRole();
        //   if(_userRole === UserRoles.L1Client || UserRoles.L1User) {
        //     _tasks = res.dataLists
        //   }
        // }

        if (res === null) {
          this.setState({
            Datas: [],
            PageCount: 0,
            TotalCount: 0,
            loading: false,
          });
          return;
        }
        // if (!res) {
        //   this.setState({
        //     loading: false,
        //   });
        //   return;
        // }
        this.setState({
          Datas: res.dataLists,
          PageCount: Math.ceil(res.count! / this.state.PageSize.value),
          TotalCount: res.count,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
  }

  handleChangeProject(e: any) {
    this.setState({
      selectedProject: e,
    });
    store.dispatch(SetResearchId({ label: e?.label, value: e?.value }));
    // if (e && e.value != 0) {
    //   store.dispatch(SetResearchId({ label: e?.label, value: e?.value }));
    // }
    this.GetDatas(
      e.value,
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.Search,
      '',
      SortType.AutoSelect
    );
  }

  handlePageNumberInput(event: { target: { value: any } }) {
    if (event.target.value > 0) {
      this.setState({ PageNumberInput: event.target.value });
    } else {
      this.setState({ PageNumberInput: '' });
    }
  }
  handleGoToPage() {
    const pageNumber = parseInt(this.state.PageNumberInput, 10);
    if (pageNumber > 0 && pageNumber <= this.state.PageCount) {
      this.handelChangePageNumber({ selected: pageNumber - 1 });
    } else {
      console.log('Invalid page number');
    }
  }
  handelChangePageNumber(e: { selected: number }) {
    this.setState({
      PageNumber: e.selected,
    });
    this.GetDatas(
      this.state.selectedProject,
      e.selected + 1,
      this.state.PageSize.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection
    );
  }
  handelChangePageSize(e: { label: string; value: number }) {
    this.setState({
      PageSize: e,
    });
    this.GetDatas(
      this.state.selectedProject,
      this.state.PageNumber,
      e.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection
    );
  }
  headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    this.setState({ HeaderName: columnHeaderName });
    this.setState({ SortDirection: sortDirection });

    this.GetDatas(
      this.state.selectedProject,
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.Search,
      columnHeaderName,
      sortDirection
    );
  };
  handleChangeDataSearch(e: any) {
    this.GetDatas(
      this.state.selectedProject.value == 0
        ? store.getState().ResearchId.value
        : this.state.selectedProject.value,
      this.state.PageNumber,
      this.state.PageSize.value,
      e.target.value
    );
  }
  handleToggle = () => {
    this.setState({ showFilterOptions: !this.state.showFilterOptions });
  };
  filterClick = (
    selectedFromDate?: Date,
    selectedToDate?: Date,
    status?: number[],
    teamType?: number
  ) => {
    var filterOptions = {
      selectedFromDate: selectedFromDate,
      selectedToDate: selectedToDate,
      status: status ? status[0] : undefined,
      teamType: teamType,
    };
    this.setState({ filterOptions: filterOptions });
    // this.GetResearch(
    //   this.state.PageNumber,
    //   this.state.PageSize.value,
    //   this.state.Search,
    //   this.state.HeaderName,
    //   this.state.SortDirection,
    //   filterOptions
    // );
  };
  render() {
    // const _searchParams = new URLSearchParams(this.props.location.search);
    const _userRole = getUserRole();
    const _splitedParams = this.props.location.search?.split('=');
    const _selectedTaskId = _splitedParams[1];
    const _selectedSubTaskId = _splitedParams[2];

    if (_selectedTaskId && !_selectedSubTaskId) {
      const _researchId = +_userRole === UserRoles.L3User ? this.state.Datas[0]?.researchId : 0;
      const _data: any =
        _userRole === UserRoles.L3User
          ? this.state.Datas[0]?.subAppTasksData || []
          : this.state.Datas;

      return (
        <NewDataCollectionTable
          loading={this.state.loading}
          researchId={_researchId}
          fullData={_data}
          data={_data.find((x: any) => {
            if (_userRole === UserRoles.L3User) return x.subAppTaskId === Number(_selectedTaskId);
            return x.appTaskId === Number(_selectedTaskId);
          })}
          onBack={() => {
            this.props.history.push($ReplaceRoute(AppRoutes.data));
          }}
          taskId={Number(_selectedTaskId)}
        />
      );
    }

    if (_selectedTaskId && _selectedSubTaskId) {
      const _fixedTaskIdParam = _splitedParams[1].split('&')[0];
      const _selectedTaskSubTasks = this.state.Datas.find(
        (x: any) => x.appTaskId === Number(_fixedTaskIdParam)
      );

      return (
        <NewDataCollectionTable
          loading={this.state.loading}
          taskId={Number(_fixedTaskIdParam)}
          fullData={this.state.Datas}
          researchId={_selectedTaskSubTasks?.researchId}
          data={
            // @ts-ignore
            _selectedTaskSubTasks?.subAppTasksData.find(
              (x: any) => x.subAppTaskId === Number(_selectedSubTaskId)
            )
          }
          onBack={() => {
            this.props.history.push($ReplaceRoute(AppRoutes.data));
          }}
        />
      );
    }
    return (
      <div className='card'>
        <div className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3'>
          <div className='d-flex align-items-center'>
            <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>Data List</h2>
          </div>
          <MainButton
            className='btn btn-primary fs-15 py-px-7 new-project-button'
            svg={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='15'
                height='15'
                viewBox='0 0 15 15'
                fill='none'
              >
                <rect width='15' height='15' fill='white' fill-opacity='0.01'></rect>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z'
                  fill='white'
                ></path>
              </svg>
            }
            onClick={() => {
              this.props.history.push($ReplaceRoute(`${AppRoutes.data_new}`));
            }}
            // style={{ height: "35px" }}
          >
            Add new dataset
          </MainButton>
          {/* <a className="btn btn-primary fs-15 py-px-7" href="create-new-proj.html">
              <i className="fa fa-plus me-1"></i> New Project</a> */}
        </div>
        <div className='border-top border-gray-400'>
          <div className='d-flex align-items-center px-3 py-3 w-50'>
            <span className='me-2 text-center text-nowrap'>Active Projects:</span>
            <DashboardProjectDropdown
              id={1}
              items={this.state.researhList}
              onSelect={(e) => {
                this.handleChangeProject(e);
              }}
              selectedItems={store.getState().ResearchId}
            />
          </div>
        </div>
        <div
          className='d-flex flex-xl-row border-top border-gray-400 py-3 flex-column row-gap-xl-0 row-gap-px-16 px-3 pb-3 filters'
          style={{ width: '100%' }}
          id='showFilter'
        >
          <div className='pe-xl-3 border-gray-400 d-sm-block d-flex gap-3' style={{ width: '50%' }}>
            <InputIcon
              className='form-control h-px-35'
              placeholder='Search task'
              chilren={undefined}
              onChange={(e) => {
                this.setState({ Search: e.target.value });
                this.handleChangeDataSearch(e);
              }}
            />
          </div>
        </div>

        <RadixTable
          data={this.state.Datas}
          loading={this.state.loading}
          onHeaderClick={(columnHeaderName: string, sortDirection: SortType) =>
            this.headerClick(columnHeaderName, sortDirection)
          }
          columns={[
            {
              Header: 'Task name',
              accessor: 'appTaskTitle',
              Cell: (_props: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <div className='line-clamp-2'>
                      <i className='fa fa-grip-vertical me-2 color-gray-600 fs-13'></i>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          this.props.history.push(
                            $ReplaceRoute(
                              `${AppRoutes.data_list.replace(
                                ':taskId',
                                _props.appTaskId?.toString() ?? ''
                              )}`
                            )
                          );
                        }}
                        title={_props.appTaskTitle}
                      >
                        {' '}
                        {_props.appTaskTitle}{' '}
                      </span>
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Dataset count',
              accessor: 'dataCount',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3 text-nowrap'>
                    {props.appTaskDataCount === 0 ? '-' : props.appTaskDataCount}
                  </Table.Cell>
                );
              },
            },
            {
              Header: '',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3 text-nowrap'>
                    <div className='d-flex align-items-center gap-3'>
                      <div className='btn-group'>
                        <button
                          type='button'
                          className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                          onClick={(e) => {
                            this.props.history.push(
                              $ReplaceRoute(
                                `${AppRoutes.data_list.replace(
                                  ':taskId',
                                  props.appTaskId?.toString() ?? ''
                                )}`
                              )
                            );
                          }}
                        >
                          <i className='fa fa-eye me-px-6'></i>View Dataset list
                        </button>
                        {/* <button
                          type="button"
                          className="btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={(e) => {
                            this.props.history.push(
                              $ReplaceRoute(
                                `${AppRoutes.data_list.replace(
                                  ":taskId",
                                  props.appTaskId?.toString() ?? ""
                                )}`
                              )
                            );
                          }}
                        ></button> */}
                      </div>
                    </div>
                  </Table.Cell>
                );
              },
            },
          ]}
        />
        {/* <RadixPaginationComponent
        pageCount={this.state.PageCount}
        initialPageSize={this.state.PageSize.value}
        onPageSizeChange={this.handelChangePageSize}
        onPageChange={this.handelChangePageNumber}
        onGoToPage={this.handleGoToPage}
        initialPageNumber={this.state.PageNumber}
      /> */}
        {this.state.PageCount > 1 && (
          <Pagination
            pageCount={this.state.PageCount}
            onPageChange={this.handelChangePageNumber}
            onPageSizeChange={(e: any) => {
              this.handelChangePageSize({
                label: e.target.value,
                value: parseInt(e.target.value),
              });
            }}
            pageInput={this.state.PageNumberInput}
            handlePageNumberInput={this.handlePageNumberInput}
          />
        )}
      </div>
    );
  }
}

const CollapsableDataItem: React.FC<{
  item: any;
  searchValue?: string;
  onGoTask: (id: number) => void;
  onGoEdit: (id: number) => void;
  onGoData: (id: number, subTaskId?: number) => void;
  onGoDiscussion?: (_taskId: number, _subTaskId?: number | null) => void;
}> = ({ item, onGoDiscussion, onGoEdit, onGoTask, onGoData, searchValue }) => {
  const [isCollapsed, setCollapse] = useState(false);

  return (
    <div className='d-flex flex-column notifItem'>
      <div
        className='align-items-center w-100 responsiveTableDataItem'
        style={{
          display: 'grid',
          gridTemplateColumns: `${LEFT_BLOCK_SIZE} 1fr`,
        }}
      >
        <div className='d-flex justify-content-center' style={{ width: LEFT_BLOCK_SIZE }}>
          {item.subAppTasksData.length > 0 ? (
            <button
              className='chevronBtn'
              // style={{
              //   visibility: item.subAppTasks.length > 0 ? "visible" : "hidden",
              // }}
              onClick={() => setCollapse((prev) => !prev)}
            >
              <IoIosArrowDroprightCircle
                size='1.5em'
                className={`textSecondary2 chevronIcon ${isCollapsed ? 'rotate' : ''}`}
              />
            </button>
          ) : null}
        </div>
        <div className='DataTaskRow relative' style={{ flexGrow: 1 }}>
          <span // go to task profile if task allowToView true
            className='fs-100 max1LineText dataName fontBoldSm pointer linkOnHover'
            onClick={() => {
              // onGoTask(item.appTaskId);
              onGoData(item.appTaskId);
            }}
            title={item.appTaskTitle}
          >
            {searchValue ? (
              <Highlighter
                highlightClassName='highlightedText'
                searchWords={searchValue.length >= 2 ? searchValue?.split(' ') : []}
                autoEscape={true}
                textToHighlight={item.appTaskTitle}
              />
            ) : (
              item.appTaskTitle
            )}
          </span>

          <div className='fs-100 d-flex align-items-center justify-content-center'>
            <span className='textSecondary dNone dBlockSm me-1'>Dataset count:</span>
            {item.appTaskDataCount > 0 ? item.appTaskDataCount : '-'}
          </div>
          <div className='fs-100 d-flex justify-content-center chipBottomRightInSm'>
            {/* <CircleIcon
              width="2em"
              height="2em"
              type={ThemeCircleIcon.dark}
              onClick={(e) => {
                // go main task
                onGoTask(item.appTaskId);
              }}
              padding="0"
              className="pointer dNoneSm"
            >
              <MdFolder />
            </CircleIcon> */}
            {item.appTaskDataCount > 0 && item.mainTaskAccess == true ? (
              <MainButton
                children='View datasets'
                type={MainButtonType.dark}
                borderRadius='24px'
                fontSize='13px'
                minHeight='1.5em'
                // minWidth="100px"
                className='px-3 mx-0'
                onClick={() => {
                  onGoData(item.appTaskId);
                }}
              ></MainButton>
            ) : // <CircleIcon
            //   width="2em"
            //   height="2em"
            //   padding="0"
            //   type={ThemeCircleIcon.dark}
            //   onClick={(e) => {
            //     onGoData(item.appTaskId);
            //   }}
            //   title="View Data"
            //   className="pointer ms-1"
            // >
            //   <BiChevronRight size="1.4em" />
            // </CircleIcon>
            null}
          </div>
        </div>
      </div>
      {item.subAppTasksData?.length > 0 ? (
        <Collapse isOpened={isCollapsed}>
          <div className='d-flex w-100 responsiveTableDataItem'>
            <div
              className='d-flex align-items-center justify-content-center'
              style={{
                width: LEFT_BLOCK_SIZE,
                minHeight: '8ch',
                // borderRight: '1px solid #ebebeb'
              }}
            >
              <p className='text-rotate m-auto' style={{ fontSize: '0.8em' }}>
                Subtasks
              </p>
            </div>
            <div className='d-flex flex-column' style={{ flexGrow: 1 }}>
              {item.subAppTasksData?.map((sub: any) => {
                // if(idx !== 0) return
                return (
                  <div key={nanoid()} className='DataTaskRow insideCollapse relative'>
                    <span
                      onClick={() => onGoData(item.appTaskId, sub.subAppTaskId)}
                      className='fs-100 max1LineText dataName fontBoldSm linkOnHover pointer'
                    >
                      {searchValue ? (
                        <Highlighter
                          highlightClassName='highlightedText'
                          searchWords={searchValue.length >= 2 ? searchValue?.split(' ') : []}
                          autoEscape={true}
                          textToHighlight={sub.subAppTaskTitle}
                        />
                      ) : (
                        sub.subAppTaskTitle
                      )}
                    </span>

                    <div className='fs-100 d-flex align-items-center justify-content-center'>
                      <span className='textSecondary dNone dBlockSm me-1'>Data Count:</span>
                      {sub.subAppTaskDataCount > 0 ? sub.subAppTaskDataCount : '-'}
                    </div>
                    <div className='fs-100 d-flex justify-content-center chipBottomRightInSm'>
                      {/* <CircleIcon
                        width="2em"
                        height="2em"
                        type={ThemeCircleIcon.dark}
                        padding="0"
                        onClick={(e) => {
                          // go main task
                          onGoTask(sub.subAppTaskId);
                        }}
                        className="pointer dNoneSm"
                      >
                        <MdFolder />
                      </CircleIcon> */}
                      {sub.subAppTaskDataCount > 0 ? (
                        <MainButton
                          children='View datasets'
                          type={MainButtonType.dark}
                          borderRadius='24px'
                          fontSize='13px'
                          minHeight='1.5em'
                          // minWidth="100px"
                          className='px-3 mx-0'
                          onClick={() => {
                            onGoData(item.appTaskId, sub.subAppTaskId);
                          }}
                        ></MainButton>
                      ) : // <CircleIcon
                      //   width="2em"
                      //   height="2em"
                      //   padding="0"
                      //   type={ThemeCircleIcon.dark}
                      //   onClick={(e) => {
                      //     onGoData(item.appTaskId, sub.subAppTaskId);
                      //   }}
                      //   title="View Data"
                      //   className="pointer ms-1"
                      // >
                      //   <BiChevronRight size="1.4em" />
                      // </CircleIcon>
                      null}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Collapse>
      ) : null}
    </div>
  );
};

export default withRouter(DataCollection);
function dispatch(arg0: HeaderTitleAction) {
  throw new Error('Function not implemented.');
}
