import { toast } from "react-toastify";
import { CreateMemberReq } from "../../data/models/requests/member/create_member_req";
import {
  ResendInvatationReq,
  UpdateMemberReq,
} from "../../data/models/requests/member/update_member_req";
import { CreateMemberResResult } from "../../data/models/responses/member/create_member_res";
import { GetMemberByIDResResult } from "../../data/models/responses/member/get_member_by_id_res";
import { InviteInfoGetResult } from "../../data/models/responses/member/Invite_info_res";
import { MemberListResResult } from "../../data/models/responses/member/member_list_res";
import { SearchMemberResResult } from "../../data/models/responses/member/search_member_res";
import { UpdateMemberResResult } from "../../data/models/responses/member/update_member_res";
import { RemoteMember } from "../../data/remotes/member/remote_member";
import { SortType } from "../../core/number_extentions";
import { IFilterOptions } from "../../views/components/FilterSection/IFilterOptions";
export class MemberController {
  remote = new RemoteMember();

  createMember(
    body: CreateMemberReq,
    action: (res: CreateMemberResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.createMember(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  updateMember(
    body: UpdateMemberReq,
    action: (res: UpdateMemberResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.updateMember(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  SearchMember(
    action: (res: SearchMemberResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.SearchMember(
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  getMemberList(
    body: { PageNumber: number; PageSize: number; SearchParameter: string; columnHeaderName?: string; sortDirection?: SortType,filterOptions?: IFilterOptions },
    action: (res: MemberListResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getMemberList(
      body,
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  getMember(
    body: { userId: string; token: string },
    action: (res: GetMemberByIDResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getMemberById(
      body,
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  getInviteInfo(
    body: { userId: string; token: string },
    action: (res: InviteInfoGetResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getInviteInfo(
      body,
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  sendInvatation(
    body: ResendInvatationReq,
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.sendInvatation(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  updateUserPassword(
    body: { email: string; newPassword: string },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.updateUserPassword(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  updateUserEmail(
    body: { userId: string; email: string },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.updateUserEmail(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
}
