import React from 'react';
import ReactPaginate from 'react-paginate';
import { LaboratoryController } from '../../../controllers/laboratory/laboratory_controller';
import { Laboratory } from '../../../data/models/responses/laboratory/laboratory_get_all_res';
import { store } from '../../../data/storeMain';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
import { InputIcon } from '../../components/search_box';
import { SelectComponent } from '../../components/select_input';
// import TableListLaboratory from "./component/table_list_laboratory";
// import { EmptyState } from "../../components/empty_state";
import debounce from 'lodash.debounce';
import { $ReplaceRoute, AccessPermition, isTokenInHttpAuth, UserRoles } from '../../../core/utils';
import { HTTP } from '../../../core/http_common';
import NewTable from '../../components/NewTable/NewTable';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppRoutes } from '../../../core/constants';
import { MdFolder } from 'react-icons/md';
import { FaEdit, FaMicroscope } from 'react-icons/fa';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import { RxMagnifyingGlass } from 'react-icons/rx';
import PhoneSearch from '../../components/PhoneSearch';
import { SortType } from '../../../core/number_extentions';
import FilterSection from '../../components/FilterSection/filterSection';
import DOMPurify from 'dompurify';
import { BaseButton } from '@fluentui/react';
import { MainButton } from '../../components/button';
import RadixTable from '../../components/NewRadixTable/RadixTable';
import Pagination from '../../components/NewRadixTable/RadixPagination';
import { DropdownMenu, Table } from '@radix-ui/themes';
import { getUserInitails } from '../../../core/utils';
import { ImageWithToken } from '../../components/box_list_scroll';
import { EquipmentController } from '../../../controllers/equipment/equipment_controller';
import { SetHeaderTitle } from '../../../data/storeMain/actions/header_title_action';

type StateType = {
  Laboratory: Laboratory[];
  PageNumber: number;
  PageSize: { label: string; value: number };
  PageCount: number;
  TotalCount: number;
  Search: string;
  loading: boolean;
  HeaderName: string;
  SortDirection: SortType;
  showFilterOptions: boolean;
  PageNumberInput: string;
};
interface RouteParams {
  id: string;
}
class LaboratoryPage extends React.Component<RouteComponentProps<RouteParams>> {
  RoleUser = store.getState().userRole;
  controller = new LaboratoryController();
  equipmentcontroller = new EquipmentController();
  state: StateType = {
    Laboratory: [],
    PageNumber: 1,
    PageSize: { label: '10', value: 10 },
    PageCount: 0,
    TotalCount: 0,
    Search: '',
    loading: true,
    HeaderName: '',
    SortDirection: SortType.ASC,
    showFilterOptions: false,
    PageNumberInput: '',
  };
  constructor(props: any) {
    super(props);
    this.handleChangeLaboratorySearch = debounce(this.handleChangeLaboratorySearch.bind(this), 500);
  }
  handelChagePageSize(e: string) {
    this.setState({
      PageSize: e,
    });
    this.GetLabs(
      this.state.PageNumber,
      parseInt(e),
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection
      // this.state.filterOptions
    );
  }
  handlePageNumberInput(event: { target: { value: any } }) {
    if (event.target.value > 0) {
      this.setState({ PageNumberInput: event.target.value });
    } else {
      this.setState({ PageNumberInput: '' });
    }
  }
  handleGoToPage() {
    const pageNumber = parseInt(this.state.PageNumberInput, 10);
    if (pageNumber > 0 && pageNumber <= this.state.PageCount) {
      this.handelChangePageNumber({ selected: pageNumber - 1 });
    } else {
      console.log('Invalid page number');
    }
  }
  componentDidMount() {
    document.title = `My Laboratories | Radvix`;
    store.dispatch(SetHeaderTitle('Laboratory'));
    if (!isTokenInHttpAuth(HTTP)) return;
    this.GetLabs(this.state.PageNumber, this.state.PageSize.value);
  }
  handleViewDetails = (id: any) => {
    this.props.history.push(
      $ReplaceRoute(`${AppRoutes.profile_laboratory.replace(':id', id?.toString() ?? '')}`)
    );
  };
  handleEdit = (id: any) => {
    this.props.history.push(
      $ReplaceRoute(`${AppRoutes.edit_laboratory.replace(':id', id?.toString() ?? '')}`)
    );
  };
  GetLabs(
    PageNumber: number,
    PageSize: number,
    Search?: string,
    columnHeaderName?: string,
    sortDirection?: SortType,
    selectedFromDate?: string,
    selectedToDate?: string
  ) {
    this.setState({
      loading: true,
    });
    if (sortDirection == undefined || sortDirection == null) {
      sortDirection = SortType.AutoSelect;
    }
    this.controller.getLaboratoryGetAll(
      {
        PageNumber: PageNumber,
        PageSize: PageSize,
        SearchParameter: Search ? Search : this.state.Search,
        columnHeaderName,
        sortDirection,
        selectedFromDate,
        selectedToDate,
      },
      (res) => {
        this.setState({
          Laboratory: res.laboratories,
          PageCount: Math.ceil(res.count! / this.state.PageSize.value),
          TotalCount: res.count,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
  }
  handelChangePageNumber(e: { selected: number }) {
    this.setState({
      PageNumber: e.selected,
    });
    this.GetLabs(
      e.selected + 1,
      this.state.PageSize.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection
    );
  }
  handelChangePageSize(e: { label: string; value: number }) {
    this.setState({
      PageSize: e,
    });
    this.GetLabs(
      this.state.PageNumber,
      e.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection
    );
  }
  headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    this.setState({ HeaderName: columnHeaderName });
    this.setState({ SortDirection: sortDirection });
    this.GetLabs(
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.Search,
      columnHeaderName,
      sortDirection
    );
  };
  handleChangeLaboratorySearch(e: any) {
    const inputValue = e.target.value;
    const sanitizedValue = DOMPurify.sanitize(inputValue);
    this.GetLabs(this.state.PageNumber, this.state.PageSize.value, sanitizedValue);
  }
  handleToggle = () => {
    this.setState({ showFilterOptions: !this.state.showFilterOptions });
  };
  filterClick = (
    selectedFromDate?: Date,
    selectedToDate?: Date,
    status?: any[],
    teamType?: number[]
  ) => {
    const formattedFromDate = selectedFromDate
      ? selectedFromDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      : undefined;
    const formattedToDate = selectedToDate
      ? selectedToDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      : undefined;

    this.GetLabs(
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      formattedFromDate,
      formattedToDate
    );
  };
  render() {
    return (
      <>
        <div className='card'>
          <div className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3'>
            <div className='d-flex align-items-center'>
              {/* <button className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none"> Back</button> */}
              <BaseButton
                className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
                style={{ fontWeight: '400', height: '19px' }}
                onClick={() => {
                  window.history.back();
                }}
              >
                Back
              </BaseButton>
              <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>My Laboratories</h2>
            </div>
            <MainButton
              className='btn btn-primary fs-15 py-px-7 new-project-button'
              svg={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='15'
                  height='15'
                  viewBox='0 0 15 15'
                  fill='none'
                >
                  <rect width='15' height='15' fill='white' fill-opacity='0.01'></rect>
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z'
                    fill='white'
                  ></path>
                </svg>
              }
              onClick={() => {
                this.props.history.push($ReplaceRoute(`${AppRoutes.new_laboratory}`));
              }}
              // style={{ height: "35px" }}
            >
              New Laboratory
            </MainButton>
            {/* <a className="btn btn-primary fs-15 py-px-7" href="create-new-proj.html">
            <i className="fa fa-plus me-1"></i> New Project</a> */}
          </div>
          <div className='px-3 pb-3 filters' id='showFilter'>
            <div className='d-flex align-items-center gap-px-12'>
              <InputIcon
                className='form-control flex-grow-1 lab-search h-px-35'
                placeholder='Search Laboratory'
                chilren={undefined}
                onChange={(e) => {
                  this.setState(
                    {
                      Search: e.target.value,
                    },
                    () => {
                      this.GetLabs(this.state.PageNumber, this.state.PageSize.value);
                    }
                  );
                }}
              />
              <button
                className='flex-shrink-0 btn btn-secondary d-sm-none h-px-35 w-px-35 p-0'
                id='filterToggler'
                // onClick={this.filterToggle}
              >
                <img src='/images/icons/mixer-horizontal.svg' />
                <i className='fa fa-times d-none color-gray-1000'></i>
              </button>
            </div>
            {/* <div className="d-sm-flex flex-wrap row-gap-sm-px-0 row-gap-px-16 sub-filters">
              <div className="ps-xl-3 pe-3 w-xl-auto w-sm-25 w-50">
                <MultiSelect
                  options={this.statusOptions}
                  onChange={this.handleStatusChange}
                />
              </div>
              <div className="pe-sm-3 border-end-sm-1 border-gray-400 w-xl-auto w-sm-25 w-50">
                <MultiSelect
                  options={this.priorityOptions}
                  onChange={this.handlePriorityChange}
                />
              </div>
              <div className="pe-3 ps-sm-3 w-xl-auto w-sm-25 w-50">
                <div className="date-picker-wrapper">
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleStartDateChange}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Start Date"
                    maxDate={new Date()}
                    className="form-control h-px-35 customBorders"
                  />
                </div>
              </div>
              <div className=" w-xl-auto w-sm-25 w-50">
                <div className="date-picker-wrapper">
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={this.handleEndDateChange}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="End Date"
                    //maxDate={new Date()}
                    className="form-control h-px-35 customBorders"
                  />
                </div>
              </div>
            </div> */}
          </div>

          <RadixTable
            data={this.state.Laboratory}
            loading={this.state.loading}
            onHeaderClick={(columnHeaderName: string, sortDirection: SortType) =>
              this.headerClick(columnHeaderName, sortDirection)
            }
            columns={[
              {
                Header: 'Laboratory Name',
                accessor: 'title',
                Cell: (_props: any) => {
                  return (
                    <Table.Cell className='p-3'>
                      <div className='line-clamp-2'>
                        <i className='fa fa-grip-vertical me-2 color-gray-600 fs-13'></i>
                        <span
                          onClick={() => {
                            this.props.history.push(
                              $ReplaceRoute(
                                `${AppRoutes.profile_laboratory.replace(
                                  ':id',
                                  _props.id?.toString() ?? ''
                                )}`
                              )
                            );
                          }}
                          title={_props.title}
                        >
                          {' '}
                          {_props.title}{' '}
                        </span>
                      </div>
                    </Table.Cell>
                  );
                },
              },
              {
                Header: 'Creator',
                accessor: 'Creator',
                Cell: (props: any) => {
                  return (
                    <Table.Cell className='p-3'>
                      <div className='d-flex align-items-center text-nowrap'>
                        {props.creator.image === null ||
                        props.creator.image === '' ||
                        props.creator.image === undefined ? (
                          <div className='avatar w-px-25 h-px-25 rounded-circle bg-primary'>
                            <label className='fw-medium text-white fs-10'>
                              {getUserInitails(
                                `${props.creator.firstName} ${props.creator.lastName}`
                              )}
                            </label>
                          </div>
                        ) : (
                          <ImageWithToken
                            originalImage={props.creator.image}
                            hasImage={props.creator.image ? true : false}
                            alt='Avatar'
                            className='rounded-circle avatar rounded-avatar-dashboard'
                            src={
                              props.creator.image === null ||
                              props.creator.image === '' ||
                              props.creator.image === undefined
                                ? '/images/images/img_avatar.png'
                                : props.creator.image
                            }
                          />
                        )}
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            this.props.history.push(
                              $ReplaceRoute(
                                `${AppRoutes.member_profile.replace(
                                  ':id',
                                  props.creator.id?.toString() ?? ''
                                )}`
                              )
                            );
                          }}
                        >
                          {' '}
                          {props.creator.firstName} {props.creator.lastName}
                        </span>
                      </div>
                    </Table.Cell>
                  );
                },
              },
              {
                Header: 'Institution',
                accessor: 'company',
                Cell: (props: any) => {
                  return (
                    <Table.Cell className='p-3'>
                      {props.company === '' ? 'Not Assigned' : props.company}
                    </Table.Cell>
                  );
                },
              },
              {
                Header: 'Equipment',
                accessor: 'equipmentsCount',
                Cell: (props: any) => {
                  return <Table.Cell className='p-3'>{props.equipmentsCount}</Table.Cell>;
                },
              },
              {
                Header: '',
                Cell: (props: any) => {
                  return (
                    <Table.Cell className='p-3 text-nowrap'>
                      <div className='d-flex align-items-center gap-3'>
                        <div className='btn-group'>
                          <button
                            type='button'
                            className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleViewDetails(props.id);
                            }}
                          >
                            <i className='fa fa-eye me-px-6'></i>View Details
                          </button>

                          <DropdownMenu.Root>
                            <DropdownMenu.Trigger>
                              <button
                                type='button'
                                className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                              >
                                <span className='visually-hidden'>Toggle Dropdown</span>
                              </button>
                            </DropdownMenu.Trigger>
                            <DropdownMenu.Content>
                              <DropdownMenu.Item className='px-0'>
                                <button
                                  className='dropdown-item fs-14'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleViewDetails(props.id);
                                  }}
                                >
                                  View Details
                                </button>
                              </DropdownMenu.Item>
                              <DropdownMenu.Item className='px-0'>
                                <button
                                  className='dropdown-item fs-14'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleEdit(props.id);
                                  }}
                                >
                                  Edit Laboratory
                                </button>
                              </DropdownMenu.Item>
                            </DropdownMenu.Content>
                          </DropdownMenu.Root>
                        </div>
                      </div>
                    </Table.Cell>
                  );
                },
              },
            ]}
          />
          {this.state.PageCount > 0 && (
            <Pagination
              pageCount={this.state.PageCount}
              onPageChange={this.handelChangePageNumber.bind(this)}
              onPageSizeChange={(e: any) => {
                this.handelChagePageSize(e);
              }}
              pageInput={this.state.PageNumberInput}
              handlePageNumberInput={this.handlePageNumberInput}
            />
          )}
        </div>
      </>
    );
  }
}

export default withRouter(LaboratoryPage);
