import { SET_EXCLUDE_CLIENTS } from "../actions/actions_type";
import { ExcludeClientsAction } from "../actions/exclude_clients_action";

// import { UserRoleState } from "../actions/user_action";

const excludedClients: (string | number)[] = [];

const reducer = (
  state: (string | number)[] = excludedClients,
  action: ExcludeClientsAction
): (string | number)[] => {
  switch (action.type) {
    case SET_EXCLUDE_CLIENTS:
      return action.payload;
  }
  return state;
};

export default reducer;