import { useEffect, useState } from "react";
// import { FaFilter } from "react-icons/fa";
import { useHistory } from "react-router";
import {
  GetEquipmentReservationReqBody,
  getEquipmentReservations,
} from "../../../controllers/equipmentReservation";
// import { ButtonGroup } from "../../components/botton_group";
// import Form from "../../components/Form/Form";
// import { SelectComponent } from "../../components/select_input";
import CustomScheduleComponent from "./customScheduleComponent";
import FilterPanel from "./filterPanel";
import moment from "moment";
import { SetHeaderTitle } from "../../../data/storeMain/actions/header_title_action";
import { store } from "../../../data/storeMain";
// type Props = {};

export const DEFAULT_DAYS = 30;
const EquipmentReservation = () => {
  const [loading, setLoading] = useState(false);
  const [dateComeFromProps, setDateComeFromProps] = useState<string | null>(
    null
  );
  const history = useHistory();
  const today = moment.utc();
  const startOfWeek = today.startOf("week").toISOString();
  const endOfWeek = today.endOf("week").toISOString();
  const [filterData, setFilterData] = useState<any>({
    selectedTimeScale: 1,
    selectedLaboratory: {
      label: "All Laboratory",
      value: null,
    },
    selectedEquipment: {
      label: "All Equipment",
      value: null,
    },

    fromDate: new Date(startOfWeek),
    toDate: new Date(endOfWeek),
    // fromDate: new Date(new Date().setDate(new Date().getDate() - DEFAULT_DAYS)),
    // toDate: new Date(new Date().setDate(new Date().getDate() + DEFAULT_DAYS)),
  });
  const [reservationList, setReservationList] = useState<any>([]);
  useEffect(() => {
    

    document.title = `Equipment Schedule | Radvix`
    store.dispatch(SetHeaderTitle('Laboratory'))
    let _filterData = {
      From: startOfWeek,
      To: endOfWeek,
      // From: new Date(
      //   new Date().setDate(new Date().getDate() - DEFAULT_DAYS)
      // ).toISOString(),
      // To: new Date(
      //   new Date().setDate(new Date().getDate() + DEFAULT_DAYS)
      // ).toISOString(),
      LaboratoryId: null,
      EquipmentId: null,
      // IsUsersAllLabsEquipmentsReservation: true,
      // IsUsersAllEquipmentsReservation: true,
    };
    if (history.location.state) {
      const { state } = history.location;
      _filterData = {
        From: state?.start,
        To: state?.end,
        LaboratoryId: state?.laboratoryId,
        EquipmentId: state?.equipmentId,
        // IsUsersAllLabsEquipmentsReservation: true,
        // IsUsersAllEquipmentsReservation: true,
      };

      setFilterData({
        ...filterData,
        selectedLaboratory: state.laboratoryId
          ? {
            label: state?.laboratoryTitle,
            value: state?.laboratoryId,
          }
          : {
            label: "All Laboratory",
            value: null,
          },
        selectedEquipment: state.equipmentId
          ? {
            label: state?.equipmentTitle,
            value: Number(state?.equipmentId),
          }
          : {
            label: "All Equipment",
            value: null,
          },
        fromDate: new Date(state?.start),
        toDate: new Date(state?.end),
      });
      setDateComeFromProps(state?.start);
    }

    handleFilterReservationList(_filterData);
  }, []);
  
  // async function handleGetReservationList() {
  //   const response = await getEquipmentReservations({
  //     LaboratoryId: null,
  //     EquipmentId: null,
  //     From: new Date().toISOString(),
  //     To: new Date().toISOString(),
  //     IsUsersAllEquipmentsReservation: true,
  //     IsUsersAllLabsEquipmentsReservation: true,
  //   });
  //   console.log(response)
  //   // setReservationList(data);
  // }

  async function handleFilterReservationList(
    _filterForm: GetEquipmentReservationReqBody
  ) {
    try {
      setLoading(true);
      const _reservations = await getEquipmentReservations(_filterForm);
      setReservationList(
        _reservations.map((_reserve) => {
          return {
            Id: 2,
            Subject: _reserve.equipment.title,
            StartTime: new Date(_reserve.start),
            EndTime: new Date(_reserve.end),
            // IsAllDay: false,
            Status: `Status ${_reserve.status}`,
            Priority: "High",
            CategoryColor: "#ccc",
            ..._reserve,
          };
        })
      );
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }
  return (
    <div className="container-fluid research new-research">
      <div className="row"></div>
      <div className="col-12 box-content p-3">
        <FilterPanel
          onChangeFilterData={setFilterData}
          filterDataDefault={filterData}
          onFilter={handleFilterReservationList}
          loading={loading}
        />
        {/* <CustomScheduleComponent
          data={reservationList}
          filterData={filterData}
          loading={loading}
          dateComeFromProps={dateComeFromProps}
          onChangeReservationStatus={() => {
            handleFilterReservationList({
              LaboratoryId: filterData.selectedLaboratory.value,
              EquipmentId: filterData.selectedEquipment.value,
              From: filterData.fromDate.toISOString(),
              To: filterData.toDate.toISOString(),
              // IsUsersAllEquipmentsReservation: true, // need to be developed
              // IsUsersAllLabsEquipmentsReservation: true, // need to be developed
            });
          }}
          onChangeDate={({ fromDate, toDate }) => {
            
            setFilterData((prev: any) => ({ ...prev, fromDate: new Date(fromDate), toDate: new Date(toDate) }));
            handleFilterReservationList({
              LaboratoryId: filterData.selectedLaboratory.value,
              EquipmentId: filterData.selectedEquipment.value,
              From: fromDate,
              To: toDate,
              // IsUsersAllEquipmentsReservation: true, // need to be developed
              // IsUsersAllLabsEquipmentsReservation: true, // need to be developed
            });
          }}
        /> */}

        {/* <ScheduleComponent
          height="550px"
          selectedDate={new Date(2018, 1, 15)}
          eventSettings={{ dataSource: data }}
        ></ScheduleComponent> */}
        {/* <ScheduleComponent
          height="550px"
          selectedDate={new Date(2018, 1, 15)}
          eventSettings={{
            dataSource: data,
            fields: {
              id: "Id",
              subject: { name: "Subject" },
              isAllDay: { name: "IsAllDay" },
              startTime: { name: "StartTime" },
              endTime: { name: "EndTime" },
            },
          }}
        >
          <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
        </ScheduleComponent> */}
        {/* <ScheduleComponent
          height="550px"
          selectedDate={new Date(2018, 1, 15)}
          eventSettings={{
            dataSource: data,
            fields: {
              id: "Id",
              subject: { name: "Subject" },
              isAllDay: { name: "IsAllDay" },
              startTime: { name: "StartTime" },
              endTime: { name: "EndTime" },
            },
          }}
        >
          <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
        </ScheduleComponent> */}
        {/* <ScheduleComponent
          width="100%"
          height="550px"
          selectedDate={new Date(2018, 1, 15)}
          eventSettings={{ dataSource: data }}
        >
          <ViewsDirective>
            <ViewDirective
              option="WorkWeek"
              startHour="10:00"
              endHour="18:00"
            />
            <ViewDirective option="Week" startHour="07:00" endHour="15:00" />
            <ViewDirective option="Month" showWeekend={false} />
          </ViewsDirective>
          <Inject services={[WorkWeek, Week, Month]} />
        </ScheduleComponent> */}
      </div>
    </div>
  );
};

export default EquipmentReservation;
