import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { ResearchesList } from "../../../data/models/responses/research/researches_res";
// import { MainButton, MainButtonType } from "../../components/button";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import "../../../core/number_extentions";
import { UserRoles } from "../../../core/utils";
import { store } from "../../../data/storeMain";
// import { SetResearchId } from "../../../data/storeMain/actions/research_action";
import { StatusLabel } from "../../components/status_label";
import { SortType } from "../../../core/number_extentions";
interface IAcordienTableResearch {
  Heading: any[];
  Items: ResearchesList[];
  role: UserRoles;
  changeResearch: (id: number, title: string) => void;
  onHeaderClick: (columnHeaderName?: string, sortDirection?: SortType) => void;
}
const AcordienTableResearchHeader: React.FC<
  IAcordienTableResearch & RouteComponentProps
> = (props) => {
  const currentResearchId = store.getState()?.ResearchId?.value;
  const handelChangeSelect = (id: number, title: string) => {
    // store.dispatch(SetResearchId({ name: title, id: id }));
    // props.changeResearch(title);
    props.changeResearch(id, title);
  };
  const [currentSortColumn, setCurrentSortColumn] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<SortType>(SortType.ASC);
  const handleHeaderClick = (columnHeaderName: string) => {
    const clickedColumn = columnHeaderName;
    let localSortDirection: SortType = sortDirection;
    if (clickedColumn && clickedColumn != "Actions" && clickedColumn != "") {
      setCurrentSortColumn(columnHeaderName);
      if (columnHeaderName === currentSortColumn) {
        localSortDirection = sortDirection === SortType.ASC ? SortType.DESC : SortType.ASC;
        setSortDirection(localSortDirection);
      }
      props.onHeaderClick(clickedColumn, localSortDirection);
    }
  };
  const showSortIcon = (columnName: string) => {
    return <>
    {currentSortColumn === columnName && (
              sortDirection === "ASC" ? (
                <img
                  src={`/images/icons/arrow_down.svg`}
                  alt="radvix"
                  width={14}
                  height={14}
                  style={{ marginLeft: "2%" }}
                />
              ) : (
                sortDirection === "DESC" && (
                  <img
                    src={`/images/icons/arrow_up.svg`}
                    alt="radvix"
                    width={14}
                    height={14}
                    style={{ marginLeft: "2%" }}
                  />
                )
              )
            )}
            {currentSortColumn  !== columnName && (
              <img src="/images/icons/sort.svg"
                alt="radvix"
                width={14}
                height={14}
                style={{ marginLeft: "2%" }} />
            )}
    </>
  }
  useEffect(() => { }, []);

  return (
    <div className="table-responsive">
      <table className="table table-striped table-light">
        <thead>
          <tr>
            {props.Heading.map((head, index) => (
              <th
                scope="col"
                className={`table-header ${head.className || ''} ${head.center ? "text-center" : ""}`}
                key={index}
                onClick={() => handleHeaderClick(head.accessor)}
              >
                {head.name} {showSortIcon(head.accessor)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.Items.map((head, index) => (
            <tr key={index}>
              <td
                className="pointer"
                onClick={() => {
                  handelChangeSelect(head.id!, head.title!);
                }}
                style={{ verticalAlign: "middle" }}
              >
                <div className="d-flex flex-column">
                  <span>{head.title}</span>
                  <div className="dNone dFlexSm mt-1">
                    <StatusLabel
                      status={head.status}
                      deadLine={(head as any).daysLeftDeadline}
                    />
                    {
                      currentResearchId === head.id ? (
                        <span
                          className="s-chip mx-1"
                          style={{
                            backgroundColor: "#383838",
                            color: "#fff",
                          }}
                        >
                          Selected
                        </span>
                      ) : ''
                    }
                  </div>
                </div>
              </td>
              <td className="text-center dNoneSm">
                {" "}
                <div className="d-flex justify-content-center">

                  <StatusLabel
                    status={head.status}
                    deadLine={(head as any).daysLeftDeadline}
                  />
                </div>
              </td>
              <td className="dNoneSm">
                <div className="col d-flex justify-content-end align-items-center">
                  {currentResearchId === head.id ? (
                    <span
                      className="s-chip mx-1"
                      style={{
                        backgroundColor: "#383838",
                        color: "#fff",
                      }}
                    >
                      Selected
                    </span>
                  ) : (
                    <CircleIcon
                      width="26px"
                      height="26px"
                      padding="0"
                      type={ThemeCircleIcon.dark}
                      onClick={() => {
                        handelChangeSelect(head.id!, head.title!);
                      }}
                      className="pointer mx-1"
                    >
                      <img
                        src="/images/icons/log-in.svg"
                        alt="radvix"
                        width={12}
                        height={12}
                      />
                    </CircleIcon>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default withRouter(AcordienTableResearchHeader);
