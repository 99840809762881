import React, { useEffect, useState } from 'react';
import { Switch, useHistory, withRouter } from 'react-router';
import { AppRoutes } from '../../../core/constants';
import { HTTP } from '../../../core/http_common';
import { toast } from 'react-toastify';
import { $ReplaceRoute } from '../../../core/utils';
import ReactLoading from 'react-loading';
import './UserSubsciption.css';
import { SignupBackground } from '../signup/component/signup_background';
import { store } from '../../../data/storeMain';
import { UserController } from '../../../controllers/user/user_controller';
import { BaseButton } from '@fluentui/react';
import { base_url_site } from '../../../core/localVariables';

const UserSubscriptions = () => {
  const history = useHistory();
  const [isFree, setisfree] = useState(false);
  const [isSeatForm, setIsSeatForm] = useState(false);
  const [coupon, setCoupon] = useState<string | null>(null);
  const [validationError, setValidationError] = useState<any>({});
  const [loading, setloading] = useState(false);
  const [selectedSeats, setSelectedSeats] = useState(1);
  const [UserId, setUserId] = useState<string | null>(null);
  const [IsSubscriptionCompleted, SetIsSubscriptionCompleted] = useState(false);
  const [montlyAnnualPrice, setMontlyAnnualPrice] = useState<number>(35);
  const [isChecked, setIsChecked] = useState(false);
  const [BillingCycle, setBillingCycle] = useState(4);
  const [isCoupon, setIsCoupon] = useState(false);
  const [isDowngrade, setisDowngrade] = useState(false);
  const [userMySubscription, setUserMySubscription] = useState<any>(null);
  const [couponsList, setCouponList] = useState<any>(null);
  const [showExpiry, setShowExpiry] = useState(false);
  const [showCouponField, setShowCouponField] = useState(false);
  const [couponDays, setCouponDay] = useState<number>(0);
  const [couponCycle, setCouponCycle] = useState<number>(0);
  const userController = new UserController();
  interface ResponseData {
    status: number;
    result: {
      companyName: string | null;
      subscriptionPrice: number | null;
      amount: number;
      billingCycle: number;
      billingStartDate: string | null;
      nextBillingDate: string | null;
      trialexpirationTime: string | null;
      activeSeats: number | null;
      selectedseats: number | null;
      userId: string | null;
      trialStartDate: string | null;
    };
    message: string;
    success: boolean;
  }
  const [responseData, setResponseData] = useState<ResponseData>({
    status: 0,
    result: {
      companyName: null,
      subscriptionPrice: null,
      amount: 0,
      billingCycle: 0,
      billingStartDate: null,
      nextBillingDate: null,
      trialexpirationTime: null,
      activeSeats: null,
      selectedseats: null,
      userId: null,
      trialStartDate: null,
    },
    message: '',
    success: false,
  });

  const toggleSwitch = () => {
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    if (!isChecked) {
      setBillingCycle(4);
      setMontlyAnnualPrice(29.75);
    } else {
      setBillingCycle(3);
      setMontlyAnnualPrice(35);
    }

    if (isSeatForm) {
      document.title = `Radvix | Seat Arrangement`;
    } else if (!isFree) {
      document.title = `Radvix | Collaborator`;
    } else {
      document.title = `Radvix | Launchpad`;
    }
  }, [isChecked, isSeatForm, isFree]);

  const handleIncrementSeats = () => {
    setSelectedSeats(selectedSeats + 1);
  };
  const [dueToday, setDueToday] = useState<number>(0);

  const CalculatePaymentwithRespectToRemaindaysTillNextBillingDate = () => {
    const nextBillingDate = new Date(formData.billingStartDate);
    const days = calculateTotalDays(nextBillingDate, new Date());

    if (days > 0) {
      const remainingDaysCost = calculateRemainingDaysCost(days, montlyAnnualPrice, BillingCycle);
      setDueToday(remainingDaysCost * selectedSeats);
    }
  };
  const calculateTotalDays = (startDate: Date, endDate: Date): number => {
    const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
    const differenceInMs = startDate.getTime() - endDate.getTime();
    const differenceInDays = Math.round(differenceInMs / oneDay);
    return differenceInDays;
  };

  const calculateRemainingDaysCost = (days: number, rate: number, billingCycle: number): number => {
    if (days === null) return 0;
    const chargesPerDay = billingCycle === 3 ? rate / 30 : (rate * 12) / 365;
    return chargesPerDay * days;
  };
  const handleDecrementSeats = () => {
    if (userMySubscription && userMySubscription.activeSeats) {
      if (selectedSeats === userMySubscription.activeSeats) {
        return;
      }
    }
    if (selectedSeats > 1) {
      setSelectedSeats(selectedSeats - 1);
    }
  };

  const cycleOptions = [
    { label: 'Days', value: 1 },
    { label: 'Week', value: 2 },
    { label: 'Month', value: 3 },
    { label: 'Year', value: 4 },
  ];

  const currentDate = new Date();
  const sixMonthsLater = new Date();
  const numericCouponCycle = Number(couponCycle);
  const foundOption = cycleOptions.find((option) => option.value === numericCouponCycle);
  let days = 0;
  if (foundOption?.label == 'Month') {
    days = 30;
  } else if (foundOption?.label == 'Days') {
    days = 1;
  } else if (foundOption?.label == 'Week') {
    days = 7;
  } else if (foundOption?.label == 'Year') {
    days = 365;
  }
  const months = days / 30;
  const totalMonths = months * couponDays;
  sixMonthsLater.setMonth(sixMonthsLater.getMonth() + totalMonths);

  useEffect(() => {
    setloading(true);
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId !== null) {
      setUserId(storedUserId); // Update userId state only if storedUserId is not null
    }
    userController.getUserSubscription(
      (res: any) => {
        setUserMySubscription(res);
        setCouponList(res.couponsList);
        if (res) {
          const subscriptionStatus = res.subscriptionStatus;
          console.log('userInfoooo', store.getState().userInfo);
          const isDowngrade =
            store.getState().userInfo.subscriptionPlan === 1 &&
            (res.selectedseats > 3 || res.selectedseats === 3);
          setisDowngrade(isDowngrade);
          if (subscriptionStatus === 1) {
            setShowExpiry(false);
            setShowCouponField(false);
          } else {
            setShowCouponField(true);
          }
          setSelectedSeats(res.activeSeats);
          formData.billingStartDate = new Date().toISOString().split('T')[0];
        }
        setloading(false);
      },
      //@ts-ignore
      (err) => {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        }
        setloading(false);
      }
    );
  }, []);

  const formData = {
    SubscriptionPrice: montlyAnnualPrice,
    Amount: montlyAnnualPrice,
    TrialStartDate: currentDate.toISOString().split('T')[0],
    TrialexpirationTime: isCoupon
      ? sixMonthsLater.toISOString().split('T')[0]
      : currentDate.toISOString().split('T')[0],
    Selectedseats: selectedSeats,
    ActiveSeats: 1,
    BillingCycle: BillingCycle,
    billingStartDate: isCoupon
      ? sixMonthsLater.toISOString().split('T')[0]
      : currentDate.toISOString().split('T')[0],
    SubscriptionSettingId: isFree ? 1 : 2,
  };
  const Col = [
    'Collaborate with up to 500 teammates.',
    'Unlimited Projects',
    'Connect with collaborator workspaces',
    '5 TB file storage (128 MB per file).',
    'Equipment booking and scheduler',
    'Timeline view',
    'Access to Radvix AI assistant',
  ];
  const features = [
    'Free forever.',
    'Single user structure',
    'Up to 3 projects',
    'Unlimited tasks',
    'Unlimited messages',
    'Unlimited datasets',
    'Unlimited equipment',
    'iOS and Android mobile apps',
    'Google and Microsoft SSO',
    '1 GB file storage (128 MB per file)',
  ];

  {
    features.map((line, index) => (
      <p key={index}>
        <span className='FeatureCardTickMark'>✔</span>&nbsp;&nbsp;{line}
      </p>
    ));
  }

  const Annual = 'Annual';
  const monthly = 'Monthly';

  const handleApplyCoupon = () => {
    if (coupon && couponsList) {
      const matchedCoupon = couponsList.filter((c: any) => c.couponName === coupon);
      const matchedCouponDay = matchedCoupon.map((c: any) => c.days);
      const matchedCouponCycle = matchedCoupon.map((c: any) => c.cycle);

      if (matchedCoupon && matchedCoupon.length > 0) {
        setShowExpiry(true);
        setCouponDay(matchedCouponDay);
        setCouponCycle(matchedCouponCycle);
        setIsCoupon(true);
        setValidationError({ coupenFailed: false });
        console.log('Coupon matched:', coupon);
      } else {
        setShowExpiry(false);
        setIsCoupon(false);
        setValidationError({ coupenFailed: true });
        return;
      }
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setShowExpiry(false);
    console.log();
    if (isFree) {
      setloading(true);
      HTTP.post(`/User/UserSubscription`, formData)
        .then((res) => {
          setloading(false);
          history.push($ReplaceRoute(AppRoutes.dashboard));
          window.dispatchEvent(
            new CustomEvent('loggedIn', {
              detail: {
                token: localStorage.getItem('token'),
                userId: localStorage.getItem('userId'),
              },
            })
          );
        })
        .catch((err) => {
          setloading(false);
          if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        });
    } else {
      setIsSeatForm(true);
      if (!showExpiry) {
        if (formData.BillingCycle == 4) {
          days = 365;
        } else {
          days = 30;
        }
        const remainingDaysCost = calculateRemainingDaysCost(days, montlyAnnualPrice, BillingCycle);
        setDueToday(remainingDaysCost * selectedSeats);
      } else {
        setDueToday(0);
      }
    }
    if (isSeatForm) {
      try {
        if (coupon) {
          const matchedCoupon = couponsList.filter((c: any) => c.couponName === coupon);
          const matchedCouponName = matchedCoupon.map((c: any) => c.couponName);
          if (matchedCoupon.length > 0) {
            if (coupon === matchedCouponName[0]) {
              setIsCoupon(true);
              setValidationError({ coupenFailed: false });
            } else {
              setIsCoupon(false);
              setValidationError({ coupenFailed: true });
              return;
            }
          } else {
            setIsCoupon(false);
            setValidationError({ coupenFailed: true });
          }
        } else {
          setIsCoupon(false);
          setValidationError({ coupenFailed: true });
        }

        setloading(false);
        SetIsSubscriptionCompleted(true);
      } catch (error) {
        console.error('Unexpected error:', error);
      }
    }
  };

  //Date formate
  const billingStartDate = formData.billingStartDate;
  let formattedDate: any;
  if (billingStartDate) {
    formattedDate = new Date(billingStartDate).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }
  // const handleNavigate = () => {
  //   debugger
  //   setloading(true);
  //   history.push(AppRoutes.Subs_Payment);
  //   // HTTP.post(`/User/UserSubscription`, formData)
  //   //   .then((res) => {
  //   //     setloading(false);
  //   //     console.log("res", res);
  //   //     history.push(AppRoutes.Subs_Payment);
  //   //   })
  //   //   .catch((err) => {
  //   //     setloading(false);
  //   //     if (err.response?.data.message)
  //   //       toast.error(`${err.response?.data.message}`);
  //   //   });
  // };
  const handleNavigate = (formData: {
    SubscriptionPrice: number;
    Amount: number;
    TrialStartDate: string;
    TrialexpirationTime: string;
    Selectedseats: number;
    ActiveSeats: number;
    BillingCycle: number;
    billingStartDate: string;
    SubscriptionSettingId: number;
  }) => {
    debugger;
    setloading(true);
    localStorage.setItem('formData', JSON.stringify(formData));
    history.push(AppRoutes.Subs_Payment);
  };
  const handleChange = (event: { target: { value: any } }) => {
    const newValue = event.target.value;
    setCoupon(newValue);
  };

  return (
    <>
      {!IsSubscriptionCompleted ? (
        <div className='pt-4 pb-5' style={{ maxHeight: '100vh', overflowY: 'auto' }}>
          <div className='container'>
            {!isSeatForm ? (
              <div className='max-w-rem-25 m-auto d-flex flex-column align-items-center justify-content-center'>
                <div className='text-center mb-3'>
                  <img
                    src='images/icons/new-radvix-logo.png'
                    width={111}
                    height={49}
                    // src='images/icons/radvix-logo.svg'
                    // srcSet='images/icons/radvix-logo.svg 111w'
                    // sizes='(max-width: 768px) 20vw, 10vw'
                    alt='Company logo'
                  />
                </div>
                {!isFree ? (
                  <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column'>
                    <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>
                      Choose Package
                    </h1>
                    <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                    <div className='d-flex gap-3 mt-3 align-items-center'>
                      <span className='color-gray-600 fs-15'>Annual</span>
                      <div>
                        <label className='switch'>
                          <input type='checkbox' checked={isChecked} onChange={toggleSwitch} />
                          <span
                            className={`slider round ${isChecked ? 'monthly-grey' : ''}`}
                          ></span>
                        </label>
                      </div>
                      <span className='color-gray-600 fs-15'>Monthly</span>
                    </div>
                    <h1 className='fs-26 color-gray-1000 fw-semibold text-center mt-2'>
                      Collaborator
                    </h1>
                    <p className='color-gray-600 fs-15 max-w-rem-16 text-center mb-0'>
                      Best for medium to large research collaborations.
                    </p>
                    <hr className='border-gray-200 border w-100 opacity-100 my-px-10' />
                    <div
                      className='fs-34 text-primary text-center mb-px-12'
                      style={{ fontWeight: '500' }}
                    >
                      ${montlyAnnualPrice}
                    </div>
                    <div
                      className={`color-gray-600 fs-15 max-w-rem-16 text-center mb-1 ${
                        isChecked ? 'div-5-2' : ''
                      }`}
                    >{`/Seat/Month`}</div>
                    {!isChecked && (
                      <div className='text-success fs-13 max-w-rem-16 text-center mb-2'>
                        Billed Annually
                      </div>
                    )}
                    <div className='w-100 mb-px-24 rounded-px-4 p-px-12 user-subscription-bg'>
                      <p className='color-gray-1000 fs-15 mb-1 fw-normal'>
                        <span className='color-gray-1000 fs-15 mb-1 fw-normal'></span>
                        Everything in Launchpad, plus
                      </p>
                      {Col.map((line, index) => (
                        <p className='color-gray-600 fs-13 margin-bottom-fixed' key={index}>
                          <i className='fa fa-check fs-13 color-gray-1000'></i>
                          &nbsp;&nbsp;{line}
                        </p>
                      ))}
                    </div>

                    <button
                      className='d-flex justify-content-center align-items-center btn btn-primary fs-15 w-100 h-px-42 continurButton'
                      onClick={handleSubmit}
                    >
                      Continue
                    </button>
                    <div className='fs-14 fw-normal color-gray-600'>
                      {!isDowngrade && (
                        <button
                          className='text-primary fw-semibold cursor-pointer'
                          type='button'
                          onClick={() => setisfree(true)}
                          style={{
                            border: 'none',
                            outline: 'none',
                            background: 'transparent',
                            marginTop: '12px',
                          }}
                        >
                          Downgrade to Launchpad
                        </button>
                      )}
                    </div>
                    <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                    <div className='fs-14 fw-normal'>
                      <a
                        className='color-gray-600 cursor-pointer'
                        href={`${base_url_site}/terms-of-services`}
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        Terms of Service |{' '}
                      </a>
                      <a
                        className='color-gray-600 cursor-pointer'
                        href={`${base_url_site}/privacy-policy`}
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column'>
                      <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>
                        Choose Package
                      </h1>
                      <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                      <h1 className='fs-26 color-gray-1000 fw-semibold text-center mt-2'>
                        Launchpad
                      </h1>
                      <p className='color-gray-600 fs-15 max-w-rem-16 text-center mb-0'>
                        Best for individual researchers, small startups.
                      </p>
                      <hr className='border-gray-200 border w-100 opacity-100 my-px-10' />
                      <div
                        className='fs-34 text-primary text-center mb-px-12'
                        style={{ fontWeight: '500' }}
                      >
                        Free
                      </div>
                      <div className='w-100 mb-px-24 rounded-px-4 p-px-12 user-subscription-bg'>
                        {features.map((line, index) => (
                          <p className='color-gray-600 fs-13 margin-bottom-fixed' key={index}>
                            <i className='fa fa-check fs-13 color-gray-1000'></i>
                            &nbsp;&nbsp;{line}
                          </p>
                        ))}
                      </div>
                      <button
                        className='d-flex justify-content-center align-items-center btn btn-primary fs-15 w-100 h-px-42 continurButton'
                        onClick={handleSubmit}
                      >
                        {loading ? (
                          <ReactLoading className='d-flex' type='spin' height='auto' width={20} />
                        ) : (
                          'Continue'
                        )}{' '}
                      </button>
                      <div className='fs-14 fw-normal color-gray-600'>
                        <button
                          className='text-primary fw-semibold cursor-pointer'
                          type='button'
                          onClick={() => setisfree(false)}
                          style={{
                            border: 'none',
                            outline: 'none',
                            background: 'transparent',
                            marginTop: '12px',
                          }}
                        >
                          Upgrade to Launchpad
                        </button>
                      </div>
                      <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                      <div className='fs-14 fw-normal'>
                        <a
                          className='color-gray-600 cursor-pointer'
                          href={`${base_url_site}/terms-of-services`}
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          Terms of Service |{' '}
                        </a>
                        <a
                          className='color-gray-600 cursor-pointer'
                          href={`${base_url_site}/privacy-policy`}
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          Privacy Policy
                        </a>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <>
                <div className='max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center'>
                  <div className='text-center mb-3'>
                    <img
                      src='images/icons/new-radvix-logo.png'
                      width={111}
                      height={49}
                      // src='images/icons/radvix-logo.svg'
                      // srcSet='images/icons/radvix-logo.svg 111w'
                      // sizes='(max-width: 768px) 20vw, 10vw'
                      alt='Company logo'
                    />
                  </div>
                  <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column'>
                    <div className='d-flex w-100'>
                      <BaseButton
                        className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
                        style={{ fontWeight: '400', height: '19px' }}
                        onClick={() => {
                          setIsSeatForm(false);
                        }}
                      >
                        Back
                      </BaseButton>
                      <div className='verifyingAccount'>
                        <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>
                          Seat arrangement
                        </h1>
                      </div>
                    </div>
                    <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                    <div className='S-div'>
                      <div className='div2'>
                        <div className='fs-15 S-div-3 mb-3'>How many seats you will need?</div>
                        <div className='S-div-4 d-flex align-items-center justify-content-between w-100'>
                          <button
                            className='S-M-Button'
                            type='button'
                            onClick={handleDecrementSeats}
                          >
                            <span
                              style={{
                                fontSize: '42px',
                                display: 'flex',
                                justifyContent: 'center',
                                position: 'relative',
                                bottom: '20px',
                              }}
                            >
                              -
                            </span>
                          </button>
                          <input
                            className='S-input seatInputStyle mx-2'
                            type='text'
                            id='Selectedseats'
                            name='Selectedseats'
                            disabled={true}
                            value={`${selectedSeats} ${selectedSeats == 1 ? 'Seat' : 'Seats'}`}
                            style={{ width: '100%' }}
                          />
                          <button
                            className='S-P-Button'
                            type='button'
                            onClick={handleIncrementSeats}
                          >
                            <span
                              style={{
                                fontSize: '31px',
                                display: 'flex',
                                justifyContent: 'center',
                                position: 'relative',
                                bottom: '11px',
                              }}
                            >
                              +
                            </span>
                          </button>
                        </div>

                        {showCouponField && (
                          <div>
                            <div className='S-div-8'>Add your coupon:</div>
                            <div className='d-flex align-items-center'>
                              {' '}
                              {/* Use Flexbox for layout */}
                              <div className='S-div-9 mr-2'>
                                {' '}
                                {/* Add margin to create space between input and button */}
                                <input
                                  className='copon_Input'
                                  type='text'
                                  placeholder='Enter coupon code'
                                  defaultValue={coupon ?? ''}
                                  onChange={handleChange}
                                  style={{ width: '218px', marginRight: '8px' }}
                                />
                              </div>
                              <button
                                className='applyButton'
                                onClick={handleApplyCoupon}
                                type='button'
                              >
                                Apply
                              </button>
                            </div>
                            <div className='ERROR_DIV' style={{ textAlign: 'justify' }}>
                              {validationError && validationError.coupenFailed == true && (
                                <span style={{ color: 'red' }} className='error-text'>
                                  Coupon you entered does not exist.
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                        {showExpiry && (
                          <div>
                            <div className='S-div-8'>Your free trial period: </div>
                            <div
                              className='d-flex w-100 Date_Container'
                              style={{ marginTop: '10px' }}
                            >
                              <div className='input-wrapper'>
                                <label className='user-Subscription-label'>From</label>
                                <input
                                  style={{ fontWeight: 'bold' }}
                                  type='date'
                                  id='startDate'
                                  className='StartDate'
                                  value={currentDate.toISOString().split('T')[0]}
                                  disabled
                                />
                              </div>
                              <div className='input-wrapper'>
                                <label className='user-Subscription-label' htmlFor='endDate'>
                                  To
                                </label>
                                <input
                                  style={{ fontWeight: 'bold' }}
                                  type='date'
                                  id='endDate'
                                  className='StartDate'
                                  value={
                                    isCoupon
                                      ? sixMonthsLater.toISOString().split('T')[0]
                                      : currentDate.toISOString().split('T')[0]
                                  }
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div style={{ paddingTop: '30px' }}>
                          <button
                            className='d-flex justify-content-center align-items-center btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton'
                            onClick={handleSubmit}
                          >
                            {loading ? (
                              <ReactLoading
                                className='d-flex'
                                type='spin'
                                height='auto'
                                width={20}
                              />
                            ) : (
                              'Continue'
                            )}{' '}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className='container'>
          <div className='max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center'>
            <div className='text-center mb-3'>
              <img
                src='images/icons/new-radvix-logo.png'
                width={111}
                height={49}
                // src='images/icons/radvix-logo.svg'
                // srcSet='images/icons/radvix-logo.svg 111w'
                // sizes='(max-width: 768px) 20vw, 10vw'
                alt='Company logo'
              />
            </div>
            <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column'>
              <div className='d-flex w-100'>
                <BaseButton
                  className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
                  style={{ fontWeight: '400', height: '19px' }}
                  onClick={() => {
                    SetIsSubscriptionCompleted(false);
                    setIsSeatForm(true);
                  }}
                >
                  Back
                </BaseButton>
                <div
                  style={{
                    alignSelf: 'center',
                    position: 'relative',
                    left: '10%',
                  }}
                >
                  <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>Confirmation</h1>
                </div>
              </div>
              <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
              {isCoupon && (
                <div style={{ padding: '6px 0px 6px 0px' }}>
                  <h4
                    className='fs-15'
                    style={{
                      textAlign: 'center',
                      fontSize: '13px',
                      fontWeight: '400',
                    }}
                  >
                    After trial, you will be billed based on number of seats being reserved. You can
                    always adjust the number of seats from your dashboard.
                  </h4>
                </div>
              )}
              <div className='w-100 mb-px-24 bg-gray-100 rounded-px-4 p-px-12 user-subscription-bg'>
                <div className='Preview-Div-5'>
                  <div className='Preview-Div-6'>
                    <div className='Preview-Div-7'>Billing Cycle Start Date:</div>
                    <div className='Preview-Div-8'>Cycle:</div>
                  </div>
                  <div className='Preview-Div-9'>
                    <div className='Preview-Div-10'>{formattedDate}</div>
                    <div className='Preview-Div-11'>
                      {formData.BillingCycle === 3 ? monthly : Annual}
                    </div>
                  </div>
                </div>
                <div className='Preview-Div-12'>
                  <div className='Preview-Div-13'>
                    {`${formData.Selectedseats === 1 ? 'Seat    ' : 'Seats'}:  `}{' '}
                  </div>
                  <div className='Preview-Div-14'>{JSON.stringify(formData.Selectedseats)}</div>
                </div>
                <div className='Preview-Div-15 col-12'>
                  <div className='Preview-Div-16 col-7'>
                    <div className='Preview-Div-17'>Cost Per Seat:</div>
                    <div className='Preview-Div-18'>Due today:</div>
                  </div>
                  <div className='Preview-Div-19 col-5'>
                    <div className='Preview-Div-confirmation-20'>
                      $
                      {JSON.stringify(
                        formData.BillingCycle === 4 ? formData.Amount : formData.Amount
                      )}
                      /Seat/Month
                    </div>
                    <div className='Preview-Div-confirmation-21'>${`${dueToday}`}</div>
                  </div>
                </div>
              </div>
              <button
                className='d-flex justify-content-center align-items-center btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton'
                onClick={() => handleNavigate(formData)}
              >
                {loading ? (
                  <ReactLoading className='d-flex' type='spin' height='auto' width={20} />
                ) : (
                  'Continue'
                )}{' '}
              </button>
              <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
              <div className='fs-14 fw-normal'>
                <a
                  className='color-gray-600 cursor-pointer'
                  href={`${base_url_site}/terms-of-services`}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Terms of Service |{' '}
                </a>
                <a
                  className='color-gray-600 cursor-pointer'
                  href={`${base_url_site}/privacy-policy`}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default withRouter(UserSubscriptions);
