import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { InputComponent, InputType } from '../../../components/inputs';
import { SelectComponent } from '../../../components/select_input';
import { IOption } from '../../../../data/models/responses/learning/update_learning_res';

type Props = {
    ProvideTrial: boolean | undefined;
    TrialExpiration: Date | undefined;
    BillingDays: number | undefined;
    Amount: number | undefined;
    TrialNotExpire: boolean | undefined;
    BillingType: number | undefined;
    onProvideTrialChange: (value: boolean) => void;
    onTrialNotExpireChange: (value: boolean) => void;
    onTrialExpirationChange: (date: Date) => void;
    onBillingDaysChange: (value: number) => void;
    onSelectCycleChange: (value: number) => void;
    onAmountChange: (value: number) => void;
};

type State = {
    provideTrial: boolean | undefined;
    trialExpiration: Date | undefined;
    billingDays: number | undefined;
    selectCycle: IOption;
    amount: number | undefined;
    cycleOptions: Array<{ value: number; label: string }>;
    trialNotExpire: boolean | undefined;
    disableBilingCycle: boolean;
};
const BilingTypeName =  [
    { label: "Days", value: 1 },
    { label: "Week", value: 2 },
    { label: "Month", value: 3 },
    { label: "Year", value: 4 },
]
class AdminClientsPayment extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        
        const bilingTypedata = BilingTypeName.find((x) => x.value === props.BillingType);
        this.state = {
            provideTrial: props.ProvideTrial,
            trialExpiration: props.TrialExpiration,
            billingDays: props.BillingDays,
            selectCycle: {
                label: bilingTypedata?.label ?? '', // Provide a default label
                value: bilingTypedata?.value ?? 0,  // Provide a default value
              },
            trialNotExpire: props.TrialNotExpire,
            amount: props.Amount,
            cycleOptions: BilingTypeName,
            disableBilingCycle: props.TrialNotExpire ?? false
        };
    }

    handleProvideTrialChange = (value: boolean) => {
        this.setState({ provideTrial: value });
        this.props.onProvideTrialChange(value);
    };
    handleTrialNotExpire = (value: boolean) => {
        this.setState({ trialNotExpire: value, disableBilingCycle:value });
        this.props.onTrialNotExpireChange(value);
    };

    handleTrialExpirationChange = (date: Date) => {
        this.setState({ trialExpiration: date });
        this.props.onTrialExpirationChange(date);
    };

    handleBillingDaysChange = (value: number) => {

        this.setState({ billingDays: value });
        this.props.onBillingDaysChange(value);
    };
    handleAmountChange = (value: number) => {

        this.setState({ amount: value });
        this.props.onAmountChange(value);
    };
    handleSelectCycleChange = (data: IOption) => {
        this.setState({ selectCycle: data });
        this.props.onSelectCycleChange(data.value);
    };

    render() {
        return (
            <div>
                <div>
                    <p className="mt-3 mb-1" style={{ fontSize: '15px' }}>Provide Trial?</p>
                    <div className="row">
                        <div className="col-2">
                            <div className="form-check">
                                <input
                                    value="1"
                                    className="form-check-input"
                                    type="radio"
                                    name="trial"
                                    onChange={() => this.handleProvideTrialChange(true)}
                                    checked={this.state.provideTrial}
                                />
                                <label className="form-check-label" style={{ fontSize: '14px' }}>
                                    Yes
                                </label>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-check">
                                <input
                                    value="0"
                                    className="form-check-input"
                                    type="radio"
                                    name="trial"
                                    onChange={() => this.handleProvideTrialChange(false)}
                                    checked={!this.state.provideTrial}
                                />
                                <label className="form-check-label" style={{ fontSize: '14px' }}>
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <span className="label d-flex align-items-center mt-3 mb-1" style={{ fontSize: '15px' }}>
                        Trial Expiration
                    </span>
                    <div className="row">
                        <div className="col-5">
                            <DatePicker
                                selected= {this.state.trialExpiration != null ? new Date(this.state.trialExpiration): new Date()}
                                onChange={this.handleTrialExpirationChange}
                                minDate={new Date()}
                                disabled={!this.state.provideTrial}                               
                            />
                        </div>
                        <div className="col-7">
                            <div className="form-check">
                                <input
                                    value="0"
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={() => this.handleTrialNotExpire(!this.state.trialNotExpire)}
                                    checked={this.state.trialNotExpire}
                                    disabled={!this.state.provideTrial}
                                />
                                <label className="form-check-label" style={{ fontSize: '15px' }}>
                                    Trial Does not expire
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <span style={{ fontSize: '15px' }} className="label d-flex align-items-center mb-3 mt-3">
                        Billing Cycle
                    </span>
                    <div className="row">
                        <span className="col-2 mb-1" style={{ fontSize: '14px' }}>
                            Every
                        </span>
                        <div className="col-3 mb-1">
                            <InputComponent
                                className="mb-3"
                                fontSize="12px"
                                inputType="number"
                                placeholder="Days"
                                type={InputType.text}
                                defaultValue={this.state.billingDays}
                                onChange={(e) => this.handleBillingDaysChange(e.target.value)}
                                disabled={this.state.disableBilingCycle}
                            ></InputComponent>
                        </div>
                        <div className="col-7 mb-1">
                            <SelectComponent
                                TextItem="label"
                                ValueItem="value"
                                placeholder="Click to select …"
                                onChange={(e) => this.handleSelectCycleChange(e)}
                                disabled={this.state.disableBilingCycle}
                                items={this.state.cycleOptions}
                                defaultValue={this.state.selectCycle}
                            //selectProps={this.state.selectCycle}
                            ></SelectComponent>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="row">
                        <span className="col-5 mt-1 mb-1" style={{ fontSize: '14px' }}>
                            Bill the amount of
                        </span>
                        <div className="col-3 mt-1 mb-1">
                            <InputComponent
                                className="mb-3"
                                fontSize="12px"
                                inputType="number"
                                placeholder="$0.00"
                                type={InputType.text}
                                defaultValue={this.state.amount}
                                onChange={(e) => this.handleAmountChange(e.target.value)}
                                disabled={this.state.disableBilingCycle}
                            ></InputComponent>
                        </div>
                        <span className="col-3 mt-1 mb-1" style={{ fontSize: '14px' }}>
                            Dollars.
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminClientsPayment;
