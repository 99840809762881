import moment from 'moment';
import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import * as Dialog from '@radix-ui/react-dialog';
import {
  GetMeetingListReqBody,
  getMeetingNoteById,
  getMeetingNoteList,
  MeetNoteType,
} from '../../../controllers/team/meet_note_controller';
// import { HTTP } from "../../../core/http_common";
// import { getAmazonUrl, getUserTitle, HTTP_GET_FILE } from "../../../core/utils";
import { MainButton, MainButtonType } from '../../components/button';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
import NewTable from '../../components/NewTable/NewTable';
import { SelectComponent } from '../../components/select_input';
import SpinnerLoader from '../../components/spinnerLoader';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import File from "../../components/File";
import { AppRoutes, MOMENT_DATE_FORMAT } from '../../../core/constants';
import UserWithAvatar from '../../components/UserWithAvatar';
import GroupedAvatars from '../../components/groupedAvatars';
import CustomTooltip from '../../components/CustomTooltip';
import { RiTimerLine } from 'react-icons/ri';
import { FaUsers } from 'react-icons/fa';
import { SortType } from '../../../core/number_extentions';
import RadixTable from '../../components/NewRadixTable/RadixTable';
import { Table } from '@radix-ui/themes';
import { $ReplaceRoute, getUserInitails } from '../../../core/utils';
import { useHistory } from 'react-router';
import { BaseButton } from '@fluentui/react';
import { ImageWithToken } from '../../components/box_list_scroll';

const TeamMeetList = ({ teamId }: { teamId: number }) => {
  const [meetNoteList, setMeetNoteList] = React.useState<MeetNoteType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [singleMeetNoteLoader, setSingleMeetNoteLoader] = React.useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [meetNoteDetail, setMeetNoteDetail] = React.useState<MeetNoteType | null>(null);
  const history = useHistory();
  const [paginate, setPaginate] = React.useState({
    pageNumber: 0,
    pageSize: {
      label: '10',
      value: 10,
    },
    totalCount: 0,
    pageCount: 0,
  });

  React.useEffect(() => {
    handleGetMeetNoteById({
      PageNumber: 1,
      PageSize: 10,
      TeamId: teamId,
    });
  }, [teamId]);

  async function handleGetMeetNoteById(_query: GetMeetingListReqBody) {
    try {
      setLoading(true);
      const res = await getMeetingNoteList(_query);
      setPaginate((prev) => ({
        ...prev,
        pageCount: Math.ceil(res.count! / paginate.pageSize.value),
        totalCount: res.count,
      }));
      setMeetNoteList(res.meetingNotes);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const handleDownload = (props: any) => {
    if (props?.name) {
      const aElement = document.createElement('a');
      aElement.href = props.name;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(props.name);
    }
  };

  async function handleGetMeetDetailById(_meetingNoteId: number) {
    try {
      setSingleMeetNoteLoader(true);
      const res = await getMeetingNoteById({
        meetingNoteId: _meetingNoteId,
        teamId: teamId,
      });
      if (res?.meetingNoteContent) {
        setMeetNoteDetail(res);
      }
      setSingleMeetNoteLoader(false);
    } catch (error) {
      setSingleMeetNoteLoader(false);
    }
  }
  const headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    handleGetMeetNoteById({
      PageNumber: 1,
      PageSize: 10,
      TeamId: teamId,
      ColumnName: columnHeaderName,
      SortDirection: sortDirection,
    });
  };
  // const renderMedia = (props: any) => {;
  //   {
  //     props.media && props.media.inputDataType == 9 && (
  //       <Table.Cell className="p-3 text-nowrap">
  //         <div className="d-flex align-items-center gap-3">
  //           <div className="btn-group">
  //             <button
  //               type="button"
  //               className="btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0"
  //             >
  //               <i className="fa fa-eye me-px-6"></i>View Details
  //             </button>
  //           </div>
  //         </div>
  //       </Table.Cell>
  //     );
  //   }
  //   {
  //     props.media &&
  //       props.media.inputDataType != 9 &&
  //       props.media.inputDataType != 4 && (
  //         <Table.Cell className="p-3">
  //           <span className="badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team-profile-file">
  //             Dataset230.xls - 23 MB
  //           </span>
  //         </Table.Cell>
  //       );
  //   }
  //   {
  //     props.media && props.media.inputDataType == 4 && (
  //       <Table.Cell className="p-3">
  //         <span className="badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team-profile-link">
  //           https://gdrive.com/file/secondaryresearch2139.docx
  //         </span>
  //       </Table.Cell>
  //     );
  //   }
  // };
  return (
    <>
      <div className='overflow-auto vh-calc-xl-320 vh-calc-sm-415 px-3'>
        <div className='py-3 border-top border-bottom border-gray-400 d-flex justify-content-between align-items-center'>
          <h3 className='color-gray-1000 fw-medium fs-15 mb-0'>Meeting Notes</h3>
          <a
            className='btn btn-primary fs-13 py-px-4 fw-normal px-2'
            onClick={(e) => {
              e.preventDefault();
              history.push(
                $ReplaceRoute(
                  `${AppRoutes.team_meet_note_new.replace(':id', teamId.toString() ?? '')}`
                )
              );
            }}
          >
            <i className='fa fa-plus me-1'></i> Add Meeting Note
          </a>
        </div>
        <RadixTable
          data={meetNoteList}
          loading={loading}
          columns={[
            {
              Header: 'Meeting Title',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <span>{props.title}</span>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Creator',
              Cell: (_props: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <div className='d-flex align-items-center text-nowrap'>
                      {_props.createdBy && (
                        <>
                          <ImageWithToken
                            originalImage={_props.createdBy?.image}
                            hasImage={_props.createdBy?.image ? true : false}
                            alt='Avatar'
                            className='rounded-circle avatar rounded-avatar-dashboard'
                            src={
                              _props.createdBy?.image === null ||
                              _props.createdBy?.image === '' ||
                              _props.createdBy?.image === undefined
                                ? '/images/images/img_avatar.png'
                                : _props.createdBy?.image
                            }
                            textInAvatar={`${_props.createdBy?.firstName} ${_props.createdBy?.lastName}`}
                          />
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              history.push(
                                $ReplaceRoute(
                                  `${AppRoutes.member_profile.replace(
                                    ':id',
                                    _props.createdBy?.id?.toString() ?? ''
                                  )}`
                                )
                              );
                            }}
                          >{`${_props.createdBy?.firstName} ${_props.createdBy?.lastName}`}</span>
                        </>
                      )}
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Date',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3'>
                    {moment(props.createdAt).format(MOMENT_DATE_FORMAT)}
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Participants',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <GroupedAvatars
                      containerWidth={10}
                      maxAvatars={3}
                      tippyContent={
                        <div className='d-flex flex-column py-2 pb-0'>
                          {props.users.map((_us: any) => (
                            <UserWithAvatar
                              parentStyle={{
                                marginBottom: '0.25em',
                              }}
                              key={_us.id}
                              user={_us}
                            />
                          ))}
                        </div>
                      }
                      users={props.users}
                    />
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Notes',
              Cell: (props: any) => {
                return (
                  <>
                    {props.media && props.media.inputDataType === 9 && (
                      <Table.Cell className='p-3 text-nowrap'>
                        <div className='d-flex align-items-center gap-3'>
                          <div className='btn-group'>
                            <button
                              type='button'
                              className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                              onClick={() => {
                                handleGetMeetDetailById(props.id); // Fetch meeting note details
                                setOpenModel(true); // Open the modal
                              }}
                            >
                              <i className='fa fa-eye me-1'></i>View Note
                            </button>
                          </div>
                        </div>
                      </Table.Cell>
                    )}
                    {props.media &&
                      props.media.inputDataType !== 9 &&
                      props.media.inputDataType !== 4 && (
                        <Table.Cell className='p-3'>
                          <span
                            className='badge text-black py-1 px-3 rounded-pill fs-13 fw-normal bg-team-profile-file cursor-pointer'
                            onClick={() => handleDownload(props.media)}
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='15'
                              height='16'
                              viewBox='0 0 15 16'
                              fill='none'
                            >
                              <rect
                                width='15'
                                height='15'
                                transform='translate(0 0.500549)'
                                fill='white'
                                fill-opacity='0.01'
                              />
                              <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M7.50005 1.55054C7.74858 1.55054 7.95005 1.75201 7.95005 2.00054V8.91414L10.1819 6.68234C10.3576 6.5066 10.6425 6.5066 10.8182 6.68234C10.994 6.85808 10.994 7.143 10.8182 7.31874L7.81825 10.3187C7.64251 10.4945 7.35759 10.4945 7.18185 10.3187L4.18185 7.31874C4.00611 7.143 4.00611 6.85808 4.18185 6.68234C4.35759 6.5066 4.64251 6.5066 4.81825 6.68234L7.05005 8.91414V2.00054C7.05005 1.75201 7.25152 1.55054 7.50005 1.55054ZM2.5 10.5005C2.77614 10.5005 3 10.7244 3 11.0005V12.5005C3 13.0544 3.44565 13.5005 3.99635 13.5005H11.0012C11.5529 13.5005 12 13.0533 12 12.5005V11.0005C12 10.7244 12.2239 10.5005 12.5 10.5005C12.7761 10.5005 13 10.7244 13 11.0005V12.5005C13 13.6046 12.1062 14.5005 11.0012 14.5005H3.99635C2.89019 14.5005 2 13.6035 2 12.5005V11.0005C2 10.7244 2.22386 10.5005 2.5 10.5005Z'
                                fill='#0D0D0D'
                              />
                            </svg>{' '}
                            {props.media.title}
                          </span>
                        </Table.Cell>
                      )}
                    {props.media && props.media.inputDataType === 4 && (
                      <Table.Cell className='p-3'>
                        <span
                          className='badge text-black py-1 px-3 rounded-pill fs-13 fw-normal bg-team-profile-link cursor-pointer'
                          onClick={() => handleDownload(props.media)}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                          >
                            <rect
                              width='15'
                              height='15'
                              transform='translate(0 0.50061)'
                              fill='white'
                              fill-opacity='0.01'
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M7.4999 2.30063C4.35188 2.30063 1.7999 4.85261 1.7999 8.00063C1.7999 11.1486 4.35188 13.7006 7.4999 13.7006C10.6479 13.7006 13.1999 11.1486 13.1999 8.00063C13.1999 4.85261 10.6479 2.30063 7.4999 2.30063ZM0.899902 8.00063C0.899902 4.35555 3.85482 1.40063 7.4999 1.40063C11.1449 1.40063 14.0999 4.35555 14.0999 8.00063C14.0999 11.6457 11.1449 14.6006 7.4999 14.6006C3.85482 14.6006 0.899902 11.6457 0.899902 8.00063Z'
                              fill='#0D0D0D'
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M13.5 8.40059H1.5V7.60059H13.5V8.40059Z'
                              fill='#1C2024'
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M7.09998 14.0006V2.00061H7.89998V14.0006H7.09998ZM10.3751 8.00059C10.3751 5.82785 9.59371 3.67839 8.0619 2.25717L8.538 1.74402C10.2397 3.32279 11.0751 5.67334 11.0751 8.00059C11.0751 10.3278 10.2397 12.6784 8.538 14.2572L8.0619 13.744C9.59371 12.3228 10.3751 10.1733 10.3751 8.00059ZM3.99976 8.00061C3.99976 5.67672 4.80793 3.32739 6.45775 1.7478L6.94184 2.25342C5.45827 3.67384 4.69976 5.8245 4.69976 8.00061C4.69977 10.1767 5.45829 12.3274 6.94186 13.7478L6.45776 14.2534C4.80795 12.6738 3.99977 10.3245 3.99976 8.00061Z'
                              fill='#1C2024'
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M7.50002 4.45862C9.66934 4.45862 11.8754 4.85976 13.3707 5.69509C13.5395 5.78936 13.5999 6.00258 13.5056 6.17134C13.4114 6.34009 13.1981 6.40048 13.0294 6.30621C11.6795 5.55216 9.60805 5.15862 7.50002 5.15862C5.39198 5.15862 3.32058 5.55216 1.9707 6.30621C1.80194 6.40048 1.58872 6.34009 1.49445 6.17134C1.40019 6.00258 1.46057 5.78936 1.62933 5.69509C3.12472 4.85976 5.33069 4.45862 7.50002 4.45862ZM7.50002 11.3506C9.66934 11.3506 11.8754 10.9494 13.3707 10.1141C13.5395 10.0198 13.5999 9.80662 13.5056 9.63787C13.4114 9.46911 13.1981 9.40873 13.0294 9.50299C11.6795 10.257 9.60805 10.6506 7.50002 10.6506C5.39198 10.6506 3.32058 10.257 1.9707 9.503C1.80194 9.40873 1.58872 9.46911 1.49445 9.63787C1.40019 9.80662 1.46057 10.0198 1.62933 10.1141C3.12472 10.9494 5.33069 11.3506 7.50002 11.3506Z'
                              fill='#1C2024'
                            />
                          </svg>{' '}
                          {props.media.externalUrl}
                        </span>
                      </Table.Cell>
                    )}
                  </>
                );
              },
            },
          ]}
        />
      </div>

      <MeetNoteDetailModal
        meetNoteDetail={meetNoteDetail}
        loading={false}
        openModel={openModel}
        onClose={() => setOpenModel(false)} // Function to close the modal
      />

      {/* <div className="py-3 px-3">
        <div className="d-flex justify-content-sm-between justify-content-center align-items-center gap-3 flex-wrap">
          <div className="d-flex align-items-center gap-px-10 justify-content-sm-center justify-content-between w-sm-auto w-100">
            <h2 className="fw-normal fs-13 mb-0 color-gray-600">Show Data :</h2>
            <select className="bg-gray-400 h-px-26 form-select color-gray-1000 py-0 fs-13 w-px-70 ps-2 pe-0">
              <option>10</option>
              <option>100</option>
            </select>
          </div>
          <div className="d-flex align-items-center">
            <nav aria-label="..." className="pe-3">
              <ul className="pagination">
                <li className="page-item disabled">
                  <span className="page-link">
                    <i className="fa fa-chevron-left"></i>
                  </span>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
                <li className="page-item active" aria-current="page">
                  <span className="page-link">2</span>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <span className="page-link">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </li>
              </ul>
            </nav>
            <a
              href="#"
              className="fw-medium fs-13 mb-0 color-gray-1000 ps-3 border-start border-gray-400"
            >
              Go to page
            </a>
          </div>
        </div>
      </div> */}
    </>
  );
};

const MeetNoteDetailModal = ({
  meetNoteDetail,
  loading,
  openModel,
  onClose,
}: {
  meetNoteDetail: MeetNoteType | null;
  loading: boolean;
  openModel: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog.Root open={openModel} onOpenChange={onClose}>
      <Dialog.Overlay className='DialogOverlay' />
      <Dialog.Content className='DialogContent'>
        <div
          className='d-flex justify-content-between align-items-center row-gap-3 flex-wrap column-gap-3 border-bottom border-gray-400'
          style={{ paddingBottom: '10px' }}
        >
          <div className='d-flex align-items-center'>
            <BaseButton
              className='btn btn-outline-primary fs-15 py-px-4 me-px-12 px-3 d-sm-block d-none back-button'
              style={{ fontWeight: '400', height: '19px' }}
              onClick={onClose}
            >
              Close
            </BaseButton>
            <h2 className='fw-medium fs-15 mb-0 color-gray-1000 modalHeader'>
              Meeting Note - {meetNoteDetail?.title}
            </h2>
          </div>
        </div>
        <div className='modal-body' style={{ paddingTop: '15px' }}>
          <div className='p-3 bg-gray-200 rounded-px-6 my-3'>
            <p className='color-gray-600 fw-medium fs-15'>{meetNoteDetail?.meetingNoteContent}</p>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default TeamMeetList;
