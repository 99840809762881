import moment from "moment";
import React from "react";
import ReactPaginate from "react-paginate";
import { RouteComponentProps, withRouter } from "react-router";
import { AdminController } from "../../../controllers/admin/admin_controller";
import {
  AppRoutes,
  TICKET_STATUS_COLORS,
  TICKET_STATUS_LABELS,
} from "../../../core/constants";
import { HTTP } from "../../../core/http_common";
import { $ReplaceRoute, isTokenInHttpAuth } from "../../../core/utils";
import { Discussion } from "../../../data/models/responses/discussion/get_all_discusstion_res";
import { Badge } from "../../components/badge";
import { MainButton, MainButtonType } from "../../components/button";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import NewTable from "../../components/NewTable/NewTable";
import { InputIcon } from "../../components/search_box";
import { SelectComponent } from "../../components/select_input";
// import TicketsTbl from "./component/tickets_tbl";
import debounce from "lodash.debounce";
import { SortType } from "../../../core/number_extentions";
type StateType = {
  Discusstion: Discussion[];
  PageNumber: number;
  PageSize: { value: number; label: string };
  PageCount: number;
  TotalCount: number;
  Search: string;
  loading?: boolean;
};
class AdminTickets extends React.Component<RouteComponentProps> {
  controller = new AdminController();
  state: StateType = {
    Discusstion: [],
    PageNumber: 1,
    PageSize: { value: 10, label: "10" },
    PageCount: 0,
    TotalCount: 0,
    loading: true,
    Search: "",
  };

  constructor(props: any) {
    super(props);
    this.handleChangeTicketSearch = debounce(
      this.handleChangeTicketSearch.bind(this),
      500
    );
  }
  componentDidMount() {
    if (!isTokenInHttpAuth(HTTP)) return;
    const _urlParams = this.props.location?.search;
    if (_urlParams) {
      const _splitiedParams = _urlParams.split("=");
      const _userId = _splitiedParams[1];

      this.GetDiscusstion(
        this.state.PageNumber,
        this.state.PageSize.value,
        _userId
      );
      // this.GetPayments(this.state.PageNumber, this.state.PageSize, _clientId);
    } else {
      this.GetDiscusstion(this.state.PageNumber, this.state.PageSize.value);
    }
  }
  GetDiscusstion(
    PageNumber: number,
    PageSize: number,
    userId?: string | null,
    Search?: string,
    columnHeaderName?: string,
    sortDirection?: SortType
  ) {
    this.setState({ loading: true });
    if (sortDirection == undefined || sortDirection == null) {
      sortDirection = SortType.AutoSelect;
    }
    this.controller.getAllDiscusstion(
      {
        PageNumber: PageNumber,
        PageSize: PageSize,
        ticket: true,
        UserId: userId,
        Search: Search ? Search : this.state.Search,
        columnHeaderName,
        sortDirection
      },
      (res) => {

        this.setState({
          Discusstion: res.discussions,
          PageCount: Math.ceil(res.count! / this.state.PageSize.value),
          TotalCount: res.count,
          loading: false,
        });
      },
      (err) => {
        this.setState({ loading: false });
      }
    );
  }
  handelChangePageNumber(e: { selected: number }) {
    this.setState({
      PageNumber: e.selected,
    });
    this.GetDiscusstion(e.selected + 1, this.state.PageSize.value);
  }
  handelChangePageSize(e: { label: string; value: number }) {
    this.setState({
      PageSize: e.value,
    });
    this.GetDiscusstion(this.state.PageNumber, e.value);
  }
  handleChangeTicketSearch(e: any) {
    this.GetDiscusstion(
      this.state.PageNumber,
      this.state.PageSize.value,
      null,
      e.target.value
    );
  }
  headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    this.GetDiscusstion(
      this.state.PageNumber,
      this.state.PageSize.value,
      null,
      this.state.Search,
      columnHeaderName,
      sortDirection);
  };
  render() {
    return (
      <div className="container-fluid research">
        <div className="row"></div>
        <div className="col-12">
          <div className="TableBox">
            <div className="TopTableBox d-flex justify-content-between align-items-center mb-3">
              <div className="left d-flex w-50 align-items-baseline">
                <h6 style={{ width: "35%" }}>Tickets</h6>
                <InputIcon
                  chilren={
                    <img src="/images/icons/search_box_icon.svg" alt="" />
                  }
                  width="100%"
                  placeholder="Search..."
                  TopPosition="15%"
                  value={this.state.Search}
                  onChange={(e) => {
                    this.setState({ Search: e.target.value });
                    this.handleChangeTicketSearch(e);
                  }}
                ></InputIcon>
              </div>
              <div className="right w-50 d-flex justify-content-end align-items-center">
                <MainButton
                  children="Broadcast "
                  type={MainButtonType.dark}
                  borderRadius="24px"
                  fontSize="14px"
                  className="mx-4"
                  minWidth="150px"
                  minHeight="24px"
                  onClick={() => {
                    this.props.history.push(
                      $ReplaceRoute(AppRoutes.admin_broadcast)
                    );
                  }}
                ></MainButton>
                <SelectComponent
                  width="90px"
                  height="44px"
                  defaultValue={this.state.PageSize}
                  items={[
                    { label: "10", value: 10 },
                    { label: "15", value: 15 },
                    { label: "20", value: 20 },
                  ]}
                  TextItem="item"
                  ValueItem="id"
                  isMulti={false}
                  placeholder={this.state.PageSize.toString()}
                  onChange={(e) => {
                    this.handelChangePageSize(e);
                  }}
                ></SelectComponent>
              </div>
            </div>
            <NewTable
              searchValue={this.state.Search}
              loadingCount={this.state.PageSize.value}
              loading={this.state.loading}
              data={this.state.Discusstion}
              customGridTemplate="1em 1.75fr 0.75fr 1.5fr 0.75fr 0.75fr 0.75fr 0.5fr"
              onHeaderClick={(columnHeaderName, sortDirection) => this.headerClick(columnHeaderName, sortDirection)}
              columns={[
                {
                  Header: "",
                  accessor: "priority",
                  cellClassName: "d-flex",
                  skeletonConfig: {
                    width: "0.5em",
                  },
                  Cell: (_row) => (
                    <Badge
                      text={_row.priority.isPriority()}
                      color="#fff"
                      background={
                        _row.priority === 0
                          ? "#9CFBFF"
                          : _row.priority === 1
                            ? "#40c3f4"
                            : "#096bff"
                      }
                      style={{
                        top: "0",
                      }}
                    />
                  ),
                },
                {
                  Header: "Subject",
                  accessor: "subject",
                  ellipsis: true,
                  // Cell: (_row) => {
                  //   return (
                  //     <div className="d-flex align-items-center max1LineText">
                  //       <Badge
                  //         text={_row.priority.isPriority()}
                  //         color={_row.priority === 0 ? "#000" : "#fff"}
                  //         className="me-2"
                  //         style={{ top: 0, borderRadius: "1em" }}
                  //         background={
                  //           _row.priority === 0
                  //             ? "#9CFBFF"
                  //             : _row.priority === 1
                  //             ? "#40c3f4"
                  //             : "#096bff"
                  //         }
                  //       />
                  //       {_row.subject}
                  //     </div>
                  //   );
                  // },
                },
                {
                  Header: "Ticket #",
                  accessor: "id",
                  align: "center",
                  Cell: (_row) => {
                    const _id =
                      _row.ticketStatus === null
                        ? `B${String(_row.broadcastCounter)}`
                        : `T${String(_row.ticketCounter )}`;
                    return <>{_id}</>;
                  },
                },
                {
                  Header: "Created By",
                  accessor: "createdBy",
                  Cell: (_row) =>
                    _row.creatorUserFirstName + " " + _row.creatorUserLastName,
                  align: "center",
                },
                {
                  Header: "Date Created",
                  accessor: "createdAt",
                  align: "center",
                  Cell: (_row) =>
                    moment(_row.histories ? _row.histories[0].createDate : _row.createdAt).format("YYYY/MM/DD"),
                },
                {
                  Header: "Update",
                  accessor: "updatedAt",
                  align: "center",
                  Cell: (_row) => (
                    <span className="s-chip darkenBg">
                      {_row.histories?.length || '0'} messages
                    </span>
                  ),
                },
                {
                  Header: "Status",
                  accessor: "status",
                  Cell: (_row) => {
                    // if (!_row.ticketStatus) return "-";
                    return (
                      <MainButton
                        //@ts-ignore
                        children={TICKET_STATUS_LABELS[_row.ticketStatus]}
                        type={MainButtonType.light}
                        borderRadius="24px"
                        fontSize="14px"
                        backgroundColor={
                          //@ts-ignore
                          TICKET_STATUS_COLORS[_row.ticketStatus]
                        }
                      // onClick={() => {
                      //   props.history.push($ReplaceRoute(AppRoutes.ticketing_ticket));
                      // }}
                      ></MainButton>
                    );
                  },
                },
                {
                  Header: "",
                  accessor: "id",
                  Cell: (_row) => (
                    <div className="d-flex justify-content-end">
                      <CircleIcon
                        width="26px"
                        padding="0"
                        height="26px"
                        type={ThemeCircleIcon.dark}
                        onClick={(e) => {
                          this.props.history.push(
                            $ReplaceRoute(
                              `${AppRoutes.admin_ticket.replace(
                                ":id",
                                _row.id.toString() ?? ""
                              )}`
                            )
                          );
                        }}
                        className="pointer mx-1"
                      >
                        <img
                          src="/images/icons/google_docs.svg"
                          alt="radvix"
                          width={12}
                          height={12}
                        />
                      </CircleIcon>
                    </div>
                  ),
                },
              ]}
            />
            {/* <TicketsTbl
              Items={this.state.Discusstion}
              Heading={[
                { name: "Subject", center: false },
                { name: "Ticket", center: true },
                { name: "Created By", center: true },
                { name: "Date Created", center: true },
                { name: "Update", center: true },
                { name: "Status", center: true },
              ]}
            ></TicketsTbl> */}

            <div className="d-flex justify-content-between align-items-center px-3 mt-3">
              <div className="d-flex justify-content-end flex-fill">
                <ReactPaginate
                  previousLabel={
                    <CircleIcon
                      width="24px"
                      padding="0"
                      backgroundColor="#ADADAD"
                      height="24px"
                      type={ThemeCircleIcon.dark}
                    >
                      <i className="fas fa-chevron-left"></i>
                    </CircleIcon>
                  }
                  nextLabel={
                    <CircleIcon
                      width="24px"
                      padding="0"
                      backgroundColor="#ADADAD"
                      height="24px"
                      type={ThemeCircleIcon.dark}
                    >
                      <i className="fas fa-angle-right"></i>
                    </CircleIcon>
                  }
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.PageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(e) => {
                    this.handelChangePageNumber(e);
                  }}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
              <div className="d-flex justify-content-end flex-fill">
                <p className="text-right mb-0 ">
                  Total Results: {this.state.TotalCount}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(AdminTickets);
