import { Skeleton, Table } from '@radix-ui/themes';
import React, { useEffect, useState } from 'react';
import EmptyChip from '../EmptyChip';
import Highlighter from 'react-highlight-words';
import { nanoid } from 'nanoid';
import { returnArrayOfNumber } from '../../../core/utils';
import { SortType } from '../../../core/number_extentions';

const NoDataFound = () => {
  return <>No Data Found</>;
};

const RadvixProjectsTable = ({
  data,
  columns,
  className = '',
  rowClassName = '',
  loading = false,
  searchValue,
  loadingCount = 6,
  headerRowClassName = '',
  noDataFound = 'No record found',
  noDataHeight = false,
  onHeaderClick,
  customHeaderWidth = false,
}: any) => {
  const sortArrowDownIcon = '/images/icons/arrow_down.svg';
  const sortarrowupIcon = '/images/icons/arrow_up.svg';
  const sortIcon = '/images/icons/caret-sort.svg';
  const [currentSortColumn, setCurrentSortColumn] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<SortType>(SortType.ASC);

  const handleHeaderClick = (columnHeaderName: string) => {
    const clickedColumn = columns.find((column: any) => column.Header === columnHeaderName);
    let localSortDirection: SortType = sortDirection;
    if (
      clickedColumn &&
      clickedColumn.Header !== 'Actions' &&
      clickedColumn.Header !== '' &&
      (clickedColumn.removeSort == null || clickedColumn.removeSort === false)
    ) {
      setCurrentSortColumn(columnHeaderName);
      if (columnHeaderName === currentSortColumn) {
        localSortDirection = sortDirection === SortType.ASC ? SortType.DESC : SortType.ASC;
        setSortDirection(localSortDirection);
      }
      onHeaderClick(clickedColumn.accessor, localSortDirection);
    }
  };

  return (
    <div className='table-responsive table-sticky-header' id='isFilterShown'>
      <table
        className={`table mb-0 ${className}`}
        style={{ tableLayout: customHeaderWidth ? 'fixed' : 'auto' }}
      >
        {/* <Table.Header>
          <Table.Row className={headerRowClassName}>
            {columns.map((column: any) => (
              <Table.ColumnHeaderCell
                key={nanoid()}
                scope='col'
                className={`p-3 cursor-pointer text-nowrap ${column.className ? column.className : ''}`}
                onClick={() => handleHeaderClick(column.Header)}
                style={{ width: column.width ? column.width : 'auto' }} 
              >
                {column.Header}
                {currentSortColumn === column.Header &&
                  column.Header !== 'Actions' &&
                  (column.removeSort == null || column.removeSort === false) &&
                  column.Header !== '' &&
                  (sortDirection === SortType.ASC ? (
                    <img
                      src={sortArrowDownIcon}
                      alt='radvix'
                      width={14}
                      height={14}
                      style={{ marginLeft: '2%' }}
                    />
                  ) : (
                    sortDirection === SortType.DESC &&
                    column.Header !== 'Actions' &&
                    (column.removeSort == null || column.removeSort === false) &&
                    column.Header !== '' && (
                      <img
                        src={sortarrowupIcon}
                        alt='radvix'
                        width={14}
                        height={14}
                        style={{ marginLeft: '2%' }}
                      />
                    )
                  ))}
                {currentSortColumn !== column.Header &&
                  column.Header !== 'Actions' &&
                  (column.removeSort == null || column.removeSort === false) &&
                  column.Header !== '' && (
                    <img
                      src={sortIcon}
                      alt='radvix'
                      width={14}
                      height={14}
                      style={{ marginLeft: '2%' }}
                    />
                  )}
              </Table.ColumnHeaderCell>
            ))}
          </Table.Row>
        </Table.Header> */}
        <Table.Body>
          {loading ? (
            returnArrayOfNumber(loadingCount).map((_, index) => (
              <Table.Row key={nanoid()} className={rowClassName}>
                {columns.map((column: any) => (
                  <Table.Cell
                    key={nanoid()}
                    className=''
                    style={{
                      minHeight: '1em',
                      ...(column.align ? { textAlign: column.align } : {}),
                      ...(column.maxWidth ? { maxWidth: column.maxWidth } : {}),
                    }}
                  >
                    <Skeleton
                      width='100%'
                      height='1.25em'
                      containerClassName='lineHeight1'
                      {...column.skeletonConfig}
                    />
                  </Table.Cell>
                ))}
              </Table.Row>
            ))
          ) : data && data.length > 0 ? (
            data.map((row: any, _idx: any) => (
              <Table.Row key={nanoid()} className={rowClassName}>
                {columns.map((column: any) => column.Cell(row, _idx))}
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={columns.length} className='text-center'>
                <div className='no-data-found'>
                  <img
                    className='no-data-found-img'
                    src='/Images/images/Guy Standing And Finding Data.png'
                  />
                  {noDataFound}
                </div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </table>
    </div>
  );
};

export default RadvixProjectsTable;
