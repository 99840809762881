import { toast } from "react-toastify";
import { RemoteEmailIntegration } from "../../data/remotes/EmailIntegration/EmailIntegration_Remote";

export class EmailIntegrationController {
  remote = new RemoteEmailIntegration();

  GetAllMessages(
    body: any,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.GetAllMessages(
      body,
      (res) => {
        // toast.success("create successfully");
        action(res);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  MarkasRead(
    body: any,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.MarkasRead(
      body,
      (res) => {
        // toast.success("create successfully");
        action(res);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }


  SendReply(
    body: any,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.SendReply(
      body,
      (res) => {
        // toast.success("create successfully");
        action(res);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  SendAsync(
    body: any,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.SendAsync(
      body,
      (res) => {
        action(res);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  

  DeleteEmail(
    body: any,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.DeleteEmail(
      body,
      (res) => {
        // toast.success("create successfully");
        action(res);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  GetUserEmailData(
    body: any,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.GetUserEmailData(
      body,
      (res) => {
        // toast.success("create successfully");
        action(res);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }


  AccountDataInsert(
    body: any,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.AccountDataInsert(
      body,
      (res) => {
        // toast.success("create successfully");
        action(res);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

}
