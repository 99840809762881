/* eslint-disable no-useless-concat */
import React from "react";

interface EmptyStateProps {
  text?: string;
  backgroundColor?: string;
  fontSize?: string;
  color?: string;
  minHeight?: string;
  className?: string;
}
export const EmptyState: React.FC<EmptyStateProps> = ({
  text,
  fontSize,
  backgroundColor,
  color,
  minHeight,
  className=""
}) => {
  let Styles = {
    fontSize,
    backgroundColor,
    color,
    minHeight,
  };
  return (
    <div
      style={Styles}
      className={`d-flex justify-content-center align-items-center rounded mt-3 ${className}`}
    >
      <span>{text}</span>
    </div>
  );
};
