// import logo from "./logo.svg";
import "./App.css";
import {
  CommandBar,
  ICommandBarItemProps,
  SearchBox,
  Stack,
} from "@fluentui/react";
import { EmailList } from "./EmailList";
//import { NavigationPane } from "./NavigationPane";
import { OpenEmailComponent } from "./OpenEmailComponent";
import { useEffect, useState } from "react";
import EmailComposeComponent from "./EmailComposeComponent";
import { HTTP } from "../../../../../core/http_common";
import SpinnerLoader from "../../../../components/spinnerLoader";
import { toast } from "react-toastify";
import { EmailIntegrationController } from "../../../../../controllers/EmailIntegration/EmailIntegration_Controller";
import { ProfileTypes } from "../../../../../core/number_extentions";
import { useLocation } from "react-router";
import { handleNotificationTypeToProfileType } from "../../../../../core/utils";

export const EmailOutlook = ({
  Emails,
  selectedProfileType,
  filterEmail,
}: any) => {
  const [folderEmails, setfolderEmails] = useState<any[]>([]);
  const [composeEmail, setComposeEmail] = useState(0);
  const [newEmail, setNewEmail] = useState(false);
  const [skipEmails, setSkipEmails] = useState("0");
  const [tabValue, setTabValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedFolderName, setSelectedFolderName] = useState<any>(null);
  const [selectedEmail, setSelectedEmail] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const controller = new EmailIntegrationController();
  const location = useLocation();

  const handleRefreshClick = () => {
    setLoading(true);
    setfolderEmails([]);
    setComposeEmail(-1);
    loadEmails(selectedFolderName, "0", searchQuery, tabValue, false);
  };
  useEffect(() => {
    if (filterEmail == true) handleRefreshClick();
  }, [filterEmail]);
  useEffect(() => {
    if (filterEmail == true) handleRefreshClick();
  }, [selectedProfileType]);
  useEffect(() => {
    if (Emails.length === 0) return;

    const registeredEmailAddress = Emails[0].registeredEmailAddress;
    localStorage.setItem("registeredEmailAddress", registeredEmailAddress);
    const filteredEmails = Emails.filter(
      (email: any) => email.folderName === "Inbox"
    );
    const folderDetails = filteredEmails.map((email: any) => ({
      folderNameId: email.folderId,
      folderName: email.folderName,
    }));
    setSelectedFolderName(folderDetails[0]);
    setfolderEmails([]);
    setSkipEmails("0");
    setComposeEmail(-1);
  }, [Emails, location.pathname, location.search]);
  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "newMail",
      text: "New Email",
      iconProps: { iconName: "Mail" },
      onClick: () => setComposeEmail(1),
      buttonStyles: { root: { background: "rgb(78, 0, 87)", color: "#fff" } },
      className: "half-button",
    },
    // {
    //   key: "markAllAsRead",
    //   text: "Mark all as read",
    //   iconProps: { iconName: "Mail" },
    //   onClick: () => setComposeEmail(1),
    // },
    {
      key: "refresh",
      iconProps: { iconName: "Refresh" },
      onClick: handleRefreshClick,
    },
  ];
  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setComposeEmail(-1);
    setfolderEmails([]);
    loadEmails(selectedFolderName, "0", query, tabValue, false);
  };
  const farItems: ICommandBarItemProps[] = [
    {
      key: "search",
      onRender: () => (
        <SearchBox
          placeholder="Search emails"
          onSearch={handleSearch}
          onClear={() => handleSearch("")}
          onChange={(_, newValue) => setSearchQuery(newValue || "")}
          styles={{ root: { width: 200 } }}
        />
      ),
    },
  ];

  const handleFolderClick = (value: any) => {
    setSelectedFolderName(value);
    setfolderEmails([]);
    setSkipEmails("0");
    setComposeEmail(-1);
    setTabValue("");
  };
  useEffect(() => {
    if (selectedFolderName == null) return;
    setLoading(true);
    //setComposeEmail(-1);
    loadEmails(selectedFolderName, skipEmails, searchQuery, tabValue, true);
  }, [selectedFolderName, skipEmails, tabValue, selectedProfileType]);

  const loadEmails = (
    selectedFolderName: any,
    skipEmails: any,
    query: string,
    tabValue: string,
    MergeEmail: boolean
  ) => {
    
    // setComposeEmail(-1);
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const queryParams = new URLSearchParams(url.search);
    const profileId = queryParams.get("profileId");
    const profileType =
      selectedProfileType !== 0
        ? handleNotificationTypeToProfileType(selectedProfileType)
        : null; // queryParams.get("profileType");
    const emailData = {
      folderNameId: selectedFolderName.folderNameId,
      folderName: selectedFolderName.folderName,
      skip: skipEmails,
      top: "20",
      ProfileId: profileId == "0" ? null : profileId,
      ProfileType: profileType ? profileType : null,
      searchQuery: query,
      tabValue: tabValue,
    };
    if ((profileType || profileId) && skipEmails == "0") {
      setComposeEmail(-1);
    }
    if (selectedProfileType == 0 && skipEmails == "0") {
      setComposeEmail(-1);
    }
    try {
      controller.GetAllMessages(
        emailData,
        (res) => {
          if (res.result) {
            const newEmails = res?.result?.value;
            if (newEmails && newEmails.length > 0) {
              if (
                profileId ||
                selectedProfileType !== 0 ||
                !MergeEmail ||
                skipEmails == "0"
              ) {
                setfolderEmails(newEmails);
              } else {
                setfolderEmails((prevEmails: any) => [
                  ...prevEmails,
                  ...newEmails,
                ]);
              }
            }
            const newEmail = queryParams.get("NewEmail");
            if (newEmail == "true") setComposeEmail(1);
            else setComposeEmail(0);

            setSelectedEmail(null);
            setLoading(false);
            window.history.replaceState({}, document.title, url.toString());
          } else {
            const newEmail = queryParams.get("NewEmail");
            setSelectedEmail(null);
            setLoading(false);
            setfolderEmails([]);
            setLoading(false);
            if (newEmail == "true") {
              setComposeEmail(1);
            } else {
              setComposeEmail(0);
            }
          }
          url.searchParams.delete("NewEmail");
          window.history.replaceState({}, document.title, url.toString());
        },
        (err: any) => {
          setComposeEmail(0);
          console.error("load emails:", err);
        }
      );
    } catch (err) {
      setLoading(false);
      setComposeEmail(0);
      console.error("load emails:", err);
    }
  };
  function removeQueryParam(url: any, paramToRemove: any) {
    const parsedUrl = new URL(url);
    const searchParams = new URLSearchParams(parsedUrl.search);
    searchParams.delete(paramToRemove);
    parsedUrl.search = searchParams.toString();
    return parsedUrl.toString();
  }
  const handleDiscardClick = () => {
    setComposeEmail(0);
    loadEmails(selectedFolderName, skipEmails, searchQuery, tabValue, false);
    //setfolderEmails([]);
  };
  const handleDeleteClick = (emailId: any) => {
    setComposeEmail(0);
    if (emailId) {
      setfolderEmails((prevEmails) => removeEmailById(prevEmails, emailId));
    }
  };
  const removeEmailById = (emailsArray: any, emailIdToRemove: any) => {
    return emailsArray.filter((email: any) => email.id !== emailIdToRemove);
  };

  const markEmailAsRead = (emailsArray: any, emailIdToMarkRead: any) => {
    return emailsArray.map((email: any) => {
      if (email.id === emailIdToMarkRead) {
        return { ...email, isRead: true };
      }
      return email;
    });
  };
  const handleSendClick = () => {
    setComposeEmail(0);
  };

  const handleEmailClick = (email: any) => {
    setComposeEmail(-1);
    const emailData = {
      Id: email.key,
    };
    try {
      controller.MarkasRead(
        emailData,
        (res) => {
          if (res) {
            setfolderEmails((prevEmails: any) =>
              markEmailAsRead(prevEmails, email.key)
            );
            setSelectedEmail(email);
            if (selectedFolderName.folderName == "Drafts") {
              setComposeEmail(4);
            } else {
              setComposeEmail(2);
            }
          }
        },
        (err: any) => {
          setComposeEmail(0);
          //toast.error("Failed to load email.");
        }
      );
    } catch (err) {
      setComposeEmail(0);
      toast.error("Failed to load email.");
    }
  };

  // useEffect(() => {
  //   if (folderByEmails == null || folderByEmails.length === 0) return;
  //   let name = "Inbox";
  //   if (selectedFolderName && selectedFolderName.folderName) {
  //     name = selectedFolderName.folderName;
  //   }
  //   const folderEmailss =
  //     folderByEmails.find((folder: any) => folder.folderName === name)
  //       ?.emailMessages || [];

  //   setfolderEmails(folderEmailss);
  // }, [folderByEmails]);
  // Find emails for the selected folder
  const sortFolders = (folders: any) => {
    const orderedFolders: any = [];
    const otherFolders: any = [];
    const folderOrder = [
      "Inbox",
      "Sent Items",
      "Draft",
      "Outbox",
      "Deleted Items",
    ];

    // Separate folders into ordered and others
    folders.forEach((folder: any) => {
      const index = folderOrder.indexOf(folder.folderName);
      if (index !== -1) {
        orderedFolders[index] = folder;
      } else {
        otherFolders.push(folder);
      }
    });

    // Filter out undefined entries (if any folder was not present)
    const filteredOrderedFolders = orderedFolders.filter(
      (folder: any) => folder !== undefined
    );

    // Concatenate the ordered folders with the others
    return [...filteredOrderedFolders, ...otherFolders];
  };

  const handleScrollClick = () => {
    if (!selectedProfileType) {
      const currentSkip = parseInt(skipEmails, 20);
      const newSkip = currentSkip + 20;
      setSkipEmails(newSkip.toString());
    } else {
      setSkipEmails("0");
    }
  };

  const handleTabClick = (e: any) => {
    
    if (e.tab == "Focused") {
      setTabValue("Focused");
    } else if (e.tab == "Other") {
      setTabValue("Other");
    }
    setSkipEmails("0");
  };

  return (
    <>
      {
        <Stack
          horizontal
          tokens={{ childrenGap: 20 }}
          style={{ background: "#fff", borderRadius: "14px" }}
        >
          <Stack.Item align="start" styles={{ root: { width: 300 } }}>
            {/* Pass the click handler to NavigationPane */}
          </Stack.Item>
          <Stack.Item
            grow={1}
            className={`${composeEmail == -1 ? "show-email-loader" : ""}`}
          >
            {composeEmail == 1 && (
              <EmailComposeComponent
                onDiscardClick={handleDiscardClick}
                OnSendClick={handleSendClick}
                setComposeEmail={setComposeEmail}
              />
            )}
            {composeEmail == 4 && (
              <EmailComposeComponent
                onDiscardClick={handleDiscardClick}
                OnSendClick={handleSendClick}
                setComposeEmail={setComposeEmail}
                email={selectedEmail}
                replyall={4}
              />
            )}
            {composeEmail == -1 && (
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center">
                  <SpinnerLoader />
                  <span className="mt-3 textSecondary">Loading...</span>
                </div>
              </div>
            )}
            {/* Pass the selected folder's emails and the email click handler to EmailList */}
            {
              <>
                <div
                  style={{ display: `${composeEmail == 0 ? "block" : "none"}` }}
                >
                  <CommandBar
                    className="customCommandBar"
                    style={{ padding: "0px", paddingRight: "32px" }}
                    items={commandBarItems}
                    farItems={farItems}
                  />
                  <EmailList
                    Emails={folderEmails}
                    onEmailClick={handleEmailClick}
                    selectedFolder={selectedFolderName}
                    handleLoadMoreEmails={handleScrollClick}
                    emailLoading={loading}
                    onTabClick={(e: any) => handleTabClick(e)}
                  />{" "}
                </div>
              </>
            }
            {composeEmail == 2 && (
              <OpenEmailComponent
                email={selectedEmail}
                onDeleteClick={(emailId: any) => handleDeleteClick(emailId)}
                setComposeEmail={setComposeEmail}
              />
            )}
          </Stack.Item>
        </Stack>
      }
    </>
  );
};
