/* eslint-disable no-useless-concat */
import React, { useState, useEffect } from "react";
import { BiCheck } from 'react-icons/bi'
import { AppData } from "../../core/constants";
import { PassChecks } from "../../core/enum";
import { FiX } from 'react-icons/fi'
interface ValidPasswordProp {
  isShow?: boolean
  password: string;
  actions: any;
}

interface ICheckPass {
  id: number
  title: string
  status: boolean
}

export const ValidPassword: React.FC<ValidPasswordProp> = ({
  isShow = false,
  password,
  actions,
}) => {

  const [checks, setChecks] = useState<ICheckPass[]>(AppData.password_checks.map((item: any) => {
    item.status = false
    return item
  }))

  useEffect(() => {

    createPasswordLabel(password)
    actions(checkStrong(password)?.info);
  }, [password])

  const checkStrong = (password: string) => {
    let score = 0;
    let regexPositive = ["[a-z]", "[A-Z]", "[0-9]", "\\W"];


    regexPositive.forEach((regex, index) => {
      if (new RegExp(regex).test(password)) {
        score += 1;
      }
    });
    if (password.length >= 4) {
      score++;
    }
    switch (score) {
      case 0:
        return {
          value: 0,
          info: "",
        };
      case 1:
        return {
          value: 1,
          info: "Weak",
        };
      case 2:
        return {
          value: 2,
          info: "Fair",
        };
      case 3:
      case 4:
        return {
          value: 3,
          info: "Good",
        };
      case 5:
        return {
          value: 4,
          info: "Strong",
        };
      default:
        return null;
    }
  }

  const createPasswordLabel = (password: string) => {
    const tempChecks = [...checks]

    let regexPositive = ["[a-z]", "[A-Z]", "[0-9]", "\\W"];

    changeStatus(tempChecks, PassChecks.OneLetter, new RegExp(regexPositive[0]).test(password))
    changeStatus(tempChecks, PassChecks.OneCapitalLetter, new RegExp(regexPositive[1]).test(password))
    changeStatus(tempChecks, PassChecks.OneNumber, new RegExp(regexPositive[2]).test(password))
    changeStatus(tempChecks, PassChecks.OneSpecialChar, new RegExp(regexPositive[3]).test(password))
    changeStatus(tempChecks, PassChecks.MinFourChar, password.trim().length >= 4)


    setChecks(tempChecks)
  }

  const changeStatus = (checks: ICheckPass[], type: PassChecks, isValid: boolean) => {

    const index = checks.findIndex(item => item.id === type)

    if (index !== -1) {

      checks[index] = {
        ...checks[index],
        status: isValid
      }

    }
  }

  return (
    <div className={`password-strength ${isShow ? "" : "hidden"}`}>
      <p>Password must meet the following requirements.</p>
      <ul className="password-strength-tips">
        {
          checks.map(item => (
            <li key={item.id}>
              {
                item.status ?
                  <BiCheck size={16} color="#03b11a" /> :
                  <FiX size={16} color="#ff0400" />
              }
              <p className={item.status ? "pass" : ""}> {item.title} </p>
            </li>
          ))
        }
      </ul>
    </div>
  );
};
