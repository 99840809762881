import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AppRoutes, MOMENT_DATE_FORMAT } from '../../../core/constants';
import { $ReplaceRoute, getUserInitails } from '../../../core/utils';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
import File from '../../components/File';
import NewTable from '../../components/NewTable/NewTable';
import { useHistory } from 'react-router-dom';
import ShowFilesInTable from '../../components/ShowFilesInTable';
import DatePicker from 'react-datepicker';
import { MdCalendarMonth, MdFolder } from 'react-icons/md';
import { BsFillChatLeftDotsFill } from 'react-icons/bs';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import UserWithAvatar from '../../components/UserWithAvatar';
// import { RiTimerLine } from "react-icons/ri";
import CustomTooltip from '../../components/CustomTooltip';
import { DataController } from '../../../controllers/data/data_controller';
import { ResearchController } from '../../../controllers/research/research_controller';
import { useDebounceEffect } from '../../../hooks/useDebounceEffect';
import { SelectComponent } from '../../components/select_input';
import { InputIcon } from '../../components/search_box';
import { RxMagnifyingGlass } from 'react-icons/rx';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector, useStore } from 'react-redux';
import PhoneSearch from '../../components/PhoneSearch';
import { ProfileTypes, SortType } from '../../../core/number_extentions';
import { nanoid } from 'nanoid';
import FilterSection from '../../components/FilterSection/filterSection';
import { IFilterOptions } from '../../components/FilterSection/IFilterOptions';
import DOMPurify from 'dompurify';
import { BaseButton } from '@fluentui/react';
import RadixTable from '../../components/NewRadixTable/RadixTable';
import { DropdownMenu, Table } from '@radix-ui/themes';
import DashboardProjectDropdown from '../../components/ActionComponent/DashboardProjectDropdown';
import { TaskController } from '../../../controllers/task/task_controller';
import Pagination from '../../components/NewRadixTable/RadixPagination';
import { ImageWithToken } from '../../components/box_list_scroll';
import Tippy from '@tippyjs/react';
import { store } from '../../../data/storeMain';
import { SetResearchId } from '../../../data/storeMain/actions/research_action';
import { MainButton } from '../../components/button';
import { SetHeaderTitle } from '../../../data/storeMain/actions/header_title_action';
import { UserController } from '../../../controllers/user/user_controller';

// type Props = {
//   fullData: any;
//   data: any;
//   researchId?: number;
//   onBack: () => void;
//   taskId?: number;
//   loading: boolean;
// };

export default function DataListTaskId() {
  const controller = new DataController();
  const researchController = new ResearchController();
  const taskController = new TaskController();
  const userController = new UserController();
  const [dataList, setDataList] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [taskId, setTaskId] = useState(0);
  const projectId = useSelector((state) => store.getState().ResearchId);
  const [selectedProject, setSelectedProject] = useState(projectId);
  const [researchId, setResearchId] = useState(0);

  const [selectedTaskId, setSelectedTaskId] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [headerName, setHeaderName] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<SortType>(SortType.AutoSelect);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>({});
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [visibleTippy, setVisibleTippy] = useState<boolean>();
  const [Tasks, setTasks] = useState<any[]>([]);
  const [researhList, setResearhList] = useState<any>([]);
  const [selectedTask, setSelectedTask] = useState<any>({
    label: 'All Tasks',
    value: 0,
  });
  const [paginate, setPaginate] = useState<{
    pageSize: { label: string; value: number };
    pageNumber: number;
    pageCount: number;
    totalCount: number;
    PageNumberInput: string;
  }>({
    pageSize: { label: '10', value: 10 },
    pageNumber: 0,
    pageCount: 0,
    totalCount: 0,
    PageNumberInput: '',
  });
  const history = useHistory();
  // const params = useParams<{taskId: string;}>();

  // const store = useStore().getState();

  const dispatch = useDispatch();

  const GetTasks = (
    researchId: number,
    PageNumber: number,
    PageSize: number,
    Search?: string,
    filterOptions?: IFilterOptions
  ): void => {
    setLoading(true);
    taskController.getTasks(
      {
        researchId,
        PageNumber,
        PageSize,
        SearchParameter: Search ? Search : '',
        filterOptions,
      },

      (res) => {
        if (res === null) {
          setTasks([]);
          setLoading(false);
          return;
        }
        const _tasks = res.appTasks.map((_task) => {
          let _taskTeamUsers = _task.appTask.teams.map((_t) => _t?.users).flat();

          return {
            appTask: {
              ..._task.appTask,
            },
          };
        });
        setTasks(_tasks.map((x) => x.appTask));
        setLoading(false);
        handleItemClick({ value: 0, label: 'All Tasks' }, researchId);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    document.title = "Data List | Radvix";
    // if (params.taskId) {
    //   handleFilterDataListByTaskId({
    //     PageSize: 10,
    //     PageNumber: 1,
    //     SearchParameter: '',
    //   });
    // }
    //if (researchId) GetTasks(researchId, 1, 10, "", filterOptions);
  }, [researchId]);

  function handleFilterDataListByTaskId(_filterForm: {
    researchId: number;
    PageSize: number;
    PageNumber: number;
    SearchParameter: string;
    columnHeaderName?: string;
    sortDirection?: SortType;
    filterOptions?: IFilterOptions;
    selectedTasks?: number;
  }) {
    setLoading(true);
    if (_filterForm.sortDirection == undefined || _filterForm.sortDirection == null) {
      _filterForm.sortDirection = SortType.AutoSelect;
    }
    controller.getDataListByTaskId(
      {
        researchId: _filterForm.researchId,
        PageNumber: _filterForm.PageNumber,
        PageSize: _filterForm.PageSize,
        SearchParameter: _filterForm.SearchParameter,
        TaskId: _filterForm?.selectedTasks ?? 0,
        ColumnHeaderName: _filterForm.columnHeaderName || '',
        SortDirection: _filterForm.sortDirection || SortType.AutoSelect,
        filterOptions: _filterForm.filterOptions || {},
      },
      (_dataList) => {
        if (!_dataList) {
          setLoading(false);
          setDataList([]);
          setPaginate((prev) => ({
            ...prev,
            pageCount: 0,
            totalCount: 0,
          }));
          return;
        }
        setDataList(_dataList.datas);
        if (_dataList.datas && _dataList.datas.length > 0) {
          //setResearchId(_dataList.datas[0].researchId);
        }
        setTaskId(_dataList.mainTaskId);
        setPaginate((prev) => ({
          ...prev,
          pageCount: Math.ceil(_dataList.count! / paginate.pageSize.value),
          totalCount: _dataList.count,
        }));
        setLoading(false);
      },
      (_err) => {
        setLoading(false);
      }
    );
  }

  useDebounceEffect(
    () => {
      if (searchValue.length > 0)
        handleFilterDataListByTaskId({
          researchId: selectedProject?.value,
          PageNumber: paginate.pageNumber + 1,
          PageSize: paginate.pageSize.value,
          SearchParameter: searchValue,
        });
    },
    500,
    [searchValue]
  );

  useEffect(() => {
    dispatch(SetHeaderTitle('Data'));
    loadResearches();

    GetTasks(store.getState().ResearchId.value, 1, 1000, '', filterOptions);
  }, []);

  const headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    setHeaderName(columnHeaderName || '');
    setSortDirection(sortDirection || SortType.AutoSelect);
    const _filterForm = {
      researchId: selectedProject?.value,
      PageSize: paginate.pageSize.value,
      PageNumber: paginate.pageNumber + 1,
      SearchParameter: searchValue,
      columnHeaderName,
      sortDirection: SortType.AutoSelect,
      filterOptions,
    };
    handleFilterDataListByTaskId(_filterForm);
  };
  const filterClick = (
    selectedFromDate?: Date,
    selectedToDate?: Date,
    status?: number[],
    teamType?: number[]
  ) => {
    var filterOptions = {
      selectedFromDate: selectedFromDate,
      selectedToDate: selectedToDate,
      status: status,
      teamType: teamType,
    };
    setFilterOptions(filterOptions);
    const _filterForm = {
      researchId: selectedProject?.value,
      PageSize: paginate.pageSize.value,
      PageNumber: paginate.pageNumber + 1,
      SearchParameter: searchValue,
      headerName,
      sortDirection,
      filterOptions,
    };
    handleFilterDataListByTaskId(_filterForm);
  };
  const handleToggle = () => {
    setShowFilterOptions(!showFilterOptions);
  };

  const handleStartDateChange = (value: any) => {
    setStartDate(value);
    filterClick(value, endDate);
  };
  const handleEndDateChange = (value: any) => {
    setEndDate(value);
    filterClick(startDate, value);
  };

  const loadResearches = () => {
    researchController.getResearches(
      {
        PageNumber: 1,
        PageSize: 100,
        SearchParameter: '',
        columnHeaderName: '',
        sortDirection: SortType.AutoSelect,
      },
      (res) => {
        let researches: { value: any; label: any }[] = [];
        if (res && res.researchesList && res.researchesList.length > 0) {
          researches = res.researchesList?.map(({ id, title }) => ({
            value: id,
            label: title,
          }));
        }
        //researches?.unshift({ value: 0, label: "All Projects" });
        setResearhList(researches);
      },
      (err) => {}
    );
  };

  const handleChangeProject = (e: any) => {
    setSelectedProject(e);
    userController.lastSelectedResearch(
      e.value,
      (res) => {},
      () => {
        // setTimelineLoading(false);
      }
    );
    GetTasks(e?.value, 1, 1000, '', filterOptions);

    if (e && e.value !== 0) {
      dispatch(SetResearchId({ label: e?.label, value: e?.value }));
    }
    // setResearchId(e.value)
    // setTaskId(e.value);

    // setResearchId(e.value)
    // params.taskId == e.value
    //
    // GetTasks(e.value, 1, 10)
  };

  const tasksMapped = Tasks.map((task) => ({
    value: task.id,
    label: task.title,
  }));
  tasksMapped.unshift({ value: 0, label: 'All Tasks' });

  const handleItemClick = (task: { value: any; label: any }, researchId: number) => {
    setSelectedTask(task);
    setSelectedTaskId(task.value);
    handleFilterDataListByTaskId({
      researchId: researchId,
      PageSize: 10,
      PageNumber: 1,
      SearchParameter: '',
      sortDirection: undefined,
      filterOptions: {},
      selectedTasks: task.value,
    });
  };
  const handleToggleTippy = () => {
    if (visibleTippy == false) {
      setVisibleTippy(true);
    } else {
      setVisibleTippy(false);
    }
  };
  const downloadFile = (props: any) => {
    if (props.externalUrl) {
      const aElement = document.createElement('a');
      aElement.href = props.externalUrl;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(props.externalUrl);
    } else if (props?.name) {
      const aElement = document.createElement('a');
      aElement.href = props.name;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(props.name);
    }
  };
  return (
    <div className='card'>
      <div className='d-flex justify-content-between align-items-center border-bottom border-gray-400 p-3 row-gap-3 flex-wrap column-gap-3'>
        <div className='d-flex align-items-center'>
          <BaseButton
            className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
            style={{ fontWeight: '400', height: '19px' }}
            onClick={() => {
              window.history.back();
            }}
          >
            Back
          </BaseButton>
          <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>Data Collection</h2>
        </div>
        <MainButton
          className='btn btn-primary fs-15 py-px-7 new-project-button'
          svg={
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='15'
              height='15'
              viewBox='0 0 15 15'
              fill='none'
            >
              <rect width='15' height='15' fill='white' fill-opacity='0.01'></rect>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z'
                fill='white'
              ></path>
            </svg>
          }
          onClick={() => {
            history.push($ReplaceRoute(`${AppRoutes.data_new}`));
          }}
          // style={{ height: "35px" }}
        >
          Add new dataset
        </MainButton>
      </div>

      <div className='d-flex flex-wrap gap-px-12 p-3'>
        <div className='d-flex justify-content-between align-items-center justify-content-center data-list-dropwdowns'>
          <span className='me-2 text-center text-nowrap'>Project:</span>
          <DashboardProjectDropdown
            id={1}
            items={researhList}
            onSelect={(e) => {
              handleChangeProject(e);
              store.dispatch(SetResearchId({ label: e?.label, value: e?.value }));
            }}
            selectedItems={selectedProject}
            className='data-list-project-dropdown'
          />
        </div>
        <div
          className='d-flex align-items-center justify-content-center ms-xl-3 data-list-dropwdowns'
          style={{ maxWidth: '509px' }}
        >
          <span className='me-2 text-center text-nowrap'>Task:</span>
          <DashboardProjectDropdown
            id={1}
            items={tasksMapped}
            onSelect={(e) => {
              setSelectedTask(e);
              handleItemClick(e, selectedProject?.value);
            }}
            selectedItems={selectedTask}
            className='data-list-task-dropdown'
            svg={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='15'
                height='16'
                viewBox='0 0 15 16'
                fill='none'
              >
                <rect
                  width='15'
                  height='15'
                  transform='translate(0 0.5)'
                  fill='white'
                  fill-opacity='0.01'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M7.75432 2.31954C7.59742 2.22682 7.4025 2.22682 7.24559 2.31954L1.74559 5.56954C1.59336 5.65949 1.49996 5.82317 1.49996 6C1.49996 6.17683 1.59336 6.34051 1.74559 6.43046L7.24559 9.68046C7.4025 9.77318 7.59742 9.77318 7.75432 9.68046L13.2543 6.43046C13.4066 6.34051 13.5 6.17683 13.5 6C13.5 5.82317 13.4066 5.65949 13.2543 5.56954L7.75432 2.31954ZM7.49996 8.66923L2.9828 6L7.49996 3.33077L12.0171 6L7.49996 8.66923ZM2.25432 8.81954C2.01658 8.67906 1.70998 8.7579 1.56949 8.99564C1.42901 9.23337 1.50785 9.53998 1.74559 9.68046L7.24559 12.9305C7.4025 13.0232 7.59742 13.0232 7.75432 12.9305L13.2543 9.68046C13.4921 9.53998 13.5709 9.23337 13.4304 8.99564C13.2899 8.7579 12.9833 8.67906 12.7456 8.81954L7.49996 11.9192L2.25432 8.81954Z'
                  fill='#1C2024'
                />
              </svg>
            }
          />
        </div>
      </div>

      <div
        className='d-flex flex-md-row border-top border-gray-400 py-3 flex-column row-gap-md-0 row-gap-px-16 px-3 pb-3 filters'
        style={{ width: '100%' }}
        id='showFilter'
      >
        <div className='pe-md-3 border-end-md-1 border-gray-400 d-sm-block d-flex gap-3 flex-grow-1 filters-search-wrapper'>
          <InputIcon
            className='form-control h-px-35'
            placeholder='Search Dataset'
            chilren={undefined}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </div>
        <div className='d-flex align-items-center w-100 w-md-50 w-xl-auto ps-md-3'>
          <div className='w-50 date-pickers-wrapper'>
            <div className='date-picker-wrapper'>
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                dateFormat='MM/dd/yyyy'
                placeholderText='Start Date'
                maxDate={new Date()}
                className='form-control h-px-35 customBorders date-picker'
              />
            </div>
          </div>
          <div className='px-2 flex-shrink-0 color-gray-400'>&ndash;</div>
          <div className='w-50 date-pickers-wrapper'>
            <div className='date-picker-wrapper'>
              <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                dateFormat='MM/dd/yyyy'
                placeholderText='End Date'
                //maxDate={new Date()}
                className='form-control h-px-35 customBorders date-picker'
              />
            </div>
          </div>
        </div>
      </div>

      <RadixTable
        data={dataList}
        loading={loading}
        // onHeaderClick={(columnHeaderName: string, sortDirection: SortType) =>
        //   headerClick(columnHeaderName, sortDirection)
        // }
        columns={[
          {
            Header: 'Dataset name',
            accessor: 'appTaskTitle',
            Cell: (_props: any) => {
              return (
                <Table.Cell className='p-3'>
                  <div className='line-clamp-2'>
                    <i className='fa fa-grip-vertical me-2 color-gray-600 fs-13'></i>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        history.push(
                          $ReplaceRoute(
                            `${AppRoutes.data_profile
                              .replace(':dataid', _props.id)
                              .replace(':appTaskId', _props.taskId?.toString() ?? '')}?researchId=${
                              _props.researchId
                            }`
                          )
                        );
                      }}
                      title={_props.title}
                    >
                      {' '}
                      {_props.title}{' '}
                    </span>
                  </div>
                </Table.Cell>
              );
            },
          },
          {
            Header: 'Files',
            accessor: 'medias',
            Cell: (props: any) => {
              return (
                <Table.Cell className='p-3 text-nowrap'>
                  {/* {<ShowFilesInTable medias={_row.medias} profileId={0} profileType={0} /> */}
                  <div style={{ display: 'flex', gap: '4px' }}>
                    {props.medias && props.medias.length > 0 && (
                      <span
                        key={props.medias[0]}
                        className='badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team text-truncate'
                        onClick={() => downloadFile(props.medias[0])}
                        style={{ cursor: 'pointer', maxWidth: '400px' }}
                      >
                        {props.medias[0].externalUrl
                          ? props.medias[0].externalUrl
                          : props.medias[0].title}
                      </span>
                    )}
                    {props.medias.slice(1).length > 0 && (
                      <>
                        <Tippy
                          content={
                            <div className='d-flex flex-column py-2 pb-0 gap-6'>
                              {props.medias.slice(1).map((media: any) => (
                                <span
                                  key={media.id}
                                  className='badge clip-path text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team'
                                  onClick={() => downloadFile(media)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {media.externalUrl ? media.externalUrl : media.title}
                                </span>
                              ))}
                            </div>
                          }
                          visible={visibleTippy}
                          onClickOutside={() => setVisibleTippy(false)}
                          interactive={true}
                          placement='bottom'
                        >
                          <span
                            onClick={handleToggleTippy}
                            className='radix-multiSelect-number'
                            style={{ cursor: 'pointer' }}
                          >
                            {`+${props.medias.slice(1).length}`}
                          </span>
                        </Tippy>
                      </>
                    )}
                  </div>
                </Table.Cell>
              );
            },
          },
          {
            Header: 'Added by',
            accessor: 'creatorFirstName',
            Cell: (props: any) => {
              return (
                <Table.Cell className='p-3'>
                  <div className='d-flex align-items-center text-nowrap'>
                    {
                      <ImageWithToken
                        originalImage={props.creatorUser?.image}
                        hasImage={props.creatorUser?.image ? true : false}
                        alt='Avatar'
                        className='rounded-circle avatar rounded-avatar-dashboard'
                        src={
                          props.creatorUser?.image === null ||
                          props.creatorUser?.image === '' ||
                          props.creatorUser?.image === undefined
                            ? '/images/images/img_avatar.png'
                            : props.creatorUser?.image
                        }
                        textInAvatar={`${props.creatorUser?.firstName} ${props.creatorUser?.lastName}`}
                      />
                    }
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push(
                          $ReplaceRoute(
                            `${AppRoutes.member_profile.replace(
                              ':id',
                              props.creatorUser.id?.toString() ?? ''
                            )}`
                          )
                        );
                      }}
                    >
                      {props.creatorUser?.firstName} {props.creatorUser?.lastName}
                    </span>
                  </div>
                </Table.Cell>
              );
            },
          },
          {
            Header: 'Date attached',
            accessor: 'createdDate',
            Cell: (props: any) => {
              return (
                <Table.Cell>{moment(props.createdDate).format(MOMENT_DATE_FORMAT)}</Table.Cell>
              );
            },
          },
          {
            Header: '',
            Cell: (props: any) => {
              return (
                <Table.Cell className='p-3 text-nowrap'>
                  <div className='d-flex align-items-center gap-3'>
                    <div className='btn-group'>
                      <button
                        type='button'
                        className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                        onClick={(e) => {
                          history.push(
                            $ReplaceRoute(
                              `${AppRoutes.data_profile
                                .replace(':dataid', props.id)
                                .replace(
                                  ':appTaskId',
                                  props.taskId?.toString() ?? ''
                                )}?researchId=${props.researchId}`
                            )
                          );
                        }}
                      >
                        <i className='fa fa-eye me-px-6'></i>View Details
                      </button>
                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger>
                          <button
                            type='button'
                            className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                          >
                            <span className='visually-hidden'>Toggle Dropdown</span>
                          </button>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content>
                          <DropdownMenu.Item className='px-0'>
                            <button
                              className='dropdown-item fs-14'
                              onClick={(e) => {
                                history.push(
                                  $ReplaceRoute(
                                    `${AppRoutes.data_profile
                                      .replace(':dataid', props.id)
                                      .replace(
                                        ':appTaskId',
                                        selectedTaskId?.toString() ?? ''
                                      )}?researchId=${props.researchId}`
                                  )
                                );
                              }}
                            >
                              View Details
                            </button>
                          </DropdownMenu.Item>
                          <DropdownMenu.Item className='px-0'>
                            <button
                              className='dropdown-item fs-14'
                              onClick={(e) => {
                                history.push($ReplaceRoute(`${AppRoutes.discussion_new}`));
                              }}
                            >
                              Start discussion
                            </button>
                          </DropdownMenu.Item>
                        </DropdownMenu.Content>
                      </DropdownMenu.Root>
                    </div>
                  </div>
                </Table.Cell>
              );
            },
          },
        ]}
      />
      {paginate.pageCount > 1 && (
        <Pagination
          pageCount={paginate.pageCount}
          onPageChange={(e: any) => {
            setPaginate((prev) => ({
              ...prev,
              pageNumber: e.selected,
            }));

            handleFilterDataListByTaskId({
              researchId: selectedProject?.value,
              PageNumber: e.selected + 1,
              PageSize: paginate.pageSize.value,
              SearchParameter: searchValue,
              columnHeaderName: headerName,
              sortDirection: sortDirection,
            });
          }}
          onPageSizeChange={(e: any) => {
            setPaginate((prev) => ({ ...prev, pageSize: e }));
          }}
          pageInput={paginate.PageNumberInput}
          handlePageNumberInput={(e: any) => {
            setPaginate((prev) => ({
              ...prev,
              pageNumber: e.selected,
            }));

            handleFilterDataListByTaskId({
              researchId: selectedProject?.value,
              PageNumber: e.selected + 1,
              PageSize: paginate.pageSize.value,
              SearchParameter: searchValue,
              columnHeaderName: headerName,
              sortDirection: sortDirection,
            });
          }}
        />
      )}
    </div>
  );
}
