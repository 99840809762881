type ChatEmailSlackTabsProps = {
  selectedCommunicationTab: number;
  setSelectedTab: (tabNumb: number) => void;
};

export default function ChatEmailSlackTabs({
  selectedCommunicationTab,
  setSelectedTab,
}: ChatEmailSlackTabsProps) {
  return (
    <div className='d-flex gap-4 align-items-center flex-nowrap scrollmenu'>
      <div
        className={
          selectedCommunicationTab === 0
            ? 'overviwe-item px-0'
            : 'overviwe-item px-0 button-dashboard-category'
        }
        onClick={() => setSelectedTab(0)}
      >
        <div className='dashboardProjectDetailItem'>
          <div
            className='d-flex flex-column align-items-center dashboardProjectDetailItem'
            id='pills-notes-tab'
          >
            <div
              className={
                selectedCommunicationTab === 0 ? 'dashboardSelectedCategory' : 'dashboardCategory'
              }
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                color={selectedCommunicationTab === 0 ? 'white' : 'black'}
              >
                <rect width='20' height='20' fill='white' fill-opacity='0.01' />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M16.6667 3.99996L3.33333 3.99999C2.22876 3.99999 1.33333 4.89543 1.33333 5.99999V12.6667C1.33333 13.7713 2.22876 14.6666 3.33333 14.6666H10C10.1769 14.6666 10.3464 14.7369 10.4714 14.862L13.3333 17.7238V15.3333C13.3333 14.9652 13.6319 14.6666 14 14.6666H16.6667C17.7712 14.6666 18.6667 13.7713 18.6667 12.6667V5.99996C18.6667 4.89539 17.7712 3.99996 16.6667 3.99996ZM3.33332 2.66665L16.6667 2.66663C18.5076 2.66663 20 4.15901 20 5.99996V12.6667C20 14.5076 18.5076 16 16.6667 16H14.6667V19.3333C14.6667 19.6029 14.5043 19.846 14.2551 19.9492C14.006 20.0524 13.7192 19.9953 13.5285 19.8048L9.72389 16H3.33333C1.49239 16 0 14.5076 0 12.6667V5.99999C0 4.15904 1.49237 2.66667 3.33332 2.66665Z'
                  fill='white'
                />
              </svg>
              <span className='textWhite'>Chat</span>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          selectedCommunicationTab === 1
            ? 'overviwe-item  px-0'
            : 'overviwe-item  px-0 button-dashboard-category'
        }
        onClick={() => setSelectedTab(1)}
        style={{ pointerEvents: 'none', opacity: '0.55' }}
      >
        <div className=' dashboardProjectDetailItem'>
          <div
            className='d-flex flex-column align-items-center dashboardProjectDetailItem'
            id='pills-description-tab'
          >
            <div
              className={
                selectedCommunicationTab === 1 ? 'dashboardSelectedCategory' : 'dashboardCategory'
              }
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                color={selectedCommunicationTab === 1 ? 'white' : 'black'}
              >
                <rect width='20' height='20' fill='white' fill-opacity='0.01' />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M1.33333 2.66663C0.596953 2.66663 0 3.26359 0 3.99996V16C0 16.7364 0.596953 17.3333 1.33333 17.3333H18.6667C19.4031 17.3333 20 16.7364 20 16V3.99996C20 3.26359 19.4031 2.66663 18.6667 2.66663H1.33333ZM1.33333 3.99996H18.6667V5.23321C18.5565 5.23311 18.4451 5.26331 18.3452 5.32669L10 10.6227L1.65483 5.32669C1.55495 5.26331 1.44347 5.23311 1.33333 5.23321V3.99996ZM1.33333 6.54392V16H18.6667V6.54392L10.3215 11.8399C10.1253 11.9644 9.87475 11.9644 9.67851 11.8399L1.33333 6.54392Z'
                  fill='#1C2024'
                />
              </svg>
              <span className='textWhite'>Email</span>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          selectedCommunicationTab === 2
            ? 'overviwe-item  px-0'
            : 'overviwe-item  px-0 button-dashboard-category'
        }
        style={{ pointerEvents: 'none', opacity: '0.55' }}
        onClick={() => setSelectedTab(2)}
      >
        <div className='d-flex flex-column align-items-center dashboardProjectDetailItem'>
          <div
            className={
              selectedCommunicationTab === 2 ? 'dashboardSelectedCategory' : 'dashboardCategory'
            }
            style={{}}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
              color={selectedCommunicationTab === 2 ? 'white' : 'black'}
            >
              <rect width='20' height='20' fill='white' fill-opacity='0.01' />
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M6.66684 6.18133C6.66684 5.01373 6.98976 4.14051 7.52448 3.56474C8.05263 2.99605 8.86127 2.63771 10.002 2.63771C11.1434 2.63771 11.9509 2.99534 12.4778 3.56275C13.0113 4.1373 13.3335 5.00942 13.3335 6.17765V7.99997H6.66684V6.18133ZM5.33351 7.99997V6.18133C5.33351 4.77529 5.72469 3.54337 6.54749 2.65741C7.37687 1.76437 8.56917 1.30438 10.002 1.30438C11.4346 1.30438 12.6262 1.76309 13.4548 2.65547C14.277 3.54074 14.6668 4.77193 14.6668 6.17765V7.99997H16.0002C16.7366 7.99997 17.3335 8.59693 17.3335 9.3333V17.3333C17.3335 18.0697 16.7366 18.6666 16.0002 18.6666H4.00017C3.2638 18.6666 2.66684 18.0697 2.66684 17.3333V9.3333C2.66684 8.59693 3.2638 7.99997 4.00017 7.99997H5.33351ZM4.00017 9.3333H16.0002V17.3333H4.00017V9.3333Z'
                fill='#60646C'
              />
            </svg>

            <span className='textWhite'>Slack</span>
          </div>
        </div>
      </div>
    </div>
  );
}
