import React from "react";
import { store } from "../../../data/storeMain";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import { InputComponent, InputType } from "../../components/inputs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MainButton, MainButtonType } from "../../components/button";
import Dropzone from "react-dropzone";
import { ButtonGroup } from "../../components/botton_group";
import { RouteComponentProps, withRouter } from "react-router";
import { AppConstants, AppRoutes, LOADING_MODE } from "../../../core/constants";
import { ResearchController } from "../../../controllers/research/research_controller";
import SimpleReactValidator from "simple-react-validator";
import { UploadController } from "../../../controllers/upload_media/upload_media";
import {
  $ReplaceRoute,
  convertTeamUserToNewTree,
  convertUsersToUncategorized,
  getUserTitle,
  isTokenInHttpAuth,
  uploadAllFiles,
  uploadAllLinks,
} from "../../../core/utils";
import { LocalDataSources } from "../../../data/local_datasources";
import { HTTP } from "../../../core/http_common";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import NewTreeMenu, { TreeOptionType } from "../../components/NewTreeMenu";
import CircleIconTooltip from "../../components/CircleIconTooltip";
import { SelectComponent } from "../../components/select_input";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import { Button } from "@radix-ui/themes";
import { TreeMenu } from "../../components/TreeMenu";
import { MdAdd } from "react-icons/md";
import { BaseButton } from "@fluentui/react";
import { SetResearchId } from "../../../data/storeMain/actions/research_action";
import { GroupController } from "../../../controllers/group/group_controller";
import { SetHeaderTitle } from "../../../data/storeMain/actions/header_title_action";

const ONE_DAY = 24 * 60 * 60 * 1000;

type StateType = {
  selectedTeamsMembers: Array<any>;
  listTeams: Array<any>;
  listWorkspaces: Array<any>;
  forceSelectedMembers: string[];
  unCheckedValues: string[];
  startDate: Date;
  endDate: Date;
  isLockEndTime: boolean;
  files: Array<File>;
  title: string;
  description: string;
  currency: number;
  priority: number;
  teamsId: Array<number>;
  usersId: Array<string>;
  status: number;
  loading: boolean;
  ExternalUrl: Array<string>;
  External: string;
  searchLoading: boolean;
  listTeamMembers: TreeOptionType[];
  selectedTeamsId: number[];
  selectedUsersId: string[];
  defaultWorkspace: TreeOptionType[];
  loginWorkSpace: any[];
  managersId: string[];
  listManagers: Array<{ label: string; value: string } | {}>;
  managerId: string;
};

class GroupPageNew extends React.Component<RouteComponentProps> {
  RoleUser = store.getState().userRole;
  controller = new GroupController();
  UploadController = new UploadController();
  local = new LocalDataSources();
  validator = new SimpleReactValidator({
    className: "text-danger",
  });

  state: StateType = {
    startDate: new Date(),
    endDate: new Date(new Date().getTime() + ONE_DAY),
    isLockEndTime: true,
    unCheckedValues: [],
    forceSelectedMembers: [],
    listTeams: [],
    listWorkspaces: [],
    selectedTeamsMembers: [],
    files: [],
    title: "",
    description: "",
    currency: 0,
    priority: 1,
    teamsId: [],
    usersId: [],
    status: 0,
    loading: false,
    ExternalUrl: [],
    External: "",
    searchLoading: false,
    listTeamMembers: [],
    listManagers: [],
    selectedTeamsId: [],
    selectedUsersId: [],
    defaultWorkspace: [],
    loginWorkSpace: [],
    managersId: [],
    managerId: "",
  };

  handleChange(target: string, val: any) {
    if (target === "priority") {
      this.setState({
        [target]: val,
      });
    } else {
      const sanitizedValue = DOMPurify.sanitize(val);
      this.setState({
        [target]: sanitizedValue,
      });
    }
  }

  handelChangeDate(
    startDate: Date = this.state.startDate,
    endDate: Date = this.state.endDate
  ): void {
    this.setState({
      startDate,
      endDate,
      isLockEndTime: false,
    });
  }

  handleCreateGroup() {
    ;
    const body = {
      title: this.state.title,
      description: this.state.description,
      startDate: new Date(this.state.startDate).toISOString(),
      endDate: new Date(this.state.endDate).toISOString(),
      priority: this.state.priority,
      groupManagers: this.state.managersId.map((manager: any) => manager.value.toString()), 
    };
    this.setState({
      loading: true,
      loadingMode: "Starting group..",
    });
    this.controller.createGroup(
      body,
      async (res) => {
        this.setState({
          title: "",
          description: "",
          startDate: new Date(),
          endDate: new Date(new Date().getTime() + ONE_DAY),
          isLockEndTime: true,
          priority: 2,
          status: 0,
          loadingMode: "",
        });
        this.props.history.push($ReplaceRoute(`${AppRoutes.research}`));
      },
      (err) => {
        this.setState({
          loading: false,
          loadingMode: "",
        });
      }
    );
  }

  formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return "0 MB";

    const m = 1024 * 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizeInMB = bytes / m;

    return `${sizeInMB.toFixed(dm)} MB`;
  }

  handelChangeSelectTest(
    e: Array<{ label: string; value: number; isUser: boolean }>
  ) {
    this.setState({
      testListDropdown: e,
      testListDropdownId: e.map((item) => item.value),
    });
  }

  componentDidMount() {
    document.title = "New Group | Radvix";
    store.dispatch(SetHeaderTitle('Projects'))
    if (!isTokenInHttpAuth(HTTP)) return;

    this.setState({ loading: true });

    this.controller.groupSearch(
      (res) => {
        const managersList = res.managers ? res.managers.map((item: any) => {
          const _userTitle = getUserTitle(item);
          return {
            label: _userTitle,
            value: item.userId,
            secondary: item.invitationStatus === 1 ? "Pending" : null,
          };
        }) : [];

        const _myId = this.local.getUserId();
        const _myInfo = this.local.getUserInfo();

        const _meMySelf = res.managers.length > 0
          ? res.managers.find((_user: any) => _myId === _user.userId)
          : {
            firstName: _myInfo.firstName,
            userId: _myId,
            lastName: _myInfo.lastName,
          };

        const defaultManager = {
          label: `${_meMySelf?.firstName} ${_meMySelf?.lastName}`,
          value: _meMySelf?.userId,
        };

        this.setState({
          listManagers: managersList,
          managerId: defaultManager.value,
          managersId: [defaultManager],
          loading: false,
        });
      },
      (err) => {
        this.setState({ loading: false });
        if (err.response?.data.message) {
          toast.error(`${err.response.data.message}`);
        } else {
          toast.error("An error occurred while fetching groups.");
        }
      }
    );
  }


  async handelUpload(id: number) {
    this.setState({
      loadingMode: LOADING_MODE.links,
    });
    await uploadAllLinks(id, this.state.ExternalUrl, 0);

    this.setState({
      loadingMode: LOADING_MODE.files,
    });
    await uploadAllFiles(id, this.state.files, 0);
  }

  onDrop = (fileList: any) => {
    fileList.map((item: any, index: number) => {
      const found = this.state.files.find(
        (element: any) => element.name === item.name
      );
      if (found === undefined) {
        this.setState({ files: [fileList[index], ...this.state.files] });
      }
    });
  };

  handelDeleteFile(arg: File) {
    this.setState({
      files: this.state.files.filter((file) => file.name !== arg.name),
    });
  }

  addExternalUrl() {
    if (this.validator.fields.input) {
      let Url = [...this.state.ExternalUrl];
      if (this.state.External.length > 2) {
        Url.push(this.state.External);
      }
      this.setState({
        ExternalUrl: Url,
        External: "",
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handelDeleteExternalLink(link: string) {
    this.setState({
      ExternalUrl: this.state.ExternalUrl.filter((item) => item !== link),
    });
  }

  bytesToMB(bytes: number) {
    return (bytes / (1024 * 1024)).toFixed(3);
  }

  render() {
    const _creatorId = localStorage.getItem("userId");
    const files = this.state.files.map((file: any) => (
      <li
        key={file.name}
        className="d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team"
        style={{ marginBottom: "3px" }}
      >
        <span
          className="text-truncate col-11 mx-2"
          style={{ fontFamily: "Sanfransisco", textAlign: "start" }}
        >
          {file.name} - {this.formatBytes(file.size)}
        </span>
        <CircleIcon
          type={ThemeCircleIcon.dark}
          width="22px"
          height="22px"
          padding="0"
          onClick={() => {
            this.handelDeleteFile(file);
          }}
          style={{ cursor: "pointer", display: "contents" }}
        >
          <img
            src="/images/icons/cross-1.svg"
            alt="radvix"
            width={15}
            height={15}
          />
        </CircleIcon>
      </li>
    ));

    const oneDayMillis = 24 * 60 * 60 * 1000;
    const defaultEndDate = new Date(
      this.state.startDate.getTime() + oneDayMillis
    );
    const _isEndDateBeforeStartDate = this.state.endDate < this.state.startDate;

    return (
      <div className="card">
        <div
          className="d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
        border-gray-400"
        >
          <div className="d-flex align-items-center">
            {/* <button className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none"> Back</button> */}
            <BaseButton
              className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button"
              style={{ fontWeight: "400", height: "19px" }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className="fw-medium fs-15 mb-0 color-gray-1000">
              Create a new group
            </h2>
          </div>
        </div>
        <div className="p-3 overflow-auto">
          <div className="row">
            <div className="col-lg-6 mobileViewMargin">
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div className="row mt-1 mb-3">
                    <InputComponent
                      isExternalURL={true}
                      className="form-control"
                      disabled={this.state.searchLoading}
                      type={InputType.text}
                      label="Group name*"
                      placeholder="Enter your group name"
                      popQuestion="This will be the name of your research project. You can always edit this later."
                      onChange={(e) => {
                        this.handleChange("title", e.target.value);
                      }}
                      inValid={this.validator.message(
                        "Research Name",
                        this.state.title,
                        "required"
                      )}
                    ></InputComponent>
                  </div>
                  <div className="row mt-1 mb-3">
                    <div className="col-lg-12">
                      <div
                        className="text-primary cursor-pointer mb-1"
                        title="Info"
                      >
                        <label className="color-gray-600 fs-15 me-2">
                          Group description*
                        </label>
                      </div>
                      <InputComponent
                        type={InputType.textarea}
                        disabled={this.state.searchLoading}
                        isExternalURL={true}
                        //label="Project description"
                        //optional="optional"
                        height="132px"
                        popQuestion="You can write a description for your team. For example, what is the main focus of this team and what are the key objectives. You can always edit this later."
                        className="mt-1"
                        onChange={(e) => {
                          this.handleChange("description", e.target.value);
                        }}
                      ></InputComponent>
                    </div>
                  </div>
                  <div className="row mt-1 mb-3">
                    <div className="col-lg-12">
                      <div className="text-primary cursor-pointer">
                        <span className="color-gray-600 fs-15 me-2">
                          Scheduled timeline*
                        </span>
                      </div>
                      <div className="row mt-1">
                        <div className="col-lg-6">
                          <div className="date-picker-wrapper">
                            <DatePicker
                              disabled={this.state.searchLoading}
                              selected={this.state.startDate}
                              className="control-border-color"
                              onChange={(e) => {
                                this.handelChangeDate(
                                  e as Date,
                                  this.state.isLockEndTime &&
                                    (e as Date)?.getTime() >
                                    this.state.endDate?.getTime()
                                    ? new Date((e as Date)?.getTime() + ONE_DAY)
                                    : undefined
                                );
                              }}
                              maxDate={
                                this.state.isLockEndTime
                                  ? undefined
                                  : new Date(
                                    this.state.endDate?.getTime() - ONE_DAY
                                  )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="date-picker-wrapper">
                            <DatePicker
                              selected={this.state.endDate}
                              disabled={this.state.searchLoading}
                              className="control-border-color"
                              onChange={(e) => {
                                this.handelChangeDate(
                                  this.state.isLockEndTime &&
                                    (e as Date)?.getTime() <
                                    this.state.startDate?.getTime()
                                    ? new Date(
                                      (e as Date)?.getTime() -
                                      AppConstants.one_day_milis
                                    )
                                    : undefined,
                                  e as Date
                                );
                              }}
                              minDate={
                                this.state.isLockEndTime
                                  ? undefined
                                  : new Date(
                                    this.state.startDate?.getTime() + ONE_DAY
                                  )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-1 mb-3">
                    <div className="col-lg-12">
                      <label className="color-gray-600 fs-15 me-2 mb-1">
                        Priority*
                      </label>
                      <div className="d-flex border-gray-400 border soft-radios p-1 rounded-px-4 w-100">
                        <div className="w-33_3">
                          <input
                            type="radio"
                            className="btn-check"
                            name="options"
                            id="low"
                            autoComplete="off"
                            checked={this.state.priority === 0}
                            onClick={() => this.handleChange("priority", 0)}
                          />
                          <label
                            className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                            htmlFor="low"
                          >
                            Low
                          </label>
                        </div>
                        <div className="w-33_3">
                          <input
                            type="radio"
                            className="btn-check"
                            name="options"
                            id="medium"
                            autoComplete="off"
                            checked={this.state.priority === 1}
                            onClick={() => this.handleChange("priority", 1)}
                          />
                          <label
                            className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                            htmlFor="medium"
                          >
                            Medium
                          </label>
                        </div>
                        <div className="w-33_3">
                          <input
                            type="radio"
                            className="btn-check"
                            name="options"
                            id="high"
                            autoComplete="off"
                            checked={this.state.priority === 2}
                            onClick={() => this.handleChange("priority", 2)}
                          />
                          <label
                            className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                            htmlFor="high"
                          >
                            High
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mt-1 mb-3">
                <div className="col-lg-12">
                  <label className="color-gray-600 fs-15 me-2 mb-1">
                    Group manager*
                  </label>
                  <div className="item">
                    <SelectComponent
                      disabled={this.state.searchLoading}
                      items={this.state.listManagers}
                      TextItem="name"
                      ValueItem="id"
                      className="my-1"
                      placeholder="Click to see the list…"
                      // label="Assign managers"
                      popQuestion="Team managers have the power to add more members to a team as well as updating team information on this page."
                      defaultValue={this.state.managersId}
                      onChange={(selectedOptions) => {
                        ;
                        const selectedManagers = selectedOptions.map((option: { label: any; value: any; }) => ({
                          label: option.label,
                          value: option.value,
                        }));
                        this.setState({
                          managersId: selectedManagers,
                          managerId: selectedManagers.map((option: { value: any; }) => option.value),
                        });
                      }}
                      isMulti={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <MainButton
                className="btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2 responsive-Button-width"
                svg={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <rect
                      width="15"
                      height="15"
                      fill="white"
                      fill-opacity="0.01"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.5 2.00006C3.22386 2.00006 3 2.22392 3 2.50006V12.5001C3 12.7762 3.22386 13.0001 3.5 13.0001H11.5C11.7761 13.0001 12 12.7762 12 12.5001V4.70717L9.29289 2.00006H3.5ZM2 2.50006C2 1.67163 2.67157 1.00006 3.5 1.00006H9.5C9.63261 1.00006 9.75979 1.05274 9.85355 1.14651L12.7803 4.07328C12.921 4.21394 13 4.4047 13 4.60361V12.5001C13 13.3285 12.3284 14.0001 11.5 14.0001H3.5C2.67157 14.0001 2 13.3285 2 12.5001V2.50006ZM4.75 7.50006C4.75 7.22392 4.97386 7.00006 5.25 7.00006H7V5.25006C7 4.97392 7.22386 4.75006 7.5 4.75006C7.77614 4.75006 8 4.97392 8 5.25006V7.00006H9.75C10.0261 7.00006 10.25 7.22392 10.25 7.50006C10.25 7.7762 10.0261 8.00006 9.75 8.00006H8V9.75006C8 10.0262 7.77614 10.2501 7.5 10.2501C7.22386 10.2501 7 10.0262 7 9.75006V8.00006H5.25C4.97386 8.00006 4.75 7.7762 4.75 7.50006Z"
                      fill="white"
                    />
                  </svg>
                }
                onClick={() => {
                  this.handleCreateGroup();
                }}
                loading={this.state.loading || this.state.searchLoading}
                disabled={this.state.loading || this.state.searchLoading}
              >
                Create my group
              </MainButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(GroupPageNew);
