import React from "react";
import { store } from "../../../data/storeMain";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import { InputComponent, InputType } from "../../components/inputs";
import "react-datepicker/dist/react-datepicker.css";
import { MainButton, MainButtonType } from "../../components/button";
import Dropzone from "react-dropzone";
import { SelectComponent } from "../../components/select_input";
import { ButtonGroup } from "../../components/botton_group";
import { RouteComponentProps, withRouter } from "react-router";
import { AppRoutes, LOADING_MODE } from "../../../core/constants";
import { DiscusstionController } from "../../../controllers/discussion/discusstion_controller";
import SimpleReactValidator from "simple-react-validator";
import { LocalDataSources } from "../../../data/local_datasources";
import { UploadController } from "../../../controllers/upload_media/upload_media";
import { CreateTicketReq } from "../../../data/models/requests/discussion/create_ticket_req";
import {
  $ReplaceRoute,
  isTokenInHttpAuth,
  uploadAllFiles,
  uploadAllLinks,
} from "../../../core/utils";
import { HTTP } from "../../../core/http_common";
import { IoIosArrowDropleftCircle } from "react-icons/io";
// import { toast } from "react-toastify";
import CircleIconTooltip from "../../components/CircleIconTooltip";
import DOMPurify from "dompurify";
import { BaseButton } from "@fluentui/react";
type StateType = {
  id: number;
  categoryId: number;
  subject: string;
  priority: number;
  userId: string;
  message: string;
  files: Array<File>;
  ExternalUrl: Array<string>;
  External: string;
  loading: boolean;
  listPriority: Array<{ label: string; value: number }>;
  listticketCategory: Array<{ label: string; value: string } | {}>;
  searchLoading: boolean;
  loadingMode: "";
};
class TicketPageNew extends React.Component<RouteComponentProps> {
  RoleUser = store.getState().userRole;
  controller = new DiscusstionController();
  validator = new SimpleReactValidator({
    className: "text-danger",
  });
  UploadController = new UploadController();
  local: LocalDataSources = new LocalDataSources();
  state: StateType = {
    id: 0,
    categoryId: 0,
    subject: "",
    priority: 1,
    userId: "",
    message: "",
    files: [],
    listPriority: [],
    listticketCategory: [],
    ExternalUrl: [],
    External: "",
    loading: false,
    searchLoading: false,
    loadingMode: "",
  };
  componentDidMount() {
    document.title = `New Ticket | Radvix`;
    if (!isTokenInHttpAuth(HTTP)) return;
    this.setState({
      searchLoading: true,
    });
    this.controller.searchDiscusstion(
      {
        discussionTopic: 1,
        isTicket: true,
      },
      (res) => {
        this.setState({
          listticketCategory: res.ticketCategory
            ? res.ticketCategory.map((item) => {
                return {
                  label: item.title,
                  value: item.id,
                };
              })
            : [],
          listPriority: this.local.getSetting().priority.map((item) => {
            return { name: item.title, id: item.id };
          }),
          searchLoading: false,
        });
      },
      (err) => {
        this.setState({
          searchLoading: false,
        });
      }
    );
  }
  handelChangeSelect(e: { label: string; value: number }, target: string) {
    this.setState({ [target]: e.value });
  }
  handleChange(target: string, val: any) {
    if (target === "priority") {
      this.setState({
        [target]: val,
      });
    } else {
      const sanitizedValue = DOMPurify.sanitize(val);
      this.setState({
        [target]: sanitizedValue,
      });
    }
  }
  CreateTicket() {
    // get ticketStatus from local storage - ticketStatus
    if (this.validator.allValid()) {
      const body: CreateTicketReq = {
        categoryId: this.state.categoryId,
        subject: this.state.subject,
        priority: this.state.priority,
        userId: this.local.getUserId(),
        message: this.state.message,
        ticketStatus: 1,
      };
      this.setState({
        loading: true,
        loadingMode: "Submiting ticket..",
      });
      this.controller.createTicket(
        body,
        async (res) => {
          if (this.state.files.length) {
            const _firstMsgId = res.histories[0].id;
            await this.handelUpload(_firstMsgId, res.id);
          } else {
            this.props.history.push(
              $ReplaceRoute(
                `${AppRoutes.ticketing_ticket.replace(
                  ":id",
                  res.id.toString() ?? ""
                )}`
              )
            );
          }

          this.setState({
            id: 0,
            categoryId: 0,
            subject: "",
            priority: 0,
            userId: "",
            message: "",
            files: [],
            ExternalUrl: [],
            External: "",
            loading: false,
            loadingMode: "",
          });
        },
        (err) => {
          this.setState({
            loading: false,
          });
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  async handelUpload(id: number, ticketId: number) {
    try {
      this.setState({
        loadingMode: LOADING_MODE.links,
      });
      await uploadAllLinks(id, this.state.ExternalUrl, 7);

      this.setState({
        loadingMode: LOADING_MODE.files,
      });
      await uploadAllFiles(id, this.state.files, 7);
      this.props.history.push(
        $ReplaceRoute(
          `${AppRoutes.ticketing_ticket.replace(
            ":id",
            ticketId.toString() ?? ""
          )}`
        )
      );
      this.setState({
        loading: false,
        loadingMode: "",
      });
    } catch (e) {
      this.setState({
        loading: false,
        loadingMode: "",
      });
    }

    // const formData = new FormData();
    // for (let i = 0; i < this.state.files.length; i++) {
    //   const file = this.state.files[i];
    //   formData.append("Files", file);
    // }
    // for (let i = 0; i < this.state.ExternalUrl.length; i++) {
    //   const file = this.state.ExternalUrl[i];
    //   formData.append("ExternalUrls", file);
    // }
    // formData.append("UseCase", "7");
    // formData.append("SectionId", id.toString());

    // await this.UploadController.UloadMedia(
    //   formData,
    //   (res) => {
    //     console.log(res, AppRoutes.ticketing_ticket)
    //     this.props.history.push(
    //       $ReplaceRoute(
    //         `${AppRoutes.ticketing_ticket.replace(
    //           ":id",
    //           ticketId.toString() ?? ""
    //         )}`
    //       )
    //     );
    //     this.setState({
    //       loading: false,
    //     });
    //   },
    //   () => {
    //     toast.error("Something went wrong while uploading files")
    //     this.setState({
    //       loading: false,
    //     });
    //   }
    // );
  }
  onDrop = (fileList: any) => {
    fileList.map((item: any, index: number) => {
      const found = this.state.files.find(
        (element: any) => element.name === item.name
      );
      if (found === undefined) {
        this.setState({ files: [fileList[index], ...this.state.files] });
      }
    });
  };

  formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 MB';
  
    const m = 1024 * 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizeInMB = bytes / m;
  
    return `${sizeInMB.toFixed(dm)} MB`;
  }
  
  handelDeleteFile(arg: File) {
    this.setState({
      files: this.state.files.filter((file) => file.name !== arg.name),
    });
  }
  render() {
    const files = this.state.files.map((file: any) => (
      <li
        key={file.name}
        className="d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team"
        style={{marginBottom:'3px'}}
      >
        <span
          className="text-truncate col-11 mx-2" 
          style={{ fontFamily: "Sanfransisco", textAlign:'start' }}
        >
          {file.name} - {this.formatBytes(file.size)}
        </span>
        <CircleIcon
          type={ThemeCircleIcon.dark}
          width="22px"
          height="22px"
          padding="0"
          onClick={() => {
            this.handelDeleteFile(file);
          }}
          style={{ cursor: "pointer", display: "contents" }}
        >
          <img
            src="/images/icons/cross-1.svg"
            alt="radvix"
            width={15}
            height={15}
          />
        </CircleIcon>
      </li>
    ));
    return (
      <div className="card responsive-div">
        <div
          className="d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
      border-gray-400"
        >
          <div className="d-flex align-items-center">
            <BaseButton
              className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button"
              style={{ fontWeight: "400", height: "19px" }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className="fw-medium fs-15 mb-0 color-gray-1000">
              Submit a new ticket
            </h2>
          </div>
        </div>
        <div className="p-3 overflow-auto">
          <div className="row">
            <div className="col-lg-12 mobileViewMargin">
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div className="row mt-1 mb-3">
                    <SelectComponent
                      disabled={this.state.searchLoading}
                      items={this.state.listticketCategory}
                      TextItem="title"
                      ValueItem="id"
                      labelClass="color-gray-600 fs-15 me-2 mb-1"
                      className="form-select control-border-color"
                      label="Select category*"
                      placeholder="Select category"
                      onChange={(e) => {
                        this.handelChangeSelect(e, "categoryId");
                      }}
                      isMulti={false}
                    ></SelectComponent>
                  </div>

                  <div className="row mt-1 mb-3">
                    <InputComponent
                      isExternalURL={true}
                      className="form-control"
                      disabled={this.state.searchLoading}
                      type={InputType.text}
                      label="Subject*"
                      placeholder="Subject"
                      //popQuestion="Please type a name that best represents this task for yourself as well as all users who will be working on this task with you."
                      onChange={(e) => {
                        this.handleChange("subject", e.target.value);
                      }}
                      inValid={this.validator.message(
                        "Subject",
                        this.state.subject,
                        "required"
                      )}
                    ></InputComponent>
                  </div>
                  <div className="row mt-1 mb-3">
                    <div className="col-lg-12">
                      <div
                        className="text-primary cursor-pointer mb-1"
                        title="Info"
                      >
                        <label className="color-gray-600 fs-15 me-2">
                          Issue / Comment
                        </label>
                      </div>
                      <textarea
                        className="form-control control-border-color"
                        disabled={this.state.searchLoading}
                        placeholder="Write down your issue here"
                        style={{
                          height: "132px",
                          resize: "none",
                          color: "black",
                        }}
                        onChange={(e) => {
                          this.handleChange("message", e.target.value);
                        }}
                        onInvalid={this.validator.message(
                          "message",
                          this.state.message,
                          "required"
                        )}
                      ></textarea>
                      {/* <InputComponent
                        type={InputType.textarea}
                        disabled={this.state.searchLoading}
                        isExternalURL={true}
                        //label="Project description"
                        //optional="optional"
                        placeholder="Enter task description"
                        height="132px"
                        popQuestion="Briefly explain what this task is about. This will provide clarity for yourself and other users that will be working on this task. You can still have documents, discussions within this task or edit this description later."
                        className="mt-1"
                        onChange={(e) => {
                          this.handleChange("description", e.target.value);
                        }}
                        value={this.state.description}
                      ></InputComponent> */}
                    </div>
                  </div>
                  <div className="row mt-1 mb-3">
                    <div className="col-lg-12">
                      <label className="color-gray-600 fs-15 me-2 mb-1">
                        Priority*
                      </label>
                      <div className="d-flex border-gray-400 border soft-radios p-1 rounded-px-4 w-100">
                        {this.state.listPriority.map((priority: any) => (
                          <div key={priority.id} className="w-33_3">
                            <input
                              type="radio"
                              className="btn-check"
                              name="ResearchPriority"
                              id={`priority-${priority.id}`}
                              autoComplete="off"
                              //disabled={_disabledAll}
                              checked={this.state.priority === priority.id}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.handleChange("priority", priority.id);
                                }
                              }}
                            />
                            <label
                              className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                              htmlFor={`priority-${priority.id}`}
                            >
                              {priority.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="d-flex align-items-center mb-1">
                      <label className="color-gray-600 fs-15 me-2">
                        Attachment*
                      </label>
                      {/* <img
                    className="text-primary cursor-pointer"
                    src="/Images/icons/info-circled.svg"
                    alt="info"
                    title="Research attachments are generic documents that you would like anyone who has access to this research project read and have access to them. You can either upload them or Insert the link."
                  /> */}
                    </div>
                    <div className="p-px-12 bg-gray-200 border-gray-400 border rounded-px-4 gap-2 attachment-paddingleft">
                      <div className="justify-content-sm-between justify-content-center align-items-center flex-wrap row-gap-px-8">
                        <Dropzone onDrop={this.onDrop}>
                          {({ getRootProps, getInputProps }) => (
                            // <section
                            //   className="container fileUploadBox"
                            //   style={{ marginTop: "0.3em" }}
                            // >
                            <div {...getRootProps({ className: "" })}>
                              <input {...getInputProps()} />

                              <div className="d-flex justify-content-between align-items-center flex-wrap row-gap-px-8 responsive-justify-content">
                                <button className="btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0">
                                  <div className="d-flex align-items-center">
                                    <div className="me-1 mb-1">
                                      <svg
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          width="15"
                                          height="15"
                                          fill="white"
                                          fill-opacity="0.01"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M7.81825 1.18197C7.64251 1.00624 7.35759 1.00624 7.18185 1.18197L4.18185 4.18197C4.00611 4.35771 4.00611 4.64263
              4.18185 4.81837C4.35759 4.9941 4.64251 4.9941 4.81825 4.81837L7.05005 2.58657V9.50005C7.05005 9.74858 7.25152 9.95005
              7.50005 9.95005C7.74858 9.95005 7.95005 9.74858 7.95005 9.50005V2.58657L10.1819 4.81837C10.3576 4.9941 10.6425 4.9941
              10.8182 4.81837C10.994 4.64263 10.994 4.35771 10.8182 4.18197L7.81825 1.18197ZM2.5 10.0001C2.77614 10.0001 3 10.2239 3
              10.5001V12.0001C3 12.5539 3.44565 13.0001 3.99635 13.0001H11.0012C11.5529 13.0001 12 12.5529 12 12.0001V10.5001C12
              10.2239 12.2239 10.0001 12.5 10.0001C12.7761 10.0001 13 10.2239 13 10.5001V12.0001C13 13.1041 12.1062 14.0001 11.0012
              14.0001H3.99635C2.89019 14.0001 2 13.1031 2 12.0001V10.5001C2 10.2239 2.22386 10.0001 2.5 10.0001Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </div>
                                    Browse local file
                                  </div>
                                </button>
                                <h3 className="fw-normal color-gray-700 fs-13 mb-0 flex-grow-1 text-center">
                                  {" "}
                                  Or drag and drop files here - Max 128MB{" "}
                                </h3>
                              </div>
                              {files.length > 0 && (
                                <div style={{ paddingTop: "10px" }}>
                                  <ul className="list-unstyled fw-normal">
                                    {files}
                                  </ul>
                                </div>
                              )}
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <MainButton
                className="btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2 responsive-Button-width"
                svg={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <rect
                      width="15"
                      height="15"
                      fill="white"
                      fill-opacity="0.01"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.5 2.00006C3.22386 2.00006 3 2.22392 3 2.50006V12.5001C3 12.7762 3.22386 13.0001 3.5 13.0001H11.5C11.7761 13.0001 12 12.7762 12 12.5001V4.70717L9.29289 2.00006H3.5ZM2 2.50006C2 1.67163 2.67157 1.00006 3.5 1.00006H9.5C9.63261 1.00006 9.75979 1.05274 9.85355 1.14651L12.7803 4.07328C12.921 4.21394 13 4.4047 13 4.60361V12.5001C13 13.3285 12.3284 14.0001 11.5 14.0001H3.5C2.67157 14.0001 2 13.3285 2 12.5001V2.50006ZM4.75 7.50006C4.75 7.22392 4.97386 7.00006 5.25 7.00006H7V5.25006C7 4.97392 7.22386 4.75006 7.5 4.75006C7.77614 4.75006 8 4.97392 8 5.25006V7.00006H9.75C10.0261 7.00006 10.25 7.22392 10.25 7.50006C10.25 7.7762 10.0261 8.00006 9.75 8.00006H8V9.75006C8 10.0262 7.77614 10.2501 7.5 10.2501C7.22386 10.2501 7 10.0262 7 9.75006V8.00006H5.25C4.97386 8.00006 4.75 7.7762 4.75 7.50006Z"
                      fill="white"
                    />
                  </svg>
                }
                onClick={() => {
                  this.CreateTicket();
                }}
                loading={this.state.loading || this.state.searchLoading}
                disabled={this.state.loading || this.state.searchLoading}
              >
                Submit Ticket
              </MainButton>
            </div>
          </div>
        </div>
      </div>
      // <div className="container-fluid research new-research">
      //   <div className="row"></div>
      //   <div className="col-12 box-content p-3">
      //     <h5 className="b-title d-flex align-items-center">
      //       <IoIosArrowDropleftCircle
      //         className="me-3 textSecondary2 cursorPointer"
      //         size="1.5em"
      //         onClick={() => {
      //           window.history.back();
      //         }}/>{" "}
      //       Submit a new ticket
      //     </h5>
      //     <div className="form row">
      //       <div className="col-md-6 left beforeNoneMd">
      //         <div className="item">
      //           <SelectComponent
      //             items={this.state.listticketCategory}
      //             TextItem="name"
      //             disabled={this.state.searchLoading}
      //             ValueItem="id"
      //             className="mt-1"
      //             label="Pick a category"
      //             popQuestion="Please select a category which best describes your ticket."
      //             isMulti={false}
      //             onChange={(e) => {
      //               this.handelChangeSelect(e, "categoryId");
      //             }}
      //           ></SelectComponent>
      //         </div>
      //         <div className="item">
      //           <InputComponent
      //             type={InputType.text}
      //             disabled={this.state.searchLoading}
      //             label="Subject"
      //             popQuestion="Please enter a subject for you ticket."
      //             onChange={(e) => {
      //               this.handleChange("subject", e.target.value);
      //             }}
      //             inValid={this.validator.message(
      //               "Subject",
      //               this.state.subject,
      //               "required"
      //             )}
      //           ></InputComponent>
      //         </div>
      //         <div className="item">
      //           <InputComponent
      //           disabled={this.state.searchLoading}
      //             type={InputType.textarea}
      //             label="Issue / Comment"
      //             popQuestion="Please briefly explain your questions or the issue that you would like us to review and respond accordingly."
      //             className="mt-1"
      //             onChange={(e) => {
      //               this.handleChange("message", e.target.value);
      //             }}
      //             inValid={this.validator.message(
      //               "message",
      //               this.state.message,
      //               "required"
      //             )}
      //           ></InputComponent>
      //         </div>
      //       </div>
      //       <div className="col-md-6 right">
      //         <div className="item">
      //           <span className="label d-flex align-items-center">
      //           Ticket attachments

      //             <div
      //       // type={MainButtonType.light}
      //       // children={optional}
      //       // borderRadius="50px"
      //       // fontSize="15px"
      //       className="s-chip filled mx-2"
      //     >optional</div>
      //     <CircleIconTooltip
      //     text="If needed, you can attach screenshots or recordings to your ticket so our support team can better understand the issue."
      //     />
      //           </span>
      //           <Dropzone onDrop={this.onDrop}>
      //             {({ getRootProps, getInputProps }) => (
      //               <section style={{marginTop: '0.35em'}} className="container fileUploadBox">
      //                 <div {...getRootProps({ className: "dropzone" })}>
      //                   <input {...getInputProps()} />
      //                   <MainButton
      //                     type={MainButtonType.light}
      //                     minHeight="30px"
      //                     minWidth="179px"
      //                     fontSize="14px"
      //                     disabled={this.state.searchLoading}
      //                     borderRadius="50px"
      //                     backgroundColor="#fff"
      //                     border="1px solid #707070"
      //                     color="#707070"
      //                     className="mt-4"
      //                     children={
      //                       <div className="d-flex justify-content-between align-items-center">
      //                         <img
      //                           src="/Images/icons/cloud_computing.svg"
      //                           alt="sssss"
      //                           height="20"
      //                         />{" "}
      //                         <span className="flex-fill">
      //                           Browse Local Files
      //                         </span>
      //                       </div>
      //                     }
      //                   ></MainButton>
      //                   <p>Or drag and drop files here</p>
      //                   <p className="text-center" style={{ marginTop: -20 }}>
      //                     Max 128MB
      //                   </p>
      //                 </div>
      //                 <aside>
      //                   <ul>{files}</ul>
      //                 </aside>
      //               </section>
      //             )}
      //           </Dropzone>
      //         </div>
      //         <div className="item">
      //           <ButtonGroup
      //             label="Ticket priority"
      //             popQuestion="Please select a priority tag for your ticket."
      //             name="TicketPriority:"
      //             items={this.state.listPriority}
      //             disabled={this.state.searchLoading}
      //             TextItem="name"
      //             ValueItem="id"
      //             selected={this.state.priority}
      //             onChange={(e) => {
      //               this.handleChange("priority", parseInt(e.target.value));
      //             }}
      //           ></ButtonGroup>
      //         </div>
      //       </div>
      //       <div className="col-12 d-flex flex-column justify-content-center align-items-center my-4">
      //         {/* <MainButton
      //           type={MainButtonType.light}
      //           children={"Start Over"}
      //           borderRadius="50px"
      //           fontSize="18px"
      //           className="mx-2"
      //           minHeight="43px"
      //           minWidth="136px"
      //         ></MainButton> */}
      //         <MainButton
      //           type={MainButtonType.dark}
      //           disabled={this.state.searchLoading || this.state.loading}
      //           children={"Submit ticket"}
      //           borderRadius="50px"
      //           fontSize="18px"
      //           className="mx-2"
      //           minHeight="43px"
      //           minWidth="136px"
      //           onClick={() => {
      //             this.CreateTicket();
      //           }}
      //           loading={this.state.loading || this.state.searchLoading}
      //         ></MainButton>
      //         <span className="mt-2 textSecondary">
      //           {this.state.loadingMode}
      //         </span>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}
export default withRouter(TicketPageNew);
