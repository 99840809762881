import React from "react";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import { IconEnum, InputComponent, InputType } from "../../components/inputs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MainButton, MainButtonType } from "../../components/button";
import Dropzone from "react-dropzone";
import { SelectComponent } from "../../components/select_input";
import { ButtonGroup } from "../../components/botton_group";
import { RouteComponentProps, withRouter } from "react-router";
import { AppConstants, AppRoutes, LOADING_MODE } from "../../../core/constants";
import { TaskController } from "../../../controllers/task/task_controller";
import { UploadController } from "../../../controllers/upload_media/upload_media";
import SimpleReactValidator from "simple-react-validator";
import { LocalDataSources } from "../../../data/local_datasources";
import { store } from "../../../data/storeMain";
import {
  $ReplaceRoute,
  // $truncate,
  AccessPermition,
  addSpaceBetweenWords,
  convertTeamUserToNewTree,
  // convertTeamUserToTree,
  // convertUsersIdsToIds,
  convertUsersToUncategorized,
  getUserTitle,
  // filterUsersNotExistInTeam,
  isTokenInHttpAuth,
  // removeDuplicat,
  removeDuplicateObj,
  uploadAllFiles,
  uploadAllLinks,
  UserRoles,
} from "../../../core/utils";
import { TaskReq } from "../../../data/models/requests/task/task_req";
import { HTTP } from "../../../core/http_common";
// import { InputLabel } from "../../components/input_label";
import { ITreeMenuData } from "../../components/TreeMenu";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import NewTreeMenu, { TreeOptionType } from "../../components/NewTreeMenu";
import CircleIconTooltip from "../../components/CircleIconTooltip";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import { BaseButton } from "@fluentui/react";
import { UpdateTaskReq } from "../../../data/models/requests/task/update_task_req";
import { User } from "../../../controllers/member/member_transfer_controller";
import { nanoid } from "nanoid";
import { ResearchController } from "../../../controllers/research/research_controller";
import { SetResearchId } from "../../../data/storeMain/actions/research_action";
import { Media } from "../../../data/models/responses/research/research_by_id_res";
import { SortType } from "../../../core/number_extentions";
import { SetHeaderTitle } from "../../../data/storeMain/actions/header_title_action";
const ONE_DAY = 24 * 60 * 60 * 1000;
type StateType = {
  id: any;
  defaultTeams: TreeOptionType[];
  defaultUsers: TreeOptionType[];
  defaultPerformTeams: TreeOptionType[];
  defaultPerformUsers: any[];
  mainTeams: number[];
  mainUsers: string[];
  mainSuggestedEquipments: number[];
  creatorUser: User;
  removedMedia: number[];
  status: number;
  addedFiles: any[];
  modifiedMedias: any[];
  researhList: any[];
  taskList: any[];
  filterTaskList: any[];
  selectedProject: any;
  ListStatus: Array<{ id: number; title: string }>;
  selectedTeamsMemebers: string[];
  forceSelectedMembers: string[];
  unCheckedValues: string[];
  startDate: Date;
  endDate: Date;
  isLockEndTime: boolean;
  medias: Media[];
  title: string;
  description: string;
  priority: number;
  researchId: number;
  suggestedEquipmentsId: { label: string; value: number }[];
  addedUsersId: string[];
  addedTeamsId: number[];
  files: Array<File>;
  loading: boolean;
  gettingSuggestedEqLoading: boolean;
  ExternalUrl: Array<string>;
  External: string;
  listTask: Array<{ label: string; value: number } | {}>;
  listEquipments: Array<{ label: string; value: number } | {}>;
  listPriority: Array<{ label: string; value: number } | {}>;
  parentId: { label: string; value: number } | null;
  searchLoading: boolean;
  listTeamMembers: TreeOptionType[];
  selectedTeamsId: number[];
  selectedUsersId: string[];
  selectedTeamsMembers: Array<any>;
  selectedPerformTeamsId: number[];
  selectedPerformUsersId: any[];
  selectedPerformTeamsMembers: Array<any>;
  loadingMode: string;
  researchStartDate: Date | null;
  listTeams: any[];
  listUsers: any[];
  taskTitle: {
    label: string;
    value: number;
  };
  selectedIcon: IconEnum | null;
};
let unsubscribeMeTask: any = undefined;
type ParamsType = {
  id: string;
};
class TaskPageNew extends React.Component<RouteComponentProps<ParamsType>> {
  RoleUser = store.getState().userRole;
  controller = new TaskController();
  UploadController = new UploadController();
  researchController = new ResearchController();
  validator = new SimpleReactValidator({
    className: "text-danger",
  });
  local: LocalDataSources = new LocalDataSources();
  handelChangeDate(
    startDate: Date = this.state.startDate,
    endDate: Date = this.state.endDate
  ): void {
    this.setState({
      startDate,
      endDate,
      isLockEndTime: false,
    });
  }
  loadResearches() {
    this.researchController.getResearches(
      {
        PageNumber: 1,
        PageSize: 100,
        SearchParameter: "",
        columnHeaderName: "",
      },
      (res: any) => {
        const filteredResearches = res.researchesList?.filter(
          (research: any) => research.title !== "All Projects"
        );
        const researches = filteredResearches?.map(({ id, title }: any) => ({
          value: id,
          label: title,
        }));
        this.setState({ researhList: researches });
        this.setState({
          selectedProject: store.getState().ResearchId,
        });
      },
      (err: any) => {}
    );
  }
  componentDidMount() {
    document.title = `Assign Tasks | Radvix`;
    store.dispatch(SetHeaderTitle('Task'))
    if (!isTokenInHttpAuth(HTTP)) return;
    this.loadResearches();
    this.loadTasks();
    if (
      this.props.match.params.id &&
      parseInt(this.props.match.params.id) > 0
    ) {
      this.setState({
        id: parseInt(this.props.match.params.id),
      });
      const _listStatus = this.local.getSetting().status.filter((_i) => {
        return _i.id !== 1;
      });
      this.setState({
        listPriority: this.local.getSetting().priority.map((item) => {
          return { name: item.title, id: item.id };
        }),
        ListStatus: _listStatus.map((_ls) => {
          const _title = addSpaceBetweenWords(_ls.title);
          console.log({ _ls, _title });
          return { name: _title, id: _ls.id };
        }),
      });
      
      this.GetSearchTask()
        .then((_searchData: any) => {
          const _currentResearchId = store.getState().ResearchId.value;
          this.controller.getTaskById(
            {
              TaskId: parseInt(this.props.match.params.id),
              ResearchId: _currentResearchId,
            },
            (res) => {debugger;
              document.title = `Edit Task | ${res.title} | Radvix`;
              console.log({ _searchData, res });
              //
              const __mainTeams = res.teams.map((_t) => _t.id);
              const __mainUsers = res.users.map((_u) => _u.id);
              // const __mainTeams = res.teams.filter(_rT => {
              //   const isInSearch = _searchData.teams?.some((_sT: any) => _sT.id === _rT.id)
              //   return isInSearch
              // }).map(_t => _t.id);
              // const __mainUsers = res.users.filter(_rU => {
              //   const isInSearch = _searchData.users?.some((_sU: any) => _sU.id === _rU.id)
              //   return isInSearch
              // }).map(_u => _u.id)
              const _defaultTeams = convertTeamUserToNewTree(res.teams);
              const _defaultUsers = res.users.map((_user) => {
                const _userTitle = getUserTitle(_user);
                return {
                  label: _userTitle,
                  value: _user.id,
                };
              });
              
                      const selectedOptions = res.performUsers?.map((_user) => {
                        const _userTitle = getUserTitle(_user);
                        return {
                          label: _userTitle,
                          value: _user.id,
                        };
                      });
              // const filterTeams = _searchData.teams.filter((x: any) => res.performTeams?.includes(x.id));
              // const filterUsers = _searchData.users.filter((x: any) => res.performUsers?.includes(x.id));
              // const _defaultPerformTeams = convertTeamUserToNewTree(filterTeams);
              // const _defaultPerformUsers = filterUsers.map((_user: any) => {
              //   const _userTitle = getUserTitle(_user);
              //   return {
              //     label: _userTitle,
              //     value: _user.id,
              //   };
              // });

              const _defaultDisabledUsers = res.disabledUsers.map((_u) => {
                const _uTitle = getUserTitle(_u);
                return {
                  label: _uTitle,
                  value: _u.id,
                  extraData: (
                    <p className="textSecondary">
                      This user has created Subtasks for this Task and cannot be
                      unassigned.
                    </p>
                  ),
                };
              });

              const _selectedTeams = res.teams.map((_team) =>
                _team.id.toString()
              );
              // const _selectedUsers = removeDuplicat(res.users.map((item) => item.id));
              const _selectedUsers = res.users.map(
                (_item) => `Member${AppConstants.divide}${_item.id.toString()}`
              );

              const _parentTasks = {
                value: res.parentTask?.id,
                label: res.parentTask?.title,
              };
              // defaultSuggestedEquipment
              this.setState({
                mainTeams: __mainTeams,
                mainUsers: __mainUsers,
                mainSuggestedEquipments: res.equipments.map((_eq) => _eq.id),
                suggestedEquipmentsId: res.equipments.map((_eq) => ({
                  label: _eq.title,
                  value: _eq.id,
                })),
                creatorUser: res.creatorUser,
                defaultTeams: _defaultTeams,
                defaultUsers: [..._defaultUsers, ..._defaultDisabledUsers],
                selectedPerformUsersId: selectedOptions,
                forceSelectedUsersIds: res.disabledUsers.map((_du) => _du.id),
                mainTeamsMembers: [..._selectedTeams, ..._selectedUsers],
                parentId: _parentTasks?.value ? _parentTasks : null,
                title: res.title,
                // description: res.discription,
                description: res.description,
                priority: res.priority,
                startDate: new Date(res.startDate),
                endDate: new Date(res.endDate),
                researchId: res.researchId,
                Medias: res.medias,
                files: res.medias.filter((x) => x.externalUrl == null),
                ExternalUrl: res.medias
                  .filter((x) => x.externalUrl != null)
                  .map((x) => x.externalUrl),
                modifiedMedias: res.medias,
                Equipments: res.equipments,
                status: res.status,
                selectedIcon: res.icon,
              });
              // const _defaultTeamsIds = res.teams.map(_t => _t.id);
              // const _defaultUsersIds = res.users.map(_u => _u.id);
              const _defaultTeamsIds = __mainTeams;
              const _defaultUsersIds = __mainUsers;
              console.log("VAAAAAAAAAAAAAAAAAAAA", {
                _defaultTeamsIds,
                _defaultUsersIds,
              });
              this.getSuggestedEquipments(_defaultTeamsIds, _defaultUsersIds)
                .then(() => {
                  this.setState({
                    gettingApiCallsFinished: true,
                    searchLoading: false,
                  });
                })
                .catch((e) => {
                  this.setState({
                    gettingApiCallsFinished: true,
                    searchLoading: false,
                  });
                });
            },
            (err) => {
              this.setState({
                searchLoading: false,
              });
            }
          );
        })
        .catch((_err) => {
          this.setState({
            searchLoading: false,
          });
        });
    } else {
      this.setState({
        listPriority: this.local.getSetting().priority.map((item) => {
          return { name: item.title, id: item.id };
        }),
        selectedPerformUsersId: [{label: `${store.getState().userInfo.firstName} ${store.getState().userInfo.lastName}`, value: store.getState().userInfo.id}],
        defaultPerformUsers: [{label: `${this.local.getUserInfo().firstName} ${this.local.getUserInfo().lastName}`, value: store.getState().userInfo.id}]
      });
      this.GetSearchTask().then((res: any) => {
        const _myId = this.local.getUserId();
        const __teams = convertTeamUserToNewTree(res.teams, [_myId]);
        const _uncategorizedTeam: TreeOptionType | false =
          res.users?.length > 0
            ? convertUsersToUncategorized(res.users, [_myId])
            : false;
        const __listTeamMembers: TreeOptionType[] = _uncategorizedTeam
          ? [_uncategorizedTeam, ...__teams]
          : __teams;

        this.setState({
          listTask: res.tasks?.map((item: any) => {
            return { label: item.title, value: item.id };
          }),
          researchStartDate: res.researchStartDate,
          selectedTeamsMembers: [_myId],
          selectedUsersId: [_myId],
          searchLoading: false,
          loading: false,
          listTeamMembers: __listTeamMembers,
        });
        this.getSuggestedEquipments([], [_myId]);
      });
      // unsubscribeMeTask = store.subscribe(() => {
      //   // console.log("called yoz seri");
      //   this.setState({
      //     listTeams: [],
      //     suggestedEquipmentsId: [],
      //     selectedTeamsMemebers: [],
      //     listEquipments: [],
      //     listTask: [],
      //     parentId: null
      //   })

      //   this.GetSearchTask();
      // });
    }
  }
  // clean up event listener
  componentWillUnmount() {
    // console.log('called removeEvent')
    if (unsubscribeMeTask) unsubscribeMeTask();
  }
  // @ts-ignore
  state: StateType = {
    id: 0,
    startDate: new Date(),
    endDate: new Date(new Date().getTime() + AppConstants.one_day_milis),
    isLockEndTime: true,
    unCheckedValues: [],
    selectedTeamsMembers: [],
    searchLoading: false,
    forceSelectedMembers: [],
    listTeams: [],
    selectedTeamsMemebers: [],
    files: [],
    addedTeamsId: [],
    addedUsersId: [],
    description: "",
    priority: 1,
    researchId: 0,
    suggestedEquipmentsId: [],
    title: "",
    External: "",
    ExternalUrl: [],
    loading: false,
    gettingSuggestedEqLoading: false,
    listEquipments: [],
    listPriority: [],
    listTask: [],
    parentId: null,
    listTeamMembers: [],
    selectedTeamsId: [],
    selectedUsersId: [],
    selectedPerformTeamsId: [],
    selectedPerformUsersId: [],
    selectedPerformTeamsMembers: [],
    researchStartDate: new Date(),
    removedMedia: [],
  };
  onDrop = (fileList: any) => {
    if (this.state.id && this.state.id > 0) {
      const _files = fileList.map((item: any) => {
        return {
          externalUrl: null,
          id: nanoid(),
          inputDataType: 0,
          name: item.name,
          title: item.name,
          useCase: 6,
          newAdded: true,
        };
      });
      this.setState({
        modifiedMedias: [...this.state.modifiedMedias, ..._files],
        addedFiles: [...fileList],
      });
    } else {
    }
    ;
    fileList.map((item: any, index: number) => {
      const found = this.state.files.find(
        (element: any) => element.name === item.name
      );
      if (found === undefined) {
        this.setState({ files: [fileList[index], ...this.state.files] });
      }
    });
  };
  handelDeleteFile(arg: File) {
    ;

    this.setState({
      files: this.state.files.filter((file) => file.name !== arg.name),
      modifiedMedias: this.state.modifiedMedias.filter(
        (file) => file.name !== arg.name
      ),
    });
  }

  handelRemoveMedia(id: number) {
    ;
    this.setState({
      removedMedia: [...this.state.removedMedia, id],
    });
  }

  addExternalUrl() {
    if (this.validator.fields.input) {
      let Url = [...this.state.ExternalUrl];
      if (this.state.External.length > 2) {
        Url.push(this.state.External);
      }
      this.setState({
        ExternalUrl: Url,
        External: "",
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  handelDeleteExternalLink(link: string) {
    this.setState({
      ExternalUrl: this.state.ExternalUrl.filter((item) => item !== link),
    });
  }
  handelChangeSelect(e: { label: string; value: number }) {
    this.setState({ categoryId: e.value });
  }
  handelChangeSelectTask(e: { label: string; value: number }) {
    this.setState({ parentId: e.value });
  }

  handleIconSelect = (icon: any) => {;
    this.setState({ selectedIcon: icon });
  };

  handleChange(target: string, val: any) {
    if (target == "priority" || target == "status") {
      this.setState({
        [target]: val,
      });
    } else {
      const sanitizedValue = DOMPurify.sanitize(val);
      this.setState({
        [target]: sanitizedValue,
      });
    }
  }
  async handelUpload(id: number) {
    try {
      // if(this.state.id && this.state.id > 0){
      //   this.setState({
      //     loadingMode: LOADING_MODE.links
      //   })
      //   await uploadAllLinks(id, this.state.ExternalUrl, 6);

      //   this.setState({
      //     loadingMode: LOADING_MODE.files
      //   })
      //   await uploadAllFiles(id, this.state.files, 6);

      //   this.setState({
      //     loading: false,
      //     loadingMode: ''
      //   })
      // }
      // else
      {
        this.setState({
          loadingMode: LOADING_MODE.links,
        });
        await uploadAllLinks(id, this.state.ExternalUrl, 6);

        this.setState({
          loadingMode: LOADING_MODE.files,
        });
        await uploadAllFiles(id, this.state.files, 6);

        this.setState({
          loading: false,
          loadingMode: "",
        });
        this.props.history.push(
          $ReplaceRoute(
            `${AppRoutes.task_profile.replace(":id", id.toString() ?? "")}`
          )
        );
      }
    } catch (e) {
      this.setState({
        loading: false,
        loadingMode: "",
      });
    }
  }
  loadTasks() {
    this.setState({
      loading: true,
    });
    this.controller.getTasks(
      {
        researchId: 0,
        PageNumber: 0,
        PageSize: 1000,
        SearchParameter: "",
        columnHeaderName: "",
        sortDirection: SortType.AutoSelect,        
      },
      (res) => {;       
        const _tasks = res.appTasks.map((_task) => {
          let _taskTeamUsers = _task.appTask.teams
            .map((_t) => _t?.users)
            .flat();

          const _usersWithTeamUsers = [
            ..._task.appTask.users,
            ..._taskTeamUsers,
          ];

          return {
            appTask: {
              ..._task.appTask,              
            },
          };
        });        
        const tasks = _tasks.flatMap(group => group.appTask || []);
        this.setState({ filterTaskList: [...[{label: "No parent", value: 0}],...tasks.filter((x) => x.researchId == store.getState().ResearchId.value).map(({ id, title }: any) => ({
          value: id,
          label: title,
        }))] });
        this.setState({ taskList: [...[{label: "No parent", value: 0}],...tasks] });
        this.setState({
          loading: false,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
  }
  GetSearchTask() {
    return new Promise((resolve, reject) => {
      this.setState({
        searchLoading: true,
      });
      this.controller.SearchTask(
        {
          taskId: null,          
        },
        (res) => {         
          const __teams = convertTeamUserToNewTree(res.teams);
          const _uncategorizedTeam: TreeOptionType | false =
            res.users?.length > 0
              ? convertUsersToUncategorized(res.users)
              : false;
          const __listTeamMembers: TreeOptionType[] = _uncategorizedTeam
            ? [_uncategorizedTeam, ...__teams]
            : __teams;

          // const _teams = convertTeamUserToTree(res.teams as any);

          // const _uncategorized = filterUsersNotExistInTeam(
          //   res.teams as any,
          //   res.users
          // );

          // if (_uncategorized !== null) {
          //   _teams.unshift(_uncategorized);
          // }

          this.setState({
            listTeamMembers: __listTeamMembers,
            researchStartDate: res.researchStartDate,
            listTeams: res.teams,
            listUsers: res.users,
            // unCheckedValues: _uncategorized?.value ? [_uncategorized.value] : [],
            // forceSelectedMembers: [this.local.getUserId()],
            // listEquipments: res.equipments?.map((item) => {
            //   return { label: item.title, value: item.id };
            // }),
          });
          // console.log('here')
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  handelChangeSelectMultiple(
    e: Array<{ label: string; value: number }>,
    target: string
  ) {
    const newLabId = e.map((item) => item.value);
    this.setState({
      [target]: newLabId,
    });
  }
  CreateTask() {
    ;
    if (this.validator.allValid()) {
      this.setState({
        loading: true,
        loadingMode: "Starting..",
      });
if(!this.state.selectedPerformUsersId){
        toast.error("Please assign the task at least to one user");
  return;
}
      
      // const _teams = this.state.selectedTeamsMemebers
      //   .filter((item) => !isNaN(Number(item)))
      //   .map((item) => +item);
      // let _users = this.state.selectedTeamsMemebers.filter((item) =>
      //   isNaN(Number(item))
      // );

      // _users = convertUsersIdsToIds(_users);

      // .filter((_user) => {
      //   return _user !== this.local.getUserId();
      // });
      const body: TaskReq = {
        title: this.state.title,
        description: this.state.description,
        priority: this.state.priority,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        researchId: store.getState().ResearchId.value,
        suggestedEquipmentsId: this.state.suggestedEquipmentsId.map(
          (_sug) => _sug.value
        ),
        media: this.state.modifiedMedias,
        addedUsersId: this.state.selectedUsersId,
        addedTeamsId: this.state.selectedTeamsId,
        addedPerformUsersId: this.state.selectedPerformUsersId?.map(
          (x) => x.value
        ),
        addedPerformTeamsId: this.state.selectedPerformTeamsId,
        id: this.state.parentId?.value ? this.state.parentId.value : 0,
        icon: this.state.selectedIcon === IconEnum.Default ? null : this.state.selectedIcon,
      };

      this.controller.createTask(
        body,
        async (res) => {
          await this.handelUpload(res.id);
            this.props.history.push(
              $ReplaceRoute(
                `${AppRoutes.task_profile.replace(
                  ":id",
                  res.id?.toString() ?? ""
                )}`
              )
            );

          this.setState({
            files: [],
            appTasksId: 0,
            description: "",
            equipmentsId: [],
            listTasks: [],
            listEquipments: [],
            startDate: new Date(),
            endDate: new Date(
              new Date().getTime() + AppConstants.one_day_milis
            ),
            isLockEndTime: true,
            researchId: 0,
            subAppTasksId: 0,
            title: "",
            External: "",
            ExternalUrl: [],
            loading: false,
            loadingMode: "",
          });
        },
        (err) => {
          // console.log("err", err);
          this.setState({
            loading: false,
            loadingMode: "",
          });
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  getSuggestedEquipments(
    _teamsId: number[],
    _usersId: string[],
    _checkSelectedEquip?: boolean
  ) {
    return new Promise((resolve, reject) => {
      if (_teamsId.length === 0 && _usersId.length === 0) reject(false);
      this.setState({
        gettingSuggestedEqLoading: true,
      });
      // const _teams = _data
      //   .filter((item: any) => !isNaN(Number(item)))
      //   .map((item: any) => +item);
      // let _users = _data.filter((item: any) => isNaN(Number(item)));

      // _users = convertUsersIdsToIds(_users);
      this.controller.getSuggestedEquipment(
        {
          teamsId: _teamsId,
          usersId: _usersId,
          researchId: store.getState().ResearchId.value,
        },
        (_suggestedEqips) => {
          const _uniqEqips = removeDuplicateObj(_suggestedEqips);
          let _filterSelectedEquipWithFreshEquip: any = [];
          console.log({ _uniqEqips });
          if (_checkSelectedEquip) {
            _filterSelectedEquipWithFreshEquip =
              this.state.suggestedEquipmentsId.filter((_se) => {
                const _isInUniqEq = _uniqEqips.some(
                  (__uq: any) => __uq.id === _se.value
                );
                return _isInUniqEq;
              });
            console.log(_filterSelectedEquipWithFreshEquip);
          }
          this.setState({
            listEquipments: _uniqEqips.map((item: any) => {
              return { label: item.title, value: item.id };
            }),
            gettingSuggestedEqLoading: false,
            suggestedEquipmentsId: _checkSelectedEquip
              ? _filterSelectedEquipWithFreshEquip
              : this.state.suggestedEquipmentsId,
          });
          resolve(true);
        },
        (err) => {
          this.setState({
            gettingSuggestedEqLoading: false,
          });
          reject(err);
        }
      );
    });
  }
  UpdateTask() {
    ;
    if (this.validator.allValid()) {
      this.setState({
        loading: true,
        loadingMode: "Updating..",
      });
      if(!this.state.selectedPerformUsersId){
        toast.error("Please assign the task at least to one user");
        return;
      }
      const _suggestedEquipmentsId = this.state.suggestedEquipmentsId.map(
        (_s) => _s.value
      );

      const __addedUsers = this.state.selectedUsersId.filter(
        (_uId) => !this.state.mainUsers.includes(_uId)
      );
      const __removedUsers = this.state.mainUsers.filter(
        (_uId) =>
          !this.state.selectedUsersId.includes(_uId) &&
          this.state.creatorUser.id !== _uId
      );
      const __addedTeams = this.state.selectedTeamsId.filter(
        (_tId) => !this.state.mainTeams.includes(_tId)
      );
      const __removedTeams = this.state.mainTeams.filter(
        (_tId) => !this.state.selectedTeamsId.includes(_tId)
      );

      
      const __addedEquips = _suggestedEquipmentsId.filter(
        (_tId) => !this.state.mainSuggestedEquipments.includes(_tId)
      );
      const __removedEquips = this.state.mainSuggestedEquipments.filter(
        (_tId) => !_suggestedEquipmentsId.includes(_tId)
      );

      
      const body: UpdateTaskReq = {
        addedEquipmentsId: __addedEquips,
        addedTeamsId: __addedTeams,
        addedUsersId: __addedUsers,
        addedPerformTeamsId: this.state.selectedPerformTeamsId,
        addedPerformUsersId: this.state.selectedPerformUsersId?.map(
          (x) => x.value
        ),
        removedEquipmentsId: __removedEquips,
        removedTeamsId: __removedTeams,
        removedUsersId: __removedUsers,
        //removedPerformTeamsId: __removedPerformTeams,
        //removedPerformUsersId: __removedPerformUsers,
        id: parseInt(this.props.match.params.id),
        title: this.state.title,
        description: this.state.description,
        priority: this.state.priority,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        status: this.state.status,
        researchId: this.state.researchId,
        media: this.state.modifiedMedias,
        removedMedia: this.state.removedMedia,
        parentId: this.state.parentId?.value ? this.state.parentId.value : 0,
        icon: this.state.selectedIcon === IconEnum.Default ? null : this.state.selectedIcon,
      };

      this.controller.updateTask(
        body,
        async (res) => {
          // console.log(this.state.addedFiles)
          if (
            this.state.addedFiles?.length > 0 ||
            this.state.ExternalUrl?.length > 0
          ) {
            await this.handelUpload(res.id);
            this.props.history.push(
              $ReplaceRoute(
                `${AppRoutes.task_profile.replace(
                  ":id",
                  res.id?.toString() ?? ""
                )}`
              )
            );
          } else {
            this.setState({
              loading: false,
              loadingMode: "",
            });
            this.props.history.push(
              $ReplaceRoute(
                `${AppRoutes.task_profile.replace(
                  ":id",
                  res.id?.toString() ?? ""
                )}`
              )
            );
          }
        },
        (err) => {
          this.setState({
            loading: false,
            loadingMode: "",
          });
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  handleChangeProject(e: any) {
    
    if (e && e.value != 0) {
      this.setState({
        selectedProject: e,
      });
      store.dispatch(SetResearchId({ label: e?.label, value: e?.value }));
    }
  }
  formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return "0 MB";

    const m = 1024 * 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizeInMB = bytes / m;

    return `${sizeInMB.toFixed(dm)} MB`;
  }
  bytesToMB(bytes: number) {
    return (bytes / (1024 * 1024)).toFixed(3);
  }

  render() {
    console.log("priority: " + this.state.priority);

    // console.log(this.state)
    const _creatorId = localStorage.getItem("userId");

    const files = this.state.files.map((file: any) =>
      file.id != null && file.id > 0 ? (
        <li
          key={file.title}
          className="d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team"
          style={{ marginBottom: "3px" }}
        >
          <span
            className="text-truncate col-11 mx-2"
            style={{ fontFamily: "Sanfransisco", textAlign: "start" }}
          >
            {file.title} - {this.formatBytes(file.size)}
          </span>
          <CircleIcon
            type={ThemeCircleIcon.dark}
            width="22px"
            height="22px"
            padding="0"
            onClick={() => {
              this.handelRemoveMedia(file.id);
              this.handelDeleteFile(file);
            }}
            style={{ cursor: "pointer", display: "contents" }}
          >
            <img
              src="/images/icons/cross-1.svg"
              alt="radvix"
              width={15}
              height={15}
            />
          </CircleIcon>
        </li>
      ) : (
        <li
          key={file.name}
          className="d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team"
          style={{ marginBottom: "3px" }}
        >
          <span
            className="text-truncate col-11 mx-2"
            style={{ fontFamily: "Sanfransisco", textAlign: "start" }}
          >
            {file.name} - {this.formatBytes(file.size)}
          </span>
          <CircleIcon
            type={ThemeCircleIcon.light}
            width="22px"
            height="22px"
            padding="0"
            onClick={() => {
              this.handelDeleteFile(file);
            }}
            style={{ cursor: "pointer", display: "contents" }}
          >
            <img
              src="/images/icons/cross-1.svg"
              alt="radvix"
              width={15}
              height={15}
            />
          </CircleIcon>
        </li>
      )
    );

    console.log("des: " + this.state.description);
    return (
      <div className="card">
        <div
          className="d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
      border-gray-400"
        >
          <div className="d-flex align-items-center">
            <BaseButton
              className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button"
              style={{ fontWeight: "400", height: "19px" }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className="fw-medium fs-15 mb-0 color-gray-1000">
              {this.state.id && this.state.id > 0 ? "Edit Task" : "Create Task"}
            </h2>
          </div>
        </div>
        <div className="p-3 overflow-auto">
          <div className="row">
            <div className="col-lg-6 mobileViewMargin">
              <div className="row">
                <div className="col-lg-12 mb-3">
                  {this.state.id == 0 ? (
                    <div className="row mt-1 mb-3">
                      <SelectComponent
                        disabled={this.state.searchLoading}
                        items={this.state.researhList}
                        defaultValue={this.state.selectedProject}
                        TextItem="title"
                        ValueItem="id"
                        labelClass="color-gray-600 fs-15 me-2 mb-1"
                        className="form-select control-border-color"
                        label="Project*"
                        placeholder="Select project"
                        onChange={(e) => {
                          this.handleChangeProject(e);
                          this.setState({ filterTaskList: [...[{label: "No parent", value: 0}],...this.state.taskList.filter((x) => x.researchId == e.value).map(({ id, title }: any) => ({
                            value: id,
                            label: title,
                          }))], parentId: null});;
                        }}
                        isMulti={false}
                      ></SelectComponent>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="row mt-1 mb-3">
                      <SelectComponent
                        disabled={this.state.searchLoading}
                        items={this.state.filterTaskList}
                        defaultValue={this.state.parentId ? this.state.parentId : {label: "No parent", value: 0}}
                        TextItem="title"
                        ValueItem="id"
                        labelClass="color-gray-600 fs-15 me-2"
                        className="form-select control-border-color"
                        label="Tasks*"
                        placeholder="Select task"
                          onChange={(e) => {
                            this.setState({
                              parentId: e
                            })
                        }}
                        isMulti={false}
                      ></SelectComponent>
                    </div>
                  <div className="row mt-1 mb-3">
                    <InputComponent
                      isExternalURL={true}
                      className="form-control"
                      disabled={this.state.searchLoading}
                      type={InputType.text}
                      addIcon = {true}
                      selectedIcons={this.state.selectedIcon}
          onIconSelect={this.handleIconSelect} 
                      label={(this.state.parentId == undefined || this.state.parentId?.value == 0 )? "Task name*": "Subtask name*"}
                      placeholder="Enter your task name"
                      popQuestion="Please type a name that best represents this task for yourself as well as all users who will be working on this task with you."
                      onChange={(e) => {
                        this.handleChange("title", e.target.value);
                      }}
                      inValid={this.validator.message(
                        "Task Name",
                        this.state.title,
                        "required"
                      )}
                      value={this.state.title}
                    ></InputComponent>
                  </div>
                  <div className="row mt-1 mb-3">
                    <div className="col-lg-12">
                      <div
                        className="text-primary cursor-pointer mb-1"
                        title="Info"
                      >
                        <label className="color-gray-600 fs-15 me-2">
                          Details and Instructions
                        </label>
                      </div>
                      <textarea
                        className="form-control control-border-color"
                        disabled={this.state.searchLoading}
                        placeholder="Enter task description"
                        style={{ height: "132px", resize: "none", color: "black" }}
                        value={this.state.description}
                        onChange={(e) => {
                          this.handleChange("description", e.target.value);
                        }}
                      ></textarea>
                      {/* <InputComponent
                        type={InputType.textarea}
                        disabled={this.state.searchLoading}
                        isExternalURL={true}
                        //label="Project description"
                        //optional="optional"
                        placeholder="Enter task description"
                        height="132px"
                        popQuestion="Briefly explain what this task is about. This will provide clarity for yourself and other users that will be working on this task. You can still have documents, discussions within this task or edit this description later."
                        className="mt-1"
                        onChange={(e) => {
                          this.handleChange("description", e.target.value);
                        }}
                        value={this.state.description}
                      ></InputComponent> */}
                    </div>
                  </div>
                  <div className="row mt-1 mb-3">
                    <div className="col-lg-12">
                      <label className="color-gray-600 fs-15 me-2 mb-1">
                        Priority*
                      </label>                      
                      <div className="d-flex border-gray-400 border soft-radios p-1 rounded-px-4 w-100">
                        {this.state.listPriority.map((priority: any) => (
                          <div key={priority.id} className="w-33_3">
                            <input
                              type="radio"
                              className="btn-check"
                              name="ResearchPriority"
                              id={`priority-${priority.id}`}
                              autoComplete="off"
                              //disabled={_disabledAll}
                              checked={this.state.priority === priority.id}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.handleChange("priority", priority.id);
                                }
                              }}
                            />
                            <label
                              className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                              htmlFor={`priority-${priority.id}`}
                            >
                              {priority.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-1 mb-3">
                    <div className="col-lg-12">
                      <div className="text-primary cursor-pointer">
                        <span className="color-gray-600 fs-15 me-2">
                          Scheduled timeline*
                        </span>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="date-picker-wrapper">
                            <DatePicker
                              disabled={this.state.searchLoading}
                              selected={this.state.startDate}
                              className="control-border-color"
                              onChange={(e) => {
                                this.handelChangeDate(
                                  e as Date,
                                  this.state.isLockEndTime &&
                                    (e as Date)?.getTime() >
                                      this.state.endDate?.getTime()
                                    ? new Date((e as Date)?.getTime() + ONE_DAY)
                                    : undefined
                                );
                              }}
                              maxDate={
                                this.state.isLockEndTime
                                  ? undefined
                                  : new Date(
                                      this.state.endDate?.getTime() - ONE_DAY
                                    )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="date-picker-wrapper">
                            <DatePicker
                              selected={this.state.endDate}
                              disabled={this.state.searchLoading}
                              className="control-border-color"
                              onChange={(e) => {
                                this.handelChangeDate(
                                  this.state.isLockEndTime &&
                                    (e as Date)?.getTime() <
                                      this.state.startDate?.getTime()
                                    ? new Date(
                                        (e as Date)?.getTime() -
                                          AppConstants.one_day_milis
                                      )
                                    : undefined,
                                  e as Date
                                );
                              }}
                              minDate={
                                this.state.isLockEndTime
                                  ? undefined
                                  : new Date(
                                      this.state.startDate?.getTime() + ONE_DAY
                                    )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.id > 0 && (
                    <div className="row mt-1 mb-3">
                    
                      <div className="col-lg-12">
                        <label className="color-gray-600 fs-15 me-2 mb-1">
                          Task status
                        </label>
                        <img
                          className="text-primary cursor-pointer"
                          src="/Images/icons/info-circled.svg"
                          alt="info"
                          title="Priority will color tag your research projects and will help you identify or sort them easier based on their priority level."
                        />
                        <div className="d-flex border-gray-400 border soft-radios p-1 rounded-px-4 w-100">
                          {this.state.ListStatus.map((status: any) => (
                            <div key={status.id} className="w-33_3">
                              <input
                                type="radio"
                                className="btn-check"
                                name="ResearchsStatus"
                                id={`status-${status.id}`}
                                autoComplete="off"
                                //disabled={_disabledAll}
                                checked={this.state.status === status.id}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    this.handleChange("status", status.id);
                                  }
                                }}
                              />
                              <label
                                className="btn text-primary textWrapLabel py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                                htmlFor={`status-${status.id}`}
                              >
                                {status.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                   
                  </div> )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mt-1 mb-3">
                <div className="col-lg-12">
                  <div className="d-flex align-items-center mb-1">
                    <label className="color-gray-600 fs-15 me-2">
                      User with access to this task
                    </label>
                    {/* <img
                  className="text-primary cursor-pointer"
                  src="/Images/icons/info-circled.svg"
                  alt="info"
                  title="If you already have the teams or individual members for this project invited to Radvix, you can select and assign them here. You can always go back later and add members or teams in the future. Once you create this project, they will get a notification."
                /> */}
                  </div>
                  <NewTreeMenu
                    //label="Teams and members:"
                    onChange={(_teams, _users) => {
;
                      const { listTeams, listUsers } = this.state;

                      // Extract users from selected teams
                      let selectedTeamUsers: any = [];
                      if (_teams.length > 0) {
                        _teams.forEach((teamId) => {
                          const team = listTeams.find((t) => t.id === teamId);
                          if (team && team.users) {
                            selectedTeamUsers = [
                              ...selectedTeamUsers,
                              ...team.users,
                            ];
                          }
                        });
                      }

                      // Find users in both listUsers and listTeams based on _users
                      let selectedIndividualUsers: any = [];
                      if (_users.length > 0) {
                        // Check in listUsers
                        selectedIndividualUsers = listUsers.filter((user) =>
                          _users.includes(user.id)
                        );

                        // Check in listTeams
                        listTeams.forEach((team) => {
                          const teamUsers = team.users.filter((user: any) =>
                            _users.includes(user.id)
                          );
                          selectedIndividualUsers = [
                            ...selectedIndividualUsers,
                            ...teamUsers,
                          ];
                        });
                      }

                      // Combine and remove duplicates
                      const combinedUsers = [
                        ...selectedTeamUsers,
                        ...selectedIndividualUsers,
                      ];

                      // Remove duplicate users
                      const allSelectedUsers = Array.from(
                        new Set(combinedUsers.map((user) => user.id))
                      ).map((id) =>
                        combinedUsers.find((user) => user.id === id)
                      );

                      const options = allSelectedUsers?.map((_user) => {
                        const _userTitle = getUserTitle(_user);
                        return {
                          label: _userTitle,
                          value: _user.id,
                        };
                      });
                      this.setState({
                        selectedTeamsId: _teams,
                        selectedUsersId: _users,
                        selectedTeamsMembers: [..._teams, ..._users],
                        defaultPerformUsers: options,                        
                      });
                    }}
                    popQuestion="If you already have the teams or individual members for this project invited to Radvix, you can select and assign them here. You can always go back later and add members or teams in the future. Once you create this project, they will get a notification."
                    loading={this.state.searchLoading}
                    defaultChilds={this.state.defaultUsers}
                    defaultParents={this.state.defaultTeams}
                    disabled={this.state.searchLoading}
                    options={this.state.listTeamMembers}
                    forceSelectedChilds={_creatorId ? [_creatorId] : []}
                  />
                </div>
              </div>
              <div className="row mt-1 mb-3">
               
                  <SelectComponent
                    disabled={this.state.searchLoading}
                    items={this.state.defaultPerformUsers}
                    TextItem="label"
                    ValueItem="value"
                    defaultValue={this.state.selectedPerformUsersId}
                    className="form-select control-border-color"
                    label="Who will be performing this task*"
                    popQuestion="Here you can select one or multiple laboratories to assign this equipment to them. If users have full access to those laboratories, they will automatically have access to this equipment."
                    placeholder="Select Users"
                    onChange={(e: any[]) => {
                      this.setState({
                        selectedPerformUsersId: e,
                      });
                    }}
                    isMulti={true}
                  ></SelectComponent>
               
              </div>
              <div className="row mt-1 mb-3">
                  <SelectComponent
                    disabled={this.state.searchLoading}
                    items={this.state.listEquipments}
                    defaultValue={this.state.suggestedEquipmentsId}
                    TextItem="name"
                    ValueItem="id"
                    labelClass="color-gray-600 fs-15 me-2 d-flex align-items-center mb-1"
                    className="form-select control-border-color"
                    label="Assign dataset to my equipment*"
                    placeholder="Select equipment"
                    onChange={(e) => {
                      this.setState({
                        suggestedEquipmentsId: e,
                      });
                    }}
                    isMulti
                  ></SelectComponent>
              </div>
              <div className="row mt-1 mb-3">
              <div className="col-lg-12">
                <div className="d-flex align-items-center mb-1">
                  <label className="color-gray-600 fs-15 me-2">
                    Task document
                  </label>
                  {/* <img
                  className="text-primary cursor-pointer"
                  src="/Images/icons/info-circled.svg"
                  alt="info"
                  title="You can attach any documents or links to this task which will help users have a better understanding of this task. Only users who are assigned to this task will be able to access these documents from Task Profile."
                /> */}
                </div>
                <div className="p-px-12 bg-gray-200 border-gray-400 border rounded-px-4 gap-2 attachment-paddingleft">
                  <div className="justify-content-sm-between justify-content-center align-items-center flex-wrap row-gap-px-8">
                    <Dropzone onDrop={this.onDrop}>
                      {({ getRootProps, getInputProps }) => (
                        // <section
                        //   className="container fileUploadBox"
                        //   style={{ marginTop: "0.3em" }}
                        // >
                        <div {...getRootProps({ className: "" })}>
                          <input {...getInputProps()} />

                          <div className="d-flex justify-content-between align-items-center flex-wrap row-gap-px-8 responsive-justify-content">
                            <button className="btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0">
                              <div className="d-flex align-items-center">
                                <div className="me-1 mb-1">
                                  <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="15"
                                      height="15"
                                      fill="white"
                                      fill-opacity="0.01"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.81825 1.18197C7.64251 1.00624 7.35759 1.00624 7.18185 1.18197L4.18185 4.18197C4.00611 4.35771 4.00611 4.64263
            4.18185 4.81837C4.35759 4.9941 4.64251 4.9941 4.81825 4.81837L7.05005 2.58657V9.50005C7.05005 9.74858 7.25152 9.95005
            7.50005 9.95005C7.74858 9.95005 7.95005 9.74858 7.95005 9.50005V2.58657L10.1819 4.81837C10.3576 4.9941 10.6425 4.9941
            10.8182 4.81837C10.994 4.64263 10.994 4.35771 10.8182 4.18197L7.81825 1.18197ZM2.5 10.0001C2.77614 10.0001 3 10.2239 3
            10.5001V12.0001C3 12.5539 3.44565 13.0001 3.99635 13.0001H11.0012C11.5529 13.0001 12 12.5529 12 12.0001V10.5001C12
            10.2239 12.2239 10.0001 12.5 10.0001C12.7761 10.0001 13 10.2239 13 10.5001V12.0001C13 13.1041 12.1062 14.0001 11.0012
            14.0001H3.99635C2.89019 14.0001 2 13.1031 2 12.0001V10.5001C2 10.2239 2.22386 10.0001 2.5 10.0001Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </div>
                                Browse local file
                              </div>
                            </button>
                            <h3 className="fw-normal color-gray-700 fs-13 mb-0 flex-grow-1 text-center">
                              {" "}
                              Or drag and drop files here - Max 128MB{" "}
                            </h3>
                          </div>
                          {files.length > 0 && (
                            <div style={{ paddingTop: "10px" }}>
                              <ul className="list-unstyled fw-normal">
                                {files}
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>

                <ul
                  className="file-list mt-1"
                  style={{
                    marginBottom:
                      this.state.ExternalUrl.length > 0 ? "0px" : "0px",
                  }}
                >
                  {this.state.ExternalUrl.map((item) => (
                    <li className="d-flex align-items-center w-100">
                      <InputComponent
                        type={InputType.text}
                        value={item}
                        className="mx-2"
                        counter={false}
                        disabled={true}
                        readOnly={true}
                        rowWidth="98%"
                        isExternalURL={true}
                      ></InputComponent>
                      <div
                        style={{
                          paddingBottom: "0px",
                          paddingRight: "0px",
                          paddingTop: "0px",
                          paddingLeft: "5px",
                        }}
                      >
                        <button
                          className="btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0"
                          disabled={this.state.searchLoading}
                          style={{ background: "#F3AEAF" }}
                          onClick={() => {
                            this.handelDeleteExternalLink(item);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <rect
                              width="15"
                              height="15"
                              fill="white"
                              fill-opacity="0.01"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.5 1.00009C5.22386 1.00009 5 1.22395 5 1.50009C5 1.77623 5.22386 2.00009 5.5 2.00009H9.5C9.77614 2.00009 10 1.77623 10 1.50009C10 1.22395 9.77614 1.00009 9.5 1.00009H5.5ZM3 3.50009C3 3.22395 3.22386 3.00009 3.5 3.00009H5H10H11.5C11.7761 3.00009 12 3.22395 12 3.50009C12 3.77623 11.7761 4.00009 11.5 4.00009H11V12.0001C11 12.5524 10.5523 13.0001 10 13.0001H5C4.44772 13.0001 4 12.5524 4 12.0001V4.00009H3.5C3.22386 4.00009 3 3.77623 3 3.50009ZM5 4.00009H10V12.0001H5V4.00009Z"
                              fill="#D93D42"
                            />
                          </svg>
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="d-flex">
                  <InputComponent
                    type={InputType.text}
                    placeholder="https://"
                    disabled={this.state.searchLoading}
                    className="control-border-color"
                    value={this.state.External}
                    counter={false}
                    onChange={(e) => {
                      this.handleChange("External", e.target.value);
                    }}
                    rowWidth="98%"
                    onEnter={(e) => {
                      this.addExternalUrl();
                    }}
                  ></InputComponent>
                  <div style={{ padding: "5px", paddingRight: "0px" }}>
                    <button
                      className="btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0"
                      disabled={this.state.searchLoading}
                      onClick={() => {
                        this.addExternalUrl();
                      }}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
                <p className="text-error mb-0">
                  {this.validator.message("input", this.state.External, "url")}
                </p>
              </div>
              </div>
            </div>
            <div>
              <MainButton
                className="btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2 responsive-Button-width"
                svg={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <rect
                      width="15"
                      height="15"
                      fill="white"
                      fill-opacity="0.01"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.5 2.00006C3.22386 2.00006 3 2.22392 3 2.50006V12.5001C3 12.7762 3.22386 13.0001 3.5 13.0001H11.5C11.7761 13.0001 12 12.7762 12 12.5001V4.70717L9.29289 2.00006H3.5ZM2 2.50006C2 1.67163 2.67157 1.00006 3.5 1.00006H9.5C9.63261 1.00006 9.75979 1.05274 9.85355 1.14651L12.7803 4.07328C12.921 4.21394 13 4.4047 13 4.60361V12.5001C13 13.3285 12.3284 14.0001 11.5 14.0001H3.5C2.67157 14.0001 2 13.3285 2 12.5001V2.50006ZM4.75 7.50006C4.75 7.22392 4.97386 7.00006 5.25 7.00006H7V5.25006C7 4.97392 7.22386 4.75006 7.5 4.75006C7.77614 4.75006 8 4.97392 8 5.25006V7.00006H9.75C10.0261 7.00006 10.25 7.22392 10.25 7.50006C10.25 7.7762 10.0261 8.00006 9.75 8.00006H8V9.75006C8 10.0262 7.77614 10.2501 7.5 10.2501C7.22386 10.2501 7 10.0262 7 9.75006V8.00006H5.25C4.97386 8.00006 4.75 7.7762 4.75 7.50006Z"
                      fill="white"
                    />
                  </svg>
                }
                onClick={() => {
                  {
                    this.state.id && this.state.id > 0
                      ? this.UpdateTask()
                      : this.CreateTask();
                  }
                }}
                loading={this.state.loading || this.state.searchLoading}
                disabled={this.state.loading || this.state.searchLoading}
              >
                {this.state.id && this.state.id > 0
                  ? "Update Task"
                  : "Create Task"}
              </MainButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(TaskPageNew);
