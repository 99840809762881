export default function ExpensesStatus({ status }: { status: number }) {
  return (
    <span
      className='badge text-white py-px-4 rounded-pill fs-13 fw-normal px-px-10'
      style={{ background: status === 0 ? '#ED5F00' : '#299764' }}
    >
      {status === 0 ? 'Waiting approval' : 'Approved'}
    </span>
  );
}
