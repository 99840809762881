import React, { useEffect, useState } from "react";
import { InputIcon } from "../../components/search_box";
import { learningController } from "../../../controllers/learning_section/learning_controller";
import {
  HelpLearningDetails,
  HelpLearningSectionDetails,
} from "../../../data/models/responses/learning/help_learningSection_res";

interface HelpPanelProps {
  isOpen: boolean;
  onClose: () => void;
}

const HelpPanel: React.FC<HelpPanelProps> = ({ isOpen, onClose }) => {
  // const [loading, setloading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sections, setSections] = useState<HelpLearningSectionDetails[]>();
  //const [search, setSearch] = useState('');
  const controller = new learningController();
  useEffect(() => {
    if (isOpen) {
      getDataWithSearch(searchQuery);
      const handleClickOutside = (event: MouseEvent) => {
        const panel = document.querySelector(
          ".help-panel"
        ) as HTMLElement | null;
        if (panel && !panel.contains(event.target as Node)) {
          onClose();
        }
      };
      window.addEventListener("click", handleClickOutside);

      // Cleanup event listener
      return () => {
        window.removeEventListener("click", handleClickOutside);
      };
    }
  }, [isOpen, onClose]);

  const getDataWithSearch = (searchQuery: string) => {
    setSearchQuery(searchQuery);
    controller.GetLearningSectionData(
      searchQuery,
      (res) => {
        setSections(res || []);
        //setloading(false);
        //setloadingMode('');
      },
      (err) => {
        //setloading(false);
        //setloadingMode('');
        console.error(err);
      }
    );
    //setSearchQuery(searchQuery);
    const regexPattern = new RegExp(searchQuery, "i");
    const matchedSectionsIndices = (sections || [])
      .map((section, index) => {
        const mainSectionMatches =
          section.name.match(regexPattern) ||
          section.shortDescription.match(regexPattern);
        // section.learningSectionDetails.filter(subsection => {
        //     const words = subsection.name.toLowerCase().split(' ');
        //     const shortDescriptionWords = subsection.shortDescription.toLowerCase().split(' ');
        //     return words.some(word => word.match(regexPattern)) || shortDescriptionWords.some(word => word.match(regexPattern));
        // });

        if (mainSectionMatches) {
          setExpandedSection(index);
        }

        return mainSectionMatches ? index : -1;
      })
      .filter((index) => index !== -1);

    setMatchedSections(matchedSectionsIndices);
    if (searchQuery === "" || matchedSectionsIndices.length === 0) {
      setExpandedSection(null);
      setMatchedSections([]);
    }
  };

  const [expandedSection, setExpandedSection] = useState<number | null>(null);
  const handleSectionClick = (index: number) => {
    if (expandedSection === index) {
      setExpandedSection(null);
    } else {
      setExpandedSection(index);
    }
  };

  //const [searchQuery, setSearchQuery] = useState("");
  const [matchedSections, setMatchedSections] = useState<number[]>([]);

  //const [showModal, setShowModal] = useState(false);
  const [sectionName, setSectionName] = useState<string>("");
  const [sectionLongDescription, setSectionLongDescription] =
    useState<string>("");
  const [sectionCode, setSectionCode] = useState<string>("");

  const handleClick = (subsection: any) => {
    setSectionCode(subsection.embeddedCode);
    setSectionLongDescription(subsection.longDescription);
    setSectionName(subsection.name);
    // Open the modal
    //setShowModal(true);
  };
  <style>
    .table
    {{
      borderColor: null,
    }}
  </style>;
  return (
    <>
      <div
        className={`help-panel bg-black-lighter shadow ${isOpen ? "open" : ""}`}
        style={{
          position: "fixed",
          top: 0,
          right: isOpen ? 0 : "-400px",
          width: "400px",
          height: "100%",
          transition: "right 0.3s ease-in-out",
          zIndex: 1000,
          background: "#fff",
          backdropFilter: "blur(10px)",
          // color: "white",
        }}
      >
        <div className="d-flex justify-content-end p-2">
          <div
            className="rounded-circle d-flex align-items-center justify-content-center"
            style={{ width: "30px", height: "30px", background: "white" }}
          >
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={onClose}
              style={{ color: "black", margin: 0 }}
            ></button>
          </div>
        </div>

        <div className="p-4">
          <h4 className="" style={{ textAlign: "center" }}>
            Radvix Learning Center
          </h4>
          <p
            style={{ fontSize: "15px", textAlign: "center" }}
            className=""
          >
            Learning Center will help you save time and easier get familiar with
            how certain tasks are done on Radvix
          </p>
          <div className="d-flex" style={{ paddingRight: 6, marginLeft: -3 }}>
            <InputIcon
              chilren={<img src="/images/icons/search_box_icon.svg" alt="" />}
              placeholder="Search..."
              TopPosition="15%"
              style={{ width: "100%", border: "none" }}
              onChange={(e) => getDataWithSearch(e.target.value)}
            ></InputIcon>
          </div>
        </div>

        <div
          className="table-responsive help-sidebar"
          style={{ maxHeight: "70%" }}
        >
          <table className="table table-striped help-sidebar">
            <tbody>
              {sections?.map((section, index) => (
                <tr
                  key={index}
                  style={{
                    paddingRight: 15,
                    paddingLeft: 15,
                    paddingTop: 15,
                    display: "block",
                  }}
                >
                  <td style={{ display: "block", background: 'white', boxShadow: 'none' }}>
                    <div className={`d-flex`}>
                      <div className="card p-3 mb-3 w-100 help_section_padding">
                        <div
                          className={`section ${
                            matchedSections.includes(index) ||
                            expandedSection === index
                              ? "expanded"
                              : ""
                          } d-flex justify-content-between align-items-center`}
                          style={{
                            marginBottom: 4,
                            backgroundColor: `${
                              matchedSections.includes(index) ||
                              expandedSection === index
                                ? "lightgray"
                                : "white"
                            }`,
                            padding: "12px",
                          }}
                          onClick={() => handleSectionClick(index)}
                        >
                          <div
                            className="flex-grow-1"
                            style={{ paddingRight: 10, paddingLeft: 5 }}
                          >
                            <span
                              className="fw-bold"
                              style={{ color: "black", borderRadius: "15px" }}
                            >
                              {section.name}
                            </span>
                            <br />
                            {/* <span className="text-muted" style={{ color: "black", fontSize: "15px" }}>
                                                            {section.shortDescription}
                                                        </span> */}
                          </div>
                          <div>
                            <i
                              className={`fas ${
                                expandedSection === index
                                  ? "fa-minus"
                                  : "fa-plus"
                              }`}
                            ></i>
                          </div>
                        </div>
                        {(matchedSections.includes(index) ||
                          expandedSection === index) &&
                          section.longDescription.length > 0 && (
                            <div
                              className="subsections pl-4"
                              style={{
                                color: "black",
                                padding: "12px",
                                paddingTop: "0px",
                                paddingLeft: 5,
                              }}
                            >
                              <div
                                className="subsection p-2 mb-2"
                                style={{
                                  borderTop: "1px solid black",
                                  padding: "8px",
                                  marginBottom: "8px",
                                }}
                              >
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: section.longDescription.replace(
                                      /\n/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></p>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal fade" id="HelpModal" tabIndex={-1}>
        <div className="modal-dialog" style={{ marginBottom: 1 }}>
          <div className="col-12 modal-content">
            <button
              type="button"
              className="btn-close dNoneSm"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close_modal_help"
            ></button>

            <div className="TableBox modalPaper">
              <div className="relative TopTableBox d-flex justify-content-between align-items-center mb-0 mb-sm-3 help-section-video">
                <div className="d-flex align-items-center help-section-video">
                  <br />
                  <div className="help-section-video">
                    <div>
                      <h5>{sectionName}</h5>
                      <p>{sectionLongDescription}</p>
                    </div>
                    <iframe
                      title="Video"
                      style={{ height: "600px" }}
                      className="help-section-video"
                      src={sectionCode}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end align-items-center dNoneSm"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpPanel;
