/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { store } from "../../../data/storeMain";
import { DateUsage } from "./component/date_usage";
import { IncomeChart } from "./component/income_chart";
import UserSignups from "./component/user_signups";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AdminController } from "../../../controllers/admin/admin_controller";
import { HTTP } from "../../../core/http_common";
import { isTokenInHttpAuth } from "../../../core/utils";
import { DashboardReportsResult } from "../../../data/models/responses/admin/dashboard_report_res";
import { SetExcludedClients } from "../../../data/storeMain/actions/exclude_clients_action";
import NewTreeMenu from "../../components/NewTreeMenu";
import { MainButton, MainButtonType } from "../../components/button";
import { SelectComponent } from "../../components/select_input";

interface StateType {
  data: DashboardReportsResult;
  dateFrom: Date;
  DateTo: Date;
  toggleChart: number;
  selectedUserClient: string | null;
  updatingLoader: boolean;
  selectedClients: any[];
  L1Clients: any[];
  countUsers: number;
  listDate: Array<{ label: string; value: number } | {}>;
}

export class AdminDashboard extends React.Component {
  handelChangeDate(target: string, params: any): void {
    this.setState({
      [target]: params,
    });
  }
  onChangeDate(e: any, params: string): void {
    let date_today = new Date();
    if (e === 0) {
      this.state.dateFrom = new Date(date_today.setDate(date_today.getDate() - date_today.getDay() - 7));
      this.state.DateTo = new Date(date_today.setDate(date_today.getDate() - date_today.getDay() + 6));
    }
    else if (e === 1) {
      this.state.dateFrom = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        new Date().getDate()
      )
      this.state.DateTo = new Date();
    }
    else if (e === 2) {
      this.state.dateFrom = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 3,
        new Date().getDate()
      )
      this.state.DateTo = new Date();
    }
    else if (e == 3) {
      this.state.dateFrom = new Date(
        new Date().getFullYear() - 1,
        new Date().getMonth(),
        new Date().getDate()
      )
      this.state.DateTo = new Date();
    }
  }

  options = [
    {
      value: 0,
      label: "Last Week"
    },
    {
      value: 1,
      label: "Last Month"
    },
    {
      value: 2,
      label: "Last 3 Month"
    },
    {
      value: 3,
      label: "Last Year"
    },
  ];

  controller = new AdminController();
  state: StateType = {
    data: {
      countUsers: 0,
      countOpenTickets: 0,
      countProjects: 0,
      countInstitutions: 0,
      sinceLaunch: 0,
      userSignUps: [],
      dateUsage: [],
      income: [],
    },
    dateFrom: new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 3,
      new Date().getDate()
    ),
    DateTo: new Date(),
    toggleChart: 2,
    selectedUserClient: null,
    updatingLoader: false,
    selectedClients: [],
    L1Clients: [],
    countUsers: 0,
    listDate: this.options,
  };

  getReset() {
    if (!isTokenInHttpAuth(HTTP)) return;
    this.controller.getDashboardReport(
      {
        fromDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 3,
          new Date().getDate()
        ),
        untilDate: new Date(),
        excludedClients: store.getState().excludedClients,
      },
      (res) => {
        let counter = 0;
        this.setState({
          data: {
            ...res,
            userSignUps: res.userSignUps.map((item) => {
              counter = counter + item.countUsers;
              return [new Date(item.dateTime).getTime(), item.countUsers];
            }),
          },
          dateFrom: new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 3,
            new Date().getDate()
          ),
          DateTo: new Date(),

        });
        this.setState({
          countUsers: counter,
          listDate: this.options,
        });
      }
    );

  }

  getDashboardReport() {
    // alert('called admin dashboard')
    
    if (!isTokenInHttpAuth(HTTP)) return;
    this.controller.getDashboardReport(
      {
        fromDate: this.state.dateFrom,
        untilDate: this.state.DateTo,
        excludedClients: store.getState().excludedClients,
      },
      (res) => {
        let counter = 0;
        this.setState({
          data: {
            ...res,
            userSignUps: res.userSignUps.map((item) => {
              counter = counter + item.countUsers;
              return [new Date(item.dateTime).getTime(), item.countUsers];
            }),
            dateUsage: res.dateUsage.map((item) => {
              return [new Date(item.dateTime).getTime(), item.countUsers];
            }),
            income: res.income.map((item) => {
              return [new Date(item.dateTime).getTime(), item.countUsers];
            }),
          },
        });
        this.setState({
          countUsers: counter
        });
      }
    );
  }

  handleChange(target: string, val: any) {
    this.setState({
      [target]: val,
    });
    this.render();
  }

  async componentDidMount() {
    this.getDashboardReport();
    await this.fetchL1Clients();
    if (!isTokenInHttpAuth(HTTP)) return;

    const selectedExcludedClients = store.getState().excludedClients;
    if (selectedExcludedClients && selectedExcludedClients.length > 0) {
      const teamsArray = Array.isArray(selectedExcludedClients) ? selectedExcludedClients : [selectedExcludedClients];
      const filteredClients = this.state.L1Clients.filter(client => teamsArray.includes(client.value));
      const selectedFilteredClients = filteredClients.map(client => ({
        label: client.label,
        value: client.value,
      }));
      this.setState({
        selectedClients: selectedFilteredClients
      });
    }
  }

  fetchL1Clients = async () => {
    const response = await HTTP.post("/Dashboard/ListUsers", {
      searchParameter: "",
      pageSize: 10000,
      pageNumber: 1
    })
      .then((response) => {
        const data = response.data.result;
        const users = data.users || [];
        const L1Clients = users.map((user: { id: string; firstName: string; lastName: string; }) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
        }));

        this.setState({ L1Clients });
      })
      .catch((error) => {
        console.error('Error fetching L1 clients:', error);
      });
  }

  RoleUser = store.getState().userRole;
  date = new Date();

  render() {
    const { L1Clients } = this.state;
    const { selectedClients } = this.state;
    const { countUsers } = this.state;

    return (
      <div className="container-fluid dashbord">
        <div className="row">
          <div className="col-12">
            <HeadDashboardPage
              Institutions={this.state.data.countInstitutions}
              Launch={this.state.data.sinceLaunch}
              Projects={this.state.data.countProjects}
              Tickets={this.state.data.countOpenTickets}
              users={this.state.data.countUsers}
            ></HeadDashboardPage>
          </div>
          <div className="col-12">
            <div className="TableBox">
              <div className="TopTableBox d-flex justify-content-between align-items-center">
                <div className="left d-flex w-50 align-items-baseline">
                  <h6 style={{ width: "35%" }}>
                    {this.state.toggleChart === 1
                      ? "Income ($)"
                      : this.state.toggleChart === 2
                        ? ("User Signup: " + countUsers)
                        : "Data Usage (GB)"}
                  </h6>
                </div>
              </div>
              <div className="w-100 bg-light rounded p-2">
                {this.state.toggleChart === 1 ? (
                  <IncomeChart data={this.state.data.income} />
                ) : this.state.toggleChart === 2 ? (
                  <UserSignups data={this.state.data.userSignUps} />
                ) : (
                  <DateUsage data={this.state.data.dateUsage} />
                )}
              </div>
              <div className="row box-content">
                {/*  <div className="col-md-5">
                  <div className="item ">
                    <ButtonGroup
                      name="AccessLevel"
                      items={[
                        { name: "Income ($) ", value: 1 },
                         { name: "User Signup ", value: 2 },
                         { name: "Data Usage (GB) ", value: 3 },
                      ]}
                      TextItem="name"
                      ValueItem="value"
                      selected={this.state.toggleChart}
                      onChange={(e) => {
                        this.handleChange(
                          "toggleChart",
                          parseInt(e.target.value)
                        );
                      }}
                    ></ButtonGroup>
                  </div>
                </div> */}
                <div className="col-md-4">
                  <div className="item pt-1">
                    <SelectComponent
                      items={this.state.listDate}
                      TextItem="name"
                      ValueItem="id"
                      // placeholder="Select"
                      onChange={(e) => {
                        this.onChangeDate(e.value, "interval");
                        
                      }}
                    ></SelectComponent>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="item">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="mx-2">From</span>
                      <DatePicker
                        selected={this.state.dateFrom}

                        onChange={(e) => {
                          this.handelChangeDate("dateFrom", e);
                        }}
                        maxDate={this.state.DateTo}
                      />
                      <span className="mx-2">Until</span>
                      <DatePicker
                        selected={this.state.DateTo}
                        onChange={(e) => {
                          this.handelChangeDate("DateTo", e);
                        }}
                        minDate={this.state.dateFrom}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="item mt-4">
                    <MainButton
                      type={MainButtonType.dark}
                      children={"Reset"}
                      borderRadius="50px"
                      fontSize="15px"
                      className="mx-2"
                      minHeight="29px"
                      minWidth="100px"
                      onClick={() => {
                        this.getReset();
                      }}
                    ></MainButton>
                    <MainButton
                      type={MainButtonType.dark}
                      children={"Apply"}
                      borderRadius="50px"
                      fontSize="15px"
                      className="mx-2"
                      minHeight="29px"
                      minWidth="100px"
                      onClick={() => {
                        this.getDashboardReport();
                      }}
                    ></MainButton>
                  </div>
                </div>
              </div>

              <div style={{ backgroundColor: "white", borderRadius: 10 }}>
                <div className="d-flex justify-content-left align-items-center">
                  <div className="max1LineText font-bold" style={{ paddingLeft: "10px", fontSize: "1rem" }}>
                    Exclude Clients:
                  </div>
                  <div id="clientsDiv" style={{ paddingLeft: "10px", paddingTop: "10px", paddingBottom: "10px", width: "90%", }}>
                    <NewTreeMenu
                      placeholder="Select clients..."
                      onChange={(_teams, _users) => {
                        this.setState({
                          selectedClients: _teams,
                          handleChangeSelectedClients: [..._teams, ..._users]
                        });
                        store.dispatch(
                          SetExcludedClients(_teams)
                        );
                      }}
                      options={L1Clients}
                      loading={this.state.updatingLoader}
                      disabled={this.state.updatingLoader}
                      defaultParents={selectedClients}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
interface HeadDashboardPageProp {
  users: number;
  Launch: number;
  Tickets: number;
  Projects: number;
  Institutions: number;
}
const HeadDashboardPage: React.FC<HeadDashboardPageProp> = (props) => {
  return (
    <div className="overviwe d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center">
      <div className="overviwe-item">
        <div className="d-flex align-items-center justify-content-around">
          <img
            src="/images/icons/members_involved_overview_icon.svg"
            alt="Avatar"
            className="avatar"
          />
          <div className="d-flex flex-column mx-2">
            <h1 className="display-5 mb-0">{props.users}</h1>
            <span className="textSecondary" style={{ fontSize: '0.9rem' }}>Users</span>
          </div>
        </div>
      </div>
      <div className="overviwe-item">
        <div className="d-flex align-items-center justify-content-around">
          <img
            src="/images/icons/expense_menu_light_icon.svg"
            alt="Avatar"
            className="avatar"
          />
          <div className="d-flex flex-column mx-2">
            <h1 className="display-5 mb-0 d-flex align-items-center">
              <span style={{ marginRight: '0.25em', fontSize: '1.5rem' }}>$</span>

              {props.Launch}</h1>
            <span className="textSecondary" style={{ fontSize: '0.9rem' }}>Since Launch</span>
          </div>
        </div>
      </div>
      <div className="overviwe-item">
        <div className="d-flex align-items-center justify-content-around">
          <img
            src="/images/icons/headphones_dark.svg"
            alt="Avatar"
            className="avatar"
          />
          <div className="d-flex flex-column mx-2">
            <h1 className="display-5 mb-0">{props.Tickets}</h1>
            <span className="textSecondary" style={{ fontSize: '0.9rem' }}>Open Tickets</span>
          </div>
        </div>
      </div>
      <div className="overviwe-item">
        <div className="d-flex align-items-center justify-content-around">
          <img
            src="/images/icons/equipment_involved_overview_icon.svg"
            alt="Avatar"
            className="avatar"
          />
          <div className="d-flex flex-column mx-2">
            <h1 className="display-5 mb-0">{props.Projects}</h1>
            <span className="textSecondary" style={{ fontSize: '0.9rem' }}>Projects</span>
          </div>
        </div>
      </div>
      <div className="overviwe-item">
        <div className="d-flex align-items-center justify-content-around">
          <img
            src="/images/icons/university.svg"
            alt="Avatar"
            className="avatar"
          />
          <div className="d-flex flex-column mx-2">
            <h1 className="display-5 mb-0">{props.Institutions}</h1>
            <span className="textSecondary" style={{ fontSize: '0.9rem' }}>Institutions </span>
          </div>
        </div>
      </div>
    </div>
  );
};
