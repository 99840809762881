import React from 'react';
import Skeleton from 'react-loading-skeleton';
import ReactPaginate from 'react-paginate';
import { RouteComponentProps, withRouter } from 'react-router';
import { MemberController } from '../../../controllers/member/member_controller';
import { AppRoutes } from '../../../core/constants';
import { HTTP } from '../../../core/http_common';
import {
  $ReplaceRoute,
  AccessPermition,
  getUserInitails,
  isTokenInHttpAuth,
  returnArrayOfNumber,
  UserRoles,
} from '../../../core/utils';
import { worSpaceController } from '../../../controllers/workSpace/workSpace';
import { Member } from '../../../data/models/responses/member/member_list_res';
import { store } from '../../../data/storeMain';
import { MainButton, MainButtonType } from '../../components/button';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
import { EmptyState } from '../../components/empty_state';
import { InputIcon } from '../../components/search_box';
import { SelectComponent } from '../../components/select_input';
import Memberitem from './component/member_item';
import debounce from 'lodash.debounce';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import { RxMagnifyingGlass } from 'react-icons/rx';
import { MdPersonAdd } from 'react-icons/md';
import PhoneSearch from '../../components/PhoneSearch';
import { FaThList } from 'react-icons/fa';
import DOMPurify from 'dompurify';
import { DropdownMenu, Table } from '@radix-ui/themes';
import UserWithAvatar from '../../components/UserWithAvatar';
import GroupedAvatars from '../../components/groupedAvatars';
import RadixTable from '../../components/NewRadixTable/RadixTable';
import { BaseButton } from '@fluentui/react';
import Tippy from '@tippyjs/react';
import { WorkspaceCollaboration } from '../../../data/models/requests/workSpace/workSpace_request';
import SelectRadix from '../../components/SelectRadix/selectRadix';
import { SortType } from '../../../core/number_extentions';
import { IFilterOptions } from '../../components/FilterSection/IFilterOptions';
import Pagination from '../../components/NewRadixTable/RadixPagination';
import { ImageWithToken } from '../../components/box_list_scroll';
import { SetHeaderTitle } from '../../../data/storeMain/actions/header_title_action';
type StateType = {
  Members: Member[];
  WorkspaceManagement: WorkspaceCollaboration[];
  teams: { id: number; title: string }[];
  PageNumber: number;
  PageSize: { label: string; value: number };
  PageCount: number;
  TotalCount: number;
  Search: string;
  loading: boolean;
  selectedTeamId: number;
  workspacesId: number;
  PageNumberInput: string;
  HeaderName: string;
  SortDirection: SortType;
  filterOptions: IFilterOptions;
};
class MemberPage extends React.Component<RouteComponentProps> {
  RoleUser = store.getState().userRole;
  controller = new MemberController();
  workspaceController = new worSpaceController();
  state: StateType = {
    Members: [],
    WorkspaceManagement: [],
    teams: [],
    PageNumber: 1,
    PageSize: { label: '10', value: 10 },
    PageCount: 0,
    TotalCount: 0,
    Search: '',
    loading: false,
    selectedTeamId: 0,
    workspacesId: 0,
    PageNumberInput: '',
    HeaderName: '',
    SortDirection: SortType.AutoSelect,
    filterOptions: {},
  };
  constructor(props: any) {
    super(props);
    this.handlePageNumberInput = this.handlePageNumberInput.bind(this);
    this.handleChangeMemberSearch = debounce(this.handleChangeMemberSearch.bind(this), 500);
  }
  componentDidMount() {
    document.title = `Users | Radvix`;
    store.dispatch(SetHeaderTitle('Team'))
    if (!isTokenInHttpAuth(HTTP)) return;
    this.GetMember(
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.Search,
      this.state.SortDirection
    );
  }
  GetMember(
    PageNumber: number,
    PageSize: number,
    Search?: string,
    columnHeaderName?: string,
    sortDirection?: SortType,
    filterOptions?: IFilterOptions
  ) {
    this.setState({
      loading: true,
    });
    if (sortDirection == undefined || sortDirection == null) {
      sortDirection = SortType.AutoSelect;
    }
    this.controller.getMemberList(
      {
        PageNumber: PageNumber,
        PageSize: PageSize,
        SearchParameter: Search ? Search : this.state.Search,
        columnHeaderName,
        sortDirection,
        filterOptions,
      },
      (res) => {
        this.setState({
          Members: res.members,
          PageCount: Math.ceil(res.count! / this.state.PageSize.value),
          TotalCount: res.count,
          loading: false,
          teams: res.members.flatMap((member) =>
            member.teams.map((team) => ({ value: team.id, label: team.title }))
          ),
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );

    // this.workspaceController.GetList(
    //   {
    //     PageNumber: this.state.PageNumber,
    //     PageSize: this.state.PageSize.value,
    //     SearchParameter: this.state.Search,
    //     SortDirection: 0,
    //     ColumnName: '',
    //   },
    //   (res: { result: { workspaceCollaborations: any; count: any } }) => {
    //     this.setState({
    //       WorkspaceManagement: res.result.workspaceCollaborations,
    //       //workspaceId: res.result[0].myWorkspaceID,
    //       PageCount: Math.ceil(res.result.count! / this.state.PageSize.value),
    //       TotalCount: res.result.count,
    //       loading: false,
    //     });
    //   },
    //   () => {
    //     this.setState({
    //       loading: false,
    //     });
    //   }
    // );
  }
  headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    this.setState({ HeaderName: columnHeaderName });
    this.setState({ SortDirection: sortDirection });
    this.GetMember(
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.Search,
      columnHeaderName,
      sortDirection,
      this.state.filterOptions
    );
  };
  handelChangePageNumber(e: { selected: number }) {
    this.setState({
      PageNumber: e.selected,
      PageNumberInput: e.selected + 1,
    });
    this.GetMember(e.selected + 1, this.state.PageSize.value);
  }
  handlePageNumberInput(event: { target: { value: any } }) {
    if (event.target.value > 0) {
      this.setState({ PageNumberInput: event.target.value });
    } else {
      this.setState({ PageNumberInput: '' });
    }
  }

  handleGoToPage() {
    const pageNumber = parseInt(this.state.PageNumberInput, 10);
    if (pageNumber > 0 && pageNumber <= this.state.PageCount) {
      this.handelChangePageNumber({ selected: pageNumber - 1 });
    } else {
      console.log('Invalid page number');
    }
  }

  handelChnagePageSize(e: any) {
    this.setState({
      PageSize: e,
    });
    this.GetMember(this.state.PageNumber, e?.value);
  }
  handelChangePageSize(e: { label: string; value: number }) {
    this.setState({
      PageSize: e,
    });
    this.GetMember(this.state.PageNumber, e.value);
  }
  handleChangeMemberSearch(e: any) {
    const inputValue = e.target.value;
    const sanitizedValue = DOMPurify.sanitize(inputValue);
    this.GetMember(this.state.PageNumber, this.state.PageSize.value, sanitizedValue);
  }

  handleViewDetails = (id: any) => {
    this.props.history.push(
      $ReplaceRoute(`${AppRoutes.member_profile.replace(':id', id.toString())}`)
    );
  };

  handleEdit = (id: any) => {
    this.props.history.push(
      $ReplaceRoute(`${AppRoutes.member_user_edit.replace(':id', id.toString() ?? '')}`)
    );
  };
  onTeamChange = (selectedTeams: { value: string; label: string }[]) => {
    this.setState({
      loading: true,
    });
    this.GetMember(this.state.PageNumber, this.state.PageSize.value, selectedTeams[0].label);
  };

  onWorkspaceChange = (selectedTeams: { value: string; label: string }[]) => {
    this.setState({
      loading: true,
    });
    // this.workspaceController.GetList(
    //   {
    //     PageNumber: this.state.PageNumber,
    //     PageSize: this.state.PageSize.value,
    //     SearchParameter: this.state.Search,
    //     SortDirection: 0,
    //     ColumnName: ""
    //   },
    //   (res: { result: { workspaceCollaborations: any; count: any; }; }) => {
    //     this.setState({
    //       WorkspaceManagement: res.result.workspaceCollaborations,
    //       //workspaceId: res.result[0].myWorkspaceID,
    //       PageCount: Math.ceil(res.result.count! / this.state.PageSize.value),
    //       TotalCount: res.result.count,
    //       loading: false,
    //     });
    //   },
    //   () => {
    //     this.setState({
    //       loading: false,
    //     });
    //   }
    // );
  };

  render() {
    return (
      <div className='card'>
        <div className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3'>
          <div className='d-flex align-items-center'>
            {/* <button className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none"> Back</button> */}
            <BaseButton
              className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none project-back-button'
              style={{ fontWeight: '400', height: '19px' }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>Users</h2>
          </div>

          {AccessPermition(this.RoleUser, [
            UserRoles.Admin,
            UserRoles.L1Client,
            UserRoles.L1User,
            UserRoles.L2User,
          ]) ? (
            <MainButton
              icon={<img src='/Images/icons/plus.svg' alt='sssss' height='20' />}
              children='Invite users'
              type={MainButtonType.dark}
              //borderRadius="24px"
              fontSize='14px'
              // className="my-2 mx-2"
              minHeight='2em'
              className='btn btn-primary fs-15 py-px-7'
              minWidth='100px'
              onClick={() => {
                this.props.history.push($ReplaceRoute(AppRoutes.member_new));
              }}
            ></MainButton>
          ) : null}
        </div>
        {/* <div
          className="d-flex flex-xl-row flex-column row-gap-xl-0 row-gap-px-16 px-3 pb-3 filters" id="showFilter">          
            <RadixFilter
              searchValue={this.state.Search}
              onSearchChange={(e) => {
                this.setState({ Search: e.target.value });
                this.handleChangeResearchSearch(e);
              }}
              onPriorityChange={this.handlePriorityChange}
              statusOptions={this.statusOptions}
              priorityOptions={this.priorityOptions}
              onStatusChange={this.handleStatusChange}
              onselect={(
                selectedFromDate,
                selectedToDate,
                status,
                selectedPriority
              ) =>
                this.filterClick(
                  selectedFromDate,
                  selectedToDate,
                  status,
                  selectedPriority,
                  this.state.Search
                )
              }
              onStartDateChange={this.handleStartDateChange}
              onEndDateChange={this.handleEndDateChange}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
        </div> */}

        <div
          className='d-flex flex-xl-row flex-column row-gap-xl-0 row-gap-px-16 px-3 pb-3 filters'
          id='showFilter'
        >
          <div className='pe-xl-3 d-sm-block d-flex gap-3'>
            <InputIcon
              className='form-control h-px-35'
              placeholder='Search user here'
              chilren={undefined}
              onChange={(e) => {
                this.setState({ Search: e.target.value });
                this.handleChangeMemberSearch(e);
              }}
            />
            {/* <button
              className="btn btn-secondary d-sm-none h-px-35 w-px-35 p-0"
              id="filterToggler"
            >
              <img src="/images/icons/mixer-horizontal.svg" />
              <i className="fa fa-times d-none color-gray-1000"></i>
            </button> */}
          </div>
          {/* <div className="d-sm-flex flex-wrap row-gap-sm-px-0 row-gap-px-16 sub-filters">
            <div className="ps-xl-3 pe-3 w-xl-auto w-sm-25 w-50">
              <select
                className="form-select h-px-35"
                value={this.state.selectedTeamId}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  let selectedLabel = "";
                  if (selectedValue === "") {
                    selectedLabel = "";
                    this.onTeamChange([{ value: "", label: "" }]);
                  } else {
                    selectedLabel =
                      e.target.options[e.target.selectedIndex].text;
                    this.setState({ selectedTeamId: selectedValue }, () => {
                      this.onTeamChange([
                        { value: selectedValue, label: selectedLabel },
                      ]);
                    });
                  }
                }}
              >
                <option value="">All Teams</option>
                {this.state.teams.flat().map((team: any) => (
                  <option key={team.value} value={team.value}>
                    {team.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="pe-sm-3 w-xl-auto w-sm-25 w-50">
              <select
                className="form-select h-px-35"
                value={this.state.workspacesId}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  let selectedLabel = "";
                  if (selectedValue === "") {
                    selectedLabel = "";
                    this.onWorkspaceChange([{ value: "", label: "" }]);
                  } else {
                    selectedLabel =
                      e.target.options[e.target.selectedIndex].text;
                    this.setState({ workspacesId: selectedValue }, () => {
                      this.onWorkspaceChange([
                        { value: selectedValue, label: selectedLabel },
                      ]);
                    });
                  }
                }}
              >
                <option value="">All Workspaces</option>
                {this.state.WorkspaceManagement.flat().map((workspace: any) => (
                  <option key={workspace.value} value={workspace.value}>
                    {workspace.label}
                  </option>
                ))}
              </select>
            </div>
          </div> */}
        </div>

        <RadixTable
          data={this.state.Members}
          loading={this.state.loading}
          onHeaderClick={(columnHeaderName: string, sortDirection: SortType) =>
            this.headerClick(columnHeaderName, sortDirection)
          }
          columns={[
            {
              Header: 'User Name',
              accessor: 'username',
              Cell: (_props: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <div className='line-clamp-2 textWrapLabel'>
                      {/* <i className="fa fa-grip-vertical me-2 color-gray-600 fs-13"></i> */}
                      <div className='d-flex align-items-center text-nowrap'>
                        {_props.profileImage === null ||
                        _props.profileImage === '' ||
                        _props.profileImage === undefined ? (
                          <div className='avatar w-px-25 h-px-25 rounded-circle bg-primary'>
                            <label className='fw-medium text-white fs-10'>
                              {(_props.firstName != " " || _props.lastName != " ") ? (
                                <span>
                              {getUserInitails(`${_props.firstName} ${_props.lastName}`)}
                              </span>
                            ): (`${_props.email.substring(0, 2).toUpperCase() }`)}
                            
                            </label>
                          </div>
                        ) : (
                          <ImageWithToken
                            originalImage={_props.profileImage}
                            hasImage={_props.profileImage ? true : false}
                            alt='Avatar'
                            className='rounded-circle avatar rounded-avatar-dashboard'
                            src={
                              _props.profileImage === null ||
                              _props.profileImage === '' ||
                              _props.profileImage === undefined
                                ? '/images/images/img_avatar.png'
                                : _props.profileImage
                            }
                          />
                        )}
                        <span
                          onClick={() => {
                            this.props.history.push(
                              $ReplaceRoute(
                                `${AppRoutes.member_profile.replace(
                                  ':id',
                                  _props.id?.toString() ?? ''
                                )}`
                              )
                            );
                          }}
                          title={`${_props.firstName} ${_props.lastName}`}
                        >
                          {' '}
                          {(_props.firstName != " " || _props.lastName != " ") ? (
                            <span>
                          {_props.firstName} {_props.lastName}
                          </span>
                        ):
                        (<>{_props.email}</>)}
                        </span>
                      </div>
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Access Level',
              accessor: 'accesslevel',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <div className='d-flex align-items-center text-nowrap'>
                      {/* <div className="avatar w-px-25 h-px-25 rounded-circle bg-primary">
                        <label className="fw-medium text-white fs-10">AH</label>
                      </div> */}
                      {props.userTypeName}
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Team',
              headerCellClassName: 'dNoneSm',
              accessor: 'teams',
              cellClassName: 'dNoneSm',
              // align: "center",
              Cell: (_row: any) => {
               if (!_row.teams || _row.teams.length === 0) return (
                <Table.Cell className='p-3'>
                  
                </Table.Cell>
               )

                const firstTeam = _row.teams[0];
                const otherTeams = _row.teams.slice(1);

                return (
                  <Table.Cell className='p-3'>
                    <div className='d-flex'>
                      {_row.teams.length > 0 && (
                        <div className='d-flex'>
                          <span
                            className='me-2 badge bg-teams text-teams py-px-6 rounded-pill fs-13 fw-normal px-3 pointer'
                            style={{ backgroundColor: '#D3D4DB' }}
                            onClick={() => {
                              this.props.history.push(
                                $ReplaceRoute(
                                  `${AppRoutes.team_profile.replace(
                                    ':id',
                                    firstTeam.id?.toString() ?? ''
                                  )}`
                                )
                              );
                            }}
                          >
                            {firstTeam.title}
                          </span>
                          {otherTeams.length > 0 && (
                            <Tippy
                              content={
                                <div className='d-flex flex-column'>
                                  {otherTeams.map((team: any) => (
                                    <div
                                      key={team.id}
                                      style={{
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <span
                                        className='badge bg-success text-white py-px-6 rounded-pill fs-13 fw-normal px-3 pointer'
                                        key={team.id}
                                        onClick={() => {
                                          this.props.history.push(
                                            $ReplaceRoute(
                                              `${AppRoutes.team_profile.replace(
                                                ':id',
                                                team.id?.toString() ?? ''
                                              )}`
                                            )
                                          );
                                        }}
                                      >
                                        {team.title}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              }
                              placement='bottom-start'
                              interactive
                              appendTo={document.body}
                            >
                              <div
                                className='pointer bg-white border rounded-circle d-flex justify-content-center align-items-center'
                                style={{
                                  width: '2em',
                                  height: '2em',
                                  cursor: 'pointer',
                                  backgroundColor: '#D3D4DB',
                                }}
                              >
                                +{otherTeams.length}
                              </div>
                            </Tippy>
                          )}
                        </div>
                      )}
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Role',
              accessor: 'role',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <div className='d-flex align-items-center text-nowrap'>
                      {props.role === 0 || props.role === 1 ? 'Super Admin' : 'Regular User'}
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: '',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3 text-nowrap'>
                    <div className='d-flex align-items-center gap-3'>
                      <div className='btn-group'>
                        <button
                          type='button'
                          className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleViewDetails(props.id);
                          }}
                        >
                          <i className='fa fa-eye me-px-6'></i>View Details
                        </button>

                        <DropdownMenu.Root>
                          <DropdownMenu.Trigger>
                            <button
                              type='button'
                              className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                            >
                              <span className='visually-hidden'>Toggle Dropdown</span>
                            </button>
                          </DropdownMenu.Trigger>
                          <DropdownMenu.Content>
                            <DropdownMenu.Item className='px-0'>
                              <button
                                className='dropdown-item fs-14'
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleViewDetails(props.id);
                                }}
                              >
                                View Details
                              </button>
                            </DropdownMenu.Item>
                            <DropdownMenu.Item className='px-0'>
                              <button
                                className='dropdown-item fs-14'
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleEdit(props.id);
                                }}
                              >
                                Edit
                              </button>
                            </DropdownMenu.Item>
                          </DropdownMenu.Content>
                        </DropdownMenu.Root>
                      </div>
                    </div>
                  </Table.Cell>
                );
              },
            },
          ]}
        />
        {this.state.PageCount > 0 && (
          <Pagination
            pageCount={this.state.PageCount}
            onPageChange={this.handelChangePageNumber.bind(this)}
            onPageSizeChange={(e: any) => {
              ;
              this.handelChnagePageSize(e);
            }}
            pageInput={this.state.PageNumberInput}
            handlePageNumberInput={this.handlePageNumberInput}
          />
        )}
      </div>
      // <div className="container-fluid research">
      //   <div className="row"></div>
      //   <div className="col-12">
      //     <div className="TableBox">
      //       <div className="relative TopTableBox d-flex flex-md-row justify-content-between align-items-md-start mb-3">
      //         {/* <div className="left d-flex align-items-center "> */}
      //         <h5 className="b-title d-flex align-items-center">
      //           <IoIosArrowDropleftCircle
      //             className="me-3 textSecondary2 cursorPointer"
      //             size="1.5em"
      //             onClick={() => {
      //               window.history.back();
      //             }}
      //           />{" "}
      //           Users
      //           {/* {this.state.TotalCount > 0 ? `(${this.state.TotalCount})` : ''} */}
      //         </h5>
      //         <div className="dFlexSm dNone align-items-center">
      //           {AccessPermition(this.RoleUser, [
      //             UserRoles.Admin,
      //             UserRoles.L1Client,
      //             UserRoles.L1User,
      //             UserRoles.L2User,
      //           ]) ? (
      //             <CircleIcon
      //               padding="0"
      //               height="2em"
      //               // type={ThemeCircleIcon.dark}
      //               onClick={() => {
      //                 this.props.history.push(
      //                   $ReplaceRoute(AppRoutes.member_new)
      //                 );
      //               }}
      //               width="2em"
      //               children={<MdPersonAdd size="1.4em" />}
      //             ></CircleIcon>
      //           ) : null}
      //           <CircleIcon
      //             padding="0"
      //             height="2em"
      //             // type={ThemeCircleIcon.dark}
      //             onClick={() => {
      //               this.props.history.push($ReplaceRoute(AppRoutes.team));
      //             }}
      //             width="2em"
      //             className="mx-1"
      //             children={<FaThList size="1em" />}
      //           ></CircleIcon>
      //           <PhoneSearch
      //             searchIcon={this.state.Search.length > 0 ? (
      //               <i
      //                 className="fa fa-times pointer"
      //                 aria-hidden="true"
      //                 onClick={() => {
      //                   this.setState(
      //                     {
      //                       Search: "",
      //                     },
      //                     () => {
      //                       this.GetMember(
      //                         this.state.PageNumber,
      //                         this.state.PageSize.value
      //                       );
      //                     }
      //                   );
      //                 }}
      //               ></i>
      //             ) : (
      //               <RxMagnifyingGlass size="1.25em" className="textSecondary" />
      //             )}
      //             value={this.state.Search}
      //             onChange={(e) => {
      //               this.setState({
      //                 Search: e.target.value,
      //               });
      //               this.handleChangeMemberSearch(e);
      //             }}
      //           />
      //         </div>
      //         {/* </div> */}
      //         <div className="right  d-flex justify-content-between align-items-baseline dNoneSm">
      //           {AccessPermition(this.RoleUser, [
      //             UserRoles.Admin,
      //             UserRoles.L1Client,
      //             UserRoles.L1User,
      //             UserRoles.L2User,
      //           ]) ? (
      //             <MainButton
      //               children="Invite users"
      //               type={MainButtonType.dark}
      //               // borderRadius="24px"
      //               // fontSize="12px"
      //               // className="my-2"
      //               minWidth="100px"
      //               borderRadius="24px"
      //               fontSize="14px"
      //               minHeight="2em"
      //               className="my-0 mx-2 px-2"
      //               onClick={() => {
      //                 this.props.history.push(
      //                   $ReplaceRoute(AppRoutes.member_new)
      //                 );
      //               }}
      //             ></MainButton>
      //           ) : null}

      //           <MainButton
      //             children="Teams"
      //             type={MainButtonType.dark}
      //             borderRadius="24px"
      //             fontSize="14px"
      //             // className="my-2 mx-2"
      //             minHeight="2em"
      //             className="my-0 mx-0 px-2"
      //             minWidth="100px"
      //             // className="my-2"
      //             onClick={() => {
      //               this.props.history.push($ReplaceRoute(AppRoutes.team));
      //             }}
      //           ></MainButton>
      //           <InputIcon
      //             chilren={
      //               this.state.Search.length > 0 ? (
      //                 <i
      //                   className="fa fa-times pointer"
      //                   aria-hidden="true"
      //                   onClick={() => {
      //                     this.setState(
      //                       {
      //                         Search: "",
      //                       },
      //                       () => {
      //                         this.GetMember(
      //                           this.state.PageNumber,
      //                           this.state.PageSize.value
      //                         );
      //                       }
      //                     );
      //                   }}
      //                 ></i>
      //               ) : (
      //                 <RxMagnifyingGlass size="1.25em" className="textSecondary" />
      //               )
      //             }
      //             width="100%"
      //             parentClassName="mx-2"
      //             placeholder="Search..."
      //             TopPosition="15%"
      //             value={this.state.Search}
      //             onChange={(e) => {
      //               this.setState({
      //                 Search: e.target.value,
      //               });
      //               this.handleChangeMemberSearch(e);
      //             }}
      //           ></InputIcon>
      //           <SelectComponent
      //             width="90px"
      //             height="44px"
      //             defaultValue={this.state.PageSize}
      //             items={[
      //               { label: "20", value: 20 },
      //               { label: "40", value: 40 },
      //               { label: "60", value: 60 },
      //             ]}
      //             TextItem="item"
      //             ValueItem="id"
      //             isMulti={false}
      //             placeholder={this.state.PageSize.toString()}
      //             onChange={(e) => {
      //               this.handelChangePageSize(e);
      //             }}
      //           ></SelectComponent>
      //         </div>
      //       </div>
      //       <div className="row">
      //         {this.state.loading
      //           ? returnArrayOfNumber(this.state.PageSize.value).map(() => (
      //             <div className="col-6 col-sm-3 d-flex align-items-center flex-column mb-3">
      //               <Skeleton
      //                 width="100px"
      //                 height="100px"
      //                 borderRadius="50%"
      //               />

      //               <div className="d-flex align-items-center w-100 mb-1">
      //                 <div className="w-100 text-center">
      //                   <Skeleton
      //                     className="mx-auto"
      //                     height="0.9em"
      //                     width="80%"
      //                   />
      //                 </div>
      //               </div>

      //               <hr className="w-100 my-0 mb-1" />
      //               <Skeleton height="1em" width="3em" className="mt-1" />

      //               <div className="d-flex justify-content-center align-items-center">
      //                 <Skeleton
      //                   height="26px"
      //                   width="26px"
      //                   className="me-1"
      //                   borderRadius="50%"
      //                 />
      //                 <Skeleton
      //                   height="26px"
      //                   width="26px"
      //                   borderRadius="50%"
      //                 />
      //               </div>
      //             </div>
      //           ))
      //           : null}
      //         {!this.state.loading && this.state.Members.length > 0 ? (
      //           this.state.Members.map((item) => (
      //             <Memberitem
      //               searchValue={this.state.Search}
      //               role={this.RoleUser}
      //               key={item.id}
      //               member={item}
      //             ></Memberitem>
      //           ))
      //         ) : !this.state.loading ? (
      //           <EmptyState
      //             backgroundColor="#fff"
      //             text="No Member List"
      //             fontSize="15px"
      //             color="#474747"
      //             minHeight="100px"
      //           />
      //         ) : null}
      //       </div>

      //       <div className="d-flex justify-content-between align-items-center px-3 mt-3 px-0-sm">
      //         <div className="dNone dFlexSm">
      //           <SelectComponent
      //             width="90px"
      //             height="44px"
      //             defaultValue={this.state.PageSize}
      //             items={[
      //               { label: "20", value: 20 },
      //               { label: "40", value: 40 },
      //               { label: "60", value: 60 },
      //             ]}
      //             TextItem="item"
      //             ValueItem="id"
      //             isMulti={false}
      //             placeholder={this.state.PageSize.toString()}
      //             onChange={(e) => {
      //               this.handelChangePageSize(e);
      //             }}
      //             selectProps={{ isSearchable: false }}

      //           ></SelectComponent>
      //         </div>
      //         {this.state.PageCount > 1 ? (
      //           <div className="d-flex justify-content-end flex-fill">
      //             <ReactPaginate
      //               previousLabel={
      //                 <CircleIcon
      //                   width="24px"
      //                   backgroundColor="#4E0057"
      //                   height="24px"
      //                   type={ThemeCircleIcon.dark}
      //                   padding="0"
      //                 >
      //                   <i className="fas fa-chevron-left"></i>
      //                 </CircleIcon>
      //               }
      //               nextLabel={
      //                 <CircleIcon
      //                   width="24px"
      //                   backgroundColor="#4E0057"
      //                   height="24px"
      //                   type={ThemeCircleIcon.dark}
      //                   padding="0"
      //                 >
      //                   <i className="fas fa-angle-right"></i>
      //                 </CircleIcon>
      //               }
      //               breakLabel={"..."}
      //               breakClassName={"break-me"}
      //               pageCount={this.state.PageCount}
      //               marginPagesDisplayed={2}
      //               pageRangeDisplayed={5}
      //               onPageChange={(e) => {
      //                 this.handelChangePageNumber(e);
      //               }}
      //               containerClassName={"pagination"}
      //               activeClassName={"active"}
      //             />
      //           </div>
      //         ) : null}

      //         {this.state.Members.length > 0 ? (
      //           <div className="d-flex justify-content-end flex-fill">
      //             <p className="text-right mb-0 ">
      //               Total Results: {this.state.TotalCount}
      //             </p>
      //           </div>
      //         ) : null}
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}
export default withRouter(MemberPage);
