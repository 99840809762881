import { SET_RESEARCH_ID, SET_RESEARCH_UPDATED } from "./actions_type";


export type ResearchAction = {
  type: string;
  payload: {label:string , value : number};
};
export type ResearchUpdatedAction = {
  type: string;
  payload: boolean;
};


export function SetResearchId(ResearchId: {label:string , value : number}): ResearchAction {
  return {
    type: SET_RESEARCH_ID,
    payload: ResearchId,
  };
}
export function SetResearchUpdated(Updated:boolean): ResearchUpdatedAction {
  return {
    type: SET_RESEARCH_UPDATED,
    payload: Updated,
  };
}