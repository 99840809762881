import moment from 'moment';
import React from 'react';
import ReactPaginate from 'react-paginate';
import { RouteComponentProps, withRouter } from 'react-router';
import { publishController } from '../../../controllers/publish/publish_controller';
import { ResearchController } from '../../../controllers/research/research_controller';
import { AppRoutes, MOMENT_DATE_FORMAT } from '../../../core/constants';
import { HTTP } from '../../../core/http_common';
import { $ReplaceRoute, isTokenInHttpAuth } from '../../../core/utils';
import { store } from '../../../data/storeMain';
import { MainButton, MainButtonType } from '../../components/button';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
// import { EmptyState } from "../../components/empty_state";
import NewTable from '../../components/NewTable/NewTable';
import { InputIcon } from '../../components/search_box';
import { SelectComponent } from '../../components/select_input';
// import MyPublicationsTable from "./component/my_publications_tbl";
import debounce from 'lodash.debounce';
import { FaEdit } from 'react-icons/fa';
import { MdAdd, MdFolder } from 'react-icons/md';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import { RxMagnifyingGlass } from 'react-icons/rx';
import Visible from '../../components/visible';
import PhoneSearch from '../../components/PhoneSearch';
import UserWithAvatar from '../../components/UserWithAvatar';
import { RiTimerLine } from 'react-icons/ri';
import CustomTooltip from '../../components/CustomTooltip';
import { StatusLabel } from '../../components/status_label';
import { ResearchStatus, SortType } from '../../../core/number_extentions';
import FilterSection from '../../components/FilterSection/filterSection';
import { IFilterOptions } from '../../components/FilterSection/IFilterOptions';
import DOMPurify from 'dompurify';
import DashboardProjectDropdown from '../../components/ActionComponent/DashboardProjectDropdown';
import { SetResearchId } from '../../../data/storeMain/actions/research_action';
import MultiSelect from '../../components/SelectRadix/radixMultiSelect';
import DatePicker from 'react-datepicker';
import RadixTable from '../../components/NewRadixTable/RadixTable';
import { DropdownMenu, Table } from '@radix-ui/themes';
import Pagination from '../../components/NewRadixTable/RadixPagination';
import { SetHeaderTitle } from '../../../data/storeMain/actions/header_title_action';

let unsubscribeMeData: any = undefined;
class MyPublications extends React.Component<RouteComponentProps> {
  RoleUser = store.getState().userRole;
  controller = new publishController();
  researchController = new ResearchController();
  state = {
    Publishes: [],
    PageNumber: 1,
    PageSize: 10,
    PageCount: 0,
    TotalCount: 0,
    ResearchId: store.getState().ResearchId.value,
    Search: '',
    loading: true,
    HeaderName: '',
    SortDirection: SortType.AutoSelect,
    showFilterOptions: false,
    filterOptions: {} as IFilterOptions,
    researhList: [],
    PageNumberInput: '',
  };

  constructor(props: any) {
    super(props);
    this.handleChangePublicationSearch = debounce(
      this.handleChangePublicationSearch.bind(this),
      500
    );
    this.handlePageNumberInput = this.handlePageNumberInput.bind(this);
  }
  getPublish(
    PageNumber: number,
    PageSize: number,
    ResearchId: number,
    Search?: string,
    columnHeaderName?: string,
    sortDirection?: SortType,
    filterOptions?: IFilterOptions
  ) {
    this.setState({
      loading: true,
    });
    if (sortDirection == undefined || sortDirection == null) {
      sortDirection = SortType.AutoSelect;
    }
    this.controller.getPublishes(
      {
        PageNumber: PageNumber,
        PageSize: PageSize,
        ResearchId: ResearchId,
        SearchParameter: Search ? Search : this.state.Search,
        columnHeaderName,
        sortDirection,
        filterOptions,
      },
      (res) => {
        if (res === null) {
          this.setState({
            Publishes: [],
            PageCount: 0,
            TotalCount: 0,
            loading: false,
          });
          return;
        }
        this.setState({
          Publishes: res ? res.publications : [],
          PageCount: Math.ceil(res.count! / this.state.PageSize),
          TotalCount: res.count,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
  }

  handelChangePageNumber(e: { selected: number }) {
    this.setState({
      PageNumber: e.selected,
    });
    this.getPublish(
      e.selected + 1,
      this.state.PageSize,
      store.getState().ResearchId.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      this.state.filterOptions
    );
  }
  handelChangePageSize(e: { label: string; value: number }) {
    this.setState({
      PageSize: e.value,
    });
    this.getPublish(
      this.state.PageNumber,
      e.value,
      store.getState().ResearchId.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      this.state.filterOptions
    );
  }
  headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    this.setState({ HeaderName: columnHeaderName });
    this.setState({ SortDirection: sortDirection });
    this.getPublish(
      this.state.PageNumber,
      this.state.PageSize,
      store.getState().ResearchId.value,
      this.state.Search,
      columnHeaderName,
      sortDirection,
      this.state.filterOptions
    );
  };
  componentDidMount() {
    document.title = `My Publications | Radvix`;
    store.dispatch(SetHeaderTitle('Write & Publish'));
    if (!isTokenInHttpAuth(HTTP)) return;
    this.loadResearches();
    unsubscribeMeData = store.subscribe(() => {
      if (store.getState().ResearchId.value > 0) {
        this.getPublish(
          this.state.PageNumber,
          this.state.PageSize,
          store.getState().ResearchId.value
        );
      }
    });
    if (store.getState().ResearchId.value > 0) {
      console.log('called');
      this.getPublish(
        this.state.PageNumber,
        this.state.PageSize,
        store.getState().ResearchId.value
      );
    } else {
      this.getPublish(this.state.PageNumber, this.state.PageSize, 0);
    }
  }
  // clean up event listener
  componentWillUnmount() {
    // console.log('called removeEvent')
    if (unsubscribeMeData) unsubscribeMeData();
  }

  loadResearches() {
    this.researchController.getResearches(
      {
        PageNumber: 1,
        PageSize: 100,
        SearchParameter: '',
        columnHeaderName: '',
        sortDirection: SortType.AutoSelect,
      },
      (res) => {
        const researches = res.researchesList?.map(({ id, title }) => ({
          value: id,
          label: title,
        }));
        this.setState({ researhList: researches });
      },
      (err) => {}
    );
  }

  handleChangePublicationSearch(e: any) {
    this.getPublish(
      this.state.PageNumber,
      this.state.PageSize,
      store.getState().ResearchId.value,
      e.target.value
    );
  }

  filterClick = (
    selectedFromDate?: Date | null,
    selectedToDate?: Date | null,
    status?: any[],
    teamType?: number[]
  ) => {
    var filterOptions = {
      selectedFromDate: selectedFromDate,
      selectedToDate: selectedToDate,
      status: status,
      teamType: teamType,
    };
    this.setState({ filterOptions: filterOptions });
    this.getPublish(
      this.state.PageNumber,
      this.state.PageSize,
      this.state.ResearchId,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      filterOptions
    );
  };

  renderStatus(status: any) {
    if (status == ResearchStatus.OnGoing) {
      return (
        <span className='badge bg-info text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          On Going
        </span>
      );
    }
    if (status == ResearchStatus.Completed) {
      return (
        <span className='badge bg-success text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          Completed
        </span>
      );
    }
    if (status == ResearchStatus.Delayed) {
      return (
        <span className='badge bg-warning text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          Delayed
        </span>
      );
    }

    if (status == ResearchStatus.OnHold) {
      return (
        <span
          className='badge bg-default text-white py-px-6 rounded-pill fs-13 fw-normal px-3'
          style={{ background: '#7E808A' }}
        >
          On Hold
        </span>
      );
    }
  }

  handleChangeProject(e: any) {
    this.setState({
      selectedProject: e,
    });
    store.dispatch(SetResearchId({ label: e?.label, value: e?.value }));
    this.getPublish(
      this.state.PageNumber,
      this.state.PageSize,
      e.value,
      this.state.Search,
      '',
      SortType.AutoSelect
    );
  }

  filterToggle = () => {
    const filteroggleButton = document.getElementById('filterToggler');
    const showFilter = document.getElementById('showFilter');
    const isFilterShown = document.getElementById('isFilterShown');
    showFilter?.classList.toggle('show-filters');
    filteroggleButton?.classList.toggle('filters-hide');
    isFilterShown?.classList.toggle('filters-height');
  };

  statusOptions: any[] = [
    { label: 'All Status', value: -1, svg: '' },
    {
      label: 'Completed',
      value: ResearchStatus.Completed,
      backgroundColor: 'bg-success',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#299764"></circle></svg>',
    },
    {
      label: 'Delayed',
      value: ResearchStatus.Delayed,
      backgroundColor: 'bg-warning',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#ED5F00"></circle></svg>',
    },
    {
      label: 'On Going',
      value: ResearchStatus.OnGoing,
      backgroundColor: 'bg-info',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#0880EA"></circle></svg>',
    },
    {
      label: 'On Hold',
      value: ResearchStatus.OnHold,
      backgroundColor: 'bg-OnHold',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#7E808A"></circle></svg>',
    },
  ];

  handleStatusChange = (selectedStatus: any[]) => {
    console.log('Selected Status:', selectedStatus);

    // Ensure selectedStatus is an array and has elements
    if (!Array.isArray(selectedStatus) || selectedStatus.length === 0) {
      console.log('selectedStatus is not a valid array or is empty.');
      return;
    }

    // Find the item with value -1
    const allValue = selectedStatus.find((x) => x.value === -1);
    console.log('All Value:', allValue);

    // Determine the value to be passed to filterClick
    const value = allValue ? undefined : selectedStatus;
    console.log('Value to pass to filterClick:', value);
    if (store.getState().ResearchId.value > 0) {
      // Call filterClick with appropriate parameters
      this.filterClick(
        this.state.filterOptions.selectedFromDate,
        this.state.filterOptions.selectedToDate,
        value
      );
    }
  };

  handlePageNumberInput(event: { target: { value: any } }) {
    if (event.target.value > 0) {
      this.setState({ PageNumberInput: event.target.value });
    } else {
      this.setState({ PageNumberInput: '' });
    }
  }

  render() {
    return (
      <div className='card'>
        <div className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3'>
          <div className='d-flex align-items-center'>
            {/* <button className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none"> Back</button> */}
            <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>My Publications</h2>
          </div>
          <MainButton
            className='btn btn-primary fs-15 py-px-7 new-project-button'
            svg={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='15'
                height='15'
                viewBox='0 0 15 15'
                fill='none'
              >
                <rect width='15' height='15' fill='white' fill-opacity='0.01'></rect>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z'
                  fill='white'
                ></path>
              </svg>
            }
            onClick={() => {
              this.props.history.push($ReplaceRoute(`${AppRoutes.publish_new}`));
            }}
            // style={{ height: "35px" }}
          >
            Start
          </MainButton>
        </div>
        <div className='border-top border-gray-400 border-bottom'>
          <div className='d-flex align-items-center p-3'>
            <span className='me-2 text-center text-nowrap'>Active Projects:</span>
            <DashboardProjectDropdown
              id={1}
              items={this.state.researhList}
              onSelect={(e) => {
                this.handleChangeProject(e);
              }}
              selectedItems={store.getState().ResearchId}
              className='publication-project-dropdown'
            />
          </div>
        </div>
        <div
          className='d-flex flex-xl-row flex-column row-gap-xl-0 row-gap-px-16 p-3 filters'
          id='showFilter'
        >
          <div className='pe-xl-3 border-end-xl-1 border-gray-400 d-sm-block d-flex gap-3'>
            <InputIcon
              className='form-control h-px-35'
              placeholder='Search publication'
              chilren={undefined}
              onChange={(e) => {
                this.setState({
                  Search: e.target.value,
                });
                this.handleChangePublicationSearch(e);
              }}
            />
            <button
              className='btn btn-secondary d-sm-none h-px-35 w-px-35 p-0'
              id='filterToggler'
              onClick={this.filterToggle}
            >
              <img src='/images/icons/mixer-horizontal.svg' />
              <i className='fa fa-times d-none color-gray-1000'></i>
            </button>
          </div>
          <div className='d-sm-flex flex-wrap gap-3 ps-xl-3 sub-filters'>
            <div style={{ width: '200px' }}>
              <MultiSelect options={this.statusOptions} onChange={this.handleStatusChange} />
            </div>
            <div style={{ width: '145px' }}>
              <div className='date-picker-wrapper'>
                <DatePicker
                  selected={this.state.filterOptions.selectedFromDate}
                  onChange={(e) => {
                    this.filterClick(
                      e,
                      this.state.filterOptions.selectedToDate,
                      this.state.filterOptions.status || []
                    );
                  }}
                  dateFormat='MM/dd/yyyy'
                  placeholderText='Start Date'
                  // maxDate={new Date()}
                  className='form-control h-px-35 customBorders'
                />
              </div>
            </div>
            <div style={{ width: '145px' }}>
              <div className='date-picker-wrapper'>
                <DatePicker
                  selected={this.state.filterOptions.selectedToDate}
                  onChange={(e) =>
                    this.filterClick(
                      this.state.filterOptions.selectedFromDate,
                      e,
                      this.state.filterOptions.status || []
                    )
                  }
                  dateFormat='MM/dd/yyyy'
                  placeholderText='End Date'
                  //maxDate={new Date()}
                  className='form-control h-px-35 customBorders'
                />
              </div>
            </div>
          </div>
        </div>

        <RadixTable
          data={this.state.Publishes}
          loading={this.state.loading}
          onHeaderClick={(columnHeaderName: string, sortDirection: SortType) =>
            this.headerClick(columnHeaderName, sortDirection)
          }
          columns={[
            {
              Header: 'Document name',
              accessor: 'name',
              Cell: (_row: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <div className='line-clamp-2'>
                      <i className='fa fa-grip-vertical me-2 color-gray-600 fs-13'></i>
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push(
                            $ReplaceRoute(
                              `${AppRoutes.publish_profile.replace(
                                ':id',
                                _row.id.toString() ?? ''
                              )}`
                            )
                          );
                        }}
                        title={_row.name}
                      >
                        {' '}
                        {_row.name}{' '}
                      </span>
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Category',
              accessor: 'category',
              Cell: (_row: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <div className='line-clamp-2'>{_row.category} </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Lead',
              accessor: 'currentlyWorkingOn',
              Cell: (_row: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <div className='line-clamp-2'>
                      <UserWithAvatar user={_row.creatorUser} />
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Deadline',
              accessor: 'endDate',
              Cell: (_row: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <div className='line-clamp-2'>
                      <span className='dNoneSm'>
                        {moment(_row.endDate).format(MOMENT_DATE_FORMAT)}
                      </span>
                      <div className='dNone dFlexSm align-items-center chipBottomRightInSm'>
                        <CustomTooltip
                          content='Deadline'
                          className='me-1 dFlexSm align-items-center'
                        >
                          <RiTimerLine size='1.3em' className='me-1 textSecondary' />
                          {moment(_row.endDate).format(MOMENT_DATE_FORMAT)}
                        </CustomTooltip>
                        <StatusLabel
                          className='justify-content-center '
                          status={_row.status}
                          deadLine={(_row as any).daysLeftDeadline}
                        />
                      </div>
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Status',
              accessor: 'status',
              Cell: (_props: any) => {
                return <Table.Cell className='p-3'>{this.renderStatus(_props.status)}</Table.Cell>;
              },
            },
            {
              Header: '',
              accessor: 'id',
              Cell: (props: any) => (
                <Table.Cell className='p-3 text-nowrap'>
                  <div className='d-flex align-items-center gap-3'>
                    <div className='btn-group'>
                      <button
                        type='button'
                        className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push(
                            $ReplaceRoute(
                              `${AppRoutes.publish_profile.replace(
                                ':id',
                                props.id.toString() ?? ''
                              )}`
                            )
                          );
                        }}
                      >
                        <i className='fa fa-eye me-px-6'></i>View Details
                      </button>

                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger>
                          <button
                            type='button'
                            className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                          >
                            <span className='visually-hidden'>Toggle Dropdown</span>
                          </button>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content>
                          <DropdownMenu.Item className='px-0'>
                            <button
                              className='dropdown-item fs-14'
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push(
                                  $ReplaceRoute(
                                    `${AppRoutes.publish_profile.replace(
                                      ':id',
                                      props.id.toString() ?? ''
                                    )}`
                                  )
                                );
                              }}
                            >
                              View Details
                            </button>
                          </DropdownMenu.Item>
                          <DropdownMenu.Item className='px-0'>
                            <button
                              className='dropdown-item fs-14'
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push(
                                  $ReplaceRoute(
                                    `${AppRoutes.publish_edit.replace(
                                      ':id',
                                      props.id.toString() ?? ''
                                    )}`
                                  )
                                );
                              }}
                            >
                              Edit Publication
                            </button>
                          </DropdownMenu.Item>
                        </DropdownMenu.Content>
                      </DropdownMenu.Root>
                    </div>
                  </div>
                </Table.Cell>
              ),
            },
          ]}
        />
        {this.state.PageCount > 0 && (
          <Pagination
            pageCount={this.state.PageCount}
            onPageChange={this.handelChangePageNumber.bind(this)}
            onPageSizeChange={(e: any) => {
              this.handelChangePageSize(e);
            }}
            pageInput={this.state.PageNumberInput}
            handlePageNumberInput={this.handlePageNumberInput}
          />
        )}
      </div>
    );
  }
}
export default withRouter(MyPublications);
