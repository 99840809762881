import React, { Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { toast } from "react-toastify";
import { AppRoutes } from "../../../core/constants";
import { $ReplaceRoute, UserRoles, getUserRole } from "../../../core/utils";
class SplashPage extends React.Component<RouteComponentProps> {
  componentDidMount() {
    // get
    const queryString = window.location?.search;

    if (queryString) {
      const isFromVerify = queryString.split("=")[1] === "emailVerify";
      if (isFromVerify) {
        toast.success(`Your email has been activated.`);
        // this.props.history.push($ReplaceRoute(AppRoutes.dashboard));
      }
    }
    
    const _userRole = getUserRole()
    if(_userRole === UserRoles.Admin){
      this.props.history.push(`/controlPanel/dashboard/Admin/home`);
    } else {
      this.props.history.push($ReplaceRoute(AppRoutes.dashboard));
    }

  }
  render() {
    return (
      <Fragment>
        <div>
          <h1>Splash Page</h1>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(SplashPage);
