import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { RouteComponentProps, withRouter } from "react-router";
import { IoIosArrowDropleftCircle } from 'react-icons/io';
interface RouteParams {
    id: string;
}
class RoleTable extends React.Component<
    RouteComponentProps<RouteParams>
> {
    render() {
        return (
            <div className="container-fluid research new-research">
                <div className="row"></div>
                <div className="col-12 box-content p-3">
                    <h5 className="b-title d-flex align-items-center">
                        <IoIosArrowDropleftCircle
                            className="me-3 textSecondary2 cursorPointer"
                            size="1.5em"
                            onClick={() => {
                                window.history.back();
                            }} />{" "}
                        Radvix Beta Role Overview
                    </h5>
                    <br />
                    <br />
                    <p className="fs-100 textSecondary">
                        Radvix offers three main access levels to suit various roles within a research team: <br /><br />
                        1. <strong>Research Admin</strong>: The highest level of access. Ideal for leading figures like the "Primary Investigator" or "Research Coordinator." Full control over project management, access rights, and advanced settings.<br /><br />

                        2. <strong>Research Manager</strong>: Moderate access level tailored for those overseeing daily operations, such as "Research Assistants." Allows for task assignment, monitoring, and limited administrative functions.<br /><br />

                        3. <strong>Researcher</strong>: The essential level for team members like "Assistants" or "Lab Technicians." Provides access to assigned tasks, data input, and specific collaboration tools.<br /><br />

                        Each of these access levels comes with preset role labels reflecting common positions within a research institution. You have the flexibility to rename these or add more labels to match your team's unique structure, all from the settings page.<br /> <br />

                        Use the tables below to understand the key differences and functions assigned to each access level, so you can choose what best fits the roles in your team.
                    </p>
                    <br />
                    {/* Title */}
                    {/* <h2 className="page-title" style={{ textAlign: 'center' }}>Radvix Beta Role Overview</h2> */}
                    <h3>Research</h3>
                    <div className="table-responsive">
                        <table className="table table-bordered" style={{ border: '1px rgba(0, 0, 0, 0.5)' }}>
                            <colgroup>
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">Research Features</th>
                                    <th scope="col">Research Admin</th>
                                    <th scope="col">Research Manager</th>
                                    <th scope="col">Researcher</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Create Research</td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaTimes className="text-danger" />
                                    </td>
                                    <td className="text-center">
                                        <FaTimes className="text-danger" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Edit Research</td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaTimes className="text-danger" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>View Research List</td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />

                    <h3>Team</h3>
                    <div className="table-responsive">
                        <table className="table table-bordered" style={{ border: '1px rgba(0, 0, 0, 0.5)' }}>
                            <colgroup>
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">Team Feature</th>
                                    <th scope="col">Research Admin</th>
                                    <th scope="col">Research Manager</th>
                                    <th scope="col">Researcher</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Create Team</td>
                                    <td>Can Create Main Team Only</td>
                                    <td>Can Create Subteam Only</td>
                                    <td className="text-center">
                                        <FaTimes className="text-danger" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Edit Team</td>
                                    <td>Only if user is a Team Manager</td>
                                    <td>Only if user is a Subteam Manager</td>
                                    <td className="text-center">
                                        <FaTimes className="text-danger" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Team List</td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Invite User</td>
                                    <td>Can Invite: Research Admin, Research Manager, Researcher</td>
                                    <td>Can Invite: Researcher</td>
                                    <td className="text-center">
                                        <FaTimes className="text-danger" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Edit/Add Role Labels</td>
                                    <td>Can Add/Edit Role Labels For: Research Admin, Research Manager, Researcher</td>
                                    <td>Can Add/Edit Role Labels For: Researcher</td>
                                    <td className="text-center">
                                        <FaTimes className="text-danger" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Users List</th>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Transfer User</td>
                                    <td>Can request transfer for: Research Admin, Research Manager, Researcher</td>
                                    <td>Can request transfer for: Researcher</td>
                                    <td className="text-center">
                                        <FaTimes className="text-danger" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />

                    <h3>Laboratory</h3>
                    <div className="table-responsive">
                        <table className="table table-bordered" style={{ border: '1px rgba(0, 0, 0, 0.5)' }}>
                            <colgroup>
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">Laboratory Features</th>
                                    <th scope="col">Research Admin</th>
                                    <th scope="col">Research Manager</th>
                                    <th scope="col">Researcher</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Create Laboratory</td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaTimes className="text-danger" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Create Equipment</td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaTimes className="text-danger" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>View Laboratory List</td>
                                    <td>Only If Assigned to.</td>
                                    <td>Only If Assigned to.</td>
                                    <td>Only If Assigned to.</td>
                                </tr>
                                <tr>
                                    <td>View Equipment List</td>
                                    <td>Only If Assigned to.</td>
                                    <td>Only If Assigned to.</td>
                                    <td>Only If Assigned to.</td>
                                </tr>
                                <tr>
                                    <td>Schedule Equipment</td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Moderate Equipment Bookings</td>
                                    <td>Only If User is a Lab Manager.</td>
                                    <td>Only If User is a Lab Manager.</td>
                                    <td className="text-center">
                                        <FaTimes className="text-danger" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />

                    <h3>Task/Dataset</h3>
                    <div className="table-responsive">
                        <table className="table table-bordered" style={{ border: '1px rgba(0, 0, 0, 0.5)' }}>
                            <colgroup>
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">Task/Dataset Features</th>
                                    <th scope="col">Research Admin</th>
                                    <th scope="col">Research Manager</th>
                                    <th scope="col">Researcher</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Assign Tasks</td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaTimes className="text-danger" />
                                    </td>
                                    <td className="text-center">
                                        <FaTimes className="text-danger" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Assign Subtasks</td>
                                    <td className="text-center">
                                        <FaTimes className="text-danger" />
                                    </td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaTimes className="text-danger" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>View Tasks</td>
                                    <td>Only If Assigned to.</td>
                                    <td>Only If Assigned to.</td>
                                    <td>Only If Assigned to.</td>
                                </tr>
                                <tr>
                                    <td>Attach Dataset</td>
                                    <td>Only to Main Tasks Assigned to.</td>
                                    <td>Only to Task/Subtasks Assigned to.</td>
                                    <td>Only to Task/Subtasks Assigned to.</td>
                                </tr>
                                <tr>
                                    <td>View Datasets</td>
                                    <td>Can view all dataset for Tasks assigned to.</td>
                                    <td>Can view all dataset for Task/Subtasks assigned to.</td>
                                    <td>Can view all dataset for Task/Subtasks assigned to.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <h3>Discussion</h3>
                    <div className="table-responsive">
                        <table className="table table-bordered" style={{ border: '1px rgba(0, 0, 0, 0.5)' }}>
                            <colgroup>
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">Discussion Features</th>
                                    <th scope="col">Research Admin</th>
                                    <th scope="col">Research Manager</th>
                                    <th scope="col">Researcher</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Initiate Discussion</td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>View Discussions</td>
                                    <td>Only if a member of discussion.</td>
                                    <td>Only if a member of discussion.</td>
                                    <td>Only if a member of discussion.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <h3>Publish</h3>
                    <div className="table-responsive">
                        <table className="table table-bordered" style={{ border: '1px rgba(0, 0, 0, 0.5)' }}>
                            <colgroup>
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">Publish Features</th>
                                    <th scope="col">Research Admin</th>
                                    <th scope="col">Research Manager</th>
                                    <th scope="col">Researcher</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Initiate Publication Flow</td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                    <td className="text-center">
                                        <FaCheck className="text-success" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Edit Publication Flow</td>
                                    <td>Only if user is Creator of Flow.</td>
                                    <td>Only if user is Creator of Flow.</td>
                                    <td>Only if user is Creator of Flow.</td>
                                </tr>
                                <tr>
                                    <td>Add Draft to Publication Flow</td>
                                    <td>Only if a member of Publication Flow.</td>
                                    <td>Only if a member of Publication Flow.</td>
                                    <td>Only if a member of Publication Flow.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />
                </div>
            </div>
        );
    }
}

export default withRouter(RoleTable);;

