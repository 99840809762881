import moment from 'moment';
import React from 'react';
import ReactPaginate from 'react-paginate';
import { RouteComponentProps, withRouter } from 'react-router';
import { ResearchController } from '../../../controllers/research/research_controller';
import { Label } from '@radix-ui/themes/dist/cjs/components/context-menu';
import { AppRoutes, MOMENT_DATE_FORMAT, DEFAULT_RESEARCH } from '../../../core/constants';
import { HTTP } from '../../../core/http_common';
import { DownloadIcon } from '@radix-ui/react-icons';
import ConfirmationContent from '../../components/ConfirmationContent';
import {
  $ReplaceRoute,
  AccessPermition,
  getUserInitails,
  isTokenInHttpAuth,
  UserRoles,
} from '../../../core/utils';
import debounce from 'lodash.debounce';
import { ResearchesList } from '../../../data/models/responses/research/researches_res';
import { store } from '../../../data/storeMain';
import { Badge } from '../../components/badge';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
// import { EmptyState } from "../../components/empty_state";
import NewTable from '../../components/NewTable/NewTable';
import { SelectComponent } from '../../components/select_input';
import { StatusLabel } from '../../components/status_label';
// import AcordienTableResearch from "./component/acordien_table_research";
import { FaEdit, FaEllipsisV } from 'react-icons/fa';
import { MdFolder } from 'react-icons/md';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import { RxMagnifyingGlass } from 'react-icons/rx';
import PhoneSearch from '../../components/PhoneSearch';
import CustomTooltip from '../../components/CustomTooltip';
import { RiTimerLine } from 'react-icons/ri';
import { Priority, ResearchStatus, SortType } from '../../../core/number_extentions';
import FilterSection from '../../components/FilterSection/filterSection';
import { IFilterOptions } from '../../components/FilterSection/IFilterOptions';
import SpinnerLoader from '../../components/spinnerLoader';
import DOMPurify from 'dompurify';
import { Avatar, Button, DropdownMenu, Flex, Select, Separator, Table } from '@radix-ui/themes';
import ActionComponent from '../../components/ActionComponent/Actions';
import SelectRadix from '../../components/SelectRadix/selectRadix';
import { parse } from 'path';
import RadixFilter from '../../components/RadixFilter/RadixFilter';
import NewRadixTable from '../../components/NewRadixTable/NewRadixTable';
import { InputIcon } from '../../components/search_box';
import { TreeOptionType } from '../../components/NewTreeMenu';
import { BaseButton, getInitials } from '@fluentui/react';
import DatePicker from 'react-datepicker';
import { MainButton, MainButtonType } from '../../components/button';
import RadixTable from '../../components/NewRadixTable/RadixTable';
// import { BaseButton } from "@radix-ui/themes/dist/cjs/components/base-button";
import MultiSelect from '../../components/SelectRadix/radixMultiSelect';
import { IOption } from '../../../data/models/responses/learning/update_learning_res';
import { access } from 'fs';
import Pagination from '../../components/NewRadixTable/RadixPagination';
import { ImageWithToken } from '../../components/box_list_scroll';
import RadixTableCollapse from '../../components/NewRadixTable/RadixTableCollpase';
import { GroupController } from '../../../controllers/group/group_controller';
import { IconEnum } from '../../components/inputs';
import ExportModal from '../../components/RadixModal/ExportModal';
import { SetHeaderTitle } from '../../../data/storeMain/actions/header_title_action';
import GroupedAvatars from '../../components/groupedAvatars';
import UserWithAvatar from '../../components/UserWithAvatar';
// import RadixPaginationComponent from "../../components/RadixPaginationComponent";

type StateType = {
  Researches: ResearchesList[];
  Groups: any[];
  PageNumber: number;
  PageCount: number;
  PageSize: { label: string; value: number };
  ResearchStatus: { label: string; value: number | null };
  TotalCount: number;
  Search: string;
  loading: boolean;
  HeaderName: string;
  SortDirection: SortType;
  filterOptions: IFilterOptions;
  showFilterOptions: boolean;
  showDuplicateLoader: boolean;
  selectedStatus: any[];
  selectedPriority: number[] | undefined;
  startDate: Date | null;
  endDate: Date | null;
  PageNumberInput: string;
  CombinedList: any[];
  ExpandedRowsState: { [key: string]: boolean };
  isExportModalOpen: boolean;
  ProjectGroupList: any[];
  ProjectList: any[];
};
interface RouteParams {
  id: string;
}

class ResearchPage extends React.Component<RouteComponentProps<RouteParams>> {
  controller = new ResearchController();
  groupController = new GroupController();
  RoleUser = store.getState().userRole;
  state: StateType = {
    Researches: [],
    PageNumber: 1,
    PageCount: 0,
    Groups: [],
    PageSize: { label: '10', value: 10 },
    ResearchStatus: { label: 'All', value: null },
    TotalCount: 0,
    Search: '',
    loading: true,
    HeaderName: '',
    SortDirection: SortType.ASC,
    filterOptions: {},
    showFilterOptions: false,
    showDuplicateLoader: false,
    selectedStatus: [],
    selectedPriority: [],
    startDate: null,
    endDate: null,
    PageNumberInput: '',
    CombinedList: [],
    ExpandedRowsState: {},
    isExportModalOpen: false,
    ProjectGroupList: [],
    ProjectList: [],
  };
  handleStartDateChange = (value: any) => {
    this.setState({ startDate: value }, () => {
      this.filterClick(
        this.state.startDate,
        this.state.endDate,
        this.state.selectedStatus,
        this.state.selectedPriority,
        this.state.Search
      );
    });
  };
  handleEndDateChange = (value: any) => {
    this.setState({ endDate: value }, () => {
      this.filterClick(
        this.state.startDate,
        this.state.endDate,
        this.state.selectedStatus,
        this.state.selectedPriority,
        this.state.Search
      );
    });
  };
  researchController = new ResearchController();
  constructor(props: any) {
    super(props);
    this.handleChangeResearchSearch = debounce(this.handleChangeResearchSearch.bind(this), 500);
    this.handlePageNumberInput = this.handlePageNumberInput.bind(this);
    this.handleGoToPage = this.handleGoToPage.bind(this);
  }
  GetResearchGroups(
    PageNumber: number,
    PageSize: number,
    Search?: string,
    columnHeaderName?: string,
    sortDirection?: SortType,
    filterOptions?: IFilterOptions
  ) {
    this.setState({
      loading: true,
    });
    if (sortDirection == undefined || sortDirection == null) {
      sortDirection = SortType.AutoSelect;
    }

    this.researchController.getGroupResearches(
      {
        PageNumber: PageNumber,
        PageSize: PageSize,
        // SearchParameter: '',
        SearchParameter: Search ? Search : this.state.Search,
        columnHeaderName,
        sortDirection,
        filterOptions,
      },
      (res) => {
        this.setState({
          Researches: res.researchesList,
          // PageCount: 1000,
          PageCount: Math.ceil(res.groups?.length! / this.state.PageSize.value),
          TotalCount: res.count,
          Groups: res.groups,
          loading: false,
          ProjectGroupList: res.groups,
          ProjectList: res.researchesList,
        });

        this.setState({
          loading: false,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
  }
  componentDidMount() {
    document.title = 'My Researches | Radvix';
    store.dispatch(SetHeaderTitle('Projects'));
    if (!isTokenInHttpAuth(HTTP)) return;
    this.GetResearch(this.state.PageNumber, this.state.PageSize.value, '');
    // this.GetResearchGroups(1, 1000, '');
    this.GetResearchGroups(this.state.PageNumber, this.state.PageSize.value, '');
  }
  GetResearch(
    PageNumber: number,
    PageSize: number,
    Search?: string,
    columnHeaderName?: string,
    sortDirection?: SortType,
    filterOptions?: IFilterOptions
  ) {
    this.setState({
      loading: true,
    });
    if (sortDirection == undefined || sortDirection == null) {
      sortDirection = SortType.AutoSelect;
    }
    this.controller.getGroupResearches(
      {
        PageNumber: PageNumber,
        PageSize: PageSize,
        SearchParameter: Search ? Search : this.state.Search,
        columnHeaderName,
        sortDirection,
        filterOptions,
      },
      (res) => {
        this.setState({
          Researches: res.researchesList,
          PageCount: Math.ceil(res.groups?.length! / this.state.PageSize.value),
          TotalCount: res.count,
          Groups: res.groups,
          loading: false,
        });

        let combinedList = [];

        // Add GroupInfo to the combined list
        if (res.groups && res.groups.length > 0) {
          combinedList.push(...res.groups);
        }

        // Add relevant researches to the combined list
        if (res.researchesList && res.researchesList.length > 0) {
          combinedList.push(...res.researchesList);
        }

        this.setState({
          CombinedList: combinedList,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
  }
  handlePageNumberInput(event: { target: { value: any } }) {
    if (event.target.value > 0) {
      this.setState({ PageNumberInput: event.target.value });
    } else {
      this.setState({ PageNumberInput: '' });
    }
  }
  handleGoToPage() {
    const pageNumber = parseInt(this.state.PageNumberInput, 10);
    if (pageNumber > 0 && pageNumber <= this.state.PageCount) {
      this.handelChangePageNumber({ selected: pageNumber - 1 });
    } else {
      console.log('Invalid page number');
    }
  }
  handelChangePageNumber(e: { selected: number }) {
    this.setState({
      PageNumber: e.selected,
    });
    this.GetResearch(
      e.selected + 1,
      this.state.PageSize.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      this.state.filterOptions
    );
  }

  handelChangePageSize(e: { label: string; value: number }) {
    this.setState({
      PageSize: e,
    });
    this.GetResearch(
      this.state.PageNumber,
      e.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      this.state.filterOptions
    );
  }
  handelChagePageSize(e: any) {
    this.setState({
      PageSize: e,
    });
    this.GetResearch(
      this.state.PageNumber,
      e?.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      this.state.filterOptions
    );
  }
  handleStatusChange = (selectedStatus: any[]) => {
    const allValue = selectedStatus.find((x) => x.value == -1);
    const value = allValue ? null : selectedStatus;
    this.setState({ selectedStatus: value }, () => {
      this.filterClick(
        this.state.startDate,
        this.state.endDate,
        value,
        this.state.selectedPriority,
        this.state.Search
      );
    });
  };

  handlePriorityChange = (selectedPriority: any[]) => {
    const allValue = selectedPriority.find((x) => x.value == -1);
    const value = allValue ? null : selectedPriority;
    this.setState({ selectedPriority: value }, () => {
      this.filterClick(
        this.state.startDate,
        this.state.endDate,
        this.state.selectedStatus,
        value,
        this.state.Search
      );
    });
  };

  handleChangeResearchSearch(e: any) {
    const inputValue = e.target.value;
    const sanitizedValue = DOMPurify.sanitize(inputValue);
    this.GetResearch(
      this.state.PageNumber,
      this.state.PageSize.value,
      sanitizedValue,
      this.state.HeaderName ? this.state.HeaderName : 'title',
      this.state.SortDirection ? this.state.SortDirection : SortType.ASC
    );
  }
  headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    this.setState({ HeaderName: columnHeaderName });
    this.setState({ SortDirection: sortDirection });
    this.GetResearch(
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.Search,
      columnHeaderName,
      sortDirection,
      this.state.filterOptions
    );
  };

  filterClick = (
    selectedFromDate?: Date | null,
    selectedToDate?: Date | null,
    status?: any[] | null,
    selectedPriority?: number[] | null,
    Search?: string
  ) => {
    const filterOptions: IFilterOptions = {
      selectedFromDate: selectedFromDate,
      selectedToDate: selectedToDate,
      status: status,
      priority: selectedPriority,
    };
    this.setState({ filterOptions: filterOptions });
    this.GetResearch(
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      filterOptions
    );
  };
  handleToggle = () => {
    this.setState({ showFilterOptions: !this.state.showFilterOptions });
  };

  statusOptions: any[] = [
    { label: 'All Status', value: -1, svg: '' },
    {
      label: 'Completed',
      value: ResearchStatus.Completed,
      backgroundColor: 'bg-success',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#299764"></circle></svg>',
    },
    {
      label: 'Delayed',
      value: ResearchStatus.Delayed,
      backgroundColor: 'bg-warning',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#ED5F00"></circle></svg>',
    },
    {
      label: 'On Going',
      value: ResearchStatus.OnGoing,
      backgroundColor: 'bg-info',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#0880EA"></circle></svg>',
    },
    {
      label: 'On Hold',
      value: ResearchStatus.OnHold,
      backgroundColor: 'bg-OnHold',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#7E808A"></circle></svg>',
    },
  ];

  priorityOptions: any[] = [
    { label: 'All Priority', value: -1 },
    {
      label: 'Low',
      value: 0,
      backgroundColor: 'bg-success',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#0880EA"></circle></svg>',
    },
    {
      label: 'Medium',
      value: 1,
      backgroundColor: 'bg-warning',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#ED5F00"></circle></svg>',
    },
    {
      label: 'High',
      value: 2,
      backgroundColor: 'bg-danger',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#D93D42" /></svg>',
    },
  ];
  handleViewDetails = (id: any) => {
    this.props.history.push(
      $ReplaceRoute(`${AppRoutes.profile_research.replace(':id', id?.toString() ?? '')}`)
    );
  };

  handleNewProject = (id: any) => {
    this.props.history.push($ReplaceRoute(`${AppRoutes.new_research}?groupId=${id}`));
  };

  handleEditGroup = (id: any) => {
    this.props.history.push(
      $ReplaceRoute(`${AppRoutes.edit_group.replace(':id', id?.toString() ?? '')}`)
    );
  };

  handleDeleteGroup = (id: any) => {
    this.groupController.deleteGroup(
      {
        groupId: id,
      },
      (res) => {
        if (res > 0) {
          this.setState({
            loading: false,
          });
          this.props.history.push($ReplaceRoute(`${AppRoutes.research}`));
        }
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
  };

  handleDuplicate = (id: any) => {
    this.setState({
      showDuplicateLoader: true,
    });

    this.controller.duplicateResearch(
      {
        id: id,
      },
      (res) => {
        if (res > 0) {
          this.setState({
            showDuplicateLoader: false,
          });
          this.props.history.push(
            $ReplaceRoute(`${AppRoutes.edit_research.replace(':id', res.toString() ?? '')}`)
          );
        }
      },
      (err) => {
        this.setState({
          showDuplicateLoader: false,
        });
      }
    );
  };

  handleEdit = (id: any) => {
    this.props.history.push(
      $ReplaceRoute(`${AppRoutes.edit_research.replace(':id', id?.toString() ?? '')}`)
    );
  };
  renderStatus(status: any) {
    if (status == ResearchStatus.OnGoing) {
      return (
        <span className='badge bg-info text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          On Going
        </span>
      );
    }
    if (status == ResearchStatus.Completed) {
      return (
        <span className='badge bg-success text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          Completed
        </span>
      );
    }
    if (status == ResearchStatus.Delayed) {
      return (
        <span className='badge bg-warning text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          Delayed
        </span>
      );
    }

    if (status == ResearchStatus.OnHold) {
      return (
        <span
          className='badge bg-default text-white py-px-6 rounded-pill fs-13 fw-normal px-3'
          style={{ background: '#7E808A' }}
        >
          On Hold
        </span>
      );
    }
  }

  handleExpandedRowsChange = (updatedExpandedRows: { [key: string]: boolean }) => {
    this.setState({ ExpandedRowsState: updatedExpandedRows });
    console.log('Expanded Rows State:', updatedExpandedRows);
  };

  renderPrioirty(status: any) {
    if (status == Priority.High) {
      return (
        <span className='badge bg-danger text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          High
        </span>
      );
    }
    if (status == Priority.Medium) {
      return (
        <span className='badge bg-warning text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          Medium
        </span>
      );
    }
    if (status == Priority.Low) {
      return (
        <span className='badge bg-success text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          Low
        </span>
      );
    }
  }

  filterToggle = () => {
    const filteroggleButton = document.getElementById('filterToggler');
    const showFilter = document.getElementById('showFilter');
    const isFilterShown = document.getElementById('isFilterShown');
    showFilter?.classList.toggle('show-filters');
    filteroggleButton?.classList.toggle('filters-hide');
    isFilterShown?.classList.toggle('filters-height');
  };
  handleOpenModal = () => {
    this.setState({ isExportModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isExportModalOpen: false });
  };
  render() {
    return (
      <>
        <ExportModal
          isOpen={this.state.isExportModalOpen}
          onClose={this.handleCloseModal}
          groups={this.state.ProjectGroupList}
          projects={this.state.ProjectList}
        />
        <div className='card'>
          <div className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-4'>
            <div className='d-flex align-items-center flex-shrink-0'>
              <BaseButton
                className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none project-back-button'
                style={{ fontWeight: '400', height: '19px' }}
                onClick={() => {
                  window.history.back();
                }}
              >
                Back
              </BaseButton>
              <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>My Projects</h2>
            </div>

            <div className='d-flex flex-wrap gap-2 gap-sm-3'>
              <MainButton
                className='btn btn-primary fs-15 py-px-7 new-project-button'
                svg={<DownloadIcon width='15' height='15' />}
                onClick={this.handleOpenModal}
              >
                Easy Export
              </MainButton>
              <MainButton
                className='btn btn-primary fs-15 py-px-7 new-project-button'
                svg={
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='15'
                    height='15'
                    viewBox='0 0 15 15'
                    fill='none'
                  >
                    <rect width='15' height='15' fill='white' fill-opacity='0.01'></rect>
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z'
                      fill='white'
                    ></path>
                  </svg>
                }
                onClick={() => {
                  this.props.history.push($ReplaceRoute(`${AppRoutes.new_group}`));
                }}
              >
                New Group
              </MainButton>
              <MainButton
                className='btn btn-primary fs-15 py-px-7 new-project-button'
                svg={
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='15'
                    height='15'
                    viewBox='0 0 15 15'
                    fill='none'
                  >
                    <rect width='15' height='15' fill='white' fill-opacity='0.01'></rect>
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z'
                      fill='white'
                    ></path>
                  </svg>
                }
                onClick={() => {
                  this.props.history.push($ReplaceRoute(`${AppRoutes.new_research}`));
                }}
              >
                New Project
              </MainButton>
            </div>
          </div>
          {/* <div
      className="d-flex flex-xl-row flex-column row-gap-xl-0 row-gap-px-16 px-3 pb-3 filters" id="showFilter">
        <RadixFilter
          searchValue={this.state.Search}
          onSearchChange={(e) => {
            this.setState({ Search: e.target.value });
            this.handleChangeResearchSearch(e);
          }}
          onPriorityChange={this.handlePriorityChange}
          statusOptions={this.statusOptions}
          priorityOptions={this.priorityOptions}
          onStatusChange={this.handleStatusChange}
          onselect={(
            selectedFromDate,
            selectedToDate,
            status,
            selectedPriority
          ) =>
            this.filterClick(
              selectedFromDate,
              selectedToDate,
              status,
              selectedPriority,
              this.state.Search
            )
          }
          onStartDateChange={this.handleStartDateChange}
          onEndDateChange={this.handleEndDateChange}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
        />
    </div> */}

          <div
            className='d-flex flex-wrap align-items-center gap-3 px-3 pb-3 filters'
            id='showFilter'
          >
            <div className='w-100 pe-xl-3 border-end-xl-1 border-gray-400 d-sm-block d-flex gap-3 flex-grow-1 filters-search-wrapper'>
              <InputIcon
                className='form-control h-px-35'
                placeholder='Search your project here'
                chilren={undefined}
                onChange={(e) => {
                  this.setState({ Search: e.target.value });
                  this.handleChangeResearchSearch(e);
                }}
              />
              <button
                className='btn btn-secondary d-sm-none h-px-35 w-px-35 p-0'
                id='filterToggler'
                onClick={this.filterToggle}
              >
                <img src='/images/icons/mixer-horizontal.svg' />
                <i className='fa fa-times d-none color-gray-1000'></i>
              </button>
            </div>

            <div className='d-none d-xl-flex align-items-center gap-3 gap-sm-2 project-page-filters'>
              <div className='w-100 w-sm-50'>
                <MultiSelect options={this.statusOptions} onChange={this.handleStatusChange} />
              </div>
              <div className='w-100 w-sm-50'>
                <MultiSelect options={this.priorityOptions} onChange={this.handlePriorityChange} />
              </div>
            </div>
            <div className='d-none d-xl-flex align-items-center border-gray-400 w-100 w-sm-auto research-dates-wrapper'>
              <div className='w-50 w-xl-auto date-pickers-wrapper'>
                <div className='date-picker-wrapper'>
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleStartDateChange}
                    dateFormat='MM/dd/yyyy'
                    placeholderText='Start Date'
                    // maxDate={new Date()}
                    className='form-control h-px-35 customBorders'
                  />
                </div>
              </div>
              <div className='px-2 flex-shrink-0 color-gray-400'>&ndash;</div>
              <div className='w-50 w-xl-auto date-pickers-wrapper'>
                <div className='date-picker-wrapper'>
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={this.handleEndDateChange}
                    dateFormat='MM/dd/yyyy'
                    placeholderText='End Date'
                    //maxDate={new Date()}
                    className='form-control h-px-35 customBorders'
                  />
                </div>
              </div>
            </div>

            <div className='w-100 d-flex flex-wrap row-gap-2 column-gap-3 sub-filters d-xl-none'>
              <div className='w-100 d-flex align-items-center gap-3 gap-sm-2 project-page-filters'>
                <div className='w-100 w-sm-50'>
                  <MultiSelect options={this.statusOptions} onChange={this.handleStatusChange} />
                </div>
                <div className='w-100 w-sm-50'>
                  <MultiSelect
                    options={this.priorityOptions}
                    onChange={this.handlePriorityChange}
                  />
                </div>
              </div>
              <div className='d-flex align-items-center w-100 w-sm-auto research-dates-wrapper'>
                <div className='w-50 w-xl-auto date-pickers-wrapper'>
                  <div className='date-picker-wrapper'>
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={this.handleStartDateChange}
                      dateFormat='MM/dd/yyyy'
                      placeholderText='Start Date'
                      // maxDate={new Date()}
                      className='form-control h-px-35 customBorders'
                    />
                  </div>
                </div>
                <div className='px-2 flex-shrink-0 color-gray-400'>&ndash;</div>
                <div className='w-50 w-xl-auto date-pickers-wrapper'>
                  <div className='date-picker-wrapper'>
                    <DatePicker
                      selected={this.state.endDate}
                      onChange={this.handleEndDateChange}
                      dateFormat='MM/dd/yyyy'
                      placeholderText='End Date'
                      //maxDate={new Date()}
                      className='form-control h-px-35 customBorders'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <RadixTableCollapse
            data={[
              ...this.state.Groups,
              ...(this.state.Researches.length > 0
                ? [
                    {
                      id: 'uncategorized',
                      title: 'Ungrouped Projects',
                      deadline: '',
                      coordinator: {
                        firstName: '',
                        lastName: '',
                        image: '',
                      },
                      status: null,
                      priority: null,
                      researchesList: this.state.Researches,
                    },
                  ]
                : []),
            ]}
            loading={this.state.loading}
            onHeaderClick={(columnHeaderName: string, sortDirection: SortType) =>
              this.headerClick(columnHeaderName, sortDirection)
            }
            onExpandedRowsChange={this.handleExpandedRowsChange}
            handleDuplicate={this.handleDuplicate}
            columns={[
              {
                Header: 'Project Name',
                accessor: 'title',
                width: '294px',
                Cell: (_props: any) => {
                  return (
                    <Table.Cell
                      className='p-3 parent-row-background'
                      style={{ minWidth: '294px', width: '294px' }}
                    >
                      <div className='line-clamp-2 textWrapLabel'>
                        {_props.researchesList.length > 0 && (
                          <i
                            className={`fa ${
                              this.state.ExpandedRowsState[_props.id]
                                ? 'fa-chevron-down'
                                : 'fa-chevron-right'
                            } me-2 color-gray-600 fs-13`}
                          />
                        )}
                        <i className='color-gray-600 fs-13'></i>
                        <span style={{ paddingLeft: '16px' }}>
                          {_props.title.split(' ').slice(0, 4).join(' ')}{' '}
                        </span>
                      </div>
                    </Table.Cell>
                  );
                },
              },
              {
                Header: 'Deadline',
                accessor: 'deadline',
                width: '132px',
                Cell: (props: any) => {
                  return (
                    <>
                      {props.id !== 'uncategorized' ? (
                        <Table.Cell
                          className='p-3 text-nowrap parent-row-background'
                          style={{ minWidth: '132px', width: '132px' }}
                        >
                          {moment(props.endDate).format(MOMENT_DATE_FORMAT)}
                        </Table.Cell>
                      ) : (
                        <Table.Cell className='p-3 text-nowrap parent-row-background'></Table.Cell>
                      )}
                    </>
                  );
                },
              },
              {
                Header: 'Project Coordinator',
                accessor: 'coordinator',
                width: '200px',
                Cell: (props: any) => {
                  return (
                    <>
                      {props.id !== 'uncategorized' ? (
                        <Table.Cell
                          className='p-3 parent-row-background text-nowrap'
                          style={{ minWidth: '200px', width: '200px' }}
                        >
                          <ImageWithToken
                            originalImage={props.creatorUser.image}
                            hasImage={props.creatorUser.image ? true : false}
                            alt='Avatar'
                            className='rounded-circle avatar rounded-avatar-dashboard'
                            src={
                              props.creatorUser.image === null ||
                              props.creatorUser.image === '' ||
                              props.creatorUser.image === undefined
                                ? '/images/images/img_avatar.png'
                                : props.creatorUser.image
                            }
                            textInAvatar={`${props.creatorUser.firstName} ${props.creatorUser.lastName}`}
                          />
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              this.props.history.push(
                                $ReplaceRoute(
                                  `${AppRoutes.member_profile.replace(
                                    ':id',
                                    props.creatorUser.id?.toString() ?? ''
                                  )}`
                                )
                              );
                            }}
                          >
                            {props.creatorUser.firstName} {props.creatorUser.lastName}
                          </span>
                        </Table.Cell>
                      ) : (
                        <Table.Cell className='p-3 parent-row-background'></Table.Cell>
                      )}
                    </>
                  );
                },
              },
              {
                Header: 'Status',
                accessor: 'status',
                width: '132px',
                Cell: (props: any) => {
                  return (
                    <Table.Cell
                      className='p-3 parent-row-background'
                      style={{ minWidth: '132px', width: '132px' }}
                    >
                      {this.renderStatus(props.status)}
                    </Table.Cell>
                  );
                },
              },
              {
                Header: 'Priority',
                accessor: 'priority',
                width: '113px',
                Cell: (props: any) => {
                  return (
                    <Table.Cell
                      className='p-3 parent-row-background'
                      style={{ minWidth: '113px', width: '113px' }}
                    >
                      {this.renderPrioirty(props.priority)}
                    </Table.Cell>
                  );
                },
              },
              {
                Header: '',
                width: 'auto',
                Cell: (props: any) => {
                  return (
                    <>
                      {props.id !== 'uncategorized' ? (
                        <Table.Cell
                          className='p-3 text-nowrap parent-row-background'
                          style={{ width: 'auto' }}
                        >
                          <div className='d-flex align-items-center gap-3'>
                            <div className='btn-group'>
                              <button
                                type='button'
                                className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleNewProject(props.id);
                                }}
                              >
                                <svg
                                  className='hover-fill'
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='15'
                                  height='15'
                                  viewBox='0 0 15 15'
                                >
                                  <rect
                                    width='15'
                                    height='15'
                                    fill='white'
                                    fill-opacity='0.01'
                                  ></rect>
                                  <path
                                    fill-rule='evenodd'
                                    clip-rule='evenodd'
                                    d='M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z'
                                    fill='currentColor'
                                  ></path>
                                </svg>
                                <span className='ps-2'>New Project</span>
                              </button>

                              <DropdownMenu.Root>
                                <DropdownMenu.Trigger>
                                  <button
                                    type='button'
                                    className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split'
                                    data-bs-toggle='dropdown'
                                    aria-expanded='false'
                                  >
                                    <span className='visually-hidden'>Toggle Dropdown</span>
                                  </button>
                                </DropdownMenu.Trigger>
                                <DropdownMenu.Content>
                                  <DropdownMenu.Item className='px-0'>
                                    <button
                                      className='dropdown-item fs-14'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.handleNewProject(props.id);
                                      }}
                                    >
                                      New Project
                                    </button>
                                  </DropdownMenu.Item>
                                  <DropdownMenu.Item className='px-0'>
                                    <button
                                      className='dropdown-item fs-14'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.handleEditGroup(props.id);
                                      }}
                                    >
                                      Edit Group
                                    </button>
                                  </DropdownMenu.Item>
                                  <DropdownMenu.Item className='px-0'>
                                    <button
                                      className='dropdown-item fs-14'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.handleDeleteGroup(props.id);
                                      }}
                                    >
                                      Delete Group
                                    </button>
                                  </DropdownMenu.Item>
                                </DropdownMenu.Content>
                              </DropdownMenu.Root>
                            </div>
                          </div>
                        </Table.Cell>
                      ) : (
                        <Table.Cell className='p-3 text-nowrap parent-row-background'></Table.Cell>
                      )}
                    </>
                  );
                },
              },
            ]}
          />
          {/* <RadixTable
      data={this.state.Groups}
      loading={this.state.loading}
      onHeaderClick={(columnHeaderName: string, sortDirection: SortType) =>
        this.headerClick(columnHeaderName, sortDirection)
      }
      columns={[
        {
          Header: 'Project Name',
          accessor: 'title',
          Cell: (_props: any) => {
            return (
              <Table.Cell className='p-3'>
                <div className='line-clamp-2 textWrapLabel'>
                  <i className='fa fa-grip-vertical me-2 color-gray-600 fs-13'></i>
                  <span
                    onClick={() => {
                      this.props.history.push(
                        $ReplaceRoute(
                          `${AppRoutes.profile_research.replace(
                            ':id',
                            _props.id?.toString() ?? ''
                          )}`
                        )
                      );
                    }}
                    title={_props.title}
                  >
                    {' '}
                    {_props.title.split(" ").slice(0, 4).join(" ")}{' '}
                  </span>
                </div>
              </Table.Cell>
            );
          },
        },
        {
          Header: 'Deadline',
          accessor: 'deadline',
          Cell: (props: any) => {
            return (
              <Table.Cell className='p-3 text-nowrap'>
                {moment(props.endDate).format(MOMENT_DATE_FORMAT)}
              </Table.Cell>
            );
          },
        },
        // {
        //   Header: 'Project Coordinator',
        //   accessor: 'coordinator',
        //   Cell: (props: any) => {
        //     return (
        //       <Table.Cell className='p-3'>
        //         <div className='d-flex align-items-center text-nowrap'>
        //           {props.creatorUser.image === null ||
        //           props.creatorUser.image === '' ||
        //           props.creatorUser.image === undefined ? (
        //             <div className='avatar w-px-25 h-px-25 rounded-circle bg-primary'>
        //               <label className='fw-medium text-white fs-10'>
        //                 {getUserInitails(
        //                   `${props.creatorUser.firstName} ${props.creatorUser.lastName}`
        //                 )}
        //               </label>
        //             </div>
        //           ) : (
        //             <ImageWithToken
        //               originalImage={props.creatorUser.image}
        //               hasImage={props.creatorUser.image ? true : false}
        //               alt='Avatar'
        //               className='rounded-circle avatar rounded-avatar-dashboard'
        //               src={
        //                 props.creatorUser.image === null ||
        //                 props.creatorUser.image === '' ||
        //                 props.creatorUser.image === undefined
        //                   ? '/images/images/img_avatar.png'
        //                   : props.creatorUser.image
        //               }
        //             />
        //           )}
        //           <span
        //             style={{ cursor: 'pointer' }}
        //             onClick={() => {
        //               this.props.history.push(
        //                 $ReplaceRoute(
        //                   `${AppRoutes.member_profile.replace(
        //                     ':id',
        //                     props.creatorUser.id?.toString() ?? ''
        //                   )}`
        //                 )
        //               );
        //             }}
        //           >
        //             {props.creatorUser.firstName} {props.creatorUser.lastName}
        //           </span>
        //         </div>
        //       </Table.Cell>
        //     );
        //   },
        // },
        // {
        //   Header: 'Status',
        //   accessor: 'status',
        //   Cell: (props: any) => {
        //     return <Table.Cell className='p-3'>{this.renderStatus(props.status)}</Table.Cell>;
        //   },
        // },
        {
          Header: 'Priority',
          accessor: 'priority',
          Cell: (props: any) => {
            return (
              <Table.Cell className='p-3'>{this.renderPrioirty(props.priority)}</Table.Cell>
            );
          },
        },
        {
          Header: '',
          Cell: (props: any) => {
            return (
              <Table.Cell className='p-3 text-nowrap'>
                <div className='d-flex align-items-center gap-3'>
                  <div className='btn-group'>
                    <button
                      type='button'
                      className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleViewDetails(props.id);
                      }}
                    >
                      <i className='fa fa-eye me-px-6'></i>View Details
                    </button>

                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger>
                        <button
                          type='button'
                          className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        >
                          <span className='visually-hidden'>Toggle Dropdown</span>
                        </button>
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content>
                        <DropdownMenu.Item className='px-0'>
                          <button
                            className='dropdown-item fs-14'
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleViewDetails(props.id);
                            }}
                          >
                            View Details
                          </button>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item className='px-0'>
                          <button
                            className='dropdown-item fs-14'
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleDuplicate(props.id);
                            }}
                          >
                            Duplicate Project
                          </button>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item className='px-0'>
                          <button
                            className='dropdown-item fs-14'
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleEdit(props.id);
                            }}
                          >
                            Edit Project
                          </button>
                        </DropdownMenu.Item>
                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </div>
                </div>
              </Table.Cell>
            );
          },
        },
      ]}
    /> */}
          {/* <RadixTable
      data={this.state.Researches}
      loading={this.state.loading}
      onHeaderClick={(columnHeaderName: string, sortDirection: SortType) =>
        this.headerClick(columnHeaderName, sortDirection)
      }
      columns={[
        {
          Header: 'Project Name',
          accessor: 'title',
          Cell: (_props: any) => {
            return (
              <Table.Cell className='p-3'>
                <div className='line-clamp-2 textWrapLabel'>
                  <i className='fa fa-grip-vertical me-2 color-gray-600 fs-13'></i>
                  <span
                    onClick={() => {
                      this.props.history.push(
                        $ReplaceRoute(
                          `${AppRoutes.profile_research.replace(
                            ':id',
                            _props.id?.toString() ?? ''
                          )}`
                        )
                      );
                    }}
                    title={_props.title}
                  >
                    {' '}
                    {_props.title.split(" ").slice(0, 4).join(" ")}{' '}
                  </span>
                </div>
              </Table.Cell>
            );
          },
        },
        {
          Header: 'Deadline',
          accessor: 'deadline',
          Cell: (props: any) => {
            return (
              <Table.Cell className='p-3 text-nowrap'>
                {moment(props.endDate).format(MOMENT_DATE_FORMAT)}
              </Table.Cell>
            );
          },
        },
        {
          Header: 'Project Coordinator',
          accessor: 'coordinator',
          Cell: (props: any) => {
            return (
              <Table.Cell className='p-3'>
                <div className='d-flex align-items-center text-nowrap'>
                  {props.creatorUser.image === null ||
                  props.creatorUser.image === '' ||
                  props.creatorUser.image === undefined ? (
                    <div className='avatar w-px-25 h-px-25 rounded-circle bg-primary'>
                      <label className='fw-medium text-white fs-10'>
                        {getUserInitails(
                          `${props.creatorUser.firstName} ${props.creatorUser.lastName}`
                        )}
                      </label>
                    </div>
                  ) : (
                    <ImageWithToken
                      originalImage={props.creatorUser.image}
                      hasImage={props.creatorUser.image ? true : false}
                      alt='Avatar'
                      className='rounded-circle avatar rounded-avatar-dashboard'
                      src={
                        props.creatorUser.image === null ||
                        props.creatorUser.image === '' ||
                        props.creatorUser.image === undefined
                          ? '/images/images/img_avatar.png'
                          : props.creatorUser.image
                      }
                    />
                  )}
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.props.history.push(
                        $ReplaceRoute(
                          `${AppRoutes.member_profile.replace(
                            ':id',
                            props.creatorUser.id?.toString() ?? ''
                          )}`
                        )
                      );
                    }}
                  >
                    {props.creatorUser.firstName} {props.creatorUser.lastName}
                  </span>
                </div>
              </Table.Cell>
            );
          },
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: (props: any) => {
            return <Table.Cell className='p-3'>{this.renderStatus(props.status)}</Table.Cell>;
          },
        },
        {
          Header: 'Priority',
          accessor: 'priority',
          Cell: (props: any) => {
            return (
              <Table.Cell className='p-3'>{this.renderPrioirty(props.priority)}</Table.Cell>
            );
          },
        },
        {
          Header: '',
          Cell: (props: any) => {
            return (
              <Table.Cell className='p-3 text-nowrap'>
                <div className='d-flex align-items-center gap-3'>
                  <div className='btn-group'>
                    <button
                      type='button'
                      className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleViewDetails(props.id);
                      }}
                    >
                      <i className='fa fa-eye me-px-6'></i>View Details
                    </button>

                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger>
                        <button
                          type='button'
                          className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        >
                          <span className='visually-hidden'>Toggle Dropdown</span>
                        </button>
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content>
                        <DropdownMenu.Item className='px-0'>
                          <button
                            className='dropdown-item fs-14'
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleViewDetails(props.id);
                            }}
                          >
                            View Details
                          </button>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item className='px-0'>
                          <button
                            className='dropdown-item fs-14'
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleDuplicate(props.id);
                            }}
                          >
                            Duplicate Project
                          </button>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item className='px-0'>
                          <button
                            className='dropdown-item fs-14'
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleEdit(props.id);
                            }}
                          >
                            Edit Project
                          </button>
                        </DropdownMenu.Item>
                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </div>
                </div>
              </Table.Cell>
            );
          },
        },
      ]}
    /> */}
          {this.state.PageCount > 0 && this.state.Groups.length > 0 && (
            <Pagination
              pageCount={this.state.PageCount}
              onPageChange={this.handelChangePageNumber.bind(this)}
              onPageSizeChange={(e: any) => {
                this.handelChagePageSize(e);
              }}
              pageInput={this.state.PageNumberInput}
              handlePageNumberInput={this.handlePageNumberInput}
            />
          )}
        </div>
      </>
    );
  }
}

export default withRouter(ResearchPage);
