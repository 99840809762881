import React from 'react';
import './SeatCard.css'; // Create a separate CSS file for styling

interface SeatCardProps {
  availableSeats: number;
  activeUsers: number;
  readonly: number;
  deActivated: number;
  totalSeats: number;
}

const SeatCard: React.FC<SeatCardProps> = ({
  availableSeats,
  activeUsers,
  readonly,
  deActivated,
  totalSeats,
}) => {
  return (
    <div className='seat__cards-wrapper p-3'>
      {availableSeats !== undefined && (
        <div className='seat__cards d-flex flex-column'>
          <div
            className='d-flex flex-column align-items-center justify-content-center'
            style={{ height: '100%' }}
          >
            <div className='s-chip seat-fonts-number seatsCardStyle'>{availableSeats}</div>
            <div className='s-chip fs-200 seat-fonts seatFontsStyle'>Available Seats</div>
          </div>
          {/* <p>Available Seats: {availableSeats}</p> */}
        </div>
      )}
      {activeUsers !== undefined && (
        <div className='seat__cards d-flex flex-column'>
          <div
            className='d-flex flex-column align-items-center justify-content-center'
            style={{ height: '100%' }}
          >
            <div className='s-chip seat-fonts-number seatsCardStyle'>{activeUsers}</div>
            <div className='s-chip fs-200  seat-fonts seatFontsStyle'>Active Users</div>
          </div>
          {/* <p>Available Seats: {availableSeats}</p> */}
        </div>
      )}
      {readonly !== undefined && (
        <div className='seat__cards d-flex flex-column'>
          <div
            className='d-flex flex-column align-items-center justify-content-center'
            style={{ height: '100%' }}
          >
            <div className='s-chip seat-fonts-number seatsCardStyle'>{readonly}</div>
            <div className='s-chip fs-200  seat-fonts seatFontsStyle'>Read-Only</div>
          </div>
          {/* <p>Available Seats: {availableSeats}</p> */}
        </div>
      )}
      {deActivated !== undefined && (
        <div className='seat__cards d-flex flex-column'>
          <div
            className='d-flex flex-column align-items-center justify-content-center'
            style={{ height: '100%' }}
          >
            <div className='s-chip seat-fonts-number seatsCardStyle'>{deActivated}</div>
            <div className='s-chip fs-200  seat-fonts seatFontsStyle'>Deactivated</div>
          </div>
          {/* <p>Available Seats: {availableSeats}</p> */}
        </div>
      )}
      {totalSeats !== undefined && (
        <div className='seat__cards d-flex flex-column'>
          <div
            className='d-flex flex-column align-items-center justify-content-center'
            style={{ height: '100%' }}
          >
            <div className='s-chip seat-fonts-number seatsCardStyle'>{totalSeats}</div>
            <div className='s-chip fs-200  seat-fonts seatFontsStyle'>Total Seats</div>
          </div>
          {/* <p>Available Seats: {availableSeats}</p> */}
        </div>
      )}
    </div>
  );
};

export default SeatCard;
