import { toast } from "react-toastify";
import { HTTP } from "../../core/http_common";
import { SortType } from "../../core/number_extentions";

type Equip = {
  id: number;
  title: string;
};
export interface GetReservationSearchReqRes {
  equipments: Equip[];
  laboratories: {
    id: number;
    title: string;
    equipments: Equip[];
  }[];
}
export interface CreateEqipmentReservationReqBody {
  equipmentId: number;
  start: string | Date;
  end: string | Date;
}
export interface GetEquipmentReservationReqBody {
  LaboratoryId: number | null;
  EquipmentId: number | null;
  From: string;
  To: string;
  // IsUsersAllLabsEquipmentsReservation?: boolean;
  // IsUsersAllEquipmentsReservation?: boolean;
}
export interface GetEquipmentReservationAllReqBody {
  LaboratoryId: number | null;
  EquipmentId: number | null;
  // From: string;
  // To: string;
  PageSize: number;
  PageNumber: number;
  SearchParameter: string;
  columnHeaderName?: string;
  sortDirection?: SortType;
  // IsUsersAllLabsEquipmentsReservation?: boolean;
  // IsUsersAllEquipmentsReservation?: boolean;
}
export interface GetEquipmentReservationReqRes {
  reservationId: number;
  start: string;
  end: string;
  status: number;
  laboratories: {
    id: number;
    title: string;
  }[];
  equipment: {
    id: number;
    title: string;
  };
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    image: string | null;
    invitationStatus: number;
  };
  manager: any | null;
  isUserManagerOfEquipmentsLaboratory: boolean;
}

export interface ChangeReservationStatusReqBody {
  id: number;
  equipmentId: number;
  laboratoriesIds: number[];
  status: 1 | 2 | 3 | 4 | 5;
}
export interface UpdateReservationStatusReqBody {
  id: number;
  equipmentId: number;
  laboratoriesIds: number[];
  start: string;
  end: string;
}

export function createEquipmentReservation(
  _body: CreateEqipmentReservationReqBody
) {
  return new Promise((resolve, reject) => {
    
    HTTP.post("/Equipment/Reservation", _body)
      .then((_res) => {
        toast.success("Reservation created successfully");
        resolve(_res);
      })
      .catch((_err) => {
        // toast.error("Reservation creation failed");
        if (_err.response?.data.message)
          toast.error(`${_err.response?.data.message}`);
        reject(_err);
      });
  });
}

export function getEquipmentReservations(
  _body: GetEquipmentReservationReqBody
): Promise<GetEquipmentReservationReqRes[]> {
  return new Promise((resolve, reject) => {
    // const _query = `LaboratoryId=${_body.LaboratoryId}&EquipmentId=${_body.EquipmentId}&From=${_body.From}&To=${_body.To}&IsUsersAllLabsEquipmentsReservation=${_body.IsUsersAllLabsEquipmentsReservation}&IsUsersAllEquipmentsReservation=${_body.IsUsersAllEquipmentsReservation}`;
    let _query = ``;
    if (_body.LaboratoryId) {
      _query += `LaboratoryId=${_body.LaboratoryId}&`;
    }
    if (_body.EquipmentId) {
      _query += `EquipmentId=${_body.EquipmentId}&`;
    }
    if (_body.From) {
      _query += `From=${_body.From}&`;
    }
    if (_body.To) {
      _query += `To=${_body.To}&`;
    }
    // if (_body.IsUsersAllLabsEquipmentsReservation) {
    //   _query += `IsUsersAllLabsEquipmentsReservation=${_body.IsUsersAllLabsEquipmentsReservation}&`;
    // }
    // if (_body.IsUsersAllEquipmentsReservation) {
    //   _query += `IsUsersAllEquipmentsReservation=${_body.IsUsersAllEquipmentsReservation}&`;
    // }

    HTTP.get(`/Equipment/GetReservationList?${_query}`)
      .then((_res) => {
        if (_res.data && _res.data.result) {
          resolve(_res.data.result);
        } else {
          resolve([]);
        }
      })
      .catch((_err) => {
        if (_err.response?.data.message)
          toast.error(`${_err.response?.data.message}`);
        reject(_err);
      });
  });
}
export function getEquipmentReservationsAll(
  _body: GetEquipmentReservationAllReqBody
): Promise<{
  equipmentReservations: GetEquipmentReservationReqRes[];
  count: number;
}> {
  return new Promise((resolve, reject) => {
    // const _query = `LaboratoryId=${_body.LaboratoryId}&EquipmentId=${_body.EquipmentId}&From=${_body.From}&To=${_body.To}&IsUsersAllLabsEquipmentsReservation=${_body.IsUsersAllLabsEquipmentsReservation}&IsUsersAllEquipmentsReservation=${_body.IsUsersAllEquipmentsReservation}`;
    let _query = ``;
    if (_body.LaboratoryId) {
      _query += `LaboratoryId=${_body.LaboratoryId}&`;
    }
    if (_body.EquipmentId) {
      _query += `EquipmentId=${_body.EquipmentId}&`;
    }

    if (_body.PageNumber) {
      _query += `PageNumber=${_body.PageNumber}&`;
    }
    if (_body.PageSize) {
      _query += `PageSize=${_body.PageSize}&`;
    }
    if (_body.SearchParameter) {
      _query += `SearchParameter=${_body.SearchParameter}&`;
    }
    if (_body.columnHeaderName) {
      _query += `ColumnName=${_body.columnHeaderName}&`;
    }
    if (_body.sortDirection) {
      _query += `SortDirection=${_body.sortDirection}&`;
    }

    // if (_body.IsUsersAllLabsEquipmentsReservation) {
    //   _query += `IsUsersAllLabsEquipmentsReservation=${_body.IsUsersAllLabsEquipmentsReservation}&`;
    // }
    // if (_body.IsUsersAllEquipmentsReservation) {
    //   _query += `IsUsersAllEquipmentsReservation=${_body.IsUsersAllEquipmentsReservation}&`;
    // }

    HTTP.get(`/Equipment/GetAllReservationList?${_query}`)
      .then((_res) => {
        if (_res.data && _res.data.result) {
          resolve(_res.data.result);
        } else {
          resolve({
            equipmentReservations: [],
            count: 0,
          });
        }
      })
      .catch((_err) => {
        if (_err.response?.data.message)
          toast.error(`${_err.response?.data.message}`);
        reject(_err);
      });
  });
}

export function changeReservationStatus(_body: ChangeReservationStatusReqBody) {
  return new Promise((resolve, reject) => {
    HTTP.put("/Equipment/ChangeReservationStatus", _body)
      .then((_res) => {
        toast.success("Reservation status changed successfully");
        resolve(_res);
      })
      .catch((_err) => {
        if (_err.response?.data.message)
          toast.error(`${_err.response?.data.message}`);
        reject(_err);
      });
  });
}
export function updateReservation(_body: UpdateReservationStatusReqBody) {
  return new Promise((resolve, reject) => {
    HTTP.put("/Equipment/UpdateReservation", _body)
      .then((_res) => {
        toast.success("Reservation updated successfully");
        resolve(_res);
      })
      .catch((_err) => {
        if (_err.response?.data.message)
          toast.error(`${_err.response?.data.message}`);
        reject(_err);
      });
  });
}

export function getReservationSearch(): Promise<GetReservationSearchReqRes> {
  return new Promise((resolve, reject) => {
    HTTP.get("/Equipment/GetReservationSearch")
      .then((_res) => {
        resolve(_res.data.result);
      })
      .catch((_err) => {
        if (_err.response?.data.message)
          toast.error(`${_err.response?.data.message}`);
        reject(_err);
      });
  });
}
