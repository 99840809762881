import { toast } from "react-toastify";
import { HTTP } from "../../core/http_common"


export type User = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    image: string;
    invitationStatus: number;
    isUserTransferred: boolean;
    transferredToUserId: string
}
// export interface GetTransfrableMembersReqRes {
//     result: User[]
// }
function getTransfrableMembers(): Promise<User[]> {
    return new Promise((resolve, reject) => {
        HTTP.get('/Team/GetTransferableUsers').then((_res) => {
            resolve(_res.data.result)
        }).catch(_err => {
            reject(_err)
        })
    })
}
function getSearchMemberTransfer(_selectedUser: string): Promise<User[]> {
    return new Promise((resolve, reject) => {
        HTTP.get(`/Team/SearchMemberTransfer?userToTransferId=${_selectedUser}`).then((_res) => {
            resolve(_res.data.result)
        }).catch(_err => {
            reject(_err)
        })
    })
}


export interface GetTransferUserLogListReqBody {
    PageSize: number;
    PageNumber: number;
    SearchParameter: string;
}
export interface GetTransferUserLogListReqRes {
    id: number,
    creatorUser: User,
    userToTransfer: User,
    userTransferTo: User,
    transferDate: string,
    transferCreated: string,
    updatedAt: string,
    status: number,
    userToTransferResponse: number,
    userTransferToResponse: number,
    isTransferImmediate: boolean,
    remainingTime: number
}
function getTransferUserLogList(
    _body: GetTransferUserLogListReqBody
): Promise<{ userTransferLogs: GetTransferUserLogListReqRes[], count: number }> {
    return new Promise((resolve, reject) => {
        // const _query = `LaboratoryId=${_body.LaboratoryId}&EquipmentId=${_body.EquipmentId}&From=${_body.From}&To=${_body.To}&IsUsersAllLabsEquipmentsReservation=${_body.IsUsersAllLabsEquipmentsReservation}&IsUsersAllEquipmentsReservation=${_body.IsUsersAllEquipmentsReservation}`;
        let _query = ``;
        if (_body.PageNumber) {
            _query += `PageNumber=${_body.PageNumber}&`;
        }
        if (_body.PageSize) {
            _query += `PageSize=${_body.PageSize}&`;
        }
        if (_body.SearchParameter) {
            _query += `SearchParameter=${_body.SearchParameter}&`;
        }
        HTTP.get(`/Team/GetTransferUserLogList?${_query}`)
            .then((_res) => {
                if (_res.data && _res.data.result) {
                    resolve(_res.data.result);
                } else {
                    resolve({
                        count: 0,
                        userTransferLogs: []
                    });
                }
            })
            .catch((_err) => {
                reject(_err);
            });
    });
}



export interface CreateMemberTransferReqBody {
    userToTransfer: string,
    userTransferTo: string,
    transferDate: Date | null,
    isTransferImmediate: boolean
}
function createMemberTransfer(
    _body: CreateMemberTransferReqBody
) {
    return new Promise((resolve, reject) => {
        HTTP.post("/Team/CreateUserTransfer", _body)
            .then((_res) => {
                toast.success("Transfer created successfully");
                resolve(_res);
            })
            .catch((_err) => {
                // toast.error("Transfer creation failed");
                if (_err.response?.data.message)
                  toast.error(`${_err.response?.data.message}`);
                reject(_err);
            });
    });
}

export interface ChangeMemberTransferStatusReqBody {
    id: number,
    userResponse?: number,
    status?: number
}
function changeMemberTransferStatus(
    _body: ChangeMemberTransferStatusReqBody
) {
    return new Promise((resolve, reject) => {
        HTTP.post("/Team/UserTransferStatusChange", _body)
            .then((_res) => {
                toast.success("Transfer status changed successfully");
                resolve(_res);
            })
            .catch((_err) => {
                if (_err.response?.data.message)
                  toast.error(`${_err.response?.data.message}`);
                reject(_err);
            });
    });
}


export interface UpdateMemberTransferReqBody {
    id: number,
    transferDate: Date
}
function updateMemberTransfer(
    _body: UpdateMemberTransferReqBody
) {
    return new Promise((resolve, reject) => {
        HTTP.put("/Team/UpdateUserTransfer", _body)
            .then((_res) => {
                toast.success("Transfer updated successfully");
                resolve(_res);
            })
            .catch((_err) => {
                if (_err.response?.data.message)
                  toast.error(`${_err.response?.data.message}`);
                reject(_err);
            });
    });
}


export {
    createMemberTransfer,
    getSearchMemberTransfer,
    getTransferUserLogList,
    getTransfrableMembers,

    changeMemberTransferStatus,
    updateMemberTransfer
}