import React, { useState, useEffect, useRef } from 'react';
import { store } from '../../../data/storeMain';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MainButton, MainButtonType } from '../../components/button';
import { IconTextRow } from '../../components/icon_text_horizontal';
import ShowNotes from '../../components/notes';
import { Note } from '../../../data/models/responses/notes/notes';
import * as Dialog from '@radix-ui/react-dialog';
import {
  $ReplaceRoute,
  // $truncate,
  AccessPermition,
  DiscussionTopic,
  getUserInitails,
  // downloadFile,
  getUserTitle,
  isOnlyWhitespace,
  isTokenInHttpAuth,
  // removeDuplicateObj,
  // removeDuplicateObjWithKey,
  Theme,
  UserRoles,
  removeDuplicateObj,
} from '../../../core/utils';
import { BoxListScroll, ImageWithToken } from '../../components/box_list_scroll';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  // AppConstants,
  AppRoutes,
  DEFAULT_RESEARCH,
  MOMENT_DATE_FORMAT,
} from '../../../core/constants';
import { GetResearchByidResResult } from '../../../data/models/responses/research/research_by_id_res';
import { ResearchController } from '../../../controllers/research/research_controller';
import '../../../core/number_extentions';
import moment from 'moment';
import { HTTP } from '../../../core/http_common';
import Visible from '../../components/visible';
import { StatusLabel } from '../../components/status_label';
// import { SetResearchId } from "../../../data/storeMain/actions/research_action";
import Tippy from '@tippyjs/react';
import Skeleton from 'react-loading-skeleton';
import EmptyChip from '../../components/EmptyChip';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import { FaEdit, FaMicroscope, FaUsers } from 'react-icons/fa';
import ConfirmationContent from '../../components/ConfirmationContent';
import { MdDeleteOutline } from 'react-icons/md';
import File from '../../components/File';
// import { Link } from "react-router-dom";
import UserWithAvatar from '../../components/UserWithAvatar';
import { GiTalk } from 'react-icons/gi';
import { BsFillChatLeftDotsFill } from 'react-icons/bs';
import ProfileTitleDesc from '../../components/ProfileTitleDesc';
import { Priority, ProfileTypes, ResearchStatus, SortType } from '../../../core/number_extentions';
import SpinnerLoader from '../../components/spinnerLoader';
import { BaseButton, FontSizes } from '@fluentui/react';
import RadixTable from '../../components/NewRadixTable/RadixTable';
import { DropdownMenu, Table } from '@radix-ui/themes';
import { AvatarIcon, RulerSquareIcon, TransformIcon, ViewGridIcon } from '@radix-ui/react-icons';
import { SetResearchId } from '../../../data/storeMain/actions/research_action';
import { SetHeaderTitle } from '../../../data/storeMain/actions/header_title_action';
import { InputIcon } from '../../components/search_box';
import MultiSelect from '../../components/SelectRadix/radixMultiSelect';
import { TaskController } from '../../../controllers/task/task_controller';
import { IFilterOptions } from '../../components/FilterSection/IFilterOptions';
import { AppTask } from '../../../data/models/responses/task/get_all_tasks_res';
import RadixTableTasks from '../../components/NewRadixTable/RadixTableTasks';
import GroupedAvatars from '../../components/groupedAvatars';
import Pagination from '../../components/NewRadixTable/RadixPagination';
import DOMPurify from 'dompurify';
import debounce from 'lodash.debounce';

interface RouteParams {
  id: string;
}
type StateType = {
  setComposeEmail: boolean;
  Research: GetResearchByidResResult;
  changeResearchMenuVisible: boolean;
  changeResearchMenuForTaskVisible: boolean;
  changeResearchMenuForTaskListVisible: boolean;
  newEmailMenuVisible: boolean;
  showDuplicateLoader: boolean;
  loading: boolean;
  LoadNotes: boolean;
  selectedTab: number;
  sortedData: any[];
  visibleTippy: boolean;
  showModal: boolean;
  tasksLoading: boolean;
  Tasks: AppTask[];
  Search: string;
  PageNumber: number;
  PageNumberInput: string;
  PageSize: { value: number; label: string };
  PageCount: number;
  TotalCount: number;
  filterOptions: IFilterOptions;
  SortDirection: SortType;
  HeaderName: string;
  ExpandedRowsState: { [key: string]: boolean };
  // leftDivHeight:number | null | undefined;
  // leftDiv:any;
};
class ResearchPageProfile extends React.Component<RouteComponentProps<RouteParams>> {
  RoleUser = store.getState().userRole;
  controller = new ResearchController();
  taskController = new TaskController();
  state: StateType = {
    showDuplicateLoader: false,
    LoadNotes: false,
    setComposeEmail: false,
    // leftDiv:HTMLDivElement,

    Research: {
      id: 0,
      notes: [],
      title: '',
      description: '',
      creatorUser: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        image: '',
        invitationStatus: 0,
        isUserTransferred: false,
        transferredToUserId: '',
      },
      workSpaces: [],
      startDate: new Date(),
      endDate: new Date(),
      currency: 0,
      priority: 0,
      status: 0,
      medias: [],
      users: [],
      teams: [],
      laboratories: [],
      equipments: [],
      allowedToEdit: false,
      daysLeftDeadline: 0,
      haveDiscussion: false,
    },
    changeResearchMenuVisible: false,
    changeResearchMenuForTaskVisible: false,
    changeResearchMenuForTaskListVisible: false,
    newEmailMenuVisible: false,
    loading: true,
    selectedTab: 0,
    sortedData: [],
    visibleTippy: false,
    showModal: false,
    tasksLoading: false,
    Tasks: [],
    Search: '',
    PageNumber: 1,
    PageNumberInput: '',
    PageSize: { value: 10, label: '10' },
    PageCount: 0,
    TotalCount: 0,
    filterOptions: {},
    SortDirection: SortType.ASC,
    HeaderName: '',
    ExpandedRowsState: {},
    // leftDivHeight:0
  };

  constructor(props: any) {
    super(props);
    this.handleChangeTaskSearch = debounce(this.handleChangeTaskSearch.bind(this), 500);
    this.handlePageNumberInput = this.handlePageNumberInput.bind(this);
    this.handleGoToPage = this.handleGoToPage.bind(this);
  }

  componentDidMount() {
    if (!isTokenInHttpAuth(HTTP)) return;
    this.setState({
      loading: true,
    });
    this.controller.getResearchById(
      { id: parseInt(this.props.match.params.id) },
      (res) => {
        document.title = `Research | ${res.title} | Radvix`;
        store.dispatch(SetHeaderTitle('Projects'));
        const _users = res?.users?.map((user: any) => {
          const userTitleAst = getUserTitle(user);
          return {
            ...user,
            title: userTitleAst,
            secondary: user.invitationStatus === 1 ? 'Pending' : null,
          };
        });
        res?.teams?.forEach((team) => {
          team.users.forEach((user: any) => {
            if (_users.findIndex((tmepUser) => tmepUser.id === user.id) === -1) {
              _users.push({
                ...user,
                title: getUserTitle(user),
                secondary: user.invitationStatus === 1 ? 'Pending' : null,
              });
            }
          });
        });

        const _equipments = res?.equipments;
        res?.laboratories?.forEach((lab: any) => {
          lab.equipments.forEach((eqip: any) => {
            if (_equipments.findIndex((tmepEquip) => tmepEquip.id === eqip.id) === -1) {
              _equipments.push({
                ...eqip,
                // title: getUserTitle(user),
                // secondary: user.invitationStatus === 1 ? "Pending" : null,
              });
            }
          });
        });

        // const __users = removeDuplicateObjWithKey(_users, "id");
        // const __equipments = removeDuplicateObjWithKey(_equipments, "id");
        this.setState({
          Research: {
            ...res,
            Notes: res?.notes,
            users: _users,
            equipments: _equipments,
          },
          loading: false,
          LoadNotes: true,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });
        if (err.response?.data?.status === 403) {
          window.history.back();
        }
      }
    );

    this.GetTasks(
      parseInt(this.props.match.params.id),
      this.state.PageNumber,
      this.state.PageSize.value
    );
  }

  GetTasks(
    researchId: number,
    PageNumber: number,
    PageSize: number,
    Search?: string,
    columnHeaderName?: string,
    sortDirection?: SortType,
    filterOptions?: IFilterOptions
  ) {
    this.setState({
      tasksLoading: true,
    });

    this.taskController.getTasks(
      {
        researchId: researchId,
        PageNumber,
        PageSize,
        SearchParameter: Search ? Search : this.state.Search,
        columnHeaderName,
        sortDirection,
        filterOptions,
      },
      (res) => {
        if (res === null) {
          this.setState({
            Tasks: [],
            PageCount: 0,
            TotalCount: 0,
            tasksLoading: false,
          });
          return;
        }
        const _tasks = res.appTasks.map((_task) => {
          let _taskTeamUsers = _task.appTask.teams.map((_t) => _t?.users).flat();

          const _usersWithTeamUsers = [..._task.appTask.users, ..._taskTeamUsers];

          return {
            appTask: {
              ..._task.appTask,
              users: removeDuplicateObj(_usersWithTeamUsers),
            },
            subAppTasks: _task.subAppTasks.map((_subTask) => {
              let _subtaskTeamUsers = _subTask.teams.map((_t) => _t?.users).flat();

              const _usersWithTeamUsers = [..._subTask.users, ..._subtaskTeamUsers];
              
              return {
                ..._subTask,
                users: removeDuplicateObj(_usersWithTeamUsers),
                researchId: this.props.match.params.id,
              };
            }),
          };
        });

        this.setState({
          Tasks: _tasks,
          PageCount: Math.ceil(res.count! / this.state.PageSize.value),
          TotalCount: res.count,
          tasksLoading: false,
        });
      },
      (err) => {
        this.setState({
          tasksLoading: false,
        });
      }
    );
  }

  handleChangeTaskSearch(e: any) {
    const inputValue = e.target.value;
    const sanitizedValue = DOMPurify.sanitize(inputValue);
    this.GetTasks(
      parseInt(this.props.match.params.id),
      this.state.PageNumber,
      this.state.PageSize.value,
      sanitizedValue
    );
  }

  filterClick = (
    selectedFromDate?: Date | null,
    selectedToDate?: Date | null,
    status?: any[] | null,
    teamType?: number[]
  ) => {
    var filterOptions = {
      selectedFromDate: selectedFromDate,
      selectedToDate: selectedToDate,
      status: status,
      teamType: teamType,
    };
    this.setState({ filterOptions: filterOptions });
    this.GetTasks(
      parseInt(this.props.match.params.id),
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      filterOptions
    );
  };

  filterToggle = () => {
    const filteroggleButton = document.getElementById('filterToggler');
    const showFilter = document.getElementById('showFilter');
    const isFilterShown = document.getElementById('isFilterShown');
    showFilter?.classList.toggle('show-filters');
    filteroggleButton?.classList.toggle('filters-hide');
    isFilterShown?.classList.toggle('filters-height');
  };

  statusOptions: any[] = [
    { label: 'All Status', value: -1, svg: '' },
    {
      label: 'Completed',
      value: ResearchStatus.Completed,
      backgroundColor: 'bg-success',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#299764"></circle></svg>',
    },
    {
      label: 'Delayed',
      value: ResearchStatus.Delayed,
      backgroundColor: 'bg-warning',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#ED5F00"></circle></svg>',
    },
    {
      label: 'On Going',
      value: ResearchStatus.OnGoing,
      backgroundColor: 'bg-info',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#0880EA"></circle></svg>',
    },
    {
      label: 'On Hold',
      value: ResearchStatus.OnHold,
      backgroundColor: 'bg-OnHold',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#7E808A"></circle></svg>',
    },
  ];

  handleStatusChange = (selectedStatus: any[]) => {
    const allValue = selectedStatus.find((x) => x.value == -1);
    const value = allValue ? null : selectedStatus;
    this.filterClick(
      this.state.filterOptions.selectedFromDate,
      this.state.filterOptions.selectedToDate,
      value
    );
  };

  handelChangePageNumber(e: { selected: number }) {
    this.setState({
      PageNumber: e.selected,
    });
    this.GetTasks(
      parseInt(this.props.match.params.id),
      e.selected + 1,
      this.state.PageSize.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      this.state.filterOptions
    );
  }

  handelChangePageSize(e: { label: string; value: number }) {
    this.setState({
      PageSize: e,
    });
    this.GetTasks(
      parseInt(this.props.match.params.id),
      this.state.PageNumber,
      e.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      this.state.filterOptions
    );
  }

  handlePageNumberInput(event: { target: { value: any } }) {
    if (event.target.value > 0) {
      this.setState({ PageNumberInput: event.target.value });
    } else {
      this.setState({ PageNumberInput: '' });
    }
  }

  handleGoToPage() {
    const pageNumber = parseInt(this.state.PageNumberInput, 10);
    if (pageNumber > 0 && pageNumber <= this.state.PageCount) {
      this.handelChangePageNumber({ selected: pageNumber - 1 });
    } else {
      console.log('Invalid page number');
    }
  }

  tasksHeaderClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    this.setState({ HeaderName: columnHeaderName });
    this.setState({ SortDirection: sortDirection });
    this.GetTasks(
      parseInt(this.props.match.params.id),
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.Search,
      columnHeaderName,
      sortDirection,
      this.state.filterOptions
    );
  };

  handleExpandedRowsChange = (updatedExpandedRows: { [key: string]: boolean }) => {
    this.setState({ ExpandedRowsState: updatedExpandedRows });
  };

  headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    this.setState({ HeaderName: columnHeaderName });
    this.setState({ SortDirection: sortDirection });
    let sortedUsers = [...this.state.Research.users];

    if (columnHeaderName === 'username') {
      sortedUsers.sort((a, b) => {
        if (a.firstName < b.firstName) return sortDirection === SortType.ASC ? -1 : 1;
        if (a.firstName > b.firstName) return sortDirection === SortType.ASC ? 1 : -1;
        return 0;
      });
    } else if (columnHeaderName === 'userTeam') {
      sortedUsers.sort((a: any, b: any) => {
        const aTeamTitle = a.userTeam && a.userTeam[0] ? a.userTeam[0].title : '';
        const bTeamTitle = b.userTeam && b.userTeam[0] ? b.userTeam[0].title : '';
        if (aTeamTitle < bTeamTitle) return sortDirection === SortType.ASC ? -1 : 1;
        if (aTeamTitle > bTeamTitle) return sortDirection === SortType.ASC ? 1 : -1;
        return 0;
      });
    } else if (columnHeaderName === 'workspace') {
      sortedUsers.sort((a: any, b: any) => {
        const aWorkspace = a.userWorkspace ? a.userWorkspace : '';
        const bWorkspace = b.userWorkspace ? b.userWorkspace : '';
        if (aWorkspace < bWorkspace) return sortDirection === SortType.ASC ? -1 : 1;
        if (aWorkspace > bWorkspace) return sortDirection === SortType.ASC ? 1 : -1;
        return 0;
      });
    }
    this.setState({ sortedData: sortedUsers });
  };

  renderStatus(status: any) {
    if (status == ResearchStatus.OnGoing) {
      return (
        <span className='badge bg-info text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          On Going
        </span>
      );
    }
    if (status == ResearchStatus.Completed) {
      return (
        <span className='badge bg-success text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          Completed
        </span>
      );
    }
    if (status == ResearchStatus.Delayed) {
      return (
        <span className='badge bg-warning text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          Delayed
        </span>
      );
    }
    if (status == ResearchStatus.OnHold) {
      return (
        <span
          className='badge bg-default text-white py-px-6 rounded-pill fs-13 fw-normal px-3'
          style={{ background: '#7E808A' }}
        >
          On Hold
        </span>
      );
    }
  }
  renderPrioirty(status: any) {
    if (status == Priority.High) {
      return (
        <span className='badge bg-danger text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          High
        </span>
      );
    }
    if (status == Priority.Medium) {
      return (
        <span className='badge bg-warning text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          Medium
        </span>
      );
    }
    if (status == Priority.Low) {
      return (
        <span className='badge bg-success text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          Low
        </span>
      );
    }
  }

  downloadFile(props: any) {
    if (props.externalUrl) {
      const aElement = document.createElement('a');
      aElement.href = props.externalUrl;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(props.externalUrl);
    } else if (props?.name) {
      const aElement = document.createElement('a');
      aElement.href = props.name;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(props.name);
    }
  }
  bytesToMB(bytes: number) {
    return (bytes / (1024 * 1024)).toFixed(3);
  }
  render() {
    const notesList = [
      {
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        date: '02-12-2023',
        creatorImage: '',
        creatorName: 'zain',
        assignedPeopleImages: ['a', 'b', 'c', 'd'],
      },
      {
        description: 'this is first note',
        date: '02-12-2023',
        assignedPeopleImages: ['a', 'b'],
      },
    ];

    const _files = this.state.Research?.medias?.filter((item) => item.externalUrl === null);
    const _links = this.state.Research?.medias?.filter((item) => item.externalUrl);
    const _userId = localStorage.getItem('userId');

    return (
      <>
        <div className='profileCard'>
          <div className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap position-relative'>
            <div className='d-xl-flex align-items-center w-100 w-lg-50'>
              <BaseButton
                className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
                style={{ fontWeight: '400', height: '19px' }}
                onClick={() => {
                  window.history.back();
                }}
              >
                Back
              </BaseButton>

              <h2 className='fw-medium fs-15 mb-0 color-gray-1000 mt-xl-0 mt-3 mw-100 text-truncate'>
                {this.state.loading ? (
                  <Skeleton width='6em' height='1.25em' />
                ) : (
                  <span>
                    {this.state.Research.title}
                    {/* {this.state.Research.title.split(' ').slice(0, 6).join(' ')}
                    {this.state.Research.title.split(' ').length > 4 ? '...' : ''} */}
                  </span>
                )}
              </h2>
            </div>
            {this.state.loading ? (
              <Skeleton width='6em' height='1.25em' />
            ) : (
              <div
                className='col-xl-6 relative TopTableBox d-flex flex-md-row justify-content-end align-items-md-start'
                style={{ gap: '10px' }}
              >
                <div className='btn-group'>
                  <MainButton
                    svg={
                      <svg
                        className='hover-fill'
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        viewBox='0 0 15 15'
                        fill='none'
                      >
                        <rect width='16' height='16' fill='white' fillOpacity='0.01' />
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M12.5 3L2.5 3.00002C1.67157 3.00002 1 3.6716 1 4.50002V9.50003C1 10.3285 1.67157 11 2.5 11H7.50003C7.63264 11 7.75982 11.0527 7.85358 11.1465L10 13.2929V11.5C10 11.2239 10.2239 11 10.5 11H12.5C13.3284 11 14 10.3285 14 9.50003V4.5C14 3.67157 13.3284 3 12.5 3ZM2.49999 2.00002L12.5 2C13.8807 2 15 3.11929 15 4.5V9.50003C15 10.8807 13.8807 12 12.5 12H11V14.5C11 14.7022 10.8782 14.8845 10.6913 14.9619C10.5045 15.0393 10.2894 14.9965 10.1464 14.8536L7.29292 12H2.5C1.11929 12 0 10.8807 0 9.50003V4.50002C0 3.11931 1.11928 2.00003 2.49999 2.00002Z'
                          fill='#5746AF'
                        />
                      </svg>
                    }
                    children='Chat'
                    type={MainButtonType.dark}
                    borderRadius='24px'
                    fontSize='14px'
                    minHeight='2em'
                    className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 main-button'
                    onClick={() => {
                      store.dispatch(
                        SetResearchId({
                          label: this.state.Research.title ?? '',
                          value: this.state.Research.id,
                        })
                      );
                      this.props.history.push(
                        $ReplaceRoute(
                          `${AppRoutes.discussion}?topicId=${DiscussionTopic.Project}&sectionId=${this.state.Research.id}&title=${this.state.Research.title}`
                        )
                      );
                    }}
                  ></MainButton>
                  <button
                    type='button'
                    className='btn btn-soft-primary btn-sm fs-13 px-2 py-0 dropdown-toggle dropdown-toggle-split'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <span className='visually-hidden'>Toggle Dropdown</span>
                  </button>
                  <ul className='dropdown-menu'>
                    <li>
                      <a
                        className='dropdown-item fs-14'
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                          store.dispatch(
                            SetResearchId({
                              label: this.state.Research.title ?? '',
                              value: this.state.Research.id,
                            })
                          );
                          this.props.history.push(
                            $ReplaceRoute(
                              `${AppRoutes.discussion}?topicId=${DiscussionTopic.Project}&sectionId=${this.state.Research.id}&title=${this.state.Research.title}`
                            )
                          );
                        }}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='21'
                          viewBox='0 0 20 21'
                          fill='none'
                        >
                          <rect
                            width='20'
                            height='20'
                            transform='translate(0 0.5)'
                            fill='white'
                            fill-opacity='0.01'
                          />
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M16.6667 4.49996L3.33333 4.49999C2.22876 4.49999 1.33333 5.39543 1.33333 6.49999V13.1667C1.33333 14.2713 2.22876 15.1666 3.33333 15.1666H10C10.1769 15.1666 10.3464 15.2369 10.4714 15.362L13.3333 18.2238V15.8333C13.3333 15.4652 13.6319 15.1666 14 15.1666H16.6667C17.7712 15.1666 18.6667 14.2713 18.6667 13.1667V6.49996C18.6667 5.39539 17.7712 4.49996 16.6667 4.49996ZM3.33332 3.16665L16.6667 3.16663C18.5076 3.16663 20 4.65901 20 6.49996V13.1667C20 15.0076 18.5076 16.5 16.6667 16.5H14.6667V19.8333C14.6667 20.1029 14.5043 20.346 14.2551 20.4492C14.006 20.5524 13.7192 20.4953 13.5285 20.3048L9.72389 16.5H3.33333C1.49239 16.5 0 15.0076 0 13.1667V6.49999C0 4.65904 1.49237 3.16667 3.33332 3.16665Z'
                            fill='#1C2024'
                          />
                        </svg>
                        Chat
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ pointerEvents: 'none', opacity: '0.55' }}
                        className='dropdown-item fs-14'
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push($ReplaceRoute(`${AppRoutes.discussion}`));
                        }}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='21'
                          viewBox='0 0 20 21'
                          fill='none'
                        >
                          <rect
                            width='20'
                            height='20'
                            transform='translate(0 0.5)'
                            fill='white'
                            fill-opacity='0.01'
                          />
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M1.33333 3.16663C0.596953 3.16663 0 3.76359 0 4.49996V16.5C0 17.2364 0.596953 17.8333 1.33333 17.8333H18.6667C19.4031 17.8333 20 17.2364 20 16.5V4.49996C20 3.76359 19.4031 3.16663 18.6667 3.16663H1.33333ZM1.33333 4.49996H18.6667V5.73321C18.5565 5.73311 18.4451 5.76331 18.3452 5.82669L10 11.1227L1.65483 5.82669C1.55495 5.76331 1.44347 5.73311 1.33333 5.73321V4.49996ZM1.33333 7.04392V16.5H18.6667V7.04392L10.3215 12.3399C10.1253 12.4644 9.87475 12.4644 9.67851 12.3399L1.33333 7.04392Z'
                            fill='#1C2024'
                          />
                        </svg>
                        Email
                      </a>
                    </li>
                    <li>
                      <a
                        className='dropdown-item fs-14'
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push($ReplaceRoute(`${AppRoutes.discussion}`));
                        }}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='21'
                          viewBox='0 0 20 21'
                          fill='none'
                        >
                          <rect
                            width='20'
                            height='20'
                            transform='translate(0 0.5)'
                            fill='white'
                            fill-opacity='0.01'
                          />
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M6.66675 6.68133C6.66675 5.51373 6.98967 4.64051 7.52439 4.06474C8.05254 3.49605 8.86118 3.13771 10.0019 3.13771C11.1433 3.13771 11.9508 3.49534 12.4777 4.06275C13.0113 4.6373 13.3334 5.50942 13.3334 6.67765V8.49997H6.66675V6.68133ZM5.33342 8.49997V6.68133C5.33342 5.27529 5.7246 4.04337 6.5474 3.15741C7.37678 2.26437 8.56908 1.80438 10.0019 1.80438C11.4345 1.80438 12.6261 2.26309 13.4547 3.15547C14.2769 4.04074 14.6667 5.27193 14.6667 6.67765V8.49997H16.0001C16.7365 8.49997 17.3334 9.09693 17.3334 9.8333V17.8333C17.3334 18.5697 16.7365 19.1666 16.0001 19.1666H4.00008C3.26371 19.1666 2.66675 18.5697 2.66675 17.8333V9.8333C2.66675 9.09693 3.26371 8.49997 4.00008 8.49997H5.33342ZM4.00008 9.8333H16.0001V17.8333H4.00008V9.8333Z'
                            fill='#1C2024'
                          />
                        </svg>
                        Slack
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='btn-group '>
                  <MainButton
                    svg={
                      <svg
                        className='hover-fill'
                        xmlns='http://www.w3.org/2000/svg'
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                      >
                        <rect width='15' height='15' fill='white' fillOpacity='0.01' />
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M3 2.5C3 2.22386 3.22386 2 3.5 2H9.08579C9.21839 2 9.34557 2.05268 9.43934 2.14645L11.8536 4.56066C11.9473 4.65443 12 4.78161 12 4.91421V12.5C12 12.7761 11.7761 13 11.5 13H3.5C3.22386 13 3 12.7761 3 12.5V2.5ZM3.5 1C2.67157 1 2 1.67157 2 2.5V12.5C2 13.3284 2.67157 14 3.5 14H11.5C12.3284 14 13 13.3284 13 12.5V4.91421C13 4.51639 12.842 4.13486 12.5607 3.85355L10.1464 1.43934C9.86514 1.15804 9.48361 1 9.08579 1H3.5ZM4.5 4C4.22386 4 4 4.22386 4 4.5C4 4.77614 4.22386 5 4.5 5H7.5C7.77614 5 8 4.77614 8 4.5C8 4.22386 7.77614 4 7.5 4H4.5ZM4.5 7C4.22386 7 4 7.22386 4 7.5C4 7.77614 4.22386 8 4.5 8H10.5C10.7761 8 11 7.77614 11 7.5C11 7.22386 10.7761 7 10.5 7H4.5ZM4.5 10C4.22386 10 4 10.2239 4 10.5C4 10.7761 4.22386 11 4.5 11H10.5C10.7761 11 11 10.7761 11 10.5C11 10.2239 10.7761 10 10.5 10H4.5Z'
                          fill='#5746AF'
                        />
                      </svg>
                    }
                    children='Task List'
                    type={MainButtonType.dark}
                    borderRadius='24px'
                    fontSize='14px'
                    minHeight='2em'
                    className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 main-button'
                    onClick={() => {
                      window.dispatchEvent(
                        new CustomEvent('HANDLE_RESEARCH_CHANGED', {
                          detail: {
                            name: this.state.Research.title,
                            id: this.state.Research.id,
                          },
                        })
                      );
                      store.dispatch(
                        SetResearchId({
                          label: this.state.Research.title ?? '',
                          value: this.state.Research.id,
                        })
                      );
                      setTimeout(() => {
                        this.props.history.push($ReplaceRoute(`${AppRoutes.task}`));
                      }, 100);
                    }}
                  ></MainButton>
                  <button
                    type='button'
                    className='btn btn-soft-primary btn-sm fs-13 px-2 py-0 dropdown-toggle dropdown-toggle-split'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <span className='visually-hidden'>Toggle Dropdown</span>
                  </button>
                  <ul className='dropdown-menu'>
                    <li>
                      <a
                        className='dropdown-item py-2 fs-14 d-flex align-items-center gap-2'
                        href='#'
                        onClick={() => {
                          window.dispatchEvent(
                            new CustomEvent('HANDLE_RESEARCH_CHANGED', {
                              detail: {
                                name: this.state.Research.title,
                                id: this.state.Research.id,
                              },
                            })
                          );
                          store.dispatch(
                            SetResearchId({
                              label: this.state.Research.title ?? '',
                              value: this.state.Research.id,
                            })
                          );
                          setTimeout(() => {
                            this.props.history.push($ReplaceRoute(`${AppRoutes.task}`));
                          }, 100);
                        }}
                      >
                        <img
                          className='w-px-16'
                          src='/Images/icons/file-text.svg'
                          alt='Task List'
                        />
                        Task List
                      </a>
                    </li>
                    <li>
                      <a
                        className='dropdown-item py-2 fs-14 d-flex align-items-center gap-2'
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push(
                            $ReplaceRoute(
                              `${AppRoutes.edit_research.replace(
                                ':id',
                                this.state.Research.id.toString() ?? ''
                              )}`
                            )
                          );
                        }}
                      >
                        <img
                          className='w-px-16'
                          src='/Images/icons/pencil-edit-2.svg'
                          alt='Edit Project'
                        />
                        Edit my project
                      </a>
                    </li>
                    <li>
                      <a
                        className='dropdown-item py-2 fs-14 d-flex align-items-center gap-2'
                        href='#'
                        onClick={() => {
                          this.setState({
                            loading: true,
                          });

                          this.controller.duplicateResearch(
                            {
                              id: this.state.Research.id,
                            },
                            (res) => {
                              if (res > 0) {
                                this.setState({
                                  loading: false,
                                });
                                this.props.history.push(
                                  $ReplaceRoute(
                                    `${AppRoutes.edit_research.replace(
                                      ':id',
                                      res.toString() ?? ''
                                    )}`
                                  )
                                );
                              }
                            },
                            (err) => {
                              this.setState({
                                loading: false,
                              });
                            }
                          );
                        }}
                      >
                        <img className='w-px-16' src='/Images/icons/copy.svg' alt='Task List' />
                        Duplicate Project
                      </a>
                    </li>
                    <li>
                      <a
                        className='dropdown-item py-2 fs-14 d-flex align-items-center gap-2'
                        href='#'
                        onClick={() => {
                          this.setState({
                            showModal: true,
                          });
                        }}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='21'
                          viewBox='0 0 20 21'
                          fill='none'
                        >
                          <rect
                            width='20'
                            height='20'
                            transform='translate(0 0.5)'
                            fill='white'
                            fill-opacity='0.01'
                          />
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M7.33333 1.83331C6.96515 1.83331 6.66667 2.13179 6.66667 2.49998C6.66667 2.86817 6.96515 3.16665 7.33333 3.16665H12.6667C13.0349 3.16665 13.3333 2.86817 13.3333 2.49998C13.3333 2.13179 13.0349 1.83331 12.6667 1.83331H7.33333ZM4 5.16665C4 4.79846 4.29848 4.49998 4.66667 4.49998H6.66667H13.3333H15.3333C15.7015 4.49998 16 4.79846 16 5.16665C16 5.53483 15.7015 5.83331 15.3333 5.83331H14.6667V16.5C14.6667 17.2364 14.0697 17.8333 13.3333 17.8333H6.66667C5.93029 17.8333 5.33333 17.2364 5.33333 16.5V5.83331H4.66667C4.29848 5.83331 4 5.53483 4 5.16665ZM6.66667 5.83331H13.3333V16.5H6.66667V5.83331Z'
                            fill='#1C2024'
                          />
                        </svg>
                        Delete Project
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
          <div className='p-3 pt-0 profileBorder'>
            <div className='d-flex flex-wrap align-items-center row-gap-sm-px-0 row-gap-px-16'>
              <div className='d-flex'>
                <h2
                  className='fw-normal fs-15 mb-0 color-gray-600 me-2'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Project Coordinator:
                </h2>
                <div className='pe-3 border-end-sm-1 border-gray-400 d-sm-block d-flex gap-3 min-h-px-25'>
                  <div className='d-flex align-items-center text-nowrap fs-15'>
                    {/* <div className="avatar w-px-25 h-px-25 rounded-circle bg-primary">
                  <label className="fw-medium text-white fs-10">AH</label>
                </div> */}
                    {this.state.loading ? (
                      <Skeleton width='6em' height='1.25em' />
                    ) : (
                      <UserWithAvatar user={this.state.Research.creatorUser} />
                    )}
                  </div>
                </div>
              </div>
              <div className='d-flex'>
                <div className='ps-sm-3 pe-3 border-end-1 border-gray-400 min-h-px-25'>
                  <div className='d-flex align-items-center'>
                    <label className='fw-normal fs-15 mb-0 color-gray-600 dateMargin textWrapLabel'>
                      Start:
                    </label>
                    {this.state.loading ? (
                      <Skeleton width='6em' height='1.25em' />
                    ) : (
                      <label className='fw-normal fs-15 mb-0 color-gray-1000 textWrapLabel'>
                        {moment(this.state.Research.startDate).format(MOMENT_DATE_FORMAT)}
                      </label>
                    )}
                  </div>
                </div>
                <div className='ps-3 pe-3 border-end-xl-1 border-gray-400 min-h-px-25'>
                  <div className='d-flex align-items-center'>
                    <label className='fw-normal fs-15 mb-0 color-gray-600 dateMargin textWrapLabel'>
                      Due Date:
                    </label>
                    {this.state.loading ? (
                      <Skeleton width='6em' height='1.25em' />
                    ) : (
                      <label className='fw-normal fs-15 mb-0 color-gray-1000 textWrapLabel'>
                        {moment(this.state.Research.endDate).format(MOMENT_DATE_FORMAT)}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className='d-flex'>
                <div className='ps-xl-3 pe-3 border-end-1 border-gray-400 min-h-px-25'>
                  <div className='d-flex align-items-center'>
                    <label className='fw-normal fs-15 mb-0 color-gray-600 me-2'>Status:</label>
                    {this.state.loading ? (
                      <Skeleton width='6em' height='1.25em' />
                    ) : (
                      <span> {this.renderStatus(this.state.Research.status)}</span>
                    )}
                  </div>
                </div>
                <div className='ps-3 pe-3 min-h-px-25'>
                  <div className='d-flex align-items-center'>
                    <label className='fw-normal fs-15 mb-0 color-gray-600 me-2'>Priority:</label>
                    {this.state.loading ? (
                      <Skeleton width='6em' height='1.25em' />
                    ) : (
                      <span>{this.renderPrioirty(this.state.Research.priority)}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row flex-nowrap scrollmenu'>
            <div
              className='dashboardProjectDetail dashboard-section overviwe align-items-center'
              style={{ borderBottom: 'none' }}
            >
              <div
                className={
                  this.state.selectedTab === 0
                    ? 'overviwe-item'
                    : 'overviwe-item button-dashboard-category'
                }
                onClick={() =>
                  this.setState({
                    selectedTab: 0,
                  })
                }
              >
                <div className='dashboardProjectDetailItem'>
                  <div
                    className='d-flex flex-column align-items-center dashboardProjectDetailItem'
                    id='pills-notes-tab'
                  >
                    <div
                      className={
                        this.state.selectedTab === 0
                          ? 'dashboardSelectedCategory'
                          : 'dashboardCategory'
                      }
                    >
                      <svg
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect width='15' height='15' fill='white' fill-opacity='0.01' />
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M12.1464 1.14645C12.3417 0.951183 12.6583 0.951183 12.8535 1.14645L14.8535 3.14645C15.0488 3.34171 15.0488 3.65829 14.8535 3.85355L10.9109 7.79618C10.8349 7.87218 10.7471 7.93543 10.651 7.9835L6.72359 9.94721C6.53109 10.0435 6.29861 10.0057 6.14643 9.85355C5.99425 9.70137 5.95652 9.46889 6.05277 9.27639L8.01648 5.34897C8.06455 5.25283 8.1278 5.16507 8.2038 5.08907L12.1464 1.14645ZM12.5 2.20711L8.91091 5.79618L7.87266 7.87267L8.12731 8.12732L10.2038 7.08907L13.7929 3.5L12.5 2.20711ZM9.99998 2L8.99998 3H4.9C4.47171 3 4.18056 3.00039 3.95552 3.01877C3.73631 3.03668 3.62421 3.06915 3.54601 3.10899C3.35785 3.20487 3.20487 3.35785 3.10899 3.54601C3.06915 3.62421 3.03669 3.73631 3.01878 3.95552C3.00039 4.18056 3 4.47171 3 4.9V11.1C3 11.5283 3.00039 11.8194 3.01878 12.0445C3.03669 12.2637 3.06915 12.3758 3.10899 12.454C3.20487 12.6422 3.35785 12.7951 3.54601 12.891C3.62421 12.9309 3.73631 12.9633 3.95552 12.9812C4.18056 12.9996 4.47171 13 4.9 13H11.1C11.5283 13 11.8194 12.9996 12.0445 12.9812C12.2637 12.9633 12.3758 12.9309 12.454 12.891C12.6422 12.7951 12.7951 12.6422 12.891 12.454C12.9309 12.3758 12.9633 12.2637 12.9812 12.0445C12.9996 11.8194 13 11.5283 13 11.1V6.99998L14 5.99998V11.1V11.1207C14 11.5231 14 11.8553 13.9779 12.1259C13.9549 12.407 13.9057 12.6653 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.6653 13.9057 12.407 13.9549 12.1259 13.9779C11.8553 14 11.5231 14 11.1207 14H11.1H4.9H4.87934C4.47686 14 4.14468 14 3.87409 13.9779C3.59304 13.9549 3.33469 13.9057 3.09202 13.782C2.7157 13.5903 2.40973 13.2843 2.21799 12.908C2.09434 12.6653 2.04506 12.407 2.0221 12.1259C1.99999 11.8553 1.99999 11.5231 2 11.1207V11.1V4.9V4.87935C1.99999 4.47689 1.99999 4.14468 2.0221 3.87409C2.04506 3.59304 2.09434 3.33469 2.21799 3.09202C2.40973 2.71569 2.7157 2.40973 3.09202 2.21799C3.33469 2.09434 3.59304 2.04506 3.87409 2.0221C4.14468 1.99999 4.47685 1.99999 4.87932 2H4.9H9.99998Z'
                          fill={this.state.selectedTab === 0 ? '#fff' : '#0D0D0D'}
                        />
                      </svg>

                      <span className='textWhite'>Tasks</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  this.state.selectedTab === 1
                    ? 'overviwe-item'
                    : 'overviwe-item button-dashboard-category'
                }
                onClick={() =>
                  this.setState({
                    selectedTab: 1,
                  })
                }
              >
                <div className='dashboardProjectDetailItem'>
                  <div
                    className='d-flex flex-column align-items-center dashboardProjectDetailItem'
                    id='pills-notes-tab'
                  >
                    <div
                      className={
                        this.state.selectedTab === 1
                          ? 'dashboardSelectedCategory'
                          : 'dashboardCategory'
                      }
                    >
                      <svg
                        width='15'
                        height='15'
                        viewBox='0 0 20 20'
                        fill='none'
                        color={this.state.selectedTab === 1 ? 'white' : 'black'}
                        xmlns='http://www.w3.org/2000/svg'
                        className={'dashboardCategoryIconAll'}
                      >
                        <rect width='15' height='15' fill='white' fillOpacity='0.01'></rect>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M12.567 4.58859C13.4085 4.3155 14.3786 4.32038 15.1726 4.72842C17.0046 5.66976 17.7973 8.09599 17.0598 10.6668C16.729
                    11.82 16.1473 12.9397 14.9151 14.07C13.683 15.2001 11.9741 15.8668 11.1813 15.8668C10.8972 15.8668 10.667 15.6429 10.667
                    15.3668C10.667 15.0906 10.9022 14.8668 11.1813 14.8668C12.087 14.8668 12.8962 14.374 13.8545 13.6481C14.5878 13.0925
                    15.1605 12.3819 15.4777 11.7627C16.0841 10.5783 16.1046 8.9091 15.1247 8.46546C14.6542 8.97951 14.0397 9.27939 13.3033
                    9.27939C11.7034 9.27939 10.7605 7.99227 10.801 6.8269C10.8398 5.7131 11.5474 4.9195 12.567 4.58859ZM4.56703
                    4.58859C5.40851 4.3155 6.37854 4.32038 7.17263 4.72842C9.00459 5.66976 9.79721 8.09599 9.05982 10.6668C8.72905 11.82
                    8.14725 12.9397 6.91513 14.07C5.68301 15.2001 3.97411 15.8668 3.18127 15.8668C2.89725 15.8668 2.66699 15.6429 2.66699
                    15.3668C2.66699 15.0906 2.90225 14.8668 3.18127 14.8668C4.08695 14.8668 4.89618 14.374 5.85443 13.6481C6.58786 13.0925
                    7.16051 12.3819 7.47762 11.7627C8.08409 10.5783 8.10461 8.9091 7.12473 8.46546C6.65415 8.97951 6.03963 9.27939 5.30333
                    9.27939C3.70339 9.27939 2.76046 7.99227 2.80101 6.8269C2.83975 5.7131 3.54742 4.9195 4.56703 4.58859Z'
                          fill='currentColor'
                        ></path>
                      </svg>
                      {/* <ViewGridIcon
                      color={
                        this.state.selectedTab === 0
                          ? "white"
                          : "black"
                      }
                      className={"dashboardCategoryIconAll"}
                    ></ViewGridIcon> */}
                      {/* <img
                      src={
                        this.state.notificationCategory === 0
                          ? "/images/icons/world.svg"
                          : "/images/icons/worldBlack.svg"
                      }
                      alt="Research"
                      className={"dashboardCategoryIconAll"}
                    /> */}
                      <span className='textWhite'>Notes</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  this.state.selectedTab === 2
                    ? 'overviwe-item'
                    : 'overviwe-item button-dashboard-category'
                }
                onClick={() =>
                  this.setState({
                    selectedTab: 2,
                  })
                }
              >
                <div className=' dashboardProjectDetailItem'>
                  <div
                    className='d-flex flex-column align-items-center dashboardProjectDetailItem'
                    id='pills-description-tab'
                  >
                    <div
                      className={
                        this.state.selectedTab === 2
                          ? 'dashboardSelectedCategory'
                          : 'dashboardCategory'
                      }
                    >
                      <svg
                        width='15'
                        height='15'
                        viewBox='0 0 20 20'
                        color={this.state.selectedTab === 2 ? 'white' : 'black'}
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className={'dashboardCategoryIconAll'}
                      >
                        <rect width='15' height='15' fill='white' fillOpacity='0.01'></rect>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M5.26641 3.93349V6.0001C5.26641 6.33148 4.99777 6.6001 4.66641 6.6001C4.33503 6.6001 4.06641 6.33148 4.06641
                    6.0001V3.33352C4.06641 3.27008 4.07625 3.20894 4.09449 3.15156C4.17154 2.90913 4.39846 2.73349 4.66641
                    2.73349H15.333C15.5402 2.73349 15.7228 2.83842 15.8306 2.99802C15.8953 3.09378 15.933 3.20922 15.933
                    3.33349V6.0001C15.933 6.33148 15.6645 6.6001 15.333 6.6001C15.0017 6.6001 14.733 6.33148 14.733
                    6.0001V3.93349H10.7331V16.0668H12.3389C12.6702 16.0668 12.9389 16.3355 12.9389 16.6668C12.9389 16.9983 12.6702 17.2668
                    12.3389 17.2668H7.67221C7.34083 17.2668 7.07221 16.9983 7.07221 16.6668C7.07221 16.3355 7.34083 16.0668 7.67221
                    16.0668H9.26641V3.93349H5.26641Z'
                          fill='currentColor'
                        ></path>
                      </svg>
                      {/* <TransformIcon
                      color={
                        this.state.selectedTab === 1
                          ? "white"
                          : "black"
                      }
                      className={"dashboardCategoryIconAll"}
                    ></TransformIcon> */}

                      <span className='textWhite'>Description</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  this.state.selectedTab === 3
                    ? 'overviwe-item'
                    : 'overviwe-item button-dashboard-category'
                }
                onClick={() =>
                  this.setState({
                    selectedTab: 3,
                  })
                }
              >
                <div className='d-flex flex-column align-items-center dashboardProjectDetailItem'>
                  <div
                    className={
                      this.state.selectedTab === 3
                        ? 'dashboardSelectedCategory'
                        : 'dashboardCategory'
                    }
                  >
                    <svg
                      width='15'
                      height='15'
                      viewBox='0 0 15 15'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      color={this.state.selectedTab === 3 ? 'white' : 'black'}
                      className={'dashboardCategoryIconAll'}
                    >
                      <rect width='15' height='15' fill='white' fillOpacity='0.01'></rect>
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M0.877014 7.49979C0.877014 3.8421 3.84216 0.876953 7.49985 0.876953C11.1575 0.876953 14.1227 3.8421 14.1227
                    7.49979C14.1227 11.1574 11.1575 14.1226 7.49985 14.1226C3.84216 14.1226 0.877014 11.1574 0.877014 7.49979ZM7.49985
                    1.82695C4.36683 1.82695 1.82701 4.36677 1.82701 7.49979C1.82701 8.97187 2.38774 10.313 3.30727 11.3212C4.19074 9.9411
                    5.73818 9.0249 7.50023 9.0249C9.26206 9.0249 10.8093 9.94088 11.6929 11.3207C12.6121 10.3126 13.1727 8.97163 13.1727
                    7.49979C13.1727 4.36677 10.6328 1.82695 7.49985 1.82695ZM10.9818 11.9786C10.2839 10.7794 8.9857 9.9749 7.50023
                    9.9749C6.01458 9.9749 4.71624 10.7796 4.01845 11.979C4.97952 12.7271 6.18765 13.1726 7.49985 13.1726C8.81227 13.1726
                    10.0206 12.7269 10.9818 11.9786ZM5.14999 6.50478C5.14999 5.20691 6.20212 4.15478 7.49999 4.15478C8.79786 4.15478 9.84999
                    5.20691 9.84999 6.50478C9.84999 7.80265 8.79786 8.85478 7.49999 8.85478C6.20212 8.85478 5.14999 7.80265 5.14999
                    6.50478ZM7.49999 5.10478C6.72679 5.10478 6.09999 5.73158 6.09999 6.50478C6.09999 7.27798 6.72679 7.90478 7.49999
                    7.90478C8.27319 7.90478 8.89999 7.27798 8.89999 6.50478C8.89999 5.73158 8.27319 5.10478 7.49999 5.10478Z'
                        fill='currentColor'
                      ></path>
                    </svg>
                    {/* <AvatarIcon
                      color={
                        this.state.selectedTab === 2
                          ? "white"
                          : "black"
                      }
                      className={"dashboardCategoryIconAll"}
                    ></AvatarIcon> */}

                    <span className='textWhite'>User &amp; Team</span>
                  </div>
                </div>
              </div>

              <div
                className={
                  this.state.selectedTab === 4
                    ? 'overviwe-item'
                    : 'overviwe-item button-dashboard-category'
                }
                onClick={() =>
                  this.setState({
                    selectedTab: 4,
                  })
                }
              >
                <div className='d-flex flex-column align-items-center dashboardProjectDetailItem'>
                  <div
                    className={
                      this.state.selectedTab === 4
                        ? 'dashboardSelectedCategory'
                        : 'dashboardCategory'
                    }
                  >
                    <svg
                      width='15'
                      height='15'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      color={this.state.selectedTab === 4 ? 'white' : 'black'}
                      className={'dashboardCategoryIconAll'}
                    >
                      <rect width='15' height='15' fill='white' fillOpacity='0.01'></rect>
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M4.66699 2.66677C4.29881 2.66677 4.00033 2.96525 4.00033 3.33344V16.6668C4.00033 17.0349 4.29881 17.3334 4.66699
                    17.3334H15.3337C15.7018 17.3334 16.0003 17.0349 16.0003 16.6668V8.0001H11.3337C10.9655 8.0001 10.667 7.70162 10.667
                    7.33344V2.66677H4.66699ZM12.0003 3.60958L15.0575 6.66677H12.0003V3.60958ZM2.66699 3.33344C2.66699 2.22886 3.56242 1.33344
                    4.66699 1.33344H11.3337C11.5105 1.33344 11.68 1.40368 11.8051 1.5287L17.1385 6.86204C17.2634 6.98705 17.3337 7.15662
                    17.3337 7.33344V16.6668C17.3337 17.7713 16.4382 18.6668 15.3337 18.6668H4.66699C3.56242 18.6668 2.66699 17.7713 2.66699
                    16.6668V3.33344Z'
                        fill='currentColor'
                      ></path>
                    </svg>

                    <span className='textWhite'>More Detail</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='tab-content' id='pills-tabContent'>
            <div
              aria-labelledby='task-tab'
              className={this.state.selectedTab === 0 ? 'd-block' : 'd-none'}
            >
              <div className='p-3 border-top border-bottom border-gray-400 d-flex justify-content-between align-items-center'>
                <h3 className='color-gray-1000 fw-medium fs-15 mb-0'>Task List</h3>

                <button
                  className='btn btn-primary fs-13 py-px-4 fw-normal px-2'
                  onClick={() => {
                    this.props.history.push($ReplaceRoute(`${AppRoutes.task_new}`));
                    store.dispatch(
                      SetResearchId({
                        label: this.state.Research.title,
                        value: this.state.Research.id,
                      })
                    );
                  }}
                >
                  <i className='fa fa-plus me-1'></i> Add new task
                </button>
              </div>
              <div
                className='d-flex flex-column row-gap-2 p-3 flex-xl-row align-items-xl-center row-gap-xl-0 filters'
                id='showFilter'
              >
                <div className='pe-xl-3 border-end-xl-1 border-gray-400 d-sm-block d-flex gap-3 flex-grow-1 filters-search-wrapper'>
                  <InputIcon
                    className='form-control h-px-35'
                    placeholder='Search task'
                    chilren={undefined}
                    onChange={(e) => {
                      this.setState({
                        Search: e.target.value,
                      });
                      this.handleChangeTaskSearch(e);
                    }}
                  />
                  <button
                    className='btn btn-secondary d-sm-none h-px-35 w-px-35 p-0'
                    id='filterToggler'
                    onClick={this.filterToggle}
                  >
                    <img src='/images/icons/mixer-horizontal.svg' />
                    <i className='fa fa-times d-none color-gray-1000'></i>
                  </button>
                </div>
                <div className='d-sm-flex flex-wrap row-gap-2 sub-filters ps-xl-3'>
                  <div className='w-100 w-sm-50 pe-sm-3 border-end-sm-1 border-gray-400 w-xl-auto multi-select-wrapper'>
                    <MultiSelect options={this.statusOptions} onChange={this.handleStatusChange} />
                  </div>
                  <div className='d-flex align-items-center w-100 w-sm-50 w-xl-auto ps-sm-3'>
                    <div className='w-50 w-xl-auto date-pickers-wrapper'>
                      <div className='date-picker-wrapper'>
                        <DatePicker
                          selected={this.state.filterOptions.selectedFromDate}
                          onChange={(e) => {
                            this.filterClick(
                              e,
                              this.state.filterOptions.selectedToDate,
                              this.state.filterOptions.status
                            );
                          }}
                          dateFormat='MM/dd/yyyy'
                          placeholderText='Start Date'
                          // maxDate={new Date()}
                          className='form-control h-px-35 customBorders'
                        />
                      </div>
                    </div>
                    <div className='px-2 flex-shrink-0 color-gray-400'>&ndash;</div>
                    <div className='w-50 w-xl-auto date-pickers-wrapper'>
                      <div className='date-picker-wrapper'>
                        <DatePicker
                          selected={this.state.filterOptions.selectedToDate}
                          onChange={(e) =>
                            this.filterClick(
                              this.state.filterOptions.selectedFromDate,
                              e,
                              this.state.filterOptions.status
                            )
                          }
                          dateFormat='MM/dd/yyyy'
                          placeholderText='End Date'
                          //maxDate={new Date()}
                          className='form-control h-px-35 customBorders'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <RadixTableTasks
                data={this.state.Tasks}
                loading={this.state.tasksLoading}
                onHeaderClick={(columnHeaderName: string, sortDirection: SortType) =>
                  this.tasksHeaderClick(columnHeaderName, sortDirection)
                }
                onExpandedRowsChange={this.handleExpandedRowsChange}
                columns={[
                  {
                    Header: 'Task name',
                    accessor: 'title',
                    Cell: (_props: any) => {
                      return (
                        <Table.Cell
                          className='p-3  parent-row-background'
                          style={{ width: '294px' }}
                        >
                          <div className='line-clamp-2'>
                            {_props.subAppTasks.length > 0 && (
                              <i
                                className={`fa ${
                                  this.state.ExpandedRowsState[_props.appTask.id]
                                    ? 'fa-chevron-down'
                                    : 'fa-chevron-right'
                                } me-2 color-gray-600 fs-13`}
                              />
                            )}
                            <span
                              style={{
                                paddingLeft: `${_props.subAppTasks.length > 0 ? '16px' : '36px'}`,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                this.props.history.push(
                                  $ReplaceRoute(
                                    `${AppRoutes.task_profile.replace(
                                      ':id',
                                      _props.appTask.id.toString() ?? ''
                                    )}?researchId=${_props.appTask.researchId}`
                                  )
                                );
                              }}
                              title={_props.appTask.title}
                            >
                              {' '}
                              {_props.appTask.title.split(' ').slice(0, 4).join(' ')}{' '}
                            </span>
                          </div>
                        </Table.Cell>
                      );
                    },
                  },
                  {
                    Header: 'Creator',
                    accessor: 'CreatorUser',
                    Cell: (props: any) => {
                      return (
                        <Table.Cell
                          className='p-3 parent-row-background'
                          style={{ width: '339px' }}
                        >
                          <div className='d-flex align-items-center text-nowrap'>
                            {props.appTask.creatorUser.image === null ||
                            props.appTask.creatorUser.image === '' ||
                            props.appTask.creatorUser.image === undefined ? (
                              <div className='avatar w-px-25 h-px-25 rounded-circle bg-primary'>
                                <label className='fw-medium text-white fs-10'>
                                  {getUserInitails(
                                    `${props.appTask.creatorUser.firstName} ${props.appTask.creatorUser.lastName}`
                                  )}
                                </label>
                              </div>
                            ) : (
                              <ImageWithToken
                                originalImage={props.appTask.creatorUser.image}
                                hasImage={props.appTask.creatorUser.image ? true : false}
                                alt='Avatar'
                                className='rounded-circle avatar rounded-avatar-dashboard'
                                src={
                                  props.appTask.creatorUser.image === null ||
                                  props.appTask.creatorUser.image === '' ||
                                  props.appTask.creatorUser.image === undefined
                                    ? '/images/images/img_avatar.png'
                                    : props.appTask.creatorUser.image
                                }
                              />
                            )}
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                this.props.history.push(
                                  $ReplaceRoute(
                                    `${AppRoutes.member_profile.replace(
                                      ':id',
                                      props.appTask.creatorUser.id?.toString() ?? ''
                                    )}`
                                  )
                                );
                              }}
                            >
                              {' '}
                              {props.appTask.creatorUser.firstName}{' '}
                              {props.appTask.creatorUser.lastName}
                            </span>
                          </div>
                        </Table.Cell>
                      );
                    },
                  },
                  {
                    Header: 'Users',
                    accessor: '',
                    Cell: (props: any) => {
                      return (
                        <Table.Cell
                          className='p-3 parent-row-background'
                          style={{ width: '200px' }}
                        >
                          <GroupedAvatars
                            containerWidth={100}
                            maxAvatars={2}
                            tippyContent={
                              <div className='d-flex flex-column py-2 pb-0'>
                                {props.appTask.users.map((_us: any) => (
                                  <UserWithAvatar
                                    parentStyle={{
                                      marginBottom: '0.25em',
                                    }}
                                    key={_us.id}
                                    user={_us}
                                  />
                                ))}
                              </div>
                            }
                            users={props.appTask.users}
                          />
                        </Table.Cell>
                      );
                    },
                  },
                  {
                    Header: 'Deadline',
                    accessor: 'endDate',
                    Cell: (props: any) => {
                      return (
                        <Table.Cell
                          className='p-3 text-nowrap parent-row-background'
                          style={{ width: '150px' }}
                        >
                          {moment(props.appTask.endDate).format(MOMENT_DATE_FORMAT)}
                        </Table.Cell>
                      );
                    },
                  },
                  {
                    Header: 'Status',
                    accessor: 'status',
                    Cell: (props: any) => {
                      return (
                        <Table.Cell
                          className='p-3 parent-row-background'
                          style={{ width: '150px' }}
                        >
                          {this.renderStatus(props.appTask.status)}
                        </Table.Cell>
                      );
                    },
                  },
                  {
                    Header: '',
                    Cell: (props: any) => {
                      return (
                        <Table.Cell className='p-3 text-nowrap parent-row-background'>
                          <div className='d-flex align-items-center gap-3'>
                            <div className='btn-group'>
                              <button
                                type='button'
                                className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.props.history.push(
                                    $ReplaceRoute(
                                      `${AppRoutes.task_profile.replace(
                                        ':id',
                                        props.appTask.id.toString() ?? ''
                                      )}`
                                    )
                                  );
                                }}
                              >
                                <i className='fa fa-eye me-px-6'></i>View Details
                              </button>

                              <DropdownMenu.Root>
                                <DropdownMenu.Trigger>
                                  <button
                                    type='button'
                                    className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split'
                                    data-bs-toggle='dropdown'
                                    aria-expanded='false'
                                  >
                                    <span className='visually-hidden'>Toggle Dropdown</span>
                                  </button>
                                </DropdownMenu.Trigger>
                                <DropdownMenu.Content>
                                  <DropdownMenu.Item className='px-0'>
                                    <button
                                      className='dropdown-item fs-14'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.push(
                                          $ReplaceRoute(
                                            `${AppRoutes.task_profile.replace(
                                              ':id',
                                              props.appTask.id.toString() ?? ''
                                            )}`
                                          )
                                        );
                                      }}
                                    >
                                      View Details
                                    </button>
                                  </DropdownMenu.Item>
                                  <DropdownMenu.Item className='px-0'>
                                    <button
                                      className='dropdown-item fs-14'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        store.dispatch(SetResearchId({ label: this.state.Research.title ?? '', value: this.state.Research.id }));
                                        this.props.history.push(
                                          $ReplaceRoute(
                                            `${AppRoutes.task_edit.replace(
                                              ':id',
                                              props.appTask.id.toString() ?? ''
                                            )}`
                                          )
                                        );
                                      }}
                                    >
                                      Edit Task
                                    </button>
                                  </DropdownMenu.Item>
                                  <DropdownMenu.Item className='px-0'>
                                    <button
                                      className='dropdown-item fs-14'
                                      onClick={() => {
                                        this.props.history.push(
                                          $ReplaceRoute(
                                            `${AppRoutes.data_new}?taskId=${props.appTask.id}`
                                          )
                                        );
                                      }}
                                    >
                                      Add Dataset
                                    </button>
                                  </DropdownMenu.Item>
                                </DropdownMenu.Content>
                              </DropdownMenu.Root>
                            </div>
                          </div>
                        </Table.Cell>
                      );
                    },
                  },
                ]}
              />
              {this.state.PageCount > 0 && (
                <Pagination
                  pageCount={this.state.PageCount}
                  onPageChange={this.handelChangePageNumber.bind(this)}
                  onPageSizeChange={(e: any) => {
                    this.handelChangePageSize(e);
                  }}
                  pageInput={this.state.PageNumberInput}
                  handlePageNumberInput={this.handlePageNumberInput}
                />
              )}
            </div>

            {this.state.selectedTab === 1 && (
              <div
                className='show active'
                id='pills-notes'
                role='tabpanel'
                aria-labelledby='pills-notes-tab'
              >
                <div>
                  <div className='overflow-auto vh-calc-xl-320 vh-calc-sm-415 px-3'>
                    <ShowNotes
                      notes={this.state.Research.notes}
                      listUsers={this.state.Research.users}
                      currentUser={this.state.Research.creatorUser}
                      NotesProfileType={1}
                      NotesProfileId={this.state.Research.id}
                      handleNoteSave={() => {}}
                      loading={this.state.loading}
                    ></ShowNotes>
                    {/* Repeat the note structure for other notes */}
                  </div>
                </div>
              </div>
            )}
            {this.state.selectedTab === 2 && (
              <div
                // className="tab-pane fade"
                id='pills-description'
                role='tabpanel'
                aria-labelledby='pills-description-tab'
              >
                <div>
                  <div className='overflow-auto vh-calc-xl-320 vh-calc-sm-415 px-3'>
                    <div className='py-3 border-top border-bottom border-gray-400 d-flex justify-content-between align-items-center'>
                      <h3 className='color-gray-1000 fw-medium fs-15 mb-0'>Description</h3>
                      <a
                        className='btn btn-primary fs-13 py-px-4 fw-normal px-2'
                        href='create-new-proj.html'
                        style={{ visibility: 'hidden' }}
                      >
                        <i className='fa fa-plus me-1'></i>
                      </a>
                    </div>
                    <div className='p-3 bg-gray-200 rounded-px-6 my-3'>
                      <p className='color-gray-600 fw-medium fs-15'>
                        {this.state.Research.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.selectedTab === 3 && (
              <div
                // className="tab-pane fade"
                id='pills-team'
                role='tabpanel'
                aria-labelledby='pills-team-tab'
              >
                <div>
                  <div className='overflow-auto vh-calc-xl-320 vh-calc-sm-415 px-3'>
                    <div className='py-3 border-top border-bottom border-gray-400 d-flex justify-content-between align-items-center'>
                      <h3 className='color-gray-1000 fw-medium fs-15 mb-0'>User &amp; Team</h3>
                      <a
                        className='btn btn-primary fs-13 py-px-4 fw-normal px-2'
                        onClick={() => {
                          this.props.history.push(
                            $ReplaceRoute(
                              `${AppRoutes.edit_research.replace(
                                ':id',
                                this.state.Research.id.toString() ?? ''
                              )}`
                            )
                          );
                        }}
                      >
                        <i className='fa fa-plus me-1'></i> Assign Users / Teams
                      </a>
                    </div>
                    <RadixTable
                      data={
                        this.state.sortedData.length > 0
                          ? this.state.sortedData
                          : this.state.Research.users
                      }
                      customHeaderWidth={false}
                      loading={this.state.loading}
                      onHeaderClick={(columnHeaderName: string, sortDirection: SortType) =>
                        this.headerClick(columnHeaderName, sortDirection)
                      }
                      columns={[
                        {
                          Header: 'User name',
                          accessor: 'username',
                          maxWidth: '256px',
                          width: '256px',
                          Cell: (props: any) => {
                            return (
                              <Table.Cell className='p-3'>
                                <div className='d-flex align-items-center text-nowrap'>
                                  {
                                    <ImageWithToken
                                      originalImage={props.image}
                                      hasImage={props.image ? true : false}
                                      alt='Avatar'
                                      className='rounded-circle avatar rounded-avatar-dashboard'
                                      src={
                                        props.image === null ||
                                        props.image === '' ||
                                        props.image === undefined
                                          ? '/images/images/img_avatar.png'
                                          : props.image
                                      }
                                      textInAvatar={
                                        !isOnlyWhitespace(props.firstName) ||
                                        !isOnlyWhitespace(props.lastName)
                                          ? `${props.firstName} ${props.lastName}`
                                          : `${props.email.substring(0, 4).toUpperCase()}`
                                      }
                                    />
                                  }
                                  <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      this.props.history.push(
                                        $ReplaceRoute(
                                          `${AppRoutes.member_profile.replace(
                                            ':id',
                                            props.id?.toString() ?? ''
                                          )}`
                                        )
                                      );
                                    }}
                                    title={props.firstName}
                                    {...props.lastName}
                                  >
                                    {' '}
                                    {!isOnlyWhitespace(props.firstName) ||
                                    !isOnlyWhitespace(props.lastName)
                                      ? `${props.firstName} ${props.lastName}`
                                      : `${props.email}`}
                                  </span>
                                </div>
                              </Table.Cell>
                            );
                          },
                        },
                        {
                          Header: 'Team',
                          accessor: 'userTeam',
                          maxWidth: '198px',
                          width: '198px',
                          Cell: (props: any) => {
                            return (
                              <Table.Cell className='p-3'>
                                {props.userTeam && props.userTeam.length > 0 ? (
                                  <span
                                    className='badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      this.props.history.push(
                                        $ReplaceRoute(
                                          `${AppRoutes.team_profile.replace(
                                            ':id',
                                            props.userTeam[0].id?.toString() ?? ''
                                          )}`
                                        )
                                      );
                                    }}
                                  >
                                    {props.userTeam[0].title}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Table.Cell>
                            );
                          },
                        },
                        {
                          Header: 'Workspace',
                          accessor: 'workspace',
                          maWidth: '199px',
                          width: '199px',
                          Cell: (props: any) => {
                            return (
                              <Table.Cell className='p-3 text-nowrap'>
                                {props.userWorkspace}
                              </Table.Cell>
                            );
                          },
                        },
                        {
                          Header: '',
                          minWidth: '194px',
                          Cell: (props: any) => {
                            return (
                              <Table.Cell className='p-3 text-nowrap'>
                                <div className='d-flex align-items-center gap-3'>
                                  <div className='btn-group'>
                                    <button
                                      type='button'
                                      className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.push(
                                          $ReplaceRoute(
                                            `${AppRoutes.member_profile.replace(
                                              ':id',
                                              props.id.toString() ?? ''
                                            )}`
                                          )
                                        );
                                      }}
                                    >
                                      <i className='fa fa-eye me-px-6'></i>View Details
                                    </button>

                                    <DropdownMenu.Root>
                                      <DropdownMenu.Trigger>
                                        <button
                                          type='button'
                                          className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split'
                                          data-bs-toggle='dropdown'
                                          aria-expanded='false'
                                        >
                                          <span className='visually-hidden'>Toggle Dropdown</span>
                                        </button>
                                      </DropdownMenu.Trigger>
                                      <DropdownMenu.Content>
                                        <DropdownMenu.Item className='px-0'>
                                          <button
                                            className='dropdown-item fs-14'
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.props.history.push(
                                                $ReplaceRoute(
                                                  `${AppRoutes.member_profile.replace(
                                                    ':id',
                                                    props.id.toString() ?? ''
                                                  )}`
                                                )
                                              );
                                            }}
                                          >
                                            View Details
                                          </button>
                                        </DropdownMenu.Item>
                                        <DropdownMenu.Item className='px-0'>
                                          <button
                                            className='dropdown-item fs-14'
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.props.history.push(
                                                $ReplaceRoute(
                                                  `${AppRoutes.member_user_edit.replace(
                                                    ':id',
                                                    props.id.toString() ?? ''
                                                  )}`
                                                )
                                              );
                                            }}
                                          >
                                            Edit User Accesss
                                          </button>
                                        </DropdownMenu.Item>
                                      </DropdownMenu.Content>
                                    </DropdownMenu.Root>
                                  </div>
                                </div>
                              </Table.Cell>
                            );
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            )}
            {this.state.selectedTab === 4 && (
              <div
                // className="tab-pane fade"
                id='pills-attachment'
                role='tabpanel'
                aria-labelledby='pills-attachment-tab'
              >
                <div>
                  <div className='overflow-auto vh-calc-xl-320 vh-calc-sm-415 px-3'>
                    <div className='py-3 border-top border-bottom border-gray-400 d-flex justify-content-between align-items-center'>
                      <h3 className='color-gray-1000 fw-medium fs-15 mb-0'>Attachment</h3>
                      <a
                        className='btn btn-primary fs-13 py-px-4 fw-normal px-2'
                        href='create-new-proj.html'
                        style={{ visibility: 'hidden' }}
                      >
                        <i className='fa fa-plus me-1'></i>
                      </a>
                    </div>
                    <RadixTable
                      data={this.state.Research.medias}
                      loading={this.state.loading}
                      noDataFound={'No attachment yet'}
                      columns={[
                        {
                          Header: 'File name',
                          maWidth: '256px',
                          width: '256px',
                          Cell: (_props: any) => {
                            return (
                              <Table.Cell className='p-3 text-nowrap'>
                                {_props.inputDataType == 4 ? _props.externalUrl : _props.title}
                              </Table.Cell>
                            );
                          },
                        },
                        {
                          Header: 'Size',
                          maWidth: '198px',
                          width: '198px',
                          Cell: (props: any) => {
                            return (
                              <Table.Cell
                                className='p-3 text-nowrap'
                                // onClick={() => this.downloadFile(props)}
                                // style={{ cursor: "pointer" }}
                              >
                                {props.inputDataType == 4 ? '-' : props.fileSize}{' '}
                                {props.inputDataType !== 4 ? 'MB' : ''}
                              </Table.Cell>
                            );
                          },
                        },
                        {
                          Header: '',
                          Cell: (props: any) => {
                            return (
                              <Table.Cell className='p-3 text-nowrap'>
                                <div className='d-flex align-items-center gap-3'>
                                  <div className='btn-group'>
                                    <button
                                      type='button'
                                      className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                                      onClick={() => this.downloadFile(props)}
                                    >
                                      {props.inputDataType == 4 ? (
                                        <>
                                          <svg
                                            className='me-px-6'
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='15'
                                            height='16'
                                            viewBox='0 0 15 16'
                                            fill='none'
                                          >
                                            <rect
                                              width='15'
                                              height='15'
                                              transform='translate(0 0.500534)'
                                              fill='white'
                                              fill-opacity='0.01'
                                            />
                                            <path
                                              fill-rule='evenodd'
                                              clip-rule='evenodd'
                                              d='M7.50039 2.30055C4.35237 2.30055 1.80039 4.85253 1.80039 8.00055C1.80039 11.1485 4.35237 13.7005 7.50039 13.7005C10.6484 13.7005 13.2004 11.1485 13.2004 8.00055C13.2004 4.85253 10.6484 2.30055 7.50039 2.30055ZM0.900391 8.00055C0.900391 4.35547 3.85531 1.40056 7.50039 1.40056C11.1454 1.40056 14.1004 4.35547 14.1004 8.00055C14.1004 11.6456 11.1454 14.6005 7.50039 14.6005C3.85531 14.6005 0.900391 11.6456 0.900391 8.00055Z'
                                              fill='#5746AF'
                                            />
                                            <path
                                              fill-rule='evenodd'
                                              clip-rule='evenodd'
                                              d='M13.5 8.40051H1.5V7.60051H13.5V8.40051Z'
                                              fill='#5746AF'
                                            />
                                            <path
                                              fill-rule='evenodd'
                                              clip-rule='evenodd'
                                              d='M7.10022 14.0005V2.00053H7.90022V14.0005H7.10022ZM10.3753 8.00051C10.3753 5.82777 9.59395 3.67831 8.06214 2.25709L8.53824 1.74394C10.2399 3.32271 11.0753 5.67326 11.0753 8.00051C11.0753 10.3278 10.2399 12.6783 8.53824 14.2571L8.06214 13.7439C9.59395 12.3227 10.3753 10.1733 10.3753 8.00051ZM4 8.00053C4 5.67664 4.80817 3.32731 6.45799 1.74772L6.94208 2.25334C5.45851 3.67376 4.7 5.82442 4.7 8.00053C4.70001 10.1766 5.45853 12.3273 6.9421 13.7477L6.458 14.2533C4.80819 12.6737 4.00001 10.3244 4 8.00053Z'
                                              fill='#5746AF'
                                            />
                                            <path
                                              fill-rule='evenodd'
                                              clip-rule='evenodd'
                                              d='M7.49929 4.45854C9.66861 4.45854 11.8746 4.85968 13.3699 5.69501C13.5387 5.78928 13.5991 6.0025 13.5048 6.17126C13.4106 6.34001 13.1973 6.4004 13.0286 6.30613C11.6787 5.55208 9.60732 5.15854 7.49929 5.15854C5.39125 5.15854 3.31985 5.55208 1.96997 6.30613C1.80121 6.4004 1.58799 6.34001 1.49372 6.17126C1.39946 6.0025 1.45984 5.78928 1.6286 5.69501C3.12399 4.85968 5.32996 4.45854 7.49929 4.45854ZM7.49929 11.3505C9.66861 11.3505 11.8746 10.9493 13.3699 10.114C13.5387 10.0198 13.5991 9.80654 13.5048 9.63779C13.4106 9.46903 13.1973 9.40865 13.0286 9.50291C11.6787 10.257 9.60732 10.6505 7.49929 10.6505C5.39125 10.6505 3.31985 10.257 1.96997 9.50292C1.80121 9.40865 1.58799 9.46903 1.49372 9.63779C1.39946 9.80654 1.45984 10.0198 1.6286 10.114C3.12399 10.9493 5.32996 11.3505 7.49929 11.3505Z'
                                              fill='#5746AF'
                                            />
                                          </svg>
                                          {`Open link`}
                                        </>
                                      ) : (
                                        <>
                                          <svg
                                            className='me-px-6'
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='15'
                                            height='16'
                                            viewBox='0 0 15 16'
                                            fill='none'
                                          >
                                            <rect
                                              width='15'
                                              height='15'
                                              transform='translate(0 0.500427)'
                                              fill='white'
                                              fill-opacity='0.01'
                                            />
                                            <path
                                              fill-rule='evenodd'
                                              clip-rule='evenodd'
                                              d='M7.5 11.5004C4.80285 11.5004 2.52952 10.1223 1.09622 8.00044C2.52952 5.87859 4.80285 4.50043 7.5 4.50043C10.1971 4.50043 12.4705 5.87859 13.9038 8.00044C12.4705 10.1223 10.1971 11.5004 7.5 11.5004ZM7.5 3.50043C4.30786 3.50043 1.65639 5.20681 0.0760002 7.73544C-0.0253338 7.89758 -0.0253334 8.10331 0.0760014 8.26544C1.65639 10.794 4.30786 12.5004 7.5 12.5004C10.6921 12.5004 13.3436 10.794 14.924 8.26544C15.0253 8.10331 15.0253 7.89758 14.924 7.73544C13.3436 5.20681 10.6921 3.50043 7.5 3.50043ZM7.5 10.0004C8.60457 10.0004 9.5 9.105 9.5 8.00043C9.5 6.89586 8.60457 6.00043 7.5 6.00043C6.39543 6.00043 5.5 6.89586 5.5 8.00043C5.5 9.105 6.39543 10.0004 7.5 10.0004Z'
                                              fill='#5746AF'
                                            />
                                          </svg>
                                          {`Open file`}
                                        </>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </Table.Cell>
                            );
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Dialog.Root open={this.state.showModal}>
          <Dialog.Portal>
            <Dialog.Overlay className='DialogOverlay' />
            <Dialog.Content className='DialogContent'>
              <div
                className='d-flex justify-content-between align-items-center  row-gap-3 flex-wrap column-gap-3 border-bottom
       border-gray-400'
                style={{ paddingBottom: '10px' }}
              >
                <div className='d-flex align-items-center'>
                  {/* <button className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none"> Back</button> */}
                  <BaseButton
                    className='btn btn-outline-primary fs-15 py-px-4 me-px-12 px-3 d-sm-block d-none back-button'
                    style={{ fontWeight: '400', height: '19px' }}
                    onClick={() => this.setState({ showModal: false })}
                  >
                    Close
                  </BaseButton>
                  <h2 className='fw-medium fs-15 mb-0 color-gray-1000 modalHeader'>
                    Delete Project
                  </h2>
                </div>
              </div>
              <div className='modal-body' style={{ paddingTop: '15px' }}>
                <div className='d-flex align-items-center gap-px-12'>
                  <div className='flex-grow-1'>
                    <p>Are you sure you want to delete this project?</p>
                  </div>
                </div>
                <div className='d-flex gap-3'>
                  <MainButton
                    className='btn btn-primary resrvationButton mt-2'
                    loading={this.state.loading}
                    onClick={() => {
                      this.setState({
                        loading: true,
                      });

                      this.controller.deleteResearch(
                        {
                          researchId: this.state.Research.id,
                        },
                        (res) => {
                          if (res > 0) {
                            this.setState({
                              loading: false,
                            });
                            this.props.history.push($ReplaceRoute(`${AppRoutes.research}`));
                          }
                        },
                        (err) => {
                          this.setState({
                            loading: false,
                          });
                        }
                      );
                    }}
                  >
                    Yes, Delete
                  </MainButton>
                  <MainButton
                    className='btn btn-primary resrvationButton mt-2'
                    loading={this.state.loading}
                    onClick={() => this.setState({ showModal: false })}
                  >
                    Reject
                  </MainButton>
                </div>
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </>
    );
  }
}
export default withRouter(ResearchPageProfile);
