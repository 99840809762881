/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { BsFillChatLeftDotsFill } from "react-icons/bs";
import { GiTalk } from "react-icons/gi";
import { MdOutlineAddchart, MdOutlineAddTask } from "react-icons/md";
import { RouteComponentProps, useHistory, withRouter } from "react-router";
import { DiscusstionController } from "../../controllers/discussion/discusstion_controller";
import { AppRoutes } from "../../core/constants";
import { $ReplaceRoute, getUserRole, Theme, UserRoles } from "../../core/utils";
// import { LocalDataSources } from "../../data/local_datasources";
// import { Discussion } from "../../data/models/responses/discussion/get_all_discusstion_res";
import { MainButton, MainButtonType } from "../components/button";
import { EmptyState } from "../components/empty_state";
import { IconTextVertical } from "../components/Icon_text_vertical";
import ReactPaginate from "react-paginate";
import { CircleIcon, ThemeCircleIcon } from "../components/circle_icon";
const RightSection: React.FC<RouteComponentProps> = (props) => {


  return (
    <div className="righ-section dNoneLg">
      <QuickActions />
      <RecentDiscussion />
    </div>
  );
};
export const QuickActions = ({
  onSelectAction,
}: {
  onSelectAction?: () => void;
}) => {
  const history = useHistory();
  const userRole = getUserRole();
  return (
    <div className="top d-flex justify-content-around align-items-start text-center quickAccessBox"
    >
      {userRole === UserRoles.L3User ? (
        <IconTextVertical
          onClick={() => {
            history.push($ReplaceRoute(AppRoutes.data_new));
            onSelectAction && onSelectAction();
          }}
          text={
            <span>
              Add Data
            </span>
          }
          theme={Theme.light}
          children={<MdOutlineAddTask size="3em" className="mb-2" />}
          className="pointer"
          fontSize="14px"
        ></IconTextVertical>
      ) : (
        <IconTextVertical
          onClick={() => {
            history.push($ReplaceRoute(AppRoutes.task_new));
            onSelectAction && onSelectAction();
          }}
          text={
            <span>
              Create <br /> New Task
            </span>
          }
          theme={Theme.light}
          children={<MdOutlineAddTask size="3em" className="mb-2" />}
          className="pointer"
          fontSize="14px"
        ></IconTextVertical>
      )}

      <IconTextVertical
        text={
          <span>
            New <br /> Discussion
          </span>
        }
        theme={Theme.light}
        children={<BsFillChatLeftDotsFill size="3em" className="mb-2" />}
        onClick={() => {
          history.push($ReplaceRoute(AppRoutes.discussion_new));
          onSelectAction && onSelectAction();
        }}
        className="pointer"
        fontSize="14px"
      ></IconTextVertical>
      <IconTextVertical
        text={
          <span>
            Data <br /> Collection
          </span>
        }
        theme={Theme.light}
        children={<MdOutlineAddchart size="3em" className="mb-2" />}
        onClick={() => {
          history.push($ReplaceRoute(AppRoutes.data));
          onSelectAction && onSelectAction();
        }}
        className="pointer"
        fontSize="14px"
      ></IconTextVertical>
    </div>
  )
}
export const RecentDiscussion = ({
  onSelectDiscussion
}: {
  onSelectDiscussion?: () => void;
}) => {
  const history = useHistory();
  const controller = new DiscusstionController();
  // const local = new LocalDataSources();
  const [paginate, setPaginate] = useState<{
    pageSize: { label: string; value: number };
    pageNumber: number;
    pageCount: number;
    totalCount: number;
  }>({
    pageSize: { label: "10", value: 10 },
    pageNumber: 0,
    pageCount: 0,
    totalCount: 0,
  });
  const [discusstion, setdiscusstion] = useState<
    {
      id: number;
      subject: string;
      unreadMessageCount: number;
    }[]
  >();
  const updateRecentDiscussion = (_filterForm: {
    PageSize: number;
    PageNumber: number;
  }) => {
    const _userRole = getUserRole();
    if (_userRole === UserRoles.Admin) return;
    controller.getRecentDiscussion(
      {
        pageNumber: _filterForm.PageNumber,
        pageSize: _filterForm.PageSize,
      },
      (res) => {
        if (!res) return;
        
        setdiscusstion(res.discussions);
        setPaginate((prev) => ({
          ...prev,
          pageCount: Math.ceil(res.count! / paginate.pageSize.value),
          totalCount: res.count,
        }));
      },
      (err) => { }
    );
  };

  useEffect(() => {
    const _isTokneHere = localStorage.getItem("token");
    if (_isTokneHere) {
      updateRecentDiscussion({
        PageSize: 10,
        PageNumber: 1,
      });
    }
    const _updateRecentDiscussion = (e: any) => {
      updateRecentDiscussion({
        PageSize: paginate.pageSize.value,
        PageNumber: paginate.pageNumber,
      });
    }
    window.addEventListener("UPDATE_RECENT_DISCUSSION", _updateRecentDiscussion);

    return () => {
      window.removeEventListener(
        "UPDATE_RECENT_DISCUSSION",
        _updateRecentDiscussion
      );
    };
  }, []);
  return (
    <div className="bottom recentDiscussBox">
      <div className="d-flex flex-column justify-content-center">
        <div className="d-flex justify-content-start align-items-center">
          {/* <img src="/Images/icons/chat.png" className="mx-2" /> */}
          <GiTalk size="2.5em" className="mx-2" color="black" />
          <span
            className="pointer title-recent"
            onClick={() => {
              history.push(
                $ReplaceRoute($ReplaceRoute(AppRoutes.discussion_list))
              );
              onSelectDiscussion && onSelectDiscussion();
            }}
          >
            {discusstion?.length} Recent Discussions
          </span>
        </div>
        <div className="massages " style={{ overflow: "hidden" }}>
          <ul>
            {discusstion && discusstion?.length > 0 ? (
              discusstion?.map((item, index) => (
                <li
                  onClick={() => {
                    history.push(
                      $ReplaceRoute(
                        `${AppRoutes.discussion.replace(
                          ":id",
                          item.id.toString() ?? ""
                        )}`
                      )
                    );
                    onSelectDiscussion && onSelectDiscussion();
                  }}
                  className="pointer"
                  key={index}
                >
                  <span
                    className="text-truncate d-inline-block max1LineText"
                    // style={{ maxWidth: "115px" }}
                    title={item.subject}
                  >
                    {item.subject}
                  </span>
                  <MainButton
                    style={{
                      whiteSpace: 'nowrap'
                    }}
                    type={MainButtonType.light}
                    children={`${item.unreadMessageCount} message`}
                    borderRadius="15px"
                    backgroundColor="#ffff"
                    color="#474747"
                    fontSize="11px"
                  ></MainButton>
                </li>
              ))
            ) : (
              <EmptyState
                backgroundColor="#4E0057"
                text="No new discussions found."
                fontSize="15px"
                color="white"
                minHeight="100px"
              />
            )}
          </ul>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-2 px-1">
          {paginate.totalCount > paginate.pageSize.value ? (
            <div className="d-flex justify-content-end flex-fill">
              <ReactPaginate
                forcePage={paginate.pageNumber}
                previousLabel={
                  <CircleIcon
                    width="24px"
                    backgroundColor="#6e6e6e"
                    height="24px"
                    padding="0"
                    type={ThemeCircleIcon.dark}
                  >
                    <i className="fas fa-chevron-left"></i>
                  </CircleIcon>
                }
                nextLabel={
                  <CircleIcon
                    width="24px"
                    backgroundColor="#6e6e6e"
                    height="24px"
                    padding="0"
                    type={ThemeCircleIcon.dark}
                  >
                    <i className="fas fa-angle-right"></i>
                  </CircleIcon>
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={paginate.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(e) => {
                  setPaginate((prev) => ({
                    ...prev,
                    pageNumber: e.selected,
                  }));

                  updateRecentDiscussion({
                    PageNumber: e.selected + 1,
                    PageSize: paginate.pageSize.value
                  });
                  // setData(
                  //   handlePaginate(
                  //     allReservation,
                  //     paginate.pageSize.value,
                  //     e.selected + 1
                  //   )
                  // );
                }}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          ) : (
            ""
          )}

          <div className="d-flex justify-content-end flex-fill">
            <p className="text-right mb-0" style={{ fontSize: 11, }}>
              Total Results: {paginate.totalCount}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default withRouter(RightSection);
