import { HTTP, handleUnauthorizedRequest } from "../../../core/http_common";
import { UploadRes } from "../../models/responses/upload_media/upload_res";

export class RemoteUpload {
  Media(body: any, action: (res: UploadRes) => any, error: (err: any) => any) {
    return HTTP.post("/Media", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
}
