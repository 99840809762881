import { ReactElement, useEffect } from "react";
import { StatusLabel } from "../status_label";
import Tippy from "@tippyjs/react";
import { MdClose } from "react-icons/md";

interface IFilterBullet {
  selecteDate?: string;
  status?: any[];
  type?: any[];
  className?: string;
  size?: "md" | "sm";
  onRemove: (
    filterType: string,
    statusId?: number,
    teamTypeId?: number
  ) => void;
}
export const FilterBullet = ({
  selecteDate,
  status,
  type,
  className = "",
  onRemove,
}: IFilterBullet): ReactElement => {
  useEffect(() => {}, []);

  const ShowCrossIcon = (
    filterType: string,
    statusNumber?: number,
    teamTypeId?: number
  ) => (
    <Tippy arrow={false} visible={false} interactive placement="bottom">
      <span
        title={"Click to remove"}
        onClick={() => onRemove(filterType, statusNumber, teamTypeId)}
        className="pointer darkenBg d-flex align-items-center justify-content-center s-chip outlined"
        style={{
          height: "1.75em",
          width: "1.75em",
          borderRadius: "50%",
          padding: 0,
          float: "right",
          background: "#474747",
          color: "white",
        }}
      >
        <MdClose />
      </span>
    </Tippy>
  );

  return (
    <div
      className={`d-flex flex-wrap justify-content-start ${className}`}
      style={{ paddingLeft: "9px", gap: "2.0rem" }}
    >
      {selecteDate && (
        <div style={{ display: "flex", gap: ".25rem" }}>
          <div
            className="s-chip font-size-filter-section"
            style={{
              backgroundColor: "#474747",
              color: "#FFFFFF",
            }}
          >
            {selecteDate}
          </div>
          {ShowCrossIcon("date")}
        </div>
      )}

      {status && (
        <div style={{ display: "flex", gap: ".25rem" }}>
          {status.map((item, index) => (
            <>
              <StatusLabel
                className="justify-content-center chipBottomRightInSm font-size-filter-section"
                status={item.Id}
                deadLine={item.deadline}
              />
              {ShowCrossIcon("status", item.Id)}
            </>
          ))}
        </div>
      )}

      {type && (
        <div style={{ display: "flex", gap: ".25rem" }}>
          {type.map((item, index) => (
            <>
              <StatusLabel
                className="justify-content-center chipBottomRightInSm"
                status={item.Id}
                deadLine={item.deadline}
              />
              {ShowCrossIcon("type", undefined, item.Id)}
            </>
          ))}
        </div>
      )}
    </div>
  );
};
