import { HTTP, handleUnauthorizedRequest } from "../../../core/http_common";
import { SortType } from "../../../core/number_extentions";
import { IFilterOptions } from "../../../views/components/FilterSection/IFilterOptions";
import { AddDataReq } from "../../models/requests/data/add_data_req";
import { UpdateDataReq } from "../../models/requests/data/update_data_req";
import { AddDataRes } from "../../models/responses/data/add_data_res";
import { GetAllDataRes } from "../../models/responses/data/get_all_data_res";
import { GetDataByIDRes } from "../../models/responses/data/get_by_id_data_res";
import { SearchDataRes } from "../../models/responses/data/search_data_res";
import { UpdateDataRes } from "../../models/responses/data/update_data_res";

export class RemoteData {
  createData(
    body: AddDataReq,
    action: (res: AddDataRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/Data", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  updateData(
    body: UpdateDataReq,
    action: (res: UpdateDataRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.put("/Data", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  SearchData(
    body: { researchId?: number },
    action: (res: SearchDataRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(`/Data/Search?researchId=${body.researchId}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getAllData(
    body: {
      researchId?: number;
      PageSize: number;
      PageNumber: number;
      SearchParameter: string;
      columnHeaderName?: string; sortDirection?: SortType;
    },
    action: (res: GetAllDataRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(
      `/Data?ResearchId=${body.researchId}&PageSize=${body.PageSize}&PageNumber=${body.PageNumber}&SearchParameter=${body.SearchParameter}&ColumnName=${body.columnHeaderName}&SortDirection=${body.sortDirection}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getDataListByTaskId(
    body: {
      researchId: number;
      PageSize: number;
      PageNumber: number;
      SearchParameter: string;
      TaskId: number;
      ColumnHeaderName : string,
      SortDirection: SortType,
      filterOptions: IFilterOptions
    },
    action: (res: GetAllDataRes) => any,
    error: (res: any) => any
  ) {;
    const ColumnName = body.ColumnHeaderName ? body.ColumnHeaderName : '';
  const sort = body.SortDirection ? body.SortDirection : SortType.AutoSelect;
  const FromDate = body?.filterOptions?.selectedFromDate ? body.filterOptions.selectedFromDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '';
    const ToDate = body?.filterOptions?.selectedToDate ? body.filterOptions.selectedToDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '';
    
    return HTTP.get(
      `/Data/DataList?PageSize=${body.PageSize}&PageNumber=${body.PageNumber}&SearchParameter=${body.SearchParameter}&TaskId=${body.TaskId}&ColumnName=${ColumnName}&SortDirection=${sort}&SelectedFromDate=${FromDate}&SelectedToDate=${ToDate}&ResearchId=${body.researchId}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getDataById(
    body: { dataId: number; researchId: number; appTaskId: number },
    action: (res: GetDataByIDRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(
      `/Data/${body.dataId}?researchId=${body.researchId}&appTaskId=${body.appTaskId}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  deleteData(
    body: {dataId: number },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.delete(
      `/Data/DeleteData?dataId=${body.dataId}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
}
