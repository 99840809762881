import { HTTP, handleUnauthorizedRequest } from "../../../core/http_common";
import { SortType } from "../../../core/number_extentions";
import { DiscussionTopic } from "../../../core/utils";
import { IFilterOptions } from "../../../views/components/FilterSection/IFilterOptions";
import { CreateTicketReq } from "../../models/requests/discussion/create_ticket_req";
import { DiscusstionCreateReq } from "../../models/requests/discussion/discusstion_create_req";
import { BroadCastRes } from "../../models/responses/discussion/create_broadcast_res";
import { CreateMessageRes } from "../../models/responses/discussion/create_massage_res";
import { CreateTicketRes } from "../../models/responses/discussion/create_ticket_res";
import { DiscusstionCreateRes } from "../../models/responses/discussion/discusstion_create_res";
import { DiscusstionSearchRes } from "../../models/responses/discussion/discusstion_search_res";
import { GetAllDiscusstionRes } from "../../models/responses/discussion/get_all_discusstion_res";
import { GetDiscusstionPanelRes } from "../../models/responses/discussion/get_discusstion_panel_res";
import {
  DiscussionListRes,
  DiscusstionSectionRes,
} from "../../models/responses/discussion/section_user_res";

export class RemoteDiscusstion {
  createDiscusstion(
    body: DiscusstionCreateReq,
    action: (res: DiscusstionCreateRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/Discussion", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  createMassage(
    body: { discussionId: number; message: string },
    action: (res: CreateMessageRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/Discussion/CreateMessage", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  createBroadCast(
    body: { subject: string; broadCastTypes: number[]; message: string },
    action: (res: BroadCastRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/Discussion/CreateBroadCast", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  createTicket(
    body: CreateTicketReq,
    action: (res: CreateTicketRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/Discussion/CreateTicket", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getAllDiscussion(
    body: {
      PageNumber: number;
      PageSize: number;
      ticket: boolean;
      SearchParameter: string;
      columnHeaderName?: string;
      sortDirection?: SortType;
      filterOptions?: IFilterOptions,
      selectedProject?: any,
      profileType?: any,
      topicId?: any,
      sectionId?: any,
    },
    action: (res: GetAllDiscusstionRes) => any,
    error: (res: any) => any
  ) {
    const ColumnName = body.columnHeaderName ? body.columnHeaderName : '';
    const sort = body.sortDirection ? body.sortDirection : SortType.AutoSelect;
    const FromDate = body?.filterOptions?.selectedFromDate ? body.filterOptions.selectedFromDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '';
    const ToDate = body?.filterOptions?.selectedToDate ? body.filterOptions.selectedToDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '';
    const status = body?.filterOptions?.status ? body.filterOptions.status : '';
    const profileType = body?.profileType;
    const StatusId = Array.isArray(status) ? status.map((obj: { value: any }) => obj.value) : [];
    const type = body.filterOptions?.teamType ? body.filterOptions?.teamType : '';
    const typeList = Array.isArray(type) ? type.map((obj: { value: any }) => obj.value) : [];
    const topicId = body?.topicId ? body?.topicId : '';
    const sectionId = body?.sectionId ? body?.sectionId : '';
    return HTTP.get(
      `/Discussion?PageSize=${body.PageSize}&PageNumber=${body.PageNumber}&SearchParameter=${body.SearchParameter}&Ticket=${body.ticket}&ColumnName=${ColumnName}&SortDirection=${sort}&SelectedFromDate=${FromDate}&SelectedToDate=${ToDate}&SelectedStatus=${StatusId}&Type=${typeList}&selectedProject=${body.selectedProject != undefined ? body.selectedProject : 0 }&profileType=${profileType != undefined ? profileType : DiscussionTopic.Ticket}&topicId=${topicId}&sectionId=${sectionId}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getDiscusstionPanel(
    body: { discussionId: number; ticket: boolean },
    action: (res: GetDiscusstionPanelRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(
      `/Discussion/Panel?discussionId=${body.discussionId}&ticket=${body.ticket}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  DiscusstionSearch(
    body: { discussionTopic: number; isTicket: boolean; taskId?: number; researchId?: number },
    action: (res: DiscusstionSearchRes) => any,
    error: (res: any) => any
  ) {
    const research = body.researchId === null || body.researchId === undefined ? '' : body.researchId
    // 
    return HTTP.get(
      `/Discussion/Search?discussionTopic=${body.discussionTopic}&isTicket=${body.isTicket
      }&researchId=${research} ${body.taskId ? "&taskId=" + body.taskId : ""}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  discusstionSectionUser(
    body: { discussionTopic: number; sectionId: number },
    action: (res: DiscusstionSectionRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(
      `/Discussion/SectionUsers?discussionTopic=${body.discussionTopic}&sectionId=${body.sectionId}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  getDiscusstionBySectionAndTopic(
    body: {
      discussionTopic: number;
      sectionId: number;
      PageNumber: number;
      PageSize: number;
      columnHeaderName?: string, sortDirection?: SortType
    },
    action: (res: DiscussionListRes) => any,
    error: (res: any) => any
  ) {
    const ColumnName = body.columnHeaderName ? body.columnHeaderName : '';
    const sort = body.sortDirection ? body.sortDirection : SortType.AutoSelect;
    return HTTP.get(
      `/Discussion/GetUserSectionList?SectionId=${body.sectionId}&DiscussionTopic=${body.discussionTopic}&PageSize=${body.PageSize}&PageNumber=${body.PageNumber}&ColumnName=${ColumnName}&SortDirection=${sort}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  getDiscussionTopicData(
    body: {
      sectionId: number;
      topicId: number;
    },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(
      `/Discussion/GetDiscussionTopicData?SectionId=${body.sectionId}&TopicId=${body.topicId}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  updateDiscussion(
    body: {
      id: number;
      categoryId?: number | null;
      subject: string;
      priority: number;
      ticketStatus: number | null; // 1 | 2;
      addedUsersId: string[];
      removedUsersId: string[];
    },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.post(`Discussion/UpdateTicket`, body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  addUsers(
    body: {
      id: number;
      topic: number;
      sectionId: number;
      usersId: string[];
    },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.post(`Discussion/AddUser`, body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  getRecentDiscussion(
    body: {
      pageSize: number;
      pageNumber: number;
    },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(
      `/Discussion/RecentDiscussion?PageSize=${body.pageSize}&PageNumber=${body.pageNumber}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  deleteDiscussion(
    body: {discussionId: number },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.delete(
      `/Discussion/DeleteDiscussion?discussionId=${body.discussionId}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
}
