import Tippy from '@tippyjs/react';
import React, { useEffect, useRef, useState } from 'react';
import { useToggle } from 'usehooks-ts';
import SpinnerLoader from '../spinnerLoader';
import { FaAngleDown, FaTimes } from 'react-icons/fa';
import { MdArrowRight } from 'react-icons/md';
import * as Checkbox from '@radix-ui/react-checkbox';
import { Collapse } from 'react-collapse';
import { CheckIcon } from '@radix-ui/react-icons';
import { BiDownArrow } from 'react-icons/bi';
import CircleIconTooltip from '../CircleIconTooltip';
import { TextField } from '@radix-ui/themes';

interface OptionProps {
  option: TreeOptionType;
  isChecked: boolean | undefined;
  onItemClicked: (option: TreeOptionType, parent?: TreeOptionType) => void;
}

const Option: React.FC<OptionProps> = ({ option, isChecked, onItemClicked }) => {
  const [isOpen, toggleCollapse] = useState(false);
  const [isChildOpen, toggleChildCollapse] = useState(false);
  const isParent = option.children?.length;
  const isOneOfMyChildChecked = option.children?.some(_ch => _ch.checked);
  const shouldIChecked = isOneOfMyChildChecked && !option.checked;

  const handleClick = () => {
    onItemClicked(option);
  };

  return (
    <div className={`d-flex flex-column my-1`}>
      <div className='d-flex align-items-center'>
        <div className='d-flex align-items-center relative'>
          {isParent ? (
            <MdArrowRight
              onClick={() => toggleCollapse(!isOpen)}
              size="1.4em" className={`treeMenuParentChevron ${isOpen ? 'openMenu' : ''}`} />
          ) : ''}
          {(!isParent && option.extraData) ? (
            <MdArrowRight
              style={{ left: '-1.5em', position: 'absolute' }}
              onClick={() => toggleChildCollapse(!isChildOpen)}
              size="1.4em" className={`treeMenuParentChevron ${isChildOpen ? 'openMenu' : ''}`} />
          ) : ''}
          <Checkbox.Root
            className="checkbox-root checkbox-style"
            checked={isChecked}
            onCheckedChange={handleClick}
          >
            <Checkbox.Indicator className="checkbox-indicator">
              <CheckIcon />
            </Checkbox.Indicator>
          </Checkbox.Root>
        </div>
        <label
          onClick={handleClick} className='ms-2 pointer max1LineText tree-menu-text'
          title={option.label}
        >
          {option.label}
          {isParent ? shouldIChecked ? (
            <span className='textSecondary ms-2'>
              (Selective Access)
            </span>
          ) : option.checked ? (
            <span className='textSecondary ms-2'>
              (Full Access)
            </span>
          ) : '' : ''}
        </label>
      </div>
      {isParent ? (
        <Collapse isOpened={isOpen}>
          <div className='treeMenuChildWrapper'>
            {option.children?.map((child) => (
              <Option key={child.value} option={child}
                isChecked={child.checked || option.checked}
                onItemClicked={() => onItemClicked(child)} />
            ))}
          </div>
        </Collapse>
      ) : option.extraData ? (
        <Collapse isOpened={isChildOpen}>
          <div style={{ paddingTop: '0.15em', paddingLeft: '1.7em' }}>
            {option.extraData}
          </div>
        </Collapse>
      ) : ''}
    </div>
  );
};

export type TreeOptionType = {
  label: string;
  value: string | number;
  checked?: boolean;
  children?: TreeOptionType[];
  extraData?: any;
};

interface NewTreeMenuTypes {
  options: TreeOptionType[];
  onChange: (_parents: (string | number)[], _childrens: (string | number)[]) => void;
  loading?: boolean;
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  defaultParents?: TreeOptionType[];
  defaultChilds?: TreeOptionType[];
  forceSelectedChilds?: (string | number)[];
  popQuestion?: string;
}

const NewTreeMenu = ({ disabled, popQuestion, forceSelectedChilds, defaultChilds, defaultParents, label, loading, options, onChange, placeholder = "Select.." }: NewTreeMenuTypes) => {
  const [visible, toggleVisible] = useState(false);
  const [state, setState] = useState<TreeOptionType[]>([]);
  const [selectedValues, setSelectedValues] = useState<TreeOptionType[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const treeMenuContainerRef = useRef<HTMLDivElement>(null);
  const renderRef = useRef(false);

  const handleClickOption = (_option: TreeOptionType, _seter = true) => {
    if (_option.value === -1) return;
    let type = 'parent';
    if (_option.children?.length === 0 || (_option.children && _option.children?.length > 0)) {
      type = 'parent';
    } else {
      type = 'child';
    }

    if (type === 'parent') {
      if (!_option.checked) {
        _option.checked = true;
        _option.children?.forEach(_child => {
          _child.checked = true;
        });

        const _chIds = _option.children?.map(_c => _c.value);
        state.forEach(_parent => {
          if (_parent.value !== _option.value) {
            _parent.children?.forEach(_ch => {
              if (_chIds?.includes(_ch.value)) _ch.checked = _option.checked;
            });
          }
        });
      } else {
        _option.checked = false;
        _option.children?.forEach(_child => {
          if (forceSelectedChilds?.includes(_child.value)) return;
          _child.checked = false;
        });

        const _chIds = _option.children?.map(_c => _c.value);
        state.forEach(_parent => {
          if (_parent.value !== _option.value) {
            _parent.children?.forEach(_ch => {
              if (_chIds?.includes(_ch.value) && !forceSelectedChilds?.includes(_ch.value)) _ch.checked = _option.checked;
            });
          }
        });
      }
    } else {
      if (!_option.checked) {
        _option.checked = true;
      } else {
        if (forceSelectedChilds?.includes(_option.value)) return;
        _option.checked = false;
        state.forEach(_parent => {
          const _chIds = _parent.children?.map(_c => _c.value);
          if (_chIds?.includes(_option.value)) _parent.checked = false;
        });
      }
    }

    if (type === 'child') {
      state.forEach(_parentOption => {
        _parentOption.children?.forEach(_child => {
          if (_child.value === _option.value) _child.checked = _option.checked;
        });
      });
    }

    if (_seter) setState([...state]);

    let exclusiveChildren: (string | number)[] = [];
    let _parents: (string | number)[] = [];
    let _parentsObj: TreeOptionType[] = [];
    let _childrens: (string | number)[] = [];
    let _childrensObj: TreeOptionType[] = [];

    state.forEach(_parent => {
      if (_parent.checked) {
        _parents.push(_parent.value);
        _parentsObj.push(_parent);
        exclusiveChildren.push(..._parent.children?.map((_ch) => _ch.value) || []);
      }
    });
    state.forEach(_parent => {
      _parent.children?.forEach(_child => {
        if (!exclusiveChildren.includes(_child.value) && _child.checked && !_childrens.includes(_child.value) && !_parent.checked) {
          _childrens.push(_child.value);
          _childrensObj.push(_child);
        }
      });
    });

    setSelectedValues([..._parentsObj, ..._childrensObj]);
    onChange(_parents, _childrens);
  };

  const handleRemoveSelectedOption = (option: TreeOptionType) => {
    handleClickOption(option);
  };

  useEffect(() => {
    if (options.length > 0) {
      let _parentsObj: TreeOptionType[] = [];
      let _childrensObj: TreeOptionType[] = [];
      setState(options.map(_parent => {
        if (_parent.checked && !_parentsObj.some(_p => _p.value === _parent.value)) _parentsObj.push(_parent);
        return {
          ..._parent, checked: _parent.checked || false,
          children: _parent.children?.map(_child => {
            if (_child.checked && !_childrensObj.some(_c => _c.value === _child.value)) _childrensObj.push(_child);
            return { ..._child, checked: _child.checked || false };
          })
        };
      }));
      setSelectedValues([..._parentsObj, ..._childrensObj]);
      renderRef.current = true;
    }
  }, [options]);

  useEffect(() => {
    defaultChilds?.forEach(_ch => {
      let done = false;
      state.forEach(_p => {
        if (done) return;
        _p.children?.forEach(_c => {
          if (_ch.value === _c.value) {
            if (_ch.extraData) _c.extraData = _ch.extraData;
            handleClickOption(_c, false);
            done = true;
          }
        });
      });
    });
    defaultParents?.forEach(_parent => {
      state.forEach(_p => {
        if (_parent.value === _p.value) handleClickOption(_p, false);
      });
    });
    setState([...state]);
  }, [defaultParents, defaultChilds]);

  const filteredOptions = state.filter(option =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (option.children && option.children.some(child => child.label.toLowerCase().includes(searchTerm.toLowerCase())))
  );

  return (
    <div className='d-flex flex-column'>
      {label ? (
        <div className='d-flex align-items-center mb-1'>
          <label className='labelStyles'>{label}</label>
          {popQuestion ? (
            <img
              className="text-primary cursor-pointer"
              src="/Images/icons/info-circled.svg"
              alt="info"
              style={{ paddingLeft: "5px" }}
              title={popQuestion}
            />
          ) : ''}
        </div>
      ) : ''}
      <Tippy
        visible={visible}
        interactive
        placement='bottom'
        disabled={disabled}
        arrow={false}
        onShow={({ popper }) => {
          popper.style.width =
            treeMenuContainerRef.current?.getBoundingClientRect().width + "px";
        }}
        content={
          <div className='py-1 tippyHeight'>
            <div className="sticky-top bg-white py-2">
              <TextField.Root
                size={"3"}
                aria-disabled={disabled}
                value={searchTerm}
                placeholder={placeholder}
                className={`form-control control-border-color`}
                aria-autocomplete="none"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {filteredOptions.length > 0 ? filteredOptions.map((option) => (
              <Option
                isChecked={option.checked}
                onItemClicked={(_option) => handleClickOption(_option)}
                key={option.value} option={option}
              />
            )) : (
              <div
                style={{ height: '2.25em' }}
                className='d-flex align-items-center justify-content-center'>
                <h6 className='textSecondary mb-0' style={{ opacity: '0.7' }}>No options</h6>
              </div>
            )}
          </div>
        }
        onClickOutside={() => toggleVisible(!visible)}
        className='w-100 treeMenuTippy'
        maxWidth="none"
        offset={[0, 4]}
      >
        <div ref={treeMenuContainerRef} style={{ borderRadius: "var(--1, 4px)" }} onClick={() => toggleVisible(!visible)} className={`treeMenuWrapper ${disabled ? 'treeMenuDisabled' : ''}`}>
          <div className='d-flex flex-wrap gap-1' style={{paddingLeft:'5px'}}>
            {selectedValues.length > 0 ? selectedValues.map(_val => (
              <Chip
                key={_val.value}
                className={`d-flex align-items-center ${_val.children ? 'bg-surface-2' : 'bg-surface-3'}`}
              >
                {_val.label}
                <FaTimes
                  style={{ marginLeft: '5px', cursor: 'pointer' }}
                  onClick={() => handleRemoveSelectedOption(_val)}
                />
              </Chip>
            )) : <span className='textSecondary customFontStyle' style={{ margin: '0 0.15em' }}>{placeholder}</span>}
          </div>
          <div className='position-absolute top-50 end-0 translate-middle-y'
            style={{ marginRight: '0.75em' }}
          >
            {loading ? (
              <SpinnerLoader size="16px" />
            ) : (
              <FaAngleDown />
            )}
          </div>
        </div>
      </Tippy>
    </div>
  );
};

const Chip = ({ className = "", children }: { className?: string, children: React.ReactNode }) => {
  return (
    <div className={`s-chip fs-100 ${className}`} style={{
      borderRadius: '999px',
      padding: '0.22em 0.48em',
      whiteSpace: 'pre-wrap'
    }}>
      {children}
    </div>
  );
};

export default NewTreeMenu;
