/* eslint-disable no-useless-concat */
import React, { Fragment, ReactNode } from "react";
import { MainButton, MainButtonType } from "./button";
// import { CircleIcon, ThemeCircleIcon } from "./circle_icon";
import CircleIconTooltip from "./CircleIconTooltip";

interface InputsProps {
  width?: string;
  height?: string;
  minWidth?: string;
  minHeigth?: string;
  backgroundColor?: string;
  className?: string;
  borderRadius?: string;
  onChange?: (e: any) => void;
  name: string;
  items: any[];
  TextItem: string;
  ValueItem: string;
  label?: ReactNode;
  popQuestion?: string;
  optional?: string;
  inValid?: string;
  Selected?: number;
  disabled?: boolean;

}
export const RadioGroup: React.FC<InputsProps> = ({
  width,
  height,
  minWidth,
  minHeigth,
  backgroundColor,
  className,
  borderRadius,
  onChange,
  name,
  items,
  TextItem,
  ValueItem,
  label,
  popQuestion,
  optional,
  inValid,
  Selected,
  disabled
}) => {
  let styles = {
    width: width,
    height: height,
    minWidth: minWidth,
    minHeigth: minHeigth,
    backgroundColor: backgroundColor,
    borderRadius: borderRadius,
  };
  let IsclassName: string;
  if (className !== undefined) {
    IsclassName = className;
  } else {
    IsclassName = "";
  }
  let TemplateLabel;
  if (label !== null && label !== undefined) {
    TemplateLabel = (
      <span className="label d-flex align-items-center">
        {label}
        {optional ? (
          <MainButton
            type={MainButtonType.light}
            children={optional}
            borderRadius="50px"
            fontSize="15px"
            className="mx-2"
          ></MainButton>
        ) : null}
        {popQuestion ? (
          <CircleIconTooltip className="ms-2"
            text={popQuestion}
          />
        ) : null}
      </span>
    );
  }
  let TemplateValidation;
  let IsinValid: string;
  if (inValid) {
    TemplateValidation = <div>{inValid}</div>;
    IsinValid = "is-invalid";
  }

  return (
    <Fragment>
      {TemplateLabel}
      {items.map((item, index) => (
        <div
          className={`${IsclassName +
            " " +
            "RadioButtonGroupStyle" +
            " " +
            "form-check form-check-inline mt-1" +
            " " +
            IsinValid
            } pointer`}
          style={styles}
          key={index}
        >
          <input
            disabled={disabled}
            className="form-check-input pointer"
            type="radio"
            name={name}
            id={item[ValueItem] + name}
            value={item[ValueItem]}
            onChange={onChange}
            checked={item[ValueItem] === Selected}
          />
          <label
            className="form-check-label pointer"
            htmlFor={item[`${ValueItem}`] + name}
            style={{ fontSize: "12px" }}
          >
            {item[`${TextItem}`]}
          </label>
        </div>
      ))}
      {TemplateValidation}
    </Fragment>
  );
};
