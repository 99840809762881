import { toast } from "react-toastify";
import {
  CreateNewCategory,
  CreateNewCategoryResult,
} from "../../data/models/responses/category/create_new_category";
import { GetAllCategoriesResult } from "../../data/models/responses/category/get_all_categories";
import { RemoteCategory } from "../../data/remotes/category/remote_category";
export class CategoryController {
  remote = new RemoteCategory();

  getAllCategories(
    body: { type: number },
    action: (res: GetAllCategoriesResult[]) => any
  ) {
    this.remote.getAllCategories(
      body,
      (res) => action(res.result!),
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
      }
    );
  }

  createNewCategory(
    body: { title: string; type: number },
    action: (res: CreateNewCategoryResult) => any,
    error: (res: any) => any
  ) {
    this.remote.createNewCategory(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result as CreateNewCategoryResult);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  editCategory(
    body: { id: number; title: string; type: number },
    action: (res: CreateNewCategoryResult) => any,
    error: (res: any) => any
  ) {
    this.remote.editCategory(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result as CreateNewCategoryResult);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  deleteCategory(
    body: { id: number },
    action: (res: CreateNewCategory) => any,
    error: (res: any) => any
  ) {
    this.remote.deleteCategory(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result as CreateNewCategory);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  getAllCountries(body: any, action: (res: any[]) => any,error: (res: any) => any) {
    this.remote.getAllCountries(
      body,
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err)
      }
    );
  }
}
