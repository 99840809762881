import React, { useEffect, useState } from 'react';
import { Switch, useHistory, withRouter } from 'react-router';
import { AppRoutes } from '../../../core/constants';
import { HTTP } from '../../../core/http_common';
import { toast } from 'react-toastify';
import { $ReplaceRoute } from '../../../core/utils';
import ReactLoading from 'react-loading';

import './Subs_Payment.css';
import { SignupBackground } from '../signup/component/signup_background';
import { PaymentIntent } from '../Subscriptions/PaymentIntent';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SpinnerLoader from '../../components/spinnerLoader';
import { base_url_site, publishable_key } from '../../../core/localVariables';
const stripePromise = loadStripe(publishable_key);
const Subs_payment = () => {
  document.title = `Radvix | Payment Method`;
  const history = useHistory();
  const [pageloading, setPageloading] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [isTrial, setProvideTrial] = useState(false);
  const [isPaymentSuccess, SetisPaymentSuccess] = useState<any>(0);

  useEffect(() => {
    if (isPaymentSuccess == 0) {
      setPageloading(true);
      HTTP.post(`/Subscription/CreateSetupIntent`)
        .then((res: any) => {
          setClientSecret(res.data.result.clientSecret);
          setProvideTrial(res.data.result.provideTrial);
          setPageloading(false);
        })
        .catch((err) => {
          setPageloading(false);
        });
    }
  }, [isPaymentSuccess]);

  const handlenavigate = () => {
    history.push($ReplaceRoute(AppRoutes.dashboard));
    window.dispatchEvent(
      new CustomEvent('loggedIn', {
        detail: {
          token: localStorage.getItem('token'),
          userId: localStorage.getItem('userId'),
        },
      })
    );
  };
  return (
    <>
      {/* <SignupBackground> */}
      {pageloading && (
        <div
          className='d-flex align-items-center justify-content-center'
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <div className='d-flex flex-column align-items-center'>
            <SpinnerLoader />
            <span className='mt-3 textSecondary'>Initializing...</span>
          </div>
        </div>
      )}
      {isPaymentSuccess == 0 && clientSecret && (
        <div style={{ maxHeight: '100vh', overflow: 'auto' }}>
          <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
            <PaymentIntent updateParentState={SetisPaymentSuccess} />
          </Elements>
        </div>
      )}
      {isPaymentSuccess == 1 && (
        <>
          <div className='container'>
            <div className='max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center vh-100'>
              <div className='text-center mb-3'>
                <img
                  src='images/icons/new-radvix-logo.png'
                  width={111}
                  height={49}
                  // src='images/icons/radvix-logo.svg'
                  // srcSet='images/icons/radvix-logo.svg 111w'
                  // sizes='(max-width: 768px) 20vw, 10vw'
                  alt='Company logo'
                />
              </div>
              <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column'>
                <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>
                  Payment Method Failed
                </h1>
                <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                <div style={{ padding: '6px 0px 6px 0px' }}>
                  <h4
                    className='fs-15'
                    style={{
                      textAlign: 'center',
                      fontSize: '13px',
                      fontWeight: '400',
                    }}
                  >
                    We were not able to verify your payment method. Please check if all information
                    is correct and try again.
                  </h4>
                </div>
                <button
                  className='d-flex justify-content-center align-items-center btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton'
                  onClick={() => {
                    SetisPaymentSuccess(0);
                  }}
                >
                  {pageloading ? (
                    <ReactLoading className='d-flex' type='spin' height='auto' width={20} />
                  ) : (
                    'Try Again'
                  )}{' '}
                </button>
                <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                <div className='fs-14 fw-normal'>
                  <a
                    className='color-gray-600 cursor-pointer'
                    href={`${base_url_site}/terms-of-services`}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Terms of Service |{' '}
                  </a>
                  <a
                    className='color-gray-600 cursor-pointer'
                    href={`${base_url_site}/privacy-policy`}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isPaymentSuccess == 2 && (
        <>
          <div className='container'>
            <div className='max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center vh-100'>
              <div className='text-center mb-3'>
                <img
                  src='images/icons/new-radvix-logo.png'
                  width={111}
                  height={49}
                  // src='images/icons/radvix-logo.svg'
                  // srcSet='images/icons/radvix-logo.svg 111w'
                  // sizes='(max-width: 768px) 20vw, 10vw'
                  alt='Company logo'
                />
              </div>
              <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column'>
                <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>Congratulations</h1>
                <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                {isTrial && (
                  <div style={{ padding: '6px 0px 6px 0px' }}>
                    <h4
                      className='fs-15'
                      style={{
                        textAlign: 'center',
                        fontSize: '13px',
                        fontWeight: '400',
                      }}
                    >
                      Your trial has started and now you can start using Radvix
                    </h4>
                  </div>
                )}
                <button
                  className='d-flex justify-content-center align-items-center btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton'
                  onClick={handlenavigate}
                >
                  {pageloading ? (
                    <ReactLoading className='d-flex' type='spin' height='auto' width={20} />
                  ) : (
                    'Go to dashboard'
                  )}{' '}
                </button>
                <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                <div className='fs-14 fw-normal'>
                  <a
                    className='color-gray-600 cursor-pointer'
                    href={`${base_url_site}/terms-of-services`}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Terms of Service |{' '}
                  </a>
                  <a
                    className='color-gray-600 cursor-pointer'
                    href={`${base_url_site}/privacy-policy`}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default withRouter(Subs_payment);
