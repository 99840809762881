/* eslint-disable no-extend-native */

import { Button } from "@radix-ui/themes";
import React from "react";
import ReactLoading from "react-loading";
export enum MainButtonType {
  dark = "darkButton",
  light = "lightButton",
  error = "errorButton",
}

interface MainButtonProps {
  children?: React.ReactNode;
  onClick?: () => void;
  border?: string;
  backgroundColor?: string;
  minHeight?: string;
  borderRadius?: string;
  minWidth?: string;
  display?: string;
  type?: MainButtonType;
  fontSize?: string;
  className?: string;
  color?: string;
  loading?: boolean;
  title?: string;
  disabled?: boolean;
  fontWeight?: number;
  style?: React.CSSProperties;
  buttonType?: "button" | "submit"
  tabIndex?: number;
  icon?: React.ReactNode; 
  svg?:React.ReactNode;
}

export const MainButton: React.FC<MainButtonProps> = ({
  border,
  backgroundColor,
  minHeight,
  minWidth,
  borderRadius,
  display,
  onClick,
  type,
  children,
  fontSize = "",
  className = "",
  fontWeight,
  color,
  loading,
  title,
  disabled,
  style,
  buttonType = 'button',
  tabIndex,
  icon,
  svg,
}) => {
  let IsLoading;
  if (loading) {
    IsLoading = "loading";
  }
  const renderIcon = () => {
    if(svg){
        return(<>{svg}</>)
    }
    else if (icon){
      return (<span className="icon" style={{ marginRight: children ? 0 : 0 }}>{icon}</span>)
    }
  }
  return (
    <Button
      type={buttonType}
      onClick={onClick}
      className={`${className + " " + type + " " + IsLoading}`}
      title={title}
      disabled={disabled}
      tabIndex={tabIndex}
    >
      {loading ? (
        <ReactLoading className="d-flex" type="spin" color={color} height="auto" width={20} />
      ) : (
        <>
        
          {renderIcon()}
          {children}
        </>
      )}
    </Button>
  );
};
