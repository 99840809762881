/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { MainButton, MainButtonType } from '../../components/button';
// import { InputComponent, InputType } from "../../components/inputs";
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import { UserController } from '../../../controllers/user/user_controller';
// import { UserSigninReq } from "../../../data/models/requests/user/signin_req";
import { AppRoutes } from '../../../core/constants';
// import SimpleReactValidator from "simple-react-validator";
import { store } from '../../../data/storeMain';
import { SetUserInfo, SetUserRole } from '../../../data/storeMain/actions/user_action';
import {
  $ReplaceRoute,
  UserRoles,
  secureEmail,
  securePhoneNumber,
  verifyUserSubscription,
} from '../../../core/utils';
// import { Link } from "react-router-dom";
// import GoogleLogin from "react-google-login";
// import { useMsal } from "@azure/msal-react";
import OTPInput from 'react-otp-input';
import { Checkbox } from '../setting/component/new_notification_tbl';
import { toast } from 'react-toastify';
import Countdown from 'react-countdown';
import { worSpaceController } from '../../../controllers/workSpace/workSpace';
import { SignupBackground } from '../signup/component/signup_background';
import ReactLoading from 'react-loading';
import { BaseButton } from '@fluentui/react';
import { base_url_site } from '../../../core/localVariables';
import axios from 'axios';

// const SERVER_LOGIN_MESSAGES = [
//   "Invalid User; User Has Been Transferred",
//   "Your account is not active! Please activate your account through the email sent to you.",
//   "Your email has not been verified!",
//   "The password is incorrect!",
// ]
// const NOT_ACTIVATED_ACCOUNT_MESSAGE = "Your email has not been verified!";
// const NOT_ACTIVATED_ACCOUNT_MESSAGE =
//   "Your account is not active! Please activate your account through the email sent to you.";
// "Your account is not active! Please activate your account through the email sent to you.";

const MfaPage: React.FC<RouteComponentProps> = (props) => {
  const [code, setCode] = useState<string | undefined>(undefined);
  const [email, setemail] = useState('');
  const [userTmpEmail, setUserTmpEmail] = useState('');
  const [userTmpMobileNumber, setUserTmpMobileNumber] = useState('');
  const [loading, setloading] = useState(false);
  let history = useHistory();
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false);
  const [canResend, setCanResend] = useState(false);
  const [resendCountDownDate, setResendCountDownDate] = useState(Date.now() + 60000);
  const [resendingCode, setResendingCode] = useState(false);
  const [mfaMode, setMfaMode] = useState<'email' | 'mobile'>('email');
  const controller: UserController = new UserController();
  const workspaceController = new worSpaceController();

  function handleResendCode(_mfaMode?: 'email' | 'mobile') {
    setResendingCode(true);

    const __mfaMode = _mfaMode ? _mfaMode : mfaMode;
    // if (mfaMode === 'email') {
    controller.resendMfaCode(
      {
        mfaPlatform: __mfaMode === 'mobile' ? 1 : 2,
        Email: userTmpEmail,
      },
      (res) => {
        setResendCountDownDate(Date.now() + 60000);
        setCanResend(false);
        setResendingCode(false);
        if (_mfaMode == 'email') {
          toast.info('Regenrated the code on email');
        } else {
          toast.info('Regenrated the code');
        }
      },
      (err) => {
        setResendingCode(false);
      }
    );
    // } else {
    //   controller.resendMfaCodeForMobile((res) => {
    //     setResendCountDownDate(Date.now() + 60000)
    //     setCanResend(false)
    //     setResendingCode(false)
    //     toast.info('Regenrated the code')
    //   }, (err) => {
    //     setResendingCode(false)
    //   })
    // }
  }

  function verifyMfaCode(_code?: string) {
    if (!code) {
      forceUpdate(1);
    } else {
      setloading(true);
      let _body: {
        code: number;
        sessionDays?: number | null;
      } = {
        code: Number(_code ? _code : code),
      };
      _body['sessionDays'] = 30;
      controller.verifyMfaCode(
        _body,
        (res) => {
          if (res) {
            debugger;
            const _mobileLogin = localStorage.getItem('isMobileLogin');
            if (_mobileLogin == '1') {
              window.location.href = res.redirectUrl;
              setloading(false);
              return;
            }
            setloading(false);

            store.dispatch(SetUserRole(res.role ?? 0));
            store.dispatch(SetUserInfo(res));
            //
            if (res.role === UserRoles.Admin) {
              // history.push(`/controlPanel/dashboard/Admin/home`);
              props.history.replace(AppRoutes.admin_clients);
              window.dispatchEvent(
                new CustomEvent('loggedIn', {
                  detail: {
                    token: res.token,
                    userId: res.id,
                  },
                })
              );
            } else {
              verifyUserSubscription(props, res);
              window.dispatchEvent(
                new CustomEvent('loggedIn', {
                  detail: {
                    token: res.token,
                    userId: res.id,
                  },
                })
              );
            }
          }
        },
        //@ts-ignore
        (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
            //setloginUserError(err.response.data.message);
            setTimeout(() => {
              setemail(email);
            }, 500);
            // history.push(AppRoutes.register_page);
          } else {
            //setloginUserError("Something went wrong");
          }
          setloading(false);
        }
      );
    }
  }
  useEffect(() => {
    document.title = `Multi factor authorize | Radvix`;
    const _userToken = localStorage.getItem('token');
    if (_userToken) {
      history.push($ReplaceRoute(AppRoutes.dashboard));
      // workspaceController.isWorkScpaceExist(
      //   (res) => {
      //     toast.error(res.message);
      //     if (res.data.result === true) {
      //       history.push($ReplaceRoute(AppRoutes.dashboard));
      //     } else {
      //       props.history.push(AppRoutes.workSpace);
      //     }
      //   },
      //   (err) => {
      //     //toast.error(err?.response.data.message)
      //   }
      // );
    } else {
      const _userTmpEmail = localStorage.getItem('tmpEmail');
      const _userTmpMobileNumber = localStorage.getItem('tmpMobileNumber');
      if (_userTmpEmail) setUserTmpEmail(_userTmpEmail);

      if (_userTmpEmail && !_userTmpMobileNumber) {
        toast.info('A code was sent to your email, please check your email');
      }

      if (_userTmpMobileNumber) {
        setUserTmpMobileNumber(_userTmpMobileNumber);
        toast.info('A code was sent to your mobile number, please check your mobile number');
        setMfaMode('mobile');
      }
    }
  }, []);
  const [, forceUpdate] = useState(0);

  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      // setCanResend(true)
      return '';
    } else {
      // Render a countdown
      return (
        <span className='ms-2'>
          {minutes}:{seconds}
        </span>
      );
    }
  };
  return (
    <div className='container'>
      <div className='max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center vh-100'>
        <div className='text-center mb-3'>
          <img
            src='images/icons/new-radvix-logo.png'
            width={111}
            height={49}
            // src='images/icons/radvix-logo.svg'
            // srcSet='images/icons/radvix-logo.svg 111w'
            // sizes='(max-width: 768px) 20vw, 10vw'
            alt='Company logo'
          />
        </div>
        <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column loginDivBorder'>
          <div className='d-flex w-100'>
            <BaseButton
              className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
              style={{ fontWeight: '400', height: '19px' }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <div className='verifyingAccount'>
              <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>Verifying account</h1>
            </div>
          </div>
          <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
          <div style={{ padding: '6px 30px 6px 38px' }}>
            <h4 style={{ textAlign: 'center', fontSize: '13px' }}>
              A code was sent to{' '}
              {mfaMode === 'mobile'
                ? securePhoneNumber(userTmpMobileNumber)
                : secureEmail(userTmpEmail)}{' '}
              <span className='fs-13 color-gray-600'>This code will expire in 5 minutes.</span>
            </h4>
          </div>
          <div className='w-100 mb-px-20'>
            <div className='mb-1 text-start'>
              <MfaInput
                code={code}
                onChange={(e) => {
                  setCode(e);
                  if (e.length === 6) {
                    verifyMfaCode(e);
                  }
                }}
              />
            </div>
          </div>
          <p
            style={{
              paddingTop: '10px',
              paddingBottom: '20px',
              marginBottom: '0px',
              fontSize: '14px',
            }}
          >
            <span className='span'>Don't Get Code? Click to</span>

            <span
              className='text-wrapper-6'
              onClick={() => {
                if (canResend) {
                  setMfaMode('mobile');
                  handleResendCode('mobile');
                }
              }}
              style={{
                color: canResend ? 'blue' : '#474747',
                cursor: 'pointer',
              }}
            >
              {' '}
              Resend
            </span>
            {canResend ? (
              ''
            ) : (
              <Countdown
                renderer={renderer}
                date={resendCountDownDate}
                autoStart
                onComplete={() => {
                  setCanResend(true);
                  // setResendCountDownDate(COUNTDOWN_TIME)
                }}
              />
            )}
          </p>
          <button
            className='d-flex justify-content-center align-items-center btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton'
            type='submit'
            onClick={(e) => {
              e.preventDefault();
              if (code?.length === 6) {
                verifyMfaCode(code);
              }
            }}
          >
            {' '}
            {loading ? (
              <ReactLoading className='d-flex' type='spin' height='auto' width={20} />
            ) : (
              'Continue'
            )}{' '}
          </button>
          <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
          <div className='fs-14 fw-normal'>
            <a
              className='color-gray-600 cursor-pointer'
              href={`${base_url_site}/terms-of-services`}
              rel='noopener noreferrer'
              target='_blank'
            >
              Terms of Service |{' '}
            </a>
            <a
              className='color-gray-600 cursor-pointer'
              href={`${base_url_site}/privacy-policy`}
              rel='noopener noreferrer'
              target='_blank'
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
    // <SignupBackground>
    // <div
    //   className="form-container"
    //   style={{
    //     maxWidth: "390px",
    //     background:
    //       "linear-gradient(360deg, rgba(212, 15, 237, 0) 0%, rgba(212, 15, 237, 1) 100%)",
    //   }}
    // >
    //   <h2 className="text-center P_Heading mb-4">
    //     Enter your one time verification code
    //   </h2>
    //   <div style={{ padding: "6px 30px 6px 30px" }}>
    //     <h4 style={{ textAlign: "center", fontSize: "14px" }}>
    //       A code was sent to{" "}
    //       {mfaMode === "mobile"
    //         ? securePhoneNumber(userTmpMobileNumber)
    //         : secureEmail(userTmpEmail)}
    //       . This code will expire in 5 minutes.
    //     </h4>
    //   </div>
    //   <form
    //     onSubmit={(e) => {
    //       e.preventDefault();
    //       if (code?.length === 6) {
    //         verifyMfaCode(code); // Ensure this function is working correctly
    //       }
    //     }}
    //   >
    //     <MfaInput
    //       code={code}
    //       onChange={(e) => {
    //         setCode(e);

    //         /* if (e.length === 4) {
    //             verifyMfaCode(e);
    //           } */
    //       }}
    //     />
    //     <p
    //       style={{
    //         paddingTop: "10px",
    //         marginBottom: "0px",
    //         fontSize: "14px",
    //       }}
    //     >
    //       <span className="span">Don't Get Code? Click to</span>

    //       <span
    //         className="text-wrapper-6"
    //         onClick={() => {
    //           if (canResend) {
    //             setMfaMode("mobile");
    //             handleResendCode("mobile");
    //           }
    //         }}
    //         style={{
    //           color: canResend ? "blue" : "#474747",
    //         }}
    //       >
    //         {" "}
    //         Resend
    //       </span>
    //       {canResend ? (
    //         ""
    //       ) : (
    //         <Countdown
    //           renderer={renderer}
    //           date={resendCountDownDate}
    //           autoStart
    //           onComplete={() => {
    //             setCanResend(true);
    //             // setResendCountDownDate(COUNTDOWN_TIME)
    //           }}
    //         />
    //       )}
    //     </p>

    //     <div className="d-flex" style={{ paddingTop: "30px" }}>
    //       <Checkbox
    //         checked={keepMeLoggedIn}
    //         onChange={(e) => {
    //           setKeepMeLoggedIn(!keepMeLoggedIn);
    //         }}
    //         theme="light"
    //       />
    //       <p
    //         className="ms-2 mb-0 pointer"
    //         onClick={() => setKeepMeLoggedIn(!keepMeLoggedIn)}
    //         //style={{ color: 'rgba(255,255,255,0.56)' }}
    //         style={{
    //           fontSize: "14px",
    //           paddingTop: "1px",
    //           // color: keepMeLoggedIn ? "#fff" : "rgba(255,255,255,0.56)",
    //         }}
    //       >
    //         Keep me logged in for 30 days
    //       </p>
    //     </div>
    //     <div></div>
    //     <div>
    //       <button className="Payment_Button mt-4 w-100" type="submit">
    //         {loading ? (
    //           <ReactLoading
    //             className="d-flex"
    //             type="spin"
    //             height="auto"
    //             width={20}
    //           />
    //         ) : (
    //           "Continue"
    //         )}
    //       </button>
    //       <p
    //         style={{
    //           paddingTop: "20px",
    //           paddingLeft: "20px",
    //           marginBottom: "0px",
    //         }}
    //       >
    //         <span className="span">Can't login.</span>
    //         <span
    //           className="text-wrapper-6"
    //           onClick={() => {
    //             setMfaMode("email");
    //             handleResendCode("email");
    //           }}
    //         >
    //           {" "}
    //           Try Another Way
    //         </span>
    //         <span className="span"> or</span>
    //         <span className="text-wrapper-6">
    //           {" "}
    //           <a
    //             href="https://radvix.io/support"
    //             target="_blank"
    //             rel="noopener noreferrer"
    //           >
    //             Contact Us
    //           </a>
    //         </span>
    //       </p>
    //     </div>
    //   </form>
    // </div>
    // </SignupBackground>
  );
};

export const MfaInput = ({
  code,
  onChange,
  containerStyle = {},
  inputStyle = {},
  labelStyle = {},
  label = 'Verification code',
}: {
  code: any;
  onChange: (otp: string) => void;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  label?: string;
}) => {
  return (
    <div className='d-flex flex-column'>
      {/* <label className="label mb-1" style={{ color: "#fff", ...labelStyle }}>
        {label}
      </label> */}
      <OTPInput
        value={code}
        onChange={onChange}
        numInputs={6}
        // renderSeparator={<span style={{color: '#fff'}}>-</span>}
        containerStyle={{
          gap: '0.5em',
          justifyContent: 'center',
          paddingTop: '10px',
          ...containerStyle,
        }}
        renderInput={(props) => (
          <input
            {...props}
            // className="w-100"
            style={{
              textAlign: 'center',
              border: '1px solid #adadad',
              // backgroundColor: 'transparent',
              // color: '#fff',
              borderRadius: '9px',
              display: 'flex',
              justifyContent: 'center',
              flex: '1 0 0',
              gap: 'var(--Spacing-0, 0px)',
              padding: 'var(--Spacing-0, 0px) var(--Spacing-3, 15px)',
              width: '2.5em',
              height: '3em',
              alignSelf: 'stretch',
              color: 'var(--Neutral-8, #B9BBC6)',
              fontSize: '17px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '160%',
              ...inputStyle,
            }}
          />
        )}
      />
    </div>
  );
};

export default withRouter(MfaPage);
