import { HTTP, handleUnauthorizedRequest } from "../../../core/http_common";
import { SortType } from "../../../core/number_extentions";
import { EquipmentCreateReq } from "../../models/requests/equipment/equipment_create_req";
import { EditEquipmentReq } from "../../models/requests/equipment/equipment_update_req";
import { EquipmentCreateRes } from "../../models/responses/equipment/equipment_create_res";
import { EquipmentSearchRes } from "../../models/responses/equipment/equipment_search_res";
import { EditEquipmentRes } from "../../models/responses/equipment/equipment_update_res";
import { GetAllEquipment } from "../../models/responses/equipment/get_all_equipment_res";
import { GetEquimentByIDRes } from "../../models/responses/equipment/get_equipment_by_id_res";

export class RemoteEquipment {
  createEquipment(
    body: EquipmentCreateReq,
    action: (res: EquipmentCreateRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/Equipment", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  updateEquipment(
    body: EditEquipmentReq,
    action: (res: EditEquipmentRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.put("/Equipment", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getAllEquipments(
    body: { PageNumber: number; PageSize: number; SearchParameter: string; columnHeaderName?: string; sortDirection?: SortType,selectedStatus?: any[], selectedLab?: any[] },
    action: (res: GetAllEquipment) => any,
    error: (res: any) => any
  ) {
    
    const status = body.selectedStatus ? body.selectedStatus : [];
  //const statusIds = status.map((item) => (typeof item === 'number' ? item : (item as { Id: any }).Id));
  const statusIds = Array.isArray(status)
  ? status.map((obj: { value: any }) => obj.value)
  : [];
  const lab = body.selectedLab ? body.selectedLab : [];
  const labIds = Array.isArray(lab)
  ? lab.map((obj: { value: any }) => obj.value)
  : [];
  //const labIds = lab.map((item) => (typeof item === 'number' ? item : (item as { Id: any }).Id));
   // const status = body.selectedStatus?.toString() === '31' ? 0 : (body.selectedStatus?.toString() === '32' ? '1' : '');
 
    return HTTP.get(
      `/Equipment?PageSize=${body.PageSize}&PageNumber=${body.PageNumber}&SearchParameter=${body.SearchParameter}&ColumnName=${body.columnHeaderName}&SortDirection=${body.sortDirection}&SelectedStatus=${statusIds}&SelectedLab=${labIds}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getEquipmentById(
    body: { equipmentId: number },
    action: (res: GetEquimentByIDRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(`/Equipment/${body.equipmentId}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  EquipmentsSearch(
    action: (res: EquipmentSearchRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.get("/Equipment/Search")
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  deleteEquipment(
    body: { equipmentId: number },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.delete(`/Equipment?equipmentId=${body.equipmentId}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
}
