import { toast } from "react-toastify";
import { UpdateResearchResResult } from "../../data/models/responses/research/update_research_req";
import { RemoteSeatManagement } from "../../data/remotes/seat_managment/remote_seatManagement";
import { UserInfoFilterReq } from "../../data/models/requests/seat_management/getData_req";
import { UpdateSeatMangementReq } from "../../data/models/requests/seat_management/update_seatMangement_req";
import { SeatManagementListRes } from "../../data/models/responses/seatManagement/get_list_res";

export class SeatManagementController {
  remote = new RemoteSeatManagement();

  
  update(
    body: UpdateSeatMangementReq[],
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.update(
      body,
      (res) => {
        action(res)
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  getList(
    body: UserInfoFilterReq,
    action: (res: SeatManagementListRes) => any,
    error: (err: any) => any
  ) {
    this.remote.getList(body,(res) => {      
      action(res.result);
    },(err) => error(err));
  }
  
}
