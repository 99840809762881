import React, { FC, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { MainButton, MainButtonType } from "./button";
import "tippy.js/dist/tippy.css";
import { CircleIcon, ThemeCircleIcon } from "./circle_icon";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import { useDebounceEffect } from "../../hooks/useDebounceEffect";
import "react-image-crop/dist/ReactCrop.css";

import Tippy from "@tippyjs/react";
import { ImageWithToken } from "./box_list_scroll";
import { toast } from "react-toastify";
const TO_RADIANS = Math.PI / 180;

interface GetPictureAndCropProps {
  //   onDrop?: (acceptedFiles: File[]) => void;
  //   onCrop?: () => void;
  //   onClear?: () => void;
  //   onUpload?: () => void;
  onRemove?: () => void;
  onSave?: (_pictures: File[]) => void;
  image?: string;
  loading?: boolean;
  disabled?: boolean;
  title?: string;
  picture?: string;
  defaultPicture?: string;
  style?: React.CSSProperties;
  originalImage?: string;
}

async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

const GetPictureAndCrop: FC<GetPictureAndCropProps> = ({
  onSave,
  picture,
  style,
  onRemove,
  defaultPicture = "/images/images/img_avatar.png",
  originalImage,
}) => {
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | undefined>(
    undefined
  );
  const [imgSrc, setImgSrc] = useState("");
  const [uploadedImgDetail, setUploadedImgDetail] = useState<any>();
  const scale = 1
  const rotate = 0
  const aspect = 1 / 1
  const [tippyVisible, setTippyVisible] = useState(false);

  // This is to demonstate how to make and center a % aspect crop
  // which is a bit trickier so we use some helper functions.
  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  const onDropPic = (_files: any) => {
    if (_files[0].size > 80000000) {
      toast.error("File size is too big, please upload a smaller file.")
      return
    }
    setCrop(undefined); // Makes crop preview update between images.
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setTippyVisible(true);
      setImgSrc(reader.result?.toString() || "");
      setUploadedImgDetail(_files[0]);
    });
    reader.readAsDataURL(_files[0]);
  };
  useEffect(() => {
    if (picture) {
      setImgSrc(picture);
    } else {
      setImgSrc("");
    }
  }, [picture]);
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );
  // console.log(uploadedImgDetail)
  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  const handleCancel = () => {
    setTippyVisible(false);
    setImgSrc(picture ? picture : "");
    setCompletedCrop(undefined);
  };

  const handleSave = () => {
    if (previewCanvasRef.current) {
      previewCanvasRef.current.toBlob(
        (blob: any) => {
          if (blob) {
            const newImage = new File(
              [blob],
              uploadedImgDetail?.name || "cropped.jpg",
              { type: uploadedImgDetail?.type || "image/jpeg" }
            );
            

            if (newImage.size > 80000000) {
              toast.error("Image size is too big")
              return
            }
            onSave && onSave([newImage]);

            setTippyVisible(false);
          }
        },
        "image/jpg",
        1
      );
    }

    // create an array of files and put the cropped image in it
    // const files = [
    //   new File(
    //     [
    //       previewCanvasRef.current?.toDataURL(uploadedImgDetail?.type, 1) ||
    //         imgSrc,
    //     ],
    //     uploadedImgDetail?.name || "cropped.jpg",
    //     {
    //       type: uploadedImgDetail?.type || "image/jpeg",
    //     }
    //   ),
    // ];
    // onSave && onSave(files);

    // setTippyVisible(false);
  };
  return (
    <div
      className="d-flex align-items-center mt-3 mt-sm-2 col-lg-12"
      style={{
        width:'100%',
        gap: "3px",
        ...style,
      }}
    >
      {!!completedCrop ? (
        <canvas
          ref={previewCanvasRef}
          style={{
            borderRadius: "50%",
            objectFit: "contain",
            //   width: completedCrop.width,
            //   height: completedCrop.height,
            width: "59.439px",
            height: "59.439px",
            // width: "6.5em",
            // height: "6.5em",
          }}
        />
      ) : (
        <div className="picture-crop-avatar-wrapper pe-xxl-2">
          {imgSrc ? (
            <div className="avatar-action d-flex justify-content-center">
              {/* <button>
                    edit
                </button>
                <button>
                    remove
                </button> */}
              <CircleIcon
                type={ThemeCircleIcon.dark}
                width="24px"
                height="24px"
                className="mx-2 pointer"
                padding="0"
                onClick={onRemove}
              >
                <img
                  src="/images/icons/garbage_can.svg"
                  alt="radvix"
                  width={12}
                  height={12}
                />
              </CircleIcon>
            </div>
          ) : (
            ""
          )}

          <ImageWithToken
            originalImage={originalImage}
            hasImage={imgSrc ? true : false}
            src={imgSrc ? imgSrc : defaultPicture}
            alt="Avatar"
            className="rounded-circle avatar"
            // width="7em"
            // height="125px"

            style={{
              // borderRadius: "50%",
              objectFit: "contain",
              opacity: imgSrc ? 1 : 0.5,
              width: "59.439px",
              height: "59.439px",
              // width: "6.71494em",
              // height: "6.71494em",
              backgroundColor: "rgb(177,177,177)",
              // backgroundImage: `url(${
              //   !imgSrc ? "/images/icons/user.svg" : picture
              // })`,
              backgroundSize: imgSrc ? "100%" : "70%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
          {/* <div
              style={{
                borderRadius: "50%",
                objectFit: "contain",
                opacity: imgSrc ? 1 : 0.5,
                width: "7em",
                height: "7em",
                backgroundColor: "rgb(177,177,177)",
                backgroundImage: `url(${
                  !imgSrc ? "/images/icons/user.svg" : picture
                })`,
                backgroundSize: imgSrc ? "100%" : "70%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            /> */}
        </div>
      )}
      <Tippy
        // visible={!!imgSrc}
        visible={tippyVisible}
        interactive
        arrow={false}
        placement="bottom-start"
        //   onClickOutside={toggleVisible}
        content={
          <div className="d-flex flex-column p-1">
            <label className="mb-2 textSecondary">Crop your image</label>
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              circularCrop={true}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
            {/* <div className="d-flex flex-column"> */}
            <p className="textSecondary mt-1 mb-0">
              Max file size: 10mb.
              {/* Accepted: jpg, png. */}
            </p>
            {/* </div> */}
            <div className="d-flex align-items-center justify-content-end mt-2">
              <MainButton
                type={MainButtonType.light}
                minHeight="30px"
                minWidth="80px"
                fontSize="14px"
                borderRadius="0.5em"
                backgroundColor="#fff"
                border="1px solid #707070"
                color="#000"
                onClick={handleCancel}
                className="btn mx-1 btn-primary fs-15 py-px-7 d-flex align-items-center gap-2 responsive-Button-width"
                children="Cancel"
              ></MainButton>
              <MainButton
                type={MainButtonType.dark}
                minHeight="30px"
                minWidth="90px"
                fontSize="14px"
                onClick={handleSave}
                borderRadius="0.5em"
                // backgroundColor="#fff"
                color="#fff"
                className="btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2 responsive-Button-width"
                children="Save"
              ></MainButton>
            </div>
          </div>
        }
      >
        <div className="p-px-12 bg-gray-200 border-gray-400 border rounded-px-4 gap-2 w-100">
  <div className="d-flexalign-items-center" style={{ height: "100%" }}>
    <Dropzone onDrop={onDropPic}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps({ className: "" })} style={{ height: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <input {...getInputProps()} />
          <div className='d-flex justify-content-between align-items-center flex-wrap row-gap-px-8'>
                              <button className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'>
                                <div className='d-flex align-items-center'>
                                  <div className='me-1 mb-1'>
                                    <svg
                                      width='15'
                                      height='15'
                                      viewBox='0 0 15 15'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <rect
                                        width='15'
                                        height='15'
                                        fill='white'
                                        fill-opacity='0.01'
                                      />
                                      <path
                                        fill-rule='evenodd'
                                        clip-rule='evenodd'
                                        d='M7.81825 1.18197C7.64251 1.00624 7.35759 1.00624 7.18185 1.18197L4.18185 4.18197C4.00611 4.35771 4.00611 4.64263
              4.18185 4.81837C4.35759 4.9941 4.64251 4.9941 4.81825 4.81837L7.05005 2.58657V9.50005C7.05005 9.74858 7.25152 9.95005
              7.50005 9.95005C7.74858 9.95005 7.95005 9.74858 7.95005 9.50005V2.58657L10.1819 4.81837C10.3576 4.9941 10.6425 4.9941
              10.8182 4.81837C10.994 4.64263 10.994 4.35771 10.8182 4.18197L7.81825 1.18197ZM2.5 10.0001C2.77614 10.0001 3 10.2239 3
              10.5001V12.0001C3 12.5539 3.44565 13.0001 3.99635 13.0001H11.0012C11.5529 13.0001 12 12.5529 12 12.0001V10.5001C12
              10.2239 12.2239 10.0001 12.5 10.0001C12.7761 10.0001 13 10.2239 13 10.5001V12.0001C13 13.1041 12.1062 14.0001 11.0012
              14.0001H3.99635C2.89019 14.0001 2 13.1031 2 12.0001V10.5001C2 10.2239 2.22386 10.0001 2.5 10.0001Z'
                                        fill='currentColor'
                                      />
                                    </svg>
                                  </div>
                                  Browse local file
                                </div>
                              </button>
                              <h3 className='fw-normal color-gray-700 fs-13 mb-0 flex-grow-1 text-center'>
                                {' '}
                                Or drag and drop files here - Max 128MB{' '}
                              </h3>
                            </div>
        </div>
      )}
    </Dropzone>
  </div>
</div>

        {/* <div className="ms-0 ms-sm-2 w-100 mt-2 mt-sm-0 p-px-12 bg-gray-200 border-gray-400 border rounded-px-4 gap-2" style={{ flexGrow: 1 }}>
  <Dropzone onDrop={onDropPic}>
    {({ getRootProps, getInputProps }) => (
      <section className="container fileUploadBox w-100 cropSectionBox">
        <div {...getRootProps({ className: "dropzone" })} style={{ textAlign: "center" }}>
          <MainButton
            type={MainButtonType.light}
            minHeight="30px"
            minWidth="100px"
            fontSize="14px"
            borderRadius="50px"
            backgroundColor="#fff"
            border="1px solid #707070"
            color="#707070"
            className="mt-4"
            children={
              <div className="d-flex justify-content-between align-items-center">
                <img
                  src="/Images/icons/cloud_computing.svg"
                  alt="sssss"
                  height="20"
                />
                <span className="flex-fill ms-2">Browse Local Files</span>
              </div>
            }
          />
          <p style={{ marginTop: "8px" }}>Or drag and drop files here</p>
          <input {...getInputProps()} multiple={false} style={{ display: "none" }} />
        </div>
      </section>
    )}
  </Dropzone>
</div> */}

      </Tippy>

      {/* {profileImage && profileImage.length > 2 ? (
        <React.Fragment>
          <img
            src={
              !this.state.cahngePic
                ? this.state.profileImage
                  ? AppConstants.base_url_image + this.state.profileImage
                  : "/images/icons/user.svg"
                : this.state.profileImage
            }
            alt="Avatar"
            className="rounded-circle avatar mx-2"
            width="125px"
            height="125px"
          />
          <CircleIcon
            type={ThemeCircleIcon.dark}
            width="30px"
            height="30px"
            className="mx-2 pointer"
            onClick={() => {
              this.setState({
                picture: [],
                profileImage: "",
              });
            }}
          >
            <img
              src="/images/icons/garbage_can.svg"
              alt="radvix"
              width={15}
              height={15}
            />
          </CircleIcon>
        </React.Fragment>
      ) : null}

      <Dropzone onDrop={onDropPic}>
        {({ getRootProps, getInputProps }) => (
          <section className="container fileUploadBox">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} multiple={false} />
              <MainButton
                type={MainButtonType.light}
                minHeight="30px"
                minWidth="179px"
                fontSize="14px"
                borderRadius="50px"
                backgroundColor="#fff"
                border="1px solid #707070"
                color="#707070"
                className="mt-4"
                children={
                  <div className="d-flex justify-content-between align-items-center">
                    <img
                      src="/Images/icons/cloud_computing.svg"
                      alt="sssss"
                      height="20"
                    />{" "}
                    <span className="flex-fill">Browse Local Files</span>
                  </div>
                }
              ></MainButton>
              <p>Or drag and drop files here</p>
            </div>
            <aside>
              <ul>{picture}</ul>
            </aside>
          </section>
        )}
      </Dropzone> */}
    </div>
  );
};
export default GetPictureAndCrop;
