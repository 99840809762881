import { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
// import { ButtonGroup } from "../../components/botton_group";
import { SelectComponent } from "../../components/select_input";
// import DatePicker from "react-datepicker";
import { MainButton, MainButtonType } from "../../components/button";
// import { LaboratoryController } from "../../../controllers/laboratory/laboratory_controller";
// import { EquipmentController } from "../../../controllers/equipment/equipment_controller";
import {
  GetEquipmentReservationReqBody,
  GetReservationSearchReqRes,
  getReservationSearch,
} from "../../../controllers/equipmentReservation";
// import { AppConstants } from "../../../core/constants";
import { DEFAULT_DAYS } from ".";
import { removeDuplicateObjWithKey } from "../../../core/utils";
import Tippy from "@tippyjs/react";
import TippyHeader from "../../components/TippyHeader";
import TippyFooter from "../../components/TippyFooter";
import { IoCalendar } from "react-icons/io5";
import { CircleIcon } from "../../components/circle_icon";
import { BiFilter } from "react-icons/bi";
import { MdClose } from "react-icons/md";

type Props = {
  onFilter: (_filterForm: GetEquipmentReservationReqBody) => void;
  onChangeFilterData: (_filterData: any) => void;
  filterDataDefault: any;
  loading?: boolean;
};

const FilterPanel = ({
  onFilter,
  onChangeFilterData,
  filterDataDefault,
  loading,
}: Props) => {
  // const laboratoryController = new LaboratoryController();
  // const equipmentController = new EquipmentController();
  const [originalLabsEq, setOriginalLabsEq] = useState<any>([]);
  // const [visible, setVisible] = useState(false);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [labsOptions, setLabsOptions] = useState<
    {
      value: number | null;
      label: string;
    }[]
  >([]);
  const [equipmentsOptions, setEquipmentsOptions] = useState<
    {
      value: number | null;
      label: string;
    }[]
  >([]);
  const [form, setForm] = useState({
    selectedTimeScale: 1,
    selectedLaboratory: {
      label: "All Laboratory",
      value: null,
    },
    selectedEquipment: {
      label: "All Equipment",
      value: null,
    },

    fromDate: new Date(new Date().setDate(new Date().getDate() - DEFAULT_DAYS)),
    toDate: new Date(new Date().setDate(new Date().getDate() + DEFAULT_DAYS)),
  });
  // const timeScales: any = {
  //   0: 1,
  //   1: 7,
  //   2: 30,
  // };
  // function handleChangeTimeScale(e: any) {
  //   const _selectedTimeScale = Number(e.target.value);

  //   // get date base on timeScales and set it to form
  //   const _toDate = new Date();
  //   _toDate.setDate(_toDate.getDate() + timeScales[_selectedTimeScale]);
  //   const _tmpVal = {
  //     ...form,
  //     selectedTimeScale: _selectedTimeScale,
  //     toDate: _toDate,
  //   };
  //   setForm(_tmpVal);
  //   onChangeFilterData(_tmpVal);
  // }

  useEffect(() => {
    handleGetFormData();
  }, []);
  // function getLaboratories() {
  //   return new Promise((resolve, reject) => {
  //     laboratoryController.getLaboratoryGetAll(
  //       {
  //         PageNumber: 1,
  //         PageSize: 1000,
  //         SearchParameter: "",
  //       },
  //       (_res) => {
  //         if (_res && _res.laboratories.length > 0) {
  //           const _labsOptions = _res.laboratories.map((laboratory: any) => {
  //             return {
  //               value: laboratory.id,
  //               label: laboratory.title,
  //             };
  //           });
  //           setLabsOptions([
  //             {
  //               value: null,
  //               label: "All Laboratory",
  //             },
  //             ..._labsOptions,
  //           ]);
  //           resolve(_labsOptions);
  //         } else {
  //           resolve([]);
  //         }
  //       },
  //       (_err) => {
  //         reject(_err);
  //       }
  //     );
  //   });
  // }
  // function getEquipments() {
  //   return new Promise((resolve, reject) => {
  //     equipmentController.getAllEquipments(
  //       {
  //         PageNumber: 1,
  //         PageSize: 1000,
  //         SearchParameter: "",
  //       },
  //       (_res) => {
  //         if (_res && _res.equipments.length > 0) {
  //           const _equipmentsOptions = _res.equipments.map((equipment: any) => {
  //             return {
  //               value: equipment.id,
  //               label: equipment.title,
  //             };
  //           });

  //           setEquipmentsOptions([
  //             {
  //               value: null,
  //               label: "All Equipment",
  //             },
  //             ..._equipmentsOptions,
  //           ]);
  //           resolve(_equipmentsOptions);
  //         } else {
  //           resolve([]);
  //         }
  //       },
  //       (_err) => {
  //         reject(_err);
  //       }
  //     );
  //   });
  // }
  async function handleGetFormData() {
    const _res = await getReservationSearch();
    
    // await getLaboratories();
    if (!_res) return;

    handleSetLabsAndEquipmentsOptions(_res);
    setOriginalLabsEq(_res);
  }
  function handleSetLabsAndEquipmentsOptions(_res: GetReservationSearchReqRes) {
    let _allLabs: any = [];
    let _allEquipments: any = _res.equipments.map((_eq) => ({
      label: _eq.title,
      value: _eq.id,
    }));

    _res.laboratories.forEach((_lab) => {
      _allLabs.push({
        label: _lab.title,
        value: _lab.id,
      });

      _allEquipments = [
        ..._allEquipments,
        ..._lab.equipments.map((_i: any) => ({
          label: _i.title,
          value: _i.id,
        })),
      ];
    });

    const _allEquipmentsTrim = removeDuplicateObjWithKey(
      _allEquipments,
      "value"
    );
    const _allLabsTrim = removeDuplicateObjWithKey(_allLabs, "value");

    setLabsOptions([
      {
        value: null,
        label: "All Laboratory",
      },
      ..._allLabsTrim,
    ]);
    setEquipmentsOptions([
      {
        value: null,
        label: "All Equipment",
      },
      ..._allEquipmentsTrim,
    ]);
  }

  function handleChangeForm(_val: any, key: string) {
    setForm((prev) => ({
      ...prev,
      [key]: _val,
    }));
  }

  useEffect(() => {
    setForm(filterDataDefault);
  }, [filterDataDefault]);
  return (
    <div
      className="d-flex align-items-center justify-content-between mb-2 "
      style={{
        borderRadius: "0.8em",
        // overflow: "hidden",
      }}
    >
      <div
        className="d-flex align-items-center p-2"
        style={{
          borderRadius: "0.6em 0 0 0.6em",
        }}
      >
        <IoCalendar size="1.6em" />
        <span className="ms-2 whiteSpaceNoWrap">Equipment schedule</span>
      </div>
      <div className="dFlexTablet dNone">
        <Tippy onClickOutside={() => setVisibleFilter(false)}
          visible={visibleFilter}
          interactive={true}
          content={
            <div className="d-flex flex-column" style={{ width: '20em', maxWidth: '100%' }}>
              <TippyHeader
                onClose={() => setVisibleFilter(false)}
                title={
                  <div className="d-flex align-items-center">
                    <FaFilter className="me-2" size="1em" />
                    <span>Filter Reservation</span>
                  </div>
                }
              />
              <div className="item">
                <SelectComponent
                  defaultValue={form.selectedLaboratory}
                  items={labsOptions}
                  TextItem="name"
                  ValueItem="id"
                  // className="my-2"
                  placeholder="Select laboratory.."
                  // label=""
                  onChange={(e) => {
                    handleChangeForm(e, "selectedLaboratory");

                    if (e.value !== null) {
                      const _selectedLabInOriginal = originalLabsEq.laboratories.find(
                        (_lab: any) => _lab.id === e.value
                      );
                      const _tmpOpt: any = _selectedLabInOriginal.equipments?.map(
                        (_eq: any) => ({
                          label: _eq.title,
                          value: _eq.id,
                        })
                      );
                      setEquipmentsOptions([
                        {
                          value: null,
                          label: "All Equipments",
                        },
                        ..._tmpOpt,
                      ]);
                      handleChangeForm(
                        {
                          label: "All Equipments",
                          value: null,
                        },
                        "selectedEquipment"
                      );
                    } else {
                      handleSetLabsAndEquipmentsOptions(originalLabsEq);
                    }
                  }}
                ></SelectComponent>
              </div>
              <div className="item">
                <SelectComponent
                  defaultValue={form.selectedEquipment}
                  items={equipmentsOptions}
                  TextItem="name"
                  ValueItem="id"
                  placeholder="Click to see the list…"
                  onChange={(e) => {
                    handleChangeForm(e, "selectedEquipment");
                  }}
                ></SelectComponent>
              </div>

              {/* <div
            className="d-grid align-items-center mt-3"
            style={{
              gridTemplateColumns: "5ch 1fr",
            }}
          >
            <span>From</span>
            <DatePicker
              selected={form.fromDate}
              onChange={(e) => {
                handleChangeForm(e, "fromDate");
              }}
              // maxDate={
              //   this.state.isLockEndTime
              //     ? undefined
              //     : new Date(
              //         this.state.endDate?.getTime() - AppConstants.one_day_milis
              //       )
              // }
            />
          </div>
          <div
            className="d-grid align-items-center mt-3"
            style={{
              gridTemplateColumns: "5ch 1fr",
            }}
          >
            <span>Until</span>
            <DatePicker
              selected={form.toDate}
              onChange={(e) => {
                handleChangeForm(e, "toDate");
              }}
              minDate={
                new Date(form.fromDate.getTime() + AppConstants.one_day_milis)
              }
            />
          </div> */}

              <TippyFooter
                confirmText="Apply Filter"
                confirmLoading={loading}
                onCancel={() => setVisibleFilter(false)}
                onConfirm={() => {
                  onFilter({
                    LaboratoryId: form.selectedLaboratory.value,
                    EquipmentId: form.selectedEquipment.value,
                    From: form.fromDate.toISOString(),
                    To: form.toDate.toISOString(),
                    // IsUsersAllEquipmentsReservation: true, // need to be developed
                    // IsUsersAllLabsEquipmentsReservation: true, // need to be developed
                  });

                  onChangeFilterData(form);
                }}
              />
            </div>
          }
          arrow={false}
          placement="bottom">
            <span>
          <CircleIcon
            onClick={() => setVisibleFilter(!visibleFilter)}
            children={visibleFilter ? <MdClose size='1.5em' /> : <BiFilter size='1.5em' />}
            height="2em"
            width="2em"
            padding="0"
          ></CircleIcon>
          </span>
        </Tippy>
      </div>
      <div
        className="d-flex align-items-center p-0 p-sm-2 dNoneTablet"
      // style={{
      //   borderRadius: "0 0.6em 0.6em 0",
      // }}
      >
        {/* <label className="me-3">
          Filters
        </label> */}
        {/* <div className="d-flex flex-column"> */}
        {/* <div className="item mt-0">
          <ButtonGroup
            name="SendTo"
            // label="Send To:"
            items={[
              { label: "Daily", value: 0 },
              { label: "Weekly", value: 1 },
              { label: "Monthly", value: 2 },
            ]}
            TextItem="label"
            ValueItem="value"
            inputType={"checkbox"}
            selected={form.selectedTimeScale}
            onChange={handleChangeTimeScale}
          ></ButtonGroup>
        </div> */}
        <div className="item mt-0 responsiveEqFilterItem">
          <SelectComponent
            defaultValue={form.selectedLaboratory}
            items={labsOptions}
            TextItem="name"
            ValueItem="id"
            // className="my-2"
            placeholder="Select laboratory.."

            // label=""
            onChange={(e) => {
              // console.log(originalLabsEq);

              handleChangeForm(e, "selectedLaboratory");

              if (e.value !== null) {
                const _selectedLabInOriginal = originalLabsEq.laboratories.find(
                  (_lab: any) => _lab.id === e.value
                );
                const _tmpOpt: any = _selectedLabInOriginal.equipments?.map(
                  (_eq: any) => ({
                    label: _eq.title,
                    value: _eq.id,
                  })
                );
                setEquipmentsOptions([
                  {
                    value: null,
                    label: "All Equipments",
                  },
                  ..._tmpOpt,
                ]);
                handleChangeForm(
                  {
                    label: "All Equipments",
                    value: null,
                  },
                  "selectedEquipment"
                );
              } else {
                handleSetLabsAndEquipmentsOptions(originalLabsEq);
              }
            }}
            selectProps={{
              menuPosition: "fixed",
              menuPlacement: "auto",
              menuPortalTarget: document.body,
              styles: {
                menuPortal: (base: any) => ({ ...base, width: "20em" }),
              },
            }}
          ></SelectComponent>
        </div>
        <div className="item mt-0 ms-2 responsiveEqFilterItem">
          <SelectComponent
            defaultValue={form.selectedEquipment}
            items={equipmentsOptions}
            TextItem="name"
            ValueItem="id"
            placeholder="Click to see the list…"
            onChange={(e) => {
              handleChangeForm(e, "selectedEquipment");
            }}
            selectProps={{
              menuPosition: "fixed",
              menuPlacement: "auto",
              menuPortalTarget: document.body,
              styles: {
                menuPortal: (base: any) => ({ ...base, width: "20em" }),
              },
            }}
          ></SelectComponent>
        </div>

        <div className="d-flex align-items-center">
          {/* <MainButton
              type={MainButtonType.light}
              minHeight="2.5em"
              fontSize="15px"
              borderRadius="1em"
              disabled={loading}
              className="px-3 w-100"
              onClick={() => {}}
              children="Reset"
            ></MainButton> */}
          <MainButton
            type={MainButtonType.dark}
            disabled={loading}
            minHeight="2.5em"
            fontSize="15px"
            borderRadius="1em"
            className="px-3 w-100 ms-1"
            onClick={() => {
              onFilter({
                LaboratoryId: form.selectedLaboratory.value,
                EquipmentId: form.selectedEquipment.value,
                From: form.fromDate.toISOString(),
                To: form.toDate.toISOString(),
                // IsUsersAllEquipmentsReservation: true, // need to be developed
                // IsUsersAllLabsEquipmentsReservation: true, // need to be developed
              });

              onChangeFilterData(form);
            }}
            children="Filter"
          ></MainButton>
        </div>
        {/* <div
          className="ms-1"
          style={{
            maxWidth: "8em",
          }}
        >
          <DatePicker
            selected={form.fromDate}
            onChange={(e) => {
              handleChangeForm(e, "fromDate");
            }}
          />
        </div>
        <div
          className="ms-1"
          style={{
            maxWidth: "8em",
          }}
        >
          <DatePicker
            selected={form.toDate}
            onChange={(e) => {
              handleChangeForm(e, "toDate");
            }}
            minDate={
              new Date(form.fromDate.getTime() + AppConstants.one_day_milis)
            }
          />
        </div> */}

        {/* </div> */}
        {/* <TippyFooter
                confirmText="Apply Filter"
                confirmLoading={loading}
                onCancel={() => setVisible(false)}
                onConfirm={() => {
                  onFilter({
                    LaboratoryId: form.selectedLaboratory.value,
                    EquipmentId: form.selectedEquipment.value,
                    From: form.fromDate.toISOString(),
                    To: form.toDate.toISOString(),
                    // IsUsersAllEquipmentsReservation: true, // need to be developed
                    // IsUsersAllLabsEquipmentsReservation: true, // need to be developed
                  });

                  onChangeFilterData(form);
                }}
              /> */}
      </div>
    </div>
  );
};

export default FilterPanel;
