import { BaseButton } from '@fluentui/react';
import { AppRoutes } from '../../../../core/constants';
import { base_url_site } from '../../../../core/localVariables';
import { UserController } from '../../../../controllers/user/user_controller';
import { toast } from 'react-toastify';

export const SignUpCheckMail = (props: any) => {
  const { Email } = props;
  const controller: UserController = new UserController();

  const handleResendEmail = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    controller.resendEmailVerfication(
      {
        email: Email,
      },
      (res) => {
        toast.info('Your email verification email has been sent.');
      },
      (err) => {}
    );
  };
  return (
    <div className='container'>
      <div className='max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center vh-100'>
        <div className='text-center mb-3'>
          <img
            src='/images/icons/new-radvix-logo.png'
            width={111}
            height={49}
            // src='images/icons/radvix-logo.svg'
            // srcSet='images/icons/radvix-logo.svg 111w'
            // sizes='(max-width: 768px) 20vw, 10vw'
            alt='Company logo'
          />
        </div>
        <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column loginDivBorder'>
          <div className='d-flex w-100'>
            <BaseButton
              className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
              style={{ fontWeight: '400', height: '19px' }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <div className='verifyingAccount'>
              <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>Check your inbox</h1>
            </div>
          </div>
          <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
          <div style={{ padding: '6px 0px 6px 0px' }}>
            <h4
              className='fs-15'
              style={{
                textAlign: 'center',
                fontSize: '13px',
                fontWeight: '400',
              }}
            >
              We have sent you an email with a link to verify <br />
              your account
            </h4>
            <h4
              className='fs-15'
              style={{
                textAlign: 'center',
                fontSize: '13px',
                fontWeight: '400',
              }}
            >
              If you haven’t received the email within next 5 <br /> minutes, you can{' '}
              <span
                style={{ color: '#5746AF', cursor: 'pointer' }}
                onClick={(e) => handleResendEmail(e)}
              >
                request a new one
              </span>
            </h4>
          </div>

          <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
          <div className='fs-14 fw-normal'>
            <a
              className='color-gray-600 cursor-pointer'
              href={`${base_url_site}/terms-of-services`}
              rel='noopener noreferrer'
              target='_blank'
            >
              Terms of Service |{' '}
            </a>
            <a
              className='color-gray-600 cursor-pointer'
              href={`${base_url_site}/privacy-policy`}
              rel='noopener noreferrer'
              target='_blank'
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
    // <>
    // <div style={{ display: "flex", justifyContent: "center" }}>
    //       <div
    //         className="overlap-confirmation"
    //         style={{
    //           background:
    //             "linear-gradient(360deg, rgba(212, 15, 237, 0) 0%, rgba(212, 15, 237, 1) 100%)",
    //         }}
    //       >
    //         {/* <div>
    //                             <img className="group-6" alt="Group" src="/images/icons/confirmation-page.svg" />
    //                         </div> */}
    //         <div
    //           className="d-flex flex-column justify-content-center"
    //           style={{ alignItems: "center", paddingTop: "13px" }}
    //         >
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="78"
    //             height="29"
    //             viewBox="0 0 78 29"
    //             fill="none"
    //           >
    //             <path
    //               fill-rule="evenodd"
    //               clip-rule="evenodd"
    //               d="M75.5561 4.71586L64.5351 14.2909L75.5561 23.7567V4.71586ZM54.2039 19.272L50.3337 15.9125L38.5745 26.0143H74.4255L62.6609 15.9107L58.7907 19.2702L58.7961 19.2666C58.1815 19.7862 57.3896 20.0998 56.5224 20.0998H56.4991H56.4686C55.6032 20.0998 54.8113 19.788 54.2039 19.272ZM74.447 2.44573H38.5584L55.7985 17.4175L55.7967 17.4157C55.9907 17.5703 56.2314 17.6543 56.4794 17.654H56.5027H56.5278C56.7749 17.6542 57.0148 17.5708 57.2086 17.4175L74.447 2.44573ZM48.4649 14.2891L37.4457 4.71407V23.7495L48.4649 14.2891ZM74.6763 0C75.212 0 75.7173 0.125422 76.1473 0.340431H76.1366L76.1563 0.34939C77.2493 0.892287 78 2.02108 78 3.32368V25.1328C77.9991 26.0154 77.648 26.8616 77.0239 27.4857C76.3998 28.1098 75.5536 28.4609 74.6709 28.4618H38.3291C37.4464 28.4609 36.6002 28.1098 35.9761 27.4857C35.352 26.8616 35.0009 26.0154 35 25.1328V3.32368C35.0005 2.7035 35.1744 2.09582 35.5021 1.56929C35.8298 1.04276 36.2982 0.61835 36.8545 0.344014L36.8347 0.352973C37.2994 0.120962 37.8115 0.000124051 38.3308 0H74.6763ZM10 8C9.44769 8 9 8.44771 9 9C9 9.55229 9.44769 10 10 10H30C30.5523 10 31 9.55229 31 9C31 8.44771 30.5523 8 30 8H10ZM10 20C9.44769 20 9 20.4477 9 21C9 21.5523 9.44769 22 10 22H30C30.5523 22 31 21.5523 31 21C31 20.4477 30.5523 20 30 20H10ZM0 15C0 14.4477 0.447693 14 1 14H21C21.5523 14 22 14.4477 22 15C22 15.5523 21.5523 16 21 16H1C0.447693 16 0 15.5523 0 15Z"
    //               fill="url(#paint0_linear_478_279)"
    //             />
    //             <defs>
    //               <linearGradient
    //                 id="paint0_linear_478_279"
    //                 x1="-1.33311e-07"
    //                 y1="2.5593"
    //                 x2="83.1457"
    //                 y2="14.1559"
    //                 gradientUnits="userSpaceOnUse"
    //               >
    //                 <stop stop-color="#4DA4F9" />
    //                 <stop offset="0.47" stop-color="#7523FE" />
    //                 <stop offset="1" stop-color="#ED0AE8" />
    //               </linearGradient>
    //             </defs>
    //           </svg>
    //         </div>
    //         <div className="confirmation-top">Check Your Inbox!</div>
    //         <div className="verfiy-message-sign-up">
    //           <span className="spanText">
    //             We have sent you an email with a link to verify{" "}
    //           </span>
    //           <br />
    //           <span className="spanText">your account</span>
    //         </div>
    //         <br></br>
    //         <div className="verfiy-messag">
    //           <span className="spanText">
    //             If you haven’t received the email within next 5{" "}
    //           </span>
    //           <br />
    //           <span className="spanText">minutes, you can </span>
    //           <span className="spanText">
    //             <span
    //               onClick={(e) => {
    //                 props.onClick();
    //               }}
    //               style={{ color: "#0d6efd", cursor: "pointer" }}
    //             >
    //               request a new one
    //             </span>
    //           </span>
    //         </div>
    //       </div>
    //     </div>
    // </>
  );
};
