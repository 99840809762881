import React from 'react';
import ReactPaginate from 'react-paginate';
import { store } from '../../../data/storeMain';
import { MainButton, MainButtonType } from '../../components/button';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
import { InputIcon } from '../../components/search_box';
import { SelectComponent } from '../../components/select_input';
// import AcordienTable from "./component/recent_teams";
import { withRouter, RouteComponentProps } from 'react-router';
import { AppRoutes } from '../../../core/constants';
import { Team } from '../../../data/models/responses/team/get_all_teams_res';
import { TeamController } from '../../../controllers/team/team_controller';
import debounce from 'lodash.debounce';
// import Highlighter from "react-highlight-words";
import {
  $ReplaceRoute,
  AccessPermition,
  getUserInitails,
  // getUserRole,
  isTokenInHttpAuth,
  UserRoles,
} from '../../../core/utils';
// import { EmptyState } from "../../components/empty_state";
import { HTTP } from '../../../core/http_common';
// import NewTable from "../../components/NewTable/NewTable";
import CollapsableTable from '../../components/CollapsableTable/CollapsableTable';
import { MdAdd, MdFolder, MdPeople } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import { RxMagnifyingGlass } from 'react-icons/rx';
import PhoneSearch from '../../components/PhoneSearch';
import UserWithAvatar from '../../components/UserWithAvatar';
// import UsersWithImage from "../laboratory/component/usersWithImage";
import GroupedAvatars from '../../components/groupedAvatars';
import { SortType, TeamType } from '../../../core/number_extentions';
import FilterSection from '../../components/FilterSection/filterSection';
import { IFilterOptions } from '../../components/FilterSection/IFilterOptions';
import DOMPurify from 'dompurify';
import SelectRadix from '../../components/SelectRadix/selectRadix';
import { DropdownMenu, Table } from '@radix-ui/themes';
import RadixTable from '../../components/NewRadixTable/RadixTable';
import { BaseButton } from '@fluentui/react';
import ActionComponent from '../../components/ActionComponent/Actions';
import Pagination from '../../components/NewRadixTable/RadixPagination';
import { ImageWithToken } from '../../components/box_list_scroll';
import { SetHeaderTitle } from '../../../data/storeMain/actions/header_title_action';
type StateType = {
  Teams: Team[];
  PageNumber: number;
  PageSize: { value: number; label: string };
  PageCount: number;
  TotalCount: number;
  Search: string;
  loading: boolean;
  HeaderName: string;
  SortDirection: SortType;
  showFilterOptions: boolean;
  filterOptions: IFilterOptions;
  PageNumberInput: string;
};
class TeamPage extends React.Component<RouteComponentProps> {
  RoleUser = store.getState().userRole;
  controller = new TeamController();
  state: StateType = {
    Teams: [],
    PageNumber: 1,
    PageSize: { value: 10, label: '10' },
    PageCount: 0,
    TotalCount: 0,
    Search: '',
    loading: true,
    HeaderName: '',
    SortDirection: SortType.AutoSelect,
    showFilterOptions: false,
    filterOptions: {},
    PageNumberInput: '',
  };
  constructor(props: any) {
    super(props);
    this.handlePageNumberInput = this.handlePageNumberInput.bind(this);
    this.handleChangeTeamSearch = debounce(this.handleChangeTeamSearch.bind(this), 500);
  }
  componentDidMount() {
    document.title = `Teams | Radvix`;
    store.dispatch(SetHeaderTitle('Team'));
    if (!isTokenInHttpAuth(HTTP)) return;
    this.GetTeams(this.state.PageNumber, this.state.PageSize.value);
  }
  GetTeams(
    PageNumber: number,
    PageSize: number,
    Search?: string,
    columnHeaderName?: string,
    sortDirection?: SortType,
    filterOptions?: IFilterOptions
  ) {
    this.setState({
      loading: true,
    });
    if (sortDirection == undefined || sortDirection == null) {
      sortDirection = SortType.AutoSelect;
    }
    this.controller.getAllTeams(
      {
        pageNumber: PageNumber,
        pageSize: PageSize,
        SearchParameter: Search ? Search : this.state.Search,
        columnHeaderName,
        sortDirection,
        filterOptions,
      },
      (res) => {
        // const _userRole = getUserRole();

        // let team: any[] = [];
        // if (_userRole === UserRoles.L2User) {
        //   team = res.teams.map((item) => {
        //     return {
        //       ...item,
        //       subTeams: res.subTeams.filter((sub) => sub.parentId === item.id),
        //     };
        //   });
        // }
        // if (
        //   _userRole === UserRoles.L1Client ||
        //   _userRole === UserRoles.L1User
        // ) {
        //   team = res.teams;
        // }
        // if (_userRole === UserRoles.L3User) {
        //   team =
        //     res?.subTeams.map((_sub: any) => {
        //       return {
        //         ..._sub,
        //         creatorUserFirstName: _sub.creatorFirstName,
        //         creatorUserId: _sub.creatorId,
        //         creatorUserLastName: _sub.creatorLastName,
        //         memberCount: _sub.subTeamMemberCount,
        //         teamType: "Subteam",
        //       };
        //     }) || [];
        // }
        debugger;
        this.setState({
          // Teams: team,
          Teams: res.teams,
          PageCount: Math.ceil(res.teamCount! / this.state.PageSize.value),
          TotalCount: res.teamCount,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
  }
  filterClick = (
    selectedFromDate?: Date | null,
    selectedToDate?: Date | null,
    status?: any[],
    teamType?: number[]
  ) => {
    var filterOptions = {
      selectedFromDate: selectedFromDate,
      selectedToDate: selectedToDate,
      status: status,
      teamType: teamType,
    };
    this.setState({ filterOptions: filterOptions });
    this.GetTeams(
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      filterOptions
    );
  };
  handleToggle = () => {
    this.setState({ showFilterOptions: !this.state.showFilterOptions });
  };
  handelChangePageNumber(e: { selected: number }) {
    this.setState({
      PageNumber: e.selected,
      PageNumberInput: e.selected + 1,
    });
    this.GetTeams(
      e.selected + 1,
      this.state.PageSize.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      this.state.filterOptions
    );
  }
  handelChangePageSize(e: { label: string; value: number }) {
    this.setState({
      PageSize: e,
    });
    this.GetTeams(
      this.state.PageNumber,
      e.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      this.state.filterOptions
    );
  }
  handleViewDetails = (id: any) => {
    this.props.history.push(
      $ReplaceRoute(`${AppRoutes.team_profile.replace(':id', id.toString())}`)
    );
  };

  handleEdit = (id: any) => {
    this.props.history.push(
      $ReplaceRoute(`${AppRoutes.team_edit.replace(':id', id.toString() ?? '')}`)
    );
  };
  handlePageNumberInput(event: { target: { value: any } }) {
    if (event.target.value > 0) {
      this.setState({ PageNumberInput: event.target.value });
    } else {
      this.setState({ PageNumberInput: '' });
    }
  }
  handleGoToPage() {
    const pageNumber = parseInt(this.state.PageNumberInput, 10);
    if (pageNumber > 0 && pageNumber <= this.state.PageCount) {
      this.handelChangePageNumber({ selected: pageNumber - 1 });
    } else {
      console.log('Invalid page number');
    }
  }
  handelChnagePageSize(e: string) {
    this.setState({
      PageSize: e,
    });
    this.GetTeams(
      this.state.PageNumber,
      parseInt(e),
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      this.state.filterOptions
    );
  }
  handleChangeTeamSearch(e: any) {
    const inputValue = e.target.value;
    const sanitizedValue = DOMPurify.sanitize(inputValue);
    this.GetTeams(this.state.PageNumber, this.state.PageSize.value, sanitizedValue);
  }
  headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    this.setState({ HeaderName: columnHeaderName });
    this.setState({ SortDirection: sortDirection });
    this.GetTeams(
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.Search,
      columnHeaderName,
      sortDirection,
      this.state.filterOptions
    );
  };
  render() {
    return (
      <div className='card'>
        <div className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap team-column'>
          <div className='d-flex align-items-center'>
            <BaseButton
              className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none project-back-button'
              style={{ fontWeight: '400', height: '19px' }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>My Team</h2>
          </div>
          <div className='relative TopTableBox d-flex flex-md-row justify-content-end align-items-md-start'>
            <div className='px-1 project-back-button'>
              <MainButton
                icon={<img src='/Images/icons/avatar.svg' alt='sssss' height='20' />}
                children='All users'
                type={MainButtonType.dark}
                minWidth='100px'
                //borderRadius="24px"
                fontSize='14px'
                minHeight='2em'
                className='btn btn-primary fs-15 py-px-7'
                style={{
                  backgroundColor: '#6E56CF',
                  color: '#FFFFFF',
                }}
                onClick={() => {
                  this.props.history.push($ReplaceRoute(AppRoutes.member));
                }}
              ></MainButton>
            </div>
            {AccessPermition(this.RoleUser, [
              UserRoles.Admin,
              UserRoles.L1Client,
              UserRoles.L1User,
            ]) ? (
              <div className='px-1'>
                <MainButton
                  icon={<img src='/Images/icons/plus.svg' alt='sssss' height='20' />}
                  children='Create new team'
                  type={MainButtonType.dark}
                  //borderRadius="24px"
                  fontSize='14px'
                  // className="my-2 mx-2"
                  minHeight='2em'
                  className='btn btn-primary fs-15 py-px-7 textWrapLabel'
                  minWidth='100px'
                  onClick={() => {
                    this.props.history.push($ReplaceRoute(AppRoutes.new_team));
                  }}
                ></MainButton>
              </div>
            ) : this.RoleUser === UserRoles.L2User ? (
              <div className='p-1'>
                <MainButton
                  icon={<img src='/Images/icons/plus.svg' alt='sssss' height='20' />}
                  children='Create new Subteam'
                  type={MainButtonType.dark}
                  borderRadius='24px'
                  fontSize='14px'
                  // className="my-2 mx-2"
                  minHeight='2em'
                  className='btn btn-primary fs-15 py-px-7'
                  minWidth='100px'
                  onClick={() => {
                    this.props.history.push($ReplaceRoute(AppRoutes.new_team));
                  }}
                ></MainButton>
              </div>
            ) : null}
          </div>
        </div>
        {/* <div
          className="d-flex flex-xl-row flex-column row-gap-xl-0 row-gap-px-16 px-3 pb-3 filters" id="showFilter">          
            <RadixFilter
              searchValue={this.state.Search}
              onSearchChange={(e) => {
                this.setState({ Search: e.target.value });
                this.handleChangeResearchSearch(e);
              }}
              onPriorityChange={this.handlePriorityChange}
              statusOptions={this.statusOptions}
              priorityOptions={this.priorityOptions}
              onStatusChange={this.handleStatusChange}
              onselect={(
                selectedFromDate,
                selectedToDate,
                status,
                selectedPriority
              ) =>
                this.filterClick(
                  selectedFromDate,
                  selectedToDate,
                  status,
                  selectedPriority,
                  this.state.Search
                )
              }
              onStartDateChange={this.handleStartDateChange}
              onEndDateChange={this.handleEndDateChange}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
        </div> */}

        <div
          className='d-flex flex-xl-row flex-column row-gap-xl-0 row-gap-px-16 px-3 pb-3 filters'
          id='showFilter'
        >
          <div className='pe-xl-3 border-gray-400 d-sm-block d-flex gap-3 col-lg-3'>
            <InputIcon
              className='form-control h-px-35'
              placeholder='Search your team here'
              onChange={(e) => {
                this.setState({ Search: e.target.value });
                this.handleChangeTeamSearch(e);
              }}
              chilren={undefined}
            />
          </div>
        </div>

        <RadixTable
          data={this.state.Teams}
          loading={this.state.loading}
          onHeaderClick={(columnHeaderName: string, sortDirection: SortType) =>
            this.headerClick(columnHeaderName, sortDirection)
          }
          columns={[
            {
              Header: 'Team Name',
              accessor: 'title',
              Cell: (_props: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <div className='line-clamp-2 textWrapLabel'>
                      <i className='fa fa-grip-vertical me-2 color-gray-600 fs-13'></i>
                      <span
                        onClick={() => {
                          this.props.history.push(
                            $ReplaceRoute(
                              `${AppRoutes.team_profile.replace(
                                ':id',
                                _props.id?.toString() ?? ''
                              )}`
                            )
                          );
                        }}
                        title={_props.title}
                      >
                        {' '}
                        {_props.title}{' '}
                      </span>
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Coordinator',
              accessor: 'creatorfirstname',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <div className='d-flex align-items-center text-nowrap'>
                      {props.creatorUser.image === null ||
                      props.creatorUser.image === '' ||
                      props.creatorUser.image === undefined ? (
                        <div className='avatar w-px-25 h-px-25 rounded-circle bg-primary'>
                          <label className='fw-medium text-white fs-10'>
                            {getUserInitails(
                              `${props.creatorUser.firstName} ${props.creatorUser.lastName}`
                            )}
                          </label>
                        </div>
                      ) : (
                        <ImageWithToken
                          originalImage={props.creatorUser.image}
                          hasImage={props.creatorUser.image ? true : false}
                          alt='Avatar'
                          className='rounded-circle avatar rounded-avatar-dashboard'
                          src={
                            props.creatorUser.image === null ||
                            props.creatorUser.image === '' ||
                            props.creatorUser.image === undefined
                              ? '/images/images/img_avatar.png'
                              : props.creatorUser.image
                          }
                        />
                      )}
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          this.props.history.push(
                            $ReplaceRoute(
                              `${AppRoutes.member_profile.replace(
                                ':id',
                                props.creatorUser.id?.toString() ?? ''
                              )}`
                            )
                          );
                        }}
                      >
                        {props.creatorUser.firstName} {props.creatorUser.lastName}
                      </span>
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Users',
              headerCellClassName: 'dNoneSm',
              accessor: 'memberCount',
              cellClassName: 'dNoneSm',
              // align: "center",
              Cell: (_row: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <GroupedAvatars
                      containerWidth={100}
                      maxAvatars={2}
                      tippyContent={
                        <div className='d-flex flex-column py-2 pb-0'>
                          {_row.members.map((_us: any) => (
                            <UserWithAvatar
                              parentStyle={{
                                marginBottom: '0.25em',
                              }}
                              key={_us.id}
                              user={_us}
                            />
                          ))}
                        </div>
                      }
                      users={_row.members}
                    />
                  </Table.Cell>
                );
              },
            },
            {
              Header: '',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3 text-nowrap'>
                    <div className='d-flex align-items-center gap-3'>
                      {/* <ActionComponent
                            id={props.id}
                            options={[
                              {
                                label: "View Details",
                                onClick: () =>
                                  this.handleViewDetails(props.id),
                              },
                              {
                                label: "Edit",
                                onClick: () => this.handleEdit(props.id),
                              },
                            ]}
                          /> */}

                      <div className='btn-group'>
                        <button
                          type='button'
                          className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleViewDetails(props.id);
                          }}
                        >
                          <i className='fa fa-eye me-px-6'></i>View Details
                        </button>

                        <DropdownMenu.Root>
                          <DropdownMenu.Trigger>
                            <button
                              type='button'
                              className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                            >
                              <span className='visually-hidden'>Toggle Dropdown</span>
                            </button>
                          </DropdownMenu.Trigger>
                          <DropdownMenu.Content>
                            <DropdownMenu.Item className='px-0'>
                              <button
                                className='dropdown-item fs-14'
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleViewDetails(props.id);
                                }}
                              >
                                View Details
                              </button>
                            </DropdownMenu.Item>
                            <DropdownMenu.Item className='px-0'>
                              <button
                                className='dropdown-item fs-14'
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleEdit(props.id);
                                }}
                              >
                                Edit Team
                              </button>
                            </DropdownMenu.Item>
                          </DropdownMenu.Content>
                        </DropdownMenu.Root>
                      </div>
                    </div>
                  </Table.Cell>
                );
              },
            },
          ]}
        />
        {this.state.PageCount > 0 && (
          <Pagination
            pageCount={this.state.PageCount}
            onPageChange={this.handelChangePageNumber.bind(this)}
            onPageSizeChange={(e: any) => {
              this.handelChnagePageSize(e);
            }}
            pageInput={this.state.PageNumberInput}
            handlePageNumberInput={this.handlePageNumberInput}
          />
        )}
      </div>
    );
  }
}

export default withRouter(TeamPage);
