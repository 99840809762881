import React from "react";
import { store } from "../../../data/storeMain";
import { InputComponent, InputType } from "../../components/inputs";
import "react-datepicker/dist/react-datepicker.css";
import { MainButton, MainButtonType } from "../../components/button";
import { SelectComponent } from "../../components/select_input";
import { RouteComponentProps, withRouter } from "react-router";
import { AppRoutes } from "../../../core/constants";
import { TeamController } from "../../../controllers/team/team_controller";
import SimpleReactValidator from "simple-react-validator";
import {
  $ReplaceRoute,
  AccessPermition,
  convertEquipsToUncategorized,
  convertLabEquipsToNewTree,
  convertLabEquipToTree,
  // convertTeamUserToNewTree,
  filterEqipmetsNotExistInLabs,
  getUserRole,
  getUserTitle,
  isTokenInHttpAuth,
  UserRoles,
} from "../../../core/utils";
import { TeamCreateReq } from "../../../data/models/requests/team/team_create_req";
import { LocalDataSources } from "../../../data/local_datasources";
import { toast } from "react-toastify";
// import { InputLabel } from "../../components/input_label";
import { HTTP } from "../../../core/http_common";
import { ITreeMenuData } from "../../components/TreeMenu";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import NewTreeMenu, { TreeOptionType } from "../../components/NewTreeMenu";
import DOMPurify from "dompurify";
import { Button } from "@radix-ui/themes";
import { BaseButton } from "@fluentui/react";
import { SetHeaderTitle } from "../../../data/storeMain/actions/header_title_action";
type StateType = {
  searchLoading: boolean;
  title: string;
  description: string;
  loadingMode: string;
  managersId: string[];
  usersId: string[];
  selectedManagers: Array<{ label: string; value: string }>;
  selectedUsers: Array<{ label: string; value: string }>;
  laboratoriesId: number[];
  equipmentsId: number[];
  researchesId: number[];
  selectedLabsId: number[];
  selectedEquipsId: number[];
  teamId: number | null;
  subTeamId: number;
  listManagers: Array<{ label: string; value: string } | {}>;
  listUsers: Array<{ label: string; value: string } | {}>;
  listResearch: Array<{ label: string; value: number } | {}>;
  listTeam: Array<{ label: string; value: number } | {}>;
  loading: boolean;

  listLaboratories: ITreeMenuData[];
  selectedLabEquip: string[];
  unCheckedValues: string[];
  listLabsEquips: TreeOptionType[];
};

class TeamPageNew extends React.Component<RouteComponentProps> {
  RoleUser = store.getState().userRole;
  controller = new TeamController();
  local = new LocalDataSources();
  validator = new SimpleReactValidator({
    className: "text-danger",
  });
  state: StateType = {
    selectedLabsId: [],
    selectedEquipsId: [],
    listLabsEquips: [],
    searchLoading: false,
    selectedLabEquip: [],
    selectedManagers: [],
    selectedUsers: [],
    title: "",
    description: "",
    managersId: [],
    usersId: [],
    laboratoriesId: [],
    equipmentsId: [],
    researchesId: [],
    subTeamId: 0,
    unCheckedValues: [],
    listLaboratories: [],
    listManagers: [],
    listResearch: [],
    listTeam: [],
    listUsers: [],
    loading: false,
    teamId: null,
    loadingMode: "",
  };
  componentDidMount() {
    document.title = `New Team | Radvix`;
    store.dispatch(SetHeaderTitle('Team'))
    if (!isTokenInHttpAuth(HTTP)) return;
    this.setState({
      searchLoading: true,
    });
    this.controller.TeamSearch(
      (res) => {
        const _uncategorizedEquips: TreeOptionType | false =
          res.equipments.length > 0
            ? convertEquipsToUncategorized(res.equipments)
            : false;
        const __labs = convertLabEquipsToNewTree(res.laboratories);

        const __listLabsEquips: TreeOptionType[] = _uncategorizedEquips
          ? [_uncategorizedEquips, ...__labs]
          : __labs;

        const _myId = this.local.getUserId();
        const _myInfo = this.local.getUserInfo();

        const _meMySelf =
          res.users.length > 0
            ? res.users.find((_user) => _myId === _user.id)
            : {
                firstName: _myInfo.firstName,
                id: _myId,
                lastName: _myInfo.lastName,
              };
        const _labs = convertLabEquipToTree(res.laboratories as any);

        const _equipmentOnlyAccess = filterEqipmetsNotExistInLabs(
          res.laboratories as any,
          res.equipments
        );

        if (_equipmentOnlyAccess !== null) {
          _labs.unshift(_equipmentOnlyAccess);
        }

        this.setState({
          listLabsEquips: __listLabsEquips,
          listLaboratories: _labs,
          unCheckedValues: _equipmentOnlyAccess?.value
            ? [_equipmentOnlyAccess.value]
            : [],
          listManagers: res.managers
            ? res.managers.map((item: any) => {
                const _userTitle = getUserTitle(item);
                return {
                  label: _userTitle,
                  value: item.userId,
                  secondary: item.invitationStatus === 1 ? "Pending" : null,
                };
              })
            : [],
          listResearch: res.researches
            ? res.researches.map((item) => {
                return { label: item.title, value: item.id };
              })
            : [],
          listTeam: res.teams
            ? res.teams.map((item) => {
                return { label: item.title, value: item.id };
              })
            : [],
          listUsers: res.users
            ? res.users.map((item: any) => {
                const _userTitle = getUserTitle(item);
                return {
                  label: _userTitle,
                  value: item.id,
                  secondary: item.invitationStatus === 1 ? "Pending" : null,
                };
              })
            : [],

          usersId: [
            {
              label: _meMySelf?.firstName + " " + _meMySelf?.lastName,
              value: _meMySelf?.id,
            },
          ],
          managersId: [
            {
              label: _meMySelf?.firstName + " " + _meMySelf?.lastName,
              value: _meMySelf?.id,
            },
          ],
          searchLoading: false,
        });
      },
      (err) => {
        this.setState({
          searchLoading: false,
        });
      }
    );
  }
  handleChange(target: string, val: any) {
    const sanitizedValue = DOMPurify.sanitize(val);
    this.setState({
      [target]: sanitizedValue,
    });
  }
  handelChangeSelect(
    target: string,
    e: Array<{ label: string; value: number }>
  ) {
    this.setState({ [target]: e.map((item) => item.value) });
  }
  handelChangeSelectSigle(target: string, e: { label: string; value: number }) {
    this.setState({ [target]: e.value });
  }
  handelCreateTeam() {
    if (this.validator.allValid()) {
      const _userRole = getUserRole();
      this.setState({
        loading: true,
        loadingMode: `Creating ${
          AccessPermition(_userRole, [
            UserRoles.Admin,
            UserRoles.L1Client,
            UserRoles.L1User,
          ])
            ? "team"
            : "subteam"
        }..`,
      });
      const _removeMySelfFromManagers = this.state.managersId.filter(
        (item: any) => item.value !== this.local.getUserId()
      );
      const _removeMySelfFromUsers = this.state.usersId.filter(
        (item: any) => item.value !== this.local.getUserId()
      );

      const body: TeamCreateReq = {
        managersId: _removeMySelfFromManagers.map((item: any) => item.value),
        usersId: _removeMySelfFromUsers.map((item: any) => item.value),
        teamId: this.state.teamId || 0,

        researchesId: this.state.researchesId,
        laboratoriesId: this.state.selectedLabsId,
        equipmentsId: this.state.selectedEquipsId,
        title: this.state.title,
        description: this.state.description,
      };

      this.controller.createTeam(
        body,
        (res) => {
          this.props.history.push(
            $ReplaceRoute(
              `${AppRoutes.team_profile.replace(":id", res.id.toString())}`
            )
          );
          this.setState({
            title: "",
            description: "",
            managersId: [],
            usersId: [],
            laboratoriesId: [],
            equipmentsId: [],
            researchesId: [],
            teamId: 0,
            listLaboratories: [],
            listManagers: [],
            listResearch: [],
            listUsers: [],
            loading: false,
            loadingMode: "",
          });
        },
        (err) => {
          this.setState({
            loading: false,
            loadingMode: "",
          });
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  render() {
    const _userRole = getUserRole();
    return (
      <div className="card responsive-div">
        <div
          className="d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
        border-gray-400"
        >
          <div className="d-flex align-items-center">
          <BaseButton
              className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button"
              style={{fontWeight:"400", height:"19px"}}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className="fw-medium fs-15 mb-0 color-gray-1000">
              Create a new team
            </h2>
          </div>
        </div>
        <div className="p-3 overflow-auto">
          <div className="row">
            <div className="">
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div className="item">
                    <InputComponent
                      className="form-control"
                      disabled={this.state.searchLoading}
                      type={InputType.text}
                      label="Team name*"
                      placeholder="Enter your team name"
                      popQuestion="You can type in a name of your team. You can also edit this at anytime."
                      onChange={(e) => {
                        this.handleChange("title", e.target.value);
                      }}
                      inValid={this.validator.message(
                        "Team Name",
                        this.state.title,
                        "required"
                      )}
                    ></InputComponent>
                  </div>
                  <div className="item">
                    <InputComponent
                      type={InputType.textarea}
                      disabled={this.state.searchLoading}
                      label="Team description"
                      placeholder="Enter your team description here"
                      height="132px"
                      popQuestion="You can write a description for your team. For example, what is the main focus of this team and what are the key objectives. You can always edit this later."
                      className="mt-1"
                      onChange={(e) => {
                        this.handleChange("description", e.target.value);
                      }}
                    ></InputComponent>
                  </div>
                  <div className="row mt-1">
                    <div className="col-lg-12 mb-3">
                      <label className="color-gray-600 fs-15 me-2 mb-1">
                        Assign managers*
                      </label>
                      {/* <img
                    className="text-primary cursor-pointer"
                    src="/Images/icons/info-circled.svg"
                    alt="info"
                    title="Team managers have the power to add more members to a team as well as updating team information on this page."
                  />   */}
                      <div className="item">
                        <SelectComponent
                          disabled={this.state.searchLoading}
                          items={this.state.listManagers}
                          TextItem="name"
                          ValueItem="id"
                          className="my-1"
                          placeholder="Click to see the list…"
                          //label="Assign managers"
                          popQuestion="Team managers have the power to add more members to a team as well as updating team information on this page."
                          defaultValue={this.state.managersId}
                          onChange={(e) => {
                            const _cantFindYourSelf = !e.find(
                              (item: { value: string }) =>
                                item.value === this.local.getUserId()
                            );

                            if (_cantFindYourSelf) {
                              return toast.error(
                                "You can't remove yourself from the team"
                              );
                            }

                            const currentSelectedManagers =
                              this.state.managersId || [];
                            const currentSelectedUsers =
                              this.state.selectedUsers || [];

                            const removedManagers =
                              currentSelectedManagers.filter(
                                (manager) =>
                                  !e.some(
                                    (selectedManager: { value: string }) =>
                                      selectedManager.value === manager
                                  )
                              );

                            const updatedUsers = currentSelectedUsers.filter(
                              (user) =>
                                !removedManagers.some(
                                  (manager) => manager === user.value
                                )
                            );

                            const selectedManagers = e.filter(
                              (selectedManager: { value: string }) =>
                                !currentSelectedManagers.some(
                                  (manager) => manager === selectedManager.value
                                )
                            );

                            const updatedUsersWithManagers = [
                              ...updatedUsers,
                              ...selectedManagers.map(
                                (manager: { label: any; value: any }) => ({
                                  label: manager.label,
                                  value: manager.value,
                                })
                              ),
                            ];

                            this.setState({
                              managersId: e,
                              usersId: updatedUsersWithManagers,
                            });
                          }}
                          isMulti
                        ></SelectComponent>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-lg-12 mb-3">
                      <label className="color-gray-600 fs-15 me-2 mb-1">
                        Assign members*
                      </label>
                      {/* <img
                    className="text-primary cursor-pointer"
                    src="/Images/icons/info-circled.svg"
                    alt="info"
                    title="Here you can select the members that you would like to be added to this subteam. Please note that you can add more members later."
                  />   */}
                      <div className="item">
                        <SelectComponent
                          disabled={this.state.searchLoading}
                          items={this.state.listUsers}
                          TextItem="name"
                          ValueItem="id"
                          className="my-1"
                          //label={"Assign members"}
                          popQuestion={
                            "Here you can select the members that you would like to be added to this subteam. Please note that you can add more members later."
                          }
                          onChange={(e) => {
                            const _cantFindYourSelf = !e.find(
                              (item: { label: string; value: string }) =>
                                item.value === this.local.getUserId()
                            );

                            if (_cantFindYourSelf) {
                              return toast.error(
                                "You can't remove yourself from team"
                              );
                            }
                            this.setState({
                              usersId: e,
                            });
                          }}
                          defaultValue={this.state.usersId}
                          // inValid={this.validator.message(
                          //   "Members To This Team",
                          //   this.state.usersId,
                          //   "required"
                          // )}

                          isMulti
                        ></SelectComponent>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-lg-12 mb-3">
                      <label className="color-gray-600 fs-15 me-2 mb-1">
                        {AccessPermition(_userRole, [
                          UserRoles.Admin,
                          UserRoles.L1Client,
                          UserRoles.L1User,
                        ])
                          ? "Assign this team to laboratories (Equipment)*"
                          : "Assign this subteam to laboratories (Equipment)*"}
                      
                      {/* <img
                    className="text-primary cursor-pointer"
                    src="/Images/icons/info-circled.svg"
                    alt="info"
                    style={{paddingLeft:"5px"}}
                    title="When you assign a team to a laboratory or only individual equipment, all of the existing or future members and managers of that team will have access to those laboratories and selected equipment."
                  />  */}
                  </label>
                      <div className="item">
                        <NewTreeMenu
                          disabled={this.state.searchLoading}
                          placeholder="Select laboratories or equipment"
                          popQuestion="When you assign a team to a laboratory or only individual equipment, all of the existing or future members and managers of that team will have access to those laboratories and selected equipment."
                          // label={AccessPermition(_userRole, [
                          //   UserRoles.Admin,
                          //   UserRoles.L1Client,
                          //   UserRoles.L1User,
                          // ])
                          //   ? "Assign this team to laboratories (Equipment)"
                          //   : "Assign this subteam to laboratories (Equipment)"}
                          onChange={(_labs, _equips) => {
                            // console.log({ _teams, _users })
                            this.setState({
                              selectedLabsId: _labs,
                              selectedEquipsId: _equips,
                              selectedLabsEquips: [..._labs, ..._equips],
                            });
                          }}
                          loading={this.state.searchLoading}
                          // onSele ctedItems={() => {

                          // }}
                          options={this.state.listLabsEquips}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="row mt-1">
                    <div className="col-lg-12 mb-3">
                      <label className="color-gray-600 fs-15 me-2 mb-1">
                        Priority*
                      </label>
                      <div className="d-flex border-gray-400 border soft-radios p-1 rounded-px-4 w-100">
                        <div className="w-33_3">
                          <input
                            type="radio"
                            className="btn-check"
                            name="options"
                            id="low"
                            autoComplete="off"
                          />
                          <label
                            className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                            htmlFor="low"
                          >
                            Low
                          </label>
                        </div>
                        <div className="w-33_3">
                          <input
                            type="radio"
                            className="btn-check"
                            name="options"
                            id="medium"
                            autoComplete="off"
                            checked
                          />
                          <label
                            className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                            htmlFor="medium"
                          >
                            Medium
                          </label>
                        </div>
                        <div className="w-33_3">
                          <input
                            type="radio"
                            className="btn-check"
                            name="options"
                            id="high"
                            autoComplete="off"
                          />
                          <label
                            className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                            htmlFor="high"
                          >
                            High
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="">
          <MainButton
            className="btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2 responsive-Button-width"
            svg={<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
            <rect width="15" height="15" fill="white" fill-opacity="0.01"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 2.00006C3.22386 2.00006 3 2.22392 3 2.50006V12.5001C3 12.7762 3.22386 13.0001 3.5 13.0001H11.5C11.7761 13.0001 12 12.7762 12 12.5001V4.70717L9.29289 2.00006H3.5ZM2 2.50006C2 1.67163 2.67157 1.00006 3.5 1.00006H9.5C9.63261 1.00006 9.75979 1.05274 9.85355 1.14651L12.7803 4.07328C12.921 4.21394 13 4.4047 13 4.60361V12.5001C13 13.3285 12.3284 14.0001 11.5 14.0001H3.5C2.67157 14.0001 2 13.3285 2 12.5001V2.50006ZM4.75 7.50006C4.75 7.22392 4.97386 7.00006 5.25 7.00006H7V5.25006C7 4.97392 7.22386 4.75006 7.5 4.75006C7.77614 4.75006 8 4.97392 8 5.25006V7.00006H9.75C10.0261 7.00006 10.25 7.22392 10.25 7.50006C10.25 7.7762 10.0261 8.00006 9.75 8.00006H8V9.75006C8 10.0262 7.77614 10.2501 7.5 10.2501C7.22386 10.2501 7 10.0262 7 9.75006V8.00006H5.25C4.97386 8.00006 4.75 7.7762 4.75 7.50006Z" fill="white"/>
          </svg> }
            onClick={() => {
                          this.handelCreateTeam();
                        }}
                        loading={this.state.loading || this.state.searchLoading}
                        disabled={this.state.loading || this.state.searchLoading}
          
          >
            {AccessPermition(_userRole, [
  UserRoles.Admin,
  UserRoles.L1Client,
  UserRoles.L1User,
])
  ? "Create my team"
  : "Create my subteam"}
          </MainButton>
        </div>
              </div>
            </div>
           
          </div>
          
        </div>
      </div>
    );
  }
}
export default withRouter(TeamPageNew);
