import { useHistory, useParams } from 'react-router';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { InputComponent, InputType } from '../../components/inputs';
import NewTreeMenu from '../../components/NewTreeMenu';
import SimpleReactValidator from 'simple-react-validator';
import BackButtonTitle from './component/back_button_title';
import SubmitButton from './component/submit_button';
import { grantController } from '../../../controllers/financials/grant_controller';
import { ResearchController } from '../../../controllers/research/research_controller';
import { GroupController } from '../../../controllers/group/group_controller';
import { store } from '../../../data/storeMain';
import { useSelector } from 'react-redux';
import { SortType } from '../../../core/number_extentions';
import { EditGrantReq } from '../../../data/models/requests/financials/update_grant_req';
import { $ReplaceRoute } from '../../../core/utils';
import { AppRoutes } from '../../../core/constants';
import { toast } from 'react-toastify';

type Params = { company: string; id: string };

export default function GrantEdit() {
  const validator = useRef(
    new SimpleReactValidator({
      className: 'text-danger',
    })
  );

  const { id }: Params = useParams();
  const controller = new grantController();
  const researchController = new ResearchController();
  const groupController = new GroupController();
  const history = useHistory();

  const [, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [amount, setAmount] = useState('');
  const [source, setSource] = useState('');
  const [description, setDescription] = useState('');

  const [managerLoading, setManagerLoading] = useState(false);
  const [managers, setManagers] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState<any[]>([]);
  const [grantManagers, setGrantManagers] = useState<any[]>([]);

  const researchId = useSelector(() => store.getState().ResearchId);
  const [researchLoading, setResearchLoading] = useState(false);
  const [researches, setResearches] = useState<any[]>([]);
  const [researchList, setResearchList] = useState<any[]>([]);
  const [selectedResearch, setSelectedResearch] = useState<any[]>([]);
  const [projects, setProjects] = useState<any[]>([]);

  useLayoutEffect(() => {
    document.title = `Edit Grant | Radvix`;
  }, []);

  function getGrantById() {
    setIsLoading(true);
    controller.getGrantById(
      { id: Number(id) },
      (res) => {
        if (res === null) {
          setIsLoading(false);
          return;
        }
        const managers = res.managers.map((manager) => manager.userId);
        setTitle(res.title);
        setAmount(res.amount.toString());
        setSource(res.source);
        setDescription(res.description);
        setGrantManagers([...res.managers]);
        setSelectedManagers([...managers]);
        setResearches([...res.researches]);
        setSelectedResearch([...res.researches]);

        setIsLoading(false);
        console.log(res, '================== Grant by id');
      },
      (err) => {
        toast.error(err?.response?.data?.message);
        setIsLoading(false);
      }
    );
  }

  useEffect(() => {
    document.title = "Edit Grant | Radvix";
    getGrantById();
  }, []);

  function loadResearches() {
    setResearchLoading(true);
    researchController.getResearches(
      {
        PageNumber: 1,
        PageSize: 100,
        SearchParameter: '',
        columnHeaderName: '',
        sortDirection: SortType.AutoSelect,
      },
      (res) => {
        if (res && res.researchesList && res.researchesList.length > 0) {
          const filteredResearches = res.researchesList?.map(({ id, title }) => {
            // if (id === researchId.value) {
            //   setSelectedResearch([{ id: Number(id), title }]);
            // }
            return {
              value: Number(id),
              label: title,
              checked: researches.some((item: any) => id === item.id),
            };
          });

          const projects = res.researchesList?.map(({ id, title }) => ({
            id,
            title,
          }));

          filteredResearches.shift();
          projects.shift();
          setProjects(projects);
          setResearchList(filteredResearches);
        }
        setResearchLoading(false);
      },
      (err) => {
        setResearchLoading(false);
      }
    );
  }

  useEffect(() => {
    loadResearches();
  }, [researches]);

  function getGroup() {
    setManagerLoading(true);
    groupController.groupSearch(
      (res) => {
        if (res && res.managers && res.managers.length > 0) {
          const managers = res.managers.map((item: any) => {
            return {
              label: item.firstName + ' ' + item.lastName,
              value: item.userId,
              checked: grantManagers.some((manager: any) => item.userId === manager.userId),
            };
          });

          setManagers(managers);
        }
        setManagerLoading(false);
      },
      (err) => {
        setManagerLoading(false);
      }
    );
  }

  useEffect(() => {
    getGroup();
  }, [grantManagers]);

  function updateGrant(body: EditGrantReq) {
    setIsLoading(true);
    controller.updateGrant(
      body,
      (res) => {
        setIsLoading(false);
        history.push(
          $ReplaceRoute(`${AppRoutes.grant_detail.replace(':id', id.toString() ?? '')}`)
        );
      },
      (err) => {
        //toast.error(err?.response?.data?.message);
        setIsLoading(false);
      }
    );
  }

  function handelUpdateGrant() {
    setIsFormValid((prvState) => !prvState);
    const formValid = validator.current.allValid();
    if (!formValid) {
      validator.current.showMessages();
      return;
    }

    updateGrant({
      id: Number(id),
      title,
      description,
      amount: Number(amount),
      source,
      managers: selectedManagers,
      researches: selectedResearch,
    });
  }

  return (
    <div className='card grant-card'>
      <div
        className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
        border-gray-400'
      >
        <BackButtonTitle title='Edit Grant' />
      </div>
      <div className='p-3 overflow-auto'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='row'>
              <div className='col-lg-12 mb-3'>
                <div className='mb-0'>
                  <InputComponent
                    className='form-control'
                    disabled={isLoading}
                    addIcon={false}
                    type={InputType.text}
                    label='Grant Name*'
                    placeholder='Enter your grant name'
                    popQuestion='This will be the name of your grant. You can always edit this later.'
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    inValid={validator.current.message('Grant Name', title, 'required')}
                  ></InputComponent>
                </div>
                <div className='item'>
                  <InputComponent
                    className='form-control'
                    disabled={isLoading}
                    addIcon={false}
                    type={InputType.text}
                    label='Grant Amount*'
                    placeholder='$100,000.00'
                    popQuestion='This will be the amount of your grant. You can always edit this later.'
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    inValid={validator.current.message('Grant Amount', amount, 'required|numeric')}
                  ></InputComponent>
                </div>
                <div className='item'>
                  <InputComponent
                    className='form-control'
                    disabled={isLoading}
                    addIcon={false}
                    type={InputType.text}
                    label='Grant Source'
                    placeholder='Enter grant source'
                    popQuestion='This will be the grant source. You can always edit this later.'
                    value={source}
                    onChange={(e) => {
                      setSource(e.target.value);
                    }}
                  ></InputComponent>
                </div>

                <div className='item'>
                  <div className='text-primary cursor-pointer mb-1' title='Info'>
                    <label className='color-gray-600 fs-15 me-2'>Grant Description</label>
                  </div>
                  <InputComponent
                    type={InputType.textarea}
                    disabled={isLoading}
                    placeholder='Description here'
                    height='132px'
                    popQuestion='You can write a description for your grant. For example, what is the main focus of this grant and what are the key objectives. You can always edit this later.'
                    className='mt-1'
                    value={description}
                    defaultValue={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  ></InputComponent>
                </div>
                <div className='item'>
                  <div className='d-flex align-items-center mb-1'>
                    <label className='color-gray-600 fs-15 me-2'>Grant Manager*</label>
                  </div>
                  <NewTreeMenu
                    onChange={(manager) => {
                      setSelectedManagers([...manager]);
                    }}
                    popQuestion='If you already have the teams or individual members for this project invited to Radvix, you can select and assign them here. You can always go back later and add members or teams in the future. Once you create this project, they will get a notification.'
                    loading={managerLoading}
                    disabled={managerLoading}
                    options={managers}
                  />
                  {validator.current.message('Grant manager', selectedManagers, 'required')}
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='col-lg-12 mb-3'>
              <div className='d-flex align-items-center mb-1'>
                <label className='color-gray-600 fs-15 me-2'>Select Project*</label>
              </div>
              <NewTreeMenu
                onChange={(research) => {
                  const selectedProject = projects.filter((item) => research.includes(item.id));
                  setSelectedResearch([...selectedProject]);
                  // setResearchList([...selectedProject]);
                }}
                popQuestion='If you already have the teams or individual members for this project invited to Radvix, you can select and assign them here. You can always go back later and add members or teams in the future. Once you create this project, they will get a notification.'
                loading={researchLoading}
                disabled={researchLoading}
                options={researchList}
              />
              {validator.current.message('Project', selectedResearch, 'required')}
            </div>
          </div>
          <div className='mt-2'>
            <SubmitButton
              isEdit={true}
              onClick={handelUpdateGrant}
              loading={isLoading}
              disabled={isLoading || researchLoading || managerLoading}
            >
              Save Grant
            </SubmitButton>
          </div>
        </div>
      </div>
    </div>
  );
}
