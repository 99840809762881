import Highlighter from 'react-highlight-words';
import { getUserTitle } from '../../../../core/utils';
import { Checkbox } from '../../setting/component/new_notification_tbl';

type MemeberProps = {
  detail: any;
  onClick: () => void;
  selected: boolean;
  locked: boolean;
  searchValue: string;
  isMe: boolean;
};
const Member = ({ detail, onClick, selected, searchValue, locked, isMe }: MemeberProps) => {
  const _fullName = getUserTitle(detail);
  return (
    <div
      onClick={() => {
        if (locked) return;
        onClick();
      }}
      role='button'
      style={{ cursor: 'pointer', position: 'relative', borderRadius: '0.5em' }}
      className={`d-flex align-items-center p-1 pe-5 btnHover max1LineText w-100 ${
        locked ? 'locked' : ''
      } `}
    >
      {/* <ImageWithToken
        hasImage={detail.image}
        originalImage={detail.image}
        src={
          detail.image
            ? IMAGE_BASE_URL + detail.image
            : "/images/images/img_avatar.png"
        }
        alt="Avatar"
        className="rounded-circle avatar"
        style={{
          width: "36px",
          height: "36px",
        }}
      /> */}

      <Checkbox checked={selected || locked} onChange={onClick} disabled={isMe} />

      <div className='d-flex flex-column ms-2 max1LineText'>
        <span className='max1LineText'>
          {searchValue ? (
            <Highlighter
              highlightClassName='highlightedText'
              searchWords={searchValue.length >= 2 ? searchValue?.split(' ') : []}
              autoEscape={true}
              textToHighlight={String(_fullName)}
            />
          ) : (
            `${_fullName}${isMe ? ' (You)' : ''}`
          )}
        </span>
        {/* {detail.firstName === " " ? null : (
          <span className="textSecondary max1LineText">
            {searchValue ? (
              <Highlighter
                highlightClassName="highlightedText"
                searchWords={
                  searchValue.length >= 2 ? searchValue?.split(" ") : []
                }
                autoEscape={true}
                textToHighlight={String(detail.email)}
              />
            ) : (
              detail.email
            )}
          </span>
        )} */}
      </div>
      {/* {selected && (
        <MdDone
          style={{
            position: "absolute",
            right: "0.5em",
            top: "50%",
            transform: "translateY(-50%)",
            color: "#096BFF",
          }}
        />
      )} */}
    </div>
  );
};

export default Member;
