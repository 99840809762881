import moment from "moment";
import React from "react";
import ReactPaginate from "react-paginate";
import { RouteComponentProps, withRouter } from "react-router";
import { ResearchController } from "../../../controllers/research/research_controller";
import { AppRoutes, MOMENT_DATE_FORMAT } from "../../../core/constants";
import { HTTP } from "../../../core/http_common";
import "./sharedWorkspace.css";
import {
  $ReplaceRoute,
  WorkspaceCollaborationEnum,
  // AccessPermition,
  isTokenInHttpAuth,
  // UserRoles,
} from "../../../core/utils";
import debounce from "lodash.debounce";
import { ResearchesList } from "../../../data/models/responses/research/researches_res";
import { store } from "../../../data/storeMain";
import { Badge } from "../../components/badge";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
// import { EmptyState } from "../../components/empty_state";
import NewTable from "../../components/NewTable/NewTable";
import { InputIcon } from "../../components/search_box";
import { SelectComponent } from "../../components/select_input";
import { StatusLabel } from "../../components/status_label";
// import AcordienTableResearch from "./component/acordien_table_research";
import { FaEdit } from "react-icons/fa";
import { MdFolder } from "react-icons/md";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { RxMagnifyingGlass } from "react-icons/rx";
import PhoneSearch from "../../components/PhoneSearch";
import CustomTooltip from "../../components/CustomTooltip";
import { RiTimerLine } from "react-icons/ri";
import { ResearchStatus, SortType } from "../../../core/number_extentions";
import FilterSection from "../../components/FilterSection/filterSection";
import { IFilterOptions } from "../../components/FilterSection/IFilterOptions";
import { SeatManagementController } from "../../../controllers/seatManagement/seat_Management_controller";
import { UserInformationRes } from "../../../data/models/responses/seatManagement/get_list_res";
import { MainButton, MainButtonType } from "../../components/button";
import SeatCard from "../seat_management/component/seatCard";
import { InputComponent, InputType } from "../../components/inputs";
import Checkbox from "../../components/checkbox";
import { worSpaceController } from "../../../controllers/workSpace/workSpace";
import { WorkspaceCollaboration } from "../../../data/models/requests/workSpace/workSpace_request";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import { SetHeaderTitle } from "../../../data/storeMain/actions/header_title_action";
type StateType = {
  workspaceId: string;
  workSpaceName: string;
  checkboxChecked: boolean;
  WorkspaceManagement: WorkspaceCollaboration[];
  PageNumber: number;
  PageCount: number;
  PageSize: { label: string; value: number };
  TotalCount: number;
  Search: string;
  loading: boolean;
  HeaderName: string;
  SortDirection: number;
  workspace: string;
  updateloading: boolean;
  AdminName: string;
  RequestedDate: Date;
  Actions: WorkspaceCollaborationEnum;
  userId: string;
};
interface RouteParams {
  id: string;
}

class SharedWorkspace extends React.Component<
  RouteComponentProps<RouteParams>
> {
  controller = new worSpaceController();
  RoleUser = store.getState().userRole;
  
  state: StateType = {
    userId: "",
    WorkspaceManagement: [],
    checkboxChecked: false,
    PageNumber: 1,
    PageCount: 0,
    PageSize: { label: "10", value: 10 },
    TotalCount: 0,
    Search: "",
    loading: true,
    HeaderName: "",
    SortDirection: 0,
    workspace: "",
    updateloading: false,
    workspaceId: store.getState().userInfo.workSpaceUniqueId,
    workSpaceName: store.getState().userInfo.workSpaceName,
    AdminName: "",
    RequestedDate: new Date(),
    Actions: 0,
  };
  validator: any;

  handleCopyClick = () => {
    navigator.clipboard
      .writeText(this.state.workspaceId)
      .then(() => {
        toast.info('Workspace ID copied to clipboard');        
      })
      .catch((error) => {
        console.error("Error copying workspace ID:", error);
      });
  };
  local: any;

  constructor(props: any) {
    super(props);
    this.handleChangeSearch = debounce(this.handleChangeSearch.bind(this), 500);
  }
  componentDidMount() {
    document.title = "Collaboration Workspaces | Radvix";
    store.dispatch(SetHeaderTitle('Workspace Management'))
    if (!isTokenInHttpAuth(HTTP)) return;
    this.GetUserList(this.state.PageNumber, this.state.PageSize.value, "");
    const userId = localStorage.getItem("userId");
    this.setState({ userId: userId });
  }
  GetUserList(
    PageNumber: number,
    PageSize: number,
    Search?: string,
    columnHeaderName?: string,
    sortDirection?: number
  ) {
    this.setState({
      loading: true,
    });
    if (sortDirection == undefined || sortDirection == null) {
      sortDirection = 0;
    }
    if(columnHeaderName == undefined){
      columnHeaderName = "requesteddate"
    }
    this.controller.GetList(
      {
        PageNumber: PageNumber,
        PageSize: PageSize,
        SearchParameter: Search ? Search : this.state.Search,
        SortDirection: sortDirection ? sortDirection : this.state.SortDirection,
        ColumnName: columnHeaderName ? columnHeaderName : this.state.HeaderName
      },
      (res) => {
        this.setState({
          WorkspaceManagement: res.result.workspaceCollaborations,
          //workspaceId: res.result[0].myWorkspaceID,
          PageCount: Math.ceil(res.result.count! / this.state.PageSize.value),
          TotalCount: res.result.count,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
  }
  handelChangePageNumber(e: { selected: number }) {
    this.setState({
      PageNumber: e.selected,
    });
    this.GetUserList(
      e.selected + 1,
      this.state.PageSize.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection
    );
  }
  handelChangePageSize(e: { label: string; value: number }) {
    this.setState({
      PageSize: e,
    });
    this.GetUserList(
      this.state.PageNumber,
      e.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection
    );
  }

  handleCheckboxChange = (checked: boolean) => {
    this.setState({
      checkboxChecked: checked
    });
  }

  handelSendRequest() {
    const body = {
      WorkspaceID: this.state.workspaceId,
      RequestedWorkspaceId: this.state.workspace,
    };

    this.setState({
      updateloading: true,
    });
    this.controller.SendRequest(
      body,
      (res) => {
        this.setState({
          userStatus: [],
        });
        this.setState({
          updateloading: false,
        });
        this.GetUserList(
          this.state.PageNumber,
          this.state.PageSize.value,
          "",
          this.state.HeaderName ? this.state.HeaderName : "title",
          this.state.SortDirection ? this.state.SortDirection : 0
        );     
      },
      (err) => {
        if (err.response?.data.message)
        {
          toast.error(`${err.response?.data.message}`);
        }
          
        this.setState({
          updateloading: false,
        });
      }
    );
  }

  handleChangeSearch(e: any) {
    const inputValue = e.target.value;
  const sanitizedValue = DOMPurify.sanitize(inputValue);
    this.GetUserList(
      this.state.PageNumber,
      this.state.PageSize.value,
      sanitizedValue,
      this.state.HeaderName ? this.state.HeaderName : "title",
      this.state.SortDirection ? this.state.SortDirection : 0
    );
  }
  headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    const sort = sortDirection == SortType.ASC ? 0 : sortDirection == SortType.DESC ? 1 : 2;
    this.setState({ HeaderName: columnHeaderName });
    // this.setState({ SortDirection: sort });
    this.GetUserList(
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.Search,
      columnHeaderName,
      sort
    );
  };

  render() {
    const handleJoin = (_workspaceUniqueId: any, action: boolean) => {
      this.setState({ updateloading: true });
      const body = {
        workspaceID: _workspaceUniqueId,
        status: action
          ? WorkspaceCollaborationEnum.Connected
          : WorkspaceCollaborationEnum.Declined,
      };
      this.controller.UpdateRequest(
        body,
        (_res) => {
          this.GetUserList(
            this.state.PageNumber,
            this.state.PageSize.value,
            ""
          );
          this.setState({ updateloading: false });
        },
        (_error) => {}
      );
    };

    return (
      <div className="container-fluid research">
        <div className="row"></div>
        <div className="col-12">
          <div className="TableBox">
            <div className="relative TopTableBox d-flex justify-content-between align-items-start mb-0  mb-sm-3">
              <div className="left d-flex align-items-center headerAlign">
                {/* <h5 style={{ width: "35%" }}>Research List</h5> */}
                <h5 className="b-title d-flex align-items-center">
                  <IoIosArrowDropleftCircle
                    className="me-3 textSecondary2 cursorPointer"
                    size="1.5em"
                    onClick={() => {
                      window.history.back();
                    }}
                  />{" "}
                  Collaboration Workspaces
                </h5>
              </div>

              <PhoneSearch
                value={this.state.Search}
                onChange={(e) => {
                  this.setState({
                    Search: e.target.value,
                  });
                  this.handleChangeSearch(e);
                }}
                searchIcon={
                  this.state.Search.length > 0 ? (
                    <i
                      className="fa fa-times pointer"
                      aria-hidden="true"
                      onClick={() => {
                        this.setState(
                          {
                            Search: "",
                          },
                          () => {
                            this.GetUserList(
                              this.state.PageNumber,
                              this.state.PageSize.value
                            );
                          }
                        );
                      }}
                    ></i>
                  ) : (
                    <RxMagnifyingGlass
                      size="1.25em"
                      className="textSecondary"
                    />
                  )
                }
              />
              <div className="right d-flex justify-content-end align-items-center dNoneSm">
                <InputIcon
                  chilren={
                    this.state.Search.length > 0 ? (
                      <i
                        className="fa fa-times pointer"
                        aria-hidden="true"
                        onClick={() => {
                          this.setState(
                            {
                              Search: "",
                            },
                            () => {
                              this.GetUserList(
                                this.state.PageNumber,
                                this.state.PageSize.value
                              );
                            }
                          );
                        }}
                      ></i>
                    ) : (
                      <RxMagnifyingGlass
                        size="1.25em"
                        className="textSecondary"
                      />
                    )
                  }
                  width="100%"
                  placeholder="Search..."
                  TopPosition="15%"
                  value={this.state.Search}
                  onChange={(e) => {
                    this.setState({
                      Search: e.target.value,
                    });
                    this.handleChangeSearch(e);
                  }}
                ></InputIcon>
                <SelectComponent
                  defaultValue={this.state.PageSize}
                  className="ms-2"
                  width="90px"
                  height="44px"
                  items={[
                    { label: "10", value: 10 },
                    { label: "15", value: 15 },
                    { label: "20", value: 20 },
                  ]}
                  TextItem="item"
                  ValueItem="id"
                  isMulti={false}
                  placeholder={this.state.PageSize.toString()}
                  onChange={(e) => {
                    this.handelChangePageSize(e);
                  }}
                ></SelectComponent>
              </div>
            </div>
            <div className="row">
              <div
                className="seat-card-container"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  maxWidth: "524px",
                }}
              >
                <div
                  className="newTableParent d-flex flex-column"
                  style={{ width: "100%", paddingLeft: "8px" }}
                >
                  <div className="myworkspaceCardTitle" style={{whiteSpace: 'initial' , paddingLeft: '5px' , paddingTop: '25px'}}>To collaborate with other workspaces, simply enter their Radvix Workspace ID below and send a request.</div>
                  <div style={{ height: "70%", display: "flex" }}>
                    <div
                      className="s-chip fs-200 seat-fonts"
                      style={{
                        // textAlign: "center",
                        width: "300px",
                        color: "#474747",
                        fontStyle: "normal",
                        fontWeight: 500,
                        letterSpacing: "0.075px",
                        paddingTop: "11px",
                      }}
                    >
                      Enter Workspace ID:
                      <InputComponent
                        disabled={this.state.updateloading}
                        counter={false}
                        placeholder="Please enter the workspace ID to connect."
                        type={InputType.text}
                        popQuestion="Please enter the name of this equipment. This will be the primary way to identify the equipment among list of equipment."
                        onChange={(e) => {
                          this.handleChange("title", e.target.value);
                        }}
                        // inValid={this.validator.message(
                        //   "Equipment Nickname",
                        //   // this.state.title,
                        //   "required"
                        // )}
                      ></InputComponent>
                    </div>
                    <div style={{ display: "flex", alignItems: "end" }}>
                      <MainButton
                        type={MainButtonType.dark}
                        children="Send Request"
                        disabled={this.state.updateloading || !this.state.checkboxChecked}
                        borderRadius="8px"
                        fontSize="16px"
                        minHeight="40px"
                        minWidth="173px"
                        className="mx-2 px-3"
                        onClick={() => {
                          this.handelSendRequest();
                        }}
                        loading={this.state.updateloading}
                      ></MainButton>
                    </div>
                  </div>
                  <div
                    className="sign-up-checkbox"
                    style={{
                      display: "flex",
                      alignSelf: "flex-start",
                      paddingLeft: "8px",
                    }}
                  >
                    <Checkbox checked={this.state.checkboxChecked} 
                    onClick={() => this.handleCheckboxChange(!this.state.checkboxChecked)} />
                    <span className="termsAgree myworkspaceCardTitle">
                      The requested workspace is aware of this request.
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="seat-card-container"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  maxWidth: "398px",
                }}
              >
                <div
                  className="newTableParent d-flex flex-column"
                  style={{ width: "100%" }}
                >
                  <div style={{ height: "100%" }}>
                  <div className="myworkspaceCardTitle" style={{padding:'25px 10px 0px 25px'}}>{`Workspace Name: ${this.state.workSpaceName}`}</div>
                    <div className="s-chip fs-200 seat-fonts myworkspaceCard">
                      <div className="myworkspaceIdTitle">
                        Your Workspace ID:
                      </div>
                      <div className="myWorkspaceId">
                        {this.state.workspaceId}
                        <img
                          src="/images/icons/copyIcon.svg"
                          alt="radvix"
                          width={"13px"}
                          height={"13px"}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={this.handleCopyClick}
                        />
                      </div>
                      <div className="myworkspaceCardTitle"
                        style={{
                          whiteSpace: "initial",
                          paddingLeft: "5px",
                          width: "297px",
                          height: "42px",
                        }}
                      >
                        Other workspaces can send you requests to connect using
                        your workspace ID.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ paddingTop: "30px" }}>
              <NewTable
                customGridTemplate="11em 8em 12em 10em 10em 0em"
                searchValue={this.state.Search}
                loadingCount={this.state.PageSize.value}
                loading={this.state.loading}
                data={this.state.WorkspaceManagement}
                headerRowClassName="d-flex d-sm-grid"
                rowClassName="newTableRowPhone relative"
                onHeaderClick={(columnHeaderName, sortDirection) =>
                  this.headerClick(columnHeaderName, sortDirection)
                }
                columns={[
                  {
                    Header: "Workspace Name",
                    accessor: "workspaceName",
                    cellClassName: "fullRow",
                    ellipsis: true,
                  },

                  {
                    Header: "Workspace ID",
                    accessor: "workspaceUniqueId",
                    cellClassName: "fullRow",
                    ellipsis: true,
                  },

                  {
                    Header: "Admin Name",
                    accessor: "adminName",
                    headerCellClassName: "dNoneSm",
                    align: "center",
                  },

                  {
                    Header: "Requested Date",
                    accessor: "requestedDate",
                    headerCellClassName: "dNoneSm",
                    // align: "center",
                    ellipsis: true,
                    Cell: (props: any) => {
                      return (
                        <div>
                          <CustomTooltip
                            content="Deadline"
                            className="dNone dFlexSm align-items-center"
                          >
                            <RiTimerLine
                              size="1.3em"
                              className="me-1 textSecondary"
                            />
                            {moment(props.endDate).format(MOMENT_DATE_FORMAT)}
                          </CustomTooltip>
                          {/* </div> */}
                          <span className="dNoneSm">
                            {moment(props.endDate).format(MOMENT_DATE_FORMAT)}
                          </span>
                        </div>
                      );
                    },
                  },

                  {
                    Header: "Actions",
                    align: "center",
                    headerCellClassName: "dNoneSm",
                    accessor: "actions",
                    Cell: (_props: any) => (
                      <div
                        className="d-flex justify-content-center"
                        style={{ width: "100%" }}
                      >
                          {_props.actions === 0 &&
                          _props.workspaceRecieverUserId ==
                            this.state.userId ? (
                            <>
                            <div
                          className={`d-flex justify-content-start gap-1`}
                          style={{ width: "100%" }}
                        >
                              <div
                                className={`s-chip workspace-join`}
                                style={{
                                  color: "#FFFFFF",
                                  fontSize: "14px",
                                  width: "50%",
                                }}
                                onClick={() =>
                                  handleJoin(_props.connectedWorkspaceId, true)
                                }
                              >
                                {this.state.updateloading ? (
        <ReactLoading className="d-flex" type="spin" height="auto" width={20} />
      ) : (
        'Join'
      )}
        
                              </div>

                              <div
                                className={`s-chip workspace-decline`}
                                style={{
                                  color: "#FFFFFF",
                                  fontSize: "14px",
                                  width: "50%",
                                }}
                                onClick={() =>
                                  handleJoin(_props.connectedWorkspaceId, false)
                                }
                              >{this.state.updateloading ? (
                                <ReactLoading className="d-flex" type="spin" height="auto" width={20} />
                              ) : (
                                'Decline'
                              )}
                                
                              </div>       
                              </div>                      
                            </>
                          ) : (
                            <div
                          className={`d-flex flex-wrap justify-content-start gap-1`}
                          style={{ width: "100%" }}
                        >
                            <div
                              className={`s-chip ${
                                _props.actions === 0 &&
                                _props.workspaceSenderUserId ==
                                  this.state.userId &&
                                "workspace-requested"
                              } ${
                                _props.actions === 1 && "workspace-connected"
                              } ${
                                _props.actions === 2 && "workspace-declined"
                              }`}
                              style={{
                                color: "#FFFFFF",
                                fontSize: "14px",
                                width: "100%",
                              }}
                            >
                              {_props.actions === 0 &&
                              _props.workspaceSenderUserId == this.state.userId
                                ? "Requested"
                                : _props.actions === 1
                                ? "Connected"
                                : "Declined"}
                            </div>
                            </div>
                          )}
                        </div>
                      
                    ),
                  },
                ]}
              />
            </div>
            <div className="d-flex justify-content-between mt-2 align-items-center px-3 mt-3 px-0-sm mt-1-sm">
              <div className="d-flex flex-fill">
                <div className="dFlexSm dNone">
                  <SelectComponent
                    defaultValue={this.state.PageSize}
                    className="me-2"
                    width="90px"
                    height="44px"
                    selectProps={{ isSearchable: false }}
                    items={[
                      { label: "10", value: 10 },
                      { label: "15", value: 15 },
                      { label: "20", value: 20 },
                    ]}
                    TextItem="item"
                    ValueItem="id"
                    isMulti={false}
                    placeholder={this.state.PageSize.toString()}
                    onChange={(e) => {
                      this.handelChangePageSize(e);
                    }}
                  ></SelectComponent>
                </div>
                {this.state.PageCount > 1 ? (
                  <div className="d-flex justify-content-end flex-fill">
                    <ReactPaginate
                      previousLabel={
                        <CircleIcon
                          width="24px"
                          backgroundColor="#4E0057"
                          height="24px"
                          padding="0"
                          type={ThemeCircleIcon.dark}
                        >
                          <i className="fas fa-chevron-left"></i>
                        </CircleIcon>
                      }
                      nextLabel={
                        <CircleIcon
                          width="24px"
                          backgroundColor="#4E0057"
                          height="24px"
                          padding="0"
                          type={ThemeCircleIcon.dark}
                        >
                          <i className="fas fa-angle-right"></i>
                        </CircleIcon>
                      }
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.PageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={(e) => {
                        this.handelChangePageNumber(e);
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                ) : null}
              </div>
              {this.state.WorkspaceManagement.length > 0 ? (
                <div className="d-flex justify-content-end flex-fill">
                  <p className="text-right mb-0 ">
                    Total Results: {this.state.TotalCount}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
  handleChange(arg0: string, value: any) {
  const sanitizedValue = DOMPurify.sanitize(value);
    this.setState({ workspace: sanitizedValue });
  }
}

export default withRouter(SharedWorkspace);
