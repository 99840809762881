import React from "react";
import "./SubscriptionCard.css"; // Create a separate CSS file for styling

interface SubscriptionCardProps {
  totalSubscriptions: number;
  users: number;
  weeklyUserSessions: number;
  subscriptionsOnTrial: number;
  subscriptionsOnPayment: number;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
totalSubscriptions,
  users,
  weeklyUserSessions,
  subscriptionsOnTrial,
  subscriptionsOnPayment,
}) => {
  return (
    <div
      className="seat-card-container py-3 px-3"
      style={{ display: "flex", gap: "19px", width:"100%" }}
    >
      {totalSubscriptions !== undefined && (
        <div className="newTableParent d-flex flex-column" style={{borderRadius: "var(--2, 6px)",
          background:" var(--Neutral-2, #F9F9FB)",height:"124px",width:"100%", maxWidth:"320px"}}>
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ height: "100%" }}
          >
            
            <div
              className="s-chip seat-fonts-number seatsCardStyle"
            >
              {totalSubscriptions}
            </div>
            <div
              className="s-chip fs-200 seat-fonts seatFontsStyle"
            >
              Subscriptions
            </div>
          </div>
        </div>
      )}
      {users !== undefined && (
        <div className="newTableParent d-flex flex-column"style={{borderRadius: "var(--2, 6px)",
          background:" var(--Neutral-2, #F9F9FB)", height:"124px",width:"100%", maxWidth:"320px"}}>
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          
          <div
            className="s-chip seat-fonts-number seatsCardStyle"
          >
            {users}
          </div>
          <div
            className="s-chip fs-200  seat-fonts seatFontsStyle"
          >
            Users
          </div>
        </div>
        {/* <p>Available Seats: {availableSeats}</p> */}
      </div>
      )}
      {weeklyUserSessions !== undefined && (
        <div className="newTableParent d-flex flex-column"style={{borderRadius: "var(--2, 6px)",
          background:" var(--Neutral-2, #F9F9FB)", height:"124px",width:"100%", maxWidth:"320px"}}>
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ height: "100%" }}
        >          
          <div
            className="s-chip seat-fonts-number seatsCardStyle"
          >
            {weeklyUserSessions}
          </div>
          <div
            className="s-chip fs-200  seat-fonts seatFontsStyle"
          >
            Weekly User Sessions
          </div>
        </div>
        {/* <p>Available Seats: {availableSeats}</p> */}
      </div>
      )}
      {subscriptionsOnTrial !== undefined && (
        <div className="newTableParent d-flex flex-column"style={{borderRadius: "var(--2, 6px)",
          background:" var(--Neutral-2, #F9F9FB)", height:"124px",width:"100%", maxWidth:"320px"}}>
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <div
            className="s-chip seat-fonts-number seatsCardStyle"
          >
            {subscriptionsOnTrial}
          </div>
          <div
            className="s-chip fs-200  seat-fonts seatFontsStyle"
          >
            Subscriptions on Trial
          </div>
        </div>
        {/* <p>Available Seats: {availableSeats}</p> */}
      </div>
      )}
      {subscriptionsOnPayment !== undefined && (
        <div className="newTableParent d-flex flex-column"style={{borderRadius: "var(--2, 6px)",
          background:" var(--Neutral-2, #F9F9FB)", height:"124px",width:"100%", maxWidth:"320px"}}>
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ height: "100%" }}
        >          
          <div
            className="s-chip seat-fonts-number seatsCardStyle"
          >
            {subscriptionsOnPayment}
          </div>
          <div
            className="s-chip fs-200  seat-fonts seatFontsStyle"
          >
            Subscriptions on Payment
          </div>
        </div>
        {/* <p>Available Seats: {availableSeats}</p> */}
      </div>
      )}
    </div>
  );
};

export default SubscriptionCard;
