import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
// import SpinnerLoader from "../../../components/spinnerLoader";
import {
  GetTransferUserLogListReqRes,
  changeMemberTransferStatus,
} from "../../../../controllers/member/member_transfer_controller";
import { BiTransfer } from "react-icons/bi";
import UserWithAvatar from "../../../components/UserWithAvatar";
import moment from "moment";
import {
  MOMENT_DATE_FORMAT,
  USER_TRANSFER_LOG_STATUS_ENUM,
  USER_TRANSFER_RESPONSE_STATUS,
} from "../../../../core/constants";
import { MainButton, MainButtonType } from "../../../components/button";
import { MdClose, MdDone, MdEdit } from "react-icons/md";
import { CountDown } from ".";
import { CircleIcon, ThemeCircleIcon } from "../../../components/circle_icon";
import { updateMemberTransfer } from "../../../../controllers/member/member_transfer_controller";

const TransferDetailModal = ({
  transferDetail,
  onChangeTransferStatus,
  userId,
  handleLogout,
  onClickClose
}: {
  transferDetail: GetTransferUserLogListReqRes | null;
  userId: string | null;
  onChangeTransferStatus: () => void;
  handleLogout: () => void;
  onClickClose: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [inEditMode, setEditMode] = useState(false);
  const [editableTransferDate, setEditableTransferDate] = useState<Date>(
    new Date()
  );

  useEffect(() => {
    if (transferDetail?.creatorUser.id === userId) {
      setEditableTransferDate(new Date(transferDetail?.transferDate));
    }
  }, [transferDetail]);

  if (!transferDetail)
    return (
      <div
        className="modal-dialog"
        style={{
          width: 460,
          maxWidth: "100%",
          borderRadius: "1em",
          overflow: "visible",
        }}
      >
        <div
          className="modal-content p-3 d-flex flex-column"
          style={{
            backgroundColor: "#fff",
          }}
        >
          No transfer found to show detail
        </div>
      </div>
    );

  const isCreatorMe = transferDetail.creatorUser.id === userId;
  const shouldIResponse =
    transferDetail.userToTransfer.id === userId ||
    transferDetail.userTransferTo.id === userId;
  const amILeaving =
    shouldIResponse && transferDetail.userToTransfer.id === userId;
  const amIEntering =
    shouldIResponse && transferDetail.userTransferTo.id === userId;
  const amIResponseAlready =
    (amILeaving && transferDetail.userToTransferResponse !== 0) ||
    (amIEntering && transferDetail.userTransferToResponse !== 0);
  const amILastPersonToRespone = amIEntering
    ? transferDetail.userToTransferResponse !== 0
    : amILeaving
    ? transferDetail.userTransferToResponse !== 0
    : false;
  async function handleChangeTransferUserResponse(_status: number) {
    if (!transferDetail) return;
    try {
      setLoading(true);
      await changeMemberTransferStatus({
        id: transferDetail.id,
        userResponse: _status,
      });
      setLoading(false);
      //   setVisible(false);
      onChangeTransferStatus();
      if (amILastPersonToRespone) {
        handleLogout();
      }
    } catch (e) {
      //   setVisible(false);
      
    }
  }
  async function handleChangeTransferStatus(_status: number) {
    if (!transferDetail) return;
    try {
      setLoading(true);
      await changeMemberTransferStatus({
        id: transferDetail.id,
        status: _status,
      });
      setLoading(false);
      //   setVisible(false);
      onChangeTransferStatus();
    } catch (e) {
      //   setVisible(false);
      
    }
  }

  async function handleUpdateDate() {
    if (!transferDetail) return;

    try {
      setLoading(true);
      await updateMemberTransfer({
        id: transferDetail.id,
        transferDate: editableTransferDate,
      });
      setEditMode(false);
      setLoading(false);
      onChangeTransferStatus();
    } catch (e) {}
  }
  return (
    <div
      className="modal-dialog"
      style={{
        width: 460,
        maxWidth: "100%",
        borderRadius: "1em",
        overflow: "visible",
      }}
    >
      <div
        className="modal-content p-3 d-flex flex-column"
        style={{
          backgroundColor: "#fff",
        }}
      >
        <div
          className="d-flex align-items-center justify-content-between pb-3"
          style={{
            borderBottom: "1px solid #e5e5e5",
          }}
        >
          <div className="d-flex align-items-center">
            <div
              className="me-2 d-flex align-items-center justify-content-center s-chip outlined"
              style={{
                width: "2.25em",
                height: "2.25em",
                fontSize: "inherit",
                // backgroundColor: '#4a4a4a',
                // color: '#fff'
              }}
              onClick={onClickClose}
            >
              <MdClose size="2em" className="dNone dFlexSm"/>
              <BiTransfer size="2em" className="dNoneSm"/>
            </div>

            <span>User transfer request</span>
          </div>
          <CountDown
            status={transferDetail.status}
            date={transferDetail.remainingTime}
          />
          {/* <div className='d-flex align-items-center'>
                        <div className='s-chip fs-300 outlined' style={{
                            color: 'blue'
                        }}>
                            Finished
                        </div>
                    </div> */}
        </div>

        {/* <label className="mb-2 font-bold mt-3">Details</label> */}
        <Detail
        className="mt-3"
          label="Transfer status"
          value={
            <span
              className="s-chip"
              style={{
                backgroundColor:
                  USER_TRANSFER_LOG_STATUS_ENUM[transferDetail.status].bg,
                color:
                  USER_TRANSFER_LOG_STATUS_ENUM[transferDetail.status].color,
              }}
            >
              {USER_TRANSFER_LOG_STATUS_ENUM[transferDetail.status].title}
            </span>
          }
        />
        <Detail
          label="Requested by"
          value={<UserWithAvatar user={transferDetail?.creatorUser} />}
        />

        <Detail
          label="Transfer date"
          value={
            <div className="d-flex align-items-center">
              {inEditMode ? (
                <div
                  style={{
                    // width: "16em",
                    maxWidth: "100%",
                  }}
                  className="d-flex align-items-center date-picker-styles small"
                >
                  <DatePicker
                    selected={editableTransferDate}
                    onChange={(e) => {
                      setEditableTransferDate(e as Date);
                    }}
                    minDate={new Date()}
                  />
                  <CircleIcon
                    disabled={loading}
                    width="1.5em"
                    height="1.5em"
                    padding="0"
                    type={ThemeCircleIcon.dark}
                    onClick={handleUpdateDate}
                    className="pointer mx-1"
                  >
                    <MdDone size="1.3em" />
                  </CircleIcon>
                  <CircleIcon
                    disabled={loading}
                    width="1.5em"
                    padding="0"
                    height="1.5em"
                    type={ThemeCircleIcon.light}
                    onClick={() => setEditMode(false)}
                    className="pointer"
                  >
                    <MdClose size="1.3em" />
                  </CircleIcon>
                </div>
              ) : (
                <>
                  {isCreatorMe && transferDetail.status === 0 ? (
                    <MdEdit
                      onClick={() => setEditMode(true)}
                      size="1.4em"
                      className="me-2 pointer textSecondary linkOnHover"
                    />
                  ) : null}
                  {moment(transferDetail.transferDate).format(
                    MOMENT_DATE_FORMAT
                  )}
                </>
              )}
            </div>
          }
        />

        {/* <Detail
          label="Updated at"
          value={
            transferDetail.updatedAt
              ? moment(transferDetail.updatedAt).format(MOMENT_DATE_FORMAT)
              : "-"
          }
        /> */}
        <Detail
          label="Transfer request date"
          value={moment(transferDetail.transferCreated).format(
            MOMENT_DATE_FORMAT
          )}
        />
        <Detail
          style={{
            borderBottom: "1px solid #e5e5e5",
          }}
          label="Transfer from"
          className="pb-1 mt-2"
          value={<span className="textSecondary fs-200">Response</span>}
        />
        <div className="mb-2 d-flex align-items-center justify-content-between">
          <UserWithAvatar user={transferDetail.userToTransfer} />
          <span
            className="s-chip"
            style={{
              backgroundColor:
                USER_TRANSFER_RESPONSE_STATUS[
                  transferDetail.userToTransferResponse
                ].bg,
              color:
                USER_TRANSFER_RESPONSE_STATUS[
                  transferDetail.userToTransferResponse
                ].color,
            }}
          >
            {
              USER_TRANSFER_RESPONSE_STATUS[
                transferDetail.userToTransferResponse
              ].title
            }
          </span>
        </div>
        <Detail
          style={{
            borderBottom: "1px solid #e5e5e5",
          }}
          label="Transfer to"
          value={<span className="textSecondary fs-200">Response</span>}
        />
        <div className="mb-2 d-flex align-items-center justify-content-between">
          <UserWithAvatar user={transferDetail.userTransferTo} />
          <span
            className="s-chip"
            style={{
              backgroundColor:
                USER_TRANSFER_RESPONSE_STATUS[
                  transferDetail.userTransferToResponse
                ].bg,
              color:
                USER_TRANSFER_RESPONSE_STATUS[
                  transferDetail.userTransferToResponse
                ].color,
            }}
          >
            {
              USER_TRANSFER_RESPONSE_STATUS[
                transferDetail.userTransferToResponse
              ].title
            }
          </span>
        </div>

        <div className="d-flex flex-column flex-md-row gap-1 align-items-center justify-content-end mt-3">
          {isCreatorMe && transferDetail.status === 0 ? (
            <>
              {/* <MainButton
                                        backgroundColor="transparent"
                                        children={"Revert Transfer"}
                                        type={MainButtonType.light}
                                        borderRadius="4px"
                                        fontSize="12px"
                                        className="d-flex justify-content-start px-2 py-1 w-100"
                                        onClick={() => handleChangeTransferStatus(3)}
                                    ></MainButton> */}

              {/* <MainButton
                                        backgroundColor="transparent"
                                        children={"Force Transfer"}
                                        type={MainButtonType.light}
                                        borderRadius="4px"
                                        fontSize="12px"
                                        className="d-flex justify-content-start px-2 py-1 w-100"
                                        onClick={() => handleChangeTransferStatus(4)}
                                    ></MainButton> */}
              <MainButton
                type={MainButtonType.error}
                borderRadius="24px"
                fontSize="14px"
                minHeight="2em"
                className="my-0 px-3 w-100"
                children={"Cancel Transfer"}
                onClick={() => handleChangeTransferStatus(2)}
              ></MainButton>
            </>
          ) : null}
          {shouldIResponse ? (
            <>
              {amIResponseAlready ? null : (
                <>
                  <MainButton
                    children="Decline transfer"
                    type={MainButtonType.light}
                    borderRadius="24px"
                    fontSize="14px"
                    minHeight="2em"
                    className="my-0 px-3 w-100"
                    disabled={loading}
                    onClick={() => handleChangeTransferUserResponse(1)}
                  ></MainButton>
                  <MainButton
                    children="Accept transfer"
                    type={MainButtonType.dark}
                    borderRadius="24px"
                    fontSize="14px"
                    minHeight="2em"
                    className="px-3 w-100"
                    disabled={loading}
                    onClick={() => handleChangeTransferUserResponse(2)}
                  ></MainButton>
                </>
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const Detail = ({
  label,
  value,
  className = "",
  style = {},
}: {
  label: string;
  className?: string;
  value?: any;
  style?: {};
}) => {
  return (
    <div
      className={`d-flex align-items-center justify-content-between my-1 ${className}`}
      style={style}
    >
      <span className="textSecondary fs-200">{label}</span>
      <div>{value}</div>
    </div>
  );
};

export default TransferDetailModal;
