import { DropdownMenu, Skeleton, Table } from "@radix-ui/themes";
import React, { useState } from "react";
import { nanoid } from "nanoid";
import {
  $ReplaceRoute,
  getUserInitails,
  returnArrayOfNumber,
  UserStatus,
} from "../../../core/utils";
import {
  Priority,
  ResearchStatus,
  SortType,
} from "../../../core/number_extentions";
import RadixTable from "./RadixTable";
import { AppRoutes, MOMENT_DATE_FORMAT } from "../../../core/constants";
import { ImageWithToken } from "../box_list_scroll";
import moment from "moment";
import { useHistory } from "react-router";
import { IconEnum } from "../inputs";
import RadvixProjectsTable from "./RadvixProjectsTable";

const NoDataFound = () => {
  return <>No Data Found</>;
};
const renderStatus = (status: UserStatus) => {
  if (status == UserStatus.active) {
    return (
      <span className="badge bg-info text-white py-px-6 rounded-pill fs-13 fw-normal px-3">
        Active
      </span>
    );
  }
  if (status == UserStatus.readOnly) {
    return (
      <span className="badge bg-success text-white py-px-6 rounded-pill fs-13 fw-normal px-3">
        Read-Only
      </span>
    );
  }
  if (status == UserStatus.deActivated) {
    return (
      <span className="badge bg-warning text-white py-px-6 rounded-pill fs-13 fw-normal px-3">
        Deactive
      </span>
    );
  }
  if (status == UserStatus.invited) {
    return (
      <span className="badge bg-warning text-white py-px-6 rounded-pill fs-13 fw-normal px-3">
        Invited
      </span>
    );
  }
};

const Icons = {
  [IconEnum.Book]: <img src="/images/icons/book.svg" alt="Book Icon" width={20} height={20} />,
  [IconEnum.Calculator]: <img src="/images/icons/calculator.svg" alt="Calculator Icon" width={20} height={20} />,
  [IconEnum.Robot]: <img src="/images/icons/robot.svg" alt="Robot Icon" width={20} height={20} />,
  [IconEnum.Bulb]: <img src="/images/icons/bulb.svg" alt="Bulb Icon" width={20} height={20} />,
  [IconEnum.Microscope]: <img src="/images/icons/microscope.svg" alt="Microscope Icon" width={20} height={20} />,
};

const renderPriority = (priority: Priority) => {
  if (priority == Priority.High) {
    return (
      <span className="badge bg-danger text-white py-px-6 rounded-pill fs-13 fw-normal px-3">
        High
      </span>
    );
  }
  if (priority == Priority.Medium) {
    return (
      <span className="badge bg-warning text-white py-px-6 rounded-pill fs-13 fw-normal px-3">
        Medium
      </span>
    );
  }
  if (priority == Priority.Low) {
    return (
      <span className="badge bg-success text-white py-px-6 rounded-pill fs-13 fw-normal px-3">
        Low
      </span>
    );
  }
};
const RadixTableSubscription = ({
  data,
  columns,
  className = "",
  rowClassName = "",
  loading = false,
  loadingCount = 6,
  headerRowClassName = "",
  noDataFound = "No record found",
  onHeaderClick,
  customHeaderWidth = false,
  onExpandedRowsChange,
  handleDuplicate,
  handleChangeUserStatus,
}: any) => {
const history = useHistory();
  const [currentSortColumn, setCurrentSortColumn] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<SortType>(SortType.ASC);
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handleHeaderClick = (columnHeaderName: string) => {
    const clickedColumn = columns.find(
      (column: any) => column.Header === columnHeaderName
    );
    let localSortDirection: SortType = sortDirection;
    if (
      clickedColumn &&
      clickedColumn.Header !== "Actions" &&
      clickedColumn.Header !== "" &&
      (clickedColumn.removeSort == null || clickedColumn.removeSort === false)
    ) {
      setCurrentSortColumn(columnHeaderName);
      if (columnHeaderName === currentSortColumn) {
        localSortDirection =
          sortDirection === SortType.ASC ? SortType.DESC : SortType.ASC;
        setSortDirection(localSortDirection);
      }
      onHeaderClick(clickedColumn.accessor, localSortDirection);
    }
  };

  const handleRowClick = (rowId: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
    onExpandedRowsChange({
      ...expandedRows,
      [rowId]: !expandedRows[rowId],
    });
  };

  const renderIcon = (value: any) => {
    if (value === IconEnum.Book) {
      return (
        <img
          src="/images/icons/book.svg"
          alt="Book Icon"
          width={20}
          height={20}
        />
      );
    }
    if (value === IconEnum.Calculator) {
      return (
        <img
          src="/images/icons/calculator.svg"
          alt="Calculator Icon"
          width={20}
          height={20}
        />
      );
    }
    if (value === IconEnum.Robot) {
      return (
        <img
          src="/images/icons/robot.svg"
          alt="Robot Icon"
          width={20}
          height={20}
        />
      );
    }
    if (value === IconEnum.Bulb) {
      return (
        <img
          src="/images/icons/bulb.svg"
          alt="Bulb Icon"
          width={20}
          height={20}
        />
      );
    }
    if (value === IconEnum.Microscope) {
      return (
        <img
          src="/images/icons/microscope.svg"
          alt="Book Icon"
          width={20}
          height={20}
        />
      );
    } else {
      return <i className="fa fa-grip-vertical me-2 color-gray-600 fs-13"></i>;
    }
  };

  return (
    <div className="table-responsive table-sticky-header" id="isFilterShown">
      <table
        className={`table mb-0 ${className}`}
        style={{ tableLayout: customHeaderWidth ? "fixed" : "auto" }}
      >
        <Table.Header>
          <Table.Row className={headerRowClassName}>
            {columns.map((column: any) => (
              <Table.ColumnHeaderCell
                key={nanoid()}
                scope="col"
                className="p-3 cursor-pointer text-nowrap"
                onClick={() => handleHeaderClick(column.Header)}
                style={{ width: column.width ? column.width : "auto" }}
              >
                {column.Header}
              </Table.ColumnHeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading ? (
            returnArrayOfNumber(loadingCount).map((_, index) => (
              <Table.Row key={nanoid()} className={rowClassName}>
                {columns.map((column: any) => (
                  <Table.Cell
                    key={nanoid()}
                    className=""
                    style={{
                      minHeight: "1em",
                      ...(column.align ? { textAlign: column.align } : {}),
                      ...(column.maxWidth ? { maxWidth: column.maxWidth } : {}),
                    }}
                  >
                    <Skeleton
                      width="100%"
                      height="1.25em"
                      containerClassName="lineHeight1"
                      {...column.skeletonConfig}
                    />
                  </Table.Cell>
                ))}
              </Table.Row>
            ))
          ) : data && data.length > 1 ? (
            data.map((row: any, _idx: any) => (
              <>
                <Table.Row
                  key={nanoid()}
                  className={rowClassName}
                  onClick={() => handleRowClick(row.id)}
                >
                  {columns.map((column: any) => column.Cell(row, _idx))}
                </Table.Row>
                {expandedRows[row.id] && row.childList.length > 1 && (
                  <Table.Row
                    key={nanoid()}
                    className={`${rowClassName} child-row`}
                  >
                    <Table.Cell colSpan={columns.length} className="pt-0 pb-0 px-xxl-0">
                      {row.childList && row.childList.length > 1 ? (
                        <RadvixProjectsTable
                          data={row.childList}
                          showHeader={false}
                          columns={[
                            {
                              Header: "",
                              accessor: "",
                              Cell: (_row: any) => {
                                debugger;
                                const _userName =
                                  _row.firstName || _row.lastName
                                    ? _row.firstName + " " + _row.lastName
                                    : "-";
                                return (
                                  <Table.Cell
                                    className="p-3 child-row-background border-bottom-0 "
                                    style={{ minWidth: '294px', width: '294px' }}
                                  >
                                    <div className="line-clamp-2 textWrapLabel">
                                    {_userName.split(' ').slice(0, 4).join(' ')}{' '}                                    
                                    </div>
                                  </Table.Cell>
                                );
                              },
                            },
                            {
                              Header: "",
                              accessor: "",
                              Cell: (props: any) => {
                                return (
                                  <>
                                    <Table.Cell
                                      className="p-3 text-nowrap child-row-background border-bottom-0 "
                                      style={{ minWidth: '150px', width: '150px' }}
                                    >                                      
                                    </Table.Cell>
                                  </>
                                );
                              },
                            },
                            {
                              Header: "",
                              accessor: "",
                              Cell: (props: any) => {
                                return (
                                  <>
                                    <Table.Cell
                                      className="p-3 child-row-background border-bottom-0 "
                                      style={{ minWidth: '339px', width: '339px' }}
                                    >
                                      {props.email}
                                    </Table.Cell>
                                  </>
                                );
                              },
                            },
                            {
                              Header: "",
                              accessor: "",
                              Cell: (props: any) => {
                                return (
                                  <Table.Cell
                                    className="p-3 child-row-background border-bottom-0 "
                                    style={{ width: "150px" }}
                                  >
                                    {moment(props.joinedDate).format("ll")}
                                  </Table.Cell>
                                );
                              },
                            },
                            {
                              Header: "",
                              accessor: "",
                              Cell: (props: any) => {
                                return (
                                  <Table.Cell
                                    className="p-3 child-row-background border-bottom-0 "
                                    style={{ width: "150px" }}
                                  ></Table.Cell>
                                );
                              },
                            },
                            {
                              Header: "",
                              accessor: "",
                              Cell: (props: any) => {
                                return (
                                  <Table.Cell
                                    className="p-3 child-row-background border-bottom-0 "
                                    style={{ width: "150px" }}
                                  >
                                    <></>
                                  </Table.Cell>
                                );
                              },
                            },
                            {
                              Header: "",
                              accessor: "",
                              Cell: (props: any) => {
                                return (
                                  <Table.Cell
                                    className="p-3 child-row-background border-bottom-0 "
                                    style={{ width: "150px" }}
                                  ><></>
                                    {/* {props.activeUsers.length} */}
                                  </Table.Cell>
                                );
                              },
                            },
                            {
                              Header: "",
                              accessor: "",
                              Cell: (props: any) => {
                                return (
                                  <Table.Cell
                                    className="p-3 child-row-background border-bottom-0 "
                                    style={{ width: "150px" }}
                                  ><></>
                                    {/* {props.workspace} */}
                                  </Table.Cell>
                                );
                              },
                            },
                            {
                              Header: "",
                              accessor: "",
                              Cell: (props: any) => {
                                return (
                                  <Table.Cell
                                    className="p-3 child-row-background border-bottom-0 "
                                    style={{ width: "150px" }}
                                  ><></>
                                    {/* {props.billingCycle} */}
                                  </Table.Cell>
                                );
                              },
                            },
                            {
                              Header: "",
                              accessor: "",
                              Cell: (props: any) => {
                                return (
                                  <Table.Cell
                                    className="p-3 child-row-background border-bottom-0 "
                                    style={{ width: "150px" }}
                                  >
                                    <></>
                                  </Table.Cell>
                                );
                              },
                            },
                            {
                              Header: "",
                              accessor: "",
                              Cell: (props: any) => {
                                return (
                                  <Table.Cell
                                    className="p-3 child-row-background border-bottom-0 "
                                    style={{ width: "150px" }}
                                  >{renderStatus(props.userAccess)}</Table.Cell>
                                );
                              },
                            },
                            {
                              Header: "",
                              Cell: (props: any) => {
                                return (
                                  <>
                                    {props.id !== "uncategorized" ? (
                                      <Table.Cell className="p-3 text-nowrap child-row-background border-bottom-0 ">
                                        <div className="d-flex align-items-center gap-3">
                                          <div className="btn-group">
                                            <button
                                              type="button"
                                              className="btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0"
                                              // onClick={(e) => {
                                              //   e.preventDefault();
                                              //   this.handleNewProject(props.id);
                                              // }}
                                            >
                                              <svg
                                                className="hover-fill"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="15"
                                                height="15"
                                                viewBox="0 0 15 15"
                                              >
                                                <rect
                                                  width="15"
                                                  height="15"
                                                  fill="white"
                                                  fill-opacity="0.01"
                                                ></rect>
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z"
                                                  fill="currentColor"
                                                ></path>
                                              </svg>
                                              <span className="ps-2">Update Account</span>
                                            </button>
        
                                            <DropdownMenu.Root>
                                              <DropdownMenu.Trigger>
                                                <button
                                                  type="button"
                                                  className="btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <span className="visually-hidden">
                                                    Toggle Dropdown
                                                  </span>
                                                </button>
                                              </DropdownMenu.Trigger>
                                              <DropdownMenu.Content>
                                                <DropdownMenu.Item className="px-0">
                                                  <button
                                                    className="dropdown-item fs-14"
                                                    onClick={() =>
                                                        handleChangeUserStatus(
                                                          { label: "Readonly", value: 1 },
                                                          props.id
                                                        )
                                                      }
                                                  >
                                                    Switch to Read-Only
                                                  </button>
                                                </DropdownMenu.Item>
                                                <DropdownMenu.Item className="px-0">
                                                  <button
                                                    className="dropdown-item fs-14"
                                                    onClick={() =>
                                                        handleChangeUserStatus(
                                                            { label: "Active", value: 0 },
                                                          props.id
                                                        )
                                                      }
                                                  >
                                                    Switch to Active
                                                  </button>
                                                </DropdownMenu.Item>
                                                <DropdownMenu.Item className="px-0">
                                                  <button
                                                    className="dropdown-item fs-14"
                                                    onClick={() =>
                                                        handleChangeUserStatus(
                                                            { label: "Deactivated", value: 2 },
                                                          props.id
                                                        )
                                                      }
                                                  >
                                                    Switch to Deactive
                                                  </button>
                                                </DropdownMenu.Item>
                                                <DropdownMenu.Item className="px-0">
                                                  <button
                                                    className="dropdown-item fs-14"
                                                    onClick={() =>
                                                        handleChangeUserStatus(
                                                            { label: "Deactivated", value: 2 },
                                                          props.id
                                                        )
                                                      }
                                                  >
                                                    Delete User
                                                  </button>
                                                </DropdownMenu.Item>
                                              </DropdownMenu.Content>
                                            </DropdownMenu.Root>
                                          </div>
                                        </div>
                                      </Table.Cell>
                                    ) : (
                                      <Table.Cell className="p-3 text-nowrap parent-row-background"></Table.Cell>
                                    )}
                                  </>
                                );
                              },
                            },
                          ]}
                        />
                      ) : (
                        <div>No researches found</div>
                      )}
                    </Table.Cell>
                  </Table.Row>
                )}
              </>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={columns.length} className="text-center">
                <div className="no-data-found">
                  <img
                    className="no-data-found-img"
                    src="/Images/images/Guy Standing And Finding Data.png"
                  />
                  {noDataFound}
                </div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </table>
    </div>
  );
};

export default RadixTableSubscription;
