import { DropdownMenu, Skeleton, Table } from '@radix-ui/themes';
import React, { useState } from 'react';
import { nanoid } from 'nanoid';
import { $ReplaceRoute, getUserInitails, returnArrayOfNumber } from '../../../core/utils';
import { Priority, ResearchStatus, SortType } from '../../../core/number_extentions';
import RadixTable from './RadixTable';
import { AppRoutes, MOMENT_DATE_FORMAT } from '../../../core/constants';
import { ImageWithToken } from '../box_list_scroll';
import moment from 'moment';
import { useHistory } from 'react-router';
import GroupedAvatars from '../groupedAvatars';
import UserWithAvatar from '../UserWithAvatar';
import { IconEnum } from '../inputs';
import RadvixProjectsTable from './RadvixProjectsTable';
import { CheckIcon } from '@radix-ui/react-icons';

const NoDataFound = () => {
  return <>No Data Found</>;
};

const renderStatus = (status: ResearchStatus) => {
  if (status === ResearchStatus.OnGoing) {
    return (
      <span className='badge bg-info text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
        On Going
      </span>
    );
  }
  if (status === ResearchStatus.Completed) {
    return (
      <span className='badge bg-success text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
        Completed
      </span>
    );
  }
  if (status === ResearchStatus.Delayed) {
    return (
      <span className='badge bg-warning text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
        Delayed
      </span>
    );
  }
  if (status === ResearchStatus.OnHold) {
    return (
      <span
        className='badge bg-default text-white py-px-6 rounded-pill fs-13 fw-normal px-3'
        style={{ background: '#7E808A' }}
      >
        On Hold
      </span>
    );
  }
};

const renderPriority = (priority: Priority) => {
  if (priority === Priority.High) {
    return (
      <span className='badge bg-danger text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
        High
      </span>
    );
  }
  if (priority === Priority.Medium) {
    return (
      <span className='badge bg-warning text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
        Medium
      </span>
    );
  }
  if (priority === Priority.Low) {
    return (
      <span className='badge bg-success text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
        Low
      </span>
    );
  }
};

const RadixTableTasks = ({
  data,
  columns,
  className = '',
  rowClassName = '',
  loading = false,
  loadingCount = 6,
  headerRowClassName = '',
  noDataFound = 'No record found',
  onHeaderClick,
  customHeaderWidth = false,
  onExpandedRowsChange,
  handleDuplicate,
  isChecked = false,
  markComplete,
}: any) => {
  const history = useHistory();
  const [currentSortColumn, setCurrentSortColumn] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<SortType>(SortType.ASC);
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});

  const handleHeaderClick = (columnHeaderName: string) => {
    const clickedColumn = columns.find((column: any) => column.Header === columnHeaderName);
    let localSortDirection: SortType = sortDirection;
    if (
      clickedColumn &&
      clickedColumn.Header !== 'Actions' &&
      clickedColumn.Header !== '' &&
      (clickedColumn.removeSort == null || clickedColumn.removeSort === false)
    ) {
      setCurrentSortColumn(columnHeaderName);
      if (columnHeaderName === currentSortColumn) {
        localSortDirection = sortDirection === SortType.ASC ? SortType.DESC : SortType.ASC;
        setSortDirection(localSortDirection);
      }
      onHeaderClick(clickedColumn.accessor, localSortDirection);
    }
  };

  const handleRowClick = (rowId: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
    onExpandedRowsChange({
      ...expandedRows,
      [rowId]: !expandedRows[rowId],
    });
  };

  const renderIcon = (value: any) => {
    if (value === IconEnum.Book) {
      return <img src='/images/icons/book.svg' alt='Book Icon' width={20} height={20} />;
    }
    if (value === IconEnum.Calculator) {
      return (
        <img src='/images/icons/calculator.svg' alt='Calculator Icon' width={20} height={20} />
      );
    }
    if (value === IconEnum.Robot) {
      return <img src='/images/icons/robot.svg' alt='Robot Icon' width={20} height={20} />;
    }
    if (value === IconEnum.Bulb) {
      return <img src='/images/icons/bulb.svg' alt='Bulb Icon' width={20} height={20} />;
    }
    if (value === IconEnum.Microscope) {
      return <img src='/images/icons/microscope.svg' alt='Book Icon' width={20} height={20} />;
    } else {
      return <i className='fa fa-grip-vertical me-2 color-gray-600 fs-13'></i>;
    }
  };
  return (
    <div className='table-responsive table-sticky-header' id='isFilterShown'>
      <table
        className={`table mb-0 ${className}`}
        style={{ tableLayout: customHeaderWidth ? 'fixed' : 'auto' }}
      >
        <Table.Header>
          <Table.Row className={headerRowClassName}>
            {columns.map((column: any) => (
              <Table.ColumnHeaderCell
                key={nanoid()}
                scope='col'
                className='p-3 cursor-pointer text-nowrap'
                onClick={() => handleHeaderClick(column.Header)}
                style={{ width: column.width ? column.width : 'auto' }}
              >
                {column.Header}
                {column.Header.length > 0 && (
                  <img
                    src='/images/icons/caret-sort.svg'
                    alt='radvix'
                    width={14}
                    height={14}
                    style={{ marginLeft: '2%' }}
                  />
                )}
              </Table.ColumnHeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading ? (
            returnArrayOfNumber(loadingCount).map((_, index) => (
              <Table.Row key={nanoid()} className={rowClassName}>
                {columns.map((column: any) => (
                  <Table.Cell
                    key={nanoid()}
                    className=''
                    style={{
                      minHeight: '1em',
                      ...(column.align ? { textAlign: column.align } : {}),
                      ...(column.maxWidth ? { maxWidth: column.maxWidth } : {}),
                    }}
                  >
                    <Skeleton
                      width='100%'
                      height='1.25em'
                      containerClassName='lineHeight1'
                      {...column.skeletonConfig}
                    />
                  </Table.Cell>
                ))}
              </Table.Row>
            ))
          ) : data && data.length > 0 ? (
            data.map((row: any, _idx: any) => (
              <React.Fragment key={row.appTask.id}>
                <Table.Row
                  key={row.appTask.id}
                  className={rowClassName}
                  onClick={() => handleRowClick(row.appTask.id)}
                >
                  {columns.map((column: any) => column.Cell(row, _idx))}
                </Table.Row>
                {expandedRows[row.appTask.id] && row.subAppTasks && row.subAppTasks.length > 0 && (
                  <Table.Row key={`${row.id}-sub`} className={`${rowClassName} child-row`}>
                    <Table.Cell colSpan={columns.length} className='p-3 pt-0 pb-0'>
                      <RadvixProjectsTable
                        data={row.subAppTasks}
                        columns={[
                          {
                            // Header: "Task name",
                            // accessor: "title",
                            // className:"border-top-0",
                            Cell: (_props: any) => {
                              return (
                                <Table.Cell
                                  className='p-3'
                                  style={{ minWidth: '278px', width: '278px' }}
                                >
                                  <div className='line-clamp-2'>
                                    <span
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        history.push(
                                          $ReplaceRoute(
                                            `${AppRoutes.task_profile.replace(
                                              ':id',
                                              _props.id.toString() ?? ''
                                            )}?researchId=${_props.researchId}`
                                          )
                                        );
                                      }}
                                      title={_props.title}
                                    >
                                      {renderIcon(_props.icon)}{' '}
                                      {_props.title.split(' ').slice(0, 4).join(' ')}
                                    </span>
                                  </div>
                                </Table.Cell>
                              );
                            },
                          },
                          {
                            // Header: "Creator",
                            // accessor: "creatorUser",
                            // className:"border-top-0",
                            Cell: (props: any) => {
                              return (
                                <Table.Cell
                                  className='p-3'
                                  style={{ minWidth: '339px', width: '339px' }}
                                >
                                  <div className='d-flex align-items-center text-nowrap'>
                                    {props.creatorUser.image ? (
                                      <ImageWithToken
                                        originalImage={props.creatorUser.image}
                                        hasImage={props.creatorUser.image ? true : false}
                                        alt='Avatar'
                                        className='rounded-circle avatar rounded-avatar-dashboard'
                                        src={props.creatorUser.image}
                                      />
                                    ) : (
                                      <div className='avatar w-px-25 h-px-25 rounded-circle bg-primary'>
                                        <label className='fw-medium text-white fs-10'>
                                          {getUserInitails(
                                            `${props.creatorUser.firstName} ${props.creatorUser.lastName}`
                                          )}
                                        </label>
                                      </div>
                                    )}
                                    <span
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        history.push(
                                          $ReplaceRoute(
                                            `${AppRoutes.member_profile.replace(
                                              ':id',
                                              props.creatorUser.id?.toString() ?? ''
                                            )}`
                                          )
                                        );
                                      }}
                                    >
                                      {props.creatorUser.firstName} {props.creatorUser.lastName}
                                    </span>
                                  </div>
                                </Table.Cell>
                              );
                            },
                          },
                          {
                            // Header: "Users",
                            // accessor: "",
                            // className:"border-top-0",
                            Cell: (props: any) => {
                              return (
                                <Table.Cell
                                  className='p-3'
                                  style={{ minWidth: '200px', width: '200px' }}
                                >
                                  <GroupedAvatars
                                    containerWidth={100}
                                    maxAvatars={2}
                                    tippyContent={
                                      <div className='d-flex flex-column py-2 pb-0'>
                                        {props.users.map((_us: any) => (
                                          <UserWithAvatar
                                            parentStyle={{
                                              marginBottom: '0.25em',
                                            }}
                                            key={_us.id}
                                            user={_us}
                                          />
                                        ))}
                                      </div>
                                    }
                                    users={props.users}
                                  />
                                </Table.Cell>
                              );
                            },
                          },
                          {
                            // Header: "Deadline",
                            // accessor: "endDate",
                            // className:"border-top-0",
                            Cell: (props: any) => {
                              return (
                                <Table.Cell
                                  className='p-3 text-nowrap'
                                  style={{ minWidth: '150px', width: '150px' }}
                                >
                                  {moment(props.endDate).format(MOMENT_DATE_FORMAT)}
                                </Table.Cell>
                              );
                            },
                          },
                          {
                            Header: 'Status',
                            accessor: 'status',
                            className: 'border-top-0',
                            Cell: (props: any) => {
                              return (
                                <Table.Cell
                                  className='p-3'
                                  style={{ minWidth: '150px', width: '150px' }}
                                >
                                  {renderStatus(props.status)}
                                </Table.Cell>
                              );
                            },
                          },
                          {
                            // Header: "",
                            // className:"border-top-0",
                            Cell: (props: any) => {
                              return (
                                <Table.Cell className='p-3 text-nowrap'>
                                  <div className='d-flex align-items-center gap-2'>
                                    <div className='btn-group'>
                                      <button
                                        type='button'
                                        className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                                        onClick={(e) => {
                                          e.preventDefault();
                                          history.push(
                                            $ReplaceRoute(
                                              `${AppRoutes.task_profile.replace(
                                                ':id',
                                                props.id.toString() ?? ''
                                              )}`
                                            )
                                          );
                                        }}
                                      >
                                        <i className='fa fa-eye me-px-6'></i>
                                        View Details
                                      </button>

                                      <DropdownMenu.Root>
                                        <DropdownMenu.Trigger>
                                          <button
                                            type='button'
                                            className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split'
                                            data-bs-toggle='dropdown'
                                            aria-expanded='false'
                                          >
                                            <span className='visually-hidden'>Toggle Dropdown</span>
                                          </button>
                                        </DropdownMenu.Trigger>
                                        <DropdownMenu.Content>
                                          <DropdownMenu.Item className='px-0'>
                                            <button
                                              className='dropdown-item fs-14'
                                              onClick={(e) => {
                                                e.preventDefault();
                                                history.push(
                                                  $ReplaceRoute(
                                                    `${AppRoutes.task_profile.replace(
                                                      ':id',
                                                      props.id.toString() ?? ''
                                                    )}`
                                                  )
                                                );
                                              }}
                                            >
                                              View Details
                                            </button>
                                          </DropdownMenu.Item>
                                          <DropdownMenu.Item className='px-0'>
                                            <button
                                              className='dropdown-item fs-14'
                                              onClick={(e) => {
                                                e.preventDefault();
                                                history.push(
                                                  $ReplaceRoute(
                                                    `${AppRoutes.task_edit.replace(
                                                      ':id',
                                                      props.id.toString() ?? ''
                                                    )}`
                                                  )
                                                );
                                              }}
                                            >
                                              Edit Task
                                            </button>
                                          </DropdownMenu.Item>
                                          <DropdownMenu.Item className='px-0'>
                                            <button
                                              className='dropdown-item fs-14'
                                              onClick={() => {
                                                history.push(
                                                  $ReplaceRoute(
                                                    `${AppRoutes.data_new}?taskId=${props.id}`
                                                  )
                                                );
                                              }}
                                            >
                                              Add Dataset
                                            </button>
                                          </DropdownMenu.Item>
                                        </DropdownMenu.Content>
                                      </DropdownMenu.Root>
                                    </div>
                                    {props.status != ResearchStatus.Completed && (
                                      <div
                                        className='h-px-29 w-px-25 p-0 align-items-center justify-content-center
                                  d-sm-flex cursor-pointer  markAsChecked'
                                      >
                                        <CheckIcon
                                          style={{
                                            height: '15px',
                                            width: '15px',
                                            color: '#299764',
                                          }}
                                          onClick={(e) => {
                                            e.preventDefault();

                                           markComplete(
                                              props.id, 
                                              props.researchId
                                            );
                                          }}
                                        ></CheckIcon>
                                      </div>
                                    )}
                                  </div>
                                </Table.Cell>
                              );
                            },
                          },
                        ]}
                      />
                    </Table.Cell>
                  </Table.Row>
                )}
              </React.Fragment>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={columns.length} className='text-center'>
                <div className='no-data-found'>
                  <img
                    className='no-data-found-img'
                    src='/Images/images/Guy Standing And Finding Data.png'
                  />
                  {noDataFound}
                </div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </table>
    </div>
  );
};

export default RadixTableTasks;
