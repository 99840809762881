import { AxiosResponse } from "axios";
import { HTTP, handleUnauthorizedRequest } from "../../../core/http_common";
import {
  WorkspaceCollaboration,
  WorkspaceRequest,
  isWorkSpaceExist_Request,
  workSpace_request,
  workspaceFilter_request,
} from "../../models/requests/workSpace/workSpace_request";

export class RemoteWorkspace {
  CreateWorkSpace(
    body: workSpace_request,
    action: (res: any) => any,
    error: (err: any) => any
  ) {    
    return HTTP.post(`/workSpace/CreateWorkSpace`, body)
      .then((res) => action(res))
      .catch((err) =>  {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  GetList(
    body: workspaceFilter_request,
    action: (res: WorkspaceCollaboration) => any,
    error: (err: any) => any
  ) {    
    return HTTP.post(`/workSpace/GetList`, body)
    .then((res: AxiosResponse<any>) => action(res.data))
      .catch((err) =>  {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  SendRequest(
    body: WorkspaceRequest,
    action: (res: any) => any,
    error: (err: any) => any
  ) {    
    return HTTP.post(`/workSpace/SendRequest`, body)
      .then((res) => action(res))
      .catch((err) =>  {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  UpdateRequest(
    body: any,
    action: (res: any) => any,
    error: (err: any) => any
  ) {    
    return HTTP.post(`/workSpace/UpdateWorkspaceRequest`, body)
      .then((res) => action(res))
      .catch((err) =>  {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  isWorkSpaceExist(action: (res: any) => any, error: (err: any) => any) {
    
    let config = {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    return HTTP.post(`/User/IsWorkspaceExist`, null,config)
      .then((res) => action(res))
      .catch((err) =>  {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
}
