import moment from "moment";
// import React, { useMemo } from "react";
// import { AppConstants } from "../../../core/constants";
// import { Theme } from "../../../core/utils";
// import { store } from "../../../data/storeMain";
// import { IconTextRow } from "../icon_text_horizontal";
import ScrollToBottom from "react-scroll-to-bottom";
import { ImageWithToken } from "../box_list_scroll";
import SpinnerLoader from "../spinnerLoader";
// import ShowFilesInTable from "../ShowFilesInTable";
import File from "../File";
import { IMAGE_BASE_URL } from "../../../core/http_common";
import { ProfileTypes } from "../../../core/number_extentions";
// import { IMAGE_BASE_URL } from "../../../core/http_common";

type Props = {
  chats: any[];
  loading?: boolean;
};

export default function ChatArea({ chats, loading }: Props) {
  // const _chats = useMemo(()=> chats.reverse(), [chats])
  const userId = localStorage.getItem("userId");
  return (
    <ScrollToBottom className="scrollToBottomClassName">
      <div className="chatAreaWrapper d-flex flex-column h-100">
        {loading && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0,0.4)",
              borderRadius: "1em",
              color: "#fff",
            }}
          >
            <SpinnerLoader />
          </div>
        )}
        {chats.map((item) => (
          <Message
            isMe={item.userId === userId}
            detail={item}
            //   date={moment(item.createDate).format("YYYY/MM/DD")}
          />
        ))}
      </div>
    </ScrollToBottom>
  );
}

type MsgProps = {
  isMe: boolean;
  detail: {
    userFirstName: string;
    userLastName: string;
    userProfilePicture: string | null;
    message: string;
    createDate: string;
    attachments?: any[];
    read: boolean;
  };
};

const Message = ({
  isMe,
  detail: {
    userFirstName,
    userLastName,
    userProfilePicture,
    message,
    createDate,
    attachments,
    read,
  },
}: MsgProps) => {
  return (
    <div style={{maxWidth: '100%'}}
      className={`
  chatMessage align-self-${isMe ? "end me" : "start"}
    d-flex flex-column align-items-${isMe ? "end" : "start"}
    `}
    >
      <div
        className={`d-flex align-items-center gap-2 ${
          isMe ? "flex-row-reverse" : ""
        }`}
      >
        <ImageWithToken
          src={
            userProfilePicture
              ? IMAGE_BASE_URL + userProfilePicture
              : "/images/images/img_avatar.png"
          }
          originalImage={userProfilePicture}
          alt="Avatar"
          className="rounded-circle avatar"
          //   width="40px"
          //   height="40px"
          style={{ height: "2em", width: "2em" }}
          hasImage={userProfilePicture ? true : false}
        />
        <span className={`textSecondary fs-100`}>
          {userFirstName} {userLastName} (
          {/* use moment format like: Today, 14:23PM */}
          {/* {moment(createDate).format(" HH:mm")} */}
          {moment(createDate).calendar()})
        </span>
      </div>
      <div
        className={`
        chatMessageContent ${isMe ? "me" : ""}
      `}
        // dangerouslySetInnerHTML={{ __html: message }}
      >
        <p className="mb-0">{message}</p>
        {/* {message} */}

        {(attachments && attachments.length > 0) ? (
          <div className="mt-1 d-flex flex-wrap gap-1">
            {/* <div className={`mt-2`}> */}

            {
              attachments.map((_file) => <File 
                file={_file}
                key={_file.id}
                fileVariant="text"
                researchId={_file.sectionId}
                profileType={_file.profileType}
                // linkVariant="text"
              />)
            }
            {/* <ShowFilesInTable medias={attachments} /> */}
            {/* </div> */}
          </div>
        ) : null}
      </div>
    </div>
  );
};