import Tippy from "@tippyjs/react";
import { useEffect, useState } from "react";
import {
  // GetEquipmentReservationAllReqBody,
  // GetEquipmentReservationReqBody,
  GetReservationSearchReqRes,
  getEquipmentReservationsAll,
  getReservationSearch,
} from "../../../controllers/equipmentReservation";
import Highlighter from "react-highlight-words";
import {
  $ReplaceRoute,
  getUserTitle,
  removeDuplicateObjWithKey,
} from "../../../core/utils";

import { MainButton, MainButtonType } from "../../components/button";
// import { DEFAULT_DAYS } from "../equipmentReservation";
// import DatePicker from "react-datepicker";
import { SelectComponent } from "../../components/select_input";
import {
  // AppConstants,
  AppRoutes,
  MOMENT_DATE_TIME_FORMAT,
  RESERVATION_STATUS_LABELS,
  RESERVATION_STATUS_STYLES,
} from "../../../core/constants";
import TippyFooter from "../../components/TippyFooter";
import TippyHeader from "../../components/TippyHeader";
import { FaFilter } from "react-icons/fa";
import NewTable from "../../components/NewTable/NewTable";
import EmptyChip from "../../components/EmptyChip";
import moment from "moment";
import { ImageWithToken } from "../../components/box_list_scroll";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import ReactPaginate from "react-paginate";
import { MdMoreVert, MdOutlineFilterAlt } from "react-icons/md";
import { Link } from "react-router-dom";
import { InputIcon } from "../../components/search_box";
import { RxMagnifyingGlass } from "react-icons/rx";
import { useDebounceEffect } from "../../../hooks/useDebounceEffect";
import EquipmentReservationContent from "../equipmentReservation/equipmentReservationContent";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import PhoneSearch from "../../components/PhoneSearch";
import UserWithAvatar from "../../components/UserWithAvatar";
import { IMAGE_BASE_URL } from "../../../core/http_common";
import { SortType } from "../../../core/number_extentions";
import FilterSection from "../../components/FilterSection/filterSection";
import DOMPurify from "dompurify";
import { SetHeaderTitle } from "../../../data/storeMain/actions/header_title_action";
import { store } from "../../../data/storeMain";
// import { IMAGE_BASE_URL } from "../../../core/http_common";
// type Props = {};
const EquipmentReservationAll = () => {
  const [loading, setLoading] = useState(false);
  const [allReservation, setAllReservation] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [showFilterOptions, setShowFilterOptions] = useState(false);

  // const [data, setData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>({
    LaboratoryId: null,
    EquipmentId: null,
  });

  const [paginate, setPaginate] = useState<{
    pageSize: { label: string; value: number };
    pageNumber: number;
    pageCount: number;
    totalCount: number;
  }>({
    pageSize: { label: "10", value: 10 },
    pageNumber: 0,
    pageCount: 0,
    totalCount: 0,
  });

  useEffect(() => {
    document.title = `All Reservations | Radvix`;
    store.dispatch(SetHeaderTitle('Laboratory'))
    handleFilterReservationList(
      {
        LaboratoryId: null,
        EquipmentId: null,
        PageSize: 10,
        PageNumber: 1,
        SearchParameter: "",
      },
      "",
      SortType.AutoSelect
    );
  }, []);

  async function handleFilterReservationList(
    _filterForm: {
      LaboratoryId: number | null;
      EquipmentId: number | null;
      PageSize: number;
      PageNumber: number;
      SearchParameter: string;
    },
    columnHeaderName: string,
    sortDirection: SortType
  ) {
    try {
      if (sortDirection == undefined || sortDirection == null) {
        sortDirection = SortType.AutoSelect;
      }
      setLoading(true);
      const _result = await getEquipmentReservationsAll({
        LaboratoryId: _filterForm.LaboratoryId,
        EquipmentId: _filterForm.EquipmentId,
        PageNumber: _filterForm.PageNumber,
        PageSize: _filterForm.PageSize,
        SearchParameter: _filterForm.SearchParameter,
        columnHeaderName: columnHeaderName,
        sortDirection: sortDirection,
      });

      setAllReservation(_result.equipmentReservations);
      setPaginate((prev) => ({
        ...prev,
        pageCount: Math.ceil(_result.count! / paginate.pageSize.value),
        totalCount: _result.count,
      }));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      // Handle error if needed
    }
  } // function handlePaginate(
  //   array: any[],
  //   page_size: number,
  //   page_number: number
  // ) {
  //   console.log(array, page_size, page_number);
  //   // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  //   return array.slice((page_number - 1) * page_size, page_number * page_size);
  // }

  useDebounceEffect(
    () => {
      if (searchValue.length > 0)
        handleFilterReservationList(
          {
            ...filterData,
            PageNumber: paginate.pageNumber + 1,
            PageSize: paginate.pageSize.value,
            SearchParameter: searchValue,
          },
          "",
          SortType.AutoSelect
        );
    },
    500,
    [searchValue]
  );
  const headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    handleFilterReservationList(
      {
        ...filterData,
        PageNumber: paginate.pageNumber + 1,
        PageSize: paginate.pageSize.value,
        SearchParameter: searchValue,
      },
      columnHeaderName || "",
      sortDirection ?? SortType.AutoSelect
    );
  };
  const handleToggle = () => {
    setShowFilterOptions(!showFilterOptions);
  };
  const filterClick = (
    selectedFromDate?: Date,
    selectedToDate?: Date,
    status?: number[],
    teamType?: number[]
  ) => {
    var filterOptions = {
      selectedFromDate: selectedFromDate,
      selectedToDate: selectedToDate,
      status: status,
      teamType: teamType,
    };
  };
  return (
    <div className="container-fluid research new-research">
      <div className="row"></div>
      <div className="col-12 box-content p-3">
        <div className="relative d-flex align-items-start justify-content-between">
          <h5 className="b-title d-flex align-items-center col-3">
            <IoIosArrowDropleftCircle
              className="me-3 textSecondary2 cursorPointer"
              size="1.5em"
              onClick={() => {
                window.history.back();
              }}
            />{" "}
            {"All reservations"}
          </h5>
          {/* <div className="col-3" style={{ paddingTop: "6px" }}>
            <CircleIcon
              width="28px"
              height="28px"
              padding="0"
              float="right"
              type={ThemeCircleIcon.dark}
              onClick={handleToggle}
              className="pointer mx-1"
            >
              <img
                src="/images/icons/filterNew(2).svg"
                alt="radvix"
                width={15}
                height={15}
              />
            </CircleIcon>
          </div> */}
          <div className="d-flex align-items-center">
            <Filtering
              onFilter={(_form) => {
                handleFilterReservationList(
                  {
                    LaboratoryId: _form.LaboratoryId,
                    EquipmentId: _form.EquipmentId,
                    PageSize: paginate.pageSize.value,
                    PageNumber: paginate.pageNumber + 1,
                    SearchParameter: searchValue,
                  },
                  "",
                  SortType.AutoSelect
                );
                setFilterData(_form);
              }}
              loading={loading}
            />
            <PhoneSearch
              searchIcon={
                searchValue.length > 0 ? (
                  <i
                    className="fa fa-times pointer p-1"
                    aria-hidden="true"
                    onClick={() => {
                      setSearchValue("");
                      handleFilterReservationList(
                        {
                          ...filterData,
                          PageNumber: paginate.pageNumber + 1,
                          PageSize: paginate.pageSize.value,
                          SearchParameter: "",
                        },
                        "",
                        SortType.AutoSelect
                      );
                      // this.handleGetNotification(
                      //   this.state.notificationPageNumber,
                      //   this.state.notificationPageSize.value,
                      //   ""
                      // );
                    }}
                  ></i>
                ) : (
                  <RxMagnifyingGlass size="1.25em" className="textSecondary" />
                )
              }
              onChange={(e) => {
                setSearchValue(e.target.value);
                
                // run filter method with debounce

                // debounce(() => {
                if (e.target.value.length === 0) {
                  handleFilterReservationList(
                    {
                      ...filterData,
                      PageNumber: paginate.pageNumber + 1,
                      PageSize: paginate.pageSize.value,
                      SearchParameter: e.target.value,
                    },
                    "",
                    SortType.AutoSelect
                  );
                }
                //   handleFilterReservationList({
                //     ...filterData,
                //     PageNumber: paginate.pageNumber + 1,
                //     PageSize: paginate.pageSize.value,
                //     SearchParameter: e.target.value,
                //   });
                // }, 500);
              }}
              value={searchValue}
            />
            <InputIcon
              parentClassName="mx-1 dNoneSm"
              chilren={
                searchValue.length > 0 ? (
                  <i
                    className="fa fa-times pointer p-1"
                    aria-hidden="true"
                    onClick={() => {
                      setSearchValue("");
                      handleFilterReservationList(
                        {
                          ...filterData,
                          PageNumber: paginate.pageNumber + 1,
                          PageSize: paginate.pageSize.value,
                          SearchParameter: "",
                        },
                        "",
                        SortType.AutoSelect
                      );
                      // this.handleGetNotification(
                      //   this.state.notificationPageNumber,
                      //   this.state.notificationPageSize.value,
                      //   ""
                      // );
                    }}
                  ></i>
                ) : (
                  <RxMagnifyingGlass size="1.25em" className="textSecondary" />
                )
              }
              placeholder="Search..."
              onChange={(e) => {
                const inputValue = e.target.value;
                const sanitizedValue = DOMPurify.sanitize(inputValue);
                setSearchValue(sanitizedValue);
                
                // run filter method with debounce

                // debounce(() => {
                if (e.target.value.length === 0) {
                  handleFilterReservationList(
                    {
                      ...filterData,
                      PageNumber: paginate.pageNumber + 1,
                      PageSize: paginate.pageSize.value,
                      SearchParameter: e.target.value,
                    },
                    "",
                    SortType.AutoSelect
                  );
                }
                //   handleFilterReservationList({
                //     ...filterData,
                //     PageNumber: paginate.pageNumber + 1,
                //     PageSize: paginate.pageSize.value,
                //     SearchParameter: e.target.value,
                //   });
                // }, 500);
              }}
              value={searchValue}
            ></InputIcon>
            <SelectComponent
              className="ms-1 dNoneSm"
              width="140px"
              // disabled={
              //   this.state.notifications?.length <
              //   this.state.notificationPageSize.value
              //     ? true
              //     : false
              // }
              height="44px"
              defaultValue={paginate.pageSize}
              selectProps={{ isSearchable: false }}
              items={[
                { label: "10", value: 10 },
                { label: "15", value: 15 },
                { label: "20", value: 20 },
              ]}
              TextItem="item"
              ValueItem="id"
              isMulti={false}
              placeholder="Select"
              onChange={(e) => {
                setPaginate((prev) => ({ ...prev, pageSize: e }));
                // this.handleGetNotification(
                //   this.state.notificationPageNumber,
                //   e.value, border: 0;
                //   this.state.notificationSearch
                // );
                handleFilterReservationList(
                  {
                    ...filterData,
                    PageNumber: paginate.pageNumber + 1,
                    PageSize: e.value,
                    SearchParameter: searchValue,
                  },
                  "",
                  SortType.AutoSelect
                );
                // this.handelChangePageSize(e, "Researches");
              }}
            ></SelectComponent>
          </div>
        </div>
        {showFilterOptions && (
          <div style={{ backgroundColor: "white", borderRadius: 10 }}>
            <div className="d-flex justify-content-left align-items-center col-12">
              <div id="clientsDiv" className="col-12">
                <FilterSection
                  className="col-6"
                  //status={[{Id: equipmentFilterStatus.Operational, isStatusSelected: false},{Id: equipmentFilterStatus.NotOperational, isStatusSelected: false}]}
                  onselect={(
                    selectedFromDate,
                    selectedToDate,
                    status,
                    teamType
                  ) =>
                    filterClick(
                      selectedFromDate ? selectedFromDate : undefined,
                      selectedToDate ? selectedToDate : undefined,
                      status,
                      teamType
                    )
                  }
                />
              </div>
            </div>
          </div>
        )}
        <NewTable
          searchValue={searchValue}
          loading={loading}
          loadingCount={paginate.pageSize.value}
          className="mt-2"
          data={allReservation}
          headerRowClassName="d-flex d-sm-grid"
          rowClassName="newTableRowPhone relative"
          customGridTemplate="3em 2fr 1fr 1fr 1fr 0.7fr 0.8fr 0.5fr"
          onHeaderClick={(columnHeaderName, sortDirection) =>
            headerClick(columnHeaderName, sortDirection)
          }
          columns={[
            {
              Header: "",
              accessor: "id",
              cellClassName: "dNoneSm",
              headerCellClassName: "dNoneSm",
              Cell: (_row) => (
                <ImageWithToken
                  originalImage={_row.equipment.image}
                  hasImage={_row.equipment.image ? true : false}
                  alt="Avatar"
                  className="rounded-circle avatar"
                  style={{
                    width: "2.5em",
                    height: "2.5em",
                  }}
                  src={
                    _row.equipment.image
                      ? IMAGE_BASE_URL + _row.equipment.image
                      : "/Images/images/equipment_picture.svg"
                  }
                />
              ),
              skeletonConfig: {
                width: "2.5em",
                height: "2.5em",
                borderRadius: "50%",
                containerClassName: "lineHeight1",
              },
            },
            {
              Header: "Equipment",
              accessor: "equipment",
              // cellClassName: "fs-100",
              //   <ImageWithToken
              //       originalImage={_row.user?.image}
              //       hasImage={_row.user?.image ? true : false}
              //       src={
              //         _row.user?.image
              //           ? AppConstants.base_url_image + _row.user?.image
              //           : "/Images/images/img_avatar.png"
              //       }
              //       alt="Avatar"
              //       className="rounded-circle avatar mx-2"
              //       height={32}
              //       width={32}
              //     />
              cellClassName: "fullRow fs-100",
              Cell: (_row) => (
                <div className="d-flex align-items-center">
                  <ImageWithToken
                    originalImage={_row.image}
                    hasImage={_row.image ? true : false}
                    alt="Avatar"
                    className="rounded-circle avatar dNone dFlexSm me-2"
                    style={{
                      width: "3em",
                      height: "3em",
                    }}
                    src={
                      _row.image
                        ? IMAGE_BASE_URL + _row.image
                        : "/Images/images/equipment_picture.svg"
                    }
                  />
                  <Link
                    className="font-bold"
                    to={$ReplaceRoute(
                      `${AppRoutes.equip_profile.replace(
                        ":id",
                        _row.equipment.id?.toString() ?? ""
                      )}`
                    )}
                  >
                    <div className="linkOnHover pointOnHover max1LineText">
                      {searchValue ? (
                        <Highlighter
                          highlightClassName="highlightedText"
                          searchWords={
                            searchValue.length >= 2
                              ? searchValue?.split(" ")
                              : []
                          }
                          autoEscape={true}
                          textToHighlight={String(_row.equipment.title)}
                        />
                      ) : (
                        _row.equipment.title
                      )}
                    </div>
                  </Link>
                </div>
              ),
              ellipsis: true,
            },
            {
              Header: "Booked by",
              accessor: "user",
              headerCellClassName: "dNoneSm",
              ellipsis: true,
              cellClassName: "fs-100 dNoneSm",
              Cell: (_row: any) => {
                const _userName = getUserTitle(_row.user);
                return (
                  <>
                    {searchValue ? (
                      <Highlighter
                        highlightClassName="highlightedText"
                        searchWords={
                          searchValue.length >= 2 ? searchValue?.split(" ") : []
                        }
                        autoEscape={true}
                        textToHighlight={String(_userName)}
                      />
                    ) : (
                      _userName
                    )}
                  </>
                );
              },
            },
            {
              Header: "Booked from",
              accessor: "start",
              ellipsis: true,
              headerCellClassName: "dNoneSm",
              cellClassName: "fs-100",
              Cell: (_row) => {
                return (
                  <div className="">
                    <span className="dNoneSm">
                      {moment(_row.start).format(MOMENT_DATE_TIME_FORMAT)}
                    </span>
                    <div
                      className="dNone dFlexSm align-items-center flex-wrap"
                      style={{
                        gap: "0.25em 0.75em",
                      }}
                    >
                      <span>
                        <span className="textSecondary">From:</span>{" "}
                        {moment(_row.start).format(MOMENT_DATE_TIME_FORMAT)}
                      </span>

                      <span>
                        <span className="textSecondary">To:</span>{" "}
                        {moment(_row.end).format(MOMENT_DATE_TIME_FORMAT)}
                      </span>
                    </div>
                  </div>
                );
              },
            },
            {
              Header: "Booked until",
              accessor: "end",
              ellipsis: true,
              headerCellClassName: "dNoneSm",
              cellClassName: "fs-100 dNoneSm",
              Cell: (_row) => moment(_row.end).format(MOMENT_DATE_TIME_FORMAT),
            },
            {
              Header: "Last update",
              accessor: "updatedAt",
              headerCellClassName: "dNoneSm",
              ellipsis: true,
              align: "center",
              Cell: (_row) => {
                return (
                  <>
                    <span className="dNoneSm">
                      {_row.updatedAt ? (
                        moment(_row.updatedAt).format(MOMENT_DATE_TIME_FORMAT)
                      ) : (
                        <div className="d-flex justify-content-center">
                          <EmptyChip
                            className="align-self-baseline"
                            text="No Update"
                          />
                        </div>
                      )}
                    </span>
                    <div className="dNone dFlexSm">
                      <UserWithAvatar user={_row.user} />
                    </div>
                  </>
                );
              },
            },
            {
              Header: "Booking status",
              accessor: "status",
              headerCellClassName: "dNoneSm",
              ellipsis: true,
              align: "center",
              cellClassName: "dNoneSm",
              Cell: (_row) => (
                <span
                  className="s-chip"
                  style={{
                    color: RESERVATION_STATUS_STYLES[_row.status].color,
                    backgroundColor: RESERVATION_STATUS_LABELS[_row.status].bg,
                  }}
                >
                  {RESERVATION_STATUS_LABELS[_row.status].text}
                </span>
              ),
            },
            {
              Header: "Actions",
              accessor: "_id",
              headerCellClassName: "dNoneSm",
              align: "center",
              Cell: (_row) => (
                <div className="d-flex align-items-center justify-content-center chipBottomRightInSm">
                  <span
                    className="s-chip dNone dBlockSm me-1"
                    style={{
                      color: RESERVATION_STATUS_STYLES[_row.status].color,
                      backgroundColor:
                        RESERVATION_STATUS_LABELS[_row.status].bg,
                    }}
                  >
                    {RESERVATION_STATUS_LABELS[_row.status].text}
                  </span>
                  <MoreMenuContext
                    {..._row}
                    onChangeReservationStatus={() => {
                      
                      handleFilterReservationList(
                        {
                          ...filterData,
                          PageNumber: paginate.pageNumber + 1,
                          PageSize: paginate.pageSize.value,
                          SearchParameter: searchValue,
                        },
                        "",
                        SortType.AutoSelect
                      );
                    }}
                  />
                </div>
              ),
            },
          ]}
        />

        <div className="d-flex justify-content-between align-items-center px-3 mt-3 px-0-sm mt-1-sm">
          <div className="d-flex flex-fill">
            <div className="dFlexSm dNone">
              <SelectComponent
                className="me-1"
                width="80px"
                // disabled={
                //   this.state.notifications?.length <
                //   this.state.notificationPageSize.value
                //     ? true
                //     : false
                // }
                height="44px"
                defaultValue={paginate.pageSize}
                selectProps={{ isSearchable: false }}
                items={[
                  { label: "10", value: 10 },
                  { label: "15", value: 15 },
                  { label: "20", value: 20 },
                ]}
                TextItem="item"
                ValueItem="id"
                isMulti={false}
                placeholder="Select"
                onChange={(e) => {
                  setPaginate((prev) => ({ ...prev, pageSize: e }));
                  // this.handleGetNotification(
                  //   this.state.notificationPageNumber,
                  //   e.value, border: 0;
                  //   this.state.notificationSearch
                  // );
                  handleFilterReservationList(
                    {
                      ...filterData,
                      PageNumber: paginate.pageNumber + 1,
                      PageSize: e.value,
                      SearchParameter: searchValue,
                    },
                    "",
                    SortType.AutoSelect
                  );
                  // this.handelChangePageSize(e, "Researches");
                }}
              ></SelectComponent>
            </div>
            {paginate.totalCount > paginate.pageSize.value ? (
              <div className="d-flex justify-content-end flex-fill">
                <ReactPaginate
                  forcePage={paginate.pageNumber}
                  previousLabel={
                    <CircleIcon
                      width="24px"
                      padding="0"
                      backgroundColor="#4E0057"
                      height="24px"
                      type={ThemeCircleIcon.dark}
                    >
                      <i className="fas fa-chevron-left"></i>
                    </CircleIcon>
                  }
                  nextLabel={
                    <CircleIcon
                      width="24px"
                      padding="0"
                      backgroundColor="#4E0057"
                      height="24px"
                      type={ThemeCircleIcon.dark}
                    >
                      <i className="fas fa-angle-right"></i>
                    </CircleIcon>
                  }
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={paginate.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(e) => {
                    setPaginate((prev) => ({
                      ...prev,
                      pageNumber: e.selected,
                    }));

                    handleFilterReservationList(
                      {
                        ...filterData,
                        PageNumber: e.selected + 1,
                        PageSize: paginate.pageSize.value,
                        SearchParameter: searchValue,
                      },
                      "",
                      SortType.AutoSelect
                    );
                    // setData(
                    //   handlePaginate(
                    //     allReservation,
                    //     paginate.pageSize.value,
                    //     e.selected + 1
                    //   )
                    // );
                  }}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="d-flex justify-content-end flex-fill">
            <p className="text-right mb-0" style={{ fontSize: 11 }}>
              Total Results: {paginate.totalCount}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MoreMenuContext = (_row: any) => {
  const [visible, setVisible] = useState(false);
  const [changeStatusVisible, setChangeStatusVisible] = useState(false);
  
  return (
    <Tippy
      className="tippyContentPaddingZero"
      onClickOutside={() => {
        setVisible(false);
        setTimeout(() => {
          setChangeStatusVisible(false);
        }, 200);
      }}
      visible={visible}
      interactive={true}
      maxWidth="none"
      content={
        changeStatusVisible ? (
          <div
            className="text-left"
            style={{ width: "30em", maxWidth: "100%" }}
          >
            <EquipmentReservationContent
              {..._row}
              StartTime={new Date(_row.start)}
              EndTime={new Date(_row.end)}
              Subject={_row.equipment.title}
              onChangeReservationStatus={() => {
                setVisible(false);
                setTimeout(() => {
                  setChangeStatusVisible(false);
                }, 200);
                
                _row.onChangeReservationStatus();
              }}
              closeQuickInfoPopup={() => {
                setVisible(false);
                setTimeout(() => {
                  setChangeStatusVisible(false);
                }, 200);
              }}
            />
          </div>
        ) : (
          <div
            className="d-flex flex-column py-1"
            style={{ width: "12em", maxWidth: "100%" }}
          >
            <MainButton
              children={"Review request"}
              type={MainButtonType.light}
              backgroundColor="transparent"
              borderRadius="4px"
              fontSize="12px"
              className="d-flex justify-content-start px-2 py-1 w-100"
              onClick={() => {
                setChangeStatusVisible(true);
              }}
            ></MainButton>
            <Link
              to={{
                pathname: $ReplaceRoute(AppRoutes.equip_reservation),
                state: {
                  reservationId: _row.reservationId,
                  equipmentId: _row.equipment.id,
                  start: _row.start,
                  end: _row.end,
                  equipmentTitle: _row.equipment.title,
                },
              }}
            >
              <MainButton
                backgroundColor="transparent"
                children={"View on calendar"}
                type={MainButtonType.light}
                borderRadius="4px"
                fontSize="12px"
                className="d-flex justify-content-start px-2 py-1 w-100"
                onClick={() => {}}
              ></MainButton>
            </Link>
          </div>
        )
      }
      arrow={false}
      placement="bottom"
    >
      <span>
        <CircleIcon
          width="26px"
          height="26px"
          padding="0"
          type={ThemeCircleIcon.dark}
          onClick={() => {
            setVisible(true);
          }}
          className="pointer"
        >
          <MdMoreVert />
        </CircleIcon>
      </span>
    </Tippy>
  );
};
const Filtering = ({
  onFilter,
  loading,
}: {
  onFilter: (data: {
    LaboratoryId: number | null;
    EquipmentId: number | null;
  }) => void;
  loading: boolean;
}) => {
  const [visible, setVisible] = useState(false);
  const [originalLabsEq, setOriginalLabsEq] = useState<any>([]);
  const [labsOptions, setLabsOptions] = useState<
    {
      value: number | null;
      label: string;
    }[]
  >([]);
  const [equipmentsOptions, setEquipmentsOptions] = useState<
    {
      value: number | null;
      label: string;
    }[]
  >([]);
  const [form, setForm] = useState({
    selectedTimeScale: 1,
    selectedLaboratory: {
      label: "All Laboratory",
      value: null,
    },
    selectedEquipment: {
      label: "All Equipment",
      value: null,
    },

    // fromDate: new Date(new Date().setDate(new Date().getDate() - DEFAULT_DAYS)),
    // toDate: new Date(new Date().setDate(new Date().getDate() + DEFAULT_DAYS)),
  });

  useEffect(() => {
    handleGetFormData();
  }, []);
  async function handleGetFormData() {
    const _res = await getReservationSearch();
    // console.log(_res);
    // await getLaboratories();
    if (!_res) return;

    handleSetLabsAndEquipmentsOptions(_res);
    setOriginalLabsEq(_res);
  }
  function handleChangeForm(_val: any, key: string) {
    setForm((prev) => ({
      ...prev,
      [key]: _val,
    }));
  }
  function handleSetLabsAndEquipmentsOptions(_res: GetReservationSearchReqRes) {
    let _allLabs: any = [];
    let _allEquipments: any = _res.equipments.map((_eq) => ({
      label: _eq.title,
      value: _eq.id,
    }));

    _res.laboratories.forEach((_lab) => {
      _allLabs.push({
        label: _lab.title,
        value: _lab.id,
      });

      _allEquipments = [
        ..._allEquipments,
        ..._lab.equipments.map((_i: any) => ({
          label: _i.title,
          value: _i.id,
        })),
      ];
    });

    const _allEquipmentsTrim = removeDuplicateObjWithKey(
      _allEquipments,
      "value"
    );
    const _allLabsTrim = removeDuplicateObjWithKey(_allLabs, "value");

    setLabsOptions([
      {
        value: null,
        label: "All Laboratory",
      },
      ..._allLabsTrim,
    ]);
    setEquipmentsOptions([
      {
        value: null,
        label: "All Equipment",
      },
      ..._allEquipmentsTrim,
    ]);
  }
  return (
    <Tippy
      onClickOutside={() => setVisible(false)}
      visible={visible}
      interactive={true}
      content={
        <div
          className="d-flex flex-column"
          style={{ width: "20em", maxWidth: "100%" }}
        >
          <TippyHeader
            onClose={() => setVisible(false)}
            title={
              <div className="d-flex align-items-center">
                <FaFilter className="me-2" size="1em" />
                <span>Filter Reservation</span>
              </div>
            }
          />
          <div className="item">
            <SelectComponent
              defaultValue={form.selectedLaboratory}
              items={labsOptions}
              TextItem="name"
              ValueItem="id"
              // className="my-2"
              placeholder="Select laboratory.."
              // label=""
              onChange={(e) => {
                // console.log(originalLabsEq);

                handleChangeForm(e, "selectedLaboratory");

                if (e.value !== null) {
                  const _selectedLabInOriginal =
                    originalLabsEq.laboratories.find(
                      (_lab: any) => _lab.id === e.value
                    );
                  const _tmpOpt: any = _selectedLabInOriginal.equipments?.map(
                    (_eq: any) => ({
                      label: _eq.title,
                      value: _eq.id,
                    })
                  );
                  setEquipmentsOptions([
                    {
                      value: null,
                      label: "All Equipments",
                    },
                    ..._tmpOpt,
                  ]);
                  handleChangeForm(
                    {
                      label: "All Equipments",
                      value: null,
                    },
                    "selectedEquipment"
                  );
                } else {
                  handleSetLabsAndEquipmentsOptions(originalLabsEq);
                }
              }}
            ></SelectComponent>
          </div>
          <div className="item">
            <SelectComponent
              defaultValue={form.selectedEquipment}
              items={equipmentsOptions}
              TextItem="name"
              ValueItem="id"
              placeholder="Click to see the list…"
              onChange={(e) => {
                handleChangeForm(e, "selectedEquipment");
              }}
            ></SelectComponent>
          </div>

          {/* <div
            className="d-grid align-items-center mt-3"
            style={{
              gridTemplateColumns: "5ch 1fr",
            }}
          >
            <span>From</span>
            <DatePicker
              selected={form.fromDate}
              onChange={(e) => {
                handleChangeForm(e, "fromDate");
              }}
              // maxDate={
              //   this.state.isLockEndTime
              //     ? undefined
              //     : new Date(
              //         this.state.endDate?.getTime() - AppConstants.one_day_milis
              //       )
              // }
            />
          </div>
          <div
            className="d-grid align-items-center mt-3"
            style={{
              gridTemplateColumns: "5ch 1fr",
            }}
          >
            <span>Until</span>
            <DatePicker
              selected={form.toDate}
              onChange={(e) => {
                handleChangeForm(e, "toDate");
              }}
              minDate={
                new Date(form.fromDate.getTime() + AppConstants.one_day_milis)
              }
            />
          </div> */}

          <TippyFooter
            confirmText="Apply Filter"
            confirmLoading={loading}
            onCancel={() => setVisible(false)}
            onConfirm={() =>
              onFilter({
                LaboratoryId: form.selectedLaboratory.value,
                EquipmentId: form.selectedEquipment.value,

                // From: form.fromDate.toISOString(),
                // To: form.toDate.toISOString(),
              })
            }
          />
        </div>
      }
      arrow={false}
      placement="bottom"
      // maxWidth={}
    >
      <span>
        <CircleIcon
          padding="0"
          height="2em"
          // type={ThemeCircleIcon.dark}
          onClick={() => {
            setVisible(true);
          }}
          className="dNone dBlockSm me-1"
          width="2em"
          children={<MdOutlineFilterAlt size="1.4em" />}
        ></CircleIcon>
        <MainButton
          children={"Filter requests"}
          type={MainButtonType.dark}
          borderRadius="24px"
          fontSize="14px"
          className="px-3 dNoneSm"
          minHeight="2em"
          onClick={() => {
            setVisible(true);
          }}
        ></MainButton>
      </span>
    </Tippy>
  );
};
export default EquipmentReservationAll;
