/* eslint-disable no-useless-concat */
import React, { Fragment, ReactNode } from "react";
import { MainButton, MainButtonType } from "./button";
// import { CircleIcon, ThemeCircleIcon } from "./circle_icon";
import CircleIconTooltip from "./CircleIconTooltip";

interface InputsProps {
  width?: string;
  height?: string;
  minWidth?: string;
  minHeigth?: string;
  backgroundColor?: string;
  className?: string;
  borderRadius?: string;
  onChange?: (e: any) => void;
  name: string;
  items: any[];
  TextItem: string;
  ValueItem: string;
  label?: ReactNode;
  popQuestion?: string;
  optional?: string;
  inValid?: string;
  selected?: number | string | Array<number | string>;
  inputType?: "radio" | "checkbox";
  parentStyles?: {};
  disabled?: boolean;
  help?: ReactNode;
}
export const ButtonGroup: React.FC<InputsProps> = ({
  width,
  height,
  minWidth,
  minHeigth,
  backgroundColor,
  className,
  borderRadius,
  onChange,
  name,
  items,
  TextItem,
  ValueItem,
  label,
  help,
  popQuestion,
  optional,
  inValid,
  selected,
  inputType = "radio",
  parentStyles = {},
  disabled
}) => {
  let styles = {
    width: width,
    height: height,
    minWidth: minWidth,
    minHeigth: minHeigth,
    backgroundColor: backgroundColor,
    borderRadius: borderRadius,
    ...parentStyles,
  };
  let IsclassName: string;
  if (className !== undefined) {
    IsclassName = className;
  } else {
    IsclassName = "";
  }
  let TemplateLabel;
  if (label !== null && label !== undefined) {
    TemplateLabel = (
      <span className="label d-flex align-items-center">
        {label}
        {help ? (
          <MainButton
            type={MainButtonType.light}
            children={help}
            borderRadius="50px"
            fontSize="15px"
            className="mx-2"
          ></MainButton>
        ) : null}
        {optional ? (
          <MainButton
            type={MainButtonType.light}
            children={optional}
            borderRadius="50px"
            fontSize="15px"
            className="mx-2"
          ></MainButton>
        ) : null}
        {popQuestion ? (
          <CircleIconTooltip className="ms-2"
            text={popQuestion}
          />
        ) : null}
      </span>
    );
  }
  let TemplateValidation;
  let IsinValid;
  if (inValid) {
    TemplateValidation = <div>{inValid}</div>;
    IsinValid = "is-invalid";
  }
  return (
    <Fragment>
      {TemplateLabel}
      <div
        className={`${IsclassName +
          " " +
          "btngroupComponentStyle" +
          " " +
          "btn-group" +
          " " +
          IsinValid
          }`}
        style={styles}
        role="group"
        aria-label="Basic radio toggle button group"
      >
        {items.map((item, index) => (
          <Fragment key={index}>
            <input
              type={inputType}
              className="btn-check"
              name={name}

              id={item[`${ValueItem}`] + name}
              autoComplete="off"
              onChange={onChange}
              disabled={item.disable || disabled}
              value={item[ValueItem]}
              checked={
                typeof selected === "object"
                  ? selected?.includes(item[ValueItem])
                  : selected === item[ValueItem]
              }
            />
            <label
              className="btn btn-outline-dark"
              style={{
                padding: "0.75em 0.75em",
                borderRight: index === items.length - 1 ? 'none' : '1px solid #ced4da'
              }}
              htmlFor={item[`${ValueItem}`] + name}
            >
              {item[`${TextItem}`]} {item.disable ? "(Locked)" : ""}
            </label>
          </Fragment>
        ))}
      </div>
      {TemplateValidation}
    </Fragment>
  );
};
