import { HeaderTitleAction } from './../actions/header_title_action';
import { HEADER_TITLE } from '../actions/actions_type';

const headerTitle = 'Dashboard';

const reducer = (state = headerTitle, action: HeaderTitleAction): any => {
  switch (action.type) {
    case HEADER_TITLE: {
      return action.payload;
    }
  }
  return state;
};

export default reducer;
