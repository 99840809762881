/* eslint-disable no-useless-concat */
import React, { Fragment, ReactNode } from 'react';
// import { MainButton, MainButtonType } from "./button";
// import { CircleIcon, ThemeCircleIcon } from "./circle_icon";

import Select, { ActionMeta, components } from 'react-select';
import { IoIosArrowDropdownCircle } from 'react-icons/io';
import CircleIconTooltip from './CircleIconTooltip';

const { Option, MultiValue, DropdownIndicator } = components;
interface InputsProps {
  width?: string;
  height?: string;
  minWidth?: string;
  minHeigth?: string;
  backgroundColor?: string;
  className?: string;
  borderRadius?: string;
  onChange?: (e: any, actionMeta: ActionMeta<any>) => void;
  placeholder?: string;
  items: any[];
  TextItem: string;
  ValueItem?: string;
  label?: ReactNode;
  popQuestion?: string;
  optional?: string;
  border?: string;
  isMulti?: boolean;
  disabled?: boolean;
  inValid?: string;
  defaultValue?: any;
  selectProps?: {};
  labelClass?: string;
  menuPlacementTop?: boolean;
  labelIcon?: boolean;
}
// const CaretDownIcon = () => {
//   return <IoIosArrowDropdownCircle />;
// };

// const itemsss = [
//   {label: '1', value: '1'},
//   {label: '2', value: '2'},
//   {label: '3', value: '3'},
//   {label: '4', value: '4'},
//   {label: '5', value: '5'},
//   {label: '6', value: '6'},
//   {label: '7', value: '7'},
//   {label: '8', value: '8'},
//   {label: '9', value: '9'},
//   {label: '10', value: '10'},
// ]

const CustomDropdownIndicator = (props: any) => {
  return (
    <DropdownIndicator {...props}>
      <IoIosArrowDropdownCircle />
    </DropdownIndicator>
  );
};
export const SelectComponent: React.FC<InputsProps> = ({
  width,
  height,
  minWidth,
  minHeigth,
  backgroundColor,
  className,
  borderRadius,
  border,
  onChange,
  placeholder,
  items,
  label,
  popQuestion,
  optional,
  isMulti,
  disabled,
  inValid,
  defaultValue,
  selectProps,
  labelClass = '',
  menuPlacementTop,
  labelIcon,
}) => {
  let styles = {
    width: width,
    height: height,
    minWidth: minWidth,
    minHeigth: minHeigth,
  };
  const customStyles = {
    option: (provided: any, state: { isSelected: any }) => ({
      ...provided,
      ...styles,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: '13px',
    }),
  };
  let IsclassName;
  if (className !== undefined) {
    IsclassName = className;
  } else {
    IsclassName = '';
  }
  let TemplateLabel;

  if (label !== null && label !== undefined) {
    TemplateLabel = (
      <span className='color-gray-600 fs-15 me-2 mb-1'>
        {label}{' '}
        {labelIcon && <img src='/images/icons/label-icon.svg' width={15} height={15} alt='icon' />}
      </span>
    );
  }
  let TemplateValidation;
  if (inValid) {
    TemplateValidation = <div>{inValid}</div>;
  }

  console.log(customStyles);

  return (
    <Fragment>
      {/* <span className="label d-flex align-items-center"> */}
      {TemplateLabel}
      {/* <CircleIconTooltip
                  className="ms-2"
                  text="Please select the start and anticipated deadline for this research project. This will help you track the progress. You can always modify these dates and no restrictions will be applied if a deadline is passed."
                />
              </span> */}

      <Select
        // loadingMessage={{

        // }}
        menuPlacement={menuPlacementTop ? 'top' : 'bottom'}
        isDisabled={disabled}
        options={items}
        // className={IsclassName}
        onChange={(_e, _action) => onChange && onChange(_e, _action)}
        placeholder={placeholder}
        className='customFontStyle'
        // classNamePrefix="InputSelectStyleSS"
        classNamePrefix='react-select'
        styles={customStyles}
        isMulti={isMulti}
        value={defaultValue}
        onMenuClose={() => {}}
        //closeMenuOnSelect={!isMulti}
        components={{
          Option: CustomOption,
          MultiValue: CustomMultiValue,
          DropdownIndicator: CustomDropdownIndicator,
        }}
        {...selectProps}
      />
      {TemplateValidation}
    </Fragment>
  );
};

const CustomMultiValue = (props: any) => {
  const { children } = props;
  return (
    <MultiValue {...props}>
      <div className='d-flex flex-wrap gap-1'>
        <span>{children}</span>
        {props.data?.secondary ? <div className='pendingChip'>{props.data.secondary}</div> : null}
      </div>
    </MultiValue>
  );
};

const CustomOption = (props: any) => {
  const { children } = props;
  return (
    <Option {...props} className='d-flex'>
      <span>{children}</span>
      {props.data?.secondary ? (
        <div className='ms-2 pendingChip'>{props.data.secondary}</div>
      ) : null}
    </Option>
  );
};
