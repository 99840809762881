import { HTTP, handleUnauthorizedRequest } from "../../../core/http_common";
import { CreateDraftReq } from "../../models/requests/publish/create_draft_req";
import { CreatePublishReq } from "../../models/requests/publish/create_publish_req";
import { EditPublishReq } from "../../models/requests/publish/update_publish_req";
import { AddUserRes } from "../../models/responses/publish/add_user_res";
import { CreateDraftRes } from "../../models/responses/publish/create_draft_res";
import { CreatePublishRes } from "../../models/responses/publish/create_publish_res";
import { GetAllPublishes } from "../../models/responses/publish/publishes_res";
import { GetPublishByID } from "../../models/responses/publish/publish_by_id_res";
import { RemoveDraftRes } from "../../models/responses/publish/remove_draft_res";
import { SearchPublishRes } from "../../models/responses/publish/search_publish_res";
import { EditPublishRes } from "../../models/responses/publish/update_publish_res";
import { SortType } from "../../../core/number_extentions";
import { IFilterOptions } from "../../../views/components/FilterSection/IFilterOptions";

export class RemotePublish {
  createPublish(
    body: CreatePublishReq,
    action: (res: CreatePublishRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/Publish", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  SearchPublish(
    body: { researchId: number },
    action: (res: SearchPublishRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(`/Publish/Search?researchId=${body.researchId}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getPublishes(
    body: {
      PageNumber: number;
      PageSize: number;
      ResearchId: number;
      SearchParameter: string;
      columnHeaderName?: string;
      sortDirection?: SortType;
      filterOptions?: IFilterOptions
    },
    action: (res: GetAllPublishes) => any,
    error: (res: any) => any
  ) {
    const ColumnName = body.columnHeaderName ? body.columnHeaderName : '';
    const sort = body.sortDirection ? body.sortDirection : SortType.AutoSelect;
    const FromDate = body?.filterOptions?.selectedFromDate ? body.filterOptions.selectedFromDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '';
    const ToDate = body?.filterOptions?.selectedToDate ? body.filterOptions.selectedToDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '';
    const status = body?.filterOptions?.status ? body.filterOptions.status : '';
    const StatusId = Array.isArray(status) ? status.map((obj: { value: any }) => obj.value) : [];
    const type = body.filterOptions?.teamType ? body.filterOptions?.teamType : '';
    const typeList = Array.isArray(type) ? type.map((obj: { Id: any }) => obj.Id) : [];
    return HTTP.get(
      `/Publish?PageNumber=${body.PageNumber}&PageSize=${body.PageSize}&ResearchId=${body.ResearchId}&SearchParameter=${body.SearchParameter}&ColumnName=${ColumnName}&SortDirection=${sort}&SelectedFromDate=${FromDate}&SelectedToDate=${ToDate}&SelectedStatus=${StatusId}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getPublishById(
    body: { publicationId: number },
    action: (res: GetPublishByID) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(`/Publish/${body.publicationId}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  createDraft(
    body: CreateDraftReq,
    action: (res: CreateDraftRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/Publish/NextDraft", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  addUser(
    body: { publicationId: number; researchId: number },
    action: (res: AddUserRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post(
      `/Publish/AddUser/?publicationId=${body.publicationId}&researchId=${body.researchId}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  updatePublish(
    body: EditPublishReq,
    action: (res: EditPublishRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.put("/Publish", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  removeDraft(
    body: { draftId: number },
    action: (res: RemoveDraftRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.delete(`/Publish/?draftId=${body.draftId}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  deletePublish(
    body: { publicationId: number },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.delete(
      `/Publish/DeletePublication?publishId=${body.publicationId}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
}
