import React from "react";

type Props = {
  text?: React.ReactNode | string;
  style?: { [key: string]: any };
  className?: string;
};

export default function EmptyChip({
  text = "Empty",
  style = {},
  className = "",
}: Props) {
  return (
    <div
      className={`s-chip ${className}`}
      style={{ textAlign: 'center', whiteSpace: 'pre-wrap', maxWidth: '100%', backgroundColor: "#e8e8e8", color: "#8d8d8d", ...style }}
    >
      {text}
    </div>
  );
}
