import React from "react";
import { store } from "../../../data/storeMain";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import { InputComponent, InputType } from "../../components/inputs";
import "react-datepicker/dist/react-datepicker.css";
import { MainButton, MainButtonType } from "../../components/button";
import Dropzone from "react-dropzone";
import { SelectComponent } from "../../components/select_input";
// import { BoxAlert } from "../../components/box_alert";
import { RouteComponentProps } from "react-router";
import { LaboratoryController } from "../../../controllers/laboratory/laboratory_controller";
import { UploadController } from "../../../controllers/upload_media/upload_media";
import SimpleReactValidator from "simple-react-validator";
// import countries from "../../../core/json/countries.json";
import {
  Equipment,
  LabManager,
  Media,
} from "../../../data/models/responses/laboratory/laboratory_by_id_res";
import { UpdateLaboratoryReq } from "../../../data/models/requests/laboratory/laboratory_update_req";
import { AppRoutes, LOADING_MODE } from "../../../core/constants";
import {
  $ReplaceRoute,
  // $truncate,
  getUserTitle,
  isTokenInHttpAuth,
  uploadAllFiles,
  uploadAllLinks,
} from "../../../core/utils";
import CategoryManagementModal from "./component/category_management_modal";
import {
  CreateNewCategory,
  CreateNewCategoryResult,
} from "../../../data/models/responses/category/create_new_category";
import { Category } from "../../../data/models/responses/laboratory/laboratory_search_res";
import { CategoryController } from "../../../controllers/category/category_controller";
import { toast } from "react-toastify";
import { LocalDataSources } from "../../../data/local_datasources";
import { HTTP } from "../../../core/http_common";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import File from "../../components/File";
import CircleIconTooltip from "../../components/CircleIconTooltip";
import DOMPurify from "dompurify";
import { BaseButton } from "@fluentui/react";

type StateType = {
  id: number;
  title: string;
  modalCategories: Array<Category>;
  categoryId: number;
  webSite: string;
  description: string;
  removedManagersId: string[];
  addedManagersId: string[];
  removedMediasId: number[];
  company: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  creatorUserId: string;
  phone: string;
  countryId: number;
  files: Array<File>;
  listCategory: Array<{ label: string; value: number }>;
  listmanagers: Array<{ label: string; value: number } | {}>;
  listCountry: Array<{ label: string; value: number }>;
  selectedManagers: Array<{ label: string; value: string }>;
  selectedCategory: { label: string; value: number };
  Managers: LabManager[];
  media: Media[];
  equipments: Equipment[];
  teams: Equipment[];
  members: LabManager[];
  External: string;
  ExternalUrl: Array<string>;
  loading: boolean;
  searchLoading: boolean;
  loadingMode: string;
};
interface RouteParams {
  id: string;
}
export class LaboratoryPageEdit extends React.Component<
  RouteComponentProps<RouteParams>
> {
  RoleUser = store.getState().userRole;
  local = new LocalDataSources();
  controller = new LaboratoryController();
  UploadController = new UploadController();
  categoryController = new CategoryController();
  validator = new SimpleReactValidator({
    className: "text-danger",
    validators: {
      zipCode: {
        rule(val, params?) {
          let text = val;
          let pattern =
            /(^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$|^(?:\d{5}(?:[-]\d{4})?|\d{9})$)/i;
          let result = pattern.test(text);
          return result;
        },
        message: "zip code is not Valid",
      },
    },
  });
  handelChangeDate(params: any): void {}
  state: StateType = {
    id: 0,
    title: "",
    categoryId: -1,
    webSite: "",
    description: "",
    removedManagersId: [],
    addedManagersId: [],
    selectedCategory: { label: "", value: -1 },
    removedMediasId: [],
    company: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    creatorUserId: "",
    phone: "",
    countryId: 0,
    files: [],
    listCategory: [],
    listmanagers: [],
    listCountry: [],
    selectedManagers: [],

    modalCategories: [],
    Managers: [],
    media: [],
    equipments: [],
    teams: [],
    members: [],
    External: "",
    ExternalUrl: [],
    loading: false,
    searchLoading: false,
    loadingMode: "",
  };

  updateSearchApi() {
    return new Promise((resolve, reject) => {
      this.setState({
        searchLoading: true,
      });
      this.controller.getLaboratorySearch(
        (res) => {
          // !sina added - remove the current this.state.Managers from the res.managers
          // let listmanagers = res.managers.filter((item) => {
          //   return !this.state.Managers.find((manager) => manager.id === item.id);
          // });

          this.setState({
            listmanagers: res.managers.map((item: any) => {
              const _userTitle = getUserTitle(item);
              return {
                label: _userTitle,
                value: item.id,
                secondary: item.invitationStatus === 1 ? "Pending" : null,
              };
            }),
            listCategory: res.categories.map((item) => {
              return { label: item.title, value: item.id };
            }),
            listCountry: res.countries.map((item) => {
              return { label: item.title, value: item.id };
            }),
            modalCategories: res.categories,
          });

          this.controller.getLaboratoryById(
            { id: parseInt(this.props.match.params.id) },
            (_res) => {
              if (_res.requestedRemoval) {
                this.props.history.push(
                  $ReplaceRoute(
                    `${AppRoutes.profile_laboratory.replace(
                      ":id",
                      this.props.match.params.id?.toString()
                    )}`
                  )
                );
              }
              document.title = `Edit Laboratory | ${_res.title} | Radvix`;
              
              const _selectedManagers = _res.labManagers.map((item: any) => {
                const _userTitle = getUserTitle(item);
                return {
                  label: _userTitle,
                  value: item.id,
                  secondary: item.invitationStatus === 1 ? "Pending" : null,
                };
              });
              // console.log(res, _res);
              const _sc = res.categories.filter(
                (_cat) => _cat.id === _res.categoryId
              )[0];

              const _selectedCategory = {
                label: _sc?.title || "",
                value: _sc?.id || -1,
              };

              this.setState({
                title: _res.title,
                categoryId: _res.categoryId,
                webSite: _res.webSite,
                description: _res.description,
                addressLine1: _res.addressLine1,
                addressLine2: _res.addressLine2,
                Managers: _res.labManagers,
                selectedManagers: _selectedManagers,
                selectedCategory: _selectedCategory,
                media: _res.media,
                equipments: _res.equipments,
                members: _res.members,
                company: _res.company,
                zipCode: _res.zipCode,
                phone: _res.phone,
                city: _res.city,
                state: _res.province,
                countryId: _res.countryId,
                searchLoading: false,
              });
              // console.log(this.state, _res);
              resolve(true);
              this.setState({
                ExternalUrl: this.state.media.filter((x) => x.externalUrl).map((y) => y.externalUrl)
              });
              
            },
            (_err) => {
              this.setState({
                searchLoading: false,
              });
            }
          );
        },
        (err) => {
          this.setState({
            searchLoading: false,
          });
        }
      );
    });
  }
  componentDidMount() {
    if (!isTokenInHttpAuth(HTTP)) return;
    this.updateSearchApi();
  }
  onDrop = (fileList: any) => {
    fileList.map((item: any, index: number) => {
      const found = this.state.files.find(
        (element: any) => element.name === item.name
      );
      if (found === undefined) {
        this.setState({ files: [fileList[index], ...this.state.files] });
      }
    });
  };
  handleChange(target: string, val: any) {
    const sanitizedValue = DOMPurify.sanitize(val);
    this.setState({
      [target]: sanitizedValue,
    });
  }
  handelChangeSelect(e: { label: string; value: number }, target: string) {
    this.setState({ [target]: e.value });
  }
  handelSelectManager(e: Array<{ label: string; value: number }>) {
    const _cantFindYourSelf = !e.find(
      (item) => item.value.toString() === this.local.getUserId()
    );

    if (_cantFindYourSelf) {
      return toast.error("You can't remove yourself from managers");
    }
    //
    if (e.length === 0) {
      return toast.warning("At least one member should stay for manager role");
    }
    this.setState({
      selectedManagers: [...e],
    });
  }
  handelRemoveManagers(id: string) {
    this.setState({
      removedManagersId: [...this.state.removedManagersId, id],
      Managers: this.state.Managers.filter((item) => item.id !== id),
    });
  }
  handelDeleteFile(arg: File) {
    this.setState({
      files: this.state.files.filter((file) => file.name !== arg.name),
    });
  }
  handelRemoveMedia(id: number) {
    this.setState({
      removedMediasId: [...this.state.removedMediasId, id],
      media: this.state.media.filter((item) => item.id !== id),
    });
  }
  addExternalUrl() {
    if (this.validator.fields.input) {
      let Url = [...this.state.ExternalUrl];
      if (this.state.External.length > 2) {
        Url.push(this.state.External);
      }
      this.setState({
        ExternalUrl: Url,
        External: "",
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  handelDeleteExternalLink(link: string) {
    this.setState({
      ExternalUrl: this.state.ExternalUrl.filter((item) => item !== link),
    });
  }
  UpdateLaboratory() {
    if (this.validator.allValid()) {
      this.setState({
        loading: true,
        loadingMode: "Updating laboratory..",
      });
      let _removed = this.state.Managers.filter((_manager) => {
        // aya in manager to selectedManagers hast? agar hast return false
        return !this.state.selectedManagers.find(
          (s_manager) => s_manager.value === _manager.id
        );
      })
        .filter((manager) => {
          return manager.id !== this.local.getUserId();
        })
        .map((item) => item.id);

      let _added = this.state.selectedManagers
        .filter((s_manager) => {
          // aya in selectedManager to Managers hast? agar nist return true
          return !this.state.Managers.find(
            (_manager) => _manager.id === s_manager.value
          );
        })
        .filter((manager) => {
          return manager.value !== this.local.getUserId();
        })
        .map((_item) => _item.value);

      const body: UpdateLaboratoryReq = {
        id: parseInt(this.props.match.params.id),
        title: this.state.title,
        // categoryId: this.state.categoryId,
        categoryId: this.state.selectedCategory.value,
        webSite: this.state.webSite,
        description: this.state.description,

        removedManagersId: _removed,
        addedManagersId: _added,

        // removedManagersId: this.state.removedManagersId,
        // addedManagersId: this.state.addedManagersId,
        removedMediasId: this.state.removedMediasId,
        company: this.state.company,
        addressLine1: this.state.addressLine1,
        addressLine2: this.state.addressLine2,
        city: this.state.city,
        province: this.state.state,
        zipCode: this.state.zipCode,
        creatorUserId: this.state.creatorUserId,
        phone: this.state.phone,
        countryId: this.state.countryId,
      };
      this.controller.updateLaboratory(
        body,
        (res) => {
          if (
            this.state.files.length > 0 ||
            this.state.ExternalUrl.length > 0
          ) {
            this.handelUpload(parseInt(this.props.match.params.id));
          } else {
            this.setState({
              loading: false,
              loadingMode: "",
            });
            this.props.history.push(
              $ReplaceRoute(
                `${AppRoutes.profile_laboratory.replace(
                  ":id",
                  parseInt(this.props.match.params.id)?.toString() ?? ""
                )}`
              )
            );
          }
        },
        (err) => {
          this.setState({
            loading: false,
            loadingMode: "",
          });
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 MB';
  
    const m = 1024 * 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizeInMB = bytes / m;
  
    return `${sizeInMB.toFixed(dm)} MB`;
  }

  async handelUpload(id: number) {
    this.setState({
      loadingMode: LOADING_MODE.links,
    });
    await uploadAllLinks(id, this.state.ExternalUrl, 1);

    this.setState({
      loadingMode: LOADING_MODE.files,
    });
    await uploadAllFiles(id, this.state.files, 1);
    this.setState({
      loading: false,
      loadingMode: "",
    });
    this.props.history.push(
      $ReplaceRoute(
        `${AppRoutes.profile_laboratory.replace(
          ":id",
          parseInt(this.props.match.params.id)?.toString() ?? ""
        )}`
      )
    );

    // const formData = new FormData();
    // for (let i = 0; i < this.state.files.length; i++) {
    //   const file = this.state.files[i];
    //   formData.append("Files", file);
    // }
    // for (let i = 0; i < this.state.ExternalUrl.length; i++) {
    //   const file = this.state.ExternalUrl[i];
    //   formData.append("ExternalUrls", file);
    // }
    // formData.append("UseCase", "1");
    // formData.append("SectionId", id.toString());

    // await this.UploadController.UloadMedia(
    //   formData,
    //   (res) => {
    //     this.props.history.push(
    //       $ReplaceRoute(
    //         `${AppRoutes.profile_laboratory.replace(
    //           ":id",
    //           parseInt(this.props.match.params.id)?.toString() ?? ""
    //         )}`
    //       )
    //     );
    //     this.setState({
    //       loading: false,
    //     });
    //   },
    //   () => {
    //     this.setState({
    //       loading: false,
    //     });
    //   }
    // );
  }

  handleCreateNewCategory(_newCategoryName: string) {
    return new Promise((resolve, reject) => {
      this.categoryController.createNewCategory(
        {
          title: _newCategoryName,
          type: 0,
        },
        (res) => {
          // this.setState({
          //   listCategory: [
          //     ...this.state.listCategory,
          //     { label: res.title, value: res.id },
          //   ],
          // });
          resolve(res as CreateNewCategoryResult);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  handleEditCategory(_newCategoryName: string, _id: number) {
    return new Promise((resolve, reject) => {
      this.categoryController.editCategory(
        {
          title: _newCategoryName,
          type: 0,
          id: _id,
        },
        (res) => {
          if (this.state.selectedCategory.value === _id) {
            this.setState({
              selectedCategory: { label: _newCategoryName, value: _id },
            });
          }
          resolve(res as CreateNewCategoryResult);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  handleDeleteCategory(_id: number) {
    return new Promise((resolve, reject) => {
      this.categoryController.deleteCategory(
        {
          id: _id,
        },
        (res) => {
          if (this.state.selectedCategory.value === _id) {
            this.setState({
              selectedCategory: {
                label: "",
                value: -1,
              },
            });
          }
          resolve(res as CreateNewCategory);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  render() {
    const files = this.state.files.map((file: any) => (
      <li
        key={file.name}
        className="d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team"
        style={{marginBottom:'3px'}}
      >
        <span
          className="text-truncate col-11 mx-2" 
          style={{ fontFamily: "Sanfransisco", textAlign:'start' }}
        >
          {file.name} - {this.formatBytes(file.size)}
        </span>
        <CircleIcon
          type={ThemeCircleIcon.dark}
          width="22px"
          height="22px"
          padding="0"
          onClick={() => {
            this.handelDeleteFile(file);
          }}
          style={{ cursor: "pointer", display: "contents" }}
        >
          <img
            src="/images/icons/cross-1.svg"
            alt="radvix"
            width={15}
            height={15}
          />
        </CircleIcon>
      </li>
    ));

    // console.log(this.state);
    return (
      <div className="card">
        <div
          className="d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
    border-gray-400"
        >
          <div className="d-flex align-items-center">
            {/* <button className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none"> Back</button> */}
            <BaseButton
              className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button"
              style={{ fontWeight: "400", height: "19px" }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className="fw-medium fs-15 mb-0 color-gray-1000">
              Edit Laboratory
            </h2>
          </div>
        </div>

        <div className="p-3 overflow-auto">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div className="item">
                    <InputComponent
                      className="form-control"
                      disabled={this.state.searchLoading}
                      type={InputType.text}
                      label="Laboratory name*"
                      placeholder="Enter your laboratory name"
                      popQuestion="Please enter the name of your laboratory. You are going to make it easier to identify your lab when working with multiple laboratories. You can always edit this name."
                      onChange={(e) => {
                        this.handleChange("title", e.target.value);
                      }}
                      inValid={this.validator.message(
                        "Laboratory Name",
                        this.state.title,
                        "required"
                      )}
                      value={this.state.title}
                    ></InputComponent>
                    {/* <input className="form-control" placeholder="Enter your project name" /> */}
                  </div>

                  <div className="item">
                    <SelectComponent
                      disabled={this.state.searchLoading}
                      items={this.state.listCategory}
                      TextItem="name"
                      ValueItem="id"
                      className="w-100"
                      placeholder="Select a category"
                      label="Select category"
                      isMulti={false}
                      onChange={(e) => {
                        this.setState({
                          selectedCategory: e,
                        });
                      }}
                      defaultValue={this.state.selectedCategory}
                    ></SelectComponent>
                  </div>

                  <div className="item" style={{ paddingTop: "15px" }}>
                    <InputComponent
                      className="form-control"
                      type={InputType.text}
                      disabled={this.state.searchLoading}
                      label="Website"
                      placeholder="https://"
                      popQuestion="Please enter the address for your laboratory website. You can always update this later."
                      optional="optional"
                      onChange={(e) => {
                        this.handleChange("webSite", e.target.value);
                      }}
                      value={this.state.webSite}
                    ></InputComponent>
                    <p className="text-error mb-0">
                      {this.validator.message(
                        "input",
                        this.state.webSite,
                        "url"
                      )}
                    </p>
                  </div>
                  <div className="item">
                    <SelectComponent
                      label="Lab manager(s)"
                      popQuestion="Creator of the lab will be automatically assigned as a lab manager. Each lab needs to have at least one lab manager. Managers can add equipment to each lab, edit their profile and manage equipment schedule."
                      items={this.state.listmanagers.filter((item) => {
                        return (
                          this.state.selectedManagers.findIndex((_manager) => {
                            // console.log(item, _manager);
                            // @ts-ignore
                            // ! talk with amir hossein
                            return _manager.id === item.value;
                          }) === -1
                        );
                      })}
                      disabled={this.state.searchLoading}
                      TextItem="name"
                      ValueItem="id"
                      className="mt-1"
                      onChange={(e) => {
                        this.handelSelectManager(e);
                      }}
                      isMulti
                      defaultValue={this.state.selectedManagers}
                    ></SelectComponent>
                  </div>
                  <div className="row mt-1">
                    <div className="col-lg-12 mb-3">
                      <div
                        className="text-primary cursor-pointer mb-1"
                        title="Info"
                      >
                        <label className="color-gray-600 fs-15 me-2">
                          Description
                        </label>
                        {/* <img
                          className="text-primary cursor-pointer"
                          src="/Images/icons/info-circled.svg"
                          alt="info"
                          title="You can write a brief description for this research project. This information will be shown on the project profile and everyone who is assigned to this research, will be able to read it."
                        /> */}
                      </div>
                      <textarea
                        className="form-control control-border-color"
                        disabled={this.state.searchLoading}
                        placeholder="Enter your project description"
                        style={{ height: "132px", resize: "none" }}
                        value={this.state.description}
                        onChange={(e) => {
                          this.handleChange("description", e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div className="d-flex align-items-center mb-1">
                    <label className="color-gray-600 fs-15 me-2">
                      Protocols and documents:
                    </label>
                    {/* <img
                      className="text-primary cursor-pointer"
                      src="/Images/icons/info-circled.svg"
                      alt="info"
                      title="Research attachments are generic documents that you would like anyone who has access to this research project read and have access to them. You can either upload them or Insert the link."
                    /> */}
                  </div>
                  <div className="p-px-12 bg-gray-200 border-gray-400 border rounded-px-4 gap-2 attachment-paddingleft">
                    <div className="justify-content-sm-between justify-content-center align-items-center flex-wrap row-gap-px-8">
                      <Dropzone onDrop={this.onDrop}>
                        {({ getRootProps, getInputProps }) => (
                          <section
                            className=""
                            
                          >
                            <div {...getRootProps({ className: "" })}>
                              <input {...getInputProps()} />
                              <div className="d-flex justify-content-between align-items-center flex-wrap row-gap-px-8">
                                <button className="btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0">
                                  <div className="d-flex align-items-center">
                                    <div className="me-1 mb-1">
                                      <svg
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          width="15"
                                          height="15"
                                          fill="white"
                                          fill-opacity="0.01"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M7.81825 1.18197C7.64251 1.00624 7.35759 1.00624 7.18185 1.18197L4.18185 4.18197C4.00611 4.35771 4.00611 4.64263
            4.18185 4.81837C4.35759 4.9941 4.64251 4.9941 4.81825 4.81837L7.05005 2.58657V9.50005C7.05005 9.74858 7.25152 9.95005
            7.50005 9.95005C7.74858 9.95005 7.95005 9.74858 7.95005 9.50005V2.58657L10.1819 4.81837C10.3576 4.9941 10.6425 4.9941
            10.8182 4.81837C10.994 4.64263 10.994 4.35771 10.8182 4.18197L7.81825 1.18197ZM2.5 10.0001C2.77614 10.0001 3 10.2239 3
            10.5001V12.0001C3 12.5539 3.44565 13.0001 3.99635 13.0001H11.0012C11.5529 13.0001 12 12.5529 12 12.0001V10.5001C12
            10.2239 12.2239 10.0001 12.5 10.0001C12.7761 10.0001 13 10.2239 13 10.5001V12.0001C13 13.1041 12.1062 14.0001 11.0012
            14.0001H3.99635C2.89019 14.0001 2 13.1031 2 12.0001V10.5001C2 10.2239 2.22386 10.0001 2.5 10.0001Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </div>
                                    Browse local file
                                  </div>
                                </button>
                                <h3 className="fw-normal color-gray-700 fs-13 mb-0 flex-grow-1 text-center">
                                  {" "}
                                  Or drag and drop files here - Max 128MB{" "}
                                </h3>
                              </div>
                              {this.state.media.length > 0 && (
                            <div style={{ paddingTop: "10px" }}>
                              <ul
                                className="list-unstyled fw-normal"
                                
                              >
                                {this.state.media
                                  .filter((item: any) => item.externalUrl === null)
                                  .map((item, index) => (
                                    <li
                                      key={index}
                                      className="d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team"
        style={{marginBottom:'3px'}}
                                    >
                                      <span
                                        className="text-truncate col-11 mx-2"
                                        style={{ fontFamily: "Sanfransisco", textAlign:'start' }}
                                      >
                                        <File file={item} />
                                      </span>
                                      <CircleIcon
                                        type={ThemeCircleIcon.dark}
                                        width="22px"
                                        height="22px"
                                        padding="0"
                                        //disabled={_disabledAll}
                                        onClick={() => {
                                          this.handelRemoveMedia(item.id!);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          display: "contents",
                                        }}
                                      >
                                        <img
                                          src="/images/icons/cross-1.svg"
                                          alt="radvix"
                                          width={15}
                                          height={15}
                                        />
                                      </CircleIcon>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          )}
                            </div>
                            <aside style={{marginTop:"3px"}}>
                              <ul>{files}</ul>
                            </aside>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                  <ul
                    className="file-list mt-1"
                    style={{
                      marginBottom:
                        this.state.ExternalUrl.length > 0 ? "0px" : "0px",
                    }}
                  >                  
                    {this.state.ExternalUrl.map((item) => (
                      <li className="d-flex align-items-center w-100">
                        <InputComponent
                          type={InputType.text}
                          value={item}
                          className="form-control"
                          counter={false}
                          disabled={true}
                          readOnly={true}
                          rowWidth="98%"
                          isExternalURL={true}
                        ></InputComponent>
                        <div
                          style={{
                            paddingBottom: "0px",
                            paddingRight: "0px",
                            paddingTop: "0px",
                            paddingLeft: "5px",
                          }}
                        >
                          <button
                            className="btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0"
                            disabled={this.state.searchLoading}
                            style={{ background: "#F3AEAF" }}
                            onClick={() => {
                              this.handelDeleteExternalLink(item);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <rect
                                width="15"
                                height="15"
                                fill="white"
                                fill-opacity="0.01"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5.5 1.00009C5.22386 1.00009 5 1.22395 5 1.50009C5 1.77623 5.22386 2.00009 5.5 2.00009H9.5C9.77614 2.00009 10 1.77623 10 1.50009C10 1.22395 9.77614 1.00009 9.5 1.00009H5.5ZM3 3.50009C3 3.22395 3.22386 3.00009 3.5 3.00009H5H10H11.5C11.7761 3.00009 12 3.22395 12 3.50009C12 3.77623 11.7761 4.00009 11.5 4.00009H11V12.0001C11 12.5524 10.5523 13.0001 10 13.0001H5C4.44772 13.0001 4 12.5524 4 12.0001V4.00009H3.5C3.22386 4.00009 3 3.77623 3 3.50009ZM5 4.00009H10V12.0001H5V4.00009Z"
                                fill="#D93D42"
                              />
                            </svg>
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>

                  <div className="d-flex">
                    <InputComponent
                      disabled={this.state.searchLoading}
                      type={InputType.text}
                      placeholder="https://"
                      className="control-border-color"
                      value={this.state.External}
                      rowWidth="98%"
                      counter={false}
                      onChange={(e) => {
                        this.handleChange("External", e.target.value);
                      }}
                      onEnter={(e) => {
                        this.addExternalUrl();
                      }}
                    ></InputComponent>
                    <div style={{ padding: "5px", paddingRight: "0px" }}>
                      <button
                        className="btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0"
                        disabled={this.state.searchLoading}
                        onClick={() => {
                          this.addExternalUrl();
                        }}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>

                  <div className="item">
                    <InputComponent
                      disabled={this.state.searchLoading}
                      type={InputType.text}
                      className="form-control"
                      optional="optional"
                      label="Laboratory contact - Institution/Company"
                      onChange={(e) => {
                        this.handleChange("company", e.target.value);
                      }}
                      value={this.state.company}
                    ></InputComponent>
                  </div>

                  <div className="row">
                    <div className="item col-md-6">
                      <InputComponent
                        disabled={this.state.searchLoading}
                        type={InputType.text}
                        label="Address line 1"
                        className="form-control"
                        onChange={(e) => {
                          this.handleChange("addressLine1", e.target.value);
                        }}
                        value={this.state.addressLine1}
                      ></InputComponent>
                    </div>
                    <div className="item col-md-6">
                      <InputComponent
                        disabled={this.state.searchLoading}
                        type={InputType.text}
                        label="Address line 2"
                        // popQuestion="Address Line 2"
                        onChange={(e) => {
                          this.handleChange("addressLine2", e.target.value);
                        }}
                        value={this.state.addressLine2}
                      ></InputComponent>
                    </div>
                  </div>
                  <div className="item">
                    <SelectComponent
                      items={this.state.listCountry}
                      disabled={this.state.searchLoading}
                      TextItem="name"
                      ValueItem="id"
                      className="mt-1"
                      label="Country"
                      onChange={(e) => {
                        this.handelChangeSelect(e, "countryId");
                      }}
                      defaultValue={this.state.listCountry.find(
                        (item) => item.value === this.state.countryId
                      )}
                    ></SelectComponent>
                  </div>
                  <div className="row">
                    <div className="item col-md-6">
                      <InputComponent
                        type={InputType.text}
                        disabled={this.state.searchLoading}
                        label="City"
                        onChange={(e) => {
                          this.handleChange("city", e.target.value);
                        }}
                        className="form-control"
                        value={this.state.city}
                      ></InputComponent>
                    </div>
                    <div className="item col-md-6">
                      <InputComponent
                        type={InputType.text}
                        disabled={this.state.searchLoading}
                        label="State/Province"
                        onChange={(e) => {
                          this.handleChange("state", e.target.value);
                        }}
                        className="form-control"
                        value={this.state.state}
                      ></InputComponent>
                    </div>
                  </div>
                  <div className="row">
                    <div className="item col-md-6">
                      <InputComponent
                        disabled={this.state.searchLoading}
                        type={InputType.text}
                        label="ZIP/Postal code"
                        counter={false}
                        onChange={(e) => {
                          this.handleChange("zipCode", e.target.value);
                        }}
                        className="form-control"
                        value={this.state.zipCode}
                      ></InputComponent>
                    </div>
                    <div className="item col-md-6">
                      <InputComponent
                        type={InputType.text}
                        disabled={this.state.searchLoading}
                        label="Phone"
                        counter={false}
                        onChange={(e) => {
                          this.handleChange("phone", e.target.value);
                        }}
                        className="form-control"
                        value={this.state.phone}
                      ></InputComponent>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>

            <div>
              <MainButton
                className="btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2"
                svg={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <rect
                      width="15"
                      height="15"
                      fill="white"
                      fill-opacity="0.01"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.5 2.00006C3.22386 2.00006 3 2.22392 3 2.50006V12.5001C3 12.7762 3.22386 13.0001 3.5 13.0001H11.5C11.7761 13.0001 12 12.7762 12 12.5001V4.70717L9.29289 2.00006H3.5ZM2 2.50006C2 1.67163 2.67157 1.00006 3.5 1.00006H9.5C9.63261 1.00006 9.75979 1.05274 9.85355 1.14651L12.7803 4.07328C12.921 4.21394 13 4.4047 13 4.60361V12.5001C13 13.3285 12.3284 14.0001 11.5 14.0001H3.5C2.67157 14.0001 2 13.3285 2 12.5001V2.50006ZM4.75 7.50006C4.75 7.22392 4.97386 7.00006 5.25 7.00006H7V5.25006C7 4.97392 7.22386 4.75006 7.5 4.75006C7.77614 4.75006 8 4.97392 8 5.25006V7.00006H9.75C10.0261 7.00006 10.25 7.22392 10.25 7.50006C10.25 7.7762 10.0261 8.00006 9.75 8.00006H8V9.75006C8 10.0262 7.77614 10.2501 7.5 10.2501C7.22386 10.2501 7 10.0262 7 9.75006V8.00006H5.25C4.97386 8.00006 4.75 7.7762 4.75 7.50006Z"
                      fill="white"
                    />
                  </svg>
                }
                onClick={() => {
                  this.UpdateLaboratory();
                }}
                loading={this.state.loading || this.state.searchLoading}
                disabled={this.state.loading || this.state.searchLoading}
              >
                Update Laboratory
              </MainButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
