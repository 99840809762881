/* eslint-disable jsx-a11y/alt-text */
import * as Accordion from '@radix-ui/react-accordion';
import {
  AvatarIcon,
  ChatBubbleIcon,
  ChevronDownIcon,
  GearIcon,
  HomeIcon,
  InfoCircledIcon,
  MixerHorizontalIcon,
  Pencil1Icon,
  Pencil2Icon,
  PieChartIcon,
  RocketIcon,
  RulerSquareIcon,
} from '@radix-ui/react-icons';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '../../core/constants';
import { $ReplaceRoute, AccessPermition, UserRoles } from '../../core/utils';
import { store } from '../../data/storeMain';
import { SetHeaderTitle } from '../../data/storeMain/actions/header_title_action';
import './style.css';
import { SetSidebarCollapse } from '../../data/storeMain/actions/sidebard_collapse_action';
import { useDebounceEffect } from '../../hooks/useDebounceEffect';

const SidebarCopy: React.FC<{}> = () => {
  const RoleUser = store.getState().userRole;
  const dispatch = useDispatch();
  const isLockEvent = useRef(true);
  const sidebardRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const [selectednavigationItem, setSelectednavigationItem] = useState<number>(0);
  const { isCollapsed } = useSelector(() => store.getState().sidebarCollapse);

  const closeSidebar = () => {
    if (isSmallScreen) {
      dispatch(SetSidebarCollapse(true));
      sidebardRef.current?.classList.remove('expand');
    }
  };

  const toggleSidebarCustom = () => {
    const element = document.querySelector('body');
    element?.classList.toggle('sidebar-opened');
    sidebardRef.current?.classList.toggle('expand');
    dispatch(SetSidebarCollapse(!isCollapsed));
  };

  const onClickOnDom = (e: any) => {
    const _isMenuBtn = e.target.classList.contains('phoneMenuBtn');
    const _isMenuBarMenuBtn = e.target.classList.contains('menuBtnDimmer');
    if (_isMenuBtn || _isMenuBarMenuBtn) return;
    if (isLockEvent.current) {
      isLockEvent.current = false;
      return;
    }
  };

  useEffect(() => {
    window.addEventListener('click', onClickOnDom);

    return () => {
      window.removeEventListener('click', onClickOnDom);
    };
  }, [isSmallScreen]);

  useDebounceEffect(
    () => {
      const bodyEl = document.body;

      if (isSmallScreen) {
        bodyEl?.classList.remove('sidebar-opened');
        dispatch(SetSidebarCollapse(true));
      } else {
        bodyEl?.classList.add('sidebar-opened');
        dispatch(SetSidebarCollapse(false));
      }
    },
    10,
    [isSmallScreen, dispatch]
  );

  return (
    <Fragment>
      <div ref={sidebardRef} className={`sidebar ${isCollapsed ? 'Collapsed' : 'expand'}`}>
        <div
          className={
            isCollapsed
              ? 'sidebar-header Collapsed d-flex justify-content-center'
              : 'sidebar-header'
          }
        >
          <div className={isCollapsed ? 'hidden' : 'logo'}>
            <img
              src='/images/images/radvix_new_logo.svg'
              onClick={() => {
                history.push($ReplaceRoute(AppRoutes.dashboard));
              }}
              className={isCollapsed ? 'hidden' : 'icon'}
            />
          </div>
          <button onClick={toggleSidebarCustom} className='sidebar-toggler-btn'>
            <img
              src='/images/images/sidebar-hide.svg'
              className={isCollapsed ? 'hidden' : 'icon'}
            />
            <img
              src='/images/images/sidebar-show.svg'
              className={isCollapsed ? 'icon' : 'hidden'}
            />
          </button>
        </div>
        <Accordion.Root
          className={
            isCollapsed ? 'AccordionRoot justify-content-center Collapsed' : 'AccordionRoot'
          }
          type='single'
          defaultValue='item-1'
          collapsible
        >
          <div className='nav-section'>
            <>
              {RoleUser !== UserRoles.Admin ? (
                <>
                  <div>
                    <Accordion.Item className='AccordionItem ' value='Dashboard'>
                      <Accordion.Trigger
                        className={`${
                          isCollapsed
                            ? 'AccordionTrigger justify-content-center'
                            : 'AccordionTrigger'
                        } ${selectednavigationItem === 0 ? 'sidebar-selected' : ''}`}
                        onClick={(e) => {
                          history.push($ReplaceRoute(AppRoutes.dashboard));
                          setSelectednavigationItem(0);
                          closeSidebar();
                          dispatch(SetHeaderTitle('Dashboard'));
                        }}
                      >
                        <div className='AccordionTriggerFirst'>
                          <span className='AccordionIconWrapper'>
                            <HomeIcon className='AccordionTriggerIcon'></HomeIcon>
                          </span>
                          <span className={isCollapsed ? 'hidden' : ''}>Dashboard</span>
                        </div>
                      </Accordion.Trigger>
                    </Accordion.Item>

                    <Accordion.Item className='AccordionItem' value='project'>
                      <Accordion.Trigger
                        className={
                          isCollapsed
                            ? 'AccordionTrigger justify-content-center'
                            : 'AccordionTrigger'
                        }
                        onClick={() => {
                          if (isCollapsed) {
                            toggleSidebarCustom();
                          }
                        }}
                      >
                        <div className='AccordionTriggerFirst'>
                          <span className='AccordionIconWrapper'>
                            <RocketIcon className='AccordionTriggerIcon'></RocketIcon>
                          </span>
                          <span className={isCollapsed ? 'hidden' : ''}>Projects</span>
                        </div>
                        <ChevronDownIcon
                          className={isCollapsed ? 'hidden' : 'AccordionChevron'}
                          aria-hidden
                        />
                      </Accordion.Trigger>

                      <Accordion.Content
                        className={`${
                          isCollapsed ? 'hidden' : 'AccordionContent accordion-button'
                        }`}
                        onClick={(e) => {
                          history.push($ReplaceRoute(AppRoutes.research));
                          setSelectednavigationItem(1);
                          closeSidebar();
                          dispatch(SetHeaderTitle('Projects'));
                        }}
                      >
                        <div
                          className={`AccordionContentText accordion-button-text ${
                            selectednavigationItem === 1 ? 'sidebar-child-selected' : ''
                          }`}
                        >
                          My Projects
                        </div>
                      </Accordion.Content>
                      <Accordion.Content
                        className={isCollapsed ? 'hidden' : 'AccordionContent accordion-button'}
                        onClick={(e) => {
                          history.push($ReplaceRoute(`${AppRoutes.timeline_research}`));
                          setSelectednavigationItem(2);
                          closeSidebar();
                          dispatch(SetHeaderTitle('Projects'));
                        }}
                      >
                        <div
                          className={`AccordionContentText accordion-button-text ${
                            selectednavigationItem === 2 ? 'sidebar-child-selected' : ''
                          }`}
                        >
                          Timeline
                        </div>
                      </Accordion.Content>
                    </Accordion.Item>
                    <Accordion.Item className='AccordionItem' value='Publish'>
                      <Accordion.Trigger
                        className={`
                    ${
                      isCollapsed ? 'AccordionTrigger justify-content-center' : 'AccordionTrigger'
                    } ${selectednavigationItem === 10 ? 'sidebar-selected' : ''}
                      `}
                        onClick={(e) => {
                          if (isCollapsed) {
                            toggleSidebarCustom();
                          }
                          history.push($ReplaceRoute(AppRoutes.publish));
                          setSelectednavigationItem(10);
                          closeSidebar();
                          dispatch(SetHeaderTitle('Write & Publish'));
                        }}
                      >
                        <div className='AccordionTriggerFirst'>
                          <span className='AccordionIconWrapper'>
                            <Pencil1Icon className='AccordionTriggerIcon'></Pencil1Icon>
                          </span>
                          <span className={isCollapsed ? 'hidden' : ''}> Proposals</span>
                        </div>
                      </Accordion.Trigger>
                    </Accordion.Item>
                    <Accordion.Item className='AccordionItem' value='Team'>
                      <Accordion.Trigger
                        className={
                          isCollapsed
                            ? 'AccordionTrigger justify-content-center'
                            : 'AccordionTrigger'
                        }
                        onClick={() => {
                          if (isCollapsed) {
                            toggleSidebarCustom();
                          }
                        }}
                      >
                        <div className='AccordionTriggerFirst'>
                          <span className='AccordionIconWrapper'>
                            <AvatarIcon className='AccordionTriggerIcon'></AvatarIcon>
                          </span>
                          <span className={`${isCollapsed ? 'hidden' : ''}`}>Team</span>
                        </div>
                        <ChevronDownIcon
                          className={isCollapsed ? 'hidden' : 'AccordionChevron'}
                          aria-hidden
                        />
                      </Accordion.Trigger>

                      {!AccessPermition(RoleUser, [UserRoles.ExternalUser]) && (
                        <Accordion.Content
                          className={isCollapsed ? 'hidden' : 'AccordionContent accordion-button'}
                          onClick={(e) => {
                            history.push($ReplaceRoute(AppRoutes.team));
                            setSelectednavigationItem(3);
                            closeSidebar();
                            dispatch(SetHeaderTitle('Team'));
                          }}
                        >
                          <div
                            className={`AccordionContentText accordion-button-text ${
                              selectednavigationItem === 3 ? 'sidebar-child-selected' : ''
                            }`}
                          >
                            Teams
                          </div>
                        </Accordion.Content>
                      )}

                      <Accordion.Content
                        className={isCollapsed ? 'hidden' : 'AccordionContent accordion-button'}
                        onClick={(e) => {
                          history.push($ReplaceRoute(AppRoutes.member));
                          setSelectednavigationItem(4);
                          closeSidebar();
                          dispatch(SetHeaderTitle('Team'));
                        }}
                      >
                        <div
                          className={`AccordionContentText accordion-button-text ${
                            selectednavigationItem === 4 ? 'sidebar-child-selected' : ''
                          }`}
                        >
                          Users
                        </div>
                      </Accordion.Content>
                    </Accordion.Item>

                    {AccessPermition(RoleUser, [UserRoles.ExternalUser]) ? null : (
                      <Accordion.Item className='AccordionItem' value='Laboratory'>
                        <Accordion.Trigger
                          className={
                            isCollapsed
                              ? 'AccordionTrigger justify-content-center'
                              : 'AccordionTrigger'
                          }
                          onClick={() => {
                            if (isCollapsed) {
                              toggleSidebarCustom();
                            }
                          }}
                        >
                          <div className='AccordionTriggerFirst'>
                            <span className='AccordionIconWrapper'>
                              <RulerSquareIcon className='AccordionTriggerIcon'></RulerSquareIcon>
                            </span>
                            <span className={isCollapsed ? 'hidden' : ''}> Laboratory</span>
                          </div>
                          <ChevronDownIcon
                            className={isCollapsed ? 'hidden' : 'AccordionChevron'}
                            aria-hidden
                          />
                        </Accordion.Trigger>
                        <Accordion.Content
                          className={isCollapsed ? 'hidden' : 'AccordionContent accordion-button'}
                          onClick={(e) => {
                            history.push($ReplaceRoute(AppRoutes.laboratory));
                            setSelectednavigationItem(5);
                            closeSidebar();
                            dispatch(SetHeaderTitle('Laboratory'));
                          }}
                        >
                          <div
                            className={`AccordionContentText accordion-button-text ${
                              selectednavigationItem === 5 ? 'sidebar-child-selected' : ''
                            }`}
                          >
                            My Laboratory
                          </div>
                        </Accordion.Content>

                        <Accordion.Content
                          className={isCollapsed ? 'hidden' : 'AccordionContent accordion-button'}
                          onClick={(e) => {
                            history.push($ReplaceRoute(AppRoutes.equip));
                            setSelectednavigationItem(6);
                            closeSidebar();
                            dispatch(SetHeaderTitle('Laboratory'));
                          }}
                        >
                          <div
                            className={`AccordionContentText accordion-button-text ${
                              selectednavigationItem === 6 ? 'sidebar-child-selected' : ''
                            }`}
                          >
                            Equipment List
                          </div>
                        </Accordion.Content>
                      </Accordion.Item>
                    )}

                    {AccessPermition(RoleUser, [UserRoles.ExternalUser]) ? null : (
                      <Accordion.Item className='AccordionItem' value='Task'>
                        <Accordion.Trigger
                          className={`
                      ${
                        isCollapsed ? 'AccordionTrigger justify-content-center' : 'AccordionTrigger'
                      } ${selectednavigationItem === 7 ? 'sidebar-selected' : ''}
                    `}
                          onClick={(e) => {
                            if (isCollapsed) {
                              toggleSidebarCustom();
                            }
                            history.push($ReplaceRoute(AppRoutes.task));
                            setSelectednavigationItem(7);
                            closeSidebar();
                          }}
                        >
                          <div className='AccordionTriggerFirst'>
                            <span className='AccordionIconWrapper'>
                              <Pencil2Icon className='AccordionTriggerIcon'></Pencil2Icon>
                            </span>
                            <span className={isCollapsed ? 'hidden' : ''}>Task</span>
                          </div>
                        </Accordion.Trigger>
                      </Accordion.Item>
                    )}
                    {AccessPermition(RoleUser, [UserRoles.ExternalUser]) ? null : (
                      <Accordion.Item className='AccordionItem' value='Data'>
                        <Accordion.Trigger
                          className={`${
                            isCollapsed
                              ? 'AccordionTrigger justify-content-center'
                              : 'AccordionTrigger'
                          } ${selectednavigationItem === 8 ? 'sidebar-selected' : ''}`}
                          onClick={(e) => {
                            if (isCollapsed) {
                              toggleSidebarCustom();
                            }
                            history.push($ReplaceRoute(AppRoutes.data_list));

                            setSelectednavigationItem(8);
                            closeSidebar();
                          }}
                        >
                          <div className='AccordionTriggerFirst'>
                            <span className='AccordionIconWrapper'>
                              <PieChartIcon className='AccordionTriggerIcon'></PieChartIcon>
                            </span>
                            <span className={`${isCollapsed ? 'hidden' : ''}`}> Data</span>
                          </div>
                        </Accordion.Trigger>
                      </Accordion.Item>
                    )}
                    <Accordion.Item className='AccordionItem' value='Discussion'>
                      <Accordion.Trigger
                        className={`
                    ${
                      isCollapsed ? 'AccordionTrigger justify-content-center' : 'AccordionTrigger'
                    } ${selectednavigationItem === 9 ? 'sidebar-selected' : ''}
                  `}
                        onClick={(e) => {
                          if (isCollapsed) {
                            toggleSidebarCustom();
                          }
                          history.push($ReplaceRoute($ReplaceRoute(AppRoutes.discussion)));
                          setSelectednavigationItem(9);
                          closeSidebar();
                          dispatch(SetHeaderTitle('Communications'));
                        }}
                      >
                        <div className='AccordionTriggerFirst'>
                          <span className='AccordionIconWrapper'>
                            <ChatBubbleIcon className='AccordionTriggerIcon'></ChatBubbleIcon>
                          </span>
                          <span className={isCollapsed ? 'hidden' : ''}> Chat</span>
                        </div>
                      </Accordion.Trigger>
                    </Accordion.Item>
                    
                    {AccessPermition(RoleUser, [UserRoles.ExternalUser]) ? null : (
                      <Accordion.Item
                        className='AccordionItem'
                        // disabled
                        value='Financials'
                      >
                        <Accordion.Trigger
                          className={
                            isCollapsed
                              ? 'AccordionTrigger justify-content-center'
                              : 'AccordionTrigger'
                          }
                          onClick={() => {
                            if (isCollapsed) {
                              toggleSidebarCustom();
                            }
                            setSelectednavigationItem(11);
                            closeSidebar();
                          }}
                        >
                          <div className='AccordionTriggerFirst'>
                            <span className='AccordionIconWrapper'>
                              <img
                                src='/images/images/money.svg'
                                className='AccordionTriggerIcon'
                              />
                            </span>
                            <span className={isCollapsed ? 'hidden' : ''}> Financials</span>
                          </div>
                          <ChevronDownIcon
                            className={isCollapsed ? 'hidden' : 'AccordionChevron'}
                            aria-hidden
                          />
                        </Accordion.Trigger>
                        {!AccessPermition(RoleUser, [UserRoles.ExternalUser]) && (
                          <>
                            <Accordion.Content
                              className={
                                isCollapsed ? 'hidden' : 'AccordionContent accordion-button'
                              }
                              onClick={(e) => {
                                setSelectednavigationItem(12);
                                closeSidebar();
                                dispatch(SetHeaderTitle('Financials'));
                                history.push($ReplaceRoute(AppRoutes.grant));
                              }}
                            >
                              <div
                                className={`AccordionContentText accordion-button-text ${
                                  selectednavigationItem === 12 ? 'sidebar-child-selected' : ''
                                }`}
                              >
                                Grants
                              </div>
                            </Accordion.Content>
                            <Accordion.Content
                              className={
                                isCollapsed ? 'hidden' : 'AccordionContent accordion-button'
                              }
                              onClick={(e) => {
                                setSelectednavigationItem(13);
                                closeSidebar();
                                history.push($ReplaceRoute(AppRoutes.expenses));
                                dispatch(SetHeaderTitle('Financials'));
                              }}
                            >
                              <div
                                className={`AccordionContentText accordion-button-text ${
                                  selectednavigationItem === 13 ? 'sidebar-child-selected' : ''
                                }`}
                              >
                                My Expenses
                              </div>
                            </Accordion.Content>
                          </>
                        )}
                      </Accordion.Item>
                    )}

                    {AccessPermition(RoleUser, [UserRoles.ExternalUser]) ? null : (
                      <Accordion.Item className='AccordionItem' value='Ticketing' disabled={false}>
                        <Accordion.Trigger
                          className={
                            isCollapsed
                              ? 'AccordionTrigger justify-content-center'
                              : 'AccordionTrigger'
                          }
                          onClick={() => {
                            if (isCollapsed) {
                              toggleSidebarCustom();
                            }
                            history.push($ReplaceRoute(AppRoutes.ticketing));
                            setSelectednavigationItem(14);
                            dispatch(SetHeaderTitle('Ticketing'));
                          }}
                        >
                          <div className='AccordionTriggerFirst'>
                            <span className='AccordionIconWrapper'>
                              <InfoCircledIcon className='AccordionTriggerIcon'></InfoCircledIcon>
                            </span>
                            <span className={isCollapsed ? 'hidden' : ''}> Ticketing</span>
                          </div>
                        </Accordion.Trigger>
                      </Accordion.Item>
                    )}
                  </div>
                  <div style={{ backgroundColor: 'white' }}>
                    {AccessPermition(RoleUser, [UserRoles.ExternalUser]) ? null : (
                      <Accordion.Item className='AccordionItem' value='Setting'>
                        <Accordion.Trigger
                          className={
                            isCollapsed
                              ? 'AccordionTrigger justify-content-center'
                              : 'AccordionTrigger'
                          }
                          onClick={() => {
                            if (isCollapsed) {
                              toggleSidebarCustom();
                            }
                            history.push($ReplaceRoute(AppRoutes.setting));
                            setSelectednavigationItem(15);
                            closeSidebar();
                            dispatch(SetHeaderTitle('Settings'));
                          }}
                        >
                          <div className='AccordionTriggerFirst'>
                            <span className='AccordionIconWrapper'>
                              <GearIcon className='AccordionTriggerIcon'></GearIcon>
                            </span>
                            <span className={isCollapsed ? 'hidden' : ''}> Settings</span>
                          </div>
                          {/* <ChevronDownIcon
                      className={
                        isCollapsed ? "hidden" : "AccordionChevron"
                      }
                      aria-hidden
                    /> */}
                        </Accordion.Trigger>
                        {/* <Accordion.Content
                    className={
                      isCollapsed
                        ? "hidden"
                        : "AccordionContent accordion-button"
                    }
                  >
                    <div className="AccordionContentText accordion-button-text">
                      Add New Team
                    </div>
                  </Accordion.Content> */}
                      </Accordion.Item>
                    )}
                    {AccessPermition(RoleUser, [UserRoles.L1Client]) ? (
                      <Accordion.Item className='AccordionItem' value='Workspace Management'>
                        <Accordion.Trigger
                          className={
                            isCollapsed
                              ? 'AccordionTrigger justify-content-center'
                              : 'AccordionTrigger'
                          }
                          onClick={() => {
                            if (isCollapsed) {
                              toggleSidebarCustom();
                            }
                            history.push($ReplaceRoute(AppRoutes.seat_management));
                            setSelectednavigationItem(16);
                            closeSidebar();
                            dispatch(SetHeaderTitle('Workspace Management'));
                          }}
                        >
                          <div className='AccordionTriggerFirst'>
                            <span className='AccordionIconWrapper'>
                              <MixerHorizontalIcon className='AccordionTriggerIcon'></MixerHorizontalIcon>
                            </span>
                            <span className={isCollapsed ? 'hidden' : ''}>
                              {' '}
                              Workspace Management
                            </span>
                          </div>
                          {/* <ChevronDownIcon
                      className={
                        isCollapsed ? "hidden" : "AccordionChevron"
                      }
                      aria-hidden
                    /> */}
                        </Accordion.Trigger>
                        {/* <Accordion.Content
                    className={
                      isCollapsed
                        ? "hidden"
                        : "AccordionContent accordion-button"
                    }
                  >
                    <div
                      className={
                        isCollapsed ? "hidden" : "AccordionChevron"
                      }
                    >
                      Add New Team
                    </div>
                  </Accordion.Content> */}
                      </Accordion.Item>
                    ) : null}
                  </div>
                </>
              ) : (
                <div>
                  <Accordion.Item className='AccordionItem ' value='Dashboard'>
                    <Accordion.Trigger
                      className={`${
                        isCollapsed ? 'AccordionTrigger justify-content-center' : 'AccordionTrigger'
                      } ${selectednavigationItem === 0 ? 'sidebar-selected' : ''}`}
                      onClick={(e) => {
                        history.push($ReplaceRoute(AppRoutes.admin_clients));
                        setSelectednavigationItem(0);
                        if (isCollapsed) {
                          toggleSidebarCustom();
                        }
                        closeSidebar();
                        dispatch(SetHeaderTitle('Admin Home'));
                      }}
                    >
                      <div className='AccordionTriggerFirst'>
                        <span className='AccordionIconWrapper'>
                          <HomeIcon className='AccordionTriggerIcon'></HomeIcon>
                        </span>
                        <span className={isCollapsed ? 'hidden' : ''}> Admin Home</span>
                      </div>
                    </Accordion.Trigger>
                  </Accordion.Item>
                  <Accordion.Item className='AccordionItem ' value='Dashboard'>
                    <Accordion.Trigger
                      className={`${
                        isCollapsed ? 'AccordionTrigger justify-content-center' : 'AccordionTrigger'
                      } ${selectednavigationItem === 1 ? 'sidebar-selected' : ''}`}
                      onClick={(e) => {
                        history.push($ReplaceRoute(AppRoutes.admin_tickets));
                        setSelectednavigationItem(1);
                        if (isCollapsed) {
                          toggleSidebarCustom();
                        }
                        closeSidebar();
                        dispatch(SetHeaderTitle('Tickets'));
                      }}
                    >
                      <div className='AccordionTriggerFirst'>
                        <span className='AccordionIconWrapper'>
                          <InfoCircledIcon className='AccordionTriggerIcon'></InfoCircledIcon>
                        </span>
                        <span className={isCollapsed ? 'hidden' : ''}> Tickets</span>
                      </div>
                    </Accordion.Trigger>
                  </Accordion.Item>
                </div>
              )}
            </>
          </div>
        </Accordion.Root>
      </div>
    </Fragment>
  );
};
// export default withRouter(SidebarCopy);
export default SidebarCopy;
