import { nanoid } from "nanoid";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import Highlighter from "react-highlight-words";
import { returnArrayOfNumber } from "../../../core/utils";
import EmptyChip from "../EmptyChip";
import { BiSort } from "react-icons/bi";
import { SortType } from "../../../core/number_extentions";
type Props = {
  data: any[];
  columns: {
    Header: string;
    accessor: string;
    cellClassName?: string;
    Cell?: (row: any, _idx: number) => React.ReactNode;
    align?: "left" | "center" | "right";
    ellipsis?: boolean;
    maxWidth?: number;
    skeletonConfig?: any;
    headerCellClassName?: string;
    removeSort?: boolean;
  }[];
  customGridTemplate?: string;
  className?: string;
  rowClassName?: string;
  headerRowClassName?: string;
  searchValue?: string;
  loading?: boolean;
  loadingCount?: number;
  noDataFound?: React.ReactNode | string;
  noDataHeight?: boolean
  onHeaderClick: (columnHeaderName?: string, sortDirection?: SortType) => void;
};
const NoDataFound = () => {
  return <>No Data Found</>;
};
export default function NewTable({
  customGridTemplate,
  data,
  columns,
  className = "",
  rowClassName = "",
  loading = false,
  searchValue,
  loadingCount = 6,
  headerRowClassName = "",
  noDataFound = <NoDataFound />,
  noDataHeight = false,
  onHeaderClick
}: Props) {
  const [currentSortColumn, setCurrentSortColumn] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<SortType>(SortType.ASC);
  const handleHeaderClick = (columnHeaderName: string) => {
    const clickedColumn = columns.find((column) => column.Header === columnHeaderName);
    let localSortDirection: SortType = sortDirection;
    if (clickedColumn && clickedColumn.Header != "Actions" && clickedColumn.Header != "" && (clickedColumn.removeSort == null|| clickedColumn.removeSort == false)) {
      setCurrentSortColumn(columnHeaderName);
      if (columnHeaderName === currentSortColumn) {
        localSortDirection = sortDirection === SortType.ASC ? SortType.DESC : SortType.ASC;
        setSortDirection(localSortDirection);
      }
      onHeaderClick(clickedColumn.accessor, localSortDirection);
    }
  };
  return (
    <div className={`d-flex flex-column ${className}`}>
      <div
        className={`newTableRow newTableRowInSm ${headerRowClassName}`}
        style={{
          gridTemplateColumns: customGridTemplate
            ? customGridTemplate
            : `repeat(${columns.length}, 1fr)`,
          borderBottom: 0,
        }}
      >
        {columns.map((column: any) => (
          <span
            key={nanoid()}
            className={`fs-200 font-bold ${column.headerCellClassName || ''} ${column.removeSort == null || column.removeSort == false ? ' table-header' : ''}`}
            style={{
              ...(column.align ? { textAlign: column.align } : {}),
              ...(column.maxWidth ? { maxWidth: column.maxWidth } : {}),
               
            }}
            onClick={() => handleHeaderClick(column.Header)}
          >
            {column.Header}
            {currentSortColumn === column.Header && column.Header != "Actions" && (column.removeSort == null|| column.removeSort == false) && column.Header != ""  && (
              sortDirection === "ASC" ? (
                <img
                  src={`/images/icons/arrow_down.svg`}
                  alt="radvix"
                  width={14}
                  height={14}
                  style={{ marginLeft: "2%" }}
                />
              ) : (
                sortDirection === "DESC" && column.Header != "Actions" && (column.removeSort == null|| column.removeSort == false) && column.Header != "" && (
                  <img
                    src={`/images/icons/arrow_up.svg`}
                    alt="radvix"
                    width={14}
                    height={14}
                    style={{ marginLeft: "2%" }}
                  />
                )
              )
            )}
            {currentSortColumn !== column.Header && column.Header != "Actions" && (column.removeSort == null|| column.removeSort == false) && column.Header != "" && (
              <img src="/images/icons/sort.svg"
                alt="radvix"
                width={14}
                height={14}
                style={{ marginLeft: "2%" }} />
            )}
          </span>
        ))}
      </div>
      <div
        className="newTableParent d-flex flex-column"
        style={{ position: "relative" }}
      >
        {loading ? (
          returnArrayOfNumber(loadingCount).map((row: any) => (
            <div
              key={nanoid()}
              className={`newTableRow ${rowClassName}`}
              style={{
                gridTemplateColumns: customGridTemplate
                  ? customGridTemplate
                  : `repeat(${columns.length}, 1fr)`,
              }}
            >
              {columns.map((column) => (
                <span
                  key={nanoid()}
                  title={
                    column.ellipsis && !column.Cell ? row[column.accessor] : ""
                  }
                  className={`fs-100 ${column.cellClassName ? column.cellClassName : ""
                    } ${column.ellipsis ? "max1LineText" : ""}`}
                  style={{
                    minHeight: '1em',
                    minWidth: column.maxWidth ? '1em' : '3em',
                    ...(column.align ? { textAlign: column.align } : {}),
                    ...(column.maxWidth ? { maxWidth: column.maxWidth } : {}),
                  }}
                >
                  <Skeleton
                    width="100%"
                    height="1.25em"
                    containerClassName="lineHeight1"
                    {...column.skeletonConfig}
                  />
                </span>
              ))}
            </div>
          ))
        ) : data && data.length > 0 ? (
          data.map((row, _idx) => (
            <div
              key={nanoid()}
              className={`newTableRow ${rowClassName}`}
              style={{
                gridTemplateColumns: customGridTemplate
                  ? customGridTemplate
                  : `repeat(${columns.length}, 1fr)`,
              }}
            >
              {columns.map((column) => (
                <span
                  key={nanoid()}
                  title={
                    column.ellipsis && !column.Cell ? row[column.accessor] : ""
                  }
                  className={`fs-100 ${column.cellClassName ? column.cellClassName : ""
                    } ${column.ellipsis ? "max1LineText" : ""}`}
                  style={{
                    ...(column.align ? { textAlign: column.align } : {}),
                    ...(column.maxWidth ? { maxWidth: column.maxWidth } : {}),
                  }}
                >
                  {column.Cell ? (
                    column.Cell(row, _idx)
                  ) : (
                    <>
                      {searchValue ? (
                        <Highlighter
                          highlightClassName="highlightedText"
                          searchWords={
                            searchValue.length >= 2
                              ? searchValue?.split(" ")
                              : []
                          }
                          autoEscape={true}
                          textToHighlight={String(row[column.accessor])}
                        />
                      ) : (
                        row[column.accessor] || "-"
                      )}
                    </>
                  )}
                </span>
              ))}
            </div>
          ))
        ) : (
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ minHeight: noDataHeight ? "12.5em" : "12em" }}
          >
            <EmptyChip text={noDataFound} className="fs-200" />
          </div>
        )}
      </div>
    </div>
  );
}
