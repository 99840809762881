import { InputIcon } from '../../../components/search_box';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import RadixTable from '../../../components/NewRadixTable/RadixTable';
import { DataController } from '../../../../controllers/data/data_controller';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../../../data/storeMain';
import { SortType } from '../../../../core/number_extentions';
import { IFilterOptions } from '../../../components/FilterSection/IFilterOptions';
import { useHistory } from 'react-router';
import { AppRoutes, MOMENT_DATE_FORMAT } from '../../../../core/constants';
import { $ReplaceRoute } from '../../../../core/utils';
import { DropdownMenu, Table } from '@radix-ui/themes';
import Tippy from '@tippyjs/react';
import { ImageWithToken } from '../../../components/box_list_scroll';
import moment from 'moment';
import { useDebounceEffect } from '../../../../hooks/useDebounceEffect';
import Pagination from '../../../components/NewRadixTable/RadixPagination';

export default function DataTabContent({ taskId }: { taskId: any }) {
  const controller = new DataController();
  const [dataList, setDataList] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const selectedProject = useSelector((state) => store.getState().ResearchId);

  const [searchValue, setSearchValue] = useState('');
  const [headerName, setHeaderName] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<SortType>(SortType.AutoSelect);
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>({});
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [visibleTippy, setVisibleTippy] = useState<boolean>();

  const [paginate, setPaginate] = useState<{
    pageSize: { label: string; value: number };
    pageNumber: number;
    pageCount: number;
    totalCount: number;
    PageNumberInput: string;
  }>({
    pageSize: { label: '10', value: 10 },
    pageNumber: 0,
    pageCount: 0,
    totalCount: 0,
    PageNumberInput: '',
  });
  const history = useHistory();

  function handleFilterDataListByTaskId(_filterForm: {
    researchId: number;
    PageSize: number;
    PageNumber: number;
    SearchParameter: string;
    columnHeaderName?: string;
    sortDirection?: SortType;
    filterOptions?: IFilterOptions;
    selectedTasks?: number;
  }) {
    setLoading(true);
    if (_filterForm.sortDirection == undefined || _filterForm.sortDirection == null) {
      _filterForm.sortDirection = SortType.AutoSelect;
    }
    controller.getDataListByTaskId(
      {
        researchId: _filterForm.researchId,
        PageNumber: _filterForm.PageNumber,
        PageSize: _filterForm.PageSize,
        SearchParameter: _filterForm.SearchParameter,
        TaskId: taskId,
        ColumnHeaderName: _filterForm.columnHeaderName || '',
        SortDirection: _filterForm.sortDirection || SortType.AutoSelect,
        filterOptions: _filterForm.filterOptions || {},
      },
      (_dataList) => {
        if (!_dataList) {
          setLoading(false);
          setDataList([]);
          return;
        }
        setDataList(_dataList.datas);
        setPaginate((prev) => ({
          ...prev,
          pageCount: Math.ceil(_dataList.count! / paginate.pageSize.value),
          totalCount: _dataList.count,
        }));
        setLoading(false);
      },
      (_err) => {
        setLoading(false);
      }
    );
  }

  useDebounceEffect(
    () => {
      if (searchValue.length > 0)
        handleFilterDataListByTaskId({
          researchId: selectedProject?.value,
          PageNumber: paginate.pageNumber + 1,
          PageSize: paginate.pageSize.value,
          SearchParameter: searchValue,
        });
    },
    500,
    [searchValue]
  );

  useEffect(() => {
    handleFilterDataListByTaskId({
      researchId: selectedProject?.value,
      PageSize: 10,
      PageNumber: 1,
      SearchParameter: '',
      sortDirection: undefined,
      filterOptions: {},
    });
  }, [taskId]);

  const headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    setHeaderName(columnHeaderName || '');
    setSortDirection(sortDirection || SortType.AutoSelect);
    const _filterForm = {
      researchId: selectedProject?.value,
      PageSize: paginate.pageSize.value,
      PageNumber: paginate.pageNumber + 1,
      SearchParameter: searchValue,
      columnHeaderName,
      sortDirection: SortType.AutoSelect,
      filterOptions,
    };
    handleFilterDataListByTaskId(_filterForm);
  };

  const filterClick = (
    selectedFromDate?: Date,
    selectedToDate?: Date,
    status?: number[],
    teamType?: number[]
  ) => {
    var filterOptions = {
      selectedFromDate: selectedFromDate,
      selectedToDate: selectedToDate,
      status: status,
      teamType: teamType,
    };
    setFilterOptions(filterOptions);
    const _filterForm = {
      researchId: selectedProject?.value,
      PageSize: paginate.pageSize.value,
      PageNumber: paginate.pageNumber + 1,
      SearchParameter: searchValue,
      headerName,
      sortDirection,
      filterOptions,
    };
    handleFilterDataListByTaskId(_filterForm);
  };

  const handleStartDateChange = (value: any) => {
    setStartDate(value);
    filterClick(value, endDate);
  };
  const handleEndDateChange = (value: any) => {
    setEndDate(value);
    filterClick(startDate, value);
  };

  const handleToggleTippy = () => {
    setVisibleTippy((prevSt) => !prevSt);
  };

  const downloadFile = (props: any) => {
    if (props.externalUrl) {
      const aElement = document.createElement('a');
      aElement.href = props.externalUrl;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(props.externalUrl);
    } else if (props?.name) {
      const aElement = document.createElement('a');
      aElement.href = props.name;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(props.name);
    }
  };

  return (
    <div aria-labelledby='data-tab'>
      <div
        className='d-flex flex-md-row border-top border-gray-400 py-3 flex-column row-gap-md-0 row-gap-px-16 px-3 pb-3 filters'
        style={{ width: '100%' }}
        id='showFilter'
      >
        <div className='pe-md-3 border-end-md-1 border-gray-400 d-sm-block d-flex gap-3 flex-grow-1 filters-search-wrapper'>
          <InputIcon
            className='form-control h-px-35'
            placeholder='Search Dataset'
            chilren={undefined}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </div>
        <div className='d-flex align-items-center w-100 w-md-50 w-lg-auto ps-md-3'>
          <div className='w-50 date-pickers-wrapper'>
            <div className='date-picker-wrapper'>
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                dateFormat='MM/dd/yyyy'
                placeholderText='Start Date'
                maxDate={new Date()}
                className='form-control h-px-35 customBorders date-picker'
              />
            </div>
          </div>
          <div className='px-2 flex-shrink-0 color-gray-400'>&ndash;</div>
          <div className='w-50 date-pickers-wrapper'>
            <div className='date-picker-wrapper'>
              <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                dateFormat='MM/dd/yyyy'
                placeholderText='End Date'
                className='form-control h-px-35 customBorders date-picker'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='overflow-auto vh-calc-xl-320 vh-calc-sm-415 px-3'>
        <RadixTable
          data={dataList}
          loading={loading}
          // onHeaderClick={(columnHeaderName: string, sortDirection: SortType) =>
          //   headerClick(columnHeaderName, sortDirection)
          // }
          columns={[
            {
              Header: 'Dataset name',
              accessor: 'appTaskTitle',
              Cell: (_props: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <div className='line-clamp-2'>
                      <i className='fa fa-grip-vertical me-2 color-gray-600 fs-13'></i>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          history.push(
                            $ReplaceRoute(
                              `${AppRoutes.data_profile
                                .replace(':dataid', _props.id)
                                .replace(
                                  ':appTaskId',
                                  _props.taskId?.toString() ?? ''
                                )}?researchId=${_props.researchId}`
                            )
                          );
                        }}
                        title={_props.title}
                      >
                        {' '}
                        {_props.title}{' '}
                      </span>
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Files',
              accessor: 'medias',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3 text-nowrap'>
                    {/* {<ShowFilesInTable medias={_row.medias} profileId={0} profileType={0} /> */}
                    <div style={{ display: 'flex', gap: '4px' }}>
                      {props.medias && props.medias.length > 0 && (
                        <span
                          key={props.medias[0]}
                          className='badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team text-truncate'
                          onClick={() => downloadFile(props.medias[0])}
                          style={{ cursor: 'pointer', maxWidth: '400px' }}
                        >
                          {props.medias[0].externalUrl
                            ? props.medias[0].externalUrl
                            : props.medias[0].title}
                        </span>
                      )}
                      {props.medias.slice(1).length > 0 && (
                        <>
                          <Tippy
                            content={
                              <div className='d-flex flex-column py-2 pb-0 gap-6'>
                                {props.medias.slice(1).map((media: any) => (
                                  <span
                                    key={media.id}
                                    className='badge clip-path text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team'
                                    onClick={() => downloadFile(media)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {media.externalUrl ? media.externalUrl : media.title}
                                  </span>
                                ))}
                              </div>
                            }
                            visible={visibleTippy}
                            onClickOutside={() => setVisibleTippy(false)}
                            interactive={true}
                            placement='bottom'
                          >
                            <span
                              onClick={handleToggleTippy}
                              className='radix-multiSelect-number'
                              style={{ cursor: 'pointer' }}
                            >
                              {`+${props.medias.slice(1).length}`}
                            </span>
                          </Tippy>
                        </>
                      )}
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Added by',
              accessor: 'creatorFirstName',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <div className='d-flex align-items-center text-nowrap'>
                      {
                        <ImageWithToken
                          originalImage={props.creatorUser?.image}
                          hasImage={props.creatorUser?.image ? true : false}
                          alt='Avatar'
                          className='rounded-circle avatar rounded-avatar-dashboard'
                          src={
                            props.creatorUser?.image === null ||
                            props.creatorUser?.image === '' ||
                            props.creatorUser?.image === undefined
                              ? '/images/images/img_avatar.png'
                              : props.creatorUser?.image
                          }
                          textInAvatar={`${props.creatorUser?.firstName} ${props.creatorUser?.lastName}`}
                        />
                      }
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          history.push(
                            $ReplaceRoute(
                              `${AppRoutes.member_profile.replace(
                                ':id',
                                props.creatorUser.id?.toString() ?? ''
                              )}`
                            )
                          );
                        }}
                      >
                        {props.creatorUser?.firstName} {props.creatorUser?.lastName}
                      </span>
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Date attached',
              accessor: 'createdDate',
              Cell: (props: any) => {
                return (
                  <Table.Cell>{moment(props.createdDate).format(MOMENT_DATE_FORMAT)}</Table.Cell>
                );
              },
            },
            {
              Header: '',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3 text-nowrap'>
                    <div className='d-flex align-items-center gap-3'>
                      <div className='btn-group'>
                        <button
                          type='button'
                          className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                          onClick={(e) => {
                            history.push(
                              $ReplaceRoute(
                                `${AppRoutes.data_profile
                                  .replace(':dataid', props.id)
                                  .replace(
                                    ':appTaskId',
                                    props.taskId?.toString() ?? ''
                                  )}?researchId=${props.researchId}`
                              )
                            );
                          }}
                        >
                          <i className='fa fa-eye me-px-6'></i>View Details
                        </button>
                        <DropdownMenu.Root>
                          <DropdownMenu.Trigger>
                            <button
                              type='button'
                              className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                            >
                              <span className='visually-hidden'>Toggle Dropdown</span>
                            </button>
                          </DropdownMenu.Trigger>
                          <DropdownMenu.Content>
                            <DropdownMenu.Item className='px-0'>
                              <button
                                className='dropdown-item fs-14'
                                onClick={(e) => {
                                  history.push(
                                    $ReplaceRoute(
                                      `${AppRoutes.data_profile
                                        .replace(':dataid', props.id)
                                        .replace(
                                          ':appTaskId',
                                          taskId?.toString() ?? ''
                                        )}?researchId=${props.researchId}`
                                    )
                                  );
                                }}
                              >
                                View Details
                              </button>
                            </DropdownMenu.Item>
                            <DropdownMenu.Item className='px-0'>
                              <button
                                className='dropdown-item fs-14'
                                onClick={(e) => {
                                  history.push($ReplaceRoute(`${AppRoutes.discussion_new}`));
                                }}
                              >
                                Start discussion
                              </button>
                            </DropdownMenu.Item>
                          </DropdownMenu.Content>
                        </DropdownMenu.Root>
                      </div>
                    </div>
                  </Table.Cell>
                );
              },
            },
          ]}
        />
        {paginate.pageCount > 1 && (
          <Pagination
            pageCount={paginate.pageCount}
            onPageChange={(e: any) => {
              setPaginate((prev) => ({
                ...prev,
                pageNumber: e.selected,
              }));

              handleFilterDataListByTaskId({
                researchId: selectedProject.value,
                PageNumber: e.selected + 1,
                PageSize: paginate.pageSize.value,
                SearchParameter: searchValue,
                columnHeaderName: headerName,
                sortDirection: sortDirection,
              });
            }}
            onPageSizeChange={(e: any) => {
              setPaginate((prev) => ({ ...prev, pageSize: e }));
            }}
            pageInput={paginate.PageNumberInput}
            handlePageNumberInput={(e: any) => {
              setPaginate((prev) => ({
                ...prev,
                pageNumber: e.selected,
              }));

              handleFilterDataListByTaskId({
                researchId: selectedProject?.value,
                PageNumber: e.selected + 1,
                PageSize: paginate.pageSize.value,
                SearchParameter: searchValue,
                columnHeaderName: headerName,
                sortDirection: sortDirection,
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
