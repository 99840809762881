import React, { ChangeEvent, Component } from 'react';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import { RouteComponentProps } from 'react-router';
import { MainButton, MainButtonType } from '../../components/button';
import { HTTP } from '../../../core/http_common';
import { AiInstructionsEnum } from '../../../core/utils';

interface State {
  UpdateNotificationButton: boolean;
  UpdateLearningButton: boolean;
  learningTextBox: string;
  NotificationTextBox: string;
  loading: boolean;
}

class AI_Instruction extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      UpdateNotificationButton: false,
      UpdateLearningButton: false,
      loading: false,
      learningTextBox: '',
      NotificationTextBox: '',
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    if(this.state.NotificationTextBox === "")
    {
      this.setState({UpdateNotificationButton : false})
    }
    else
    {
      this.setState({UpdateNotificationButton : true})
    }
    if(this.state.learningTextBox === "")
    {
      this.setState({UpdateLearningButton : false})
    }
    else{
      this.setState({UpdateLearningButton : true})
    }
  
    try {
      const response = await HTTP.get('/AiInstructions/Search', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const data = response.data.result;
  
      if (data && data.length > 0) {
        const [firstItem, secondItem] = data;
  
        this.setState(prevState => ({
          NotificationTextBox: firstItem.type === 0 ? firstItem.prompt : prevState.NotificationTextBox,
          learningTextBox: firstItem.type === 1 ? firstItem.prompt : prevState.learningTextBox,
          UpdateNotificationButton: firstItem.type === 0 ? true : false,
          UpdateLearningButton: firstItem.type === 1 ? true : false,
        }));
  
        if (secondItem.type === 1) {
          this.setState(prevState => ({
            learningTextBox: secondItem.prompt,
            UpdateLearningButton: true,
          }));
        } else {
          this.setState(prevState => ({
            NotificationTextBox: secondItem.prompt,
            UpdateNotificationButton: true,
          }));
        }
      } else {
        this.setState({
          NotificationTextBox: '',
          learningTextBox: '',
          UpdateLearningButton: false,
          UpdateNotificationButton: false
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.setState({ loading: false });
    }
  }
  
  handleChangeNotification = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      NotificationTextBox: e.target.value,

    });
  };

  handleChangeLearning = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      learningTextBox: e.target.value,
    });
  };

  handleSaveNotificationButtonClick = async () => {
    if (this.state.UpdateNotificationButton) {
      try {
        const body = {
          Type: AiInstructionsEnum.notification,
          Prompt: this.state.NotificationTextBox,
        }
        this.setState({ loading: true });
        const response = await HTTP.put('/AiInstructions/Update', body, {
          
        });
        if (response.data.message === 'Success' && response.data.status === 200) {
          this.setState({ loading: false })
          this.setState({ UpdateNotificationButton: true })
        }
      } catch (error) {
        console.error("Error sending message:", error);
      } finally {
        //setLoading(false);
      }
    }
    else {
      try {
        const body = {
          Type: AiInstructionsEnum.notification,
          Prompt: this.state.NotificationTextBox,
        }
        this.setState({ loading: true });
        const response = await HTTP.post('/AiInstructions/Create', body, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.data.message === 'Success' && response.data.status === 200) {
          this.setState({ loading: false })
          this.setState({ UpdateNotificationButton: true })
        }
      } catch (error) {
        console.error("Error sending message:", error);
      } finally {
        //setLoading(false);
      }
    }

  };

  handleSaveLearningButtonClick = async () => {
    if (this.state.UpdateLearningButton) {
      try {
        const body = {
          Type: AiInstructionsEnum.learningCenter,
          Prompt: this.state.learningTextBox,
        }
        this.setState({ loading: true });
        const response = await HTTP.put('/AiInstructions/Update', body, {
          
        });
        if (response.data.message === 'Success' && response.data.status === 200) {
          this.setState({ loading: false })
          this.setState({ UpdateLearningButton: true })
        }
      } catch (error) {
        console.error("Error sending message:", error);
      } finally {
        this.setState({loading: false})
        //setLoading(false);
      }
    }
    else {
      try {
        const body = {
          Type: AiInstructionsEnum.learningCenter,
          Prompt: this.state.learningTextBox,
        }
        const response = await HTTP.post('/AiInstructions/Create', body, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.data.message === 'Success' && response.data.status === 200) {
          this.setState({ loading: false })
          this.setState({ UpdateLearningButton: true })

        }
      } catch (error) {
        this.setState({loading: false})
        console.error("Error sending message:", error);
      } finally {
        //setLoading(false);
      }
    }
  };

    render() {
      return (
        <div style={{ paddingTop: "16px" }}>
          <div className="d-flex justify-content-left align-items-center" style={{ backgroundColor: "#ebebeb", borderRadius: "11px" }}>
            <div className="TableBox">
              <div className="TopTableBox d-flex flex-column flex-md-row justify-content-between align-items-md-start mb-3">
                <div className="left d-flex align-items-baseline">
                  <h4 className="b-title d-flex align-items-center">
                    <IoIosArrowDropleftCircle
                      className="me-3 textSecondary2 cursorPointer"
                      size="1.5em"
                      onClick={() => {
                        window.history.back();
                      }}
                    />{" "}
                    AI Instruction
                  </h4>
                </div>
              </div>
              <div className="TopTableBox d-flex flex-column flex-md-row justify-content-between align-items-md-start mb-3">
                <div className='row' style={{ paddingTop: "14px", marginLeft: "0px" }}>
                  <h6>ChatGPT Notification Instruction</h6>
                  <div className="col-md-12 mb-3">
                    <textarea
                      className='aiInstruction InputComponentStyle form-control'
                      placeholder="Write here... "
                      value={this.state.NotificationTextBox}
                      onChange={this.handleChangeNotification}
                    ></textarea>
                  </div>
                  <div style={{ width: "92%" }}>
                    <MainButton
                      loading={this.state.loading}
                      type={MainButtonType.dark}
                      children={this.state.UpdateNotificationButton ? "Update" : "Save"}
                      // loading={this.state.loading || this.state.searchLoading}
                      // disabled={this.state.loading || this.state.searchLoading}
                      borderRadius="50px"
                      fontSize="15px"
                      className="mx-2 px-3"
                      minHeight="43px"
                      onClick={this.handleSaveNotificationButtonClick}
                      minWidth="136px"
                      style={{ float: "right" }}
                    ></MainButton>
                  </div>
                </div>
                <div className='row' style={{ paddingTop: "14px" }} >
                  <h6>Learning Center Instruction</h6>
                  <div className="col-md-12 mb-3">
                    <textarea
                      className='aiInstruction InputComponentStyle form-control'
                      placeholder="Write here... "
                      value={this.state.learningTextBox}
                      onChange={this.handleChangeLearning}
                    ></textarea>
                  </div>
                  <div style={{ width: "92%" }}>
                    <MainButton
                      loading={this.state.loading}
                      type={MainButtonType.dark}
                      children={this.state.UpdateLearningButton ? "Update" : "Save"}
                      // loading={this.state.loading || this.state.searchLoading}
                      // disabled={this.state.loading || this.state.searchLoading}
                      borderRadius="50px"
                      fontSize="15px"
                      className="mx-2 px-3"
                      minHeight="43px"
                      style={{ float: "right" }}
                      onClick={this.handleSaveLearningButtonClick}
                      minWidth="136px"
                    ></MainButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      );
    }
  }

export default AI_Instruction;
