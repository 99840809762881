import React, { Fragment } from "react";
import { store } from "../../../data/storeMain";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import { InputComponent, InputType } from "../../components/inputs";
import "react-datepicker/dist/react-datepicker.css";
import { MainButton, MainButtonType } from "../../components/button";
import Dropzone from "react-dropzone";
import { IconTextRow } from "../../components/icon_text_horizontal";
import {
  // $truncate,
  getUserTitle,
  isTokenInHttpAuth,
  Theme,
  uploadAllFiles,
  uploadAllLinks,
} from "../../../core/utils";
import {
  BoxListScroll,
  ImageWithToken,
} from "../../components/box_list_scroll";
import { TextField } from "@radix-ui/themes";
import { RouteComponentProps, withRouter } from "react-router";
import { GetDiscusstionPanelResResult } from "../../../data/models/responses/discussion/get_discusstion_panel_res";
import { DiscusstionController } from "../../../controllers/discussion/discusstion_controller";
import { LocalDataSources } from "../../../data/local_datasources";
import { UploadController } from "../../../controllers/upload_media/upload_media";
import SimpleReactValidator from "simple-react-validator";
// import moment from "moment";
import {
  // AppConstants,
  BROADCAST_TYPE_LABELS,
  LOADING_MODE,
  MOMENT_DATE_TIME_FORMAT,
  TICKET_STATUS_COLORS,
  TICKET_STATUS_LABELS,
} from "../../../core/constants";
import { HTTP } from "../../../core/http_common";
import { MdRefresh } from "react-icons/md";
import ChatArea from "../../components/ChatArea/chatArea";
import {
  Priority,
  ProfileTypes,
  ticketFilterStatus,
} from "../../../core/number_extentions";
import { BaseButton } from "@fluentui/react";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Tippy from "@tippyjs/react";
import AddMemberContent from "../discussion/component/add_member_content";
import UserWithAvatar from "../../components/UserWithAvatar";
import GroupedAvatars from "../../components/groupedAvatars";
interface RouteParams {
  id: string;
}
type StateType = {
  files: Array<File>;
  Discusstion: GetDiscusstionPanelResResult;
  myUserId: string;
  loading: boolean;
  ExternalUrl: Array<string>;
  External: string;
  massage: string;
  chatLoading: boolean;
  searchLoading: boolean;
  loadingMode: "";
  showAttachments: boolean;
};
class Ticket extends React.Component<RouteComponentProps<RouteParams>> {
  RoleUser = store.getState().userRole;
  date = new Date();
  controller = new DiscusstionController();
  local = new LocalDataSources();
  UploadController = new UploadController();
  validator = new SimpleReactValidator({
    className: "text-danger",
  });
  handelChangeDate(params: any): void {}
  state: StateType = {
    files: [],
    searchLoading: false,
    loadingMode: "",
    chatLoading: false,
    Discusstion: {
      creatorUserFirstName: "",
      creatorUserId: "",
      creatorUserLastName: "",
      histories: [],
      id: 0,
      isTicket: false,
      priority: 0,
      subject: "",
      topic: 0,
      users: [],
      ticketStatus: null,
      broadcastTypes: [],
      sectionId: 0,
      broadcastCounter: 0,
      ticketCounter: 0,
    },
    myUserId: this.local.getUserId(),
    loading: false,
    ExternalUrl: [],
    External: "",
    massage: "",
    showAttachments: false,
  };
  componentDidMount() {
    if (!isTokenInHttpAuth(HTTP)) return;
    this.getDiscustionPanel();
  }
  getDiscustionPanel() {
    this.setState({ chatLoading: true });
    this.controller.getDiscusstionPanel(
      {
        discussionId: parseInt(this.props.match.params.id),
        ticket: true,
      },
      (res) => {
        document.title = `Ticket | ${res.subject} | Radvix`;
        const _users = res.users.map((_u: any) => {
          const userTitleAst = getUserTitle(_u);
          return {
            ..._u,
            title: userTitleAst,
            secondary: _u.invitationStatus === 1 ? "Pending" : null,
          };
        });
        let _broadcastTypes: any[] = [];
        if (typeof res.broadcastTypes === "string" && res.broadcastTypes) {
          _broadcastTypes = res.broadcastTypes.split(",");
        }
        const updatedHistories = res.histories.map((history) => {
          return {
            ...history,
            sectionId: res.id, // Check if res.id is the correct property to set here
            attachments: (history.attachments || []).map((attachment) => ({
              ...attachment,
              sectionId: res.id, // Check if res.sectionId is the correct property to set here
              profileType: ProfileTypes.TICKETS,
            })),
          };
        });

        this.setState({
          Discusstion: {
            ...res,
            users: _users,
            broadcastTypes: _broadcastTypes,
            histories: updatedHistories,
          },
          chatLoading: false,
        });
      },
      (err) => {
        this.setState({ chatLoading: false });
      }
    );
  }
  onDrop = (fileList: any) => {
    fileList.map((item: any, index: number) => {
      const found = this.state.files.find(
        (element: any) => element.name === item.name
      );
      if (found === undefined) {
        this.setState({ files: [fileList[index], ...this.state.files] });
      }
    });
  };
  handleChange(target: string, val: any) {
    this.setState({
      [target]: val,
    });
  }
  async handelUpload(id: number) {
    this.setState({
      loadingMode: LOADING_MODE.links,
    });
    await uploadAllLinks(id, this.state.ExternalUrl, 7);
    this.setState({
      loadingMode: LOADING_MODE.files,
    });
    await uploadAllFiles(id, this.state.files, 7);
    this.setState({
      loading: false,
      loadingMode: "",
      ExternalUrl: [],
      External: "",
      massage: "",
      files: [],
    });
    this.getDiscustionPanel();

    // const formData = new FormData();
    // for (let i = 0; i < this.state.files.length; i++) {
    //   const file = this.state.files[i];
    //   formData.append("Files", file);
    // }
    // for (let i = 0; i < this.state.ExternalUrl.length; i++) {
    //   const file = this.state.ExternalUrl[i];
    //   formData.append("ExternalUrls", file);
    // }
    // formData.append("UseCase", "7");
    // formData.append("SectionId", id.toString());

    // await this.UploadController.UloadMedia(
    //   formData,
    //   (res) => {

    //   },
    //   () => {
    //     this.setState({
    //       loading: false,
    //       ExternalUrl: [],
    //       External: "",
    //       massage: "",
    //       files: [],
    //     });
    //   }
    // );
  }
  addExternalUrl() {
    if (this.validator.fields.input) {
      let Url = [...this.state.ExternalUrl];
      if (this.state.External.length > 2) {
        Url.push(this.state.External);
      }
      this.setState({
        ExternalUrl: Url,
        External: "",
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.key === "Enter" || event.key === "Return") && !event.shiftKey) {
      event.preventDefault(); // Prevent default Enter key behavior (e.g., form submission)
      this.SendMassage();
    }
  };

  renderStatus(status: any) {
    debugger;
    if (status == 1) {
      return (
        <span className="badge bg-info text-white py-px-6 rounded-pill fs-13 fw-normal px-3">
          Open
        </span>
      );
    } if(status == 2) {
      return (
        <span className="badge bg-success text-white py-px-6 rounded-pill fs-13 fw-normal px-3">
          Close
        </span>
      );
    }
  }
  renderPrioirty(status: any) {
    if (status == Priority.High) {
      return (
        <span className="badge bg-danger text-white py-px-6 rounded-pill fs-13 fw-normal px-3">
          High
        </span>
      );
    }
    if (status == Priority.Medium) {
      return (
        <span className="badge bg-warning text-white py-px-6 rounded-pill fs-13 fw-normal px-3">
          Medium
        </span>
      );
    }
    if (status == Priority.Low) {
      return (
        <span className="badge bg-success text-white py-px-6 rounded-pill fs-13 fw-normal px-3">
          Low
        </span>
      );
    }
  }

  downloadFile(props: any) {
    if (props.externalUrl) {
      const aElement = document.createElement("a");
      aElement.href = props.externalUrl;
      aElement.setAttribute("target", "_blank");
      aElement.click();
      URL.revokeObjectURL(props.externalUrl);
    } else if (props?.name) {
      const aElement = document.createElement("a");
      aElement.href = props.name;
      aElement.setAttribute("target", "_blank");
      aElement.click();
      URL.revokeObjectURL(props.name);
    }
  }

  handelDeleteFile(arg: File) {
    this.setState({
      files: this.state.files.filter((file) => file.name !== arg.name),
    });
  }
  handelDeleteExternalLink(link: string) {
    this.setState({
      ExternalUrl: this.state.ExternalUrl.filter((item) => item !== link),
    });
  }
  SendMassage() {
    if (this.validator.allValid()) {
      const body = {
        discussionId: parseInt(this.props.match.params.id),
        message: this.state.massage,
      };

      this.setState({
        loading: true,
        loadingMode: "Sending..",
      });
      this.controller.createMassage(
        body,
        async (res) => {
          if (
            this.state.files.length > 0 ||
            this.state.ExternalUrl.length > 0
          ) {
            await this.handelUpload(res.result);
          } else {
            this.getDiscustionPanel();
            this.setState({
              loading: false,
              ExternalUrl: [],
              loadingMode: "",
              External: "",
              massage: "",
              files: [],
            });
          }
        },
        (err) => {
          this.setState({
            loading: false,
            ExternalUrl: [],
            External: "",
            loadingMode: "",
            massage: "",
            files: [],
          });
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  render() {
    const selectedDiscussion = this.state.Discusstion?.histories?.map(
      (x: any) =>
        x.userId !== store.getState().userInfo.id ? (
          <div className="mb-3">
            <div className="p-3 bg-gray-200 rounded-px-6 my-px-6 max-w-rem-32">
              <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-2">
                <div className="d-flex align-items-center text-nowrap fs-15">
                  <div className="d-flex align-items-center text-nowrap">
                    <ImageWithToken
                      originalImage={x.userProfilePicture}
                      hasImage={x.userProfilePicture ? true : false}
                      alt="Avatar"
                      className="rounded-circle avatar rounded-avatar-dashboard"
                      src={
                        x.userProfilePicturee === null ||
                        x.userProfilePicture === "" ||
                        x.userProfilePicture === undefined
                          ? "/images/images/img_avatar.png"
                          : x.userProfilePicture
                      }
                      textInAvatar={`${x.userFirstName} ${x.userLastName}`}
                    />
                    {x.userFirstName} {x.userLastName}
                  </div>
                </div>
                <h6 className="fs-13 color-gray-500 fw-normal">
                  {moment(x.createDate).format(MOMENT_DATE_TIME_FORMAT)}{" "}
                </h6>
              </div>
              <p className="color-gray-600 fw-medium fs-15"> {x.message}</p>
              {x.attachments?.map((attachment: any) => (
                <div
                  className="bg-white rounded-px-6 d-flex border border-gray-300"
                  style={{ marginBottom: "4px" }}
                >
                  <div className="d-flex align-items-center gap-3 p-2 justify-content-between w-100">
                    <div className="d-flex align-items-center discussion-chat-text">
                      <h6
                        className="fs-13 fw-medium color-gray-1000 mb-0 text-truncate"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.downloadFile(attachment)}
                      >
                        {attachment.inputDataType === 4
                          ? attachment.externalUrl
                          : attachment.title}
                      </h6>
                    </div>
                    <div
                      className={`btn-group ${
                        attachment.inputDataType === 4 ? "w-30" : "w-24"
                      }`}
                    >
                      <button
                        type="button"
                        className="btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0"
                        onClick={() => this.downloadFile(attachment)}
                      >
                        {attachment.inputDataType == 4 ? (
                          <>
                            <svg
                              className="me-px-6"
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                            >
                              <rect
                                width="15"
                                height="15"
                                transform="translate(0 0.500534)"
                                fill="white"
                                fill-opacity="0.01"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.50039 2.30055C4.35237 2.30055 1.80039 4.85253 1.80039 8.00055C1.80039 11.1485 4.35237 13.7005 7.50039 13.7005C10.6484 13.7005 13.2004 11.1485 13.2004 8.00055C13.2004 4.85253 10.6484 2.30055 7.50039 2.30055ZM0.900391 8.00055C0.900391 4.35547 3.85531 1.40056 7.50039 1.40056C11.1454 1.40056 14.1004 4.35547 14.1004 8.00055C14.1004 11.6456 11.1454 14.6005 7.50039 14.6005C3.85531 14.6005 0.900391 11.6456 0.900391 8.00055Z"
                                fill="#5746AF"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13.5 8.40051H1.5V7.60051H13.5V8.40051Z"
                                fill="#5746AF"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.10022 14.0005V2.00053H7.90022V14.0005H7.10022ZM10.3753 8.00051C10.3753 5.82777 9.59395 3.67831 8.06214 2.25709L8.53824 1.74394C10.2399 3.32271 11.0753 5.67326 11.0753 8.00051C11.0753 10.3278 10.2399 12.6783 8.53824 14.2571L8.06214 13.7439C9.59395 12.3227 10.3753 10.1733 10.3753 8.00051ZM4 8.00053C4 5.67664 4.80817 3.32731 6.45799 1.74772L6.94208 2.25334C5.45851 3.67376 4.7 5.82442 4.7 8.00053C4.70001 10.1766 5.45853 12.3273 6.9421 13.7477L6.458 14.2533C4.80819 12.6737 4.00001 10.3244 4 8.00053Z"
                                fill="#5746AF"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.49929 4.45854C9.66861 4.45854 11.8746 4.85968 13.3699 5.69501C13.5387 5.78928 13.5991 6.0025 13.5048 6.17126C13.4106 6.34001 13.1973 6.4004 13.0286 6.30613C11.6787 5.55208 9.60732 5.15854 7.49929 5.15854C5.39125 5.15854 3.31985 5.55208 1.96997 6.30613C1.80121 6.4004 1.58799 6.34001 1.49372 6.17126C1.39946 6.0025 1.45984 5.78928 1.6286 5.69501C3.12399 4.85968 5.32996 4.45854 7.49929 4.45854ZM7.49929 11.3505C9.66861 11.3505 11.8746 10.9493 13.3699 10.114C13.5387 10.0198 13.5991 9.80654 13.5048 9.63779C13.4106 9.46903 13.1973 9.40865 13.0286 9.50291C11.6787 10.257 9.60732 10.6505 7.49929 10.6505C5.39125 10.6505 3.31985 10.257 1.96997 9.50292C1.80121 9.40865 1.58799 9.46903 1.49372 9.63779C1.39946 9.80654 1.45984 10.0198 1.6286 10.114C3.12399 10.9493 5.32996 11.3505 7.49929 11.3505Z"
                                fill="#5746AF"
                              />
                            </svg>
                            {`Open link`}
                          </>
                        ) : (
                          <>
                            <svg
                              className="me-px-6"
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                            >
                              <rect
                                width="15"
                                height="15"
                                transform="translate(0 0.500427)"
                                fill="white"
                                fill-opacity="0.01"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.5 11.5004C4.80285 11.5004 2.52952 10.1223 1.09622 8.00044C2.52952 5.87859 4.80285 4.50043 7.5 4.50043C10.1971 4.50043 12.4705 5.87859 13.9038 8.00044C12.4705 10.1223 10.1971 11.5004 7.5 11.5004ZM7.5 3.50043C4.30786 3.50043 1.65639 5.20681 0.0760002 7.73544C-0.0253338 7.89758 -0.0253334 8.10331 0.0760014 8.26544C1.65639 10.794 4.30786 12.5004 7.5 12.5004C10.6921 12.5004 13.3436 10.794 14.924 8.26544C15.0253 8.10331 15.0253 7.89758 14.924 7.73544C13.3436 5.20681 10.6921 3.50043 7.5 3.50043ZM7.5 10.0004C8.60457 10.0004 9.5 9.105 9.5 8.00043C9.5 6.89586 8.60457 6.00043 7.5 6.00043C6.39543 6.00043 5.5 6.89586 5.5 8.00043C5.5 9.105 6.39543 10.0004 7.5 10.0004Z"
                                fill="#5746AF"
                              />
                            </svg>
                            {`Open file`}
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="mb-3">
            <div className="p-3 bg-primary-3 rounded-px-6 my-px-6 max-w-rem-32 ms-auto discussion-send-message">
              <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-2">
                <div className="d-flex align-items-center text-nowrap fs-15">
                  <div className="d-flex align-items-center text-nowrap">
                    <ImageWithToken
                      originalImage={x.userProfilePicture}
                      hasImage={x.userProfilePicture ? true : false}
                      alt="Avatar"
                      className="rounded-circle avatar rounded-avatar-dashboard"
                      src={
                        x.userProfilePicturee === null ||
                        x.userProfilePicture === "" ||
                        x.userProfilePicture === undefined
                          ? "/images/images/img_avatar.png"
                          : x.userProfilePicture
                      }
                      textInAvatar={`${x.userFirstName} ${x.userLastName}`}
                    />
                    {x.userFirstName} {x.userLastName}
                  </div>
                </div>
                <h6 className="fs-13 color-gray-500 fw-normal">
                  {moment(x.createDate).format(MOMENT_DATE_TIME_FORMAT)}{" "}
                </h6>
              </div>
              <p className="color-gray-600 fw-medium fs-15"> {x.message}</p>
              {x.attachments?.map((attachment: any) => (
                <div
                  className="bg-white rounded-px-6 d-flex border border-gray-300"
                  style={{ marginBottom: "4px" }}
                >
                  <div className="d-flex align-items-center gap-3 p-2 justify-content-between w-100">
                    <div className="d-flex align-items-center discussion-chat-text w-70">
                      <h6
                        className="fs-13 fw-medium color-gray-1000 mb-0 text-truncate"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.downloadFile(attachment)}
                      >
                        {attachment.inputDataType === 4
                          ? attachment.externalUrl
                          : attachment.title}
                      </h6>
                    </div>
                    <div className={"btn-group w-24"}>
                      <button
                        type="button"
                        className="btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0"
                        onClick={() => this.downloadFile(attachment)}
                      >
                        {attachment.inputDataType == 4 ? (
                          <>
                            <svg
                              className="me-px-6 hover-fill"
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                            >
                              <rect
                                width="15"
                                height="15"
                                transform="translate(0 0.500534)"
                                fill="white"
                                fill-opacity="0.01"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.50039 2.30055C4.35237 2.30055 1.80039 4.85253 1.80039 8.00055C1.80039 11.1485 4.35237 13.7005 7.50039 13.7005C10.6484 13.7005 13.2004 11.1485 13.2004 8.00055C13.2004 4.85253 10.6484 2.30055 7.50039 2.30055ZM0.900391 8.00055C0.900391 4.35547 3.85531 1.40056 7.50039 1.40056C11.1454 1.40056 14.1004 4.35547 14.1004 8.00055C14.1004 11.6456 11.1454 14.6005 7.50039 14.6005C3.85531 14.6005 0.900391 11.6456 0.900391 8.00055Z"
                                fill="#5746AF"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13.5 8.40051H1.5V7.60051H13.5V8.40051Z"
                                fill="#5746AF"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.10022 14.0005V2.00053H7.90022V14.0005H7.10022ZM10.3753 8.00051C10.3753 5.82777 9.59395 3.67831 8.06214 2.25709L8.53824 1.74394C10.2399 3.32271 11.0753 5.67326 11.0753 8.00051C11.0753 10.3278 10.2399 12.6783 8.53824 14.2571L8.06214 13.7439C9.59395 12.3227 10.3753 10.1733 10.3753 8.00051ZM4 8.00053C4 5.67664 4.80817 3.32731 6.45799 1.74772L6.94208 2.25334C5.45851 3.67376 4.7 5.82442 4.7 8.00053C4.70001 10.1766 5.45853 12.3273 6.9421 13.7477L6.458 14.2533C4.80819 12.6737 4.00001 10.3244 4 8.00053Z"
                                fill="#5746AF"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.49929 4.45854C9.66861 4.45854 11.8746 4.85968 13.3699 5.69501C13.5387 5.78928 13.5991 6.0025 13.5048 6.17126C13.4106 6.34001 13.1973 6.4004 13.0286 6.30613C11.6787 5.55208 9.60732 5.15854 7.49929 5.15854C5.39125 5.15854 3.31985 5.55208 1.96997 6.30613C1.80121 6.4004 1.58799 6.34001 1.49372 6.17126C1.39946 6.0025 1.45984 5.78928 1.6286 5.69501C3.12399 4.85968 5.32996 4.45854 7.49929 4.45854ZM7.49929 11.3505C9.66861 11.3505 11.8746 10.9493 13.3699 10.114C13.5387 10.0198 13.5991 9.80654 13.5048 9.63779C13.4106 9.46903 13.1973 9.40865 13.0286 9.50291C11.6787 10.257 9.60732 10.6505 7.49929 10.6505C5.39125 10.6505 3.31985 10.257 1.96997 9.50292C1.80121 9.40865 1.58799 9.46903 1.49372 9.63779C1.39946 9.80654 1.45984 10.0198 1.6286 10.114C3.12399 10.9493 5.32996 11.3505 7.49929 11.3505Z"
                                fill="#5746AF"
                              />
                            </svg>
                            {`Open link`}
                          </>
                        ) : (
                          <>
                            <svg
                              className="me-px-6"
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                            >
                              <rect
                                width="15"
                                height="15"
                                transform="translate(0 0.500427)"
                                fill="white"
                                fill-opacity="0.01"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.5 11.5004C4.80285 11.5004 2.52952 10.1223 1.09622 8.00044C2.52952 5.87859 4.80285 4.50043 7.5 4.50043C10.1971 4.50043 12.4705 5.87859 13.9038 8.00044C12.4705 10.1223 10.1971 11.5004 7.5 11.5004ZM7.5 3.50043C4.30786 3.50043 1.65639 5.20681 0.0760002 7.73544C-0.0253338 7.89758 -0.0253334 8.10331 0.0760014 8.26544C1.65639 10.794 4.30786 12.5004 7.5 12.5004C10.6921 12.5004 13.3436 10.794 14.924 8.26544C15.0253 8.10331 15.0253 7.89758 14.924 7.73544C13.3436 5.20681 10.6921 3.50043 7.5 3.50043ZM7.5 10.0004C8.60457 10.0004 9.5 9.105 9.5 8.00043C9.5 6.89586 8.60457 6.00043 7.5 6.00043C6.39543 6.00043 5.5 6.89586 5.5 8.00043C5.5 9.105 6.39543 10.0004 7.5 10.0004Z"
                                fill="#5746AF"
                              />
                            </svg>
                            {`Open file`}
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
    );

    const files = this.state.files.map((file: any) => (
      <li key={file.name}>
        <span className="text-truncate col-10 mx-2">
          {file.name} - {file.size} bytes
        </span>
        <CircleIcon
          type={ThemeCircleIcon.dark}
          width="22px"
          padding="0"
          height="22px"
          onClick={() => {
            this.handelDeleteFile(file);
          }}
        >
          <img
            src="/images/icons/garbage_can.svg"
            alt="radvix"
            width={15}
            height={15}
          />
        </CircleIcon>
      </li>
    ));
    return (
      <div className="profileCard">
        <div className="d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap position-relative">
          <div className="d-xl-flex align-items-center w-100 w-lg-50">
            <BaseButton
              className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button"
              style={{ fontWeight: "400", height: "19px" }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>

            <h2 className="fw-medium fs-15 mb-0 color-gray-1000 mt-xl-0 mt-3 mw-100 text-truncate">
              {this.state.chatLoading ? (
                <Skeleton width="6em" height="1.25em" />
              ) : (
                <span>
                  {this.state.Discusstion.subject}
                  {/* {this.state.Research.title.split(' ').slice(0, 6).join(' ')}
                {this.state.Research.title.split(' ').length > 4 ? '...' : ''} */}
                </span>
              )}
            </h2>
          </div>
        </div>
        <div className="p-3 border-bottom border-gray-400 pt-0 profileBorder">
          <div className="col-12 d-flex flex-wrap align-items-center row-gap-sm-px-0 row-gap-px-16">
            <div className="col-6 d-flex">
              <div className="ps-xl-3 pe-3 border-end-1 border-gray-400 min-h-px-25">
                <div className="d-flex align-items-center">
                  <label className="fw-normal fs-15 mb-0 color-gray-600 me-2">
                    Status:
                  </label>
                  {this.state.chatLoading ? (
                    <Skeleton width="6em" height="1.25em" />
                  ) : (
                    <span>
                      {" "}
                      {this.renderStatus(this.state.Discusstion.ticketStatus)}
                    </span>
                  )}
                </div>
              </div>
              <div className="ps-3 pe-3 min-h-px-25">
                <div className="d-flex align-items-center">
                  <label className="fw-normal fs-15 mb-0 color-gray-600 me-2">
                    Priority:
                  </label>
                  {this.state.chatLoading ? (
                    <Skeleton width="6em" height="1.25em" />
                  ) : (
                    <span>
                      {this.renderPrioirty(this.state.Discusstion.priority)}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div
              className="col-6 d-flex align-items-center justify-content-end"
              style={{ paddingRight: "15px" }}
            >
              <label className="fw-normal fs-15 mb-0 color-gray-600 me-2">
                Users:
              </label>
              <span className="px-1">
                {this.state.chatLoading == true ? (
                  <Skeleton
                    width="100%"
                    height="1.25em"
                    style={{ marginTop: "30px" }}
                  />
                ) : (
                  <GroupedAvatars
                    containerWidth={10}
                    maxAvatars={3}
                    tippyContent={
                      <div className="d-flex flex-column py-2 pb-0">
                        {this.state.Discusstion.users.map((_us: any) => {
                          return (
                            <UserWithAvatar
                              parentStyle={{
                                marginBottom: "0.25em",
                              }}
                              key={_us.id}
                              user={_us}
                            />
                          );
                        })}
                      </div>
                    }
                    users={this.state.Discusstion.users}
                  />
                )}
              </span>
            </div>
          </div>
        </div>
        <div
          className={`p-3 overflow-auto flex-grow-1 ${
            this.state.showAttachments
              ? "vh-calc-lg-415-attachment"
              : "vh-calc-lg-415"
          }`}
        >
          {/* <div className="mb-3">
                  <div className="p-3 bg-gray-200 rounded-px-6 my-px-6 max-w-rem-32">
                    <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-2">
                      <div className="d-flex align-items-center text-nowrap fs-15">
                        <div className="avatar w-px-25 h-px-25 rounded-circle bg-primary">
                          <label className="fw-medium text-white fs-10">
                            NH
                          </label>
                        </div>{" "}
                        Nima Hosseinzadeh
                      </div>
                      <h6 className="fs-13 color-gray-500 fw-normal">
                        27 May, 2024 | 08:32 AM
                      </h6>
                    </div>
                    <p className="color-gray-600 fw-medium fs-15">
                      {" "}
                      A short introductory chapter describes the progress in
                      this field in general and stresses the importance of
                      chirality in natural products chemistry{" "}
                    </p>
                    <div className="bg-white rounded-px-6 d-flex border border-gray-300">
                      <div className="d-flex align-items-center gap-3 p-2 justify-content-between w-100">
                        <div className="d-flex align-items-center">
                          <h6 className="fs-13 fw-medium color-gray-1000 mb-0 text-truncate">
                            Data_Set_234.xls
                          </h6>
                          <h6 className="fs-13 fw-medium color-gray-1000 mb-0 ms-1">
                            23MB
                          </h6>
                        </div>
                        <button className="btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0">
                          <div className="d-flex align-items-center">
                            <i className="fa fa-eye me-px-6 mt-px-1"></i> Open
                            file{" "}
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
          {selectedDiscussion}
        </div>
        <div className="py-3 px-3 border-top border-gray-400">
          <div className="flex-sm-row flex-column align-items-center gap-3">
            <div className="d-flex align-items-center gap-3 w-100">
              <button
                className="btn btn-secondary btn-sm fs-13 h-px-35 min-w-px-35 p-0 d-flex align-items-center justify-content-center"
                onClick={() => {
                  this.setState({
                    showAttachments: !this.state.showAttachments,
                  });
                }}
              >
                {!this.state.showAttachments ? (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 11 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.5 1.00006C1.22386 1.00006 1 1.22392 1 1.50006V11.5001C1 11.7762 1.22386 12.0001 1.5 12.0001H9.5C9.7761 12.0001 10
                                        11.7762 10 11.5001V3.70717L7.29289 1.00006H1.5ZM0 1.50006C0 0.671631 0.67157 6.10352e-05 1.5 6.10352e-05H7.5C7.63261
                                        6.10352e-05 7.75979 0.0527411 7.85355 0.146511L10.7803 3.07328C10.921 3.21394 11 3.4047 11 3.60361V11.5001C11 12.3285
                                        10.3284 13.0001 9.5 13.0001H1.5C0.67157 13.0001 0 12.3285 0 11.5001V1.50006ZM2.75 6.50006C2.75 6.22392 2.97386 6.00006
                                        3.25 6.00006H5V4.25006C5 3.97392 5.22386 3.75006 5.5 3.75006C5.77614 3.75006 6 3.97392 6 4.25006V6.00006H7.75C8.0261
                                        6.00006 8.25 6.22392 8.25 6.50006C8.25 6.7762 8.0261 7.00006 7.75 7.00006H6V8.75006C6 9.02616 5.77614 9.25006 5.5
                                        9.25006C5.22386 9.25006 5 9.02616 5 8.75006V7.00006H3.25C2.97386 7.00006 2.75 6.7762 2.75 6.50006Z"
                      fill="black"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                  >
                    <rect
                      width="18.2"
                      height="18.2"
                      transform="translate(0.400002 0.400024)"
                      fill="white"
                      fill-opacity="0.01"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.6951 5.29169C14.9676 5.01924 14.9676 4.5775 14.6951 4.30505C14.4227 4.03259 13.9809 4.03259 13.7085 4.30505L9.50014 8.5134L5.29181 4.30505C5.01934 4.03259 4.5776 4.03259 4.30515 4.30505C4.03269 4.5775 4.03269 5.01924 4.30515 5.29169L8.5135 9.50004L4.30515 13.7084C4.03269 13.9809 4.03269 14.4226 4.30515 14.6951C4.5776 14.9675 5.01934 14.9675 5.29181 14.6951L9.50014 10.4867L13.7085 14.6951C13.9809 14.9675 14.4227 14.9675 14.6951 14.6951C14.9676 14.4226 14.9676 13.9809 14.6951 13.7084L10.4868 9.50004L14.6951 5.29169Z"
                      fill="#60646C"
                    />
                  </svg>
                )}
              </button>
              <div className="row mt-1" style={{ width: `100%` }}>
                <div className={`col-lg-12'}`}>
                  <div
                    className="text-primary cursor-pointer mb-1"
                    title="Info"
                  >
                    <Fragment>
                      <div>
                        <TextField.Root
                          size={"3"}
                          value={this.state.massage}
                          placeholder={"Type your message here"}
                          className={`form-control h-px-35 w-100 control-border-color`}
                          //className={`${IsclassName} InputComponentStyle form-control ${IsinValid}`}
                          onKeyDown={this.handleKeyDown}
                          onChange={(e: any) => {
                            const sanitizedValue = e.target.value.replace(
                              /[<>]/g,
                              ""
                            );
                            this.handleChange("massage", sanitizedValue);
                          }}
                        ></TextField.Root>
                      </div>
                    </Fragment>
                  </div>
                </div>
              </div>
              <MainButton
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <rect
                      width="15"
                      height="15"
                      fill="white"
                      fill-opacity="0.01"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.20308 1.04312C1.00481 0.954998 0.772341 1.0048 0.627577 1.16641C0.482813 1.32802 0.458794 1.56455 0.568117 1.75196L3.92115 7.50002L0.568117 13.2481C0.458794 13.4355 0.482813 13.672 0.627577 13.8336C0.772341 13.9952 1.00481 14.045 1.20308 13.9569L14.7031 7.95693C14.8836 7.87668 15 7.69762 15 7.50002C15 7.30243 14.8836 7.12337 14.7031 7.04312L1.20308 1.04312ZM4.84553 7.10002L2.21234 2.586L13.2689 7.50002L2.21234 12.414L4.84552 7.90002H9C9.22092 7.90002 9.4 7.72094 9.4 7.50002C9.4 7.27911 9.22092 7.10002 9 7.10002H4.84553Z"
                      fill="white"
                    />
                  </svg>
                }
                children="Send"
                type={MainButtonType.dark}
                //borderRadius="24px"
                fontSize="14px"
                // className="my-2 mx-2"
                minHeight="2em"
                className="btn btn-primary fs-15 py-px-7"
                minWidth="100px"
                onClick={() => {
                  this.SendMassage();
                }}
              ></MainButton>
            </div>
            {this.state.showAttachments === true && (
              <div className="col-lg-12 mb-3">
                <div className="d-flex align-items-center mb-1">
                  <label className="color-gray-600 fs-15 me-2">
                    Attach files or links
                  </label>
                  <img
                    className="text-primary cursor-pointer"
                    src="/Images/icons/info-circled.svg"
                    alt="info"
                    title="Research attachments are generic documents that you would like anyone who has access to this research project read and have access to them. You can either upload them or Insert the link."
                  />
                </div>
                <div className="p-px-12 bg-gray-200 border-gray-400 border rounded-px-4 gap-2 attachment-paddingleft">
                  <div className="justify-content-sm-between justify-content-center align-items-center flex-wrap row-gap-px-8">
                    <Dropzone onDrop={this.onDrop}>
                      {({ getRootProps, getInputProps }) => (
                        // <section
                        //   className="container fileUploadBox"
                        //   style={{ marginTop: "0.3em" }}
                        // >
                        <div {...getRootProps({ className: "" })}>
                          <input {...getInputProps()} />

                          <div className="d-flex justify-content-between align-items-center flex-wrap row-gap-px-8 responsive-justify-content">
                            <button className="btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0">
                              <div className="d-flex align-items-center">
                                <div className="me-1 mb-1">
                                  <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="15"
                                      height="15"
                                      fill="white"
                                      fill-opacity="0.01"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.81825 1.18197C7.64251 1.00624 7.35759 1.00624 7.18185 1.18197L4.18185 4.18197C4.00611 4.35771 4.00611 4.64263
              4.18185 4.81837C4.35759 4.9941 4.64251 4.9941 4.81825 4.81837L7.05005 2.58657V9.50005C7.05005 9.74858 7.25152 9.95005
              7.50005 9.95005C7.74858 9.95005 7.95005 9.74858 7.95005 9.50005V2.58657L10.1819 4.81837C10.3576 4.9941 10.6425 4.9941
              10.8182 4.81837C10.994 4.64263 10.994 4.35771 10.8182 4.18197L7.81825 1.18197ZM2.5 10.0001C2.77614 10.0001 3 10.2239 3
              10.5001V12.0001C3 12.5539 3.44565 13.0001 3.99635 13.0001H11.0012C11.5529 13.0001 12 12.5529 12 12.0001V10.5001C12
              10.2239 12.2239 10.0001 12.5 10.0001C12.7761 10.0001 13 10.2239 13 10.5001V12.0001C13 13.1041 12.1062 14.0001 11.0012
              14.0001H3.99635C2.89019 14.0001 2 13.1031 2 12.0001V10.5001C2 10.2239 2.22386 10.0001 2.5 10.0001Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </div>
                                Browse local file
                              </div>
                            </button>
                            <h3 className="fw-normal color-gray-700 fs-13 mb-0 flex-grow-1 text-center">
                              {" "}
                              Or drag and drop files here - Max 128MB{" "}
                            </h3>
                          </div>
                          {files.length > 0 && (
                            <div style={{ paddingTop: "10px" }}>
                              <ul className="list-unstyled fw-normal">
                                {files}
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>

                <ul
                  className="file-list mt-1"
                  style={{
                    marginBottom:
                      this.state.ExternalUrl.length > 0 ? "0px" : "0px",
                  }}
                >
                  {this.state.ExternalUrl.map((item) => (
                    <li className="d-flex align-items-center w-100">
                      <InputComponent
                        type={InputType.text}
                        value={item}
                        className="mx-2"
                        counter={false}
                        disabled={true}
                        readOnly={true}
                        rowWidth="98%"
                        isExternalURL={true}
                      ></InputComponent>
                      <div
                        style={{
                          paddingBottom: "0px",
                          paddingRight: "0px",
                          paddingTop: "0px",
                          paddingLeft: "5px",
                        }}
                      >
                        <button
                          className="btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0"
                          style={{ background: "#F3AEAF" }}
                          onClick={() => {
                            this.handelDeleteExternalLink(item);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <rect
                              width="15"
                              height="15"
                              fill="white"
                              fill-opacity="0.01"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.5 1.00009C5.22386 1.00009 5 1.22395 5 1.50009C5 1.77623 5.22386 2.00009 5.5 2.00009H9.5C9.77614 2.00009 10 1.77623 10 1.50009C10 1.22395 9.77614 1.00009 9.5 1.00009H5.5ZM3 3.50009C3 3.22395 3.22386 3.00009 3.5 3.00009H5H10H11.5C11.7761 3.00009 12 3.22395 12 3.50009C12 3.77623 11.7761 4.00009 11.5 4.00009H11V12.0001C11 12.5524 10.5523 13.0001 10 13.0001H5C4.44772 13.0001 4 12.5524 4 12.0001V4.00009H3.5C3.22386 4.00009 3 3.77623 3 3.50009ZM5 4.00009H10V12.0001H5V4.00009Z"
                              fill="#D93D42"
                            />
                          </svg>
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="d-flex">
                  <InputComponent
                    type={InputType.text}
                    placeholder="https://"
                    className="control-border-color"
                    value={this.state.External}
                    counter={false}
                    onChange={(e) => {
                      this.handleChange("External", e.target.value);
                    }}
                    rowWidth="98%"
                    onEnter={(e) => {
                      this.addExternalUrl();
                    }}
                  ></InputComponent>
                  <div style={{ padding: "5px", paddingRight: "0px" }}>
                    <button
                      className="btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0"
                      onClick={() => {
                        this.addExternalUrl();
                      }}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
                <p className="text-error mb-0">
                  {this.validator.message("input", this.state.External, "url")}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      // <div className="research new-research">
      //   {/* <div className="row"></div> */}
      //   <div className="col-12 box-content p-3 discusstion">
      //     <div className="d-flex align-items-center justify-content-between gap-2">
      //       <h5 className="b-title d-flex align-items-center">
      //         <span
      //           onClick={() => {
      //             window.history.back();
      //           }}
      //           className="backPage"
      //         ></span>{" "}
      //         {/* {
      //           this.state.Discusstion.ticketStatus === null ? : (

      //           )
      //         } */}
      //         {this.state.Discusstion.ticketStatus ? (
      //           <>
      //             Ticket
      //             <MainButton
      //               children={this.state.Discusstion.priority.isPriority()}
      //               type={MainButtonType.dark}
      //               borderRadius="24px"
      //               fontSize="12px"
      //               className="px-3 py-1 me-2 ms-3"
      //               color={
      //                 this.state.Discusstion.priority === 0 ? "#000" : "#fff"
      //               }
      //               backgroundColor={
      //                 this.state.Discusstion.priority === 0
      //                   ? "#9CFBFF"
      //                   : this.state.Discusstion.priority === 1
      //                   ? "#40c3f4"
      //                   : "#096bff"
      //               }
      //             ></MainButton>
      //             <MainButton
      //               children={
      //                 // @ts-ignore
      //                 TICKET_STATUS_LABELS[this.state.Discusstion.ticketStatus]
      //               }
      //               type={MainButtonType.dark}
      //               borderRadius="24px"
      //               fontSize="12px"
      //               className="px-3 py-1"
      //               // className="mx-2"
      //               color={"#000"}
      //               backgroundColor={
      //                 // @ts-ignore
      //                 TICKET_STATUS_COLORS[this.state.Discusstion.ticketStatus]
      //               }
      //             ></MainButton>
      //           </>
      //         ) : (
      //           <>Broadcast</>
      //         )}
      //       </h5>

      //       <CircleIcon
      //         onClick={() => {
      //           this.getDiscustionPanel();
      //         }}
      //         width="2em"
      //         padding="0"
      //         height="2em"
      //         type={ThemeCircleIcon.dark}
      //         backgroundColor="#9D9D9D"
      //         fontSize="14px"
      //         color="#ffffff"
      //         className="p-0 iconBtn"
      //         disabled={this.state.loading || this.state.chatLoading}
      //         title="Update Chats"
      //         loading={this.state.loading || this.state.chatLoading}
      //       >
      //         <MdRefresh size="1.3em" />
      //       </CircleIcon>
      //       {/* <span
      //         onClick={() => {
      //           this.getDiscustionPanel();
      //         }}
      //         title="Update Chats"
      //         className="d-flex align-items-center justify-content-center darkenBg p-1 pointer"
      //         style={{
      //           borderRadius: "50%",
      //           backgroundImage: "none",
      //           height: "1.75em",
      //           width: "1.75em",
      //         }}
      //       >
      //       </span> */}
      //     </div>

      //     <div className="d-flex align-items-center justify-content-between title-discusstion">
      //       <div style={{flexGrow: 1}} className="d-flex">
      //         This
      //         {this.state.Discusstion.ticketStatus === null
      //           ? " " + "broadcast"
      //           : " " + "ticket"}
      //           {" "}
      //         {/* ticket */}(
      //         <span style={{ fontSize: 16 }}>#{this.state.Discusstion.ticketStatus === null
      //         // @ts-ignore
      //                   ? `B${String(this.state?.Discusstion?.broadcastCounter / 10000).slice(2)}`
      //                   // @ts-ignore
      //                   : `T${String(this.state?.Discusstion?.ticketCounter / 10000).slice(2)}`}</span>
      //         ) is related to
      //         {/* {this.state.Discusstion.topic.isTopic()} */}
      //         <span style={{ fontSize: 16 }} className="ms-1">
      //           {this.state.Discusstion.subject}
      //         </span>
      //       </div>
      //       {typeof this.state.Discusstion.broadcastTypes === "object" &&
      //       this.state.Discusstion.broadcastTypes.length > 0 ? (
      //         <div className="d-flex align-items-center">
      //           {this.state.Discusstion.broadcastTypes.map((item: any) => {
      //             return (
      //               <div className="chipDark mx-1">
      //                 {BROADCAST_TYPE_LABELS[item]}
      //               </div>
      //             );
      //           })}
      //         </div>
      //       ) : null}
      //     </div>

      //     <div className="row mt-3 justify-content-around">
      //       <div
      //         className={
      //           this.state.Discusstion.ticketStatus === null
      //             ? "col-md-12"
      //             : `col-md-8`
      //         }
      //       >
      //         {/* {this.state.Discusstion.histories.map((item) => (
      //           <div
      //             className={
      //               item.userId === this.state.myUserId ? "submit" : "Received"
      //             }
      //             key={item.id}
      //           >
      //             <IconTextRow
      //               theme={Theme.dark}
      //               text={`${item.userFirstName} (${moment(
      //                 item.createDate
      //               ).format("YYYY/MM/DD")})`}
      //               fontSize="14px"
      //               className="fw-lighter"
      //               children={
      //                 <img
      //                   src={
      //                     item.userProfilePicture
      //                       ? AppConstants.base_url_image +
      //                         item.userProfilePicture
      //                       : "/images/images/img_avatar.png"
      //                   }
      //                   alt="Avatar"
      //                   className="rounded-circle avatar mx-2"
      //                   width="30px"
      //                   height="30px"
      //                 />
      //               }
      //             ></IconTextRow>
      //             <div
      //               className={
      //                 item.userId === this.state.myUserId
      //                   ? "submit-msg w-75"
      //                   : "Received-msg w-75"
      //               }
      //             >
      //               {item.message}
      //               <ul className="file-list">
      //                 {item.attachments
      //                   .filter((item) => item.externalUrl === null)
      //                   .map((item) => (
      //                     <li key={item.id}>
      //                       <img
      //                         src={`/images/icons/${item.inputDataType.isMedia()}`}
      //                         alt=""
      //                         width={15}
      //                         height={15}
      //                       />
      //                       {item.title}
      //                     </li>
      //                   ))}
      //               </ul>
      //             </div>
      //           </div>
      //         ))} */}
      //         <ChatArea
      //           loading={this.state.chatLoading}
      //           chats={this.state.Discusstion.histories}
      //         />
      //         {this.state.Discusstion.ticketStatus === null ? null : (
      //           <div className="item">
      //             <InputComponent
      //             disabled={this.state.chatLoading}
      //               type={InputType.textarea}
      //               label="Message:"
      //               className="mt-3"
      //               rows={7}
      //               onChange={(e) => {
      //                 this.handleChange("massage", e.target.value);
      //               }}
      //               inValid={this.validator.message(
      //                 "Message",
      //                 this.state.massage,
      //                 "required"
      //               )}
      //               value={this.state.massage}
      //               fontSize="14px"
      //             ></InputComponent>
      //           </div>
      //         )}
      //       </div>
      //       {this.state.Discusstion.ticketStatus === null ? null : (
      //         <div className="col-md-4">
      //           <div className="teams teams-light p-1 chat-teams-light mb-1 discussContactsWrapper d-flex flex-column">
      //             <div className="d-flex justify-content-between align-items-center px-2">
      //               <IconTextRow
      //                 theme={Theme.dark}
      //                 text="Users in this ticket"
      //                 children={
      //                   <img
      //                     src="/images/icons/team_menu.svg"
      //                     className="me-2"
      //                     alt=""
      //                     width={25}
      //                     height={25}
      //                   />
      //                 }
      //                 fontSize="14px"
      //               ></IconTextRow>
      //               {/* <CircleIcon
      //               width="2em"
      //               height="2em"
      //               type={ThemeCircleIcon.dark}
      //               backgroundColor="#9D9D9D"
      //               fontSize="14px"
      //               color="#ffffff"
      //               className="ms-2 p-0 iconBtn"
      //             >
      //               <MdAdd size="1.4em" />
      //             </CircleIcon> */}
      //             </div>
      //             <BoxListScroll
      //               default_photo="/Images/images/img_avatar.png"
      //               items={this.state.Discusstion.users}
      //               TextItem="title"
      //               ValueItem="id"
      //               ImageItem="image"
      //               Deletabel
      //               // className="mt-3"
      //               emptyText="No users has been added yet."
      //             ></BoxListScroll>
      //           </div>

      //           <div className="item">
      //             <span
      //               className="label d-flex align-items-center"
      //               style={{ fontSize: "14px" }}
      //             >
      //               Attachment:
      //               <MainButton
      //                 type={MainButtonType.light}
      //                 children={"optional"}
      //                 borderRadius="50px"
      //                 disabled={this.state.chatLoading}
      //                 fontSize="11px"
      //                 className="mx-2"
      //               ></MainButton>
      //               <CircleIcon
      //                 width="20px"
      //                 height="20px"
      //                 padding="0"
      //                 type={ThemeCircleIcon.dark}
      //                 disabled={this.state.chatLoading}
      //                 backgroundColor="transparent"
      //                 border="1px solid #D5D5D5"
      //                 fontSize="10px"
      //                 color="#D5D5D5"
      //               >
      //                 <i className="fas fa-question"></i>
      //               </CircleIcon>
      //             </span>
      //             <Dropzone onDrop={this.onDrop}>
      //               {({ getRootProps, getInputProps }) => (
      //                 <section className="container fileUploadBox">
      //                   <div {...getRootProps({ className: "dropzone" })}>
      //                     <input {...getInputProps()} />
      //                     <MainButton
      //                       type={MainButtonType.light}
      //                       minHeight="30px"
      //                       minWidth="179px"
      //                       disabled={this.state.chatLoading}
      //                       fontSize="14px"
      //                       borderRadius="50px"
      //                       backgroundColor="#fff"
      //                       border="1px solid #707070"
      //                       color="#707070"
      //                       className="mt-4"
      //                       children={
      //                         <div className="d-flex justify-content-between align-items-center">
      //                           <img
      //                             src="/Images/icons/cloud_computing.svg"
      //                             alt="sssss"
      //                             height="20"
      //                           />{" "}
      //                           <span className="flex-fill">
      //                             Browse Local Files
      //                           </span>
      //                         </div>
      //                       }
      //                     ></MainButton>
      //                     <p>Or drag and drop files here</p>
      //                   </div>
      //                   <aside>
      //                     <ul>{files}</ul>
      //                   </aside>
      //                 </section>
      //               )}
      //             </Dropzone>
      //           </div>
      //           {/* <div className="item d-flex justify-content-between align-items-center">
      //           <InputComponent
      //             type={InputType.text}
      //             placeholder="https://"
      //             className="mx-2"
      //             onChange={(e) => {
      //               this.handleChange("External", e.target.value);
      //             }}
      //             onEnter={(e) => {
      //               this.addExternalUrl();
      //             }}
      //             value={this.state.External}
      //             height="35px"
      //           ></InputComponent>
      //           <CircleIcon
      //             width="35px"
      //             height="35px"
      //             type={ThemeCircleIcon.dark}
      //             backgroundColor="#9D9D9D"
      //             fontSize="16px"
      //             color="#ffffff"
      //             className="ms-2"
      //             onClick={() => {
      //               this.addExternalUrl();
      //             }}
      //           >
      //             <i className="fas fa-plus"></i>
      //           </CircleIcon>
      //         </div>
      //         <p className="text-error">
      //           {this.validator.message("input", this.state.External, "url")}
      //         </p>
      //         <ul className="file-list mt-3">
      //           {this.state.ExternalUrl.map((item, index) => (
      //             <li
      //               className="my-2 d-flex flex-column flex-md-row"
      //               key={index}
      //             >
      //               <a href={item} target="_blank" rel="noopener noreferrer">
      //                 <MainButton
      //                   children={$truncate(item, 50)}
      //                   type={MainButtonType.dark}
      //                   borderRadius="24px"
      //                   fontSize="14px"
      //                   backgroundColor="#F5F5F5"
      //                   color="#096BFF"
      //                 ></MainButton>
      //               </a>
      //               <CircleIcon
      //                 type={ThemeCircleIcon.dark}
      //                 width="22px"
      //                 height="22px"
      //                 className="mx-3 pointer"
      //                 onClick={() => this.handelDeleteExternalLink(item)}
      //               >
      //                 <img
      //                   src="/images/icons/garbage_can.svg"
      //                   alt="radvix"
      //                   width={15}
      //                   height={15}
      //                 />
      //               </CircleIcon>
      //             </li>
      //           ))}
      //         </ul> */}
      //           <div className="d-flex flex-column align-items-center">
      //             <MainButton
      //               type={MainButtonType.dark}
      //               children={"Send"}
      //               borderRadius="50px"
      //               fontSize="16px"
      //               className="mt-3 btn-block"
      //               minHeight="37px"
      //               minWidth="100%"
      //               onClick={() => {
      //                 this.SendMassage();
      //               }}
      //               loading={this.state.loading}
      //             ></MainButton>
      //             <span className="mt-2 textSecondary">
      //               {this.state.loadingMode}
      //             </span>
      //           </div>
      //         </div>
      //       )}

      //       {/* <div className="col-md-7">

      //       </div> */}
      //       {/* <div className="col-md-4"></div> */}
      //     </div>
      //   </div>
      // </div>
    );
  }
}
export default withRouter(Ticket);
