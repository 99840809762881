import { HTTP, handleUnauthorizedRequest } from "../../../core/http_common";
import { ResearchReq } from "../../models/requests/research/research_req";
import { UpdateResearchReq } from "../../models/requests/research/update_research_req";
import { ResearchesRes } from "../../models/responses/research/researches_res";
import { GetResearchByidRes } from "../../models/responses/research/research_by_id_res";
import { ResearchRes } from "../../models/responses/research/research_res";
import {
  ResearchSearchRes,
  WorkspaceTeamsRes,
} from "../../models/responses/research/research_search_res";
import { TimelineRes } from "../../models/responses/research/timeline_res";
import { UpdateResearchRes } from "../../models/responses/research/update_research_req";
import { SortType } from "../../../core/number_extentions";
import { IFilterOptions } from "../../../views/components/FilterSection/IFilterOptions";

export class RemoteGroup {
  createGroup(
    body: any,
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    ;
    return HTTP.post("/Group", body)
      .then((res) => action(res))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  
        groupSearch(
          action: (res: any) => any,
          error: (err: any) => any
        ) {
          return HTTP.get("/Group/Search")
            .then((res) => action(res.data))
            .catch((err) => {
              if (err.response && err.response.status === 401) {
                // Retry the request with refreshed token
                return handleUnauthorizedRequest(err.config)
                  .then((_res) => {
                    action(_res?.data);
                  })
                  .catch((e) => error(e));
              } else {
                error(err);
              }
            });
        }
           
  updateGroup(
    body: UpdateResearchReq,
    action: (res: UpdateResearchRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.put("/Group", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  getGroupById(
    body: { id: number },
    action: (res: any) => any,
    error: (res: any) => any
  ) {;
    return HTTP.get(`/Group/${body.id}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  deleteGroup(
    body: { groupId: number },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.delete(`/Group?groupId=${body.groupId}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
}

