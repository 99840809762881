import React from "react";
import { store } from "../../../data/storeMain";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import { InputComponent, InputType } from "../../components/inputs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MainButton, MainButtonType } from "../../components/button";
import { SelectComponent } from "../../components/select_input";
import Dropzone from "react-dropzone";
import { RouteComponentProps, withRouter } from "react-router";
import { AppRoutes } from "../../../core/constants";
import SimpleReactValidator from "simple-react-validator";
import { expenseController } from "../../../controllers/expense/expense_controller";
import { UploadController } from "../../../controllers/upload_media/upload_media";
import { Media } from "../../../data/models/responses/research/researches_res";
import {
  $ReplaceRoute,
  // $truncate,
  isTokenInHttpAuth,
  uploadAllFiles,
  uploadAllLinks,
} from "../../../core/utils";
import { HTTP } from "../../../core/http_common";
import { CategoryController } from "../../../controllers/category/category_controller";
import { CreateNewCategory, CreateNewCategoryResult } from "../../../data/models/responses/category/create_new_category";
import CategoryManagementModal from "../laboratory/component/category_management_modal";
import { Category, SearchExpenseResResult } from "../../../data/models/responses/expense/search_expense_res";
import File from "../../components/File";
import { IoIosArrowDropleftCircle } from "react-icons/io";
interface RouteParams {
  id: string;
}
type StateType = {
  medias: Array<Media>;
  categoryId: number;
  title: string;
  appTaskId: number;
  description: string;
  amount: number;
  date: Date;
  loading: boolean;
  appTasksList: Array<string>;
  categories: Array<string>;
  External: string;
  ExternalUrl: Array<string>;
  removedMediasId: number[];
  files: Array<File>;
  modalCategories: Array<Category>;
  selectedCategory: { label: string; value: number };
  selectedAppTask: { label: string; value: number };
  listCategory: Array<{ label: string; value: number } | {}>;
};
class ExpensePageEdit extends React.Component<
  RouteComponentProps<RouteParams>
> {
  controller = new expenseController();
  UploadController = new UploadController();
  categoryController = new CategoryController();
  RoleUser = store.getState().userRole;
  date = new Date();
  handelChangeDate(target: string, params: any): void {
    this.setState({
      [target]: params,
    });
  }
  state: StateType = {
    loading: false,
    medias: [],
    files: [],
    categoryId: 0,
    title: "",
    appTaskId: 0,
    description: "",
    amount: 0,
    date: new Date(),
    appTasksList: [],
    categories: [],
    ExternalUrl: [],
    External: "",
    removedMediasId: [],
    modalCategories: [],
    selectedCategory: { label: "", value: -1 },
    selectedAppTask: { label: "", value: -1 },
    listCategory: [],
  };
  onDrop = (fileList: any) => {
    fileList.map((item: any, index: number) => {
      const found = this.state.files.find(
        (element: any) => element.name === item.name
      );
      if (found === undefined) {
        this.setState({ files: [fileList[index], ...this.state.files] });
      }
    });
  };
  handelDeleteFile(arg: File) {
    this.setState({
      files: this.state.files.filter((file) => file.name !== arg.name),
    });
  }
  async handelUpload(id: number) {
    try {
      await uploadAllLinks(id, this.state.ExternalUrl, 4);
      await uploadAllFiles(id, this.state.files, 4);
      this.setState({
        loading: false,
      });
      this.props.history.push(
        $ReplaceRoute(
          `${AppRoutes.expense_profile.replace(
            ":id",
            this.props.match.params.id.toString() ?? ""
          )}`
        )
      );
    } catch (e) {
      this.setState({
        loading: false,
      });
    }

    // const formData = new FormData();
    // for (let i = 0; i < this.state.files.length; i++) {
    //   const file = this.state.files[i];
    //   formData.append("Files", file);
    // }
    // for (let i = 0; i < this.state.ExternalUrl.length; i++) {
    //   const file = this.state.ExternalUrl[i];
    //   formData.append("ExternalUrls", file);
    // }
    // formData.append("UseCase", "4");
    // formData.append("SectionId", id.toString());

    // await this.UploadController.UloadMedia(
    //   formData,
    //   (res) => {
    //     this.setState({
    //       loading: false,
    //     });
    //     this.props.history.push(
    //       $ReplaceRoute(
    //         `${AppRoutes.expense_profile.replace(
    //           ":id",
    //           this.props.match.params.id.toString() ?? ""
    //         )}`
    //       )
    //     );
    //   },
    //   () => {
    //     this.setState({
    //       loading: false,
    //     });
    //   }
    // );
  }
  handleChange(target: string, val: any) {
    this.setState({
      [target]: val,
    });
  }
  addExternalUrl() {
    let Url = [...this.state.ExternalUrl];
    if (this.state.External.length > 2) {
      Url.push(this.state.External);
    }
    this.setState({
      ExternalUrl: Url,
      External: "",
    });
  }
  validator = new SimpleReactValidator({
    className: "text-danger",
  });
  handelEditExpense() {
    const body = {
      id: parseInt(this.props.match.params.id),
      title: this.state.title,
      appTaskId: this.state.selectedAppTask.value,
      description: this.state.description,
      amount: this.state.amount,
      date: this.state.date,
      categoryId: this.state.selectedCategory.value,
      removedMediaId: this.state.removedMediasId,
    };
    this.setState({
      loading: true,
    });
    this.controller.updateExpense(
      body,
      async (res) => {
        if (this.state.files.length || this.state.ExternalUrl.length) {
          await this.handelUpload(parseInt(this.props.match.params.id));
        } else {
          this.setState({
            loading: false,
          });
          this.props.history.push(
            $ReplaceRoute(
              `${AppRoutes.expense_profile.replace(
                ":id",
                this.props.match.params.id.toString() ?? ""
              )}`
            )
          );
        }
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
    if (this.validator.allValid()) {
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  componentDidMount() {
    if (!isTokenInHttpAuth(HTTP)) return;


    this.GetSearchExpense().then((_res) => {
      this.controller.getExpenseById(
        { id: parseInt(this.props.match.params.id) },
        (res) => {
          document.title = `Edit Expense | ${res.title} | Radvix`
          const _sc = _res.category?.filter(
            (_cat) => _cat.id === res.categoryId
          )[0];
          const _sa = _res.appTasks?.filter(
            (_app) => _app.id === res.appTaskId
          )[0];

          const _selectedCategory = {
            label: _sc?.title || "",
            value: _sc?.id || -1,
          };
          const _selectedAppTask = {
            label: _sa?.title || "",
            value: _sa?.id || -1,
          };


          this.setState({
            categoryId: res.categoryId,
            // @ts-ignore
            date: new Date(res.date),
            title: res.title,
            description: res.description,
            appTaskTitle: res.appTaskTitle,
            amount: res.amount,
            medias: res.medias,
            selectedCategory: _selectedCategory,
            selectedAppTask: _selectedAppTask,
          });
        },
        (err) => { }
      );
    }).catch(_err => {

    })
  }
  GetSearchExpense(): Promise<SearchExpenseResResult> {
    return new Promise((resolve, reject) => {
      this.controller.SearchExpense(
        (res) => {
          
          if (!res) {
            resolve({
              appTasks: [],
              category: []
            })
            return
          }
          
          this.setState({
            appTasksList: res.appTasks?.map((item) => {
              return { label: item.title, value: item.id };
            }),
            // categories: res.category?.map((item) => {
            //   return { label: item.title, value: item.id };
            // }),
            listCategory: res.category?.map((item) => {
              return { label: item.title, value: item.id };
            }),
            modalCategories: res.category,
          });

          resolve(res)
        },
        (err) => {
          reject(err)
        }
      );
    })

  }
  handelChangeSelect(target: string, e: { label: string; value: number }) {
    this.setState({ [target]: e.value });
  }
  handelDeleteExternalLink(link: string) {
    this.setState({
      ExternalUrl: this.state.ExternalUrl.filter((item) => item !== link),
    });
  }
  handelRemoveMedia(id: number) {
    this.setState({
      removedMediasId: [...this.state.removedMediasId, id],
      medias: this.state.medias.filter((item) => item.id !== id),
    });
  }

  handleCreateNewCategory(_newCategoryName: string) {
    return new Promise((resolve, reject) => {
      this.categoryController.createNewCategory(
        {
          title: _newCategoryName,
          type: 3,
        },
        (res) => {
          // this.setState({
          //   listCategory: [
          //     ...this.state.listCategory,
          //     { label: res.title, value: res.id },
          //   ],
          // });
          resolve(res as CreateNewCategoryResult);
        },
        (err) => {
          // 
          reject(err);
        }
      );
    });
  }
  handleEditCategory(_newCategoryName: string, _id: number) {
    return new Promise((resolve, reject) => {
      this.categoryController.editCategory(
        {
          title: _newCategoryName,
          type: 3,
          id: _id,
        },
        (res) => {
          if (this.state.selectedCategory.value === _id) {
            this.setState({
              selectedCategory: { label: _newCategoryName, value: _id },
            });
          }
          resolve(res as CreateNewCategoryResult);
        },
        (err) => {
          // 
          reject(err);
        }
      );
    });
  }

  handleDeleteCategory(_id: number) {
    return new Promise((resolve, reject) => {
      this.categoryController.deleteCategory(
        {
          id: _id,
        },
        (res) => {
          // console.log(this.state.selectedCategory.value, _id);
          if (this.state.selectedCategory.value === _id) {
            this.setState({
              selectedCategory: {
                label: "",
                value: -1,
              },
            });
          }
          resolve(res as CreateNewCategory);
        },
        (err) => {
          // 
          reject(err);
        }
      );
    });
  }
  render() {
    const files = this.state.files.map((file: any, index) => (
      <li key={index}>
        <span className="text-truncate col-10 mx-2">
          {file.name} - {file.size} bytes
        </span>
        <CircleIcon
          type={ThemeCircleIcon.dark}
          width="22px"
          height="22px"
          padding="0"
          onClick={() => {
            this.handelDeleteFile(file);
          }}
        >
          <img
            src="/images/icons/garbage_can.svg"
            alt="radvix"
            width={15}
            height={15}
          />
        </CircleIcon>
      </li>
    ));
    return (
      <div className="container-fluid research new-research">
        <div className="row"></div>
        <div className="col-12 box-content p-3">
          <h5 className="b-title d-flex align-items-center">
            <IoIosArrowDropleftCircle
              className="me-3 textSecondary2 cursorPointer"
              size="1.5em"
              onClick={() => {
                window.history.back();
              }}
            />{" "}
            Edit Expense
          </h5>
          <div className="form row">
            <div className="col-md-6 left beforeNoneMd">
              <div className="item">
                <InputComponent
                  type={InputType.text}
                  label="Expense Name:"
                  popQuestion="Expense Name:"
                  onChange={(e) => {
                    this.handleChange("title", e.target.value);
                  }}
                  value={this.state.title}
                  inValid={this.validator.message(
                    "Name",
                    this.state.title,
                    "required"
                  )}
                ></InputComponent>
              </div>
              <div className="item">
                <span className="label d-flex align-items-center">
                  Associated Task:
                </span>
                <SelectComponent
                  items={this.state.appTasksList}
                  TextItem="name"
                  ValueItem="id"
                  className="mt-1"
                  placeholder="Click to see the list…"
                  isMulti={false}
                  onChange={(e) => {
                    // this.handelChangeSelect("appTaskId", e);
                    this.setState({
                      selectedAppTask: e,
                    })
                  }}
                  inValid={this.validator.message(
                    "",
                    this.state.selectedAppTask.value,
                    "required"
                  )}
                  defaultValue={this.state.selectedAppTask}
                ></SelectComponent>
              </div>
              <div className="item">
                <InputComponent
                  type={InputType.textarea}
                  label="Description:"
                  optional="optional"
                  onChange={(e) => {
                    this.handleChange("description", e.target.value);
                  }}
                  value={this.state.description}
                ></InputComponent>
              </div>
            </div>
            <div className="col-md-6 right">
              <div className="row d-flex justify-content-between align-items-center">
                <div className="col-md-6">
                  <div className="item">
                    <InputComponent
                      type={InputType.text}
                      label="Amount:"
                      popQuestion="Amount:"
                      placeholder="$0.00"
                      onChange={(e) => {
                        this.handleChange("amount", e.target.value);
                      }}
                      value={String(this.state.amount)}
                    // inValid={this.validator.message(
                    //   "amount",
                    //   this.state.description,
                    //   "required"
                    // )}
                    ></InputComponent>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item">
                    <span className="label d-flex align-items-center">
                      Scheduled Timeline:{" "}
                      <CircleIcon
                        width="20px"
                        height="20px"
                        padding="0"
                        type={ThemeCircleIcon.dark}
                        backgroundColor="transparent"
                        border="1px solid #D5D5D5"
                        fontSize="10px"
                        color="#D5D5D5"
                      >
                        <i className="fas fa-question"></i>
                      </CircleIcon>
                    </span>
                    <DatePicker
                      selected={this.state.date}
                      onChange={(e) => {
                        this.handelChangeDate("date", e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="item">
                <span className="label d-flex align-items-center">
                  Protocols and Documents:
                  <div
                    // type={MainButtonType.light}
                    // children={optional}
                    // borderRadius="50px"
                    // fontSize="15px"
                    className="s-chip filled mx-2"
                  >
                    optional
                  </div>
                  <CircleIcon
                    width="20px"
                    height="20px"
                    padding="0"
                    type={ThemeCircleIcon.dark}
                    backgroundColor="transparent"
                    border="1px solid #D5D5D5"
                    fontSize="10px"
                    color="#D5D5D5"
                  >
                    <i className="fas fa-question"></i>
                  </CircleIcon>
                </span>
                <Dropzone onDrop={this.onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="container fileUploadBox" style={{ marginTop: "0.3em" }}>
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <MainButton
                          type={MainButtonType.light}
                          minHeight="30px"
                          minWidth="179px"
                          fontSize="14px"
                          borderRadius="50px"
                          backgroundColor="#fff"
                          border="1px solid #707070"
                          color="#707070"
                          className="mt-4"
                          children={
                            <div className="d-flex justify-content-between align-items-center">
                              <img
                                src="/Images/icons/cloud_computing.svg"
                                alt="sssss"
                                height="20"
                              />{" "}
                              <span className="flex-fill">
                                Browse Local Files
                              </span>
                            </div>
                          }
                        ></MainButton>
                        <p>Or drag and drop files here</p>
                      </div>
                      <aside>
                        <ul>{files}</ul>
                      </aside>
                    </section>
                  )}
                </Dropzone>
                <ul className="file-list d-flex flex-wrap mt-1">
                  {this.state.medias
                    .filter((item) => item.externalUrl === null)
                    .map((item, index) => (
                      <li
                        key={index}
                        className="d-flex align-items-center me-3 max1LineText"
                      >
                        <File file={item} />
                        {/* <img
                          src={`/images/icons/${item.inputDataType?.isMedia()}`}
                          alt=""
                          width={20}
                          height={20}
                        />
                        {$truncate(item.title ?? "", 20)} */}
                        <CircleIcon
                          type={ThemeCircleIcon.dark}
                          width="22px"
                          height="22px"
                          padding="0"
                          className="ms-1 pointer"
                          onClick={() => {
                            this.handelRemoveMedia(item.id!);
                          }}
                        >
                          <img
                            src="/images/icons/garbage_can.svg"
                            alt="radvix"
                            width={15}
                            height={15}
                          />
                        </CircleIcon>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="item mt-0 d-flex justify-content-between align-items-center">
                <InputComponent
                  type={InputType.text}
                  placeholder="https://"
                  className="mx-2"
                  value={this.state.External}
                  onChange={(e) => {
                    this.handleChange("External", e.target.value);
                  }}
                  onEnter={(e) => {
                    this.addExternalUrl();
                  }}
                // inValid={this.validator.message(
                //   "External",
                //   this.state.description,
                //   "required"
                // )}
                ></InputComponent>
                <CircleIcon
                  width="36px"
                  height="36px"
                  padding="0"
                  type={ThemeCircleIcon.dark}
                  backgroundColor="#9D9D9D"
                  fontSize="18px"
                  color="#ffffff"
                  className="px-3"
                  onClick={() => {
                    this.addExternalUrl();
                  }}
                >
                  <i className="fas fa-plus"></i>
                </CircleIcon>
              </div>
              <p className="text-error mb-0">
                {this.validator.message("input", this.state.External, "url")}
              </p>
              <ul className="file-list mt-1 d-flex flex-column">
                {this.state.medias
                  .filter((item) => item.externalUrl)
                  .map((item, index) => (
                    <li key={index} className="my-1 d-flex align-items-center">
                      <File file={item} />
                      {/* <MainButton
                        children={item.externalUrl}
                        type={MainButtonType.dark}
                        borderRadius="24px"
                        fontSize="14px"
                        backgroundColor="#F5F5F5"
                        color="#096BFF"
                        className="text-truncate"
                      ></MainButton> */}
                      <CircleIcon
                        padding="0"
                        type={ThemeCircleIcon.dark}
                        width="22px"
                        height="22px"
                        className="ms-2"
                        onClick={() => {
                          this.handelRemoveMedia(item.id!);
                        }}
                      >
                        <img
                          src="/images/icons/garbage_can.svg"
                          alt="radvix"
                          width={15}
                          height={15}
                        />
                      </CircleIcon>
                    </li>
                  ))}
                {this.state.ExternalUrl.map((item) => (
                  <li className="my-1 d-flex align-items-center max1LineText">
                    <a
                      title="Go to the link"
                      className="max1LineText s-chip outlined fs-200 blueColor"
                      href={item}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item}
                    </a>
                    <CircleIcon
                      type={ThemeCircleIcon.dark}
                      width="22px"
                      height="22px"
                      padding="0"
                      className="ms-2 pointer"
                      onClick={() => this.handelDeleteExternalLink(item)}
                    >
                      <img
                        src="/images/icons/garbage_can.svg"
                        alt="radvix"
                        width={15}
                        height={15}
                      />
                    </CircleIcon>
                  </li>
                ))}
              </ul>
              <div className="item mt-0">
                <span className="label d-flex align-items-center">
                  Category:
                  <div
                    // type={MainButtonType.light}
                    // children={optional}
                    // borderRadius="50px"
                    // fontSize="15px"
                    className="s-chip filled mx-2"
                  >
                    optional
                  </div>
                  <CircleIcon
                    width="20px"
                    height="20px"
                    padding="0"
                    type={ThemeCircleIcon.dark}
                    backgroundColor="transparent"
                    border="1px solid #D5D5D5"
                    fontSize="10px"
                    color="#D5D5D5"
                  >
                    <i className="fas fa-question"></i>
                  </CircleIcon>
                </span>
                <div className="d-flex justify-content-between align-items-center">
                  <SelectComponent
                    items={this.state.listCategory}
                    TextItem="name"
                    defaultValue={this.state.selectedCategory}
                    ValueItem="id"
                    className="mt-1 w-100"
                    placeholder="Click to see the list…"
                    isMulti={false}
                    onChange={(e) => {

                      this.setState({
                        selectedCategory: e,
                      });
                    }}
                  ></SelectComponent>
                  <span
                    className="ms-2"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-add-category-expense"
                  >
                    <CircleIcon
                      width="36px"
                      height="36px"
                      padding="0"
                      type={ThemeCircleIcon.dark}
                      backgroundColor="#9D9D9D"
                      fontSize="18px"
                      color="#ffffff"
                      className="ms-2 px-3"
                    >
                      <i className="fas fa-list"></i>
                    </CircleIcon>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center my-4">
              <MainButton
                type={MainButtonType.dark}
                children={"Edit"}
                borderRadius="50px"
                fontSize="18px"
                className="mx-2"
                minHeight="43px"
                minWidth="136px"
                disabled={this.state.loading}
                onClick={() => {
                  this.handelEditExpense();
                }}
              ></MainButton>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="modal-add-category-expense"
          tabIndex={-1}
          aria-labelledby="modal-add-category-expense"
          aria-hidden="true"
        >
          <CategoryManagementModal
            onCreateNewCategory={(_categoryName) => {
              return new Promise((resolve, reject) => {
                this.handleCreateNewCategory(_categoryName).then((_res) => {
                  this.GetSearchExpense().then(() => {
                    resolve(_res as CreateNewCategoryResult);
                  });
                });
              });
            }}
            onEditCategory={(_categoryName, _categoryId) => {
              return new Promise((resolve, reject) => {
                this.handleEditCategory(_categoryName, _categoryId).then(
                  (_res) => {
                    this.GetSearchExpense().then(() => {
                      resolve(_res as CreateNewCategoryResult);
                    });
                  }
                );
              });
            }}
            onDeleteCategory={(_categoryId) => {
              return new Promise((resolve, reject) => {
                this.handleDeleteCategory(_categoryId).then((_res) => {
                  this.GetSearchExpense().then(() => {
                    resolve(_res as CreateNewCategoryResult);
                  });
                });
              });
            }}
            // @ts-ignore
            categories={this.state.modalCategories}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(ExpensePageEdit);
