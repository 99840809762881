// import React from "react";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../core/constants";
import { $ReplaceRoute, getUserTitle } from "../../core/utils";
import { ImageWithToken } from "./box_list_scroll";
import { IMAGE_BASE_URL } from "../../core/http_common";
// import { IMAGE_BASE_URL } from "../../core/http_common";

type Props = {
  user: any;
  justAvatar?: boolean;
  parentStyle?: any;
  avatarStyle?: any;
  parentClassName?: string;
};

export default function UserWithAvatar({
  user,
  justAvatar,
  parentClassName = "",
  parentStyle = {},
  avatarStyle = {},
}: Props) {
  const _userTitle = getUserTitle(user);
  if (!user || user.id === undefined) return <span>NO ID</span>;
  return (
    <Link
      to={$ReplaceRoute(
        `${AppRoutes.member_profile.replace(":id", user.id ?? "")}`
      )}
      style={{ maxWidth: "100%", ...parentStyle }}
      className={`max1LineText ${parentClassName}`}
    >
      <div className="d-flex align-items-center max1LineText">
        {user.image ? (
          <ImageWithToken
            hasImage={user.image ? true : false}
            originalImage={user.image}
            src={
              user.image
                ? IMAGE_BASE_URL + user.image
                : "/images/images/img_avatar.png"
            }
            alt="Avatar"
            className="rounded-circle avatar"
            style={{
              height: "2em",
              width: "2em",
              ...avatarStyle,
            }}
          />
        ) : (
          <>
            <div className="avatar w-px-25 h-px-25 rounded-circle bg-primary">
            {user?.name ? (
            <label className="fw-medium text-white fs-10">{`${user?.name?.charAt(0)}`}</label>) : 
              <label className="fw-medium text-white fs-10">{`${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`}</label>}
            </div>
          </>
        )}

        {justAvatar ? null : (
          <div className="d-flex align-items-center ms-2 linkOnHover flex-wrap gap-1 pointOnHover max1LineText">
            <span className="max1LineText">{_userTitle}</span>
            {user.invitationStatus === 1 ? (
              <span className="pendingChip">Pending</span>
            ) : null}
          </div>
        )}
        {/* <p className="mb-0 ms-2">{_userTitle}</p> */}

        {/* {
                    item.count > 1 ? (
                      <span className="mx-2">
                        - in {item.count} 
                      </span>
                    ) : ''
                  } */}
      </div>
    </Link>
  );
}
