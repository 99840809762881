import React from 'react'
import { LeftBottomLoginLinks } from '../login/login';
import { InputComponent, InputType } from '../../components/inputs';
import { MainButton, MainButtonType } from '../../components/button';
import { HTTP } from '../../../core/http_common';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { AppRoutes } from '../../../core/constants';

interface AdminArpTypes {

};

const AdminArp = (props: AdminArpTypes) => {
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);
    const history = useHistory()
    async function handleRegisterAdmin() {
        try {
            setLoading(true)
            const _res = await HTTP.post('/User/ARP', {
                email,
                password
            })
            if (_res) {
                toast.success('Register done.')
                history.push(AppRoutes.login)
            }
            setLoading(false)
        } catch (e: any) {
            if (e.response.data.title) {
                toast.error(e.response.data.title)
                setLoading(false)
            }
        }

    }
    return (
        <div className="login d-flex flex-column flex-md-row">
            <div className="left">
                <div className="left_logo_login">
                    <img src="/images/images/radvix_logo.svg" className="logo" alt="" />

                    <img
                        src="/images/images/img_avatar.png"
                        className="logo-Member dNoneTablet"
                        alt=""
                    />
                </div>
                <LeftBottomLoginLinks />
            </div>
            <div className="right" style={{ maxWidth: '100%', width: 400 }}>
                <h1 className="right_login_title">Create admin account</h1>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleRegisterAdmin()
                    }}
                    className="w-100 mt-3"
                    autoComplete="off"
                >
                    <label className="text-light login_input_label">Email:</label>
                    <InputComponent
                        type={InputType.text}

                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        fontSize="16px"
                        // inValid={Validator.current.message(
                        //     "email",
                        //     email,
                        //     "required|email"
                        // )}
                        // autoComplete="off"
                        name={`email-${Math.random().toString(36).substring(7)}`}
                        value={email}
                    ></InputComponent>

                    {/* <div className="password mt-3 d-flex justify-content-between align-items-center"> */}

                    <label
                        className="mt-3 text-light login_input_label"
                        style={{ marginBottom: 0 }}
                    >
                        Password:
                    </label>
                    <InputComponent
                        type={InputType.text}
                        // placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679; bymani "
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        fontSize="16px"
                        // inValid={Validator.current.message(
                        //     "password",
                        //     password,
                        //     "required"
                        // )}
                        isPassword
                    ></InputComponent>
                    {/* </div> */}

                    <MainButton
                        children={"Register"}
                        type={MainButtonType.light}
                        buttonType={"submit"}
                        borderRadius="9px"
                        minWidth="75px"
                        minHeight="38px"
                        className="mt-3 w-100"
                        // style={{
                        //     marginTop: Validator.current.message(
                        //         "password",
                        //         password,
                        //         "required"
                        //     ) ? '0.1em' : '1.8em'
                        // }}
                        loading={loading}
                        disabled={loading || email.toLowerCase() !== 'admin@radvix.io' || password.length < 5}
                    // onClick={() => {
                    // }}
                    ></MainButton>
                </form>
            </div>
        </div>
    )
};

export default AdminArp