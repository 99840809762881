import { useEffect, useState } from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { toast } from "react-toastify";
import { UserRoles } from "../core/utils";
// import { LocalDataSources } from "../data/local_datasources";

export type ProtectedRouteProps = {
  // isAuthenticated: boolean;
  authenticationPath: string;
  whoAccess: UserRoles[];
} & RouteProps;

export default function ProtectedRoute({
  // isAuthenticated,
  authenticationPath,
  whoAccess,
  ...routeProps
}: ProtectedRouteProps) {
  const [iCanUse, setiCanUse] = useState(true);
  useEffect(() => {
    // const local: LocalDataSources = new LocalDataSources();
    // let RoleUser: UserRoles = local.getUserInfo().role;
    let RoleUser: any = JSON.parse(localStorage.getItem("userInfo") || "{}");
    if (!RoleUser) {
      return;
    }
    
    if (!whoAccess.includes(RoleUser?.role)) {
      setiCanUse(false);
    }
  }, [whoAccess]);
  
  if (iCanUse) {
    return <Route {...routeProps} />;
  } else {
    toast.error("You do not have access to this route !");
    return <Redirect to={{ pathname: authenticationPath }} />;
  }
}
