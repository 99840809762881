import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  Pivot,
  PivotItem,
  SelectionMode,
  Text,
} from "@fluentui/react";

export const EmailList = ({
  Emails,
  onEmailClick,
  selectedFolder,
  handleLoadMoreEmails,
  emailLoading,
  onTabClick,
}: {
  Emails: any;
  onEmailClick: (email: any) => void;
  selectedFolder: any;
  handleLoadMoreEmails: () => void;
  emailLoading: boolean;
  onTabClick:(e: any) => void;
}) => {
  const [emailItems, setEmailItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const loader = useRef(null);
  const emailListRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (!emailLoading && emailListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = emailListRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        setLoading(true);
        handleLoadMoreEmails();
      }
    }
  }, [handleLoadMoreEmails]);

  const handleItemClick = (item: any) => {
    onEmailClick(item);
  };
  
  useEffect(() => {
    const options = {
      root: null, 
      rootMargin: '0px',
      threshold: 1,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !loading) {
        setLoading(true);
        handleLoadMoreEmails();
      }
    }, options);

    if (emailListRef.current) {
      observer.observe(emailListRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [emailListRef.current, handleLoadMoreEmails]); 
  const getColumns = (): IColumn[] => {
    const baseColumns: IColumn[] = [
      {
        key: "column1",
        name: "From",
        fieldName: "from",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: (item) => {
          return (
            <span style={{ fontWeight: item.isRead ? 'normal' : 'bold' }}>
              {item.from}
            </span>
          );
        }
      },
      {
        key: "column2",
        name: "Subject",
        fieldName: "subject",
        minWidth: 300,
        maxWidth: 400,
        isResizable: true,
        onRender: (item) => {
          return (
            <span style={{ fontWeight: item.isRead ? 'normal' : 'bold' }}>
              {item.subject}
            </span>
          );
        }
      },
      {
        key: "column3",
        name: "Received",
        fieldName: "received",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: (item) => {
          return (
            <span style={{ fontWeight: item.isRead ? 'normal' : 'bold' }}>
              {item.received}
            </span>
          );
        }
      },
    ];

    // Example: Adding a specific column for 'Sent Items' folder
    if (selectedFolder?.folderName === "Sent Items") {
      baseColumns.length = 0;
      baseColumns.push(
        {
          key: "column4",
          name: "To",
          fieldName: "to",
          minWidth: 100,
          maxWidth: 200,
          isResizable: true,
        },
        {
          key: "column2",
          name: "Subject",
          fieldName: "subject",
          minWidth: 300,
          maxWidth: 400,
          isResizable: true,
        },
        {
          key: "column3",
          name: "Send",
          fieldName: "received",
          minWidth: 100,
          maxWidth: 200,
          isResizable: true,
        }
      );
    }

    // Extend with other conditions as needed for different folders
    // ...

    return baseColumns;
  };
  useEffect(() => {
    setEmailItems(
      Emails.map((email: any) => ({
        key: email.id,
        from: email.from?.emailAddress?.name,
        importance: email.importance,
        emailAddress: email.from?.emailAddress?.address,
        subject: email.subject,
        received: formatDateTime(email.receivedDateTime),
        receivedDateTime: email.receivedDateTime,
        to: email?.toRecipients[0]?.emailAddress?.name,
        body: email.body.content, // Assuming 'received' is the correct field name
        isRead: email.isRead,
        toRecipients: email.toRecipients,
        ccRecipients: email.ccRecipients,
        attachments: email.attachments
      }))
    );
  }, [Emails]);
  if (Emails.length === 0) {
    return (
      <Text variant="large" block styles={{ root: { margin: 20 } }}>
        No records found.
      </Text>
    );
  }

  const formatDateTime = (dateTimeStr: any) => {
    const date = new Date(dateTimeStr);

    // Set locale and options for formatting
    const locale = "en-GB"; // Using 'en-GB' to get day before month in date format
    const options: any = {
      weekday: "short", // "Thu"      
      hour: "2-digit", // "05"
      minute: "2-digit", // "26"
      hour12: false, // Use 24-hour time without AM/PM
    };

    return date.toLocaleString(locale, options);
  };
  const renderTabs = () => {
              return (
      
          <DetailsList
            items={emailItems.filter(email => email.importance === 'high' || email.importance === 'normal')}
            columns={getColumns()}
            setKey="set"            
            //onItemInvoked={onEmailClick} // Invoke onEmailClick when an item is clicked
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            styles={{
              root: {
                "@media (max-width: 640px)": {
                  // Adjust this media query as needed
                  overflowX: "auto", // Enable horizontal scrolling on small screens
                },
              },
              contentWrapper: {
                // Custom styles for content wrapper can go here
              },
            }}
            onRenderMissingItem={() => {
              if (loading) {
                return (
                  <div ref={loader} style={{ height: '100px', textAlign: 'center' }}>
                    <span>Loading more emails...</span>
                  </div>
                );
              }
              return null;
            }}
            onRenderRow={(props, defaultRender) => {
              if (props) {
                return (
                  <div {...props}
                    onClick={() => handleItemClick(props.item)}
                    style={{ cursor: 'pointer' }}
                    >
                      {defaultRender!(props)}
                  </div>
                );
              }
              return null;
            }}
          />
    )
  }
  const handleTabClick = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>): void => {
    if (item && item.props.headerText) {
      onTabClick({tab: item.props.headerText})
    }
  };
  return (
    <div>
      <div style={{ height: "500px", overflowY: "auto" }} ref={emailListRef} onScroll={handleScroll}>
      {/* { selectedFolder && selectedFolder.folderName === "Inbox" && <Pivot aria-label="Focused Other Email Tabs" onLinkClick={handleTabClick}>
        <PivotItem headerText="Focused" key="focused">
        {renderTabs()}
        </PivotItem>
        <PivotItem headerText="Other" key="other">
          <DetailsList
            items={emailItems.filter(email => email.importance === 'low')}
            columns={getColumns()}
            setKey="set"
            onItemInvoked={onEmailClick} // Reuse the same for demonstration, adjust as necessary
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            styles={{
              root: {
                "@media (max-width: 640px)": {
                  // Adjust this media query as needed
                  overflowX: "auto", // Enable horizontal scrolling on small screens
                  cursor: "pointer", // Change cursor to pointer on small screens
                },
                cursor: "pointer",
              },
              contentWrapper: {
                cursor: "pointer",
                selectors: {
                  ":hover": {
                    // Apply additional styles on hover if necessary
                    backgroundColor: "rgba(0, 120, 212, 0.1)", // Light background on hover, optional
                  },
                },
              },
            }}
          />
        </PivotItem>
      </Pivot>
} */}
{ renderTabs() }
      
      {emailLoading && (
        <div style={{ height: '100px', textAlign: 'center' }}>
          <span>Loading more emails...</span>
        </div>
      )}
    
    </div>
    </div>
  );
};

