import { HTTP, handleUnauthorizedRequest } from "../../../core/http_common";
import { SortType } from "../../../core/number_extentions";
import { IFilterOptions } from "../../../views/components/FilterSection/IFilterOptions";
import { TeamCreateReq } from "../../models/requests/team/team_create_req";
import { UpdateTeamReq } from "../../models/requests/team/update_team_req";
import { GetAllTeams } from "../../models/responses/team/get_all_teams_res";
import { GetTeamByIDRes } from "../../models/responses/team/get_by_id_res";
import { TeamCreateRes } from "../../models/responses/team/team_create_res";
import { TeamSearchRes } from "../../models/responses/team/team_search_res";
import { UpdateTeamRes } from "../../models/responses/team/update_team_res";
import { NotesCreateReq } from "../../../data/models/requests/notes/notes_create_req";

export class RemoteTeam {
  createTeam(
    body: TeamCreateReq,
    action: (res: TeamCreateRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/Team", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  createNotes(
    body: NotesCreateReq,
    action: (res: TeamCreateRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/Notes", body)
      .then((res) => {action(res.data)})
      .catch((err) => {
        if (err.response && err.response.status === 401) {
            
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
            
          error(err);
        }
      });
  }
  updateTeam(
    body: UpdateTeamReq,
    action: (res: UpdateTeamRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.put("/Team", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getByIdTeam(
    body: { teamId: number },
    action: (res: GetTeamByIDRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(`/Team/${body.teamId}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  TeamSearch(action: (res: TeamSearchRes) => any, error: (res: any) => any) {
    return HTTP.get("/Team/search")
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getAllTeams(
    body: { pageSize: number; pageNumber: number; SearchParameter: string; columnHeaderName?: string; sortDirection?: SortType,filterOptions?: IFilterOptions},
    action: (res: GetAllTeams) => any,
    error: (res: any) => any
  ) {
    const FromDate = body?.filterOptions?.selectedFromDate ? body.filterOptions.selectedFromDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '';
    const ToDate = body?.filterOptions?.selectedToDate ? body.filterOptions.selectedToDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '';
    const status = body?.filterOptions?.status ? body.filterOptions.status : '';
    const type = body.filterOptions?.teamType ? body.filterOptions?.teamType : '';
    const typeList = Array.isArray(type) ? type.map((obj: { Id: any }) => obj.Id) : [];
    return HTTP.get(
      `/Team?PageSize=${body.pageSize}&PageNumber=${body.pageNumber}&SearchParameter=${body.SearchParameter}&ColumnName=${body.columnHeaderName}&SortDirection=${body.sortDirection}&SelectedFromDate=${FromDate}&SelectedToDate=${ToDate}&Type=${typeList}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
}
