/* eslint-disable jsx-a11y/alt-text */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { MainButton, MainButtonType } from '../../components/button';
import { InputComponent, InputType } from '../../components/inputs';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
// import { useLocation } from "react-router-dom";
// import { UserController } from "../../../controllers/user/user_controller";
// import { UserAccountHandleReq } from "../../../data/models/requests/user/account_req";

// import SimpleReactValidator from "simple-react-validator";
import { Link } from 'react-router-dom';
import { ValidPassword } from '../../components/valid_password';
import { HTTP } from '../../../core/http_common';
import { toast } from 'react-toastify';
import { AppRoutes } from '../../../core/constants';
import { LeftBottomLoginLinks } from '../login/login';
import ReactLoading from 'react-loading';
import { SignupBackground } from '../signup/component/signup_background';
import { BaseButton } from '@fluentui/react';
import { base_url_site } from '../../../core/localVariables';

//@ts-ignore
const ResetPassword: React.FC<RouteComponentProps> = () => {
  // const path = window.location.pathname;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isShowPassCheck, setIsShowPassCheck] = useState(false);
  const [newPw, setNewPw] = useState('');
  const [confirmPw, setConfirmPw] = useState('');
  const toggleVerifyPass = () => {
    setIsShowPassCheck(!isShowPassCheck);
  };
  const [isStrength, setStrength] = useState('');
  const [tokenPassword, setTokenPassword] = useState('');
  const [isError, setError] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const dataHandler = async (childData: any) => {
    setStrength(childData);
  };

  useEffect(() => {
    document.title = `Reset Your Password | Radvix`;
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams.get('token'));
    const token = urlParams.get('token');
    const email = urlParams.get('email');
    // replace space in token with +
    const trimToken = token?.replace(/ /g, '+');

    setTokenPassword(trimToken || '');
    // if (trimToken) {
    //   let config = {
    //     headers: {
    //       authorization: `Bearer ${trimToken}`,
    //     },
    //   };
    //   HTTP.post(`/Team/MemberById?userId=0278b289-50fb-4dba-9e3d-3a58f4ff1b3f`, null, config).then(res => {
    //
    //   })
    // }
    setUserEmail(email || '');
  }, []);
  async function handleChangePassword() {
    if (newPw !== confirmPw) {
      toast.warning('New password and confirm password are not the same');
      return;
    }
    if (isStrength !== 'Strong') {
      if (!newPw || (newPw && isStrength !== 'Strong')) {
        setError('Password is not strong');
      }
      toast.warning('Password is not strong');
    } else {
      const res = await changePassword(newPw, userEmail);
      toast.success('Password changed successfully');

      if (!res) return;
      // if (onChangePassword) {
      //   onChangePassword();
      //   logout();
      // }
    }
  }
  function changePassword(newPassword: string, email: string) {
    setLoading(true);
    return new Promise((resolve, reject) => {
      let config = {
        headers: {
          authorization: `Bearer ${tokenPassword}`,
        },
      };

      console.log(email);

      // prevent special characters in email from removing
      // const _email = email.replace(/ /g, "+");

      // const _email = encodeURIComponent(email);

      HTTP.post(
        '/User/ResetPassword',
        {
          newPassword,
          email,
        },
        config
      )
        .then((res) => {
          setLoading(false);
          history.push(AppRoutes.login);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          setLoading(false);
          toast.error('Something went wrong');
        });
    });
  } // 123456.Ast

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    handleChangePassword();
  };

  return (
    <div className='container'>
      <div className='max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center vh-100'>
        <div className='text-center mb-3'>
          <img
            src='images/icons/new-radvix-logo.png'
            width={111}
            height={49}
            // src='images/icons/radvix-logo.svg'
            // srcSet='images/icons/radvix-logo.svg 111w'
            // sizes='(max-width: 768px) 20vw, 10vw'
            alt='Company logo'
          />
        </div>
        <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column loginDivBorder'>
          <div className='d-flex w-100'>
            <BaseButton
              className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
              style={{ fontWeight: '400', height: '19px' }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <div>
              <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>
                Create new password
              </h1>
            </div>
          </div>
          <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
          <div className='w-100 mb-px-20'>
            <div className='mb-1 text-start'>
              <label className='color-gray-600 fs-15 me-2'>New password</label>
            </div>
            <input
              className='form-control h-px-45'
              type='password'
              onChange={(e) => {
                setNewPw(e.target.value);
              }}
              onBlur={toggleVerifyPass}
              onFocus={toggleVerifyPass}
              value={newPw}
              name='name'
              placeholder='Enter your password'
            />
            <ValidPassword isShow={false} password={newPw} actions={dataHandler} />
            {isError && (
              <label htmlFor='password text-danger'>
                <p className='errors text-danger'>{isError}</p>
              </label>
            )}
          </div>
          <div className='w-100 mb-px-20'>
            <div className='mb-1 text-start'>
              <label className='color-gray-600 fs-15 me-2'>Confirm new password</label>
            </div>
            <input
              className='form-control h-px-45'
              type='password'
              onChange={(e) => {
                setConfirmPw(e.target.value);
              }}
              value={confirmPw}
              name='name'
              placeholder='Re-type your password'
            />
          </div>
          <button
            className='d-flex align-items-center justify-content-center btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton'
            type='submit'
            onClick={handleChangePassword}
          >
            {' '}
            {loading ? (
              <ReactLoading className='d-flex' type='spin' height='auto' width={20} />
            ) : (
              'Submit'
            )}{' '}
          </button>
          <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
          <div className='fs-14 fw-normal'>
            <a
              className='color-gray-600 cursor-pointer'
              href={`${base_url_site}/terms-of-services`}
              rel='noopener noreferrer'
              target='_blank'
            >
              Terms of Service |{' '}
            </a>
            <a
              className='color-gray-600 cursor-pointer'
              href={`${base_url_site}/privacy-policy`}
              rel='noopener noreferrer'
              target='_blank'
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
    // <div>
    //   <div
    //     className="form-container"
    //     style={{
    //       background:
    //         "linear-gradient(360deg, rgba(212, 15, 237, 0) 0%, rgba(212, 15, 237, 1) 100%)",
    //     }}
    //   >
    //     <h2 className="text-center P_Heading mb-4">Reset Password</h2>
    //     <form onSubmit={handleSubmit}>
    //       <div className="mt-3  mb-2 card_Holder_label">New Password: </div>
    //       <div className="">
    //         <input
    //           type="password"
    //           onChange={(e) => {
    //             setNewPw(e.target.value);
    //           }}
    //           onBlur={toggleVerifyPass}
    //           onFocus={toggleVerifyPass}
    //           value={newPw}
    //           name="name"
    //           className="Payemnt_Card_input w-100"
    //           placeholder="New Password"
    //         />
    //         <ValidPassword
    //           isShow={false}
    //           password={newPw}
    //           actions={dataHandler}
    //         />
    //         {isError && (
    //           <label htmlFor="password text-danger" >
    //             <p className="errors text-danger">{isError}</p>
    //           </label>
    //         )}
    //       </div>
    //       <div className="mt-3  mb-2 card_Holder_label">
    //         Confirm New Password:{" "}
    //       </div>
    //       <div className="">
    //         <input
    //           type="password"
    //           onChange={(e) => {
    //             setConfirmPw(e.target.value);
    //           }}
    //           value={confirmPw}
    //           name="name"
    //           className="Payemnt_Card_input w-100"
    //           placeholder="Confirm New Password"
    //         />
    //       </div>
    //       <div></div>
    //       <div>
    //         <button className="Payment_Button mt-4 w-100" type="submit">
    //           {loading ? (
    //             <ReactLoading
    //               className="d-flex"
    //               type="spin"
    //               height="auto"
    //               width={20}
    //             />
    //           ) : (
    //             "Submit"
    //           )}
    //         </button>
    //         <p
    //           style={{
    //             paddingTop: "20px",
    //             textAlign:"center",
    //             marginBottom: "0px",
    //           }}
    //         >
    //           <span className="span"style={{fontSize:"14px"}}>Back to login page?</span>
    //           <span
    //             className="text-wrapper-6"
    //             style={{fontSize:"14px"}}
    //             onClick={() => {
    //               history.push(AppRoutes.login);
    //             }}
    //           >
    //             {" "}
    //             Login
    //           </span>
    //         </p>
    //       </div>
    //     </form>
    //   </div>
    // </div>
    // <div className="login d-flex flex-column flex-md-row">
    //   <div className="left">
    //     <div className="left_logo_login">
    //       <img src="/images/images/radvix_logo.svg" className="logo" alt="" />
    //       <img
    //         src="/images/images/img_avatar.png"
    //         className="logo-Member dNoneTablet"
    //         alt=""
    //       />
    //     </div>
    //     <LeftBottomLoginLinks />
    //   </div>
    //   <div className="right" style={{
    //     maxWidth: 430
    //   }}>
    //     <h1 className="right_login_title">Reset Your Password</h1>
    //     <p className="right_login_subHeading align-self-start">
    //       Enter new password for your account and click on change password.
    //     </p>
    //     <div className="w-100 ">
    //       <div className="mt-3 w-100 position-relative">
    //         <InputComponent
    //           labelStyle={{ color: "#fff" }}
    //           type={InputType.text}
    //           label="Password:"
    //           onChange={(e) => {
    //             setNewPw(e.target.value);
    //           }}
    //           onBlur={toggleVerifyPass}
    //           onFocus={toggleVerifyPass}
    //           isPassword={true}
    //           value={newPw}
    //         ></InputComponent>
    //         <ValidPassword
    //           isShow={isShowPassCheck}
    //           password={newPw}
    //           actions={dataHandler}
    //         />
    //         {isError && (
    //           <label htmlFor="password text-danger">
    //             <p className="errors text-danger">{isError}</p>
    //           </label>
    //         )}
    //       </div>
    //       <div className="mt-3 w-100">
    //         <InputComponent
    //           labelStyle={{ color: "#fff" }}
    //           type={InputType.text}
    //           label="Confirm Password:"
    //           onChange={(e) => {
    //             setConfirmPw(e.target.value);
    //           }}
    //           isPassword={true}
    //           value={confirmPw}
    //         ></InputComponent>
    //       </div>

    //       <div className="password mt-2 d-flex justify-content-center">
    //         <MainButton
    //           children={"Change Password"}
    //           type={MainButtonType.light}
    //           borderRadius="50px"
    //           minWidth="75px"
    //           minHeight="37px"
    //           fontSize="16px"
    //           fontWeight={700}
    //           className="py-2 px-4 mt-3"
    //           loading={loading}
    //           onClick={() => {
    //             handleChangePassword();
    //           }}
    //         ></MainButton>
    //       </div>
    //     </div>

    // <div className="text-center fs-300 mt-4">
    //   <p style={{ color: "rgba(255,255,255,0.64)" }}>
    //     Go Back to the{" "}
    //     <Link to="/login" style={{ color: "#fff" }}>
    //       Login
    //     </Link>{" "}
    //     or{" "}
    //    <Link to="/register" style={{ color: "#fff" }}>
    //      Register
    //    </Link>{" "}
    //    for a New Account{" "}
    //       </p>
    //     </div>
    //   </div>
    // </div>
  );
};
export default withRouter(ResetPassword);
