import { HTTP } from "../../../core/http_common";
import { UpdateSeatMangementReq } from "../../models/requests/seat_management/update_seatMangement_req";
import { UserInfoFilterReq } from "../../models/requests/seat_management/getData_req";
import { SeatManagementListRes, SeatManagementRes } from "../../models/responses/seatManagement/get_list_res";

export class RemoteSeatManagement {
  
  update(
    body: UpdateSeatMangementReq[],
    action: (res: boolean) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/SeatManagement", body)
      .then((res) => action(res.data))
      .catch((err) =>  error(err));
  }
  getList(
    body: UserInfoFilterReq,
    action: (res: SeatManagementRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/SeatManagement/GetUserList", body)
      .then((res) => action(res.data))
      .catch((err) =>  error(err));
  }  
}
