import React, { useEffect, useState } from 'react';
import { Switch, useHistory, withRouter } from 'react-router';
import { AppRoutes } from '../../../core/constants';
import { HTTP } from '../../../core/http_common';
import { toast } from 'react-toastify';
import { $ReplaceRoute } from '../../../core/utils';
import ReactLoading from 'react-loading';
import './UserSubsciption.css';
import { SignupBackground } from '../signup/component/signup_background';
import { store } from '../../../data/storeMain';
import { BaseButton } from '@fluentui/react';

const UpdateSubscription = () => {
  const monthlyRate = 35;
  const annualRate = 29.75;
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [selectedSeats, setSelectedSeats] = useState(0);
  const [montlyAnnualPrice, setMontlyAnnualPrice] = useState<number>(monthlyRate);
  const [nextBillingAmount, setNextBiilingAmount] = useState<number>(0);
  const [dueToday, setDueToday] = useState<number>();
  const [currentSeats, setcurrentSeats] = useState<number>(0);
  const [activeSeats, setActiveSeats] = useState<number>(0);
  const userData = store.getState().userInfo;

  interface ResponseData {
    status: number;
    result: {
      nextBillingDate: Date;
      currentSeats: number;
      billingCycle: number;
      billingAmount: number;
      isUserOnTrial: boolean;
      activeSeats: number;
    };
    message: string;
    success: boolean;
  }
  const [responseData, setResponseData] = useState<ResponseData>({
    status: 0,
    result: {
      nextBillingDate: new Date(),
      currentSeats: 0,
      billingCycle: 0,
      billingAmount: 0,
      isUserOnTrial: false,
      activeSeats: 0,
    },
    message: '',
    success: false,
  });

  useEffect(() => {
    setloading(true);
    HTTP.post(`/Subscription/GetData`)
      .then((res) => {
        setloading(false);
        console.log('res', res);
        setNextBiilingAmount(res.data.result.billingAmount);
        setcurrentSeats(res.data.result.currentSeats);
        setResponseData(res.data);
        setActiveSeats(res.data.result.activeSeats);
        setSelectedSeats(res.data.result.currentSeats);
      })
      .catch((err) => {
        setloading(false);
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
      });

    if (responseData.result.billingCycle == 4) {
      setMontlyAnnualPrice(annualRate);
    } else {
      // setBillingCycle(3);
      setMontlyAnnualPrice(monthlyRate);
    }
  }, []);
  const calculateTotalDays = (startDate: Date, endDate: Date): number => {
    const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
    const differenceInMs = startDate.getTime() - endDate.getTime();
    const differenceInDays = Math.round(differenceInMs / oneDay);
    return differenceInDays;
  };

  const calculateRemainingDaysCost = (days: number, rate: number, billingCycle: number): number => {
    if (days === null) return 0;
    const chargesPerDay = billingCycle === 3 ? monthlyRate / 30 : (annualRate * 12) / 365;
    return chargesPerDay * days;
  };
  const handleIncrementSeats = () => {
    const totalAddedSeats = selectedSeats + 1;
    setSelectedSeats(totalAddedSeats);

    const billingAmount: any =
      formData.BillingCycle === 3
        ? nextBillingAmount + monthlyRate
        : nextBillingAmount + annualRate * 12;
    setNextBiilingAmount(billingAmount);

    const nextBillingDate = new Date(responseData.result.nextBillingDate);
    const days = calculateTotalDays(nextBillingDate, new Date());

    if (!responseData.result.isUserOnTrial && selectedSeats >= currentSeats) {
      const remainingDaysCost = calculateRemainingDaysCost(
        days,
        billingAmount,
        formData.BillingCycle
      );
      formData.DueToday = remainingDaysCost * (totalAddedSeats - currentSeats);
      setDueToday(formData.DueToday);
    }
  };

  const handleDecrementSeats = () => {
    const totalAddedSeats = selectedSeats - 1;
    if (totalAddedSeats < activeSeats) return;

    setSelectedSeats(totalAddedSeats);

    if (selectedSeats >= currentSeats) setDueToday(0);

    const billingAmount =
      formData.BillingCycle === 3
        ? nextBillingAmount - monthlyRate
        : nextBillingAmount - annualRate * 12;
    setNextBiilingAmount(billingAmount);

    const nextBillingDate = new Date(responseData.result.nextBillingDate);
    const days = calculateTotalDays(nextBillingDate, new Date());

    if (!responseData.result.isUserOnTrial && selectedSeats > currentSeats) {
      const remainingDaysCost = calculateRemainingDaysCost(
        days,
        billingAmount,
        formData.BillingCycle
      );
      formData.DueToday = remainingDaysCost * (totalAddedSeats - currentSeats);
      setDueToday(formData.DueToday);
    }
  };

  const currentDate = new Date();
  const sixMonthsLater = new Date();
  sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 1);

  useEffect(() => {}, []);

  const formData = {
    NewSeats: selectedSeats,
    BillingCycle: responseData.result.billingCycle,
    SubscriptionPlanId: 2,
    NextBillingCycle: responseData.result.nextBillingDate,
    NextBillingAmount: responseData.result.billingAmount,
    DueToday: dueToday,
  };

  const Annual = 'Annual';
  const monthly = 'Monthly';

  //Date formate
  const billingStartDate = formData.NextBillingCycle;
  let formattedDate: any;
  if (billingStartDate) {
    formattedDate = new Date(billingStartDate).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }
  const handleNavigate = () => {
    setloading(true);
    formData.NextBillingAmount = nextBillingAmount;
    HTTP.post(`/Subscription/UpdateSubscription`, formData)
      .then((res) => {
        setloading(false);
        console.log('res', res);
        history.push(`/${userData.institution}/dashboard/team/seat_management/`);
      })
      .catch((err) => {
        setloading(false);
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
      });
  };

  return (
    <>
      <div className='overflow-y-auto d-flex flex-column align-items-center vh-100'>
        <div className='container d-flex flex-column align-items-center py-5 flex-grow-1'>
          <div className='max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center'>
            <div className='text-center mb-3'>
              <img
                src='images/icons/new-radvix-logo.png'
                width={111}
                height={49}
                // src='images/icons/radvix-logo.svg'
                // srcSet='images/icons/radvix-logo.svg 111w'
                // sizes='(max-width: 768px) 20vw, 10vw'
                alt='Company logo'
              />
            </div>
            <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column'>
              <div className='d-flex w-100'>
                <BaseButton
                  className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
                  style={{ fontWeight: '400', height: '19px' }}
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  Back
                </BaseButton>
                <div
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>
                    Update Subscription
                  </h1>
                </div>
              </div>
              <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
              <div className='Preview-Div-4' style={{ height: '186px' }}>
                <div className='Preview-Div-5'>
                  <div className='Preview-Div-6'>
                    <div className='Preview-Div-7'>Current Seat Limit:</div>
                    <div className='Preview-Div-7'>New Seat Limit:</div>
                    <div className='Preview-Div-8'>Cycle:</div>
                    <div className='Preview-Div-13'>Subscription:</div>
                  </div>
                  <div className='Preview-Div-9' style={{ paddingLeft: '33px' }}>
                    <div className='Preview-Div-10'>
                      {responseData.result.currentSeats} {'Seats'}
                    </div>
                    <div className='Preview-Div-10'>
                      {selectedSeats - currentSeats >= 0 ? selectedSeats - currentSeats : 0}{' '}
                      {'Seats'}
                    </div>
                    <div className='Preview-Div-11'>
                      {formData.BillingCycle === 3 ? monthly : Annual}
                    </div>
                    <div className='Preview-Div-12'>
                      <div className='Preview-Div-11'>Collaborator</div>
                    </div>
                  </div>
                </div>

                <div className='Preview-Div-15 col-12'>
                  <div className='Preview-Div-16 col-7' style={{ width: '50.333%' }}>
                    <div className='Preview-Div-17'>Next Billing Cycle:</div>
                    <div className='Preview-Div-17'>Next Billing Amount:</div>
                    <div className='Preview-Div-18'>Due today:</div>
                  </div>
                  <div className='Preview-Div-19 col-5' style={{ paddingLeft: '11px' }}>
                    <div className='Preview-Div-20'>{formattedDate}</div>
                    <div className='Preview-Div-21'>${nextBillingAmount}</div>
                    <div className='Preview-Div-21'>
                      ${dueToday !== undefined ? dueToday.toFixed(2) : '0.00'}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 fs-15 S-div-3 mt-3 '>How many seats you will need?</div>
              <div className='S-div-4 d-flex align-items-center justify-content-between w-100 mb-4'>
                <button
                  className='S-M-Button'
                  type='button'
                  onClick={handleDecrementSeats}
                  disabled={loading ? true : false}
                >
                  <span
                    style={{
                      fontSize: '42px',
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'relative',
                      bottom: '20px',
                    }}
                  >
                    -
                  </span>
                </button>
                <input
                  style={{ width: '100%' }}
                  className='S-input seatInputStyle mx-2'
                  type='text'
                  id='Selectedseats'
                  name='Selectedseats'
                  disabled={true}
                  value={`${selectedSeats} ${selectedSeats > 1 ? 'Seats' : 'Seats'}`}
                  //   onChange={handleChange}
                />
                <button
                  className='S-P-Button'
                  type='button'
                  onClick={handleIncrementSeats}
                  disabled={loading ? true : false}
                >
                  <span
                    style={{
                      fontSize: '31px',
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'relative',
                      bottom: '11px',
                    }}
                  >
                    +
                  </span>
                </button>
              </div>
              {/* <div className="S-div-3" style={{ marginTop: "20px" }}>
              How many seats you will need?
            </div>
            <div className="Si-div-4">
              <div className="Si-div-5">
                <input
                  style={{ width: "100%" }}
                  className="S-input"
                  type="text"
                  id="Selectedseats"
                  name="Selectedseats"
                  disabled={true}
                  value={`${selectedSeats} ${
                    selectedSeats > 1 ? "Seats" : "Seats"
                  }`}
                  //   onChange={handleChange}
                />
              </div>
              <div className="Si-div-7">
                <button
                  className="S-M-Button"
                  type="button"
                  style={{backgroundColor: loading ? "#C5C5C5" : "transparent",cursor:"pointer"}}
                  disabled = {loading ? true : false}
                  onClick={handleDecrementSeats}
                >
                  <span
                    style={{
                      fontSize: "42px",
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                      bottom: "4px",
                    }}
                  >
                    -
                  </span>
                </button>
                <button
                  className="S-P-Button"
                  type="button"
                  style={{backgroundColor: loading ? "#C5C5C5" : "#474747",cursor:"pointer"}}
                  disabled = {loading ? true : false}
                  onClick={handleIncrementSeats}
                >
                  <span
                    style={{
                      fontSize: "31px",
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                      bottom: "4px",
                    }}
                  >
                    +
                  </span>
                </button>
              </div>
            </div> */}
              <button
                className='d-flex justify-content-center align-items-center btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton'
                onClick={handleNavigate}
              >
                {loading ? (
                  <ReactLoading className='d-flex' type='spin' height='auto' width={20} />
                ) : (
                  'Continue'
                )}{' '}
              </button>
              <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
              <div className='fs-14 fw-normal'>
                <a
                  className='color-gray-600 cursor-pointer'
                  href='https://radvix.io/terms-of-services'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Terms of Service |{' '}
                </a>
                <a
                  className='color-gray-600 cursor-pointer'
                  href='https://radvix.io/privacy-policy'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div>
          <div
            className="Preview-0-Div-1"
            style={{
              background:
                "linear-gradient(360deg, rgba(212, 15, 237, 0) 0%, rgba(212, 15, 237, 1) 100%)",
            }}
          >
            <div className="Preview-Div-2">Update Subscription</div>

            <div className="Preview-Div-4" style={{ height: "186px" }}>
              <div className="Preview-Div-5">
                <div className="Preview-Div-6">
                  <div className="Preview-Div-7">Current Seat Limit:</div>
                  <div className="Preview-Div-7">New Seat Limit:</div>
                  <div className="Preview-Div-8">Cycle:</div>
                  <div className="Preview-Div-13">Subscription:</div>
                </div>
                <div className="Preview-Div-9" style={{ paddingLeft: "33px" }}>
                  <div className="Preview-Div-10">
                    {responseData.result.currentSeats} {"Seats"}
                  </div>
                  <div className="Preview-Div-10">
                    {selectedSeats - currentSeats  >= 0 ? selectedSeats - currentSeats : 0} {"Seats"}
                  </div>
                  <div className="Preview-Div-11">
                    {formData.BillingCycle === 3 ? monthly : Annual}
                  </div>
                  <div className="Preview-Div-12">
                    <div className="Preview-Div-11">Collaborator</div>
                  </div>
                </div>
              </div>

              <div className="Preview-Div-15 col-12">
                <div
                  className="Preview-Div-16 col-7"
                  style={{ width: "50.333%" }}
                >
                  <div className="Preview-Div-17">Next Billing Cycle:</div>
                  <div className="Preview-Div-17">Next Billing Amount:</div>
                  <div className="Preview-Div-18">Due today:</div>
                </div>
                <div
                  className="Preview-Div-19 col-5"
                  style={{ paddingLeft: "29px" }}
                >
                  <div className="Preview-Div-20">{formattedDate}</div>
                  <div className="Preview-Div-21">${nextBillingAmount}</div>
                  <div className="Preview-Div-21">
                    ${dueToday !== undefined ? dueToday.toFixed(2) : "0.00"}
                  </div>
                </div>
              </div>
            </div>
            <div className="S-div-3" style={{ marginTop: "20px" }}>
              How many seats you will need?
            </div>
            <div className="Si-div-4">
              <div className="Si-div-5">
                <input
                  style={{ width: "100%" }}
                  className="S-input"
                  type="text"
                  id="Selectedseats"
                  name="Selectedseats"
                  disabled={true}
                  value={`${selectedSeats} ${
                    selectedSeats > 1 ? "Seats" : "Seats"
                  }`}
                  //   onChange={handleChange}
                />
              </div>
              <div className="Si-div-7">
                <button
                  className="S-M-Button"
                  type="button"
                  style={{backgroundColor: loading ? "#C5C5C5" : "transparent",cursor:"pointer"}}
                  disabled = {loading ? true : false}
                  onClick={handleDecrementSeats}
                >
                  <span
                    style={{
                      fontSize: "42px",
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                      bottom: "4px",
                    }}
                  >
                    -
                  </span>
                </button>
                <button
                  className="S-P-Button"
                  type="button"
                  style={{backgroundColor: loading ? "#C5C5C5" : "#474747",cursor:"pointer"}}
                  disabled = {loading ? true : false}
                  onClick={handleIncrementSeats}
                >
                  <span
                    style={{
                      fontSize: "31px",
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                      bottom: "4px",
                    }}
                  >
                    +
                  </span>
                </button>
              </div>
            </div>
            <button
              style={{ marginTop: "26px" ,backgroundColor: loading ? "#C5C5C5" : "#3F7BFA",cursor:"pointer"}}
              onClick={handleNavigate}
              className="Seates_Submit "
              disabled={loading ? true : false}
            >
              {loading ? (
                <ReactLoading
                  className="d-flex"
                  type="spin"
                  height="auto"
                  width={20}
                />
              ) : (
                "Continue"
              )}
            </button>
            <button
              className="submitButton backButton"
              onClick={() => window.history.back()}
            >
              Back
            </button>
          </div>
        </div> */}
    </>
  );
};
export default withRouter(UpdateSubscription);
