import { combineReducers } from 'redux';
import userRole from './user_role_reduser';
import userInfo from './user_info_reduser';
import settingApp from './setting_reduser';
import ResearchId from './research_id';
import ResearchUpdated from './research_updated';
import excludedClients from './exclude_clients';
import notificationCount from './notification_count';
import headerTitle from './header_title';
import sidebarCollapse from './sidebar_collapse';

const rootReducer = combineReducers({
  userRole,
  userInfo,
  settingApp,
  ResearchId,
  ResearchUpdated,
  excludedClients,
  notificationCount,
  headerTitle,
  sidebarCollapse,
});
export default rootReducer;
