import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { StatusLabel } from "../status_label";
import { AppTask } from "../../../data/models/responses/task/get_all_tasks_res";
import CircleIconTooltip from "../CircleIconTooltip";
import { FilterBullet } from "./filterBullet";
import { MainButton, MainButtonType } from "../button";
import { isDate } from "moment";
import { ProfileTypes } from "../../../core/number_extentions";

type Props = {
  loading?: boolean;
  disabled?: boolean;
  date?: Date | null;
  status?: any[];
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  teamType?: any[];
  onselect: (
    selectedFromDate?: Date | null,
    selectedToDate?: Date | null,
    selectedStatus?: number[],
    teamType?: number[]
  ) => void;
  IsTypeAvailable?: boolean;
  className?: string;
  IsDateNotAvailable?: boolean;
  pageType?: number;
  dateLabel?: string;
};

export default function FilterSection({
  loading,
  disabled,
  date,
  status,
  startDate,
  endDate,
  onselect,
  teamType,
  IsTypeAvailable,
  className,
  IsDateNotAvailable,
  pageType,
  dateLabel,
}: Props) {
  let IsLoading;
  if (loading) {
    IsLoading = "loading";
  }
  const ONE_DAY = 24 * 60 * 60 * 1000;
  const [statuses, setStatuses] = useState(status);
  const [teamTypes, setTeamTypes] = useState(teamType);
  const [startDates, setStartDate] = useState<Date | null>(startDate || null);
  const [endDates, setEndDate] = useState<Date | null>(endDate || null);
  const [selectedStatus, setSelectedStatus] = useState<any[]>([]);
  const [showFilterBullets, setShowFilterBullets] = useState<boolean>(false);
  const [showStatusDiv, setShowStatusDiv] = useState<boolean>(false);

  const [selectedType, setSelectedtype] = useState<any[]>([]);
  const [showTypeDiv, setShowTypeDiv] = useState<boolean>(false);

  const handleDateChange = (selectedDate: Date | null, type: string) => {
    if (type === "start") {
      setStartDate(selectedDate);
    } else if (type === "end") {
      setEndDate(selectedDate);
    }
  };

  const handleStatusClick = (selectedStatusId: any) => {
    if (showFilterBullets) {
      //setShowStatusDiv(true);
      const updatedStatus = statuses?.map((x) => {
        if (selectedStatusId.Id === x.Id) {
          x.isStatusSelected = !x.isStatusSelected;
        }
        return x;
      });

      const selectedStatuses =
        updatedStatus?.filter((x) => x.isStatusSelected) || [];
      setSelectedStatus(selectedStatuses);
      setStatuses(updatedStatus); //
    } else {
      const updatedStatus = statuses?.map((x) => {
        if (selectedStatusId.Id === x.Id) {
          x.isStatusSelected = !x.isStatusSelected;
        }
        return x;
      });

      const selectedStatuses =
        updatedStatus?.filter((x) => x.isStatusSelected) || [];
      setSelectedStatus(selectedStatuses);
      setStatuses(updatedStatus);
      // setShowStatusDiv(true);
    }
  };

  const handleTypeClick = (selectedTypeId: any) => {
    if (showFilterBullets) {
      const updatedType = teamTypes?.map((x) => {
        if (selectedTypeId.Id === x.Id) {
          x.isTypeSelected = !x.isTypeSelected;
        }
        return x;
      });

      const selectedTypes = updatedType?.filter((x) => x.isTypeSelected) || [];
      setSelectedtype(selectedTypes);
      setTeamTypes(updatedType); //
    } else {
      const updatedType = teamTypes?.map((x) => {
        if (selectedTypeId.Id === x.Id) {
          x.isTypeSelected = !x.isTypeSelected;
        }
        return x;
      });

      const selectedTypes = updatedType?.filter((x) => x.isTypeSelected) || [];
      setSelectedtype(selectedTypes);
      setTeamTypes(updatedType); //
    }
  };

  const handleApplyFilter = () => {
    const hasDates = startDates !== null || endDates !== null;
    const hasStatus = selectedStatus.length > 0;
    if (hasDates || hasStatus || selectedType.length > 0) {
      setShowFilterBullets(true);
      setShowStatusDiv(hasStatus);
      setShowTypeDiv(selectedType.length > 0);
    } else {
      setShowFilterBullets(false);
      setShowStatusDiv(false);
      setShowTypeDiv(false);
    }
    onselect(startDates, endDates, selectedStatus, selectedType);
    //    onselect(startDates, endDates, selectedStatus);
  };

  const handleRemoveFilter = (
    filterType: string,
    statusId?: number,
    teamTypeId?: number
  ) => {
    if (filterType === "date") {
      setStartDate(null);
      setEndDate(null);
      onselect(null, null, selectedStatus, selectedType);
      if (selectedStatus.length <= 0 && selectedType.length <= 0) {
        setShowFilterBullets(false);
        setShowStatusDiv(false);
        setShowTypeDiv(false);
        status?.forEach((x) => {
          x.isStatusSelected = false;
        });
        teamType?.forEach((x) => {
          x.isTypeSelected = false;
        });
      }
    } else if (filterType === "status") {
      const updatedStatus = statuses?.map((x) => {
        if (statusId === x.Id) {
          x.isStatusSelected = !x.isStatusSelected;
        }
        return x;
      });
      const selectedStatuses =
        updatedStatus?.filter((x) => x.isStatusSelected) || [];
      setSelectedStatus(selectedStatuses);
      setStatuses(updatedStatus);
      onselect(startDates, endDates, selectedStatuses, selectedType);
      if (!selectedStatus || selectedStatuses.length == 0) {
        setShowStatusDiv(false);
      }
      if (
        startDates === null &&
        endDates === null &&
        selectedType.length <= 0 &&
        (!selectedStatus || selectedStatuses.length == 0)
      ) {
        setShowFilterBullets(false);
      }
    } else if (filterType === "type") {
      const updatedType = teamTypes?.map((x) => {
        if (teamTypeId === x.Id) {
          x.isTypeSelected = !x.isTypeSelected;
        }
        return x;
      });
      const selectedTypes = updatedType?.filter((x) => x.isTypeSelected) || [];
      setSelectedtype(selectedTypes);
      setTeamTypes(updatedType);
      onselect(startDate, endDate, selectedStatus, selectedTypes);
      if (!selectedType || selectedTypes.length == 0) {
        setShowTypeDiv(false);
      }
      if (
        startDates === null &&
        endDates === null &&
        selectedStatus.length <= 0 &&
        (!selectedType || selectedTypes.length == 0)
      ) {
        setShowFilterBullets(false);
      }
    }
  };
  const showClassName = () => {
    if (pageType == ProfileTypes.RESEARCHSELECTOR && showFilterBullets) {
      return "col-3";
    } else if (showFilterBullets) {
      return "col-2";
    } else {
      return "col-12";
    }
  };
  return (
    <>
      <div
        style={{ backgroundColor: "white", borderRadius: 10, padding: "9px" }}
      >
        <div className="d-flex justify-content-left align-items-center">
          <div
            className="mb-0 max1LineText font-bold  d-flex"
            style={{ paddingLeft: "1%", width: "20%" }}
          >
            <img
              src="/images/icons/filterNew.svg"
              alt="radvix"
              width={17}
              height={20}
            />
            <h6
              className="mb-0 d-flex align-items-center"
              style={{ fontWeight: "bold" , paddingLeft: "2%"}}
            >
              {" "}
              Filtering Tool
            </h6>
          </div>
        </div>
        <br />
        {!IsDateNotAvailable && (
          <div className="d-flex item col-12 padding-filter-section">
            <span
              className="fs-200 font-bold"
              style={{ width: `${pageType == ProfileTypes.RESEARCHSELECTOR ? '17%' : '12%'}`,alignSelf: "center" }}
            >
              {dateLabel ? dateLabel : "Date Created"}:{" "}
            </span>
            <div className={`item mt-0 ${className ? className : "col-6"}`}>
              <div className="d-flex justify-content-between align-items-center">
                <DatePicker
                  //className="border-radius-filter"
                  disabled={disabled}
                  selected={startDates}
                  onChange={(selectedDate) =>
                    handleDateChange(
                      selectedDate ? new Date(selectedDate.toString()) : null,
                      "start"
                    )
                  }
                  maxDate={
                    endDate ? undefined : new Date((endDate as any) - ONE_DAY)
                  }
                  placeholderText="Starting Date"
                />
                <span className="mx-2">Until</span>
                <DatePicker
                  //className="border-radius-filter"
                  disabled={disabled}
                  selected={endDates}
                  onChange={(selectedDate) =>
                    handleDateChange(
                      selectedDate ? new Date(selectedDate.toString()) : null,
                      "end"
                    )
                  }
                  maxDate={
                    startDate
                      ? undefined
                      : new Date((startDate as any) - ONE_DAY)
                  }
                  placeholderText="Ending Date"
                />
              </div>
            </div>
          </div>
        )}
        {statuses && (
          <div className="d-flex justify-content-left align-items-center col-12 padding-filter-section">
            <span className="fs-200 font-bold">
              Status:{" "}
            </span>
            <div
              className="fs-100 d-flex justify-content-center"
              style={{ paddingLeft: "9px" }}
            >
              <div
                className="dFlexSm chipBottomRightInSm d-flex"
                style={{ gap: "8px" }}
              >
                {statuses?.map((item, index) => (
                  <StatusLabel
                    className={`justify-content-center chipBottomRightInSm pointer-filter-section ${
                      item.isStatusSelected === true ? `filter-op` : ""
                    }`}
                    status={item.Id}
                    deadLine={0}
                    isSelected={item.isStatusSelected}
                    onStatusClick={(statusId) => handleStatusClick(statusId)}
                  />
                ))}
              </div>
              {/* <StatusLabel
            className="dNoneSm"
            status={status as any}
            deadLine={(status as any).daysLeftDeadline}
            size="sm"
          /> */}
            </div>
          </div>
        )}
        {IsTypeAvailable == true && teamTypes && (
          <div className="d-flex justify-content-left align-items-center  col-12 padding-filter-section">
            <span className="fs-200 font-bold">
              Type:{" "}
            </span>
            <div
              className="fs-100 d-flex justify-content-center"
              style={{ paddingLeft: "9px" }}
              //title={status?.toString()}
            >
              <div
                className="dFlexSm chipBottomRightInSm d-flex"
                style={{ gap: "8px" }}
              >
                {teamTypes?.map((item, index) => (
                  <StatusLabel
                    className={`justify-content-center chipBottomRightInSm pointer-filter-section ${
                      item.isTypeSelected === true ? `filter-op` : ""
                    }`}
                    status={item.Id}
                    deadLine={0}
                    isSelected={item.isTypeSelected}
                    onStatusClick={(typeId) => handleTypeClick(typeId)}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className="d-flex justify-content-left align-items-center  col-12 padding-filter-section"
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "8px",
          borderRadius: "6px",
        }}
      >
        {showFilterBullets && (
          <div
            className={`${
              pageType == ProfileTypes.RESEARCHSELECTOR ? "col-9" : "col-10"
            } d-flex`}
          >
            <div className="">
              <span className="label d-flex align-items-center font-filter-section">
                Active Filters:{" "}
              </span>
            </div>
            <div
              className="fs-100 d-flex justify-content-center"
              //title={status?.toString()}
            >
              <div className="dFlexSm chipBottomRightInSm">
                <FilterBullet
                  selecteDate={
                    startDates
                      ? `Created from ${startDates?.toLocaleDateString()} ${
                          endDates ? "-" : ""
                        }  ${endDates ? endDates?.toLocaleDateString() : ""}`
                      : ""
                  }
                  status={
                    showStatusDiv && selectedStatus && selectedStatus.length > 0
                      ? selectedStatus
                      : undefined
                  }
                  type={
                    showTypeDiv && selectedType && selectedType.length > 0
                      ? selectedType
                      : undefined
                  }
                  onRemove={(filterType, statusId, teamTypeId) =>
                    handleRemoveFilter(filterType, statusId, teamTypeId)
                  }
                />
              </div>
            </div>
          </div>
        )}
        <div
          className={showClassName()}
          style={{ textAlign: "right" , paddingRight: "1%"}}
        >
          <MainButton
            children="Apply Filter"
            type={MainButtonType.dark}
            // borderRadius="24px"
            // fontSize="12px"
            // className="my-2"
            //minWidth="72px"
            borderRadius="24px"
            fontSize="14px"
            minHeight="2em"
            className="my-0 mx-0 px-3 dNoneXxs"
            onClick={() => handleApplyFilter()}
          ></MainButton>
        </div>
      </div>
    </>
  );
}
