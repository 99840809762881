import React from 'react';
import { store } from '../../../data/storeMain';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
import 'react-datepicker/dist/react-datepicker.css';
import { MainButton, MainButtonType } from '../../components/button';
import { IconTextRow } from '../../components/icon_text_horizontal';
import {
  $ReplaceRoute,
  DiscussionTopic,
  getUserInitails,
  getUserTitle,
  isTokenInHttpAuth,
  Theme,
} from '../../../core/utils';
import { BoxListScroll, ImageWithToken } from '../../components/box_list_scroll';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppRoutes, MOMENT_DATE_FORMAT } from '../../../core/constants';
import { GetDataByIDResResult } from '../../../data/models/responses/data/get_by_id_data_res';
import { DataController } from '../../../controllers/data/data_controller';
import moment from 'moment';
import { HTTP } from '../../../core/http_common';
import Skeleton from 'react-loading-skeleton';
import EmptyChip from '../../components/EmptyChip';
import { FaMicroscope, FaUsers } from 'react-icons/fa';
import File from '../../components/File';
import { Link } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import UserWithAvatar from '../../components/UserWithAvatar';
import { GiTalk } from 'react-icons/gi';
import { BsFillChatLeftDotsFill } from 'react-icons/bs';
import ProfileTitleDesc from '../../components/ProfileTitleDesc';
import { ProfileTypes } from '../../../core/number_extentions';
import ConfirmationContent from '../../components/ConfirmationContent';
import Visible from '../../components/visible';
import { MdDeleteOutline } from 'react-icons/md';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import { DropdownMenu, Table } from '@radix-ui/themes';
import RadixTable from '../../components/NewRadixTable/RadixTable';
import ShowNotes from '../../components/notes';
import { BaseButton } from '@fluentui/react';
import { SetResearchId } from '../../../data/storeMain/actions/research_action';
import {
  HeaderTitleAction,
  SetHeaderTitle,
} from '../../../data/storeMain/actions/header_title_action';
interface RouteParams {
  appTaskId: string;
  dataid: string;
}
let unsubscribeMeData: any = undefined;
class DataPageProfile extends React.Component<RouteComponentProps<RouteParams>> {
  RoleUser = store.getState().userRole;
  controller = new DataController();
  state: GetDataByIDResResult = {
    newEmailMenuVisible: false,
    taskId: 0,
    taskTitle: '',
    description: '',
    allowToEdit: false,
    allowedToView: false,
    subTaskId: null,
    subTaskTitle: null,
    researchId: 0,
    taskCreatorUserId: '',
    taskCreatorFirstName: '',
    taskCreatorLastName: '',
    data: {
      id: 0,
      title: '',
      creatorUserId: '',
      creatorFirstName: '',
      creatorLastName: '',
      createdDate: new Date(),
      creatorUser: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        image: '',
        invitationStatus: 0,
        isUserTransferred: false,
        transferredToUserId: '',
      },
      discussionId: 0,
      medias: [],
    },
    equipments: [],
    users: [],
    haveDiscussion: false,
    loading: true,
    selectedTab: 0,
    researchTitle: '',
  };
  componentDidMount() {
    document.title = "Data Profile | Radvix";
    if (!isTokenInHttpAuth(HTTP)) return;
    const search = this.props.location.search;
    const researchId = new URLSearchParams(search).get('researchId');
    this.setState({
      loading: true,
    });
    this.controller.getDataById(
      {
        dataId: parseInt(this.props.match.params.dataid),
        researchId: parseInt(researchId ?? ''),
        appTaskId: parseInt(this.props.match.params.appTaskId),
      },
      (res) => {
        if (!res) {
          this.setState({
            loading: false,
          });
          return;
        }
        document.title = `Data | ${res.data.title} | Radvix`;
        //dispatch(SetHeaderTitle('Data'))
        const _users = res.users.map((user: any) => {
          const userTitleAst = getUserTitle(user);
          return {
            ...user,
            title: userTitleAst,
            secondary: user.invitationStatus === 1 ? 'Pending' : null,
          };
        });
        this.setState({
          taskId: res.taskId,
          taskTitle: res.taskTitle,
          allowToEdit: res.allowToEdit,
          allowedToView: res.allowedToView,
          description: res.description,
          subTaskId: res.subTaskId,
          subTaskTitle: res.subTaskTitle,
          researchId: res.researchId,
          researchTitle: res.researchTitle,
          taskCreatorUserId: res.taskCreatorUserId,
          taskCreatorFirstName: res.taskCreatorFirstName,
          taskCreatorLastName: res.taskCreatorLastName,
          data: res.data,
          equipments: res.equipments,
          users: _users,
          haveDiscussion: res.haveDiscussion,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });

        if (err.response?.data?.status === 403) {
          window.history.back();
        }
      }
    );
    unsubscribeMeData = store.subscribe(() => {
      if (store.getState().ResearchId.value <= 0) {
        this.props.history.push($ReplaceRoute(AppRoutes.data_list));
        // this.setState({ researchName: store.getState().ResearchId.name });
      }
    });
  }

  // clean up event listener
  componentWillUnmount() {
    if (unsubscribeMeData) unsubscribeMeData();
  }
  bytesToMB(bytes: number) {
    return (bytes / (1024 * 1024)).toFixed(3);
  }
  downloadFile(props: any) {
    if (props.externalUrl) {
      const aElement = document.createElement('a');
      aElement.href = props.externalUrl;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(props.externalUrl);
    } else if (props?.name) {
      const aElement = document.createElement('a');
      aElement.href = props.name;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(props.name);
    }
  }
  render() {
    const _links = this.state.data.medias.filter((item) => item.externalUrl);
    const _files = this.state.data.medias.filter((media) => media.externalUrl === null);

    return (
      <div className='profileCard'>
        <div className='d-flex justify-content-between align-items-center p-3 flex-wrap gap-3 position-relative'>
          <div className='d-flex flex-wrap align-items-center gap-3'>
            <BaseButton
              className='btn btn-outline-primary fs-15 py-px-4 px-3 d-sm-block d-none back-button'
              style={{ fontWeight: '400', height: '19px' }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>

            <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>
              {this.state.loading ? (
                <Skeleton width='6em' height='1.25em' />
              ) : (
                <span>{this.state.data.title}</span>
              )}
            </h2>
          </div>
          {this.state.loading ? (
            <Skeleton width='6em' height='1.25em' />
          ) : (
            <div
              className='relative TopTableBox d-flex flex-md-row justify-content-end align-items-md-start'
              style={{ gap: '10px' }}
            >
              <div className='btn-group'>
                <MainButton
                  svg={
                    <svg
                      className='hover-fill'
                      xmlns='http://www.w3.org/2000/svg'
                      width='15'
                      height='15'
                      viewBox='0 0 15 15'
                      fill='none'
                    >
                      <rect width='15' height='15' fill='white' fillOpacity='0.01' />
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M12.5 3L2.5 3.00002C1.67157 3.00002 1 3.6716 1 4.50002V9.50003C1 10.3285 1.67157 11 2.5 11H7.50003C7.63264 11 7.75982 11.0527 7.85358 11.1465L10 13.2929V11.5C10 11.2239 10.2239 11 10.5 11H12.5C13.3284 11 14 10.3285 14 9.50003V4.5C14 3.67157 13.3284 3 12.5 3ZM2.49999 2.00002L12.5 2C13.8807 2 15 3.11929 15 4.5V9.50003C15 10.8807 13.8807 12 12.5 12H11V14.5C11 14.7022 10.8782 14.8845 10.6913 14.9619C10.5045 15.0393 10.2894 14.9965 10.1464 14.8536L7.29292 12H2.5C1.11929 12 0 10.8807 0 9.50003V4.50002C0 3.11931 1.11928 2.00003 2.49999 2.00002Z'
                        fill='#5746AF'
                      />
                    </svg>
                  }
                  children='Chat'
                  type={MainButtonType.dark}
                  borderRadius='24px'
                  fontSize='14px'
                  minHeight='2em'
                  className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 main-button'
                  onClick={() => {
                    store.dispatch(
                      SetResearchId({
                        label: this.state.researchTitle,
                        value: this.state.researchId,
                      })
                    );
                    this.props.history.push(
                      $ReplaceRoute(
                        `${AppRoutes.discussion}?topicId=${DiscussionTopic.Data}&taskId=${
                          this.state.taskId
                        }&sectionId=${this.state.data.id}&title=${
                          this.state.data.title
                        }&researchId=${store.getState().ResearchId?.value}&researchTitle=${
                          store.getState().ResearchId?.label
                        }`
                      )
                    );
                  }}
                ></MainButton>
                <button
                  type='button'
                  className='btn btn-soft-primary btn-sm fs-13 px-2 py-0 dropdown-toggle dropdown-toggle-split'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  <span className='visually-hidden'>Toggle Dropdown</span>
                </button>
                <ul className='dropdown-menu'>
                  <li>
                    <a
                      className='dropdown-item fs-14'
                      href='#'
                      onClick={(e) => {
                        e.preventDefault();
                        store.dispatch(
                          SetResearchId({
                            label: this.state.researchTitle,
                            value: this.state.researchId,
                          })
                        );
                        this.props.history.push(
                          $ReplaceRoute(
                            `${AppRoutes.discussion}?topicId=${DiscussionTopic.Data}&taskId=${
                              this.state.taskId
                            }&sectionId=${this.state.data.id}&title=${
                              this.state.data.title
                            }&researchId=${store.getState().ResearchId?.value}&researchTitle=${
                              store.getState().ResearchId?.label
                            }`
                          )
                        );
                      }}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='21'
                        viewBox='0 0 20 21'
                        fill='none'
                      >
                        <rect
                          width='20'
                          height='20'
                          transform='translate(0 0.5)'
                          fill='white'
                          fill-opacity='0.01'
                        />
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M16.6667 4.49996L3.33333 4.49999C2.22876 4.49999 1.33333 5.39543 1.33333 6.49999V13.1667C1.33333 14.2713 2.22876 15.1666 3.33333 15.1666H10C10.1769 15.1666 10.3464 15.2369 10.4714 15.362L13.3333 18.2238V15.8333C13.3333 15.4652 13.6319 15.1666 14 15.1666H16.6667C17.7712 15.1666 18.6667 14.2713 18.6667 13.1667V6.49996C18.6667 5.39539 17.7712 4.49996 16.6667 4.49996ZM3.33332 3.16665L16.6667 3.16663C18.5076 3.16663 20 4.65901 20 6.49996V13.1667C20 15.0076 18.5076 16.5 16.6667 16.5H14.6667V19.8333C14.6667 20.1029 14.5043 20.346 14.2551 20.4492C14.006 20.5524 13.7192 20.4953 13.5285 20.3048L9.72389 16.5H3.33333C1.49239 16.5 0 15.0076 0 13.1667V6.49999C0 4.65904 1.49237 3.16667 3.33332 3.16665Z'
                          fill='#1C2024'
                        />
                      </svg>
                      Chat
                    </a>
                  </li>
                  <li>
                    <a
                      className='dropdown-item fs-14'
                      href='#'
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push($ReplaceRoute(`${AppRoutes.discussion}`));
                      }}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='21'
                        viewBox='0 0 20 21'
                        fill='none'
                      >
                        <rect
                          width='20'
                          height='20'
                          transform='translate(0 0.5)'
                          fill='white'
                          fill-opacity='0.01'
                        />
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M1.33333 3.16663C0.596953 3.16663 0 3.76359 0 4.49996V16.5C0 17.2364 0.596953 17.8333 1.33333 17.8333H18.6667C19.4031 17.8333 20 17.2364 20 16.5V4.49996C20 3.76359 19.4031 3.16663 18.6667 3.16663H1.33333ZM1.33333 4.49996H18.6667V5.73321C18.5565 5.73311 18.4451 5.76331 18.3452 5.82669L10 11.1227L1.65483 5.82669C1.55495 5.76331 1.44347 5.73311 1.33333 5.73321V4.49996ZM1.33333 7.04392V16.5H18.6667V7.04392L10.3215 12.3399C10.1253 12.4644 9.87475 12.4644 9.67851 12.3399L1.33333 7.04392Z'
                          fill='#1C2024'
                        />
                      </svg>
                      Email
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ pointerEvents: 'none', opacity: '0.55' }}
                      className='dropdown-item fs-14'
                      href='#'
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push($ReplaceRoute(`${AppRoutes.discussion}`));
                      }}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='21'
                        viewBox='0 0 20 21'
                        fill='none'
                      >
                        <rect
                          width='20'
                          height='20'
                          transform='translate(0 0.5)'
                          fill='white'
                          fill-opacity='0.01'
                        />
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M6.66675 6.68133C6.66675 5.51373 6.98967 4.64051 7.52439 4.06474C8.05254 3.49605 8.86118 3.13771 10.0019 3.13771C11.1433 3.13771 11.9508 3.49534 12.4777 4.06275C13.0113 4.6373 13.3334 5.50942 13.3334 6.67765V8.49997H6.66675V6.68133ZM5.33342 8.49997V6.68133C5.33342 5.27529 5.7246 4.04337 6.5474 3.15741C7.37678 2.26437 8.56908 1.80438 10.0019 1.80438C11.4345 1.80438 12.6261 2.26309 13.4547 3.15547C14.2769 4.04074 14.6667 5.27193 14.6667 6.67765V8.49997H16.0001C16.7365 8.49997 17.3334 9.09693 17.3334 9.8333V17.8333C17.3334 18.5697 16.7365 19.1666 16.0001 19.1666H4.00008C3.26371 19.1666 2.66675 18.5697 2.66675 17.8333V9.8333C2.66675 9.09693 3.26371 8.49997 4.00008 8.49997H5.33342ZM4.00008 9.8333H16.0001V17.8333H4.00008V9.8333Z'
                          fill='#1C2024'
                        />
                      </svg>
                      Slack
                    </a>
                  </li>
                </ul>
              </div>
              <>
                <span style={{ paddingRight: '5px' }}>
                  <ConfirmationContent
                    title='Delete Data'
                    message='Are you sure you want to delete this data?'
                    onConfirm={() => {
                      this.controller.deleteData(
                        {
                          dataId: this.state.data.id,
                        },
                        (res) => {
                          if (res) {
                            toast.success('Data deleted successfully');
                            this.props.history.push($ReplaceRoute(AppRoutes.data_list));
                          }
                        },
                        (err) => {}
                      );
                    }}
                    onCancel={() => {}}
                    confirmText='Yes, Delete'
                  >
                    <MainButton
                      svg={
                        <svg
                          className='hover-fill'
                          xmlns='http://www.w3.org/2000/svg'
                          width='15'
                          height='15'
                          viewBox='0 0 15 15'
                          fill='none'
                        >
                          <rect width='15' height='15' fill='white' fillOpacity='0.01' />
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M5.5 1C5.22386 1 5 1.22386 5 1.5C5 1.77614 5.22386 2 5.5 2H9.5C9.77614 2 10 1.77614 10 1.5C10 1.22386 9.77614 1 9.5 1H5.5ZM3 3.5C3 3.22386 3.22386 3 3.5 3H5H10H11.5C11.7761 3 12 3.22386 12 3.5C12 3.77614 11.7761 4 11.5 4H11V12C11 12.5523 10.5523 13 10 13H5C4.44772 13 4 12.5523 4 12V4H3.5C3.22386 4 3 3.77614 3 3.5ZM5 4H10V12H5V4Z'
                            fill='#5746AF'
                          />
                        </svg>
                      }
                      children='Delete Dataset'
                      type={MainButtonType.dark}
                      borderRadius='24px'
                      fontSize='14px'
                      minHeight='2em'
                      className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 main-button'
                      onClick={() => {}}
                    />
                  </ConfirmationContent>
                </span>
              </>
            </div>
          )}
        </div>
        <div className='p-3 pt-0'>
          <div className='d-flex flex-wrap align-items-center row-gap-sm-px-0 row-gap-2'>
            <div className='w-100 w-sm-auto ps-sm-3 pe-3 border-end-sm-1 border-gray-400 min-h-px-25'>
              <div className='d-flex align-items-center'>
                <label className='fw-normal fs-15 mb-0 color-gray-600 me-2'>Task:</label>
                <p className='fw-medium fs-13 mb-0'>
                  {this.state.loading ? (
                    <Skeleton width='6em' height='1.25em' />
                  ) : (
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        this.props.history.push(
                          $ReplaceRoute(
                            `${AppRoutes.task_profile.replace(
                              ':id',
                              this.state.taskId?.toString() ?? ''
                            )}`
                          )
                        );
                      }}
                      title={this.state.taskTitle}
                    >
                      {this.state.taskTitle}
                    </span>
                  )}
                </p>
              </div>
            </div>

            <div className='w-100 w-sm-auto ps-sm-3 pe-3 border-end-md-1 border-gray-400 min-h-px-25'>
              <div className='d-flex align-items-center'>
                <label className='fw-normal fs-15 mb-0 color-gray-600 me-2'>
                  Dataset attached by:
                </label>
                {this.state.loading ? (
                  <Skeleton width='6em' height='1.25em' />
                ) : (
                  <UserWithAvatar user={this.state.data.creatorUser} />
                )}
              </div>
            </div>

            <div className='w-100 w-sm-auto ps-sm-3 pe-3 border-end-sm-1 border-gray-400 min-h-px-25'>
              <div className='d-flex align-items-center'>
                <label className='fw-normal fs-15 mb-0 color-gray-600 me-2'>Assigned by:</label>
                {this.state.loading ? (
                  <Skeleton width='6em' height='1.25em' />
                ) : (
                  <UserWithAvatar user={this.state.data.creatorUser} />
                )}
              </div>
            </div>

            <div className='w-100 w-sm-auto ps-sm-3 pe-3 min-h-px-25'>
              <div className='d-flex align-items-center'>
                <label className='fw-normal fs-15 mb-0 color-gray-600 me-2'>Date attached:</label>
                {this.state.loading ? (
                  <Skeleton width='6em' height='1.25em' />
                ) : (
                  <label className='fw-normal fs-15 mb-0 color-gray-1000'>
                    {moment(this.state.data.createdDate).format(MOMENT_DATE_FORMAT)}
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='row flex-nowrap scrollmenu border-top-1 border-gray-400'>
          <div
            className='dashboardProjectDetail dashboard-section overviwe align-items-center'
            style={{ borderBottom: 'none' }}
          >
            <div
              className={
                this.state.selectedTab === 0
                  ? 'overviwe-item'
                  : 'overviwe-item button-dashboard-category'
              }
              onClick={() =>
                this.setState({
                  selectedTab: 0,
                })
              }
            >
              <div className='dashboardProjectDetailItem'>
                <div
                  className='d-flex flex-column align-items-center dashboardProjectDetailItem'
                  id='pills-notes-tab'
                >
                  <div
                    className={
                      this.state.selectedTab === 0
                        ? 'dashboardSelectedCategory'
                        : 'dashboardCategory'
                    }
                  >
                    <svg
                      width='15'
                      height='15'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className={'dashboardCategoryIconAll'}
                    >
                      <rect width='15' height='15' fill='white' fillOpacity='0.01'></rect>
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M4.66699 2.66677C4.29881 2.66677 4.00033 2.96525 4.00033 3.33344V16.6668C4.00033 17.0349 4.29881 17.3334 4.66699
                  17.3334H15.3337C15.7018 17.3334 16.0003 17.0349 16.0003 16.6668V8.0001H11.3337C10.9655 8.0001 10.667 7.70162 10.667
                  7.33344V2.66677H4.66699ZM12.0003 3.60958L15.0575 6.66677H12.0003V3.60958ZM2.66699 3.33344C2.66699 2.22886 3.56242 1.33344
                  4.66699 1.33344H11.3337C11.5105 1.33344 11.68 1.40368 11.8051 1.5287L17.1385 6.86204C17.2634 6.98705 17.3337 7.15662
                  17.3337 7.33344V16.6668C17.3337 17.7713 16.4382 18.6668 15.3337 18.6668H4.66699C3.56242 18.6668 2.66699 17.7713 2.66699
                  16.6668V3.33344Z'
                        fill={this.state.selectedTab === 0 ? 'white' : 'black'}
                      ></path>
                    </svg>
                    {/* <ViewGridIcon
                    color={
                      this.state.selectedTab === 0
                        ? "white"
                        : "black"
                    }
                    className={"dashboardCategoryIconAll"}
                  ></ViewGridIcon> */}
                    {/* <img
                    src={
                      this.state.notificationCategory === 0
                        ? "/images/icons/world.svg"
                        : "/images/icons/worldBlack.svg"
                    }
                    alt="Research"
                    className={"dashboardCategoryIconAll"}
                  /> */}
                    <span className='textWhite'>Additional Data</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.selectedTab === 1
                  ? 'overviwe-item'
                  : 'overviwe-item button-dashboard-category'
              }
              onClick={() =>
                this.setState({
                  selectedTab: 1,
                })
              }
            >
              <div className=' dashboardProjectDetailItem'>
                <div
                  className='d-flex flex-column align-items-center dashboardProjectDetailItem'
                  id='pills-description-tab'
                >
                  <div
                    className={
                      this.state.selectedTab === 1
                        ? 'dashboardSelectedCategory'
                        : 'dashboardCategory'
                    }
                  >
                    <svg
                      width='15'
                      height='15'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className={'dashboardCategoryIconAll'}
                    >
                      <rect width='15' height='15' fill='white' fillOpacity='0.01'></rect>
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M5.26641 3.93349V6.0001C5.26641 6.33148 4.99777 6.6001 4.66641 6.6001C4.33503 6.6001 4.06641 6.33148 4.06641
                  6.0001V3.33352C4.06641 3.27008 4.07625 3.20894 4.09449 3.15156C4.17154 2.90913 4.39846 2.73349 4.66641
                  2.73349H15.333C15.5402 2.73349 15.7228 2.83842 15.8306 2.99802C15.8953 3.09378 15.933 3.20922 15.933
                  3.33349V6.0001C15.933 6.33148 15.6645 6.6001 15.333 6.6001C15.0017 6.6001 14.733 6.33148 14.733
                  6.0001V3.93349H10.7331V16.0668H12.3389C12.6702 16.0668 12.9389 16.3355 12.9389 16.6668C12.9389 16.9983 12.6702 17.2668
                  12.3389 17.2668H7.67221C7.34083 17.2668 7.07221 16.9983 7.07221 16.6668C7.07221 16.3355 7.34083 16.0668 7.67221
                  16.0668H9.26641V3.93349H5.26641Z'
                        fill={this.state.selectedTab === 1 ? 'white' : 'black'}
                      ></path>
                    </svg>
                    {/* <TransformIcon
                    color={
                      this.state.selectedTab === 1
                        ? "white"
                        : "black"
                    }
                    className={"dashboardCategoryIconAll"}
                  ></TransformIcon> */}

                    <span className='textWhite'>Description</span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                this.state.selectedTab === 2
                  ? 'overviwe-item'
                  : 'overviwe-item button-dashboard-category'
              }
              onClick={() =>
                this.setState({
                  selectedTab: 2,
                })
              }
            >
              <div className='d-flex flex-column align-items-center dashboardProjectDetailItem'>
                <div
                  className={
                    this.state.selectedTab === 2 ? 'dashboardSelectedCategory' : 'dashboardCategory'
                  }
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='15'
                    height='15'
                    viewBox='0 0 20 20'
                    fill='none'
                  >
                    <rect width='15' height='15' fill='white' fill-opacity='0.01' />
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M0.666667 0C0.298477 0 0 0.298477 0 0.666667V19.3333C0 19.7015 0.298477 20 0.666667 20H6C6.36819 20 6.66667 19.7015 6.66667 19.3333V6.66667H19.3333C19.7015 6.66667 20 6.36819 20 6V0.666667C20 0.298477 19.7015 0 19.3333 0H0.666667ZM1.33333 5.4334V1.33333H5.43336L5.4334 5.43343L1.33333 5.4334ZM1.33333 6.56673L1.33333 18.6667H5.33333V17.2333H3.66669C3.35373 17.2333 3.10003 16.9797 3.10003 16.6667C3.10003 16.3537 3.35373 16.1 3.66669 16.1H5.33333V14.5667H3.00003C2.68707 14.5667 2.43336 14.3129 2.43336 14C2.43336 13.6871 2.68707 13.4333 3.00003 13.4333H5.33333V11.9001L3.66669 11.9C3.35373 11.9 3.10003 11.6463 3.10003 11.3334C3.10004 11.0204 3.35375 10.7667 3.66671 10.7667L5.33333 10.7667V9.23336L3.66665 9.23333C3.35369 9.23333 3.1 8.97963 3.1 8.66667C3.1 8.35371 3.35372 8.1 3.66668 8.1L5.33333 8.10003V6.56676L1.33333 6.56673ZM6.56673 5.33333H8.1V3.66675C8.1 3.35379 8.35371 3.10008 8.66667 3.10008C8.97963 3.10008 9.23333 3.35379 9.23333 3.66675V5.33333H10.7667V3.66673C10.7667 3.35377 11.0204 3.10007 11.3334 3.10007C11.6463 3.10007 11.9 3.35377 11.9 3.66673V5.33333H13.4335V3.00007C13.4335 2.68711 13.6871 2.4334 14.0001 2.4334C14.3131 2.4334 14.5668 2.68711 14.5668 3.00007V5.33333H16.1001V3.66673C16.1001 3.35377 16.3539 3.10007 16.6668 3.10007C16.9797 3.10007 17.2335 3.35377 17.2335 3.66673V5.33333H18.6667V1.33333H6.56669L6.56673 5.33333Z'
                      fill={this.state.selectedTab === 2 ? 'white' : 'black'}
                    />
                  </svg>

                  <span className='textWhite'>Equipment</span>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.selectedTab === 3
                  ? 'overviwe-item'
                  : 'overviwe-item button-dashboard-category'
              }
              onClick={() =>
                this.setState({
                  selectedTab: 3,
                })
              }
            >
              <div className='d-flex flex-column align-items-center dashboardProjectDetailItem'>
                <div
                  className={
                    this.state.selectedTab === 3 ? 'dashboardSelectedCategory' : 'dashboardCategory'
                  }
                >
                  <svg
                    width='15'
                    height='15'
                    viewBox='0 0 15 15'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className={'dashboardCategoryIconAll'}
                  >
                    <rect width='15' height='15' fill='white' fillOpacity='0.01'></rect>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M0.877014 7.49979C0.877014 3.8421 3.84216 0.876953 7.49985 0.876953C11.1575 0.876953 14.1227 3.8421 14.1227
                  7.49979C14.1227 11.1574 11.1575 14.1226 7.49985 14.1226C3.84216 14.1226 0.877014 11.1574 0.877014 7.49979ZM7.49985
                  1.82695C4.36683 1.82695 1.82701 4.36677 1.82701 7.49979C1.82701 8.97187 2.38774 10.313 3.30727 11.3212C4.19074 9.9411
                  5.73818 9.0249 7.50023 9.0249C9.26206 9.0249 10.8093 9.94088 11.6929 11.3207C12.6121 10.3126 13.1727 8.97163 13.1727
                  7.49979C13.1727 4.36677 10.6328 1.82695 7.49985 1.82695ZM10.9818 11.9786C10.2839 10.7794 8.9857 9.9749 7.50023
                  9.9749C6.01458 9.9749 4.71624 10.7796 4.01845 11.979C4.97952 12.7271 6.18765 13.1726 7.49985 13.1726C8.81227 13.1726
                  10.0206 12.7269 10.9818 11.9786ZM5.14999 6.50478C5.14999 5.20691 6.20212 4.15478 7.49999 4.15478C8.79786 4.15478 9.84999
                  5.20691 9.84999 6.50478C9.84999 7.80265 8.79786 8.85478 7.49999 8.85478C6.20212 8.85478 5.14999 7.80265 5.14999
                  6.50478ZM7.49999 5.10478C6.72679 5.10478 6.09999 5.73158 6.09999 6.50478C6.09999 7.27798 6.72679 7.90478 7.49999
                  7.90478C8.27319 7.90478 8.89999 7.27798 8.89999 6.50478C8.89999 5.73158 8.27319 5.10478 7.49999 5.10478Z'
                      fill={this.state.selectedTab === 3 ? 'white' : 'black'}
                    ></path>
                  </svg>
                  {/* <RulerSquareIcon
                  color={
                    this.state.selectedTab === 3 ? "white" : "black"
                  }
                  className={"dashboardCategoryIconAll"}
                ></RulerSquareIcon> */}

                  <span className='textWhite'>User</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='tab-content' id='pills-tabContent'>
          {this.state.selectedTab === 0 && (
            <div
              // className="tab-pane fade"
              id='pills-attachment'
              role='tabpanel'
              aria-labelledby='pills-attachment-tab'
            >
              <div>
                <div className='overflow-auto vh-calc-xl-320 vh-calc-sm-415 px-3'>
                  <div className='py-3 border-top border-bottom border-gray-400 d-flex justify-content-between align-items-center'>
                    <h3 className='color-gray-1000 fw-medium fs-15 mb-0'>Attachment</h3>
                    <a
                      className='btn btn-primary fs-13 py-px-4 fw-normal px-2'
                      href='create-new-proj.html'
                      style={{ visibility: 'hidden' }}
                    >
                      <i className='fa fa-plus me-1'></i>
                    </a>
                  </div>
                  <RadixTable
                    data={this.state.data.medias}
                    loading={this.state.loading}
                    noDataFound={'No attachment yet'}
                    columns={[
                      {
                        Header: 'File name',
                        Cell: (_props: any) => {
                          return (
                            <Table.Cell className='p-3 text-nowrap'>
                              {_props.inputDataType == 4 ? _props.externalUrl : _props.title}
                            </Table.Cell>
                          );
                        },
                      },
                      {
                        Header: 'Size',
                        Cell: (props: any) => {
                          return (
                            <Table.Cell className='p-3 text-nowrap'>
                              {props.inputDataType == 4 ? '-' : `${props.fileSize} MB`}
                            </Table.Cell>
                          );
                        },
                      },
                      {
                        Header: '',
                        Cell: (props: any) => {
                          return (
                            <Table.Cell className='p-3 text-nowrap'>
                              <div className='d-flex align-items-center gap-3'>
                                <div className='btn-group'>
                                  <button
                                    type='button'
                                    className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                                    onClick={() => this.downloadFile(props)}
                                  >
                                    {props.inputDataType == 4 ? (
                                      <>
                                        <svg
                                          className='me-px-6'
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='15'
                                          height='16'
                                          viewBox='0 0 15 16'
                                          fill='none'
                                        >
                                          <rect
                                            width='15'
                                            height='15'
                                            transform='translate(0 0.500534)'
                                            fill='white'
                                            fill-opacity='0.01'
                                          />
                                          <path
                                            fill-rule='evenodd'
                                            clip-rule='evenodd'
                                            d='M7.50039 2.30055C4.35237 2.30055 1.80039 4.85253 1.80039 8.00055C1.80039 11.1485 4.35237 13.7005 7.50039 13.7005C10.6484 13.7005 13.2004 11.1485 13.2004 8.00055C13.2004 4.85253 10.6484 2.30055 7.50039 2.30055ZM0.900391 8.00055C0.900391 4.35547 3.85531 1.40056 7.50039 1.40056C11.1454 1.40056 14.1004 4.35547 14.1004 8.00055C14.1004 11.6456 11.1454 14.6005 7.50039 14.6005C3.85531 14.6005 0.900391 11.6456 0.900391 8.00055Z'
                                            fill='#5746AF'
                                          />
                                          <path
                                            fill-rule='evenodd'
                                            clip-rule='evenodd'
                                            d='M13.5 8.40051H1.5V7.60051H13.5V8.40051Z'
                                            fill='#5746AF'
                                          />
                                          <path
                                            fill-rule='evenodd'
                                            clip-rule='evenodd'
                                            d='M7.10022 14.0005V2.00053H7.90022V14.0005H7.10022ZM10.3753 8.00051C10.3753 5.82777 9.59395 3.67831 8.06214 2.25709L8.53824 1.74394C10.2399 3.32271 11.0753 5.67326 11.0753 8.00051C11.0753 10.3278 10.2399 12.6783 8.53824 14.2571L8.06214 13.7439C9.59395 12.3227 10.3753 10.1733 10.3753 8.00051ZM4 8.00053C4 5.67664 4.80817 3.32731 6.45799 1.74772L6.94208 2.25334C5.45851 3.67376 4.7 5.82442 4.7 8.00053C4.70001 10.1766 5.45853 12.3273 6.9421 13.7477L6.458 14.2533C4.80819 12.6737 4.00001 10.3244 4 8.00053Z'
                                            fill='#5746AF'
                                          />
                                          <path
                                            fill-rule='evenodd'
                                            clip-rule='evenodd'
                                            d='M7.49929 4.45854C9.66861 4.45854 11.8746 4.85968 13.3699 5.69501C13.5387 5.78928 13.5991 6.0025 13.5048 6.17126C13.4106 6.34001 13.1973 6.4004 13.0286 6.30613C11.6787 5.55208 9.60732 5.15854 7.49929 5.15854C5.39125 5.15854 3.31985 5.55208 1.96997 6.30613C1.80121 6.4004 1.58799 6.34001 1.49372 6.17126C1.39946 6.0025 1.45984 5.78928 1.6286 5.69501C3.12399 4.85968 5.32996 4.45854 7.49929 4.45854ZM7.49929 11.3505C9.66861 11.3505 11.8746 10.9493 13.3699 10.114C13.5387 10.0198 13.5991 9.80654 13.5048 9.63779C13.4106 9.46903 13.1973 9.40865 13.0286 9.50291C11.6787 10.257 9.60732 10.6505 7.49929 10.6505C5.39125 10.6505 3.31985 10.257 1.96997 9.50292C1.80121 9.40865 1.58799 9.46903 1.49372 9.63779C1.39946 9.80654 1.45984 10.0198 1.6286 10.114C3.12399 10.9493 5.32996 11.3505 7.49929 11.3505Z'
                                            fill='#5746AF'
                                          />
                                        </svg>
                                        {`Open link`}
                                      </>
                                    ) : (
                                      <>
                                        <svg
                                          className='me-px-6'
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='15'
                                          height='16'
                                          viewBox='0 0 15 16'
                                          fill='none'
                                        >
                                          <rect
                                            width='15'
                                            height='15'
                                            transform='translate(0 0.500427)'
                                            fill='white'
                                            fill-opacity='0.01'
                                          />
                                          <path
                                            fill-rule='evenodd'
                                            clip-rule='evenodd'
                                            d='M7.5 11.5004C4.80285 11.5004 2.52952 10.1223 1.09622 8.00044C2.52952 5.87859 4.80285 4.50043 7.5 4.50043C10.1971 4.50043 12.4705 5.87859 13.9038 8.00044C12.4705 10.1223 10.1971 11.5004 7.5 11.5004ZM7.5 3.50043C4.30786 3.50043 1.65639 5.20681 0.0760002 7.73544C-0.0253338 7.89758 -0.0253334 8.10331 0.0760014 8.26544C1.65639 10.794 4.30786 12.5004 7.5 12.5004C10.6921 12.5004 13.3436 10.794 14.924 8.26544C15.0253 8.10331 15.0253 7.89758 14.924 7.73544C13.3436 5.20681 10.6921 3.50043 7.5 3.50043ZM7.5 10.0004C8.60457 10.0004 9.5 9.105 9.5 8.00043C9.5 6.89586 8.60457 6.00043 7.5 6.00043C6.39543 6.00043 5.5 6.89586 5.5 8.00043C5.5 9.105 6.39543 10.0004 7.5 10.0004Z'
                                            fill='#5746AF'
                                          />
                                        </svg>
                                        {`Open file`}
                                      </>
                                    )}
                                  </button>
                                </div>
                              </div>
                            </Table.Cell>
                          );
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.selectedTab === 1 && (
            <div
              // className="tab-pane fade"
              id='pills-description'
              role='tabpanel'
              aria-labelledby='pills-description-tab'
            >
              <div>
                <div className='overflow-auto vh-calc-xl-320 vh-calc-sm-415 px-3'>
                  <div className='py-3 border-top border-bottom border-gray-400 d-flex justify-content-between align-items-center'>
                    <h3 className='color-gray-1000 fw-medium fs-15 mb-0'>Description</h3>
                    <a
                      className='btn btn-primary fs-13 py-px-4 fw-normal px-2'
                      href='create-new-proj.html'
                      style={{ visibility: 'hidden' }}
                    >
                      <i className='fa fa-plus me-1'></i>
                    </a>
                  </div>
                  <div className='p-3 bg-gray-200 rounded-px-6 my-3'>
                    <p className='color-gray-600 fw-medium fs-15'>{this.state.description}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.selectedTab === 2 && (
            <div id='pills-attachment' role='tabpanel' aria-labelledby='pills-attachment-tab'>
              <div>
                <div className='overflow-auto vh-calc-xl-320 vh-calc-sm-415 px-3'>
                  <div className='py-3 border-top border-gray-400 d-flex justify-content-between align-items-center'>
                    <h3 className='color-gray-1000 fw-medium fs-15 mb-0'>Equipment</h3>
                    <a
                      className='btn btn-primary fs-13 py-px-4 fw-normal px-2'
                      href='create-new-proj.html'
                      style={{ visibility: 'hidden' }}
                    >
                      <i className='fa fa-plus me-1'></i>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className='overflow-auto vh-calc-xl-320 vh-calc-sm-415 px-3'>
                  {this.state.equipments.map((item) => (
                    <div className='border-top  border-gray-400'>
                      <div
                        key={item.id} // Assuming each lab has a unique id
                        className='col-8 py-3 d-flex justify-content-between align-items-center'
                      >
                        <div className='col-3 d-flex align-items-center'>
                          {item.image === null || item.image === '' || item.image === undefined ? (
                            <div className='avatar w-px-25 h-px-25 rounded-circle bg-primary'>
                              <label className='fw-medium text-white fs-10'>
                                {getUserInitails(item.title)}
                              </label>
                            </div>
                          ) : (
                            <ImageWithToken
                              originalImage={item.image}
                              hasImage={item.image ? true : false}
                              alt='Avatar'
                              className='rounded-circle avatar rounded-avatar-dashboard'
                              src={
                                item.image === null || item.image === '' || item.image === undefined
                                  ? '/images/images/img_avatar.png'
                                  : item.image
                              }
                            />
                          )}
                          <h3
                            className='color-gray-1000 fw-medium fs-15 mb-0 ms-2'
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(
                                $ReplaceRoute(
                                  `${AppRoutes.equip_profile.replace(
                                    ':id',
                                    item.id.toString() ?? ''
                                  )}`
                                )
                              );
                            }}
                          >
                            {item.title}
                          </h3>
                        </div>
                        {/* <div className='col-3 d-flex align-items-center'>
                          <div className='avatar w-px-25 h-px-25 rounded-circle bg-primary'>
                            <label className='fw-medium text-white fs-10'>
                              {getUserInitails(item.title)}
                            </label>
                          </div>
                          <h3
                            className='color-gray-1000 fw-medium fs-15 mb-0 ms-2'
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(
                                $ReplaceRoute(
                                  `${AppRoutes.equip_profile.replace(
                                    ':id',
                                    item.id.toString() ?? ''
                                  )}`
                                )
                              );
                            }}
                          >
                            {item.title}
                          </h3>
                        </div> */}
                        <div className='btn-group'>
                          <button
                            type='button'
                            className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(
                                $ReplaceRoute(
                                  `${AppRoutes.equip_profile.replace(
                                    ':id',
                                    this.state.researchId.toString() ?? ''
                                  )}`
                                )
                              );
                            }}
                          >
                            <i className='fa fa-eye me-px-6'></i>View Details
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {this.state.selectedTab === 3 && (
            <div
              // className="tab-pane fade"
              id='pills-attachment'
              role='tabpanel'
              aria-labelledby='pills-attachment-tab'
            >
              <div>
                <div className='overflow-auto vh-calc-xl-320 vh-calc-sm-415 px-3'>
                  <div className='py-3 border-top border-bottom border-gray-400 d-flex justify-content-between align-items-center'>
                    <h3 className='color-gray-1000 fw-medium fs-15 mb-0'>User</h3>
                    <a
                      className='btn btn-primary fs-13 py-px-4 fw-normal px-2'
                      href='create-new-proj.html'
                      style={{ visibility: 'hidden' }}
                    >
                      <i className='fa fa-plus me-1'></i>
                    </a>
                  </div>
                  <RadixTable
                    data={this.state.users}
                    loading={this.state.loading}
                    columns={[
                      {
                        Header: 'User name',
                        Cell: (_props: any) => {
                          return (
                            <Table.Cell className='p-3'>
                              <div className='d-flex align-items-center text-nowrap'>
                                {_props.image === null ||
                                _props.image === '' ||
                                _props.image === undefined ? (
                                  <div className='avatar w-px-25 h-px-25 rounded-circle bg-primary'>
                                    <label className='fw-medium text-white fs-10'>
                                      {getUserInitails(`${_props.firstName} ${_props.lastName}`)}
                                    </label>
                                  </div>
                                ) : (
                                  <ImageWithToken
                                    originalImage={_props.image}
                                    hasImage={_props.image ? true : false}
                                    alt='Avatar'
                                    className='rounded-circle avatar rounded-avatar-dashboard'
                                    src={
                                      _props.image === null ||
                                      _props.image === '' ||
                                      _props.image === undefined
                                        ? '/images/images/img_avatar.png'
                                        : _props.image
                                    }
                                  />
                                )}
                                <span
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    this.props.history.push(
                                      $ReplaceRoute(
                                        `${AppRoutes.member_profile.replace(
                                          ':id',
                                          _props.id?.toString() ?? ''
                                        )}`
                                      )
                                    );
                                  }}
                                  title={_props.firstName}
                                  {..._props.lastName}
                                >
                                  {' '}
                                  {_props.firstName} {_props.lastName}
                                </span>
                              </div>
                              {/* <div className="d-flex align-items-center text-nowrap">
                              <div className="avatar w-px-25 h-px-25 rounded-circle bg-primary">
                                <label className="fw-medium text-white fs-10">
                                  {getUserInitails(
                                    `${_props.firstName} ${_props.lastName}`
                                  )}
                                </label>
                              </div>
                              {_props.firstName} {_props.lastName}
                            </div> */}
                            </Table.Cell>
                          );
                        },
                      },
                      {
                        Header: 'Workspace',
                        Cell: (props: any) => {
                          return (
                            <Table.Cell className='p-3 text-nowrap'>
                              {props.userWorkspace}
                            </Table.Cell>
                          );
                        },
                      },
                      {
                        Header: '',
                        Cell: (props: any) => {
                          return (
                            <Table.Cell className='p-3 text-nowrap'>
                              <div className='d-flex align-items-center gap-3'>
                                <div className='btn-group'>
                                  <button
                                    type='button'
                                    className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.history.push(
                                        $ReplaceRoute(
                                          `${AppRoutes.member_profile.replace(
                                            ':id',
                                            props.id.toString() ?? ''
                                          )}`
                                        )
                                      );
                                    }}
                                  >
                                    <i className='fa fa-eye me-px-6'></i>View Details
                                  </button>

                                  <DropdownMenu.Root>
                                    <DropdownMenu.Trigger>
                                      <button
                                        type='button'
                                        className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split'
                                        data-bs-toggle='dropdown'
                                        aria-expanded='false'
                                      >
                                        <span className='visually-hidden'>Toggle Dropdown</span>
                                      </button>
                                    </DropdownMenu.Trigger>
                                    <DropdownMenu.Content>
                                      <DropdownMenu.Item className='px-0'>
                                        <button
                                          className='dropdown-item fs-14'
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.props.history.push(
                                              $ReplaceRoute(
                                                `${AppRoutes.member_profile.replace(
                                                  ':id',
                                                  props.id.toString() ?? ''
                                                )}`
                                              )
                                            );
                                          }}
                                        >
                                          View Details
                                        </button>
                                      </DropdownMenu.Item>
                                      <DropdownMenu.Item className='px-0'>
                                        <button
                                          className='dropdown-item fs-14'
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.props.history.push(
                                              $ReplaceRoute(
                                                `${AppRoutes.member_user_edit.replace(
                                                  ':id',
                                                  props.id.toString() ?? ''
                                                )}`
                                              )
                                            );
                                          }}
                                        >
                                          Edit User Accesss
                                        </button>
                                      </DropdownMenu.Item>
                                    </DropdownMenu.Content>
                                  </DropdownMenu.Root>
                                </div>
                              </div>
                            </Table.Cell>
                          );
                        },
                      },
                    ]}
                  />
                </div>
                {/* <div className="py-3 px-3">
              <div className="d-flex justify-content-sm-between justify-content-center align-items-center gap-3 flex-wrap">
                <div className="d-flex align-items-center gap-px-10 justify-content-sm-center justify-content-between w-sm-auto w-100">
                  <h2 className="fw-normal fs-13 mb-0 color-gray-600">
                    Show Data :
                  </h2>
                  <select className="bg-gray-400 h-px-26 form-select color-gray-1000 py-0 fs-13 w-px-70 ps-2 pe-0">
                    <option>10</option>
                    <option>100</option>
                  </select>
                </div>
                <div className="d-flex align-items-center">
                  <nav aria-label="..." className="pe-3">
                    <ul className="pagination">
                      <li className="page-item disabled">
                        <span className="page-link">
                          <i className="fa fa-chevron-left"></i>
                        </span>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item active" aria-current="page">
                        <span className="page-link">2</span>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <span className="page-link">
                          <i className="fa fa-chevron-right"></i>
                        </span>
                      </li>
                    </ul>
                  </nav>
                  <a
                    href="#"
                    className="fw-medium fs-13 mb-0 color-gray-1000 ps-3 border-start border-gray-400"
                  >
                    Go to page
                  </a>
                </div>
              </div>
            </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
      // <div className="container-fluid research new-research">
      //   <div className="row"></div>
      //   <div className="col-12 box-content p-3">
      //     <div className="d-flex justify-content-between align-items-start">
      //       <h5 className="b-title d-flex align-items-center">
      //         <IoIosArrowDropleftCircle
      //           className="me-3 textSecondary2 cursorPointer"
      //           size="1.5em"
      //           onClick={() => {
      //             window.history.back();
      //           }}
      //         />{" "}
      //         {"Dataset profile"}
      //         {/* {this.state.allowToEdit ? (
      //           <CircleIcon
      //             width="22px"
      //             height="22px"
      //             type={ThemeCircleIcon.dark}
      //             backgroundColor="#474747"
      //             fontSize="10px"
      //             color="#ffff"
      //             className="mx-1 pointer"
      //             onClick={() => {
      //               this.props.history.push(
      //                 this.props.location.pathname.replace("profile", "Edit") +
      //                   this.props.location.search
      //               );
      //             }}
      //           >
      //             <img src="/images/icons/edit.svg" alt="radvix" />
      //           </CircleIcon>
      //         ) : null} */}
      //         {/* <CircleIcon
      //           width="22px"
      //           height="22px"
      //           type={ThemeCircleIcon.dark}
      //           backgroundColor="#474747"
      //           fontSize="10px"
      //           color="#ffff"
      //           className="mx-1"
      //         >
      //           <img src="/images/icons/start_discussion.svg" alt="radvix" />
      //         </CircleIcon> */}
      //       </h5>
      //       <div className="d-flex align-items-center">
      //         <Visible visible={this.state.allowToEdit}>
      //           <>
      //             <span style={{ paddingRight: "5px" }}>
      //               <ConfirmationContent
      //                 title="Delete Data"
      //                 message="Are you sure you want to delete this data?"
      //                 onConfirm={() => {
      //                   this.controller.deleteData(
      //                     {
      //                       dataId: this.state.data.id,
      //                     },
      //                     (res) => {
      //                       if (res) {
      //                         toast.success("Data deleted successfully");
      //                         this.props.history.push(
      //                           $ReplaceRoute(AppRoutes.data)
      //                         );
      //                       }

      //                     },
      //                     (err) => {}
      //                   );
      //                 }}
      //                 onCancel={() => {}}
      //                 confirmText="Yes, Delete"
      //               >
      //                 <CircleIcon
      //                   width="1.4em"
      //                   height="1.4em"
      //                   fontSize="1.08rem"
      //                   padding="0"
      //                   type={ThemeCircleIcon.error}
      //                   // backgroundColor="#474747"
      //                   // color="#ffff"

      //                   className="pointer p-0"
      //                 >
      //                   <MdDeleteOutline />
      //                 </CircleIcon>
      //               </ConfirmationContent>
      //             </span>
      //           </>
      //         </Visible>
      //         <Tippy
      //         content={
      //           <div className="d-flex flex-column p-2">
      //             <div className="d-flex align-items-center mt-3 justify-content-end">
      //               <MainButton
      //                 children="New Email"
      //                 type={MainButtonType.light}
      //                 borderRadius="8px"
      //                 fontSize="16px"
      //                 className="px-3 py-1 me-1"
      //                 onClick={() => {
      //                   const profileType = ProfileTypes.DATA;
      //                   const currentUrl = window.location.href;
      //                   const url = new URL(currentUrl);
      //                   const pathname = url.pathname;
      //                   const parts = pathname.split("/");
      //                   const appTaskId = parts[parts.length - 2];
      //                   const taskId: number = Number(appTaskId);
      //                   const profileId = parseInt(this.props.match.params.dataid);
      //                   this.props.history.push(
      //                     $ReplaceRoute(
      //                       `${
      //                         AppRoutes.dashboard
      //                       }?isEmail=true&profileId=${profileId}&profileType=${profileType}&NewEmail=${true}&taskId=${taskId}`
      //                     )
      //                   );
      //                   this.setState({ setComposeEmail: true });
      //                 }}
      //               ></MainButton>
      //               <MainButton
      //                 children="Open Email"
      //                 type={MainButtonType.dark}
      //                 borderRadius="8px"
      //                 fontSize="16px"
      //                 className="px-3 py-1"
      //                 onClick={() => {
      //                   const profileType = ProfileTypes.DATA;
      //                   const currentUrl = window.location.href;
      //                   const url = new URL(currentUrl);
      //                   const pathname = url.pathname;
      //                   const parts = pathname.split("/");
      //                   const appTaskId = parts[parts.length - 2];
      //                   const profileId = parseInt(this.props.match.params.dataid);
      //                   this.props.history.push(
      //                     $ReplaceRoute(
      //                       `${
      //                         AppRoutes.dashboard
      //                       }?isEmail=true&profileId=${profileId}&profileType=${profileType}&NewEmail=${false}&taskId=${appTaskId}`
      //                     )
      //                   );
      //                   this.setState({ setComposeEmail: false });
      //                 }}
      //               ></MainButton>
      //             </div>
      //           </div>
      //         }
      //         placement="bottom"
      //         arrow={false}
      //         interactive
      //         visible={this.state.newEmailMenuVisible}
      //         onClickOutside={() => {
      //           this.setState({
      //             newEmailMenuVisible: false,
      //           });
      //         }}
      //       >
      //         <div>
      //           <CircleIcon
      //             width="1.5em"
      //             height="1.5em"
      //             fontSize="1.08rem"
      //             type={ThemeCircleIcon.dark}
      //             padding="0"
      //             className="dNone dFlexSm pointer"
      //             onClick={() => {
      //               this.setState({
      //                 newEmailMenuVisible: true,
      //               });
      //             }}
      //           >
      //             <BsFillChatLeftDotsFill size="0.9em" />
      //           </CircleIcon>

      //           <MainButton
      //             children={"Email"}
      //             type={MainButtonType.dark}
      //             // borderRadius="24px"
      //             // fontSize="12px"
      //             className="px-3 dNoneSm me-1"
      //             borderRadius="24px"
      //             fontSize="14px"
      //             minHeight="2em"
      //             onClick={() => {
      //               this.setState({
      //                 newEmailMenuVisible: true,
      //               });
      //             }}
      //           ></MainButton>
      //         </div>
      //       </Tippy>
      //         <Visible visible={this.state.allowedToView}>
      //           <>
      //             <MainButton
      //               children={
      //                 this.state.haveDiscussion
      //                   ? "Discussion Panel"
      //                   : "Create Discussion"
      //               }
      //               type={MainButtonType.dark}
      //               borderRadius="24px"
      //               fontSize="14px"
      //               minHeight="2em"
      //               className="px-3 dNoneSm"
      //               onClick={() => {
      //                 if (this.state.haveDiscussion) {
      //                   if (this.state.subTaskId) {
      //                     return this.props.history.push(
      //                       $ReplaceRoute(
      //                         // topicId 1 0 => setting.discussionTopicEnum[0] : 0, for research
      //                         `${AppRoutes.discussion_list}?topicId=3&sectionId=${this.state.data.id}&parentTask=${this.state.taskId}&subTaskId=${this.state.subTaskId}`
      //                       )
      //                     );
      //                   }
      //                   this.props.history.push(
      //                     $ReplaceRoute(
      //                       // topicId 1 0 => setting.discussionTopicEnum[0] : 0, for research
      //                       `${AppRoutes.discussion_list}?topicId=3&sectionId=${this.state.data.id}&parentTask=${this.state.taskId}`
      //                     )
      //                   );
      //                 } else {
      //                   if (this.state.subTaskId) {
      //                     return this.props.history.push(
      //                       $ReplaceRoute(
      //                         // topicId 1 0 => setting.discussionTopicEnum[0] : 0, for research
      //                         `${AppRoutes.discussion_new}?topicId=3&sectionId=${this.state.data.id}&parentTask=${this.state.taskId}&subTaskId=${this.state.subTaskId}`
      //                       )
      //                     );
      //                   }
      //                   this.props.history.push(
      //                     $ReplaceRoute(
      //                       // topicId 1 0 => setting.discussionTopicEnum[0] : 0, for research
      //                       `${AppRoutes.discussion_new}?topicId=3&sectionId=${this.state.data.id}&parentTask=${this.state.taskId}`
      //                     )
      //                   );
      //                 }
      //               }}
      //             ></MainButton>
      //             <CircleIcon
      //               width="1.5em"
      //               height="1.5em"
      //               fontSize="1.08rem"
      //               type={ThemeCircleIcon.dark}
      //               padding="0"
      //               // backgroundColor="#474747"
      //               // fontSize="10px"
      //               // color="#ffff"
      //               className="dNone dFlexSm pointer"
      //               onClick={() => {
      //                 if (this.state.haveDiscussion) {
      //                   if (this.state.subTaskId) {
      //                     return this.props.history.push(
      //                       $ReplaceRoute(
      //                         // topicId 1 0 => setting.discussionTopicEnum[0] : 0, for research
      //                         `${AppRoutes.discussion_list}?topicId=3&sectionId=${this.state.data.id}&parentTask=${this.state.taskId}&subTaskId=${this.state.subTaskId}`
      //                       )
      //                     );
      //                   }
      //                   this.props.history.push(
      //                     $ReplaceRoute(
      //                       // topicId 1 0 => setting.discussionTopicEnum[0] : 0, for research
      //                       `${AppRoutes.discussion_list}?topicId=3&sectionId=${this.state.data.id}&parentTask=${this.state.taskId}`
      //                     )
      //                   );
      //                 } else {
      //                   if (this.state.subTaskId) {
      //                     return this.props.history.push(
      //                       $ReplaceRoute(
      //                         // topicId 1 0 => setting.discussionTopicEnum[0] : 0, for research
      //                         `${AppRoutes.discussion_new}?topicId=3&sectionId=${this.state.data.id}&parentTask=${this.state.taskId}&subTaskId=${this.state.subTaskId}`
      //                       )
      //                     );
      //                   }
      //                   this.props.history.push(
      //                     $ReplaceRoute(
      //                       // topicId 1 0 => setting.discussionTopicEnum[0] : 0, for research
      //                       `${AppRoutes.discussion_new}?topicId=3&sectionId=${this.state.data.id}&parentTask=${this.state.taskId}`
      //                     )
      //                   );
      //                 }
      //               }}
      //             >
      //               {this.state.haveDiscussion ? (
      //                 <GiTalk size="0.9em" />
      //               ) : (
      //                 <BsFillChatLeftDotsFill size="0.9em" />
      //               )}
      //             </CircleIcon>
      //           </>
      //         </Visible>
      //       </div>
      //     </div>
      //     <ProfileTitleDesc
      //       title={this.state.data?.title}
      //       description={this.state.description}
      //       loading={this.state.loading || false}
      //     />
      //     {/* <div className="Studying p-4 my-2 d-flex flex-column align-items-center">
      //       {this.state.loading ? (
      //         <>
      //           <Skeleton height="2.1em" width="10em" className="mt-2" />
      //           <div className="w-100 d-flex justify-content-center">
      //             <Skeleton
      //               containerClassName="lineHeight1 d-flex mt-2 w-100 justify-content-center"
      //               height="0.9em"
      //               width="100%"
      //               style={{ maxWidth: "15em" }}
      //             />
      //           </div>
      //         </>
      //       ) : (
      //         <>
      //           <h3 className="px-5 text-center fs-4">
      //             {this.state.data?.title}
      //           </h3>
      //           <p>{this.state.description}</p>
      //         </>
      //       )}
      //     </div> */}
      //     <div className="row justify-content-center">
      //       <div className="col-md-6  tabel-info ">
      //         <div className="row border-bottom">
      //           <h6 className="col-5 t-title mb-0 border-t-l">
      //             {this.state.subTaskId ? "Subtask" : "Task"}
      //           </h6>

      //           <div className="col-7 t-desc border-t-r">
      //             {this.state.loading ? (
      //               <Skeleton width="6em" height="1.25em" />
      //             ) : (
      //               <Link
      //                 className="linkOnHover"
      //                 to={$ReplaceRoute(
      //                   `${AppRoutes.task_profile.replace(
      //                     ":id",
      //                     this.state.subTaskId
      //                       ? this.state.subTaskId.toString()
      //                       : this.state.taskId.toString() ?? ""
      //                   )}`
      //                 )}
      //                 title={`Go to profile`}
      //               >
      //                 {this.state.subTaskTitle
      //                   ? this.state.subTaskTitle
      //                   : this.state.taskTitle}
      //               </Link>
      //             )}
      //           </div>
      //         </div>
      //         <div className="row border-bottom ">
      //           <h6 className="col-5 t-title mb-0 ">Dataset attached by</h6>
      //           <div className="col-7 t-desc ">
      //             {this.state.loading ? (
      //               <Skeleton width="6em" height="1.25em" />
      //             ) : (
      //               <UserWithAvatar user={this.state.data.creatorUser} />
      //             )}
      //             {/* <Link
      //                 to={
      //                   this.state.data.creatorUserId
      //                     ? $ReplaceRoute(
      //                       `${AppRoutes.member_profile.replace(
      //                         ":id",
      //                         this.state.data.creatorUserId.toString()
      //                       )}`
      //                     )
      //                     : ""
      //                 }
      //                 title={`Go to user profile`}
      //                 className="linkOnHover"
      //               >
      //                 {this.state.data.creatorFirstName +
      //                   " " +
      //                   this.state.data.creatorLastName}
      //               </Link> */}
      //           </div>
      //         </div>
      //         <div className="row border-bottom">
      //           <h6 className="col-5 t-title mb-0">Dataset files</h6>
      //           <div className="col-7 t-desc">
      //             {this.state.loading ? (
      //               <div className="d-flex flex-column align-items-start">
      //                 <Skeleton width="6em" height="1.25em" />
      //                 <Skeleton width="4em" height="1.25em" />
      //                 <Skeleton width="8em" height="1.25em" />
      //               </div>
      //             ) : (
      //               <ul className="file-list d-flex flex-column align-items-start mb-0 w-100">
      //                 {_files.length === 0 && _links.length === 0 ? (
      //                   <EmptyChip />
      //                 ) : (
      //                   <>
      //                     {_files.map((item) => (
      //                       <li
      //                         key={nanoid()}
      //                         className="max1LineText pb-1"
      //                         style={{ maxWidth: "100%" }}
      //                       >
      //                         {/* <img
      //                           src={`/images/icons/${item.inputDataType.isMedia()}`}
      //                           alt=""
      //                           width={20}
      //                           height={20}
      //                         /> */}
      //                         <File
      //                           file={item}
      //                           profileType={ProfileTypes.TASK}
      //                           researchId={
      //                             this.state.subTaskId
      //                               ? this.state.subTaskId
      //                               : this.state.taskId
      //                           }
      //                         />{" "}
      //                         {/* <span
      //                           className="pointer"
      //                           title={`Download ${item.title}`}
      //                           onClick={() => {
      //                             downloadFile(
      //                               AppConstants.base_url_image + `/${item.name}`,
      //                               item.title,
      //                               item.id,
      //                               this.state.data.id,
      //                               ProfileTypes.DATA,
      //                             );
      //                           }}
      //                         >
      //                           {$truncate(item.title, 20)}
      //                         </span> */}
      //                       </li>
      //                     ))}

      //                     {_links.length > 0 ? (
      //                       <li
      //                         className="mt-2 max1LineText"
      //                         style={{ maxWidth: "100%" }}
      //                       >
      //                         Shared Links:
      //                         {_links.map((item) => (
      //                           <div
      //                             key={nanoid()}
      //                             className="pb-1 max1LineText"
      //                             style={{ maxWidth: "100%" }}
      //                           >
      //                             <File file={item} />
      //                             {/* <MainButton
      //                               children={
      //                                 <a
      //                                   href={item.externalUrl}
      //                                   target="_blank"
      //                                   rel="noreferrer"
      //                                 >
      //                                   {$truncate(item.externalUrl, 30)}
      //                                 </a>
      //                               }
      //                               type={MainButtonType.dark}
      //                               borderRadius="24px"
      //                               fontSize="14px"
      //                               backgroundColor="#F5F5F5"
      //                               color="#096BFF"
      //                             ></MainButton> */}
      //                           </div>
      //                         ))}
      //                       </li>
      //                     ) : (
      //                       ""
      //                     )}
      //                   </>
      //                 )}
      //               </ul>
      //             )}
      //           </div>
      //         </div>
      //         <div className="row border-bottom">
      //           <h6 className="col-5 t-title mb-0">Date attached</h6>
      //           <div className="col-7 t-desc">
      //             {moment(this.state.data.createdDate).format(
      //               MOMENT_DATE_FORMAT
      //             )}
      //           </div>
      //         </div>
      //         {/* <div className="row border-bottom">
      //           <h6 className="col-5 t-title mb-0">Data Description</h6>
      //           <div className="col-7 t-desc">{this.state.description}</div>
      //         </div> */}

      //         <div className="row">
      //           <h6 className="col-5 t-title mb-0 border-b-l">
      //             {this.state.subTaskId ? "Subtask" : "Task"} assigned by
      //           </h6>
      //           <div className="col-7 t-desc border-b-r">
      //             {this.state.loading ? (
      //               <Skeleton width="6em" height="1.25em" />
      //             ) : (
      //               <Link
      //                 to={
      //                   this.state.taskCreatorUserId
      //                     ? $ReplaceRoute(
      //                         `${AppRoutes.member_profile.replace(
      //                           ":id",
      //                           this.state.taskCreatorUserId.toString()
      //                         )}`
      //                       )
      //                     : ""
      //                 }
      //                 title={`Go to user profile`}
      //                 className="linkOnHover"
      //               >
      //                 {this.state.taskCreatorFirstName +
      //                   " " +
      //                   this.state.taskCreatorLastName}
      //               </Link>
      //             )}

      //             {/* {this.state.users.join(" - ")} */}
      //           </div>
      //         </div>
      //       </div>
      //       <div className="col-md-6 mt-3 mt-sm-0 colPaddingSm">
      //         <div className="teams teams-light Labs d-flex flex-column align-items-start">
      //           <IconTextRow
      //             theme={Theme.dark}
      //             text="Equipment used for this dataset"
      //             className="mb-3"
      //             children={<FaMicroscope size="2.75em" className="me-2" />}
      //             fontSize="12px"
      //           ></IconTextRow>
      //           <Visible
      //             visible={
      //               !this.state.loading && this.state.equipments.length === 0
      //             }
      //           >
      //             <EmptyChip
      //               className="margin2emAuto"
      //               text="No equipments has been added yet."
      //             />
      //           </Visible>
      //           <Visible visible={this.state.equipments.length > 0}>
      //             <div className="w-100">
      //               <BoxListScroll
      //                 loading={this.state.loading}
      //                 default_photo="/Images/images/equipment_picture.svg"
      //                 items={this.state.equipments}
      //                 TextItem="title"
      //                 ValueItem="id"
      //                 ImageItem="image"
      //                 emptyText="no equipments has been added yet."
      //                 className="pointer"
      //                 onClick={(e, _value) => {
      //                   this.props.history.push(
      //                     $ReplaceRoute(
      //                       `${AppRoutes.equip_profile.replace(
      //                         ":id",
      //                         _value?.toString() ?? ""
      //                       )}`
      //                     )
      //                   );
      //                 }}
      //               ></BoxListScroll>
      //             </div>
      //           </Visible>
      //         </div>
      //         <div className="teams teams-light my-3 ">
      //           <IconTextRow
      //             theme={Theme.dark}
      //             text="Users with access to this dataset"
      //             className="mb-2"
      //             children={<FaUsers size="2.75em" className="me-2" />}
      //             fontSize="12px"
      //           ></IconTextRow>
      //           <BoxListScroll
      //             loading={this.state.loading}
      //             default_photo="/Images/images/img_avatar.png"
      //             items={this.state.users}
      //             TextItem="title"
      //             ValueItem="id"
      //             ImageItem="image"
      //             className="mt-2 pointer"
      //             emptyText="no users has been added yet."
      //             onClick={(e, _value) => {
      //               this.props.history.push(
      //                 $ReplaceRoute(
      //                   `${AppRoutes.member_profile.replace(
      //                     ":id",
      //                     _value?.toString() ?? ""
      //                   )}`
      //                 )
      //               );
      //             }}
      //             // onClick={() => {
      //             //   this.props.history.push(
      //             //     $ReplaceRoute(AppRoutes.member_profile)
      //             //   );
      //             // }}
      //           ></BoxListScroll>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}
export default withRouter(DataPageProfile);
function dispatch(arg0: HeaderTitleAction) {
  throw new Error('Function not implemented.');
}
