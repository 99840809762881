import React from "react";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import { InputComponent, InputType } from "../../components/inputs";
import "react-datepicker/dist/react-datepicker.css";
import { MainButton, MainButtonType } from "../../components/button";
import Dropzone from "react-dropzone";
import { ButtonGroup } from "../../components/botton_group";
import { DiscusstionController } from "../../../controllers/discussion/discusstion_controller";
import { UploadController } from "../../../controllers/upload_media/upload_media";
import SimpleReactValidator from "simple-react-validator";
import { LocalDataSources } from "../../../data/local_datasources";
import { $ReplaceRoute, uploadAllFiles, uploadAllLinks } from "../../../core/utils";
import { RouteComponentProps, withRouter } from "react-router";
import { AppRoutes } from "../../../core/constants";
type StateType = {
  files: Array<File>;
  loading: boolean;
  ExternalUrl: Array<string>;
  External: string;
  massage: string;
  broadCastTypes: number;
  selectedBroadCastTypes: Array<number>;
  listbroadCast: Array<{ label: string; value: number }>;
  subject: string;
};

class AdminBroadcast extends React.Component<RouteComponentProps> {
  controller = new DiscusstionController();
  UploadController = new UploadController();
  validator = new SimpleReactValidator({
    className: "text-danger",
  });
  local: LocalDataSources = new LocalDataSources();
  componentDidMount() {
    this.setState({
      listbroadCast: this.local.getSetting().broadCastTypeEnum.map((item) => {
        return { label: item.title, value: item.id };
      }),
    });
  }
  state: StateType = {
    files: [],
    loading: false,
    ExternalUrl: [],
    External: "",
    massage: "",
    broadCastTypes: 0,
    selectedBroadCastTypes: [],
    listbroadCast: [],
    subject: "",
  };
  onDrop = (fileList: any) => {
    fileList.map((item: any, index: number) => {
      const found = this.state.files.find(
        (element: any) => element.name === item.name
      );
      if (found === undefined) {
        this.setState({ files: [fileList[index], ...this.state.files] });
      }
    });
  };
  handelDeleteFile(arg: File) {
    this.setState({
      files: this.state.files.filter((file) => file.name !== arg.name),
    });
  }
  handleChange(target: string, val: any) {
    this.setState({
      [target]: val,
    });
  }
  addExternalUrl() {
    if (this.validator.fields.input) {
      let Url = [...this.state.ExternalUrl];
      if (this.state.External.length > 2) {
        Url.push(this.state.External);
      }
      this.setState({
        ExternalUrl: Url,
        External: "",
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  handelDeleteExternalLink(link: string) {
    this.setState({
      ExternalUrl: this.state.ExternalUrl.filter((item) => item !== link),
    });
  }
  SendMassage() {
    if (this.validator.allValid()) {
      const body = {
        subject: this.state.subject,
        broadCastTypes: this.state.selectedBroadCastTypes,
        message: this.state.massage,
      };

      this.setState({
        loading: true,
      });
      this.controller.createBroadCast(
        body,
        async (res) => {
          if (this.state.files.length || this.state.ExternalUrl.length) {
            const _firstMsgId = res.histories[0]?.id;

            await this.handelUpload(_firstMsgId);
          }

          this.setState({
            loading: false,
            ExternalUrl: [],
            External: "",
            massage: "",
            subject: "",
            files: [],
          });
          // this.props.history.push($ReplaceRoute(AppRoutes.admin_tickets));
          this.props.history.push(
            $ReplaceRoute(
              `${AppRoutes.admin_ticket.replace(
                ":id",
                res.id.toString() ?? ""
              )}`
            )
          );
        },
        (err) => {
          this.setState({
            loading: false,
            ExternalUrl: [],
            External: "",
            massage: "",
            subject: "",
            files: [],
          });
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  async handelUpload(id: number) {
    try {
      await uploadAllLinks(id, this.state.ExternalUrl, 7);
      await uploadAllFiles(id, this.state.files, 7);
    } catch (e) {
      this.setState({
        loading: false,
      });
    }

    // return new Promise((resolve, reject) => {
    //   const formData = new FormData();
    //   for (let i = 0; i < this.state.files.length; i++) {
    //     const file = this.state.files[i];
    //     formData.append("Files", file);
    //   }
    //   for (let i = 0; i < this.state.ExternalUrl.length; i++) {
    //     const file = this.state.ExternalUrl[i];
    //     formData.append("ExternalUrls", file);
    //   }
    //   formData.append("UseCase", "7");
    //   formData.append("SectionId", id.toString());

    //   this.UploadController.UloadMedia(
    //     formData,
    //     (res) => {
    //       resolve(res);
    //     },
    //     () => {
    //       this.setState({
    //         loading: false,
    //       });
    //       reject();
    //     }
    //   );
    // });
  }
  render() {
    const files = this.state.files.map((file: any) => (
      <li key={file.name}>
        <span className="text-truncate col-10 mx-2">
          {file.name} - {file.size} bytes
        </span>
        <CircleIcon
          type={ThemeCircleIcon.dark}
          width="22px"
          padding="0"
          height="22px"
          onClick={() => {
            this.handelDeleteFile(file);
          }}
        >
          <img
            src="/images/icons/garbage_can.svg"
            alt="radvix"
            width={15}
            height={15}
          />
        </CircleIcon>
      </li>
    ));
    return (
      <div className="container-fluid research new-research">
        <div className="row"></div>
        <div className="col-12 box-content p-3">
          <h5 className="b-title d-flex align-items-center">
            <span
              onClick={() => {
                window.history.back();
              }}
              className="backPage"
            ></span>{" "}
            New Broadcast
          </h5>
          <div className="form row">
            <div className="col-md-4 left">
              <div className="item">
                <InputComponent
                  value={this.state.subject}
                  type={InputType.text}
                  label="Subject:"
                  onChange={(e) => {
                    this.handleChange("subject", e.target.value);
                  }}
                  inValid={this.validator.message(
                    "subject",
                    this.state.massage,
                    "required"
                  )}
                ></InputComponent>
              </div>
              <div className="item">
                <span className="label d-flex align-items-center">
                  Attachment:
                  <MainButton
                    type={MainButtonType.light}
                    children={"optional"}
                    borderRadius="50px"
                    fontSize="15px"
                    className="mx-2"
                  ></MainButton>
                </span>
                <Dropzone onDrop={this.onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="container fileUploadBox">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <MainButton
                          type={MainButtonType.light}
                          minHeight="30px"
                          minWidth="179px"
                          fontSize="14px"
                          borderRadius="50px"
                          backgroundColor="#fff"
                          border="1px solid #707070"
                          color="#707070"
                          className="mt-4"
                          children={
                            <div className="d-flex justify-content-between align-items-center">
                              <img
                                src="/Images/icons/cloud_computing.svg"
                                alt="sssss"
                                height="20"
                              />{" "}
                              <span className="flex-fill">
                                Browse Local Files
                              </span>
                            </div>
                          }
                        ></MainButton>
                        <p>Or drag and drop files here</p>
                      </div>
                      <aside>
                        <ul>{files}</ul>
                      </aside>
                    </section>
                  )}
                </Dropzone>
              </div>
              <div className="item d-flex justify-content-between align-items-center">
                <InputComponent
                  type={InputType.text}
                  placeholder="https://"
                  className="mx-2"
                  onChange={(e) => {
                    this.handleChange("External", e.target.value);
                  }}
                  onEnter={(e) => {
                    this.addExternalUrl();
                  }}
                  value={this.state.External}
                ></InputComponent>
                <CircleIcon
                  width="36px"
                  height="36px"
                  padding="0"
                  type={ThemeCircleIcon.dark}
                  backgroundColor="#9D9D9D"
                  fontSize="18px"
                  color="#ffffff"
                  className="px-3"
                  onClick={() => {
                    this.addExternalUrl();
                  }}
                >
                  <i className="fas fa-plus"></i>
                </CircleIcon>
              </div>
              <p className="text-error mb-0">
                {this.validator.message("input", this.state.External, "url")}
              </p>
              <ul className="file-list mt-1">
                {this.state.ExternalUrl.map((item) => (
                  <li className="my-1 d-flex align-items-center max1LineText">
                    <a title="Go to the link" className="max1LineText s-chip outlined fs-200 blueColor" href={item} target="_blank" rel="noopener noreferrer">
                      {item}
                    </a>
                    <CircleIcon
                      type={ThemeCircleIcon.dark}
                      padding="0"
                      width="22px"
                      height="22px"
                      className="ms-2 pointer"
                      onClick={() => this.handelDeleteExternalLink(item)}
                    >
                      <img
                        src="/images/icons/garbage_can.svg"
                        alt="radvix"
                        width={15}
                        height={15}
                      />
                    </CircleIcon>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-md-8 right">
              <div className="item">
                <InputComponent
                  value={this.state.massage}
                  type={InputType.textarea}
                  label="Message:"
                  className="mt-2"
                  rows={7}
                  onChange={(e) => {
                    this.handleChange("massage", e.target.value);
                  }}
                  inValid={this.validator.message(
                    "Message",
                    this.state.massage,
                    "required"
                  )}
                ></InputComponent>
              </div>
              <div className="item my-4">
                <ButtonGroup
                  name="SendTo"
                  label="Send To:"
                  items={this.state.listbroadCast}
                  TextItem="label"
                  ValueItem="value"
                  inputType={"checkbox"}
                  selected={this.state.selectedBroadCastTypes}
                  onChange={(e) => {
                    // is in selectedBroadCastTypes remove it else add it
                    const _val = parseInt(e.target.value);
                    let _selectedBroadCastTypes =
                      this.state.selectedBroadCastTypes;
                    if (_selectedBroadCastTypes.includes(_val)) {
                      _selectedBroadCastTypes = _selectedBroadCastTypes.filter(
                        (item) => item !== _val
                      );
                    } else {
                      _selectedBroadCastTypes.push(_val);
                    }
                    this.setState({
                      selectedBroadCastTypes: _selectedBroadCastTypes,
                    });

                    // this.handleChange(
                    //   "broadCastTypes",
                    //   parseInt(e.target.value)
                    // );
                  }}
                ></ButtonGroup>
              </div>
              <div className="item">
                <div className="d-flex justify-content-center align-items-center my-4">
                  {/* <MainButton
                    type={MainButtonType.light}
                    children={"Start Over"}
                    borderRadius="50px"
                    fontSize="20px"
                    className="mx-2"
                    minHeight="47px"
                    minWidth="110px"
                  ></MainButton> */}
                  <MainButton
                    type={MainButtonType.dark}
                    children={"Start"}
                    borderRadius="50px"
                    fontSize="20px"
                    className="mx-2"
                    minHeight="47px"
                    minWidth="110px"
                    onClick={() => {
                      this.SendMassage();
                    }}
                  ></MainButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AdminBroadcast);
