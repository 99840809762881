// CategoryManagementModalProps

import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { CreateNewCategoryResult } from "../../../../data/models/responses/category/create_new_category";
import { MainButton, MainButtonType } from "../../../components/button";
import { CircleIcon, ThemeCircleIcon } from "../../../components/circle_icon";
import { InputComponent, InputType } from "../../../components/inputs";

interface CategoryItem {
  id: number;
  title: string;
  value?: string;
  allowToEdit?: boolean;
}
interface CategoryType {
  category: CategoryItem;
  handleEditCategory: (
    categoryName: string,
    categoryId: number
  ) => Promise<unknown>;
  handleDeleteCategory: (categoryId: number) => Promise<unknown>;
}
interface CategoryManagementModalType {
  categories?: CategoryItem[];
  label?: string;
  onCreateNewCategory: (
    categoryName: string
  ) => Promise<CreateNewCategoryResult>;
  onEditCategory: (
    categoryName: string,
    categoryId: number
  ) => Promise<CreateNewCategoryResult>;
  onDeleteCategory: (categoryId: number) => Promise<CreateNewCategoryResult>;
}
const CategoryManagementModal: React.FC<CategoryManagementModalType> = ({
  categories,
  onCreateNewCategory,
  onEditCategory,
  onDeleteCategory,
  label = "Laboratory Category Labels"
}) => {
  const [newCategoryName, setNewCategoryName] = useState("");
  const handleCreateNewCategory = () => {
    onCreateNewCategory(newCategoryName)
      .then(() => {
        setNewCategoryName("");
      })
      .catch(() => { });
  };

  const handleEditCategory = (_editedName: string, _categoryId: number) => {
    return new Promise((resolve, reject) => {
      onEditCategory(_editedName, _categoryId)
        .then(resolve)
        .catch(() => { });
    });
  };

  const handleDeleteCategory = (_categoryId: number) => {
    return new Promise((resolve, reject) => {
      onDeleteCategory(_categoryId)
        .then(resolve)
        .catch(() => { });
    });
  };
  return (
    <div className="modal-dialog" style={{ maxWidth: "600px" }}>
      <div className="modal-content bg-light">
        <div className="modal-header">
          <h5 className="mb-0">{label}</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="close_modal_Type"
          ></button>
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center justify-content-between w-100 px-1">
            <small>Category Name</small>
            <small>Rename</small>
          </div>
          <div className="d-flex flex-column modal-category-wrapper mt-1">
            {categories && categories.length > 0 ? (
              categories.map((_category: CategoryItem) => {
                return (
                  <Category
                    category={_category}
                    key={_category.id}
                    handleEditCategory={handleEditCategory}
                    handleDeleteCategory={handleDeleteCategory}
                  />
                );
              })
            ) : (
              <p className="p-3 text-center mb-0">No category found</p>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <div className="d-flex align-items-center">
            <small style={{ whiteSpace: "nowrap" }}>New Category</small>
            <InputComponent
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              className="mx-2"
              fontSize="14px"
              type={InputType.text}
              placeholder="Type a category name..."
              onEnter={handleCreateNewCategory}
            // label="Description:"
            // popQuestion="Description:"
            // optional="optional"
            // onChange={(e) => {
            //   this.handleChange("description", e.target.value);
            // }}
            ></InputComponent>
            <MainButton
              disabled={newCategoryName.length === 0}
              type={MainButtonType.dark}
              children={"Add"}
              onClick={handleCreateNewCategory}
              borderRadius="50px"
              fontSize="18px"
              className="mx-2"
              minHeight="34px"
              minWidth="72px"
            // loading={this.state.loading}
            ></MainButton>
          </div>
        </div>
      </div>
    </div>
  );
};
const Category: React.FC<CategoryType> = ({
  category,
  handleEditCategory,
  // handleDeleteCategory,
}): JSX.Element => {
  const { title, id, allowToEdit } = category;
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingLoader, setEditingLoader] = useState(false);
  const [editableTitle, setEditableTitle] = useState("");

  useEffect(() => {
    setEditableTitle(title);
  }, [title]);

  const handleEdit = () => {
    setEditingLoader(true)
    handleEditCategory(editableTitle, id).then(() => {
      setIsEditMode(false);
      setEditingLoader(false)
    }).catch(er => {
      setEditingLoader(false)
    });
  };
  // const handleDelete = () => {
  //   handleDeleteCategory(id).then(() => { });
  // };
  return (
    <div className="d-flex align-items-center justify-content-between w-100 modal-category-item">
      {isEditMode ? (
        <InputComponent
          value={editableTitle}
          onChange={(e) => setEditableTitle(e.target.value)}
          id={`category_name_${id}`}
          width="18em"
          className="ms-1 edit-cat-name-input"
          fontSize="13px"
          type={InputType.text}
          placeholder="Type a category name..."
          autoFocus
        // onBlur={(e) => {
        //   e.preventDefault();
        //   setIsEditMode(false);
        // }}
        />
      ) : (
        <h6
          onClick={(e) => {
            if (!allowToEdit) return;
            e.stopPropagation();
            setIsEditMode(true);
          }}
          className="mb-1 ms-2 mt-1"
        >
          {title}
        </h6>
      )}
      <div className="d-flex align-items-center">
        {allowToEdit ? (
          <>
            {isEditMode ? (
              <CircleIcon
                width="22px"
                height="22px"
                type={ThemeCircleIcon.light}
                backgroundColor="#474747"
                fontSize="10px"
                padding="0"
                color="#ffff"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditMode(false);
                }}
                className="ms-auto d-flex justify-content-center align-items-center pointer me-2 p-0"
              >
                <MdClose color="#000" size="1.5em" />
              </CircleIcon>
            ) : (
              <CircleIcon
                width="22px"
                height="22px"
                type={ThemeCircleIcon.dark}
                backgroundColor="#474747"
                fontSize="10px"
                padding="0"
                color="#ffff"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditMode(true);
                }}
                className="ms-auto d-flex justify-content-center align-items-center pointer me-2"
              >
                <img
                  // data-bs-toggle="modal"
                  // data-bs-target="#modal-add-category"
                  src="/images/icons/edit.svg"
                  alt="radvix"
                // onClick={(e) => {
                //   e.stopPropagation();
                //   setIsEditMode(true);
                // }}
                />
              </CircleIcon>
            )}

            {/* <button onClick={handleDelete}>Remove</button> */}
            {isEditMode && (
              <MainButton
                children="Save"
                type={MainButtonType.dark}
                borderRadius="24px"
                fontSize="12px"
                className="px-3"
                onClick={handleEdit}
                disabled={editingLoader}
              ></MainButton>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};
export default CategoryManagementModal;
