import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import {
  createMemberTransfer,
  getSearchMemberTransfer,
  getTransfrableMembers,
  User,
} from "../../../../controllers/member/member_transfer_controller";
import SpinnerLoader from "../../../components/spinnerLoader";
import UserWithAvatar from "../../../components/UserWithAvatar";
import { Checkbox } from "../../setting/component/new_notification_tbl";
import { MainButton, MainButtonType } from "../../../components/button";
import { $ReplaceRoute } from "../../../../core/utils";
import { AppRoutes } from "../../../../core/constants";
import { ButtonGroup } from "../../../components/botton_group";

const CreateMemberTransfer = () => {
  const history = useHistory();
  const [transferDate, setTransferDate] = useState<Date>(new Date());
  const [transfrableUserList, setTransfrableUserList] = useState<User[]>([]);
  const [availableUserToTransferList, setAvailableUserToTransferList] =
    useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [targetUser, setTargetUser] = useState<User | undefined>(undefined);
  const [gettingUserListLoader, setGettingUserListLoader] = useState(false);
  const [creatingTransferLoader, setCreatingTransferLoader] = useState(false);
  const [isTransferImmediate, setIsTransferImmediate] = useState(false);

  useEffect(() => {
    document.title = `New Transfer | Radvix`
    
    handleGetAvailableUserToTransfer();
  }, []);

  async function handleGetAvailableUserToTransfer() {
    try {
      setGettingUserListLoader(true);
      const _res = await getTransfrableMembers();
      setTransfrableUserList(_res);
      setGettingUserListLoader(false);
    } catch (e) {
      setGettingUserListLoader(false);
    }
  }

  async function handleSelectUser(_user: User) {
    try {
      setGettingUserListLoader(true);
      const _res = await getSearchMemberTransfer(_user.id);
      setAvailableUserToTransferList(_res);
      setSelectedUser(_user);
      setTargetUser(undefined);
      setGettingUserListLoader(false);
    } catch (e) {
      setGettingUserListLoader(false);
    }
  }

  async function handleCreateTransfer() {
    try {
      if (!selectedUser || !targetUser) return;
      setCreatingTransferLoader(true);
      const _res = await createMemberTransfer({
        isTransferImmediate,
        transferDate: isTransferImmediate ? null : transferDate,
        userToTransfer: selectedUser?.id,
        userTransferTo: targetUser?.id,
      });
      setCreatingTransferLoader(false);

      if (_res) history.push($ReplaceRoute(AppRoutes.member_transfer));
    } catch (e) {
      setCreatingTransferLoader(false);
    }
  }
  return (
    <div className="container-fluid research new-research">
      <div className="row"></div>
      <div className="col-12 box-content p-3">
        <h5 className="b-title d-flex align-items-center">
          <IoIosArrowDropleftCircle
            className="me-3 textSecondary2 cursorPointer"
            size="1.5em"
            onClick={() => {
              window.history.back();
            }}
          />{" "}
          Request a user transfer
        </h5>
        <div className="form row">
          <div className="col-md-6 left beforeNoneMd">
            <UserList
              userLoading
              label="Transfer all data from"
              list={transfrableUserList}
              selectedUser={selectedUser?.id}
              onSelectUser={handleSelectUser}
              loading={gettingUserListLoader}
            />
          </div>
          <div className="col-md-6 right">
            <UserList
              emptyText="Please select a user to transfer from first"
              label="And assign them to"
              list={availableUserToTransferList}
              selectedUser={targetUser?.id}
              onSelectUser={(_user) => {
                setTargetUser(_user);
              }}
              loading={gettingUserListLoader}
            />
          </div>
        </div>
        <div className="row">
          {selectedUser ? (
            <div className="col-md-6 mt-3">
              <span className="textSeondary fs-100">Transfer from user:</span>
              <UserWithAvatar user={selectedUser} />
            </div>
          ) : (
            ""
          )}
          {targetUser ? (
            <div className="col-md-6 mt-3">
              <span className="textSeondary fs-100 mb-1 mt-3">To user:</span>
              <UserWithAvatar user={targetUser} />
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className="mt-4"

        >
          <ButtonGroup
            parentStyles={{
              width: "16em",
              maxWidth: "100%",

            }}
            label="Should this transfer be applied immediately after both users have approved?"
            name="immediate"
            items={[
              {
                id: 1,
                name: "Yes",
              },
              {
                id: 0,
                name: "No",
              },
            ]}
            TextItem="name"
            ValueItem="id"
            selected={isTransferImmediate ? 1 : 0}
            onChange={(e) => {
              setIsTransferImmediate(
                parseInt(e.target.value) === 1 ? true : false
              );
            }}
          ></ButtonGroup>
        </div>
        {isTransferImmediate ? null : (
          <div className="w-100 mt-4">
            <span className="label">Please select a date that you would like to transfer be applied on</span>

            <div
              style={{
                width: "16em",
                maxWidth: "100%",
              }}
            >
              <DatePicker
                selected={transferDate}
                onChange={(e) => {
                  setTransferDate(e as Date);
                }}
                minDate={new Date()}
              />
            </div>
          </div>
        )}

        <div className="col-12 d-flex justify-content-center align-items-center my-4">
          <MainButton
            type={MainButtonType.dark}
            children={"Request transfer"}
            onClick={handleCreateTransfer}
            disabled={creatingTransferLoader || gettingUserListLoader}
            borderRadius="50px"
            fontSize="18px"
            className="mx-2 px-3"
            minHeight="43px"
            // minWidth="136px"
            loading={creatingTransferLoader}
          ></MainButton>
        </div>
      </div>
    </div>
  );
};

const UserList = ({
  list,
  selectedUser,
  onSelectUser,
  label,
  loading,
  userLoading,
  emptyText = "No users found"
}: {
  list: User[];
  selectedUser: string | undefined;
  onSelectUser: (_user: User) => void;
  label: string;
  emptyText?: string;
  loading: boolean;
  userLoading?: boolean;
}) => {
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
    undefined
  );
  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center justify-content-between w-100">
        <span className="textSecondary fs-200">{label}</span>

        {loading ? <SpinnerLoader size="1em" /> : ""}
      </div>
      <div
        className="d-flex flex-column mt-1 justify-content-start"
        style={{
          borderRadius: "0.5em",
          padding: "0.4em 0.25em",
          backgroundColor: "#e1e1e1",
          minHeight: "18em",
          maxHeight: "32em",
          overflowY: "auto",
          position: "relative",
        }}
      >
        {/* {true && <div className='d-flex align-items-center justify-content-center' style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(0,0,0,0.24)',
                    zIndex: 22,
                    height: '100%'
                }}>
                    <SpinnerLoader />
                </div>} */}
        {list.length === 0 ? (
          <div
            style={{
              height: "100%",
            }}
            className="d-flex align-items-center justify-content-center"
          >
            {emptyText}
          </div>
        ) : (
          list.map((_user) => {
            const _isSelected = selectedUser === _user.id;
            return (
              <div
                className="d-flex align-items-center p-1 px-2"
                style={{
                  borderRadius: "0.4em",
                  backgroundColor: _isSelected
                    ? "rgba(0,0,0,0.16)"
                    : "transparent",
                }}
              >
                {userLoading && loading && _user.id === selectedUserId ? (
                  <div className="me-2 d-flex align-items-center justify-content-center">
                    <SpinnerLoader size="1.3em" />
                  </div>
                ) : (
                  <Checkbox
                    checked={_isSelected}
                    onChange={() => {
                      setSelectedUserId(_user.id);
                      onSelectUser(_user);
                    }}
                    inputClassName="me-2"
                  />
                )}
                <div className="max1LineText">
                  <UserWithAvatar user={_user} />
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
export default CreateMemberTransfer;
