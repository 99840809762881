import React, { useState, useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import './style.css';
import { BaseButton } from '@fluentui/react';
import { MainButton } from '../button';
import Select from 'react-select';
import { ResearchController } from '../../../controllers/research/research_controller';
import { group } from 'console';
import { Checkbox } from '../../pages/setting/component/new_notification_tbl';

interface ExportModalProps {
  isOpen: boolean;
  onClose: () => void;
  groups: any[];
  projects: any[];
}

const ExportModal: React.FC<ExportModalProps> = ({ isOpen, onClose, groups, projects }) => {
  const [projectGroup, setProjectGroup] = useState<{ value: string; label: string } | null>(null);
  const [selectedProject, setSelectedProject] = useState<{ value: string; label: string } | null>(null);
  const [groupOptions, setGroupOptions] = useState<{ value: string; label: string }[]>([]);
  const [projectOptions, setProjectOptions] = useState<{ value: string; label: string }[]>([]);
  const [allprojectOptions, setallProjectOptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const controller = new ResearchController();

  const [exportOptions, setExportOptions] = useState({
    exportNotes: false,
    exportTasks: false,
    exportSubtasks: false,
    exportDates: false,
    exportStatus: false,
    exportPriority: false,
  });

  useEffect(() => {
    ;
    // Load group options
    
    const newProjectGroupsOptions = groups?.map(research => ({
      value: research.id,
      label: research.title,
    }));
    setGroupOptions([...newProjectGroupsOptions,...[{label: "Ungroup", value: "0"}]]);
    const allResearches = groups.flatMap(group => group.researchesList || []);
    const allProjects = [...projects,...allResearches]
    // Load all researches by default    
    const newProjectOptions = allProjects?.map(research => ({
      value: research.id,
      label: research.title,
    }));

    setallProjectOptions(allProjects);
  }, [groups]);

  const handleGroupChange = (selectedGroup: { value: string; label: string } | null) => {
    setProjectGroup(selectedGroup);

    if (selectedGroup) {
      if(selectedGroup?.value == "0"){
        const selectedGroupData = allprojectOptions.filter((group: any) => group.projectGroupId === 0 || group.projectGroupId === null);
        if (selectedGroupData && selectedGroupData.length > 0) {
          const newProjectOptions = selectedGroupData.map((research: any) => ({
            value: research.id,
            label: research.title,
          }));
          setProjectOptions([...[{label: "All projects", value: "0"}],...newProjectOptions]);
        } else {
          setProjectOptions([]);
        }
      }
      else{
        const selectedGroupData = allprojectOptions.filter((group: any) => group.projectGroupId === selectedGroup.value);
        if (selectedGroupData && selectedGroupData.length > 0) {
          const newProjectOptions = selectedGroupData.map((research: any) => ({
            value: research.id,
            label: research.title,
          }));
          setProjectOptions([...[{label: "All projects", value: "0"}],...newProjectOptions]);
        } else {
          setProjectOptions([]);
        }
      }
      
    } else {
      // Reset to all researches if no group is selected
      
      // const newProjectOptions = projects.map(research => ({
      //   value: research.id,
      //   label: research.title,
      // }));
       setProjectOptions([]);
    }

    setSelectedProject(null);
  };

  const handleExport = () => {
    const selectedGroupId = projectGroup ? projectGroup.value : null;
    const selectedProjectId = selectedProject ? selectedProject.value : null;

    console.log('Exporting with options:', {
      selectedGroupId,
      selectedProjectId,
      ...exportOptions,
    });

    const body = {
      selectedGroupId,
      selectedProjectId,
      exportProjectNotes: exportOptions.exportNotes,
      exportTask: exportOptions.exportTasks,
      exportSubtask: exportOptions.exportSubtasks,
      exportStatus: exportOptions.exportStatus,
      exportPriority: exportOptions.exportPriority,
      exportStartEnd: exportOptions.exportDates,
    };
    
    setLoading(true);

    controller.exportData(
      body,
      async (res) => {
        const base64Data = res.file;
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'exported_data.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);

        console.log('Export successful:', res);
        setLoading(false);
        setProjectGroup(null);
        setSelectedProject(null);
        setProjectOptions([]);
        setExportOptions({
          exportNotes: false,
          exportTasks: false,
          exportSubtasks: false,
          exportDates: false,
          exportStatus: false,
          exportPriority: false,
        });
        onClose();
      },
      (err) => {
        console.error('Export failed:', err);
        setLoading(false);
      }
    );

    
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <div
            className="d-flex justify-content-between align-items-center row-gap-3 flex-wrap column-gap-3 border-bottom border-gray-400"
            style={{ paddingBottom: '10px' }}
          >
            <div className="d-flex align-items-center">
              <BaseButton
                className="btn btn-outline-primary fs-15 py-px-4 me-px-12 px-3 d-sm-block d-none back-button"
                style={{ fontWeight: '400', height: '19px' }}
                onClick={onClose}
              >
                Close
              </BaseButton>
              <h2 className="fw-medium fs-15 mb-0 color-gray-1000 modalHeader">
                Export Data
              </h2>
            </div>
          </div>
          <div className="modal-body" style={{ paddingTop: '15px' }}>
            <div className="form-group">
              <label className="color-gray-600 fs-15 me-2">Project Group</label>
              <Select
                value={projectGroup}
                onChange={handleGroupChange}
                options={groupOptions}
                isClearable
              />
            </div>
            <div className="form-group">
              <label className="color-gray-600 fs-15 me-2">Project Selector</label>
              <Select
                value={selectedProject}
                onChange={(e) => { setSelectedProject(e)}}
                options={projectOptions}
                isMulti={false}
                closeMenuOnSelect={true}
              />
            </div>
            <div className="form-group">
              <label className="color-gray-600 fs-15 me-2">Export Options</label>
              <div className="checkbox-wrapper">
                {Object.keys(exportOptions).map((optionKey) => (
                  <div key={optionKey}>
                    <Checkbox
                    
                    checked={exportOptions[optionKey as keyof typeof exportOptions]}
                    onChange={(e) => setExportOptions({
                      ...exportOptions,
                      [optionKey]: e.target.checked,
                    })}
                  />
                   
                    <label htmlFor={optionKey} className="ms-2">
                      {optionKey
                        .replace(/([A-Z])/g, ' $1')
                        .replace(/^./, str => str.toUpperCase())}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', marginTop: 25, justifyContent: 'flex-start' }}>
            <MainButton
              className="btn btn-primary resrvationButton"
              onClick={handleExport}
              disabled={loading}
            >
              {loading ? 'Exporting...' : 'Export'}
            </MainButton>
          </div>
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close" onClick={onClose}>
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ExportModal;
