import { handleUnauthorizedRequest, HTTP } from "../../../core/http_common";
import { CreateLearningReq, CreateSubLearningReq } from "../../models/requests/learningSection/create_learning_req";
import {
    UpdatelearningReq, UpdateSublearningReq
} from "../../models/requests/learningSection/update_learning_req";
import { CreatelearningRes, CreateSublearningRes, uploadlearningRes } from "../../models/responses/learning/create_learning_res";
import { SearchLearningRes, SearchSubLearningRes } from "../../models/responses/learning/search_learning_res";
import { UpdatelearningrRes, UpdateSublearningRes } from "../../models/responses/learning/update_learning_res";
import { HelpLearningDetails, HelpLearningDetailsRes, HelpLearningSectionDetails } from "../../models/responses/learning/help_learningSection_res";

export class RemoteMember {
    createLearningSection(
        body: CreateSubLearningReq,
        action: (res: CreateSublearningRes) => any,
        error: (res: any) => any
    ) {
        return HTTP.post("/LearningSection/CreateSectionDetails", body)
            .then((res) => action(res.data))
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    // Retry the request with refreshed token
                    return handleUnauthorizedRequest(err.config)
                        .then((_res) => {
                            action(_res?.data);
                        })
                        .catch((e) => error(e));
                } else {
                    error(err);
                }
            });
    }
    updateLearningSection(
        body: UpdateSublearningReq,
        action: (res: UpdateSublearningRes) => any,
        error: (res: any) => any
    ) {
        return HTTP.put("/LearningSection", body)
            .then((res) => action(res.data))
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    // Retry the request with refreshed token
                    return handleUnauthorizedRequest(err.config)
                        .then((_res) => {
                            action(_res?.data);
                        })
                        .catch((e) => error(e));
                } else {
                    error(err);
                }
            });
    }
    SearchLearningSection(
        searchquery: string,
        action: (res: SearchLearningRes) => any,
        error: (res: any) => any
    ) {
        return HTTP.get(`/LearningSection/GetSections${searchquery ? `?searchQuery=${searchquery}` : ""}`)
            .then((res) => action(res.data))
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    // Retry the request with refreshed token
                    return handleUnauthorizedRequest(err.config)
                        .then((_res) => {
                            action(_res?.data);
                        })
                        .catch((e) => error(e));
                } else {
                    error(err);
                }
            });
    }
    DeleteLearningSection(
        sectionIdToDelete: number,
        success: () => any,
        error: (res: any) => any
    ) {
        return HTTP.delete(`/LearningSection?learningSectionId=${sectionIdToDelete}`)
            .then(() => {
                // Make sure to only call the success callback if the deletion was successful
                success();
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    // Handle unauthorized request
                } else {
                    error(err);
                }
            });
    }
    updateSubLearningSection(
        body: UpdateSublearningReq,
        action: (res: UpdateSublearningRes) => any,
        error: (res: any) => any
    ) {
        return HTTP.post("/LearningSection/UpdateSectionDetails", body)
            .then((res) => action(res.data))
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    // Retry the request with refreshed token
                    return handleUnauthorizedRequest(err.config)
                        .then((_res) => {
                            action(_res?.data);
                        })
                        .catch((e) => error(e));
                } else {
                    error(err);
                }
            });
    }
createSubLearningSection(
        body: CreateSubLearningReq,
        action: (res: CreateSublearningRes) => any,
        error: (res: any) => any
    ) {
        return HTTP.post("/LearningSection/CreateSectionDetails", body)
            .then((res) => action(res.data))
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    // Retry the request with refreshed token
                    return handleUnauthorizedRequest(err.config)
                        .then((_res) => {
                            action(_res?.data);
                        })
                        .catch((e) => error(e));
                } else {
                    error(err);
                }
            });
    }
    SearchSubLearningSection(
        body: { searchquery: string },
        action: (res: SearchSubLearningRes) => any,
        error: (res: any) => any
    ) {
        return HTTP.get(`/LearningSection/GetSectionDetailsList?learningDetailsSectionId=${body.searchquery ? `&searchQuery=${body.searchquery}` : ""}`)
            .then((res) => action(res.data))
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    // Retry the request with refreshed token
                    return handleUnauthorizedRequest(err.config)
                        .then((_res) => {
                            action(_res?.data);
                        })
                        .catch((e) => error(e));
                } else {
                    error(err);
                }
            });
    }
    DeleteLearningSubSection(
        sectionIdToDelete: number,
        success: () => any,
        error: (res: any) => any
    ) {
        return HTTP.delete(`/LearningSection/DeleteSectionsDetails?learningSectionId=${sectionIdToDelete}`)
            .then(() => {
                // Make sure to only call the success callback if the deletion was successful
                success();
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    // Handle unauthorized request
                } else {
                    error(err);
                }
            });
    }
    getLearningSectionList(
        searchQuery: string,
        action: (res: HelpLearningDetailsRes) => any,
        error: (res: any) => any
    ) {
        return HTTP.get(`/LearningSection/GetHelpSectionDetails${searchQuery ? `?searchQuery=${searchQuery}` : ""}`)
            .then((res) => action(res.data))
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    // Retry the request with refreshed token
                    return handleUnauthorizedRequest(err.config)
                        .then((_res) => {
                            action(_res?.data);
                        })
                        .catch((e) => error(e));
                } else {
                    error(err);
                }
            });
    }

    uploadLearningSection(
        body: HelpLearningSectionDetails[],
        action: (res: uploadlearningRes) => any,
        error: (res: any) => any
    ) {
        return HTTP.post("/LearningSection/UploadSectionDetails", body)
            .then((res) => action(res.data))
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    // Retry the request with refreshed token
                    return handleUnauthorizedRequest(err.config)
                        .then((_res) => {
                            action(_res?.data);
                        })
                        .catch((e) => error(e));
                } else {
                    error(err);
                }
            });
    }
    // getMemberList(
    //     body: { PageNumber: number; PageSize: number; SearchParameter: string },
    //     action: (res: MemberListRes) => any,
    //     error: (res: any) => any
    // ) {
    //     return HTTP.get(
    //         `/Team/MemberList?PageSize=${body.PageSize}&PageNumber=${body.PageNumber}&SearchParameter=${body.SearchParameter}`
    //     )
    //         .then((res) => action(res.data))
    //         .catch((err) => {
    //             if (err.response && err.response.status === 401) {
    //                 // Retry the request with refreshed token
    //                 return handleUnauthorizedRequest(err.config)
    //                     .then((_res) => {
    //                         action(_res?.data);
    //                     })
    //                     .catch((e) => error(e));
    //             } else {
    //                 error(err);
    //             }
    //         });
    // }
    // getMemberById(
    //     body: { userId: string; token: string },
    //     action: (res: GetMemberByIDRes) => any,
    //     error: (res: any) => any
    // ) {
    //     let config = {
    //         headers: {
    //             authorization: `Bearer ${body.token}`,
    //         },
    //     };
    //     return HTTP.get(`/Team/MemberById?userId=${body.userId}`, config)
    //         .then((res) => action(res.data))
    //         .catch((err) => {
    //             if (err.response && err.response.status === 401) {
    //                 // Retry the request with refreshed token
    //                 return handleUnauthorizedRequest(err.config)
    //                     .then((_res) => {
    //                         action(_res?.data);
    //                     })
    //                     .catch((e) => error(e));
    //             } else {
    //                 error(err);
    //             }
    //         });
    // }
    // getInviteInfo(
    //     body: { userId: string; token: string },
    //     action: (res: InviteInfoGet) => any,
    //     error: (res: any) => any
    // ) {
    //     let config = {
    //         headers: {
    //             authorization: `Bearer ${body.token}`,
    //         },
    //     };
    //     return HTTP.get(`/Invitation?userId=${body.userId}`, config)
    //         .then((res) => action(res.data))
    //         .catch((err) => {
    //             if (err.response && err.response.status === 401) {
    //                 // Retry the request with refreshed token
    //                 return handleUnauthorizedRequest(err.config)
    //                     .then((_res) => {
    //                         action(_res?.data);
    //                     })
    //                     .catch((e) => error(e));
    //             } else {
    //                 error(err);
    //             }
    //         });
    // }

    // sendInvatation(
    //     body: ResendInvatationReq,
    //     action: (res: any) => any,
    //     error: (res: any) => any
    // ) {
    //     return HTTP.post("/Invitation", body)
    //         .then((res) => action(res.data))
    //         .catch((err) => {
    //             if (err.response && err.response.status === 401) {
    //                 // Retry the request with refreshed token
    //                 return handleUnauthorizedRequest(err.config)
    //                     .then((_res) => {
    //                         action(_res?.data);
    //                     })
    //                     .catch((e) => error(e));
    //             } else {
    //                 error(err);
    //             }
    //         });
    // }
    // resendInvatation(
    //     body: ResendInvatationReq,
    //     action: (res: any) => any,
    //     error: (res: any) => any
    // ) {
    //     return HTTP.put("/Invitation", body)
    //         .then((res) => action(res.data))
    //         .catch((err) => {
    //             if (err.response && err.response.status === 401) {
    //                 // Retry the request with refreshed token
    //                 return handleUnauthorizedRequest(err.config)
    //                     .then((_res) => {
    //                         action(_res?.data);
    //                     })
    //                     .catch((e) => error(e));
    //             } else {
    //                 error(err);
    //             }
    //         });
    // }

    // updateUserPassword(
    //     body: { email: string; newPassword: string },
    //     action: (res: any) => any,
    //     error: (res: any) => any
    // ) {
    //     return HTTP.post("/User/ResetPassword", body)
    //         .then((res) => action(res.data))
    //         .catch((err) => {
    //             if (err.response && err.response.status === 401) {
    //                 // Retry the request with refreshed token
    //                 return handleUnauthorizedRequest(err.config)
    //                     .then((_res) => {
    //                         action(_res?.data);
    //                     })
    //                     .catch((e) => error(e));
    //             } else {
    //                 error(err);
    //             }
    //         });
    // }

    // updateUserEmail(
    //     body: { userId: string; email: string },
    //     action: (res: any) => any,
    //     error: (res: any) => any
    // ) {
    //     return HTTP.post(
    //         `/User/Account/UpdateUserEmail/${body.userId}/${body.email}`
    //     )
    //         .then((res) => action(res.data))
    //         .catch((err) => {
    //             if (err.response && err.response.status === 401) {
    //                 // Retry the request with refreshed token
    //                 return handleUnauthorizedRequest(err.config)
    //                     .then((_res) => {
    //                         action(_res?.data);
    //                     })
    //                     .catch((e) => error(e));
    //             } else {
    //                 error(err);
    //             }
    //         });
    // }
}
