import { toast } from "react-toastify";
import { AddDataReq } from "../../data/models/requests/data/add_data_req";
import { UpdateDataReq } from "../../data/models/requests/data/update_data_req";
import { AddDataResResult } from "../../data/models/responses/data/add_data_res";
import { GetAllDataResResult } from "../../data/models/responses/data/get_all_data_res";
import { GetDataByIDResResult } from "../../data/models/responses/data/get_by_id_data_res";
import { SearchDataResResult } from "../../data/models/responses/data/search_data_res";
import { UpdateDataResResult } from "../../data/models/responses/data/update_data_res";
import { RemoteData } from "../../data/remotes/data/remote_data";
import { store } from "../../data/storeMain";
import { User } from "../member/member_transfer_controller";
import { SortType } from "../../core/number_extentions";
import { IFilterOptions } from "../../views/components/FilterSection/IFilterOptions";
export class DataController {
  remote = new RemoteData();

  createData(
    body: AddDataReq,
    action: (res: AddDataResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.createData(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  updateData(
    body: UpdateDataReq,
    action: (res: UpdateDataResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.updateData(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  SearchData(
    body: {researchId?: number},
    action: (res: SearchDataResResult) => any,
    error: (res: any) => any
  ) {
    body.researchId = body.researchId ? body.researchId : store.getState().ResearchId.value
    if (body.researchId != null) {
      this.remote.SearchData(
        {...body},
        (res) => {
          action(res.result!);
        },
        (err) => {
          if (err.response?.data.message)
            toast.error(`${err.response?.data.message}`);
          error(err);
        }
      );
    } else {
      action({
        researchId: 0,
        accessableEquipments: [],
        appTasks: [],
        subAppTasks: [],
      });
      // toast.error(`please select research in search data`, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }
  }
  getAllData(
    body: {researchId?: number; PageSize: number; PageNumber: number; SearchParameter: string; columnHeaderName?: string; sortDirection?: SortType },
    action: (res: GetAllDataResResult) => any,
    error: (res: any) => any
  ) {;
    if (body.researchId != null ) {
      this.remote.getAllData(
        {...body },
        (res) => {
          action(res.result!);
        },
        (err) => {
          if (err.response?.data.message)
            toast.error(`${err.response?.data.message}`);
          error(err);
        }
      );
    } else {
      action({ count: 0, dataLists: [] });
      return;
      // console.log(`please select research in get all data`);
      // toast.error(`please select research in get all data`, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }
  }
  getDataListByTaskId(
    body: {
      researchId: number;
      PageSize: number;
      PageNumber: number;
      SearchParameter: string;
      TaskId: number;
      ColumnHeaderName : string,
      SortDirection: SortType,
      filterOptions: IFilterOptions
    },
    action: (res: {
      count: number;
      mainTaskId: number;
      datas: {
        allowToEdit: boolean;
        allowedToView: boolean;
        createdDate: Date;
        creatorUser: User;
        discussionId: number;
        haveDiscussion: boolean;
        id: number;
        medias: any[];
        title: string;
        researchId: number;
      }[];
    }) => any,
    error: (res: any) => any
  ) {
    this.remote.getDataListByTaskId(
      body,
      (res) => {
        action(res.result! as any);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  getDataById(
    body: { dataId: number; researchId: number; appTaskId: number },
    action: (res: GetDataByIDResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getDataById(
      body,
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  deleteData(
    body: {
      dataId: number
    },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.deleteData(
      body,
      (res) => {
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
}
