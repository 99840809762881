import { NOTIFICATION_COUNT } from '../actions/actions_type';
import { NotificationCountAction } from '../actions/notification_count_action';

const notificationCount = 0;

const reducer = (state = notificationCount, action: NotificationCountAction): any => {
  switch (action.type) {
    case NOTIFICATION_COUNT: {
      return action.payload;
    }
  }
  return state;
};

export default reducer;
