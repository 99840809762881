import { toast } from "react-toastify";
import { CreateLearningReq, CreateSubLearningReq } from "../../data/models/requests/learningSection/create_learning_req";
import {
    UpdatelearningReq, UpdateSublearningReq,
} from "../../data/models/requests/learningSection/update_learning_req";
import { CreatelearningResResult, CreateSublearningResResult, uploadlearningRes } from "../../data/models/responses/learning/create_learning_res";
// import { GetMemberByIDResResult } from "../../data/models/responses/member/get_member_by_id_res";
// import { InviteInfoGetResult } from "../../data/models/responses/member/Invite_info_res";
// import { MemberListResResult } from "../../data/models/responses/member/member_list_res";
import { SearchlearningResResult, SearchSublearningResResult } from "../../data/models/responses/learning/search_learning_res";
import { UpdatelearningResResult, UpdateSublearningResResult } from "../../data/models/responses/learning/update_learning_res";
import { RemoteMember } from "../../data/remotes/learning/remote_learning";
import { HelpLearningDetails, HelpLearningSectionDetails } from "../../data/models/responses/learning/help_learningSection_res";
export class learningController {
    remote = new RemoteMember();

    createLearningSection(
        body: CreateSubLearningReq,
        action: (res: CreateSublearningResResult) => any,
        error: (res: any) => any
    ) {
        this.remote.createLearningSection(
            body,
            (res) => {
                toast.success(`${res.message}`);
                action(res.result);
            },
            (err) => {
                if (err.response?.data.message)
                    toast.error(`${err.response?.data.message}`);
                error(err);
            }
        );
    }
    uploadLearningSection(
        body: HelpLearningSectionDetails[],
        action: (res: uploadlearningRes) => any,
        error: (res: any) => any
    ) {
        this.remote.uploadLearningSection(
            body,
            (res) => {
                toast.success(`${res.message}`);
                action(res);
            },
            (err) => {
                if (err.response?.data.message)
                    toast.error(`${err.response?.data.message}`);
                error(err);
            }
        );
    }

    updateLearningSection(
        body: UpdateSublearningReq,
        action: (res: UpdateSublearningResResult) => any,
        error: (res: any) => any
    ) {
        this.remote.updateLearningSection(
            body,
            (res) => {
                toast.success(`${res.message}`);
                action(res.result);
            },
            (err) => {
                if (err.response?.data.message)
                    toast.error(`${err.response?.data.message}`);
                error(err);
            }
        );
    }
    SearchLearningSection(
        searchquery: string,
        action: (res: SearchlearningResResult[]) => any,
        error: (res: any) => any
    ) {
        this.remote.SearchLearningSection(
            searchquery,
            (res) => {
                action(res.result!);
            },
            (err) => {
                if (err.response?.data.message)
                    toast.error(`${err.response?.data.message}`);
                error(err);
            }
        );
    }

    DeleteLearningSection(
        sectionIdToDelete: number,
        success: () => any,
        error: (res: any) => any
    ) {
        this.remote.DeleteLearningSection(
            sectionIdToDelete,
            () => {
                success();
                toast.success(`Deleted Succesfully`);
            },
            (err) => {
                if (err.response?.data.message) {
                    toast.error(`${err.response.data.message}`);
                }
                error(err);
            }
        );
    }
    updateLearningSubSection(
        body: UpdateSublearningReq,
        action: (res: UpdateSublearningResResult) => any,
        error: (res: any) => any
    ) {
        this.remote.updateSubLearningSection(
            body,
            (res) => {
                toast.success(`${res.message}`);
                action(res.result);
            },
            (err) => {
                if (err.response?.data.message)
                    toast.error(`${err.response?.data.message}`);
                error(err);
            }
        );
    }
    // createLearningSubSection(
    //     body: CreateSubLearningReq,
    //     action: (res: boolean) => any,
    //     error: (res: any) => any
    // ) {
    //     this.remote.createSubLearningSection(
    //         body,
    //         (res) => {
    //             toast.success(`${res.message}`);
    //             action(res.result);
    //         },
    //         (err) => {
    //             if (err.response?.data.message)
    //                 toast.error(`${err.response?.data.message}`);
    //             error(err);
    //         }
    //     );
    // }

    SearchSubLearningSection(
        body: {searchquery: string },
        action: (res: SearchSublearningResResult[]) => any,
        error: (res: any) => any
    ) {
        this.remote.SearchSubLearningSection(body,
            (res) => {
                action(res.result!);
            },
            (err) => {
                if (err.response?.data.message)
                    toast.error(`${err.response?.data.message}`);
                error(err);
            }
        );
    }
    DeleteLearningSubSection(
        sectionIdToDelete: number,
        success: () => any,
        error: (res: any) => any
    ) {
        this.remote.DeleteLearningSubSection(
            sectionIdToDelete,
            () => {
                success();
            },
            (err) => {
                if (err.response?.data.message) {
                    toast.error(`${err.response.data.message}`);
                }
                error(err);
            }
        );
    }
    GetLearningSectionData(
        searchQuery: string,
        action: (res: HelpLearningSectionDetails[]) => any,
        error: (res: any) => any
    ) {
        this.remote.getLearningSectionList(
            searchQuery,
            (res) => {
                action(res.result!);
            },
            (err) => {
                if (err.response?.data.message)
                    toast.error(`${err.response?.data.message}`);
                error(err);
            }
        );
    }
    // getMember(
    //     body: { userId: string; token: string },
    //     action: (res: GetMemberByIDResResult) => any,
    //     error: (res: any) => any
    // ) {
    //     this.remote.getMemberById(
    //         body,
    //         (res) => {
    //             action(res.result!);
    //         },
    //         (err) => {
    //             if (err.response?.data.message)
    //                 toast.error(`${err.response?.data.message}`);
    //             error(err);
    //         }
    //     );
    // }
    // getInviteInfo(
    //     body: { userId: string; token: string },
    //     action: (res: InviteInfoGetResult) => any,
    //     error: (res: any) => any
    // ) {
    //     this.remote.getInviteInfo(
    //         body,
    //         (res) => {
    //             action(res.result!);
    //         },
    //         (err) => {
    //             if (err.response?.data.message)
    //                 toast.error(`${err.response?.data.message}`);
    //             error(err);
    //         }
    //     );
    // }

    // sendInvatation(
    //     body: ResendInvatationReq,
    //     action: (res: any) => any,
    //     error: (res: any) => any
    // ) {
    //     this.remote.sendInvatation(
    //         body,
    //         (res) => {
    //             toast.success(`${res.message}`);
    //             action(res.result);
    //         },
    //         (err) => {
    //             if (err.response?.data.message)
    //                 toast.error(`${err.response?.data.message}`);
    //             error(err);
    //         }
    //     );
    // }

    // updateUserPassword(
    //     body: { email: string; newPassword: string },
    //     action: (res: any) => any,
    //     error: (res: any) => any
    // ) {
    //     this.remote.updateUserPassword(
    //         body,
    //         (res) => {
    //             toast.success(`${res.message}`);
    //             action(res.result);
    //         },
    //         (err) => {
    //             if (err.response?.data.message)
    //                 toast.error(`${err.response?.data.message}`);
    //             error(err);
    //         }
    //     );
    // }

    // updateUserEmail(
    //     body: { userId: string; email: string },
    //     action: (res: any) => any,
    //     error: (res: any) => any
    // ) {
    //     this.remote.updateUserEmail(
    //         body,
    //         (res) => {
    //             toast.success(`${res.message}`);
    //             action(res.result);
    //         },
    //         (err) => {
    //             if (err.response?.data.message)
    //                 toast.error(`${err.response?.data.message}`);
    //             error(err);
    //         }
    //     );
    // }
}
