import React from 'react';
import { store } from '../../../data/storeMain';
import { InputComponent, InputType } from '../../components/inputs';
import 'react-datepicker/dist/react-datepicker.css';
import { MainButton, MainButtonType } from '../../components/button';
import { SelectComponent } from '../../components/select_input';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
import Dropzone from 'react-dropzone';
import { RadioGroup } from '../../components/radio_group';
import { Laboratory } from '../../../data/models/responses/equipment/get_equipment_by_id_res';
import { RouteComponentProps } from 'react-router';
import { UploadController } from '../../../controllers/upload_media/upload_media';
import SimpleReactValidator from 'simple-react-validator';
import { EquipmentController } from '../../../controllers/equipment/equipment_controller';
import { LocalDataSources } from '../../../data/local_datasources';
import { AppRoutes, LOADING_MODE } from '../../../core/constants';
import { EditEquipmentReq } from '../../../data/models/requests/equipment/equipment_update_req';
import {
  $ReplaceRoute,
  // $truncate,
  addSpaceBetweenWords,
  isTokenInHttpAuth,
  uploadAllFiles,
  uploadAllLinks,
  uploadToS3PresignedUrl,
} from '../../../core/utils';
import GetPictureAndCrop from '../../components/GetPictureAndCrop';
import { HTTP, IMAGE_BASE_URL } from '../../../core/http_common';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import File from '../../components/File';
import CircleIconTooltip from '../../components/CircleIconTooltip';
import DOMPurify from 'dompurify';
import { BaseButton } from '@fluentui/react';
import { SetHeaderTitle } from '../../../data/storeMain/actions/header_title_action';
type StateType = {
  id: number;
  title: string;
  description: string;
  contractId: string;
  manufacturer: string;
  model: string;
  technicianName: string;
  technicianEmail: string;
  technicianPhone: string;
  status: any;
  addedLaboratoriesId: any;
  removedLaboratoriesId: number[];
  removedMediasId: number[];
  Laboratories: Laboratory[];
  Medias: any[];
  files: Array<File>;
  selectedLaboratories: Array<{ label: string; value: number }>;
  listLaboratory: Array<{ name: string; value: number }>;
  listequipmentStatus: Array<{ label: string; value: number }>;
  imageUrl: string | any;
  picture: Array<File>;
  loading: boolean;
  ExternalUrl: Array<string>;
  External: string;
  cahngePic: boolean;
  mainLaboratory: number[];
  searchLoading: boolean;
  loadingMode: string;
};
interface RouteParams {
  id: string;
}
export class EditEquip extends React.Component<RouteComponentProps<RouteParams>> {
  RoleUser = store.getState().userRole;
  controller = new EquipmentController();
  UploadController = new UploadController();
  validator = new SimpleReactValidator({
    className: 'text-danger',
  });
  local: LocalDataSources = new LocalDataSources();
  state: StateType = {
    id: 0,
    title: '',
    contractId: '',
    mainLaboratory: [],
    description: '',
    manufacturer: '',
    model: '',
    technicianName: '',
    technicianEmail: '',
    technicianPhone: '',
    status: {},
    addedLaboratoriesId: null,
    removedLaboratoriesId: [],
    removedMediasId: [],
    Laboratories: [],
    Medias: [],
    files: [],
    listLaboratory: [],
    listequipmentStatus: [],
    imageUrl: '',
    picture: [],
    loading: false,
    ExternalUrl: [],
    External: '',
    cahngePic: false,
    selectedLaboratories: [],
    searchLoading: false,
    loadingMode: '',
  };
  onDrop = (fileList: any) => {
    fileList.map((item: any, index: number) => {
      const found = this.state.files.find((element: any) => element.name === item.name);
      if (found === undefined) {
        this.setState({ files: [fileList[index], ...this.state.files] });
      }
    });
  };
  onDropPic = (picture: any) => {
    this.setState({
      picture,
      imageUrl: URL.createObjectURL(picture[0]),
      cahngePic: true,
    });
  };
  componentDidMount() {
    if (!isTokenInHttpAuth(HTTP)) return;
    this.setState({
      searchLoading: true,
      listequipmentStatus: this.local.getSetting().equipmentStatus.map((item) => {
        const _title = addSpaceBetweenWords(item.title);
        return { label: _title, value: item.id };
      }),
    });
    this.controller.EquipmentsSearch(
      (res) => {
        this.setState({
          listLaboratory: res.map((item) => {
            return { label: item.title, value: item.id };
          }),
        });
        this.controller.getEquipment(
          {
            equipmentId: parseInt(this.props.match.params.id),
          },
          (res) => {
            document.title = `Edit Equipment | ${res.title} | Radvix`;
            store.dispatch(SetHeaderTitle('Laboratory'))
            const _selectedLaboratories = res.laboratories.map((item) => {
              return { label: item.title, value: item.id };
            });
            const _equip = this.state.listequipmentStatus.find((x) => x.value == res.status);

            this.setState({
              searchLoading: false,
              title: res.title,
              description: res.description,
              technicianName: res.technicianName,
              technicianEmail: res.technicianEmail,
              model: res.model,
              manufacturer: res.manufacturer,
              status: _equip,
              technicianPhone: res.technicianPhone,
              contractId: res.contractId,
              imageUrl: res.image,
              Medias: res.medias,
              Laboratories: res.laboratories,
              selectedLaboratories: _selectedLaboratories,
              mainLaboratory: res.laboratories.map((item) => item.id),
              id: res.id,
            });
            this.setState({
              ExternalUrl: this.state.Medias.filter((x) => x.externalUrl).map((y) => y.externalUrl),
            });
          },
          (err) => {
            this.setState({
              searchLoading: false,
            });
          }
        );
      },
      (err) => {
        this.setState({
          searchLoading: false,
        });
      }
    );
  }
  handelDeleteFile(arg: File) {
    this.setState({
      files: this.state.files.filter((file) => file.name !== arg.name),
    });
  }
  handelDeletePic(arg: File) {
    this.setState({
      picture: this.state.picture.filter((file) => file.name !== arg.name),
      imageUrl: null,
    });
  }
  handleChange(target: string, val: any) {
    const sanitizedValue = DOMPurify.sanitize(val);
    this.setState({
      [target]: sanitizedValue,
    });
  }
  handelRemoveMedia(id: number) {
    this.setState({
      removedMediasId: [...this.state.removedMediasId, id],
      Medias: this.state.Medias.filter((item) => item.id !== id),
    });
  }
  addExternalUrl() {
    if (this.validator.fields) {
      let Url = [...this.state.ExternalUrl];
      if (this.state.External.length > 2) {
        Url.push(this.state.External);
      }
      this.setState({
        ExternalUrl: Url,
        External: '',
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  handelDeleteExternalLink(link: string) {
    this.setState({
      ExternalUrl: this.state.ExternalUrl.filter((item) => item !== link),
    });
  }
  handelChangeSelect(e: Array<{ label: string; value: number }>) {
    //const newLabId = e.map((item) => item.value);
    this.setState({
      addedLaboratoriesId: e,
    });
  }
  handelRemoveLaboratoy(id: number) {
    this.setState({
      removedLaboratoriesId: [...this.state.removedLaboratoriesId, id],
      Laboratories: this.state.Laboratories.filter((item) => item.id !== id),
    });
  }

  formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 MB';

    const m = 1024 * 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizeInMB = bytes / m;

    return `${sizeInMB.toFixed(dm)} MB`;
  }

  UpdateEquipment() {
    if (this.validator.allValid()) {
      this.setState({
        loading: true,
        loadingMode: 'Updating equipment..',
      });
      const _selectedLaboratories = this.state.selectedLaboratories.map((item) => item.value);
      const _addedLabs = _selectedLaboratories.filter(
        (item) => !this.state.mainLaboratory.includes(item)
      );

      const _removedLabs = this.state.mainLaboratory.filter(
        (item) => !_selectedLaboratories.includes(item)
      );

      // const _addLab = [
      //   ..._selectedLaboratories.filter(
      //     (item) => !this.state.removedLaboratoriesId.includes(item)
      //   ),
      // ];

      // const _removeLab = [
      //   ...this.state.Laboratories.map((_l) => _l.id).filter(
      //     (item) => !_selectedLaboratories.includes(item)
      //   ),
      // ];

      const body: EditEquipmentReq = {
        id: parseInt(this.props.match.params.id),

        addedLaboratoriesId: _addedLabs,
        removedLaboratoriesId: _removedLabs,
        contractId: this.state.contractId,
        title: this.state.title,
        description: this.state.description,
        manufacturer: this.state.manufacturer,
        model: this.state.model,
        technicianName: this.state.technicianName,
        technicianEmail: this.state.technicianEmail,
        technicianPhone: this.state.technicianPhone,
        status: this.state.status?.value,
        removedMediasId: this.state.removedMediasId,
      };

      this.controller.updateEquipment(
        body,
        async (res) => {
          if (
            this.state.files.length ||
            this.state.ExternalUrl.length ||
            this.state.picture.length
          ) {
            await this.handelUpload(res.id);
            this.setState({
              loadingMode: LOADING_MODE.pic,
            });
            await this.handelUploadPic(res.id);
            this.setState({
              loadingMode: '',
              loading: false,
            });
            this.props.history.push(
              $ReplaceRoute(`${AppRoutes.equip_profile.replace(':id', res.id?.toString() ?? '')}`)
            );
          } else {
            this.setState({
              loading: false,
              loadingMode: '',
            });
            this.props.history.push(
              $ReplaceRoute(`${AppRoutes.equip_profile.replace(':id', res.id?.toString() ?? '')}`)
            );
          }
        },
        (err) => {
          this.setState({
            loading: false,
            loadingMode: '',
          });
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  async handelUpload(id: number) {
    try {
      this.setState({
        loadingMode: LOADING_MODE.links,
      });
      await uploadAllLinks(id, this.state.ExternalUrl, 2);
      this.setState({
        loadingMode: LOADING_MODE.files,
      });
      await uploadAllFiles(id, this.state.files, 2);
    } catch (e) {
      this.setState({
        loading: false,
        loadingMode: '',
      });
    }
    // return new Promise((resolve, reject) => {
    //   const formData = new FormData();
    //   for (let i = 0; i < this.state.files.length; i++) {
    //     const file = this.state.files[i];
    //     formData.append("Files", file);
    //   }
    //   for (let i = 0; i < this.state.ExternalUrl.length; i++) {
    //     const file = this.state.ExternalUrl[i];
    //     formData.append("ExternalUrls", file);
    //   }
    //   formData.append("UseCase", "2");
    //   formData.append("SectionId", id.toString());

    //   this.UploadController.UloadMedia(
    //     formData,
    //     (res) => {
    //       resolve(res)
    //     },
    //     () => {
    //       this.setState({
    //         loading: false,
    //       });
    //       reject(false)
    //     }
    //   );
    // })
  }
  handelUploadPic(id: number) {
    return new Promise((resolve, reject) => {
      if (this.state.picture.length === 0) resolve(true);
      const file = this.state.picture[0];
      const formData = new FormData();
      // for (let i = 0; i < this.state.picture.length; i++) {
      //   const file = this.state.picture[i];
      //   formData.append("Files", file);
      // }
      formData.append('UseCase', '3');
      formData.append('SectionId', id.toString());

      formData.append('FileName', file.name);
      // @ts-ignore
      formData.append('FileSize', file.size);
      this.UploadController.UloadMedia(
        formData,
        async (res) => {
          await uploadToS3PresignedUrl(file, res.result?.cloudFrontUrl, res.result?.mediaId);
          this.setState({
            loading: false,
          });

          resolve(res);
        },
        () => {
          this.setState({
            loading: false,
          });
          reject(false);
        }
      );
    });
  }
  render() {
    const files = this.state.files.map((file: any) => (
      <li
        key={file.name}
        className='d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team'
        style={{ marginBottom: '3px' }}
      >
        <span
          className='text-truncate col-11 mx-2'
          style={{ fontFamily: 'Sanfransisco', textAlign: 'start' }}
        >
          {file.name} - {this.formatBytes(file.size)}
        </span>
        <CircleIcon
          type={ThemeCircleIcon.dark}
          width='22px'
          height='22px'
          padding='0'
          onClick={() => {
            this.handelDeleteFile(file);
          }}
          style={{ cursor: 'pointer', display: 'contents' }}
        >
          <img src='/images/icons/cross-1.svg' alt='radvix' width={15} height={15} />
        </CircleIcon>
      </li>
    ));
    // const picture = this.state.picture.map((file: any) => (
    //   <li key={file.name}>
    //     <span className="text-truncate col-10 mx-2">
    //       {file.name} - {file.size} bytes
    //     </span>
    //     <CircleIcon
    //       type={ThemeCircleIcon.dark}
    //       width="22px"
    //       height="22px"
    //       padding="0"
    //       onClick={() => {
    //         this.handelDeletePic(file);
    //       }}
    //     >
    //       <img
    //         src="/images/icons/garbage_can.svg"
    //         alt="radvix"
    //         width={15}
    //         height={15}
    //       />
    //     </CircleIcon>
    //   </li>
    // ));
    return (
      <div className='card'>
        <div
          className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
      border-gray-400'
        >
          <div className='d-flex align-items-center'>
            {/* <button className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none"> Back</button> */}
            <BaseButton
              className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
              style={{ fontWeight: '400', height: '19px' }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>Edit Equipment</h2>
          </div>
        </div>

        <div className='p-3 overflow-auto'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='row'>
                <div className='col-lg-12 mb-3'>
                  <div className='item'>
                    <InputComponent
                      className='form-control'
                      disabled={this.state.searchLoading}
                      type={InputType.text}
                      label='Equipment name*'
                      placeholder='Enter your laboratory name'
                      popQuestion='Please enter the name of your laboratory. You are going to make it easier to identify your lab when working with multiple laboratories. You can always edit this name.'
                      onChange={(e) => {
                        this.handleChange('title', e.target.value);
                      }}
                      inValid={this.validator.message(
                        'Laboratory Name',
                        this.state.title,
                        'required'
                      )}
                      value={this.state.title}
                    ></InputComponent>
                    {/* <input className="form-control" placeholder="Enter your project name" /> */}
                  </div>

                  <div className='item'>
                    <label className='color-gray-600 fs-15 me-2'>Equipment Picture</label>

                    <GetPictureAndCrop
                      disabled={this.state.searchLoading}
                      originalImage={this.state.imageUrl?.name || ''}
                      defaultPicture='/Images/images/equipment_picture.svg'
                      style={{ marginTop: '0.4em' }}
                      picture={this.state.imageUrl ? IMAGE_BASE_URL + this.state.imageUrl.name : ''}
                      onRemove={() => {
                        // const _pictureId = Number(this.state.imageUrl.split('/')[this.state.imageUrl.split('/').length - 1].split('.')[0])

                        this.setState({
                          imageUrl: '',
                          picture: [],
                          removedMediasId: [...this.state.removedMediasId, this.state.imageUrl?.id],
                        });
                      }}
                      onSave={(_pictures: any) => {
                        this.setState({
                          picture: _pictures,
                          imageUrl: URL.createObjectURL(_pictures[0]),
                        });
                      }}
                    />

                    {/* <div className="d-flex justify-content-between align-items-center"> 
                  {this.state.imageUrl && this.state.imageUrl.length > 2 ? (
                    <Fragment>
                      <img
                        src={
                          !this.state.cahngePic
                            ? AppConstants.base_url_image + this.state.imageUrl
                            : this.state.imageUrl
                        }
                        alt="Avatar"
                        className="rounded-circle avatar mx-2"
                        width="125px"
                        height="125px"
                      />
                      <CircleIcon
                        type={ThemeCircleIcon.dark}
                        width="22px"
                        height="22px"
                        className="mx-2 px-2 pointer"
                        onClick={() => {
                          this.setState({
                            picture: [],
                            imageUrl: "",
                          });
                        }}
                      >
                        <img
                          src="/images/icons/garbage_can.svg"
                          alt="radvix"
                          width={15}
                          height={15}
                        />
                      </CircleIcon>
                    </Fragment>
                  ) : null}
                  <Dropzone onDrop={this.onDropPic}>
                    {({ getRootProps, getInputProps }) => (
                      <section className="container fileUploadBox">
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} multiple={false} />
                          <MainButton
                            type={MainButtonType.light}
                            minHeight="30px"
                            minWidth="179px"
                            fontSize="14px"
                            borderRadius="50px"
                            backgroundColor="#fff"
                            border="1px solid #707070"
                            color="#707070"
                            className="mt-4"
                            children={
                              <div className="d-flex justify-content-between align-items-center">
                                <img
                                  src="/Images/icons/cloud_computing.svg"
                                  alt="sssss"
                                  height="20"
                                />{" "}
                                <span className="flex-fill">
                                  Browse Local Files
                                </span>
                              </div>
                            }
                          ></MainButton>
                          <p>Or drag and drop files here</p>
                        </div>
                        <aside>
                          <ul>{picture}</ul>
                        </aside>
                      </section>
                    )}
                  </Dropzone>
                </div> */}
                  </div>
                  {/* <div className="col-lg-12 mb-3">
                    <div className="d-flex align-items-center mb-1">
                      <label className="color-gray-600 fs-15 me-2">
                        Equipment Picture
                      </label>
                      <img
                        className="text-primary cursor-pointer"
                        src="/Images/icons/info-circled.svg"
                        alt="info"
                        title="This will be your public profile picture."
                      />
                    </div>
                    <div>
                      <div className="d-flex justify-content-sm-between justify-content-center align-items-center flex-wrap row-gap-px-8">
                      <GetPictureAndCrop
                disabled={this.state.searchLoading}
                  originalImage={this.state.imageUrl?.name || ""}
                  defaultPicture="/Images/images/equipment_picture.svg"
                  style={{ marginTop: "0.4em" }}
                  picture={
                    this.state.imageUrl
                      ? IMAGE_BASE_URL + this.state.imageUrl.name
                      : ""
                  }
                  onRemove={() => {
                    
                    // const _pictureId = Number(this.state.imageUrl.split('/')[this.state.imageUrl.split('/').length - 1].split('.')[0])
                    
                    this.setState({
                      imageUrl: "",
                      picture: [],
                      removedMediasId: [
                        ...this.state.removedMediasId,
                        this.state.imageUrl?.id,
                      ],
                    });
                  }}
                  onSave={(_pictures: any) => {
                    this.setState({
                      picture: _pictures,
                      imageUrl: URL.createObjectURL(_pictures[0]),
                    });
                  }}
                />
                      </div>
                    </div>
                  </div> */}
                  <div className='item'>
                    <InputComponent
                      disabled={this.state.searchLoading}
                      type={InputType.text}
                      className='form-control'
                      label='Manufacturer'
                      placeholder='Enter manufacturer'
                      popQuestion='Please enter the manufacturer of this equipment. You can always update this later.'
                      optional='optional'
                      value={this.state.manufacturer}
                      onChange={(e) => {
                        this.handleChange('manufacturer', e.target.value);
                      }}
                    ></InputComponent>
                  </div>

                  <div className='item'>
                    <InputComponent
                      disabled={this.state.searchLoading}
                      type={InputType.text}
                      label='Model'
                      placeholder='Enter model'
                      popQuestion='Please enter the technical model name or ID for this equipment. This is helpful for technical support in the future.'
                      optional='optional'
                      value={this.state.model}
                      onChange={(e) => {
                        this.handleChange('model', e.target.value);
                      }}
                    ></InputComponent>
                  </div>
                  <div className='item'>
                    <InputComponent
                      type={InputType.textarea}
                      disabled={this.state.searchLoading}
                      label='Description'
                      placeholder='Enter your equipment description'
                      popQuestion='This will be shown on the laboratory profile and briefly explains what are the main objectives of this laboratory or what is the main focus of the projects.'
                      optional='optional'
                      value={this.state.description}
                      onChange={(e) => {
                        this.handleChange('description', e.target.value);
                      }}
                      height='132px'
                      className='mt-1'
                    ></InputComponent>
                  </div>
                  <div className='item'>
                    <SelectComponent
                      disabled={this.state.searchLoading}
                      items={this.state.listequipmentStatus}
                      TextItem='label'
                      ValueItem='value'
                      className='w-100'
                      placeholder='Select a category'
                      label='Equipment Status'
                      menuPlacementTop={true}
                      isMulti={false}
                      onChange={(e) => {
                        this.setState({
                          status: e,
                        });
                      }}
                      defaultValue={this.state.status}
                    ></SelectComponent>
                  </div>
                  {/* <div className="item">
                <RadioGroup
                  disabled={this.state.searchLoading}
                  label="Equipment status"
                  popQuestion="Here you can update the status of this equipment. When an equipment changes status, all users who have access to this equipment will be notified."
                  TextItem="name"
                  ValueItem="id"
                  name="Currency"
                  items={this.state.listequipmentStatus}
                  onChange={(e) => {
                    this.handleChange("status", parseInt(e.target.value));
                  }}
                  Selected={this.state.status}
                ></RadioGroup>
              </div> */}
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='row'>
                <div className='col-lg-12 mb-3'>
                  <div className='item' style={{ paddingBottom: '15px' }}>
                    <SelectComponent
                      disabled={this.state.searchLoading}
                      items={this.state.listLaboratory}
                      TextItem='title'
                      ValueItem='id'
                      // className="mb-2"
                      label='Select laboratories to assign this equipment to'
                      popQuestion='Here you can select one or multiple laboratories to assign this equipment to them. If users have full access to those laboratories, they will automatically have access to this equipment.'
                      placeholder='Select laboratories'
                      defaultValue={this.state.selectedLaboratories}
                      onChange={(e) => {
                        this.handelChangeSelect(e);
                      }}
                      isMulti
                      inValid={this.validator.message(
                        'Assign To Laboratory',
                        this.state.Laboratories,
                        'required'
                      )}
                    ></SelectComponent>
                  </div>
                  <div className='d-flex align-items-center mb-1'>
                    <label className='color-gray-600 fs-15 me-2'>Protocols and documents:</label>
                    {/* <img
                      className="text-primary cursor-pointer"
                      src="/Images/icons/info-circled.svg"
                      alt="info"
                      title="Research attachments are generic documents that you would like anyone who has access to this research project read and have access to them. You can either upload them or Insert the link."
                    /> */}
                  </div>
                  <div className='p-px-12 bg-gray-200 border-gray-400 border rounded-px-4 gap-2 attachment-paddingleft'>
                    <div className='justify-content-sm-between justify-content-center align-items-center flex-wrap row-gap-px-8'>
                      <Dropzone onDrop={this.onDrop}>
                        {({ getRootProps, getInputProps }) => (
                          // <section
                          //   className="container fileUploadBox"
                          //   style={{ marginTop: "0.3em" }}
                          // >
                          <div {...getRootProps({ className: '' })}>
                            <input {...getInputProps()} />

                            <div className='d-flex justify-content-between align-items-center flex-wrap row-gap-px-8'>
                              <button className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'>
                                <div className='d-flex align-items-center'>
                                  <div className='me-1 mb-1'>
                                    <svg
                                      width='15'
                                      height='15'
                                      viewBox='0 0 15 15'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <rect
                                        width='15'
                                        height='15'
                                        fill='white'
                                        fill-opacity='0.01'
                                      />
                                      <path
                                        fill-rule='evenodd'
                                        clip-rule='evenodd'
                                        d='M7.81825 1.18197C7.64251 1.00624 7.35759 1.00624 7.18185 1.18197L4.18185 4.18197C4.00611 4.35771 4.00611 4.64263
              4.18185 4.81837C4.35759 4.9941 4.64251 4.9941 4.81825 4.81837L7.05005 2.58657V9.50005C7.05005 9.74858 7.25152 9.95005
              7.50005 9.95005C7.74858 9.95005 7.95005 9.74858 7.95005 9.50005V2.58657L10.1819 4.81837C10.3576 4.9941 10.6425 4.9941
              10.8182 4.81837C10.994 4.64263 10.994 4.35771 10.8182 4.18197L7.81825 1.18197ZM2.5 10.0001C2.77614 10.0001 3 10.2239 3
              10.5001V12.0001C3 12.5539 3.44565 13.0001 3.99635 13.0001H11.0012C11.5529 13.0001 12 12.5529 12 12.0001V10.5001C12
              10.2239 12.2239 10.0001 12.5 10.0001C12.7761 10.0001 13 10.2239 13 10.5001V12.0001C13 13.1041 12.1062 14.0001 11.0012
              14.0001H3.99635C2.89019 14.0001 2 13.1031 2 12.0001V10.5001C2 10.2239 2.22386 10.0001 2.5 10.0001Z'
                                        fill='currentColor'
                                      />
                                    </svg>
                                  </div>
                                  Browse local file
                                </div>
                              </button>
                              <h3 className='fw-normal color-gray-700 fs-13 mb-0 flex-grow-1 text-center'>
                                {' '}
                                Or drag and drop files here - Max 128MB{' '}
                              </h3>
                            </div>
                            {this.state.Medias.length > 0 && (
                              <div style={{ paddingTop: '10px' }}>
                                <ul className='list-unstyled fw-normal'>
                                  {this.state.Medias.filter(
                                    (item) => item.externalUrl === null
                                  ).map((item, index) => (
                                    <li
                                      key={index}
                                      className='d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team'
                                      style={{ marginBottom: '3px' }}
                                    >
                                      <span
                                        className='text-truncate col-11 mx-2'
                                        style={{ fontFamily: 'Sanfransisco', textAlign: 'start' }}
                                      >
                                        <File file={item} />
                                      </span>
                                      <CircleIcon
                                        type={ThemeCircleIcon.dark}
                                        width='22px'
                                        height='22px'
                                        padding='0'
                                        disabled={this.state.loading}
                                        onClick={() => {
                                          this.handelRemoveMedia(item.id!);
                                        }}
                                        style={{
                                          cursor: 'pointer',
                                          display: 'contents',
                                        }}
                                      >
                                        <img
                                          src='/images/icons/cross-1.svg'
                                          alt='radvix'
                                          width={15}
                                          height={15}
                                        />
                                      </CircleIcon>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                            {files.length > 0 && (
                              <div style={{ paddingTop: '10px' }}>
                                <ul className='list-unstyled fw-normal'>{files}</ul>
                              </div>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                  {this.state.Medias.length > 0 && (
                    <ul
                      className='file-list mt-1'
                      style={{
                        marginBottom: this.state.Medias.length > 0 ? '0px' : '0px',
                      }}
                    >
                      {this.state.Medias.filter((item: any) => item.externalUrl).map(
                        (item, index) => (
                          <li key={index} className='d-flex align-items-center w-100'>
                            <InputComponent
                              type={InputType.text}
                              value={item.externalUrl!}
                              className='mx-2'
                              counter={false}
                              disabled={true}
                              readOnly={true}
                              rowWidth='98%'
                              isExternalURL={true}
                            ></InputComponent>
                            <div
                              style={{
                                paddingBottom: '0px',
                                paddingRight: '0px',
                                paddingTop: '0px',
                                paddingLeft: '5px',
                              }}
                            >
                              <button
                                className='btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0'
                                disabled={this.state.searchLoading}
                                style={{ background: '#F3AEAF' }}
                                onClick={() => {
                                  this.handelDeleteExternalLink(item.externalUrl!);
                                }}
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='15'
                                  height='15'
                                  viewBox='0 0 15 15'
                                  fill='none'
                                >
                                  <rect width='15' height='15' fill='white' fill-opacity='0.01' />
                                  <path
                                    fill-rule='evenodd'
                                    clip-rule='evenodd'
                                    d='M5.5 1.00009C5.22386 1.00009 5 1.22395 5 1.50009C5 1.77623 5.22386 2.00009 5.5 2.00009H9.5C9.77614 2.00009 10 1.77623 10 1.50009C10 1.22395 9.77614 1.00009 9.5 1.00009H5.5ZM3 3.50009C3 3.22395 3.22386 3.00009 3.5 3.00009H5H10H11.5C11.7761 3.00009 12 3.22395 12 3.50009C12 3.77623 11.7761 4.00009 11.5 4.00009H11V12.0001C11 12.5524 10.5523 13.0001 10 13.0001H5C4.44772 13.0001 4 12.5524 4 12.0001V4.00009H3.5C3.22386 4.00009 3 3.77623 3 3.50009ZM5 4.00009H10V12.0001H5V4.00009Z'
                                    fill='#D93D42'
                                  />
                                </svg>
                              </button>
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                  )}
                  <ul
                    className='file-list mt-1'
                    style={{
                      marginBottom: this.state.External.length > 0 ? '0px' : '0px',
                    }}
                  >
                    {this.state.ExternalUrl.map((item) => (
                      <li className='d-flex align-items-center w-100'>
                        <InputComponent
                          type={InputType.text}
                          value={item}
                          className='form-control'
                          counter={false}
                          disabled={true}
                          readOnly={true}
                          rowWidth='98%'
                          isExternalURL={true}
                        ></InputComponent>
                        <div
                          style={{
                            paddingBottom: '0px',
                            paddingRight: '0px',
                            paddingTop: '0px',
                            paddingLeft: '5px',
                          }}
                        >
                          <button
                            className='btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0'
                            disabled={this.state.searchLoading}
                            style={{ background: '#F3AEAF' }}
                            onClick={() => {
                              this.handelDeleteExternalLink(item);
                            }}
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='15'
                              height='15'
                              viewBox='0 0 15 15'
                              fill='none'
                            >
                              <rect width='15' height='15' fill='white' fill-opacity='0.01' />
                              <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M5.5 1.00009C5.22386 1.00009 5 1.22395 5 1.50009C5 1.77623 5.22386 2.00009 5.5 2.00009H9.5C9.77614 2.00009 10 1.77623 10 1.50009C10 1.22395 9.77614 1.00009 9.5 1.00009H5.5ZM3 3.50009C3 3.22395 3.22386 3.00009 3.5 3.00009H5H10H11.5C11.7761 3.00009 12 3.22395 12 3.50009C12 3.77623 11.7761 4.00009 11.5 4.00009H11V12.0001C11 12.5524 10.5523 13.0001 10 13.0001H5C4.44772 13.0001 4 12.5524 4 12.0001V4.00009H3.5C3.22386 4.00009 3 3.77623 3 3.50009ZM5 4.00009H10V12.0001H5V4.00009Z'
                                fill='#D93D42'
                              />
                            </svg>
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>

                  <div className='d-flex'>
                    <InputComponent
                      disabled={this.state.searchLoading}
                      type={InputType.text}
                      placeholder='https://'
                      className='control-border-color'
                      value={this.state.External}
                      rowWidth='98%'
                      counter={false}
                      onChange={(e) => {
                        this.handleChange('External', e.target.value);
                      }}
                      onEnter={(e) => {
                        this.addExternalUrl();
                      }}
                    ></InputComponent>
                    <div style={{ padding: '5px', paddingRight: '0px' }}>
                      <button
                        className='btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0'
                        disabled={this.state.searchLoading}
                        onClick={() => {
                          this.addExternalUrl();
                        }}
                      >
                        <i className='fa fa-plus'></i>
                      </button>
                    </div>
                  </div>

                  <div className='item'>
                    <InputComponent
                      disabled={this.state.searchLoading}
                      type={InputType.text}
                      label='Warranty and technical support'
                      placeholder='Specialist name'
                      value={this.state.technicianName}
                      // popQuestion="Technician Name:"
                      onChange={(e) => {
                        this.handleChange('technicianName', e.target.value);
                      }}
                    ></InputComponent>
                  </div>
                  <div className='item'>
                    <InputComponent
                      type={InputType.text}
                      label='Contract ID'
                      placeholder='Contract ID'
                      disabled={this.state.searchLoading}
                      value={this.state.contractId}
                      onChange={(e) => {
                        this.handleChange('contractId', e.target.value);
                      }}
                      optional='optional'
                      // inValid={this.validator.message(
                      //   "technician Email",
                      //   this.state.technicianEmail,
                      //   "required|email"
                      // )}
                    ></InputComponent>
                  </div>
                  <div className='item'>
                    <InputComponent
                      type={InputType.text}
                      disabled={this.state.searchLoading}
                      label='Email*'
                      placeholder='Email'
                      value={this.state.technicianEmail}
                      onChange={(e) => {
                        this.handleChange('technicianEmail', e.target.value);
                      }}
                    ></InputComponent>
                  </div>
                  <div className='item'>
                    <InputComponent
                      type={InputType.text}
                      disabled={this.state.searchLoading}
                      label='Phone*'
                      placeholder='Phone'
                      value={this.state.technicianPhone}
                      onChange={(e) => {
                        this.handleChange('technicianPhone', e.target.value);
                      }}
                    ></InputComponent>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <MainButton
                className='btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2'
                svg={
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='15'
                    height='15'
                    viewBox='0 0 15 15'
                    fill='none'
                  >
                    <rect width='15' height='15' fill='white' fill-opacity='0.01' />
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M3.5 2.00006C3.22386 2.00006 3 2.22392 3 2.50006V12.5001C3 12.7762 3.22386 13.0001 3.5 13.0001H11.5C11.7761 13.0001 12 12.7762 12 12.5001V4.70717L9.29289 2.00006H3.5ZM2 2.50006C2 1.67163 2.67157 1.00006 3.5 1.00006H9.5C9.63261 1.00006 9.75979 1.05274 9.85355 1.14651L12.7803 4.07328C12.921 4.21394 13 4.4047 13 4.60361V12.5001C13 13.3285 12.3284 14.0001 11.5 14.0001H3.5C2.67157 14.0001 2 13.3285 2 12.5001V2.50006ZM4.75 7.50006C4.75 7.22392 4.97386 7.00006 5.25 7.00006H7V5.25006C7 4.97392 7.22386 4.75006 7.5 4.75006C7.77614 4.75006 8 4.97392 8 5.25006V7.00006H9.75C10.0261 7.00006 10.25 7.22392 10.25 7.50006C10.25 7.7762 10.0261 8.00006 9.75 8.00006H8V9.75006C8 10.0262 7.77614 10.2501 7.5 10.2501C7.22386 10.2501 7 10.0262 7 9.75006V8.00006H5.25C4.97386 8.00006 4.75 7.7762 4.75 7.50006Z'
                      fill='white'
                    />
                  </svg>
                }
                onClick={() => {
                  this.UpdateEquipment();
                }}
                loading={this.state.loading || this.state.searchLoading}
                disabled={this.state.loading || this.state.searchLoading}
              >
                Update Equipment
              </MainButton>
            </div>
          </div>
        </div>
      </div>
      // <div className="container-fluid research new-research">
      //   <div className="row"></div>
      //   <div className="col-12 box-content p-3">
      //     <h5 className="b-title d-flex align-items-center">
      //       <IoIosArrowDropleftCircle
      //         className="me-3 textSecondary2 cursorPointer"
      //         size="1.5em"
      //         onClick={() => {
      //           window.history.back();
      //         }}
      //       />{" "}
      //       Update equipment profile
      //     </h5>
      //     <div className="form row">
      //       <div className="col-md-6 left beforeNoneMd">
      //         <div className="item">
      //           <InputComponent
      //           disabled={this.state.searchLoading}
      //             type={InputType.text}
      //             label="Equipment name"
      //             popQuestion="Please enter the name of this equipment. This will be the primary way to identify the equipment among list of equipment."
      //             onChange={(e) => {
      //               this.handleChange("title", e.target.value);
      //             }}
      //             inValid={this.validator.message(
      //               "Equipment Nickname",
      //               this.state.title,
      //               "required"
      //             )}
      //             value={this.state.title}
      //           ></InputComponent>
      //         </div>
      //         <div className="item">
      //           <span className="label d-flex align-items-center">
      //             Equipment picture
      //             <div
      //               // type={MainButtonType.light}
      //               // children={optional}
      //               // borderRadius="50px"
      //               // fontSize="15px"
      //               className="s-chip filled mx-2"
      //             >
      //               optional
      //             </div>
      //             <CircleIconTooltip
      //               text="Please upload an image of the equipment to improve the user experience."
      //             />
      //           </span>

      //           <GetPictureAndCrop
      //           disabled={this.state.searchLoading}
      //             originalImage={this.state.imageUrl?.name || ""}
      //             defaultPicture="/Images/images/equipment_picture.svg"
      //             style={{ marginTop: "0.4em" }}
      //             picture={
      //               this.state.imageUrl
      //                 ? IMAGE_BASE_URL + this.state.imageUrl.name
      //                 : ""
      //             }
      //             onRemove={() => {

      //               // const _pictureId = Number(this.state.imageUrl.split('/')[this.state.imageUrl.split('/').length - 1].split('.')[0])

      //               this.setState({
      //                 imageUrl: "",
      //                 picture: [],
      //                 removedMediasId: [
      //                   ...this.state.removedMediasId,
      //                   this.state.imageUrl?.id,
      //                 ],
      //               });
      //             }}
      //             onSave={(_pictures: any) => {
      //               this.setState({
      //                 picture: _pictures,
      //                 imageUrl: URL.createObjectURL(_pictures[0]),
      //               });
      //             }}
      //           />

      //           {/* <div className="d-flex justify-content-between align-items-center">
      //             {this.state.imageUrl && this.state.imageUrl.length > 2 ? (
      //               <Fragment>
      //                 <img
      //                   src={
      //                     !this.state.cahngePic
      //                       ? AppConstants.base_url_image + this.state.imageUrl
      //                       : this.state.imageUrl
      //                   }
      //                   alt="Avatar"
      //                   className="rounded-circle avatar mx-2"
      //                   width="125px"
      //                   height="125px"
      //                 />
      //                 <CircleIcon
      //                   type={ThemeCircleIcon.dark}
      //                   width="22px"
      //                   height="22px"
      //                   className="mx-2 px-2 pointer"
      //                   onClick={() => {
      //                     this.setState({
      //                       picture: [],
      //                       imageUrl: "",
      //                     });
      //                   }}
      //                 >
      //                   <img
      //                     src="/images/icons/garbage_can.svg"
      //                     alt="radvix"
      //                     width={15}
      //                     height={15}
      //                   />
      //                 </CircleIcon>
      //               </Fragment>
      //             ) : null}
      //             <Dropzone onDrop={this.onDropPic}>
      //               {({ getRootProps, getInputProps }) => (
      //                 <section className="container fileUploadBox">
      //                   <div {...getRootProps({ className: "dropzone" })}>
      //                     <input {...getInputProps()} multiple={false} />
      //                     <MainButton
      //                       type={MainButtonType.light}
      //                       minHeight="30px"
      //                       minWidth="179px"
      //                       fontSize="14px"
      //                       borderRadius="50px"
      //                       backgroundColor="#fff"
      //                       border="1px solid #707070"
      //                       color="#707070"
      //                       className="mt-4"
      //                       children={
      //                         <div className="d-flex justify-content-between align-items-center">
      //                           <img
      //                             src="/Images/icons/cloud_computing.svg"
      //                             alt="sssss"
      //                             height="20"
      //                           />{" "}
      //                           <span className="flex-fill">
      //                             Browse Local Files
      //                           </span>
      //                         </div>
      //                       }
      //                     ></MainButton>
      //                     <p>Or drag and drop files here</p>
      //                   </div>
      //                   <aside>
      //                     <ul>{picture}</ul>
      //                   </aside>
      //                 </section>
      //               )}
      //             </Dropzone>
      //           </div> */}
      //         </div>
      //         <div className="item">
      //           <InputComponent
      //           disabled={this.state.searchLoading}
      //             type={InputType.text}
      //             label="Manufacturer"
      //             popQuestion="Please enter the manufacturer of this equipment. You can always update this later."
      //             optional="optional"
      //             onChange={(e) => {
      //               this.handleChange("manufacturer", e.target.value);
      //             }}
      //             value={this.state.manufacturer}
      //           ></InputComponent>
      //         </div>

      //         <div className="item">
      //           <InputComponent
      //           disabled={this.state.searchLoading}
      //             type={InputType.text}
      //             label="Model"
      //             popQuestion="Please enter the technical model name or ID for this equipment. This is helpful for technical support in the future."
      //             optional="optional"
      //             onChange={(e) => {
      //               this.handleChange("model", e.target.value);
      //             }}
      //             value={this.state.model}
      //           ></InputComponent>
      //         </div>
      //         <div className="item">
      //           <InputComponent
      //             type={InputType.textarea}
      //             disabled={this.state.searchLoading}
      //             label="Description"
      //             popQuestion="Please briefly explain what is the primary use case of this equipment. This will help new users to better understand the use case of equipment prior to reviewing the SOP and protocols."
      //             optional="optional"
      //             onChange={(e) => {
      //               this.handleChange("description", e.target.value);
      //             }}
      //             value={this.state.description}
      //           ></InputComponent>
      //         </div>
      //         <div className="item">
      //           <RadioGroup
      //             label="Equipment status"
      //             disabled={this.state.searchLoading}
      //             popQuestion="Here you can update the status of this equipment. When an equipment changes status, all users who have access to this equipment will be notified."
      //             TextItem="name"
      //             ValueItem="id"
      //             name="Currency"
      //             items={this.state.listequipmentStatus}
      //             onChange={(e) => {
      //               this.handleChange("status", parseInt(e.target.value));
      //             }}
      //             Selected={this.state.status}
      //           ></RadioGroup>
      //         </div>
      //       </div>
      //       <div className="col-md-6 right">
      //         <div className="item">
      //           <SelectComponent
      //             items={this.state.listLaboratory}
      //             disabled={this.state.searchLoading}
      //             defaultValue={this.state.selectedLaboratories}
      //             TextItem="title"
      //             ValueItem="id"
      //             // className="my-2"
      //             label="Select laboratories to assign this equipment to"
      //             popQuestion="Here you can select one or multiple laboratories to assign this equipment to them. If users have full access to those laboratories, they will automatically have access to this equipment."
      //             placeholder="Select laboratories"
      //             onChange={(e) => {
      //               this.setState({
      //                 selectedLaboratories: e,
      //               });
      //               // this.handelChangeSelect(e);
      //             }}
      //             isMulti
      //           ></SelectComponent>
      //         </div>
      //         {/* <div className="teams Labs">
      //           <div className="tags p-3">
      //             {this.state.Laboratories.map((item) => (
      //               <div key={item.id}>
      //                 <MainButton
      //                   backgroundColor="#EBEBEB"
      //                   className="tag-delete"
      //                   children={
      //                     <div className="d-flex align-items-center justify-content-between">
      //                       <span className="flex-fill">{item.title}</span>
      //                       <CircleIcon
      //                         type={ThemeCircleIcon.dark}
      //                         width="22px"
      //                         height="22px"
      //                         onClick={() => {
      //                           this.handelRemoveLaboratoy(item.id);
      //                         }}
      //                       >
      //                         <img
      //                           src="/images/icons/garbage_can.svg"
      //                           alt="radvix"
      //                           width={15}
      //                           height={15}
      //                         />
      //                       </CircleIcon>
      //                     </div>
      //                   }
      //                   type={MainButtonType.light}
      //                   borderRadius="24px"
      //                   fontSize="14px"
      //                 ></MainButton>
      //               </div>
      //             ))}
      //           </div>
      //         </div> */}
      //         <div className="item">
      //           <span className="label d-flex align-items-center">
      //             Equipment document and protocols
      //             <div
      //               // type={MainButtonType.light}
      //               // children={optional}
      //               // borderRadius="50px"
      //               // fontSize="15px"
      //               className="s-chip filled mx-2"
      //             >
      //               optional
      //             </div>
      //             <CircleIconTooltip
      //               text="Here you can attach any document or protocols that users who have access to this equipment need to review. These documents will appear on equipment profile."
      //             />
      //           </span>
      //           <Dropzone onDrop={this.onDrop}>
      //             {({ getRootProps, getInputProps }) => (
      //               <section
      //                 style={{ marginTop: "0.35em" }}
      //                 className="container fileUploadBox"
      //               >
      //                 <div {...getRootProps({ className: "dropzone" })}>
      //                   <input {...getInputProps()} />
      //                   <MainButton
      //           disabled={this.state.searchLoading}
      //                     type={MainButtonType.light}
      //                     minHeight="30px"
      //                     minWidth="179px"
      //                     fontSize="14px"
      //                     borderRadius="50px"
      //                     backgroundColor="#fff"
      //                     border="1px solid #707070"
      //                     color="#707070"
      //                     className="mt-4"
      //                     children={
      //                       <div className="d-flex justify-content-between align-items-center">
      //                         <img
      //                           src="/Images/icons/cloud_computing.svg"
      //                           alt="sssss"
      //                           height="20"
      //                         />{" "}
      //                         <span className="flex-fill">
      //                           Browse Local Files
      //                         </span>
      //                       </div>
      //                     }
      //                   ></MainButton>
      //                   <p>Or drag and drop files here</p>
      //                 </div>
      //                 <aside>
      //                   <ul>{files}</ul>
      //                 </aside>
      //               </section>
      //             )}
      //           </Dropzone>
      //           <ul className="file-list d-flex flex-wrap mt-1">
      //             {this.state.Medias.filter(
      //               (item) => item.externalUrl === null
      //             ).map((item) => (
      //               <li className="d-flex align-items-center me-3 max1LineText">
      //                 <File file={item} />
      //                 <CircleIcon
      //           disabled={this.state.searchLoading}
      //                   type={ThemeCircleIcon.dark}
      //                   width="22px"
      //                   height="22px"
      //                   padding="0"
      //                   className="ms-1 pointer"
      //                   onClick={() => {
      //                     this.handelRemoveMedia(item.id);
      //                   }}
      //                 >
      //                   <img
      //                     src="/images/icons/garbage_can.svg"
      //                     alt="radvix"
      //                     width={15}
      //                     height={15}
      //                   />
      //                 </CircleIcon>
      //               </li>
      //             ))}
      //           </ul>
      //         </div>
      //         <div className="item d-flex justify-content-between align-items-center mt-1">
      //           <InputComponent
      //             type={InputType.text}
      //             disabled={this.state.searchLoading}
      //             placeholder="https://"
      //             className="mx-2"
      //             onChange={(e) => {
      //               this.handleChange("External", e.target.value);
      //             }}
      //             onEnter={(e) => {
      //               this.addExternalUrl();
      //             }}
      //             value={this.state.External}
      //           ></InputComponent>
      //           <CircleIcon
      //             width="36px"
      //             height="36px"
      //             padding="0"
      //             type={ThemeCircleIcon.dark}
      //             disabled={this.state.searchLoading}
      //             backgroundColor="#9D9D9D"
      //             fontSize="18px"
      //             color="#ffffff"
      //             className="px-3"
      //             onClick={() => {
      //               this.addExternalUrl();
      //             }}
      //           >
      //             <i className="fas fa-plus"></i>
      //           </CircleIcon>
      //         </div>
      //         <p className="text-error mb-0">
      //           {this.validator.message("input", this.state.External, "url")}
      //         </p>
      //         <ul className="file-list mt-1 d-flex flex-column">
      //           {this.state.Medias.filter((item) => item.externalUrl).map(
      //             (item, index) => (
      //               <li key={index} className="my-1 d-flex align-items-center">
      //                 <File file={item} />
      //                 {/* <MainButton
      //                   children={item.externalUrl}
      //                   type={MainButtonType.dark}
      //                   borderRadius="24px"
      //                   fontSize="14px"
      //                   backgroundColor="#F5F5F5"
      //                   color="#096BFF"
      //                   className="text-truncate"
      //                 ></MainButton> */}
      //                 <CircleIcon
      //                   type={ThemeCircleIcon.dark}
      //                   disabled={this.state.searchLoading}
      //                   width="22px"
      //                   padding="0"
      //                   height="22px"
      //                   className="ms-2"
      //                   onClick={() => {
      //                     this.handelRemoveMedia(item.id!);
      //                   }}
      //                 >
      //                   <img
      //                     src="/images/icons/garbage_can.svg"
      //                     alt="radvix"
      //                     width={15}
      //                     height={15}
      //                   />
      //                 </CircleIcon>
      //               </li>
      //             )
      //           )}
      //           {this.state.ExternalUrl.map((item) => (
      //             <li className="my-1 d-flex align-items-center max1LineText">
      //               <a
      //                 title="Go to the link"
      //                 className="max1LineText s-chip outlined fs-200 blueColor"
      //                 href={item}
      //                 target="_blank"
      //                 rel="noopener noreferrer"
      //               >
      //                 {item}
      //               </a>
      //               <CircleIcon
      //           disabled={this.state.searchLoading}
      //                 type={ThemeCircleIcon.dark}
      //                 width="22px"
      //                 height="22px"
      //                 padding="0"
      //                 className="ms-2 pointer"
      //                 onClick={() => this.handelDeleteExternalLink(item)}
      //               >
      //                 <img
      //                   src="/images/icons/garbage_can.svg"
      //                   alt="radvix"
      //                   width={15}
      //                   height={15}
      //                 />
      //               </CircleIcon>
      //             </li>
      //           ))}
      //         </ul>
      //         <div className="item mt-0">
      //           <span className="label d-flex align-items-center">
      //             Warranty and technical support
      //             <div
      //               // type={MainButtonType.light}
      //               // children={optional}
      //               // borderRadius="50px"
      //               // fontSize="15px"
      //               className="s-chip filled mx-2"
      //             >
      //               optional
      //             </div>
      //             {/* <MainButton
      //               type={MainButtonType.light}
      //               children={"optional"}
      //               borderRadius="50px"
      //               fontSize="15px"
      //               className="mx-2"
      //             ></MainButton> */}
      //             <CircleIconTooltip
      //               text="Please enter the technical support or warranty information for this equipment. "
      //             />
      //           </span>

      //           <InputComponent
      //             type={InputType.text}
      //             disabled={this.state.searchLoading}
      //             label="Specialist name"
      //             onChange={(e) => {
      //               this.handleChange("technicianName", e.target.value);
      //             }}
      //             value={this.state.technicianName}
      //           ></InputComponent>
      //         </div>
      //         <div className="item">
      //           <InputComponent
      //             type={InputType.text}
      //             disabled={this.state.searchLoading}
      //             label="Contract ID"
      //             onChange={(e) => {
      //               this.handleChange("contractId", e.target.value);
      //             }}
      //             value={this.state.contractId}
      //           ></InputComponent>
      //         </div>
      //         <div className="item">
      //           <InputComponent
      //             type={InputType.text}
      //             disabled={this.state.searchLoading}
      //             label="Email"
      //             onChange={(e) => {
      //               this.handleChange("technicianEmail", e.target.value);
      //             }}
      //             value={this.state.technicianEmail}
      //           ></InputComponent>
      //         </div>
      //         <div className="item">
      //           <InputComponent
      //           disabled={this.state.searchLoading}
      //             type={InputType.text}
      //             label="Phone"
      //             onChange={(e) => {
      //               this.handleChange("technicianPhone", e.target.value);
      //             }}
      //             value={this.state.technicianPhone}
      //           ></InputComponent>
      //         </div>
      //         {/* <div className="item">
      //           <SelectComponent
      //             items={
      //               this.state.listLaboratory
      //                 .filter(item => this.state.Laboratories.findIndex(lab => lab.id === +item.value) === -1)
      //             }
      //             TextItem="title"
      //             ValueItem="id"
      //             className="my-2"
      //             label="Assign To Laboratory:"
      //             popQuestion="Assign To Laboratory:"
      //             onChange={(e) => {
      //               this.handelChangeSelect(e);
      //             }}
      //             isMulti
      //           ></SelectComponent>
      //         </div>
      //         <div className="teams Labs">
      //           <div className="tags p-3">
      //             {this.state.Laboratories.map((item) => (
      //               <div key={item.id}>
      //                 <MainButton
      //                   backgroundColor="#EBEBEB"
      //                   className="tag-delete"
      //                   children={
      //                     <div className="d-flex align-items-center justify-content-between">
      //                       <span className="flex-fill">{item.title}</span>
      //                       <CircleIcon
      //                         type={ThemeCircleIcon.dark}
      //                         width="22px"
      //                         height="22px"
      //                         onClick={() => {
      //                           this.handelRemoveLaboratoy(item.id);
      //                         }}
      //                       >
      //                         <img
      //                           src="/images/icons/garbage_can.svg"
      //                           alt="radvix"
      //                           width={15}
      //                           height={15}
      //                         />
      //                       </CircleIcon>
      //                     </div>
      //                   }
      //                   type={MainButtonType.light}
      //                   borderRadius="24px"
      //                   fontSize="14px"
      //                 ></MainButton>
      //               </div>
      //             ))}
      //           </div>
      //         </div> */}
      //       </div>
      //       <div className="col-12 d-flex flex-column justify-content-center align-items-center my-4">
      //         <MainButton
      //           type={MainButtonType.dark}
      //           children={"Update equipment"}
      //           borderRadius="50px"
      //           fontSize="18px"
      //           className="mx-2 px-3"
      //           minHeight="43px"
      //           minWidth="136px"
      //           onClick={() => {
      //             this.UpdateEquipment();
      //           }}
      //           loading={this.state.loading || this.state.searchLoading}
      //           disabled={this.state.searchLoading || this.state.loading}
      //         ></MainButton>

      //         <span className="mt-2 textSecondary">
      //           {this.state.loadingMode}
      //         </span>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}
