import React from 'react'
import { CircleIcon } from '../circle_icon';
import { RxMagnifyingGlass } from 'react-icons/rx';
import { InputIcon } from '../search_box';
import { MdClose } from 'react-icons/md';

interface PhoneSearchTypes {
    searchIcon?: React.ReactNode
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    buttonClassName?: string;
    phoneSearchInputStyle?: React.CSSProperties;
    buttonStyle?: React.CSSProperties;
};

const PhoneSearch = ({
    searchIcon,
    onChange,
    value,
    buttonClassName = "",
    phoneSearchInputStyle = {},
    buttonStyle = {}
}: PhoneSearchTypes) => {
    const [show, setShow] = React.useState(false);
    return (
        <div className="dNone dFlexSm align-items-center">
            <div className='phoneSearchInput' style={{
                zIndex: show ? 1 : -1
                ,...phoneSearchInputStyle}}>
                <InputIcon
                    parentClassName='w-100 me-2'
                    chilren={
                        searchIcon
                    }
                    style={{
                        visibility: show ? 'visible' : 'hidden',
                    }}
                    placeholder="Search..."
                    value={value}
                    onChange={onChange}
                ></InputIcon>
            </div>
            <CircleIcon
                onClick={() => setShow(!show)}
                children={show ? <MdClose size='1.5em' /> : <RxMagnifyingGlass size='1.5em' />}
                height="2em"
                width="2em"
                padding="0"
                className={buttonClassName}
                style={buttonStyle}
            ></CircleIcon>
        </div>
    )
};

export default PhoneSearch