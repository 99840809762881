import React from "react";
import ReactPaginate from "react-paginate";
import { CircleIcon, ThemeCircleIcon } from "../circle_icon";
import { useMediaQuery } from 'react-responsive';

const Pagination = ({
  pageCount,
  onPageChange,
  onPageSizeChange,
  pageSizeOptions = [10, 15, 20],
  initialPage = 0,
  pageInput,
  handlePageNumberInput,
}: any) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 576px)' });
  console.log("page count:" + pageCount)
  return (
    <div className="py-3 px-3">
      <div className="d-flex justify-content-sm-between justify-content-center align-items-center gap-3 flex-wrap">
        <div className="d-flex align-items-center gap-px-10 justify-content-sm-center justify-content-between w-sm-auto w-100">
          <h2 className="fw-normal fs-13 mb-0 color-gray-600">Show Data:</h2>
          <select
            className="h-px-26 form-select color-gray-1000 py-0 fs-13 w-px-70 ps-2 pe-0"
            style={{ borderRadius: "0.25rem", borderColor: "var(--gray-400)" }}
            onChange={(e) => onPageSizeChange({label: e.target.value, value: parseInt(e.target.value)})}
          >
            {pageSizeOptions.map((size: any) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="d-flex align-items-center mobile-pagination">
          <ReactPaginate
          
            previousLabel={
              <CircleIcon
                className="page-item"
                width="24px"
                backgroundColor="#6E56CF"
                height="24px"
                padding="0"
                type={ThemeCircleIcon.dark}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <rect
                    y="0.500641"
                    width="16"
                    height="16"
                    rx="8"
                    fill="#6E56CF"
                  ></rect>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.43131 3.84479C9.64619 4.04625 9.65708 4.38376 9.45563 4.59865L5.79759 8.50055L9.45563 12.4024C9.65708 12.6173 9.64619 12.9548 9.43131 13.1563C9.21642 13.3577 8.8789 13.3468 8.67745 13.132L4.67745 8.86532C4.48512 8.66016 4.48512 8.34093 4.67745 8.13578L8.67745 3.86911C8.8789 3.65422 9.21642 3.64334 9.43131 3.84479Z"
                    fill="white"
                  ></path>
                </svg>
              </CircleIcon>
            }
            nextLabel={
              <CircleIcon
                className="page-item"
                width="24px"
                backgroundColor="#6E56CF"
                height="24px"
                padding="0"
                type={ThemeCircleIcon.dark}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M0 8.50064C0 4.08236 3.58172 0.500641 8 0.500641C12.4183 0.500641 16 4.08236 16 8.50064C16 12.9189 12.4183 16.5006 8 16.5006C3.58172 16.5006 0 12.9189 0 8.50064Z"
                    fill="#6E56CF"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.56896 3.84472C6.78384 3.64326 7.12136 3.65415 7.32282 3.86904L11.3228 8.13571C11.5151 8.34085 11.5151 8.66009 11.3228 8.86524L7.32282 13.1319C7.12136 13.3468 6.78384 13.3577 6.56896 13.1562C6.35407 12.9547 6.34318 12.6172 6.54464 12.4024L10.2027 8.50048L6.54464 4.59858C6.34318 4.38369 6.35407 4.04617 6.56896 3.84472Z"
                    fill="white"
                  ></path>
                </svg>
              </CircleIcon>
            }
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={0}
            pageRangeDisplayed={isSmallScreen ? 1 : 5}
            onPageChange={onPageChange}
            initialPage={initialPage}
            containerClassName={"pagination pagination-separte-space"}
            activeClassName={"inactive-pagination active-background"}
            activeLinkClassName={"inactive-pagination-link active-background-link"}
            pageClassName="inactive-pagination"
            pageLinkClassName="inactive-pagination-link"
            
          />
<div className="pagination-parent-separte separte-pagination-mobile"><div className="pagination-child-separte"></div></div>
          <a className="fw-medium fs-13 mb-0 color-gray-1000 ps-3 border-gray-400 gotoPage-text">
            Go to page
          </a>
          <input
            type="text"
            className="form-control gotoPage-input"
            style={{
              width: "50px",
              height: "30px",
              marginRight: "10px",
            }}
            //value={pageInput}
            onChange={() => handlePageNumberInput}
          />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
