import React from "react";
import { store } from "../../../data/storeMain";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import { IconEnum, InputComponent, InputType } from "../../components/inputs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MainButton, MainButtonType } from "../../components/button";
import Dropzone from "react-dropzone";
// import { RadioGroup } from "../../components/radio_group";
import { ButtonGroup } from "../../components/botton_group";
import { RouteComponentProps, withRouter } from "react-router";
import { AppConstants, AppRoutes, LOADING_MODE } from "../../../core/constants";
import { ResearchController } from "../../../controllers/research/research_controller";
import SimpleReactValidator from "simple-react-validator";
import { UploadController } from "../../../controllers/upload_media/upload_media";
// import {
//   SetResearchId,
//   SetResearchUpdated,
// } from "../../../data/storeMain/actions/research_action";
import {
  $ReplaceRoute,
  // $truncate,
  convertTeamUserToNewTree,
  // convertTeamUserToTree,
  // convertUsersIdsToIds,
  convertUsersToUncategorized,
  // filterUsersNotExistInTeam,
  // getUserTitle,
  isTokenInHttpAuth,
  uploadAllFiles,
  uploadAllLinks,
  uploadToS3PresignedUrl,
  // uploadToS3PresignedUrl,
} from "../../../core/utils";
import { LocalDataSources } from "../../../data/local_datasources";
// import { InputLabel } from "../../components/input_label";
// import { TreeMenu } from "../../components/TreeMenu";
import { HTTP } from "../../../core/http_common";
import { IoIosArrowDropleftCircle } from "react-icons/io";
// import { MdAdd } from "react-icons/md";
import NewTreeMenu, { TreeOptionType } from "../../components/NewTreeMenu";
import CircleIconTooltip from "../../components/CircleIconTooltip";
import { SelectComponent } from "../../components/select_input";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import { Button } from "@radix-ui/themes";
import { TreeMenu } from "../../components/TreeMenu";
import { MdAdd } from "react-icons/md";
import { BaseButton } from "@fluentui/react";
import { SetResearchId } from "../../../data/storeMain/actions/research_action";
import axios from "axios";
import { SetHeaderTitle } from "../../../data/storeMain/actions/header_title_action";
// import SpinnerLoader from "../../components/spinnerLoader";
const ONE_DAY = 24 * 60 * 60 * 1000;
type StateType = {
  selectedTeamsMembers: Array<any>;
  listTeams: Array<any>;
  listWorkspaces: Array<any>;
  forceSelectedMembers: string[];
  unCheckedValues: string[];
  listGroups: Array<any>;
  startDate: Date;
  endDate: Date;
  isLockEndTime: boolean;
selectedGroup: number | null;
  files: Array<File>;
  title: string;
  description: string;
  currency: number;
  priority: number;
  teamsId: Array<number>;
  usersId: Array<string>;
  status: number;
  loading: boolean;
  ExternalUrl: Array<string>;
  External: string;
  searchLoading: boolean;
  listTeamMembers: TreeOptionType[];
  selectedTeamsId: number[];
  selectedUsersId: string[];
  loadingMode: string;
  defaultWorkspace: TreeOptionType[];
  loginWorkSpace: any[];
  selectedIcon: IconEnum | null;
};
class ResearchPageNew extends React.Component<RouteComponentProps> {
  RoleUser = store.getState().userRole;
  controller = new ResearchController();
  UploadController = new UploadController();
  local = new LocalDataSources();
  validator = new SimpleReactValidator({
    className: "text-danger",
  });
  state: StateType = {
    startDate: new Date(),
    loadingMode: "",
    endDate: new Date(new Date().getTime() + ONE_DAY),
    isLockEndTime: true,
    unCheckedValues: [],
    forceSelectedMembers: [],
    listTeams: [],
    listWorkspaces: [],
    listGroups: [],
    selectedTeamsMembers: [],
    files: [],
    title: "",
    description: "",
    currency: 0,
    priority: 1,
    teamsId: [],
    usersId: [],
    status: 0,
    loading: false,
    ExternalUrl: [],
    External: "",
    searchLoading: false,
    listTeamMembers: [],
    selectedTeamsId: [],
    selectedUsersId: [],
    defaultWorkspace: [],
    loginWorkSpace: [],
    selectedGroup: null,
    selectedIcon: null,
  };

  handleWorkspaceClick = (selectedWorkspaceId: any) => {
    {
      const _cantFindYourSelf = !selectedWorkspaceId.find(
        (item: any) => item.value === this.state.loginWorkSpace[0].value
      );

      if (_cantFindYourSelf) {
        return toast.error("You can't remove yourself from workspace.");
      }
      this.setState({
        defaultWorkspace: [],
      });
      this.setState({
        searchLoading: true,
      });
      var a = this.state.defaultWorkspace;
      this.controller.GetWorkspacesTeams(
        (res) => {
          this.setState({
            defaultWorkspace: selectedWorkspaceId,
          });
          console.log("Workspace teams:", res);
          const _myId = this.local.getUserId();
          const _teams = convertTeamUserToNewTree(res.teams[0], [_myId]);
          const _uncategorizedTeam: TreeOptionType | false =
            res.users.length > 0
              ? convertUsersToUncategorized(res.users[0], [_myId])
              : false;

          const __listTeamMembers: TreeOptionType[] =
            _uncategorizedTeam &&
            _uncategorizedTeam.children &&
            _uncategorizedTeam.children.length > 0
              ? [_uncategorizedTeam, ..._teams]
              : _teams;

          this.setState({
            listTeamMembers: __listTeamMembers,
            searchLoading: false,
          });
          console.log("new teammmmmmmmmmmmmm: ", this.state.listTeamMembers);
        },
        (err) => {
          this.setState({
            searchLoading: false,
            defaultWorkspace: a,
          });
        },
        selectedWorkspaceId
      );
    }
  };

  handleChange(target: string, val: any) {;
    if (target === "priority") {
      this.setState({
        [target]: val,
      });
    } else {
      const sanitizedValue = DOMPurify.sanitize(val);
      this.setState({
        [target]: sanitizedValue,
      });
    }
  }
  handelChangeDate(
    startDate: Date = this.state.startDate,
    endDate: Date = this.state.endDate
  ): void {
    this.setState({
      startDate,
      endDate,
      isLockEndTime: false,
    });
  }
  handelCreateResearch() {
    if (this.validator.allValid()) {
      const workspaceIds = this.state.defaultWorkspace.map(
        (item) => item.value
      );
      const body = {
        title: this.state.title,
        description: this.state.description,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        currency: this.state.currency,
        priority: this.state.priority,
        teamsId: this.state.selectedTeamsId,
        projectGroupId: this.state.selectedGroup,
        usersId: this.state.selectedUsersId.filter((_user) => {
          return _user !== this.local.getUserId();
        }),
        status: 0,
        icon: this.state.selectedIcon === IconEnum.Default ? null : this.state.selectedIcon,
        workSpacesId: workspaceIds,
      };
      this.setState({
        loading: true,
        loadingMode: "Starting project..",
      });
      //
      this.controller.createResearch(
        body,
        async (res) => {;
          if (
            this.state.files.length > 0 ||
            this.state.ExternalUrl.length > 0 
          ) {
            await this.handelUpload(res.id);
            this.setState({
              files: [],
              title: "",
              description: "",
              startDate: new Date(),
              endDate: new Date(new Date().getTime() + ONE_DAY),
              currency: 1,
              priority: 2,
              teamsId: [],
              usersId: [],
              status: 0,
              loading: false,
              loadingMode: "",
            });
            window.dispatchEvent(
              new CustomEvent("HANDLE_RESEARCH_CHANGED", {
                detail: {
                  name: res.title,
                  id: res.id,
                },
              })
            );
            store.dispatch(
              SetResearchId({ label:res.title, value: res.id})
            );
            this.props.history.push(
              $ReplaceRoute(
                `${AppRoutes.profile_research.replace(
                  ":id",
                  res.id?.toString() ?? ""
                )}`
              )
            );
          } else {
            this.setState({
              files: [],
              title: "",
              description: "",
              startDate: new Date(),
              endDate: new Date(new Date().getTime() + ONE_DAY),
              isLockEndTime: true,
              currency: 2,
              priority: 2,
              teamsId: [],
              usersId: [],
              status: 0,
              loadingMode: "",
            });
            window.dispatchEvent(
              new CustomEvent("HANDLE_RESEARCH_CHANGED", {
                detail: {
                  name: res.title,
                  id: res.id,
                },
              })
            );
            store.dispatch(
              SetResearchId({ label:res.title, value: res.id})
            );
            this.props.history.push(
              $ReplaceRoute(
                `${AppRoutes.profile_research.replace(
                  ":id",
                  res.id?.toString() ?? ""
                )}`
              )
            );
          }
        },
        (err) => {
          this.setState({
            loading: false,
            loadingMode: "",
          });
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 MB';
  
    const m = 1024 * 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizeInMB = bytes / m;
  
    return `${sizeInMB.toFixed(dm)} MB`;
  }

  handleIconSelect = (icon: any) => {;
    this.setState({ selectedIcon: icon });
  };
  
  handelChangeSelectTest(
    e: Array<{ label: string; value: number; isUser: boolean }>
  ) {
    this.setState({
      testListDropdown: e,
      testListDropdownId: e.map((item) => item.value),
    });
  }
  componentDidMount() {
    document.title = "New Research | Radvix";
    store.dispatch(SetHeaderTitle('Projects'))
    if (!isTokenInHttpAuth(HTTP)) return;
    this.setState({
      searchLoading: true,
    });
    ;
    const queryParams = new URLSearchParams(this.props.location.search);
const groupId = parseInt(queryParams.get('groupId') ?? '0', 10);
this.setState({ selectedGroup: groupId }, () => {
  console.log("Selected Group State:", this.state.selectedGroup);
});
    this.controller.researchSearch((res) => {
      ;
      // const _OptionType: TreeOptionType = {}
      const _myId = this.local.getUserId();
      const _teams = convertTeamUserToNewTree(res.teams, [_myId]);
      const _uncategorizedTeam: TreeOptionType | false =
        res.users.length > 0
          ? convertUsersToUncategorized(res.users, [_myId])
          : false;
      const __listTeamMembers: TreeOptionType[] = _uncategorizedTeam
        ? [_uncategorizedTeam, ..._teams]
        : _teams;
      const _workSpaces = res.workspaces;
      const _workSpaceOptions = _workSpaces.map((workspace, index) => ({
        label: workspace.name,
        value: workspace.workspaceId,
        subscriptionId: workspace.subscriptionId,
        workspaceId: workspace.workspaceId,
      }));
      const _listGroups = res.groups?.map((groups, index) => ({
        label: groups.title,
        value: groups.id,
      }));
      const loginWorkSpace = _workSpaceOptions.filter(
        (x) => x.subscriptionId == res.mySubscriptionId
      );
      this.setState({
        selectedTeamsMembers: [_myId],
        selectedUsersId: [_myId],
        searchLoading: false,
        listTeamMembers: __listTeamMembers,
        listWorkspaces: _workSpaceOptions,
        defaultWorkspace: loginWorkSpace,
        loginWorkSpace: loginWorkSpace,
        listGroups: _listGroups,
      });
    });
  }

  async handelUpload(id: number) {
    this.setState({
      loadingMode: LOADING_MODE.links,
    });
    await uploadAllLinks(id, this.state.ExternalUrl, 0);

    this.setState({
      loadingMode: LOADING_MODE.files,
    });
    await uploadAllFiles(id, this.state.files, 0);
  }

  

  onDrop = (fileList: any) => {
    fileList.map((item: any, index: number) => {
      const found = this.state.files.find(
        (element: any) => element.name === item.name
      );
      if (found === undefined) {
        this.setState({ files: [fileList[index], ...this.state.files] });
      }
    });
  };
  handelDeleteFile(arg: File) {
    this.setState({
      files: this.state.files.filter((file) => file.name !== arg.name),
    });
  }
  addExternalUrl() {
    if (this.validator.fields.input) {
      let Url = [...this.state.ExternalUrl];
      if (this.state.External.length > 2) {
        Url.push(this.state.External);
      }
      this.setState({
        ExternalUrl: Url,
        External: "",
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  handelDeleteExternalLink(link: string) {
    this.setState({
      ExternalUrl: this.state.ExternalUrl.filter((item) => item !== link),
    });
  }
  bytesToMB(bytes: number) {
    return (bytes / (1024 * 1024)).toFixed(3);
  }
  render() {
    // console.log(this.state.selectedTeamMembers);
    // console.log("==================================");
    const _creatorId = localStorage.getItem("userId");
    const files = this.state.files.map((file: any) => (
      <li
        key={file.name}
        className="d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team"
        style={{marginBottom:'3px'}}
      >
        <span
          className="text-truncate col-11 mx-2" 
          style={{ fontFamily: "Sanfransisco", textAlign:'start' }}
        >
          {file.name} - {this.formatBytes(file.size)}
        </span>
        <CircleIcon
          type={ThemeCircleIcon.dark}
          width="22px"
          height="22px"
          padding="0"
          onClick={() => {
            this.handelDeleteFile(file);
          }}
          style={{ cursor: "pointer", display: "contents" }}
        >
          <img
            src="/images/icons/cross-1.svg"
            alt="radvix"
            width={15}
            height={15}
          />
        </CircleIcon>
      </li>
    ));
    // console.log(this.state)

    const oneDayMillis = 24 * 60 * 60 * 1000;
    const maxStartDate = new Date(this.state.endDate.getTime() - oneDayMillis)
      .toISOString()
      .split("T")[0];
    const minEndDate = new Date(this.state.startDate.getTime() + oneDayMillis)
      .toISOString()
      .split("T")[0];
    return (
      <div className="card">
        <div
          className="d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
        border-gray-400"
        >
          <div className="d-flex align-items-center">
            <BaseButton
              className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button"
              style={{fontWeight:"400", height:"19px"}}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className="fw-medium fs-15 mb-0 color-gray-1000">
              Create a new project
            </h2>
          </div>
        </div>
        <div className="p-3 overflow-auto">
          <div className="row">
            <div className="col-lg-6 mobileViewMargin">
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div>
                    <SelectComponent
                      disabled={this.state.searchLoading}
                      items={this.state.listWorkspaces}
                      defaultValue={this.state.defaultWorkspace}
                      TextItem="title"
                      ValueItem="id"
                      labelClass="color-gray-600 fs-15 me-2"
                      className="form-select control-border-color"
                      label="Workspaces*"
                      placeholder="Select workspaces"
                      onChange={(e) => {
                        this.handleWorkspaceClick(e);
                      }}
                      isMulti
                    ></SelectComponent>
                  </div>
                  <div className="item" style={{ paddingTop: "15px" }}>
                    <SelectComponent
                      disabled={this.state.searchLoading}
                      items={this.state.listGroups}
                      defaultValue={this.state.listGroups.find(x => x.value == this.state.selectedGroup)}
                      TextItem="title"
                      ValueItem="id"
                      labelClass="color-gray-600 fs-15 me-2"
                      className="form-select control-border-color"
                      label="Group*"
                      placeholder="Select group"
                      onChange={(e) => {
                        this.setState({selectedGroup : e.value})
                      }}
                      isMulti={false}
                    ></SelectComponent>
                  </div>
                  <div className="item" style={{ paddingTop: "15px" }}>
                    <InputComponent
                      className="form-control"
                      disabled={this.state.searchLoading}
                      addIcon={true}
                      type={InputType.text}
                      label="Project name*"
                      placeholder="Enter your project name"
                      selectedIcons={this.state.selectedIcon}
                      onIconSelect={this.handleIconSelect} 
                      popQuestion="This will be the name of your research project. You can always edit this later."
                      onChange={(e) => {
                        this.handleChange("title", e.target.value);
                      }}
                      inValid={this.validator.message(
                        "Research Name",
                        this.state.title,
                        "required"
                      )}
                    ></InputComponent>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="text-primary cursor-pointer">
                        <span className="color-gray-600 fs-15 me-2">
                          Scheduled timeline*
                          {/* <img
                            className="text-primary cursor-pointer"
                            src="/Images/icons/info-circled.svg"
                            alt="info"
                            style={{ paddingLeft: "5px" }}
                            title="Please select the start and anticipated deadline for this research project. This will help you track the progress. You can always modify these dates and no restrictions will be applied if a deadline is passed."
                          /> */}
                        </span>
                      </div>
                      <div className="row mt-1">
                        <div className="col-lg-6 mb-3">
                        <div className="date-picker-wrapper">
                          <DatePicker
                            disabled={this.state.searchLoading}
                            selected={this.state.startDate}
                            className="control-border-color"
                            onChange={(e) => {
                              this.handelChangeDate(
                                e as Date,
                                this.state.isLockEndTime &&
                                  (e as Date)?.getTime() >
                                    this.state.endDate?.getTime()
                                  ? new Date((e as Date)?.getTime() + ONE_DAY)
                                  : undefined
                              );
                            }}
                            maxDate={
                              this.state.isLockEndTime
                                ? undefined
                                : new Date(
                                    this.state.endDate?.getTime() - ONE_DAY
                                  )
                            }
                          />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                        <div className="date-picker-wrapper">
                          <DatePicker
                            selected={this.state.endDate}
                            disabled={this.state.searchLoading}
                            className="control-border-color"
                            onChange={(e) => {
                              this.handelChangeDate(
                                this.state.isLockEndTime &&
                                  (e as Date)?.getTime() <
                                    this.state.startDate?.getTime()
                                  ? new Date(
                                      (e as Date)?.getTime() -
                                        AppConstants.one_day_milis
                                    )
                                  : undefined,
                                e as Date
                              );
                            }}
                            minDate={
                              this.state.isLockEndTime
                                ? undefined
                                : new Date(
                                    this.state.startDate?.getTime() + ONE_DAY
                                  )
                            }
                          />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-lg-12 mb-3">
                      <label className="color-gray-600 fs-15 me-2 mb-1">
                        Priority*
                      </label>
                      {/* <img
                        className="text-primary cursor-pointer"
                        src="/Images/icons/info-circled.svg"
                        alt="info"
                        title="Priority will color tag your research projects and will help you identify or sort them easier based on their priority level."
                      /> */}
                      <div className="d-flex border-gray-400 border soft-radios p-1 rounded-px-4 w-100">
                        <div className="w-33_3">
                          <input
                            type="radio"
                            className="btn-check"
                            name="options"
                            id="low"
                            autoComplete="off"
                            checked={this.state.priority === 0}
                            onClick={() => this.handleChange("priority", 0)}
                          />
                          <label
                            className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                            htmlFor="low"
                          >
                            Low
                          </label>
                        </div>
                        <div className="w-33_3">
                          <input
                            type="radio"
                            className="btn-check"
                            name="options"
                            id="medium"
                            autoComplete="off"
                            checked={this.state.priority === 1}
                            onClick={() => this.handleChange("priority", 1)}
                          />
                          <label
                            className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                            htmlFor="medium"
                          >
                            Medium
                          </label>
                        </div>
                        <div className="w-33_3">
                          <input
                            type="radio"
                            className="btn-check"
                            name="options"
                            id="high"
                            autoComplete="off"
                            checked={this.state.priority === 2}
                            onClick={() => this.handleChange("priority", 2)}
                          />
                          <label
                            className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                            htmlFor="high"
                          >
                            High
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-lg-12 mb-3">
                      <div
                        className="text-primary cursor-pointer mb-1"
                        title="Info"
                      >
                        <label className="color-gray-600 fs-15 me-2">
                          Project description*
                        </label>
                        {/* <img
                          className="text-primary cursor-pointer"
                          src="/Images/icons/info-circled.svg"
                          alt="info"
                          title="You can write a brief description for this research project. This information will be shown on the project profile and everyone who is assigned to this research, will be able to read it."
                        /> */}
                      </div>
                      <InputComponent
                      type={InputType.textarea}
                      disabled={this.state.searchLoading}
                      //label="Project description"
                      //optional="optional"
                      height="132px"
                      popQuestion="You can write a description for your team. For example, what is the main focus of this team and what are the key objectives. You can always edit this later."
                      className="mt-1"
                      onChange={(e) => {
                        this.handleChange("description", e.target.value);
                      }}
                    ></InputComponent>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="col-lg-12 mb-3">
                <div className="d-flex align-items-center mb-1">
                  <label className="color-gray-600 fs-15 me-2">
                    Team and members*
                  </label>
                  {/* <img
                    className="text-primary cursor-pointer"
                    src="/Images/icons/info-circled.svg"
                    alt="info"
                    title="If you already have the teams or individual members for this project invited to Radvix, you can select and assign them here. You can always go back later and add members or teams in the future. Once you create this project, they will get a notification."
                  /> */}
                </div>
                <NewTreeMenu
                  //label="Teams and members:"
                  onChange={(_teams, _users) => {
                    // console.log({ _teams, _users })
                    this.setState({
                      selectedTeamsId: _teams,
                      selectedUsersId: _users,
                      selectedTeamsMembers: [..._teams, ..._users],
                    });
                  }}
                  popQuestion="If you already have the teams or individual members for this project invited to Radvix, you can select and assign them here. You can always go back later and add members or teams in the future. Once you create this project, they will get a notification."
                  loading={this.state.searchLoading}
                  // onSele ctedItems={() => {

                  // }}
                  disabled={this.state.searchLoading}
                  options={this.state.listTeamMembers}
                  forceSelectedChilds={_creatorId ? [_creatorId] : []}
                />
              </div>
              <div className="col-lg-12 mb-3">
                <div className="d-flex align-items-center mb-1">
                  <label className="color-gray-600 fs-15 me-2">
                    Attachment*
                  </label>
                  {/* <img
                    className="text-primary cursor-pointer"
                    src="/Images/icons/info-circled.svg"
                    alt="info"
                    title="Research attachments are generic documents that you would like anyone who has access to this research project read and have access to them. You can either upload them or Insert the link."
                  /> */}
                </div>
                <div className="p-px-12 bg-gray-200 border-gray-400 border rounded-px-4 gap-2 attachment-paddingleft">
                  <div className="justify-content-sm-between justify-content-center align-items-center flex-wrap row-gap-px-8">
                    <Dropzone onDrop={this.onDrop}>
                      {({ getRootProps, getInputProps }) => (
                        // <section
                        //   className="container fileUploadBox"
                        //   style={{ marginTop: "0.3em" }}
                        // >
                        <div {...getRootProps({ className: "" })}>
                          <input {...getInputProps()} />

                          <div className="d-flex justify-content-between align-items-center flex-wrap row-gap-px-8 responsive-justify-content">
                            <button className="btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0">
                              <div className="d-flex align-items-center">
                                <div className="me-1 mb-1">
                                  <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="15"
                                      height="15"
                                      fill="white"
                                      fill-opacity="0.01"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.81825 1.18197C7.64251 1.00624 7.35759 1.00624 7.18185 1.18197L4.18185 4.18197C4.00611 4.35771 4.00611 4.64263
              4.18185 4.81837C4.35759 4.9941 4.64251 4.9941 4.81825 4.81837L7.05005 2.58657V9.50005C7.05005 9.74858 7.25152 9.95005
              7.50005 9.95005C7.74858 9.95005 7.95005 9.74858 7.95005 9.50005V2.58657L10.1819 4.81837C10.3576 4.9941 10.6425 4.9941
              10.8182 4.81837C10.994 4.64263 10.994 4.35771 10.8182 4.18197L7.81825 1.18197ZM2.5 10.0001C2.77614 10.0001 3 10.2239 3
              10.5001V12.0001C3 12.5539 3.44565 13.0001 3.99635 13.0001H11.0012C11.5529 13.0001 12 12.5529 12 12.0001V10.5001C12
              10.2239 12.2239 10.0001 12.5 10.0001C12.7761 10.0001 13 10.2239 13 10.5001V12.0001C13 13.1041 12.1062 14.0001 11.0012
              14.0001H3.99635C2.89019 14.0001 2 13.1031 2 12.0001V10.5001C2 10.2239 2.22386 10.0001 2.5 10.0001Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </div>
                                Browse local file
                              </div>
                            </button>
                            <h3 className="fw-normal color-gray-700 fs-13 mb-0 flex-grow-1 text-center">
                              {" "}
                              Or drag and drop files here - Max 128MB{" "}
                            </h3>
                          </div>
                          {files.length > 0 && (
                            <div style={{ paddingTop: "10px" }}>
                              <ul
                                className="list-unstyled fw-normal"                                
                              >
                                {files}
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>

                <ul
                  className="file-list mt-1"
                  style={{
                    marginBottom:
                      this.state.ExternalUrl.length > 0 ? "0px" : "0px",
                  }}
                >
                  {this.state.ExternalUrl.map((item) => (
                    <li className="d-flex align-items-center w-100">
                      <InputComponent
                        type={InputType.text}
                        value={item}
                        className="mx-2"
                        counter={false}
                        disabled={true}
                        readOnly={true}
                        rowWidth="98%"
                        isExternalURL={true}
                      ></InputComponent>
                      <div
                        style={{
                          paddingBottom: "0px",
                          paddingRight: "0px",
                          paddingTop: "0px",
                          paddingLeft: "5px",
                        }}
                      >
                        <button
                          className="btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0"
                          disabled={this.state.searchLoading}
                          style={{ background: "#F3AEAF" }}
                          onClick={() => {
                            this.handelDeleteExternalLink(item)
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <rect
                              width="15"
                              height="15"
                              fill="white"
                              fill-opacity="0.01"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.5 1.00009C5.22386 1.00009 5 1.22395 5 1.50009C5 1.77623 5.22386 2.00009 5.5 2.00009H9.5C9.77614 2.00009 10 1.77623 10 1.50009C10 1.22395 9.77614 1.00009 9.5 1.00009H5.5ZM3 3.50009C3 3.22395 3.22386 3.00009 3.5 3.00009H5H10H11.5C11.7761 3.00009 12 3.22395 12 3.50009C12 3.77623 11.7761 4.00009 11.5 4.00009H11V12.0001C11 12.5524 10.5523 13.0001 10 13.0001H5C4.44772 13.0001 4 12.5524 4 12.0001V4.00009H3.5C3.22386 4.00009 3 3.77623 3 3.50009ZM5 4.00009H10V12.0001H5V4.00009Z"
                              fill="#D93D42"
                            />
                          </svg>
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="d-flex">
                  <InputComponent
                    type={InputType.text}
                    placeholder="https://"
                    disabled={this.state.searchLoading}
                    className="control-border-color"
                    value={this.state.External}
                    counter={false}
                    onChange={(e) => {
                      this.handleChange("External", e.target.value);
                    }}
                    rowWidth="98%"
                    onEnter={(e) => {
                      this.addExternalUrl();
                    }}
                  ></InputComponent>
                  <div style={{ padding: "5px", paddingRight: "0px" }}>
                    <button
                      className="btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0"
                      disabled={this.state.searchLoading}
                      onClick={() => {
                        this.addExternalUrl();
                      }}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
                <p className="text-error mb-0">
                  {this.validator.message("input", this.state.External, "url")}
                </p>
              </div>
            </div>
            <div>
            <MainButton
              className="btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2 responsive-Button-width"
              svg={<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
              <rect width="15" height="15" fill="white" fill-opacity="0.01"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 2.00006C3.22386 2.00006 3 2.22392 3 2.50006V12.5001C3 12.7762 3.22386 13.0001 3.5 13.0001H11.5C11.7761 13.0001 12 12.7762 12 12.5001V4.70717L9.29289 2.00006H3.5ZM2 2.50006C2 1.67163 2.67157 1.00006 3.5 1.00006H9.5C9.63261 1.00006 9.75979 1.05274 9.85355 1.14651L12.7803 4.07328C12.921 4.21394 13 4.4047 13 4.60361V12.5001C13 13.3285 12.3284 14.0001 11.5 14.0001H3.5C2.67157 14.0001 2 13.3285 2 12.5001V2.50006ZM4.75 7.50006C4.75 7.22392 4.97386 7.00006 5.25 7.00006H7V5.25006C7 4.97392 7.22386 4.75006 7.5 4.75006C7.77614 4.75006 8 4.97392 8 5.25006V7.00006H9.75C10.0261 7.00006 10.25 7.22392 10.25 7.50006C10.25 7.7762 10.0261 8.00006 9.75 8.00006H8V9.75006C8 10.0262 7.77614 10.2501 7.5 10.2501C7.22386 10.2501 7 10.0262 7 9.75006V8.00006H5.25C4.97386 8.00006 4.75 7.7762 4.75 7.50006Z" fill="white"/>
            </svg>}
              onClick={() => {
                this.handelCreateResearch();
              }}
              loading={this.state.loading || this.state.searchLoading}
              disabled={this.state.loading || this.state.searchLoading}
            >
              Create my project
            </MainButton>
          </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ResearchPageNew);
