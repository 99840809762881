import { useEffect, useState } from "react";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useHistory, useParams } from "react-router";
import {
  $ReplaceRoute,
  // AccessPermition,
  // getUserRole,
  getUserTitle,
  removeDuplicateObjWithKey,
  uploadAllFiles,
  uploadAllLinks,
  // UserRoles,
} from "../../../core/utils";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { MainButton, MainButtonType } from "../../components/button";
import {
  createMeetingNote,
  getMeetingNoteSearch,
  GetMeetingNoteSearchReqRes,
  UserType,
} from "../../../controllers/team/meet_note_controller";
import { toast } from "react-toastify";
import { SelectComponent } from "../../components/select_input";
import { ButtonGroup } from "../../components/botton_group";
// import { UploadController } from "../../../controllers/upload_media/upload_media";
import { AppRoutes, LOADING_MODE } from "../../../core/constants";
import { InputComponent, InputType } from "../../components/inputs";
import { RadioGroup } from "../../components/radio_group";
import Dropzone from "react-dropzone";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import SimpleReactValidator from "simple-react-validator";
import { BaseButton } from "@fluentui/react";
import { Label } from "@radix-ui/react-label";
import { SetHeaderTitle } from "../../../data/storeMain/actions/header_title_action";
import { store } from "../../../data/storeMain";

// import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
// import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
// import Table from '@ckeditor/ckeditor5-table/src/table';
// import Heading from '@ckeditor/ckeditor5-heading/src/heading';

// type Props = {};

export default function TeamMeetNewPage(this: any) {
  const params: {
    id: string;
  } = useParams();
  const history = useHistory();

  // const _userRole = getUserRole();
  const [teamLevel, setTeamLevel] = useState("team");
  const [selectedMeetNoteType, setSelectedMeetNoteType] = useState(0);
  const [creatingNote, setCreatingNote] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loadingMode, setLoadingMode] = useState("");
  const [noteContent, setNoteContent] = useState("");
  // const _uploadController = new UploadController();
  const [form, setForm] = useState({
    selectedUsers: [],
    isMainOrSubTeamsHaveAccess: 0,
  });
  const [meetNoteTitle, setMeetNoteTitle] = useState("");
  const [meetNoteDescription, setMeetNoteDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [links, setLinks] = useState<string[]>([]);
  const [link, setLink] = useState<string>(''); 
  const [addLink, setAddLink] = useState(false);
  const [externalUrl, setExternalUrl] = useState([]);
  const [originalData, setOriginalData] =
    useState<GetMeetingNoteSearchReqRes | null>(null);
  const validator = new SimpleReactValidator({
    className: "text-danger",
  });
  const [usersOptions, setUsersOptions] = useState<
    {
      label: string;
      value: any;
      secondary: string | null;
    }[]
  >([]);
  const [errorMessages, setErrorMessages] = useState({
    link: "",
  });

  useEffect(() => {
    handleGetMeetingNoteSearch();
  }, []);

  async function handleGetMeetingNoteSearch() {
    setSearchLoading(true);
    const _result = await getMeetingNoteSearch({ teamId: params.id });
    if (_result) {
      const _splitedTeamLevel = history.location.search.split("=");
      let _teamLevel = "team";
      if (_splitedTeamLevel.length > 1) {
        _teamLevel = _splitedTeamLevel[1];
      }
      setTeamLevel(_teamLevel);

      let _findedTitle = _result.title;
      if (_teamLevel === "subteam") {
        const _findedSubTeam = _result.subTeams?.find(
          (_subT) => _subT.id === Number(params.id)
        );
        if (_findedSubTeam) {
          _findedTitle = _findedSubTeam.title;
        }
      }
      // const _teamLevel = history.location.search.split('teamLevel=')[1];

      document.title = `New Meeting Note | ${_findedTitle} | Radvix`;
      store.dispatch(SetHeaderTitle('Team'))
      let _allUsers: UserType[] = _result.users;
      _result.subTeams?.forEach((_subT) => {
        _allUsers = [..._allUsers, ..._subT.users];
      });
      _allUsers = removeDuplicateObjWithKey(_allUsers, "id");
      setOriginalData({ ..._result, title: _findedTitle });
      setUsersOptions(
        _allUsers.map((_user) => {
          const _userTitle = getUserTitle(_user);
          return {
            label: _userTitle,
            value: _user.id,
            secondary: _user.invitationStatus === 1 ? "Pending" : null,
          };
        })
      );

      // setForm(prev=> (
      //   {
      //     ...prev,
      //     selectedUsers: _allUsers.map((_user) => )
      //   }
      // ))
    }
    setSearchLoading(false);
  }
  async function handleCreateMeetNote() {
    setSearchLoading(true);

    try {
      setCreatingNote(true);
      setLoadingMode("Saving meeting note..");
      const _result = await createMeetingNote({
        // isMainTeam: originalData?.subTeams !== null,
        isMainOrSubTeamsHaveAccess: form.isMainOrSubTeamsHaveAccess === 1,
        teamId: Number(params.id),
        usersIds: form.selectedUsers.map((_u: any) => _u.value),
        title: meetNoteTitle,
      });

      if (!_result) {
        setCreatingNote(false);
        return toast.error("Something went wrong while creating meet note");
      }
;
      if (selectedMeetNoteType === 0) {
        setLoadingMode("Uploading note..");
        await handelUploadNote(_result.id);
        setLoadingMode("");
      } else {
        await handelUploadFileOrLink(_result.id);
      }

      setCreatingNote(false);
      setLoadingMode("");
      setSearchLoading(false);
      history.push(
        $ReplaceRoute(
          `${AppRoutes.team_profile.replace(":id", params.id.toString())}?selectedTab=2`));
    } catch (error) {
      setCreatingNote(false);
      setLoadingMode("");
      setSearchLoading(false);
      // toast.error("Something went wrong while creating meet note");
    }
  }

  async function handelUploadNote(id: number) {
    try {
      // const formData = new FormData();
      // const sanitizedValue = noteContent.replace(/[<>]/g, '');
      // console.log({noteContent, sanitizedValue})

      const blob = new Blob([noteContent], { type: "text/plain" });
      const file = new File(
        [blob],
        meetNoteTitle
          ? `${meetNoteTitle}.txt`
          : `Meet_NOTE_${params.id}_${id}.txt`,
        {
          type: "text/plain",
        }
      );
      await uploadAllFiles(id, [file], 19);
    } catch (e) {}

    // return new Promise((resolve, reject) => {

    //   console.log(file);
    //   formData.append("Files", file);
    //   formData.append("UseCase", "19");
    //   formData.append("SectionId", id.toString());

    //   _uploadController.UloadMedia(
    //     formData,
    //     (res) => {
    //       // setLoading(false)
    //       resolve(true);
    //     },
    //     () => {
    //       reject("Error");
    //       // setLoading(false)
    //     }
    //   );
    // });
  }
  function bytesToMB(bytes: number) {
    return (bytes / (1024 * 1024)).toFixed(3);
  }

  async function handelUploadFileOrLink(id: number) {
    try {
      if (selectedMeetNoteType === 1) {
        setLoadingMode(LOADING_MODE.files);
        await uploadAllFiles(id, files, 20);
        setLoadingMode("");
      } else {
        setLoadingMode(LOADING_MODE.links);
        await uploadAllLinks(id, links, 20);
        setLoadingMode("");
      }
    } catch (e) {
      setLoadingMode("");
    }

    // return new Promise((resolve, reject) => {
    //   const formData = new FormData();

    //   if (selectedMeetNoteType === 1) {
    //     formData.append("Files", files[0]);
    //   } else {
    //     formData.append("ExternalUrls", link);
    //   }

    //   formData.append("UseCase", "20");
    //   formData.append("SectionId", id.toString());
    //   _uploadController.UloadMedia(
    //     formData,
    //     (res) => {
    //       resolve(true);
    //     },
    //     () => {
    //       reject(false);
    //     }
    //   );
    // });
  }

  function onDropFile(_files: any) {
    setFiles(_files);
  }

  const handleChange = (value: string) => {
    setLink(value); // Update the input field value
  };

  const handleAddLink = () => {
    if (link.trim() !== '') {
      setLinks([...links, link]);
      setLink('');
    }
  };

  const handelDeleteExternalLink = (linkToDelete: string) => {
    const updatedLinks = links.filter(l => l !== linkToDelete);
    setLinks(updatedLinks);
  };

  return (
    <div className="card responsive-div">
      <div
        className="d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
        border-gray-400"
      >
        <div className="d-xxl-flex align-items-center">
          <BaseButton
            className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button"
            style={{ fontWeight: "400", height: "19px" }}
            onClick={() => {
              window.history.back();
            }}
          >
            Back
          </BaseButton>
          <h2 className="fw-medium fs-15 mb-0 color-gray-1000 mt-xxl-0 mt-3">
            New Meeting Note
          </h2>
        </div>
      </div>
      <div className="p-3 overflow-auto">
        <div className="row">
          <div className="">
            <div className="row">
              <div className="col-lg-12 mb-3">
                {/* <div className="item">
                    <SelectComponent
                      disabled={this.state.searchLoading}
                      items={this.state.listUserTypes}
                      // defaultValue={this.state.typeId}
                      TextItem="label"
                      ValueItem="value"
                      labelClass="color-gray-600 fs-15 me-2"
                      className="form-select"
                      label="Role name*"
                      placeholder="Click to see the list…"
                      onChange={(e) => {
                        this.setState({
                          typeId: e,
                        });
                      }}
                      inValid={
                        !this.state.isGuestInvited
                          ? this.validator.message(
                              "Type",
                              this.state.typeId.value,
                              "min:0,num"
                            )
                          : undefined
                      }
                      // isMulti
                    ></SelectComponent>
                  </div> */}
                <div className="row mt-1">
                  <div className="col-lg-12">
                    <label className="color-gray-600 fs-15 me-2 mb-1">
                      Select note format*
                    </label>
                    <img
                      className="text-primary cursor-pointer"
                      src="/Images/icons/info-circled.svg"
                      alt="info"
                      title="Priority will color tag your research projects and will help you identify or sort them easier based on their priority level."
                    />
                    <div className="d-flex border-gray-400 border soft-radios p-1 rounded-px-4 w-100">
                      <div className="w-33_3">
                        <input
                          type="radio"
                          className="btn-check"
                          name="options"
                          id="low"
                          autoComplete="off"
                          checked={selectedMeetNoteType === 0}
                          onClick={(e) => {
                            setSelectedMeetNoteType(0);
                            // this.handleChange("status", parseInt(e.target.value));
                          }}
                        />
                        <label
                          className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                          htmlFor="low"
                        >
                          Text Edit
                        </label>
                      </div>
                      <div className="w-33_3">
                        <input
                          type="radio"
                          className="btn-check"
                          name="options"
                          id="medium"
                          autoComplete="off"
                          checked={selectedMeetNoteType === 1}
                          onClick={(e) => {
                            setSelectedMeetNoteType(1);
                          }}
                        />
                        <label
                          className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                          htmlFor="medium"
                        >
                          Upload File
                        </label>
                      </div>
                      <div className="w-33_3">
                        <input
                          type="radio"
                          className="btn-check"
                          name="options"
                          id="high"
                          autoComplete="off"
                          checked={selectedMeetNoteType === 2}
                          onClick={(e) => {
                            setSelectedMeetNoteType(2);
                            // this.handleChange("status", parseInt(e.target.value));
                          }}
                        />
                        <label
                          className="btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center"
                          htmlFor="high"
                        >
                          Add Link
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <InputComponent
                  className="form-control"
                  type={InputType.text}
                  disabled={searchLoading}
                  //placeholder="Enter user’s email address"
                  label="Meeting title*"
                  popQuestion="This email will be used to send a secured invitation link to the user. User will be update to change their emails on the registration page."
                  onChange={(e) => {
                    setMeetNoteTitle(e.target.value);
                  }}
                  value={meetNoteTitle}
                ></InputComponent>
              </div>
              {selectedMeetNoteType == 0 && (
                // <>
                // <div
                //         className="text-primary cursor-pointer mb-1"
                //         title="Info"
                //       >
                //         <label className="color-gray-600 fs-15 me-2">
                //           Your note here*
                //         </label>
                //         <img
                //           className="text-primary cursor-pointer"
                //           src="/Images/icons/info-circled.svg"
                //           alt="info"
                //           title="You can write a brief description for this research project. This information will be shown on the project profile and everyone who is assigned to this research, will be able to read it."
                //         />
                //       </div>
                // <div className="sub-menu"style={{paddingTop:"10px", paddingBottom:"10px"}}>
                //   <div className="menus"style={{gap:"55px",paddingLeft:"30px"}}>
                //     <div className="menu">
                //       <div className="tittle-wrapper">
                //         <div className="tittle">
                //           <svg
                //             xmlns="http://www.w3.org/2000/svg"
                //             width="20"
                //             height="20"
                //             viewBox="0 0 20 20"
                //             fill="none"
                //           >
                //             <rect
                //               width="20"
                //               height="20"
                //               fill="white"
                //               fill-opacity="0.01"
                //             />
                //             <path
                //               d="M6.8069 16.0001C6.27756 16.0001 5.8983 15.8828 5.66911 15.6481C5.44536 15.408 5.3335 15.0288 5.3335 14.5104V5.4899C5.3335 4.96056 5.4481 4.5813 5.67728 4.3521C5.91194 4.11744 6.28847 4.00012 6.8069 4.00012C8.57004 4.00012 11.008 4.00012 12.0382 4.00012C13.5166 4.00012 14.7387 5.30883 14.7387 6.91292C14.7387 8.11347 14.1384 9.09152 12.9379 9.64815C14.4878 9.93147 15.357 11.5039 15.357 12.6418C15.357 14.0168 14.6538 16.0001 12.3699 16.0001C11.7034 16.0001 8.56851 16.0001 6.8069 16.0001ZM11.172 10.6713H7.7335V14.1748H11.172C11.8668 14.1748 12.8396 13.4949 12.8396 12.3985C12.8396 11.302 11.8668 10.6713 11.172 10.6713ZM7.7335 5.8255V8.92782H10.9064C11.3856 8.92782 12.3699 8.47931 12.3699 7.33348C12.3699 6.18764 11.3075 5.8255 10.9064 5.8255H7.7335Z"
                //               fill="#1C2024"
                //             />
                //           </svg>
                //         </div>
                //       </div>
                //     </div>
                //     <div className="menu">
                //       <div className="tittle-wrapper">
                //         <div className="tittle">
                //           <svg
                //             xmlns="http://www.w3.org/2000/svg"
                //             width="20"
                //             height="20"
                //             viewBox="0 0 20 20"
                //             fill="none"
                //           >
                //             <rect
                //               width="20"
                //               height="20"
                //               fill="white"
                //               fill-opacity="0.01"
                //             />
                //             <path
                //               fill-rule="evenodd"
                //               clip-rule="evenodd"
                //               d="M7.56675 4.66696C7.56675 4.33558 7.83537 4.06696 8.16675 4.06696H14.1667C14.4982 4.06696 14.7667 4.33558 14.7667 4.66696C14.7667 4.99833 14.4982 5.26696 14.1667 5.26696H12.008L9.64136 14.7334H11.8334C12.1648 14.7334 12.4334 15.0021 12.4334 15.3334C12.4334 15.6649 12.1648 15.9334 11.8334 15.9334H5.8334C5.50202 15.9334 5.2334 15.6649 5.2334 15.3334C5.2334 15.0021 5.50202 14.7334 5.8334 14.7334H7.99212L10.3587 5.26696H8.16675C7.83537 5.26696 7.56675 4.99833 7.56675 4.66696Z"
                //               fill="#1C2024"
                //             />
                //           </svg>
                //         </div>
                //       </div>
                //     </div>
                //     <div className="menu">
                //       <Label className="label-instance design-component-instance-node" />
                //       <div className="tittle-wrapper">
                //         <div className="tittle">
                //           <svg
                //             xmlns="http://www.w3.org/2000/svg"
                //             width="20"
                //             height="20"
                //             viewBox="0 0 20 20"
                //             fill="none"
                //           >
                //             <rect
                //               width="20"
                //               height="20"
                //               fill="white"
                //               fill-opacity="0.01"
                //             />
                //             <path
                //               fill-rule="evenodd"
                //               clip-rule="evenodd"
                //               d="M4.1808 8.21089C4.43262 7.94228 4.85452 7.92868 5.12312 8.18049L10.0005 12.753L14.8778 8.18049C15.1465 7.92868 15.5684 7.94228 15.8202 8.21089C16.072 8.47951 16.0584 8.9014 15.7898 9.15321L10.4565 14.1533C10.2 14.3937 9.80097 14.3937 9.54453 14.1533L4.2112 9.15321C3.94258 8.9014 3.92898 8.47951 4.1808 8.21089Z"
                //               fill="#1C2024"
                //             />
                //           </svg>
                //         </div>
                //       </div>
                //     </div>
                //     <div className="menu">
                //       <div className="tittle-wrapper">
                //         <div className="tittle">
                //           <svg
                //             xmlns="http://www.w3.org/2000/svg"
                //             width="20"
                //             height="20"
                //             viewBox="0 0 20 20"
                //             fill="none"
                //           >
                //             <rect
                //               width="20"
                //               height="20"
                //               fill="white"
                //               fill-opacity="0.01"
                //             />
                //             <path
                //               fill-rule="evenodd"
                //               clip-rule="evenodd"
                //               d="M2 7.00018C2.55228 7.00018 3 6.55246 3 6.00018C3 5.4479 2.55228 5.00018 2 5.00018C1.44772 5.00018 1 5.4479 1 6.00018C1 6.55246 1.44772 7.00018 2 7.00018ZM5.33333 6.00018C5.33333 5.632 5.63181 5.33352 6 5.33352H18C18.3681 5.33352 18.6667 5.632 18.6667 6.00018C18.6667 6.36837 18.3681 6.66685 18 6.66685H6C5.63181 6.66685 5.33333 6.36837 5.33333 6.00018ZM6 9.33352C5.63181 9.33352 5.33333 9.632 5.33333 10.0002C5.33333 10.3684 5.63181 10.6669 6 10.6669H18C18.3681 10.6669 18.6667 10.3684 18.6667 10.0002C18.6667 9.632 18.3681 9.33352 18 9.33352H6ZM6 13.3335C5.63181 13.3335 5.33333 13.6321 5.33333 14.0002C5.33333 14.3683 5.63181 14.6669 6 14.6669H18C18.3681 14.6669 18.6667 14.3683 18.6667 14.0002C18.6667 13.6321 18.3681 13.3335 18 13.3335H6ZM3 10.0002C3 10.5525 2.55228 11.0002 2 11.0002C1.44772 11.0002 1 10.5525 1 10.0002C1 9.4479 1.44772 9.00018 2 9.00018C2.55228 9.00018 3 9.4479 3 10.0002ZM2 15.0002C2.55228 15.0002 3 14.5525 3 14.0002C3 13.4479 2.55228 13.0002 2 13.0002C1.44772 13.0002 1 13.4479 1 14.0002C1 14.5525 1.44772 15.0002 2 15.0002Z"
                //               fill="#1C2024"
                //             />
                //           </svg>
                //         </div>
                //       </div>
                //     </div>
                //     <div className="menu">
                //       <div className="tittle-wrapper">
                //         <div className="tittle">
                //           <svg
                //             xmlns="http://www.w3.org/2000/svg"
                //             width="20"
                //             height="20"
                //             viewBox="0 0 20 20"
                //             fill="none"
                //           >
                //             <rect
                //               width="20"
                //               height="20"
                //               fill="white"
                //               fill-opacity="0.01"
                //             />
                //             <path
                //               fill-rule="evenodd"
                //               clip-rule="evenodd"
                //               d="M12.567 4.58862C13.4085 4.31553 14.3786 4.32041 15.1726 4.72845C17.0046 5.66979 17.7973 8.09602 17.0598 10.6668C16.729 11.82 16.1473 12.9398 14.9151 14.07C13.683 15.2001 11.9741 15.8668 11.1813 15.8668C10.8972 15.8668 10.667 15.6429 10.667 15.3668C10.667 15.0907 10.9022 14.8668 11.1813 14.8668C12.087 14.8668 12.8962 14.374 13.8545 13.6481C14.5878 13.0925 15.1605 12.3819 15.4777 11.7627C16.0841 10.5784 16.1046 8.90913 15.1247 8.46549C14.6542 8.97954 14.0397 9.27942 13.3033 9.27942C11.7034 9.27942 10.7605 7.9923 10.801 6.82693C10.8398 5.71313 11.5474 4.91953 12.567 4.58862ZM4.56703 4.58862C5.40851 4.31553 6.37854 4.32041 7.17263 4.72845C9.00459 5.66979 9.79721 8.09602 9.05982 10.6668C8.72905 11.82 8.14725 12.9398 6.91513 14.07C5.68301 15.2001 3.97411 15.8668 3.18127 15.8668C2.89725 15.8668 2.66699 15.6429 2.66699 15.3668C2.66699 15.0907 2.90225 14.8668 3.18127 14.8668C4.08695 14.8668 4.89618 14.374 5.85443 13.6481C6.58786 13.0925 7.16051 12.3819 7.47762 11.7627C8.08409 10.5784 8.10461 8.90913 7.12473 8.46549C6.65415 8.97954 6.03963 9.27942 5.30333 9.27942C3.70339 9.27942 2.76046 7.9923 2.80101 6.82693C2.83975 5.71313 3.54742 4.91953 4.56703 4.58862Z"
                //               fill="#1C2024"
                //             />
                //           </svg>
                //         </div>
                //       </div>
                //     </div>
                //     <div className="div">
                //       <div className="tittle-wrapper">
                //         <div className="tittle">
                //           <svg
                //             xmlns="http://www.w3.org/2000/svg"
                //             width="20"
                //             height="20"
                //             viewBox="0 0 20 20"
                //             fill="none"
                //           >
                //             <rect
                //               width="20"
                //               height="20"
                //               fill="white"
                //               fill-opacity="0.01"
                //             />
                //             <path
                //               fill-rule="evenodd"
                //               clip-rule="evenodd"
                //               d="M10.6668 2.66677H16.6668C17.035 2.66677 17.3335 2.96525 17.3335 3.33344V6.66677H10.6668V2.66677ZM9.3335 6.66677V2.66677H3.3335C2.96531 2.66677 2.66683 2.96525 2.66683 3.33344V6.66677H9.3335ZM2.66683 8.0001V12.0001H9.3335V8.0001H2.66683ZM10.6668 8.0001H17.3335V12.0001H10.6668V8.0001ZM10.6668 13.3334H17.3335V16.6668C17.3335 17.0349 17.035 17.3334 16.6668 17.3334H10.6668V13.3334ZM2.66683 16.6668V13.3334H9.3335V17.3334H3.3335C2.96531 17.3334 2.66683 17.0349 2.66683 16.6668ZM1.3335 3.33344C1.3335 2.22886 2.22892 1.33344 3.3335 1.33344H16.6668C17.7714 1.33344 18.6668 2.22886 18.6668 3.33344V16.6668C18.6668 17.7713 17.7714 18.6668 16.6668 18.6668H3.3335C2.22892 18.6668 1.3335 17.7713 1.3335 16.6668V3.33344Z"
                //               fill="#1C2024"
                //             />
                //           </svg>
                //         </div>
                //       </div>
                //       <div className="tittle">
                //         <svg
                //           xmlns="http://www.w3.org/2000/svg"
                //           width="20"
                //           height="20"
                //           viewBox="0 0 20 20"
                //           fill="none"
                //         >
                //           <rect
                //             width="20"
                //             height="20"
                //             fill="white"
                //             fill-opacity="0.01"
                //           />
                //           <path
                //             fill-rule="evenodd"
                //             clip-rule="evenodd"
                //             d="M4.1808 8.21089C4.43262 7.94228 4.85452 7.92868 5.12312 8.18049L10.0005 12.753L14.8778 8.18049C15.1465 7.92868 15.5684 7.94228 15.8202 8.21089C16.072 8.47951 16.0584 8.9014 15.7898 9.15321L10.4565 14.1533C10.2 14.3937 9.80097 14.3937 9.54453 14.1533L4.2112 9.15321C3.94258 8.9014 3.92898 8.47951 4.1808 8.21089Z"
                //             fill="#1C2024"
                //           />
                //         </svg>
                //       </div>
                //     </div>
                //     <div className="div">
                //       <div className="tittle-wrapper">
                //         <div className="tittle">
                //           <svg
                //             xmlns="http://www.w3.org/2000/svg"
                //             width="20"
                //             height="20"
                //             viewBox="0 0 20 20"
                //             fill="none"
                //           >
                //             <rect
                //               width="20"
                //               height="20"
                //               fill="white"
                //               fill-opacity="0.01"
                //             />
                //             <path
                //               fill-rule="evenodd"
                //               clip-rule="evenodd"
                //               d="M12.0002 2.66677H8.00016V17.3334H12.0002V2.66677ZM13.3335 2.66677V17.3334H16.6668C17.035 17.3334 17.3335 17.0349 17.3335 16.6668V3.33344C17.3335 2.96525 17.035 2.66677 16.6668 2.66677H13.3335ZM3.3335 2.66677H6.66683V17.3334H3.3335C2.96531 17.3334 2.66683 17.0349 2.66683 16.6668V3.33344C2.66683 2.96525 2.96531 2.66677 3.3335 2.66677ZM3.3335 1.33344C2.22892 1.33344 1.3335 2.22886 1.3335 3.33344V16.6668C1.3335 17.7713 2.22892 18.6668 3.3335 18.6668H16.6668C17.7714 18.6668 18.6668 17.7713 18.6668 16.6668V3.33344C18.6668 2.22886 17.7714 1.33344 16.6668 1.33344H3.3335Z"
                //               fill="#1C2024"
                //             />
                //           </svg>
                //         </div>
                //       </div>
                //       <div className="tittle">
                //         <svg
                //           xmlns="http://www.w3.org/2000/svg"
                //           width="20"
                //           height="20"
                //           viewBox="0 0 20 20"
                //           fill="none"
                //         >
                //           <rect
                //             width="20"
                //             height="20"
                //             fill="white"
                //             fill-opacity="0.01"
                //           />
                //           <path
                //             fill-rule="evenodd"
                //             clip-rule="evenodd"
                //             d="M4.1808 8.21089C4.43262 7.94228 4.85452 7.92868 5.12312 8.18049L10.0005 12.753L14.8778 8.18049C15.1465 7.92868 15.5684 7.94228 15.8202 8.21089C16.072 8.47951 16.0584 8.9014 15.7898 9.15321L10.4565 14.1533C10.2 14.3937 9.80097 14.3937 9.54453 14.1533L4.2112 9.15321C3.94258 8.9014 3.92898 8.47951 4.1808 8.21089Z"
                //             fill="#1C2024"
                //           />
                //         </svg>
                //       </div>
                //     </div>
                //   </div>
                //   </div>
                  <div className="item">
                  <div className="col-lg-12">
                  <>
            <label className="textSecondary mb-1 mt-3">
              Your Note Here*
            </label>
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "bold",
                  "italic",
                  "heading",
                  "table",
                  "undo",
                  "redo",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                  "insertTable",
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                ],
              }}
              data={noteContent}
              
              onChange={(event: any, editor: { getData: () => any; }) => {
                const data = editor.getData();
                setNoteContent(data);
              }}
            />
          </>
                    {/* <InputComponent
                      type={InputType.textarea}
                      disabled={searchLoading}
                      label="Your note here*"
                      placeholder="Description will be here"
                      //optional="optional"
                      height="132px"
                      className="mt-1"
                      onChange={(e) => {
                        setNoteContent(e.target.value);
                      }}
                      value={noteContent}
                    ></InputComponent> */}
                  </div>
                </div>
              )}
              {selectedMeetNoteType == 1 && (
                <div className="item">
                <div className="p-px-12 bg-gray-200 border-gray-400 border rounded-px-4 gap-2 attachment-paddingleft">
                  <div className="justify-content-sm-between justify-content-center align-items-center flex-wrap row-gap-px-8">
                    <Dropzone onDrop={onDropFile} multiple={false}>
                      {({ getRootProps, getInputProps }) => (
                        // <section
                        //   className="container fileUploadBox"
                        //   style={{ marginTop: "0.3em" }}
                        // >
                        <div {...getRootProps({ className: "" })}>
                          <input {...getInputProps()} />

                          <div className="d-flex justify-content-between align-items-center flex-wrap row-gap-px-8">
                            <button className="btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0">
                              <div className="d-flex align-items-center">
                                <div className="me-1 mb-1">
                                  <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="15"
                                      height="15"
                                      fill="white"
                                      fill-opacity="0.01"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.81825 1.18197C7.64251 1.00624 7.35759 1.00624 7.18185 1.18197L4.18185 4.18197C4.00611 4.35771 4.00611 4.64263
            4.18185 4.81837C4.35759 4.9941 4.64251 4.9941 4.81825 4.81837L7.05005 2.58657V9.50005C7.05005 9.74858 7.25152 9.95005
            7.50005 9.95005C7.74858 9.95005 7.95005 9.74858 7.95005 9.50005V2.58657L10.1819 4.81837C10.3576 4.9941 10.6425 4.9941
            10.8182 4.81837C10.994 4.64263 10.994 4.35771 10.8182 4.18197L7.81825 1.18197ZM2.5 10.0001C2.77614 10.0001 3 10.2239 3
            10.5001V12.0001C3 12.5539 3.44565 13.0001 3.99635 13.0001H11.0012C11.5529 13.0001 12 12.5529 12 12.0001V10.5001C12
            10.2239 12.2239 10.0001 12.5 10.0001C12.7761 10.0001 13 10.2239 13 10.5001V12.0001C13 13.1041 12.1062 14.0001 11.0012
            14.0001H3.99635C2.89019 14.0001 2 13.1031 2 12.0001V10.5001C2 10.2239 2.22386 10.0001 2.5 10.0001Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </div>
                                Browse local file
                              </div>
                            </button>
                            <h3 className="fw-normal color-gray-700 fs-13 mb-0 flex-grow-1 text-center">
                              {" "}
                              Or drag and drop files here - Max 128MB{" "}
                            </h3>
                          </div>
                          <ul className="pt-5-x">
                     {files.map((file: any) => (
                        <li
                        key={file.name}
                        className="d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team"
                        style={{marginBottom:'3px'}}
                      >
                        <span
                          className="text-truncate col-11 mx-2" 
                          style={{ fontFamily: "Sanfransisco", textAlign:'start' }}
                        >
                          {file.name} - {bytesToMB(file.size)} MB
                        </span>
                        <CircleIcon
                          type={ThemeCircleIcon.dark}
                          width="22px"
                          height="22px"
                          padding="0"
                          onClick={() => {
                            setFiles([]);
                          }}
                          style={{ cursor: "pointer", display: "contents" }}
                        >
                          <img
                            src="/images/icons/cross-1.svg"
                            alt="radvix"
                            width={15}
                            height={15}
                          />
                        </CircleIcon>
                      </li>
                      ))}
                    </ul>
                          {/* {files.length > 0 && (
                            <div style={{ paddingTop: "10px" }}>
                              <ul className="list-unstyled fw-normal">
                                {files}
                              </ul>
                            </div>
                          )} */}
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>
                </div>
              )}
              {selectedMeetNoteType == 2 && (
                <>
      <ul className="file-list mt-1" style={{ marginBottom: links.length > 0 ? '0px' : '0px' }}>
        {links.map((linkItem, index) => (
          <li key={index} className="d-flex align-items-center w-100">
            <InputComponent
              type={InputType.text}
              value={linkItem}
              className="mx-2"
              counter={false}
              disabled={true}
              readOnly={true}
              rowWidth="98%"
              isExternalURL={true}
            />
            <div style={{ paddingBottom: '0px', paddingRight: '0px', paddingTop: '0px', paddingLeft: '5px' }}>
              <button
                className="btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0"
                style={{ background: '#F3AEAF' }}
                onClick={() => handelDeleteExternalLink(linkItem)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <rect width="15" height="15" fill="white" fillOpacity="0.01" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.5 1.00009C5.22386 1.00009 5 1.22395 5 1.50009C5 1.77623 5.22386 2.00009 5.5 2.00009H9.5C9.77614 2.00009 10 1.77623 10 1.50009C10 1.22395 9.77614 1.00009 9.5 1.00009H5.5ZM3 3.50009C3 3.22395 3.22386 3.00009 3.5 3.00009H5H10H11.5C11.7761 3.00009 12 3.22395 12 3.50009C12 3.77623 11.7761 4.00009 11.5 4.00009H11V12.0001C11 12.5524 10.5523 13.0001 10 13.0001H5C4.44772 13.0001 4 12.5524 4 12.0001V4.00009H3.5C3.22386 4.00009 3 3.77623 3 3.50009ZM5 4.00009H10V12.0001H5V4.00009Z"
                    fill="#D93D42"
                  />
                </svg>
              </button>
            </div>
          </li>
        ))}
      </ul>

      <div className="d-flex">
        <InputComponent
          type={InputType.text}
          placeholder="https://"
          className="control-border-color"
          counter={false}
          value={link}
          onChange={(e) => handleChange(e.target.value)}
          rowWidth="98%"
        />
        <div style={{ padding: '5px', paddingRight: '0px' }}>
          <button
            className="btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0"
            onClick={handleAddLink}
          >
            <i className="fa fa-plus"></i>
          </button>
        </div>
      </div>
    </>
              )}
              <div className="item pt-3">
                <div className="col-lg-12 mb-3">
                  <label className="color-gray-600 fs-15 me-2 mb-1">
                    Participants*
                  </label>
                  <img
                    className="text-primary cursor-pointer"
                    src="/Images/icons/info-circled.svg"
                    alt="info"
                    title="You can add this user to your existing teams. If you are creating a Level 2 user, you will need to add them to a team to be able to send an invitation. You can always add users to more teams in the future."
                  />

                  <div className="item">
                    <SelectComponent
                      disabled={searchLoading}
                      items={usersOptions}
                      TextItem="name"
                      ValueItem="id"
                      className="mt-1"
                      placeholder="Click to see the list"
                      menuPlacementTop={true}
                      //label="Participants"
                      popQuestion="This is list of members who participated in this meeting. All members in this team will have access to this note no matter if they participated or not."
                      // optional="optional"
                      defaultValue={form.selectedUsers}
                      onChange={(e) => {
                        setForm((prev) => ({ ...prev, selectedUsers: e }));
                        // this.handelChangeSelect("addedManagersId", e);
                      }}
                      isMulti
                    ></SelectComponent>
                  </div>
                </div>
              </div>

              {/* <div className="item">
                <div className="col-lg-12 mb-3">
                  <label className="color-gray-600 fs-15 me-2 mb-1">
                    {AccessPermition(_userRole, [
                      UserRoles.Admin,
                      UserRoles.L1Client,
                      UserRoles.L1User,
                    ])
                      ? "Assign this team to laboratories (Equipment)*"
                      : "Assign this subteam to laboratories (Equipment)*"}
                  </label>
                  <img
                    className="text-primary cursor-pointer"
                    src="/Images/icons/info-circled.svg"
                    alt="info"
                    title="When you assign a team to a laboratory or only individual equipment, all of the existing or future members and managers of that team will have access to those laboratories and selected equipment."
                  />
                  <div className="item">
                    <NewTreeMenu
                      disabled={this.state.searchLoading}
                      placeholder="Select laboratories or equipment"
                      popQuestion="When you assign a team to a laboratory or only individual equipment, all of the existing or future members and managers of that team will have access to those laboratories and selected equipment."
                      onChange={(_labs, _equips) => {
                        // console.log({ _teams, _users })
                        this.setState({
                          selectedLabsId: _labs,
                          selectedEquipsId: _equips,
                          selectedLabsEquips: [..._labs, ..._equips],
                        });
                      }}
                      loading={this.state.searchLoading}
                      // onSele ctedItems={() => {

                      // }}
                      options={this.state.listLabsEquips}
                    />
                  </div>
                </div>
              </div> */}
            </div>
            {/* <div className="p-3"> */}
            <div style={{ paddingTop: "5px" }}>
              <MainButton
                className="btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2"
                icon={
                  <img
                    src="/images/icons/file-plus.svg"
                    className="w-px-20"
                    style={{ paddingBottom: "2px" }}
                  />
                }
                onClick={() => {
                  handleCreateMeetNote();
                }}
                loading={searchLoading}
                disabled={searchLoading}
              >
                Save Meeting Note
              </MainButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
