import React from "react";
interface IBadge {
  text: string;
  color: string;
  background?: string;
  className?: string;
  style?: {};
}
export const Badge: React.FC<IBadge> = ({
  text,
  background,
  color,
  className = "",
  style = {},
}) => {
  return (
    <span
      className={`lable ${className}`}
      style={{ backgroundColor: background, minWidth: 5, ...style }}
    >
      <span
        className="tooltiptext"
        style={{ backgroundColor: background, color }}
      >
        {text}
      </span>
    </span>
  );
};
