import { HTTP, handleUnauthorizedRequest } from '../../../core/http_common';
import { SortType } from '../../../core/number_extentions';
import { CreateGrantReq } from '../../models/requests/financials/create_grant_req';
import { EditGrantReq } from '../../models/requests/financials/update_grant_req';
import { CreateGrantRes } from '../../models/responses/financials/grant/create_grant_res';
import { GetGrantByID } from '../../models/responses/financials/grant/grant_by_id_res';
import { GetAllGrantResult } from '../../models/responses/financials/grant/grant_res';
import { SearchGrantRes } from '../../models/responses/financials/grant/search_grant_res';
import { EditGrantRes } from '../../models/responses/financials/grant/update_grant_res';

export class RemoteGrant {
  createGrant(
    body: CreateGrantReq,
    action: (res: CreateGrantRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post('/Grant/Create', body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  SearchGrant(
    body: { researchId: number },
    action: (res: SearchGrantRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(`/Grant/Search?researchId=${body.researchId}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  getGrant(
    body: {
      PageNumber: number;
      PageSize: number;
      SearchParameter: string;
      columnHeaderName?: string;
      sortDirection?: SortType;
    },
    action: (res: GetAllGrantResult) => any,
    error: (res: any) => any
  ) {
    const ColumnName = body.columnHeaderName ? body.columnHeaderName : '';
    const sort = body.sortDirection ? body.sortDirection : SortType.AutoSelect;
    return HTTP.get(
      `/Grant?PageNumber=${body.PageNumber}&PageSize=${body.PageSize}&SearchParameter=${body.SearchParameter}&ColumnName=${ColumnName}&SortDirection=${sort}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  getGrantById(body: { id: number }, action: (res: GetGrantByID) => any, error: (res: any) => any) {
    return HTTP.get(`/Grant/${body.id}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  updateGrant(body: EditGrantReq, action: (res: EditGrantRes) => any, error: (res: any) => any) {
    return HTTP.put('/Grant', body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  deleteGrant(body: { grantId: number }, action: (res: any) => any, error: (res: any) => any) {
    return HTTP.delete(`/Grant?grantId=${body.grantId}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
}
