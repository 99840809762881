import React from "react";
import { store } from "../../../data/storeMain";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import { InputComponent, InputType } from "../../components/inputs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MainButton, MainButtonType } from "../../components/button";
import { SelectComponent } from "../../components/select_input";
import Dropzone from "react-dropzone";
import { RouteComponentProps, withRouter } from "react-router";
import { AppRoutes } from "../../../core/constants";
import SimpleReactValidator from "simple-react-validator";
import { expenseController } from "../../../controllers/expense/expense_controller";
import { UploadController } from "../../../controllers/upload_media/upload_media";
import {
  $ReplaceRoute,
  // $truncate,
  isTokenInHttpAuth,
  uploadAllFiles,
  uploadAllLinks,
} from "../../../core/utils";
import { HTTP } from "../../../core/http_common";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { toast } from "react-toastify";
import CategoryManagementModal from "../laboratory/component/category_management_modal";
import { Category } from "../../../data/models/responses/expense/search_expense_res";
import {
  CreateNewCategory,
  CreateNewCategoryResult,
} from "../../../data/models/responses/category/create_new_category";
import { CategoryController } from "../../../controllers/category/category_controller";
import CircleIconTooltip from "../../components/CircleIconTooltip";
type StateType = {
  modalCategories: Array<Category>;
  files: Array<File>;
  categoryId: number;
  title: string;
  appTaskId: { label: string, value: number } | null;
  description: string;
  amount: number;
  date: Date;
  appTasksList: Array<string>;
  listCategory: Array<{ label: string; value: number } | {}>;
  External: string;
  ExternalUrl: Array<string>;
  selectedCategory: { label: string; value: number } | null;
  loading: boolean;
};
let unsubscribeMeData: any = undefined;
class ExpensePageNew extends React.Component<RouteComponentProps> {
  controller = new expenseController();
  UploadController = new UploadController();
  categoryController = new CategoryController();
  RoleUser = store.getState().userRole;
  date = new Date();
  handelChangeDate(target: string, params: any): void {
    this.setState({
      [target]: params,
    });
  }
  state: StateType = {
    files: [],
    categoryId: 0,
    loading: false,
    title: "",
    appTaskId: null,
    description: "",
    amount: 0,
    date: new Date(),
    appTasksList: [],
    listCategory: [],
    ExternalUrl: [],
    External: "",
    modalCategories: [],
    selectedCategory: null,
  };
  onDrop = (fileList: any) => {
    fileList.map((item: any, index: number) => {
      const found = this.state.files.find(
        (element: any) => element.name === item.name
      );
      if (found === undefined) {
        this.setState({ files: [fileList[index], ...this.state.files] });
      }
    });
  };
  handelDeleteFile(arg: File) {
    this.setState({
      files: this.state.files.filter((file) => file.name !== arg.name),
    });
  }
  async handelUpload(id: any) {
    try {
      await uploadAllLinks(id, this.state.ExternalUrl, 4);
      await uploadAllFiles(id, this.state.files, 4);
      this.setState({
        loading: false,
      });
      this.props.history.push(
        $ReplaceRoute(
          `${AppRoutes.expense_profile.replace(":id", id?.toString() ?? "")}`
        )
      );
    } catch (e) {
      this.setState({
        loading: false,
      });
    }

    // const formData = new FormData();
    // for (let i = 0; i < this.state.files.length; i++) {
    //   const file = this.state.files[i];
    //   formData.append("Files", file);
    // }
    // for (let i = 0; i < this.state.ExternalUrl.length; i++) {
    //   const file = this.state.ExternalUrl[i];
    //   formData.append("ExternalUrls", file);
    // }
    // formData.append("UseCase", "4");
    // formData.append("SectionId", id.toString());

    // await this.UploadController.UloadMedia(
    //   formData,
    //   (res) => {
    //     this.setState({
    //       loading: false,
    //     });
    //     this.props.history.push(
    //       $ReplaceRoute(
    //         `${AppRoutes.expense_profile.replace(":id", id?.toString() ?? "")}`
    //       )
    //     );
    //   },
    //   () => {
    //     this.setState({
    //       loading: false,
    //     });
    //   }
    // );
  }
  handleChange(target: string, val: any) {
    this.setState({
      [target]: val,
    });
  }
  addExternalUrl() {
    let Url = [...this.state.ExternalUrl];
    if (this.state.External.length > 2) {
      Url.push(this.state.External);
    }
    this.setState({
      ExternalUrl: Url,
      External: "",
    });
  }
  validator = new SimpleReactValidator({
    className: "text-danger",
  });
  handelCreateExpense() {
    if (this.validator.allValid()) {
      this.setState({
        loading: true,
      });
      const body = {
        categoryId: this.state.selectedCategory?.value,
        title: this.state.title,
        appTaskId: this.state.appTaskId?.value,
        description: this.state.description,
        amount: Number(this.state.amount),
        date: this.state.date,
      };
      this.controller.createExpense(
        body,
        (res) => {
          if (this.state.files.length || this.state.ExternalUrl.length) {
            this.handelUpload(res.id);
          } else {
            this.setState({
              loading: false,
            });
            this.props.history.push(
              $ReplaceRoute(
                `${AppRoutes.expense_profile.replace(
                  ":id",
                  res.id?.toString() ?? ""
                )}`
              )
            );
          }
          this.setState({
            categoryId: 0,
            title: "",
            appTaskId: null,
            description: 2,
            amount: 0,
            date: new Date(),
          });
        },
        (err) => {
          this.setState({
            loading: false,
          });
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  componentDidMount() {
    document.title = `Add Expense | Radvix`
    if (!isTokenInHttpAuth(HTTP)) return;
    // this.controller.SearchPublish((res) => {
    //   this.setState({
    //     listMembers: res,
    //   });
    // });
    this.GetSearchExpense();

    unsubscribeMeData = store.subscribe(() => {
      this.setState({
        selectedCategory: null,
        appTaskId: null
      })
      this.GetSearchExpense();
    });
  }
  // clean up event listener
  componentWillUnmount() {
    // console.log('called removeEvent')
    if (unsubscribeMeData) unsubscribeMeData();
  }
  GetSearchExpense() {
    return new Promise((resolve, reject) => {
      this.controller.SearchExpense(
        (res) => {
          if (!res) {
            // const toastId = toast("", {
            //   autoClose: false,
            //   position: "top-right",
            //   hideProgressBar: true,
            //   closeOnClick: false,
            // });
            // toast.update(toastId, {
            //   render: <CreateNewTaskToast />,
            // });
            this.setState({
              appTasksList: [],
              listCategory: [],
              modalCategories: []
            })
            toast.info(
              "There is no task in this research, please create a task first"
            );
            return
          }
          this.setState({
            appTasksList: res?.appTasks?.map((item) => {
              return { label: item.title, value: item.id };
            }),
            // categories: res?.category?.map(item => {
            //   return { label: item.title, value: item.id }
            // }),

            listCategory: res?.category?.map((item) => {
              return { label: item.title, value: item.id };
            }),
            modalCategories: res.category,
          });
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  handelChangeSelect(target: string, e: { label: string; value: number }) {
    this.setState({ [target]: e.value });
  }
  handelDeleteExternalLink(link: string) {
    this.setState({
      ExternalUrl: this.state.ExternalUrl.filter((item) => item !== link),
    });
  }

  handleCreateNewCategory(_newCategoryName: string) {
    return new Promise((resolve, reject) => {
      this.categoryController.createNewCategory(
        {
          title: _newCategoryName,
          type: 3,
        },
        (res) => {
          // this.setState({
          //   listCategory: [
          //     ...this.state.listCategory,
          //     { label: res.title, value: res.id },
          //   ],
          // });
          resolve(res as CreateNewCategoryResult);
        },
        (err) => {
          // 
          reject(err);
        }
      );
    });
  }
  handleEditCategory(_newCategoryName: string, _id: number) {
    return new Promise((resolve, reject) => {
      this.categoryController.editCategory(
        {
          title: _newCategoryName,
          type: 3,
          id: _id,
        },
        (res) => {
          if (this.state.selectedCategory?.value === _id) {
            this.setState({
              selectedCategory: { label: _newCategoryName, value: _id },
            });
          }
          resolve(res as CreateNewCategoryResult);
        },
        (err) => {
          // 
          reject(err);
        }
      );
    });
  }

  handleDeleteCategory(_id: number) {
    return new Promise((resolve, reject) => {
      this.categoryController.deleteCategory(
        {
          id: _id,
        },
        (res) => {
          // console.log(this.state.selectedCategory.value, _id);
          if (this.state.selectedCategory?.value === _id) {
            this.setState({
              selectedCategory: null,
            });
          }
          resolve(res as CreateNewCategory);
        },
        (err) => {
          // 
          reject(err);
        }
      );
    });
  }
  render() {
    const files = this.state.files.map((file: any) => (
      <li key={file.name}>
        <span className="text-truncate col-10 mx-2">
          {file.name} - {file.size} bytes
        </span>
        <CircleIcon
          type={ThemeCircleIcon.dark}
          width="22px"
          height="22px"
          padding="0"
          onClick={() => {
            this.handelDeleteFile(file);
          }}
        >
          <img
            src="/images/icons/garbage_can.svg"
            alt="radvix"
            width={15}
            height={15}
          />
        </CircleIcon>
      </li>
    ));
    return (
      <div className="container-fluid research new-research">
        <div className="row"></div>
        <div className="col-12 box-content p-3">
          <h5 className="b-title d-flex align-items-center">
            <IoIosArrowDropleftCircle
              className="me-3 textSecondary2 cursorPointer"
              size="1.5em"
              onClick={() => {
                window.history.back();
              }}
            />{" "}
            Add my expense
          </h5>
          <div className="form row">
            <div className="col-md-6 left beforeNoneMd">
              <div className="item">
                <InputComponent
                  type={InputType.text}
                  label="Expense Name"
                  popQuestion="Enter a name for the expense you would like to add to the records."
                  onChange={(e) => {
                    this.handleChange("title", e.target.value);
                  }}
                  inValid={this.validator.message(
                    "Name",
                    this.state.title,
                    "required"
                  )}
                ></InputComponent>
              </div>
              <div className="item">
                {/* <InputComponent
                  type={InputType.text}
                  label="Associated Task:"
                  popQuestion="Associated Task:"
                ></InputComponent> */}
                {/* <span className="label d-flex align-items-center">
                Attach expense to a task
                </span> */}
                <SelectComponent
                  items={this.state.appTasksList}
                  TextItem="name"
                  label="Attach expense to a task"
                  popQuestion="Please select a task that you would like to assign this expense to. This will help track the expense easier."
                  ValueItem="id"
                  className="mt-1"
                  placeholder="Click to see the list…"
                  isMulti={false}
                  defaultValue={this.state.appTaskId}
                  onChange={(e) => {
                    // this.handelChangeSelect("appTaskId", e);
                    this.setState({
                      appTaskId: e
                    })
                  }}
                  inValid={this.validator.message(
                    "Task",
                    this.state.appTaskId,
                    "required"
                  )}
                ></SelectComponent>
              </div>
              <div className="item">
                <InputComponent
                  type={InputType.textarea}
                  label="Description"
                  popQuestion="Please briefly explain this expense for yourself and supervisors."
                  // optional="optional"
                  onChange={(e) => {
                    this.handleChange("description", e.target.value);
                  }}
                  inValid={this.validator.message(
                    "Description",
                    this.state.description,
                    "required"
                  )}
                ></InputComponent>
              </div>
            </div>
            <div className="col-md-6 right">
              <div className="row d-flex justify-content-between align-items-center">
                <div className="col-md-6">
                  <div className="item">
                    <InputComponent
                      type={InputType.text}
                      inputType="number"
                      label="Total amount"
                      popQuestion="Please enter the total amount of the expenses."
                      placeholder="$0.00"

                      onChange={(e) => {
                        this.handleChange("amount", e.target.value);
                      }}
                      inValid={this.validator.message(
                        "amount",
                        this.state.amount,
                        "required"
                      )}
                    ></InputComponent>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item">
                    <span className="label d-flex align-items-center">
                      Date
                      <CircleIconTooltip className="ms-2"
                        text="Please enter the date where this expense happened."
                      />
                    </span>
                    <DatePicker
                      selected={this.state.date}
                      onChange={(e) => {
                        this.handelChangeDate("date", e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="item">
                <span className="label d-flex align-items-center">
                  Receipt or documents
                  <div
                    // type={MainButtonType.light}
                    // children={optional}
                    // borderRadius="50px"
                    // fontSize="15px"
                    className="s-chip filled mx-2"
                  >
                    optional
                  </div>
                  <CircleIconTooltip
                    text="Please attach any documents associated with this expense."
                  />
                </span>
                <Dropzone onDrop={this.onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section
                      style={{ marginTop: "0.35em" }}
                      className="container fileUploadBox"
                    >
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <MainButton
                          type={MainButtonType.light}
                          minHeight="30px"
                          minWidth="179px"
                          fontSize="14px"
                          borderRadius="50px"
                          backgroundColor="#fff"
                          border="1px solid #707070"
                          color="#707070"
                          className="mt-4"
                          children={
                            <div className="d-flex justify-content-between align-items-center">
                              <img
                                src="/Images/icons/cloud_computing.svg"
                                alt="sssss"
                                height="20"
                              />{" "}
                              <span className="flex-fill">
                                Browse Local Files
                              </span>
                            </div>
                          }
                        ></MainButton>
                        <p>Or drag and drop files here</p>
                      </div>
                      <aside>
                        <ul>{files}</ul>
                      </aside>
                    </section>
                  )}
                </Dropzone>
              </div>
              <div className="item d-flex justify-content-between align-items-center">
                <InputComponent
                  type={InputType.text}
                  placeholder="https://"
                  className="mx-2"
                  value={this.state.External}
                  onChange={(e) => {
                    this.handleChange("External", e.target.value);
                  }}
                  onEnter={(e) => {
                    this.addExternalUrl();
                  }}
                ></InputComponent>
                <CircleIcon
                  width="36px"
                  padding="0"
                  height="36px"
                  type={ThemeCircleIcon.dark}
                  backgroundColor="#9D9D9D"
                  fontSize="18px"
                  color="#ffffff"
                  className=""
                  onClick={() => {
                    this.addExternalUrl();
                  }}
                >
                  <i className="fas fa-plus"></i>
                </CircleIcon>
              </div>
              <p className="text-error mb-0">
                {this.validator.message("input", this.state.External, "url")}
              </p>
              <ul className="file-list mt-1">
                {this.state.ExternalUrl.map((item) => (
                  <li className="my-1 d-flex align-items-center max1LineText">
                    <a
                      title="Go to the link"
                      className="max1LineText s-chip outlined fs-200 blueColor"
                      href={item}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item}
                    </a>
                    <CircleIcon
                      type={ThemeCircleIcon.dark}
                      width="22px"
                      height="22px"
                      padding="0"
                      className="ms-2 pointer"
                      onClick={() => this.handelDeleteExternalLink(item)}
                    >
                      <img
                        src="/images/icons/garbage_can.svg"
                        alt="radvix"
                        width={15}
                        height={15}
                      />
                    </CircleIcon>
                  </li>
                ))}
              </ul>
              <div className="item mt-0">
                <span className="label d-flex align-items-center">
                  Expense category
                  <div
                    // type={MainButtonType.light}
                    // children={optional}
                    // borderRadius="50px"
                    // fontSize="15px"
                    className="s-chip filled mx-2"
                  >
                    optional
                  </div>
                  <CircleIconTooltip
                    text="Please select a category for your expense. You can add or edit categories using the button on the right side."
                  />
                </span>
                <div className="d-flex justify-content-between align-items-center">
                  <SelectComponent
                    items={this.state.listCategory}
                    TextItem="name"
                    ValueItem="id"
                    className="mt-1 w-100"
                    placeholder="Click to see the list…"
                    isMulti={false}
                    onChange={(e) => {
                      this.setState({
                        selectedCategory: e,
                      });
                    }}
                    defaultValue={this.state.selectedCategory}
                  ></SelectComponent>
                  <span
                    className="ms-2"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-add-category-expense"
                  >
                    <CircleIcon
                      width="36px"
                      height="36px"
                      type={ThemeCircleIcon.dark}
                      backgroundColor="#9D9D9D"
                      fontSize="18px"
                      color="#ffffff"
                      padding="0"
                      className="pointer"
                    >
                      <i className="fas fa-list"></i>
                    </CircleIcon>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center my-4">
              {/* <MainButton
                type={MainButtonType.light}
                children={"Start Over"}
                borderRadius="50px"
                fontSize="18px"
                className="mx-2"
                minHeight="43px"
                minWidth="136px"
              ></MainButton> */}
              <MainButton
                type={MainButtonType.dark}
                children={"Add expense"}
                borderRadius="50px"
                disabled={this.state.loading}
                loading={this.state.loading}
                fontSize="18px"
                className="mx-2"
                minHeight="43px"
                minWidth="136px"
                onClick={() => {
                  this.handelCreateExpense();
                }}
              ></MainButton>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="modal-add-category-expense"
          tabIndex={-1}
          aria-labelledby="modal-add-category-expense"
          aria-hidden="true"
        >
          <CategoryManagementModal
            label="Expense category labels"
            onCreateNewCategory={(_categoryName) => {
              return new Promise((resolve, reject) => {
                this.handleCreateNewCategory(_categoryName).then((_res) => {
                  this.GetSearchExpense().then(() => {
                    resolve(_res as CreateNewCategoryResult);
                  });
                });
              });
            }}
            onEditCategory={(_categoryName, _categoryId) => {
              return new Promise((resolve, reject) => {
                this.handleEditCategory(_categoryName, _categoryId).then(
                  (_res) => {
                    this.GetSearchExpense().then(() => {
                      resolve(_res as CreateNewCategoryResult);
                    });
                  }
                );
              });
            }}
            onDeleteCategory={(_categoryId) => {
              return new Promise((resolve, reject) => {
                this.handleDeleteCategory(_categoryId).then((_res) => {
                  this.GetSearchExpense().then(() => {
                    resolve(_res as CreateNewCategoryResult);
                  });
                });
              });
            }}
            // @ts-ignore
            categories={this.state.modalCategories}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(ExpensePageNew);
