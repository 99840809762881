import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { AppRoutes } from "../../../../core/constants";
import { $ReplaceRoute, getUserTitle, UserRoles } from "../../../../core/utils";
import { MemberUser } from "../../../../data/models/responses/admin/list_member_user_res";
// import { MainButton, MainButtonType } from "../../../components/button";
import { CircleIcon, ThemeCircleIcon } from "../../../components/circle_icon";
import { IMAGE_BASE_URL } from "../../../../core/http_common";
// import { IMAGE_BASE_URL } from "../../../../core/http_common";
interface IMemberItem {
  member: MemberUser;
}
const MemberItem: React.FC<IMemberItem & RouteComponentProps> = (props) => {
  const _userTitle = getUserTitle(props.member);
  return (
    <div className="col-md-3 d-flex justify-content-center align-items-center flex-column mb-3">
      <img
        src={
          props.member.profileImage
            ? IMAGE_BASE_URL + props.member.profileImage
            : "/images/images/img_avatar.png"
        }
        alt="Avatar"
        className="rounded-circle avatar"
        width="100px"
        height="100px"
      />

      <h5
        className="mt-2 fw-light  text-truncate col-7 text-center"
        style={{ fontSize: "12px" }}
        title={props.member.firstName + " " + props.member.lastName}
      >
        {_userTitle}
      </h5>
      <hr className="w-100 my-0" />
      <span className="chipDark mt-2 mb-2">
        {UserRoles[props.member.role]}
      </span>
      {/* <MainButton
        children="Principal Investigator"
        type={MainButtonType.dark}
        borderRadius="24px"
        fontSize="11px"
        className="my-2"
      ></MainButton> */}
      <div className="d-flex justify-content-center align-items-center">
        <CircleIcon
          width="26px"
          height="26px"
          padding="0"
          type={ThemeCircleIcon.dark}
          onClick={() => {
            props.history.push(
              $ReplaceRoute(`${AppRoutes.member_profile.replace(
                ":id",
                props.member.id ?? ""
              )}`)
            );
          }}
          className="pointer mx-1"
        >
          <img
            src="/images/icons/google_docs.svg"
            alt="radvix"
            width={12}
            height={12}
          />
        </CircleIcon>
      </div>
    </div>
  );
};
export default withRouter(MemberItem);
