/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import { MainButton, MainButtonType } from "../../components/button";
import { InputComponent, InputType } from "../../components/inputs";
import { RouteComponentProps, useLocation, withRouter } from "react-router";
import { UserController } from "../../../controllers/user/user_controller";
// import { UserAccountHandleReq } from "../../../data/models/requests/user/account_req";

import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import { LeftBottomLoginLinks } from "../login/login";

const ActivateAccount: React.FC<RouteComponentProps> = (props) => {
  // const path = window.location.pathname;
  const [email, setemail] = useState("");
  const [loading, setloading] = useState(false);
  const location = useLocation();

  const controller: UserController = new UserController();
  useEffect(() => {
    if (location.state && location.state.email) {
      setemail(location.state.email);
    }
  }, [location]);
  async function emailHandler() {
    const formValid = Validator.current.allValid();

    if (!formValid) {
      Validator.current.showMessages();
      forceUpdate(1);
    } else {
      // const body: UserAccountHandleReq = {
      //   email: email,
      // };

      setloading(true);
      await controller.requestConfirmEmail(
        email,
        (res) => {
          setemail("");
          setloading(false);
        },
        () => {
          // console.log("error");
          setloading(false);
        }
      );
    }
  }
  // async function resetEmailHandler() {
  //   const formValid = Validator.current.allValid();

  //   if (!formValid) {
  //     Validator.current.showMessages();
  //     forceUpdate(1);
  //   } else {
  //     const body: UserAccountHandleReq = {
  //       email: email,
  //     };

  //     setloading(true);
  //     await controller.accountReset(
  //       body,
  //       (res) => {
  //         setemail("");
  //         setloading(false);
  //       },
  //       () => {
  //         // console.log("error");
  //         setloading(false);
  //       }
  //     );
  //   }
  // }

  const Validator = useRef(
    new SimpleReactValidator({
      className: "text-danger",
    })
  );
  const [, forceUpdate] = useState(0);
  return (
    <div className="login d-flex flex-column flex-md-row">
      <div className="left">
        <div className="left_logo_login">
          <img src="/images/images/radvix_logo.svg" className="logo" alt="" />
          <img
            src="/images/images/img_avatar.png"
            className="logo-Member"
            alt=""
          />
        </div>
        <LeftBottomLoginLinks />
      </div>
      <div className="right" style={{ maxWidth: 430 }}>
        <h1 className="right_login_title">Activate Your Account</h1>
        <p className="right_login_subHeading">
          If you have not received the activation email, please enter your email address in the form below. We will promptly send you a new activation link to your email.
        </p>
        <div className="w-100 ">
          <label className="text-light login_input_label">Email:</label>
          <InputComponent
            value={email}
            type={InputType.text}
            // placeholder="example@email.com bymani "
            onChange={(e) => {
              setemail(e.target.value);
            }}
            fontSize="16px"
            inValid={Validator.current.message(
              "email",
              email,
              "required|email"
            )}
          ></InputComponent>

          <div className="password mt-2 d-flex justify-content-center">
            <MainButton
              children={"Send Email"}
              type={MainButtonType.light}
              borderRadius="50px"
              minWidth="75px"
              minHeight="37px"
              fontSize="16px"
              fontWeight={700}
              className="py-2 px-4 mt-3"
              loading={loading}
              onClick={() => {
                // path === "/reset" ? 
                // resetEmailHandler()
                emailHandler();
              }}
            ></MainButton>
          </div>
        </div>

        <div className="text-center fs-300 mt-4">
          <p style={{ color: "rgba(255,255,255,0.64)" }}>
            Go Back to the{" "}
            <Link to="/login" style={{ color: "#fff" }}>
              Login
            </Link>{" "}
            {/* or{" "}
            <Link to="/register" style={{ color: "#fff" }}>
              Register
            </Link>{" "}
            for a New Account{" "} */}
          </p>
        </div>
      </div>
    </div>
  );
};
export default withRouter(ActivateAccount);
