import { toast } from "react-toastify";
import { CreateTicketReq } from "../../data/models/requests/discussion/create_ticket_req";
import { DiscusstionCreateReq } from "../../data/models/requests/discussion/discusstion_create_req";
import { BroadCastResResult } from "../../data/models/responses/discussion/create_broadcast_res";
import { CreateMessageRes } from "../../data/models/responses/discussion/create_massage_res";
import { CreateTicketResResult } from "../../data/models/responses/discussion/create_ticket_res";
import { DiscusstionCreateResResult } from "../../data/models/responses/discussion/discusstion_create_res";
import { DiscusstionSearchResResult } from "../../data/models/responses/discussion/discusstion_search_res";
import { GetAllDiscusstionResResult } from "../../data/models/responses/discussion/get_all_discusstion_res";
import { GetDiscusstionPanelResResult } from "../../data/models/responses/discussion/get_discusstion_panel_res";
import {
  DiscussionListResResult,
  DiscusstionSectionResResult,
} from "../../data/models/responses/discussion/section_user_res";
import { RemoteDiscusstion } from "../../data/remotes/discussion/remote_discusstion";
import { SortType } from "../../core/number_extentions";
import { IFilterOptions } from "../../views/components/FilterSection/IFilterOptions";
export class DiscusstionController {
 
  remote = new RemoteDiscusstion();

  createDiscusstion(
    body: DiscusstionCreateReq,
    action: (res: DiscusstionCreateResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.createDiscusstion(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  createMassage(
    body: { discussionId: number; message: string },
    action: (res: CreateMessageRes) => any,
    error: (res: any) => any
  ) {
    this.remote.createMassage(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  createBroadCast(
    body: { subject: string; broadCastTypes: number[]; message: string },
    action: (res: BroadCastResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.createBroadCast(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  createTicket(
    body: CreateTicketReq,
    action: (res: CreateTicketResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.createTicket(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  searchDiscusstion(
    body: { discussionTopic: number; isTicket: boolean; taskId?: number; researchId?: number },
    action: (res: DiscusstionSearchResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.DiscusstionSearch(
      body,
      (res) => {
        // toast.success(`${res.message}`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  getAllDiscusstion(
    body: {
      PageNumber: number;
      PageSize: number;
      ticket: boolean;
      SearchParameter: string;
      columnHeaderName?: string; 
      sortDirection?: SortType;
      filterOptions?: IFilterOptions,
      selectedProject?: any,
      profileType?: any,
      topicId?: any,
      sectionId?: any,
    },
    action: (res: GetAllDiscusstionResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getAllDiscussion(
      body,
      (res) => {
        // toast.success(`${res.message}`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  getDiscusstionPanel(
    body: { discussionId: number; ticket: boolean },
    action: (res: GetDiscusstionPanelResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getDiscusstionPanel(
      body,
      (res) => {
        // toast.success(`${res.message}`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  discusstionSectionUser(
    body: { discussionTopic: number; sectionId: number },
    action: (res: DiscusstionSectionResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.discusstionSectionUser(
      body,
      (res) => {
        // toast.success(`${res.message}`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  getDiscusstionBySectionAndTopic(
    body: {
      discussionTopic: number;
      sectionId: number;
      PageNumber: number;
      PageSize: number;
      columnHeaderName?: string, 
      sortDirection?: SortType
    },
    action: (res: DiscussionListResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getDiscusstionBySectionAndTopic(
      body,
      (res) => {
        // toast.success(`${res.message}`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  getDiscussionTopicData(
    body: {
      sectionId: number;
      topicId: number;
    },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.getDiscussionTopicData(
      body,
      (res) => {
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  updateDiscussion(
    body: {
      id: number;
      categoryId?: number | null;
      subject: string;
      priority: number;
      ticketStatus: number | null; //1 | 2;
      addedUsersId: string[];
      removedUsersId: string[];
    },
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.updateDiscussion(
      body,
      (res) => action(res.result!),
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);

        error(err);
      }
    );
  }
  addUsers(
    body: {
      id: number;
      topic: number;
      sectionId: number;
      usersId: string[];
    },
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.addUsers(
      body,
      (res) => action(res.result!),
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  getRecentDiscussion(
    body: {
      pageNumber: number;
      pageSize: number;
    },
    action: (res: {
      count: number;
      discussions: {
        id: number;
        subject: string;
        unreadMessageCount: number;
        historyCreatedAt: Date;
      }[];
    }) => void,
    error: (res: any) => any
  ) {
    this.remote.getRecentDiscussion(
      body,
      (res) => {
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  deleteDiscussion(
    body: {
      discussionId: number
    },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.deleteDiscussion(
      body,
      (res) => {
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
}
