import Tippy from "@tippyjs/react";
import { nanoid } from "nanoid";
import React from "react";
import { MdClose } from "react-icons/md";
import EmptyChip from "./EmptyChip";
import File from "./File";

type Props = {
  medias: any[];
  profileId: number;
  profileType: number;
};

export default function ShowFilesInTable({ medias, profileId, profileType }: Props) {
  const [menuVisible, setMenuVisible] = React.useState(false);
  return medias?.length > 0 ? (
    <div
      className="ellips gap-1 align-items-center"
      style={{
        display: "grid",
        gridTemplateColumns: `minmax(2em, min-content) ${medias.length > 1 ? '2em' : ''}`,
      }}
    >
      {/* {medias[?].map((_med: any) => ( */}
      <File file={medias[0]} profileType={profileType} researchId={profileId} />
      {/* ))} */}

      {medias.length > 1 && (
        <Tippy
          arrow={false}
          visible={menuVisible}
          interactive
          placement="bottom"
          onClickOutside={() => setMenuVisible(false)}
          content={
            <div className="d-flex flex-column">
              <div className="w-100 d-flex justify-content-between align-items-center">
                <span className="textSecondary">
                  All files ({medias.length})
                </span>
                <span
                  title="Close"
                  className="ms-2 pointer"
                  onClick={() => setMenuVisible(false)}
                >
                  <MdClose />
                </span>
              </div>
              <div className="d-flex flex-wrap gap-2 align-items-center mt-2">
                {medias.map((_med: any) => (
                  <File profileType={profileType} researchId={profileId} key={nanoid()} file={_med} />
                ))}
              </div>
            </div>
          }
        >
          <span
            title={menuVisible ? "Click to close" : "Show all files"}
            onClick={() => {
              
              setMenuVisible(!menuVisible);
            }}
            className="pointer darkenBg d-flex align-items-center justify-content-center s-chip outlined"
            style={{
              height: "1.75em",
              width: "1.75em",
              borderRadius: "50%",
              padding: 0,
            }}
          >
            {menuVisible ? (
              <MdClose />
            ) : (
              <span className="fs-100">{medias.length - 1}</span>
            )}
          </span>
        </Tippy>
      )}
    </div>
  ) : (
    <div className="d-flex">
    <EmptyChip />
    </div>
  );
}

