import { ReactElement, useEffect, useState } from "react";
import { ResearchStatus } from "../../core/number_extentions";
import * as Label from "@radix-ui/react-label";

interface IStatusLabel {
  status: number;
  deadLine: number;
  className?: string;
  size?: "md" | "sm";
  isSelected?: boolean;
  onStatusClick?: (statusId: any) => void;
}

export const StatusLabel = ({
  status,
  deadLine,
  size = "md",
  className = "",
  isSelected = false,
  onStatusClick,
}: IStatusLabel): ReactElement => {
  const [ProgressTimeline, setProgressTimeline] = useState<
    { color: string; status: string } | undefined
  >(undefined);

  useEffect(() => {
    const tempTimeLineStatus = status === ResearchStatus.OnGoing && deadLine < 0;

    if (tempTimeLineStatus) {
      setProgressTimeline({
        color: ResearchStatus.Delayed.isStatusColor(),
        status: ResearchStatus.Delayed.isStatus(),
      });
    } else {
      setProgressTimeline(undefined);
    }
  }, [status, deadLine]);

  return (
    <div className={`d-flex flex-wrap justify-content-start gap-1 ${className}`}>
      {ProgressTimeline !== undefined ? (
        <Label.Root
          // className="s-chip"
          // style={{
          //   backgroundColor: ProgressTimeline.color,
          //   color: "#FFFFFF",
          // }}
          // onClick={() =>
          //   onStatusClick ? onStatusClick({ Id: status, isStatusSelected: isSelected }) : {}
          // }
        >
          {ProgressTimeline.status}
        </Label.Root>
      ) : (
        <Label.Root
          className="s-chip"
          style={{
            backgroundColor: status?.isStatusColor(),
            color: status?.isStatusTextColor(),
          }}
          onClick={() =>
            onStatusClick ? onStatusClick({ Id: status, isStatusSelected: isSelected }) : {}
          }
        >
          {status.isStatus()}
        </Label.Root>
      )}
    </div>
  );
};
