import React, { useEffect, useState } from 'react';
import { Switch, useHistory, useLocation, withRouter } from 'react-router';
import { AppRoutes } from '../../../core/constants';
import {
  $ReplaceRoute,
  SubscriptionStatusEnum,
  UserRoles,
  UserStatus,
  getUserRole,
  handleClearStorageAndTokens,
  handleLogoutApi,
} from '../../../core/utils';
import ReactLoading from 'react-loading';
import './UserSubsciption.css';
import { SignupBackground } from '../signup/component/signup_background';
import { string } from 'yup';
import { useSelector } from 'react-redux';
import { store } from '../../../data/storeMain';
import { base_url_redirect, base_url_site } from '../../../core/localVariables';
import { BaseButton } from '@fluentui/react';
import { handleUnauthorizedRequest } from '../../../core/http_common';

interface RootState {
  userInfo: {
    paymentMethod: boolean;
    subscriptionStatus: SubscriptionStatusEnum;
    paymentMethodId: string;
    role: UserRoles;
    l1ClientEmail: string;
    status: UserStatus;
  };
}
const UserPayment = () => {
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [paymnetmethodId, setPaymentMethodId] = useState<string>();
  const userInfo = useSelector((state: RootState) => state.userInfo);
  useEffect(() => {
    //setPaymentMethodId(userInfo.paymentMethodId);
  }, []);

  const handleContactSupport = () => {
    window.location.href = base_url_redirect + 'contact-us';
  };

  const UpgradePayment = () => {
    history.push(AppRoutes.Subs_Payment);
  };

  const logout = () => {
    handleLogoutApi()
      .then((res) => {
        handleClearStorageAndTokens();
        setTimeout(() => {
          window.location.reload();
        }, 250);
      })
      .catch((err) => {
        if (err === 'Device Id not found') {
          handleClearStorageAndTokens();
          window.location.replace(AppRoutes.login);
          return;
        }
        const _tokenInStorage = localStorage.getItem('token');
        if (!_tokenInStorage) {
          handleClearStorageAndTokens();
          setTimeout(() => {
            window.location.reload();
          }, 250);
        } else {
          if (err.response && err.response.status === 401) {
            // Retry the request with refreshed token
            return handleUnauthorizedRequest(err.config).then((_res) => {
              handleClearStorageAndTokens();
              setTimeout(() => {
                window.location.reload();
              }, 250);
            });
            // .catch((e) => error(e));
          } else {
            handleClearStorageAndTokens();
            setTimeout(() => {
              window.location.reload();
            }, 250);
            // error(err);
          }
        }
      });
  };

  return (
    <>
      {userInfo.role != UserRoles.L1Client &&
        userInfo.subscriptionStatus != SubscriptionStatusEnum.active && (
          <>
            <div className='container'>
              <div className='max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center  vh-100'>
                <div className='text-center mb-3'>
                  <img
                    src='images/icons/new-radvix-logo.png'
                    width={111}
                    height={49}
                    // src='images/icons/radvix-logo.svg'
                    // srcSet='images/icons/radvix-logo.svg 111w'
                    // sizes='(max-width: 768px) 20vw, 10vw'
                    alt='Company logo'
                  />
                </div>
                <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column'>
                  <div className='d-flex w-100'>
                    <BaseButton
                      className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
                      style={{ fontWeight: '400', height: '19px' }}
                      onClick={() => {
                        logout();
                      }}
                    >
                      Back
                    </BaseButton>
                    <div
                      style={{
                        alignSelf: 'center',
                      }}
                    >
                      <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>
                        Workspace Restricted
                      </h1>
                    </div>
                  </div>
                  <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                  <div style={{ padding: '6px 0px 6px 0px' }}>
                    <h4
                      className='fs-15'
                      style={{
                        textAlign: 'center',
                        fontSize: '13px',
                        fontWeight: '400',
                      }}
                    >
                      Unfortunately, there has been an issue with your workspace payment method.
                      Please contact your account admin {' ('}
                      {userInfo.l1ClientEmail ? userInfo.l1ClientEmail : ''}
                      {') '} for further information.
                    </h4>
                  </div>
                  <a className='w-100 mt-3' href={`${base_url_site}/support`}>
                    <button
                      className='d-flex justify-content-center align-items-center btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton'
                      type='button'
                    >
                      {loading ? (
                        <ReactLoading className='d-flex' type='spin' height='auto' width={20} />
                      ) : (
                        'Contact Support'
                      )}
                    </button>
                  </a>
                  {/* <button
              className="d-flex justify-content-center align-items-center btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton"
              onClick={handleNavigate}
            >
              {loading ? (
                <ReactLoading
                  className="d-flex"
                  type="spin"
                  height="auto"
                  width={20}
                />
              ) : (
                "Continue"
              )}{" "}
            </button> */}
                  <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                  <div className='fs-14 fw-normal'>
                    <a
                      className='color-gray-600 cursor-pointer'
                      href={`${base_url_site}/terms-of-services`}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      Terms of Service |{' '}
                    </a>
                    <a
                      className='color-gray-600 cursor-pointer'
                      href={`${base_url_site}/privacy-policy`}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      {userInfo.role == UserRoles.L1Client &&
        userInfo.subscriptionStatus != SubscriptionStatusEnum.nopay && (
          <>
            <div className='container'>
              <div className='max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center vh-100'>
                <div className='text-center mb-3'>
                  <img
                    src='images/icons/new-radvix-logo.png'
                    width={111}
                    height={49}
                    // src='images/icons/radvix-logo.svg'
                    // srcSet='images/icons/radvix-logo.svg 111w'
                    // sizes='(max-width: 768px) 20vw, 10vw'
                    alt='Company logo'
                  />
                </div>
                <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column'>
                  <div className='d-flex w-100'>
                    <BaseButton
                      className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
                      style={{ fontWeight: '400', height: '19px' }}
                      onClick={() => {
                        logout();
                      }}
                    >
                      Back
                    </BaseButton>
                    <div
                      style={{
                        alignSelf: 'center',
                      }}
                    >
                      <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>
                        Workspace Restricted
                      </h1>
                    </div>
                  </div>
                  <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                  <div style={{ padding: '6px 0px 6px 0px' }}>
                    <h4
                      className='fs-15'
                      style={{
                        textAlign: 'center',
                        fontSize: '13px',
                        fontWeight: '400',
                      }}
                    >
                      Unfortunately, there has been an issue with your workspace payment method.
                      Please update your payment information using the link below.
                    </h4>
                  </div>
                  <button
                    className='d-flex justify-content-center align-items-center mt-3 btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton'
                    onClick={() => UpgradePayment()}
                  >
                    {loading ? (
                      <ReactLoading className='d-flex' type='spin' height='auto' width={20} />
                    ) : (
                      'Upgrade Payment Information'
                    )}{' '}
                  </button>
                  <a className='w-100' href={`${base_url_site}/support`}>
                    <button
                      className='d-flex justify-content-center align-items-center btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton'
                      type='button'
                    >
                      {loading ? (
                        <ReactLoading className='d-flex' type='spin' height='auto' width={20} />
                      ) : (
                        'Contact Support'
                      )}
                    </button>
                  </a>
                  <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                  <div className='fs-14 fw-normal'>
                    <a
                      className='color-gray-600 cursor-pointer'
                      href={`${base_url_site}/terms-of-services`}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      Terms of Service |{' '}
                    </a>
                    <a
                      className='color-gray-600 cursor-pointer'
                      href={`${base_url_site}/privacy-policy`}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <form>
                <div>
                  <div
                    className="paymentForm-container"
                    style={{
                      background:
                        "linear-gradient(360deg, rgba(212, 15, 237, 0) 0%, rgba(212, 15, 237, 1) 100%)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "20px",
                      }}
                    >
                      <img
                        src="/images/icons/PaymentError.svg"
                        alt="radvix"
                        width={"60.35px"}
                        height={"60.35px"}
                      />
                    </div>
                    <h2
                      className="text-center P_Heading mb-4"
                      style={{ paddingTop: "15px", fontSize: "36px" }}
                    >
                      Workspace Restricted
                    </h2>
                    <form>
                      <p style={{ textAlign: "center" }}>
                        Unfortunately, there has been an issue with your
                        workspace payment method. Please update your payment
                        information using the link below.
                      </p>
                      <div>
                        <button
                          className="Payment_Button mt-4 w-100"
                          type="submit"
                          onClick={() => UpgradePayment()}
                        >
                          {loading ? (
                            <ReactLoading
                              className="d-flex"
                              type="spin"
                              height="auto"
                              width={20}
                            />
                          ) : (
                            "Upgrade Payment Information"
                          )}
                        </button>
                      </div>
                      <div>
                        <a href="https://radvix.io/support">
                          <button
                            className="Payment_Button mt-4 w-100"
                            type="button"
                          >
                            {loading ? (
                              <ReactLoading
                                className="d-flex"
                                type="spin"
                                height="auto"
                                width={20}
                              />
                            ) : (
                              "Contact Support"
                            )}
                          </button>
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </form> */}
          </>
        )}

      {userInfo.role !== UserRoles.L1Client &&
        userInfo.subscriptionStatus == SubscriptionStatusEnum.active &&
        userInfo.status == UserStatus.deActivated && (
          <>
            <div className='container'>
              <div className='max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center vh-100'>
                <div className='text-center mb-3'>
                  <img
                    src='images/icons/new-radvix-logo.png'
                    width={111}
                    height={49}
                    // src='images/icons/radvix-logo.svg'
                    // srcSet='images/icons/radvix-logo.svg 111w'
                    // sizes='(max-width: 768px) 20vw, 10vw'
                    alt='Company logo'
                  />
                </div>
                <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column'>
                  <div className='d-flex w-100'>
                    <BaseButton
                      className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
                      style={{ fontWeight: '400', height: '19px' }}
                      onClick={() => {
                        logout();
                      }}
                    >
                      Back
                    </BaseButton>
                    <div
                      style={{
                        alignSelf: 'center',
                      }}
                    >
                      <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>
                        Access Restricted
                      </h1>
                    </div>
                  </div>
                  <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                  <div style={{ padding: '6px 0px 6px 0px' }}>
                    <h4
                      className='fs-15 pb-3'
                      style={{
                        textAlign: 'center',
                        fontWeight: '400',
                      }}
                    >
                      Unfortunately, your access to the workspace has been restricted by your admin.
                      In case you think this has been a mistake please reach out to them{' ('}
                      {userInfo.l1ClientEmail ? userInfo.l1ClientEmail : ''}
                      {') '}
                    </h4>
                  </div>
                  <a className='w-100' href={`${base_url_site}/support`}>
                    <button
                      className='d-flex justify-content-center align-items-center btn btn-primary fs-15 w-100 h-px-42 continurButton'
                      type='button'
                    >
                      {loading ? (
                        <ReactLoading className='d-flex' type='spin' height='auto' width={20} />
                      ) : (
                        'Contact Support'
                      )}
                    </button>
                  </a>
                  <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                  <div className='fs-14 fw-normal'>
                    <a
                      className='color-gray-600 cursor-pointer'
                      href={`${base_url_site}/terms-of-services`}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      Terms of Service |{' '}
                    </a>
                    <a
                      className='color-gray-600 cursor-pointer'
                      href={`${base_url_site}/privacy-policy`}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <form>
              <div>
                <div
                  className="paymentForm-container"
                  style={{
                    background:
                      "linear-gradient(360deg, rgba(212, 15, 237, 0) 0%, rgba(212, 15, 237, 1) 100%)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "20px",
                    }}
                  >
                    <img
                      src="/images/icons/PaymentError.svg"
                      alt="radvix"
                      width={"60.35px"}
                      height={"60.35px"}
                    />
                  </div>
                  <h2
                    className="text-center P_Heading mb-4"
                    style={{ paddingTop: "15px", fontSize: "36px" }}
                  >
                    Access Restricted
                  </h2>
                  <form>
                    <p style={{ textAlign: "center" }}>
                      Unfortunately, your access to the workspace has been
                      restricted by your admin. In case you think this has been
                      a mistake please reach out to them{" ("}
                      {userInfo.l1ClientEmail ? userInfo.l1ClientEmail : ""}
                      {") "}
                    </p>
                    <div>
                      <a href="https://radvix.io/support">
                        <button
                          className="Payment_Button mt-4 w-100"
                          type="button"
                        >
                          {loading ? (
                            <ReactLoading
                              className="d-flex"
                              type="spin"
                              height="auto"
                              width={20}
                            />
                          ) : (
                            "Contact Support"
                          )}
                        </button>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </form> */}
          </>
        )}
      {userInfo.role !== UserRoles.L1Client &&
        userInfo.subscriptionStatus == SubscriptionStatusEnum.active &&
        userInfo.status == UserStatus.readOnly && (
          <>
            <div className='container'>
              <div className='max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center vh-100'>
                <div className='text-center mb-3'>
                  <img
                    src='images/icons/new-radvix-logo.png'
                    width={111}
                    height={49}
                    // src='images/icons/radvix-logo.svg'
                    // srcSet='images/icons/radvix-logo.svg 111w'
                    // sizes='(max-width: 768px) 20vw, 10vw'
                    alt='Company logo'
                  />
                </div>
                <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column'>
                  <div className='d-flex w-100'>
                    <BaseButton
                      className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
                      style={{ fontWeight: '400', height: '19px' }}
                      onClick={() => {
                        logout();
                      }}
                    >
                      Back
                    </BaseButton>
                    <div
                      style={{
                        alignSelf: 'center',
                      }}
                    >
                      <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>
                        Read-Only Access
                      </h1>
                    </div>
                  </div>
                  <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                  <div style={{ padding: '6px 0px 6px 0px' }}>
                    <h4
                      className='fs-15'
                      style={{
                        textAlign: 'center',
                        fontSize: '13px',
                        fontWeight: '400',
                      }}
                    >
                      Your access type in workspace has been changed to “Ready-Only” which means you
                      still have access to all data but won’t be able to add or create objects.
                    </h4>
                  </div>
                  <button
                    className='d-flex justify-content-center align-items-center mt-3 btn btn-primary fs-15 w-100 h-px-42 continurButton'
                    onClick={() =>
                      history.push(`/${store.getState().userInfo.companyName}/dashboard/home`)
                    }
                  >
                    {loading ? (
                      <ReactLoading className='d-flex' type='spin' height='auto' width={20} />
                    ) : (
                      'View Dashboard'
                    )}{' '}
                  </button>
                  <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
                  <div className='fs-14 fw-normal'>
                    <a
                      className='color-gray-600 cursor-pointer'
                      href='https://radvix.io/terms-of-services'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      Terms of Service |{' '}
                    </a>
                    <a
                      className='color-gray-600 cursor-pointer'
                      href='https://radvix.io/privacy-policy'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <form onSubmit={handleContactSupport}>
              <div>
                <div
                  className="paymentForm-container"
                  style={{
                    background:
                      "linear-gradient(360deg, rgba(212, 15, 237, 0) 0%, rgba(212, 15, 237, 1) 100%)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "20px",
                    }}
                  >
                    <img
                      src="/images/icons/PaymentError.svg"
                      alt="radvix"
                      width={"60.35px"}
                      height={"60.35px"}
                    />
                  </div>
                  <h2
                    className="text-center P_Heading mb-4"
                    style={{ paddingTop: "15px", fontSize: "36px" }}
                  >
                    Read-Only Access
                  </h2>
                  <form>
                    <p
                      style={{ textAlign: "center", padding: "0 15px 0 15px" }}
                    >
                      Your access type in workspace has been changed to
                      “Ready-Only” which means you still have access to all data
                      but won’t be able to add or create objects.
                    </p>
                    <div>
                      <button
                        className="Payment_Button mt-4 w-100"
                        type="submit"
                        onClick={() =>
                          history.push(
                            `/${
                              store.getState().userInfo.companyName
                            }/dashboard/home`
                          )
                        }
                      >
                        {loading ? (
                          <ReactLoading
                            className="d-flex"
                            type="spin"
                            height="auto"
                            width={20}
                          />
                        ) : (
                          "View Dashboard"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </form> */}
          </>
        )}
    </>
  );
};
export default withRouter(UserPayment);
