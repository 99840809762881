import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { AdminController } from "../../../controllers/admin/admin_controller";
import { HTTP, IMAGE_BASE_URL } from "../../../core/http_common";
import {
  $truncate,
  isTokenInHttpAuth,
  UserRoles,
} from "../../../core/utils";
import { UserClients } from "../../../data/models/responses/admin/clients_res";
import { ButtonGroup } from "../../components/botton_group";
import { MainButton, MainButtonType } from "../../components/button";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import { InputComponent, InputType } from "../../components/inputs";
import { InputIcon } from "../../components/search_box";
import { SelectComponent } from "../../components/select_input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NewTable from "../../components/NewTable/NewTable";
import { ImageWithToken } from "../../components/box_list_scroll";
// import { AppConstants } from "../../../core/constants";
import moment from "moment";
import { RouteComponentProps, withRouter } from "react-router";
import { MdMoreVert } from "react-icons/md";
import { RegisterController } from "../../../controllers/register/research_controller";
import { SubscriptionResResult } from "../../../data/models/responses/register/subscription_res";

import debounce from "lodash.debounce";
import Highlighter from "react-highlight-words";
import AdminUserDetail from "./component/userDetailModal";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
type StateType = {
  Members: UserClients[];
  SubscriptionId: number | null;
  PageNumber: number;
  PageSize: { label: string; value: number };
  PageCount: number;
  TotalCount: number;
  Role: { label: string; value: number | null };
  Search: string;
  selectedUserClient: string | null;
  subscriptionPlans: [];
  loading: boolean;
  userId: string | null;

};
const subscriptionColors: any = {
  Free: "#a3a3a3",
  Essential: "#00a598",
  Standard: "#0020a5",
  Enterprise: "#ffba00",
};

class AdminUsers extends React.Component<RouteComponentProps> {
  // export class AdminClients extends React.Component {
  controller = new AdminController();
  subController = new RegisterController();

  state: StateType = {
    subscriptionPlans: [],
    Members: [],
    PageNumber: 1,
    PageSize: { label: "10", value: 10 },
    PageCount: 0,
    TotalCount: 0,
    Search: "",
    selectedUserClient: null,
    SubscriptionId: null,
    Role: {
      label: "All",
      value: null,
    },
    loading: true,
    userId: null,
  };

  constructor(props: any) {
    super(props);
    this.handleChangeUserSearch = debounce(
      this.handleChangeUserSearch.bind(this),
      500
    );
  }
  componentDidMount() {
    if (!isTokenInHttpAuth(HTTP)) return;

    this.initialize();
  }
  async initialize() {
    const _urlParams = this.props.location?.search;
    if (_urlParams) {
      const _splitiedParams = _urlParams.split("=");
      const _subId = _splitiedParams[1];
      const { search } = this.props.location;
      const params = new URLSearchParams(search);
      const userId = params.get('selectedClientId');

      await this.GetUsers(
        this.state.PageNumber,
        this.state.PageSize.value,
        Number(_subId),
        null,
        String(userId)

      );
      this.setState({
        SubscriptionId: Number(_subId),
        userId: userId,
      });
    } else {
      await this.GetUsers(this.state.PageNumber, this.state.PageSize.value);
    }

    await this.getSubscriptionPlans();
  }
  getSubscriptionPlans() {
    return new Promise((resolve, reject) => {
      this.subController.getSubscription(
        {
          PageNumber: 1,
          PageSize: 100,
          SearchParameter: "",
        },
        (res) => {
          this.setState({
            subscriptionPlans: res,
          });

          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  GetUsers(
    PageNumber: number,
    PageSize: number,
    _subId?: number | null,
    Search?: string | null,
    Id?: string | null
  ) {
    this.setState({
      loading: true,
    });
    return new Promise((resolve, reject) => {
      this.controller.getListMembers(
        {
          id: Id ? Id : this.state.userId,
          pageNumber: PageNumber,
          pageSize: PageSize,
          searchParameter: Search ? Search : this.state.Search,
          role: this.state.Role.value,
          subscriptionId: _subId ? _subId : this.state.SubscriptionId,
        },
        (res) => {          
          this.setState({
            Members: res?.members || [],
            PageCount: Math.ceil(res?.count! / this.state.PageSize.value),
            TotalCount: res?.count || 0,
            loading: false,
          });
          resolve(res);
        },
        (err: any) => {
          this.setState({
            loading: false,
          });
          reject(err);
        }
      );
    });
  }
  handelChangePageNumber(e: { selected: number }) {
    this.setState({
      PageNumber: e.selected,
    });
    this.GetUsers(e.selected + 1, this.state.PageSize.value);
  }
  handelChangePageSize(e: { label: string; value: number }) {
    this.setState({
      PageSize: e.value,
    });
    this.GetUsers(this.state.PageNumber, e.value);
  }
  handleChangeUserSearch(e: any) {
    this.GetUsers(
      this.state.PageNumber,
      this.state.PageSize.value,
      null,
      e.target.value
    );
  }
  headerClick = (columnHeaderName?: string, sortDirection?: string) => {
  };
  render() {
    // const _span = document.getElementById("invite-client-span");
    // if (_span) {
    //   _span.click();
    // }
    return (
      <div className="container-fluid research">
        <div className="row"></div>
        <div className="col-12">
          <div className="TableBox">
            <div className="TopTableBox d-flex justify-content-between align-items-center mb-3">
              <div className="left d-flex align-items-baseline">   
              <h5 className="d-flex justify-content-between align-items-center">           
              <IoIosArrowDropleftCircle
                className="me-3 textSecondary2 cursorPointer"
                size="1.5em"
                onClick={() => {
                  window.history.back();
                }}
              />
              {" "}
              
                Radvix Users
              
              </h5>
              </div>
              <div className="right d-flex justify-content-end align-items-center">
                {/* <span
                  id="invite-client-span"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-invite-client"
                >
                  <MainButton
                    children="Add User"
                    type={MainButtonType.dark}
                    borderRadius="24px"
                    fontSize="14px"
                    minWidth="100px"
                    minHeight="34px"
                    className="mx-4"
                  ></MainButton>
                </span> */}
                <InputIcon
                  chilren={
                    <img src="/images/icons/search_box_icon.svg" alt="" />
                  }
                  // width="100%"
                  parentClassName="mx-2"
                  placeholder="Search..."
                  TopPosition="15%"
                  onChange={(e) => {
                    this.setState({
                      Search: e.target.value,
                    });
                    this.handleChangeUserSearch(e);
                  }}
                ></InputIcon>
                <div style={{ width: "8em", maxWidth: "100%" }}>
                  <SelectComponent
                    className="me-2"
                    // minWidth="240px"

                    height="44px"
                    defaultValue={this.state.Role}
                    items={[
                      { label: "All", value: null },
                      { label: "L1 Clients", value: 1 },
                      { label: "L1 Users", value: 2 },
                      { label: "L2 Users", value: 3 },
                      { label: "L3 Users", value: 4 },
                    ]}
                    TextItem="item"
                    ValueItem="id"
                    isMulti={false}
                    placeholder="Select"
                    onChange={(e) => {
                      this.setState({
                        Role: e,
                      });
                      setTimeout(() => {
                        this.GetUsers(
                          this.state.PageNumber,
                          this.state.PageSize.value
                        );
                      }, 500);
                      // this.handelChangePageSize(e);
                    }}
                  ></SelectComponent>
                </div>
                <SelectComponent
                  width="90px"
                  height="44px"
                  items={[
                    { label: "10", value: 10 },
                    { label: "15", value: 15 },
                    { label: "20", value: 20 },
                  ]}
                  TextItem="item"
                  ValueItem="id"
                  defaultValue={this.state.PageSize}
                  isMulti={false}
                  placeholder={this.state.PageSize.toString()}
                  onChange={(e) => {
                    this.handelChangePageSize(e);
                  }}
                ></SelectComponent>
              </div>
            </div>
            <NewTable
              data={this.state.Members}
              loading={this.state.loading}
              // customGridTemplate="3.25em 2fr 1fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 1fr"
              customGridTemplate="3.25em 2fr 1fr 1fr 0.8fr 0.4fr 0.8fr 0.8fr 1fr"
              onHeaderClick={(columnHeaderName, sortDirection) => this.headerClick(columnHeaderName, sortDirection)}
              columns={[
                {
                  Header: "",
                  accessor: "firstName",
                  Cell: (_row) => (
                    <ImageWithToken
                      /*  hasImage={_row.profileImage ? true : false}
                       originalImage={_row.profileImage} */
                      src={
                        /* _row.profileImage
                          ? IMAGE_BASE_URL + _row.profileImage
                          : */ "/Images/images/img_avatar.png"
                      }
                      alt="Avatar"
                      className="rounded-circle avatar mx-2"
                      height={40}
                      width={40}
                    />
                  ),
                },
                {
                  Header: "User Name",
                  accessor: "firstName",
                  ellipsis: true,
                  Cell: (_row: any) => {
                    const _userName =
                      _row.firstName || _row.lastName
                        ? _row.firstName + " " + _row.lastName
                        : "-";
                    return (
                      <div
                        className="d-flex flex-column max1LineText pe-3"
                        style={{ lineHeight: 1.4 }}
                      >
                        <span
                          title={`Fullname (firstName + lastName) => ${_userName}`}
                          className="max1LineText font-bold"
                          style={{ fontSize: "1rem" }}
                        >
                          {this.state.Search ? (
                            <Highlighter
                              highlightClassName="highlightedText"
                              searchWords={
                                this.state.Search.length >= 2
                                  ? this.state.Search?.split(" ")
                                  : []
                              }
                              autoEscape={true}
                              textToHighlight={String(_userName)}
                            />
                          ) : (
                            _userName
                          )}
                        </span>
                        <span
                          title={`Emailto: ${_row.email}`}
                          className={
                            _row.firstName !== " "
                              ? "textSecondary max1LineText fs-100"
                              : "max1LineText fs-300 font-bold"
                          }
                        >
                          <a href={`mailto:${_row.email}`}>
                            {this.state.Search ? (
                              <Highlighter
                                highlightClassName="highlightedText"
                                searchWords={
                                  this.state.Search.length >= 2
                                    ? this.state.Search?.split(" ")
                                    : []
                                }
                                autoEscape={true}
                                textToHighlight={String(_row.email)}
                              />
                            ) : (
                              $truncate(_row.email, 32)
                            )}
                          </a>
                        </span>
                      </div>
                      // <IconTextRow
                      //   className="max1LineText"
                      //   spanClassName="max1LineText"
                      //   theme={Theme.light}
                      //   children={}
                      //   text={}
                      // ></IconTextRow>
                    );
                  },
                },
                {
                  Header: "Registration Status",
                  accessor: "isEmailConfirmed",
                  align: "center",
                  Cell: (_row: any) => (
                    <span
                      title={_row.isEmailConfirmed}
                      className="pendingChip">
                      {_row.isEmailConfirmed === true ? "Approved" : "Pending"}
                    </span>
                  ),
                },
                {
                  Header: "Institution",
                  accessor: "institution",
                  ellipsis: true,
                  // align: "le",
                },
                {
                  Header: "Date Joined",
                  accessor: "joinedDate",
                  ellipsis: true,
                  // align: "left",
                  Cell: (_row: any) => (
                    <span title={moment(_row.joinedDate).format("YYYY/MM/DD")}>
                      {moment(_row.joinedDate).format("ll")}
                    </span>
                  ),
                },
                {
                  Header: "Sub ID",
                  accessor: "subscriptionId",
                  align: "center",
                },
                {
                  Header: "Subscription",
                  accessor: "subscription",
                  align: "center",
                  Cell: (_row: any) => (
                    <span
                      className="pendingChip"
                      style={{
                        backgroundColor:
                          subscriptionColors[
                          _row.subscription.split(" ")[1] || "Free"
                          ],
                        color:
                          _row.subscription === "Radvix Standard"
                            ? "white"
                            : "black",
                      }}
                    >
                      {_row.subscription.split(" ")[1]}
                    </span>
                  ),
                },
                {
                  Header: "User Role",
                  accessor: "role",
                  align: "center",
                  Cell: (_row) => (
                    <span
                      title={UserRoles[_row.role]}
                      className="pendingChip"
                    // style={{
                    //   backgroundColor:
                    //     subscriptionColors[
                    //       _row.subscription.split(" ")[1] || "Free"
                    //     ],
                    //   color:
                    //     _row.subscription === "Radvix Standard"
                    //       ? "white"
                    //       : "black",
                    // }}
                    >
                      {/* {UserRoles[_row.role]?.slice(0, 2) || "-"} */}
                      {UserRoles[_row.role] || "-"}
                    </span>
                  ),
                },
                // {
                //   Header: "Projects",
                //   accessor: "totalProjects",
                //   align: "center",
                // },
                // {
                //   Header: "Storage Usage",
                //   accessor: "storageUsage",
                //   align: "center",
                // },
                {
                  Header: "",
                  accessor: "id",
                  Cell: (_row: any) => (
                    <div className="d-flex justify-content-end">
                      {/* <CircleIcon
                        width="26px"
                        height="26px"
                        type={ThemeCircleIcon.dark}
                        onClick={() => {
                          this.props.history.push(
                            `${AppRoutes.admin_member.replace(
                              ":id",
                              _row.id?.toString() +
                                `?name=${_row.firstName + _row.lastName}`
                            )}`
                          );
                        }}
                        className="pointer mx-1"
                      >
                        <img
                          src="/images/icons/google_docs.svg"
                          alt="radvix"
                          width={12}
                          height={12}
                        />
                      </CircleIcon> */}
                      <span
                        //

                        id="modal-user-detail-span"
                        data-bs-toggle="modal"
                        data-bs-target="#modal-user-detail"
                      >
                        <CircleIcon
                          width="26px"
                          height="26px"
                          padding="0"
                          type={ThemeCircleIcon.dark}
                          onClick={() => {
                            this.setState({
                              selectedUserClient: _row.id,
                            });
                            // this.props.history.push(
                            //   `${AppRoutes.admin_member.replace(
                            //     ":id",
                            //     _row.id?.toString() +
                            //       `?name=${_row.firstName + _row.lastName}`
                            //   )}`
                            // );
                          }}
                          className="pointer"
                        >
                          <MdMoreVert />
                        </CircleIcon>
                      </span>
                    </div>
                  ),
                },
              ]}
            />
            {/* <RadvixClients
              Items={this.state.Members}
              Heading={[
                { name: "Client Name", center: false, key: "firstName" },
                { name: "Institution", center: true, key: "institution" },
                { name: "Date Joined", center: true, key: "joinedDate" },
                { name: "Subscription", center: true, key: "subscription" },
                { name: "Total Members", center: true, key: "totalMembers" },
                { name: "Projects", center: true, key: "projects" },
                { name: "Storage Usage", center: true, key: "storageUsage" },
              ]}
            ></RadvixClients> */}

            <div className="d-flex justify-content-between align-items-center px-3 mt-3">
              <div className="d-flex justify-content-end flex-fill">
                <ReactPaginate
                  previousLabel={
                    <CircleIcon
                      width="24px"
                      backgroundColor="#ADADAD"
                      height="24px"
                      padding="0"
                      type={ThemeCircleIcon.dark}
                    >
                      <i className="fas fa-chevron-left"></i>
                    </CircleIcon>
                  }
                  nextLabel={
                    <CircleIcon
                      width="24px"
                      backgroundColor="#ADADAD"
                      height="24px"
                      padding="0"
                      type={ThemeCircleIcon.dark}
                    >
                      <i className="fas fa-angle-right"></i>
                    </CircleIcon>
                  }
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.PageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(e) => {
                    this.handelChangePageNumber(e);
                  }}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
              <div className="d-flex justify-content-end flex-fill">
                <p className="text-right mb-0 ">
                  Total Results: {this.state.TotalCount}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="modal-invite-client"
          tabIndex={-1}
          aria-labelledby="modal-invite-client"
          aria-hidden="true"
        >
          <AddClientModal subscriptionPlans={this.state.subscriptionPlans} />
        </div>
        <div
          className="modal fade"
          id="modal-user-detail"
          tabIndex={-1}
          aria-labelledby="modal-user-detail"
          aria-hidden="true"
        >
          <AdminUserDetail
            selectedUser={this.state.selectedUserClient}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(AdminUsers);

type AddClientModalProps = {
  subscriptionPlans: SubscriptionResResult[];
};
const AddClientModal = ({ subscriptionPlans }: AddClientModalProps) => {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    invitationNote: "",
    selectedSubscription: 1,
    subscriptionExpireDate: new Date(),
  });
  const _subscriptionOptions = subscriptionPlans.map((item) => {
    return {
      id: item.id,
      name: item.title.split(" ")[1],
      disable: item.id === 4,
    };
  });

  const handleChangeFormState = (_k: string, _v: any) => {
    setForm((prev) => ({
      ...prev,
      [_k]: _v,
    }));
  };

  const handleInviteClient = () => {
    
  };
  return (
    <div className="modal-dialog" style={{ width: 800, maxWidth: "100%" }}>
      <div className="modal-content bg-light">
        <div className="modal-header">
          <h5 className="mb-0">Invite a new client</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="close_modal_Type"
          ></button>
        </div>
        <div className="d-flex align-items-start flex-column flex-sm-row p-3 gap-3">
          <div className="d-flex flex-column w-100">
            <InputComponent
              value={form.firstName}
              onChange={(e) =>
                handleChangeFormState("firstName", e.target.value)
              }
              className="mb-3"
              fontSize="14px"
              type={InputType.text}
              // placeholder=""
              // onEnter={handleCreateNewCategory}
              label="First Name:"
            // popQuestion="Description:"
            // optional="optional"
            // onChange={(e) => {
            //   this.handleChange("description", e.target.value);
            // }}
            ></InputComponent>
            <InputComponent
              value={form.lastName}
              onChange={(e) =>
                handleChangeFormState("lastName", e.target.value)
              }
              className="mb-3"
              fontSize="14px"
              type={InputType.text}
              // placeholder=""
              // onEnter={handleCreateNewCategory}
              label="Last Name:"
            // popQuestion="Description:"
            // optional="optional"
            // onChange={(e) => {
            //   this.handleChange("description", e.target.value);
            // }}
            ></InputComponent>
            <InputComponent
              value={form.email}
              onChange={(e) => handleChangeFormState("email", e.target.value)}
              className="mb-3"
              fontSize="14px"
              type={InputType.text}
              // placeholder=""
              // onEnter={handleCreateNewCategory}
              label="User Email:"
            // popQuestion="Description:"
            // optional="optional"
            // onChange={(e) => {
            //   this.handleChange("description", e.target.value);
            // }}
            ></InputComponent>
            <InputComponent
              value={form.invitationNote}
              onChange={(e) =>
                handleChangeFormState("invitationNote", e.target.value)
              }
              // className="mx-2"
              fontSize="14px"
              type={InputType.textarea}
              rows={4}
              // placeholder=""
              // onEnter={handleCreateNewCategory}
              label="Invitation Note:"
            // popQuestion="Description:"
            // optional="optional"
            // onChange={(e) => {
            //   this.handleChange("description", e.target.value);
            // }}
            ></InputComponent>
          </div>
          <div className="d-flex flex-column w-100 date-picker-styles">
            <div className="item">
              <ButtonGroup
                label="Subscription Plan:"
                // popQuestion="Subscription Plan:"
                name="selectedPlan"
                items={_subscriptionOptions}
                TextItem="name"
                ValueItem="id"
                selected={form.selectedSubscription}
                onChange={(e) => {
                  handleChangeFormState(
                    "selectedSubscription",
                    parseInt(e.target.value)
                  );
                }}
              ></ButtonGroup>
            </div>
            <span className="label d-flex align-items-center mt-3 mb-1">
              Trial Expiration Date:
            </span>
            <DatePicker
              selected={form.subscriptionExpireDate}
              onChange={(e) => {
                handleChangeFormState("subscriptionExpireDate", e);
              }}
            />

            <MainButton
              children="Send Invitation"
              onClick={() => handleInviteClient()}
              type={MainButtonType.dark}
              borderRadius="24px"
              fontSize="14px"
              minWidth="100px"
              minHeight="34px"
              className="mt-4"
            ></MainButton>
          </div>
        </div>
      </div>
    </div>
  );
};


