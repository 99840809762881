import { toast } from "react-toastify";
import { TeamCreateReq } from "../../data/models/requests/team/team_create_req";
import { UpdateTeamReq } from "../../data/models/requests/team/update_team_req";
import { GetAllTeamsResult } from "../../data/models/responses/team/get_all_teams_res";
import { GetTeamByIDResResult } from "../../data/models/responses/team/get_by_id_res";
import { TeamCreateResResult } from "../../data/models/responses/team/team_create_res";
import { TeamSearchResResult } from "../../data/models/responses/team/team_search_res";
import { UpdateTeamResResult } from "../../data/models/responses/team/update_team_res";
import { RemoteTeam } from "../../data/remotes/team/remote_team";
import { SortType } from "../../core/number_extentions";
import { IFilterOptions } from "../../views/components/FilterSection/IFilterOptions";
import { NotesCreateReq,NotesGetReq } from "../../data/models/requests/notes/notes_create_req";
import { RemoteNotes } from "../../data/remotes/notes/remote_notes";


export class NotesController {
  remote = new RemoteNotes();

  createNotes(
    body: NotesCreateReq,
    action: (res: TeamCreateResResult) => any,
    error: (res: any) => any
  ) {

    this.remote.createNotes(
      body,
      (res) => {
        toast.success('Note added successfully');
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
          error(err)
      }
    );
  }

  getNotes(
    body: NotesGetReq,
    action: (res: any) => any,
    error: (res: any) => any
  ) {

    this.remote.getNotes(
      body,
      (res) => {
        // toast.success('Note added successfully');
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
          error(err)
      }
    );
  }
}