import React, { SVGProps, useState } from 'react';
import { store } from '../../../data/storeMain';
import { InputComponent, InputType } from '../../components/inputs';
import 'react-datepicker/dist/react-datepicker.css';
import { MainButton, MainButtonType } from '../../components/button';
import { SelectComponent } from '../../components/select_input';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
import Dropzone from 'react-dropzone';
import * as Dialog from '@radix-ui/react-dialog';
import { UserController } from '../../../controllers/user/user_controller';
import { MemberController } from '../../../controllers/member/member_controller';
import { LocalDataSources, UserImage } from '../../../data/local_datasources';
import {
  // AppConstants,
  AppRoutes,
  DEFAULT_RESEARCH,
  clientId,
  socialMediaLogos,
} from '../../../core/constants';
import { UploadController } from '../../../controllers/upload_media/upload_media';
import { UpdateMyProfileReq } from '../../../data/models/requests/user/update_myprofile_req';
// import countries from "../../../core/json/countries.json";
import {
  $truncate,
  AccessPermition,
  addSpaceBetweenWords,
  // getUserRole,
  handleClearStorageAndTokens,
  handleLogoutApi,
  isTokenInHttpAuth,
  secureEmail,
  // uploadAllFiles,
  uploadToS3PresignedUrl,
  UserRoles,
} from '../../../core/utils';
import GetPictureAndCrop from '../../components/GetPictureAndCrop';
// import { SetUserRole } from "../../../data/storeMain/actions/user_action";
import { HTTP, IMAGE_BASE_URL, handleUnauthorizedRequest } from '../../../core/http_common';
import { UserSocial } from '../../../data/models/responses/member/get_member_by_id_res';
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';
import SimpleReactValidator from 'simple-react-validator';
// import { InputIcon } from "../../components/search_box";
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import { SetResearchId } from '../../../data/storeMain/actions/research_action';
import { useHistory } from 'react-router';
import { ValidPassword } from '../../components/valid_password';
import SpinnerLoader from '../../components/spinnerLoader';
import { CategoryController } from '../../../controllers/category/category_controller';
import CircleIconTooltip from '../../components/CircleIconTooltip';
import GoogleLogin from 'react-google-login';
import { MicrosoftLogin } from '../login/login';
import Skeleton from 'react-loading-skeleton';
import MfaSection from './mfaSection';
import DOMPurify from 'dompurify';
import { Button, Separator } from '@radix-ui/themes';
import { BaseButton } from '@fluentui/react';
import { SetHeaderTitle } from '../../../data/storeMain/actions/header_title_action';

type StateType = {
  isMobileNumberVerified: boolean;
  id: string;
  microsoftEmail: string | null;
  googleEmail: string | null;
  firstName: string;
  lastName: string;
  email: string;
  degree: { value: number; label: string };
  major: string;
  phoneNumber: string;
  institution: string;
  addressLine1: string;
  addressLine2: string;
  locationId: number;
  selectedLocation: { value: number; label: string } | null;
  zipCode: string;
  token: string;
  role: number | null;
  profileImage: UserImage | null;
  resume: [];
  resumeAdded: Array<File>;
  files: Array<File>;
  picture: Array<File>;
  loading: boolean;
  pageLoading: boolean;
  cahngePic: boolean;
  ExternalUrl: Array<UserSocial>;
  External: string;
  university: string;
  listDegree: Array<{ value: number; label: string }>;
  userTypeList: Array<{ value: number; label: string }>;
  selectedUserTypeId: { value: number; label: string };
  socialMediaProfiles: Array<UserSocial>;
  socialMediaProfilesOptions: Array<{ value: number; label: string }>;
  listCountry: Array<{ label: string; value: number }>;
  billingEmail: string;
  billingAddress: string;
  cardInfomation: string;
  cardExpireDate: string;
  mobileNumber: string | null;
  cardCVC: string;
  nameOnCard: string;
  city: string;
  province: string;
  loadingMode?: string;
  removedMediasId: [];
  addedSocialMediaLinks: [];
  selectedSocialMediaLinkType: { value: number; label: string } | null;
  selectedSocialMediaLinkUrl: string;
  listTimeZone: { label: string; value: number }[];
  selectedTimeZone: { value: number; label: string } | null;
  basicInfo: boolean;
  academicInfo: boolean;
  contactInfo: boolean;
  securityLoginInfo: boolean;
  selectedTab: number;
  showModal: boolean;
};
export class EditMyProfile extends React.Component {
  // RoleUser = store.getState().userRole;
  controller = new UserController();
  controllerMember = new MemberController();
  UploadController = new UploadController();
  categoryController = new CategoryController();

  validator = new SimpleReactValidator({
    className: 'text-danger',
  });
  local = new LocalDataSources();
  state: StateType = {
    id: '',
    loadingMode: '',
    googleEmail: null,
    microsoftEmail: null,
    mobileNumber: null,
    userTypeList: [],
    isMobileNumberVerified: false,
    resume: [],
    firstName: '',
    lastName: '',
    email: '',
    degree: {
      label: 'Select Degree',
      value: -1,
    },
    selectedUserTypeId: {
      label: 'Select Role',
      value: -1,
    },
    major: '',
    phoneNumber: '',
    institution: '',
    addressLine1: '',
    addressLine2: '',
    locationId: 0,
    selectedLocation: null,
    resumeAdded: [],
    listTimeZone: [],
    selectedTimeZone: null,
    zipCode: '',
    city: '',
    province: '',
    token: '',
    role: null,
    files: [],
    listDegree: [],
    loading: false,
    pageLoading: true,
    ExternalUrl: [],
    External: '',
    profileImage: null,
    picture: [],
    cahngePic: false,
    socialMediaProfiles: [],
    listCountry: [],
    removedMediasId: [],
    addedSocialMediaLinks: [],
    billingEmail: '',
    billingAddress: '',
    cardInfomation: '',
    university: '',
    cardExpireDate: '',
    cardCVC: '',
    nameOnCard: '',
    selectedSocialMediaLinkType: null,
    selectedSocialMediaLinkUrl: '',
    socialMediaProfilesOptions: [
      { label: 'LinkedIn', value: 13 },
      { label: 'Scholar', value: 14 },
      { label: 'Researchgate', value: 15 },
      { label: 'Personal Website', value: 16 },
      { label: 'Lab Website', value: 17 },
      { label: 'Team Website', value: 18 },
      { label: 'Other', value: 12 },
    ],
    basicInfo: true,
    academicInfo: false,
    contactInfo: false,
    securityLoginInfo: false,
    selectedTab: 0,
    showModal: false,
  };

  onDrop = (fileList: any) => {
    if (this.state.resume && this.state.resume.length > 0) {
      // @ts-ignore
      const _currentResumeId = this.state?.resume[0]?.id;
      this.setState({
        resumeAdded: fileList,
        resume: [],
        removedMediasId: [...this.state.removedMediasId, _currentResumeId],
      });
    } else {
      this.setState({ resumeAdded: fileList });
    }
    // if (this.state.files.length === 1) {
    //   toast.warning("You can only upload one file");
    //   return;
    // }
    // fileList.map((item: any, index: number) => {
    //   const found = this.state.files.find(
    //     (element: any) => element.name === item.name
    //   );
    //   console.log("found", found);
    //   if (found === undefined) {
    //     this.setState({
    //       files: [fileList[index], ...this.state.files],
    //       resume: [fileList[index], ...this.state.files],
    //     });
    //   }
    // });
  };
  handelDeleteFile(arg: File) {
    this.setState({
      files: this.state.files.filter((file) => file.name !== arg.name),
    });
  }
  getProfile() {
    this.setState({
      loading: true,
    });
    HTTP.get('/AppSetting/TimeZoneList')
      .then((res) => {
        if (res.data.result) {
          const _listTimeZone = res.data.result?.timeZones.map((_tm: any) => {
            return { label: _tm.title, value: _tm.id };
          });
          // console.log(_data);

          // setListTimeZone(_data);

          this.categoryController.getAllCountries(
            {},
            (_countries: any) => {
              // const _listDegrees = [
              //   { label: }
              // ]

              this.setState({
                listDegree: this.local.getSetting().degree.map((item) => {
                  const modifiedTitle = addSpaceBetweenWords(item.title);
                  return { label: modifiedTitle, value: item.id };
                }),
                listCountry: _countries.map((item: any) => {
                  return { label: item.title, value: item.id };
                }),
              });

              this.controllerMember.getMember(
                {
                  userId: this.local.getUserId(),
                  token: localStorage.getItem('token') ?? '',
                },
                (res) => {
                  const _selectedLocation = this.state.listCountry.find(
                    (item: any) => item.value === res.location?.id
                  );
                  const _selectedDegree = this.state.listDegree.find(
                    (item) => item.value === res.degree
                  ) ?? {
                    label: 'Select Degree',
                    value: -1,
                  };
                  const _selectedTimeZone = _listTimeZone.find(
                    (_tm: any) => _tm.value === res?.timeZone?.timeZoneId
                  );
                  const _userRole = store.getState().userRole;
                  this.setState({
                    id: this.local.getUserId,
                    isMobileNumberVerified: res.isMobileNumberVerified,
                    mobileNumber: res.mobileNumber,
                    firstName: res.firstName,
                    lastName: res.lastName,
                    microsoftEmail: res.microsoftEmail,
                    googleEmail: res.googleEmail,
                    email: res.userEmail,
                    degree: _selectedDegree,
                    major: res.major,
                    city: res.city,
                    province: res.province,
                    selectedTimeZone: _selectedTimeZone,
                    listTimeZone: _listTimeZone,
                    pageLoading: false,
                    phoneNumber: res.phoneNumber,
                    resume: res.resume ? [res.resume] : null,
                    // institution: this.state.institution,
                    institution: res.institution,
                    addressLine1: res.addressLine1,
                    addressLine2: res.addressLine2,
                    zipCode: res.zipCode,
                    role: _userRole,
                    billingEmail: res.billingEmail,
                    billingAddress: res.billingAddress,
                    cardInfomation: res.cardInfomation,
                    cardExpireDate: res.cardExpireDate,
                    cardCVC: res.cardCVC,
                    selectedLocation: _selectedLocation,
                    nameOnCard: res.nameOnCard,
                    profileImage: res.profileImage,
                    university: res.university,
                    socialMediaProfiles: res.socialMediaProfiles,
                    ExternalUrl: res.socialMediaProfiles,
                    loading: false,
                  });
                  if (AccessPermition(_userRole, [UserRoles.L1Client, UserRoles.L1User])) {
                    this.controller.getUserType(
                      (_res) => {
                        const _selectedUserTypeRole = _res.find((_uRole) => {
                          if (_uRole.id === res.userTypeId) return _uRole;
                        });
                        this.setState({
                          selectedUserTypeId: _selectedUserTypeRole
                            ? {
                                label: _selectedUserTypeRole.title,
                                value: _selectedUserTypeRole.id,
                                role: _selectedUserTypeRole.userRoleEnum,
                              }
                            : {
                                label: 'Select Role',
                                value: -1,
                              },
                          userTypeList: _res.map((item) => {
                            return {
                              label: item.title,
                              value: item.id,
                              role: item.userRoleEnum,
                            };
                          }),
                        });
                      },
                      (err) => {
                        this.setState({
                          pageLoading: false,
                        });
                        toast.error('Error while getting user type');
                      }
                    );
                  }
                },
                (err) => {
                  this.setState({
                    pageLoading: false,
                  });
                  toast.error('Error while getting user profile');
                }
              );
            },
            (err) => {
              this.setState({
                pageLoading: false,
              });
              toast.error('Error while getting countries');
            }
          );
        }
      })
      .catch((err) => {
        // error(err);
        this.setState({
          pageLoading: false,
        });
        toast.error('Error while getting time zone');
      });
  }
  componentDidMount() {
    document.title = `Edit My Profile | Radvix`;
    store.dispatch(SetHeaderTitle('Team'));
    if (!isTokenInHttpAuth(HTTP)) return;
    this.getProfile();
  }
  handleChange(target: string, val: any) {
    const sanitizedValue = DOMPurify.sanitize(val);
    this.setState({
      [target]: sanitizedValue,
    });
  }
  handelChangeSelect(e: { label: string; value: number }, target: string) {
    this.setState({ [target]: e.value });
  }

  addExternalUrl() {
    let Url = [...this.state.ExternalUrl];
    if (this.state.External.length > 2) {
      // Create an object of type UserSocial
      const newExternalUrl: UserSocial = {
        id: parseInt(nanoid()), // Assuming UserSocial has an id property
        externalUrl: this.state.External,
        useCase: 0,
      };
      Url.push(newExternalUrl);
    }
    this.setState({
      ExternalUrl: Url,
      External: '',
    });
  }
  onDropPic = (picture: any) => {
    let img = new Image();
    img.src = window.URL.createObjectURL(picture[0]);
    img.onload = () => {
      if (img.width === img.height) {
        this.setState({
          picture,
          profileImage: URL.createObjectURL(picture[0]),
          cahngePic: true,
        });
      } else {
        alert(`Sorry, this image doesn't look like the size we wanted. It's
   ${img.width} x ${img.height} but we require 1 : 1 size image.`);
      }
    };
  };
  handelDeletePic(arg: File) {
    this.setState({
      picture: this.state.picture.filter((file) => file.name !== arg.name),
      profileImage: '',
    });
  }
  handelDeleteExternalLink(link: { id: number }) {
    this.setState({
      removedMediasId: [...this.state.removedMediasId, link.id],
      ExternalUrl: this.state.ExternalUrl.filter((_item) => _item.id !== link.id),
    });
  }
  UpdateMyProfile() {
    {
      this.setState({
        loadingMode: 'Updating profile..',
      });
      const _userRole = store.getState().userRole;
      // @ts-ignore
      const body: UpdateMyProfileReq = {
        id: this.local.getUserId(),
        cookieString: '',
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        degree: this.state.degree?.value,
        major: this.state.major,
        phoneNumber: this.state.phoneNumber,
        institution: this.state.institution,
        addressLine1: this.state.addressLine1,
        addressLine2: this.state.addressLine2,
        zipCode: this.state.zipCode,
        token: localStorage.getItem('token')!,
        role: this.state.role || _userRole,
        city: this.state.city,
        province: this.state.province,
        locationId: this.state.selectedLocation?.value,
        lastSeletedSearchId: store.getState().ResearchId.value,
        billingEmail: this.state.billingEmail,
        billingAddress: this.state.billingAddress,
        cardInfomation: this.state.cardInfomation,
        cardExpireDate: this.state.cardExpireDate,
        cardCVC: this.state.cardCVC,
        nameOnCard: this.state.nameOnCard,
        university: this.state.university,
        removedMediasId: this.state.removedMediasId,
        timeZoneId: this.state.selectedTimeZone?.value,
        userTypeId: AccessPermition(this.state.role || _userRole, [
          UserRoles.L1Client,
          UserRoles.L1User,
        ])
          ? this.state.selectedUserTypeId.value === -1
            ? null
            : this.state.selectedUserTypeId.value
          : null,
      };

      this.setState({
        loading: true,
      });
      this.controller.UpdateMyProfile(
        body,
        async (res) => {
          // if (this.state.files.length > 0) {
          //   this.handelUpload(res.id);
          // }

          if (this.state.resumeAdded.length > 0) {
            this.setState({
              loadingMode: 'Uploading resume..',
            });

            // await uploadAllFiles(Number(res.id), this.state.resumeAdded, 11);
            await this.handelUpload(res.id);
          }

          if (this.state.addedSocialMediaLinks.length > 0) {
            this.setState({
              loadingMode: 'Uploading links..',
            });
            await this.handleUploadLinks();
          }
          // if (
          //   this.state.ExternalUrl.length > this.state.socialMediaProfiles.length
          // ) {
          //   this.handleUploadLinks();
          // }
          if (this.state.picture.length) {
            this.setState({
              loadingMode: 'Uploading profile picture..',
            });
            await this.handelUploadPic();
          } else {
            setTimeout(() => {
              window.history.back();
            }, 500);
            this.setState({
              loading: false,
              loadingMode: '',
            });

            window.dispatchEvent(new CustomEvent('UPDATE_USER_INFO'));
            // this.props.history.push(
            //   `${AppRoutes.equip_profile.replace(
            //     ":id",
            //     res.id?.toString() ?? ""
            //   )}`
            // );
          }
        },
        () => {
          this.setState({
            loading: false,
            loadingMode: '',
          });
        }
      );
    }
  }

  formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 MB';

    const m = 1024 * 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizeInMB = bytes / m;

    return `${sizeInMB.toFixed(dm)} MB`;
  }

  handleUploadMedia(_formData: any) {
    return new Promise((resolve, reject) => {
      this.UploadController.UloadMedia(
        _formData,
        (res) => {
          resolve(res);
        },
        () => {
          reject();
        }
      );
    });
  }
  async handleUploadLinks() {
    const _obj = {};
    const _myRequests = [];
    this.state.addedSocialMediaLinks.forEach((item: any) => {
      // @ts-ignore
      if (!_obj[item.id]) {
        // @ts-ignore
        _obj[item.id] = [item.url];
      } else {
        // @ts-ignore
        _obj[item.id] = [..._obj[item.id], item.url];
      }
    });

    for (const key in _obj) {
      // @ts-ignore
      const _links = _obj[key];
      for (let i = 0; i < _links.length; i++) {
        const formData = new FormData();
        //@ts-ignore

        // formData.append("ExternalUrls", _links);
        formData.append('UseCase', key);

        const file = _links[i];

        formData.append('ExternalUrls', file);

        _myRequests.push(this.handleUploadMedia(formData));
      }
    }
    try {
      const allPromise = Promise.all(_myRequests);
      const values = await allPromise;
    } catch (error) {
      // rejectReason of any first rejected promise
    }
    // const formData = new FormData();
    // for (let i = 0; i < this.state.ExternalUrl.length; i++) {
    //   const file = this.state.ExternalUrl[i].externalUrl;
    //   formData.append("ExternalUrls", file);
    // }
    // formData.append("UseCase", "12");
    // this.UploadController.UloadMedia(
    //   formData,
    //   (res) => {},
    //   () => {}
    // );
  }
  handelUpload(id: string) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      const file = this.state.resumeAdded[0];
      formData.append('FileName', file.name);
      // @ts-ignore
      formData.append('FileSize', file.size);

      // for (let i = 0; i < this.state.resumeAdded.length; i++) {
      //   const file = this.state.resumeAdded[i];
      //   formData.append("Files", file);
      // }
      // for (let i = 0; i < this.state.ExternalUrl.length; i++) {
      //   const file = this.state.ExternalUrl[i];
      //   formData.append("ExternalUrls", file);
      // }
      formData.append('UseCase', '11');

      this.UploadController.UloadMedia(
        formData,
        async (res) => {
          await uploadToS3PresignedUrl(file, res.result?.cloudFrontUrl, res.result?.mediaId);
          resolve(true);
        },
        () => {
          this.setState({
            loading: false,
          });
          reject(false);
        }
      );
    });
  }

  logout() {
    handleLogoutApi()
      .then((res) => {
        handleClearStorageAndTokens();
        setTimeout(() => {
          window.location.reload();
        }, 250);
      })
      .catch((err) => {
        if (err === 'Device Id not found') {
          handleClearStorageAndTokens();
          window.location.replace(AppRoutes.login);
          return;
        }
        const _tokenInStorage = localStorage.getItem('token');
        if (!_tokenInStorage) {
          handleClearStorageAndTokens();
          setTimeout(() => {
            window.location.reload();
          }, 250);
        } else {
          if (err.response && err.response.status === 401) {
            // Retry the request with refreshed token
            return handleUnauthorizedRequest(err.config).then((_res) => {
              handleClearStorageAndTokens();
              setTimeout(() => {
                window.location.reload();
              }, 250);
            });
            // .catch((e) => error(e));
          } else {
            handleClearStorageAndTokens();
            setTimeout(() => {
              window.location.reload();
            }, 250);
            // error(err);
          }
        }
      });

    // HTTP.post('/User/Logout').then((res) => {
    //   handleClearStorageAndTokens()
    // }).catch((err) => {
    //   if (err.response && err.response.status === 401) {
    //     // Retry the request with refreshed token
    //     return handleUnauthorizedRequest(err.config)
    //       .then((_res) => {
    //         handleClearStorageAndTokens()
    //         setTimeout(() => {
    //           window.location.reload();
    //         }, 250)
    //       })
    //     // .catch((e) => error(e));
    //   } else {
    //     // error(err);
    //   }
    // });

    // window.history.push(AppRoutes.login);
  }

  async handelUploadPic() {
    const formData = new FormData();
    // if (this.state.picture.length === 0) return;
    const file = this.state.picture[0];
    formData.append('UseCase', '5');
    formData.append('FileName', file.name);
    // @ts-ignore
    formData.append('FileSize', file.size);

    await this.UploadController.UloadMedia(
      formData,
      async (res) => {
        await uploadToS3PresignedUrl(file, res.result?.cloudFrontUrl, res.result?.mediaId);

        this.setState({
          loading: false,
        });
        setTimeout(() => {
          window.dispatchEvent(new CustomEvent('UPDATE_USER_INFO'));
          window.history.back();
        }, 500);
      },
      () => {
        this.setState({
          loading: false,
        });
      }
    );
  }

  async handleConnectMicrosoftAccount(isConnect: boolean, token?: string) {
    this.setState({
      loading: true,
    });
    await this.controller.connectMicrosoftAccount(
      (res) => {
        if (res) {
          toast.success('Your microsoft account has been connected');
          this.getProfile();
        } else {
          toast.error('unable to connect your microsoft at this time, please try again later.');
          this.setState({
            loading: false,
          });
        }
      },
      (err) => {},
      isConnect,
      token
    );
  }
  async handleConnectGoogleAccount(isConnect: boolean, token?: string) {
    const response = await this.controller.connectGoogleAccount(isConnect, token);
    if (response) {
      if (isConnect) {
        toast.success('Your google account has been connected');
      } else {
        toast.success('Your google account has been disconnected');
        if (this.state.googleEmail === this.state.email) {
          this.logout();
        }
      }

      this.getProfile();
      return;
    }

    toast.error('Something went wrong');
  }
  render() {
    // if (this.state.pageLoading || this.state.loading) {
    //   return (
    //     <div
    //       style={{
    //         height: "100%",
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //       }}
    //     >
    //       <div className="spinner-border" role="status">
    //         <span className="sr-only">Loading...</span>
    //       </div>
    //     </div>
    //   );
    // }

    const files =
      this.state.resume?.length > 0
        ? this.state.resume.map((file: any) => (
            <li
              key={file.id}
              className='d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team'
              style={{ marginBottom: '3px' }}
            >
              <span
                className='text-truncate col-11 mx-2'
                style={{ fontFamily: 'Sanfransisco', textAlign: 'start' }}
              >
                {file.name} - {this.formatBytes(file.size)}
              </span>
              <CircleIcon
                type={ThemeCircleIcon.dark}
                width='22px'
                height='22px'
                padding='0'
                onClick={() => {
                  this.handelDeleteFile(file);

                  this.setState({
                    resume: this.state.resume.filter((item: any) => item.id !== file.id),
                    removedMediasId: [...this.state.removedMediasId, file.id],
                  });
                }}
                style={{ cursor: 'pointer', display: 'contents' }}
              >
                <img src='/images/icons/cross-1.svg' alt='radvix' width={15} height={15} />
              </CircleIcon>
            </li>
          ))
        : this.state.resumeAdded.map((file: any) => (
            <li
              key={file.id}
              className='d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team'
              style={{ marginBottom: '3px' }}
            >
              <span
                className='text-truncate col-11 mx-2 d-block'
                style={{ fontFamily: 'Sanfransisco', textAlign: 'start' }}
              >
                {file.name} - {this.formatBytes(file.size)}
              </span>
              <CircleIcon
                type={ThemeCircleIcon.dark}
                width='22px'
                height='22px'
                padding='0'
                onClick={() => {
                  // this.handelDeleteFile(file);
                  this.setState({
                    resumeAdded: this.state.resumeAdded.filter((item: any) => item.id !== file.id),
                    removedMediasId: [...this.state.removedMediasId, file.id],
                  });
                }}
                style={{ cursor: 'pointer', display: 'contents' }}
              >
                <img src='/images/icons/cross-1.svg' alt='radvix' width={15} height={15} />
              </CircleIcon>
            </li>
          ));
    // const picture = this.state.picture.map((file: any) => (
    //   <li key={file.name}>
    //     <span className="text-truncate col-10 mx-2">
    //       {file.name} - {file.size} bytes
    //     </span>
    //     <CircleIcon
    //       type={ThemeCircleIcon.dark}
    //       width="22px"
    //       height="22px"
    //       padding="0"
    //       onClick={() => {
    //         this.handelDeletePic(file);
    //       }}
    //     >
    //       <img
    //         src="/images/icons/garbage_can.svg"
    //         alt="radvix"
    //         width={15}
    //         height={15}
    //       />
    //     </CircleIcon>
    //   </li>
    // ));
    return (
      <div className='profileCard rounded-px-5 responsiveEditProfile-div'>
        <div
          className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
        border-gray-400'
        >
          <div className='d-flex align-items-center'>
            <BaseButton
              className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>Edit Profile</h2>
          </div>
        </div>

        <div>
          <div className='d-flex overflow-auto'>
            <div
              className='dashboardProjectDetail dashboard-section overviwe align-items-center'
              style={{ borderBottom: 'none' }}
            >
              <div
                className={
                  this.state.selectedTab === 0
                    ? 'overviwe-item'
                    : 'overviwe-item button-dashboard-category'
                }
                onClick={() =>
                  this.setState({
                    selectedTab: 0,
                  })
                }
              >
                <div className='dashboardProjectDetailItem'>
                  <div
                    className='d-flex flex-column align-items-center dashboardProjectDetailItem'
                    id='pills-notes-tab'
                  >
                    <div
                      className={
                        this.state.selectedTab === 0
                          ? 'dashboardSelectedCategory'
                          : 'dashboardCategory'
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'
                      >
                        <rect width='20' height='20' fill='white' fill-opacity='0.01' />
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M10.0002 1.16675C7.3308 1.16675 5.16684 3.33071 5.16684 6.00008C5.16684 8.20392 6.64182 10.0633 8.65848 10.6448C7.06644 10.8388 5.70093 11.4019 4.67226 12.4156C3.36305 13.7059 2.7002 15.6267 2.7002 18.1333C2.7002 18.4831 2.98376 18.7666 3.33353 18.7666C3.68332 18.7666 3.96686 18.4831 3.96686 18.1333C3.96686 15.8399 4.57065 14.2942 5.56138 13.3178C6.55398 12.3396 8.03582 11.8334 10.0001 11.8334C11.9644 11.8334 13.4463 12.3396 14.439 13.3178C15.4296 14.2942 16.0335 15.8399 16.0335 18.1333C16.0335 18.4831 16.317 18.7666 16.6668 18.7666C17.0166 18.7667 17.3002 18.4831 17.3002 18.1334C17.3002 15.6267 16.6372 13.7059 15.328 12.4156C14.2994 11.4019 12.9338 10.8388 11.3418 10.6448C13.3584 10.0633 14.8335 8.20393 14.8335 6.00008C14.8335 3.33071 12.6695 1.16675 10.0002 1.16675ZM6.4335 6.00008C6.4335 4.03027 8.03036 2.43341 10.0002 2.43341C11.97 2.43341 13.5668 4.03027 13.5668 6.00008C13.5668 7.96989 11.97 9.56675 10.0002 9.56675C8.03036 9.56675 6.4335 7.96989 6.4335 6.00008Z'
                          fill={this.state.selectedTab === 0 ? 'white' : 'black'}
                        />
                      </svg>

                      <span className='textWhite'>Basic Information</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.selectedTab === 1
                  ? 'overviwe-item'
                  : 'overviwe-item button-dashboard-category'
              }
              onClick={() =>
                this.setState({
                  selectedTab: 1,
                })
              }
            >
              <div className=' dashboardProjectDetailItem'>
                <div
                  className='d-flex flex-column align-items-center dashboardProjectDetailItem'
                  id='pills-description-tab'
                >
                  <div
                    className={
                      this.state.selectedTab === 1
                        ? 'dashboardSelectedCategory'
                        : 'dashboardCategory'
                    }
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      color={this.state.selectedTab === 1 ? 'white' : 'black'}
                      className={'dashboardCategoryIconAll'}
                    >
                      <rect width='20' height='20' fill='white' fillOpacity='0.01' />
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M18.6667 14.667V5.33358H1.33333V14.667H18.6667ZM20 5.33358V14.667C20 15.4032 19.4031 16.0003 18.6667 16.0003H1.33333C0.596953 16.0003 0 15.4032 0 14.667V5.33358C0 4.5972 0.596953 4.00024 1.33333 4.00024H18.6667C19.4031 4.00024 20 4.5972 20 5.33358ZM2.66667 7.00016C2.66667 6.81607 2.81591 6.66683 3 6.66683H7.66667C7.85076 6.66683 8 6.81607 8 7.00016V13.0002C8 13.1843 7.85076 13.3335 7.66667 13.3335H3C2.81591 13.3335 2.66667 13.1843 2.66667 13.0002V7.00016ZM10 9.3335C9.63181 9.3335 9.33333 9.63198 9.33333 10.0002C9.33333 10.3684 9.63181 10.6668 10 10.6668H14C14.3681 10.6668 14.6667 10.3684 14.6667 10.0002C14.6667 9.63198 14.3681 9.3335 14 9.3335H10ZM9.33333 12.6668C9.33333 12.2986 9.63181 12.0002 10 12.0002H16.6667C17.0348 12.0002 17.3333 12.2986 17.3333 12.6668C17.3333 13.035 17.0348 13.3335 16.6667 13.3335H10C9.63181 13.3335 9.33333 13.035 9.33333 12.6668ZM10 6.66683C9.63181 6.66683 9.33333 6.96531 9.33333 7.3335C9.33333 7.70168 9.63181 8.00016 10 8.00016H15.3333C15.7015 8.00016 16 7.70168 16 7.3335C16 6.96531 15.7015 6.66683 15.3333 6.66683H10Z'
                        fill='currentColor'
                      />
                    </svg>
                    {/* <TransformIcon
                      color={
                        this.state.selectedTab === 1
                          ? "white"
                          : "black"
                      }
                      className={"dashboardCategoryIconAll"}
                    ></TransformIcon> */}

                    <span className='textWhite'>Academic Background</span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                this.state.selectedTab === 2
                  ? 'overviwe-item'
                  : 'overviwe-item button-dashboard-category'
              }
              onClick={() =>
                this.setState({
                  selectedTab: 2,
                })
              }
            >
              <div className='d-flex flex-column align-items-center dashboardProjectDetailItem'>
                <div
                  className={
                    this.state.selectedTab === 2 ? 'dashboardSelectedCategory' : 'dashboardCategory'
                  }
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    color={this.state.selectedTab === 2 ? 'white' : 'black'}
                    className={'dashboardCategoryIconAll'}
                  >
                    <rect width='20' height='20' fill='white' fillOpacity='0.01' />
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M5.33333 3.33337C5.33333 2.96519 5.63181 2.66671 6 2.66671H14C14.3681 2.66671 14.6667 2.96519 14.6667 3.33337V16.6667C14.6667 17.0348 14.3681 17.3334 14 17.3334H6C5.63181 17.3334 5.33333 17.0348 5.33333 16.6667V3.33337ZM6 1.33337C4.89543 1.33337 4 2.2288 4 3.33337V16.6667C4 17.7712 4.89543 18.6667 6 18.6667H14C15.1045 18.6667 16 17.7712 16 16.6667V3.33337C16 2.2288 15.1045 1.33337 14 1.33337H6ZM8 15.5334C7.74227 15.5334 7.53333 15.7423 7.53333 16C7.53333 16.2578 7.74227 16.4667 8 16.4667H12C12.2577 16.4667 12.4667 16.2578 12.4667 16C12.4667 15.7423 12.2577 15.5334 12 15.5334H8Z'
                      fill='currentColor'
                    />
                  </svg>

                  <span className='textWhite'>Contact Information</span>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.selectedTab === 3
                  ? 'overviwe-item'
                  : 'overviwe-item button-dashboard-category'
              }
              onClick={() =>
                this.setState({
                  selectedTab: 3,
                })
              }
            >
              <div className='d-flex flex-column align-items-center dashboardProjectDetailItem'>
                <div
                  className={
                    this.state.selectedTab === 3 ? 'dashboardSelectedCategory' : 'dashboardCategory'
                  }
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    color={this.state.selectedTab === 3 ? 'white' : 'black'}
                    className={'dashboardCategoryIconAll'}
                  >
                    <rect width='20' height='20' fill='white' fillOpacity='0.01' />
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M6.66699 6.18139C6.66699 5.01379 6.98991 4.14057 7.52463 3.5648C8.05278 2.99611 8.86142 2.63777 10.0022 2.63777C11.1436 2.63777 11.9511 2.9954 12.478 3.56281C13.0115 4.13736 13.3337 5.00948 13.3337 6.17771V8.00003H6.66699V6.18139ZM5.33366 8.00003V6.18139C5.33366 4.77535 5.72485 3.54343 6.54765 2.65747C7.37702 1.76443 8.56933 1.30444 10.0022 1.30444C11.4348 1.30444 12.6264 1.76315 13.455 2.65553C14.2771 3.5408 14.667 4.77199 14.667 6.17771V8.00003H16.0003C16.7367 8.00003 17.3337 8.59699 17.3337 9.33336V17.3334C17.3337 18.0698 16.7367 18.6667 16.0003 18.6667H4.00033C3.26395 18.6667 2.66699 18.0698 2.66699 17.3334V9.33336C2.66699 8.59699 3.26395 8.00003 4.00033 8.00003H5.33366ZM4.00033 9.33336H16.0003V17.3334H4.00033V9.33336Z'
                      fill='currentColor'
                    />
                  </svg>

                  <span className='textWhite'>Security & Login</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='p-3 overflow-auto'>
          <div className='row'>
            <div className=''>
              <div
                style={{ display: this.state.selectedTab === 0 ? 'block' : 'none' }}
                className='row'
              >
                <div className='item'>
                  <InputComponent
                    className='form-control'
                    type={InputType.text}
                    disabled={this.state.loading}
                    value={this.state.firstName}
                    label='First Name'
                    popQuestion='Enter your first name.'
                    onChange={(e) => {
                      this.handleChange('firstName', e.target.value);
                    }}
                    inValid={this.validator.message(
                      'First Name*',
                      this.state.firstName,
                      'required|firstName'
                    )}
                  ></InputComponent>
                </div>
                <div className='item'>
                  <InputComponent
                    className='form-control'
                    type={InputType.text}
                    disabled={this.state.loading}
                    value={this.state.lastName}
                    label='Last Name*'
                    popQuestion='Enter your last name.'
                    onChange={(e) => {
                      this.handleChange('lastName', e.target.value);
                    }}
                    inValid={this.validator.message(
                      'Last Name*',
                      this.state.lastName,
                      'required|lastName'
                    )}
                  ></InputComponent>
                </div>
                <div className='col-lg-12 mb-3'>
                  <div className='d-flex align-items-center mb-1'>
                    <label className='color-gray-600 fs-15 me-2'>Profile Picture</label>
                    {/* <img
                        className="text-primary cursor-pointer"
                        src="/Images/icons/info-circled.svg"
                        alt="info"
                        title="This will be your public profile picture."
                      /> */}
                  </div>
                  <div>
                    <div className='d-flex justify-content-sm-between justify-content-center align-items-center flex-wrap row-gap-px-8'>
                      <GetPictureAndCrop
                        originalImage={
                          this.state.profileImage?.name ? this.state.profileImage?.name : ''
                        }
                        picture={
                          this.state.profileImage?.name
                            ? IMAGE_BASE_URL + this.state.profileImage.name
                            : ''
                        }
                        onRemove={() => {
                          this.setState({
                            profileImage: '',
                            picture: [],
                            removedMediasId: [
                              ...this.state.removedMediasId,
                              this.state.profileImage?.id,
                            ],
                          });
                        }}
                        onSave={(_pictures: any) => {
                          this.setState({
                            picture: _pictures,
                            profileImage: URL.createObjectURL(_pictures[0]),
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='item'>
                  <InputComponent
                    className='form-control'
                    type={InputType.text}
                    value={this.state.email}
                    disabled={this.state.loading}
                    label='User email*'
                    popQuestion='This is the email used to register on Radvix and login.'
                    onChange={(e) => {
                      this.handleChange('email', e.target.value);
                    }}
                    inValid={this.validator.message(
                      'User Email*',
                      this.state.email,
                      'required|email'
                    )}
                  ></InputComponent>
                </div>
                <div className='item'>
                  <SelectComponent
                    disabled={this.state.loading}
                    items={this.state.userTypeList}
                    defaultValue={this.state.selectedUserTypeId}
                    TextItem='label'
                    ValueItem='value'
                    labelClass='color-gray-600 fs-15 me-2'
                    className='form-select'
                    label='Role name*'
                    menuPlacementTop={true}
                    placeholder='Click to see the list…'
                    onChange={(e) => {
                      this.setState({
                        selectedUserTypeId: e,
                      });
                    }}
                    // inValid={
                    //   !this.state.isGuestInvited
                    //     ? this.validator.message(
                    //         "Type",
                    //         this.state.typeId.value,
                    //         "min:0,num"
                    //       )
                    //     : undefined
                    // }
                    // isMulti
                  ></SelectComponent>
                </div>
              </div>

              {this.state.selectedTab == 1 && (
                <div className='row'>
                  <div className='item'>
                    <InputComponent
                      className='form-control'
                      type={InputType.text}
                      disabled={this.state.loading}
                      value={this.state.university}
                      label='School / University'
                      //popQuestion="This email will be used to send a secured invitation link to the user. User will be update to change their emails on the registration page."
                      onChange={(e) => {
                        this.handleChange('university', e.target.value);
                      }}
                      // inValid={this.validator.message(
                      //   "First Name*",
                      //   this.state.firstName,
                      //   "required|firstName"
                      // )}
                    ></InputComponent>
                  </div>
                  <div className='d-flex align-items-center gap-2'>
                    <div className='col-lg-6 mb-3'>
                      <div className='d-flex align-items-center mb-1'>
                        <label className='color-gray-600 fs-15 me-2'>Degree</label>
                      </div>
                      <SelectComponent
                        items={this.state.listDegree}
                        TextItem='title'
                        ValueItem='id'
                        // className="my-2"
                        placeholder='Select'
                        //label="Degree"
                        defaultValue={this.state.degree}
                        onChange={(e) => {
                          this.setState({
                            degree: e,
                          });
                          // this.handelChangeSelect(e, "degree");
                        }}
                      ></SelectComponent>
                    </div>
                    <div className='item w-100'>
                      <div
                        className='d-flex align-items-center mb-1'
                        style={{ marginTop: '-12px' }}
                      >
                        <label className='color-gray-600 fs-15 me-2'>Major</label>
                      </div>
                      <InputComponent
                        height='38px'
                        type={InputType.text}
                        //label="Major"
                        marginBottom={true}
                        onChange={(e) => {
                          this.handleChange('major', e.target.value);
                        }}
                        value={this.state.major}
                      ></InputComponent>
                    </div>
                  </div>
                  <div className='item' style={{ paddingBottom: '20px' }}>
                    <div className='d-flex align-items-center mb-1'>
                      <label className='color-gray-600 fs-15 me-2'>Timezone</label>
                    </div>
                    <SelectComponent
                      //label="Timezone"
                      items={this.state.listTimeZone}
                      defaultValue={this.state.selectedTimeZone}
                      onChange={(_e) => {
                        // onChangeSetting(_e, "timeZoneId");
                        this.setState({
                          selectedTimeZone: _e,
                        });
                      }}
                      TextItem='name'
                      ValueItem='id'
                      className='mt-1'
                      placeholder='Eastern Time'
                    ></SelectComponent>
                  </div>
                  <div className='item'>
                    <div className='d-flex align-items-center mb-1'>
                      <label className='color-gray-600 fs-15 me-2'>Resume</label>
                      {/* <img
                        className="text-primary cursor-pointer"
                        src="/Images/icons/info-circled.svg"
                        alt="info"
                        title="Please upload a single document as your public resume to share on your public profile with other users in your research team."
                      /> */}
                    </div>
                    {/* <span className="label d-flex align-items-center">
                      Resume
                      <div
                        // type={MainButtonType.light}
                        // children={optional}
                        // borderRadius="50px"
                        // fontSize="15px"
                        className="s-chip filled mx-2"
                      >
                        optional
                      </div> 
                      <CircleIconTooltip text="Please upload a single document as your public resume to share on your public profile with other users in your research team." />
                    </span>*/}
                    <div
                      className='p-px-12 bg-gray-200 border-gray-400 border rounded-px-4 gap-2'
                      style={{ height: '75%' }}
                    >
                      <div
                        className='d-flex justify-content-center align-items-center flex-column'
                        style={{ height: '100%' }}
                      >
                        <Dropzone onDrop={this.onDrop}>
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps({ className: '' })}
                              style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <input {...getInputProps()} />
                              <button
                                className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 mb-3'
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  width: '50%',
                                }}
                              >
                                <div className='d-flex align-items-center'>
                                  <div className='me-1 mb-1'>
                                    <svg
                                      width='15'
                                      height='15'
                                      viewBox='0 0 15 15'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <rect
                                        width='15'
                                        height='15'
                                        fill='white'
                                        fillOpacity='0.01'
                                      />
                                      <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M7.81825 1.18197C7.64251 1.00624 7.35759 1.00624 7.18185 1.18197L4.18185 4.18197C4.00611 4.35771 4.00611 4.64263 4.18185 4.81837C4.35759 4.9941 4.64251 4.9941 4.81825 4.81837L7.05005 2.58657V9.50005C7.05005 9.74858 7.25152 9.95005 7.50005 9.95005C7.74858 9.95005 7.95005 9.74858 7.95005 9.50005V2.58657L10.1819 4.81837C10.3576 4.9941 10.6425 4.9941 10.8182 4.81837C10.994 4.64263 10.994 4.35771 10.8182 4.18197L7.81825 1.18197ZM2.5 10.0001C2.77614 10.0001 3 10.2239 3 10.5001V12.0001C3 12.5539 3.44565 13.0001 3.99635 13.0001H11.0012C11.5529 13.0001 12 12.5529 12 12.0001V10.5001C12 10.2239 12.2239 10.0001 12.5 10.0001C12.7761 10.0001 13 10.2239 13 10.5001V12.0001C13 13.1041 12.1062 14.0001 11.0012 14.0001H3.99635C2.89019 14.0001 2 13.1031 2 12.0001V10.5001C2 10.2239 2.22386 10.0001 2.5 10.0001Z'
                                        fill='currentColor'
                                      />
                                    </svg>
                                  </div>
                                  Browse local file
                                </div>
                              </button>
                              <h3 className='fw-normal color-gray-700 fs-13 mb-0 text-center'>
                                Or drag and drop files here - Max 128MB
                              </h3>
                            </div>
                          )}
                        </Dropzone>
                        <aside className='mw-100'>
                          <ul>{files}</ul>
                        </aside>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {this.state.selectedTab == 2 && (
                <div className='row'>
                  <h5>Contact Information</h5>
                  <div className='item'>
                    <InputComponent
                      className='form-control'
                      type={InputType.text}
                      disabled={this.state.loading}
                      value={this.state.institution}
                      label='Institution / Company'
                      //popQuestion="This email will be used to send a secured invitation link to the user. User will be update to change their emails on the registration page."
                      onChange={(e) => {
                        this.handleChange('institution', e.target.value);
                      }}
                      // inValid={this.validator.message(
                      //   "First Name*",
                      //   this.state.firstName,
                      //   "required|firstName"
                      // )}
                    ></InputComponent>
                  </div>
                  <div className='item'>
                    <InputComponent
                      type={InputType.text}
                      label='Address Line 1'
                      // popQuestion="Address Line 1"
                      className='mb-3'
                      onChange={(e) => {
                        this.handleChange('addressLine1', e.target.value);
                      }}
                      value={this.state.addressLine1}
                    ></InputComponent>
                    <InputComponent
                      type={InputType.text}
                      label='Address Line 2'
                      // popQuestion="Address Line 2"
                      onChange={(e) => {
                        this.handleChange('addressLine2', e.target.value);
                      }}
                      value={this.state.addressLine2}
                    ></InputComponent>
                  </div>
                  <div className='d-flex align-items-center gap-2'>
                    <div className='item w-100'>
                      <InputComponent
                        type={InputType.text}
                        label='City'
                        onChange={(e) => {
                          this.setState({
                            city: e.target.value,
                          });
                          // this.handleChange("city", e.target.value);
                        }}
                        value={this.state.city}
                      ></InputComponent>
                    </div>
                    <div className='item w-100'>
                      <InputComponent
                        type={InputType.text}
                        label='State/Province'
                        onChange={(e) => {
                          this.setState({
                            province: e.target.value,
                          });
                          // this.handleChange("province", e.target.value);
                        }}
                        value={this.state.province}
                      ></InputComponent>
                    </div>
                  </div>
                  <div className='d-flex align-items-start gap-2'>
                    <div className='item w-100'>
                      <InputComponent
                        type={InputType.text}
                        label=' ZIP/Postal Code'
                        counter={false}
                        onChange={(e) => {
                          this.handleChange('zipCode', e.target.value);
                        }}
                        value={this.state.zipCode}
                      ></InputComponent>
                    </div>
                    <div className='item w-100'>
                      <InputComponent
                        type={InputType.text}
                        label='Phone'
                        counter={false}
                        onChange={(e) => {
                          this.handleChange('phoneNumber', e.target.value);
                        }}
                        // inValid={this.validator.message(
                        //   "Phone",
                        //   this.state.phoneNumber,
                        //   "phone"
                        // )}
                        value={this.state.phoneNumber}
                      ></InputComponent>
                    </div>
                  </div>
                  <div className='item' style={{ paddingBottom: '20px' }}>
                    <div className='d-flex align-items-center mb-1'>
                      <label className='color-gray-600 fs-15 me-2'>Country</label>
                    </div>
                    <SelectComponent
                      items={this.state.listCountry}
                      TextItem='name'
                      ValueItem='id'
                      className='mt-1'
                      placeholder='Select'
                      //label="Country"
                      onChange={(e) => {
                        this.setState({
                          selectedLocation: e,
                        });
                        // this.handelChangeSelect(e, "locationId");
                      }}
                      defaultValue={this.state.selectedLocation}
                    ></SelectComponent>
                  </div>
                  <div className='item'>
                    <div className='d-flex align-items-center'>
                      <label className='color-gray-600 fs-15 me-2'>Social Links</label>
                      {/* <img
                        className="text-primary cursor-pointer"
                        src="/Images/icons/info-circled.svg"
                        alt="info"
                        title="You can add your LinkedIn, ResearchGate, Google Scholar and other websites using this section to be displayed on your public profile."
                      /> */}
                    </div>
                    {/* <span className="color-gray-600 fs-15 me-2">
                   Social Links
                     <CircleIconTooltip
                      className="ms-2"
                      text="You can add your LinkedIn, ResearchGate, Google Scholar and other websites using this section to be displayed on your public profile."
                    />
                  </span> */}
                    <div className='d-flex align-items-center gap-2 col-lg-12'>
                      <div className='col-lg-4'>
                        <SelectComponent
                          items={this.state.socialMediaProfilesOptions}
                          TextItem='name'
                          ValueItem='id'
                          className='w-100'
                          menuPlacementTop={true}
                          placeholder='Select…'
                          defaultValue={this.state.selectedSocialMediaLinkType}
                          onChange={(e) => {
                            this.setState({
                              selectedSocialMediaLinkType: e,
                            });
                          }}
                        />
                      </div>
                      <div className='d-flex col-lg-8'>
                        <InputComponent
                          type={InputType.text}
                          placeholder='https://'
                          disabled={this.state.loading}
                          className='col-lg-10'
                          value={this.state.selectedSocialMediaLinkUrl}
                          counter={false}
                          onChange={(e) => {
                            this.setState({
                              selectedSocialMediaLinkUrl: e.target.value,
                            });
                          }}
                          rowWidth='94%'
                          onEnter={(e) => {
                            if (this.validator.fields.input) {
                              const _id = nanoid();
                              this.setState({
                                addedSocialMediaLinks: [
                                  ...this.state.addedSocialMediaLinks,
                                  {
                                    id: this.state.selectedSocialMediaLinkType?.value,
                                    url: this.state.selectedSocialMediaLinkUrl,
                                    nanoId: _id,
                                  },
                                ],
                                selectedSocialMediaLinkUrl: '',
                                selectedSocialMediaLinkType: null,
                                ExternalUrl: [
                                  ...this.state.ExternalUrl,
                                  {
                                    id: _id,
                                    useCase: this.state.selectedSocialMediaLinkType?.value,
                                    externalUrl: this.state.selectedSocialMediaLinkUrl,
                                    newAdded: true,
                                  },
                                ],
                              });
                            } else {
                              this.validator.showMessages();
                              this.forceUpdate();
                            }
                          }}
                          height='40px'
                          isExternalURL={true}
                        />
                        <div className='pt-5-x pl-5-x'>
                          <button
                            className='btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0'
                            onClick={() => {
                              if (this.validator.fields.input) {
                                const _id = nanoid();
                                this.setState({
                                  addedSocialMediaLinks: [
                                    ...this.state.addedSocialMediaLinks,
                                    {
                                      id: this.state.selectedSocialMediaLinkType?.value,
                                      url: this.state.selectedSocialMediaLinkUrl,
                                      nanoId: _id,
                                    },
                                  ],
                                  selectedSocialMediaLinkUrl: '',
                                  selectedSocialMediaLinkType: null,
                                  ExternalUrl: [
                                    ...this.state.ExternalUrl,
                                    {
                                      id: _id,
                                      useCase: this.state.selectedSocialMediaLinkType?.value,
                                      externalUrl: this.state.selectedSocialMediaLinkUrl,
                                      newAdded: true,
                                    },
                                  ],
                                });
                              } else {
                                this.validator.showMessages();
                                this.forceUpdate();
                              }
                            }}
                          >
                            <i className='fa fa-plus'></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <ul
                      className='file-list mt-1'
                      style={{
                        marginBottom: this.state.ExternalUrl.length > 0 ? '0px' : '0px',
                      }}
                    >
                      {/* {this.state.ExternalUrl.map((item) => (
                          <li className="d-flex align-items-center w-100">
                            <InputComponent
                              type={InputType.text}
                              value={item.toString()}
                              className="mx-2"
                              counter={false}
                              disabled={true}
                              readOnly={true}
                              rowWidth="98%"
                              isExternalURL={true}
                            ></InputComponent>
                            <div
                              style={{
                                paddingBottom: "0px",
                                paddingRight: "0px",
                                paddingTop: "0px",
                                paddingLeft: "5px",
                              }}
                            >
                              <button
                                className="btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0"
                                disabled={this.state.loading}
                                style={{ background: "#F3AEAF" }}
                                onClick={() => {
                                  if (item?.newAdded) {
                                    this.setState({
                                      ExternalUrl: this.state.ExternalUrl.filter(
                                        (i) => i.id !== item.id
                                      ),
                                      addedSocialMediaLinks:
                                        this.state.addedSocialMediaLinks.filter(
                                          (i: any) => i.nanoId !== item.id
                                        ),
                                    });
                                  } else {
                                    this.setState({
                                      removedMediasId: [
                                        ...this.state.removedMediasId,
                                        item.id,
                                      ],
                                      ExternalUrl: this.state.ExternalUrl.filter(
                                        (_item) => _item.id !== item.id
                                      ),
                                    });
                                  }
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 15 15"
                                  fill="none"
                                >
                                  <rect
                                    width="15"
                                    height="15"
                                    fill="white"
                                    fill-opacity="0.01"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M5.5 1.00009C5.22386 1.00009 5 1.22395 5 1.50009C5 1.77623 5.22386 2.00009 5.5 2.00009H9.5C9.77614 2.00009 10 1.77623 10 1.50009C10 1.22395 9.77614 1.00009 9.5 1.00009H5.5ZM3 3.50009C3 3.22395 3.22386 3.00009 3.5 3.00009H5H10H11.5C11.7761 3.00009 12 3.22395 12 3.50009C12 3.77623 11.7761 4.00009 11.5 4.00009H11V12.0001C11 12.5524 10.5523 13.0001 10 13.0001H5C4.44772 13.0001 4 12.5524 4 12.0001V4.00009H3.5C3.22386 4.00009 3 3.77623 3 3.50009ZM5 4.00009H10V12.0001H5V4.00009Z"
                                    fill="#D93D42"
                                  />
                                </svg>
                              </button>
                            </div>
                          </li>
                        ))} */}
                      {this.state.ExternalUrl.map((item) => (
                        <li key={item.id} className='d-flex align-items-center w-100'>
                          <div
                            className='d-flex align-items-center'
                            style={{ width: '94%', marginBottom: '-15px' }}
                          >
                            <img
                              src={`/images/images/new/${
                                // @ts-ignore
                                socialMediaLogos[item.useCase]
                              }`}
                              alt=''
                              height='20px'
                              className='me-2 mb-2'
                            />
                            <InputComponent
                              value={item.externalUrl}
                              className='mt-0'
                              type={InputType.text}
                              counter={false}
                              rowWidth='100%'
                              readOnly={true}
                              disabled={true}
                            ></InputComponent>
                          </div>

                          <div
                            style={{
                              paddingBottom: '0px',
                              paddingRight: '0px',
                              paddingTop: '0px',
                              paddingLeft: '5px',
                            }}
                          >
                            <button
                              className='btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0'
                              disabled={this.state.loading}
                              style={{ background: '#F3AEAF' }}
                              onClick={() => {
                                this.handelDeleteExternalLink(item);
                              }}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='15'
                                height='15'
                                viewBox='0 0 15 15'
                                fill='none'
                              >
                                <rect width='15' height='15' fill='white' fill-opacity='0.01' />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M5.5 1.00009C5.22386 1.00009 5 1.22395 5 1.50009C5 1.77623 5.22386 2.00009 5.5 2.00009H9.5C9.77614 2.00009 10 1.77623 10 1.50009C10 1.22395 9.77614 1.00009 9.5 1.00009H5.5ZM3 3.50009C3 3.22395 3.22386 3.00009 3.5 3.00009H5H10H11.5C11.7761 3.00009 12 3.22395 12 3.50009C12 3.77623 11.7761 4.00009 11.5 4.00009H11V12.0001C11 12.5524 10.5523 13.0001 10 13.0001H5C4.44772 13.0001 4 12.5524 4 12.0001V4.00009H3.5C3.22386 4.00009 3 3.77623 3 3.50009ZM5 4.00009H10V12.0001H5V4.00009Z'
                                  fill='#D93D42'
                                />
                              </svg>
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                    {/* <div className="d-flex align-items-center w-100">
                      <InputComponent
                        // value={this.state.External}
                        // defaultValue="ast"
                        value={this.state.selectedSocialMediaLinkUrl}
                        className="mt-0"
                        type={InputType.text}
                        placeholder="https://"
                        counter={false}
                        onChange={(e) => {
                          this.setState({
                            selectedSocialMediaLinkUrl: e.target.value,
                          });
                          // this.handleChange("External", e.target.value);
                        }}
                      ></InputComponent>
                      <CircleIcon
                        disabled={
                          !this.state.selectedSocialMediaLinkType ||
                          !this.state.selectedSocialMediaLinkUrl
                        }
                        width="36px"
                        height="36px"
                        type={ThemeCircleIcon.dark}
                        backgroundColor="#9D9D9D"
                        fontSize="18px"
                        color="#ffffff"
                        className="mx-2 iconBtn"
                        padding="0"
                        buttonType="submit"
                      >
                        <i className="fas fa-plus"></i>
                      </CircleIcon>
                    </div> */}

                    <p className='text-error'>
                      {this.validator.message(
                        'input',
                        this.state.selectedSocialMediaLinkUrl,
                        'url'
                      )}
                    </p>
                  </div>
                </div>
              )}

              {this.state.selectedTab == 3 && (
                <div className='row'>
                  <h5 className='font-bold'>Connected Accounts</h5>
                  <p className='textSecondary fs-200'>
                    Connect your Google or Microsoft account to make it easier to log in to Radvix.
                  </p>
                  {this.state.pageLoading ? (
                    <>
                      <Skeleton height={44} className='w-100' />
                      <Skeleton height={44} className='w-100 mt-1' />
                    </>
                  ) : (
                    <>
                      <div className='col-6'>
                        {this.state.googleEmail ? (
                          <>
                            <GoogleButton
                              text={`Google Account - ${secureEmail(this.state.googleEmail)}`}
                              className='lightButton'
                              variant='contained'
                              disabled={true}
                              onClick={
                                this.state.googleEmail
                                  ? () => {
                                      this.handleConnectGoogleAccount(false);
                                    }
                                  : undefined
                              }
                            />
                          </>
                        ) : (
                          <GoogleLogin
                            clientId={clientId}
                            buttonText='Connect Google Account'
                            onSuccess={(res: any) => {
                              // const {
                              //   email,
                              //   familyName,
                              //   givenName,
                              //   googleId,
                              //   imageUrl,
                              //   name,
                              // } = res.profileObj;
                              //
                              if (res.tokenId) this.handleConnectGoogleAccount(true, res.tokenId);
                            }}
                            onFailure={(err) => {
                              //
                            }}
                            cookiePolicy={'single_host_origin'}
                            // isSignedIn={true}
                            style={{
                              borderRadius: '50px',
                            }}
                            className='google-login-btn contained'
                          />
                        )}
                      </div>
                      <div className='col-6'>
                        {this.state.microsoftEmail ? (
                          <>
                            <MicrosoftLogin
                              className='lightButton'
                              disabled={true}
                              variant='contained'
                              text={
                                this.state.microsoftEmail
                                  ? `Microsoft Account - ${secureEmail(this.state.microsoftEmail)}`
                                  : `Connect Microsoft Account`
                              }
                              onSuccess={(res: any) => {
                                if (res.idToken)
                                  this.handleConnectMicrosoftAccount(true, res.idToken);
                              }}
                              onFailure={(err: any) => {}}
                              onClick={
                                this.state.microsoftEmail
                                  ? () => {
                                      this.handleConnectMicrosoftAccount(false);
                                    }
                                  : undefined
                              }
                            />
                          </>
                        ) : (
                          <MicrosoftLogin
                            disabled={false}
                            className={`${this.state.microsoftEmail ? 'lightButton' : ''}`}
                            // variant={this.state.microsoftEmail ? 'contained' : 'outlined'}
                            text={`Connect Microsoft Account`}
                            onSuccess={(res: any) => {
                              if (res.idToken)
                                this.handleConnectMicrosoftAccount(true, res.idToken);
                            }}
                            onFailure={(err: any) => {}}
                            onClick={
                              this.state.microsoftEmail
                                ? () => {
                                    this.handleConnectMicrosoftAccount(false);
                                  }
                                : undefined
                            }
                          />
                        )}
                      </div>
                    </>
                  )}
                  <div style={{ paddingTop: '15px' }}>
                    <Separator orientation='horizontal' size={'2'} style={{ width: '100%' }} />
                  </div>
                  <div style={{ paddingTop: '30px' }}>
                    <h5 className='font-bold'>SMS Two-Factor Authentication</h5>
                    <p className='textSecondary fs-200'>
                      Two-factor authentication adds an extra layer of security to your account.
                      Once enabled, you'll be asked to enter a unique code from your phone or email
                      when you sign in.
                    </p>
                    <div className='d-flex align-items-center'>
                      {/* <div
                        className="item w-50"
                        style={{ marginTop: "-15px", paddingRight: "5px" }}
                      >
                        <SelectComponent
                          items={this.state.listCountry}
                          TextItem="name"
                          ValueItem="id"
                          className="form-select control-border-color"
                          placeholder="Select"
                          label="Country"
                          labelclassName="color-gray-600 fs-15 me-2"
                          onChange={(e) => {
                            this.setState({
                              selectedLocation: e,
                            });
                            // this.handelChangeSelect(e, "locationId");
                          }}
                          defaultValue={this.state.selectedLocation}
                        ></SelectComponent>
                      </div> */}
                      <div className='row w-50'>
                        <div className='col-lg-12 mb-3'>
                          <div className='d-flex align-items-center mb-1'>
                            <label className='color-gray-600 fs-15 me-2'>Country</label>
                            {/* <div
                              className="text-primary cursor-pointer"
                              title="Info"
                            >
                              <svg
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  width="15"
                                  height="15"
                                  fill="white"
                                  fill-opacity="0.01"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M7.49991 0.876953C3.84222 0.876953 0.877075 3.8421 0.877075 7.49978C0.877075 11.1575 3.84222 14.1227 7.49991
                        14.1227C11.1576 14.1227 14.1227 11.1575 14.1227 7.49978C14.1227 3.8421 11.1576 0.876953 7.49991 0.876953ZM1.82707
                        7.49978C1.82707 4.36677 4.36689 1.82695 7.49991 1.82695C10.6329 1.82695 13.1727 4.36677 13.1727 7.49978C13.1727 10.6328
                        10.6329 13.1727 7.49991 13.1727C4.36689 13.1727 1.82707 10.6328 1.82707 7.49978ZM8.24992 4.50005C8.24992 4.91426 7.91413
                        5.25005 7.49992 5.25005C7.08571 5.25005 6.74992 4.91426 6.74992 4.50005C6.74992 4.08583 7.08571 3.75005 7.49992
                        3.75005C7.91413 3.75005 8.24992 4.08583 8.24992 4.50005ZM6.00003 6.00005H6.50003H7.50003C7.77618 6.00005 8.00003 6.2239
                        8.00003
                        6.50005V10.0001H8.50003H9.00003V11.0001H8.50003H7.50003H6.50003H6.00003V10.0001H6.50003H7.00003V7.00005H6.50003H6.00003V6.00005Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div> */}
                          </div>
                          <select
                            className='form-select'
                            disabled={true}
                            style={{ height: '35px', marginTop: '-5px' }}
                            onChange={(e) => {
                              const selectedCountry = this.state.listCountry.find(
                                (country) => country.value === parseInt(e.target.value)
                              );
                              this.setState({
                                selectedLocation: selectedCountry || null,
                              });
                            }}
                            value={
                              133
                              // this.state.selectedLocation
                              //   ? this.state.selectedLocation.value
                              //   : ""
                            }
                          >
                            <option value={133}>United States</option>
                            {/* {this.state.listCountry.map((country) => (
                              <option key={country.value} value={country.value}>
                                {country.label}
                              </option>
                            ))} */}
                          </select>
                        </div>
                      </div>
                      <div className='item w-100' style={{ paddingLeft: '5px' }}>
                        <InputComponent
                          type={InputType.text}
                          label='Phone number'
                          counter={false}
                          onChange={(e) => {
                            this.handleChange('phoneNumber', e.target.value);
                          }}
                          // inValid={this.validator.message(
                          //   "Phone",
                          //   this.state.phoneNumber,
                          //   "phone"
                          // )}
                          value={this.state.phoneNumber}
                        ></InputComponent>
                      </div>

                      {/* <span
                className="ms-2"
                data-bs-toggle="modal"
                data-bs-target="#modal-change-password"
              > */}
                    </div>
                    <MainButton
                      svg={
                        <svg
                          className='hover-fill'
                          width='15'
                          height='15'
                          viewBox='0 0 15 15'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect width='15' height='15' fill='white' fillOpacity='0.01' />
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M1.20308 1.04312C1.00481 0.954998 0.772341 1.0048 0.627577 1.16641C0.482813 1.32802 0.458794 1.56455 0.568117 1.75196L3.92115 7.50002L0.568117 13.2481C0.458794 13.4355 0.482813 13.672 0.627577 13.8336C0.772341 13.9952 1.00481 14.045 1.20308 13.9569L14.7031 7.95693C14.8836 7.87668 15 7.69762 15 7.50002C15 7.30243 14.8836 7.12337 14.7031 7.04312L1.20308 1.04312ZM4.84553 7.10002L2.21234 2.586L13.2689 7.50002L2.21234 12.414L4.84552 7.90002H9C9.22092 7.90002 9.4 7.72094 9.4 7.50002C9.4 7.27911 9.22092 7.10002 9 7.10002H4.84553Z'
                            fill='#5746AF'
                          />
                        </svg>
                      }
                      children='Send Code'
                      type={MainButtonType.dark}
                      borderRadius='24px'
                      fontSize='14px'
                      minHeight='2em'
                      className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 main-button'
                      onClick={() => {
                        this.state.phoneNumber == null || this.state.phoneNumber.length < 10
                          ? toast.error('Please enter a valid number')
                          : this.setState({ showModal: true });
                      }}
                    ></MainButton>
                  </div>
                  <div style={{ paddingTop: '15px' }}>
                    <Separator orientation='horizontal' size={'2'} style={{ width: '100%' }} />
                  </div>
                  <div
                    className='col-12'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingTop: '10px',
                    }}
                  >
                    <div className='col-8' style={{ fontSize: 'larger', fontWeight: 600 }}>
                      Reset Password
                    </div>
                    <div className='col-4' style={{ display: 'flex', justifyContent: 'end' }}>
                      <MainButton
                        svg={
                          <svg
                            className='hover-fill'
                            xmlns='http://www.w3.org/2000/svg'
                            width='15'
                            height='15'
                            viewBox='0 0 15 15'
                            fill='none'
                          >
                            <rect width='15' height='15' fill='white' fill-opacity='0.01' />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M1.84961 7.5001C1.84961 4.6647 4.05942 1.8501 7.49961 1.8501C10.2779 1.8501 11.6511 3.90652 12.2363 5.00012H10.4996C10.2235 5.00012 9.99963 5.22398 9.99963 5.50012C9.99963 5.77626 10.2235 6.00012 10.4996 6.00012H13.4996C13.7757 6.00012 13.9996 5.77626 13.9996 5.50012V2.50012C13.9996 2.22398 13.7757 2.00012 13.4996 2.00012C13.2235 2.00012 12.9996 2.22398 12.9996 2.50012V4.3133C12.2951 3.07138 10.6655 0.850098 7.49961 0.850098C3.43679 0.850098 0.849609 4.18549 0.849609 7.5001C0.849609 10.8147 3.43679 14.1501 7.49961 14.1501C9.44345 14.1501 11.0618 13.3809 12.2141 12.2085C12.8311 11.5807 13.3129 10.8391 13.6414 10.0408C13.7465 9.78548 13.6247 9.49327 13.3694 9.38818C13.114 9.28308 12.8218 9.4049 12.7167 9.66026C12.4359 10.3426 12.0247 10.9746 11.5009 11.5075C10.5291 12.4964 9.16467 13.1501 7.49961 13.1501C4.05942 13.1501 1.84961 10.3355 1.84961 7.5001Z'
                              fill='#5746AF'
                            />
                          </svg>
                        }
                        disabled={this.state.pageLoading}
                        children='Reset Password'
                        type={MainButtonType.dark}
                        borderRadius='24px'
                        fontSize='14px'
                        minHeight='2em'
                        className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 main-button'
                        onClick={() => {}}
                      ></MainButton>
                    </div>
                  </div>
                  <div style={{ paddingTop: '10px' }}>
                    <Separator orientation='horizontal' size={'2'} style={{ width: '100%' }} />
                  </div>
                  {/* </span> */}
                </div>
              )}
            </div>
          </div>
          <div style={{ paddingTop: '20px' }}>
            <MainButton
              className='btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2'
              svg={
                <svg
                  className='hover-fill'
                  xmlns='http://www.w3.org/2000/svg'
                  width='15'
                  height='15'
                  viewBox='0 0 15 15'
                  fill='none'
                >
                  <rect width='15' height='15' fill='white' fillOpacity='0.01' />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M12.1464 1.14645C12.3417 0.951183 12.6583 0.951183 12.8535 1.14645L14.8535 3.14645C15.0488 3.34171 15.0488 3.65829 14.8535 3.85355L10.9109 7.79618C10.8349 7.87218 10.7471 7.93543 10.651 7.9835L6.72359 9.94721C6.53109 10.0435 6.29861 10.0057 6.14643 9.85355C5.99425 9.70137 5.95652 9.46889 6.05277 9.27639L8.01648 5.34897C8.06455 5.25283 8.1278 5.16507 8.2038 5.08907L12.1464 1.14645ZM12.5 2.20711L8.91091 5.79618L7.87266 7.87267L8.12731 8.12732L10.2038 7.08907L13.7929 3.5L12.5 2.20711ZM9.99998 2L8.99998 3H4.9C4.47171 3 4.18056 3.00039 3.95552 3.01877C3.73631 3.03668 3.62421 3.06915 3.54601 3.10899C3.35785 3.20487 3.20487 3.35785 3.10899 3.54601C3.06915 3.62421 3.03669 3.73631 3.01878 3.95552C3.00039 4.18056 3 4.47171 3 4.9V11.1C3 11.5283 3.00039 11.8194 3.01878 12.0445C3.03669 12.2637 3.06915 12.3758 3.10899 12.454C3.20487 12.6422 3.35785 12.7951 3.54601 12.891C3.62421 12.9309 3.73631 12.9633 3.95552 12.9812C4.18056 12.9996 4.47171 13 4.9 13H11.1C11.5283 13 11.8194 12.9996 12.0445 12.9812C12.2637 12.9633 12.3758 12.9309 12.454 12.891C12.6422 12.7951 12.7951 12.6422 12.891 12.454C12.9309 12.3758 12.9633 12.2637 12.9812 12.0445C12.9996 11.8194 13 11.5283 13 11.1V6.99998L14 5.99998V11.1V11.1207C14 11.5231 14 11.8553 13.9779 12.1259C13.9549 12.407 13.9057 12.6653 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.6653 13.9057 12.407 13.9549 12.1259 13.9779C11.8553 14 11.5231 14 11.1207 14H11.1H4.9H4.87934C4.47686 14 4.14468 14 3.87409 13.9779C3.59304 13.9549 3.33469 13.9057 3.09202 13.782C2.7157 13.5903 2.40973 13.2843 2.21799 12.908C2.09434 12.6653 2.04506 12.407 2.0221 12.1259C1.99999 11.8553 1.99999 11.5231 2 11.1207V11.1V4.9V4.87935C1.99999 4.47689 1.99999 4.14468 2.0221 3.87409C2.04506 3.59304 2.09434 3.33469 2.21799 3.09202C2.40973 2.71569 2.7157 2.40973 3.09202 2.21799C3.33469 2.09434 3.59304 2.04506 3.87409 2.0221C4.14468 1.99999 4.47685 1.99999 4.87932 2H4.9H9.99998Z'
                    fill='white'
                  />
                </svg>
              }
              onClick={() => {
                this.UpdateMyProfile();
              }}
              loading={this.state.loading || this.state.loading}
              disabled={this.state.loading || this.state.loading}
            >
              Update my profile
            </MainButton>
          </div>
        </div>
        <Dialog.Root open={this.state.showModal}>
          <Dialog.Portal>
            <Dialog.Overlay className='DialogOverlay' />
            <Dialog.Content className='DialogContent'>
              <div
                className='d-flex justify-content-between align-items-center  row-gap-3 flex-wrap column-gap-3 border-bottom border-gray-400'
                style={{ paddingBottom: '10px' }}
              >
                <div className='d-flex align-items-center'>
                  <BaseButton
                    className='btn btn-outline-primary fs-15 py-px-4 me-px-12 px-3 d-sm-block d-none back-button'
                    style={{ fontWeight: '400', height: '19px' }}
                    onClick={() => this.setState({ showModal: false })}
                  >
                    Close
                  </BaseButton>
                  <h2 className='fw-medium fs-15 mb-0 color-gray-1000 modalHeader'>
                    Verify Phone Number
                  </h2>
                </div>
              </div>
              <div className='col-12 d-flex' style={{ alignItems: 'center', paddingTop: '10px' }}>
                <div className='col-12'>
                  <MfaSection
                    userMobileNumber={this.state.phoneNumber}
                    isMobileNumberVerified={this.state.isMobileNumberVerified}
                    onCodeSend={() => {
                      this.getProfile();
                    }}
                  />
                </div>
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </div>
      // <div className="container-fluid research new-research">
      //   <div className="row"></div>
      //   <div className="col-12 box-content p-3">
      //     <div className="d-flex align-items-center w-100 justify-content-between">
      //       <h5 className="b-title d-flex align-items-center">
      //         <IoIosArrowDropleftCircle
      //           className="me-3 textSecondary2 cursorPointer"
      //           size="1.5em"
      //           onClick={() => {
      //             window.history.back();
      //           }}
      //         />{" "}
      //         Edit My Profile
      //       </h5>
      //       <div className="d-flex align-items-center">
      //         {this.state.loading || this.state.pageLoading ? (
      //           <SpinnerLoader size="1.25em" />
      //         ) : (
      //           ""
      //         )}
      //         <span
      //           className="ms-2"
      //           data-bs-toggle="modal"
      //           data-bs-target="#modal-change-password"
      //         >
      //           <MainButton
      //             disabled={this.state.pageLoading}
      //             children="Reset Password"
      //             type={MainButtonType.dark}
      //             borderRadius="24px"
      //             fontSize="14px"
      //             minHeight="2em"
      //             className="px-3"
      //             onClick={() => {}}
      //           ></MainButton>
      //         </span>
      //       </div>
      //     </div>
      //     <div className="form row mt-4 mb-2">
      //       <div className="col-md-6 d-flex flex-column">
      //         <h6 className="font-bold">Connected Accounts</h6>
      //         <p className="textSecondary fs-200">
      //           Connect your Google or Microsoft account to make it easier to
      //           log in to Radvix.
      //         </p>
      //         {this.state.pageLoading ? (
      //           <>
      //             <Skeleton height={44} className="w-100" />
      //             <Skeleton height={44} className="w-100 mt-1" />
      //           </>
      //         ) : (
      //           <>
      //             {this.state.googleEmail ? (
      //               <>
      //                 <GoogleButton
      //                   text={`Google Account - ${secureEmail(
      //                     this.state.googleEmail
      //                   )}`}
      //                   className="darkButton"
      //                   variant="contained"
      //                   disabled={true}
      //                   onClick={
      //                     this.state.googleEmail
      //                       ? () => {
      //                           this.handleConnectGoogleAccount(false);
      //                         }
      //                       : undefined
      //                   }
      //                 />
      //               </>
      //             ) : (
      //               <GoogleLogin
      //                 clientId={clientId}
      //                 buttonText="Connect Google Account"
      //                 onSuccess={(res: any) => {
      //                   // const {
      //                   //   email,
      //                   //   familyName,
      //                   //   givenName,
      //                   //   googleId,
      //                   //   imageUrl,
      //                   //   name,
      //                   // } = res.profileObj;
      //                   //
      //                   if (res.tokenId)
      //                     this.handleConnectGoogleAccount(true, res.tokenId);
      //                 }}
      //                 onFailure={(err) => {
      //                   //
      //                 }}
      //                 cookiePolicy={"single_host_origin"}
      //                 // isSignedIn={true}
      //                 style={{
      //                   borderRadius: "50px",
      //                 }}
      //                 className="google-login-btn contained"
      //               />
      //             )}
      //             {this.state.microsoftEmail ? (
      //               <>
      //                 <MicrosoftLogin
      //                   className="darkButton"
      //                   disabled={true}
      //                   variant="contained"
      //                   text={
      //                     this.state.microsoftEmail
      //                       ? `Microsoft Account - ${secureEmail(
      //                           this.state.microsoftEmail
      //                         )}`
      //                       : `Connect Microsoft Account`
      //                   }
      //                   onSuccess={(res: any) => {
      //                     if (res.idToken)
      //                       this.handleConnectMicrosoftAccount(
      //                         true,
      //                         res.idToken
      //                       );
      //                   }}
      //                   onFailure={(err: any) => {}}
      //                   onClick={
      //                     this.state.microsoftEmail
      //                       ? () => {
      //                           this.handleConnectMicrosoftAccount(false);
      //                         }
      //                       : undefined
      //                   }
      //                 />
      //               </>
      //             ) : (
      //               <MicrosoftLogin
      //                 disabled={false}
      //                 className={`mt-2${
      //                   this.state.microsoftEmail ? "darkButton" : ""
      //                 }`}
      //                 // variant={this.state.microsoftEmail ? 'contained' : 'outlined'}
      //                 text={`Connect Microsoft Account`}
      //                 onSuccess={(res: any) => {
      //                   if (res.idToken)
      //                     this.handleConnectMicrosoftAccount(true, res.idToken);
      //                 }}
      //                 onFailure={(err: any) => {}}
      //                 onClick={
      //                   this.state.microsoftEmail
      //                     ? () => {
      //                         this.handleConnectMicrosoftAccount(false);
      //                       }
      //                     : undefined
      //                 }
      //               />
      //             )}
      //           </>
      //         )}
      //       </div>
      //       <div className="col-md-6 flex flex-column">
      //         <h6 className="font-bold">Two-factor authentication</h6>
      //         <p className="textSecondary fs-200">
      //           Two-factor authentication adds an extra layer of security to
      //           your account. Once enabled, you'll be asked to enter a unique
      //           code from your phone or email when you sign in.
      //         </p>
      //         {/* <div className="d-flex align-items-center justify-content-center" style={{ height: '6em' }}>
      //           <span className="s-chip filled">Comming soon</span>
      //         </div> */}
      //         <MfaSection
      //           userMobileNumber={this.state.mobileNumber}
      //           isMobileNumberVerified={this.state.isMobileNumberVerified}
      //           onCodeSend={() => {
      //             this.getProfile();
      //           }}
      //         />
      //       </div>
      //     </div>
      //     <div className="form row">
      //       <div className="col-md-6 left beforeNoneMd">
      //         <form
      //           className="item"
      //           autoComplete="off"
      //           onSubmit={(e) => e.preventDefault()}
      //         >
      //           <div className="item">
      //             <InputComponent
      //               type={InputType.text}
      //               label="First Name:"
      //               popQuestion="Enter your first name."
      //               onChange={(e) => {
      //                 this.handleChange("firstName", e.target.value);
      //               }}
      //               value={this.state.firstName}
      //             ></InputComponent>
      //           </div>
      //           <div className="item">
      //             <InputComponent
      //               type={InputType.text}
      //               label="Last Name:"
      //               popQuestion="Enter your last name."
      //               onChange={(e) => {
      //                 this.handleChange("lastName", e.target.value);
      //               }}
      //               value={this.state.lastName}
      //             ></InputComponent>
      //           </div>
      //           <div className="item">
      //             <span className="label d-flex align-items-center mb-1">
      //               Profile Picture
      //               <div
      //                 // type={MainButtonType.light}
      //                 // children={optional}
      //                 // borderRadius="50px"
      //                 // fontSize="15px"
      //                 className="s-chip filled mx-2"
      //               >
      //                 optional
      //               </div>
      //               <CircleIconTooltip text="This will be your public profile picture." />
      //             </span>

      //             <GetPictureAndCrop
      //               originalImage={
      //                 this.state.profileImage?.name
      //                   ? this.state.profileImage?.name
      //                   : ""
      //               }
      //               picture={
      //                 this.state.profileImage?.name
      //                   ? IMAGE_BASE_URL + this.state.profileImage.name
      //                   : ""
      //               }
      //               onRemove={() => {
      //                 this.setState({
      //                   profileImage: "",
      //                   picture: [],
      //                   removedMediasId: [
      //                     ...this.state.removedMediasId,
      //                     this.state.profileImage?.id,
      //                   ],
      //                 });
      //               }}
      //               onSave={(_pictures: any) => {
      //                 this.setState({
      //                   picture: _pictures,
      //                   profileImage: URL.createObjectURL(_pictures[0]),
      //                 });
      //               }}
      //             />
      //             {/* <button onClick={() => this.handelUploadPic()}>
      //             Test save pic
      //           </button> */}
      //             {/* <div className="d-flex justify-content-between align-items-center">
      //             {this.state.profileImage &&
      //             this.state.profileImage.length > 2 ? (
      //               <Fragment>
      //                 <img
      //                   src={
      //                     !this.state.cahngePic
      //                       ? this.state.profileImage
      //                         ? AppConstants.base_url_image +
      //                           this.state.profileImage
      //                         : "/images/icons/user.svg"
      //                       : this.state.profileImage
      //                   }
      //                   alt="Avatar"
      //                   className="rounded-circle avatar mx-2"
      //                   width="125px"
      //                   height="125px"
      //                 />
      //                 <CircleIcon
      //                   type={ThemeCircleIcon.dark}
      //                   width="30px"
      //                   height="30px"
      //                   className="mx-2 pointer"
      //                   onClick={() => {
      //                     this.setState({
      //                       picture: [],
      //                       profileImage: "",
      //                     });
      //                   }}
      //                 >
      //                   <img
      //                     src="/images/icons/garbage_can.svg"
      //                     alt="radvix"
      //                     width={15}
      //                     height={15}
      //                   />
      //                 </CircleIcon>
      //               </Fragment>
      //             ) : null}

      //             <Dropzone onDrop={this.onDropPic}>
      //               {({ getRootProps, getInputProps }) => (
      //                 <section className="container fileUploadBox">
      //                   <div {...getRootProps({ className: "dropzone" })}>
      //                     <input {...getInputProps()} multiple={false} />
      //                     <MainButton
      //                       type={MainButtonType.light}
      //                       minHeight="30px"
      //                       minWidth="179px"
      //                       fontSize="14px"
      //                       borderRadius="50px"
      //                       backgroundColor="#fff"
      //                       border="1px solid #707070"
      //                       color="#707070"
      //                       className="mt-4"
      //                       children={
      //                         <div className="d-flex justify-content-between align-items-center">
      //                           <img
      //                             src="/Images/icons/cloud_computing.svg"
      //                             alt="sssss"
      //                             height="20"
      //                           />{" "}
      //                           <span className="flex-fill">
      //                             Browse Local Files
      //                           </span>
      //                         </div>
      //                       }
      //                     ></MainButton>
      //                     <p>Or drag and drop files here</p>
      //                   </div>
      //                   <aside>
      //                     <ul>{picture}</ul>
      //                   </aside>
      //                 </section>
      //               )}
      //             </Dropzone>
      //           </div> */}
      //           </div>
      //           <div className="item">
      //             <InputComponent
      //               disabled
      //               type={InputType.text}
      //               label="User Email"
      //               popQuestion="This is the email used to register on Radvix and login."
      //               onChange={(e) => {
      //                 this.handleChange("email", e.target.value);
      //               }}
      //               value={this.state.email}
      //             ></InputComponent>
      //           </div>
      //           <div className="item">
      //             <span className="label d-flex align-items-center">
      //               Education Background:
      //               <div
      //                 // type={MainButtonType.light}
      //                 // children={optional}
      //                 // borderRadius="50px"
      //                 // fontSize="15px"
      //                 className="s-chip filled mx-2"
      //               >
      //                 optional
      //               </div>
      //               <CircleIconTooltip text="Please fill out the form below to help other users in your research team get familiar with you educational background. This information will be available on your public profile." />
      //             </span>
      //             <InputComponent
      //               type={InputType.text}
      //               label="School/University"
      //               // popQuestion="School/University"
      //               onChange={(e) => {
      //                 this.handleChange("university", e.target.value);
      //               }}
      //               value={this.state.university}
      //             ></InputComponent>
      //           </div>
      //           <div className="d-flex align-items-center gap-2">
      //             <div className="item w-100">
      //               <SelectComponent
      //                 items={this.state.listDegree}
      //                 TextItem="title"
      //                 ValueItem="id"
      //                 // className="my-2"
      //                 placeholder="Select"
      //                 label="Degree"
      //                 defaultValue={this.state.degree}
      //                 onChange={(e) => {
      //                   this.setState({
      //                     degree: e,
      //                   });
      //                   // this.handelChangeSelect(e, "degree");
      //                 }}
      //               ></SelectComponent>
      //             </div>
      //             <div className="item w-100">
      //               <InputComponent
      //                 type={InputType.text}
      //                 label="Major"
      //                 onChange={(e) => {
      //                   this.handleChange("major", e.target.value);
      //                 }}
      //                 value={this.state.major}
      //               ></InputComponent>
      //             </div>
      //           </div>

      //           <div className="item">
      //             <SelectComponent
      //               label="Timezone"
      //               items={this.state.listTimeZone}
      //               defaultValue={this.state.selectedTimeZone}
      //               onChange={(_e) => {
      //                 // onChangeSetting(_e, "timeZoneId");
      //                 this.setState({
      //                   selectedTimeZone: _e,
      //                 });
      //               }}
      //               TextItem="name"
      //               ValueItem="id"
      //               className="mt-1"
      //               placeholder="Eastern Time"
      //             ></SelectComponent>
      //           </div>

      //           {AccessPermition(this.state.role || 8, [
      //             UserRoles.L1Client,
      //             UserRoles.L1User,
      //           ]) ? (
      //             <div className="item w-100">
      //               <SelectComponent
      //                 items={this.state.userTypeList.filter((item: any) => {
      //                   if (this.state.role === 1) return item.role === 2;
      //                   return item.role === this.state.role;
      //                 })}
      //                 TextItem="title"
      //                 ValueItem="id"
      //                 // className="my-2"
      //                 placeholder="Select"
      //                 label="Role Name"
      //                 defaultValue={this.state.selectedUserTypeId}
      //                 onChange={(e) => {
      //                   this.setState({
      //                     selectedUserTypeId: e,
      //                   });
      //                   // this.handelChangeSelect(e, "degree");
      //                 }}
      //               ></SelectComponent>
      //             </div>
      //           ) : (
      //             ""
      //           )}
      //         </form>
      //         {/* <ChangePassword /> */}
      //       </div>
      //       <div className="col-md-6 right">
      //         <div className="item">
      //           <span className="label d-flex align-items-center">
      //             Resume
      //             <div
      //               // type={MainButtonType.light}
      //               // children={optional}
      //               // borderRadius="50px"
      //               // fontSize="15px"
      //               className="s-chip filled mx-2"
      //             >
      //               optional
      //             </div>
      //             <CircleIconTooltip text="Please upload a single document as your public resume to share on your public profile with other users in your research team." />
      //           </span>
      //           <Dropzone onDrop={this.onDrop} multiple={false}>
      //             {({ getRootProps, getInputProps }) => (
      //               <section
      //                 style={{ marginTop: "0.35em" }}
      //                 className="container fileUploadBox"
      //               >
      //                 <div {...getRootProps({ className: "dropzone" })}>
      //                   <input {...getInputProps()} />
      //                   <MainButton
      //                     type={MainButtonType.light}
      //                     minHeight="30px"
      //                     minWidth="179px"
      //                     fontSize="14px"
      //                     borderRadius="50px"
      //                     backgroundColor="#fff"
      //                     border="1px solid #707070"
      //                     color="#707070"
      //                     className="mt-4"
      //                     children={
      //                       <div className="d-flex justify-content-between align-items-center">
      //                         <img
      //                           src="/Images/icons/cloud_computing.svg"
      //                           alt="sssss"
      //                           height="20"
      //                         />{" "}
      //                         <span className="flex-fill">
      //                           Browse Local Files
      //                         </span>
      //                       </div>
      //                     }
      //                   ></MainButton>
      //                   <p>Or drag and drop files here</p>
      //                   <p className="text-center" style={{ marginTop: -20 }}>
      //                     Max 128MB
      //                   </p>
      //                 </div>
      //                 <aside>
      //                   <ul>{files}</ul>
      //                 </aside>
      //               </section>
      //             )}
      //           </Dropzone>
      //         </div>
      //         <div className="item">
      //           <form
      //             className="item"
      //             autoComplete="off"
      //             onSubmit={(e) => e.preventDefault()}
      //           >
      //             <span className="label d-flex align-items-center">
      //               Contact
      //               <div
      //                 // type={MainButtonType.light}
      //                 // children={optional}
      //                 // borderRadius="50px"
      //                 // fontSize="15px"
      //                 className="s-chip filled mx-2"
      //               >
      //                 optional
      //               </div>
      //               <CircleIconTooltip text="Please provide the physical address of your office. This will be displayed on your public profile." />
      //             </span>
      //             <div className="item mt-1">
      //               <InputComponent
      //                 type={InputType.text}
      //                 label="Institution/Company"
      //                 // popQuestion="Institution/Company"
      //                 className="mb-3"
      //                 onChange={(e) => {
      //                   this.handleChange("institution", e.target.value);
      //                 }}
      //                 value={this.state.institution}
      //               ></InputComponent>
      //             </div>
      //             <div className="item">
      //               <InputComponent
      //                 type={InputType.text}
      //                 label="Address Line 1"
      //                 // popQuestion="Address Line 1"
      //                 className="mb-3"
      //                 onChange={(e) => {
      //                   this.handleChange("addressLine1", e.target.value);
      //                 }}
      //                 value={this.state.addressLine1}
      //               ></InputComponent>
      //               <InputComponent
      //                 type={InputType.text}
      //                 label="Address Line 2"
      //                 // popQuestion="Address Line 2"
      //                 onChange={(e) => {
      //                   this.handleChange("addressLine2", e.target.value);
      //                 }}
      //                 value={this.state.addressLine2}
      //               ></InputComponent>
      //             </div>
      //             <div className="d-flex align-items-center gap-2">
      //               <div className="item w-100">
      //                 <InputComponent
      //                   type={InputType.text}
      //                   label="City"
      //                   onChange={(e) => {
      //                     this.setState({
      //                       city: e.target.value,
      //                     });
      //                     // this.handleChange("city", e.target.value);
      //                   }}
      //                   value={this.state.city}
      //                 ></InputComponent>
      //               </div>
      //               <div className="item w-100">
      //                 <InputComponent
      //                   type={InputType.text}
      //                   label="State/Province"
      //                   onChange={(e) => {
      //                     this.setState({
      //                       province: e.target.value,
      //                     });
      //                     // this.handleChange("province", e.target.value);
      //                   }}
      //                   value={this.state.province}
      //                 ></InputComponent>
      //               </div>
      //             </div>
      //             <div className="d-flex align-items-start gap-2">
      //               <div className="item w-100">
      //                 <InputComponent
      //                   type={InputType.text}
      //                   label=" ZIP/Postal Code"
      //                   counter={false}
      //                   onChange={(e) => {
      //                     this.handleChange("zipCode", e.target.value);
      //                   }}
      //                   value={this.state.zipCode}
      //                 ></InputComponent>
      //               </div>
      //               <div className="item w-100">
      //                 <InputComponent
      //                   type={InputType.text}
      //                   label="Phone"
      //                   counter={false}
      //                   onChange={(e) => {
      //                     this.handleChange("phoneNumber", e.target.value);
      //                   }}
      //                   inValid={this.validator.message(
      //                     "Phone",
      //                     this.state.phoneNumber,
      //                     "phone"
      //                   )}
      //                   value={this.state.phoneNumber}
      //                 ></InputComponent>
      //               </div>
      //             </div>
      //             <div className="item">
      //               <SelectComponent
      //                 items={this.state.listCountry}
      //                 TextItem="name"
      //                 ValueItem="id"
      //                 className="mt-1"
      //                 placeholder="Select"
      //                 label="Country"
      //                 onChange={(e) => {
      //                   this.setState({
      //                     selectedLocation: e,
      //                   });
      //                   // this.handelChangeSelect(e, "locationId");
      //                 }}
      //                 defaultValue={this.state.selectedLocation}
      //               ></SelectComponent>
      //             </div>
      //           </form>
      //           <div className="item">
      //             <span className="label d-flex align-items-center col-12 mb-1">
      //               Social Links
      //               <CircleIconTooltip
      //                 className="ms-2"
      //                 text="You can add your LinkedIn, ResearchGate, Google Scholar and other websites using this section to be displayed on your public profile."
      //               />
      //             </span>
      //             <form
      //               autoComplete="off"
      //               onSubmit={(e) => {
      //                 e.preventDefault();
      //                 if (this.validator.fields.input) {
      //                   const _id = nanoid();
      //                   this.setState({
      //                     addedSocialMediaLinks: [
      //                       ...this.state.addedSocialMediaLinks,
      //                       {
      //                         id: this.state.selectedSocialMediaLinkType?.value,
      //                         url: this.state.selectedSocialMediaLinkUrl,
      //                         nanoId: _id,
      //                       },
      //                     ],
      //                     selectedSocialMediaLinkUrl: "",
      //                     selectedSocialMediaLinkType: null,
      //                     ExternalUrl: [
      //                       ...this.state.ExternalUrl,
      //                       {
      //                         id: _id,
      //                         useCase:
      //                           this.state.selectedSocialMediaLinkType?.value,
      //                         externalUrl:
      //                           this.state.selectedSocialMediaLinkUrl,
      //                         newAdded: true,
      //                       },
      //                     ],
      //                   });
      //                 } else {
      //                   this.validator.showMessages();
      //                   this.forceUpdate();
      //                 }
      //               }}
      //               className="d-flex align-items-center gap-2"
      //             >
      //               <SelectComponent
      //                 items={this.state.socialMediaProfilesOptions}
      //                 TextItem="name"
      //                 ValueItem="id"
      //                 className="w-100"
      //                 placeholder="Select…"
      //                 defaultValue={this.state.selectedSocialMediaLinkType}
      //                 onChange={(e) => {
      //                   this.setState({
      //                     selectedSocialMediaLinkType: e,
      //                   });
      //                 }}
      //               ></SelectComponent>

      //               <div className="d-flex align-items-center w-100">
      //                 <InputComponent
      //                   // value={this.state.External}
      //                   // defaultValue="ast"
      //                   value={this.state.selectedSocialMediaLinkUrl}
      //                   className="mt-0"
      //                   type={InputType.text}
      //                   placeholder="https://"
      //                   counter={false}
      //                   onChange={(e) => {
      //                     this.setState({
      //                       selectedSocialMediaLinkUrl: e.target.value,
      //                     });
      //                     // this.handleChange("External", e.target.value);
      //                   }}
      //                 ></InputComponent>
      //                 <CircleIcon
      //                   disabled={
      //                     !this.state.selectedSocialMediaLinkType ||
      //                     !this.state.selectedSocialMediaLinkUrl
      //                   }
      //                   width="36px"
      //                   height="36px"
      //                   type={ThemeCircleIcon.dark}
      //                   backgroundColor="#9D9D9D"
      //                   fontSize="18px"
      //                   color="#ffffff"
      //                   className="mx-2 iconBtn"
      //                   padding="0"
      //                   buttonType="submit"
      //                 >
      //                   <i className="fas fa-plus"></i>
      //                 </CircleIcon>
      //               </div>
      //             </form>

      //             <p className="text-error">
      //               {this.validator.message(
      //                 "input",
      //                 this.state.selectedSocialMediaLinkUrl,
      //                 "url"
      //               )}
      //             </p>
      //           </div>
      //           <ul className="mt-3 d-flex flex-column gap-1 p-0">
      //             {/* {this.state.socialMediaProfiles.map((item) => (
      //               <li
      //                 key={item}
      //                 className="d-flex justify-content-between align-items-center mb-2"
      //               >
      //                 <div>
      //                   <img
      //                     src="/images/images/linkedIn_logo_initials.png"
      //                     alt=""
      //                   />{" "}
      //                   <MainButton
      //                     children={item}
      //                     type={MainButtonType.dark}
      //                     borderRadius="24px"
      //                     fontSize="14px"
      //                     backgroundColor="#F5F5F5"
      //                     color="#096BFF"
      //                   ></MainButton>
      //                 </div>
      //                 <CircleIcon
      //                   type={ThemeCircleIcon.dark}
      //                   width="29px"
      //                   height="29px"
      //                   className="mx-2 pointer"
      //                   onClick={()=>{

      //                   }}
      //                 >
      //                   <img
      //                     src="/images/icons/garbage_can.svg"
      //                     alt="radvix"
      //                     width={15}
      //                     height={15}
      //                   />
      //                 </CircleIcon>
      //               </li>
      //             ))} */}

      //             {/* {this.state.ExternalUrl.map((item) => ( */}
      //             {this.state.ExternalUrl.map((item) => (
      //               <li
      //                 key={item.id}
      //                 className="d-flex justify-content-between align-items-center mb-2"
      //               >
      //                 <div className="d-flex align-items-center gap-1 s-chip outlined">
      //                   <img
      //                     src={`/images/images/new/${
      //                       // @ts-ignore
      //                       socialMediaLogos[item.useCase]
      //                     }`}
      //                     alt=""
      //                     height="20px"
      //                     className="me-2"
      //                   />

      //                   <a
      //                     href={item.externalUrl}
      //                     target={"_blank"}
      //                     className="max1LineText"
      //                     rel="noreferrer"
      //                   >
      //                     {$truncate(item.externalUrl, 50)}
      //                   </a>
      //                   <CircleIcon
      //                     type={ThemeCircleIcon.dark}
      //                     width="24px"
      //                     height="24px"
      //                     className="pointer ms-2"
      //                     padding="0"
      //                     onClick={() => {
      //                       if (item?.newAdded) {
      //                         this.setState({
      //                           ExternalUrl: this.state.ExternalUrl.filter(
      //                             (i) => i.id !== item.id
      //                           ),
      //                           addedSocialMediaLinks:
      //                             this.state.addedSocialMediaLinks.filter(
      //                               (i: any) => i.nanoId !== item.id
      //                             ),
      //                         });
      //                       } else {
      //                         this.setState({
      //                           removedMediasId: [
      //                             ...this.state.removedMediasId,
      //                             item.id,
      //                           ],
      //                           ExternalUrl: this.state.ExternalUrl.filter(
      //                             (_item) => _item.id !== item.id
      //                           ),
      //                         });
      //                       }
      //                     }}
      //                   >
      //                     <img
      //                       src="/images/icons/garbage_can.svg"
      //                       alt="radvix"
      //                       width={12}
      //                       height={12}
      //                     />
      //                   </CircleIcon>
      //                 </div>
      //               </li>
      //             ))}
      //           </ul>
      //         </div>
      //       </div>
      //       <div className="col-12 d-flex flex-column justify-content-center align-items-center my-4">
      //         <MainButton
      //           type={MainButtonType.dark}
      //           children={"Update My Profile"}
      //           borderRadius="50px"
      //           fontSize="18px"
      //           className="mx-2 px-3"
      //           minHeight="43px"
      //           minWidth="136px"
      //           loading={this.state.loading}
      //           disabled={this.state.pageLoading || this.state.loading}
      //           onClick={() => {
      //             this.UpdateMyProfile();
      //           }}
      //         ></MainButton>
      //         <span className="mt-2 textSecondary">
      //           {this.state.loadingMode}
      //         </span>
      //       </div>
      //     </div>
      //   </div>

      //   <div
      //     className="modal fade"
      //     id="modal-change-password"
      //     tabIndex={-1}
      //     aria-labelledby="modal-change-password"
      //     aria-hidden="true"
      //   >
      //     <ChangePwModal
      //       userEmail={this.state.email}
      //       onClose={() => {
      //         document.getElementById("modal-change-password")?.click();
      //       }}
      //       onChangePassword={() => {
      //         document.getElementById("modal-change-password")?.click();
      //       }}
      //       // data-bs-dismiss="modal"
      //       // aria-label="Close"
      //       // id="close_modal_Type"
      //     />
      //   </div>
      // </div>
    );
  }
}

type ChangePwModalProps = {
  onClose?: () => void;
  onChangePassword?: () => void;
  userEmail: string;
  controller?: AbortController;
};

const ChangePwModal = ({ onChangePassword, onClose, userEmail }: ChangePwModalProps) => {
  const [oldPw, setOldPw] = useState('');
  const [newPw, setNewPw] = useState('');
  const [confirmPw, setConfirmPw] = useState('');
  const [isError, setError] = useState('');
  const [loading, setLoading] = useState(false);
  // const [showPw, setShowPw] = useState(false);
  const [isShowPassCheck, setIsShowPassCheck] = useState(false);
  const history = useHistory();
  async function handleChangePassword() {
    if (newPw !== confirmPw) {
      toast.warning('New password and confirm password are not the same');
      return;
    }
    if (isStrength !== 'Strong') {
      if (!newPw || (newPw && isStrength !== 'Strong')) {
        setError('Password is not strong');
      }
      toast.warning('Password is not strong');
    } else {
      const res = await changePassword(oldPw, newPw, userEmail);
      toast.success('Password changed successfully');

      if (!res) return;
      if (onChangePassword) {
        onChangePassword();
        logout();
      }
    }
  }
  function logout() {
    const setting: any = localStorage.getItem('setting');
    localStorage.clear();
    localStorage.setItem('setting', setting);

    delete HTTP.defaults.headers.common['authorization'];
    store.dispatch(SetResearchId(DEFAULT_RESEARCH));
    // window.location.replace(AppRoutes.login);

    // @ts-ignore
    window.tidioChatApi?.setContactProperties({
      subscription_id: 0,
      subscription_type: ' ',
      user_type: ' ',
    });
    history.push(AppRoutes.login);
  }
  function changePassword(oldPw: string, newPw: string, email: string) {
    setLoading(true);
    return new Promise((resolve, reject) => {
      HTTP.post('/User/ChangePassword', {
        oldPassword: oldPw,
        newPassword: newPw,
        email: email,
      })
        .then((res) => resolve(res.data))
        .catch((err) => {
          setLoading(false);
          reject(err);
        });
    });
  }

  const toggleVerifyPass = () => {
    setIsShowPassCheck(!isShowPassCheck);
  };
  const [isStrength, setStrength] = useState('');
  const dataHandler = async (childData: any) => {
    setStrength(childData);
  };
  return (
    <div className='modal-dialog' style={{ maxWidth: '100%', width: 400 }}>
      <div className='modal-content bg-light'>
        <div className='modal-header'>
          <span>Reset Password</span>
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='modal'
            aria-label='Close'
            id='close_modal_Type'
          ></button>
        </div>
        <div className='modal-body'>
          <div className='w-100'>
            <InputComponent
              type={InputType.text}
              label='Old Password:'
              onChange={(e) => {
                setOldPw(e.target.value);
              }}
              isPassword={true}
              value={oldPw}
            ></InputComponent>
          </div>
          <div className='mt-3 w-100 position-relative'>
            <InputComponent
              type={InputType.text}
              label='Password:'
              onChange={(e) => {
                setNewPw(e.target.value);
              }}
              onBlur={toggleVerifyPass}
              onFocus={toggleVerifyPass}
              isPassword={true}
              value={newPw}
            ></InputComponent>
            <ValidPassword isShow={isShowPassCheck} password={newPw} actions={dataHandler} />
            {isError && (
              <label htmlFor='password text-danger'>
                <p className='errors text-danger'>{isError}</p>
              </label>
            )}
          </div>
          <div className='mt-3 w-100'>
            <InputComponent
              type={InputType.text}
              label='Confirm Password:'
              onChange={(e) => {
                setConfirmPw(e.target.value);
              }}
              isPassword={true}
              value={confirmPw}
            ></InputComponent>
          </div>
        </div>
        <div className='modal-footer p-1'>
          <MainButton
            type={MainButtonType.light}
            children={'Close'}
            borderRadius='24px'
            fontSize='14px'
            className='mx-2 px-3'
            minHeight='2em'
            // minWidth="136px"
            onClick={onClose}
            disabled={loading}
          ></MainButton>
          <MainButton
            disabled={loading}
            loading={loading}
            type={MainButtonType.dark}
            children={'Reset Password'}
            borderRadius='24px'
            fontSize='14px'
            className='mx-0 px-3'
            minHeight='2em'
            // minWidth="136px"
            onClick={handleChangePassword}
          ></MainButton>
        </div>
      </div>
    </div>
  );
};

export function LogosGoogleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18px'
      style={{
        minWidth: '18px',
      }}
      height='18px'
      viewBox='0 0 256 262'
      {...props}
    >
      <path
        fill='#4285F4'
        d='M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622l38.755 30.023l2.685.268c24.659-22.774 38.875-56.282 38.875-96.027'
      ></path>
      <path
        fill='#34A853'
        d='M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055c-34.523 0-63.824-22.773-74.269-54.25l-1.531.13l-40.298 31.187l-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1'
      ></path>
      <path
        fill='#FBBC05'
        d='M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82c0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782'
      ></path>
      <path
        fill='#EB4335'
        d='M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0C79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251'
      ></path>
    </svg>
  );
}
const GoogleButton = ({
  onClick,
  text = 'Sign in with Google',
  className = '',
  variant = 'contained',
  disabled = false,
}: {
  onClick?: () => void | undefined;
  text?: string;
  className?: string;
  variant?: string;
  disabled?: boolean;
}) => {
  return (
    <button
      onClick={onClick}
      className={`google-login-btn ${variant} signInHover ${className}`}
      style={{
        width: '100%',
        display: 'inline-flex',
        alignItems: 'center',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px',
        border: '1px solid transparent',
        fontSize: 14,
        fontWeight: 500,
        fontFamily: 'Roboto, sans-serif',
      }}
      disabled={disabled}
      type='button'
    >
      <div
        style={{
          marginRight: 10,
          padding: 10,
        }}
      >
        <LogosGoogleIcon />
      </div>
      <span
        className='max1LineText'
        style={{
          padding: '10px 10px 10px 0px',
          fontWeight: 500,
          flexGrow: 1,
          textAlign: 'left',
        }}
      >
        {text}
      </span>
    </button>
  );
};
