/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import * as Avatar from '@radix-ui/react-avatar';

import ProgressBar from '@ramonak/react-progress-bar';
import { MainButton, MainButtonType } from '../components/button';
import { SelectComponent } from '../components/select_input';
import { useHistory, RouteComponentProps, withRouter } from 'react-router';
import {
  // AppConstants,
  AppRoutes,
  // DEFAULT_RESEARCH,
} from '../../core/constants';
import { ResearchController } from '../../controllers/research/research_controller';
import { store } from '../../data/storeMain';
import { LocalDataSources } from '../../data/local_datasources';
import AcordienTableResearchHeader from './component/acordian_table_research';
import { InputIcon } from '../components/search_box';
import ReactPaginate from 'react-paginate';
import HelpPanel from '../pages/Help/HelpPanel';
// import { CircleIcon, ThemeCircleIcon } from "../components/circle_icon";
import {
  SetResearchId,
  // SetResearchUpdated,
} from '../../data/storeMain/actions/research_action';
import { EmptyState } from '../components/empty_state';
import {
  $ReplaceRoute,
  handleClearStorageAndTokens,
  handleLogoutApi,
  UserRoles,
} from '../../core/utils';
import { UserController } from '../../controllers/user/user_controller';
import { ImageWithToken } from '../components/box_list_scroll';
import { IMAGE_BASE_URL, handleUnauthorizedRequest } from '../../core/http_common';
import { ProfileTypes, ResearchStatus, SortType } from '../../core/number_extentions';
// @ts-ignore
import Quote from 'inspirational-quotes';
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri';
import Skeleton from 'react-loading-skeleton';
// import { MdClose, MdHome, MdMenu, MdMoreVert } from "react-icons/md";
import { AiFillHome, AiOutlineQuestionCircle } from 'react-icons/ai';
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from 'react-icons/io';
// import { FaUser } from "react-icons/fa";
import { IoLogOut } from 'react-icons/io5';
// import { toast } from "react-toastify";
// import { QuickActions, RecentDiscussion } from "./right_section";
import PhoneSearch from '../components/PhoneSearch';
import { RxMagnifyingGlass } from 'react-icons/rx';
import { CircleIcon, ThemeCircleIcon } from '../components/circle_icon';
import { IFilterOptions } from '../components/FilterSection/IFilterOptions';
import FilterSection from '../components/FilterSection/filterSection';
import { BellIcon, SunIcon, AvatarIcon, EnterIcon, ClockIcon } from '@radix-ui/react-icons';
import { HamburgerMenuIcon, ChevronDownIcon } from '@radix-ui/react-icons';
// import { ResearchId } from "../../data/storeMain/redusers/research_id";
import { ConnectMicrosoft } from '../components/ConnectMicrosoft/connectMicrosoft';
import { getUserInitails } from '../../core/utils';
import HeaderDropDown from '../components/ActionComponent/HeaderDropDown';
import { DropdownMenu, Separator } from '@radix-ui/themes';
import { SetNotificationCount } from '../../data/storeMain/actions/notification_count_action';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import { SetHeaderTitle } from '../../data/storeMain/actions/header_title_action';
import { useMediaQuery } from 'react-responsive';
import { SetSidebarCollapse } from '../../data/storeMain/actions/sidebard_collapse_action';

interface IHeader {
  lastSelectedResearchId: number | null;
}
const Header: React.FC<IHeader & RouteComponentProps> = (props) => {
  let history = useHistory();
  const [listResearch, setListResearch] = useState<Array<any>>([]);
  const [PageNumber, setPageNumber] = useState<number>(1);
  const [PageSize, setPageSize] = useState<number>(10);
  const [helpPanelVisible, setHelpPanelVisible] = useState(false);
  const [PageCount, setPageCount] = useState<number>(0);
  const [TotalCount, setTotalCount] = useState<number>(0);
  // const [showNotif, setShowNotif] = useState(true);
  const [timelineLoading, setTimelineLoading] = useState(true);
  const [gettingResearchLoader, setGettingResearchLoader] = useState(true);
  const [Search, setSearch] = useState('');
  const [columnHeaderName, setColumnHeaderName] = useState<string | undefined>('');
  const { isCollapsed } = useSelector(() => store.getState().sidebarCollapse);
  const [sortDirection, setSortDirection] = useState<SortType>(SortType.ASC);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>({});
  const [hideProjectSection, setHideProjectSection] = useState<boolean>(true);
  const [showMicrosoftBox, setShowMicrosoftBox] = useState<boolean>(false);
  const [ProgressTimeline, setProgressTimeline] = useState<{
    percentage: number;
    color: string;
    status: string;
  }>({
    percentage: 0,
    color: '#474747',
    status: '',
  });
  const [ResearchName, setResearchName] = useState<string>('Choose your research');
  const [isTrial, setIsTrial] = useState(false);
  const [trialDays, setTrialDays] = useState(0);
  const controller = new ResearchController();
  const userController = new UserController();
  const local = new LocalDataSources();
  const RoleUser = store.getState().userRole;
  const [firstLoad, setfirstLoad] = useState(true);
  const notificationCount = useSelector(() => store.getState().notificationCount);
  const headerTitle = useSelector(() => store.getState().headerTitle);
  const dispatch = useDispatch();
  // const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });

  // console.log(props.lastSelectedResearchId);
  // useEffect(() => {
  //   console.log("render header");
  // }, []);

  // useEffect(() => {
  //   const notif = localStorage.getItem("newResearch");
  //   if (notif) {
  //     setShowNotif(JSON.parse(notif));
  //   }
  //   GetResearch(PageNumber, PageSize);
  //   if (store.getState().ResearchId.value >= 0) {
  //     timelineProgress();
  //   }
  //   const unsubscribeMe = store.subscribe(() => {
  //     if (store.getState().ResearchId.value >= 0) {
  //       console.error("come from subscribe");
  //       changeResearch(store.getState().ResearchId.name);
  //       timelineProgress();
  //     }
  //     if (store.getState().ResearchUpdated) {
  //       // console.log(store.getState().ResearchUpdated, "HEADER");

  //       GetResearch(PageNumber, PageSize, false);
  //       store.dispatch(SetResearchUpdated(false));
  //     }
  //   });

  //   return () => {
  //     // console.error('called')
  //     unsubscribeMe();
  //   };
  // }, [Search]);
  const toggleHelpPanel = () => {
    setHelpPanelVisible(!helpPanelVisible);
  };

  const toggleMicrosoftConnet = () => {
    if (showMicrosoftBox) {
      setShowMicrosoftBox(false);
    } else {
      setShowMicrosoftBox(true);
    }
    // const microsoftElement = document.querySelector(".OpenMicrosoftConnect");
    // microsoftElement?.classList.toggle("hidden");
  };

  useEffect(() => {
    calculateTrialDays();
    hideSideMenuIfSmallScreen();
    const _handleChangeResearch = (e: any) => {
      //GetResearch(PageNumber, PageSize);
      changeResearch(e.detail.id, e.detail.name);
    };
    // const _handleToggleQuickAccess = (e: any) => {
    //   setQuickAccessMode(e.detail?.action || '')
    //   toggleQuickAccess()
    // };
    // window.addEventListener("LOGOUT", logout);
    window.addEventListener('HANDLE_RESEARCH_CHANGED', _handleChangeResearch);
    // window.addEventListener("TOGGLE_QUICK_ACCESS", _handleToggleQuickAccess);
    return () => {
      // window.removeEventListener("LOGOUT", logout);
      window.removeEventListener('HANDLE_RESEARCH_CHANGED', _handleChangeResearch);
      // window.removeEventListener(
      //   "TOGGLE_QUICK_ACCESS",
      //   _handleToggleQuickAccess
      // );
    };
  }, []);

  const calculateTrialDays = () => {
    controller.getTrialInfo(
      (res) => {
        const { isTrial, days } = res;
        setIsTrial(isTrial);
        setTrialDays(days);
      },
      (err) => {
        console.error('Error fetching trial info:', err);
      }
    );
  };

  const handelChangePageNumber = (e: { selected: number }) => {
    setPageNumber(e.selected);
    GetResearch(e.selected + 1, PageSize, false, columnHeaderName, sortDirection, filterOptions);
  };
  const handelChangePageSize = (e: { label: string; value: number }) => {
    setPageSize(e.value);
    GetResearch(PageNumber, e.value, false, columnHeaderName, sortDirection, filterOptions);
  };

  const headerButtons = () => {
    return (
      <>
        {RoleUser !== UserRoles.Admin ? (
          <div className='align-items-center px-4 h-px-67 d-sm-flex padding-left-none  gap-4'>
            <div
              className='h-px-25 w-px-25 p-0 align-items-center justify-content-center bg-gray-200 rounded-px-2
                    d-sm-flex  top-header-button-layout opacity-50'
              title='Coming Soon'
            >
              <img
                src='/images/images/microsoft-logo.svg'
                height='15px'
                width='15px'
                //onClick={toggleMicrosoftConnet}
              />
            </div>
            {showMicrosoftBox ? (
              <div className='OpenMicrosoftConnect '>
                <ConnectMicrosoft
                  // selectedProfileType={this.state.notificationCategory}
                  filterEmail={true}
                />
              </div>
            ) : null}
            <div
              className='top-header-button-layout h-px-25 w-px-25 p-0 align-items-center justify-content-center
                    d-sm-flex opacity-50'
              title='Coming Soon'
            >
              <img src='/images/images/slack-logo.svg' height='15px' width='15px' />
            </div>
          </div>
        ) : null}
        <div className='border-start border-end border-gray-400 align-items-center px-4 h-px-67 d-sm-flex   gap-4'>
          {/* <div className='top-header-button-layout h-px-25 w-px-25 p-0 px-2 d-flex align-items-center justify-content-center opacity-50' title='Coming Soon'>
            <SunIcon
              // onClick={toggleHelpPanel}
              className='AccordionTriggerIcon '
            ></SunIcon>
          </div> */}
          <MainButton
            className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-25 py-0 mt-2'
            style={{}}
            onClick={toggleHelpPanel}
          >
            {' '}
            Need Help?
          </MainButton>
        </div>
      </>
    );
  };

  const logout = () => {
    // console.log('header layout logout')
    // clear all local storage except setting

    handleLogoutApi()
      .then((res) => {
        handleClearStorageAndTokens();
        history.push(AppRoutes.login);
      })
      .catch((err) => {
        if (err === 'Device Id not found') {
          handleClearStorageAndTokens();
          window.location.replace(AppRoutes.login);
          return;
        }
        const _tokenInStorage = localStorage.getItem('token');
        if (!_tokenInStorage) {
          handleClearStorageAndTokens();
          history.push(AppRoutes.login);
        } else {
          if (err.response && err.response.status === 401) {
            // Retry the request with refreshed token
            return handleUnauthorizedRequest(err.config).then((_res) => {
              handleClearStorageAndTokens();
              history.push(AppRoutes.login);
            });
            // .catch((e) => error(e));
          } else {
            handleClearStorageAndTokens();
            history.push(AppRoutes.login);
            // error(err);
          }
        }
      });
  };
  const GetResearch = (
    PageNumber: number,
    PageSize: number,
    UpdateLastSelectedResearch: boolean = false,
    columnName?: string,
    sort?: SortType,
    filterOptions?: IFilterOptions
  ) => {
    // console.log("GET RESEARCH HEADER", local.logedin());
    const _isTokenHere = localStorage.getItem('token');
    if (_isTokenHere) {
      setGettingResearchLoader(true);
      controller.getResearches(
        {
          PageNumber: PageNumber,
          PageSize: PageSize,
          SearchParameter: Search,
          columnHeaderName: columnName,
          sortDirection: sort,
          filterOptions: filterOptions,
        },
        (res) => {
          setListResearch(res.researchesList!);
          setPageCount(Math.ceil(res.count! / PageSize));
          setTotalCount(res.count!);
          const _justIdTitleResearchList = res.researchesList?.map(({ id, title }) => ({
            id,
            title,
          }));
          localStorage.setItem('researchList', JSON.stringify(_justIdTitleResearchList));

          if (res.count && res.count > 0 && PageNumber === 1) {
            if (res.researchesList) {
              const _lastResearchId = props.lastSelectedResearchId;
              // console.log(_lastResearchId);
              if (_lastResearchId && _lastResearchId !== -1) {
                const _findResearchFromList = res.researchesList.find(
                  (_r) => _r.id === _lastResearchId
                );

                // store.dispatch(
                //   SetResearchId({
                //     label: _findResearchFromList?.title!,
                //     value: _findResearchFromList?.id!,
                //   })
                // );
                // console.log(
                //   "called setTimeout changeResearch if _lastResearchId && _lastResearchId !== -1"
                // );
                setTimeout(() => {
                  // changeResearch(store.getState().ResearchId.name);
                  changeResearch(_findResearchFromList?.id!, _findResearchFromList?.title!);
                }, 250);
              } else {
                // store.dispatch(
                //   SetResearchId({
                //     label: res.researchesList[0].title!,
                //     value: res.researchesList[0].id!,
                //   })
                // );
                // console.log(
                //   "called setTimeout changeResearch else _lastResearchId && _lastResearchId !== -1"
                // );
                setTimeout(() => {
                  changeResearch(
                    store.getState().ResearchId.value,
                    store.getState().ResearchId.label
                  );
                }, 250);
              }
            }
          } else {
            changeResearch(0, 'No Project Selected');
          }
          setGettingResearchLoader(false);
        },
        (err) => {
          setGettingResearchLoader(false);
        }
      );
    }
  };

  const changeResearch = (id: number, title: string) => {
    // setResearchName(title);
    // store.dispatch(SetResearchId({ label: title, value: id }));
    // timelineProgress(id);
    // // console.error("called changeResearch");
    // // console.log("CALLED changeResearch before dispatchEvent RESEARCHCHANGED");
    // window.dispatchEvent(new CustomEvent('RESEARCH_CHANGED'));
  };

  const timelineProgress = (_researchId: number) => {
    if (_researchId === 0 || !_researchId || _researchId === undefined) {
      setTimelineLoading(false);
      return;
    }
    // if (
    //   !store.getState().ResearchId ||
    //   !store.getState().ResearchId.value ||
    //   store.getState().ResearchId.value === -1
    // )
    //   return;
    setTimelineLoading(true);
    userController.lastSelectedResearch(
      _researchId,
      (res) => {
        // console.log(res, ResearchStatus);
        const tmepTimeLineStatus =
          res.researchStatus === ResearchStatus.OnGoing && res.daysLeftDeadline < 0
            ? ResearchStatus.Delayed
            : res.researchStatus;

        setProgressTimeline({
          color: tmepTimeLineStatus.isStatusColor(),
          percentage: res.researchStatus === 2 || res.researchStatus === 3 ? 100 : res.timeLine,
          status: tmepTimeLineStatus.isStatus(),
        });
        setTimelineLoading(false);
      },
      () => {
        setTimelineLoading(false);
      }
    );
  };

  useEffect(() => {
    if (RoleUser === UserRoles.Admin) return;
    // console.error("useEffect called");
    const { location } = props;
    const _currentPath = location.pathname.split('/')[3];
    const tmepHideProjectSection: boolean =
      _currentPath === 'research' ||
      _currentPath === 'laboratory' ||
      _currentPath === 'Equip' ||
      _currentPath === 'team' ||
      _currentPath === 'Setting' ||
      _currentPath === 'Ticketing' ||
      _currentPath === 'Member' ||
      _currentPath === 'LearningCenter' ||
      _currentPath === 'home';

    setHideProjectSection(tmepHideProjectSection);
    // Log the state data to the console
    isUrlValid();
  }, [props.location]);

  const isUrlValid = () => {
    const _userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
    if (!_userInfo) return;

    const _currentCompanyNameInUrl = props.location.pathname.split('/')[1];
    if (_currentCompanyNameInUrl !== _userInfo.institution) {
      return props.history.push($ReplaceRoute(AppRoutes.dashboard));
    }
  };

  useEffect(() => {
    // const { location } = props;
    // const _currentPath = location.pathname.split("/")[3];
    // console.log(location.pathname.split("/"));
    // const tmepHideProjectSection: boolean =
    //   _currentPath === "research" ||
    //   _currentPath === "laboratory" ||
    //   _currentPath === "Equip" ||
    //   _currentPath === "team" ||
    //   _currentPath === "Setting" ||
    //   _currentPath === "Ticketing" ||
    //   _currentPath === "Member";
    // if (store.getState().ResearchId.value >= 0 && !tmepHideProjectSection) {
    // }
    // console.log('rendered')
    // timelineProgress(props.lastSelectedResearchId || 0);

    if (RoleUser === UserRoles.Admin) {
      setGettingResearchLoader(false);
      setTimelineLoading(false);
    } else {
      GetResearch(PageNumber, PageSize, true);
    }

    // console.log(store.getState(), _currentPath, tmepHideProjectSection);
    // if (
    //   !store.getState().ResearchUpdated &&
    //   // (_currentPath === "" || !_currentPath) &&
    //   !tmepHideProjectSection
    // ) {
    // }
  }, [props.lastSelectedResearchId]);

  const isLinkActive = (_url: string) => {
    let _currentMainLocation = null;
    if (RoleUser === UserRoles.Admin) {
      _currentMainLocation = props.location.pathname.split('/')[4];
    } else {
      _currentMainLocation = props.location.pathname.split('/')[3];
    }

    return _currentMainLocation?.toLocaleLowerCase() === _url.toLocaleLowerCase();
  };

  const headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    setColumnHeaderName(columnHeaderName);
    setSortDirection(sortDirection ?? SortType.AutoSelect);

    GetResearch(PageNumber, PageSize, false, columnHeaderName, sortDirection, filterOptions);
  };

  const filterClick = (
    selectedFromDate?: Date,
    selectedToDate?: Date,
    status?: number[],
    teamType?: number[]
  ) => {
    var filterOptions = {
      selectedFromDate: selectedFromDate,
      selectedToDate: selectedToDate,
      status: status,
      teamType: teamType,
    };
    setFilterOptions(filterOptions);
    GetResearch(PageNumber, PageSize, false, columnHeaderName, sortDirection, filterOptions);
  };

  const handleToggle = () => {
    setShowFilterOptions(!showFilterOptions);
  };

  const toggleSidebar = () => {
    dispatch(SetSidebarCollapse(!isCollapsed));
  };

  const hideSideMenuIfSmallScreen = () => {
    const sidebarMenu = document.getElementById('sidebar');
    const element = document.querySelector('body');
    if (window.innerWidth <= 768 && firstLoad) {
      // sidebarMenu?.classList.remove("expand");
      // element?.classList.remove("sidebar-opened");
      toggleSidebar();
    }
    setfirstLoad(false);
  };

  return (
    <Fragment>
      <div className='topHeader fixed z-1'>
        <button
          type='button'
          className='toggleCollapsefa mobile-sidebar-toggle d-md-none fs-22'
          onClick={toggleSidebar}
        >
          {!isCollapsed ? (
            <img src='/Images/icons/cross-2.svg' />
          ) : (
            <img src='/Images/icons/hamburger-menu.svg' />
          )}
        </button>

        <div className='align-items-center'>
          <span className='fs-15 fw-bold mb-0 d-md-block d-none'>{headerTitle}</span>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='d-none d-lg-block'>
            {isTrial && RoleUser !== UserRoles.Admin && (
              <div className='d-flex align-items-center h-px-64 border-end border-gray-400 px-4 pt-px-2'>
                <div className='trial-info p-2'>
                  <span className='trial-text'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='15'
                      height='15'
                      viewBox='0 0 15 15'
                      fill='none'
                    >
                      <rect width='15' height='15' fill='white' fill-opacity='0.01' />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M7.5001 0.849976C7.22395 0.849976 7.0001 1.07383 7.0001 1.34998V3.52234C7.0001 3.79848 7.22395 4.02234 7.5001 4.02234C7.77624 4.02234 8.0001 3.79848 8.0001 3.52234V1.8718C10.8863 2.12488 13.1501 4.54806 13.1501 7.49998C13.1501 10.6204 10.6205 13.15 7.5001 13.15C4.37969 13.15 1.8501 10.6204 1.8501 7.49998C1.8501 6.10612 2.35419 4.83128 3.19061 3.8459C3.36931 3.63538 3.34351 3.31985 3.13298 3.14115C2.92246 2.96245 2.60693 2.98825 2.42823 3.19877C1.44417 4.35808 0.850098 5.86029 0.850098 7.49998C0.850098 11.1727 3.8274 14.15 7.5001 14.15C11.1728 14.15 14.1501 11.1727 14.1501 7.49998C14.1501 3.82728 11.1728 0.849976 7.5001 0.849976ZM6.74061 8.08072L4.22375 4.57237C4.15243 4.47295 4.16358 4.33652 4.2501 4.25C4.33661 4.16348 4.47305 4.15233 4.57246 4.22365L8.08081 6.74051C8.56239 7.08599 8.61918 7.78091 8.2001 8.2C7.78101 8.61909 7.08609 8.56229 6.74061 8.08072Z'
                        fill='#473B1F'
                      />
                    </svg>
                    {trialDays} Days Left in Trial
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* {hideProjectSection || RoleUser === UserRoles.Admin ? null : (
            <div className="d-flex align-items-center justify-content-end mx-3 layout-width">
              <h6 className="mb-0 me-2 Selected-Research fw-light dNoneMd whiteSpaceNoWrap textSecondary">
                Active Project:
              </h6>
              {gettingResearchLoader ? (
                <Skeleton
                  className="dNoneSm"
                  width="24em"
                  style={{ maxWidth: "100%" }}
                  height="1.5em"
                  borderRadius="50px"
                />
              ) : (
                <button
                  type="button"
                  className="select_header_research dNoneLg"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <span className="text-start text-truncate">
                    {" "}
                    {ResearchName}
                  </span>

                  <div className="d-flex justify-content-center align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <rect width="14" height="14" rx="7" fill="#4E0057" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.07988 6.07988C4.18639 5.97337 4.35907 5.97337 4.46557 6.07988L7 8.61431L9.53443 6.07988C9.64093 5.97337 9.81361 5.97337 9.92012 6.07988C10.0266 6.18639 10.0266 6.35907 9.92012 6.46557L7.19285 9.19285C7.08634 9.29935 6.91366 9.29935 6.80715 9.19285L4.07988 6.46557C3.97337 6.35907 3.97337 6.18639 4.07988 6.07988Z"
                        fill="white"
                        stroke="white"
                        stroke-width="0.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </button>
              )}
            </div>
          )} */}
          {/* {props.location.pathname.search("/Admin") >= 0 ? null : (
            <div className="d-flex">
              <div className="d-flex justify-content-start align-items-center"></div>

              {hideProjectSection ||
              RoleUser === UserRoles.Admin ||
              ResearchName === "Choose your research" ? null : (
                <>
                  <div className="dNoneMd d-flex justify-content-center align-items-center">
                    <h6 className="mb-0 mx-2 Selected-Research fw-light">
                      Timeline
                    </h6>
                    {timelineLoading ? (
                      <Skeleton
                        width="7em"
                        style={{ maxWidth: "100%" }}
                        height="1.5em"
                        borderRadius="50px"
                      />
                    ) : (
                      <div className="d-flex align-items-center">
                        <div
                          className="overflow-hidden"
                          style={{ borderRadius: 50 }}
                        >
                          <ProgressBar
                            width="12em"
                            completed={
                              ProgressTimeline.percentage >= 5
                                ? ProgressTimeline.percentage
                                : 5
                            }
                            isLabelVisible={false}
                            customLabel={
                              ProgressTimeline.status !==
                              ResearchStatus.OnGoing.isStatus()
                                ? ProgressTimeline.status
                                : undefined
                            }
                            // customLabel={
                            //   ProgressTimeline.status !==
                            //   ResearchStatus.OnGoing.isStatus()
                            //     ? ProgressTimeline.status
                            //     : undefined
                            // }

                            className="w-100 overflow-hidden d-flex align-items-center"
                            bgColor={
                              ProgressTimeline.status !==
                              ResearchStatus.OnGoing.isStatus()
                                ? ProgressTimeline.color
                                : "#474747"
                            }
                            labelAlignment={
                              ProgressTimeline.status !==
                              ResearchStatus.OnGoing.isStatus()
                                ? "center"
                                : "outside"
                            }
                            labelColor={
                              ProgressTimeline.status !==
                              ResearchStatus.OnGoing.isStatus()
                                ? "#FFF"
                                : "#474747"
                            }
                            // barContainerClassName="overflow-hidden"
                          />
                        </div>
                        <span
                          className="font-bold  ms-2"
                          style={{
                            color: "rgb(71, 71, 71)",
                            fontSize: "13px",
                          }}
                        >
                          {ProgressTimeline.percentage}%
                        </span>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          )} */}

          <div className='d-flex align-items-center ps-sm-4  h-px-67 gap-4'>
            {RoleUser !== UserRoles.Admin ? (
              <div
                className='top-header-button-layout h-px-25 p-0 px-2 d-flex align-items-center justify-content-center'
                style={{ margin: '0px' }}
              >
                <BellIcon
                  // onClick={toggleHelpPanel}
                  className='AccordionTriggerIcon'
                ></BellIcon>

                <div className='headerIconRedTextWrapper'>
                  <span className='headerIconRedText'>{notificationCount}</span>
                </div>
              </div>
            ) : null}
            <div className='d-none d-xl-flex header-button-height'>{headerButtons()}</div>
            <HelpPanel isOpen={helpPanelVisible} onClose={toggleHelpPanel} />

            <div className='sidebar-footer dropdown p-1 border-gray-400 d-flex gap-2'>
              {/* <div className='d-flex align-items-center justify-content-between dropdown dropdown-toggle caret-none w-sm-rem-9 gap-2'>
                {!local.getUserInfo().image ? (
                  <>
                    <div
                      className='avatar rounded-circle bg-primary pointer'
                      style={{ height: '35px', width: '35px' }}
                      onClick={() => {
                        props.history.push(
                          $ReplaceRoute(
                            `${AppRoutes.member_profile.replace(':id', local.getUserId())}`
                          )
                        );
                      }}
                    >
                      <label
                        className='fw-medium text-white fs-10 pointer'
                        onClick={() => {
                          props.history.push(
                            $ReplaceRoute(
                              `${AppRoutes.member_profile.replace(':id', local.getUserId())}`
                            )
                          );
                        }}
                      >
                        {getUserInitails(
                          `${local.getUserInfo().firstName} ${local.getUserInfo().lastName}`
                        )}
                      </label>
                    </div>
                  </>
                ) : (
                  <ImageWithToken
                    originalImage={
                      local.getUserId() && local.getUserInfo().image
                        ? local.getUserInfo().image?.name
                        : ''
                    }
                    onClick={() => {
                      props.history.push(
                        $ReplaceRoute(
                          `${AppRoutes.member_profile.replace(':id', local.getUserId())}`
                        )
                      );
                    }}
                    src={
                      local.getUserId() && local.getUserInfo().image
                        ? local.getUserInfo().image.name
                          ? IMAGE_BASE_URL + local.getUserInfo().image.name
                          : '/Images/images/img_avatar.png'
                        : '/Images/images/img_avatar.png'
                    }
                    alt='Avatar'
                    className='rounded-circle avatar pointer'
                    hasImage={local.getUserId() && local.getUserInfo().image ? true : false}
                    style={{
                      height: 42,
                      width: 42,
                    }}
                  />
                )}

                <div
                  className='flex-column align-items-baseline d-none d-sm-flex pointer'
                  onClick={() => {
                    props.history.push(
                      $ReplaceRoute(`${AppRoutes.member_profile.replace(':id', local.getUserId())}`)
                    );
                  }}
                >
                  <span className='fs-15 fw-semibold color-gray-1000'>
                    Hi, {local.getUserInfo().firstName}!
                  </span>
                  {gettingResearchLoader ? (
                    <Skeleton containerClassName='lineHeight1' height='1.25em' width='5em' />
                  ) : (
                    <span
                      className='fs-13 color-gray-500 width-6 text-truncate color-gray-700'
                      style={{ maxWidth: '100%' }}
                      // type={MainButtonType.dark}
                      // borderRadius="24px"
                      // onClick={() => {
                      //   if (RoleUser.isRole() === "Admin") {
                      //     props.history.push(AppRoutes.admin_dashboard);
                      //   }
                      // }}
                    >
                      {local.getUserInfo().role === UserRoles.Admin
                        ? 'Radvix Admin'
                        : local.getUserInfo().role === UserRoles.ExternalUser
                        ? 'Guest Collaborator'
                        : local.getUserInfo().userTypeName}
                    </span>
                  )}
                </div>
              </div> */}
              <div className='flex-shrink-0 flex-column align-items-baseline d-sm-flex pointer'>
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger>
                    <div className='d-flex align-items-center gap-2'>
                      <div className='d-flex align-items-center justify-content-between dropdown dropdown-toggle caret-none w-sm-rem-9 gap-2'>
                        {!local.getUserInfo().image ? (
                          <>
                            <div
                              className='avatar rounded-circle bg-primary pointer'
                              style={{ height: '35px', width: '35px' }}
                            >
                              <label className='fw-medium text-white fs-10 pointer'>
                                {getUserInitails(
                                  `${local.getUserInfo().firstName} ${local.getUserInfo().lastName}`
                                )}
                              </label>
                            </div>
                          </>
                        ) : (
                          <ImageWithToken
                            originalImage={
                              local.getUserId() && local.getUserInfo().image
                                ? local.getUserInfo().image?.name
                                : ''
                            }
                            src={
                              local.getUserId() && local.getUserInfo().image
                                ? local.getUserInfo().image.name
                                  ? IMAGE_BASE_URL + local.getUserInfo().image.name
                                  : '/Images/images/img_avatar.png'
                                : '/Images/images/img_avatar.png'
                            }
                            alt='Avatar'
                            className='rounded-circle avatar pointer'
                            hasImage={local.getUserId() && local.getUserInfo().image ? true : false}
                            style={{
                              height: 42,
                              width: 42,
                            }}
                          />
                        )}

                        <div className='flex-column align-items-baseline d-none d-sm-flex pointer'>
                          <span className='fs-15 fw-semibold color-gray-1000'>
                            Hi, {local.getUserInfo().firstName}!
                          </span>
                          {gettingResearchLoader ? (
                            <Skeleton
                              containerClassName='lineHeight1'
                              height='1.25em'
                              width='5em'
                            />
                          ) : (
                            <span
                              className='fs-13 color-gray-500 width-6 text-truncate color-gray-700'
                              style={{ maxWidth: '100%' }}
                            >
                              {local.getUserInfo().role === UserRoles.Admin
                                ? 'Radvix Admin'
                                : local.getUserInfo().role === UserRoles.ExternalUser
                                ? 'Guest Collaborator'
                                : local.getUserInfo().userTypeName}
                            </span>
                          )}
                        </div>
                      </div>
                      <DropdownMenu.TriggerIcon style={{ width: '100%', height: '100%' }} />
                    </div>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Content
                    align='end'
                    className='width-100-small mt-md-1 rounded'
                    style={{ minWidth: '210px', marginRight: '-4px' }}
                  >
                    <div className='d-lg-none'>
                      {isTrial && RoleUser !== UserRoles.Admin && (
                        <div className='d-flex align-items-center border-bottom border-gray-400 pt-2 pb-3 px-px-12'>
                          <div className='trial-info p-2'>
                            <span className='trial-text'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='15'
                                height='15'
                                viewBox='0 0 15 15'
                                fill='none'
                              >
                                <rect width='15' height='15' fill='white' fill-opacity='0.01' />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M7.5001 0.849976C7.22395 0.849976 7.0001 1.07383 7.0001 1.34998V3.52234C7.0001 3.79848 7.22395 4.02234 7.5001 4.02234C7.77624 4.02234 8.0001 3.79848 8.0001 3.52234V1.8718C10.8863 2.12488 13.1501 4.54806 13.1501 7.49998C13.1501 10.6204 10.6205 13.15 7.5001 13.15C4.37969 13.15 1.8501 10.6204 1.8501 7.49998C1.8501 6.10612 2.35419 4.83128 3.19061 3.8459C3.36931 3.63538 3.34351 3.31985 3.13298 3.14115C2.92246 2.96245 2.60693 2.98825 2.42823 3.19877C1.44417 4.35808 0.850098 5.86029 0.850098 7.49998C0.850098 11.1727 3.8274 14.15 7.5001 14.15C11.1728 14.15 14.1501 11.1727 14.1501 7.49998C14.1501 3.82728 11.1728 0.849976 7.5001 0.849976ZM6.74061 8.08072L4.22375 4.57237C4.15243 4.47295 4.16358 4.33652 4.2501 4.25C4.33661 4.16348 4.47305 4.15233 4.57246 4.22365L8.08081 6.74051C8.56239 7.08599 8.61918 7.78091 8.2001 8.2C7.78101 8.61909 7.08609 8.56229 6.74061 8.08072Z'
                                  fill='#473B1F'
                                />
                              </svg>
                              {trialDays} Days Left in Trial
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='d-flex align-items-center px-px-12 h-px-67  gap-3 border-bottom border-gray-400 d-xl-none mb-1'>
                      <div
                        className='d-flex h-px-25 w-px-25 p-0 align-items-center justify-content-center bg-gray-200 rounded-px-2
                    d-sm-flex  top-header-button-layout mt-0'
                      >
                        <img
                          src='/images/images/microsoft-logo.svg'
                          height='15px'
                          width='15px'
                          //onClick={toggleMicrosoftConnet}
                        />
                      </div>
                      {showMicrosoftBox ? (
                        <div className='OpenMicrosoftConnect '>
                          <ConnectMicrosoft
                            // selectedProfileType={this.state.notificationCategory}
                            filterEmail={true}
                          />
                        </div>
                      ) : null}
                      <div
                        className='d-flex top-header-button-layout h-px-25 w-px-25 p-0 align-items-center justify-content-center
                    d-sm-flex mt-0'
                      >
                        <img src='/images/images/slack-logo.svg' height='15px' width='15px' />
                      </div>
                      <div className=''>
                        <Separator
                          orientation='vertical'
                          size={'2'}
                          style={{ alignSelf: 'center' }}
                        />
                      </div>
                      <div className='top-header-button-layout h-px-25 w-px-25 p-0 px-2 d-flex align-items-center justify-content-center mt-0'>
                        <SunIcon
                          // onClick={toggleHelpPanel}
                          className='AccordionTriggerIcon '
                        ></SunIcon>
                      </div>
                      <MainButton
                        className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-25 py-0'
                        style={{}}
                        onClick={toggleHelpPanel}
                      >
                        Need Help?
                      </MainButton>
                    </div>

                    {/* <DropdownMenu.Item  className="">
                      <div className="onlyShowInSmallScreen">
                        {headerButtons()}
                      </div>
                    </DropdownMenu.Item> */}
                    <div style={{ padding: '3px 0' }}>
                      <DropdownMenu.Item className='mb-1'>
                        <div className='dashboardDropdownMenuItem'>
                          <AvatarIcon className='dashboardDropdownMenuItemLogo'></AvatarIcon>
                          <div
                            className='dashboardDropdownMenuItemTextDiv'
                            onClick={() => {
                              props.history.push(
                                $ReplaceRoute(
                                  `${AppRoutes.member_profile.replace(':id', local.getUserId())}`
                                )
                              );
                              dispatch(SetHeaderTitle('Account'));
                            }}
                          >
                            <span className='dashboardDropdownMenuItemText'>My Account</span>
                          </div>
                        </div>
                      </DropdownMenu.Item>
                      <DropdownMenu.Item>
                        <div
                          className='dashboardDropdownMenuItem'
                          onClick={() => {
                            logout();
                          }}
                        >
                          <EnterIcon className='dashboardDropdownMenuItemLogo'></EnterIcon>
                          <div className='dashboardDropdownMenuItemTextDiv'>
                            <span className='dashboardDropdownMenuItemText'>Logout</span>
                          </div>
                        </div>
                      </DropdownMenu.Item>
                    </div>
                    {/* <DropdownMenu.Separator />
        <DropdownMenu.Item shortcut="⌘ N">Archive</DropdownMenu.Item> */}
                  </DropdownMenu.Content>
                </DropdownMenu.Root>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {hideProjectSection || RoleUser === UserRoles.Admin ? null : (
        <div className="header justify-content-center dNone dFlexLg secondaryHeader">
          {gettingResearchLoader ? (
            <Skeleton
              width="24em"
              style={{ maxWidth: "100%" }}
              height="1.5em"
              // borderRadius="50px"
            />
          ) : (
            <div
              className="phoneResearchBar"
              style={{
                borderTop: "1px solid #e1e1e1",
                backgroundColor: "#f5f5f5",
                borderBottom: "1px solid #e1e1e1",
                // background-color: rgb(245, 245, 245);
                padding: "0.5em",
              }}
            >
              <button
                type="button"
                className="select_header_research"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                style={{
                  borderRadius: 0,
                  width: "100%",
                }}
              >
                <span className="text-start text-truncate">
                  {" "}
                  {ResearchName}
                </span>

                <div className="d-flex justify-content-center align-items-center">
                  <IoIosArrowDropdownCircle size="1em" color="#959595" />
                </div>
              </button>
            </div>
          )}
        </div>
      )} */}

      <div className='modal fade' id='exampleModal' tabIndex={-1}>
        <div className='modal-dialog'>
          <div className='col-12 modal-content'>
            <button
              type='button'
              className='btn-close dNoneSm'
              data-bs-dismiss='modal'
              aria-label='Close'
              id='close_modal'
            ></button>
            <div className='TableBox modalPaper'>
              <div className='relative TopTableBox d-flex justify-content-between align-items-center mb-0 mb-sm-3'>
                {/* <div className="d-flex align-items-baseline"> */}
                <h6 className='headerAlign' style={{ width: '35%' }}>
                  Project List
                </h6>
                <div className='d-flex align-items-center'>
                  <PhoneSearch
                    phoneSearchInputStyle={{
                      right: '5em',
                    }}
                    value={Search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      GetResearch(PageNumber, PageSize);
                    }}
                    searchIcon={
                      Search.length > 0 ? (
                        <i
                          className='fa fa-times pointer p-1'
                          aria-hidden='true'
                          onClick={() => {
                            setSearch('');
                            GetResearch(PageNumber, PageSize);
                          }}
                        ></i>
                      ) : (
                        <RxMagnifyingGlass size='1.25em' className='textSecondary' />
                      )
                    }
                  />
                  <button
                    type='button'
                    className='btn-close dNone dFlexSm ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    id='close_modal'
                  ></button>
                </div>

                {/* </div> */}
                <div className='d-flex justify-content-end align-items-center dNoneSm'>
                  <div>
                    <CircleIcon
                      width='28px'
                      height='28px'
                      padding='0'
                      float='right'
                      type={ThemeCircleIcon.dark}
                      onClick={handleToggle}
                      className='pointer mx-1'
                    >
                      <img
                        src='/images/icons/filterNew(2).svg'
                        alt='radvix'
                        width={15}
                        height={15}
                      />
                    </CircleIcon>
                  </div>
                  <InputIcon
                    chilren={
                      Search.length > 0 ? (
                        <i
                          className='fa fa-times pointer p-1'
                          aria-hidden='true'
                          onClick={() => {
                            setSearch('');
                            GetResearch(PageNumber, PageSize);
                          }}
                        ></i>
                      ) : (
                        <RxMagnifyingGlass size='1.25em' className='textSecondary' />
                      )
                    }
                    width='100%'
                    placeholder='Search...'
                    TopPosition='15%'
                    value={Search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      GetResearch(PageNumber, PageSize);
                    }}
                    parentClassName='me-2'
                  ></InputIcon>
                  <SelectComponent
                    selectProps={{ isSearchable: false }}
                    width='90px'
                    height='44px'
                    items={[
                      { label: '10', value: 10 },
                      { label: '15', value: 15 },
                      { label: '20', value: 20 },
                    ]}
                    TextItem='item'
                    ValueItem='id'
                    isMulti={false}
                    placeholder={PageSize.toString()}
                    onChange={(e) => {
                      handelChangePageSize(e);
                    }}
                  ></SelectComponent>
                </div>
              </div>
              {showFilterOptions && (
                <div style={{ backgroundColor: 'white', borderRadius: 10 }}>
                  <div className='d-flex justify-content-left align-items-center col-12'>
                    <div id='clientsDiv' className='col-12'>
                      <FilterSection
                        pageType={ProfileTypes.RESEARCHSELECTOR}
                        className='col-9'
                        dateLabel='Deadline'
                        status={[
                          {
                            Id: ResearchStatus.Completed,
                            isStatusSelected: false,
                          },
                          {
                            Id: ResearchStatus.Delayed,
                            isStatusSelected: false,
                          },
                          {
                            Id: ResearchStatus.OnGoing,
                            isStatusSelected: false,
                          },
                          {
                            Id: ResearchStatus.OnHold,
                            isStatusSelected: false,
                          },
                        ]}
                        onselect={(selectedFromDate, selectedToDate, status, teamType) =>
                          filterClick(
                            selectedFromDate ? selectedFromDate : undefined,
                            selectedToDate ? selectedToDate : undefined,
                            status,
                            teamType
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {listResearch.length > 0 ? (
                <AcordienTableResearchHeader
                  Items={listResearch}
                  Heading={[
                    { name: 'Project Name', center: false, accessor: 'title' },
                    {
                      name: 'Status',
                      center: true,
                      className: 'dNoneSm',
                      accessor: 'status',
                    },
                  ]}
                  changeResearch={(_id, _title) => {
                    changeResearch(_id, _title);
                    document.getElementById('close_modal')?.click();
                  }}
                  role={RoleUser}
                  onHeaderClick={(columnHeaderName, sortDirection) =>
                    headerClick(columnHeaderName, sortDirection)
                  }
                ></AcordienTableResearchHeader>
              ) : (
                <EmptyState
                  backgroundColor='#fff'
                  text='No Project List'
                  fontSize='15px'
                  color='#474747'
                  minHeight='100px'
                />
              )}

              <div className='d-flex justify-content-between align-items-baseline px-3 px-0-sm mt-1-sm mt-3'>
                <div className='dFlexSm dNone'>
                  <SelectComponent
                    width='90px'
                    height='44px'
                    items={[
                      { label: '10', value: 10 },
                      { label: '15', value: 15 },
                      { label: '20', value: 20 },
                    ]}
                    TextItem='item'
                    ValueItem='id'
                    isMulti={false}
                    placeholder={PageSize.toString()}
                    onChange={(e) => {
                      handelChangePageSize(e);
                    }}
                    selectProps={{ isSearchable: false }}
                  ></SelectComponent>
                </div>
                {TotalCount > PageSize ? (
                  <div className='d-flex justify-content-end flex-fill'>
                    <ReactPaginate
                      previousLabel={<IoIosArrowDropleftCircle size='2.5em' color='#4E0057' />}
                      nextLabel={<IoIosArrowDroprightCircle size='2.5em' color='#4E0057' />}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={PageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={(e) => {
                        handelChangePageNumber(e);
                      }}
                      containerClassName={'pagination'}
                      activeClassName={'active'}
                    />
                  </div>
                ) : (
                  ''
                )}
                {listResearch.length > 0 ? (
                  <div className='d-flex justify-content-end flex-fill'>
                    <p className='text-right mb-0 '>Total Results: {TotalCount}</p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default withRouter(Header);

const QuoteSectionForAdmin = () => {
  // !!add ahmadzogi => Quote
  // const Quote = import("inspirational-quotes").then((Quote) => Quote);
  // const _quote = Quote.getRandomQuote({
  //   author: true,
  // });
  const _quote = Quote.getQuote({
    author: true,
  });
  return (
    <div
      className='flex flex-column ms-4'
      style={{
        overflowX: 'hidden',
      }}
    >
      <p
        className='max1LineText textSecondary mb-0'
        title={_quote.text}
        style={{ lineHeight: '1' }}
      >
        {/* create a quote icon with unicode */}

        <RiDoubleQuotesL className='me-1' />
        {_quote.text}
        <RiDoubleQuotesR className='ms-1' />
      </p>
      <span className='max1LineText textSecondary fs-100' style={{ opacity: '0.5' }}>
        {_quote.author}
      </span>
    </div>
  );
};
