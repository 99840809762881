import { HEADER_TITLE } from './actions_type';

export type HeaderTitleAction = {
  type: string;
  payload: string;
};
export function SetHeaderTitle(headerTitle: string): HeaderTitleAction {
  return {
    type: HEADER_TITLE,
    payload: headerTitle,
  };
}
