import React from 'react';
import { store } from '../../../data/storeMain';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
import 'react-datepicker/dist/react-datepicker.css';
import { MainButton, MainButtonType } from '../../components/button';
import { IconTextRow } from '../../components/icon_text_horizontal';
import { ProfileTypes, SortType } from '../../../core/number_extentions';
import {
  $ReplaceRoute,
  $truncate,
  DiscussionTopic,
  // AccessPermition,
  // downloadFile,
  getUserTitle,
  isTokenInHttpAuth,
  // removeDuplicateObj,
  removeDuplicateObjWithKey,
  Theme,
  // UserRoles,
} from '../../../core/utils';
import {
  BoxListScroll,
  // ImageWithToken,
} from '../../components/box_list_scroll';
import { withRouter, RouteComponentProps } from 'react-router';
import {
  // AppConstants,
  AppRoutes,
  LABORATORY_REMOVAL_MANAGER_ANSWER_STATUS,
  LABORATORY_REMOVAL_MANAGER_STATUS,
} from '../../../core/constants';
import { LaboratoryController } from '../../../controllers/laboratory/laboratory_controller';
import { GetLaboratoryByIDResult } from '../../../data/models/responses/laboratory/laboratory_by_id_res';
import { HTTP } from '../../../core/http_common';
// import { Link } from "react-router-dom";
import Visible from '../../components/visible';
import Skeleton from 'react-loading-skeleton';
import EmptyChip from '../../components/EmptyChip';
import { FaEdit, FaMicroscope, FaUsers } from 'react-icons/fa';
import { MdClose, MdDeleteOutline } from 'react-icons/md';
import ConfirmationContent from '../../components/ConfirmationContent';
import File from '../../components/File';
import { nanoid } from 'nanoid';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import UsersWithImage from './component/usersWithImage';
import NewTable from '../../components/NewTable/NewTable';
import UserWithAvatar from '../../components/UserWithAvatar';
import { GiTalk } from 'react-icons/gi';
import { BsFillChatLeftDotsFill } from 'react-icons/bs';
import ProfileTitleDesc from '../../components/ProfileTitleDesc';
import Tippy from '@tippyjs/react';
import { BaseButton } from '@fluentui/react';
import {
  TrashIcon,
  RulerSquareIcon,
  FileTextIcon,
  AvatarIcon,
  IdCardIcon,
  SewingPinIcon,
} from '@radix-ui/react-icons';
import { getUserInitails } from '../../../core/utils';
import RadixTable from '../../components/NewRadixTable/RadixTable';
import { Table, Tooltip } from '@radix-ui/themes';
import { IMAGE_BASE_URL } from '../../../core/http_common';
import { ImageWithToken } from '../../components/box_list_scroll';
import { RESERVATION_STATUS_STYLES, RESERVATION_STATUS_LABELS } from '../../../core/constants';
import CircleIconTooltip from '../../components/CircleIconTooltip';

interface RouteParams {
  id: string;
}
class LaboratoryPageProfile extends React.Component<RouteComponentProps<RouteParams>> {
  RoleUser = store.getState().userRole;
  controller = new LaboratoryController();
  state: GetLaboratoryByIDResult = {
    creatorUser: null,
    title: '',
    id: 0,
    categoryName: '',
    categoryId: 0,
    description: '',
    labManagers: [],
    haveDiscussion: false,
    webSite: '',
    addressLine1: '',
    addressLine2: '',
    zipCode: '',
    company: '',
    phone: '',
    countryId: 0,
    media: [],
    equipments: [],
    teams: [],
    members: [],
    province: '',
    city: '',
    countryName: '',
    allowedToEdit: false,
    loading: true,
    requestedRemoval: null,
    laboratoryManagerRemovalRequests: [],
    newEmailMenuVisible: false,
    selectedTab: 0,
    visibleTippy: false,
  };
  componentDidMount() {
    if (!isTokenInHttpAuth(HTTP)) return;
    this.setState({
      loading: true,
    });
    this.getLaboratoryDetail();
  }
  bytesToMB(bytes: number) {
    return (bytes / (1024 * 1024)).toFixed(3);
  }
  downloadFile(props: any) {
    if (props.externalUrl) {
      const aElement = document.createElement('a');
      aElement.href = props.externalUrl;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(props.externalUrl);
    } else if (props?.name) {
      const aElement = document.createElement('a');
      aElement.href = props.name;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(props.name);
    }
  }
  getLaboratoryDetail() {
    this.controller.getLaboratoryById(
      {
        id: parseInt(this.props.match.params.id),
      },
      (res) => {
        document.title = `Laboratory | ${res.title} | Radvix`;

        const _users = res.members.map((user: any) => {
          const userTitleAst = getUserTitle(user);
          return {
            ...user,
            title: userTitleAst,
            secondary: user.invitationStatus === 1 ? 'Pending' : null,
          };
        });

        res.teams.forEach((_team: any) => {
          _team.users.forEach((user: any) => {
            if (_users.findIndex((tmepUser) => tmepUser.id === user.id) === -1) {
              _users.push({
                ...user,
                title: getUserTitle(user),
                secondary: user.invitationStatus === 1 ? 'Pending' : null,
              });
            }
          });
        });

        // const _members = res.members.map((member: any) => {
        //   const memberTitleAst = getUserTitle(member);
        //   return {
        //     ...member,
        //     title: memberTitleAst,
        //     secondary: member.invitationStatus === 1 ? "Pending" : null,
        //   };
        // });
        const _equipments = removeDuplicateObjWithKey(res.equipments, 'id');
        this.setState({
          title: res.title,
          id: res.id,
          categoryName: res.categoryName,
          labManagers: res.labManagers,
          creatorUser: res.creatorUser,
          webSite: res.webSite,
          media: res.media,
          equipments: _equipments,
          teams: res.teams,
          members: _users,
          addressLine1: res.addressLine1,
          addressLine2: res.addressLine2,
          description: res.description,
          city: res.city,
          province: res.province,
          company: res.company,
          phone: res.phone,
          zipCode: res.zipCode,
          countryName: res.countryName,
          allowedToEdit: res.allowedToEdit,
          haveDiscussion: res.haveDiscussion,
          allowedToView: res.allowedToView,
          loading: false,
          requestedRemoval: res.requestedRemoval,

          laboratoryManagerRemovalRequests: res.laboratoryManagerRemovalRequests,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });

        if (err.response?.data?.status === 403) {
          window.history.back();
        }
      }
    );
  }

  handleAnswerRequestedRemoval(_status: number) {
    if (!this.state.requestedRemoval) return;
    this.controller.answerRemovalFromLab(
      {
        laboratoryId: this.state.id,
        laboratoryManagerRequestId: this.state.requestedRemoval?.laboratoryManagerRequestId,
        removalAnswerStatus: _status,
        requesterManagerId: this.state.requestedRemoval?.requestedManager.id,
      },
      (_res) => {
        this.getLaboratoryDetail();
      },
      (_err) => {}
    );
  }

  handleChangeRequestedRemovalStatus(_labManagerRequestId: number, _status: number) {
    const _userId = localStorage.getItem('userId');
    if (!_userId) return;
    this.controller.answerRemovalFromLab(
      {
        laboratoryId: this.state.id,
        laboratoryManagerRequestId: _labManagerRequestId,
        status: _status,
        requesterManagerId: _userId,
      },
      (_res) => {
        this.getLaboratoryDetail();
      },
      (_err) => {}
    );
  }
  handleToggleTippy = () => {
    if (this.state.visibleTippy == false) {
      this.setState({ visibleTippy: true });
    } else {
      this.setState({ visibleTippy: false });
    }
  };
  headerClick = (columnHeaderName?: string, sortDirection?: string) => {};
  render() {
    const _files = this.state.media.filter((item) => item.externalUrl === null);
    const _links = this.state.media.filter((item) => item.externalUrl);
    if (this.state.requestedRemoval) {
      return (
        <ChooseRemovalRequest
          detail={this.state}
          onAccept={() => this.handleAnswerRequestedRemoval(1)}
          onReject={() => this.handleAnswerRequestedRemoval(2)}
        />
      );
    }

    return (
      <>
        <div className='profileCard'>
          <div className='d-flex justify-content-between align-items-center p-3 row-gap-px-12 flex-wrap column-gap-4 position-relative'>
            <div className='d-flex flex-wrap align-items-center gap-px-12'>
              <BaseButton
                className='btn btn-outline-primary fs-15 py-px-4 px-3 d-sm-block d-none back-button'
                style={{ fontWeight: '400', height: '19px' }}
                onClick={() => {
                  window.history.back();
                }}
              >
                Back
              </BaseButton>
              <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>
                {this.state.loading ? (
                  <Skeleton width='6em' height='1.25em' />
                ) : (
                  <span>{this.state.title}</span>
                )}
              </h2>
            </div>
            {this.state.loading ? (
              <Skeleton width='6em' height='1.25em' />
            ) : (
              <div className='d-flex align-items-cente gap-px-10 relative TopTableBox'>
                <div className='btn-group'>
                  <MainButton
                    svg={
                      <svg
                        className='hover-fill'
                        xmlns='http://www.w3.org/2000/svg'
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                      >
                        <rect width='15' height='15' fill='white' fillOpacity='0.01' />
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M12.5 3L2.5 3.00002C1.67157 3.00002 1 3.6716 1 4.50002V9.50003C1 10.3285 1.67157 11 2.5 11H7.50003C7.63264 11 7.75982 11.0527 7.85358 11.1465L10 13.2929V11.5C10 11.2239 10.2239 11 10.5 11H12.5C13.3284 11 14 10.3285 14 9.50003V4.5C14 3.67157 13.3284 3 12.5 3ZM2.49999 2.00002L12.5 2C13.8807 2 15 3.11929 15 4.5V9.50003C15 10.8807 13.8807 12 12.5 12H11V14.5C11 14.7022 10.8782 14.8845 10.6913 14.9619C10.5045 15.0393 10.2894 14.9965 10.1464 14.8536L7.29292 12H2.5C1.11929 12 0 10.8807 0 9.50003V4.50002C0 3.11931 1.11928 2.00003 2.49999 2.00002Z'
                          fill='#5746AF'
                        />
                      </svg>
                    }
                    children='Chat'
                    type={MainButtonType.dark}
                    borderRadius='24px'
                    fontSize='14px'
                    minHeight='2em'
                    className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 main-button'
                    onClick={() => {
                      this.props.history.push(
                        $ReplaceRoute(
                          `${AppRoutes.discussion}?topicId=${DiscussionTopic.Laboratory}&sectionId=${this.state.id}&title=${this.state.title}`
                        )
                      );
                    }}
                  ></MainButton>
                  <button
                    type='button'
                    className='btn btn-soft-primary btn-sm fs-13 px-2 py-0 dropdown-toggle dropdown-toggle-split'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <span className='visually-hidden'>Toggle Dropdown</span>
                  </button>
                  <ul className='dropdown-menu'>
                    <li>
                      <a
                        className='dropdown-item fs-14'
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push(
                            $ReplaceRoute(
                              `${AppRoutes.discussion}?topicId=${DiscussionTopic.Laboratory}&sectionId=${this.state.id}&title=${this.state.title}`
                            )
                          );
                        }}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='21'
                          viewBox='0 0 20 21'
                          fill='none'
                        >
                          <rect
                            width='20'
                            height='20'
                            transform='translate(0 0.5)'
                            fill='white'
                            fill-opacity='0.01'
                          />
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M16.6667 4.49996L3.33333 4.49999C2.22876 4.49999 1.33333 5.39543 1.33333 6.49999V13.1667C1.33333 14.2713 2.22876 15.1666 3.33333 15.1666H10C10.1769 15.1666 10.3464 15.2369 10.4714 15.362L13.3333 18.2238V15.8333C13.3333 15.4652 13.6319 15.1666 14 15.1666H16.6667C17.7712 15.1666 18.6667 14.2713 18.6667 13.1667V6.49996C18.6667 5.39539 17.7712 4.49996 16.6667 4.49996ZM3.33332 3.16665L16.6667 3.16663C18.5076 3.16663 20 4.65901 20 6.49996V13.1667C20 15.0076 18.5076 16.5 16.6667 16.5H14.6667V19.8333C14.6667 20.1029 14.5043 20.346 14.2551 20.4492C14.006 20.5524 13.7192 20.4953 13.5285 20.3048L9.72389 16.5H3.33333C1.49239 16.5 0 15.0076 0 13.1667V6.49999C0 4.65904 1.49237 3.16667 3.33332 3.16665Z'
                            fill='#1C2024'
                          />
                        </svg>
                        Chat
                      </a>
                    </li>
                    <li>
                      <a
                        className='dropdown-item fs-14'
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push($ReplaceRoute(`${AppRoutes.discussion}`));
                        }}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='21'
                          viewBox='0 0 20 21'
                          fill='none'
                        >
                          <rect
                            width='20'
                            height='20'
                            transform='translate(0 0.5)'
                            fill='white'
                            fill-opacity='0.01'
                          />
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M1.33333 3.16663C0.596953 3.16663 0 3.76359 0 4.49996V16.5C0 17.2364 0.596953 17.8333 1.33333 17.8333H18.6667C19.4031 17.8333 20 17.2364 20 16.5V4.49996C20 3.76359 19.4031 3.16663 18.6667 3.16663H1.33333ZM1.33333 4.49996H18.6667V5.73321C18.5565 5.73311 18.4451 5.76331 18.3452 5.82669L10 11.1227L1.65483 5.82669C1.55495 5.76331 1.44347 5.73311 1.33333 5.73321V4.49996ZM1.33333 7.04392V16.5H18.6667V7.04392L10.3215 12.3399C10.1253 12.4644 9.87475 12.4644 9.67851 12.3399L1.33333 7.04392Z'
                            fill='#1C2024'
                          />
                        </svg>
                        Email
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ pointerEvents: 'none', opacity: '0.55' }}
                        className='dropdown-item fs-14'
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push($ReplaceRoute(`${AppRoutes.discussion}`));
                        }}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='21'
                          viewBox='0 0 20 21'
                          fill='none'
                        >
                          <rect
                            width='20'
                            height='20'
                            transform='translate(0 0.5)'
                            fill='white'
                            fill-opacity='0.01'
                          />
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M6.66675 6.68133C6.66675 5.51373 6.98967 4.64051 7.52439 4.06474C8.05254 3.49605 8.86118 3.13771 10.0019 3.13771C11.1433 3.13771 11.9508 3.49534 12.4777 4.06275C13.0113 4.6373 13.3334 5.50942 13.3334 6.67765V8.49997H6.66675V6.68133ZM5.33342 8.49997V6.68133C5.33342 5.27529 5.7246 4.04337 6.5474 3.15741C7.37678 2.26437 8.56908 1.80438 10.0019 1.80438C11.4345 1.80438 12.6261 2.26309 13.4547 3.15547C14.2769 4.04074 14.6667 5.27193 14.6667 6.67765V8.49997H16.0001C16.7365 8.49997 17.3334 9.09693 17.3334 9.8333V17.8333C17.3334 18.5697 16.7365 19.1666 16.0001 19.1666H4.00008C3.26371 19.1666 2.66675 18.5697 2.66675 17.8333V9.8333C2.66675 9.09693 3.26371 8.49997 4.00008 8.49997H5.33342ZM4.00008 9.8333H16.0001V17.8333H4.00008V9.8333Z'
                            fill='#1C2024'
                          />
                        </svg>
                        Slack
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='btn-group'>
                  <MainButton
                    svg={
                      <svg
                        className='hover-fill'
                        xmlns='http://www.w3.org/2000/svg'
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                      >
                        <rect width='15' height='15' fill='white' fillOpacity='0.01' />
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M12.1464 1.14645C12.3417 0.951183 12.6583 0.951183 12.8535 1.14645L14.8535 3.14645C15.0488 3.34171 15.0488 3.65829 14.8535 3.85355L10.9109 7.79618C10.8349 7.87218 10.7471 7.93543 10.651 7.9835L6.72359 9.94721C6.53109 10.0435 6.29861 10.0057 6.14643 9.85355C5.99425 9.70137 5.95652 9.46889 6.05277 9.27639L8.01648 5.34897C8.06455 5.25283 8.1278 5.16507 8.2038 5.08907L12.1464 1.14645ZM12.5 2.20711L8.91091 5.79618L7.87266 7.87267L8.12731 8.12732L10.2038 7.08907L13.7929 3.5L12.5 2.20711ZM9.99998 2L8.99998 3H4.9C4.47171 3 4.18056 3.00039 3.95552 3.01877C3.73631 3.03668 3.62421 3.06915 3.54601 3.10899C3.35785 3.20487 3.20487 3.35785 3.10899 3.54601C3.06915 3.62421 3.03669 3.73631 3.01878 3.95552C3.00039 4.18056 3 4.47171 3 4.9V11.1C3 11.5283 3.00039 11.8194 3.01878 12.0445C3.03669 12.2637 3.06915 12.3758 3.10899 12.454C3.20487 12.6422 3.35785 12.7951 3.54601 12.891C3.62421 12.9309 3.73631 12.9633 3.95552 12.9812C4.18056 12.9996 4.47171 13 4.9 13H11.1C11.5283 13 11.8194 12.9996 12.0445 12.9812C12.2637 12.9633 12.3758 12.9309 12.454 12.891C12.6422 12.7951 12.7951 12.6422 12.891 12.454C12.9309 12.3758 12.9633 12.2637 12.9812 12.0445C12.9996 11.8194 13 11.5283 13 11.1V6.99998L14 5.99998V11.1V11.1207C14 11.5231 14 11.8553 13.9779 12.1259C13.9549 12.407 13.9057 12.6653 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.6653 13.9057 12.407 13.9549 12.1259 13.9779C11.8553 14 11.5231 14 11.1207 14H11.1H4.9H4.87934C4.47686 14 4.14468 14 3.87409 13.9779C3.59304 13.9549 3.33469 13.9057 3.09202 13.782C2.7157 13.5903 2.40973 13.2843 2.21799 12.908C2.09434 12.6653 2.04506 12.407 2.0221 12.1259C1.99999 11.8553 1.99999 11.5231 2 11.1207V11.1V4.9V4.87935C1.99999 4.47689 1.99999 4.14468 2.0221 3.87409C2.04506 3.59304 2.09434 3.33469 2.21799 3.09202C2.40973 2.71569 2.7157 2.40973 3.09202 2.21799C3.33469 2.09434 3.59304 2.04506 3.87409 2.0221C4.14468 1.99999 4.47685 1.99999 4.87932 2H4.9H9.99998Z'
                          fill='#5746AF'
                        />
                      </svg>
                    }
                    children='Edit Laboratory'
                    type={MainButtonType.dark}
                    borderRadius='24px'
                    fontSize='14px'
                    minHeight='2em'
                    className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 main-button'
                    onClick={() => {
                      this.props.history.push(
                        $ReplaceRoute(
                          `${AppRoutes.edit_laboratory.replace(
                            ':id',
                            this.state.id.toString() ?? ''
                          )}`
                        )
                      );
                    }}
                  ></MainButton>

                  <button
                    type='button'
                    className='btn btn-soft-primary btn-sm fs-13 px-2 py-0 dropdown-toggle dropdown-toggle-split'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <span className='visually-hidden'>Toggle Dropdown</span>
                  </button>
                  <ul className='dropdown-menu'>
                    <li>
                      <a
                        className='dropdown-item py-2 fs-14 d-flex align-items-center gap-2'
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push(
                            $ReplaceRoute(
                              `${AppRoutes.edit_laboratory.replace(
                                ':id',
                                this.state.id.toString() ?? ''
                              )}`
                            )
                          );
                        }}
                      >
                        <img
                          className='w-px-16'
                          src='/Images/icons/pencil-edit-2.svg'
                          alt='Edit Project'
                        />
                        Edit Laboratory
                      </a>
                    </li>
                    <li>
                      <a
                        className='dropdown-item py-2 fs-14 d-flex align-items-center gap-2'
                        href='#'
                        onClick={() => {
                          this.controller.deleteLaboratory(
                            {
                              laboratoryId: this.state.id,
                            },
                            (res) => {
                              this.props.history.push($ReplaceRoute(AppRoutes.laboratory));
                            },
                            (err) => {}
                          );
                        }}
                      >
                        <TrashIcon className='w-px-16'></TrashIcon>
                        Delete Laboratory
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
          <div className='p-3 pt-0'>
            <div className='d-flex flex-wrap align-items-center gap-3'>
              <div className='d-flex flex-wrap align-items-center'>
                <h2 className='fw-normal fs-15 mb-0 color-gray-600 me-2'>Lab Manager:</h2>
                <div className='pe-3 border-gray-400 d-sm-block d-flex gap-3 min-h-px-25'>
                  <div className='d-flex align-items-center text-nowrap fs-15'>
                    {this.state.loading ? (
                      <Skeleton width='6em' height='1.25em' />
                    ) : (
                      <>
                        {this.state.creatorUser?.image === null ||
                        this.state.creatorUser?.image === '' ||
                        this.state.creatorUser?.image === undefined ? (
                          <>
                            <div className='avatar w-px-25 h-px-25 rounded-circle bg-primary'>
                              <label className='fw-medium text-white fs-10'>
                                {getUserInitails(
                                  `${this.state.creatorUser?.firstName} ${this.state.creatorUser?.lastName}`
                                )}
                              </label>
                            </div>
                          </>
                        ) : (
                          <ImageWithToken
                            originalImage={this.state.creatorUser.image}
                            hasImage={this.state.creatorUser.image ? true : false}
                            alt='Avatar'
                            className='rounded-circle avatar rounded-avatar-dashboard'
                            src={
                              this.state.creatorUser.image === null ||
                              this.state.creatorUser.image === '' ||
                              this.state.creatorUser.image === undefined
                                ? '/images/images/img_avatar.png'
                                : this.state.creatorUser.image
                            }
                          />
                        )}
                        <span
                          className='pointer'
                          style={{ paddingRight: '5px' }}
                          onClick={() => {
                            this.props.history.push(
                              $ReplaceRoute(
                                `${AppRoutes.member_profile.replace(
                                  ':id',
                                  this.state.creatorUser.id?.toString() ?? ''
                                )}`
                              )
                            );
                          }}
                        >{`${this.state.creatorUser?.firstName} ${this.state.creatorUser?.lastName}`}</span>
                        {this.state.labManagers.slice(1).length > 0 && (
                          <>
                            <Tippy
                              content={
                                <div className='d-flex flex-column py-2 pb-0 gap-6'>
                                  {this.state.labManagers
                                    .filter((x) => x.id != this.state.creatorUser.id)
                                    .map((manager) => (
                                      <div key={manager.id} className='d-flex align-items-center'>
                                        {manager.image === null ||
                                        manager.image === '' ||
                                        manager.image === undefined ? (
                                          <>
                                            <div className='avatar w-px-25 h-px-25 rounded-circle bg-primary'>
                                              <label className='fw-medium text-white fs-10'>
                                                {getUserInitails(
                                                  `${manager.firstName} ${manager.lastName}`
                                                )}
                                              </label>
                                            </div>
                                          </>
                                        ) : (
                                          <ImageWithToken
                                            originalImage={manager.image}
                                            hasImage={manager.image ? true : false}
                                            alt='Avatar'
                                            className='rounded-circle avatar rounded-avatar-dashboard'
                                            src={
                                              manager.image === null ||
                                              manager.image === '' ||
                                              manager.image === undefined
                                                ? '/images/images/img_avatar.png'
                                                : manager.image
                                            }
                                          />
                                        )}
                                        <span
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            this.props.history.push(
                                              $ReplaceRoute(
                                                `${AppRoutes.member_profile.replace(
                                                  ':id',
                                                  manager.id?.toString() ?? ''
                                                )}`
                                              )
                                            );
                                          }}
                                        >
                                          {' '}
                                          {manager.firstName} {manager.lastName}
                                        </span>
                                        {/* <span>{`${manager.firstName} ${manager.lastName}`}</span> */}
                                      </div>
                                    ))}
                                </div>
                              }
                              visible={this.state.visibleTippy}
                              onClickOutside={() => this.setState({ visibleTippy: false })}
                              interactive={true}
                              placement='bottom'
                            >
                              <span
                                onClick={this.handleToggleTippy}
                                className='radix-multiSelect-number'
                                style={{ cursor: 'pointer' }}
                              >
                                {`+${this.state.labManagers.slice(1).length}`}
                              </span>
                            </Tippy>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className='d-flex flex-wrap row-gap-1 column-gap-2 align-items-center min-h-px-25'>
                <label className='fw-normal fs-15 mb-0 color-gray-600'>Category:</label>
                {this.state.loading ? (
                  <Skeleton width='6em' height='1.25em' />
                ) : (
                  this.state.categoryName
                )}
              </div>

              {this.state.webSite && (
                <div className='ps-sm-3 pe-3 border-gray-400 min-h-px-25'>
                  <div className='d-flex align-items-center'>
                    <label className='fw-normal fs-15 mb-0 color-gray-600 me-2'>Website:</label>
                    {this.state.loading ? (
                      <Skeleton width='6em' height='1.25em' />
                    ) : (
                      <span
                        className='badge websiteBG py-px-6 rounded-pill fs-13 fw-normal px-3'
                        style={{ cursor: 'pointer' }}
                        onClick={() => window.open(this.state.webSite, '_blank')}
                      >
                        {this.state.webSite}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row flex-nowrap scrollmenu'>
            <div
              className='dashboardProjectDetail dashboard-section overviwe align-items-center'
              style={{ borderBottom: 'none' }}
            >
              <div
                className={
                  this.state.selectedTab === 0
                    ? 'overviwe-item'
                    : 'overviwe-item button-dashboard-category'
                }
                onClick={() =>
                  this.setState({
                    selectedTab: 0,
                  })
                }
              >
                <div className='dashboardProjectDetailItem'>
                  <div
                    className='d-flex flex-column align-items-center dashboardProjectDetailItem'
                    id='pills-notes-tab'
                  >
                    <div
                      className={
                        this.state.selectedTab === 0
                          ? 'dashboardSelectedCategory'
                          : 'dashboardCategory'
                      }
                    >
                      <RulerSquareIcon
                        color={this.state.selectedTab === 0 ? 'white' : 'black'}
                        className={'dashboardCategoryIconAll'}
                      ></RulerSquareIcon>
                      <span className='textWhite'>Equipment</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  this.state.selectedTab === 1
                    ? 'overviwe-item'
                    : 'overviwe-item button-dashboard-category'
                }
                onClick={() =>
                  this.setState({
                    selectedTab: 1,
                  })
                }
              >
                <div className=' dashboardProjectDetailItem'>
                  <div
                    className='d-flex flex-column align-items-center dashboardProjectDetailItem'
                    id='pills-description-tab'
                  >
                    <div
                      className={
                        this.state.selectedTab === 1
                          ? 'dashboardSelectedCategory'
                          : 'dashboardCategory'
                      }
                    >
                      <FileTextIcon
                        color={this.state.selectedTab === 1 ? 'white' : 'black'}
                        className={'dashboardCategoryIconAll'}
                      ></FileTextIcon>

                      <span className='textWhite'>Protocols and Documents</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  this.state.selectedTab === 2
                    ? 'overviwe-item'
                    : 'overviwe-item button-dashboard-category'
                }
                onClick={() =>
                  this.setState({
                    selectedTab: 2,
                  })
                }
              >
                <div className='d-flex flex-column align-items-center dashboardProjectDetailItem'>
                  <div
                    className={
                      this.state.selectedTab === 2
                        ? 'dashboardSelectedCategory'
                        : 'dashboardCategory'
                    }
                  >
                    <AvatarIcon
                      color={this.state.selectedTab === 2 ? 'white' : 'black'}
                      className={'dashboardCategoryIconAll'}
                    ></AvatarIcon>

                    <span className='textWhite'>User</span>
                  </div>
                </div>
              </div>
              <div
                className={
                  this.state.selectedTab === 3
                    ? 'overviwe-item'
                    : 'overviwe-item button-dashboard-category'
                }
                onClick={() =>
                  this.setState({
                    selectedTab: 3,
                  })
                }
              >
                <div className='d-flex flex-column align-items-center dashboardProjectDetailItem'>
                  <div
                    className={
                      this.state.selectedTab === 3
                        ? 'dashboardSelectedCategory'
                        : 'dashboardCategory'
                    }
                  >
                    <IdCardIcon
                      color={this.state.selectedTab === 3 ? 'white' : 'black'}
                      className={'dashboardCategoryIconAll'}
                    ></IdCardIcon>
                    <span className='textWhite'>Lab Details</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.selectedTab === 0 && (
            <>
              <div className='tab-content' id='pills-tabContent'>
                <div
                  // className="tab-pane fade"
                  id='pills-team'
                  role='tabpanel'
                  aria-labelledby='pills-team-tab'
                >
                  <div>
                    <div className='overflow-auto vh-calc-xl-320 vh-calc-sm-415 px-3'>
                      <div className='py-3 border-top border-bottom border-gray-400 d-flex justify-content-between align-items-center'>
                        <h3 className='color-gray-1000 fw-medium fs-15 mb-0'>Equipment</h3>
                      </div>
                      <RadixTable
                        data={this.state.equipments}
                        loading={this.state.loading}
                        onHeaderClick={(columnHeaderName: string, sortDirection: SortType) =>
                          this.headerClick(columnHeaderName, sortDirection)
                        }
                        columns={[
                          {
                            Header: 'Equipment',
                            width: '300px',
                            Cell: (_row: any) => {
                              return (
                                <Table.Cell className='p-3 col-lg-3 col-md-4'>
                                  <div className='d-flex align-items-center'>
                                    <ImageWithToken
                                      originalImage={_row.image}
                                      // hasImage={_row.image ? true : false}
                                      alt='Avatar'
                                      className='rounded-circle avatar  dFlexSm me-2'
                                      style={{
                                        width: '3em',
                                        height: '3em',
                                      }}
                                      src={
                                        _row.image
                                          ? IMAGE_BASE_URL + _row.image
                                          : '/Images/images/equipment_picture.svg'
                                      }
                                    />
                                    <span
                                      onClick={(e) =>
                                        this.props.history.push(
                                          $ReplaceRoute(
                                            `${AppRoutes.equip_profile.replace(
                                              ':id',
                                              _row.id?.toString() ?? ''
                                            )}`
                                          )
                                        )
                                      }
                                      title={_row.title}
                                      className='max1LineText whiteSpaceNoneSm fontBoldSm linkOnHover pointer pe-6 pe-sm-0'
                                    >
                                      {_row.title}
                                    </span>
                                  </div>
                                </Table.Cell>
                              );
                            },
                          },

                          {
                            Header: 'Status',
                            width: '180px',
                            Cell: (_row: any) => {
                              return (
                                <Table.Cell
                                  className='p-3'
                                  style={{ minWidth: '180px', width: '180px' }}
                                >
                                  {/* chipBottomRightInSm */}
                                  <div
                                    className='badge labStatusBG text-white py-px-6 rounded-pill fs-13 fw-normal px-3 '
                                    style={{
                                      color:
                                        _row.status.isStatusEquipment() === 'Operational'
                                          ? '#FFFFFF'
                                          : '#000',
                                      backgroundColor:
                                        _row.status.isStatusEquipment() === 'Operational'
                                          ? '#006EA8'
                                          : '#FFC962',
                                      width: 'max-content',
                                    }}
                                  >
                                    {_row.status.isStatusEquipment()}
                                  </div>
                                </Table.Cell>
                              );
                            },
                          },
                          {
                            Header: '',
                            Cell: (props: any) => {
                              return (
                                <Table.Cell className='p-3 text-nowrap'>
                                  <div className='d-flex align-items-center gap-3'>
                                    <div className='btn-group'>
                                      <button
                                        type='button'
                                        className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                                        onClick={(e) =>
                                          this.props.history.push(
                                            $ReplaceRoute(
                                              `${AppRoutes.equip_profile.replace(
                                                ':id',
                                                props.id?.toString() ?? ''
                                              )}`
                                            )
                                          )
                                        }
                                      >
                                        <i className='fa fa-eye me-px-6'></i>
                                        View Details
                                      </button>
                                    </div>
                                  </div>
                                </Table.Cell>
                              );
                            },
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {this.state.selectedTab === 1 && (
            <div
              // className="tab-pane fade"
              id='pills-team'
              role='tabpanel'
              aria-labelledby='pills-team-tab'
            >
              <div>
                <div className='overflow-auto vh-calc-xl-320 vh-calc-sm-415 px-3'>
                  <div className='py-3 border-top border-bottom border-gray-400 d-flex justify-content-between align-items-center'>
                    <h3 className='color-gray-1000 fw-medium fs-15 mb-0'>Protocols & Documents</h3>
                  </div>
                  <RadixTable
                    data={this.state.media}
                    loading={this.state.loading}
                    noDataFound={'No attachment yet'}
                    columns={[
                      {
                        Header: 'File name',
                        Cell: (_props: any) => {
                          return (
                            <Table.Cell
                              className='p-3 text-nowrap'
                              style={{ cursor: 'pointer' }}
                              onClick={() => this.downloadFile(_props)}
                            >
                              {_props.inputDataType == 4 ? _props.externalUrl : _props.title}
                            </Table.Cell>
                          );
                        },
                      },
                      {
                        Header: 'Size',
                        Cell: (props: any) => {
                          return (
                            <Table.Cell className='p-3 text-nowrap'>
                              {props.inputDataType == 4 ? '-' : `${props.fileSize} MB`}
                            </Table.Cell>
                          );
                        },
                      },
                      {
                        Header: '',
                        Cell: (props: any) => {
                          return (
                            <Table.Cell className='p-3 text-nowrap'>
                              <div className='d-flex align-items-center gap-3'>
                                <div className='btn-group'>
                                  <button
                                    type='button'
                                    className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                                    onClick={() => this.downloadFile(props)}
                                  >
                                    {props.inputDataType == 4 ? (
                                      <>
                                        <svg
                                          className='me-px-6'
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='15'
                                          height='16'
                                          viewBox='0 0 15 16'
                                          fill='none'
                                        >
                                          <rect
                                            width='15'
                                            height='15'
                                            transform='translate(0 0.500534)'
                                            fill='white'
                                            fill-opacity='0.01'
                                          />
                                          <path
                                            fill-rule='evenodd'
                                            clip-rule='evenodd'
                                            d='M7.50039 2.30055C4.35237 2.30055 1.80039 4.85253 1.80039 8.00055C1.80039 11.1485 4.35237 13.7005 7.50039 13.7005C10.6484 13.7005 13.2004 11.1485 13.2004 8.00055C13.2004 4.85253 10.6484 2.30055 7.50039 2.30055ZM0.900391 8.00055C0.900391 4.35547 3.85531 1.40056 7.50039 1.40056C11.1454 1.40056 14.1004 4.35547 14.1004 8.00055C14.1004 11.6456 11.1454 14.6005 7.50039 14.6005C3.85531 14.6005 0.900391 11.6456 0.900391 8.00055Z'
                                            fill='#5746AF'
                                          />
                                          <path
                                            fill-rule='evenodd'
                                            clip-rule='evenodd'
                                            d='M13.5 8.40051H1.5V7.60051H13.5V8.40051Z'
                                            fill='#5746AF'
                                          />
                                          <path
                                            fill-rule='evenodd'
                                            clip-rule='evenodd'
                                            d='M7.10022 14.0005V2.00053H7.90022V14.0005H7.10022ZM10.3753 8.00051C10.3753 5.82777 9.59395 3.67831 8.06214 2.25709L8.53824 1.74394C10.2399 3.32271 11.0753 5.67326 11.0753 8.00051C11.0753 10.3278 10.2399 12.6783 8.53824 14.2571L8.06214 13.7439C9.59395 12.3227 10.3753 10.1733 10.3753 8.00051ZM4 8.00053C4 5.67664 4.80817 3.32731 6.45799 1.74772L6.94208 2.25334C5.45851 3.67376 4.7 5.82442 4.7 8.00053C4.70001 10.1766 5.45853 12.3273 6.9421 13.7477L6.458 14.2533C4.80819 12.6737 4.00001 10.3244 4 8.00053Z'
                                            fill='#5746AF'
                                          />
                                          <path
                                            fill-rule='evenodd'
                                            clip-rule='evenodd'
                                            d='M7.49929 4.45854C9.66861 4.45854 11.8746 4.85968 13.3699 5.69501C13.5387 5.78928 13.5991 6.0025 13.5048 6.17126C13.4106 6.34001 13.1973 6.4004 13.0286 6.30613C11.6787 5.55208 9.60732 5.15854 7.49929 5.15854C5.39125 5.15854 3.31985 5.55208 1.96997 6.30613C1.80121 6.4004 1.58799 6.34001 1.49372 6.17126C1.39946 6.0025 1.45984 5.78928 1.6286 5.69501C3.12399 4.85968 5.32996 4.45854 7.49929 4.45854ZM7.49929 11.3505C9.66861 11.3505 11.8746 10.9493 13.3699 10.114C13.5387 10.0198 13.5991 9.80654 13.5048 9.63779C13.4106 9.46903 13.1973 9.40865 13.0286 9.50291C11.6787 10.257 9.60732 10.6505 7.49929 10.6505C5.39125 10.6505 3.31985 10.257 1.96997 9.50292C1.80121 9.40865 1.58799 9.46903 1.49372 9.63779C1.39946 9.80654 1.45984 10.0198 1.6286 10.114C3.12399 10.9493 5.32996 11.3505 7.49929 11.3505Z'
                                            fill='#5746AF'
                                          />
                                        </svg>
                                        {`Open link`}
                                      </>
                                    ) : (
                                      <>
                                        <svg
                                          className='me-px-6'
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='15'
                                          height='16'
                                          viewBox='0 0 15 16'
                                          fill='none'
                                        >
                                          <rect
                                            width='15'
                                            height='15'
                                            transform='translate(0 0.500427)'
                                            fill='white'
                                            fill-opacity='0.01'
                                          />
                                          <path
                                            fill-rule='evenodd'
                                            clip-rule='evenodd'
                                            d='M7.5 11.5004C4.80285 11.5004 2.52952 10.1223 1.09622 8.00044C2.52952 5.87859 4.80285 4.50043 7.5 4.50043C10.1971 4.50043 12.4705 5.87859 13.9038 8.00044C12.4705 10.1223 10.1971 11.5004 7.5 11.5004ZM7.5 3.50043C4.30786 3.50043 1.65639 5.20681 0.0760002 7.73544C-0.0253338 7.89758 -0.0253334 8.10331 0.0760014 8.26544C1.65639 10.794 4.30786 12.5004 7.5 12.5004C10.6921 12.5004 13.3436 10.794 14.924 8.26544C15.0253 8.10331 15.0253 7.89758 14.924 7.73544C13.3436 5.20681 10.6921 3.50043 7.5 3.50043ZM7.5 10.0004C8.60457 10.0004 9.5 9.105 9.5 8.00043C9.5 6.89586 8.60457 6.00043 7.5 6.00043C6.39543 6.00043 5.5 6.89586 5.5 8.00043C5.5 9.105 6.39543 10.0004 7.5 10.0004Z'
                                            fill='#5746AF'
                                          />
                                        </svg>
                                        {`Open file`}
                                      </>
                                    )}
                                  </button>
                                </div>
                              </div>
                            </Table.Cell>
                          );
                        },
                      },
                    ]}
                  />
                </div>{' '}
              </div>
            </div>
          )}
          {this.state.selectedTab === 2 && (
            <div
              // className="tab-pane fade"
              id='pills-team'
              role='tabpanel'
              aria-labelledby='pills-team-tab'
            >
              <div>
                <div className='overflow-auto vh-calc-xl-320 vh-calc-sm-415 px-3'>
                  <div className='py-3 border-top border-bottom border-gray-400 d-flex justify-content-between align-items-center'>
                    <h3 className='color-gray-1000 fw-medium fs-15 mb-0'>User</h3>
                  </div>
                  <RadixTable
                    data={this.state.members}
                    loading={this.state.loading}
                    columns={[
                      {
                        Header: 'User',
                        Cell: (_props: any) => {
                          return (
                            <Table.Cell className='p-3 col-lg-3 col-md-4'>
                              <div className='d-flex align-items-center text-nowrap'>
                                {_props.image === null ||
                                _props.image === '' ||
                                _props.image === undefined ? (
                                  <div className='avatar w-px-25 h-px-25 rounded-circle bg-primary'>
                                    <label className='fw-medium text-white fs-10'>
                                      {getUserInitails(`${_props.firstName} ${_props.lastName}`)}
                                    </label>
                                  </div>
                                ) : (
                                  <ImageWithToken
                                    originalImage={_props.image}
                                    hasImage={_props.image ? true : false}
                                    alt='Avatar'
                                    className='rounded-circle avatar rounded-avatar-dashboard'
                                    src={
                                      _props.image === null ||
                                      _props.image === '' ||
                                      _props.image === undefined
                                        ? '/images/images/img_avatar.png'
                                        : _props.image
                                    }
                                  />
                                )}
                                <span
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    this.props.history.push(
                                      $ReplaceRoute(
                                        `${AppRoutes.member_profile.replace(
                                          ':id',
                                          _props.id?.toString() ?? ''
                                        )}`
                                      )
                                    );
                                  }}
                                  title={`${_props.firstName} ${_props.lastName}`}
                                >
                                  {' '}
                                  {_props.firstName} {_props.lastName}
                                </span>
                              </div>
                            </Table.Cell>
                          );
                        },
                      },

                      {
                        Header: '',
                        Cell: (props: any) => {
                          return (
                            <Table.Cell className='p-3 text-nowrap'>
                              <div className='d-flex align-items-center gap-3'>
                                <div className='btn-group'>
                                  <button
                                    type='button'
                                    className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.history.push(
                                        $ReplaceRoute(
                                          `${AppRoutes.member_profile.replace(
                                            ':id',
                                            props.id.toString() ?? ''
                                          )}`
                                        )
                                      );
                                    }}
                                  >
                                    <i className='fa fa-eye me-px-6'></i>View Details
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split'
                                    data-bs-toggle='dropdown'
                                    aria-expanded='false'
                                  >
                                    <span className='visually-hidden'>Toggle Dropdown</span>
                                  </button>
                                  <ul className='dropdown-menu'>
                                    <li>
                                      <a
                                        className='dropdown-item fs-14'
                                        href='#'
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.props.history.push(
                                            $ReplaceRoute(
                                              `${AppRoutes.member_profile.replace(
                                                ':id',
                                                props.id.toString() ?? ''
                                              )}`
                                            )
                                          );
                                        }}
                                      >
                                        View Details
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className='dropdown-item fs-14'
                                        href='#'
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.props.history.push(
                                            $ReplaceRoute(
                                              `${AppRoutes.member_user_edit.replace(
                                                ':id',
                                                props.id.toString() ?? ''
                                              )}`
                                            )
                                          );
                                        }}
                                      >
                                        Edit User Accesss
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </Table.Cell>
                          );
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          )}

          {this.state.selectedTab === 3 && (
            <div
              // className="tab-pane fade"
              id='pills-description'
              role='tabpanel'
              aria-labelledby='pills-description-tab'
            >
              <div>
                <div className='overflow-auto vh-calc-xl-320 vh-calc-sm-415 px-3'>
                  <div className='py-3 border-top border-bottom border-gray-400 d-flex justify-content-between align-items-center'>
                    <h3 className='color-gray-1000 fw-medium fs-15 mb-0'>Information</h3>
                    <a
                      className='btn btn-primary fs-13 py-px-4 fw-normal px-2'
                      href='create-new-proj.html'
                      style={{ visibility: 'hidden' }}
                    >
                      <i className='fa fa-plus me-1'></i>
                    </a>
                  </div>
                  {(this.state.company ||
                    this.state.addressLine1 ||
                    this.state.addressLine2 ||
                    this.state.city ||
                    this.state.countryName ||
                    this.state.phone) && (
                    <div className='p-3 bg-gray-200 rounded-px-6 my-3 col-lg-3 col-md-3 col-sm-12 max-content-width'>
                      <div className='pb-xxl-2'>
                        <SewingPinIcon style={{ width: '15px', height: '15px' }}></SewingPinIcon>
                        <span className='labInfoText'>Address</span>
                      </div>
                      <div className='labInfolayout'>
                        <span className='labInfoText'>
                          {this.state.company}
                          {`${this.state.addressLine1}${this.state.addressLine1 ? ' - ' : ''}${
                            this.state.addressLine2
                          }`}
                          {`${this.state.city}${
                            this.state.city || this.state.province ? ' - ' : ''
                          }${this.state.province}${
                            this.state.province || this.state.zipCode ? ' - ' : ''
                          }${this.state.zipCode}`}
                          {this.state.countryName}
                          {this.state.phone}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {this.state.selectedTab === 0 &&
          this.state.laboratoryManagerRemovalRequests?.length > 0 && (
            <>
              <div className='profileCard' style={{ marginTop: '2.5rem' }}>
                <div className='tab-content' id='pills-tabContent'>
                  <div
                    // className="tab-pane fade"
                    id='pills-team'
                    role='tabpanel'
                    aria-labelledby='pills-team-tab'
                  >
                    <div>
                      <div className='overflow-auto vh-calc-xl-320 vh-calc-sm-415 px-3'>
                        <div className='py-3 border-bottom border-gray-400 d-flex justify-content-between align-items-center'>
                          <h3 className='color-gray-1000 fw-medium fs-15 mb-0'>
                            Manager Removal Request by You
                          </h3>
                        </div>
                        <RadixTable
                          data={this.state.laboratoryManagerRemovalRequests}
                          loading={this.state.loading}
                          columns={[
                            {
                              Header: 'User',
                              Cell: (_props: any) => {
                                return (
                                  <Table.Cell className='p-3 col-lg-3 col-md-4'>
                                    <UserWithAvatar user={_props.removalRequestedManager} />
                                  </Table.Cell>
                                );
                              },
                            },

                            {
                              Header: 'Status',
                              Cell: (props: any) => {
                                return (
                                  <Table.Cell className='p-3'>
                                    <div
                                      // title={_fullUserText}
                                      className='d-flex align-items-center'
                                    >
                                      <span className='dNone dFlexSm me-1'>Status:</span>
                                      <div
                                        className='badge labStatusBG text-white py-px-6 rounded-pill fs-13 fw-normal px-3'
                                        style={{
                                          backgroundColor:
                                            LABORATORY_REMOVAL_MANAGER_STATUS[props.status].bg,
                                          color:
                                            LABORATORY_REMOVAL_MANAGER_STATUS[props.status].color,
                                        }}
                                      >
                                        {LABORATORY_REMOVAL_MANAGER_STATUS[props.status].text}
                                      </div>
                                    </div>
                                  </Table.Cell>
                                );
                              },
                            },
                            {
                              Header: 'Answer Status',
                              Cell: (props: any) => {
                                return (
                                  <Table.Cell className='p-3 text-nowrap'>
                                    <div
                                      // title={_fullUserText}
                                      className='d-flex align-items-center'
                                    >
                                      <span className='dNone dFlexSm me-1 ms-3'>
                                        Answer Status:
                                      </span>
                                      <div
                                        className='badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3'
                                        style={{
                                          backgroundColor:
                                            LABORATORY_REMOVAL_MANAGER_ANSWER_STATUS[
                                              props.removalAnswerStatus
                                            ].bg,
                                          color:
                                            LABORATORY_REMOVAL_MANAGER_ANSWER_STATUS[
                                              props.removalAnswerStatus
                                            ].color,
                                        }}
                                      >
                                        {
                                          LABORATORY_REMOVAL_MANAGER_ANSWER_STATUS[
                                            props.removalAnswerStatus
                                          ].text
                                        }
                                      </div>
                                    </div>
                                  </Table.Cell>
                                );
                              },
                            },
                            {
                              Header: '',
                              Cell: (props: any) => {
                                if (props.status === 1)
                                  return (
                                    <Table.Cell className='p-3 text-nowrap'>
                                      <div className='d-flex align-items-center gap-3'>
                                        <ConfirmationContent
                                          title='Cancel Request'
                                          message='Are you sure you want to cancel the request?'
                                          onConfirm={() => {
                                            this.handleChangeRequestedRemovalStatus(
                                              props.laboratoryManagerRequestId,
                                              0
                                            );
                                          }}
                                          onCancel={() => {}}
                                          confirmText='Yes, Cancel'
                                          cancelText='No'
                                        >
                                          <MainButton
                                            svg={
                                              <svg
                                                className='hover-fill'
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='15'
                                                height='15'
                                                viewBox='0 0 15 15'
                                                fill='none'
                                              >
                                                <rect
                                                  width='15'
                                                  height='15'
                                                  fill='white'
                                                  fillOpacity='0.01'
                                                />
                                                <path
                                                  fillRule='evenodd'
                                                  clipRule='evenodd'
                                                  d='M11.7814 4.03163C12.006 3.80708 12.006 3.44301 11.7814 3.21846C11.5569 2.99391 11.1928 2.99391 10.9683 3.21846L7.49981 6.68688L4.0314 3.21846C3.80684 2.99391 3.44277 2.99391 3.21822 3.21846C2.99367 3.44301 2.99367 3.80708 3.21822 4.03163L6.68664 7.50005L3.21822 10.9685C2.99367 11.1931 2.99367 11.5571 3.21822 11.7817C3.44277 12.0062 3.80684 12.0062 4.0314 11.7817L7.49981 8.31322L10.9683 11.7817C11.1928 12.0062 11.5569 12.0062 11.7814 11.7817C12.006 11.5571 12.006 11.1931 11.7814 10.9685L8.31298 7.50005L11.7814 4.03163Z'
                                                  fill='#5746AF'
                                                />
                                              </svg>
                                            }
                                            children='Cancel Request'
                                            type={MainButtonType.dark}
                                            borderRadius='24px'
                                            fontSize='14px'
                                            minHeight='2em'
                                            className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 main-button'
                                            onClick={() => {
                                              this.handleChangeRequestedRemovalStatus(
                                                props.laboratoryManagerRequestId,
                                                0
                                              );
                                            }}
                                          ></MainButton>
                                        </ConfirmationContent>
                                      </div>
                                    </Table.Cell>
                                  );
                                return '';
                              },
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
      </>
    );
  }
}
export default withRouter(LaboratoryPageProfile);

const ChooseRemovalRequest = ({
  onReject,
  onAccept,
  detail,
}: {
  onAccept: () => void;
  onReject: () => void;
  detail: any;
}) => {
  return (
    <div className='container-fluid research new-research'>
      {/* <div className="col-12 box-content p-3">
        <div className="d-flex justify-content-between align-items-start">
          <h5 className="b-title d-flex align-items-center">
            <IoIosArrowDropleftCircle
              className="me-3 textSecondary2 cursorPointer"
              size="1.5em"
              onClick={() => {
                window.history.back();
              }}
            />{" "}
            {"Laboratory List > Lab Profile"}
          </h5>
          <div className="d-flex align-items-center">
            <button onClick={onReject}>Reject</button>
            <button onClick={onAccept}>Accept</button>
          </div>
        </div>
      </div> */}

      <div className='d-flex flex-column align-items-center py-5'>
        <img
          alt=''
          src='/images/images/choice.png'
          style={{
            height: '5em',
          }}
        />
        <h4 className='mt-3'>Request ro remove</h4>
        <p className='text-center textSecondary' style={{ maxWidth: '64ch' }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt sed, ad explicabo, enim
          consequuntur assumenda dicta id possimus asperiores, ullam hic totam molestiae. Fugiat non
          veniam illo ea corrupti maxime!
        </p>

        <div
          className='d-flex align-items-center justify-content-between p-1 pe-4'
          style={{
            // width: "24em",
            maxWidth: '100%',
            backgroundColor: '#ebebeb',
            borderRadius: '10em',
          }}
        >
          <div className='d-flex'>
            <UserWithAvatar
              user={detail.requestedRemoval.requestedManager}
              avatarStyle={{
                border: '1px solid #ccc',
              }}
            />
          </div>
        </div>

        <div className='d-flex align-items-center mt-3'>
          <MainButton
            children={'Reject'}
            type={MainButtonType.light}
            borderRadius='24px'
            fontSize='14px'
            className='px-3 me-1'
            minHeight='2em'
            onClick={onReject}
          ></MainButton>
          <MainButton
            children={'Accept'}
            type={MainButtonType.dark}
            borderRadius='24px'
            fontSize='14px'
            className='px-3'
            minHeight='2em'
            onClick={onAccept}
          ></MainButton>
        </div>
      </div>
    </div>
  );
};
