import { TopicType } from '../../../../core/number_extentions';
import {
  AvatarIcon,
  Pencil1Icon,
  PieChartIcon,
  ReaderIcon,
  RulerSquareIcon,
  TransformIcon,
  ViewGridIcon,
} from '@radix-ui/react-icons';

type TabButtonsProps = {
  notificationCategory: number;
  handleCategoryNotificationSearch: (e: number) => void;
};

export default function TabButtons({
  notificationCategory,
  handleCategoryNotificationSearch,
}: TabButtonsProps) {
  return (
    <>
      <div
        style={{ paddingLeft: '0px' }}
        className={
          notificationCategory === TopicType.All
            ? 'overviwe-item'
            : 'overviwe-item button-dashboard-category'
        }
        onClick={(e) => {
          handleCategoryNotificationSearch(TopicType.All);
        }}
      >
        <div className='dashboardProjectDetailItem'>
          <div className='d-flex flex-column align-items-center dashboardProjectDetailItem'>
            <div
              className={
                notificationCategory === TopicType.All
                  ? 'dashboardSelectedCategory'
                  : 'dashboardCategory'
              }
            >
              <ViewGridIcon
                color={notificationCategory === TopicType.All ? 'white' : 'black'}
                className={'dashboardCategoryIconAll'}
              ></ViewGridIcon>
              <span className='textWhite'>All</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          notificationCategory === TopicType.Project
            ? 'overviwe-item'
            : 'overviwe-item button-dashboard-category'
        }
        onClick={(e) => {
          handleCategoryNotificationSearch(TopicType.Project);
        }}
      >
        <div className=' dashboardProjectDetailItem'>
          <div className='d-flex flex-column align-items-center dashboardProjectDetailItem'>
            <div
              className={
                notificationCategory === TopicType.Project
                  ? 'dashboardSelectedCategory'
                  : 'dashboardCategory'
              }
            >
              <TransformIcon
                color={notificationCategory === TopicType.Project ? 'white' : 'black'}
                className={'dashboardCategoryIconAll'}
              ></TransformIcon>

              <span className='textWhite'>Research</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          notificationCategory === TopicType.Team
            ? 'overviwe-item'
            : 'overviwe-item button-dashboard-category'
        }
        onClick={(e) => {
          handleCategoryNotificationSearch(TopicType.Team);
        }}
      >
        <div className='d-flex flex-column align-items-center dashboardProjectDetailItem'>
          <div
            className={
              notificationCategory === TopicType.Team
                ? 'dashboardSelectedCategory'
                : 'dashboardCategory'
            }
          >
            <AvatarIcon
              color={notificationCategory === TopicType.Team ? 'white' : 'black'}
              className={'dashboardCategoryIconAll'}
            ></AvatarIcon>

            <span className='textWhite'>Team</span>
          </div>
        </div>
      </div>

      <div
        className={
          notificationCategory === TopicType.Laboratory
            ? 'overviwe-item'
            : 'overviwe-item button-dashboard-category'
        }
        onClick={(e) => {
          handleCategoryNotificationSearch(TopicType.Laboratory);
        }}
      >
        <div className='d-flex flex-column align-items-center dashboardProjectDetailItem'>
          <div
            className={
              notificationCategory === TopicType.Laboratory
                ? 'dashboardSelectedCategory'
                : 'dashboardCategory'
            }
          >
            <RulerSquareIcon
              color={notificationCategory === TopicType.Laboratory ? 'white' : 'black'}
              className={'dashboardCategoryIconAll'}
            ></RulerSquareIcon>

            <span className='textWhite'>Laboratory</span>
          </div>
        </div>
      </div>
      <div
        className={
          notificationCategory === TopicType.Task
            ? 'overviwe-item'
            : 'overviwe-item button-dashboard-category'
        }
        onClick={(e) => {
          handleCategoryNotificationSearch(TopicType.Task);
        }}
      >
        <div className='d-flex flex-column align-items-center dashboardProjectDetailItem'>
          <div
            className={
              notificationCategory === TopicType.Task
                ? 'dashboardSelectedCategory'
                : 'dashboardCategory'
            }
          >
            <ReaderIcon
              color={notificationCategory === TopicType.Task ? 'white' : 'black'}
              className={'dashboardCategoryIconAll'}
            ></ReaderIcon>

            <span className='textWhite'>Task</span>
          </div>
        </div>
      </div>
      <div
        className={
          notificationCategory === TopicType.Data
            ? 'overviwe-item'
            : 'overviwe-item button-dashboard-category'
        }
        onClick={(e) => {
          handleCategoryNotificationSearch(TopicType.Data);
        }}
      >
        <div className='d-flex flex-column align-items-center dashboardProjectDetailItem'>
          <div
            className={
              notificationCategory === TopicType.Data
                ? 'dashboardSelectedCategory'
                : 'dashboardCategory'
            }
          >
            <PieChartIcon
              color={notificationCategory === TopicType.Data ? 'white' : 'black'}
              className={'dashboardCategoryIconAll'}
            ></PieChartIcon>
            <span className='textWhite'>Data</span>
          </div>
        </div>
      </div>

      <div
        className={
          notificationCategory === TopicType.Publication
            ? 'overviwe-item'
            : 'overviwe-item button-dashboard-category'
        }
        onClick={(e) => {
          handleCategoryNotificationSearch(TopicType.Publication);
        }}
      >
        <div className='d-flex flex-column align-items-center dashboardProjectDetailItem'>
          <div
            className={
              notificationCategory === TopicType.Publication
                ? 'dashboardSelectedCategory'
                : 'dashboardCategory'
            }
          >
            <Pencil1Icon
              color={notificationCategory === TopicType.Publication ? 'white' : 'black'}
              className={'dashboardCategoryIconAll'}
            ></Pencil1Icon>

            <span className='textWhite'>Write & Publish</span>
          </div>
        </div>
      </div>

      <div
        className={
          notificationCategory === TopicType.Expense
            ? 'overviwe-item'
            : 'overviwe-item button-dashboard-category'
        }
        style={{ pointerEvents: 'none', opacity: '0.55' }}
        onClick={(e) => {
          handleCategoryNotificationSearch(TopicType.Expense);
        }}
      >
        <div className='d-flex flex-column align-items-center dashboardProjectDetailItem'>
          <div
            className={
              notificationCategory === TopicType.Expense
                ? 'dashboardSelectedCategory'
                : 'dashboardCategory'
            }
          >
            <img
              src={
                notificationCategory === TopicType.Expense
                  ? '/images/images/money-white.svg'
                  : '/images/images/money.svg'
              }
              alt='Dollar'
              className={'dashboardCategoryIconAll'}
            />

            <span className='textWhite'>Financials</span>
          </div>
        </div>
      </div>
    </>
  );
}
