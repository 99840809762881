import React, { memo, ReactElement } from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';

interface ICheckbox {
  checked?: boolean;
  onClick?: () => void;
}

function MyCheckbox({
  checked,
  onClick
}: ICheckbox): ReactElement {
  const handleChange = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <form>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox.Root className="CheckboxRoot" checked={checked} onCheckedChange={handleChange} id="c1">
          <Checkbox.Indicator className="CheckboxIndicator">
            <CheckIcon />
          </Checkbox.Indicator>
        </Checkbox.Root>
      </div>
    </form>
  );
}

export default memo(MyCheckbox);
