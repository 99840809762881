import {  SET_RESEARCH_UPDATED } from "../actions/actions_type";
import { ResearchUpdatedAction } from "../actions/research_action";

const ResearchUpdated: boolean = false;
const reducer = (
    state: boolean = ResearchUpdated,
    action: ResearchUpdatedAction
  ): boolean => {
    switch (action.type) {
      case SET_RESEARCH_UPDATED:
        return action.payload;
    }
    return state;
  };
  
  export default reducer;