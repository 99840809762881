import Tippy from "@tippyjs/react";
import React, { useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../core/constants";
import { $ReplaceRoute, getUserTitle } from "../../core/utils";
import { ImageWithToken } from "./box_list_scroll";
import TippyHeader from "./TippyHeader";
import { nanoid } from "nanoid";
import { IMAGE_BASE_URL } from "../../core/http_common";
// import { IMAGE_BASE_URL } from "../../core/http_common";

function GroupedAvatars({
  users,
  maxAvatars,
  containerWidth,
  parentClassName = "",
  tippyContent = <div />,
}: {
  users: any[];
  maxAvatars: number;
  containerWidth: number;
  parentClassName?: string;
  tippyContent: React.ReactNode;
}) {
  const [maxVisibleAvatars, setMaxVisibleAvatars] = useState(maxAvatars);
  const [tippyVisible, setTippyVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const containerPadding = parseInt(
      window.getComputedStyle(container).paddingLeft
    );
    const avatarWidth = 32; // assumes avatars are always 40x40
    const availableWidth = container.clientWidth - containerPadding * 2;
    const maxAvatarsThatFit = Math.floor(
      (availableWidth + 10) / (avatarWidth + 10)
    ); // adds 10px margin between avatars
    setMaxVisibleAvatars(Math.min(maxAvatarsThatFit, users.length));
  }, [containerWidth, users.length]);

  const visibleAvatars = users.slice(0, maxVisibleAvatars);

  // console.log(tippyVisible)
  return (
    <div
      className={parentClassName}
      style={{ display: "flex" }}
      ref={containerRef}
    >
      {visibleAvatars.map((user, index) => (
        <Link
        key={nanoid()}
          style={{
            marginLeft: index === 0 ? 0 : "-1.5em",
          }}
          title={`${user.firstName} ${user.lastName}`}
          to={$ReplaceRoute(
            `${AppRoutes.member_profile.replace(":id", user.id ?? "")}`
          )}
        >
          <ImageWithToken
            hasImage={user.image ? true : false}
            originalImage={user.image}
            src={
              user.image
                ? IMAGE_BASE_URL + user.image
                : "/images/images/img_avatar.png"
            }
            alt="A"
            className="rounded-circle avatar d-block"
            style={{
              height: "2em",
              width: "2em",

              border: "1px solid #adadad",
            }}
            textInAvatar={user.image ? null : getUserTitle(user)}
          // width="1.75em"
          // height="1.75em"
          />
        </Link>
      ))}
      {users.length > maxVisibleAvatars && (
        <Tippy
          content={
            <div
              className="d-flex flex-column"
              style={{ width: "16em", maxWidth: "100%" }}
            >
              <TippyHeader title={`Total Users (${users.length})`} />
              {tippyContent}
            </div>
          }
          placement="bottom-start"
          interactive
          appendTo={document.body}
          visible={tippyVisible}
          arrow={false}
          onClickOutside={() => setTippyVisible(false)}
        >
          <div className="pointer"
            onClick={() => { 
              // console.log('on click')
              setTippyVisible(true) }}
            style={{
              marginLeft: "-0.5em",
              backgroundColor: "white",
              borderRadius: "50%",
              width: "2em",
              height: "2em",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #adadad",
            }}
          >
            <span>+{users.length - maxVisibleAvatars}</span>
          </div>
        </Tippy>
      )}
    </div>
  );
}

export default GroupedAvatars;
