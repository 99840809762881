import { NOTIFICATION_COUNT } from './actions_type';

export type NotificationCountAction = {
  type: string;
  payload: number;
};
export function SetNotificationCount(notificationCount: number): NotificationCountAction {
  return {
    type: NOTIFICATION_COUNT,
    payload: notificationCount,
  };
}
