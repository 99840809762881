import { PassChecks } from './enum';

export const clientId = '329574455809-45n9l8bomekacm5ovoe23sa54prfbqto.apps.googleusercontent.com';
export class AppConstants {
  static token: string = '';
  // static base_url_api: string = "https://app.radvix.io/api/";
  static base_url_api: string = 'https://api.radvix.io/api/';
  static base_url_image: string = 'https://api.radvix.io';
  static divide: string = '___';
  static one_day_milis = 24 * 60 * 60 * 1000;
  static fakePassword = 'R@dV1xApplication';
}
export const MOMENT_DATE_FORMAT = 'll';
export const MOMENT_DATE_TIME_FORMAT = 'lll';
export const DEFAULT_RESEARCH: { label: string; value: number } = {
  label: '',
  value: -1,
};
export class AppRoutes {
  static splash: string = '/';
  static dashboard: string = `/:company/dashboard/home`;
  static login: string = '/login';
  static mfa: string = '/mfa';
  static arp: string = '/arp';
  static reset: string = '/reset';
  static resetPassword: string = '/resetPassword';
  static activateAcount: string = '/activateaccount';
  static research: string = `/:company/dashboard/research/`;
  static new_research: string = `/:company/dashboard/research/new/`;
  static timeline_research: string = `/:company/dashboard/research/timeline`;
  static profile_research: string = `/:company/dashboard/research/profile/:id`;
  static edit_research: string = `/:company/dashboard/research/edit/:id`;
  static team: string = `/:company/dashboard/team/`;
  static team_profile: string = `/:company/dashboard/team/profile/:id`;
  static team_edit: string = `/:company/dashboard/team/edit/:id`;
  static team_meet_note_new: string = `/:company/dashboard/team/meetNoteNew/:id`;
  static new_team: string = `/:company/dashboard/team/new/`;
  static laboratory: string = `/:company/dashboard/laboratory/`;
  static new_laboratory: string = `/:company/dashboard/laboratory/new/`;
  static profile_laboratory: string = `/:company/dashboard/laboratory/profile/:id`;
  static edit_laboratory: string = `/:company/dashboard/laboratory/edit/:id`;
  static task: string = `/:company/dashboard/Task/`;
  static task_new: string = `/:company/dashboard/Task/new/`;
  static task_profile: string = `/:company/dashboard/Task/profile/:id`;
  static task_edit: string = `/:company/dashboard/Task/new/:id`;
  static member: string = `/:company/dashboard/Member`;
  static member_new: string = `/:company/dashboard/Member/new/`;
  static member_profile: string = `/:company/dashboard/Member/profile/:id`;
  static member_new_rolehelp: string = `/:company/dashboard/Member/Role_help`;
  static member_user_edit: string = `/:company/dashboard/Member/UserEdit/:id`;
  static member_edit_profile: string = `/:company/dashboard/Member/EditProfile/:id`;
  static member_transfer: string = `/:company/dashboard/MemberTransfer`;
  static create_member_transfer: string = `/:company/dashboard/CreateMemberTransfer`;

  static grant: string = `/:company/dashboard/grant/`;
  static grant_new: string = `/:company/dashboard/grant/new`;
  static grant_edit: string = `/:company/dashboard/grant/edit/:id`;
  static grant_detail: string = `/:company/dashboard/grant/:id`;
  static expenses: string = `/:company/dashboard/expenses/`;
  static expenses_new: string = `/:company/dashboard/expenses/new`;
  static expenses_edit: string = `/:company/dashboard/expenses/edit/:id`;

  static seat_management: string = `/:company/dashboard/team/seat_management/`;
  static equip_new: string = `/:company/dashboard/Equip/new/`;
  static equip: string = `/:company/dashboard/Equip/`;
  static equip_profile: string = `/:company/dashboard/Equip/profile/:id`;
  static equip_edit: string = `/:company/dashboard/Equip/edit/:id`;
  static equip_reservation: string = `/:company/dashboard/Equip/reservation`;
  static equip_reservation_all: string = `/:company/dashboard/Equip/reservationAll`;
  static discussion: string = `/:company/dashboard/Discussion/panel`;
  // static discussion_new: string = `/:company/dashboard/Discussion/new/:topic/:section`;
  static discussion_new: string = `/:company/dashboard/Discussion/new`;
  static signup: string = `/Signup/`;
  static workSpace: string = `/workSpace`;
  static sharedWorkSpace: string = `/:company/dashboard/SharedWorkSpace/`;
  static UserSubscriptions: string = `/UserSubscriptions`;
  static UpdateSubscription: string = `/UpdateSubscriptions`;
  static UserPayment: string = `/UserPayment`;
  static Subs_Payment: string = `/Subs_payment`;
  static linkConfirmation: string = `/LinkConfirmation`;
  static setting: string = `/:company/dashboard/Setting/`;
  static ticketing: string = `/:company/dashboard/Ticketing/`;
  static ticketing_new: string = `/:company/dashboard/Ticketing/new`;
  static library_page: string = `/library/:company/page`;
  static register_page: string = `/register`;
  static invite_register: string = `/invite_register`;
  static discussion_list: string = `/:company/dashboard/Discussion/list`;
  static data: string = `/:company/dashboard/Data/`;
  static data_list: string = `/:company/dashboard/Data/list`;
  static data_new: string = `/:company/dashboard/Data/new`;
  static data_mydata: string = `/:company/dashboard/Data/mydata/`;
  static data_profile: string = `/:company/dashboard/Data/profile/:dataid/:appTaskId`;
  static data_edit: string = `/:company/dashboard/Data/edit/:dataid/:appTaskId`;
  static publish: string = `/:company/dashboard/Publish/`;
  static publish_new: string = `/:company/dashboard/Publish/new`;
  static publish_upload: string = `/:company/dashboard/Publish/upload/:id/:draftId`;
  static expense: string = `/:company/dashboard/Expense/`;
  static expense_new: string = `/:company/dashboard/Expense/new`;
  static expense_profile: string = `/:company/dashboard/Expense/profile/:id`;
  static ticketing_ticket: string = `/:company/dashboard/Ticketing/ticket/:id`;
  static LearningCenter: string = `/:company/dashboard/LearningCenter/`;
  static AiInstruction: string = `/:company/dashboard/aiInstructions/`;

  static admin_dashboard: string = '/controlPanel/dashboard/Admin/home';
  static admin_clients: string = '/controlPanel/dashboard/Admin/clients/';
  static admin_users: string = '/controlPanel/dashboard/Admin/users/';
  static admin_member: string = '/controlPanel/dashboard/Admin/member/:id';
  static admin_payments: string = '/controlPanel/dashboard/Admin/payments';
  static admin_tickets: string = '/controlPanel/dashboard/Admin/tickets/';
  static admin_ticket: string = '/controlPanel/dashboard/Admin/ticket/:id';
  static admin_broadcast: string = '/controlPanel/dashboard/Admin/broadcast/';

  static publish_profile: string = '/:company/dashboard/Publish/profile/:id';
  static publish_edit: string = '/:company/dashboard/Publish/edit/:id';
  static expense_edit: string = '/:company/dashboard/Expense/edit/:id';

  static new_group: string = `/:company/dashboard/group/new/`;
  static edit_group: string = `/:company/dashboard/group/edit/:id`;
}
export const LOADING_MODE = {
  links: 'Uploading links..',
  files: 'Uploading files..',
  pic: 'Uploading picture..',
};
export const USER_TRANSFER_RESPONSE_STATUS: {
  [key: number]: {
    title: string;
    bg: string;
    color: string;
  };
} = {
  0: {
    title: 'Pending',
    bg: '#87878730',
    color: '#000',
  },
  1: {
    title: 'Declined',
    bg: '#fb474730',
    color: '#f13333',
  },
  2: {
    title: 'Accepted',
    bg: '#4bb54330',
    color: '#148b0b',
  },
};
export const USER_TRANSFER_LOG_STATUS_ENUM: {
  [key: number]: {
    title: string;
    bg: string;
    color: string;
  };
} = {
  0: {
    title: 'Pending',
    bg: '#87878730',
    color: '#000',
  },
  1: {
    title: 'Transferred',
    bg: '#4bb54330',
    color: '#148b0b',
  },
  2: {
    title: 'Canceled',
    bg: '#ccc',
    color: '#000',
  },
  3: {
    title: 'Revert',
    bg: '#e1772242',
    color: '#d57613',
  },
  4: {
    title: 'ForcedTransfer',
    bg: '#4bb54330',
    color: '#148b0b',
  },
};
export const LABORATORY_REMOVAL_MANAGER_STATUS: {
  [key: number]: {
    text: string;
    bg: string;
    color: string;
  };
} = {
  0: {
    text: 'Canceled',
    bg: '#ccc',
    color: '#000',
  },
  1: {
    text: 'Active',
    bg: '#67cdd9',
    color: '#000',
  },
  2: {
    text: 'Finished',
    bg: '#4BB543',
    color: '#000',
  },
};
export const LABORATORY_REMOVAL_MANAGER_ANSWER_STATUS: {
  [key: number]: {
    text: string;
    bg: string;
    color: string;
  };
} = {
  0: {
    text: 'Pending',
    bg: '#7E808A',
    color: '#000',
  },
  1: {
    text: 'Accepted',
    bg: '#4bb54363',
    color: '#148b0b',
  },
  2: {
    text: 'Rejected',
    bg: '#ef666661',
    color: '#ef6666',
  },
};
export const EXPENSE_STATUS: {
  [key: number]: {
    text: string;
    bg: string;
    color: string;
  };
} = {
  0: {
    text: 'Pending',
    bg: '#ccc',
    color: '#000',
  },
  1: {
    text: 'Approved',
    bg: '#4bb54363',
    color: '#148b0b',
  },
  2: {
    text: 'Declined',
    bg: '#ef666661',
    color: '#ef6666',
  },
};

export const RESERVATION_STATUS_CLASSNAME_EVENT: {
  [key: number]: string;
} = {
  1: 'approved',
  2: 'approvedPending',
  3: 'pending',
  4: 'rejected',
  5: 'canceled',
};
export const RESERVATION_STATUS_LABELS: {
  [key: number]: {
    text: string;
    shortText: string;
    bg: string;
  };
} = {
  1: {
    text: 'Approved',
    shortText: 'AP',
    bg: '#299764', // #4bb5432b
  },
  2: {
    text: 'Approved Edit',
    shortText: 'APE',
    bg: '#4bb5432b', // #4bb5432b
  },
  3: {
    text: 'Pending',
    shortText: 'PE',
    bg: '#7E808A',
  },
  4: {
    text: 'Rejected',
    shortText: 'RE',
    bg: '#D93D42',
  },
  5: {
    text: 'Canceled',
    shortText: 'CA',
    bg: '#D93D42',
  },
};
export const RESERVATION_STATUS_STYLES: {
  [key: number]: {
    opacity: string;
    color: string;
  };
} = {
  1: {
    opacity: '0.8',
    color: '#FFF',
  },
  2: {
    opacity: '0.8',
    color: '#FFF',
  },
  3: {
    opacity: '0.14',
    color: '#FFF',
  },
  4: {
    opacity: '0.8',
    color: '#FFF',
  },
  5: {
    opacity: '0.4',
    color: '#000',
  },
};
export const BROADCAST_TYPE_LABELS: {
  [key: number]: string;
} = {
  0: 'All Users',
  1: 'All L1 Clients',
  2: 'All L1 Users',
  3: 'All L2 Users',
  4: 'All L3 Users',
};
export class AppData {
  static password_checks = [
    {
      id: PassChecks.OneLetter,
      title: 'At least one letter',
    },
    {
      id: PassChecks.OneCapitalLetter,
      title: 'At least one capital letter',
    },
    {
      id: PassChecks.OneNumber,
      title: 'At least one number',
    },
    {
      id: PassChecks.OneSpecialChar,
      title: 'At least one special character: @$!%*?&',
    },
    {
      id: PassChecks.MinFourChar,
      title: 'Be at least 4 character',
    },
  ];
}
export const socialMediaLogos = {
  12: 'Other.png',
  13: 'linkedin.png',
  14: 'googleScholar.png',
  15: 'researchGate.png',
  16: 'Personal.png',
  17: 'labWebsite.png',
  18: 'Team.png',
};

export const LEFT_BLOCK_SIZE = '3em';

export const TICKET_STATUS_COLORS = {
  '1': '#ffba00',
  '2': '#ccc',
  null: '#b1dfff',
};
export const TICKET_STATUS_LABELS = {
  1: 'Open',
  2: 'Closed',
  null: 'Broadcast',
};
export const defaultNotification = [
  {
    fromTo: [1, 9],
    title: 'Project',
    child: [
      {
        id: 1,
        key: 'newResearchProjects',
        title: 'New research projects',
        dashboard: false,
        email: false,
      },
      {
        id: 2,
        key: 'updatesOnResearchProjects',
        title: 'Updates on research projects',
        dashboard: false,
        email: false,
      },
    ],
  },

  {
    fromTo: [10, 19],
    title: 'Laboratory',
    child: [
      {
        id: 10,
        key: 'newLaboratory',
        title: 'New laboratory',
        dashboard: false,
        email: false,
      },
      {
        id: 11,
        key: 'updatesOnLaboratories',
        title: 'Updates on laboratories',
        dashboard: false,
        email: false,
      },
      {
        id: 12,
        key: 'newEquipment',
        title: 'New equipment',
        dashboard: false,
        email: false,
      },
      {
        id: 13,
        key: 'updatesOnEquipments',
        title: 'Updates on equipment',
        dashboard: false,
        email: false,
      },

      {
        id: 14,
        key: 'equipmentReservation',
        title: 'Equipment reservation',
        dashboard: false,
        email: false,
      },
      {
        id: 15,
        key: 'updatesOnEquipmentReservations',
        title: 'Updates on equipment reservations',
        dashboard: false,
        email: false,
      },
    ],
  },

  {
    fromTo: [30, 39],
    title: 'Team',
    child: [
      {
        id: 30,
        key: 'updatesOnTeam',
        title: 'Updates on team',
        dashboard: false,
        email: false,
      },
      {
        id: 31,
        key: 'newSubteam',
        title: 'New subteam',
        dashboard: false,
        email: false,
      },
      {
        id: 32,
        key: 'memberTransfer',
        title: 'Member transfer',
        dashboard: false,
        email: false,
      },
      {
        id: 33,
        key: 'accessRemovals',
        title: 'Access removals',
        dashboard: false,
        email: false,
      },
      {
        id: 34,
        key: 'userAccessUpdates',
        title: 'User access updates',
        dashboard: false,
        email: false,
      },
    ],
  },

  {
    fromTo: [50, 59],
    title: 'Task',
    child: [
      {
        id: 50,
        key: 'newTaskAssignment',
        title: 'New task assignment',
        dashboard: false,
        email: false,
      },
      {
        id: 51,
        key: 'updatesOnAssignedTasks',
        title: 'Updates on assigned tasks',
        dashboard: false,
        email: false,
      },
      {
        id: 52,
        key: 'datasetAttachmentToAssignedTasks',
        title: 'Dataset attachment to assigned tasks',
        dashboard: false,
        email: false,
      },
    ],
  },

  {
    fromTo: [70, 79],
    title: 'Discussion',
    child: [
      {
        id: 70,
        key: 'newMessageOnDiscussionPanels',
        title: 'New message on discussion panels',
        dashboard: false,
        email: false,
      },
    ],
  },

  {
    fromTo: [80, 89],
    title: 'Publication',
    child: [
      {
        id: 80,
        key: 'newPublication',
        title: 'New publication',
        dashboard: false,
        email: false,
      },
      {
        id: 81,
        key: 'updatesOnPublication',
        title: 'Updates on publication',
        dashboard: false,
        email: false,
      },
    ],
  },
];
// export const defaultNotification = [
//   {
//     fromTo: [1, 9],
//     title: "Research",
//     child: [
//       {
//         id: 1,
//         key: "researchCreate",
//         title: "New project added",
//         dashboard: false,
//         email: false,
//       },
//       {
//         id: 2,
//         key: "researchEdit",
//         title: "Project updated",
//         dashboard: false,
//         email: false,
//       },
//       {
//         id: 3,
//         key: "researchStatus",
//         title: "Project status changed",
//         dashboard: false,
//         email: false,
//       },
//     ],
//   },

//   {
//     fromTo: [10, 19],
//     title: "Laboratory",
//     child: [
//       {
//         id: 10,
//         key: "labCreate",
//         title: "New laboratory added",
//         dashboard: false,
//         email: false,
//       },
//       {
//         id: 11,
//         key: "labEdit",
//         title: "Laboratory updated",
//         dashboard: false,
//         email: false,
//       },
//       {
//         id: 12,
//         key: "labManager",
//         title: "Laboratory manager",
//         dashboard: false,
//         email: false,
//       },
//     ],
//   },

//   {
//     fromTo: [20, 29],
//     title: "Equipment",
//     child: [
//       {
//         id: 20,
//         key: "equipmentCreate",
//         title: "Equipment added",
//         dashboard: false,
//         email: false,
//       },
//       {
//         id: 21,
//         key: "equipmentStatus",
//         title: "Equipment status changed",
//         dashboard: false,
//         email: false,
//       },
//     ],
//   },

//   {
//     fromTo: [30, 39],
//     title: "Team",
//     child: [
//       {
//         id: 30,
//         key: "teamManager",
//         title: "Team manager",
//         dashboard: false,
//         email: false,
//       },
//       {
//         id: 31,
//         key: "teamMembers",
//         title: "Team members",
//         dashboard: false,
//         email: false,
//       },
//       {
//         id: 32,
//         key: "teamProjects",
//         title: "Team projects",
//         dashboard: false,
//         email: false,
//       },
//       {
//         id: 33,
//         key: "teamLab",
//         title: "Team laboratory",
//         dashboard: false,
//         email: false,
//       },
//       {
//         id: 34,
//         key: "teamEquipment",
//         title: "Team equipment",
//         dashboard: false,
//         email: false,
//       },
//       {
//         id: 35,
//         key: "subTeamCreated",
//         title: "New subteam added",
//         dashboard: false,
//         email: false,
//       },
//     ],
//   },

//   {
//     fromTo: [40, 49],
//     title: "Member",
//     child: [
//       {
//         id: 40,
//         key: "memberTeam",
//         title: "Member team",
//         dashboard: false,
//         email: false,
//       },
//       {
//         id: 41,
//         key: "memberProject",
//         title: "Member projects",
//         dashboard: false,
//         email: false,
//       },
//       {
//         id: 42,
//         key: "memberLab",
//         title: "Member laboratory",
//         dashboard: false,
//         email: false,
//       },
//       {
//         id: 43,
//         key: "memberEquipment",
//         title: "Member equipment",
//         dashboard: false,
//         email: false,
//       },
//     ],
//   },

//   {
//     fromTo: [50, 59],
//     title: "Task",
//     child: [
//       {
//         id: 50,
//         key: "taskCreate",
//         title: "New task added",
//         dashboard: false,
//         email: false,
//       },
//       {
//         id: 51,
//         key: "taskStatus",
//         title: "Task status changed",
//         dashboard: false,
//         email: false,
//       },
//     ],
//   },

//   {
//     fromTo: [60, 69],
//     title: "Data",
//     child: [
//       {
//         id: 60,
//         key: "dataCreate",
//         title: "Data added",
//         dashboard: false,
//         email: false,
//       },
//     ],
//   },

//   {
//     fromTo: [70, 79],
//     title: "Discussion",
//     child: [
//       {
//         id: 70,
//         key: "discussionCreate",
//         title: "New discussion added",
//         dashboard: false,
//         email: false,
//       },
//     ],
//   },

//   {
//     fromTo: [80, 89],
//     title: "Publication",
//     child: [
//       {
//         id: 80,
//         key: "publicationCreate",
//         title: "New publication added",
//         dashboard: false,
//         email: false,
//       },
//       {
//         id: 81,
//         key: "publicationRevision",
//         title: "Publication revision",
//         dashboard: false,
//         email: false,
//       },
//       {
//         id: 82,
//         key: "publicationStatus",
//         title: "Publication status changed",
//         dashboard: false,
//         email: false,
//       },
//     ],
//   },
// ];
