// import { useRef } from "react";
// import { useForm, Controller } from "react-hook-form";
// import Form from "../../../components/Form/Form";
// import Input from "../../../components/Form/Input";
// import InputLable from "../../../components/Form/InputLable";
// import InputGroup from "../../../components/Form/InputGroup";
// import GoogleLogin from "react-google-login";
import {  AppRoutes } from "../../../../core/constants";
// import { MainButton, MainButtonType } from "../../../components/button";
// import { ErrorMessage } from "@hookform/error-message";
import { RegisterTypes } from "./register_information";
// import Visible from "../../../components/visible";
// import InputMessage from "../../../components/Form/InputMessage";
// import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import YupPassword from "yup-password";
import { Link } from "react-router-dom";
YupPassword(yup);

export interface IRegisterInformationTypeInputs {
  email: string;
  password: string;
  confirmPassword: string;
  googleId?: string;
  firstName?: string;
  lastName?: string;
}

interface IRegisterInformationType {
  defaultValues?: IRegisterInformationTypeInputs;
  registerType: RegisterTypes;
  changeRegisterType: (value: RegisterTypes) => void;
  onSubmit: (values: IRegisterInformationTypeInputs) => void;
  isLoading?: boolean;
}

// const schema = yup
//   .object({
//     email: yup.string().email().required(),
//     password: yup.string().password().required(),
//     confirmPassword: yup.string().password().required(),
//     googleId: yup.string().optional(),
//     firstName: yup.string().optional(),
//     lastName: yup.string().optional(),
//   })
//   .required();

function RegisterInformationType({
  // defaultValues,
  // registerType,
  // changeRegisterType,
  // onSubmit,
  // isLoading = false,
}: IRegisterInformationType): JSX.Element {
  // const colectedDatas = useRef<IRegisterInformationTypeInputs>({
  //   email: "",
  //   password: "",
  //   confirmPassword: "",
  // });

  // const {
  //   handleSubmit,
  //   getValues,
  //   setValue,
  //   setError,
  //   control,
  //   formState: { errors },
  // } = useForm<IRegisterInformationTypeInputs>({
  //   resolver: yupResolver(schema),
  // });

  // useEffect(() => {
  //   if (defaultValues) {
  //     setValue("email", defaultValues.email);
  //     setValue("password", defaultValues.password);
  //     setValue("confirmPassword", defaultValues.confirmPassword);
  //     colectedDatas.current = {
  //       ...colectedDatas.current,
  //       email: defaultValues.email,
  //       password: defaultValues.password,
  //       confirmPassword: defaultValues.confirmPassword,
  //     };
  //   }
  // }, [defaultValues, registerType]);

  // const onSubmitForm = () => {
  

  //   if (registerType === "manual") {
  //     const [password, confirmPassword] = getValues([
  //       "password",
  //       "confirmPassword",
  //     ]);

  //     if (!isValidPassword(password, confirmPassword)) {
  //       return;
  //     }
  //   }
  //   const [password, confirmPassword, email] = getValues([
  //     "password",
  //     "confirmPassword",
  //     "email",
  //   ]);

  

  //   updateAndSendData({
  //     email,
  //     password,
  //     confirmPassword,
  //   });
  // };

  // const updateAndSendData = (values: IRegisterInformationTypeInputs) => {
  //   colectedDatas.current = {
  //     ...colectedDatas.current,
  //     ...values,
  //   };
  //   onSubmit(colectedDatas.current);
  // };

  // const isValidPassword = (password?: string, confirmPassword?: string) => {
  //   let isValid = true;
  //   // if (password !== confirmPassword) {
  //   //   setError("password", {
  //   //     message: "password and confirmPassword is not same",
  //   //   });
  //   //   isValid = false;
  //   // }
  //   return isValid;
  // };

  

  return (
    <div className="w-100 d-flex flex-column align-items-center">
      <h1 className="right_login_title mb-4">Sign Up</h1>
      {/* <Form className="w-100" onSubmit={handleSubmit(onSubmitForm)}>
        <>
          <InputLable label="Email:">
            <>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="email"
                    disabled={registerType === "google"}
                    readOnly={registerType === "google"}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => <InputMessage message={message} />}
              />
            </>
          </InputLable>
          <Visible visible={registerType === "manual"}>
            <InputGroup>
              <InputLable label="Password:">
                <>
                  <Controller
                    control={control}
                    name="password"
                    render={({ field }) => <Input {...field} type="password" />}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="password"
                    render={({ message }) => <InputMessage message={message} />}
                  />
                </>
              </InputLable>
              <InputLable label="Confirm Password:">
                <>
                  <Controller
                    control={control}
                    name="confirmPassword"
                    render={({ field }) => <Input {...field} type="password" />}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="confirmPassword"
                    render={({ message }) => <InputMessage message={message} />}
                  />
                </>
              </InputLable>
            </InputGroup>
          </Visible>
          <div className="mt-2 d-flex justify-content-center">
            <MainButton
              children={"Next"}
              type={MainButtonType.light}
              buttonType="submit"
              borderRadius="50px"
              minWidth="200px"
              minHeight="37px"
              className="align-self-end"
              loading={isLoading}
            ></MainButton>
          </div>
        </>
      </Form>
      <div className="line">Or sign up with</div>
      <div className="d-flex flex-column w-80">
        <GoogleLogin
          clientId={clientId}
          buttonText="Sign Up using Google"
          onSuccess={(res: any) => {
            changeRegisterType("google");
            const { email, familyName, googleId, givenName } = res.profileObj;

            setValue("password", AppConstants.fakePassword);
            setValue("confirmPassword", AppConstants.fakePassword);

            updateAndSendData({
              email,
              firstName: givenName,
              lastName: familyName,
              googleId,
              password: AppConstants.fakePassword,
              confirmPassword: AppConstants.fakePassword,
            });
          }}
          onFailure={(err) => {
            
          }}
          cookiePolicy={"single_host_origin"}
          style={{
            borderRadius: "50px",
          }}
        />
      </div> */}

      <p style={{ color: "rgba(255,255,255,0.56)" }} className="mt-4">
        Already have an account?{" "}
        <Link style={{ color: "#fff" }} to={AppRoutes.login}>
          Sign Up
        </Link>
      </p>
    </div>
  );
}

export default RegisterInformationType;
