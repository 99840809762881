import axios from "axios";
// import fileDownload from "js-file-download";
import { ItemDataType } from "rsuite/esm/@types/common";
// import { LocalDataSources } from "../data/local_datasources";
import { Member, User } from "../data/models/responses/member/member_list_res";
import { Equipment } from "../data/models/responses/member/update_member_res";
import { Team } from "../data/models/responses/research/research_by_id_res";
import { Laboratories } from "../data/models/responses/team/team_create_res";
import { AppConstants, AppRoutes, DEFAULT_RESEARCH } from "./constants";
import { HTTP, IMAGE_BASE_URL, handleUnauthorizedRequest } from "./http_common";
import { store } from "../data/storeMain";
import { SetResearchId } from "../data/storeMain/actions/research_action";
import { worSpaceController } from "../controllers/workSpace/workSpace";
import { ProfileTypes } from "./number_extentions";
import { number } from "yup";
import { useEffect, useState } from "react";

export enum DiscussionTopic{
  Project ,
  GeneralTopic,
  Task,
  Data,
  Equipment,
  Laboratory,
  Team,
  Publication,
  Expense,
  Ticket,
  Broadcast
}

export enum Theme {
  light,
  dark,
}
export enum UserRoles {
  Admin,
  L1Client,
  L1User,
  L2User,
  L3User,
  Unspecified,
  ExternalUser,
}

export enum UserStatus{
  active,
  readOnly,
  deActivated,
  invited
}

export enum WorkspaceCollaborationEnum{
    Requested,
    Connected,
    Declined,
}

export enum SubscriptionStatusEnum
{
    trial,
    active ,
    nopay ,
    inactive,
    cancelled,
    devAccount,
    enterprise
}
export enum subscriptionPlan{
    launchpad = 1,
    collaborator = 2,
    standard = 3,
    enterprise = 4,
    ultimate = 5,
    special = 6,
    
}
export enum AiInstructionsEnum {
  notification = 0,
learningCenter = 1
}

// interface TreeValueUser extends User {
//   teamsIds: number[];
// }
export const isTokenInHttpAuth = (_HTTP: any) =>
  _HTTP?.defaults.headers.common["authorization"];
export const getUserTitle = (_user: Member | any) => {
  // if (_user.firstName === " ") console.log(_user.firstName === " ", _user);
  // console.log(_user);
  if (!_user) return "No User";
  return _user.firstName === " "
    ? _user.email
      ? _user.email
      : `* No Email ...${_user.id.toString().slice(-4)}`
    : _user.firstName ? (_user.firstName + " " + _user.lastName) : _user.name;
};

export const getUserInitails = (_user: string) => {
  // if (_user.firstName === " ") console.log(_user.firstName === " ", _user);
  // console.log(_user);
  const user = _user.split(" ");
  if(user && user.length > 1){
    return `${user[0].charAt(0)}${user[1].charAt(0)}`;
  }
  else{
    return `${user[0].charAt(0)}`
  }
};
export const AccessPermition = (
  role: UserRoles,
  AccessList: Array<UserRoles>
) => {
  return AccessList.includes(role);
};
export const $truncate = (str: string, len: number) => {
  if (str.length > len) {
    if (len <= 3) {
      return str.slice(0, len - 3) + "...";
    } else {
      return str.slice(0, len) + "...";
    }
  } else {
    return str;
  }
};
// const local = new LocalDataSources();
// const companyName = local.getUserInfo().institution;
export const $ReplaceRoute = (route: string) => {
  const _userInfo: any = JSON.parse(localStorage.getItem("userInfo") || "{}");
  if (!_userInfo) return route;
  // console.log("ReplaceRoute: ", route, _userInfo, route.replace(":company", _userInfo?.institution));
  // console.log("---------------------------------")
  // if (!companyName) {
  //   return route;
  // }
  
  if (_userInfo.role === UserRoles.Admin) {
    return route.replace(":company", "controlPanel");
  }
  return `${route.replace(":company", _userInfo?.institution)}`;
};
export const HTTP_GET_FILE = axios.create({});
let token = localStorage.getItem("token");
if (token) {
  HTTP_GET_FILE.defaults.headers.common["authorization"] = `Bearer ${token}`;
}
export const getAmazonUrl = (url: string) => {
  return new Promise(
    (
      resolve: {
        (value: string): void;
      },
      reject
    ) => {
      const _originalImage = url.replace(/\//g, "%2F");
      const _src = `${IMAGE_BASE_URL}/api/Media?fileName=${_originalImage}`;
      HTTP.get(_src)
        .then((res) => {
          if (res.data) return resolve(res.data.result);
          return reject("No Data");
        })
        .catch((_err) => {
          reject(_err);
        });
    }
  );
};

// export const downloadFile = async (
//   Path: any,
//   FileName: string,
//   OriginalName: string | undefined
// ) => {
//   console.log("downloadFile: ", Path, FileName, OriginalName);

//   const _amazonUrl: string = await getAmazonUrl(OriginalName || "");

//   // fetch(_amazonUrl, {
//   //   method: "get",
//   //   mode: "no-cors",
//   //   referrerPolicy: "no-referrer",
//   //   headers: {
//   //     "X-RapidAPI-Key": "your-rapidapi-keyasddddddddddddddddddddddd",
//   //     "X-RapidAPI-Host": "famous-quotes4.p.rapidapi.com",
//   //   },
//   // })
//   //   .then((res) => res.blob())
//   //   .then((res) => {

//   const aElement = document.createElement("a");
//   aElement.setAttribute("download", FileName);
//   // const href = URL.createObjectURL(res);
//   aElement.href = _amazonUrl;
//   // aElement.setAttribute('href', href);
//   aElement.setAttribute("target", "_blank");
//   aElement.click();
//   // URL.revokeObjectURL(href);
//   // });

//   // create an a tag and click on it to download the file
//   // const link = document.createElement("a");
//   // link.href = _amazonUrl;
//   // // link.download = 'ASTM-Report.pdf';
//   // link.target = "_blank";
//   // // link.click();
//   // document.body.appendChild(link)
//   // link.click()
//   // document.body.removeChild(link)

//   // HTTP_GET_FILE.get(_amazonUrl, {
//   //   responseType: "blob",
//   // }).then((response) => {
//   //   const extension = Path.split(".")?.at(-1);
//   //   // console.log(extension)
//   //   // fileDownload(response.data, `radvix.${extension}`);
//   //   // const _fileName = Pat.split("/")?.at(-1);
//   //   fileDownload(response.data, `${FileName}.${extension}`);
//   // });
//   // HTTP_GET_FILE.get(Path, {
//   //   responseType: "blob",
//   // }).then((response) => {
//   //   const extension = Path.split(".")?.at(-1);
//   //   // console.log(extension)
//   //   // fileDownload(response.data, `radvix.${extension}`);
//   //   // const _fileName = Pat.split("/")?.at(-1);
//   //   fileDownload(response.data, `${FileName}.${extension}`);
//   // });
// };

export async function uploadToS3PresignedUrl(
  fileObject: any,
  presignedUrl: string | undefined,
  mediaId: number | undefined
) {
  if (!presignedUrl || !mediaId) return;
  // var myHeaders = new Headers();
  // myHeaders.append("Content-Type", "image/png");

  // var file = fileObject;

  // var requestOptions = {
  //   method: "PUT",
  //   headers: myHeaders,
  //   body: file,
  //   redirect: "follow",
  // };

  try {
    await axios.put(presignedUrl, fileObject, {
      headers: {
        "Content-Type": fileObject.type,
        "Cache-Control": "max-age=6000",
      },
      maxBodyLength: Infinity,
    });

    // await HTTP.post("/Media/MediaUploadStatus", {
    //   mediaId: mediaId,
    //   isUploadedSuccessfully: true,
    // });
  } catch (e) {
    
    // await HTTP.post("/Media/MediaUploadStatus", {
    //   mediaId: mediaId,
    //   isUploadedSuccessfully: false,
    // });
  }
}
// export async function uploadToS3PresignedUrl(
//   fileObject: any,
//   presignedUrl: string | undefined
// ) {
//   if (!presignedUrl) return;

//   try {
//     const requestOpt = {
//       method: "PUT",
//       // headers: {
//       //   "Content-Type": fileObject.type,
//       // },
//       body: fileObject,
//     };
//     console.log({presignedUrl, requestOpt})
//     const res = await fetch(presignedUrl, requestOpt);

//     return res;
//   } catch(e) {
//     console.log(e)
//   }

// }
export const returnArrayOfNumber = (_count: number) => {
  let arr = [];
  for (let i = 0; i < _count; i++) {
    arr.push(i);
  }
  return arr;
};

export const isArrayDataEqual = (arr1: any[], arr2: any[]) => {
  const _arr1 = arr1.map((item) => JSON.stringify(item));
  const _arr2 = arr2.map((item) => JSON.stringify(item));
  return (
    _arr1.length === _arr2.length &&
    _arr1.every((value, index) => value === _arr2[index])
  );
};

export function convertEquipsToUncategorized(
  _equips: Equipment[],
  _forceCheckedEquips?: number[]
) {
  return {
    label: "Equipment Only Access",
    value: -1,
    children: _equips.map((_eq) => {
      return {
        label: _eq.title,
        value: _eq.id,
        checked: _forceCheckedEquips
          ? _forceCheckedEquips.includes(_eq.id)
          : false,
      };
    }),
  };
}
export function convertUsersToUncategorized(
  _users: Member[],
  _forceCheckedUser?: string[]
) {
  return {
    label: "Uncategorized",
    value: -1,
    children: _users.map((_u) => {
      const _userTitle = getUserTitle(_u);
      return {
        label: _userTitle,
        value: _u.id,
        checked: _forceCheckedUser ? _forceCheckedUser.includes(_u.id) : false,
      };
    }),
  };
}
export function convertTeamUserToNewTree(
  _teams: Team[],
  _forceCheckedUser?: string[]
) {
  return _teams?.map((_team) => {
    return {
      label: _team.title,
      value: _team.id,
      children: _team.users.map((_u: any) => {
        const _userTitle = getUserTitle(_u);
        return {
          label: _userTitle,
          value: _u.id,
          checked: _forceCheckedUser
            ? _forceCheckedUser.includes(_u.id)
            : false,
        };
      }),
    };
  });
}
export function convertLabEquipsToNewTree(
  _labs: Laboratories[],
  _forceCheckedEquips?: number[]
) {
  return _labs.map((_lab) => {
    return {
      label: _lab.title,
      value: _lab.id,
      children: _lab.equipments.map((_eq) => {
        return {
          label: _eq.title,
          value: _eq.id,
          checked: _forceCheckedEquips
            ? _forceCheckedEquips.includes(_eq.id)
            : false,
        };
      }),
    };
  });
}

export const convertTeamUserToTree = (team: Team[]): ItemDataType[] => {
  const data = team.map((item) => {
    return {
      label: item.title,
      value: `${item.id}`,
      groupBy: `${item.id}`,
      children: Array.isArray(item.users)
        ? item.users.map((user) => {
          return {
            label: getUserTitle(user as unknown as Member),
            value: `Member${AppConstants.divide}${user.id}`,
            groupBy: `${item.id}`,
          };
        })
        : [],
    };
  });

  return data;
};
export const convertLabEquipToTree = (team: Laboratories[]): ItemDataType => {
  const data = team.map((item) => {
    return {
      label: item.title,
      value: `${item.id}`,
      groupBy: `${item.id}`,
      children: Array.isArray(item.equipments)
        ? item.equipments.map((equipment) => {
          return {
            label: equipment.title,
            value: `Equip${AppConstants.divide}${equipment.id}`,
            groupBy: `${item.id}`,
          };
        })
        : [],
    };
  });

  return data;
};
export const getUserRole = () => {
  const _userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  
  if (!_userInfo) return -1;
  return _userInfo.role;
};
export const filterUsersNotExistInTeam = (
  teams: Team[],
  users: User[]
): null | ItemDataType => {
  // console.log("utils: ", { teams, users });
  if (!users) return null;
  const _users = users.filter((user) => {
    let isExist = false;
    // console.log(user);
    teams.forEach((team) => {
      const index = team.users.findIndex((teamUser) => teamUser.id === user.id);
      if (index >= 0) {
        isExist = true;
      }
    });

    return !isExist;
  });
  // console.log(_users);

  return _users.length > 0
    ? {
      label: "Uncategorized",
      value: `Uncategorized`,
      groupBy: `Uncategorized`,
      children: _users.map((user) => {
        return {
          label: getUserTitle(user as unknown as Member),
          value: `Member${AppConstants.divide}${user.id}`,
          groupBy: `Uncategorized`,
        };
      }),
    }
    : null;
};

export const filterEqipmetsNotExistInLabs = (
  labs: Laboratories[],
  equips: Equipment[]
): null | ItemDataType => {
  const _equips = equips.filter((equip) => {
    let isExist = false;

    labs.forEach((lab) => {
      const index = lab.equipments.findIndex(
        (equipLab) => equipLab.id === equip.id
      );
      if (index >= 0) {
        isExist = true;
      }
    });

    return !isExist;
  });

  return _equips.length > 0
    ? {
      label: "Equipment Only Access",
      value: `Equipment Only Access`,
      groupBy: `Equipment Only Access`,
      children: _equips.map((equipment) => {
        return {
          label: equipment.title,
          value: `Equip${AppConstants.divide}${equipment.id}`,
          groupBy: `Equipment Only Access`,
        };
      }),
    }
    : null;
};

export const convertUsersIdsToIds = (values: string[]) => {
  return values.map((item) => item.split(AppConstants.divide)[1]);
};
export const convertEquipsIdsToIds = (values: string[]) => {
  return values.map((item) => item.split(AppConstants.divide)[1]);
};

export const removeDuplicat = (array: any[]) => {
  return array.filter((item, index) => array.indexOf(item) === index);
};

export const removeDuplicateObj = (things: Object[]) => {
  if (!things || things.length === 0) return [];
  return things.filter((thing, index) => {
    const _thing = JSON.stringify(thing);
    return (
      index ===
      things.findIndex((obj) => {
        return JSON.stringify(obj) === _thing;
      })
    );
  });
};
export const removeDuplicateObjWithKey = (arr: any[], key: string) => {
  const map = new Map();
  return arr.filter((item) => !map.has(item[key]) && map.set(item[key], 1));
};
export const removeTeamUsersFromSelectedUsers = (
  teams: Team[],
  users: User[]
): User[] => {
  const _users = [...users];
  teams.forEach((team) => {
    team.users.forEach((user) => {
      const _index = _users.findIndex((item) => item.id === user.id);
      if (_index >= 0) {
        _users.splice(_index, 1);
      }
    });
  });
  return _users;
};
// export const debounce = (func: any, wait: number) => {
//   let timeout: any;
//   return function (this: any, ...args: any) {
//     const context = this;
//     clearTimeout(timeout);
//     timeout = setTimeout(() => func.apply(context, args), wait);
//   };
// };
// export const removeTeamUsersFromSelectedUsers = (teams: Team[], users: User[]): User[] => {
//   const _users = [...users]
//   teams.forEach(team => {
//     team.users.forEach(user => {
//       const _index = _users.findIndex(item => item.id === user.id)
//       if (_index >= 0) {
//         _users.splice(_index, 1)
//       }
//     })
//   })
//   return _users;
// }

export async function uploadAllFiles(
  _sectionId: number,
  _files: File[],
  _useCase: number
) {
  if (_files.length === 0) return;
  const _myRequests = [];
  for (let i = 0; i < _files.length; i++) {
    const _file = _files[i];
    _myRequests.push(handleUploadSingleFile(_sectionId, _file, _useCase));
  }

  await Promise.all(_myRequests);
}

export async function uploadAllLinks(
  _sectionId: number,
  _links: string[],
  _useCase: number
) {
  if (_links.length === 0) return;
  const formData = new FormData();
  formData.append("UseCase", _useCase.toString());
  formData.append("SectionId", _sectionId.toString());

  _links.forEach((_link) => {
    formData.append("ExternalUrls", _link);
  });

  await handleUseMediaApi(formData);
}

function formatBytes(bytes: number, decimals: number = 2): number {
  if (!+bytes) return 0;

  const m = 1024 * 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizeInMB = bytes / m;

  return parseFloat(sizeInMB.toFixed(dm));
}


export async function handleUploadSingleFile(
  _sectionId: number,
  _file: File,
  _useCase: number
) {
  try {
    const formData = new FormData();
    formData.append("UseCase", _useCase.toString());
    formData.append("SectionId", _sectionId.toString());
    formData.append("FileName", _file.name);
    formData.append("FileSize", String(formatBytes(_file.size)));
    const _mediaApiRes = await handleUseMediaApi(formData);
    await uploadToS3PresignedUrl(
      _file,
      _mediaApiRes?.cloudFrontUrl,
      _mediaApiRes?.mediaId
    );
  } catch (error) {
    
  }
}

export function handleUseMediaApi(_formData: any): Promise<
  | {
    cloudFrontUrl: string;
    mediaId: number;
  }
  | undefined
> {
  return new Promise((resolve, reject) => {
    HTTP.post("/Media", _formData)
      .then((res) => resolve(res.data?.result))
      .catch((err) => reject(err));
    // this.UploadController.UloadMedia(
    //   _formData,
    //   (res) => {
    //     resolve(res?.result);
    //   },
    //   () => {
    //     reject(false);
    //   }
    // );
  });
}

export function hashCode(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

export function intToRGB(i: number): string {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();
  return "00000".substring(0, 6 - c.length) + c;
}

export function generateColorFromName(name: string): {
  backgroundColor: string;
  textColor: string;
} {
  const hash = hashCode(name);
  const color = intToRGB(hash);
  const backgroundColor = `#${color}`;
  const red = parseInt(color.substring(0, 2), 16);
  const green = parseInt(color.substring(2, 4), 16);
  const blue = parseInt(color.substring(4, 6), 16);
  const relativeLuminance = 0.2126 * red + 0.7152 * green + 0.0722 * blue;
  const textColor = relativeLuminance > 128 ? "#000000" : "#ffffff";
  return { backgroundColor, textColor };
}

export function separateString(s: any) {
  let result = "";
  for (let i = 0; i < s.length; i++) {
    if (s.charAt(i) === s.charAt(i).toUpperCase() && i > 0) {
      result += " ";
    }
    result += s.charAt(i);
  }
  return result;
}

export function removeGmtFromDate(_date: Date) {
  const year = _date.getFullYear();
  const month = String(_date.getMonth() + 1).padStart(2, "0");
  const day = String(_date.getDate()).padStart(2, "0");
  const hour = String(_date.getHours()).padStart(2, "0");
  const minute = String(_date.getMinutes()).padStart(2, "0");
  const second = String(_date.getSeconds()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}T${hour}:${minute}:${second}`;
  return formattedDate;
}

export function addSpaceBetweenWords(str: string): string {
  return str
    .replace(/([A-Z])/g, " $1") // Insert space before uppercase letters
    .trim();
}
// export function addSpaceBetweenWords(str: string): string {
//   return str.replace(/([a-z])([A-Z])/g, "$1 $2");
// }

export function securePhoneNumber(phoneNumber: string): string {
  // show last 4 digit and others are replaced with *
  const securePhoneNumber =
    phoneNumber.substring(0, phoneNumber.length - 4).replace(/./g, "*") +
    phoneNumber.substring(phoneNumber.length - 4);

  return securePhoneNumber;
}
export function secureEmail(email: string): string {
  const username = email.substring(0, 3);
  const domain = email.substring(email.indexOf("@"));
  const secureEmail = username + "***" + domain;

  return secureEmail;
}

export const handleClearStorageAndTokens = () => {
  const setting: any = localStorage.getItem("setting");
  localStorage.clear();
  localStorage.setItem("setting", setting);

  delete HTTP.defaults.headers.common["authorization"];
  store.dispatch(SetResearchId(DEFAULT_RESEARCH));
  // window.location.replace(AppRoutes.login);

  // @ts-ignore
  window.tidioChatApi?.setContactProperties({
    subscription_id: 0,
    subscription_type: " ",
    user_type: " ",
  });
};

export const handleLogoutApi = () => {
  return new Promise((resolve, reject) => {
    const _deviceId = localStorage.getItem("deviceId");
    
    if (!_deviceId) {
      reject("Device Id not found")
      return
    }
    HTTP.post(`/User/Logout?deviceId=${_deviceId}&deviceToken=a`)
      .then((res) => {
        resolve(res.data?.result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const handleNotificationTypeToProfileType = (selectedNotificationType: any): number => {
  if(selectedNotificationType == 1){
    return ProfileTypes.RESEARCH;
  }
  if(selectedNotificationType == 2){
    return ProfileTypes.TEAMPROFILE;
  }
  if(selectedNotificationType == 3){
    return ProfileTypes.LABORTARYPROFILE;
  }
  if(selectedNotificationType == 4){
    return ProfileTypes.TASK;
  }
  if(selectedNotificationType == 5){
    return ProfileTypes.DATA;
  }
  if(selectedNotificationType == 6){
    return ProfileTypes.DISCUSSIONPROFILE;
  }
  if(selectedNotificationType == 7){
    return ProfileTypes.PUBLICATIONFLOW;
  }
  else{
    return 0;
  }
}
export const verifyUserSubscription = (props: any, res?: any) => {
  const workspaceController = new worSpaceController();
  workspaceController.isWorkScpaceExist(
    (resWorkSpace) => {
      const company = store.getState().userInfo.companyName ? store.getState().userInfo.companyName : store.getState().userInfo.institution;
      const userRedirect = resWorkSpace.data.value.result.userRedirect;  
      //store.dispatch(SetUserInfo(resWorkSpace.data.value.result));    
      switch (userRedirect) {
        case 0:
          props.history.push(AppRoutes.workSpace);
          break;
        case 1:
          props.history.push(AppRoutes.UserSubscriptions);
          break;
        case 2:
          props.history.push(AppRoutes.UserPayment);
          break;
        case 3:
          props.history.push(AppRoutes.UserPayment);
          break;
        case 4:
          const _splitedPatch = props.location.pathname.split("/");
          if (_splitedPatch.length > 2) {
            const _isInDashboard = _splitedPatch[2] === "dashboard";
            if (!_isInDashboard) {
              props.history.push(`/${company}/dashboard/home`);    
            }
          }
          else if(_splitedPatch.length > 1){
            if(_splitedPatch[1] != "UpdateSubscriptions"){
              props.history.push(`/${company}/dashboard/home`);
            }
          }
          else{
            props.history.push(`/${company}/dashboard/home`);
          }
          
          break;
        default:
          
          break;
      }
    },
    (err) => {
      handleLogoutApi().then((res) => {
        handleClearStorageAndTokens()
        props.history.push(AppRoutes.login);
      }).catch((err) => {
        if (err === "Device Id not found") {
          handleClearStorageAndTokens();
          window.location.replace(AppRoutes.login);
          return;
        }
        const _tokenInStorage = localStorage.getItem('token')
        if (!_tokenInStorage) {
          handleClearStorageAndTokens();
          props.history.push(AppRoutes.login);
        } else {
          if (err.response && err.response.status === 401) {
            // Retry the request with refreshed token
            return handleUnauthorizedRequest(err.config)
              .then((_res) => {
                handleClearStorageAndTokens()
                props.history.push(AppRoutes.login);
    
              })
            // .catch((e) => error(e));
          } else {
            handleClearStorageAndTokens()
            props.history.push(AppRoutes.login);
            // error(err);
          }
        }
      });
    }
  );
}


export default function useMediaQuery(query: any) {
  const [matches, setMatches] = useState(false);
  useEffect(
    () => {
      const mediaQuery = window.matchMedia(query);
      setMatches(mediaQuery.matches);
      const handler = (event: any) => setMatches(event.matches);
      mediaQuery.addEventListener("change", handler);
      return () => mediaQuery.removeEventListener("change", handler);
    },
    [] // Empty array ensures effect is only run on mount and unmount
  );
  return matches;
}

export const isOnlyWhitespace = (str: string): boolean => str.trim().length === 0;

export const formattedCurrency = (amount: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);