import { useHistory } from 'react-router';
import { AppRoutes } from '../../../../core/constants';
import { $ReplaceRoute } from '../../../../core/utils';
import { MainButton } from '../../../components/button';
import { ReactNode } from 'react';

type AddNewButtonProps = {
  children: ReactNode;
  hasIcon?: boolean;
  onClick: () => void;
};

export default function AddNewButton({ children, hasIcon, onClick }: AddNewButtonProps) {
  const history = useHistory();

  return (
    <MainButton
      className='btn btn-primary btn-sm fs-13 px-2 h-px-40 min-w-px-40 py30'
      svg={
        hasIcon ? (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='15'
            height='16'
            viewBox='0 0 15 16'
            fill='none'
          >
            <rect
              width='15'
              height='15'
              transform='translate(0 0.5)'
              fill='white'
              fill-opacity='0.01'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M8 3.25C8 2.97386 7.77614 2.75 7.5 2.75C7.22386 2.75 7 2.97386 7 3.25V7.5H2.75C2.47386 7.5 2.25 7.72386 2.25 8C2.25 8.27614 2.47386 8.5 2.75 8.5H7V12.75C7 13.0261 7.22386 13.25 7.5 13.25C7.77614 13.25 8 13.0261 8 12.75V8.5H12.25C12.5261 8.5 12.75 8.27614 12.75 8C12.75 7.72386 12.5261 7.5 12.25 7.5H8V3.25Z'
              fill='white'
            />
          </svg>
        ) : null
      }
      onClick={onClick}
    >
      {children}
    </MainButton>
  );
}
