// import { useState } from "react";
import { AccessPermition, UserRoles } from "../../../core/utils";
import { store } from "../../../data/storeMain";
import { SetResearchId } from "../../../data/storeMain/actions/research_action";
import { ButtonGroup } from "../../components/botton_group";
import { SelectComponent } from "../../components/select_input";
// onSelectTask={(e) => {}}
//                 onChangeIsDiscusstionForSubTask={(e) => {
//                   // e.value [0 No, 1 Yes]
//                 }}
//                 onSelectSubTask={(e) => {}}

//                 onChangeExpenseMode={(e) => {
//                   // e.value [0 task, 1 subtask]
//                 }}
//                 onSelectExpense={(e) => {}}

//                 onSelectPublication={(e) => {}}
interface Props {
  selectedProject: any;
  listResearch: any[];
  onResearchChange: (e: any) => void;
  listExpense: any[];
  typeId: number;
  listTasks: any[];
  listSubTasks: any[];
  listDataSets: any[];
  listPublication: any[];
  listEquipment: any[];
  listLaboratory: any[];
  listTeam: any[];
  selectedPublication: { value: number; label: string } | null;
  selectedTask: { value: number; label: string } | null;
  selectedSubTask: { value: number; label: string } | null;
  selectedExpense: { value: number; label: string } | null;
  selectedDataSet: { value: number; label: string } | null;
  selectedEquipment: { value: number; label: string } | null;
  selectedLaboratory: { value: number; label: string } | null;
  selectedTeam: { value: number; label: string } | null;
  myRole: number;
  isDiscussionForSubTask: number;
  currentResearch: {
    id: number;
    name: string;
  };
  onSelectTask: (e: any) => void;
  onChangeIsDiscusstionForSubTask: (e: any) => void;
  onSelectSubTask: (e: any) => void;

  onChangeExpenseMode: (e: any) => void;
  onSelectExpense: (e: any) => void;
  onSelectPublication: (e: any) => void;
  onSelectDataSet: (e: any) => void;
  onSelectEquipment: (e: any) => void;
  onSelectLaboratory: (e: any) => void;
  onSelectTeam: (e: any) => void;
  validator?: any;
  disabledAll: boolean;
}

const General: React.FC<Props> = () => {
  return <div></div>;
};
const TaskSubTask: React.FC<Props> = ({
  selectedProject,
  listResearch,
  onResearchChange,
  listTasks,
  listSubTasks,
  selectedTask,
  selectedSubTask,
  myRole,
  onSelectTask,
  onSelectSubTask,
  isDiscussionForSubTask,
  onChangeIsDiscusstionForSubTask,
  validator,
  disabledAll,
  typeId,
}) => {
  // const [isForSubTask, setIsForSubTask] = useState(0);
  return (
    <>
      {/* {myRole} */}
      <div className="row mt-1 mb-3">
        <SelectComponent
          //disabled={this.state.searchLoading}
          items={listResearch}
          defaultValue={selectedProject}
          TextItem="title"
          ValueItem="id"
          labelClass="color-gray-600 fs-15 me-2"
          className="form-select control-border-color"
          label="Projects*"
          placeholder="Select project"
          onChange={(e) => {
            onResearchChange(e);
            //store.dispatch(SetResearchId({ label: e?.label, value: e?.value }));
          }}
          isMulti={false}
        ></SelectComponent>
      </div>
      <div className="item">
        <SelectComponent
          disabled={disabledAll}
          items={
            typeId
              ? typeId === 3
                ? listTasks
                : typeId === 2
                ? listSubTasks
                : listTasks
              : listTasks
          }
          defaultValue={
            typeId
              ? typeId === 3
                ? selectedTask
                : typeId === 2
                ? selectedSubTask
                : selectedTask
              : selectedTask
          }
          label={typeId === 2 ? "Select subtask" : "Select task"}
          // popQuestion="Select Task For Discussion:"
          TextItem="name"
          // ValueItem="id"
          className="mt-1"
          placeholder="Click to see the list…"
          onChange={(e) => {
            if (typeId === 2) {
              onSelectSubTask(e);
            } else {
              onSelectTask(e);
            }
            //   this.handelSelectMembers(e);
            // if role is L3User then call onSelectSubTask cb otherwise onSelectTask

            // if (myRole === UserRoles.L3User) {
            //   // call onSelectSubTask
            //   onSelectSubTask(e);
            // } else {
            // call onSelectTask
            //onSelectTask(e);
            // }
          }}
          inValid={
            isDiscussionForSubTask
              ? null
              : typeId === 2
              ? validator.message("task", selectedTask, "required")
              : validator.message("task", selectedSubTask, "required")
          }
          // isMulti
          // inValid={this.validator.message(
          //   "message",
          //   this.state.message,
          //   "required"
          // )}
        ></SelectComponent>
      </div>

      {AccessPermition(myRole, [UserRoles.L2User || UserRoles.L3User]) ? (
        <>
          {selectedTask &&
          selectedTask.value &&
          (typeId === 2
            ? listTasks != null && listTasks.length > 0
            : listSubTasks != null && listSubTasks.length > 0) ? (
            <div className="item" hidden>
              <ButtonGroup
                disabled={disabledAll}
                label="Is discusstion for subtask?"
                // popQuestion="Priority Level:"
                name="discusType"
                items={[
                  {
                    id: 1,
                    name: "Yes",
                  },
                  {
                    id: 0,
                    name: "No",
                  },
                ]}
                TextItem="name"
                ValueItem="id"
                selected={isDiscussionForSubTask}
                onChange={(e) => {
                  onChangeIsDiscusstionForSubTask(parseInt(e.target.value));
                }}
              ></ButtonGroup>
            </div>
          ) : null}

          {/* {isDiscussionForSubTask === 1 ? (
            <div className="item">
              <SelectComponent
                disabled={disabledAll}
                inValid={validator.message(
                  "subtask",
                  selectedSubTask,
                  "required"
                )}
                items={listSubTasks}
                label="Select subtask"
                // popQuestion="Select Subtask For Discussion:"
                TextItem="name"
                // ValueItem="id"
                className="mt-1"
                placeholder="Click to see the list…"
                defaultValue={selectedSubTask}
                onChange={(e) => {
                  onSelectSubTask(e);
                  //   this.handelSelectMembers(e);
                }}
              // isMulti
              // inValid={this.validator.message(
              //   "message",
              //   this.state.message,
              //   "required"
              // )}
              ></SelectComponent>
            </div>
          ) : null} */}
        </>
      ) : null}
    </>
  );
};
const DataSet: React.FC<Props> = ({
  selectedProject,
  listResearch,
  onResearchChange,
  listTasks,
  listDataSets,
  listSubTasks,
  myRole,
  typeId,
  selectedTask,
  selectedSubTask,
  selectedDataSet,
  isDiscussionForSubTask,
  onSelectTask,
  onSelectSubTask,
  onChangeIsDiscusstionForSubTask,
  onSelectDataSet,
  validator,
  disabledAll,
}) => {
  // const [isForSubTask, setIsForSubTask] = useState(0);
  return (
    <>
      {/* {myRole} */}
      <div className="row mt-1 mb-3">
        <SelectComponent
          //disabled={this.state.searchLoading}
          items={listResearch}
          defaultValue={selectedProject}
          TextItem="title"
          ValueItem="id"
          labelClass="color-gray-600 fs-15 me-2"
          className="form-select control-border-color"
          label="Projects*"
          placeholder="Select project"
          onChange={(e) => {
            onResearchChange(e);
            //store.dispatch(SetResearchId({ label: e?.label, value: e?.value }));
          }}
          isMulti={false}
        ></SelectComponent>
      </div>
      <div className="item">
        <SelectComponent
          disabled={disabledAll}
          items={
            typeId
              ? typeId === 3
                ? listTasks
                : typeId === 2
                ? listTasks
                : listTasks
              : listTasks
          }
          label={typeId === 2 ? "Select task" : "Select task"}
          // popQuestion="Select Task For Discussion:"
          TextItem="name"
          // ValueItem="id"
          className="mt-1"
          placeholder="Click to see the list…"
          defaultValue={
            typeId
              ? typeId === 3
                ? selectedTask
                : typeId === 2
                ? selectedTask
                : selectedTask
              : selectedTask
          }
          onChange={(e) => {
            onSelectTask(e)
            //   this.handelSelectMembers(e);
            // if role is L3User then call onSelectSubTask cb otherwise onSelectTask
          }}
          // isMulti
          // inValid={this.validator.message(
          //   "message",
          //   this.state.message,
          //   "required"
          // )}
        ></SelectComponent>
      </div>
      {AccessPermition(myRole, [UserRoles.L2User || UserRoles.L3User]) ? (
        <>
          {selectedTask &&
          selectedTask.value &&
          (typeId === 2
            ? listTasks != null && listTasks.length > 0
            : listSubTasks != null && listSubTasks.length > 0) ? (
            <div className="item" hidden>
              <ButtonGroup
                disabled={disabledAll}
                label="Is discusstion for subtask?"
                // popQuestion="Priority Level:"
                name="discusType"
                items={[
                  {
                    id: 1,
                    name: "Yes",
                  },
                  {
                    id: 0,
                    name: "No",
                  },
                ]}
                TextItem="name"
                ValueItem="id"
                selected={isDiscussionForSubTask}
                onChange={(e) => {
                  onChangeIsDiscusstionForSubTask(parseInt(e.target.value));
                }}
              ></ButtonGroup>
            </div>
          ) : null}

          {isDiscussionForSubTask === 1 && listSubTasks.length > 0 ? (
            <div className="item">
              <SelectComponent
                disabled={disabledAll}
                items={listSubTasks}
                label="Select subtask"
                defaultValue={selectedSubTask}
                // popQuestion="Select subtask For Discussion:"
                TextItem="name"
                // ValueItem="id"
                className="mt-1"
                placeholder="Click to see the list…"
                onChange={(e) => {
                  onSelectSubTask(e);
                  //   this.handelSelectMembers(e);
                }}
                // isMulti
                // inValid={this.validator.message(
                //   "message",
                //   this.state.message,
                //   "required"
                // )}
              ></SelectComponent>
            </div>
          ) : null}
        </>
      ) : null}

      <div className="item pt-3">
        <SelectComponent
          disabled={disabledAll}
          items={listDataSets}
          inValid={validator.message("dataset", selectedDataSet, "required")}
          label="Select dataset"
          // popQuestion="Select Dataset For Discussion:"
          TextItem="name"
          defaultValue={selectedDataSet}
          // ValueItem="id"
          className="mt-1"
          placeholder="Click to see the list…"
          onChange={(e) => {
            onSelectDataSet(e);
          }}
          // isMulti
          // inValid={this.validator.message(
          //   "message",
          //   this.state.message,
          //   "required"
          // )}
        ></SelectComponent>
      </div>
    </>
  );
};
const Expense: React.FC<Props> = ({
  listTasks,
  listExpense,
  typeId,
  listSubTasks,
  myRole,
  selectedTask,
  selectedSubTask,
  selectedExpense,
  isDiscussionForSubTask,
  onSelectTask,
  onSelectSubTask,
  onChangeIsDiscusstionForSubTask,
  onSelectExpense,
  validator,
  disabledAll,
}) => {
  // const [isForSubTask, setIsForSubTask] = useState(0);
  return (
    <>
      <div className="item">
        <SelectComponent
          items={listTasks}
          disabled={disabledAll}
          label={
            AccessPermition(myRole, [UserRoles.L3User])
              ? "Select subtask"
              : "Select task"
          }
          // popQuestion="Select task For Discussion:"
          TextItem="name"
          // ValueItem="id"
          className="mt-1"
          placeholder="Click to see the list…"
          defaultValue={selectedTask}
          onChange={(e) => {
            onSelectTask(e);
            //   this.handelSelectMembers(e);
            // if role is L3User then call onSelectSubTask cb otherwise onSelectTask
          }}
          // isMulti
          // inValid={this.validator.message(
          //   "message",
          //   this.state.message,
          //   "required"
          // )}
        ></SelectComponent>
      </div>
      {AccessPermition(myRole, [UserRoles.L2User]) ? (
        <>
          {selectedTask && selectedTask.value && listSubTasks.length > 0 ? (
            <div className="item">
              <ButtonGroup
                disabled={disabledAll}
                label="Is discusstion for subtask?"
                // popQuestion="Priority Level:"
                name="discusType"
                items={[
                  {
                    id: 1,
                    name: "Yes",
                  },
                  {
                    id: 0,
                    name: "No",
                  },
                ]}
                TextItem="name"
                ValueItem="id"
                selected={isDiscussionForSubTask}
                onChange={(e) => {
                  onChangeIsDiscusstionForSubTask(parseInt(e.target.value));
                }}
              ></ButtonGroup>
            </div>
          ) : null}

          {isDiscussionForSubTask === 1 && listSubTasks.length > 0 ? (
            <div className="item">
              <SelectComponent
                disabled={disabledAll}
                items={listSubTasks}
                label="Select subtask"
                defaultValue={selectedSubTask}
                // popQuestion="Select Subtask For Discussion:"
                TextItem="name"
                // ValueItem="id"
                className="mt-1"
                placeholder="Click to see the list…"
                onChange={(e) => {
                  onSelectSubTask(e);
                  //   this.handelSelectMembers(e);
                }}
                // isMulti
                // inValid={this.validator.message(
                //   "message",
                //   this.state.message,
                //   "required"
                // )}
              ></SelectComponent>
            </div>
          ) : null}
        </>
      ) : null}

      <div className="item">
        <SelectComponent
          disabled={disabledAll}
          items={listExpense}
          label="Select expense"
          // popQuestion="Select Expense For Discussion:"
          TextItem="name"
          defaultValue={selectedExpense}
          // ValueItem="id"
          className="mt-1"
          placeholder="Click to see the list…"
          onChange={(e) => {
            onSelectExpense(e);
          }}
          inValid={validator.message("expense", selectedExpense, "required")}
          // isMulti
          // inValid={this.validator.message(
          //   "message",
          //   this.state.message,
          //   "required"
          // )}
        ></SelectComponent>
      </div>
    </>
  );
};
const Research: React.FC<Props> = ({ myRole, currentResearch }) => {
  return (
    <>
      <div className="item">
        <span className="label">Selected project:</span>
        <p
          className="px-2 py-1 mb-0"
          style={{
            borderRadius: "0.5em",
            backgroundColor: "#c6c6c6",
            fontWeight: 800,
          }}
        >
          {currentResearch}
        </p>
      </div>
    </>
  );
};
const Publication: React.FC<Props> = ({
  selectedPublication,
  listPublication,
  onSelectPublication,
  validator,
  disabledAll,
}) => {
  return (
    <>
      <div className="item">
        <SelectComponent
          items={listPublication}
          disabled={disabledAll}
          //   label={AccessPermition(myRole, [UserRoles.L3User]) ? "Select Subtask" : "Select Task:"}
          label={"Select publication"}
          // popQuestion="Select Publication :"
          TextItem="name"
          // ValueItem="id"
          className="mt-1"
          defaultValue={selectedPublication}
          placeholder="Click to see the list…"
          onChange={(e) => {
            onSelectPublication(e);
            //   this.handelSelectMembers(e);
          }}
          inValid={validator.message(
            "publication",
            selectedPublication,
            "required"
          )}
          // isMulti
          // inValid={this.validator.message(
          //   "message",
          //   this.state.message,
          //   "required"
          // )}
        ></SelectComponent>
      </div>
    </>
  );
};
const Equipment: React.FC<Props> = ({
  selectedEquipment,
  listEquipment,
  onSelectEquipment,
  validator,
  disabledAll,
}) => {
  return (
    <>
      <div className="item">
        <SelectComponent
          items={listEquipment}
          //   label={AccessPermition(myRole, [UserRoles.L3User]) ? "Select Subtask" : "Select Task:"}
          label={"Select equipment"}
          // popQuestion="Select Equipment :"
          TextItem="name"
          // ValueItem="id"
          className="mt-1"
          disabled={disabledAll}
          defaultValue={selectedEquipment}
          placeholder="Click to see the list…"
          onChange={(e) => {
            onSelectEquipment(e);
            //   this.handelSelectMembers(e);
          }}
          inValid={validator.message(
            "equipment",
            selectedEquipment,
            "required"
          )}
          // isMulti
          // inValid={this.validator.message(
          //   "message",
          //   this.state.message,
          //   "required"
          // )}
        ></SelectComponent>
      </div>
    </>
  );
};
const Laboratory: React.FC<Props> = ({
  selectedLaboratory,
  listLaboratory,
  onSelectLaboratory,
  validator,
  disabledAll,
}) => {
  return (
    <>
      <div className="item">
        <SelectComponent
          items={listLaboratory}
          //   label={AccessPermition(myRole, [UserRoles.L3User]) ? "Select Subtask" : "Select Task:"}
          label={"Select laboratory"}
          // popQuestion="Select Laboratory :"
          TextItem="name"
          disabled={disabledAll}
          // ValueItem="id"
          className="mt-1"
          defaultValue={selectedLaboratory}
          placeholder="Click to see the list…"
          onChange={(e) => {
            onSelectLaboratory(e);
            //   this.handelSelectMembers(e);
          }}
          inValid={validator.message(
            "laboratory",
            selectedLaboratory,
            "required"
          )}
          // isMulti
          // inValid={this.validator.message(
          //   "message",
          //   this.state.message,
          //   "required"
          // )}
        ></SelectComponent>
      </div>
    </>
  );
};
const Team: React.FC<Props> = ({
  selectedTeam,
  listTeam,
  onSelectTeam,
  validator,
  disabledAll,
}) => {
  return (
    <>
      <div className="item">
        <SelectComponent
          items={listTeam}
          //   label={AccessPermition(myRole, [UserRoles.L3User]) ? "Select Subtask" : "Select Task:"}
          label={"Select team"}
          disabled={disabledAll}
          // popQuestion="Select Team :"
          TextItem="name"
          // ValueItem="id"
          className="mt-1"
          defaultValue={selectedTeam}
          placeholder="Click to see the list…"
          onChange={(e) => {
            onSelectTeam(e);
            //   this.handelSelectMembers(e);
          }}
          // isMulti
          inValid={validator.message("team", selectedTeam, "required")}
        ></SelectComponent>
      </div>
    </>
  );
};
const Researches: React.FC<Props> = ({
  selectedProject,
  listResearch,
  onResearchChange,
  listTasks,
  listSubTasks,
  selectedTask,
  selectedSubTask,
  myRole,
  onSelectTask,
  onSelectSubTask,
  isDiscussionForSubTask,
  onChangeIsDiscusstionForSubTask,
  validator,
  disabledAll,
  typeId,
}) => {
  // const [isForSubTask, setIsForSubTask] = useState(0);
  return (
    <>
      {/* {myRole} */}
      <div className="row mt-1 mb-3">
        <SelectComponent
          //disabled={this.state.searchLoading}
          items={listResearch}
          defaultValue={selectedProject}
          TextItem="title"
          ValueItem="id"
          labelClass="color-gray-600 fs-15 me-2"
          className="form-select control-border-color"
          label="Projects*"
          placeholder="Select project"
          onChange={(e) => {
            onResearchChange(e);
            //store.dispatch(SetResearchId({ label: e?.label, value: e?.value }));
          }}
          isMulti={false}
        ></SelectComponent>
      </div>
    </>
  );
};
export {
  Researches,
  General,
  TaskSubTask,
  DataSet,
  Expense,
  Research,
  Publication,
  Equipment,
  Laboratory,
  Team,
};
