import Tippy from '@tippyjs/react';
import React from 'react'
import { CircleIcon, ThemeCircleIcon } from '../circle_icon';

interface CircleIconTooltipTypes {
    text: string;
    className?: string;
};

const CircleIconTooltip = ({ text, className = "" }: CircleIconTooltipTypes) => {
    return (
        <Tippy content={text} placement="bottom" className='simpleToolTip'>
            <span className={className}>
                <CircleIcon
                    width="20px"
                    height="20px"
                    padding="0"
                    type={ThemeCircleIcon.dark}
                    backgroundColor="transparent"
                    border="1px solid #D5D5D5"
                    fontSize="10px"
                    color="#D5D5D5"
                >
                    <i className="fas fa-question pointer"></i>
                </CircleIcon>
            </span>
        </Tippy>
    )
};

export default CircleIconTooltip