import Tippy from "@tippyjs/react";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { BiTransfer } from "react-icons/bi";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { MdCalendarMonth, MdMoreVert } from "react-icons/md";
import { RxMagnifyingGlass } from "react-icons/rx";
import ReactPaginate from "react-paginate";
import { Link, useHistory } from "react-router-dom";
import {
  changeMemberTransferStatus,
  getTransferUserLogList,
  GetTransferUserLogListReqRes,
  // getTransfrableMembers,
  // User,
} from "../../../../controllers/member/member_transfer_controller";
import {
  // AppConstants,
  AppRoutes,
  // DEFAULT_RESEARCH,
  MOMENT_DATE_FORMAT,
  USER_TRANSFER_LOG_STATUS_ENUM,
  USER_TRANSFER_RESPONSE_STATUS,
} from "../../../../core/constants";
import { $ReplaceRoute, getUserRole, handleClearStorageAndTokens, handleLogoutApi, UserRoles } from "../../../../core/utils";
import { useDebounceEffect } from "../../../../hooks/useDebounceEffect";
// import { ImageWithToken } from "../../../components/box_list_scroll";
import { MainButton, MainButtonType } from "../../../components/button";
import { CircleIcon, ThemeCircleIcon } from "../../../components/circle_icon";
import NewTable from "../../../components/NewTable/NewTable";
import { InputIcon } from "../../../components/search_box";
import { SelectComponent } from "../../../components/select_input";
import UserWithAvatar from "../../../components/UserWithAvatar";
import TransferDetailModal from "./transfer_detail_modal";
import { handleUnauthorizedRequest } from "../../../../core/http_common";
// import { SetResearchId } from "../../../../data/storeMain/actions/research_action";
// import { store } from "../../../../data/storeMain";
import Countdown from "react-countdown";
import PhoneSearch from "../../../components/PhoneSearch";
import CustomTooltip from "../../../components/CustomTooltip";

export default function MemberTransfer() {
  const history = useHistory();
  const _userRole = getUserRole();
  const [transferModalDetail, setTransferModalDetail] =
    useState<GetTransferUserLogListReqRes | null>(null);
  const userId = localStorage.getItem("userId");
  const [allTransferUserLogList, setAllTransferUserLogList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [paginate, setPaginate] = useState<{
    pageSize: { label: string; value: number };
    pageNumber: number;
    pageCount: number;
    totalCount: number;
  }>({
    pageSize: { label: "10", value: 10 },
    pageNumber: 0,
    pageCount: 0,
    totalCount: 0,
  });

  useEffect(() => {
    document.title = `User Transfers | Radvix`
    handleFilterTransferList({
      PageSize: 10,
      PageNumber: 1,
      SearchParameter: "",
    });
  }, []);

  async function handleFilterTransferList(_filterForm: {
    PageSize: number;
    PageNumber: number;
    SearchParameter: string;
  }) {
    try {
      setLoading(true);
      const _result = await getTransferUserLogList({
        PageNumber: _filterForm.PageNumber,
        PageSize: _filterForm.PageSize,
        SearchParameter: _filterForm.SearchParameter,
      });

      setAllTransferUserLogList(_result.userTransferLogs);
      // setData(

      // );
      setPaginate((prev) => ({
        ...prev,
        pageCount: Math.ceil(_result.count! / paginate.pageSize.value),
        totalCount: _result.count,
      }));
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }
  const headerClick = (columnHeaderName?: string, sortDirection?: string) => {
  };
  useDebounceEffect(
    () => {
      if (searchValue.length > 0)
        handleFilterTransferList({
          PageNumber: paginate.pageNumber + 1,
          PageSize: paginate.pageSize.value,
          SearchParameter: searchValue,
        });
    },
    500,
    [searchValue]
  );

  function handleOnChangeTransferStatus() {
    handleFilterTransferList({
      PageNumber: paginate.pageNumber + 1,
      PageSize: paginate.pageSize.value,
      SearchParameter: searchValue,
    });
  }
  function handleLogout() {
    handleLogoutApi().then((res) => {
      handleClearStorageAndTokens()
      history.push(AppRoutes.login);
    }).catch((err) => {
      if (err === "Device Id not found") {
        handleClearStorageAndTokens();
        window.location.replace(AppRoutes.login);
        return;
      }
      const _tokenInStorage = localStorage.getItem('token')
      if (!_tokenInStorage) {
        handleClearStorageAndTokens();
        window.location.replace(AppRoutes.login);
      } else {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              handleClearStorageAndTokens()
              history.push(AppRoutes.login);
            })
          // .catch((e) => error(e));
        } else {
          handleClearStorageAndTokens()
          history.push(AppRoutes.login);
          // error(err);
        }
      }
    });
  }
  return (
    <div className="container-fluid research">
      <div className="row"></div>
      <div className="col-12">
        <div className="TableBox">
          <div className="TopTableBox d-flex flex-md-row justify-content-between align-items-md-start mb-0 mb-sm-3">
            <div className="left d-flex align-items-center">
              <h5 className="b-title d-flex align-items-center" style={{}}>
                <IoIosArrowDropleftCircle
                  className="me-3 textSecondary2 cursorPointer"
                  size="1.5em"
                  onClick={() => {
                    window.history.back();
                  }}
                />{" "}
                User transfers
              </h5>
            </div>
            <div className="dNone dFlexSm align-items-center">
              {_userRole === UserRoles.L3User ? null : (
                <Link className="me-1" to={$ReplaceRoute(AppRoutes.create_member_transfer)}>
                  <CircleIcon
                    padding="0"
                    height="2em"
                    // type={ThemeCircleIcon.dark}
                    onClick={() => {
                    }}
                    width="2em"
                    children={<BiTransfer size="1.4em" />}
                  ></CircleIcon>
                </Link>
              )}
              <PhoneSearch
                searchIcon={searchValue.length > 0 ? (
                  <i
                    className="fa fa-times pointer p-1"
                    aria-hidden="true"
                    onClick={() => {
                      setSearchValue("");
                      handleFilterTransferList({
                        PageNumber: paginate.pageNumber + 1,
                        PageSize: paginate.pageSize.value,
                        SearchParameter: "",
                      });
                      // this.handleGetNotification(
                      //   this.state.notificationPageNumber,
                      //   this.state.notificationPageSize.value,
                      //   ""
                      // );
                    }}
                  ></i>
                ) : (
                  <RxMagnifyingGlass
                    size="1.25em"
                    className="textSecondary"
                  />
                )}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  
                  // run filter method with debounce

                  // debounce(() => {
                  if (e.target.value.length === 0) {
                    handleFilterTransferList({
                      PageNumber: paginate.pageNumber + 1,
                      PageSize: paginate.pageSize.value,
                      SearchParameter: e.target.value,
                    });
                  }
                  //   handleFilterReservationList({
                  //     ...filterData,
                  //     PageNumber: paginate.pageNumber + 1,
                  //     PageSize: paginate.pageSize.value,
                  //     SearchParameter: e.target.value,
                  //   });
                  // }, 500);
                }}
                value={searchValue}
              />
            </div>
            <div className="right dNoneSm d-flex justify-content-between align-items-baseline">
              {_userRole === UserRoles.L3User ? null : (
                <Link to={$ReplaceRoute(AppRoutes.create_member_transfer)}>
                  <MainButton
                    children="Request a transfer"
                    type={MainButtonType.dark}
                    minWidth="100px"
                    borderRadius="24px"
                    fontSize="14px"
                    minHeight="2em"
                    className="my-0 mx-0 px-2"
                    onClick={() => { }}
                  ></MainButton>
                </Link>
              )}
              <InputIcon
                parentClassName="mx-1"
                chilren={
                  searchValue.length > 0 ? (
                    <i
                      className="fa fa-times pointer p-1"
                      aria-hidden="true"
                      onClick={() => {
                        setSearchValue("");
                        handleFilterTransferList({
                          PageNumber: paginate.pageNumber + 1,
                          PageSize: paginate.pageSize.value,
                          SearchParameter: "",
                        });
                        // this.handleGetNotification(
                        //   this.state.notificationPageNumber,
                        //   this.state.notificationPageSize.value,
                        //   ""
                        // );
                      }}
                    ></i>
                  ) : (
                    <RxMagnifyingGlass
                      size="1.25em"
                      className="textSecondary"
                    />
                  )
                }
                placeholder="Search..."
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  
                  // run filter method with debounce

                  // debounce(() => {
                  if (e.target.value.length === 0) {
                    handleFilterTransferList({
                      PageNumber: paginate.pageNumber + 1,
                      PageSize: paginate.pageSize.value,
                      SearchParameter: e.target.value,
                    });
                  }
                  //   handleFilterReservationList({
                  //     ...filterData,
                  //     PageNumber: paginate.pageNumber + 1,
                  //     PageSize: paginate.pageSize.value,
                  //     SearchParameter: e.target.value,
                  //   });
                  // }, 500);
                }}
                value={searchValue}
              ></InputIcon>
              <SelectComponent
                className="ms-1"
                width="140px"
                // disabled={
                //   this.state.notifications?.length <
                //   this.state.notificationPageSize.value
                //     ? true
                //     : false
                // }
                height="44px"
                defaultValue={paginate.pageSize}
                selectProps={{ isSearchable: false }}
                items={[
                  { label: "10", value: 10 },
                  { label: "15", value: 15 },
                  { label: "20", value: 20 },
                ]}
                TextItem="item"
                ValueItem="id"
                isMulti={false}
                placeholder="Select"
                onChange={(e) => {
                  setPaginate((prev) => ({ ...prev, pageSize: e }));
                  // this.handleGetNotification(
                  //   this.state.notificationPageNumber,
                  //   e.value, border: 0;
                  //   this.state.notificationSearch
                  // );
                  handleFilterTransferList({
                    PageNumber: paginate.pageNumber + 1,
                    PageSize: e.value,
                    SearchParameter: searchValue,
                  });
                  // this.handelChangePageSize(e, "Researches");
                }}
              ></SelectComponent>
            </div>
          </div>
          <p className="fs-100 textSecondary">
            Using this feature, you are able to fully transfer information from an existing user to another user. Best use case of this feature is when a member is leaving a team. Please note that users must be from the same access level. (Level 3, Level 2 or Level 1). After you submit the request, Both users need to accept the request. If they don't respond within 30 days, the transfer will be applied automatically. L1 Client can always override the transfer requests in case of emergency.
          </p>
          <NewTable
            data={allTransferUserLogList}
            noDataFound="No Member Found"
            searchValue={searchValue}
            loading={loading}
            loadingCount={paginate.pageSize.value}
            rowClassName="newTableRowPhone relative"
            headerRowClassName="transferTableHeaderRow"
            className="mb-2"
            customGridTemplate="1.3fr 1.3fr 0.5fr 0.7fr 0.8fr 3.5em"
            onHeaderClick={(columnHeaderName, sortDirection) => headerClick(columnHeaderName, sortDirection)}
            columns={[
              {
                Header: "Transfer from",
                accessor: "title",
                cellClassName: 'fullRow',
                ellipsis: true,
                Cell: (_row) => {
                  return (
                    <div className="d-grid align-items-center transferTableHeaderRow max1LineText gap-1">
                      <div
                        style={{
                          backgroundColor:
                            _row.userToTransferResponse === 0
                              ? "transparent"
                              : USER_TRANSFER_RESPONSE_STATUS[
                                _row.userToTransferResponse
                              ].bg,
                          color:
                            _row.userToTransferResponse === 0
                              ? "inherit"
                              : USER_TRANSFER_RESPONSE_STATUS[
                                _row.userToTransferResponse
                              ].color,
                          borderRadius: "1em",
                        }}
                        className="max1LineText"
                      >
                        <UserWithAvatar
                          parentClassName="max1LineText"
                          user={_row.userToTransfer}
                        />
                      </div>

                      <div className="dNone dFlexSm max1LineText">
                        <div className="max1LineText"
                          style={{
                            backgroundColor:
                              _row.userTransferToResponse === 0
                                ? "transparent"
                                : USER_TRANSFER_RESPONSE_STATUS[
                                  _row.userTransferToResponse
                                ].bg,
                            color:
                              _row.userTransferToResponse === 0
                                ? "inherit"
                                : USER_TRANSFER_RESPONSE_STATUS[
                                  _row.userTransferToResponse
                                ].color,
                            borderRadius: "1em",
                          }}
                        >
                          <UserWithAvatar
                            parentClassName="max1LineText"
                            user={_row.userTransferTo}
                          />
                        </div>
                      </div>
                    </div>
                  );
                },
              },
              {
                Header: "Transfer to",
                accessor: "title",
                cellClassName: 'dNoneSm',
                ellipsis: true,
                Cell: (_row) => {
                  return (
                    <div
                      style={{
                        backgroundColor:
                          _row.userTransferToResponse === 0
                            ? "transparent"
                            : USER_TRANSFER_RESPONSE_STATUS[
                              _row.userTransferToResponse
                            ].bg,
                        color:
                          _row.userTransferToResponse === 0
                            ? "inherit"
                            : USER_TRANSFER_RESPONSE_STATUS[
                              _row.userTransferToResponse
                            ].color,
                        borderRadius: "1em",
                      }}
                    >
                      <UserWithAvatar
                        parentClassName="max1LineText"
                        user={_row.userTransferTo}
                      />
                    </div>
                  );
                },
              },
              {
                Header: "Status",
                accessor: "title",
                headerCellClassName: 'dNoneSm',
                align: "center",
                cellClassName: 'dNoneSm',
                ellipsis: true,
                Cell: (_row) => {
                  return (
                    <span
                      className="s-chip"
                      style={{
                        backgroundColor:
                          USER_TRANSFER_LOG_STATUS_ENUM[_row.status].bg,
                        color: USER_TRANSFER_LOG_STATUS_ENUM[_row.status].color,
                      }}
                    >
                      {USER_TRANSFER_LOG_STATUS_ENUM[_row.status].title}
                    </span>
                  );
                },
              },
              {
                Header: "Latest update",
                accessor: "updatedAt",
                headerCellClassName: 'dNoneSm',
                ellipsis: true,
                Cell: (_row) =>
                  <div className="d-flex align-items-center">
                    <CustomTooltip content='Latest update' className="dNone dFlexSm align-items-center me-3">
                      <MdCalendarMonth size="1.3em" className="me-1 textSecondary" />
                      {moment(
                        _row.updatedAt ? _row.updatedAt : _row.transferCreated
                      ).format(MOMENT_DATE_FORMAT)}</CustomTooltip>
                    <span className="dNoneSm">
                      {moment(
                        _row.updatedAt ? _row.updatedAt : _row.transferCreated
                      ).format(MOMENT_DATE_FORMAT)}
                    </span>
                  </div>,
              },
              {
                Header: "Auto transfer counter",
                align: "right",
                headerCellClassName: 'dNoneSm',
                accessor: "id",
                Cell: (_row) => (
                  <div className="d-flex justify-content-end"><CountDown status={_row.status} date={_row.remainingTime} /></div>
                ),
              },

              {
                Header: "Actions",
                align: "center",
                headerCellClassName: 'dNoneSm',
                accessor: "id",
                Cell: (_row) => (
                  <div className="d-flex align-items-center justify-content-center chipBottomRightInSm">

                    <span
                      className="s-chip dNone dFlexSm"
                      style={{
                        backgroundColor:
                          USER_TRANSFER_LOG_STATUS_ENUM[_row.status].bg,
                        color: USER_TRANSFER_LOG_STATUS_ENUM[_row.status].color,
                      }}
                    >
                      {USER_TRANSFER_LOG_STATUS_ENUM[_row.status].title}
                    </span>
                    <TransferMoreMenuContext
                      userId={userId}
                      handleLogout={handleLogout}
                      row={_row}
                      onClickDetail={() => {
                        setTransferModalDetail(_row);
                      }}
                      onChangeTransferStatus={handleOnChangeTransferStatus}
                    />
                  </div>
                ),
              },
            ]}
          />
          <div className="d-flex justify-content-between align-items-center px-3 mt-3   px-0-sm mt-1-sm">

            <div className="dFlexSm dNone">
              <SelectComponent
                width="90px"
                // disabled={
                //   this.state.notifications?.length <
                //   this.state.notificationPageSize.value
                //     ? true
                //     : false
                // }
                height="44px"
                defaultValue={paginate.pageSize}
                selectProps={{ isSearchable: false }}
                items={[
                  { label: "10", value: 10 },
                  { label: "15", value: 15 },
                  { label: "20", value: 20 },
                ]}
                TextItem="item"
                ValueItem="id"
                isMulti={false}
                placeholder="Select"
                onChange={(e) => {
                  setPaginate((prev) => ({ ...prev, pageSize: e }));
                  // this.handleGetNotification(
                  //   this.state.notificationPageNumber,
                  //   e.value, border: 0;
                  //   this.state.notificationSearch
                  // );
                  handleFilterTransferList({
                    PageNumber: paginate.pageNumber + 1,
                    PageSize: e.value,
                    SearchParameter: searchValue,
                  });
                  // this.handelChangePageSize(e, "Researches");
                }}
              ></SelectComponent>
            </div>
            {paginate.totalCount > paginate.pageSize.value ? (
              <div className="d-flex justify-content-end flex-fill">
                <ReactPaginate
                  forcePage={paginate.pageNumber}
                  previousLabel={
                    <CircleIcon
                      width="24px"
                      backgroundColor="#ADADAD"
                      height="24px"
                      padding="0"
                      type={ThemeCircleIcon.dark}
                    >
                      <i className="fas fa-chevron-left"></i>
                    </CircleIcon>
                  }
                  nextLabel={
                    <CircleIcon
                      width="24px"
                      backgroundColor="#ADADAD"
                      height="24px"
                      padding="0"
                      type={ThemeCircleIcon.dark}
                    >
                      <i className="fas fa-angle-right"></i>
                    </CircleIcon>
                  }
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={paginate.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(e) => {
                    setPaginate((prev) => ({
                      ...prev,
                      pageNumber: e.selected,
                    }));

                    handleFilterTransferList({
                      PageNumber: e.selected + 1,
                      PageSize: paginate.pageSize.value,
                      SearchParameter: searchValue,
                    });
                    // setData(
                    //   handlePaginate(
                    //     allReservation,
                    //     paginate.pageSize.value,
                    //     e.selected + 1
                    //   )
                    // );
                  }}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            ) : (
              ""
            )}

            <div className="d-flex justify-content-end flex-fill">
              <p className="text-right mb-0" style={{ fontSize: 11 }}>
                Total Results: {paginate.totalCount}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="modal-transfer-detail"
        tabIndex={-1}
        aria-labelledby="modal-transfer-detail"
        aria-hidden="true"
      >
        <TransferDetailModal
          userId={userId}
          onChangeTransferStatus={() => {
            document.getElementById("modal-transfer-detail")?.click();
            handleOnChangeTransferStatus();
          }}
          onClickClose={() => {
            document.getElementById("modal-transfer-detail")?.click();
          }}
          transferDetail={transferModalDetail}
          handleLogout={handleLogout}
        />
      </div>
    </div>
  );
}

const TransferMoreMenuContext = ({
  row,
  userId,
  onChangeTransferStatus,
  onClickDetail,
  handleLogout
}: {
  row: GetTransferUserLogListReqRes;
  userId: string | null;
  onChangeTransferStatus: () => void;
  onClickDetail: () => void;
  handleLogout: () => void;
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const isCreatorMe = row.creatorUser.id === userId;
  const shouldIResponse =
    row.userToTransfer.id === userId || row.userTransferTo.id === userId;
  const amILeaving = shouldIResponse && row.userToTransfer.id === userId;
  const amIEntering = shouldIResponse && row.userTransferTo.id === userId;
  const amIResponseAlready =
    (amILeaving && row.userToTransferResponse !== 0) ||
    (amIEntering && row.userTransferToResponse !== 0);

  const amILastPersonToRespone = amIEntering
    ? row.userToTransferResponse !== 0
    : amILeaving
      ? row.userTransferToResponse !== 0
      : false;
  

  async function handleChangeTransferUserResponse(_status: number) {
    try {
      setLoading(true);
      await changeMemberTransferStatus({
        id: row.id,
        userResponse: _status,
      });
      setLoading(false);
      setVisible(false);
      onChangeTransferStatus();

      if (amILastPersonToRespone) {
        handleLogout();
      }
    } catch (e) {
      setVisible(false);
      
    }
  }
  async function handleChangeTransferStatus(_status: number) {
    try {
      setLoading(true);
      await changeMemberTransferStatus({
        id: row.id,
        status: _status,
      });
      setLoading(false);
      setVisible(false);
      onChangeTransferStatus();
    } catch (e) {
      setVisible(false);
      
    }
  }
  return (
    <>

      <Tippy
        className="tippyContentPaddingZero"
        onClickOutside={() => {
          setVisible(false);
        }}
        visible={visible}
        interactive={true}
        maxWidth="none"
        content={
          <div
            className="d-flex flex-column py-1"
            style={{ width: "12em", maxWidth: "100%" }}
          >
            <span
              //

              id="modal-transfer-detail-span"
              data-bs-toggle="modal"
              data-bs-target="#modal-transfer-detail"
            >
              <MainButton
                children={"Detail"}
                type={MainButtonType.light}
                backgroundColor="transparent"
                borderRadius="4px"
                fontSize="12px"
                className="d-flex justify-content-start px-2 py-1 w-100"
                onClick={() => {
                  setVisible(false);
                  onClickDetail();
                }}
              ></MainButton>
            </span>

            {shouldIResponse ? (
              <>
                {amIResponseAlready ? null : (
                  <>
                    <MainButton
                      backgroundColor="transparent"
                      disabled={loading}
                      children={"Accept transfer"}
                      type={MainButtonType.light}
                      borderRadius="4px"
                      fontSize="12px"
                      className="d-flex justify-content-start px-2 py-1 w-100"
                      onClick={() => handleChangeTransferUserResponse(2)}
                    ></MainButton>
                    <MainButton
                      backgroundColor="transparent"
                      children={"Decline Transfer"}
                      disabled={loading}
                      type={MainButtonType.light}
                      borderRadius="4px"
                      fontSize="12px"
                      className="d-flex justify-content-start px-2 py-1 w-100"
                      onClick={() => handleChangeTransferUserResponse(1)}
                    ></MainButton>
                  </>
                )}
              </>
            ) : null}
            {isCreatorMe && row.status === 0 ? (
              <>
                {/* <MainButton
                                        backgroundColor="transparent"
                                        children={"Revert Transfer"}
                                        type={MainButtonType.light}
                                        borderRadius="4px"
                                        fontSize="12px"
                                        className="d-flex justify-content-start px-2 py-1 w-100"
                                        onClick={() => handleChangeTransferStatus(3)}
                                    ></MainButton> */}

                {/* <MainButton
                                        backgroundColor="transparent"
                                        children={"Force Transfer"}
                                        type={MainButtonType.light}
                                        borderRadius="4px"
                                        fontSize="12px"
                                        className="d-flex justify-content-start px-2 py-1 w-100"
                                        onClick={() => handleChangeTransferStatus(4)}
                                    ></MainButton> */}
                <MainButton
                  backgroundColor="transparent"
                  children={"Cancel Transfer"}
                  type={MainButtonType.light}
                  borderRadius="4px"
                  fontSize="12px"
                  className="d-flex justify-content-start px-2 py-1 w-100"
                  onClick={() => handleChangeTransferStatus(2)}
                ></MainButton>
              </>
            ) : null}
          </div>
        }
        arrow={false}
        placement="bottom"
      >
        <span>
          <CircleIcon
            width="2em"
            height="2em"
            padding="0"
            type={ThemeCircleIcon.dark}
            onClick={() => {
              setVisible(true);
            }}
            className="pointer ms-1"
          >
            <MdMoreVert />
          </CircleIcon>
        </span>
      </Tippy>
    </>
  );
};

const CountDownComp = ({
  date,
  status,
}: {
  date: number;
  status: number;
}) => {

  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return 'Finish';
    } else {
      // Render a countdown
      return <div className="d-flex align-items-center">
        <div className="d-flex flex-column align-items-center me-2">
          <span className="">{days}</span>
          <span className="textSecondary" style={{ fontSize: '0.7rem' }}>DAYS</span>
        </div>
        <div className="d-flex flex-column align-items-center">
          <span className="">{hours}</span>
          <span className="textSecondary" style={{ fontSize: '0.7rem' }}>HOURS</span>
        </div>
        {/* {days}d : {hours}h */}
      </div>;
    }
  };
  return (

    <Countdown
      renderer={renderer}
      date={Date.now() + date} />
  );
};
export const CountDown = memo(CountDownComp)
