import Tippy from "@tippyjs/react";
import React from "react";
import { MainButton, MainButtonType } from "./button";

type Props = {
  title: string;
  message?: string;
  confirmText?: string;
  cancelText?: string;
  content?: React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  children: React.ReactNode;
  type?: any;
};

export default function ConfirmationContent({
  title,
  message,
  content,
  onConfirm,
  onCancel,
  children,
  confirmText = "Confirm",
  cancelText = "Reject",
  type,
}: Props) {
  const [show, setShow] = React.useState(false);
  return (
    <Tippy
      arrow={false}
      aria-label="Confirmation"
      visible={show}
      onClickOutside={() => setShow(false)}
      interactive={true}
      placement="bottom"
      content={
        <Content
          title={title}
          message={message}
          content={content}
          onConfirm={() => {
            setShow(false);
            onConfirm();
          }}
          onCancel={() => {
            setShow(false);
            onCancel();
          }}
          confirmText={confirmText}
          cancelText={cancelText}
          type={type}
        />
      }
    >
      <span
        onClick={() => {
          setShow(!show);
        }}
      >
        {children}
      </span>
    </Tippy>
  );
}

const Content = ({
  title,
  message,
  content,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  type = MainButtonType.error,
}: {
  title: string;
  message?: string;
  content?: React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
  type?: any;
}) => {
  return (
    <div className="d-flex flex-column align-items-start">
      <h5 className="mb-0 p-1">{title}</h5>
      {message && <p className="mb-0 p-1 textSecondary">{message}</p>}
      {content && <div className="mt-2 px-1">{content}</div>}
      <div className="d-flex align-items-center mt-3 justify-content-end w-100">
        <MainButton
          children={cancelText}
          type={MainButtonType.light}
          borderRadius="8px"
          fontSize="16px"
          className="px-3 py-1 me-1"
          onClick={() => {
            onCancel();
          }}
        ></MainButton>
        <MainButton
          children={confirmText}
          type={type}
          borderRadius="8px"
          fontSize="16px"
          className="px-3 py-1"
          onClick={() => {
            onConfirm();
          }}
        ></MainButton>
      </div>
    </div>
  );
};
