import { toast } from "react-toastify";
import { LocalDataSources } from "../../data/local_datasources";
import { LaboratoryCreateReq } from "../../data/models/requests/laboratory/laboratory_create_req";
import { UpdateLaboratoryReq } from "../../data/models/requests/laboratory/laboratory_update_req";
import {
  AnswerRemovalLaboratoryReq,
  GetLaboratoryByIDResult,
} from "../../data/models/responses/laboratory/laboratory_by_id_res";
import { LaboratoryCreateResResult } from "../../data/models/responses/laboratory/laboratory_create_res";
import { LaboratoryGetAllResResult } from "../../data/models/responses/laboratory/laboratory_get_all_res";
import { UpdateLaboratoryResResult } from "../../data/models/responses/laboratory/laboratory_update_res";
import { LboratorySearchResResult } from "../../data/models/responses/laboratory/laboratory_search_res";
import { RemoteLaboratory } from "../../data/remotes/laboratory/remote_laboratory";
import { SortType } from "../../core/number_extentions";
export class LaboratoryController {
  remote = new RemoteLaboratory();
  local = new LocalDataSources();
  createLaboratory(
    body: LaboratoryCreateReq,
    action: (res: LaboratoryCreateResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.createLaboratory(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        if (error) error(err);
      }
    );
  }
  updateLaboratory(
    body: UpdateLaboratoryReq,
    action: (res: UpdateLaboratoryResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.updateLaboratory(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        if (error) error(err);
      }
    );
  }
  getLaboratorySearch(action: (res: LboratorySearchResResult) => any, error: (err:any) => void) {
    this.remote.getLaboratorySearch(
      (res) => {
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  getLaboratoryGetAll(
    body: { PageNumber: number; PageSize: number; SearchParameter: string; columnHeaderName?: string; sortDirection?: SortType,selectedFromDate?: string, selectedToDate?: string, },
    action: (res: LaboratoryGetAllResResult) => any,
    error: (err: any) => any
  ) {
    this.remote.getLaboratoryGetAll(
      body,
      (res) => {
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        if (error) error(err);
      }
    );
  }
  getLaboratoryById(
    body: { id: number },
    action: (res: GetLaboratoryByIDResult) => any,
    error: (err: any) => any
  ) {
    this.remote.getLaboratoryById(
      body,
      (res) => {
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        if (error) error(err);
      }
    );
  }

  deleteLaboratory(
    body: { laboratoryId: number },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.deleteLaboratory(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
          error(err)
      }
    );
  }

  answerRemovalFromLab(
    body: AnswerRemovalLaboratoryReq,
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.answerRemovalFromLab(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        if (error) error(err);
      }
    );
  }
}
