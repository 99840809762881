// import { useEffect } from "react";
// // import { useForm, Controller } from "react-hook-form";
// import { MainButton, MainButtonType } from "../../../components/button";
// import Form from "../../../components/Form/Form";
// import Input from "../../../components/Form/Input";
// import InputGroup from "../../../components/Form/InputGroup";
// import InputLable from "../../../components/Form/InputLable";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
// import { ErrorMessage } from "@hookform/error-message";
// import InputMessage from "../../../components/Form/InputMessage";
// import SelectBox from "../../../components/Form/SelectBox";
import { IRegisterInformationTypeInputs } from "./register_information_type";
import { FaChevronCircleLeft } from "react-icons/fa";
// import { SelectComponent } from "../../../components/select_input";

export interface RegisterInformationUserInputs {
  timezone: string;
  firstName: string;
  lastName: string;
}

interface IRegisterInformationUser {
  timezones: any[];
  defaultValues?: IRegisterInformationTypeInputs;
  onSubmit: (values: RegisterInformationUserInputs) => void;
  onPrev: () => void;
  isLoading?: boolean;
}

// const schema = yup
//   .object({
//     timezone: yup.string().min(1).required(),
//     firstName: yup.string().min(1).required(),
//     lastName: yup.string().min(1).required(),
//   })
//   .required();

function RegisterInformationUser({
  // timezones,
  // defaultValues,
  onSubmit,
  onPrev,
  // isLoading = false,
}: IRegisterInformationUser) {
  // const {
  //   handleSubmit,
  //   getValues,
  //   setValue,
  //   control,
  //   formState: { errors },
  // } = useForm<RegisterInformationUserInputs>({
  //   resolver: yupResolver(schema),
  // });

  // useEffect(() => {
  //   if (defaultValues !== undefined) {
  //     setValue("firstName", defaultValues.firstName ?? getValues("firstName"));
  //     setValue("lastName", defaultValues.lastName ?? getValues("lastName"));
  //   }
  // }, [defaultValues]);

  // const onSubmitForm = (values: RegisterInformationUserInputs) => {
  //   onSubmit(values);
  // };

  return (
    <>
      <div className="w-100 d-flex align-items-center mb-5">
        <FaChevronCircleLeft
          onClick={() => {
            onPrev();
          }}
          color="#9d9d9d"
          size={20}
          className="pointer"
        />
        <h1 className="right_login_title m-0 ms-3">Enter User Information</h1>
      </div>
      {/* <SelectBox
                     onChange={onChange}
                     options={timezones}
                   /> */}
      {/* <Form onSubmit={handleSubmit(onSubmitForm)}>
        <>
          <InputLable label="Timezone:">
            <>
              <Controller
                control={control}
                name="timezone"
                rules={{
                  required: true,
                }}
                render={({ field: { onChange } }) => (
                  <SelectComponent
                    // label="Timezone"
                    items={timezones}
                    // defaultValue={notificationSetting.timeZoneId}
                    onChange={(_e)=> {
                      
                      onChange(_e.value)}}
                    TextItem="name"
                    ValueItem="id"
                    className="my-2"
                    placeholder="Eastern Time"
                    // selectProps={{
                    //   menuIsOpen: true,
                    // }}
                  ></SelectComponent>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="timezone"
                render={({ message }) => <InputMessage message={message} />}
              />
            </>
          </InputLable>
          <InputGroup>
            <InputLable label="First Name:">
              <>
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field }) => (
                    <Input {...field} placeholder="First Name" />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="firstName"
                  render={({ message }) => <InputMessage message={message} />}
                />
              </>
            </InputLable>
            <InputLable label="Last Name:">
              <>
                <Controller
                  control={control}
                  name="lastName"
                  render={({ field }) => (
                    <Input {...field} placeholder="Last Name" />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="lastName"
                  render={({ message }) => <InputMessage message={message} />}
                />
              </>
            </InputLable>
          </InputGroup>

          <div className="mt-2 d-flex justify-content-center">
            <MainButton
              children={"Register"}
              type={MainButtonType.light}
              buttonType="submit"
              borderRadius="50px"
              minWidth="200px"
              minHeight="37px"
              className="align-self-end"
              loading={isLoading}
            ></MainButton>
          </div>
        </>
      </Form> */}
    </>
  );
}

export default RegisterInformationUser;
