import { memo, ReactElement, FC } from 'react'
import { LeftBottomLoginLinks } from '../../login/login';

interface IAuthLayout {
    children: ReactElement
}

const AuthLayout: FC<IAuthLayout> = ({
    children
}) => {
    return (
        <div className="login d-flex flex-column flex-md-row">
            <div className="left">
                <div className="left_logo_login">
                    <img src="/images/images/radvix_logo.svg" className="logo" alt="" />

                    <img
                        src="/images/images/img_avatar.png"
                        className="logo-Member"
                        alt=""
                    />
                </div>
                <LeftBottomLoginLinks />
            </div>
            <div className="right auth-body w-100">
                {
                    children
                }
            </div>
        </div>
    );
}

export default memo(AuthLayout)