import React, { Fragment, ReactNode, useEffect, useRef, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Header from "./header_layout";
import RightSection, { QuickActions, RecentDiscussion } from "./right_section";
import Sidebar from "./sidebar";
import { toast, ToastContainer } from "react-toastify";
import { AppSettingController } from "../../controllers/app_setting/setting_controller";
import { LocalDataSources } from "../../data/local_datasources";
import { MemberController } from "../../controllers/member/member_controller";
import { store } from "../../data/storeMain";
import {
  SetUserRole,
  SetUserInfo,
} from "../../data/storeMain/actions/user_action";
import { AppRoutes, clientId } from "../../core/constants";
import { HTTP, handleUnauthorizedRequest } from "../../core/http_common";
import { gapi } from "gapi-script";
import {
  getUserRole,
  handleClearStorageAndTokens,
  handleLogoutApi,
  isTokenInHttpAuth,
  UserRoles,
  verifyUserSubscription,
} from "../../core/utils";
// import { SetResearchId } from "../../data/storeMain/actions/research_action";
import SpinnerLoader from "../components/spinnerLoader";
import ShowNotes from "../components/notes";

import { MdBolt, MdClose, MdMenu } from "react-icons/md";
import { GiTalk } from "react-icons/gi";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import ChatGPTChatbox from "./chatgpt_chatbox";
import { worSpaceController } from "../../controllers/workSpace/workSpace";
import SidebarCopy from "./sidebar_copy";
import { SetResearchId } from "../../data/storeMain/actions/research_action";
// const ResearchId: { name: string; id: number } = { name: "", id: -1 };
interface IMainLayout {
  children: ReactNode;
}
class MainLayout extends React.Component<IMainLayout & RouteComponentProps> {
  private controller: AppSettingController = new AppSettingController();
  private memberController: MemberController = new MemberController();
  private local: LocalDataSources = new LocalDataSources();
  state = {
    role: 0,
    getMmeberInfo: "pending",
    lastSelectedResearchId: null,
    loading: true,
    isChatboxOpen: false,
  };
  handleToggleChatbox = () => {
    if (this.state.isChatboxOpen == true) {
      this.setState(() => ({
        isChatboxOpen: false,
      }));
    } else {
      this.setState(() => ({
        isChatboxOpen: true,
      }));
    }
  };
  updateUserInfo() {
    if (!isTokenInHttpAuth(HTTP)) return;
    this.memberController.getMember(
      {
        userId: localStorage.getItem("userId") ?? "",
        token: localStorage.getItem("token") ?? "",
      },
      (res) => {
        store.dispatch(SetUserRole(res.role));
        const UserInfo = {
          firstName: res.firstName,
          lastName: res.lastName,
          email: res.userEmail,
          image: res.profileImage,
          role: res.role,
          institution: res.companyName,
          userTypeName: res.userTypeName,
          lastSelectedResearchId: res.lastSeletedSearchId,
        };
        localStorage.setItem("userInfo", JSON.stringify(UserInfo) ?? "");
        ;
        store.dispatch(SetResearchId({ label: res?.lastSeletedSearchObject?.title, value: res?.lastSeletedSearchObject?.Id }));
        // console.log(
        //   "CALLED updateUserInfo before dispatchEvent RESEARCHCHANGED"
        // );
        window.dispatchEvent(new CustomEvent("RESEARCH_CHANGED"));
        this.setState({
          getMmeberInfo: "succsses",
          lastSelectedResearchId: res.lastSeletedSearchId,
        });
      },
      (err) => {
        // console.log(err)
      }
    );
  }

  IsSubscriptionActive(e?: any) {
    const detail = e?.detail;

    const _isTokenHere = detail?.token || localStorage.getItem("token");
    if (!_isTokenHere) {
      this.setState({
        loading: false,
      });
      return;
    }

    this.setState({
      loading: true,
    });
    if (store.getState().userRole == UserRoles.L1Client) {
      verifyUserSubscription(this.props);
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  loggedIn(e?: any) {
    const detail = e?.detail;;
    const _isTokenHere = detail?.token || localStorage.getItem("token");
    if (!_isTokenHere) {
      this.setState({
        loading: false,
      });
      return;
    }

    this.setState({
      loading: true,
    });

    this.memberController.getMember(
      {
        userId: detail?.userId
          ? detail.userId
          : localStorage.getItem("userId") ?? "",
        token: detail?.token
          ? detail.token
          : localStorage.getItem("token") ?? "",
      },
      (res) => {
        const UserInfo = {
          firstName: res.firstName,
          lastName: res.lastName,
          email: res.userEmail,
          image: res.profileImage,
          role: res.role,
          institution: res.companyName,
          userTypeName: res.userTypeName,
          lastSeletedSearchId: res.lastSeletedSearchId,
          id: res.id,
          l1ClientEmail: res.l1ClientEmail,
          status: res.userAccess,
          subscriptionStatus: res.subscriptionStatus,
          paymentMethodId: res.paymentMethodId,
          workSpaceUniqueId: res.workSpaceUniqueId,
          workSpaceName: res.workSpaceName,
          subscriptionPlan: res.subscriptionPlan,
        };
        localStorage.setItem("userInfo", JSON.stringify(UserInfo) ?? "");
        store.dispatch(SetResearchId({ label: res?.lastSeletedSearchObject?.title, value: res?.lastSeletedSearchObject?.id }));
        store.dispatch(SetUserRole(res.role));
        // @ts-ignore
        store.dispatch(SetUserInfo(UserInfo));
        window.dispatchEvent(new CustomEvent("RESEARCH_CHANGED"));
        if (detail?.token) localStorage.setItem("token", detail.token);
        if (detail?.userId) localStorage.setItem("userId", detail.userId);

        this.setState({
          getMmeberInfo: "succsses",
          lastSelectedResearchId: res.lastSeletedSearchId,
          loading: false,
        });
        this.IsSubscriptionActive();
        const _fullName = res.firstName
          ? res.firstName + " " + res.lastName
          : res.userEmail;
        // @ts-ignore
        window.tidioChatApi?.setContactProperties({
          subscription_id2: res.companyName,
          subscription_type: res.subscriptionSettingTitle,
          user_type: UserRoles[res.role],
        });
        // @ts-ignore
        window.tidioChatApi?.setVisitorData({
          distinct_id: res.id,
          name: _fullName,
          email: res.userEmail,
          // city: res.,
          // country: "US",
          // tags: ["tag1", "tag2"]
        });
        // const _tidioChatGui = document.getElementById("tidio-chat");
        // if (_tidioChatGui) {
        //   _tidioChatGui.style.display = "block";
        // }
      },
      (err) => {
        const _splitedPatch = this.props.location.pathname.split("/");
        if (_splitedPatch.length > 2) {
          const _isInDashboard = _splitedPatch[2] === "dashboard";
          if (_isInDashboard) this.logout(); //remove the code
        }
        this.setState({
          loading: false,
        });
      }
    );
    // this.setState({
    //   getMmeberInfo: "succsses",
    // });
  }
  logout() {
    handleLogoutApi()
      .then((res) => {
        handleClearStorageAndTokens();
        this.props.history.push(AppRoutes.login);
      })
      .catch((err) => {
        if (err === "Device Id not found") {
          handleClearStorageAndTokens();
          window.location.replace(AppRoutes.login);
          return;
        }
        const _tokenInStorage = localStorage.getItem("token");
        if (!_tokenInStorage) {
          handleClearStorageAndTokens();
          this.props.history.push(AppRoutes.login);
        } else {
          if (err.response && err.response.status === 401) {
            // Retry the request with refreshed token
            return handleUnauthorizedRequest(err.config).then((_res) => {
              handleClearStorageAndTokens();
              this.props.history.push(AppRoutes.login);
            });
            // .catch((e) => error(e));
          } else {
            handleClearStorageAndTokens();
            this.props.history.push(AppRoutes.login);
            // error(err);
          }
        }
      });

    // HTTP.post('/User/Logout').then((res) => {
    //   handleClearStorageAndTokens()
    //   this.props.history.push(AppRoutes.login);
    // }).catch((err) => {
    //   if (err.response && err.response.status === 401) {
    //     // Retry the request with refreshed token
    //     return handleUnauthorizedRequest(err.config)
    //       .then((_res) => {
    //         handleClearStorageAndTokens()
    //         this.props.history.push(AppRoutes.login);
    //       })
    //     // .catch((e) => error(e));
    //   } else {
    //     // error(err);
    //   }
    // });

    // HTTP.post('/User/Logout').then((res) => {

    // }).catch((err) => {

    // })
  }
  checklocalStorage(event: StorageEvent) {
    // if (event.storageArea === localStorage) {
    //   if (event.key === 'userId')localStorage{
    //     const setting: any = localStorage.getItem("setting");
    //     console.log('checklocalStorage localStorage clear')
    //     localStorage.clear();
    //     localStorage.setItem("setting", setting);
    //     delete HTTP.defaults.headers.common["authorization"];
    //     store.dispatch(SetResearchId(ResearchId));
    //     // window.location.replace(AppRoutes.login);
    //     // this.props.history.push(AppRoutes.login);
    //     window.location.reload()
    //   }
    // }
  }

  componentDidMount() {
    // @ts-ignore
    // window.tidioChatApi?.on('conversationStart', (_data) => {
    //   console.log('CALLLLLLED conversationStart: ',_data)
    // });
    // @ts-ignore
    // window.tidioChatApi?.tidioIdentify = {
    //   distinct_id: "unique_id", // Unique visitor ID in your system
    //   email: "contact@mail", // visitor email
    //   name: "John Doe", // Visitor name
    //   phone: "+44 2032897807" //Visitor phone
    // }
    // @ts-ignore
    // console.log("tidioChatApi", window.tidioChatApi,window, document);
    // @ts-ignore
    // window.tidioChatApi?.messageFromVisitor('Message from visitor!');
    // @ts-ignore
    // if (window.tidioChatApi) {
    //   // @ts-ignore
    //   console.log(window.tidioChatApi);
    //   // @ts-ignore
    //   window.tidioChatApi.on("ready", (_res) => {
    //     console.log("tidioChatApi ready", _res);
    //   });
    // } else {
    //   document.addEventListener("tidioChat-ready", (_prp) => {
    //     console.log("tidioChat ready", _prp);
    //   });
    // }

    console.log("called viooooooooooooooooo");

    this.loggedIn();
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);

    // console.log("cmd main la 0", this.local.logedin());
    this.controller.enumList();
    // console.log("cmd main la 1", this.local.logedin());
    window.addEventListener("loggedIn", this.loggedIn.bind(this));
    window.addEventListener("UPDATE_USER_INFO", this.updateUserInfo.bind(this));
    window.addEventListener("storage", this.checklocalStorage.bind(this));

    if (this.local.logedin()) {
      // console.log('cmd main la 2', localStorage.getItem("token"));
      HTTP.defaults.headers.common[
        "authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;

      this.setState({
        getMmeberInfo: "succsses",
      });
    } else {
      const _currentMainLocation: any = this.props.location.pathname;
      // console.log({_currentMainLocation});
      if (
        _currentMainLocation === "/invite_register" ||
        _currentMainLocation === "/activateaccount" ||
        _currentMainLocation === "/reset" ||
        _currentMainLocation === "/resetPassword" ||
        _currentMainLocation === "/login" ||
        _currentMainLocation === "/register" ||
        _currentMainLocation === "/mfa" ||
        _currentMainLocation === "/arp" ||
        _currentMainLocation.includes("/linkConfirmation") ||
        _currentMainLocation == "/Signup/" ||
        _currentMainLocation == "/UserPayment"
      ) {
        this.setState({
          loading: false,
        });
        if (_currentMainLocation == "/Signup") {
          this.props.history.push(AppRoutes.signup);
        }
        return;
      }
      this.logout(); //remove the code
    }
  }

  componentWillUnmount() {
    // console.log("calllllled 2 event clean");
    window.removeEventListener("loggedIn", this.loggedIn.bind(this));
    window.removeEventListener("storage", this.checklocalStorage.bind(this));
    window.removeEventListener(
      "UPDATE_USER_INFO",
      this.updateUserInfo.bind(this)
    );
  }

  toggleQuickAccess(_action?: string) {
    // dispatch event to toggle quick access
    window.dispatchEvent(
      new CustomEvent("TOGGLE_QUICK_ACCESS", {
        detail: {
          action: _action,
        },
      })
    );
  }

  render() {
    // console.log(this.state.getMmeberInfo);
    // console.log(this.state.lastSelectedResearchId);
    // console.log(this.state)
    // console.log('caaaaaaaaaalllleeed')
    // console.log('this.state', this.state)
    // console.trace()
    if (this.state.loading)
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <div className="d-flex flex-column align-items-center">
            <SpinnerLoader />
            <span className="mt-3 textSecondary">Initializing...</span>
          </div>
        </div>
      );
    console.log("calllllled");
    return (
      <Fragment>
        {this.props.location.pathname !== "/login" &&
        this.props.location.pathname !== "/register" &&
        this.props.location.pathname !== "/invite_register" &&
        this.props.location.pathname !== "/reset" &&
        this.props.location.pathname !== "/activateaccount" &&
        this.props.location.pathname !== "/mfa" &&
        this.props.location.pathname !== "/arp" &&
        this.props.location.pathname !== "/LinkConfirmation" &&
        this.props.location.pathname !== "/arp" &&
        this.props.location.pathname !== "/workSpace" &&
        this.props.location.pathname !== "/UserSubscriptions" &&
        this.props.location.pathname !== "/UserPayment" &&
        this.props.location.pathname !== "/Subs_payment" &&
        this.props.location.pathname !== "/Signup/" &&
        this.props.location.pathname !== "/UpdateSubscriptions" &&
        this.state.getMmeberInfo === "succsses" ? (
          <Fragment>
            <main className="d-flex flex-direction-column">
              <div style={{ borderRight: "1px solid #DDDDE3" }}>
                {/* {store.getState().userRole === UserRoles.Admin ?  <Sidebar></Sidebar>: <SidebarCopy></SidebarCopy>}  */}
                <SidebarCopy></SidebarCopy>
              </div>

                            <div className="hearderFixed mainDivScroll" style={{ backgroundColor:'#f0f2f5' }}>
                <Header
                  lastSelectedResearchId={this.state.lastSelectedResearchId}
                ></Header>
                <BottomMenuBar />
                <div className="content" style={{}}>
                  {this.props.children}
                </div>
              </div>
            </main>
          </Fragment>
        ) : (
          <div
            style={{ minHeight: "100vh" }}
          >
            {this.props.children}
          </div>
        )}
        <ToastContainer position="bottom-center" />
      </Fragment>
    );
  }
}

const BottomMenuBar = () => {
  const quickMenuWrapperRef = useRef<HTMLDivElement | null>(null);
  const [quickAccessMode, setQuickAccessMode] = useState<string>("");
  const [quickMenu, setQuickMenu] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  useOnClickOutside(quickMenuWrapperRef, (_e: any) => {
    const _target = _e?.target;
    if (_target?.classList?.contains(`${quickAccessMode}BtnDimmer`)) return;
    if (_target?.classList?.contains(`tabletMenuBtn`)) return;
    if (quickMenu) {
      toggleQuickAccess();
    }
  });

  function toggleQuickAccess(_action?: string) {
    if (quickMenuWrapperRef.current) {
      quickMenuWrapperRef.current.classList.toggle("open");
      setQuickMenu((prev) => !prev);
      if (_action) setQuickAccessMode(_action);
    }
  }
  function toggleSideBar() {
    if (sideBar) {
      const sidebarElement = document.querySelector(".sidebar");
      sidebarElement?.classList.add("closeing-sidebar");
      sidebarElement?.classList.remove("opening-sidebar");
    } else {
      const sidebarElement = document.querySelector(".sidebar");
      sidebarElement?.classList.remove("closeing-sidebar");
      sidebarElement?.classList.add("opening-sidebar");
    }
    setSideBar((prev) => !prev);
  }

  useEffect(() => {
    const closeSideBar = () => {
      const sidebarElement = document.querySelector(".sidebar");
      sidebarElement?.classList.add("closeing-sidebar");
      sidebarElement?.classList.remove("opening-sidebar");
      setSideBar(false);
    };
    window.addEventListener("closeSidebar", closeSideBar);
    window.addEventListener("closeSidebar", closeSideBar);
    return () => {
      window.removeEventListener("closeSidebar", closeSideBar);
    };
  }, []);
  return (
    <>
      <div className="xsPhoneMenuWrapper align-items-center hidden">
        <div
          onClick={() => {
            toggleSideBar();
          }}
          className="relative relative d-flex flex-column align-items-center py-2"
        >
          <div
            className="menuBtnDimmer"
            style={{
              zIndex: 5,
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
          {sideBar ? (
            <>
              <MdClose color="#fff" size="1.4em" />
              <span
                className="mt-1 fs-100"
                style={{
                  color: "rgba(255,255,255,0.56)",
                }}
              >
                Close
              </span>
            </>
          ) : (
            <>
              <MdMenu color="#fff" size="1.4em" />
              <span
                className="mt-1 fs-100"
                style={{
                  color: "rgba(255,255,255,0.56)",
                }}
              >
                Main Menu
              </span>
            </>
          )}

          <div
            style={{
              position: "absolute",
              right: 0,
              height: "1.5em",
              top: "50%",
              transform: "translateY(-50%)",
              borderRight: "1px solid rgba(255,255,255,0.16)",
            }}
          />
        </div>
        <div
          onClick={() => {
            toggleQuickAccess("action");
          }}
          className="relative d-flex flex-column align-items-center py-2"
          style={
            {
              // borderRight: '1px solid rgba(255,255,255,0.32)'
            }
          }
        >
          <div
            className="actionBtnDimmer"
            style={{
              zIndex: 5,
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
          {quickAccessMode === "action" && quickMenu ? (
            <>
              <MdClose color="#fff" size="1.4em" />
              <span
                className="mt-1 fs-100"
                style={{
                  color: "rgba(255,255,255,0.56)",
                }}
              >
                Close
              </span>
            </>
          ) : (
            <>
              <MdBolt color="#fff" size="1.4em" />
              <span
                className="mt-1 fs-100"
                style={{
                  color: "rgba(255,255,255,0.56)",
                }}
              >
                Quick Action
              </span>
            </>
          )}

          <div
            style={{
              position: "absolute",
              right: 0,
              height: "1.5em",
              top: "50%",
              transform: "translateY(-50%)",
              borderRight: "1px solid rgba(255,255,255,0.16)",
            }}
          />
        </div>
        <div
          onClick={() => {
            toggleQuickAccess("discussion");
          }}
          className="relative d-flex flex-column align-items-center py-2"
        >
          <div
            className="discussionBtnDimmer"
            style={{
              zIndex: 5,
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
          {quickAccessMode === "discussion" && quickMenu ? (
            <>
              <MdClose color="#fff" size="1.4em" />
              <span
                className="mt-1 fs-100"
                style={{
                  color: "rgba(255,255,255,0.56)",
                }}
              >
                Close
              </span>
            </>
          ) : (
            <>
              <GiTalk color="#fff" size="1.4em" />
              <span
                className="mt-1 fs-100"
                style={{
                  color: "rgba(255,255,255,0.56)",
                }}
              >
                Recent Discussion
              </span>
            </>
          )}
        </div>
      </div>

      <div ref={quickMenuWrapperRef} className="quickMenuWrapper">
        {/* <div style={{
          padding: '0.16em',
          position: 'absolute',
          top: '2em',
          left: 0,
          backgroundColor: '#2c2c2c',
          borderTopLeftRadius: '0.5em',
          borderBottomLeftRadius: '0.5em',
          transform: 'translateX(-94%)'
        }}
          onClick={() => toggleQuickAccess()}
        >
          <MdClose color="#fff" size='1.5em' />
        </div> */}
        <QuickMenu
          toggleQuickAccess={(_type) => toggleQuickAccess(_type)}
          quickAccessMode={quickAccessMode}
        />
      </div>

      {/* <div className="phoneBtnWrapper d-flex flex-column d-lg-none">
        <i
          className="fa fa-bars d-flex phoneMenuBtn"
          aria-hidden="true"
          onClick={() => {
            toggleSideBar()
          }}
        ></i>
        <span onClick={() => {
          toggleQuickAccess()
        }} className="mt-1 tabletMenuBtn phoneMenuBtn d-flex align-items-center justify-content-center" style={{
          width: 34,
          height: 34,
          padding: '0.25em'
        }}>
          <div className="relative">
            <div className="tabletMenuBtn" style={{
              zIndex: 5,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',

            }} />
            <MdBolt size='1.5em' />
          </div>
        </span>
      </div> */}
    </>
  );
};

const QuickMenu = ({
  quickAccessMode,
  toggleQuickAccess,
}: {
  quickAccessMode: string;
  toggleQuickAccess: (_type: string) => void;
}) => {
  // function closeQuickMenu() {
  //   window.dispatchEvent(new Event("TOGGLE_QUICK_ACCESS"));
  // }
  return (
    <div className="flex column righ-section">
      <div className="d-flex align-items-center justify-content-between p-4 pb-0">
        <h4 className="fs-400 mb-0" style={{ color: "#fff" }}>
          {quickAccessMode === "action"
            ? "Quick Action"
            : quickAccessMode === "discussion"
            ? "Recent Discussion"
            : "Quick Access"}
        </h4>
        {/* <MdClose color="#fff" onClick={closeQuickMenu} size="1.5em" /> */}
      </div>
      {quickAccessMode === "action" ? (
        <QuickActions onSelectAction={() => toggleQuickAccess("action")} />
      ) : quickAccessMode === "discussion" ? (
        <RecentDiscussion
          onSelectDiscussion={() => toggleQuickAccess("discussion")}
        />
      ) : (
        <>
          <QuickActions onSelectAction={() => toggleQuickAccess("action")} />
          <RecentDiscussion
            onSelectDiscussion={() => toggleQuickAccess("discussion")}
          />
        </>
      )}
    </div>
  );
};
export default withRouter(MainLayout);
