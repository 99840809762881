import { MainButton, MainButtonType } from '../../components/button';
import { InputIcon } from '../../components/search_box';
import { $ReplaceRoute, formattedCurrency, getUserInitails } from '../../../core/utils';
import { DropdownMenu, Table } from '@radix-ui/themes';
import { ImageWithToken } from '../../components/box_list_scroll';
import RadixTable from '../../components/NewRadixTable/RadixTable';
import { useHistory } from 'react-router';
import { AppRoutes } from '../../../core/constants';
import { useEffect, useLayoutEffect, useState } from 'react';
import Pagination from '../../components/NewRadixTable/RadixPagination';
import { SortType } from '../../../core/number_extentions';
import BackButtonTitle from './component/back_button_title';
import { grantController } from '../../../controllers/financials/grant_controller';
import DeleteConfirmModal from './component/delete_confirm_modal';
import Tippy from '@tippyjs/react';

const grant = [
  {
    id: 1,
    title: 'Micro research 2024',
    expenses: 3,
    budget: 100,
    spending: 10,
    projects: 3,
    grantManager: {
      email: 'nima242@gmail.com',
      firstName: 'Nima',
      id: '5e5aae75-6b29-46fd-86c1-78355dd63695',
      image:
        'https://library.staging.radvix.io/Uploads/Radvix/general/campaign-creators-gMsnXqILjp4-unsplash_50d265c6-4ecc-4088-a4b4-452f0f681e50.jpg',
      invitationStatus: 2,
      isTaskAssigne: null,
      isUserTransferred: false,
      lastName: 'Hosseinzadeh',
    },
  },
  {
    id: 2,
    title: 'Micro research 2024',
    expenses: '0',
    budget: 100,
    spending: 10,
    projects: 3,
    grantManager: {
      email: 'nima242@gmail.com',
      firstName: 'Nima',
      id: '5e5aae75-6b29-46fd-86c1-78355dd63695',
      image:
        'https://library.staging.radvix.io/Uploads/Radvix/general/campaign-creators-gMsnXqILjp4-unsplash_50d265c6-4ecc-4088-a4b4-452f0f681e50.jpg',
      invitationStatus: 2,
      isTaskAssigne: null,
      isUserTransferred: false,
      lastName: 'Hosseinzadeh',
    },
  },
];

export default function GrantPage() {
  const history = useHistory();
  const controller = new grantController();

  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [visibleTippy, setvisibleTippy] = useState(false);
  const [tippyIndex, setTippyIndex] = useState(-1);
  const [headerName, setHeaderName] = useState('');
  const [sortDirection, setSortDirection] = useState(() => SortType.AutoSelect);

  const [grantCount, setGrantCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState({ label: '10', value: 10 });
  const [pageCount, setPageCount] = useState(0);
  const [pageNumberInput, setPageNumberInput] = useState(0);

  const [grants, setGrants] = useState<any[]>([]);

  const [showModal, setShowModal] = useState(false);
  const [grantId, setGrantId] = useState<null | number>(null);

  useLayoutEffect(() => {
    document.title = `Grant List | Radvix`;
  }, []);

  function getGrant(
    PageNumber: number,
    PageSize: number,
    Search?: string,
    columnHeaderName?: string,
    sortDirection?: SortType
  ) {
    setIsLoading(true);
    controller.getGrant(
      {
        PageNumber,
        PageSize,
        SearchParameter: Search || '',
        columnHeaderName,
        sortDirection,
      },
      (res) => {
        if (res === null) {
          setGrants([]);
          setPageCount(0);
          setIsLoading(false);
          return;
        }

        console.log(res, '================== getGrant');

        setGrants(res.grantList);
        setPageCount(Math.ceil(res.count! / pageSize.value));
        setGrantCount(res.count!);
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      }
    );
  }

  useEffect(() => {
    document.title = 'My Grants | Radvix';
    getGrant(pageNumber, pageSize.value, search, headerName, sortDirection);
  }, [pageNumber, pageSize, search, headerName, sortDirection]);

  function handleChangePageNumber(e: { selected: number }) {
    setPageNumber(e.selected + 1);
  }

  function handlePageNumberInput(event: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setPageNumberInput(value);
      setPageNumber(value);
    } else {
      setPageNumberInput(1);
    }
  }

  function handleChangeProject(e: { value: number; label: string }) {
    // setSelectedResearch(e);
    // dispatch(SetResearchId({ label: e.label, value: e.value }));
    setPageCount(0);
  }

  function handlePageSizeChange(e: { label: string; value: number }) {
    setPageSize(e);
    setPageCount(0);
  }

  function handleStatusChange(selectedStatus: any[]) {
    const allValue = selectedStatus.find((x) => x.value === -1);
    const value = allValue ? null : selectedStatus;

    // setStatusFilter(value || []);
  }

  function headerClick(columnName: string, sortDirection: SortType) {
    setHeaderName(columnName);
    setSortDirection(sortDirection);
  }

  function deleteGrant(grantId: number) {
    controller.deleteGrant(
      { grantId },
      (res) => {
        setShowModal(false);
        setGrantId(null);

        let pageNum = pageNumber;

        if (grantCount - (pageNumber - 1) * pageSize.value === 1) {
          pageNum = pageNumber > 1 ? pageNumber - 1 : pageNumber;
          setPageCount(0);
        }
        getGrant(pageNum, pageSize.value, search, headerName, sortDirection);
      },
      (err) => {
        setShowModal(false);
      }
    );
  }

  return (
    <>
      <div className='card'>
        <div className='d-flex justify-content-between align-items-center p-3 gap-3 flex-wrap'>
          <BackButtonTitle title='List of grants' />

          <MainButton
            icon={<img src='/Images/icons/plus.svg' alt='sssss' height='20' />}
            children='Add Grant'
            type={MainButtonType.dark}
            fontSize='14px'
            minHeight='2em'
            className='btn btn-primary fs-15 py-px-7 textWrapLabel'
            minWidth='100px'
            onClick={() => {
              history.push($ReplaceRoute(AppRoutes.grant_new));
            }}
          />
        </div>

        <div
          className='d-flex flex-xl-row flex-column row-gap-xl-0 row-gap-px-16 px-3 pb-3 filters'
          id='showFilter'
        >
          <div
            className='w-100 pe-xl-3 border-gray-400 d-sm-block d-flex gap-3'
            style={{ maxWidth: '490px' }}
          >
            <InputIcon
              className='form-control h-px-35'
              placeholder='Search Grant here'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              chilren={undefined}
            />
          </div>
        </div>

        <RadixTable
          data={grants}
          loading={isLoading}
          onHeaderClick={(columnHeaderName: string, sortDirection: SortType) =>
            headerClick(columnHeaderName, sortDirection)
          }
          columns={[
            {
              Header: 'Grant name',
              accessor: 'title',
              width: '372px',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <div className='line-clamp-2 textWrapLabel'>
                      <i className='fa fa-grip-vertical me-2 color-gray-600 fs-13'></i>
                      <span
                        onClick={() => {
                          history.push(
                            $ReplaceRoute(
                              `${AppRoutes.grant_detail.replace(':id', props.id?.toString() ?? '')}`
                            )
                          );
                        }}
                        title={props.title}
                      >
                        {' '}
                        {props.title}
                      </span>
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Expenses to Review',
              accessor: 'expensesReview',
              width: '176px',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3'>
                    {props.expensesToReview > 0 ? (
                      <span
                        className='badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3'
                        style={{ background: '#D93D42' }}
                      >
                        {props.expensesToReview} Expenses
                      </span>
                    ) : (
                      <span
                        className='badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3'
                        style={{ background: '#7E808A' }}
                      >
                        No Expenses
                      </span>
                    )}
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Budget',
              accessor: 'budget',
              width: '133px',
              Cell: (props: any) => {
                return <Table.Cell className='p-3'>{formattedCurrency(props.amount)}</Table.Cell>;
              },
            },
            {
              Header: 'Spending',
              accessor: 'spending',
              width: '108px',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3'>{formattedCurrency(props.expenseAmount)}</Table.Cell>
                );
              },
            },
            {
              Header: 'Projects',
              accessor: 'projects',
              width: '200px',
              Cell: (props: any) => {
                return <Table.Cell className='p-3'>{props.researches}</Table.Cell>;
              },
            },
            {
              Header: 'Grant Manager',
              accessor: 'grantManager',
              width: '200px',
              Cell: (props: any, index: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <div className='d-flex align-items-center text-nowrap'>
                      <ImageWithToken
                        originalImage={props.creatorUser.image}
                        hasImage={props.creatorUser.image ? true : false}
                        alt='Avatar'
                        className='rounded-circle avatar rounded-avatar-dashboard'
                        src={
                          props.creatorUser.image === null ||
                          props.creatorUser.image === '' ||
                          props.creatorUser.image === undefined
                            ? '/images/images/img_avatar.png'
                            : props.creatorUser.image
                        }
                      />

                      <span
                        className='pointer'
                        style={{ paddingRight: '5px' }}
                        onClick={() => {
                          history.push(
                            $ReplaceRoute(
                              `${AppRoutes.member_profile.replace(
                                ':id',
                                props.creatorUser.id?.toString() ?? ''
                              )}`
                            )
                          );
                        }}
                      >{`${props.creatorUser?.firstName} ${props.creatorUser?.lastName}`}</span>
                      {props.managers.slice(1).length > 0 && (
                        <>
                          <Tippy
                            content={
                              <div className='d-flex flex-column py-2 pb-0 gap-6'>
                                {props.managers
                                  .filter((x: { id: any }) => x.id !== props.creatorUser.id)
                                  .map((manager: any) => (
                                    <div key={manager.id} className='d-flex align-items-center'>
                                      {manager.image === null ||
                                      manager.image === '' ||
                                      manager.image === undefined ? (
                                        <>
                                          <div className='avatar w-px-25 h-px-25 rounded-circle bg-primary'>
                                            <label className='fw-medium text-white fs-10'>
                                              {getUserInitails(
                                                `${manager.firstName} ${manager.lastName}`
                                              )}
                                            </label>
                                          </div>
                                        </>
                                      ) : (
                                        <ImageWithToken
                                          originalImage={manager.image}
                                          hasImage={manager.image ? true : false}
                                          alt='Avatar'
                                          className='rounded-circle avatar rounded-avatar-dashboard'
                                          src={
                                            manager.image === null ||
                                            manager.image === '' ||
                                            manager.image === undefined
                                              ? '/images/images/img_avatar.png'
                                              : manager.image
                                          }
                                        />
                                      )}
                                      <span
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          history.push(
                                            $ReplaceRoute(
                                              `${AppRoutes.member_profile.replace(
                                                ':id',
                                                manager.id?.toString() ?? ''
                                              )}`
                                            )
                                          );
                                        }}
                                      >
                                        {manager.firstName} {manager.lastName}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            }
                            visible={index === tippyIndex}
                            onClickOutside={() => setTippyIndex(-1)}
                            interactive={true}
                            placement='bottom'
                          >
                            <span
                              onClick={() => setTippyIndex(index)}
                              className='radix-multiSelect-number'
                              style={{ cursor: 'pointer' }}
                            >
                              {`+${props.managers.slice(1).length}`}
                            </span>
                          </Tippy>
                        </>
                      )}
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: '',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3 text-nowrap'>
                    <div className='d-flex align-items-center gap-3'>
                      <div className='btn-group'>
                        <button
                          type='button'
                          className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              $ReplaceRoute(
                                `${AppRoutes.grant_detail.replace(
                                  ':id',
                                  props.id.toString() ?? ''
                                )}`
                              )
                            );
                          }}
                        >
                          <i className='fa fa-eye me-px-6'></i>View Details
                        </button>

                        <DropdownMenu.Root>
                          <DropdownMenu.Trigger>
                            <button
                              type='button'
                              className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                            >
                              <span className='visually-hidden'>Toggle Dropdown</span>
                            </button>
                          </DropdownMenu.Trigger>
                          <DropdownMenu.Content>
                            <DropdownMenu.Item className='px-0'>
                              <button
                                className='dropdown-item fs-14'
                                onClick={(e) => {
                                  e.preventDefault();
                                  history.push(
                                    $ReplaceRoute(
                                      `${AppRoutes.grant_detail.replace(
                                        ':id',
                                        props.id.toString() ?? ''
                                      )}`
                                    )
                                  );
                                }}
                              >
                                View Details
                              </button>
                            </DropdownMenu.Item>
                            <DropdownMenu.Item className='px-0'>
                              <button
                                className='dropdown-item fs-14'
                                onClick={(e) => {
                                  e.preventDefault();
                                  history.push(
                                    $ReplaceRoute(
                                      `${AppRoutes.grant_edit.replace(
                                        ':id',
                                        props.id.toString() ?? ''
                                      )}`
                                    )
                                  );
                                }}
                              >
                                Edit Grant
                              </button>
                            </DropdownMenu.Item>
                            {/* <DropdownMenu.Item className='px-0'>
                              <button
                                className='dropdown-item fs-14'
                                onClick={(e) => {
                                  setShowModal(true);
                                  setGrantId(props.id);
                                }}
                              >
                                Delete Grant
                              </button>
                            </DropdownMenu.Item> */}
                          </DropdownMenu.Content>
                        </DropdownMenu.Root>
                      </div>
                    </div>
                  </Table.Cell>
                );
              },
            },
          ]}
        />
        {pageCount > 0 && (
          <Pagination
            pageCount={pageCount}
            onPageChange={handleChangePageNumber}
            onPageSizeChange={(e: any) => handlePageSizeChange(e)}
            pageInput={pageNumberInput}
            handlePageNumberInput={handlePageNumberInput}
          />
        )}
      </div>

      <DeleteConfirmModal
        deleteTitle='Grant'
        show={showModal}
        loading={isLoading}
        onHide={() => setShowModal(false)}
        onConfirm={() => deleteGrant(grantId!)}
      />
    </>
  );
}
