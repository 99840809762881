import { memo, useEffect, useMemo, useState, useRef } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { RegisterController } from "../../../controllers/register/research_controller";
import { UserController } from "../../../controllers/user/user_controller";
// import { AppRoutes } from "../../../core/constants";
import { HTTP } from "../../../core/http_common";
import { Messages } from "../../../core/messages";
// import { UserRoles } from "../../../core/utils";
import { SubscriptionResResult } from "../../../data/models/responses/register/subscription_res";
import { store } from "../../../data/storeMain";
import {
  SetUserInfo,
  SetUserRole,
} from "../../../data/storeMain/actions/user_action";
import Visible from "../../components/visible";
import RegisterInformation from "./components/register_information";
import Subscriptions from "./components/subscriptions";

type RegisterStep = "subscription" | "information";

export interface IRegisterForm {
  email: string;
  timezone: string;
  firstName: string;
  lastName: string;
  password?: string;
  confirmPassword?: string;
  googleId?: string;
}

const Register = () => {
  const controller = useMemo(() => new RegisterController(), []);
  const loginController: UserController = new UserController();
  const [subscriptions, setSubscriptions] = useState<SubscriptionResResult[]>(
    []
  );
  const [step, setStep] = useState<RegisterStep>("subscription");
  const [listTimeZone, setListTimeZone] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();

  const selectedSubscription = useRef<SubscriptionResResult | null>(null);

  useEffect(() => {
    getSubscription();
    getTimezones();
  }, []);

  function getSubscription() {
    controller.getSubscription(
      {
        PageNumber: 1,
        PageSize: 10,
        SearchParameter: "",
      },
      (res) => {
        setSubscriptions(res);
      },
      (err) => {}
    );
  }

  function getTimezones() {
    HTTP.get("/AppSetting/TimeZoneList")
      .then((res) => {
        if (res.data.result) {
          const _data = res.data.result?.timeZones.map((_tm: any) => {
            return { label: _tm.title, value: _tm.id };
          });
          

          setListTimeZone(_data);
        }
      })
      .catch((err) => {
        // error(err);
      });
  }

  function changeStep(step: RegisterStep) {
    setStep(step);
  }

  const onRegisterUser = (values: IRegisterForm) => {
    if (selectedSubscription.current === undefined) {
      changeStep("subscription");
      return;
    }
    setIsLoading(true);

    
    controller.SignUp(
      {
        email: values.email,
        isL1Client: true,
        firstName: values.firstName,
        lastName: values.lastName,
        username: values.email,
        confirmPassword:
          values.googleId === undefined ? values.confirmPassword : undefined,
        password: values.googleId === undefined ? values.password : undefined,
        passwordUnique: values.googleId ?? undefined,
      },
      (success) => {
        if (values.googleId !== undefined) {
          addSubscription(+values.timezone, success.token ?? "", values);
        } else {
          addSubscription(+values.timezone, success.token ?? "");
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
  };

  const addSubscription = (timezone: number, token: string, values?: any) => {
    if (
      selectedSubscription.current === undefined ||
      selectedSubscription.current === null
    )
      return;
    controller.addUserSubscription(
      {
        subscriptionId: selectedSubscription.current.id,
        timeZoneId: timezone,
        token,
      },
      (success) => {
        if (values !== undefined) {
          const body: any = {
            passwordUnique: values.googleId,
            email: values.email,
          };
          handleSignIn(body);
        } else {
          toast.success(Messages.confirmYourEmail);
          history.replace("/login");
        }
      },
      (error) => {
        toast.success(Messages.confirmYourEmail);
        history.replace("/login");
        setIsLoading(false);
      }
    );
  };
  const handleSignIn = (body: any) => {
    setIsLoading(true);
    loginController.Signin(
      body,
      (res) => {
        setIsLoading(false);
        if (res) {
          store.dispatch(SetUserRole(res.role ?? 0));
          store.dispatch(SetUserInfo(res));
          // 
          // if (res.role === UserRoles.Admin) {
          // history.replace(AppRoutes.admin_dashboard);
          // window.location.replace(AppRoutes.admin_dashboard);
          // } else {
          // props.history.replace(`/${res.companyName}/dashboard`);
          // window.location.replace(`/${res.companyName}/dashboard`);

          history.push(`/${res.companyName}/dashboard/home`);
          window.dispatchEvent(new CustomEvent("loggedIn", {
            detail: {
              token: res.token,
              userId: res.id
            }
          }));
          // new CustomEvent('loggedIn')
          // }
        }
      },
      //@ts-ignore
      (err) => {
        // if (err?.response?.data?.message) {
        //   setloginUserError(err.response.data.message);
        //   // history.push(AppRoutes.register_page);
        // } else {
        //   setloginUserError("Something went wrong");
        // }
        setIsLoading(false);
      }
    );
  };
  return (
    <TransitionGroup>
      <Visible visible={step === "subscription"}>
        <CSSTransition classNames="dialog" timeout={300}>
          <Subscriptions
            subscriptions={subscriptions}
            onSelectSubscription={(subscription) => {
              selectedSubscription.current = subscription;
              changeStep("information");
            }}
          />
        </CSSTransition>
      </Visible>
      <Visible visible={step === "information"}>
        <CSSTransition classNames="dialog" timeout={300}>
          <RegisterInformation
            timezones={listTimeZone}
            onSubmitForm={onRegisterUser}
            isLoading={isLoading}
          />
        </CSSTransition>
      </Visible>
    </TransitionGroup>
  );
};

export default memo(Register);
