import React from "react";
import ReactPaginate from "react-paginate";
import { AdminController } from "../../../controllers/admin/admin_controller";
import { HTTP } from "../../../core/http_common";
import { getUserTitle, isTokenInHttpAuth } from "../../../core/utils";
import { PaymentList } from "../../../data/models/responses/admin/payments_res";
import { MainButton, MainButtonType } from "../../components/button";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
// import { InputIcon } from "../../components/search_box";
import { SelectComponent } from "../../components/select_input";
// import { PaymentsTbl } from "./component/payment_tbl";
import { RouteComponentProps, withRouter } from "react-router";
import NewTable from "../../components/NewTable/NewTable";
import moment from "moment";
type StateType = {
  Payments: PaymentList[];
  PageNumber: number;
  PageSize: number;
  PageCount: number;
  TotalCount: number;
  Search: string;
  loading?: boolean
};

class AdminPayments extends React.Component<RouteComponentProps> {
  controller = new AdminController();
  state: StateType = {
    Payments: [],
    PageNumber: 1,
    PageSize: 10,
    PageCount: 0,
    TotalCount: 0,
    Search: "",
    loading: true
  };
  componentDidMount() {
    if (!isTokenInHttpAuth(HTTP)) return;
    const _urlParams = this.props.location?.search;
    if (_urlParams) {
      const _splitiedParams = _urlParams.split("=");
      const _userId = _splitiedParams[1];

      // console.log(_urlParams, _userId);
      this.GetPayments(this.state.PageNumber, this.state.PageSize, _userId);
    } else {
      this.GetPayments(this.state.PageNumber, this.state.PageSize);
    }
  }
  GetPayments(PageNumber: number, PageSize: number, _userId?: string) {
    this.setState({ loading: true })
    this.controller.getPayments(
      {
        pageNumber: PageNumber,
        pageSize: PageSize,
        userId: _userId,
      },
      (res) => {
        this.setState({
          Payments: res.paymentList,
          PageCount: Math.ceil(res.count! / this.state.PageSize),
          TotalCount: res.count,
          loading: false
        });
      },
      (err) => {
        this.setState({ loading: false })
      }
    );
  }
  handelChangePageNumber(e: { selected: number }) {
    this.setState({
      PageNumber: e.selected,
    });
    this.GetPayments(e.selected + 1, this.state.PageSize);
  }
  handelChangePageSize(e: { label: string; value: number }) {
    this.setState({
      PageSize: e.value,
    });
    this.GetPayments(this.state.PageNumber, e.value);
  }
  headerClick = (columnHeaderName?: string, sortDirection?: string) => {
  };
  render() {
    return (
      <div className="container-fluid research">
        <div className="row"></div>
        <div className="col-12">
          <div className="TableBox">
            <div className="TopTableBox d-flex justify-content-between align-items-center mb-3">
              <div className="left d-flex align-items-baseline">
                <h5>Payments</h5>
              </div>
              <div className="right w-50 d-flex justify-content-end align-items-center">
                <MainButton
                  children="Payment Setup"
                  type={MainButtonType.dark}
                  borderRadius="24px"
                  fontSize="14px"
                  className="ms-0 px-2"
                  minWidth="8em"
                  minHeight="2em"
                ></MainButton>
                {/* <InputIcon
                  chilren={
                    <img src="/images/icons/search_box_icon.svg" alt="" />
                  }
                  width="100%"
                  parentClassName="mx-2"
                  placeholder="Search..."
                  TopPosition="15%"
                ></InputIcon> */}
                <SelectComponent
                  width="90px"
                  height="44px"
                  items={[
                    { label: "10", value: 10 },
                    { label: "15", value: 15 },
                    { label: "20", value: 20 },
                  ]}
                  TextItem="item"
                  ValueItem="id"
                  isMulti={false}
                  placeholder={this.state.PageSize.toString()}
                  onChange={(e) => {
                    this.handelChangePageSize(e);
                  }}
                ></SelectComponent>
              </div>
            </div>
            <NewTable
              loading={this.state.loading}
              customGridTemplate="1.6fr 0.8fr 1fr 1.2fr 0.8fr 0.6fr"
              data={this.state.Payments}
              onHeaderClick={(columnHeaderName, sortDirection) => this.headerClick(columnHeaderName, sortDirection)}
              columns={[
                { Header: "Payment Amount", accessor: "title", ellipsis: true },
                {
                  Header: "Date",
                  align: "center",
                  accessor: "payDate",
                  Cell: (_row) => moment(_row.payDate).format("DD/MM/YYYY"),
                },
                {
                  Header: "Client Name",
                  accessor: "user",
                  ellipsis: true,
                  Cell: (_row) => {
                    const _userTitle = getUserTitle(_row.user);
                    return _userTitle || "-";
                  },
                },
                {
                  Header: "Client Email",
                  accessor: "user",
                  ellipsis: true,
                  Cell: (_row) => _row.user?.email || "-",
                },
                {
                  Header: "Status",
                  accessor: "status",
                  Cell: (_row) => (
                    <MainButton
                      children={_row.status.isStatus()}
                      type={MainButtonType.dark}
                      borderRadius="24px"
                      fontSize="14px"
                      color={
                        _row.status.isStatus() === "OnHold"
                          ? "#FFFFFF"
                          : "#474747"
                      }
                      backgroundColor={_row.status.isStatusColor()}
                    ></MainButton>
                  ),
                },
                {
                  Header: "",
                  accessor: "id",
                  Cell: (_row) => (
                    <div className="d-flex justify-content-end">
                      <CircleIcon
                        width="26px"
                        padding="0"
                        height="26px"
                        type={ThemeCircleIcon.dark}
                        onClick={(e) => { }}
                        className="pointer"
                      >
                        <img
                          src="/images/icons/google_docs.svg"
                          alt="radvix"
                          width={12}
                          height={12}
                        />
                      </CircleIcon>
                    </div>
                  ),
                },
              ]}
            />
            {/* <PaymentsTbl
              Items={this.state.Payments}
              Heading={[
                { name: "Payment Amount", center: false },
                { name: "Date", center: true },
                { name: "Client Name", center: true },
                { name: "Client Email", center: true },
                { name: "Status", center: true },
              ]}
            ></PaymentsTbl> */}

            <div className="d-flex justify-content-between align-items-baseline">
              <div className="d-flex justify-content-end flex-fill">
                <ReactPaginate
                  previousLabel={
                    <CircleIcon
                      width="24px"
                      padding="0"
                      backgroundColor="#ADADAD"
                      height="24px"
                      type={ThemeCircleIcon.dark}
                    >
                      <i className="fas fa-chevron-left"></i>
                    </CircleIcon>
                  }
                  nextLabel={
                    <CircleIcon
                      padding="0"
                      width="24px"
                      backgroundColor="#ADADAD"
                      height="24px"
                      type={ThemeCircleIcon.dark}
                    >
                      <i className="fas fa-angle-right"></i>
                    </CircleIcon>
                  }
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.PageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(e) => {
                    this.handelChangePageNumber(e);
                  }}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
              <div className="d-flex justify-content-end flex-fill">
                <p className="text-right mb-0 ">
                  Total Results: {this.state.TotalCount}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AdminPayments);
