import { HTTP, handleUnauthorizedRequest } from "../../../core/http_common";
import { NotesCreateReq,NotesGetReq } from "../../../data/models/requests/notes/notes_create_req";
import { TeamCreateRes } from "../../models/responses/team/team_create_res";

export class RemoteNotes {
  createNotes(
    body: NotesCreateReq,
    action: (res: TeamCreateRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/Notes", body)
      .then((res) => {action(res.data)})
      .catch((err) => {
        if (err.response && err.response.status === 401) {
           
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
           
          error(err);
        }
      });
  }

  getNotes(
    body: NotesGetReq,
    action: (res: TeamCreateRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/Notes/GetNotes", body)
      .then((res) => {action(res.data)})
      .catch((err) => {
        
        if (err.response && err.response.status === 401) {
           
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
           
          error(err);
        }
      });
  }
}
