// import React from "react";
// import { AppConstants } from "../../core/constants";
// import { getAmazonUrl } from "../../core/utils";

import { toast } from 'react-toastify';
import { HTTP } from '../../core/http_common';

type Props = {
  file?: {
    externalUrl?: string;
    id?: number;
    inputDataType?: number;
    name?: string;
    title?: string;
    useCase?: number;
  };
  fileVariant?: 'outlined' | 'text';
  linkVariant?: 'outlined' | 'text';
  className?: string;
  style?: any;
  profileType?: number;
  researchId?: number;
  svg?: React.ReactNode;
  bgClass?: string;
};

export default function File({
  file,
  fileVariant = 'outlined',
  linkVariant = 'outlined',
  className = '',
  style = {},
  profileType,
  researchId,
  svg,
  bgClass = '',
}: Props) {
  const { externalUrl, inputDataType, title } = file || {};
  const handleDownloadClick = async () => {
    if (!file?.name && !externalUrl) {
      toast.error('File not available for download.');
      return;
    }

    try {
      const response = await checkUserAccess(researchId || 0, profileType || 0);

      if (response.success) {
        if (externalUrl) {
          const aElement = document.createElement('a');
          aElement.href = externalUrl;
          aElement.setAttribute('target', '_blank');
          aElement.click();
          URL.revokeObjectURL(externalUrl);
        } else if (file?.name) {
          const aElement = document.createElement('a');
          aElement.href = file.name;
          aElement.setAttribute('target', '_blank');
          aElement.click();
          URL.revokeObjectURL(file.name);
        }
      } else {
        toast.error('User Access Denied.');
      }
    } catch (error) {
      toast.error('An error occurred while checking user access.');
    }
  };

  // const [amazonUrl, setAmazonUrl] = React.useState<string>("");
  // console.log("file", file);
  // useEffect(() => {
  //   if (!externalUrl && name) {
  //     handleGetAmazonUrl(name).then((_url: any) => {
  //       setAmazonUrl(_url);
  //     });
  //     // setAmazonUrl(url);
  //     // });
  //   }
  // }, []);
  // console.log(amazonUrl);
  // @ts-ignore
  // const handleGetAmazonUrl = (_name: string) => {
  //   return new Promise(async (resolve, reject) => {
  //     const _url: string = await getAmazonUrl(_name || "");
  //     resolve(_url);
  //   });
  // };
  async function checkUserAccess(profileId: number, profileType: number) {
    try {
      const response = await HTTP.post('/UserAccess/DownloadAccess', {
        profileId,
        profileType,
      });

      if (response.data.result) {
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      console.error('Error:', error);
      return { success: false, error: 'An error occurred' };
    }
  }
  if (!externalUrl) {
    // create a function that return string

    // get the file type from the name
    const fileType = title?.split('.').pop();

    // const _amazonUrl: string = handleGetAmazonUrl();
    return (
      <a
        className={`s-chip text-truncate fs-200 ${fileVariant} d-flex pointer max1LineText ${className} ${bgClass}`}
        style={{ padding: '0.16em 0.25em', margin: '0.16em 0', lineHeight: '1.5', ...style }}
        title='Download the file'
        onClick={handleDownloadClick}
        target='_blank'
        rel='noopener noreferrer'
      >
        {/* <img
          src={`/images/icons/${inputDataType?.isMedia()}`}
          alt=""
          style={{
            height: "1.25em",
            width: "1.25em",
          }}
        // width={20}
        // height={20}
        /> */}
        <span className='mx-1 max1LineText'>
          {/* {title + (fileType ? "." + fileType : "")} */}
          {svg} {title}
          {/* {$truncate(title || "", 32)} */}
        </span>
      </a>
    );
    // return (
    //   <div
    //     className={`s-chip ${fileVariant} pointer max1LineText`}
    //     style={{ padding: "0.25em" }}
    //     title="Download the file"
    //     onClick={() => {
    //       downloadFile(
    //         AppConstants.base_url_image + `/${name}`,
    //         title || "",
    //         name
    //       );
    //     }}
    //   >
    //     <img
    //       src={`/images/icons/${inputDataType?.isMedia()}`}
    //       alt=""
    //       style={{
    //         height: "1.5em",
    //         width: "1.5em",
    //       }}
    //       // width={20}
    //       // height={20}
    //     />
    //     <span className="mx-1">
    //       {title + (fileType ? "." + fileType : "")}
    //       {/* {$truncate(title || "", 32)} */}
    //     </span>
    //   </div>
    // );
  }
  return (
    <a
      href={externalUrl}
      target='_blank'
      rel='noreferrer'
      className={`s-chip fs-200 ${linkVariant} blueColor max1LineText d-flex ${className} ${bgClass}`}
      title='Go to the link'
      style={style}
    >
      {/* {$truncate(externalUrl || "", 32)} */}
      <span className='max1LineText'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='12'
          height='13'
          viewBox='0 0 12 13'
          fill='none'
        >
          <rect
            width='12'
            height='12'
            transform='translate(0 0.500458)'
            fill='white'
            fill-opacity='0.01'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M5.99972 1.94047C3.48131 1.94047 1.43972 3.98205 1.43972 6.50047C1.43972 9.01885 3.48131 11.0605 5.99972 11.0605C8.51816 11.0605 10.5598 9.01885 10.5598 6.50047C10.5598 3.98205 8.51816 1.94047 5.99972 1.94047ZM0.719727 6.50047C0.719727 3.58441 3.08366 1.22047 5.99972 1.22047C8.91576 1.22047 11.2798 3.58441 11.2798 6.50047C11.2798 9.41653 8.91576 11.7805 5.99972 11.7805C3.08366 11.7805 0.719727 9.41653 0.719727 6.50047Z'
            fill='#0D0D0D'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M10.8002 6.82044H1.2002V6.18044H10.8002V6.82044Z'
            fill='#1C2024'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M5.67988 11.3005V1.70046H6.31988V11.3005H5.67988ZM8.29995 6.50044C8.29995 4.76225 7.67487 3.04268 6.44942 1.90571L6.8303 1.49519C8.19163 2.7582 8.85995 4.63864 8.85995 6.50044C8.85995 8.36225 8.19163 10.2427 6.8303 11.5057L6.44942 11.0952C7.67487 9.95822 8.29995 8.23864 8.29995 6.50044ZM3.19971 6.50046C3.19971 4.64135 3.84624 2.76188 5.1661 1.49821L5.55337 1.90271C4.36652 3.03904 3.75971 4.75957 3.75971 6.50046C3.75971 8.24135 4.36653 9.9619 5.55339 11.0982L5.16611 11.5027C3.84626 10.239 3.19972 8.35958 3.19971 6.50046Z'
            fill='#1C2024'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M5.99972 3.66686C7.73518 3.66686 9.5 3.98777 10.6962 4.65604C10.8313 4.73145 10.8796 4.90203 10.8042 5.03704C10.7288 5.17204 10.5582 5.22035 10.4232 5.14493C9.34328 4.54169 7.68615 4.22686 5.99972 4.22686C4.31329 4.22686 2.65617 4.54169 1.57627 5.14493C1.44126 5.22035 1.27068 5.17204 1.19527 5.03704C1.11986 4.90203 1.16816 4.73145 1.30317 4.65604C2.49948 3.98777 4.26426 3.66686 5.99972 3.66686ZM5.99972 9.18045C7.73518 9.18045 9.5 8.85949 10.6962 8.19125C10.8313 8.11585 10.8796 7.94526 10.8042 7.81026C10.7288 7.67525 10.5582 7.62695 10.4232 7.70236C9.34328 8.3056 7.68615 8.62045 5.99972 8.62045C4.31329 8.62045 2.65617 8.3056 1.57627 7.70237C1.44126 7.62695 1.27068 7.67525 1.19527 7.81026C1.11986 7.94526 1.16816 8.11585 1.30317 8.19125C2.49948 8.85949 4.26426 9.18045 5.99972 9.18045Z'
            fill='#1C2024'
          />
        </svg>
        <span style={{ paddingLeft: '5px' }}>{externalUrl}</span>
      </span>
    </a>
  );
}
