import React, { useEffect } from 'react'
import { HTTP } from '../../../core/http_common';
import { toast } from 'react-toastify';
import NewTable from '../../components/NewTable/NewTable';
import ReactPaginate from 'react-paginate';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
import { SelectComponent } from '../../components/select_input';
import moment from 'moment';
import { MOMENT_DATE_TIME_FORMAT } from '../../../core/constants';
import { SortType } from '../../../core/number_extentions';
import RadixTable from '../../components/NewRadixTable/RadixTable';
import { Table } from '@radix-ui/themes';
import { MainButton } from '../../components/button';

interface SessionHistoryTypes {

};

function getUserSessionList(
    _body: {
        PageNumber: number;
        PageSize: number;
        columnHeaderName?: string;
        sortDirection?: SortType;
    }
): Promise<{
    userSessions: any[];
    count: number;
}> {
    return new Promise((resolve, reject) => {
        // const _query = `LaboratoryId=${_body.LaboratoryId}&EquipmentId=${_body.EquipmentId}&From=${_body.From}&To=${_body.To}&IsUsersAllLabsEquipmentsReservation=${_body.IsUsersAllLabsEquipmentsReservation}&IsUsersAllEquipmentsReservation=${_body.IsUsersAllEquipmentsReservation}`;
        let _query = ``;

        // if (_body.From) {
        //   _query += `From=${_body.From}&`;
        // }
        // if (_body.To) {
        //   _query += `To=${_body.To}&`;
        // }
        if (_body.PageNumber) {
            _query += `PageNumber=${_body.PageNumber}&`;
        }
        if (_body.PageSize) {
            _query += `PageSize=${_body.PageSize}&`;
        }
        if (_body.columnHeaderName) {
            _query += `ColumnName=${_body.columnHeaderName}&`;
        } if (_body.sortDirection) {
            _query += `SortDirection=${_body.sortDirection}&`;
        }

        HTTP.get(`/User/UserSessions?${_query}`)
            .then((_res) => {
                if (_res.data && _res.data.result) {
                    resolve(_res.data.result);
                } else {
                    resolve({
                        userSessions: [],
                        count: 0,
                    });
                }
            })
            .catch((_err) => {
                if (_err.response?.data.message)
                    toast.error(`${_err.response?.data.message}`);
                reject(_err);
            });
    });
}
const SessionHistory = (props: SessionHistoryTypes) => {
    const [loading, setLoading] = React.useState(false);
    const [sessionList, setSessionList] = React.useState<any>([]);
    const [paginate, setPaginate] = React.useState({
        pageNumber: 0,
        pageSize: {
            label: "10",
            value: 10,
        },
        totalCount: 0,
        pageCount: 0,
    });
    useEffect(() => {
        handleGetUserSessions({
            PageNumber: 1,
            PageSize: 10,
        });
    }, [])
    async function handleGetUserSessions(_query: {
        PageNumber: number;
        PageSize: number;
        columnHeaderName?: string;
        sortDirection?: SortType;
    }) {
        try {
            setLoading(true);
            const res = await getUserSessionList(_query);
            setPaginate((prev) => ({
                ...prev,
                pageCount: Math.ceil(res.count! / paginate.pageSize.value),
                totalCount: res.count,
            }));
            setSessionList(res.userSessions);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            
        }
    }
    const headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
        handleGetUserSessions({
            PageNumber: paginate.pageNumber,
            PageSize: paginate.pageSize.value,
            columnHeaderName,
            sortDirection
        });
    };

    return (

            // <div className="TableBox" style={{ marginTop: 0, background:"#fff", padding:"0", paddingBottom:"10px" }}>
                <RadixTable
                    loading={loading}
                    data={sessionList}
                    onHeaderClick={(columnHeaderName: string | undefined, sortDirection: SortType | undefined) => headerClick(columnHeaderName, sortDirection)}
                    columns={[
                        // {
                        //     Header: "Session ID",
                        //     accessor: "id",
                        // },
                        {
                            Header: "Issued at",
                            accessor: "issuedAt",
                            Cell: (_row: any) => 
                            {
                                return(
                                <Table.Cell className="p-3">
                    <div className="line-clamp-2 textWrapLabel"style={{fontWeight:"400"}}>
                      {moment(_row.issuedAt).format(MOMENT_DATE_TIME_FORMAT)}
                    </div>
                  </Table.Cell>)
                            }
                        },
                        {
                            Header: "Last active",
                            accessor: "lastActive",
                            Cell: (_row: any) => 
                                {
                                    return(
                                    <Table.Cell className="p-3">
                        <div className="line-clamp-2 textWrapLabel"style={{fontWeight:"400"}}>
                        {moment(_row.lastActive).format(MOMENT_DATE_TIME_FORMAT)}
                        </div>
                      </Table.Cell>)
                                }
                            
                        },
                        {
                            Header: "Ip Address",
                            accessor: "ipAddress",
                            Cell: (_row: any) => 
                            {
                                return(
                                <Table.Cell className="p-3">
                    <div className="line-clamp-2 textWrapLabel"style={{fontWeight:"400"}}>
                      {_row.ipAddress}
                    </div>
                  </Table.Cell>)
                            }
                        },
                        {
                            Header: "",
                            Cell: (_row: any) => 
                            {
                                return(
                                <Table.Cell className="p-3">
                    <MainButton
                    data-bs-toggle="modal"
                    data-bs-target="#modal-add-type"
                    className="btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 hover-fill"
                    svg={<svg className='hover-fill' xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                        <rect width="15" height="15" transform="translate(0 0.500244)" fill="white" fillOpacity="0.01"/>
                        <path fillRule="evenodd" clip-rule="evenodd" d="M5 5.13622C5 4.26052 5.24219 3.60561 5.64323 3.17378C6.03934 2.74726 6.64582 2.47851 7.5014 2.47851C8.35745 2.47851 8.96306 2.74673 9.35823 3.17229C9.75838 3.6032 10 4.25729 10 5.13346V6.5002H5V5.13622ZM4 6.5002V5.13622C4 4.08169 4.29339 3.15775 4.91049 2.49328C5.53252 1.8235 6.42675 1.47852 7.5014 1.47852C8.57583 1.47852 9.46952 1.82254 10.091 2.49183C10.7076 3.15578 11 4.07917 11 5.13346V6.5002H12C12.5523 6.5002 13 6.94792 13 7.5002V13.5002C13 14.0525 12.5523 14.5002 12 14.5002H3C2.44772 14.5002 2 14.0525 2 13.5002V7.5002C2 6.94792 2.44772 6.5002 3 6.5002H4ZM3 7.5002H12V13.5002H3V7.5002Z" fill="#5746AF"/>
                      </svg>}
                    // onClick={() => {
                    //   setEditId(_row.id);
                    //   setEditable(true);
                    //   setType(_row.title);
                    //   setOpenModel(true);
                    // } }
                  >
                    
                    Terminate
                  </MainButton>
                  </Table.Cell>)
                            }
                        },
                    ]}
                />
                // {paginate.pageCount > 1 ? (
                //     <div className="d-flex justify-content-between align-items-center px-3 mt-3">
                //         <div className="d-flex justify-content-end flex-fill">
                //             <ReactPaginate
                //                 forcePage={paginate.pageNumber}
                //                 previousLabel={
                //                     <CircleIcon
                //                         width="24px"
                //                         padding="0"
                //                         backgroundColor="#ADADAD"
                //                         height="24px"
                //                         type={ThemeCircleIcon.dark}
                //                     >
                //                         <i className="fas fa-chevron-left"></i>
                //                     </CircleIcon>
                //                 }
                //                 nextLabel={
                //                     <CircleIcon
                //                         width="24px"
                //                         backgroundColor="#ADADAD"
                //                         height="24px"
                //                         padding="0"
                //                         type={ThemeCircleIcon.dark}
                //                     >
                //                         <i className="fas fa-angle-right"></i>
                //                     </CircleIcon>
                //                 }
                //                 breakLabel={"..."}
                //                 breakClassName={"break-me"}
                //                 pageCount={paginate.pageCount}
                //                 marginPagesDisplayed={2}
                //                 pageRangeDisplayed={5}
                //                 onPageChange={(e) => {
                //                     setPaginate((prev) => ({ ...prev, pageNumber: e.selected }));

                //                     handleGetUserSessions({
                //                         PageNumber: e.selected + 1,
                //                         PageSize: paginate.pageSize.value,
                //                     });
                //                     // setData(
                //                     //   handlePaginate(
                //                     //     allReservation,
                //                     //     paginate.pageSize.value,
                //                     //     e.selected + 1
                //                     //   )
                //                     // );
                //                 }}
                //                 containerClassName={"pagination"}
                //                 activeClassName={"active"}
                //             />
                //         </div>
                //         <div className="d-flex justify-content-end flex-fill">
                //             <p className="text-right mb-0" style={{ fontSize: 11 }}>
                //                 Total Results: {paginate.totalCount}
                //             </p>
                //         </div>
                //     </div>
                // ) : null}
            // </div>
    )
};

export default SessionHistory