import React from "react";
import ReactPaginate from "react-paginate";
import { store } from "../../../data/storeMain";
import { MainButton, MainButtonType } from "../../components/button";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import { InputIcon } from "../../components/search_box";
import { SelectComponent } from "../../components/select_input";
// import DiscusstionListTable from "./component/discusstion_list_table";
import { RouteComponentProps, withRouter } from "react-router";
import { AppRoutes, MOMENT_DATE_FORMAT } from "../../../core/constants";
import { DiscusstionController } from "../../../controllers/discussion/discusstion_controller";
import { Discussion } from "../../../data/models/responses/discussion/get_all_discusstion_res";
import { $ReplaceRoute, isTokenInHttpAuth } from "../../../core/utils";
import { HTTP } from "../../../core/http_common";
// import { SetResearchId } from "../../../data/storeMain/actions/research_action";
import Tippy from "@tippyjs/react";
import NewTable from "../../components/NewTable/NewTable";
import { Badge } from "../../components/badge";
// import { AiFillMessage } from "react-icons/ai";
import debounce from "lodash.debounce";
import { BsFillChatLeftDotsFill } from "react-icons/bs";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { RxMagnifyingGlass } from "react-icons/rx";
import EmptyChip from "../../components/EmptyChip";
import PhoneSearch from "../../components/PhoneSearch";
import { MdAdd } from "react-icons/md";
import CustomTooltip from "../../components/CustomTooltip";
import { SortType, discussionFilterStatus } from "../../../core/number_extentions";
import { number } from "yup";
import FilterSection from "../../components/FilterSection/filterSection";
import { IFilterOptions } from "../../components/FilterSection/IFilterOptions";
import moment from "moment";
import DOMPurify from "dompurify";
type StateType = {
  Discusstion: Discussion[];
  PageNumber: number;
  PageSize: number;
  PageCount: number;
  TotalCount: number;
  Search: string;
  dataComeFromUrl?: null | {
    _topicId: number;
    _sectionId: number;
    _parentTaskId: number | null;
    _subTaskId: number | null;
    _researchTitle: string | null;
  };
  changeResearchMenuVisible: boolean;
  loading: boolean;
  HeaderName: string;
  SortDirection: SortType;
  showFilterOptions: boolean;
  filterOptions: IFilterOptions;

};
class DiscusstionList extends React.Component<RouteComponentProps> {
  RoleUser = store.getState().userRole;
  controller = new DiscusstionController();
  state: StateType = {
    Discusstion: [],
    PageNumber: 1,
    PageSize: 10,
    PageCount: 0,
    TotalCount: 0,
    Search: "",
    dataComeFromUrl: null,
    changeResearchMenuVisible: false,
    loading: true,
    HeaderName:"",
    SortDirection: SortType.AutoSelect,
    showFilterOptions: false,
    filterOptions: {}
  };

  constructor(props: any) {
    super(props);
    this.handleChangeDiscussionSearch = debounce(
      this.handleChangeDiscussionSearch.bind(this),
      500
    );
  }
  handleGetDiscusstionBySectionAndTopic(
    discussionTopic: number,
    sectionId: number,
    PageNumber: number,
    PageSize: number,
    columnHeaderName?: string,
     sortDirection?: SortType
  ) {
    this.setState({
      loading: true,
    });
    const _body = {
      discussionTopic,
      sectionId,
      PageNumber,
      PageSize,
      columnHeaderName,
      sortDirection,
    };
    this.controller.getDiscusstionBySectionAndTopic(
      _body,
      (_res) => {
        if (!_res) {
          this.setState({
            loading: false,
          });
          return;
        }
        this.setState({
          Discusstion: _res.discussions || [],
          PageCount: Math.ceil(_res.count! / this.state.PageSize),
          TotalCount: _res.count,
          loading: false,
        });
      },
      (_err) => {
        this.setState({
          loading: false,
        });
      }
    );
  }
  componentDidMount() {
    document.title = `My Discussion Panels | Radvix`
    if (!isTokenInHttpAuth(HTTP)) return;

    const _urlParams = this.props.location?.search;
    if (_urlParams) {
      const _splitiedParams = _urlParams.split("?")[1].split("&");
      const _topicId = Number(_splitiedParams[0].split("=")[1]);
      const _sectionId = Number(_splitiedParams[1].split("=")[1]);
      const _parentTaskId = Number(_splitiedParams[2]?.split("=")[1]) || null;
      const _subTaskId = Number(_splitiedParams[3]?.split("=")[1]) || null;
      let _researchTitle = null;
      if (_topicId === 0) {
        _researchTitle = _splitiedParams[2]?.split("=")[1] || null;
        // remove all %20 from string
        _researchTitle = _researchTitle?.replace(/%20/g, " ");
      }
      this.setState({
        dataComeFromUrl: {
          _topicId,
          _sectionId,
          _parentTaskId,
          _subTaskId,
          _researchTitle,
        },
      });
      this.handleGetDiscusstionBySectionAndTopic(_topicId, _sectionId, 1, 10);
    } else {
      this.GetDiscusstion(this.state.PageNumber, this.state.PageSize);
    }
  }
  GetDiscusstion(PageNumber: number, PageSize: number, Search?: string, columnHeaderName?: string, sortDirection?: SortType,filterOptions?: IFilterOptions) {
    this.setState({
      loading: true,
    });
    if (sortDirection == undefined || sortDirection == null) {
      sortDirection = SortType.AutoSelect;
    }
    this.controller.getAllDiscusstion(
      {
        PageNumber: PageNumber,
        PageSize: PageSize,
        ticket: false,
        SearchParameter: Search ? Search : this.state.Search,
        columnHeaderName,
        sortDirection,
        filterOptions
        // UserId: store.getState().userInfo.id,
      },
      (res) => {
        
        if (!res) {
          this.setState({
            loading: false,
          });
          return;
        }
        
        this.setState({
          Discusstion: res.discussions || [],
          PageCount: Math.ceil(res.count! / this.state.PageSize),
          TotalCount: res.count,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
  }
  handelChangePageNumber(e: { selected: number }) {
    this.setState({
      PageNumber: e.selected,
    });
    this.GetDiscusstion(e.selected + 1, this.state.PageSize, this.state.Search, this.state.HeaderName, this.state.SortDirection,this.state.filterOptions);
  }
  handelChangePageSize(e: { label: string; value: number }) {
    this.setState({
      PageSize: e.value,
    });
    this.GetDiscusstion(this.state.PageNumber, e.value,this.state.Search, this.state.HeaderName, this.state.SortDirection, this.state.filterOptions);
  }

  handleChangeDiscussionSearch(e: any) {
    this.GetDiscusstion(
      this.state.PageNumber,
      this.state.PageSize,
      e.target.value
    );
  }
  headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    this.setState({ HeaderName: columnHeaderName });
    this.setState({ SortDirection: sortDirection });
    if(this.state.dataComeFromUrl?._topicId){
      this.handleGetDiscusstionBySectionAndTopic(this.state.dataComeFromUrl?._topicId, this.state.dataComeFromUrl?._sectionId, 1, 10,columnHeaderName,sortDirection);
    }
    else{
      this.GetDiscusstion(
        this.state.PageNumber,
        this.state.PageSize,
        this.state.Search,
        columnHeaderName,
        sortDirection,
        this.state.filterOptions
      );
    }
    
  };
  handleToggle = () => {
    this.setState({ showFilterOptions: !this.state.showFilterOptions });
  };
  filterClick = (selectedFromDate?: Date, selectedToDate?: Date, status?: any[], teamType?: number[]) => {
    var filterOptions= {
      selectedFromDate: selectedFromDate,
      selectedToDate: selectedToDate,
      status: status,
      teamType: teamType,
    }
    this.setState({ filterOptions: filterOptions });

    this.GetDiscusstion(
      this.state.PageNumber,
      this.state.PageSize,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      filterOptions
    );
  };
  render() {
    
    return (
      <div className="container-fluid research">
        <div className="row"></div>
        <div className="col-12">
          <div className="TableBox">
            <div className="relative TopTableBox d-flex flex-md-row justify-content-between align-items-md-start mb-0  mb-sm-3">
              <div className="left d-flex align-items-center col-3 headerAlign">
                {/* <span
                  // style={{
                  //   backgroundSize: "auto",
                  //   height: '2em',
                  //   width:'2em'
                  // }}
                  onClick={() => {
                    window.history.back();
                  }}
                  className="backPage"
                ></span>{" "}
                <h5
                  className="b-title d-flex align-items-center whiteSpaceNoWrap mb-0"
                  // style={{ width: "90%" }}
                >
                  My Discussion Panels
                </h5> */}
                <h5
                  // style={{ width: "35%" }}
                  className="b-title d-flex align-items-center"
                >
                  <IoIosArrowDropleftCircle
                    className="me-3 textSecondary2 cursorPointer"
                    size="1.5em"
                    onClick={() => {
                      window.history.back();
                    }}
                  />{" "}
                  My Discussion Panels
                </h5>
              </div>
              <div className="col-3" style={{paddingTop:'6px'}}>
                <CircleIcon
                  width="28px"
                  height="28px"
                  padding="0"
                  float="right"
                  type={ThemeCircleIcon.dark}
                  onClick={this.handleToggle}
                  className="pointer mx-1"
                >
                  <img
                    src="/images/icons/filterNew(2).svg"
                    alt="radvix"
                    width={15}
                    height={15}
                  />
                </CircleIcon>
              </div>
              <div className="right  d-flex justify-content-between align-items-baseline">

                {this.state.dataComeFromUrl?._topicId === 0 ? (
                  <Tippy
                    content={
                      <div className="d-flex flex-column p-2">
                        <h5>Need to change research</h5>
                        <p className="textSecondary">
                          To create a discussion for selected research we need
                          to change the current research, please accept to
                          continue.
                        </p>
                        <div className="d-flex flex-column">
                          <span className="textSecondary">
                            Current research:
                          </span>
                          <span
                            title={store.getState().ResearchId?.label}
                            className="text-black mt-1"
                            style={{ fontWeight: 700 }}
                          >
                            {store.getState().ResearchId?.label}
                          </span>
                        </div>
                        <div className="d-flex flex-column mt-2">
                          <span className="textSecondary">
                            Target research:
                          </span>
                          <span
                            title={
                              this.state.dataComeFromUrl?._researchTitle || ""
                            }
                            className="text-black mt-1"
                            style={{ fontWeight: 700 }}
                          >
                            {this.state.dataComeFromUrl?._researchTitle}
                          </span>
                        </div>
                        <div className="d-flex align-items-center mt-3 justify-content-end">
                          <MainButton
                            children="Decline"
                            type={MainButtonType.light}
                            borderRadius="8px"
                            fontSize="16px"
                            className="px-3 py-1 me-1"
                            onClick={() => {
                              this.setState({
                                changeResearchMenuVisible: false,
                              });
                            }}
                          ></MainButton>
                          <MainButton
                            children="Accept"
                            type={MainButtonType.dark}
                            borderRadius="8px"
                            fontSize="16px"
                            className="px-3 py-1"
                            onClick={() => {
                              // store.dispatch(
                              //   SetResearchId({
                              //     name:
                              //       this.state.dataComeFromUrl
                              //         ?._researchTitle || "",
                              //     id:
                              //       this.state.dataComeFromUrl?._sectionId ||
                              //       -1,
                              //   })
                              // );
                              window.dispatchEvent(
                                new CustomEvent("HANDLE_RESEARCH_CHANGED", {
                                  detail: {
                                    name:
                                      this.state.dataComeFromUrl
                                        ?._researchTitle || "",
                                    id:
                                      this.state.dataComeFromUrl?._sectionId ||
                                      -1,
                                  },
                                })
                              );
                              setTimeout(() => {
                                this.props.history.push(
                                  $ReplaceRoute(
                                    `${AppRoutes.discussion_new}?topicId=0&sectionId=${this.state.dataComeFromUrl?._sectionId}`
                                  )
                                );
                              }, 100);
                            }}
                          ></MainButton>
                        </div>
                      </div>
                    }
                    placement="bottom"
                    arrow={false}
                    interactive
                    visible={this.state.changeResearchMenuVisible}
                    onClickOutside={() => {
                      this.setState({
                        changeResearchMenuVisible: false,
                      });
                    }}
                  >
                    <div>
                      <MainButton
                        children="Start a new discussion"
                        type={MainButtonType.dark}
                        borderRadius="24px"
                        fontSize="14px"
                        minHeight="2em"
                        className="my-0 mx-0 px-2 dNoneSm"
                        minWidth="120px"
                        onClick={() => {
                          const _currentSelectedResearch =
                            store.getState().ResearchId?.value;
                          if (
                            _currentSelectedResearch ===
                            this.state.dataComeFromUrl?._sectionId
                          ) {
                            this.props.history.push(
                              $ReplaceRoute(
                                `${AppRoutes.discussion_new}?topicId=0&sectionId=${this.state.dataComeFromUrl?._sectionId}`
                              )
                            );
                          } else {
                            this.setState({
                              changeResearchMenuVisible: true,
                            });
                          }
                          // store.dispatch(
                          //   SetResearchId({
                          //     name: this.state.Research.title,
                          //     id: this.state.Research.id,
                          //   })
                          // );
                        }}
                      ></MainButton>
                      <CircleIcon
                        padding="0"
                        height="2em"
                        // type={ThemeCircleIcon.dark}
                        onClick={() => {
                          const _currentSelectedResearch =
                            store.getState().ResearchId?.value;
                          if (
                            _currentSelectedResearch ===
                            this.state.dataComeFromUrl?._sectionId
                          ) {
                            this.props.history.push(
                              $ReplaceRoute(
                                `${AppRoutes.discussion_new}?topicId=0&sectionId=${this.state.dataComeFromUrl?._sectionId}`
                              )
                            );
                          } else {
                            this.setState({
                              changeResearchMenuVisible: true,
                            });
                          }
                        }}
                        width="2em"
                        className="dNone dFlexSm me-1"
                        children={<MdAdd size="1.4em" />}
                        style={{
                          minWidth: '2em',
                          minHeight: '2em',
                        }}
                      ></CircleIcon>
                    </div>
                  </Tippy>
                ) : (
                  <>
                    <MainButton
                      children="Start a new discussion"
                      type={MainButtonType.dark}
                      borderRadius="24px"
                      minHeight="2em"
                      fontSize="14px"
                      className="my-0 mx-0 px-3 dNoneSm"
                      minWidth="120px"
                      onClick={() => {
                        if (this.props.location?.search) {
                          this.props.history.push(
                            $ReplaceRoute(
                              `${AppRoutes.discussion_new}${this.props.location?.search}`
                            )
                          );
                        } else {
                          this.props.history.push(
                            $ReplaceRoute(`${AppRoutes.discussion_new}`)
                          );
                        }
                      }}
                    ></MainButton>
                    <CircleIcon
                      padding="0"
                      height="2em"
                      // type={ThemeCircleIcon.dark}
                      onClick={() => {
                        if (this.props.location?.search) {
                          this.props.history.push(
                            $ReplaceRoute(
                              `${AppRoutes.discussion_new}${this.props.location?.search}`
                            )
                          );
                        } else {
                          this.props.history.push(
                            $ReplaceRoute(`${AppRoutes.discussion_new}`)
                          );
                        }
                      }}
                      width="2em"
                      className="dNone dFlexSm me-1"
                      children={<MdAdd size="1.4em" />}
                      style={{
                        minWidth: '2em',
                        minHeight: '2em',
                      }}
                    ></CircleIcon>
                  </>
                )}
                <InputIcon
                  parentClassName="mx-2 dNoneSm"
                  chilren={
                    this.state.Search.length > 0 ? (
                      <i
                        className="fa fa-times pointer"
                        aria-hidden="true"
                        onClick={() => {
                          this.setState(
                            {
                              Search: "",
                            },
                            () => {
                              this.GetDiscusstion(
                                this.state.PageNumber,
                                this.state.PageSize
                              );
                            }
                          );
                        }}
                      ></i>
                    ) : (
                      <RxMagnifyingGlass
                        size="1.25em"
                        className="textSecondary"
                      />
                    )
                  }
                  width="100%"
                  placeholder="Search..."
                  TopPosition="15%"
                  value={this.state.Search}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const sanitizedValue = DOMPurify.sanitize(inputValue);
                    this.setState({
                      Search: sanitizedValue,
                    });
                    this.handleChangeDiscussionSearch(e);
                  }}
                ></InputIcon>
                <SelectComponent
                  width="90px" className="dNoneSm"
                  height="44px"
                  items={[
                    { label: "10", value: 10 },
                    { label: "15", value: 15 },
                    { label: "20", value: 20 },
                  ]}
                  defaultValue={this.state.PageSize}
                  TextItem="item"
                  ValueItem="id"
                  isMulti={false}
                  placeholder={this.state.PageSize.toString()}
                  onChange={(e) => {
                    this.handelChangePageSize(e);
                  }}
                ></SelectComponent>


                <PhoneSearch
                  searchIcon={this.state.Search.length > 0 ? (
                    <i
                      className="fa fa-times pointer"
                      aria-hidden="true"
                      onClick={() => {
                        this.setState(
                          {
                            Search: "",
                          },
                          () => {
                            this.GetDiscusstion(
                              this.state.PageNumber,
                              this.state.PageSize
                            );
                          }
                        );
                      }}
                    ></i>
                  ) : (
                    <RxMagnifyingGlass
                      size="1.25em"
                      className="textSecondary"
                    />
                  )}
                  value={this.state.Search}
                  onChange={(e) => {
                    this.setState({
                      Search: e.target.value,
                    });
                    this.handleChangeDiscussionSearch(e);
                  }}
                  buttonStyle={{
                    marginRight: 0,
                    marginLeft: 0,
                    minHeight: '2em'
                  }}
                />
              </div>
            </div>
            { this.state.showFilterOptions &&
            <div style={{ backgroundColor: "white", borderRadius: 10}}>
              <div className="d-flex justify-content-left align-items-center col-12">                
                <div id="clientsDiv" className="col-12">
                  <FilterSection
                  dateLabel="Last Message"
                  status={[{Id: discussionFilterStatus.UnreadMessage, isStatusSelected: false},{Id: discussionFilterStatus.NoNewMessage, isStatusSelected: false}]}
                  onselect={(selectedFromDate, selectedToDate,status,teamType) => this.filterClick(selectedFromDate ? selectedFromDate : undefined, selectedToDate ? selectedToDate : undefined,status,teamType)}
                  />
                </div>
              </div>
            </div>
            }
            <NewTable
              searchValue={this.state.Search}
              loadingCount={this.state.PageSize}
              loading={this.state.loading}
              data={this.state.Discusstion}
              customGridTemplate="1em 1.5fr 0.8fr 0.8fr 7em 4em"
              headerRowClassName="d-flex d-sm-grid"
              rowClassName="newTableRowPhone relative"
              onHeaderClick={(columnHeaderName, sortDirection) => this.headerClick(columnHeaderName, sortDirection)}
              columns={[
                {
                  Header: "",
                  accessor: "priority",
                  cellClassName: "d-flex dNoneSm",
                  headerCellClassName: 'dNoneSm',
                  skeletonConfig: {
                    width: "0.5em",
                  },
                  Cell: (_row) => (
                    <Badge
                      text={_row.priority.isPriority()}
                      color="#fff"
                      background={
                        _row.priority === 0
                          ? "#9CFBFF"
                          : _row.priority === 1
                            ? "#40c3f4"
                            : "#096bff"
                      }
                      style={{
                        top: "0",
                      }}
                    />
                  ),
                },
                {
                  Header: "Discussion subject",
                  accessor: "subject",
                  ellipsis: true,
                  cellClassName: "fullRow",
                  Cell: (_row: any) => (
                    <div className="max1LineText d-flex whiteSpaceNoneSm pe-4 pe-sm-0">
                      <Badge
                        text={_row.priority.isPriority()}
                        color="#fff"
                        background={
                          _row.priority === 0
                            ? "#9CFBFF"
                            : _row.priority === 1
                              ? "#40c3f4"
                              : "#096bff"
                        }
                        style={{
                          top: "0",
                        }}
                        className="dNone dFlexSm"
                      />
                      <span className="max1LineText whiteSpaceNoneSm fontBoldSm linkOnHover pointer" title={_row.subject} onClick={() => {
                        this.props.history.push(
                          $ReplaceRoute(
                            `${AppRoutes.discussion.replace(
                              ":id",
                              _row.id.toString() ?? ""
                            )}`
                          )
                        );
                      }}>{_row.subject}</span>
                    </div>
                  ),
                },
                {
                  Header: "Related to",
                  headerCellClassName: 'dNoneSm',
                  accessor: "topic",
                  align: "center",
                  Cell: (_row) => {
                    return (
                      <>
                        <span className="dNoneSm">
                          {_row.topic.isTopic()}
                        </span>
                        <CustomTooltip className="dNone dBlockSm" content='Related to'>
                          <span className="s-chip" style={{ backgroundColor: 'hsl(0, 0%, 85%)' }}>
                            {_row.topic.isTopic()}
                          </span>
                        </CustomTooltip>
                      </>
                    )
                  },
                },
                {
                  Header: "Update",
                  headerCellClassName: 'dNoneSm',
                  accessor: "priority",
                  align: "center",
                  Cell: (_row) => {
                    if (_row.unreadMessageCount > 0)
                      return (
                        <>
                          <span className="s-chip p-0 darkenBg topRightInSm dNone dFlexSm align-items-center justify-content-center"
                            style={{
                              width: '1.75em',
                              height: '1.75em'
                            }}
                          >
                            {_row.unreadMessageCount}
                          </span>
                          <span className="s-chip darkenBg py-1 px-2 dNoneSm">
                            {_row.unreadMessageCount} messages
                          </span>
                        </>
                      );

                    return (
                      <div className="d-flex justify-content-center dNoneSm">
                        <EmptyChip text="No messages" />
                      </div>
                    );
                  },
                },
                {
                  Header: "Last Message",
                  headerCellClassName: 'dNoneSm',
                  accessor: "priority",
                  align: "center",
                  Cell: (_row) => {                   
                      return (                        
                          <span className="dNoneSm">
                            {moment(_row.lastMessageDate).format(MOMENT_DATE_FORMAT)}
                          </span>
                      );
                  } 
                },
                {
                  Header: "Actions",
                  headerCellClassName: 'dNoneSm',
                  cellClassName: 'dNoneSm',
                  accessor: "id",
                  align: "center",
                  Cell: (_row) => {
                    return (
                      <div className="d-flex justify-content-center">
                        <CircleIcon
                          width="26px"
                          height="26px"
                          padding="0"
                          type={ThemeCircleIcon.dark}
                          onClick={() => {
                            this.props.history.push(
                              $ReplaceRoute(
                                `${AppRoutes.discussion.replace(
                                  ":id",
                                  _row.id.toString() ?? ""
                                )}`
                              )
                            );
                          }}
                          className="pointer"
                        >
                          <BsFillChatLeftDotsFill />
                          {/* <img
                            src="/images/icons/google_docs.svg"
                            alt="radvix"
                            width={12}
                            height={12}
                          /> */}
                        </CircleIcon>
                      </div>
                    );
                  },
                },
              ]}
            />
            {/* <DiscusstionListTable
              Items={this.state.Discusstion}
              Heading={["Subject", "Related To", "Update"]}
            ></DiscusstionListTable> */}

            <div className="d-flex justify-content-between align-items-center px-3 mt-3 px-0-sm mt-1-sm">
              <div className="dNone dFlexSm">
                <SelectComponent
                  width="90px" className="me-1"
                  height="44px"
                  items={[
                    { label: "10", value: 10 },
                    { label: "15", value: 15 },
                    { label: "20", value: 20 },
                  ]}
                  defaultValue={this.state.PageSize}
                  TextItem="item"
                  ValueItem="id"
                  isMulti={false}
                  selectProps={{ isSearchable: false }}
                  placeholder={this.state.PageSize.toString()}
                  onChange={(e) => {
                    this.handelChangePageSize(e);
                  }}
                ></SelectComponent>
              </div>
              {this.state.TotalCount > this.state.PageSize ? (
                <div className="d-flex justify-content-end flex-fill">
                  <ReactPaginate
                    previousLabel={
                      <CircleIcon
                        width="24px"
                        padding="0"
                        backgroundColor="#4E0057"
                        height="24px"
                        type={ThemeCircleIcon.dark}
                      >
                        <i className="fas fa-chevron-left"></i>
                      </CircleIcon>
                    }
                    nextLabel={
                      <CircleIcon
                        width="24px"
                        padding="0"
                        backgroundColor="#4E0057"
                        height="24px"
                        type={ThemeCircleIcon.dark}
                      >
                        <i className="fas fa-angle-right"></i>
                      </CircleIcon>
                    }
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.PageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(e) => {
                      this.handelChangePageNumber(e);
                    }}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                </div>
              ) : (
                ""
              )}
              <div className="d-flex justify-content-end flex-fill">
                <p className="text-right mb-0 ">
                  Total Results: {this.state.TotalCount}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(DiscusstionList);
