import { UserRoles } from "../core/utils";
import { AppSettingResResult } from "./models/responses/app_setting/enum_list_res";
export type UserImage = {
  id: number;
  inputDataType: number;
  name: string;
  title: string;
  useCase: number;
  externalUrl: string | null;
};
export type UserResume = {
  id: number;
  inputDataType: number;
  name: string;
  title: string;
  useCase: number;
  externalUrl: string | null;
};
type UserInfo = {
  email: string;
  firstName: string;
  lastName: string;
  // image: string;
  image: UserImage;
  role: UserRoles;
  institution: string;
  userTypeName: string;
  lastSeletedSearchId: number;
};
export class LocalDataSources {
  getSetting(): { [key: string]: AppSettingResResult[] } {
    const Setting = localStorage.getItem("setting");
    if (Setting) {
      return JSON.parse(Setting);
    }
    return {};
  }
  getUserId(): string {
    const userId = localStorage.getItem("userId");
    if (userId) {
      return userId;
    }
    return "";
  }

  getUserInfo(): UserInfo {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      return JSON.parse(userInfo);
    }
    return {
      firstName: "",
      email: "",
      lastName: "",
      image: {
        id: 0,
        inputDataType: 0,
        name: "",
        title: "",
        useCase: 0,
        externalUrl: null,
      },
      role: UserRoles.L1Client,
      institution: "",
      userTypeName: "",
      lastSeletedSearchId: -1,
    };
  }
  logedin(): boolean {
    const logedin = localStorage.getItem("logedin");
    if (logedin) {
      return JSON.parse(logedin);
    }
    return false;
  }
}
