import { SidebardCollapseAction } from '../actions/sidebard_collapse_action';
import { SIDEBAR_COLLAPSE } from '../actions/actions_type';

const initalState = {
  isCollapsed: false,
};

const reducer = (state = initalState, action: SidebardCollapseAction) => {
  switch (action.type) {
    case SIDEBAR_COLLAPSE: {
      return { ...state, isCollapsed: action.payload };
    }
    default:
      return state;
  }
};

export default reducer;
