import { HTTP, handleUnauthorizedRequest } from "../../../core/http_common";
import { CreateNewCategory } from "../../models/responses/category/create_new_category";
import { GetAllCategories } from "../../models/responses/category/get_all_categories";

export class RemoteCategory {
  getAllCategories(
    body: { type: number },
    action: (res: GetAllCategories) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(`/Category?type=${body.type}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  createNewCategory(
    body: { title: string; type: number },
    action: (res: CreateNewCategory) => any,
    error: (res: any) => any
  ) {
    return HTTP.post(`/Category`, body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  editCategory(
    body: { id: number; title: string; type: number },
    action: (res: CreateNewCategory) => any,
    error: (res: any) => any
  ) {
    return HTTP.put(`/Category`, body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  deleteCategory(
    body: { id: number },
    action: (res: CreateNewCategory) => any,
    error: (res: any) => any
  ) {
    return HTTP.delete(`/Category?categoryId=${body.id}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getAllCountries(
    body: any,
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(`/Category/GetCountries`)
      .then((res) => {
        
        action(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
}
