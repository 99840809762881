import { SIDEBAR_COLLAPSE } from './actions_type';

export type SidebardCollapseAction = {
  type: string;
  payload: boolean;
};

export function SetSidebarCollapse(sidebarCollapsed: boolean): SidebardCollapseAction {
  return {
    type: SIDEBAR_COLLAPSE,
    payload: sidebarCollapsed,
  };
}
