export interface ClientsRes {
  status: number;
  result: ClientsResResult;
  message: string;
}

export interface ClientsResResult {
  [x: string]: any;
  users: UserClients[];
  count: number;
  l1Clients: any[];
}

export interface UserClients {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  institution: string;
  role: number;
  joinedDate: Date;
  totalMembers: number;
  totalProjects: number;
  storageUsage: number;
  subscription: string;
  profileImage: string;
}

export enum BillingCycleEnum
{        
    Day = 1,
    Week = 2,
    Month = 3,
    Year = 4
}