import { SET_EXCLUDE_CLIENTS } from "./actions_type";


export type ExcludeClientsAction = {
  type: string;
  payload: (string | number)[];
};
export function SetExcludedClients(excludedClients: (string | number)[]): ExcludeClientsAction {
  return {
    type: SET_EXCLUDE_CLIENTS,
    payload: excludedClients,
  };
}
