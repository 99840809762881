import React from 'react';
import { store } from '../../../data/storeMain';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
import { InputComponent, InputType } from '../../components/inputs';
import 'react-datepicker/dist/react-datepicker.css';
import { MainButton, MainButtonType } from '../../components/button';
import Dropzone from 'react-dropzone';
import { SelectComponent } from '../../components/select_input';
import { ButtonGroup } from '../../components/botton_group';
// import { BoxAlert } from "../../components/box_alert";
import { RouteComponentProps, withRouter } from 'react-router';
import { AppRoutes, LOADING_MODE } from '../../../core/constants';
import { DiscusstionController } from '../../../controllers/discussion/discusstion_controller';
import { UploadController } from '../../../controllers/upload_media/upload_media';
import SimpleReactValidator from 'simple-react-validator';
import { LocalDataSources } from '../../../data/local_datasources';
import { DiscusstionCreateReq } from '../../../data/models/requests/discussion/discusstion_create_req';
import {
  $ReplaceRoute,
  // $truncate,
  getUserTitle,
  isTokenInHttpAuth,
  removeDuplicateObj,
  uploadAllFiles,
  uploadAllLinks,
  UserRoles,
} from '../../../core/utils';
import {
  Expense,
  General,
  DataSet,
  Publication,
  Research,
  TaskSubTask,
  Equipment,
  Laboratory,
  Team,
  Researches,
} from './forms';
import { DiscusstionSectionResResult } from '../../../data/models/responses/discussion/section_user_res';
// import { SelectTeamsAndMembers } from "../../components/select_teams_and_members";
import { toast } from 'react-toastify';
import { HTTP } from '../../../core/http_common';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import DOMPurify from 'dompurify';
import { BaseButton } from '@fluentui/react';
import { SetResearchId } from '../../../data/storeMain/actions/research_action';
import { ResearchController } from '../../../controllers/research/research_controller';
import { SetHeaderTitle } from '../../../data/storeMain/actions/header_title_action';

type StateType = {
  selectedTaskId?: number;
  typeId: number;
  selectedTypeId: { label: string; value: number } | null;
  topic: { label: string; value: number };
  sectionId: { label: string; value: number };
  searchLoading: boolean;
  loadingMode: string;
  currentResearch: { id: number; name: string };
  subject: string;
  message: string;
  priority: number;
  isDiscussionForSubTask: number;
  usersId: string[];
  files: Array<File>;
  ExternalUrl: Array<string>;
  External: string;
  loading: boolean;
  listPriority: Array<{ label: string; value: number }>;
  listDiscusstionOn: Array<{ label: string; value: number } | {}>;
  selectedOption: number;
  listMembers: Array<{ label: string; value: string } | {}>;
  selectedMembers: Array<{ label: string; value: string } | {}>;

  listExpense: Array<{ label: string; value: number } | {}>;
  selectedExpense: { label: string; value: number } | null;

  listTasks: Array<{ label: string; value: number } | {}>;
  selectedTask: { label: string; value: number } | null;

  listPublication: Array<{ label: string; value: number } | {}>;
  selectedPublication: { label: string; value: number } | null;

  listSubTasks: Array<{ label: string; value: number } | {}>;
  selectedSubTask: { label: string; value: number } | null;

  listDataSets: Array<{ label: string; value: number } | {}>;
  selectedDataSet: { label: string; value: number } | null;

  listEquipment: Array<{ label: string; value: number } | {}>;
  selectedEquipment: { value: number; label: string } | null;

  listLaboratory: Array<{ label: string; value: number } | {}>;
  selectedLaboratory: { value: number; label: string } | null;

  listTeam: Array<{ label: string; value: number } | {}>;
  selectedTeam: { value: number; label: string } | null;

  researhList: any[];
  selectedProject: any;
};

interface RouteParams {
  topic: string;
  section: string;
}
const Default = () => <></>;

const forms: {
  [key: number | string]:
    | React.FC<{
        listResearch: any[];
        onResearchChange: (e: any) => void;
        typeId: any[];
        selectedTypeId: { label: string; value: number } | null;

        listExpense: any[];
        selectedExpense: { label: string; value: number } | null;

        listTasks: any[];
        selectedTask: { label: string; value: number } | null;

        listPublication: any[];
        selectedPublication: { label: string; value: number } | null;

        listSubTasks: any[];
        selectedSubTask: { label: string; value: number } | null;

        listDataSets: any[];
        selectedDataSet: { label: string; value: number } | null;

        listEquipment: any[];
        selectedEquipment: { value: number; label: string } | null;

        listLaboratory: any[];
        selectedLaboratory: { value: number; label: string } | null;

        listTeam: any[];
        selectedTeam: { value: number; label: string } | null;

        myRole: number;
        isDiscussionForSubTask: number;
        currentResearch: {
          id: number;
          name: string;
        };
        onSelectTask: (e: any) => void;
        onChangeIsDiscusstionForSubTask: (e: any) => void;
        onSelectSubTask: (e: any) => void;
        onChangeExpenseMode: (e: any) => void;
        onSelectExpense: (e: any) => void;
        onSelectPublication: (e: any) => void;
        onSelectDataSet: (e: any) => void;
        onSelectEquipment: (e: any) => void;
        onSelectLaboratory: (e: any) => void;
        onSelectTeam: (e: any) => void;
        disabledAll: boolean;
      }>
    | any;
} = {
  0: Researches,
  1: General,
  2: TaskSubTask,
  3: DataSet,
  4: Equipment,
  5: Laboratory,
  6: Team,
  8: Expense,
  7: Publication,
  '-1': Default,
};
let unsubscribeMeData: any = undefined;
class NewDiscusstion extends React.Component<RouteComponentProps<RouteParams>> {
  RoleUser = store.getState().userRole;
  controller = new DiscusstionController();
  researchController = new ResearchController();
  UploadController = new UploadController();
  local: LocalDataSources = new LocalDataSources();
  validator = new SimpleReactValidator({
    className: 'text-danger',
  });

  state: StateType = {
    //selectedTaskId: null,
    loadingMode: '',
    searchLoading: false,
    topic: { label: 'Please Select..', value: -1 },
    sectionId: { label: 'Please Select..', value: -1 },
    currentResearch: { id: -1, name: '' },
    subject: '',
    message: '',
    priority: 1,
    isDiscussionForSubTask: 0,
    usersId: [],
    files: [],
    ExternalUrl: [],
    External: '',
    listDiscusstionOn: [],
    typeId: 2,
    selectedTypeId: null,
    listMembers: [],
    selectedMembers: [],

    listPublication: [],
    selectedPublication: null,

    listExpense: [],
    selectedExpense: null,

    listTasks: [],
    selectedTask: null,

    listSubTasks: [],
    selectedSubTask: null,

    listDataSets: [],
    selectedDataSet: null,

    listEquipment: [],
    selectedEquipment: null,

    listLaboratory: [],
    selectedLaboratory: null,

    listTeam: [],
    selectedTeam: null,

    listPriority: [],
    loading: false,
    selectedOption: 0,

    researhList: [],
    selectedProject: [],
  };
  componentDidMount() {
    document.title = `New Discussion Panel | Radvix`;
    store.dispatch(SetHeaderTitle('Communications'));
    if (!isTokenInHttpAuth(HTTP)) return;
    const queryParams = new URLSearchParams(this.props.location.search);
    const topicEnum = queryParams.get('topicId');
    if (store.getState().ResearchId.value >= 0) {
      this.setState({ currentResearch: store.getState().ResearchId.value });
    }

    // const _mySettings: any =
    //   store.getState().settingApp.discussionTopicEnum;
    // let _discusstionOptions: any = [];

    // if (_mySettings && _mySettings.discussionTopicEnum) {
    // _discusstionOptions = _mySettings.discussionTopicEnum.map(
    //   (_dis: { title: string; id: number }) => {
    //     return {
    //       label: _dis.title,
    //       value: _dis.id,
    //     };
    //   }
    // );
    // }

    const _listDiscussionOn = [
      {
        label: 'General',
        value: 1,
      },
      {
        label: 'Task',
        value: 2,
      },
      {
        label: 'Data Set',
        value: 3,
      },
      {
        label: 'Equipment',
        value: 4,
      },
      {
        label: 'Laboratory',
        value: 5,
      },
      {
        label: 'Team',
        value: 6,
      },
      {
        label: 'Expense',
        value: 8,
      },
      {
        label: 'Project',
        value: 0,
      },
      {
        label: 'Publication',
        value: 7,
      },
    ];
    this.setState({
      // listDiscusstionOn: _discusstionOptions,
      listDiscusstionOn:
        this.RoleUser === UserRoles.ExternalUser
          ? _listDiscussionOn.filter((item) => item.value === 1 || item.value === 7)
          : _listDiscussionOn,
      // listMembers: res.members
      //   ? res.members.map((item) => {
      //       const _userTitle =
      //         item.firstName === " "
      //           ? item.email
      //           : item.firstName + " " + item.lastName;

      //       return {
      //         label: _userTitle,
      //         value: item.id,
      //         secondary: item.invitationStatus === 1 ? "Pending" : null,
      //       };
      //     })
      //   : [],
      // listDiscusstionOn: res.discussionOn
      //   ? res.discussionOn.map((item) => {
      //       return {
      //         label: item.title,
      //         value: item.id,
      //       };
      //     })
      //   : [],
      listPriority: this.local.getSetting().priority.map((item) => {
        return { name: item.title, id: item.id };
      }),
    });

    const _urlParams = this.props.location?.search;
    if (_urlParams) {
      const _splitiedParams = _urlParams.split('?')[1].split('&');
      //const _topicId = Number(_splitiedParams[0].split("=")[1]);
      //const _sectionId = Number(_splitiedParams[1].split("=")[1]);
      //const _parentTaskId = Number(_splitiedParams[2]?.split("=")[1]);
      const _subTaskId = Number(_splitiedParams[3]?.split('=')[1]);
      const researchId = queryParams.get('researchId')
        ? parseInt(queryParams.get('researchId') as string)
        : null;
      const _sectionId = queryParams.get('sectionId')
        ? parseInt(queryParams.get('sectionId') as string)
        : null;
      const _topicId = queryParams.get('topicId')
        ? parseInt(queryParams.get('topicId') as string)
        : null;
      const _parentTaskId = queryParams.get('taskId');
      //this.setState({ selectedTaskId: _parentTaskId ? parseInt(_parentTaskId) : null});
      this.state.typeId = _parentTaskId ? 3 : 2;
      const _findedTopicFromListDiscusstionOn = _listDiscussionOn.find(
        (_d: any) => _d.value === _topicId
      );

      this.handleChangeDiscusstion(
        // @ts-ignore
        _findedTopicFromListDiscusstionOn,
        _sectionId,
        _parentTaskId ? parseInt(_parentTaskId) : null,
        _subTaskId,
        researchId
      );
    }

    // this.controller.searchDiscusstion(
    //   {
    //     discussionTopic: 1,
    //     isTicket: false,
    //   },
    //   (res) => {
    //     // const _mySettings = JSON.parse(localStorage.getItem("setting") ?? "{}");

    //     // if (parseInt(this.props.match.params.topic) !== 1) {
    //     //   this.controller.discusstionSectionUser(
    //     //     {
    //     //       discussionTopic: parseInt(this.props.match.params.topic),
    //     //       sectionId: parseInt(this.props.match.params.section),
    //     //     },
    //     //     (res) => {

    //     //       this.setState({
    //     //         listMembers: res.members
    //     //           ? res.members.map((item) => {
    //     //               return {
    //     //                 label: item.firstName + " " + item.lastName,
    //     //                 value: item.id,
    //     //               };
    //     //             })
    //     //           : [],
    //     //       });
    //     //     },
    //     //     (err) => {}
    //     //   );
    //     // }
    //   },
    //   (err) => { }
    // );
    unsubscribeMeData = store.subscribe(() => {
      // alert('called inside sub')
      if (store.getState().ResearchId.value >= 0) {
        // if (this.state.topic?.value === 2) {
        this.setState({
          topic: { label: 'Please Select..', value: -1 },
          sectionId: { label: 'Please Select..', value: -1 },
          listMembers: [],
          selectedMembers: [],

          listPublication: [],
          selectedPublication: null,

          listExpense: [],
          selectedExpense: null,

          listTasks: [],
          selectedTask: null,

          listSubTasks: [],
          selectedSubTask: null,

          listDataSets: [],
          selectedDataSet: null,

          listEquipment: [],
          selectedEquipment: null,

          listLaboratory: [],
          selectedLaboratory: null,

          listTeam: [],
          selectedTeam: null,

          currentResearch: store.getState().ResearchId,
        });

        // this.handleGetSearchTopic(2, false).then((res: any) => {
        //   const _userRole = this.local.getUserInfo()?.role;

        //   let _myState: {
        //     listTasks: [];
        //   } = {
        //     listTasks: [],
        //   };
        //   // let _tasks: [] = [];
        //   // let _findedTaskFrom_tasks = null;
        //   if (_userRole === UserRoles.L3User) {
        //     _myState.listTasks = res.subTask.map((_task: any) => {
        //       return {
        //         label: _task.title,
        //         value: _task.id,
        //       };
        //     });
        //   } else {
        //     _myState.listTasks = res.discussionOn.map((_task: any) => {
        //       return {
        //         label: _task.title,
        //         value: _task.id,
        //       };
        //     });
        //   }
        //   this.setState({
        //     ..._myState,
        //   });
        // });
        // }
        // this.setState({  });
      }
    });

    this.loadResearches();
  }
  // clean up event listener
  componentWillUnmount() {
    if (unsubscribeMeData) unsubscribeMeData();
  }
  onDrop = (fileList: any) => {
    fileList.map((item: any, index: number) => {
      const found = this.state.files.find((element: any) => element.name === item.name);
      if (found === undefined) {
        this.setState({ files: [fileList[index], ...this.state.files] });
      }
    });
  };
  handelDeleteFile(arg: File) {
    this.setState({
      files: this.state.files.filter((file) => file.name !== arg.name),
    });
  }

  formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 MB';

    const m = 1024 * 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizeInMB = bytes / m;

    return `${sizeInMB.toFixed(dm)} MB`;
  }

  addExternalUrl() {
    if (this.validator.fields.input && this.state.External) {
      let Url = [...this.state.ExternalUrl];
      Url.push(this.state.External);
      this.setState({
        ExternalUrl: Url,
        External: '',
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handelDeleteExternalLink(link: string) {
    this.setState({
      ExternalUrl: this.state.ExternalUrl.filter((item) => item !== link),
    });
  }
  handelChangeSelect(e: { label: string; value: number }, target: string) {
    this.setState({ [target]: e.value });
  }
  handleChange(target: string, val: any) {
    if (target === 'priority') {
      this.setState({
        [target]: val,
      });
    } else {
      const sanitizedValue = DOMPurify.sanitize(val);
      this.setState({
        [target]: sanitizedValue,
      });
    }
  }
  handelSelectMembers(e: Array<{ label: string; value: string }>) {
    // this.setState({ usersId: e.map((item) => item.value) });

    this.setState({ selectedMembers: e });
  }

  handleGetSearchTopic = (
    discussionTopic: number,
    isTicket: boolean,
    researchId?: number,
    taskId?: number
  ) => {
    return new Promise((resolve, reject) => {
      this.setState({
        searchLoading: true,
      });
      this.controller.searchDiscusstion(
        {
          discussionTopic,
          taskId,
          researchId,
          isTicket,
        },
        (res) => {
          this.setState({
            searchLoading: false,
          });
          resolve(res);
        },
        (err) => {
          this.setState({
            searchLoading: false,
          });
          reject(err);
        }
      );
    });
  };
  handleGetSectionUsers = (discussionTopic: number, sectionId: number) => {
    if (sectionId === -1) return Promise.resolve({ members: [] });
    return new Promise((resolve, reject) => {
      this.setState({
        searchLoading: true,
      });
      this.controller.discusstionSectionUser(
        {
          discussionTopic,
          sectionId,
        },
        (_res) => {
          let _allTeamUsers: any[] = [..._res?.members];
          _res.teams?.forEach((_team) => {
            _allTeamUsers.push(...(_team?.users || []));
          });
          const _uniqueMembers: any = removeDuplicateObj(_allTeamUsers || []);

          this.setState({
            searchLoading: false,
          });
          resolve({
            members: _uniqueMembers,
          });
        },
        (err) => {
          this.setState({
            searchLoading: false,
          });
          reject(err);
        }
      );
    });
  };
  isUserHaveId(_user: { id: number | undefined }) {
    return _user.id === undefined ? false : true;
  }

  handleUpdateTeamsAndMembers(_res: DiscusstionSectionResResult) {
    const _members = _res.members.map((_mem: any) => {
      const _userTitle = getUserTitle(_mem);
      return {
        label: _userTitle,
        value: _mem.id,
        secondary: _mem.invitationStatus === 1 ? 'Pending' : null,
      };
    });

    this.setState({
      listMembers: _members,
      selectedMembers: _members,
    });
  }
  loadResearches() {
    this.researchController.getResearches(
      {
        PageNumber: 1,
        PageSize: 100,
        SearchParameter: '',
        columnHeaderName: '',
      },
      (res: any) => {
        const filteredResearches = res.researchesList?.filter(
          (research: any) => research.title !== 'All Projects'
        );
        const researches = filteredResearches?.map(({ id, title }: any) => ({
          value: id,
          label: title,
        }));
        this.setState({ researhList: researches });
        this.setState({
          selectedProject: store.getState().ResearchId,
        });
      },
      (err: any) => {}
    );
  }

  handleChangeProject(e: any) {
    this.setState({
      selectedProject: e,
    });
    this.handleChangeDiscusstion(this.state.topic, null, this.state.selectedTaskId, null, e?.value);
    //store.dispatch(SetResearchId({ label: e?.label, value: e?.value }));
  }

  handleChangeDiscusstion = (
    e: {
      value: number | null | undefined;
      label: string | null;
    },
    sectionId: number | null = null,
    parentTaskId: number | null = null,
    subTaskId: number | null = null,
    researchId: number
  ) => {
    this.validator.purgeFields();
    const _userRole = this.local.getUserInfo()?.role;

    switch (e.value) {
      case 1: {
        // (general): return a method => fill all members of subscribtion plan into
        // this.state.listMembers && select mySelf as default , can't remove mySelf

        // this.handleGetSearchTopic(0, false).then((res) => {
        //
        // });
        this.handleGetSectionUsers(1, 0).then((res) => {
          // @ts-ignore
          if (res.members.length > 0) {
            // @ts-ignore
            const _members = res.members
              .filter(this.isUserHaveId) // !nabayad filter konim, bayad az server data dorost biyad
              .map((_mem: any) => {
                const _userTitle = getUserTitle(_mem);
                return {
                  label: _userTitle,
                  value: _mem.id,
                  secondary: _mem.invitationStatus === 1 ? 'Pending' : null,
                };
              });

            const _findMe = _members.find(
              (_mem: { value: string }) => _mem.value === this.local.getUserId()
            );
            this.setState({
              listMembers: _members,
              selectedMembers: _findMe ? [_findMe] : [],
              sectionId: { label: 'General', value: 0 },
              // usersId: [_members[0].value],
            });
          }
        });
        break;
      }

      case 2: {
        const validsectionId = sectionId !== null ? sectionId : undefined;
        this.handleGetSearchTopic(2, false, researchId, validsectionId).then((res: any) => {
          let _myState: {
            listTasks: [];
            listSubTasks: [];
            selectedTask?: any;
            sectionId?: any;
            selectedSubTask?: any;
            isDiscussionForSubTask?: number;
          } = {
            listTasks: [],
            listSubTasks: [],
          };
          // let _tasks: [] = [];
          // let _findedTaskFrom_tasks = null;
          // if (_userRole === UserRoles.L3User) {
          _myState.listSubTasks = res.subTask.map((_task: any) => {
            return {
              label: _task.title,
              value: _task.id,
            };
          });
          // } else {
          _myState.listTasks = res.discussionOn.map((_task: any) => {
            return {
              label: _task.title,
              value: _task.id,
            };
          });
          //  _myState.selectedTask = res.discussionOn[0].Id;
          // }

          if (sectionId) {
            const _realTaskId = sectionId;

            if (this.state.typeId === 3) {
              _myState.selectedTask = _myState.listTasks.find(
                (_t: any) => _t.value === _realTaskId
              );
            } else {
              _myState.selectedSubTask = _myState.listSubTasks.find(
                (_t: any) => _t.value === _realTaskId
              );
            }

            // if (parentTaskId && _myState.selectedTask) {
            //   this.setState({
            //     isDiscussionForSubTask: 1,
            //   });
            //   this.onSelectTask(_myState.selectedTask, true).then(
            //     (_subTasks: any) => {
            //       if (_userRole !== UserRoles.L3User) {
            //         const _findedSubtaskFromList = _subTasks?.find(
            //           (_st: any) => _st.value === sectionId
            //         );
            //         this.onSelectSubTask(_findedSubtaskFromList).then(() => {
            //           this.focusSubjectInput();
            //         });
            //       } else {
            //         this.validator.purgeFields();
            //         this.focusSubjectInput();
            //       }
            //     }
            //   );
            // } else {
            if (_myState.selectedTask) {
              _myState.sectionId = _myState.selectedTask;

              this.onSelectTask(_myState.selectedTask, true).then(() => {
                this.focusSubjectInput();
              });
            }
            // }
          }

          this.setState({
            ..._myState,
          });
        });

        // (task): return a method => fetch tasks base on myRole =>
        // l1 (tasks): created by mySelf and all assigned to me.
        // L2 (tasks): all tasks that assigned to me by L1.
        // L3 (tasks): all subTasks that assigned to me by L2.

        break;
      }

      case 3: {
        const validsectionId = parentTaskId !== null ? parentTaskId : undefined;
        this.handleGetSearchTopic(2, false, researchId, validsectionId).then((res: any) => {
          let _myState: {
            listTasks: [];
            listSubTasks: [];
            selectedTask?: any;
            sectionId?: any;
            isDiscussionForSubTask?: number;
          } = {
            listTasks: [],
            listSubTasks: [],
          };
          // let _tasks: [] = [];
          // let _findedTaskFrom_tasks = null;
          // if (_userRole === UserRoles.L3User) {
          _myState.listSubTasks = res.subTask.map((_task: any) => {
            return {
              label: _task.title,
              value: _task.id,
            };
          });
          // } else {
          _myState.listTasks = res.discussionOn.map((_task: any) => {
            return {
              label: _task.title,
              value: _task.id,
            };
          });
          //}

          if (sectionId && parentTaskId) {
            _myState.selectedTask = _myState.listTasks.find((_t: any) => _t.value === parentTaskId);

            if (_myState.selectedTask) {
              // should check if data for task
              {
                this.onSelectTask(_myState.selectedTask, true).then((_dataSetList: any) => {
                  const _findedDataSetFromList = _dataSetList?.find(
                    (_dt: any) => _dt.value === sectionId
                  );

                  this.onSelectDataSet(_findedDataSetFromList).then(() => {
                    this.focusSubjectInput();
                  });
                });
              }
            }
          }

          this.setState({
            ..._myState,
          });

          // let _tasks: [] = [];
          // if (_userRole === UserRoles.L3User) {
          //   _tasks = res.subTask.map((_task: any) => {
          //     return {
          //       label: _task.title,
          //       value: _task.id,
          //     };
          //   });
          // } else {
          //   _tasks = res.discussionOn.map((_task: any) => {
          //     return {
          //       label: _task.title,
          //       value: _task.id,
          //     };
          //   });
          // }

          // this.setState({
          //   listTasks: _tasks,
          //   selectedTask: null,
          // });
        });

        // (DataSet): just like  (task) return a method => fetch tasks base on myRole =>
        // l1 (tasks): created by mySelf and all assigned to me.
        // L2 (tasks): all tasks that assigned to me by L1.
        // L3 (tasks): all tasks that assigned to me by L2.
        break;
      }

      case 8: {
        this.handleGetSearchTopic(2, false, researchId).then((res: any) => {
          let _myState: {
            listTasks: [];
            listSubTasks: [];
            selectedTask?: any;
            sectionId?: any;
            isDiscussionForSubTask?: number;
          } = {
            listTasks: [],
            listSubTasks: [],
          };
          // let _tasks: [] = [];
          // let _findedTaskFrom_tasks = null;
          // if (_userRole === UserRoles.L3User) {
          _myState.listSubTasks = res.subTask.map((_task: any) => {
            return {
              label: _task.title,
              value: _task.id,
            };
          });
          // } else {
          _myState.listTasks = res.discussionOn.map((_task: any) => {
            return {
              label: _task.title,
              value: _task.id,
            };
          });
          //}
          // ?topicId=8&sectionId=10013&parentTask=10039&subTask=10040
          if (sectionId && parentTaskId) {
            _myState.selectedTask = _myState.listTasks.find((_t: any) => _t.value === parentTaskId);

            if (_myState.selectedTask) {
              // should check if data for task
              if (subTaskId) {
                // _myState.isDiscussionForSubTask = 1;
                this.setState({
                  isDiscussionForSubTask: 1,
                });
                this.onSelectTask(_myState.selectedTask, true).then((_subTasks: any) => {
                  const _findedSubtaskFromList = _subTasks?.find(
                    (_st: any) => _st.value === subTaskId
                  );
                  this.onSelectSubTask(_findedSubtaskFromList).then((_subTaskExpenseList: any) => {
                    const _findedExpenseFromSubTaskExpenseList = _subTaskExpenseList?.find(
                      (_stE: any) => _stE.value === sectionId
                    );
                    this.onSelectExpense(_findedExpenseFromSubTaskExpenseList).then(() => {
                      this.focusSubjectInput();
                    });
                  });
                });
              } else {
                this.onSelectTask(_myState.selectedTask, true).then((_expenseList: any) => {
                  const _findedExpenseFromList = _expenseList?.find(
                    (_dt: any) => _dt.value === sectionId
                  );

                  this.onSelectExpense(_findedExpenseFromList).then(() => {
                    this.focusSubjectInput();
                  });
                });
              }
            }
          }

          this.setState({
            ..._myState,
          });

          // let _tasks: [] = [];
          // if (_userRole === UserRoles.L3User) {
          //   _tasks = res.subTask.map((_task: any) => {
          //     return {
          //       label: _task.title,
          //       value: _task.id,
          //     };
          //   });
          // } else {
          //   _tasks = res.discussionOn.map((_task: any) => {
          //     return {
          //       label: _task.title,
          //       value: _task.id,
          //     };
          //   });
          // }

          // this.setState({
          //   listTasks: _tasks,
          //   selectedTask: null,
          // });
        });
        // (expense): return a mthod => fetch expense base on myRole =>
        // L1 (expenses): select all expense added by L1 and L2 users to the tasks created by me,
        //    do not list that expense which added to subtasks unless the L2 user creates an expense it's parent task.
        // L2 (expenses): if task selected, all expenses that added by mySelf to tasks that assigned to me,
        //    if subtasks selected, all expense that added by me or any L3 users in my subteams that subtasks created by me
        // L3 (expenses): list of all expenses that added by me to any subtasks
        break;
      }

      case 0: {
        const _researchId =
          store.getState().ResearchId.value !== -1
            ? store.getState().ResearchId.value
            : this.state.currentResearch.id;

        // (research): do nothing
        this.handleGetSectionUsers(0, _researchId).then((res) => {
          this.handleUpdateTeamsAndMembers(res as DiscusstionSectionResResult);

          this.setState({
            sectionId: {
              label: 'Research',
              value: _researchId,
            },
          });
        });

        break;
      }
      case 7: {
        this.handleGetSearchTopic(7, false, store.getState().ResearchId.value).then((res: any) => {
          const _listPub = res.discussionOn.map((_pub: any) => {
            return {
              label: _pub.title,
              value: _pub.id,
            };
          });
          if (sectionId) {
            const _findedPubFromList = _listPub?.find((_lab: any) => {
              return _lab.value === sectionId;
            });

            if (_findedPubFromList)
              this.onSelectPublication(_findedPubFromList).then(() => this.focusSubjectInput());
          }

          this.setState({
            listPublication: _listPub,
          });
        });
        // (publication): list of all publication that i am member of my research projects
        break;
      }

      case 4: {
        // equipment
        this.handleGetSearchTopic(4, false).then((res: any) => {
          const _listEquipment = res.discussionOn.map((_eq: any) => {
            return {
              label: _eq.title,
              value: _eq.id,
            };
          });
          if (sectionId) {
            const _findedEquiFromList = _listEquipment?.find((_eq: any) => {
              return _eq.value === sectionId;
            });

            if (_findedEquiFromList)
              this.onSelectEquipment(_findedEquiFromList).then(() => this.focusSubjectInput());
          }
          this.setState({
            listEquipment: _listEquipment,
          });
        });
        break;
      }
      case 5: {
        // laboratory
        this.handleGetSearchTopic(5, false).then((res: any) => {
          const _listLaboratory = res?.discussionOn.map((_lab: any) => {
            return {
              label: _lab.title,
              value: _lab.id,
            };
          });
          if (sectionId) {
            const _findedLabFromList = _listLaboratory?.find((_lab: any) => {
              return _lab.value === sectionId;
            });

            if (_findedLabFromList)
              this.onSelectLaboratory(_findedLabFromList).then(() => this.focusSubjectInput());
          }
          this.setState({
            // @ts-ignore
            listLaboratory: _listLaboratory,
          });
        });
        break;
      }
      case 6: {
        // team
        this.handleGetSearchTopic(6, false).then((res: any) => {
          const _listTeam = res?.discussionOn.map((_team: any) => {
            return {
              label: _team.title,
              value: _team.id,
            };
          });
          if (sectionId) {
            const _findedTeamFromList = _listTeam?.find((_lab: any) => {
              return _lab.value === sectionId;
            });

            if (_findedTeamFromList)
              this.onSelectTeam(_findedTeamFromList).then(() => this.focusSubjectInput());
          }
          this.setState({
            listTeam: res.discussionOn.map((_t: any) => {
              return {
                label: _t.title,
                value: _t.id,
              };
            }),
          });
        });
        break;
      }
      default:
        break;
    }

    if (sectionId) {
      this.setState({
        topic: e,
        listMembers: [],
        selectedMembers: [],
        isDiscussionForSubTask: 0,
        listTasks: [],
        selectedPublication: null,
        listPublication: [],
        selectedSubTask: null,
        listSubTasks: [],
      });
      // setTimeout(()=> {

      // }, 2000)
    } else {
      this.setState({
        topic: e,
        listMembers: [],
        selectedMembers: [],
        sectionId: null,
        isDiscussionForSubTask: 0,
        selectedTask: null,
        listTasks: [],
        selectedPublication: null,
        listPublication: [],
        selectedSubTask: null,
        listSubTasks: [],

        selectedEquipment: null,
        listEquipment: [],
        selectedLaboratory: null,
        listLaboratory: [],
        selectedTeam: null,
        listTeam: [],
      });
    }
  };
  focusSubjectInput() {
    const _subjectInputEl = document.getElementById('New_Discuss_Subject_Input');

    _subjectInputEl?.focus();
  }
  CreateDiscusstion() {
    this.setState({
      loading: true,
      loadingMode: 'Starting discussion..',
    });
    if (this.state.selectedMembers.length < 2) {
      toast.error('Please ensure there are at least two participants in the discussion');
      console.log(this.state.selectedMembers);
      this.setState({
        loading: false,
        loadingMode: '',
      });
      return;
    }
    const _selectedUsers = this.state.selectedMembers.map((_mem: any) => _mem.value);
    const body: DiscusstionCreateReq = {
      topic: this.state.topic.value,
      sectionId: this.state.sectionId.value,
      subject: this.state.subject,
      message: this.state.message,
      priority: this.state.priority,
      usersId: _selectedUsers,
      researchId: this.state.selectedProject?.value,
    };

    this.controller.createDiscusstion(
      body,
      async (res) => {
        if (this.state.files.length || this.state.ExternalUrl.length) {
          const _firstMsgId = res.histories[0]?.id;
          await this.handelUpload(_firstMsgId, res.id);
        } else {
          this.props.history.push(
            $ReplaceRoute(
              `${AppRoutes.discussion}?discussionId=${res.id}&sectionId=${this.state.selectedProject?.value}&title=${this.state.selectedProject?.label}`
            )
          );
          this.setState({
            loading: false,
            loadingMode: '',
          });
        }

        this.setState({
          topic: { label: 'Please Select..', value: -1 },
          sectionId: { label: 'Please Select..', value: -1 },
          subject: '',
          message: '',
          priority: 1,
          usersId: [],
          files: [],
          ExternalUrl: [],
          External: '',
          loading: false,
        });
      },
      (err) => {
        this.setState({
          loading: false,
          loadingMode: '',
        });
      }
    );
    // if (true) {
    // } else {
    //   this.validator.showMessages();
    //   this.forceUpdate();
    // }
  }
  async handelUpload(id: number, disId: number) {
    try {
      this.setState({
        loadingMode: LOADING_MODE.links,
      });
      await uploadAllLinks(id, this.state.ExternalUrl, 7);

      this.setState({
        loadingMode: LOADING_MODE.files,
      });
      await uploadAllFiles(id, this.state.files, 7);

      this.props.history.push(
        $ReplaceRoute(`${AppRoutes.discussion.replace(':id', disId.toString() ?? '')}`)
      );
      this.setState({
        loading: false,
        loadingMode: '',
      });
    } catch (e) {
      this.setState({
        loading: false,
        loadingMode: '',
      });
    }

    // const formData = new FormData();
    // for (let i = 0; i < this.state.files.length; i++) {
    //   const file = this.state.files[i];
    //   formData.append("Files", file);
    // }
    // for (let i = 0; i < this.state.ExternalUrl.length; i++) {
    //   const file = this.state.ExternalUrl[i];
    //   formData.append("ExternalUrls", file);
    // }
    // formData.append("UseCase", "7");
    // formData.append("SectionId", id.toString());

    // await this.UploadController.UloadMedia(
    //   formData,
    //   (res) => {
    //     this.props.history.push(
    //       $ReplaceRoute(
    //         `${AppRoutes.discussion.replace(":id", disId.toString() ?? "")}`
    //       )
    //     );
    //     this.setState({
    //       loading: false,
    //     });
    //   },
    //   () => {
    //     this.setState({
    //       loading: false,
    //     });
    //   }
    // );
  }

  onSelectPublication = (e: any) => {
    return new Promise((resolve, reject) => {
      try {
        this.handleGetSectionUsers(7, e.value).then((res) => {
          // @ts-ignore
          const _members = res?.members?.map((_mem: any) => {
            const _userTitle = getUserTitle(_mem);
            return {
              label: _userTitle,
              value: _mem.id,
              secondary: _mem.invitationStatus === 1 ? 'Pending' : null,
            };
          });

          this.setState({
            listMembers: _members,
            selectedMembers: _members,
            selectedPublication: e,
            sectionId: e,
          });
          resolve(true);
        });
      } catch (e) {
        reject(e);
      }
    });
    // this.setState({
    // });
  };
  onSelectEquipment = (e: any) => {
    return new Promise((resolve, reject) => {
      try {
        this.handleGetSectionUsers(4, e.value).then((res) => {
          //
          // @ts-ignore
          const _members = res?.members?.map((_mem: any) => {
            const _userTitle = getUserTitle(_mem);
            return {
              label: _userTitle,
              value: _mem.id,
              secondary: _mem.invitationStatus === 1 ? 'Pending' : null,
            };
          });

          this.setState({
            listMembers: _members,
            selectedMembers: _members,
            selectedEquipment: e,
            sectionId: e,
          });
          resolve(true);
        });
      } catch (e) {
        reject(e);
      }
    });
    // this.setState({
    // });
  };
  onSelectLaboratory = (e: any) => {
    return new Promise((resolve, reject) => {
      try {
        this.handleGetSectionUsers(5, e.value).then((res) => {
          // @ts-ignore
          const _members = res?.members?.map((_mem: any) => {
            const _userTitle = getUserTitle(_mem);
            return {
              label: _userTitle,
              value: _mem.id,
              secondary: _mem.invitationStatus === 1 ? 'Pending' : null,
            };
          });
          this.setState({
            listMembers: _members,
            selectedMembers: _members,
            selectedLaboratory: e,
            sectionId: e,
          });

          resolve(true);
        });
      } catch (e) {
        reject(e);
      }
    });

    // this.setState({
    // });
  };
  onSelectTeam = (e: any) => {
    return new Promise((resolve, reject) => {
      try {
        this.handleGetSectionUsers(6, e.value).then((res) => {
          // @ts-ignore
          const _members = res?.members?.map((_mem: any) => {
            const _userTitle = getUserTitle(_mem);
            return {
              label: _userTitle,
              value: _mem.id,
              secondary: _mem.invitationStatus === 1 ? 'Pending' : null,
            };
          });

          this.setState({
            listMembers: _members,
            selectedMembers: _members,
            selectedTeam: e,
            sectionId: e,
          });
          resolve(true);
        });
      } catch (e) {
        reject(e);
      }
    });
    // this.setState({
    // });
  };
  onSelectDataSet = (e: any) => {
    // this.handleGetSectionUsers(3, e.value).then((res) => {
    //   // @ts-ignore
    //   const _members = res?.members?.map((_mem: any) => {
    //     const _userTitle = getUserTitle(_mem);
    //     return {
    //       label: _userTitle,
    //       value: _mem.id,
    //       secondary: _mem.invitationStatus === 1 ? "Pending" : null,
    //     };
    //   });

    //   this.setState({
    //     listMembers: _members,
    //     selectedMembers: _members,
    //     selectedDataSet: e,
    //     sectionId: e,
    //   });
    // });
    return new Promise((resolve, reject) => {
      this.setState({
        selectedDataSet: e,
        sectionId: e,
      });
      resolve(true);
    });
  };
  onSelectExpense = (e: any) => {
    return new Promise((resolve, reject) => {
      this.setState({
        selectedExpense: e,
        sectionId: e,
      });
      resolve(true);
    });
  };

  handleGetDataBaseOnTaskIdAndTopicId(_taskId: number, _topicId: number) {
    return new Promise((resolve, reject) => {
      this.handleGetSearchTopic(_topicId, false, 0, _taskId).then((_res: any) => {
        resolve(_res);
      });
    });
  }
  onSelectTask(e: any, isFromUrl: boolean = false) {
    return new Promise((resolve, reject) => {
      try {
        const _userRole = this.local.getUserInfo()?.role;
        const _selectedTopic = this.state.topic.value;
        const _selectedTaskId = e.value;
        const _isDiscussionForTask = this.state.isDiscussionForSubTask === 0;
        // const _selectedTaskData: any = this.state.listTasks.find(
        //   (item: any) => item.id === _selectedTaskData.value
        // );
        if (this.RoleUser === UserRoles.L2User || this.RoleUser === UserRoles.L3User) {
          this.setState({
            typeId: this.state.isDiscussionForSubTask === 1 ? 3 : 2,
          });

          if (this.state.typeId === 3) {
            this.setState({
              listSubTasks: _selectedTaskId?.subTasks?.map((item: any) => {
                return { label: item.title, value: item.id };
              }),
              selectedSubTask: null,
            });
          } else {
            this.setState({
              listSubTasks: _selectedTaskId?.Tasks?.map((item: any) => {
                return { label: item.title, value: item.id };
              }),
              selectedSubTask: null,
            });
          }
        }

        // is selected topic dataSet | task | expense => niazi nist check konim - motmaenim
        if (_selectedTopic === 3 || _selectedTopic === 2 || _selectedTopic === 8) {
          // is L1 users
          if (
            _userRole === UserRoles.L1Client ||
            _userRole === UserRoles.L1User ||
            _userRole === UserRoles.L3User
          ) {
            if (_selectedTopic === 3 || _selectedTopic === 8) {
              // is dataset | expense : get data base on task id and topic id
              this.handleGetDataBaseOnTaskIdAndTopicId(_selectedTaskId, _selectedTopic).then(
                (_data: any) => {
                  const _dataOptions = _data?.discussionOn?.map((_data: any) => {
                    return {
                      label: _data.title,
                      value: _data.id,
                    };
                  });

                  if (_selectedTopic === 3) {
                    this.setState({
                      listDataSets: _dataOptions,
                      selectedDataSet: null,
                    });
                  }
                  if (_selectedTopic === 8) {
                    this.setState({
                      listExpense: _dataOptions,
                      selectedExpense: null,
                    });
                  }
                  resolve(_dataOptions);
                }
              );
            }
            // alert('called')
            if (_selectedTopic !== 8) {
              this.handleGetSectionUsers(2, _selectedTaskId).then((_res: any) => {
                const _members = _res?.members?.map((_mem: any) => {
                  const _userTitle = getUserTitle(_mem);
                  return {
                    label: _userTitle,
                    value: _mem.id,
                    secondary: _mem.invitationStatus === 1 ? 'Pending' : null,
                  };
                });

                this.setState({
                  listMembers: _members,
                  selectedMembers: _members,
                });

                if (_selectedTopic === 2) resolve(true);
              });
            }
          }

          // is L2 user
          if (_userRole === UserRoles.L2User || _userRole === UserRoles.L3User) {
            // get subTasks based on selected task
            this.handleGetSubTaskBaseOnTaskId(e.value).then((_subtasks) => {
              this.setState({
                listSubTasks: _subtasks,
                selectedSubTask: null,
              });
              if (!_isDiscussionForTask) resolve(_subtasks);
            });

            if (_isDiscussionForTask) {
              if (_selectedTopic === 3 || _selectedTopic === 8) {
                // is dataset | expense : get data base on task id and topic id
                this.handleGetDataBaseOnTaskIdAndTopicId(_selectedTaskId, _selectedTopic).then(
                  (_data: any) => {
                    const _dataOptions = _data?.discussionOn?.map((_data: any) => {
                      return {
                        label: _data.title,
                        value: _data.id,
                      };
                    });

                    if (_selectedTopic === 3) {
                      this.setState({
                        listDataSets: _dataOptions,
                        selectedDataSet: null,
                      });
                    }
                    if (_selectedTopic === 8) {
                      this.setState({
                        listExpense: _dataOptions,
                        selectedExpense: null,
                      });
                    }

                    resolve(_dataOptions);
                  }
                );
              }
              if (_selectedTopic !== 8) {
                this.handleGetSectionUsers(2, _selectedTaskId).then((_res: any) => {
                  const _members = _res?.members?.map((_mem: any) => {
                    const _userTitle = getUserTitle(_mem);
                    return {
                      label: _userTitle,
                      value: _mem.id,
                      secondary: _mem.invitationStatus === 1 ? 'Pending' : null,
                    };
                  });

                  this.setState({
                    listMembers: _members,
                    selectedMembers: _members,
                  });

                  if (_selectedTopic === 2) resolve(true);
                });
              }
            }
          }
        }

        if (_selectedTopic === 2 && _isDiscussionForTask) {
          this.setState({
            sectionId: e,
          });
        }
        this.setState({
          selectedTask: e,
        });
      } catch (e) {
        reject(e);
      }
    });

    // this.handleGetSectionUsers(2, e.value).then((res: any) => {
    //   if (_userRole === UserRoles.L2User) {
    //     // is user L2
    //     this.handleGetSubTaskBaseOnTaskId(e.value).then((_subtasks) => {
    //       // get subtask base on taskId
    //       if (this.state.topic.value === 3 || this.state.topic.value === 8) {
    //         this.handleGetDataBaseOnTaskIdAndTopicId(
    //           e.value,
    //           this.state.topic.value
    //         ).then((_dataSets: any) => {
    //           if (this.state.selectedSubTask?.value) {
    //             this.setState({
    //               selectedSubTask: null,
    //             });
    //           }

    //           if (this.state.topic.value === 3) {
    //             if (this.state.selectedDataSet?.value) {
    //               this.setState({
    //                 selectedDataSet: null,
    //               });
    //             }

    //             this.setState({
    //               listDataSets: _dataSets?.discussionOn?.map(
    //                 (_dataSet: any) => {
    //                   return {
    //                     label: _dataSet.title,
    //                     value: _dataSet.id,
    //                   };
    //                 }
    //               ),
    //             });
    //           }
    //           if (this.state.topic.value === 8) {
    //             if (this.state.selectedExpense?.value) {
    //               this.setState({
    //                 selectedExpense: null,
    //               });
    //             }
    //             this.setState({
    //               listExpenses: _dataSets?.discussionOn?.map(
    //                 (_dataSet: any) => {
    //                   return {
    //                     label: _dataSet.title,
    //                     value: _dataSet.id,
    //                   };
    //                 }
    //               ),
    //             });
    //           }
    //         });
    //       }

    //       this.setState({
    //         listSubTasks: _subtasks,
    //       });
    //     });
    //   }
    //   if (_userRole === UserRoles.L1User || _userRole === UserRoles.L1Client) {
    //     // is user L1
    //     // get subtask base on taskId
    //     if (this.state.topic.value === 3) {
    //       this.handleGetDataBaseOnTaskIdAndTopicId(e.value, 3).then(
    //         (_dataSets: any) => {
    //           this.setState({
    //             listDataSets: _dataSets?.discussionOn?.map((_dataSet: any) => {
    //               return {
    //                 label: _dataSet.title,
    //                 value: _dataSet.id,
    //               };
    //             }),
    //           });

    //           if (this.state.selectedDataSet?.value) {
    //             this.setState({
    //               selectedDataSet: null,
    //             });
    //           }
    //         }
    //       );
    //     }
    //   }

    //   if (this.state.topic.value === 2) {
    //     const _members = res?.members?.map((_mem: any) => {
    //       const _userTitle = getUserTitle(_mem);
    //       return {
    //         label: _userTitle,
    //         value: _mem.id,
    //         secondary: _mem.invitationStatus === 1 ? "Pending" : null,
    //       };
    //     });
    //     this.setState({
    //       listMembers: _members,
    //       selectedMembers: _members,
    //     });
    //   }
    // });

    // L1: automatically select(members): the creator of task, all assigned teams and members to this task, L3 users not be added
    // L2: default on No: automatically select(members): the creator of task, all assigned teams and members to this task, L3 users not be added
    // if mode on DataSet:
  }

  handleGetSubTaskBaseOnTaskId(_taskId: number) {
    // get subtasks base on task id
    return new Promise((resolve, reject) => {
      // this.handleGetSectionUsers(2, _taskId).then((res) => {

      // });
      this.handleGetSearchTopic(2, false, _taskId).then((_res: any) => {
        resolve(
          _res.subTask.map((_subtask: any) => {
            return {
              label: _subtask.title,
              value: _subtask.id,
            };
          })
        );
      });
    });
  }
  onSelectSubTask(_e: any) {
    // L2: automatically select(members): the creator of subtask, and L3 users that assigned to this subtask
    // L3: automatically select(members): L2 user who has created this subtask, and L3 users that assigned to this subtask
    // const _currentTaskId = this.state.selectedTask?.value;
    return new Promise((resolve, reject) => {
      try {
        const _currentTopicId = this.state.topic?.value;
        const _selectedSubTaskId = _e?.value;

        if (_currentTopicId === 2 || _currentTopicId === 3 || _currentTopicId === 8) {
          if (_currentTopicId === 3 || _currentTopicId === 8) {
            // if topic is DataSet or Expense
            this.handleGetDataBaseOnTaskIdAndTopicId(_selectedSubTaskId, _currentTopicId).then(
              (_data: any) => {
                const _dataOptions = _data?.discussionOn?.map((_dataSet: any) => {
                  return {
                    label: _dataSet.title,
                    value: _dataSet.id,
                  };
                });
                if (_currentTopicId === 3) {
                  this.setState({
                    listDataSets: _dataOptions,
                    selectedDataSet: null,
                  });
                }
                if (_currentTopicId === 8) {
                  this.setState({
                    listExpense: _dataOptions,
                    selectedExpense: null,
                  });
                }
                this.setState({
                  // listMembers: _members,
                  // selectedMembers: _members,
                  selectedSubTask: _e,
                });

                resolve(_dataOptions);
              }
            );
          } else {
            this.setState({
              // listMembers: _members,
              // selectedMembers: _members,
              selectedSubTask: _e,
              sectionId: _e,
            });
          }

          // 2: Task, 3: DataSet : get members base on subtask

          if (_currentTopicId !== 8) {
            this.handleGetSectionUsers(2, _e.value).then((res: any) => {
              const _members = res?.members?.map((_mem: any) => {
                const _userTitle = getUserTitle(_mem);
                return {
                  label: _userTitle,
                  value: _mem.id,
                  secondary: _mem.invitationStatus === 1 ? 'Pending' : null,
                };
              });
              this.setState({
                listMembers: _members,
                selectedMembers: _members,
              });
              if (_currentTopicId !== 3) {
                resolve(true);
              }
            });
          }
        }
      } catch (e) {
        reject(e);
      }
    });

    // if mode on DataSet:
    // if (this.state.topic.value === 3 || this.state.topic.value === 8) {
    //   this.handleGetDataBaseOnTaskIdAndTopicId(
    //     _e.value,
    //     this.state.topic.value
    //   ).then((_dataSetsForSubtasks: any) => {
    //     if (_dataSetsForSubtasks) {
    //       if (this.state.topic.value === 3) {
    //         this.setState({
    //           listDataSets: _dataSetsForSubtasks?.discussionOn?.map(
    //             (_dataSet: any) => {
    //               return {
    //                 label: _dataSet.title,
    //                 value: _dataSet.id,
    //               };
    //             }
    //           ),
    //           selectedSubTask: _e,
    //         });
    //       }
    //       if (this.state.topic.value === 8) {
    //         this.setState({
    //           listExpense: _dataSetsForSubtasks?.discussionOn?.map(
    //             (_dataSet: any) => {
    //               return {
    //                 label: _dataSet.title,
    //                 value: _dataSet.id,
    //               };
    //             }
    //           ),
    //           selectedSubTask: _e,
    //         });
    //       }
    //     }
    //   });
    // } else {
    //   this.handleGetSectionUsers(2, _e.value).then((res: any) => {
    //     const _members = res?.members?.map((_mem: any) => {
    //       const _userTitle = getUserTitle(_mem);
    //       return {
    //         label: _userTitle,
    //         value: _mem.id,
    //         secondary: _mem.invitationStatus === 1 ? "Pending" : null,
    //       };
    //     });
    //     this.setState({
    //       listMembers: _members,
    //       selectedMembers: _members,
    //       selectedSubTask: _e,
    //       sectionId: _e,
    //     });
    //   });
    // }
  }
  onChangeIsDiscusstionForSubTask(_value: number) {
    // e.value [0 No, 1 Yes] just L2 use this method
    // No:  do nothing
    // Yes: all subtasks that created by me under the selected task (parent)
    const _currentTopicId = this.state.topic.value;
    const _currentTaskId = this.state.selectedTask?.value || 0;
    // const _currentSubTaskId = this.state.selectedSubTask?.value || 0;

    this.validator.purgeFields();

    if (_value === 1) {
      this.setState({
        listMembers: [],
        selectedMembers: [],
        selectedSubTask: null,
        listDataSets: [],
        selectedDataSet: null,
        selectedExpense: null,
        listExpense: [],
        sectionId: null,
      });
    } else {
      // if task is already selected and selected topic is task | dataset : get members base on taskId
      if (
        _currentTaskId &&
        (_currentTopicId === 2 || _currentTopicId === 3 || _currentTopicId === 8)
      ) {
        if (_currentTopicId !== 8) {
          this.handleGetSectionUsers(2, _currentTaskId).then((res: any) => {
            const _members = res?.members?.map((_mem: any) => {
              const _userTitle = getUserTitle(_mem);
              return {
                label: _userTitle,
                value: _mem.id,
                secondary: _mem.invitationStatus === 1 ? 'Pending' : null,
              };
            });
            this.setState({
              listMembers: _members,
              selectedMembers: _members,
            });
          });
        }

        if (_currentTopicId === 2) {
          // is topic task : set sectionId
          this.setState({
            sectionId: this.state.selectedTask,
          });
        }
        // @ts-ignore
        if (_currentTopicId === 3 || _currentTopicId === 8) {
          // if is topic dataset : get dataset base on taskId
          this.handleGetDataBaseOnTaskIdAndTopicId(_currentTaskId, _currentTopicId).then(
            (_data: any) => {
              const _dataOption = _data?.discussionOn?.map((_dataSet: any) => {
                return {
                  label: _dataSet.title,
                  value: _dataSet.id,
                };
              });
              if (_currentTopicId === 3) {
                this.setState({
                  selectedDataSet: null,
                  sectionId: null,
                  listDataSets: _dataOption,
                });
              }
              // @ts-ignore
              if (_currentTopicId === 8) {
                this.setState({
                  selectedExpense: null,
                  sectionId: null,
                  listExpense: _dataOption,
                });
              }
            }
          );
        }
      }

      // if (this.state.selectedTask?.value && this.state.topic.value === 2) {
      //   this.handleGetSectionUsers(2, this.state.selectedTask?.value).then(
      //     (res: any) => {
      //       const _members = res?.members?.map((_mem: any) => {
      //         const _userTitle = getUserTitle(_mem);
      //         return {
      //           label: _userTitle,
      //           value: _mem.id,
      //           secondary: _mem.invitationStatus === 1 ? "Pending" : null,
      //         };
      //       });
      //       this.setState({
      //         listMembers: _members,
      //         selectedMembers: _members,
      //         // selectedTask: e,
      //         // sectionId: e,
      //       });
      //     }
      //   );
      // }
      // if (this.state.selectedSubTask?.value) {
      //   this.setState({
      //     selectedSubTask: null,
      //   });
      // }
      // if (this.state.selectedDataSet?.value) {
      //   this.setState({
      //     selectedDataSet: null,
      //     listDataSets: [],
      //   });
      // }

      // if (
      //   this.state.selectedTask?.value &&
      //   (this.state.topic.value === 3 || this.state.topic.value === 8)
      // ) {
      //   this.handleGetDataBaseOnTaskIdAndTopicId(
      //     this.state.selectedTask?.value,
      //     this.state.topic.value
      //   ).then((_dataSets: any) => {
      //     if (this.state.selectedSubTask?.value) {
      //       this.setState({
      //         selectedSubTask: { label: "Please Select..", value: -1 },
      //       });
      //     }
      //     if (this.state.selectedDataSet?.value) {
      //       this.setState({
      //         selectedDataSet: { label: "Please Select..", value: -1 },
      //       });
      //     }
      //     this.setState({
      //       listDataSets: _dataSets?.discussionOn?.map((_dataSet: any) => {
      //         return {
      //           label: _dataSet.title,
      //           value: _dataSet.id,
      //         };
      //       }),
      //     });
      //   });
      // }
    }

    this.setState({
      isDiscussionForSubTask: _value,
    });

    // check if any task selected run this method => handleGetSubTaskBaseOnTaskId
  }
  render() {
    const files = this.state.files.map((file: any) => (
      <li
        key={file.name}
        className='d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team'
        style={{ marginBottom: '3px' }}
      >
        <span
          className='text-truncate col-11 mx-2'
          style={{ fontFamily: 'Sanfransisco', textAlign: 'start' }}
        >
          {file.name} - {this.formatBytes(file.size)}
        </span>
        <CircleIcon
          type={ThemeCircleIcon.dark}
          width='22px'
          padding='0'
          height='22px'
          onClick={() => {
            this.handelDeleteFile(file);
          }}
          style={{ cursor: 'pointer', display: 'contents' }}
        >
          <img src='/images/icons/cross-1.svg' alt='radvix' width={15} height={15} />
        </CircleIcon>
      </li>
    ));

    const FormBaseOnDiscussionType = forms[this.state.topic.value];

    return (
      <div className='card'>
        <div
          className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
      border-gray-400'
        >
          <div className='d-flex align-items-center'>
            {/* <button className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none"> Back</button> */}
            <BaseButton
              className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
              style={{ fontWeight: '400', height: '19px' }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>New Communications</h2>
          </div>
        </div>

        <div className='p-3 overflow-auto' style={{ marginBottom: '-5px' }}>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='row'>
                <div className='col-lg-12 mb-3'>
                  <div className='item'>
                    <SelectComponent
                      items={this.state.listDiscusstionOn}
                      TextItem='name'
                      disabled={this.state.searchLoading}
                      ValueItem='id'
                      className='mt-1'
                      placeholder='Please select…'
                      label='I would like to discuss on*'
                      labelIcon
                      popQuestion='Please select the category of the item you would like to start a discussion on.'
                      defaultValue={this.state.topic}
                      onChange={(e) => {
                        this.handleChangeDiscusstion(
                          e,
                          null,
                          this.state.selectedTaskId,
                          null,
                          this.state.selectedProject?.value
                        );
                        this.setState({ selectedOption: e.value });
                      }}
                      isMulti={false}
                    ></SelectComponent>
                  </div>
                  {this.state.topic.value !== -1 && (
                    <>
                      {(this.state.topic.label === 'Task' ||
                        this.state.topic.label === 'Data Set') &&
                      (this.RoleUser === UserRoles.L2User || this.RoleUser === UserRoles.L3User) ? (
                        <>
                          <div className='item'>
                            <ButtonGroup
                              disabled={this.state.searchLoading}
                              name='AttachTo'
                              items={[
                                { name: 'Task', value: 2 },
                                { name: 'Sub-Task', value: 3 },
                              ]}
                              inValid={this.validator.message(
                                'Access Level',
                                this.state.typeId,
                                'min:1,num'
                              )}
                              TextItem='name'
                              ValueItem='value'
                              selected={this.state.typeId}
                              onChange={(e) => {
                                this.setState({
                                  typeId: e.target.value,
                                });
                                this.handleChange('typeId', parseInt(e.target.value));
                              }}
                            />
                          </div>
                        </>
                      ) : null}

                      <div style={{ paddingTop: '15px' }}>
                        <FormBaseOnDiscussionType
                          myRole={store.getState().userRole}
                          listResearch={this.state.researhList}
                          selectedProject={this.state.selectedProject}
                          listTasks={this.state.listTasks}
                          selectedTask={this.state.selectedTask}
                          listExpense={this.state.listExpense}
                          selectedExpense={this.state.selectedExpense}
                          listPublication={this.state.listPublication}
                          selectedPublication={this.state.selectedPublication}
                          listSubTasks={this.state.listSubTasks}
                          selectedSubTask={this.state.selectedSubTask}
                          listDataSets={this.state.listDataSets}
                          selectedDataSet={this.state.selectedDataSet}
                          currentResearch={
                            this.state.selectedOption == 0
                              ? store.getState().ResearchId.label
                              : this.state.currentResearch
                          }
                          listEquipment={this.state.listEquipment}
                          selectedEquipment={this.state.selectedEquipment}
                          listLaboratory={this.state.listLaboratory}
                          selectedLaboratory={this.state.selectedLaboratory}
                          listTeam={this.state.listTeam}
                          selectedTeam={this.state.selectedTeam}
                          // events
                          onResearchChange={this.handleChangeProject.bind(this)}
                          onSelectTask={this.onSelectTask.bind(this)}
                          isDiscussionForSubTask={this.state.isDiscussionForSubTask}
                          onChangeIsDiscusstionForSubTask={this.onChangeIsDiscusstionForSubTask.bind(
                            this
                          )}
                          onSelectSubTask={this.onSelectSubTask.bind(this)}
                          onChangeExpenseMode={(_e: any) => {
                            // e.value [0 task, 1 subtask] just L2 use this method
                            // task: update expense list: all expenses that created by me to any tasks that assigned to me
                            // subtask: update expense list: all expenses that created by me
                            // || any expenses created by L3 user in my subteam to any subtask that created by me
                          }}
                          onSelectExpense={this.onSelectExpense.bind(this)}
                          // L1: automatically select(members): the creator of expense and mySelf, can't remove
                          // L2: default on task: automatically select(members): the creator of expense and L1 user who assigned the task, can't remove
                          //  if subtask selected: automatically select(members): the creator of subtask and L3 users and mySelf, can't remove
                          // L3: automatically select(members): the creator of subtask and mySelf, can't remove

                          onSelectPublication={this.onSelectPublication.bind(this)}
                          onSelectEquipment={this.onSelectEquipment.bind(this)}
                          onSelectLaboratory={this.onSelectLaboratory.bind(this)}
                          onSelectTeam={this.onSelectTeam.bind(this)}
                          onSelectDataSet={this.onSelectDataSet.bind(this)}
                          validator={this.validator}
                          disabledAll={this.state.searchLoading}
                          typeId={this.state.typeId}
                        />
                      </div>
                    </>
                  )}
                  <div className='item' style={{ paddingTop: '15px' }}>
                    <InputComponent
                      disabled={this.state.searchLoading}
                      id='New_Discuss_Subject_Input'
                      type={InputType.text}
                      label='Subject*'
                      labelIcon
                      popQuestion='This will be the main subject for this discussion panel. This is similar to subject of an email string.'
                      onChange={(e) => {
                        this.handleChange('subject', e.target.value);
                      }}
                      inValid={this.validator.message('Subject', this.state.subject, 'required')}
                    ></InputComponent>
                  </div>
                  <div className='item'>
                    <InputComponent
                      type={InputType.textarea}
                      label='Opening message'
                      labelIcon
                      disabled={this.state.searchLoading}
                      popQuestion='Please enter the opening message for this discussion panel. This message will appear in the beginning of this discussion flow.'
                      className='mt-1'
                      onChange={(e) => {
                        this.handleChange('message', e.target.value);
                      }}
                      inValid={this.validator.message('message', this.state.message, 'required')}
                    ></InputComponent>
                  </div>
                  <div className='col-lg-12 mb-3'>
                    <div className='d-flex align-items-center mb-1'>
                      <label className='color-gray-600 fs-15 me-2'>
                        Attach files or links{' '}
                        <img src='/images/icons/label-icon.svg' width={15} height={15} alt='icon' />
                      </label>
                      {/* <img
                        className="text-primary cursor-pointer"
                        src="/Images/icons/info-circled.svg"
                        alt="info"
                        title="Research attachments are generic documents that you would like anyone who has access to this research project read and have access to them. You can either upload them or Insert the link."
                      /> */}
                    </div>
                    <div className='p-px-12 bg-gray-200 border-gray-400 border rounded-px-4 gap-2 attachment-paddingleft'>
                      <div className='justify-content-sm-between justify-content-center align-items-center flex-wrap row-gap-px-8'>
                        <Dropzone onDrop={this.onDrop}>
                          {({ getRootProps, getInputProps }) => (
                            // <section
                            //   className="container fileUploadBox"
                            //   style={{ marginTop: "0.3em" }}
                            // >
                            <div {...getRootProps({ className: '' })}>
                              <input {...getInputProps()} />

                              <div className='d-flex justify-content-between align-items-center flex-wrap row-gap-px-8 responsive-justify-content'>
                                <button className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'>
                                  <div className='d-flex align-items-center'>
                                    <div className='me-1 mb-1'>
                                      <svg
                                        width='15'
                                        height='15'
                                        viewBox='0 0 15 15'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <rect
                                          width='15'
                                          height='15'
                                          fill='white'
                                          fill-opacity='0.01'
                                        />
                                        <path
                                          fill-rule='evenodd'
                                          clip-rule='evenodd'
                                          d='M7.81825 1.18197C7.64251 1.00624 7.35759 1.00624 7.18185 1.18197L4.18185 4.18197C4.00611 4.35771 4.00611 4.64263
              4.18185 4.81837C4.35759 4.9941 4.64251 4.9941 4.81825 4.81837L7.05005 2.58657V9.50005C7.05005 9.74858 7.25152 9.95005
              7.50005 9.95005C7.74858 9.95005 7.95005 9.74858 7.95005 9.50005V2.58657L10.1819 4.81837C10.3576 4.9941 10.6425 4.9941
              10.8182 4.81837C10.994 4.64263 10.994 4.35771 10.8182 4.18197L7.81825 1.18197ZM2.5 10.0001C2.77614 10.0001 3 10.2239 3
              10.5001V12.0001C3 12.5539 3.44565 13.0001 3.99635 13.0001H11.0012C11.5529 13.0001 12 12.5529 12 12.0001V10.5001C12
              10.2239 12.2239 10.0001 12.5 10.0001C12.7761 10.0001 13 10.2239 13 10.5001V12.0001C13 13.1041 12.1062 14.0001 11.0012
              14.0001H3.99635C2.89019 14.0001 2 13.1031 2 12.0001V10.5001C2 10.2239 2.22386 10.0001 2.5 10.0001Z'
                                          fill='currentColor'
                                        />
                                      </svg>
                                    </div>
                                    Browse local file
                                  </div>
                                </button>
                                <h3 className='fw-normal color-gray-700 fs-13 mb-0 flex-grow-1 text-center'>
                                  {' '}
                                  Or drag and drop files here - Max 128MB{' '}
                                </h3>
                              </div>
                              {files.length > 0 && (
                                <div style={{ paddingTop: '10px' }}>
                                  <ul className='list-unstyled fw-normal'>{files}</ul>
                                </div>
                              )}
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    </div>

                    <ul
                      className='file-list mt-1'
                      style={{
                        marginBottom: this.state.ExternalUrl.length > 0 ? '0px' : '0px',
                      }}
                    >
                      {this.state.ExternalUrl.map((item) => (
                        <li className='d-flex align-items-center w-100'>
                          <InputComponent
                            type={InputType.text}
                            value={item}
                            className='mx-2'
                            counter={false}
                            disabled={true}
                            readOnly={true}
                            rowWidth='98%'
                            isExternalURL={true}
                          ></InputComponent>
                          <div
                            style={{
                              paddingBottom: '0px',
                              paddingRight: '0px',
                              paddingTop: '0px',
                              paddingLeft: '5px',
                            }}
                          >
                            <button
                              className='btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0'
                              disabled={this.state.searchLoading}
                              style={{ background: '#F3AEAF' }}
                              onClick={() => {
                                this.handelDeleteExternalLink(item);
                              }}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='15'
                                height='15'
                                viewBox='0 0 15 15'
                                fill='none'
                              >
                                <rect width='15' height='15' fill='white' fill-opacity='0.01' />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M5.5 1.00009C5.22386 1.00009 5 1.22395 5 1.50009C5 1.77623 5.22386 2.00009 5.5 2.00009H9.5C9.77614 2.00009 10 1.77623 10 1.50009C10 1.22395 9.77614 1.00009 9.5 1.00009H5.5ZM3 3.50009C3 3.22395 3.22386 3.00009 3.5 3.00009H5H10H11.5C11.7761 3.00009 12 3.22395 12 3.50009C12 3.77623 11.7761 4.00009 11.5 4.00009H11V12.0001C11 12.5524 10.5523 13.0001 10 13.0001H5C4.44772 13.0001 4 12.5524 4 12.0001V4.00009H3.5C3.22386 4.00009 3 3.77623 3 3.50009ZM5 4.00009H10V12.0001H5V4.00009Z'
                                  fill='#D93D42'
                                />
                              </svg>
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div className='d-flex'>
                      <InputComponent
                        type={InputType.text}
                        placeholder='https://'
                        disabled={this.state.searchLoading}
                        className='control-border-color'
                        value={this.state.External}
                        counter={false}
                        onChange={(e) => {
                          this.handleChange('External', e.target.value);
                        }}
                        rowWidth='98%'
                        onEnter={(e) => {
                          this.addExternalUrl();
                        }}
                      ></InputComponent>
                      <div style={{ padding: '5px', paddingRight: '0px' }}>
                        <button
                          className='btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0'
                          disabled={this.state.searchLoading}
                          onClick={() => {
                            this.addExternalUrl();
                          }}
                        >
                          <i className='fa fa-plus'></i>
                        </button>
                      </div>
                    </div>
                    <p className='text-error mb-0'>
                      {this.validator.message('input', this.state.External, 'url')}
                    </p>
                  </div>

                  <div className='item' style={{ marginTop: '-10px' }}>
                    <SelectComponent
                      items={this.state.listMembers}
                      label='Users in this discussion panel'
                      labelIcon
                      // popQuestion="Add Members:"
                      TextItem='name'
                      ValueItem='id'
                      disabled={this.state.searchLoading}
                      className='mt-1'
                      placeholder='Click to see the list…'
                      menuPlacementTop={true}
                      onChange={(_e) => {
                        if (this.state.topic.value !== 0) {
                          const _cantFindYourSelf = !_e.find(
                            (item: { label: string; value: string }) =>
                              item.value === this.local.getUserId()
                          );

                          if (_cantFindYourSelf) {
                            return toast.warning("You can't remove yourself from users");
                          }
                        }

                        this.setState({
                          selectedMembers: _e,
                        });
                      }}
                      isMulti
                      // inValid={this.validator.message(
                      //   "message",
                      //   this.state.message,
                      //   "required"
                      // )}
                      defaultValue={this.state.selectedMembers}
                    ></SelectComponent>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='row mt-1'>
                <div className='col-lg-12 mb-3'>
                  <label className='color-gray-600 fs-15 me-2 mb-1'>
                    Discussion Priority{' '}
                    <img src='/images/icons/label-icon.svg' width={15} height={15} alt='icon' />
                  </label>
                  {/* <img
                        className="text-primary cursor-pointer"
                        src="/Images/icons/info-circled.svg"
                        alt="info"
                        title="Priority will color tag your research projects and will help you identify or sort them easier based on their priority level."
                      /> */}
                  <div className='d-flex border-gray-400 border soft-radios p-1 rounded-px-4 w-100'>
                    <div className='w-33_3'>
                      <input
                        type='radio'
                        className='btn-check'
                        name='options'
                        id='low'
                        autoComplete='off'
                        checked={this.state.priority === 0}
                        onClick={() => this.handleChange('priority', 0)}
                      />
                      <label
                        className='btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center'
                        htmlFor='low'
                      >
                        Low
                      </label>
                    </div>
                    <div className='w-33_3'>
                      <input
                        type='radio'
                        className='btn-check'
                        name='options'
                        id='medium'
                        autoComplete='off'
                        checked={this.state.priority === 1}
                        onClick={() => this.handleChange('priority', 1)}
                      />
                      <label
                        className='btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center'
                        htmlFor='medium'
                      >
                        Medium
                      </label>
                    </div>
                    <div className='w-33_3'>
                      <input
                        type='radio'
                        className='btn-check'
                        name='options'
                        id='high'
                        autoComplete='off'
                        checked={this.state.priority === 2}
                        onClick={() => this.handleChange('priority', 2)}
                      />
                      <label
                        className='btn text-primary py-0 h-px-29 fs-13 d-flex align-items-center justify-content-center'
                        htmlFor='high'
                      >
                        High
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='item'>
              <MainButton
                className='btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2 responsive-Button-width'
                svg={
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='15'
                    height='15'
                    viewBox='0 0 15 15'
                    fill='none'
                  >
                    <rect width='15' height='15' fill='white' fill-opacity='0.01' />
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M3.5 2.00006C3.22386 2.00006 3 2.22392 3 2.50006V12.5001C3 12.7762 3.22386 13.0001 3.5 13.0001H11.5C11.7761 13.0001 12 12.7762 12 12.5001V4.70717L9.29289 2.00006H3.5ZM2 2.50006C2 1.67163 2.67157 1.00006 3.5 1.00006H9.5C9.63261 1.00006 9.75979 1.05274 9.85355 1.14651L12.7803 4.07328C12.921 4.21394 13 4.4047 13 4.60361V12.5001C13 13.3285 12.3284 14.0001 11.5 14.0001H3.5C2.67157 14.0001 2 13.3285 2 12.5001V2.50006ZM4.75 7.50006C4.75 7.22392 4.97386 7.00006 5.25 7.00006H7V5.25006C7 4.97392 7.22386 4.75006 7.5 4.75006C7.77614 4.75006 8 4.97392 8 5.25006V7.00006H9.75C10.0261 7.00006 10.25 7.22392 10.25 7.50006C10.25 7.7762 10.0261 8.00006 9.75 8.00006H8V9.75006C8 10.0262 7.77614 10.2501 7.5 10.2501C7.22386 10.2501 7 10.0262 7 9.75006V8.00006H5.25C4.97386 8.00006 4.75 7.7762 4.75 7.50006Z'
                      fill='white'
                    />
                  </svg>
                }
                onClick={() => {
                  this.CreateDiscusstion();
                }}
                loading={this.state.loading || this.state.searchLoading}
                disabled={this.state.loading || this.state.searchLoading}
              >
                Start chat
              </MainButton>
            </div>
          </div>
        </div>
      </div>
      // <div className="container-fluid research new-research">
      //   <div className="row"></div>
      //   <div className="col-12 box-content p-3">
      //     <h5 className="b-title d-flex align-items-center">
      //       <IoIosArrowDropleftCircle
      //         className="me-3 textSecondary2 cursorPointer"
      //         size="1.5em"
      //         onClick={() => {
      //           window.history.back();
      //         }}
      //       />
      //       New smart discussion panel
      //     </h5>
      //     <div className="form row">
      //       <div className="col-md-6 left beforeNoneMd">
      //         <div className="item">
      //           <SelectComponent
      //             items={this.state.listDiscusstionOn}
      //             TextItem="name"
      //             disabled={this.state.searchLoading}
      //             ValueItem="id"
      //             className="mt-1"
      //             placeholder="Please select…"
      //             label="I would like to discuss on"
      //             popQuestion="Please select the category of the item you would like to start a discussion on."
      //             defaultValue={this.state.topic}
      //             onChange={(e) => {
      //               this.handleChangeDiscusstion(e);
      //             }}
      //             isMulti={false}
      //           ></SelectComponent>
      //         </div>

      //         {this.state.topic.value !== -1 && (
      //           <>{((this.state.topic.label === "Task" || this.state.topic.label === "Data Set") && (this.RoleUser === UserRoles.L2User || this.RoleUser === UserRoles.L3User)) ? (
      //             <div className="item">
      //               <ButtonGroup
      //                 disabled={this.state.searchLoading}
      //                 name="AttachTo"
      //                 items={[
      //                   { name: "Task", value: 2 },
      //                   { name: "Sub-Task", value: 3 },
      //                 ]}
      //                 inValid={this.validator.message(
      //                   "Access Level",
      //                   this.state.typeId,
      //                   "min:1,num"
      //                 )}
      //                 TextItem="name"
      //                 ValueItem="value"
      //                 selected={this.state.typeId}
      //                 onChange={(e) => {

      //                   this.setState({
      //                     typeId: e.target.value,
      //                   });
      //                   this.handleChange("typeId", parseInt(e.target.value));
      //                 }}
      //               />

      //             </div>) : null}

      //             <FormBaseOnDiscussionType
      //               myRole={store.getState().userRole}
      //               listTasks={this.state.listTasks}
      //               selectedTask={this.state.selectedTask}
      //               listExpense={this.state.listExpense}
      //               selectedExpense={this.state.selectedExpense}
      //               listPublication={this.state.listPublication}
      //               selectedPublication={this.state.selectedPublication}
      //               listSubTasks={this.state.listSubTasks}
      //               selectedSubTask={this.state.selectedSubTask}
      //               listDataSets={this.state.listDataSets}
      //               selectedDataSet={this.state.selectedDataSet}
      //               currentResearch={this.state.currentResearch}
      //               listEquipment={this.state.listEquipment}
      //               selectedEquipment={this.state.selectedEquipment}
      //               listLaboratory={this.state.listLaboratory}
      //               selectedLaboratory={this.state.selectedLaboratory}
      //               listTeam={this.state.listTeam}
      //               selectedTeam={this.state.selectedTeam}
      //               // events
      //               onSelectTask={this.onSelectTask.bind(this)}
      //               isDiscussionForSubTask={this.state.isDiscussionForSubTask}
      //               onChangeIsDiscusstionForSubTask={this.onChangeIsDiscusstionForSubTask.bind(
      //                 this
      //               )}
      //               onSelectSubTask={this.onSelectSubTask.bind(this)}
      //               onChangeExpenseMode={(_e: any) => {
      //                 // e.value [0 task, 1 subtask] just L2 use this method
      //                 // task: update expense list: all expenses that created by me to any tasks that assigned to me
      //                 // subtask: update expense list: all expenses that created by me
      //                 // || any expenses created by L3 user in my subteam to any subtask that created by me
      //               }}
      //               onSelectExpense={this.onSelectExpense.bind(this)}
      //               // L1: automatically select(members): the creator of expense and mySelf, can't remove
      //               // L2: default on task: automatically select(members): the creator of expense and L1 user who assigned the task, can't remove
      //               //  if subtask selected: automatically select(members): the creator of subtask and L3 users and mySelf, can't remove
      //               // L3: automatically select(members): the creator of subtask and mySelf, can't remove

      //               onSelectPublication={this.onSelectPublication.bind(this)}
      //               onSelectEquipment={this.onSelectEquipment.bind(this)}
      //               onSelectLaboratory={this.onSelectLaboratory.bind(this)}
      //               onSelectTeam={this.onSelectTeam.bind(this)}
      //               onSelectDataSet={this.onSelectDataSet.bind(this)}
      //               validator={this.validator}
      //               disabledAll={this.state.searchLoading}
      //               typeId={this.state.typeId}
      //             /></>

      //         )}
      //         <div className="item">
      //           <InputComponent
      //             disabled={this.state.searchLoading}
      //             id="New_Discuss_Subject_Input"
      //             type={InputType.text}
      //             label="Discussion panel subject"
      //             popQuestion="This will be the main subject for this discussion panel. This is similar to subject of an email string."
      //             onChange={(e) => {
      //               this.handleChange("subject", e.target.value);
      //             }}
      //             inValid={this.validator.message(
      //               "Subject",
      //               this.state.subject,
      //               "required"
      //             )}
      //           ></InputComponent>
      //         </div>
      //         <div className="item">
      //           <InputComponent
      //             type={InputType.textarea}
      //             label="Opening message"
      //             disabled={this.state.searchLoading}
      //             popQuestion="Please enter the opening message for this discussion panel. This message will appear in the beginning of this discussion flow."
      //             className="mt-1"
      //             onChange={(e) => {
      //               this.handleChange("message", e.target.value);
      //             }}
      //             inValid={this.validator.message(
      //               "message",
      //               this.state.message,
      //               "required"
      //             )}
      //           ></InputComponent>
      //         </div>

      //         <div className="item">
      //           <span className="label d-flex align-items-center">
      //             Attach files or links to your message
      //             <div
      //               // type={MainButtonType.light}
      //               // children={optional}
      //               // borderRadius="50px"
      //               // fontSize="15px"
      //               className="s-chip filled mx-2"
      //             >optional</div>
      //             {/* <CircleIcon
      //               width="20px"
      //               height="20px"
      //               padding="0"
      //               type={ThemeCircleIcon.dark}
      //               backgroundColor="transparent"
      //               border="1px solid #D5D5D5"
      //               fontSize="10px"
      //               color="#D5D5D5"
      //             >
      //               <i className="fas fa-question"></i>
      //             </CircleIcon> */}
      //           </span>
      //           <Dropzone onDrop={this.onDrop}>
      //             {({ getRootProps, getInputProps }) => (
      //               <section className="container fileUploadBox" style={{ marginTop: '0.3em' }}>
      //                 <div {...getRootProps({ className: "dropzone" })}>
      //                   <input {...getInputProps()} />
      //                   <MainButton
      //                     type={MainButtonType.light}
      //                     minHeight="30px"
      //                     disabled={this.state.searchLoading}
      //                     minWidth="179px"
      //                     fontSize="14px"
      //                     borderRadius="50px"
      //                     backgroundColor="#fff"
      //                     border="1px solid #707070"
      //                     color="#707070"
      //                     className="mt-4"
      //                     children={
      //                       <div className="d-flex justify-content-between align-items-center">
      //                         <img
      //                           src="/Images/icons/cloud_computing.svg"
      //                           alt="sssss"
      //                           height="20"
      //                         />{" "}
      //                         <span className="flex-fill">
      //                           Browse Local Files
      //                         </span>
      //                       </div>
      //                     }
      //                   ></MainButton>
      //                   <p>Or drag and drop files here</p>
      //                   <p className="text-center" style={{ marginTop: -20 }}>
      //                     Max 128MB
      //                   </p>
      //                 </div>
      //                 <aside>
      //                   <ul>{files}</ul>
      //                 </aside>
      //               </section>
      //             )}
      //           </Dropzone>
      //         </div>
      //         <div className="item d-flex justify-content-between align-items-center">
      //           <InputComponent
      //             disabled={this.state.searchLoading}
      //             type={InputType.text}
      //             placeholder="https://"
      //             className="mx-2"
      //             counter={false}
      //             onChange={(e) => {
      //               this.handleChange("External", e.target.value);
      //             }}
      //             onEnter={(e) => {
      //               this.addExternalUrl();
      //             }}
      //             value={this.state.External}
      //           ></InputComponent>
      //           <CircleIcon
      //             width="36px"
      //             height="36px"
      //             padding="0"
      //             type={ThemeCircleIcon.dark}
      //             backgroundColor="#9D9D9D"
      //             fontSize="18px"
      //             disabled={this.state.searchLoading}
      //             color="#ffffff"
      //             className="px-3"
      //             onClick={() => {
      //               this.addExternalUrl();
      //             }}
      //           >
      //             <i className="fas fa-plus"></i>
      //           </CircleIcon>
      //         </div>
      //         <p className="text-error mb-0">
      //           {this.validator.message("input", this.state.External, "url")}
      //         </p>
      //         <ul className="file-list mt-1">
      //           {this.state.ExternalUrl.map((item) => (
      //             <li className="my-1 d-flex align-items-center max1LineText">
      //               <a title="Go to the link" className="max1LineText s-chip outlined fs-200 blueColor" href={item} target="_blank" rel="noopener noreferrer">
      //                 {item}
      //               </a>
      //               <CircleIcon
      //                 type={ThemeCircleIcon.dark}
      //                 width="22px"
      //                 disabled={this.state.searchLoading}
      //                 padding="0"
      //                 height="22px"
      //                 className="ms-2 pointer"
      //                 onClick={() => this.handelDeleteExternalLink(item)}
      //               >
      //                 <img
      //                   src="/images/icons/garbage_can.svg"
      //                   alt="radvix"
      //                   width={15}
      //                   height={15}
      //                 />
      //               </CircleIcon>
      //             </li>
      //           ))}
      //         </ul>
      //       </div>
      //       <div className="col-md-6 right">
      //         {this.state.topic.value === 8 ? (
      //           ""
      //         ) : (
      //           <div className="item">
      //             <SelectComponent
      //               items={this.state.listMembers}
      //               label="Users in this discussion panel"
      //               // popQuestion="Add Members:"
      //               TextItem="name"
      //               ValueItem="id"
      //               disabled={this.state.searchLoading}
      //               className="mt-1"
      //               placeholder="Click to see the list…"
      //               onChange={(_e) => {
      //                 if (this.state.topic.value !== 0) {

      //                   const _cantFindYourSelf = !_e.find(
      //                     (item: { label: string; value: string }) =>
      //                       item.value === this.local.getUserId()
      //                   );

      //                   if (_cantFindYourSelf) {
      //                     return toast.warning(
      //                       "You can't remove yourself from users"
      //                     );
      //                   }
      //                 }

      //                 this.setState({
      //                   selectedMembers: _e,
      //                 });
      //               }}
      //               isMulti
      //               // inValid={this.validator.message(
      //               //   "message",
      //               //   this.state.message,
      //               //   "required"
      //               // )}
      //               defaultValue={this.state.selectedMembers}
      //             ></SelectComponent>
      //           </div>
      //         )}

      //         {/* <SelectTeamsAndMembers
      //           listTeams={this.state.listTeams}
      //           listMembers={this.state.listMembers}
      //           selectedMembers={this.state.selectedMembers}
      //           selectedTeams={this.state.selectedTeams}
      //           onChangeTeams={(_e) => {
      //             // from team multi select

      //             this.setState({
      //               selectedTeams: _e,
      //             });
      //           }}
      //           onChangeMembers={(_e) => {
      //             // from member multi select
      //             this.setState({
      //               selectedMembers: _e,
      //             });
      //           }}
      //         /> */}

      //         {/* <div className="item">
      //           <SelectComponent
      //             items={this.state.listMembers}
      //             label="Add Members To Discussion:"
      //             popQuestion="Add Members To Discussion:"
      //             TextItem="name"
      //             ValueItem="id"
      //             className="my-2"
      //             placeholder="Click to see the list…"
      //             onChange={(e) => {
      //               this.handelSelectMembers(e);
      //             }}
      //             isMulti
      //             inValid={this.validator.message(
      //               "message",
      //               this.state.message,
      //               "required"
      //             )}
      //             defaultValue={this.state.selectedMembers}
      //           ></SelectComponent>
      //         </div> */}
      //         <div className="item">
      //           <ButtonGroup
      //             disabled={this.state.searchLoading}
      //             label="Discussion priority"
      //             popQuestion="Select a priority for this discussion to help you sort them in the future."
      //             name="Priority"
      //             items={this.state.listPriority}
      //             TextItem="name"
      //             ValueItem="id"
      //             selected={this.state.priority}
      //             onChange={(e) => {
      //               this.handleChange("priority", parseInt(e.target.value));
      //             }}
      //           ></ButtonGroup>
      //         </div>
      //       </div>
      //       <div className="col-12 d-flex flex-column justify-content-center align-items-center my-4">
      //         {/* <MainButton
      //           type={MainButtonType.light}
      //           children={"Start Over"}
      //           borderRadius="50px"
      //           fontSize="18px"
      //           className="mx-2"
      //           minHeight="43px"
      //           minWidth="136px"
      //         ></MainButton> */}
      //         <MainButton
      //           type={MainButtonType.dark}
      //           children={"Start discussion"}
      //           borderRadius="50px"
      //           fontSize="18px"
      //           className="mx-2 px-3"
      //           minHeight="43px"
      //           minWidth="136px"
      //           onClick={() => {
      //             this.CreateDiscusstion();
      //           }}
      //           loading={this.state.loading || this.state.searchLoading}
      //           disabled={this.state.loading || this.state.searchLoading}
      //         ></MainButton>
      //         <span className="mt-2 textSecondary">
      //           {this.state.loadingMode}
      //         </span>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}
export default withRouter(NewDiscusstion);
