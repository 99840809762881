import React from "react";
import { store } from "../../../data/storeMain";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import { InputComponent, InputType } from "../../components/inputs";
import "react-datepicker/dist/react-datepicker.css";
import { MainButton, MainButtonType } from "../../components/button";
import Dropzone from "react-dropzone";
import { IconTextRow } from "../../components/icon_text_horizontal";
import {
  // $truncate,
  getUserTitle,
  isTokenInHttpAuth,
  Theme,
  uploadAllFiles,
  uploadAllLinks,
} from "../../../core/utils";
import { BoxListScroll } from "../../components/box_list_scroll";
import { DiscusstionController } from "../../../controllers/discussion/discusstion_controller";
import { LocalDataSources } from "../../../data/local_datasources";
import { UploadController } from "../../../controllers/upload_media/upload_media";
import SimpleReactValidator from "simple-react-validator";
import { GetDiscusstionPanelResResult } from "../../../data/models/responses/discussion/get_discusstion_panel_res";
import { RouteComponentProps, withRouter } from "react-router";
// import moment from "moment";
import { BROADCAST_TYPE_LABELS } from "../../../core/constants";
import { HTTP } from "../../../core/http_common";
import ChatArea from "../../components/ChatArea/chatArea";
import { ButtonGroup } from "../../components/botton_group";
import { MdRefresh } from "react-icons/md";
import { AdminController } from "../../../controllers/admin/admin_controller";
type StateType = {
  files: Array<File>;
  Discusstion: GetDiscusstionPanelResResult;
  myUserId: string;
  loading: boolean;
  chatLoading: boolean;
  ExternalUrl: Array<string>;
  External: string;
  massage: string;
};
interface RouteParams {
  id: string;
}
class AdminTicket extends React.Component<RouteComponentProps<RouteParams>> {
  RoleUser = store.getState().userRole;
  controller = new DiscusstionController();
  local = new LocalDataSources();
  UploadController = new UploadController();
  adminController = new AdminController();
  validator = new SimpleReactValidator({
    className: "text-danger",
  });
  state: StateType = {
    files: [],
    Discusstion: {
      creatorUserFirstName: "",
      creatorUserId: "",
      creatorUserLastName: "",
      histories: [],
      id: 0,
      isTicket: false,
      priority: 0,
      subject: "",
      topic: 0,
      users: [],
      ticketStatus: 0,
      broadcastTypes: [],
      sectionId: 0,
    },
    myUserId: this.local.getUserId(),
    loading: false,
    chatLoading: false,
    ExternalUrl: [],
    External: "",
    massage: "",
  };
  componentDidMount() {
    if (!isTokenInHttpAuth(HTTP)) return;

    this.getDiscustionPanel();
  }
  getDiscustionPanel() {
    this.setState({ chatLoading: true });
    this.controller.getDiscusstionPanel(
      {
        discussionId: parseInt(this.props.match.params.id),
        ticket: true,
      },
      (res) => {
        const _users = res.users.map((_u: any) => {
          const userTitleAst = getUserTitle(_u);
          return {
            ..._u,
            title: userTitleAst,
            secondary: _u.invitationStatus === 1 ? "Pending" : null,
          };
        });
        let _broadcastTypes: any[] = [];
        if (typeof res.broadcastTypes === "string" && res.broadcastTypes) {
          _broadcastTypes = res.broadcastTypes.split(",");
        }

        this.setState({
          Discusstion: {
            ...res,
            users: _users,
            broadcastTypes: _broadcastTypes,
          },
          chatLoading: false,
        });
      },
      (err) => {
        this.setState({ chatLoading: false });
      }
    );
  }
  onDrop = (fileList: any) => {
    fileList.map((item: any, index: number) => {
      const found = this.state.files.find(
        (element: any) => element.name === item.name
      );
      if (found === undefined) {
        this.setState({ files: [fileList[index], ...this.state.files] });
      }
    });
  };
  handleChange(target: string, val: any) {
    this.setState({
      [target]: val,
    });
  }
  async handelUpload(id: number) {
    try {
      await uploadAllLinks(id, this.state.ExternalUrl, 7);
      await uploadAllFiles(id, this.state.files, 7);
      this.setState({
        loading: false,
        ExternalUrl: [],
        External: "",
        massage: "",
        files: [],
      });
      this.getDiscustionPanel();
    } catch(e) {
      this.setState({
        loading: false,
        ExternalUrl: [],
        External: "",
        massage: "",
        files: [],
      });
    }


    // const formData = new FormData();
    // for (let i = 0; i < this.state.files.length; i++) {
    //   const file = this.state.files[i];
    //   formData.append("Files", file);
    // }
    // for (let i = 0; i < this.state.ExternalUrl.length; i++) {
    //   const file = this.state.ExternalUrl[i];
    //   formData.append("ExternalUrls", file);
    // }
    // formData.append("UseCase", "7");
    // formData.append("SectionId", id.toString());

    // await this.UploadController.UloadMedia(
    //   formData,
    //   (res) => {
    //     this.setState({
    //       loading: false,
    //       ExternalUrl: [],
    //       External: "",
    //       massage: "",
    //       files: [],
    //     });
    //     this.getDiscustionPanel();
    //   },
    //   () => {
    //     this.setState({
    //       loading: false,
    //       ExternalUrl: [],
    //       External: "",
    //       massage: "",
    //       files: [],
    //     });
    //   }
    // );
  }
  addExternalUrl() {
    if (this.validator.fields.input) {
      let Url = [...this.state.ExternalUrl];
      if (this.state.External.length > 2) {
        Url.push(this.state.External);
      }
      this.setState({
        ExternalUrl: Url,
        External: "",
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  handelDeleteFile(arg: File) {
    this.setState({
      files: this.state.files.filter((file) => file.name !== arg.name),
    });
  }
  handelDeleteExternalLink(link: string) {
    this.setState({
      ExternalUrl: this.state.ExternalUrl.filter((item) => item !== link),
    });
  }
  SendMassage() {
    if (this.validator.allValid()) {
      const body = {
        discussionId: parseInt(this.props.match.params.id),
        message: this.state.massage,
      };

      this.setState({
        loading: true,
      });
      this.controller.createMassage(
        body,
        (res) => {
          if (this.state.files.length || this.state.ExternalUrl.length) {
            this.handelUpload(res.result);
          } else {
            this.setState({
              loading: false,
              ExternalUrl: [],
              External: "",
              massage: "",
              files: [],
            });
            this.getDiscustionPanel();
          }
        },
        (err) => {
          this.setState({
            loading: false,
            ExternalUrl: [],
            External: "",
            massage: "",
            files: [],
          });
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handleChangeTicketStatus(_status: number) {
    this.adminController.updateTicket(
      {
        id: this.state.Discusstion.id,
        priority: this.state.Discusstion.priority,
        subject: this.state.Discusstion.subject,
        ticketStatus: Number(_status),
        categoryId: null,
      },
      (res) => {
        this.getDiscustionPanel();
      },
      (err) => {}
    );
  }
  render() {
    const files = this.state.files.map((file: any) => (
      <li key={file.name}>
        <span className="text-truncate col-10 mx-2">
          {file.name} - {file.size} bytes
        </span>
        <CircleIcon
          type={ThemeCircleIcon.dark}
          width="22px"
          padding="0"
          height="22px"
          onClick={() => {
            this.handelDeleteFile(file);
          }}
        >
          <img
            src="/images/icons/garbage_can.svg"
            alt="radvix"
            width={15}
            height={15}
          />
        </CircleIcon>
      </li>
    ));
    return (
      <div className="container-fluid research new-research">
        <div className="row"></div>
        

        <div className="col-12 box-content p-3 discusstion">
        {typeof this.state.Discusstion.broadcastTypes === "object" &&
        this.state.Discusstion.broadcastTypes.length > 0 ? (
          <div className="d-flex align-items-center justify-content-center w-100">
            {this.state.Discusstion.broadcastTypes.map((item: any) => {
              return <div className="chipDark mx-1">{BROADCAST_TYPE_LABELS[item]}</div>;
            })}
          </div>
        ) : null}
          {/* <h5 className="b-title d-flex align-items-center">
            <span
              onClick={() => {
                window.history.back();
              }}
              className="backPage"
            ></span>
            Ticket
            <MainButton
              children={this.state.Discusstion.priority.isPriority()}
              type={MainButtonType.dark}
              borderRadius="24px"
              fontSize="14px"
              className="mx-2"
              backgroundColor={
                this.state.Discusstion.priority === 0
                  ? "#9CFBFF"
                  : this.state.Discusstion.priority === 1
                  ? "#40c3f4"
                  : "#096bff"
              }
              minWidth="100px"
            ></MainButton>
            <MainButton
              children="Open"
              type={MainButtonType.light}
              borderRadius="24px"
              fontSize="14px"
              className="mx-2"
              backgroundColor="#FFBA00"
              minWidth="100px"
            ></MainButton>
          </h5> */}
          <div
            style={{ position: "relative" }}
            className="title-discusstion text-center"
          >
            <span
              style={{
                position: "absolute",
                left: "0.24em",
                top: "0.24em",
                // bottom: '0.32em'
              }}
              onClick={() => {
                window.history.back();
              }}
              className="backPage"
            ></span>
            This {this.state.Discusstion.ticketStatus ? "ticket" : "broadcast"}{" "}
            (#{!this.state.Discusstion.ticketStatus 
                        ? `B${String( this.state.Discusstion.broadcastCounter != null && this.state.Discusstion.broadcastCounter )}`
                        : `T${String(this.state.Discusstion.ticketCounter != null && this.state.Discusstion.ticketCounter )}`}) is related to
            {/* {this.state.Discusstion.topic.isTopic()} */}
            <span className="ms-1">{this.state.Discusstion.subject}</span>
            <MainButton
              children={this.state.Discusstion.priority.isPriority()}
              type={MainButtonType.dark}
              borderRadius="24px"
              fontSize="14px"
              className="mx-2"
              backgroundColor={
                this.state.Discusstion.priority === 0
                  ? "#9CFBFF"
                  : this.state.Discusstion.priority === 1
                  ? "#40c3f4"
                  : "#096bff"
              }
              minWidth="100px"
            ></MainButton>
            <span
              onClick={() => {
                this.getDiscustionPanel();
              }}
              title="Refresh Discussion"
              className="d-flex align-items-center justify-content-center darkenBg p-1 pointer"
              style={{
                borderRadius: "50%",
                backgroundImage: "none",

                position: "absolute",
                right: "0.24em",
                top: "0.24em",
                height: "1.75em",
                width: "1.75em",
              }}
            >
              <MdRefresh size="1.3em" />
            </span>
          </div>
          <div className="row mt-2">
            <div className="col-md-8">
              <ChatArea
                loading={this.state.chatLoading}
                chats={this.state.Discusstion.histories}
              />
              <div className="item">
                <InputComponent
                  type={InputType.textarea}
                  label="Message:"
                  className="mt-2"
                  rows={4}
                  onChange={(e) => {
                    this.handleChange("massage", e.target.value);
                  }}
                  inValid={this.validator.message(
                    "Message",
                    this.state.massage,
                    "required"
                  )}
                  value={this.state.massage}
                ></InputComponent>
              </div>
            </div>
            <div className="col-md-4">
              {/* <div className="d-flex flex-column"> */}
              {/* <span>Ticket status</span> */}
              {this.state.Discusstion.ticketStatus ? (
                <div className="item mb-3">
                  <ButtonGroup
                    label="Ticket status"
                    popQuestion="Ticket status:"
                    name="discusType"
                    items={[
                      {
                        id: 1,
                        name: "Open",
                      },
                      {
                        id: 2,
                        name: "Closed",
                      },
                    ]}
                    TextItem="name"
                    ValueItem="id"
                    selected={this.state.Discusstion.ticketStatus}
                    onChange={(e) => {
                      this.handleChangeTicketStatus(e.target.value);
                      // onChangeIsDiscusstionForSubTask(parseInt(e.target.value));
                    }}
                  ></ButtonGroup>
                  {/* </div> */}
                </div>
              ) : null}

              <div className="teams teams-light p-1 chat-teams-light mb-1">
                <div className="d-flex justify-content-between align-items-center p-2 pb-0">
                  <IconTextRow
                    theme={Theme.dark}
                    text={`Users In This Discussion (${
                      this.state.Discusstion?.users?.length || 0
                    })`}
                    children={
                      <img
                        src="/images/icons/team_menu.svg"
                        className="mx-2"
                        alt=""
                      />
                    }
                  ></IconTextRow>
                  {/* <CircleIcon
                    width="36px"
                    height="36px"
                    type={ThemeCircleIcon.dark}
                    backgroundColor="#9D9D9D"
                    fontSize="18px"
                    color="#ffffff"
                    className="mx-2"
                  >
                    <i className="fas fa-plus"></i>
                  </CircleIcon> */}
                </div>
                <BoxListScroll
                  default_photo="/Images/images/img_avatar.png"
                  items={this.state.Discusstion.users}
                  TextItem="title"
                  ValueItem="id"
                  ImageItem="image"
                  Deletabel
                  className="mt-3"
                  emptyText="no Users has been added yet."
                ></BoxListScroll>
              </div>

              <div className="item">
                <span className="label d-flex align-items-center">
                  Attachment:
                  <MainButton
                    type={MainButtonType.light}
                    children={"optional"}
                    borderRadius="50px"
                    fontSize="15px"
                    className="mx-2"
                  ></MainButton>
                  <CircleIcon
                    width="20px"
                    height="20px"
                    padding="0"
                    type={ThemeCircleIcon.dark}
                    backgroundColor="transparent"
                    border="1px solid #D5D5D5"
                    fontSize="10px"
                    color="#D5D5D5"
                  >
                    <i className="fas fa-question"></i>
                  </CircleIcon>
                </span>
                <Dropzone onDrop={this.onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="container fileUploadBox">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <MainButton
                          type={MainButtonType.light}
                          minHeight="30px"
                          minWidth="179px"
                          fontSize="14px"
                          borderRadius="50px"
                          backgroundColor="#fff"
                          border="1px solid #707070"
                          color="#707070"
                          className="mt-4"
                          children={
                            <div className="d-flex justify-content-between align-items-center">
                              <img
                                src="/Images/icons/cloud_computing.svg"
                                alt="sssss"
                                height="20"
                              />{" "}
                              <span className="flex-fill">
                                Browse Local Files
                              </span>
                            </div>
                          }
                        ></MainButton>
                        <p>Or drag and drop files here</p>
                      </div>
                      <aside>
                        <ul>{files}</ul>
                      </aside>
                    </section>
                  )}
                </Dropzone>
              </div>
              <div className="item d-flex justify-content-between align-items-center">
                <InputComponent
                  type={InputType.text}
                  placeholder="https://"
                  className="mx-2"
                  onChange={(e) => {
                    this.handleChange("External", e.target.value);
                  }}
                  onEnter={(e) => {
                    this.addExternalUrl();
                  }}
                  value={this.state.External}
                ></InputComponent>
                <CircleIcon
                  width="36px"
                  height="36px"
                  padding="0"
                  type={ThemeCircleIcon.dark}
                  backgroundColor="#9D9D9D"
                  fontSize="18px"
                  color="#ffffff"
                  className="px-3"
                  onClick={() => {
                    this.addExternalUrl();
                  }}
                >
                  <i className="fas fa-plus"></i>
                </CircleIcon>
              </div>
              <p className="text-error mb-0">
                {this.validator.message("input", this.state.External, "url")}
              </p>
              <ul className="file-list mt-1">
                {this.state.ExternalUrl.map((item) => (
                  <li className="my-1 d-flex align-items-center max1LineText">
                    <a title="Go to the link" className="max1LineText s-chip outlined fs-200 blueColor" href={item} target="_blank" rel="noopener noreferrer">
                      {item}
                    </a>
                    <CircleIcon
                      type={ThemeCircleIcon.dark}
                      width="22px"
                      height="22px"
                      padding="0"
                      className="ms-2 pointer"
                      onClick={() => this.handelDeleteExternalLink(item)}
                    >
                      <img
                        src="/images/icons/garbage_can.svg"
                        alt="radvix"
                        width={15}
                        height={15}
                      />
                    </CircleIcon>
                  </li>
                ))}
              </ul>
              <div>
                <MainButton
                  type={MainButtonType.dark}
                  children={"Send"}
                  borderRadius="50px"
                  fontSize="20px"
                  className="mt-3 btn-block"
                  minHeight="47px"
                  minWidth="100%"
                  disabled={this.state.loading}
                  onClick={() => {
                    this.SendMassage();
                  }}
                  loading={this.state.loading}
                ></MainButton>
              </div>
            </div>
            {/* <div className="col-md-7"></div>
            <div className="col-md-4"></div> */}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(AdminTicket);
