import React, { useEffect, useState } from "react";
import {
  Stack,
  Text,
  IconButton,
  IStackTokens,
  IIconProps,
  Separator,
  Persona,
  PersonaSize,
  mergeStyleSets,
  TextField,
  PrimaryButton,
  List,
  Link,
} from "@fluentui/react";
import { ImageWithToken } from "../../../../components/box_list_scroll";
import "./App.css";
import { HTTP } from "../../../../../core/http_common";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import EmailComposeComponent from "./EmailComposeComponent";
import { EmailIntegrationController } from "../../../../../controllers/EmailIntegration/EmailIntegration_Controller";
const styles = mergeStyleSets({
  emailHeader: {
    background: "#f3f2f1", // Use Fluent UI Neutral Colors for consistency
    padding: "10px 20px",
    borderRadius: "5px",
  },
  headerContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  emailBody: {
    marginTop: "20px",
    padding: "20px",
    background: "#fff", // A lighter background for the email body improves readability
    borderRadius: "5px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // A subtle shadow for depth
  },
  actionButtons: {
    display: "flex",
    gap: "10px",
  },
  metadataText: {
    fontStyle: "italic",
  },
});

interface Email {
  subject: string;
  from: string;
  to: string;
  date: string;
  body: string;
}

interface OpenEmailComponentProps {
  email: any;
  onDeleteClick: any;
  setComposeEmail: any;
}

export const OpenEmailComponent: React.FC<OpenEmailComponentProps> = ({
  email,
  onDeleteClick,
  setComposeEmail,
}) => {
  const replyIcon: IIconProps = { iconName: "Reply" };
  const forwardIcon: IIconProps = { iconName: "Forward" };
  const [loading, setLoading] = useState(false);
  const [replyOrReplyAllOrForward, setReplyOrReplyAllOrForward] = useState(0);

  const controller = new EmailIntegrationController();
  const handleReply = (isReplyAll: any) => {
    isReplyAll == true ? setReplyOrReplyAllOrForward(2) : setReplyOrReplyAllOrForward(1);
    setShowReplyEditor(1);
  };
  const [replyMessage, setReplyMessage] = useState("");
  const [showReplyEditor, setShowReplyEditor] = useState(0);
  const [body, setBody] = useState(email?.body);
  const maxVisibleAttachments = 3;
  const handleReplyChange = (event: any) => {
    setReplyMessage(event.target.value);
  };
  useEffect(() => {
    if (email == null) return;
    const emailHtmlWithInlineImages = replaceCidWithInlineImages(
      email.body,
      email.attachments
    );
    setBody(emailHtmlWithInlineImages);
  }, [email]);
  const downloadFile = (file: any) => {
    if (!file.contentBytes) {
      console.error("No file data found!");
      return;
    }

    // Convert base64 content to blob
    const blob = base64ToBlob(file.contentBytes, file.contentType);

    // Create a URL for the blob object
    const url = window.URL.createObjectURL(blob);

    // Create a temporary anchor element and trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = file.name || "download"; // Default file name if not provided
    document.body.appendChild(a);
    a.click();

    // Clean up by revoking the object URL and removing the anchor element
    window.URL.revokeObjectURL(url);
    a?.parentNode?.removeChild(a);
  };

  const handleSendReply = () => {
    setLoading(true);
    const emailData = {
      Body: replyMessage,
      Id: email.key,
    };

    try {
      controller.SendReply(
        emailData.Body,
        (res) => {
          setLoading(false);
          toast.info("Email sent successfully.");
          setShowReplyEditor(0);
          setReplyMessage("");
        },
        (err: any) => {
          setLoading(false);
          //toast.error("Failed to send email.");
          console.error("Failed to send email:", err);
        }
      );
    } catch (error) {
      setLoading(false);
      console.error("Failed to send email:", error);
      toast.error("Failed to send email.");
    }
  };

  const handleDeleteEmail = async () => {
    setLoading(true);
    try {
      controller.DeleteEmail(
        email.key,
        (res) => {
          if (res) {
            setLoading(false);
            toast.info("Email deleted successfully.");
            onDeleteClick(email.key);
          } else {
            toast.error("Failed to delete email.");
          }
        },
        (err: any) => {
          console.error(err);
        }
      );
    } catch (error) {
      setLoading(false);
      console.error("Failed to delete email:", error);
      toast.error("Failed to delete email.");
    }
    // try {
    //   // Correctly format the query string
    //   await HTTP.get(`/Messages/DeleteEmail?emailId=${email.key}`)
    //     .then((res) => {
    //       if (res) {
    //         setLoading(false);
    //         toast.info("Email deleted successfully.");
    //         onDeleteClick(email.key);
    //       } else {
    //         toast.error("Failed to delete email.");
    //       }
    //     })
    //     .catch((err) => console.error(err));
    // } catch (error) {
    //   console.error("Failed to delete email:", error);
    //   toast.error("Failed to delete email.");
    // } finally {
    //   setLoading(false);
    // }
  };

  const formatDateTime = (dateTimeStr: any) => {
    const date = new Date(dateTimeStr);

    // Set locale and options for formatting
    const locale = "en-GB"; // Using 'en-GB' to get day before month in date format
    const options: any = {
      weekday: "short", // "Thu"
      day: "2-digit", // "26"
      month: "2-digit", // "01"
      year: "numeric", // "2023"
      hour: "2-digit", // "05"
      minute: "2-digit", // "26"
      hour12: false, // Use 24-hour time without AM/PM
    };

    return date.toLocaleString(locale, options);
  };

  function base64ToBlob(base64: any, contentType: any) {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }
  const [to, setTo] = useState<any[]>([]);
  const [cc, setCc] = useState<any[]>([]);
  useEffect(() => {
    if (email?.toRecipients) {
      const registeredEmailAddress = localStorage.getItem(
        "registeredEmailAddress"
      );
      let toValues: any = "You; ";
      let ccValues: any = [];
      email?.toRecipients.forEach((element: any) => {
        if (registeredEmailAddress != element.emailAddress.address) {
          toValues += element.emailAddress.address + "; ";
        }
      });
      setTo(toValues);

      email?.ccRecipients.forEach((element: any) => {
        if (registeredEmailAddress != element.emailAddress.address) {
          ccValues += element.emailAddress.address + "; ";
        }
      });
      setCc(ccValues);
    }
  }, [email]);
  const base64ToDataUrl = (base64: any, contentType: any) => {
    return `data:${contentType};base64,${base64}`;
  };

  // Function to replace CID references with data URLs
  const replaceCidWithInlineImages = (htmlContent: any, attachments: any) => {
    let modifiedHtmlContent = htmlContent;

    attachments?.forEach((attachment: any) => {
      const { contentBytes, contentType, contentId } = attachment; // Adjust these property names based on your actual API response
      const dataUrl = base64ToDataUrl(contentBytes, contentType);
      const cidReference = `cid:${contentId}`;
      modifiedHtmlContent = modifiedHtmlContent.replace(
        new RegExp(cidReference, "gi"),
        dataUrl
      );
    });

    return modifiedHtmlContent;
  };
  const deleteIcon: IIconProps = { iconName: "Delete" };
  const crossIcon: IIconProps = { iconName: "Cancel" };

  return (
    <div className="email-container">
      <IconButton
        iconProps={crossIcon}
        title="Close"
        ariaLabel="Close"
        onClick={() => setComposeEmail(0)}
      />
      <h2 className="email-title">{email?.subject}</h2>
      {showReplyEditor == 1 && (
        <EmailComposeComponent
          email={email}
          replyall={replyOrReplyAllOrForward}
          setComposeEmail={setComposeEmail}
        />
      )}
      {showReplyEditor == 0 && (
        <>
          {" "}
          <header className="email-header">
            <div className="sender-info">
              <ImageWithToken
                src={"/Images/images/img_avatar.png"}
                alt="Avatar"
                className={"test"}
                height={40}
                width={40}
              />
              <div className="sender-details">
                <div className="sender-name">
                  Sender &lt;{`${email?.emailAddress}`}&gt;
                </div>
                <div className="recipient-info">
                  <span>To:</span>
                  <span>{to}</span>
                </div>
                {cc && cc.length > 0 && (
                  <div className="recipient-info">
                    <span>Cc:</span>
                    <span>{cc}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="email-meta">
              <div className="email-actions">
                <button onClick={() => handleReply(false)}>
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/a5c0de9f79b9be5071cf8e2b40d3b186acb3b9edf83157415b8103819675ea5a?apiKey=5a68a44d41f14ffb82c4d545f2147a7a&"
                    alt=""
                    className="email-action-icon"
                  />
                </button>
                <button onClick={() => handleReply(true)}>
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/088dca6a173339c8b28cfdcb4b8abcdf069c7f23b1e891de408346c1501c8a46?apiKey=5a68a44d41f14ffb82c4d545f2147a7a&"
                    alt=""
                    className="email-action-icon"
                  />
                </button>
                <button onClick={() => {setReplyOrReplyAllOrForward(3);setShowReplyEditor(1)}}>
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/c90ef5211d23417bbc2872655eba01cbcb89e80b896e778c53f3e293ebb30f77?apiKey=5a68a44d41f14ffb82c4d545f2147a7a&"
                    alt=""
                    className="email-action-icon"
                  />
                </button>
                <IconButton
                  iconProps={deleteIcon}
                  title="Delete"
                  ariaLabel="Delete"
                  onClick={handleDeleteEmail}
                />
              </div>
              <div className="email-timestamp">
                {formatDateTime(email?.receivedDateTime)}
              </div>
            </div>
          </header>
          {email?.attachments != null && (
            <div style={{ marginTop: "10px" }}>
              <Text variant="mediumPlus">Attachments:</Text>
              <div
                style={{
                  maxHeight: `${maxVisibleAttachments * 40 + 10}px`,
                  overflowY: "auto",
                }}
              >
                <List
                  items={
                    email?.attachments.filter(
                      (x: any) => x.isInline !== true
                    ) || []
                  }
                  onRenderCell={(item, index) => (
                    <div
                      key={index}
                      style={{
                        background: "#f4f4f4",
                        padding: "5px 10px",
                        borderRadius: "2px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "5px",
                      }}
                    >
                      <Text>{item.name}</Text>
                      <Link
                        style={{ marginRight: "10px" }}
                        onClick={() => downloadFile(item)}
                      >
                        Download
                      </Link>
                    </div>
                  )}
                />
              </div>
            </div>
          )}
          <div className="email-body" style={{ overflow: 'auto', wordWrap: 'break-word', maxWidth: '100%' }}>
  <div
    style={{
      overflow: 'auto',
      wordWrap: 'break-word',
      maxWidth: '100%',
      // Add other CSS rules as needed
    }}
              dangerouslySetInnerHTML={{ __html: body }}
            ></div>
          </div>
          <div className="email-footer">
            <div className="email-reply">
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/a5c0de9f79b9be5071cf8e2b40d3b186acb3b9edf83157415b8103819675ea5a?apiKey=5a68a44d41f14ffb82c4d545f2147a7a&"
                alt=""
                className="email-action-icon"
              />
              <button className="email-reply-text" onClick={handleReply}>
                Reply
              </button>
            </div>
            <div className="email-forward">
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c90ef5211d23417bbc2872655eba01cbcb89e80b896e778c53f3e293ebb30f77?apiKey=5a68a44d41f14ffb82c4d545f2147a7a&"
                alt=""
                className="email-action-icon"
              />
              <button className="email-forward-text" onClick={() => {setReplyOrReplyAllOrForward(3);setShowReplyEditor(1)}}>
                Forward
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OpenEmailComponent;
