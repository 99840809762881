import React from "react";
import { MdClose } from "react-icons/md";

type Props = {
  onClose?: () => void;
  title: string | React.ReactNode;
  className?: string;
  style?: any;
  closeStyle?: any;
};

export default function TippyHeader({
  title,
  onClose,
  className = "",
  style = {},
  closeStyle = {},
}: Props) {
  return (
    <div
      className={`d-flex py-2 pt-1 ${className}`}
      style={{
        borderBottom: "1px solid #E5E5E5",
        position: "relative",
        ...style,
      }}
    >
      <span className="textSecondary">{title}</span>
      {onClose && (
        <span
          onClick={onClose}
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            cursor: "pointer",
            transform: "translateY(-50%)",
            ...closeStyle,
          }}
        >
          <MdClose />
        </span>
      )}
    </div>
  );
}
