import { useHistory } from 'react-router';
import { AppRoutes } from '../../../../core/constants';
import { $ReplaceRoute } from '../../../../core/utils';
import Skeleton from 'react-loading-skeleton';
import AddNewButton from './add_new_button';

export default function NotSelectedMessage({ loading }: { loading: boolean }) {
  const history = useHistory();

  return (
    <>
      <div className='d-flex justify-content-end p-px-12 d-xl-none'>
        <AddNewButton
          onClick={() => {
            history.push($ReplaceRoute(AppRoutes.discussion_new));
          }}
        >
          New Chat
        </AddNewButton>
      </div>

      {loading ? (
        <>
          <Skeleton width='100%' height='1.25em' style={{ marginTop: '30px' }} />
          <Skeleton width='100%' height='1.25em' style={{ marginTop: '30px' }} />
          <Skeleton width='100%' height='1.25em' style={{ marginTop: '30px' }} />
        </>
      ) : (
        <div className='no-data-found'>
          <img
            className='no-data-found-img'
            src='/Images/images/Guy Standing And Finding Data.png'
            alt='Not found'
          />
          No selected message yet
        </div>
      )}
    </>
  );
}
