import Tippy from '@tippyjs/react'
import React from 'react'

interface CustomTooltipTypes {
    children: React.ReactNode;
    content: any;
    className?: string
};

const CustomTooltip = ({ className = "", content, children }: CustomTooltipTypes) => {
    return (
        <Tippy
            content={content}
            // arrow={false}
            className='simpleToolTip'
            placement='bottom'
        >
            <span className={className}>
                {children}
            </span>
        </Tippy>
    )
};

export default CustomTooltip