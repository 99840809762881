import React, { MouseEventHandler } from 'react';
import ReactPaginate from 'react-paginate';
import { store } from '../../../data/storeMain';
import { MainButton, MainButtonType } from '../../components/button';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
import { InputIcon } from '../../components/search_box';
import { SelectComponent } from '../../components/select_input';
// import MyTicketTable from "./component/my_tickets";
import { RouteComponentProps, withRouter } from 'react-router';
import {
  AppRoutes,
  MOMENT_DATE_FORMAT,
  TICKET_STATUS_COLORS,
  TICKET_STATUS_LABELS,
} from '../../../core/constants';
import { Discussion } from '../../../data/models/responses/discussion/get_all_discusstion_res';
import { DiscusstionController } from '../../../controllers/discussion/discusstion_controller';
// import { EmptyState } from "../../components/empty_state";
import { $ReplaceRoute, isTokenInHttpAuth } from '../../../core/utils';
import { HTTP } from '../../../core/http_common';
import NewTable from '../../components/NewTable/NewTable';
import moment from 'moment';
import { BsFillChatLeftDotsFill } from 'react-icons/bs';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import {
  Priority,
  SortType,
  ticketFilterStatus,
  ticketPriorityFilter,
} from '../../../core/number_extentions';
import DatePicker from 'react-datepicker';
import FilterSection from '../../components/FilterSection/filterSection';
import { IFilterOptions } from '../../components/FilterSection/IFilterOptions';
import DOMPurify from 'dompurify';
import RadixTable from '../../components/NewRadixTable/RadixTable';
import { DropdownMenu, Table } from '@radix-ui/themes';
import { ImageWithToken } from '../../components/box_list_scroll';
import Tippy from '@tippyjs/react';
import MultiSelect from '../../components/SelectRadix/radixMultiSelect';
import Pagination from '../../components/NewRadixTable/RadixPagination';
type StateType = {
  Discusstion: Discussion[];
  PageNumber: number;
  PageSize: number;
  PageCount: number;
  TotalCount: number;
  Search: string;
  loading: boolean;
  HeaderName: string;
  SortDirection: SortType;
  showFilterOptions: boolean;
  filterOptions: IFilterOptions;
  selectedStatus: any[];
  selectedPriority: number[] | undefined;
  startDate: Date | null;
  endDate: Date | null;
  PageNumberInput: string;
};
class TicketPage extends React.Component<RouteComponentProps> {
  RoleUser = store.getState().userRole;
  controller = new DiscusstionController();
  state: StateType = {
    Discusstion: [],
    PageNumber: 1,
    PageSize: 10,
    PageCount: 0,
    TotalCount: 0,
    Search: '',
    loading: true,
    HeaderName: '',
    SortDirection: SortType.AutoSelect,
    showFilterOptions: false,
    filterOptions: {},
    selectedStatus: [],
    selectedPriority: [],
    startDate: null,
    endDate: null,
    PageNumberInput: '',
  };
  // filterToggle: MouseEventHandler<HTMLButtonElement> | undefined;
  constructor(props: any) {
    super(props);
    this.handlePageNumberInput = this.handlePageNumberInput.bind(this);
  }
  componentDidMount() {
    document.title = `My Tickets | Radvix`;
    if (!isTokenInHttpAuth(HTTP)) return;
    this.GetDiscusstion(this.state.PageNumber, this.state.PageSize);
  }
  renderStatus(status: any) {
    if (status == 1) {
      return (
        <span className='badge bg-info text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          Open
        </span>
      );
    } else {
      return (
        <span className='badge bg-success text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          Close
        </span>
      );
    }
  }
  filterToggle = () => {
    const filteroggleButton = document.getElementById('filterToggler');
    const showFilter = document.getElementById('showFilter');
    const isFilterShown = document.getElementById('isFilterShown');
    showFilter?.classList.toggle('show-filters');
    filteroggleButton?.classList.toggle('filters-hide');
    isFilterShown?.classList.toggle('filters-height');
  };
  handelChagePageSize(e: any) {
    this.setState({
      PageSize: e,
    });
    this.GetDiscusstion(
      this.state.PageNumber,
      e?.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      this.state.filterOptions
    );
  }
  GetDiscusstion(
    PageNumber: number,
    PageSize: number,
    Search?: string,
    columnHeaderName?: string,
    sortDirection?: SortType,
    filterOptions?: IFilterOptions
  ) {
    this.setState({
      loading: true,
    });
    this.controller.getAllDiscusstion(
      {
        PageNumber: PageNumber,
        PageSize: PageSize,
        ticket: true,
        SearchParameter: this.state.Search,
        columnHeaderName,
        sortDirection,
        filterOptions,
      },
      (res) => {
        if (!res) {
          this.setState({
            loading: false,
          });
          return;
        }
        this.setState({
          Discusstion: res.discussions,
          PageCount: Math.ceil(res.count! / this.state.PageSize),
          TotalCount: res.count,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
  }
  handelChangePageNumber(e: { selected: number }) {
    this.setState({
      PageNumber: e.selected,
    });
    this.GetDiscusstion(
      e.selected + 1,
      this.state.PageSize,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      this.state.filterOptions
    );
  }
  handelChangePageSize(e: { label: string; value: number }) {
    this.setState({
      PageSize: e.value,
    });
    this.GetDiscusstion(
      this.state.PageNumber,
      e.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      this.state.filterOptions
    );
  }
  headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    this.setState({ HeaderName: columnHeaderName });
    this.setState({ SortDirection: sortDirection });
    this.GetDiscusstion(
      this.state.PageNumber,
      this.state.PageSize,
      this.state.Search,
      columnHeaderName,
      sortDirection,
      this.state.filterOptions
    );
  };
  handleToggle = () => {
    this.setState({ showFilterOptions: !this.state.showFilterOptions });
  };

  renderPrioirty(status: any) {
    if (status == Priority.High) {
      return (
        <span className='badge bg-danger text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          High
        </span>
      );
    }
    if (status == Priority.Medium) {
      return (
        <span className='badge bg-warning text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          Medium
        </span>
      );
    }
    if (status == Priority.Low) {
      return (
        <span className='badge bg-success text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          Low
        </span>
      );
    }
  }

  priorityOptions: any[] = [
    { label: 'All Priority', value: -1 },
    {
      label: 'Low',
      value: 0,
      backgroundColor: 'bg-success',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#0880EA"></circle></svg>',
    },
    {
      label: 'Medium',
      value: 1,
      backgroundColor: 'bg-warning',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#ED5F00"></circle></svg>',
    },
    {
      label: 'High',
      value: 2,
      backgroundColor: 'bg-danger',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#D93D42" /></svg>',
    },
  ];

  statusOptions: any[] = [
    { label: 'All Status', value: -1, svg: '' },
    {
      label: 'Open',
      value: ticketFilterStatus.open,
      backgroundColor: 'bg-success',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#299764"></circle></svg>',
    },
    {
      label: 'Closed',
      value: ticketFilterStatus.closed,
      backgroundColor: 'bg-warning',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#ED5F00"></circle></svg>',
    },
  ];

  handleChangeTicketSearch(e: any) {
    const inputValue = e.target.value;
    const sanitizedValue = DOMPurify.sanitize(inputValue);
    this.GetDiscusstion(
      this.state.PageNumber,
      this.state.PageSize,
      sanitizedValue,
      this.state.HeaderName ? this.state.HeaderName : 'subject',
      this.state.SortDirection ? this.state.SortDirection : SortType.ASC
    );
  }
  handleStartDateChange = (value: any) => {
    this.setState({ startDate: value }, () => {
      this.filterClick(
        this.state.startDate,
        this.state.endDate,
        this.state.selectedStatus,
        this.state.selectedPriority,
        this.state.Search
      );
    });
  };

  handleStatusChange = (selectedStatus: any[]) => {
    debugger;
    const allValue = selectedStatus.find((x) => x.value == -1);
    const value = allValue ? null : selectedStatus;
    this.setState({ selectedStatus: value }, () => {
      this.filterClick(
        this.state.startDate,
        this.state.endDate,
        value,
        this.state.selectedPriority,
        this.state.Search
      );
    });
  };

  handlePriorityChange = (selectedPriority: any[]) => {
    debugger;
    const allValue = selectedPriority.find((x) => x.value == -1);
    const value = allValue ? null : selectedPriority;
    this.setState({ selectedPriority: value }, () => {
      this.filterClick(
        this.state.startDate,
        this.state.endDate,
        this.state.selectedStatus,
        value,
        this.state.Search
      );
    });
  };

  handlePageNumberInput(event: { target: { value: any } }) {
    if (event.target.value > 0) {
      this.setState({ PageNumberInput: event.target.value });
    } else {
      this.setState({ PageNumberInput: '' });
    }
  }

  handleEndDateChange = (value: any) => {
    this.setState({ endDate: value }, () => {
      this.filterClick(
        this.state.startDate,
        this.state.endDate,
        this.state.selectedStatus,
        this.state.selectedPriority,
        this.state.Search
      );
    });
  };

  filterClick = (
    selectedFromDate?: Date | null,
    selectedToDate?: Date | null,
    status?: any,
    priority?: any,
    search?: string
  ) => {
    var filterOptions = {
      selectedFromDate: selectedFromDate,
      selectedToDate: selectedToDate,
      status: status,
      teamType: priority,
    };
    this.setState({ filterOptions: filterOptions });

    this.GetDiscusstion(
      this.state.PageNumber,
      this.state.PageSize,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      filterOptions
    );
  };
  render() {
    return (
      <div className='card'>
        <div className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-4'>
          <div className='d-flex align-items-center flex-shrink-0'>
            <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>Ticket List</h2>
          </div>

          <div className='d-flex flex-wrap gap-2 gap-sm-3'>
            <MainButton
              className='btn btn-primary fs-15 py-px-7 new-project-button'
              svg={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='15'
                  height='15'
                  viewBox='0 0 15 15'
                  fill='none'
                >
                  <rect width='15' height='15' fill='white' fill-opacity='0.01'></rect>
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z'
                    fill='white'
                  ></path>
                </svg>
              }
              onClick={() => {
                this.props.history.push($ReplaceRoute(`${AppRoutes.ticketing_new}`));
              }}
            >
              New Ticket
            </MainButton>
          </div>
        </div>
        <div className='d-flex flex-wrap row-gap-2 column-gap-3 px-3 pb-3 filters' id='showFilter'>
          <div className='w-100 pe-xl-3 border-end-xl-1 border-gray-400 d-sm-block d-flex gap-3 ticketing-search-bar'>
            <InputIcon
              className='form-control h-px-35'
              placeholder='Search publication'
              chilren={undefined}
              onChange={(e) => {
                this.setState({ Search: e.target.value });
                this.handleChangeTicketSearch(e);
              }}
            />
            <button
              className='btn btn-secondary d-sm-none h-px-35 w-px-35 p-0'
              id='filterToggler'
              onClick={this.filterToggle}
            >
              <img src='/images/icons/mixer-horizontal.svg' />
              <i className='fa fa-times d-none color-gray-1000'></i>
            </button>
          </div>

          <div className='w-100 d-none d-xl-flex align-items-center border-gray-400 ticketing-select-wrapper'>
            <div className='w-100 w-sm-50 w-xl-auto multi-select-wrapper'>
              <MultiSelect options={this.statusOptions} onChange={this.handleStatusChange} />
            </div>
            <div className='mx-px-12 flex-shrink-0 h-100 w-px-1 border-end border-gray-400'></div>
            <div className='w-100 w-sm-50 w-xl-auto multi-select-wrapper'>
              <MultiSelect options={this.priorityOptions} onChange={this.handlePriorityChange} />
            </div>
          </div>

          <div className='d-none d-xl-flex align-items-center w-100 w-xl-auto ticketing-dates-wrapper'>
            <div className='w-50 w-xl-auto date-pickers-wrapper'>
              <div className='date-picker-wrapper'>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.handleStartDateChange}
                  dateFormat='MM/dd/yyyy'
                  placeholderText='Start Date'
                  // maxDate={new Date()}
                  className='form-control h-px-35 customBorders'
                />
              </div>
            </div>
            <div className='px-2 flex-shrink-0 color-gray-400'>&ndash;</div>
            <div className='w-50 w-xl-auto date-pickers-wrapper'>
              <div className='date-picker-wrapper'>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={this.handleEndDateChange}
                  dateFormat='MM/dd/yyyy'
                  placeholderText='End Date'
                  //maxDate={new Date()}
                  className='form-control h-px-35 customBorders'
                />
              </div>
            </div>
          </div>

          <div className='w-100 d-flex flex-wrap row-gap-2 column-gap-3 sub-filters d-xl-none'>
            <div className='w-100 d-flex align-items-center border-gray-400 ticketing-select-wrapper'>
              <div className='w-100 w-sm-50 w-xl-auto multi-select-wrapper'>
                <MultiSelect options={this.statusOptions} onChange={this.handleStatusChange} />
              </div>
              <div className='mx-px-12 flex-shrink-0 h-100 w-px-1 border-end border-gray-400'></div>
              <div className='w-100 w-sm-50 w-xl-auto multi-select-wrapper'>
                <MultiSelect options={this.priorityOptions} onChange={this.handlePriorityChange} />
              </div>
            </div>

            <div className='d-flex align-items-center w-100 w-xl-auto ticketing-dates-wrapper'>
              <div className='w-50 w-xl-auto date-pickers-wrapper'>
                <div className='date-picker-wrapper'>
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleStartDateChange}
                    dateFormat='MM/dd/yyyy'
                    placeholderText='Start Date'
                    // maxDate={new Date()}
                    className='form-control h-px-35 customBorders'
                  />
                </div>
              </div>
              <div className='px-2 flex-shrink-0 color-gray-400'>&ndash;</div>
              <div className='w-50 w-xl-auto date-pickers-wrapper'>
                <div className='date-picker-wrapper'>
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={this.handleEndDateChange}
                    dateFormat='MM/dd/yyyy'
                    placeholderText='End Date'
                    //maxDate={new Date()}
                    className='form-control h-px-35 customBorders'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <RadixTable
          data={this.state.Discusstion}
          loading={this.state.loading}
          onHeaderClick={(columnHeaderName: string, sortDirection: SortType) =>
            this.headerClick(columnHeaderName, sortDirection)
          }
          columns={[
            {
              Header: 'Subject',
              accessor: 'subject',
              Cell: (_props: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <div className='line-clamp-2'>
                      <i className='fa fa-grip-vertical me-2 color-gray-600 fs-13'></i>
                      <span
                        style={{ cursor: 'pointer' }}
                        // onClick={(e) => {
                        //   this.props.history.push(
                        //     $ReplaceRoute(
                        //       `${AppRoutes.data_profile
                        //         .replace(':dataid', _props.id)
                        //         .replace(':appTaskId', _props.taskId?.toString() ?? '')}?researchId=${
                        //         _props.researchId
                        //       }`
                        //     )
                        //   );
                        // }}
                        title={_props.subject}
                      >
                        {' '}
                        {_props.subject}{' '}
                      </span>
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Ticket #',
              accessor: 'ticketNumber',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3 text-nowrap'>
                    {/* {<ShowFilesInTable medias={_row.medias} profileId={0} profileType={0} /> */}
                    <div style={{ display: 'flex', gap: '4px' }}>{props.ticketNumber}</div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Date',
              accessor: 'createdat',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3'>
                    <div className='d-flex align-items-center text-nowrap'>
                      {moment(props.createdAt).format(MOMENT_DATE_FORMAT)}
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Status',
              accessor: 'status',
              align: 'center',
              Cell: (_row: { ticketStatus: string | number }) => {
                return (
                  <Table.Cell className='p-3 ' style={{ minWidth: '132px', width: '132px' }}>
                    {this.renderStatus(_row.ticketStatus)}
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Type',
              accessor: 'priority',
              Cell: (_row: any) => {
                return (
                  <Table.Cell className='p-3' style={{ minWidth: '113px', width: '113px' }}>
                    {this.renderPrioirty(_row.priority)}
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Last Message',
              accessor: 'createdat',
              Cell: (_row: { lastMessageDate: moment.MomentInput }) => {
                return (
                  <Table.Cell>{moment(_row.lastMessageDate).format(MOMENT_DATE_FORMAT)}</Table.Cell>
                );
              },
            },
            {
              Header: '',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3 text-nowrap'>
                    <div className='d-flex align-items-center gap-3'>
                      <div className='btn-group'>
                        <button
                          type='button'
                          className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                          onClick={(e) => {
                            this.props.history.push(
                              $ReplaceRoute(
                                `${AppRoutes.ticketing_ticket.replace(':id', props.id)}`
                              )
                            );
                          }}
                        >
                          <i className='fa fa-eye me-px-6'></i>View Detail
                        </button>
                      </div>
                    </div>
                  </Table.Cell>
                );
              },
            },
          ]}
        />
        {this.state.PageCount > 0 && this.state.Discusstion.length > 0 && (
          <Pagination
            pageCount={this.state.PageCount}
            onPageChange={this.handelChangePageNumber.bind(this)}
            onPageSizeChange={(e: any) => {
              this.handelChagePageSize(e);
            }}
            pageInput={this.state.PageNumberInput}
            handlePageNumberInput={this.handlePageNumberInput}
          />
        )}
        {/* <div className="d-flex justify-content-between align-items-center px-3 mt-3">
          {this.state.PageCount > 1 ? (
            <div className="d-flex justify-content-end flex-fill">
              <ReactPaginate
                previousLabel={
                  <CircleIcon
                    width="24px"
                    backgroundColor="#4E0057"
                    height="24px"
                    padding="0"
                    type={ThemeCircleIcon.dark}
                  >
                    <i className="fas fa-chevron-left"></i>
                  </CircleIcon>
                }
                nextLabel={
                  <CircleIcon
                    width="24px"
                    backgroundColor="#4E0057"
                    height="24px"
                    padding="0"
                    type={ThemeCircleIcon.dark}
                  >
                    <i className="fas fa-angle-right"></i>
                  </CircleIcon>
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.PageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(e) => {
                  this.handelChangePageNumber(e);
                }}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          ) : null}

          {this.state.Discusstion.length > 0 ? (
            <div className="d-flex justify-content-end flex-fill">
              <p className="text-right mb-0 ">
                Total Results: {this.state.TotalCount}
              </p>
            </div>
          ) : null}
        </div> */}
      </div>
    );
  }
}
export default withRouter(TicketPage);
