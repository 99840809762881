import React from "react";
import { store } from "../../../data/storeMain";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import "react-datepicker/dist/react-datepicker.css";
import { MainButton, MainButtonType } from "../../components/button";
import { RouteComponentProps, withRouter } from "react-router";
import { AppRoutes, EXPENSE_STATUS, MOMENT_DATE_FORMAT } from "../../../core/constants";
import { expenseController } from "../../../controllers/expense/expense_controller";
import moment from "moment";
import {
  $ReplaceRoute,
  // $truncate,
  AccessPermition,
  isTokenInHttpAuth,
  UserRoles,
} from "../../../core/utils";
import { GetExpenseByIDResult } from "../../../data/models/responses/expense/expense_by_id_res";
import { HTTP } from "../../../core/http_common";
import Visible from "../../components/visible";
import Skeleton from "react-loading-skeleton";
import EmptyChip from "../../components/EmptyChip";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import File from "../../components/File";
import { nanoid } from "nanoid";
import UserWithAvatar from "../../components/UserWithAvatar";
interface RouteParams {
  id: string;
}
let unsubscribeMeData: any = undefined;
class ExpensePageProfile extends React.Component<
  RouteComponentProps<RouteParams>
> {
  RoleUser = store.getState().userRole;
  controller = new expenseController();
  state: GetExpenseByIDResult = {
    id: parseInt(this.props.match.params.id),
    title: "",
    appTaskTitle: "",
    creatorFirstName: "",
    creatorLastName: "",
    status: 0,
    date: new Date(),
    medias: [],
    amount: 0,
    description: "",
    haveDiscussion: false,
    allowedToView: false,
    loading: true,
    creatorUser: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      image: "",
      invitationStatus: 0,
      isUserTransferred: false,
      transferredToUserId: "",
    }
  };
  componentDidMount() {
    if (!isTokenInHttpAuth(HTTP)) return;
    this.setState({
      loading: true,
    });
    this.controller.getExpenseById(
      { id: parseInt(this.props.match.params.id) },
      (res) => {
        document.title = `Expense | ${res.title} | Radvix`
        this.setState({
          creatorFirstName: res.creatorFirstName,
          creatorLastName: res.creatorLastName,
          creatorUserId: res.creatorUserId,
          creatorUser: res.creatorUser,
          date: res.date,
          title: res.title,
          amount: res.amount,
          description: res.description,
          appTaskId: res.appTaskId,
          appTaskTitle: res.appTaskTitle,
          medias: res.medias,
          status: res.status,
          haveDiscussion: res.haveDiscussion,
          allowedToView: res.allowedToView,
          categoryTitle: res.categoryTitle,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });

        if (err.response?.data?.status === 403) {
          window.history.back();
        }
      }
    );
    unsubscribeMeData = store.subscribe(() => {
      if (store.getState().ResearchId.value >= 0) {
        this.props.history.push($ReplaceRoute(AppRoutes.expense));
        // this.setState({ researchName: store.getState().ResearchId.name });
      }
    });
  }
  // clean up event listener
  componentWillUnmount() {
    // console.log('called removeEvent')
    if (unsubscribeMeData) unsubscribeMeData();
  }
  handelCreateExpenseState(isApproved: boolean) {
    this.controller.createState(
      {
        expenseId: parseInt(this.props.match.params.id),
        isApproved: isApproved,
      },
      (res) => {
        this.props.history.push($ReplaceRoute(`${AppRoutes.expense}`));
      },
      (err) => {}
    );
  }
  render() {
    const _files = this.state.medias?.filter((item) => !item.externalUrl) || [];
    const _links = this.state.medias?.filter((item) => item.externalUrl) || [];
    const _userRole = store.getState().userInfo.role;
    return (
      <div className="container-fluid research new-research">
        <div className="row"></div>
        <div className="col-12 box-content p-3">
          <div className="d-flex justify-content-between align-items-start">
            <h5 className="b-title d-flex  align-items-center">
              <IoIosArrowDropleftCircle
                className="me-3 textSecondary2 cursorPointer"
                size="1.5em"
                onClick={() => {
                  window.history.back();
                }}
              />{" "}
              {"Expense Profile"}
            </h5>
            <div className="d-flex align-items-center">
              {AccessPermition(this.RoleUser, [
                UserRoles.Admin,
                UserRoles.L1Client,
                UserRoles.L1User,
              ]) ? (
                <CircleIcon
                  width="1.5em"
                  height="1.5em"
                  fontSize="1.08rem"
                  padding="0"
                  type={ThemeCircleIcon.dark}
                  className="mx-1 pointer"
                  onClick={(e) =>
                    this.props.history.push(
                      $ReplaceRoute(
                        `${AppRoutes.expense_edit.replace(
                          ":id",
                          this.props.match.params.id?.toString() ?? ""
                        )}`
                      )
                    )
                  }
                >
                  <FaEdit size='0.8em'/>
                </CircleIcon>
              ) : null}
              {/* <Visible
                visible={this.state.haveDiscussion && this.state.allowedToView}
              >
                <MainButton
                  children="Discussion Panel"
                  type={MainButtonType.dark}
                  borderRadius="24px"
                  fontSize="12px"
                  className="px-3 mx-1"
                  onClick={() => {
                    // if (this.state.subTaskId) {
                    //   return this.props.history.push(
                    //     $ReplaceRoute(
                    //       // topicId 1 0 => setting.discussionTopicEnum[0] : 0, for research
                    //       `${AppRoutes.discussion_new}?topicId=2&sectionId=${this.state.data.id}&parentTask=${this.state.taskId}&subTaskId=${this.state.subTaskId}`
                    //     )
                    //   );
                    // }
                    this.props.history.push(
                      $ReplaceRoute(
                        // topicId 1 0 => setting.discussionTopicEnum[0] : 0, for research
                        `${AppRoutes.discussion_list}?topicId=8&sectionId=${this.props.match.params.id}&parentTask=${this.state.appTaskId}`
                      )
                    );
                  }}
                ></MainButton>
              </Visible> */}
              <Visible visible={this.state.allowedToView}>
                <MainButton
                  children={
                    this.state.haveDiscussion
                      ? "Discussion Panel"
                      : "Create Discussion"
                  }
                  type={MainButtonType.dark}
                  borderRadius="24px"
                  fontSize="14px"
                  minHeight="2em"
                  className="px-3"
                  onClick={() => {
                    if (this.state.haveDiscussion) {
                      this.props.history.push(
                        $ReplaceRoute(
                          // topicId 1 0 => setting.discussionTopicEnum[0] : 0, for research
                          `${AppRoutes.discussion_list}?topicId=8&sectionId=${this.props.match.params.id}&parentTask=${this.state.appTaskId}`
                        )
                      );
                    } else {
                      this.props.history.push(
                        $ReplaceRoute(
                          // topicId 1 0 => setting.discussionTopicEnum[0] : 0, for research
                          `${AppRoutes.discussion_new}?topicId=8&sectionId=${this.props.match.params.id}&parentTask=${this.state.appTaskId}`
                        )
                      );
                    }

                    // if (this.state.subTaskId) {
                    //   return this.props.history.push(
                    //     $ReplaceRoute(
                    //       // topicId 1 0 => setting.discussionTopicEnum[0] : 0, for research
                    //       `${AppRoutes.discussion_new}?topicId=2&sectionId=${this.state.data.id}&parentTask=${this.state.taskId}&subTaskId=${this.state.subTaskId}`
                    //     )
                    //   );
                    // }
                  }}
                ></MainButton>
              </Visible>
            </div>
          </div>
          <div className="Studying p-4 my-2 d-flex align-items-center flex-column">
            <h6 className="text-center">{this.state.categoryTitle} For</h6>
            {this.state.loading ? (
              <Skeleton height="2.1em" width="12em" />
            ) : (
              <h3 className="px-5 text-center fs-4">{this.state.title}</h3>
            )}
            {
            // @ts-ignore
            AccessPermition(_userRole, [
              UserRoles.Admin,
              UserRoles.L1Client,
              UserRoles.L1User,
            ]) && this.state.status === 0 ? (
              <div className="d-flex justify-content-center align-items-center mt-3">
                <MainButton
                  children="Decline"
                  type={MainButtonType.light}
                  borderRadius="24px"
                  fontSize="14px"
                  className="mx-3 px-3"
                  minHeight="34px"
                  backgroundColor="#F5C602"
                  onClick={() => {
                    this.handelCreateExpenseState(false);
                  }}
                ></MainButton>
                <MainButton
                  children="Approve"
                  type={MainButtonType.dark}
                  borderRadius="24px"
                  fontSize="14px"
                  className="mx-3 px-3"
                  minHeight="34px"
                  backgroundColor="#53A501"
                  onClick={() => {
                    this.handelCreateExpenseState(true);
                  }}
                ></MainButton>
              </div>
            ) : null}
          </div>
          <div className="row">
            <div className="col-md-6  tabel-info ">
              <div className="row border-bottom ">
                <h6 className="col-5 t-title mb-0 border-t-l">Expense status</h6>
                <div className="col-7 t-desc border-t-r">
                  {this.state.loading ? (
                    <Skeleton height="1.5em" width="8em" />
                  ) : AccessPermition(this.RoleUser, [
                      UserRoles.L3User,
                      UserRoles.L2User,
                    ]) ? (
                    <div
                      className="s-chip"
                      style={{
                        backgroundColor: EXPENSE_STATUS[this.state.status!].bg,
                        color:
                          EXPENSE_STATUS[this.state.status!].color,
                      }}
                    >
                      {EXPENSE_STATUS[this.state.status!].text}
                    </div>
                  ) : (
                    // <MainButton
                    //   children={this.state.status?.isStatus()}
                    //   type={MainButtonType.light}
                    //   borderRadius="24px"
                    //   fontSize="14px"
                    //   color={
                    //     this.state.status?.isStatus() === "OnHold"
                    //       ? "#FFFFFF"
                    //       : "#474747"
                    //   }
                    //   backgroundColor={this.state.status?.isStatusColor()}
                    // ></MainButton>
                    <div className="d-flex justify-content-start align-items-center">
                      <div
                        className="s-chip"
                        style={{
                          backgroundColor: EXPENSE_STATUS[this.state.status!].bg,
                          color: EXPENSE_STATUS[this.state.status!].color,
                        }}
                      >
                        {EXPENSE_STATUS[this.state.status!].text}
                      </div>
                      {/* <MainButton
                        children={this.state.status?.isStatus()}
                        type={MainButtonType.dark}
                        borderRadius="24px"
                        fontSize="14px"
                        color={
                          this.state.status?.isStatus() === "OnHold"
                            ? "#FFFFFF"
                            : "#474747"
                        }
                        backgroundColor={this.state.status?.isStatusColor()}
                      ></MainButton> */}
                      <p className="mb-0 mx-2">
                        {moment(this.state.date).format(MOMENT_DATE_FORMAT)}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="row border-bottom">
                <h6 className="col-5 t-title mb-0">Associated Task</h6>
                <div className="col-7 t-desc">
                  {this.state.loading ? (
                    <Skeleton height="1.25em" width="6em" />
                  ) : (
                    <Link
                      title={`Go to task profile`}
                      to={$ReplaceRoute(
                        `${AppRoutes.task_profile.replace(
                          ":id",
                          this.state.appTaskId?.toString() ?? ""
                        )}`
                      )}
                      className="linkOnHover"
                    >
                      {this.state.appTaskTitle}
                    </Link>
                  )}
                </div>
              </div>
              <div className="row border-bottom">
                <h6 className="col-5 t-title mb-0">Requested by</h6>
                <div className="col-7 t-desc">
                  {this.state.loading ? (
                    <Skeleton height="1.25em" width="6em" />
                  ) : (
                    <UserWithAvatar 
                      user={this.state.creatorUser}
                    />
                  )}
                    {/* <Link
                      title={`Go to user profile`}
                      to={$ReplaceRoute(
                        `${AppRoutes.member_profile.replace(
                          ":id",
                          this.state.creatorUserId?.toString() ?? ""
                        )}`
                      )}
                      className="linkOnHover"
                    >
                      {this.state.creatorFirstName} {this.state.creatorLastName}
                    </Link> */}
                </div>
              </div>
              <div className="row border-bottom">
                <h6 className="col-5 t-title mb-0">Date submitted</h6>
                <div className="col-7 t-desc">
                  {moment(this.state.date).format(MOMENT_DATE_FORMAT)}
                </div>
              </div>
              <div className="row border-bottom">
                <h6 className="col-5 t-title mb-0">Description </h6>
                <div className="col-7 t-desc">
                  {this.state.loading ? (
                    <Skeleton height="1.25em" width="6em" />
                  ) : this.state.description ? (
                    this.state.description
                  ) : (
                    <EmptyChip />
                  )}
                </div>
              </div>

              <div className="row">
                <h6 className="col-5 t-title mb-0 border-b-l">Receipt Files</h6>
                <div className="col-7 t-desc border-b-r">
                  {this.state.loading ? (
                    <Skeleton height="1.25em" width="8em" />
                  ) : _files?.length === 0 && _links?.length === 0 ? (
                    <EmptyChip />
                  ) : (
                    <ul className="file-list d-flex flex-column align-items-start mb-0 w-100">
                      {_files.map((item) => (
                        <li key={nanoid()} className="max1LineText w-100 pb-1">
                          <File
                            file={item}
                            // linkVariant=""
                          />
                        </li>
                      ))}
                      {_links.length > 0 ? (
                        <li className="mt-2 max1LineText w-100">
                          Shared Links:
                          {_links.map((item) => (
                            <div
                              key={nanoid()}
                              className="pb-1 max1LineText w-100"
                            >
                              <File file={item} />
                            </div>
                          ))}
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3 mt-sm-0 colPaddingSm" style={{ paddingRight: "1.5rem" }}>
              <div className="Amount row">
                <div className="col-3 d-flex justify-content-center align-items-center title">
                  Amount
                </div>
                <div className="col-9 d-flex justify-content-start align-items-center price">
                  {this.state.loading ? (
                    <Skeleton height="1em" width="3em" />
                  ) : (
                    <>${this.state.amount}</>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ExpensePageProfile);
