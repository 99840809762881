/* eslint-disable jsx-a11y/alt-text */
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { MainButton, MainButtonType } from '../../components/button';
import { InputComponent, InputType } from '../../components/inputs';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import { UserController } from '../../../controllers/user/user_controller';
import { UserAccountHandleReq } from '../../../data/models/requests/user/account_req';

import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router-dom';
import { LeftBottomLoginLinks } from '../login/login';
import { SignupBackground } from '../signup/component/signup_background';
import ReactLoading from 'react-loading';
import { AppRoutes } from '../../../core/constants';
import { BaseButton } from '@fluentui/react';
import { base_url_site } from '../../../core/localVariables';

//@ts-ignore
const ResetPage: React.FC<RouteComponentProps> = ({ title, subHeading }) => {
  // const path = window.location.pathname;
  const [email, setemail] = useState('');
  const [loading, setloading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const location = useLocation();
  let history = useHistory();
  console.log(location);
  const controller: UserController = new UserController();

  useEffect(() => {
    document.title = `Reset Your Password | Radvix`;
    if (location.state && location.state.email) {
      setemail(location.state.email);
    }
  }, [location]);
  console.log({ email });
  // async function emailHandler() {
  //   const formValid = Validator.current.allValid();

  //   if (!formValid) {
  //     Validator.current.showMessages();
  //     forceUpdate(1);
  //   } else {
  //     const body: UserAccountHandleReq = {
  //       email: email,
  //     };

  //     setloading(true);
  //     await controller.requestConfirmEmail(
  //       email,
  //       (res) => {
  //         setemail("");
  //         setloading(false);
  //       },
  //       () => {
  //         console.log("error");
  //         setloading(false);
  //       }
  //     );
  //   }
  // }

  const handleLinkSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    // Redirect to the login page
    history.push(AppRoutes.login);
  };
  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault(); // Prevent default form submission behavior
    await resetEmailHandler();
  };
  async function resetEmailHandler() {
    const formValid = Validator.current.allValid();

    if (!formValid) {
      Validator.current.showMessages();
      forceUpdate(1);
    } else {
      const body: UserAccountHandleReq = {
        email: email,
      };
      setloading(true);
      await controller.accountReset(
        body,
        (res) => {
          setemail(email);
          setloading(false);
          setEmailSent(true);
        },
        () => {
          console.log('error');
          setloading(false);
        }
      );
    }
  }

  const Validator = useRef(
    new SimpleReactValidator({
      className: 'text-danger',
    })
  );
  const [, forceUpdate] = useState(0);

  function handleEmailChange(event: ChangeEvent<HTMLInputElement>): void {
    setemail(event.target.value);
  }

  return (
    <div>
      {!emailSent && (
        <div className='container'>
          <div className='max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center vh-100'>
            <div className='text-center mb-3'>
              <img
                src='images/icons/new-radvix-logo.png'
                width={111}
                height={49}
                // src='images/icons/radvix-logo.svg'
                // srcSet='images/icons/radvix-logo.svg 111w'
                // sizes='(max-width: 768px) 20vw, 10vw'
                alt='Company logo'
              />
            </div>
            <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column loginDivBorder'>
              <div className='d-flex w-100'>
                <BaseButton
                  className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
                  style={{ fontWeight: '400', height: '19px' }}
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  Back
                </BaseButton>
                <div className='verifyingAccount'>
                  <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>
                    Forgot Password
                  </h1>
                </div>
              </div>
              <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
              <div style={{ padding: '6px 30px 6px 38px' }}>
                <span className='d-flex fs-15 color-gray-600' style={{ textAlign: 'center' }}>
                  {' '}
                  Enter your email and we will send you a link to reset your password.
                </span>
              </div>
              <div className='w-100 mb-px-20'>
                <div className='mb-1 text-start'>
                  <label className='color-gray-600 fs-15 me-2'>Email</label>
                </div>
                <input
                  className='form-control h-px-45'
                  placeholder='Enter your email address'
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              <button
                className='d-flex justify-content-center align-items-center btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton'
                type='submit'
                onClick={handleSubmit}
              >
                {' '}
                {loading ? (
                  <ReactLoading className='d-flex' type='spin' height='auto' width={20} />
                ) : (
                  'Continue'
                )}{' '}
              </button>
              <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
              <div className='fs-14 fw-normal'>
                <a
                  className='color-gray-600 cursor-pointer'
                  href={`${base_url_site}/terms-of-services`}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Terms of Service |{' '}
                </a>
                <a
                  className='color-gray-600 cursor-pointer'
                  href={`${base_url_site}/privacy-policy`}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
        //   <div
        //   className="form-container"
        //   style={{
        //     background:
        //       "linear-gradient(360deg, rgba(212, 15, 237, 0) 0%, rgba(212, 15, 237, 1) 100%)",
        //   }}
        // >
        //   <h2 className="text-center P_Heading mb-4">Forgot Password?</h2>
        //   <form onSubmit={handleSubmit}>
        //     <p style={{ textAlign: "center" }}>
        //       Enter your email and we will send you a link to reset your
        //       password
        //     </p>
        //     <div className="mt-3  mb-2 card_Holder_label">Email: </div>
        //     <div className="">
        //       <input
        //         name="name"
        //         onChange={handleEmailChange}
        //         className="Payemnt_Card_input w-100"
        //         placeholder="Your Email"
        //         value={email}
        //       />
        //     </div>
        //     <div></div>
        //     <div>
        //       <button className="Payment_Button mt-4 w-100" type="submit">
        //         {loading ? (
        //           <ReactLoading
        //             className="d-flex"
        //             type="spin"
        //             height="auto"
        //             width={20}
        //           />
        //         ) : (
        //           "Continue"
        //         )}
        //       </button>
        //       <p
        //         style={{
        //           paddingTop: "20px",
        //           textAlign:"center",
        //           marginBottom: "0px",
        //         }}
        //       >
        //         <span className="span" style={{fontSize:"14px"}}>Back to login page?</span>
        //         <span
        //           className="text-wrapper-6"
        //           style={{fontSize:"14px"}}
        //           onClick={() => {
        //           history.push(AppRoutes.login);
        //           }}
        //         >
        //           {" "}
        //           Login
        //         </span>
        //       </p>
        //     </div>
        //   </form>
        // </div>
      )}
      {emailSent && (
        <div className='container'>
          <div className='max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center vh-100'>
            <div className='text-center mb-3'>
              <img
                src='images/icons/new-radvix-logo.png'
                width={111}
                height={49}
                // src='images/icons/radvix-logo.svg'
                // srcSet='images/icons/radvix-logo.svg 111w'
                // sizes='(max-width: 768px) 20vw, 10vw'
                alt='Company logo'
              />
            </div>
            <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column loginDivBorder'>
              <div className='d-flex w-100'>
                <BaseButton
                  className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
                  style={{ fontWeight: '400', height: '19px' }}
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  Back
                </BaseButton>
                <div className='verifyingAccount'>
                  <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>Request Sent</h1>
                </div>
              </div>
              <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
              <div style={{ padding: '6px 0px 6px 0px' }}>
                <h4
                  className='fs-15 color-gray-600'
                  style={{
                    textAlign: 'center',
                    fontSize: '13px',
                    fontWeight: '400',
                  }}
                >
                  Please check your email. We sent a password reset link to{' '}
                  <a href='#' className='text-wrapper-6'>
                    {email}
                  </a>
                </h4>
              </div>
              <p
                style={{
                  paddingTop: '10px',
                  textAlign: 'center',
                  marginBottom: '0px',
                  paddingBottom: '20px',
                }}
              >
                <span className='span' style={{ fontSize: '14px', fontWeight: '500' }}>
                  Didn't receive the email?
                </span>
                <span
                  className='text-wrapper-6'
                  style={{
                    fontSize: '14px',
                    color: '#5746AF',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    resetEmailHandler();
                  }}
                >
                  {' '}
                  Click to resend
                </span>
              </p>
              <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
              <div className='fs-14 fw-normal'>
                <a
                  className='color-gray-600 cursor-pointer'
                  href={`${base_url_site}/terms-of-services`}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Terms of Service |{' '}
                </a>
                <a
                  className='color-gray-600 cursor-pointer'
                  href={`${base_url_site}/privacy-policy`}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
        //   <div
        //   className="form-container"
        //   style={{
        //     maxWidth:"390px",
        //     background:
        //       "linear-gradient(360deg, rgba(212, 15, 237, 0) 0%, rgba(212, 15, 237, 1) 100%)",
        //   }}
        // >
        //   <div
        //           className="d-flex flex-column justify-content-center"
        //           style={{ alignItems: "center", paddingTop: "13px" }}
        //         >
        //           <svg
        //             xmlns="http://www.w3.org/2000/svg"
        //             width="78"
        //             height="29"
        //             viewBox="0 0 78 29"
        //             fill="none"
        //           >
        //             <path
        //               fill-rule="evenodd"
        //               clip-rule="evenodd"
        //               d="M75.5561 4.71586L64.5351 14.2909L75.5561 23.7567V4.71586ZM54.2039 19.272L50.3337 15.9125L38.5745 26.0143H74.4255L62.6609 15.9107L58.7907 19.2702L58.7961 19.2666C58.1815 19.7862 57.3896 20.0998 56.5224 20.0998H56.4991H56.4686C55.6032 20.0998 54.8113 19.788 54.2039 19.272ZM74.447 2.44573H38.5584L55.7985 17.4175L55.7967 17.4157C55.9907 17.5703 56.2314 17.6543 56.4794 17.654H56.5027H56.5278C56.7749 17.6542 57.0148 17.5708 57.2086 17.4175L74.447 2.44573ZM48.4649 14.2891L37.4457 4.71407V23.7495L48.4649 14.2891ZM74.6763 0C75.212 0 75.7173 0.125422 76.1473 0.340431H76.1366L76.1563 0.34939C77.2493 0.892287 78 2.02108 78 3.32368V25.1328C77.9991 26.0154 77.648 26.8616 77.0239 27.4857C76.3998 28.1098 75.5536 28.4609 74.6709 28.4618H38.3291C37.4464 28.4609 36.6002 28.1098 35.9761 27.4857C35.352 26.8616 35.0009 26.0154 35 25.1328V3.32368C35.0005 2.7035 35.1744 2.09582 35.5021 1.56929C35.8298 1.04276 36.2982 0.61835 36.8545 0.344014L36.8347 0.352973C37.2994 0.120962 37.8115 0.000124051 38.3308 0H74.6763ZM10 8C9.44769 8 9 8.44771 9 9C9 9.55229 9.44769 10 10 10H30C30.5523 10 31 9.55229 31 9C31 8.44771 30.5523 8 30 8H10ZM10 20C9.44769 20 9 20.4477 9 21C9 21.5523 9.44769 22 10 22H30C30.5523 22 31 21.5523 31 21C31 20.4477 30.5523 20 30 20H10ZM0 15C0 14.4477 0.447693 14 1 14H21C21.5523 14 22 14.4477 22 15C22 15.5523 21.5523 16 21 16H1C0.447693 16 0 15.5523 0 15Z"
        //               fill="url(#paint0_linear_478_279)"
        //             />
        //             <defs>
        //               <linearGradient
        //                 id="paint0_linear_478_279"
        //                 x1="-1.33311e-07"
        //                 y1="2.5593"
        //                 x2="83.1457"
        //                 y2="14.1559"
        //                 gradientUnits="userSpaceOnUse"
        //               >
        //                 <stop stop-color="#4DA4F9" />
        //                 <stop offset="0.47" stop-color="#7523FE" />
        //                 <stop offset="1" stop-color="#ED0AE8" />
        //               </linearGradient>
        //             </defs>
        //           </svg>
        //         </div>
        //   <h2 className="text-center P_Heading mb-4">Link Sent!</h2>
        //   <form onSubmit={handleLinkSubmit}>
        //     <p style={{ textAlign: "center", marginBottom:"0px"}}>
        //     Please Check Your Email. We sent a
        //     </p>
        //     <div style={{ textAlign: "center", marginBottom:"0px"}}>
        //     password reset link to <a href="#" className="text-wrapper-6">{email}</a>
        //     </div>
        //     <div>
        //       <button className="Payment_Button mt-4 w-100" type="submit">
        //         {loading ? (
        //           <ReactLoading
        //             className="d-flex"
        //             type="spin"
        //             height="auto"
        //             width={20}
        //           />
        //         ) : (
        //           "Login"
        //         )}
        //       </button>
        //       <p
        //         style={{
        //           paddingTop: "20px",
        //           textAlign:"center",
        //           marginBottom: "0px",
        //         }}
        //       >
        //         <span className="span"style={{fontSize:"14px"}}>Didn't receive the email?</span>
        //         <span
        //           className="text-wrapper-6"
        //           style={{fontSize:"14px"}}
        //           onClick={() => {
        //             resetEmailHandler()
        //           }}
        //         >
        //           {" "}
        //           Click to resend
        //         </span>
        //       </p>
        //     </div>
        //   </form>
        // </div>
      )}
    </div>

    // <div className="login d-flex flex-column flex-md-row">
    //   <div className="left">
    //     <div className="left_logo_login">
    //       <img src="/images/images/radvix_logo.svg" className="logo" alt="" />
    //       <img
    //         src="/images/images/img_avatar.png"
    //         className="logo-Member dNoneTablet"
    //         alt=""
    //       />
    //     </div>
    //     <LeftBottomLoginLinks />
    //   </div>
    //   <div className="right" style={{maxWidth: 430}}>
    //     <h1 className="right_login_title">Reset Your Password</h1>
    //     <p className="right_login_subHeading">
    //     To reset your password, simply provide us with your email address. Once you click 'Send Link', we'll send you a link with instructions on how to reset your password.
    //     </p>
    //     <div className="w-100 ">
    //       <label className="text-light login_input_label">Email:</label>
    //       <InputComponent
    //         type={InputType.text}
    //         // placeholder="example@email.com bymani "
    //         onChange={(e) => {
    //           setemail(e.target.value);
    //         }}
    //         fontSize="16px"
    //         inValid={Validator.current.message(
    //           "email",
    //           email,
    //           "required|email"
    //         )}
    //         value={email}
    //       ></InputComponent>

    //       <div className="password mt-2 d-flex justify-content-center">
    //         <MainButton
    //           children={"Send Link"}
    //           type={MainButtonType.light}
    //           borderRadius="50px"
    //           minWidth="75px"
    //           minHeight="37px"
    //           fontSize="16px"
    //           fontWeight={700}
    //           className="py-2 px-4 mt-3"
    //           loading={loading}
    //           onClick={() => {
    //             resetEmailHandler()
    //           }}
    //         ></MainButton>
    //       </div>
    //     </div>
    //     <div className="text-center fs-300 mt-4">
    //       <p style={{ color: "rgba(255,255,255,0.64)" }}>
    //         Go Back to the{" "}
    //         <Link to="/login" style={{ color: "#fff" }}>
    //           Login
    //         </Link>{" "}
    // or{" "}
    //<Link to="/register" style={{ color: "#fff" }}>
    //Register
    //</Link>{" "}
    //for a New Account{" "} */}
    //   </p>
    // </div>
    //<div className="reset_screen_footer_detail">
    //<p>
    // Go Back to the <Link to="/login">Login</Link> or{" "}
    //<Link to="/register">Register</Link> for a New Account{" "}
    //</p>
    //</div>
    //   </div>
    // </div>
  );
};
export default withRouter(ResetPage);
