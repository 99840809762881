import React, { useEffect, useState } from 'react';
import { worSpaceController } from '../../../controllers/workSpace/workSpace';
import { HTTP } from '../../../core/http_common';
import { toast } from 'react-toastify';
import { useHistory, withRouter } from 'react-router';
import { AppRoutes } from '../../../core/constants';
import ReactLoading from 'react-loading';
import './workSpace.css';
import { SignupBackground } from '../signup/component/signup_background';
import { UserController } from '../../../controllers/user/user_controller';
import { store } from '../../../data/storeMain';
import { SetUserInfo, SetUserRole } from '../../../data/storeMain/actions/user_action';
import { BaseButton } from '@fluentui/react';
import { base_url_site } from '../../../core/localVariables';
import { SetHeaderTitle } from '../../../data/storeMain/actions/header_title_action';
interface LinkConfirmationProps {
  history?: any;
  location?: any;
  match?: any;
}
const WorkSpace: React.FC<LinkConfirmationProps> = (props) => {
  const controller: worSpaceController = new worSpaceController();
  const userController: UserController = new UserController();
  const [workSpace, setWorkSpace] = useState<string>('');
  const [error, setError] = useState(false);
  const [loading, setloading] = useState(false);
  const [UserId, setUserId] = useState<string | null>(null);
  const token = props.location.search.split('&')[0]?.split('=')[1];
  const userId = props.location.search.split('&')[1]?.split('=')[1];
  document.title = `Radvix | Workspace`;
  store.dispatch(SetHeaderTitle('Workspace Management'));
  let history = useHistory();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWorkSpace(e.target.value);
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    try {
      const workspaceData = {
        Name: workSpace,
        Description: '',
        WorkspaceId: '',
        UserId: UserId ? UserId : '',
      };
      setloading(true);

      await controller.CreateWorkSpace(
        workspaceData,
        (res) => {
          setloading(false);
          history.push(AppRoutes.UserSubscriptions);
        },
        (err) => {
          setloading(false);
          setError(true);
          toast.error(err.response.data.message);
        }
      );
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  };

  return (
    <>
      <div className='container'>
        <div className='max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center vh-100'>
          <div className='text-center mb-3'>
            <img
              src='images/icons/new-radvix-logo.png'
              width={111}
              height={49}
              // src='images/icons/radvix-logo.svg'
              // srcSet='images/icons/radvix-logo.svg 111w'
              // sizes='(max-width: 768px) 20vw, 10vw'
              alt='Company logo'
            />
          </div>
          <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column loginDivBorder'>
            <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>Create workspace</h1>
            <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
            <div className='w-100 mb-px-20'>
              <div className='mb-1 text-start'>
                <label className='color-gray-600 fs-15 me-2'>Workspace name</label>
              </div>
              <input
                className={`form-control h-px-45 ${error ? 'workspaceError' : ''}`}
                style={{ outline: 'none', width: '331px' }}
                type='text'
                id='workspace'
                name='workSpace'
                readOnly={false}
                disabled={false}
                onChange={handleChange}
                placeholder='Enter your workspace name'
                required
              />
            </div>
            <button
              className='d-flex justify-content-center align-items-center btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton'
              type='submit'
              onClick={handleSubmit}
            >
              {' '}
              {loading ? (
                <ReactLoading className='d-flex' type='spin' height='auto' width={20} />
              ) : (
                'Continue'
              )}{' '}
            </button>
            <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
            <div className='fs-14 fw-normal'>
              <a
                className='color-gray-600 cursor-pointer'
                href={`${base_url_site}/terms-of-services`}
                rel='noopener noreferrer'
                target='_blank'
              >
                Terms of Service |{' '}
              </a>
              <a
                className='color-gray-600 cursor-pointer'
                href={`${base_url_site}/privacy-policy`}
                rel='noopener noreferrer'
                target='_blank'
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <SignupBackground>
        <div
          className="WorkSpace_Div"
          style={{
            background:
              "linear-gradient(360deg, rgba(212, 15, 237, 0) 0%, rgba(212, 15, 237, 1) 100%)",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <h2 className="mt-3 workspaceText">Work Space ID</h2>
          <div
            className="w-100 d-grid gap-2 mt-4"
            style={{ textAlign: "start", paddingLeft: "12px" }}
          >
            <form onSubmit={handleSubmit}>
              <div style={{ paddingTop: "6px" }}>
                <label
                  htmlFor="email"
                  className="worSpace_label"
                  style={{ paddingBottom: "4px" }}
                >
                  Workspace name:
                </label>
                <input
                  className={`SignupInput-Email ${
                    error ? "workspaceError" : ""
                  }`}
                  style={{ outline: "none", width: "331px" }}
                  type="text"
                  id="workspace"
                  name="workSpace"
                  readOnly={false}
                  disabled={false}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="ERROR_DIV">
                {error && (
                  <span style={{ color: "red" }} className="error-text">
                    Workspace already exist choose a different one
                  </span>
                )}
              </div>
              <div style={{ width: "331px" }}>
                <button className="WorkSpace_Button w-100" type="submit">
                  {loading ? (
                    <ReactLoading
                      className="d-flex"
                      type="spin"
                      height="auto"
                      width={20}
                    />
                  ) : (
                    "Next"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </SignupBackground> */}
      {/* <footer>
            <div className=''>
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/a952160485198003d9b67d5ec1bd6e959126d9f5b16028917165801885ecde74?apiKey=fe8cbab1c27b4f469d664c4f9a510dfd&"
                    className="img-fluid"
                    alt="Footer"
                    style={{bottom:0,left:0,width:'100%'}}
                />
            </div>
        </footer> */}
    </>
  );
};

export default withRouter(WorkSpace);
