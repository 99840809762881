import React, { useEffect, useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { ResearchController } from "../../../../../controllers/research/research_controller";
import { TaskController } from "../../../../../controllers/task/task_controller";
import { DataController } from "../../../../../controllers/data/data_controller";
import { UserController } from "../../../../../controllers/user/user_controller";
import { TeamController } from "../../../../../controllers/team/team_controller";
import { store } from "../../../../../data/storeMain";

interface BreadCrumbItem {
  label: any;
  active: boolean;
}

interface BreadCrumbsProps {
  paths: BreadCrumbItem[];
}

export const BreadCrumbs = ({ profileId, profileType, handler }: any) => {
  const [visible, setVisible] = useState(true);
  const [path, setPath] = useState<any>([]);

  const handleCloseClick = () => {
    setVisible(false); // Hide the breadcrumbs
  };
  var researchController = new ResearchController();
  useEffect(() => {
    if (profileId && profileType) {
      const id: number = Number(profileId);
      researchController.getBreadCrumbs(
        { profileType: profileType, profileId: id },
        (res: any) => {
          var data = [];
          data.push({
            label: store.getState().userInfo.workSpaceName,
            active: true,
          });
          if (res && res.Research) {
            data.push({
              label: res.Research,
              active: true,
            });
          }
          if (res && res.TaskProfile) {
            data.push({
              label: res.TaskProfile,
              active: true,
            });
          }
          if (res && res.DataProfile) {
            data.push({
              label: res.DataProfile,
              active: true,
            });
          }
          if (res && res.PublicationFlow) {
            data.push({
              label: res.PublicationFlow,
              active: true,
            });
          }
          if (res && res.LaboratoryProfile) {
            data.push({
              label: res.LaboratoryProfile,
              active: true,
            });
          }
          setPath(data);
        },
        (err: any) => {
          console.error("Error fetching research Data:", err);
        }
      );
    }
  }, [profileId]);
  return (
    <>
    {path && path.length > 0 && <>
      <button
        onClick={() => { setPath([]); handler()}}
        style={{ border: "none", background: "transparent", cursor: "pointer", display:"flex" }}
      >
        ✖
      </button>
      <nav className="breadCrumbs-bootstrap" aria-label="breadcrumb">
        <ol className="breadcrumb">
          {path.map((path: any, index: any) => (
            <li
              className={`breadcrumb-item ${
                index === path.length - 1 ? "active" : ""
              }`}
            >
              {path.label}
            </li>
          ))}
        </ol>
      </nav>
      </>
}
    </>
  );
};
