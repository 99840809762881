import { toast } from "react-toastify";
import { HTTP } from "../../core/http_common";
import { SortType } from "../../core/number_extentions";

export type UserType = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  image: string;
  invitationStatus: number;
};
export type MediaType = {
  id: number;
  name: string;
  title: string;
  externalUrl: string;
  useCase: number;
  inputDataType: number;
};
export interface GetMeetingNoteSearchReqRes {
  id: number;
  title: string;
  users: UserType[];
  subTeams: {
    id: number;
    title: string;
    users: UserType[];
  }[];
}

export type MeetNoteType = {
  id: number;
  title: string;
  createdAt: string;
  team: {
    id: number;
    title: string;
  };
  subTeam: {
    id: number;
    title: string;
  };
  isMainTeam: boolean;
  isSubTeamsHaveAccess: boolean;
  users: UserType[];
  media: MediaType;
  meetingNoteContent?: string | null;
};
export interface GetMeetingListReqRes {
  meetingNotes: MeetNoteType[];
  count: 0;
}
export interface CreateMeetingNoteReqBody {
  teamId: number;
  title: string;
  // isMainTeam: boolean;
  // isSubTeamsHaveAccess: boolean;
  isMainOrSubTeamsHaveAccess: boolean;
  usersIds: string[];
}
export interface GetMeetingListReqBody {
  TeamId: number;
  // From: string;
  // To: string;
  PageNumber: number;
  PageSize: number;
  ColumnName?: string,
  SortDirection?: SortType
}
export interface GetMeetingNoteByIdReqBody {
  meetingNoteId: number;
  teamId: number;
}
export interface CreateMeetingNoteReqRes {
  id: number;
}
export function createMeetingNote(
  _body: CreateMeetingNoteReqBody
): Promise<CreateMeetingNoteReqRes> {
  return new Promise((resolve, reject) => {
    HTTP.post("/Team/CreateMeetingNote", _body)
      .then((_res) => {
        toast.success(
          _res.data.message
            ? _res.data.message
            : "Meeting note created successfully"
        );
        resolve(_res.data.result);
      })
      .catch((_err) => {
        if (_err.response?.data.message)
          toast.error(`${_err.response?.data.message}`);
        reject(_err);
      });
  });
}

export function getMeetingNoteList(
  _body: GetMeetingListReqBody
): Promise<GetMeetingListReqRes> {
  return new Promise((resolve, reject) => {
    // const _query = `LaboratoryId=${_body.LaboratoryId}&EquipmentId=${_body.EquipmentId}&From=${_body.From}&To=${_body.To}&IsUsersAllLabsEquipmentsReservation=${_body.IsUsersAllLabsEquipmentsReservation}&IsUsersAllEquipmentsReservation=${_body.IsUsersAllEquipmentsReservation}`;
    let _query = ``;
    if (_body.TeamId) {
      _query += `TeamId=${_body.TeamId}&`;
    }
    // if (_body.From) {
    //   _query += `From=${_body.From}&`;
    // }
    // if (_body.To) {
    //   _query += `To=${_body.To}&`;
    // }
    if (_body.PageNumber) {
      _query += `PageNumber=${_body.PageNumber}&`;
    }
    if (_body.PageSize) {
      _query += `PageSize=${_body.PageSize}&`;
    }
    if (_body.ColumnName) {
      _query += `ColumnName=${_body.ColumnName}&`
    }

    if (_body.SortDirection) {
      _query += `SortDirection=${_body.SortDirection}`
    }
    else {
      _query += `SortDirection=${SortType.AutoSelect}`
    }
    HTTP.get(`/Team/MeetingNoteList?${_query}`)
      .then((_res) => {
        if (_res.data && _res.data.result) {
          resolve(_res.data.result);
        } else {
          resolve({
            meetingNotes: [],
            count: 0,
          });
        }
      })
      .catch((_err) => {
        if (_err.response?.data.message)
          toast.error(`${_err.response?.data.message}`);
        reject(_err);
      });
  });
}
export function getMeetingNoteById(
  _body: GetMeetingNoteByIdReqBody
): Promise<MeetNoteType | null> {
  return new Promise((resolve, reject) => {
    // const _query = `LaboratoryId=${_body.LaboratoryId}&EquipmentId=${_body.EquipmentId}&From=${_body.From}&To=${_body.To}&IsUsersAllLabsEquipmentsReservation=${_body.IsUsersAllLabsEquipmentsReservation}&IsUsersAllEquipmentsReservation=${_body.IsUsersAllEquipmentsReservation}`;
    let _query = ``;
    if (_body.meetingNoteId) {
      _query += `meetingNoteId=${_body.meetingNoteId}&`;
    }
    if (_body.teamId) {
      _query += `teamId=${_body.teamId}&`;
    }

    HTTP.get(`/Team/MeetingNoteById?${_query}`)
      .then((_res) => {
        if (_res.data && _res.data.result) {
          resolve(_res.data.result);
        } else {
          resolve(null);
        }
      })
      .catch((_err) => {
        if (_err.response?.data.message)
          toast.error(`${_err.response?.data.message}`);
        reject(_err);
      });
  });
}

export function getMeetingNoteSearch(_body: {
  teamId: string;
}): Promise<GetMeetingNoteSearchReqRes> {
  return new Promise((resolve, reject) => {
    let _query = ``;
    if (_body.teamId) {
      _query += `teamId=${_body.teamId}&`;
    }
    HTTP.get(`/Team/MeetingNoteSearch?${_query}`)
      .then((_res) => {
        resolve(_res.data.result);
      })
      .catch((_err) => {
        // toast.error("Something went wrong while geting reservation options");
        if (_err.response?.data.message)
          toast.error(`${_err.response?.data.message}`);
        reject(_err);
      });
  });
}
