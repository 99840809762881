import { handleUnauthorizedRequest, HTTP } from "../../../core/http_common";
import { SortType } from "../../../core/number_extentions";
import { IFilterOptions } from "../../../views/components/FilterSection/IFilterOptions";
import { CreateMemberReq } from "../../models/requests/member/create_member_req";
import {
  ResendInvatationReq,
  UpdateMemberReq,
} from "../../models/requests/member/update_member_req";
import { CreateMemberRes } from "../../models/responses/member/create_member_res";
import { GetMemberByIDRes } from "../../models/responses/member/get_member_by_id_res";
import { InviteInfoGet } from "../../models/responses/member/Invite_info_res";
import { MemberListRes } from "../../models/responses/member/member_list_res";
import { SearchMemberRes } from "../../models/responses/member/search_member_res";
import { UpdateMemberRes } from "../../models/responses/member/update_member_res";

export class RemoteMember {
  createMember(
    body: CreateMemberReq,
    action: (res: CreateMemberRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/Team/CreateMember", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  updateMember(
    body: UpdateMemberReq,
    action: (res: UpdateMemberRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/Team/UpdateMember", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  SearchMember(
    action: (res: SearchMemberRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(`/Team/SearchMember`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getMemberList(
    body: { PageNumber: number; PageSize: number; SearchParameter: string ; columnHeaderName?: string; sortDirection?: SortType,filterOptions?: IFilterOptions},
    action: (res: MemberListRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(
      `/Team/MemberList?PageSize=${body.PageSize}&PageNumber=${body.PageNumber}&SearchParameter=${body.SearchParameter}&ColumnName=${body.columnHeaderName}&SortDirection=${body.sortDirection}&FilterOptions=${body.filterOptions}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getMemberById(
    body: { userId: string; token: string },
    action: (res: GetMemberByIDRes) => any,
    error: (res: any) => any
  ) {
    let config = {
      headers: {
        authorization: `Bearer ${body.token}`,
      },
    };
    const requestbody: any = {
      userId: body.userId
    }
    return HTTP.post("/Team/MemberById",requestbody, config)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getInviteInfo(
    body: { userId: string; token: string },
    action: (res: InviteInfoGet) => any,
    error: (res: any) => any
  ) {
    let config = {
      headers: {
        authorization: `Bearer ${body.token}`,
      },
    };
    return HTTP.get(`/Invitation?userId=${body.userId}`, config)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  sendInvatation(
    body: ResendInvatationReq,
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/Invitation", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  resendInvatation(
    body: ResendInvatationReq,
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.put("/Invitation", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  updateUserPassword(
    body: { email: string; newPassword: string },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/User/ResetPassword", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  updateUserEmail(
    body: { userId: string; email: string },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.post(
      `/User/Account/UpdateUserEmail/${body.userId}/${body.email}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
}
