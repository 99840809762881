import { BaseButton } from '@fluentui/react';

export default function BackButtonTitle({ title }: { title: string }) {
  return (
    <div className='d-flex flex-wrap row-gap-3 align-items-center'>
      <BaseButton
        className='d-flex align-items-center btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12'
        style={{ fontWeight: '400', height: '19px' }}
        onClick={() => {
          window.history.back();
        }}
      >
        Back
      </BaseButton>
      <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>{title}</h2>
    </div>
  );
}
