import { ReactChild, ReactFragment, ReactPortal, useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import * as Dialog from '@radix-ui/react-dialog';
import { UserController } from "../../../../controllers/user/user_controller";
import {
  AccessPermition,
  getUserRole,
  UserRoles,
} from "../../../../core/utils";
import { MdAdd } from "react-icons/md";
import { MainButton } from "../../../components/button";
import CollapsableTable from "../../../components/CollapsableTable/CollapsableTable";
import { InputComponent, InputType } from "../../../components/inputs";
import DOMPurify from "dompurify";
import { BaseButton } from "@fluentui/react";
import RadixTable from "../../../components/NewRadixTable/RadixTable";
import { Table } from "@radix-ui/themes";

export const UserTypesTable = ({setOpenAddRoleModal,openAddRoleModal}: any) => {
  const controller = new UserController();
  const [Type, setType] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [SelectLevel, setSelectLevel] = useState(0);
  const [EditId, setEditId] = useState(0);
  const [, forceUpdate] = useState(0);
  const [Editable, setEditable] = useState(false);
  const [editRole, setEditRole] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<any[]>([]);
  const RoleUser = getUserRole();

  useEffect(() => {
    getUserTypes();
  }, []);

  const Validator = useRef(
    new SimpleReactValidator({
      className: "text-danger",
    })
  );

  const getUserTypes = () => {
    setLoading(true);
    controller.getUserType(
      (res) => {
        // Extract unique titles from the response
        const uniqueTitles = Array.from(new Set(res.map(item => item.title)));
  
        // Helper function to sum users by their title
        const countUsersByTitle = (title: any) => {
          return res.find(item => item.title === title)?.countUser || 0;
        };
  
        // Helper function to get IDs by their title
        const getIdsByTitle = (title: any) => {
          return res.filter(item => item.title === title).map(item => item.id);
        };
  
        // Define titles and levels dynamically based on unique titles
        const titlesAndLevels = uniqueTitles.map(title => ({
          title: title,
          level: res.find(item => item.title === title)?.userRoleEnum || 3, // Adjust the default level as needed
        }));
  
        let _list = [];
  
        // Example access permissions check
        if (AccessPermition(RoleUser, [UserRoles.Admin, UserRoles.L1Client, UserRoles.L1User])) {
          titlesAndLevels.forEach(({ title, level }) => {
            _list.push({
              id: getIdsByTitle(title),
              title: title,
              countUser: countUsersByTitle(title),
              userTypes: res.filter((item) => item.userRoleEnum === level),
              level: level,
            });
          });
        }
  
        // Example additional permission check
        if (AccessPermition(RoleUser, [UserRoles.L2User])) {
          _list.push({
            id: getIdsByTitle("Researcher"), // Example hard-coded title
            title: "Researcher", // Example hard-coded title
            countUser: countUsersByTitle("Researcher"), // Example hard-coded title
            userTypes: res.filter((item) => item.userRoleEnum === 4), // Example hard-coded level
            level: 4, // Example hard-coded level
          });
        }
  
        setList(_list);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        // Handle error
      }
    );
  };
  

  const addUserType = () => {;
    const formValid = Validator.current.allValid();
    if (!formValid) {
      Validator.current.showMessages();
      forceUpdate(1);
    } else {
      controller.createUserType(
        { title: Type, userRoleEnum: 2 },
        (res) => {
          setType("");
          getUserTypes();
          setOpenModel(false);
          openAddRoleModal(false);
          setEditable(false);
          setEditRole(false);
        },
        () => { }
      );
      setEditRole(false);
      setEditable(false);
      setOpenModel(false)
    }
  };

  const editUserType = () => {
    const formValid = Validator.current.allValid();
    if (!formValid) {
      Validator.current.showMessages();
      forceUpdate(1);
    } else {
      controller.updateUserType(
        { title: Type, id: EditId },
        (res) => {
          setType("");
          getUserTypes();
          document.getElementById("close_modal_Type")?.click();
        },
        () => { }
      );
      setEditRole(false);
      setEditable(false);
    }
  };

  const headerClick = (columnHeaderName?: string, sortDirection?: string) => {};


  return (
    <div>
      <RadixTable
        loading={loading}
        onHeaderClick={(columnHeaderName: string | undefined, sortDirection: string | undefined) => headerClick(columnHeaderName, sortDirection)}
        columns={[
          {
            Header: "Role labels",
            accessor: "title",
            Cell: (_row: { title: any; }) => 
              {
                return(
                <Table.Cell className="p-3">
    <div className="line-clamp-2 textWrapLabel"style={{fontWeight:"400"}}>
    {_row.title}
    </div>
  </Table.Cell>)}},
          {
            Header: "Current User",
            accessor: "countUser",
             Cell: (_row: { countUser: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; }) => {
              return(
              <Table.Cell className="p-3">
                <div className="line-clamp-2 textWrapLabel"style={{fontWeight:"400"}}>
                {_row.countUser}
                </div>
              </Table.Cell>)}},
          {Header: "",
            accessor: "id",
            Cell: (_row: any) => {
              return (
                <Table.Cell className="p-3">
                <div className="line-clamp-2 textWrapLabel"style={{fontWeight:"400"}}>
                <MainButton
                    data-bs-toggle="modal"
                    data-bs-target="#modal-add-type"
                    className="btn hover-fill btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0"
                    svg={<svg
                      className="hover-fill"
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <rect
                        width="15"
                        height="15"
                        fill="white"
                        fillOpacity="0.01"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.1464 1.14645C12.3417 0.951183 12.6583 0.951183 12.8535 1.14645L14.8535 3.14645C15.0488 3.34171 15.0488 3.65829 14.8535 3.85355L10.9109 7.79618C10.8349 7.87218 10.7471 7.93543 10.651 7.9835L6.72359 9.94721C6.53109 10.0435 6.29861 10.0057 6.14643 9.85355C5.99425 9.70137 5.95652 9.46889 6.05277 9.27639L8.01648 5.34897C8.06455 5.25283 8.1278 5.16507 8.2038 5.08907L12.1464 1.14645ZM12.5 2.20711L8.91091 5.79618L7.87266 7.87267L8.12731 8.12732L10.2038 7.08907L13.7929 3.5L12.5 2.20711ZM9.99998 2L8.99998 3H4.9C4.47171 3 4.18056 3.00039 3.95552 3.01877C3.73631 3.03668 3.62421 3.06915 3.54601 3.10899C3.35785 3.20487 3.20487 3.35785 3.10899 3.54601C3.06915 3.62421 3.03669 3.73631 3.01878 3.95552C3.00039 4.18056 3 4.47171 3 4.9V11.1C3 11.5283 3.00039 11.8194 3.01878 12.0445C3.03669 12.2637 3.06915 12.3758 3.10899 12.454C3.20487 12.6422 3.35785 12.7951 3.54601 12.891C3.62421 12.9309 3.73631 12.9633 3.95552 12.9812C4.18056 12.9996 4.47171 13 4.9 13H11.1C11.5283 13 11.8194 12.9996 12.0445 12.9812C12.2637 12.9633 12.3758 12.9309 12.454 12.891C12.6422 12.7951 12.7951 12.6422 12.891 12.454C12.9309 12.3758 12.9633 12.2637 12.9812 12.0445C12.9996 11.8194 13 11.5283 13 11.1V6.99998L14 5.99998V11.1V11.1207C14 11.5231 14 11.8553 13.9779 12.1259C13.9549 12.407 13.9057 12.6653 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.6653 13.9057 12.407 13.9549 12.1259 13.9779C11.8553 14 11.5231 14 11.1207 14H11.1H4.9H4.87934C4.47686 14 4.14468 14 3.87409 13.9779C3.59304 13.9549 3.33469 13.9057 3.09202 13.782C2.7157 13.5903 2.40973 13.2843 2.21799 12.908C2.09434 12.6653 2.04506 12.407 2.0221 12.1259C1.99999 11.8553 1.99999 11.5231 2 11.1207V11.1V4.9V4.87935C1.99999 4.47689 1.99999 4.14468 2.0221 3.87409C2.04506 3.59304 2.09434 3.33469 2.21799 3.09202C2.40973 2.71569 2.7157 2.40973 3.09202 2.21799C3.33469 2.09434 3.59304 2.04506 3.87409 2.0221C4.14468 1.99999 4.47685 1.99999 4.87932 2H4.9H9.99998Z"
                        fill="#5746AF"
                      />
                    </svg>}
                    onClick={() => {;
                      setEditId(_row.id[0]);
                      setEditable(true);
                      setType(_row.title);
                      setOpenModel(true);
                    } }
                  >
                    
                    Edit Label
                  </MainButton>
                </div>
              </Table.Cell>
                  
              );
            },
          },
        ]}

        childDataKey="userTypes"
        data={list} childColumns={[]}      // customGridTemplate=""
      />

      

      <Dialog.Root open={openModel || openAddRoleModal} >
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
        <div
          className="d-flex justify-content-between align-items-center  row-gap-3 flex-wrap column-gap-3 border-bottom
      border-gray-400"
        style={{paddingBottom: "10px" }}>
          <div className="d-flex align-items-center">
            {/* <button className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none"> Back</button> */}
            <BaseButton
              className="btn btn-outline-primary fs-15 py-px-4 me-px-12 px-3 d-sm-block d-none back-button"
              style={{ fontWeight: "400", height: "19px"}}
              onClick={() => {
                setOpenModel(false);
                setEditRole(false);
                setEditable(false);
                setOpenAddRoleModal(false)
              }}
            >
              Close
            </BaseButton>
            <h2 className="fw-medium fs-15 mb-0 color-gray-1000 modalHeader">
            {Editable ? "Edit user type" : editRole ? "Update role name" : "Create new user type"}
            </h2>
          </div>
          
        </div>
          <div className="modal-body" style={{paddingTop: "15px"}}>
            <div>
            <InputComponent
                  type={InputType.text}
                  label={Editable ? "Edit label " : editRole ? "Edit label " : "Enter label "}
                  popQuestion={
                    Editable ? "Edit User Type" : editRole ? "Edit Role Name " : "Enter New User Type"
                  }
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const sanitizedValue = DOMPurify.sanitize(inputValue);
                    setType(sanitizedValue);
                  }}
                  inValid={Validator.current.message(
                    "user Type",
                    Type,
                    "required"
                  )}
                  value={Type}
                  className="form-control"
                  inputPadding="px-px-12"
                ></InputComponent>
            </div>
          </div>
          <div style={{ display: 'flex',  justifyContent: 'flex-start' }}>
          <MainButton
                className="btn btn-primary resrvationButton"
                onClick={() => (Editable ? editUserType() : editRole ? editUserType() : addUserType())}
                loading={loading}
                disabled={loading}
              >
               {editRole ? 'Update' : 'Save'}
              </MainButton>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};
