import Tippy from '@tippyjs/react';
import React, { useRef, useState } from 'react';
import { MainButton, MainButtonType } from './button';
import { Note } from '../../data/models/responses/notes/notes';
import { ImageWithToken } from '../components/box_list_scroll';
import { InputComponent, InputType } from '../components/inputs';
import Visible from '../components/visible';
import { toast } from 'react-toastify';
import { SelectComponent } from '../components/select_input';
import { $ReplaceRoute, getUserInitails, getUserTitle } from '../../core/utils';
import { Member } from '../../data/models/responses/member/member_list_res';
import { NotesCreateReq, NotesGetReq } from '../../data/models/requests/notes/notes_create_req';
import { NotesController } from '../../controllers/notes/notes';
import { useEffect, DependencyList } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { Table } from '@radix-ui/themes';
import RadixTable from './NewRadixTable/RadixTable';
import GroupedAvatars from './groupedAvatars';
import UserWithAvatar from './UserWithAvatar';
import { useHistory } from 'react-router';
import { AppRoutes } from '../../core/constants';
import { useMediaQuery } from 'react-responsive';

type Props = {
  notes: any[];
  listUsers: any[];
  currentUser: any;
  NotesProfileType: number;
  NotesProfileId: number;
  handleNoteSave: any;
  loading?: boolean;
};

export default function ShowNotes({
  notes,
  listUsers,
  currentUser,
  NotesProfileType,
  NotesProfileId,
  handleNoteSave,
  loading,
}: Props) {
  const [showNewNote, setshowNewNote] = React.useState(false);
  const [usersListWithValues, setUsersListWithValues] = React.useState<any[]>([]);
  const isMobile = useMediaQuery({ maxWidth: 1009 });
  const [selectedUsers, setSelectedUsers] = React.useState<any[]>([]);
  const [allNotes, setAllNotes] = React.useState<any[]>([]);
  const leftDiv = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  const [btnLoading, setBtnLoading] = useState(false);

  const [description, setDescription] = React.useState<string>('');
  const notesController = new NotesController();
  const validator = new SimpleReactValidator({
    className: 'text-danger',
  });
  useEffect(() => {
    if (usersListWithValues && usersListWithValues.length > 0) {
      var userFullName = currentUser.firstName + ' ' + currentUser.lastName;
      setSelectedUsers([{ label: userFullName, value: currentUser.id }]);
    }
  }, [usersListWithValues]);
  const handleShowNewNote = () => {
    setshowNewNote(true);
    {
      setUsersListWithValues(
        listUsers
          ? listUsers.map((item: any) => {
              const _userTitle = getUserTitle(item);
              return {
                label: _userTitle,
                value: item.id,
              };
            })
          : []
      );
    }
  };
  const handleCreateClick = () => {
    if (validator.allValid()) {
      if (description === '') return;
      setBtnLoading(true);
      const body: NotesCreateReq = {
        AssignedUsers: selectedUsers.map((item: any) => item.value),
        description: description,
        CreatorId: currentUser.id,
        NotesProfileType: NotesProfileType,
        NotesProfileId: NotesProfileId,
      };

      notesController.createNotes(
        body,
        (res) => {
          setshowNewNote(false);
          // window.location.reload();
          setDescription('');

          const getNotesBody: NotesGetReq = {
            profileId: NotesProfileId,
            profileType: NotesProfileType,
          };
          var responseNotes = notesController.getNotes(
            getNotesBody,
            (res) => {
              setAllNotes(res);
              notes = [];
              setBtnLoading(false);
            },
            (err) => {setBtnLoading(false);}
          );
        },
        (err) => {
          setBtnLoading(false);
          handleNoteSave();
        }
      );
    } else {
      validator.showMessages();
    }
  };

  useEffect(() => {
    setAllNotes(notes);
  }, [notes]);

  return (
    <div>
      <div className='py-3 border-top border-bottom border-gray-400 d-flex justify-content-between align-items-center'>
        <h3 className='color-gray-1000 fw-medium fs-15 mb-0'>
          {`${showNewNote ? 'Add new note' : 'Notes and reminders'}`}
        </h3>
        {!showNewNote && (
          <a className='btn btn-primary fs-13 py-px-4 fw-normal px-2' onClick={handleShowNewNote}>
            <i className='fa fa-plus me-1'></i> Add Notes
          </a>
        )}
      </div>
      {!showNewNote && (
        <>
          <RadixTable
            data={allNotes}
            noDataFound={'No notes and reminders yet'}
            loading={loading}
            customHeaderWidth={true}
            columns={[
              {
                Header: 'Creator',
                maxWidth: '216px',
                width: '216px',
                Cell: (props: any) => {
                  return (
                    <Table.Cell className='p-3'>
                      <div className='d-flex align-items-center text-nowrap'>
                        {props.creatorImage === null ||
                        props.creatorImage === '' ||
                        props.creatorImage === undefined ? (
                          <div className='avatar w-px-25 h-px-25 rounded-circle bg-primary'>
                            <label className='fw-medium text-white fs-10'>
                              {getUserInitails(`${props.creatorName}`)}
                            </label>
                          </div>
                        ) : (
                          <ImageWithToken
                            originalImage={props.creatorImage}
                            hasImage={props.creatorImage ? true : false}
                            alt='Avatar'
                            className='rounded-circle avatar rounded-avatar-dashboard'
                            src={
                              props.creatorImage === null ||
                              props.creatorImage === '' ||
                              props.creatorImage === undefined
                                ? '/images/images/img_avatar.png'
                                : props.creatorImage
                            }
                          />
                        )}
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            history.push(
                              $ReplaceRoute(
                                `${AppRoutes.member_profile.replace(
                                  ':id',
                                  props.creatorId?.toString() ?? ''
                                )}`
                              )
                            );
                          }}
                          title={props.creatorName}
                        >
                          {' '}
                          {props.creatorName}
                        </span>
                      </div>
                    </Table.Cell>
                  );
                },
              },
              {
                Header: 'Date',
                width: '168px',
                maxWidth: '168px',
                Cell: (props: any) => {
                  return <Table.Cell className='p-3 text-nowrap'>{props.createdDate}</Table.Cell>;
                },
              },
              {
                Header: 'Mentioned',
                width: '199px',
                maxWidth: '199px',
                Cell: (props: any) => {
                  return (
                    <Table.Cell className='p-3'>
                      <GroupedAvatars
                        containerWidth={10}
                        maxAvatars={3}
                        tippyContent={
                          <div className='d-flex flex-column py-2 pb-0'>
                            {props.assignedUsers.map((_us: any) => {
                              console.log('User:', _us);
                              return (
                                <UserWithAvatar
                                  parentStyle={{
                                    marginBottom: '0.25em',
                                  }}
                                  key={_us.id}
                                  user={_us}
                                />
                              );
                            })}
                          </div>
                        }
                        users={props.assignedUsers}
                      />
                    </Table.Cell>
                  );
                },
              },
              {
                Header: 'Note',
                minWidth: '500px',
                Cell: (props: any) => {
                  return <Table.Cell className='p-3'>{props.description}</Table.Cell>;
                },
              },
            ]}
          />
        </>
      )}

      <Visible visible={showNewNote}>
        <>
          <div className='notes-padding'>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='col-lg-12 mb-3'>
                  <div className='row mt-1'>
                    <div>
                      <SelectComponent
                        items={usersListWithValues}
                        defaultValue={selectedUsers}
                        TextItem='name'
                        ValueItem='id'
                        labelClass='color-gray-600 fs-15 me-2'
                        className='form-select control-border-color'
                        label='Team and members*'
                        placeholder='Select Users To Mention'
                        onChange={(e: any) => {
                          const _cantFindYourSelf = !e.find(
                            (item: { label: string; value: string }) =>
                              item.value === currentUser.id
                          );

                          if (_cantFindYourSelf) {
                            return toast.error("You can't remove yourself from team");
                          }
                          setSelectedUsers(e);
                        }}
                        isMulti
                      ></SelectComponent>
                    </div>
                  </div>
                </div>
                <div className='row mt-1'>
                  <div className='col-lg-12'>
                    <div className='text-primary cursor-pointer mb-1' title='Info'>
                      <label className='color-gray-600 fs-15 me-2'>
                        Type the note or reminder text
                      </label>
                      <svg
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect width='15' height='15' fill='white' fillOpacity='0.01'></rect>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M7.49991 0.876953C3.84222 0.876953 0.877075 3.8421 0.877075 7.49978C0.877075 11.1575 3.84222 14.1227 7.49991
                      14.1227C11.1576 14.1227 14.1227 11.1575 14.1227 7.49978C14.1227 3.8421 11.1576 0.876953 7.49991 0.876953ZM1.82707
                      7.49978C1.82707 4.36677 4.36689 1.82695 7.49991 1.82695C10.6329 1.82695 13.1727 4.36677 13.1727 7.49978C13.1727 10.6328
                      10.6329 13.1727 7.49991 13.1727C4.36689 13.1727 1.82707 10.6328 1.82707 7.49978ZM8.24992 4.50005C8.24992 4.91426 7.91413
                      5.25005 7.49992 5.25005C7.08571 5.25005 6.74992 4.91426 6.74992 4.50005C6.74992 4.08583 7.08571 3.75005 7.49992
                      3.75005C7.91413 3.75005 8.24992 4.08583 8.24992 4.50005ZM6.00003 6.00005H6.50003H7.50003C7.77618 6.00005 8.00003 6.2239
                      8.00003 6.50005V10.0001H8.50003H9.00003V11.0001H8.50003H7.50003H6.50003H6.00003V10.0001H6.50003H7.00003V7.00005H6.50003H6.00003V6.00005Z'
                          fill='currentColor'
                        ></path>
                      </svg>
                    </div>
                    <InputComponent
                      type={InputType.textarea}
                      //disabled={this.state.searchLoading}
                      //label="Project description"
                      //optional="optional"
                      placeholder='Enter note here'
                      height='132px'
                      popQuestion=''
                      className='form-control'
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    ></InputComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div
              className={`col-lg-6 ${isMobile ? 'd-flex justify-content-between' : 'd-flex'}`}
              style={{ display: 'flex', paddingBottom: '10px' }}
            >
              <div className='col-lg-6 notes-padding'>
                <MainButton
                  className='btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2'
                  style={{ paddingInline: '0.875rem' }}
                  disabled={btnLoading}
                  loading={btnLoading}
                  onClick={() => {
                    // setshowNewNote(false);
                    handleCreateClick();
                  }}
                >
                  <img src='/Images/icons/file-plus.svg' className='w-px-13' /> Save Note
                </MainButton>
              </div>
              <div
                className='col-lg-6 notes-padding'
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                <MainButton
                  className='notes-cancel-button'
                  onClick={() => {
                    setshowNewNote(false);
                  }}
                  disabled={btnLoading}
                >
                  Cancel
                </MainButton>
              </div>
            </div>
          </div>

          {/* <div>
          <div
            style={{ margin: "1rem", padding: "0 1rem 0 1rem" }}
            className="item"
          >
            <div style={{ marginBottom: "1rem" }}>
              <InputComponent
                type={InputType.textarea}
                label="Type the note or reminder text"
                className="mt-1"
                maxLength={150}
                counter={false}
                inValid={validator.message(
                  "Note description is required",
                  description,
                  "required"
                )}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              ></InputComponent>
            </div>
            {console.log("userssss" + usersListWithValues.length)}
            {console.log("users" + usersListWithValues)}
            {console.log("currentUser" + currentUser)}
            <div style={{ marginBottom: "1rem" }}>
              <SelectComponent
                // disabled={this.state.searchLoading}
                items={usersListWithValues}
                TextItem="name"
                ValueItem="id"
                className="my-1"
                placeholder={"Select Users To Mention"}
                onChange={(e: any) => {
                  const _cantFindYourSelf = !e.find(
                    (item: { label: string; value: string }) =>
                      item.value === currentUser.id
                  );

                  if (_cantFindYourSelf) {
                    return toast.error("You can't remove yourself from team");
                  }
                  setSelectedUsers(e);
                }}
                defaultValue={selectedUsers}
                // inValid={this.validator.message(
                //   "Members To This Team",
                //   this.state.usersId,
                //   "required"
                // )}

                isMulti
              ></SelectComponent>
            </div>
            <div
              style={{
                marginBottom: "1rem",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <MainButton
                children={"Post it!"}
                type={MainButtonType.dark}
                className="px-3 dNoneSm"
                borderRadius="24px"
                fontSize="14px"
                minHeight="2em"
                onClick={() => {
                  // setshowNewNote(false);
                  handleCreateClick();
                }}
              ></MainButton>
              <MainButton
                children={"Discard"}
                type={MainButtonType.dark}
                className="px-3 dNoneSm"
                borderRadius="24px"
                fontSize="14px"
                minHeight="2em"
                style={{ marginLeft: "1rem" }}
                onClick={() => {
                  setshowNewNote(false);
                  // handleCreateClick()
                }}
              ></MainButton>
            </div>
          </div>
        </div> */}
        </>
      </Visible>
    </div>
  );
}
