import { RemoteWorkspace } from "../../data/remotes/workSpace/remoteWorkSpace";
import {
  WorkspaceCollaboration,
  WorkspaceRequest,
  isWorkSpaceExist_Request,
  workSpace_request,
  workspaceFilter_request,
} from "../../data/models/requests/workSpace/workSpace_request";

export class worSpaceController {
  remote = new RemoteWorkspace();

  CreateWorkSpace(
    body: workSpace_request,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.CreateWorkSpace(body, action, error);
  }

  GetList(
    body: workspaceFilter_request,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.GetList(body, action, error);
  }

  SendRequest(
    body: WorkspaceRequest,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.SendRequest(body, action, error);
  }

  UpdateRequest(
    body: any,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.UpdateRequest(body, action, error);
  }

  isWorkScpaceExist(action: (res: any) => any, error: (err: any) => any) {
    this.remote.isWorkSpaceExist(action, error);
  }
}
