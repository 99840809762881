import { HTTP, handleUnauthorizedRequest } from "../../../core/http_common";
import { ChangePasswordReq } from "../../models/requests/user/change_password_req";
import { UserSigninReq } from "../../models/requests/user/signin_req";
import { UserAccountHandleReq } from "../../models/requests/user/account_req";
import { UpdateMyProfileReq } from "../../models/requests/user/update_myprofile_req";
import { ChangePasswordRes } from "../../models/responses/user/change_password_res";
import { DashboardMyReport } from "../../models/responses/user/dashboard_report";
import { UserSigninRes } from "../../models/responses/user/signin_res";
import { UserAccountResetResult } from "../../models/responses/user/account_reset_res";
import { UpdateMyProfileRes } from "../../models/responses/user/update_myprofile_req";
import { UserTypeGet } from "../../models/responses/user/user_type_res";
import { RegisterReq } from "../../models/requests/register/register_req";
import { RegisterRes } from "../../models/responses/register/register_res";
import { SortType } from "../../../core/number_extentions";
import { signUpReq } from "../../models/requests/user/sign_up_req";
import { BooleanLiteral } from "@babel/types";

export class RemoteUser {
  signIn(
    body: UserSigninReq,
    action: (res: UserSigninRes) => any,
    error: (err: any) => any
  ) {
    
    return HTTP.post("/User/SignIn", body)
      .then((res) => action(res.data))
      .catch((err) => error(err));
  }

  isUserExist(
    body: any,
    action: (res: boolean) => any,
    error: (err: any) => any
) {
    return HTTP.post(`/User/isUserExist`,body)
        .then((res) => action(res.data.result))
        .catch((err) => error(err));
}

  signUp(
    body: signUpReq,
    action: (res: UserSigninRes) => any,
    error: (err: any) => any
  ) {
    const URL = body.IsL1Client === true ? "/User/Signup" : "/User/SignUpInvitation";
    let config = {
      headers: {
        authorization: `Bearer ${body.Token}`,
      },
    };
    return HTTP.post(URL, body,!body.IsL1Client ? config : {})
      .then((res) => action(res.data))
      .catch((err) => error(err));
  }


  resendMfaCode(
    body: {
      mfaPlatform?: number;
      Email?: string;
    },
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    const _tmpToken = localStorage.getItem("tmpToken");
    const _token = localStorage.getItem("token");
    if (!_tmpToken) {
      error("No token found!");
      return;
    }
    let config = {
      headers: {
        authorization: `Bearer ${_token || _tmpToken}`,
      },
    };
    return HTTP.post(
      `/User/ReSendMFACode${body.mfaPlatform ? `?mfaPlatform=${body.mfaPlatform}` : ""
      }${body.Email ? `&Email=${body.Email}` : ""}`,
      null,
      config
    )
      .then((res) => action(res.data))
      .catch((err) => error(err));
  }
  sendCodeBySms(
    body: {
      mobileNumber: string;
    },
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    return HTTP.post("/User/SendCodeBySMS", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  verifyMobileNumber(
    body: {
      code: number;
    },
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    return HTTP.post("/User/VerifyMobileNumber", body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  resendMfaCodeForMobile(action: (res: any) => any, error: (err: any) => any) {
    return HTTP.post("/User/ReSendMFACodeForMobile")
      .then((res) => action(res.data))
      .catch((err) => error(err));
  }

  verifyMfaCode(
    body: {
      code: number;
      sessionDays?: number | null;
      Token?: string;
      email?: string;
    },
    action: (res: UserSigninRes) => any,
    error: (err: any) => any
  ) {
    const email = localStorage.getItem("email");
    if(!email){
      body.email = body.email || undefined;
    }
    else{
      body.email = email || undefined;
    }
    let _tmpToken = localStorage.getItem("tmpToken");
    if(body.Token){
      _tmpToken = body.Token;
    }
    if (!_tmpToken) {
      error("No token found!");
      return;
    }
    let config = {
      headers: {
        authorization: `Bearer ${_tmpToken}`,
      },
    };
    return HTTP.post("/User/VerifyMfaCode", body)
      .then((res) => action(res.data))
      .catch((err) => error(err));
  }

  SignUp(
    body: RegisterReq,
    action: (res: RegisterRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post("/User/SignUp", body)
      .then((res) => action(res.data))
      .catch((err) => {
        error(err);
      });
  }

  accountReset(
    body: UserAccountHandleReq,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    // make sure body.email is not modified when adding to query
    const _email = encodeURIComponent(body.email);

    return HTTP.post(`/User/RequestResetPassword?email=${_email}`)
      .then((res) => action(res))
      .catch((err) => error(err));
  }

  updateMyProfile(
    body: UpdateMyProfileReq,
    action: (res: UpdateMyProfileRes) => any,
    error: (err: any) => any
  ) {
    let config = {
      headers: {
        authorization: `Bearer ${body.token}`,
      },
    };
    // console.log("body", {body, HTTP});
    return HTTP.put("/User", body, config)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  changePassword(
    body: ChangePasswordReq,
    action: (res: ChangePasswordRes) => any,
    error: (err: any) => any
  ) {
    let config = {
      headers: {
        authorization: `Bearer ${body.token}`,
      },
    };
    return HTTP.post(
      "/User/ChangePassword",
      {
        email: body.email,
        oldPassword: body.oldPassword,
        newPassword: body.newPassword,
      },
      config
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  dshboardReport(
    ResearchId: number,
    action: (res: DashboardMyReport) => any,
    error: (err: any) => any
  ) {
    if (!HTTP.defaults.headers.common.authorization) {
      HTTP.defaults.headers.common[
        "authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
    }
    // console.log("return HTTP", HTTP.defaults);
    return HTTP.post(`/Dashboard/MyReports/${ResearchId}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  lastSelectedResearch(
    ResearchId: number,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    if (!HTTP.defaults.headers.common.authorization) {
      HTTP.defaults.headers.common[
        "authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
    }
    // console.log("return HTTP", HTTP.defaults);
    return HTTP.post(`/Dashboard/LastSelectedResearch/${ResearchId}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  requestConfirmEmail(
    email: string,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    return HTTP.post(`/User/Account/RequestConfirmEmail`, {
      email: email,
    })
      .then((res) => action(res))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  createUserType(
    body: { userRoleEnum: number; title: string },
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    return HTTP.post(`/UserType/Create`, body)
      .then((res) => action(res))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  editUserType(
    body: { id: number; title: string },
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    return HTTP.post(`/UserType/Update`, body)
      .then((res) => action(res))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getUserTypes(action: (res: UserTypeGet) => any, error: (res: any) => any) {
    return HTTP.get(`/UserType/Get`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  updateNotificationSettings(
    body: any,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    return HTTP.post(`/Notification/UpdateNotificationSettings`, body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  getNotificationSettings(action: (res: any) => any, error: (err: any) => any) {
    return HTTP.get(`/Notification/GetNotificationSettings`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  getNotification(
    body: {
      researchId?: number;
      pageSize: number;
      pageNumber: number;
      searchParameter?: string;
      columnHeaderName?: string;
      sortDirection?: SortType;
    },
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    return HTTP.post(`/Notification`, body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  summarizeNotifications(
    ResearchId: number,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    return HTTP.get(`/Notification/summarizeNotifications/${ResearchId}`)
      .then((res) => action(res.data))
      .catch((err) => {
        error(err);
      });
  }

  readNotification(
    body: {
      notificationIds: number[];
    },
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    return HTTP.post(`/Notification/ReadNotifications`, body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getDefaultNotificationSetting(
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    return HTTP.get(`/Notification/GetDefaultNotificationSettings`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  resendEmailVerfication(
    body: {
      email?: string;
    },
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    const _tmpToken = localStorage.getItem("tmpToken");
    // if (!_tmpToken) {
    //   error("No token found!");
    //   return;
    // }
    let config = {
      headers: {
        authorization: `Bearer ${_tmpToken}`,
      },
    };
    return HTTP.post(
      `/User/ReSendVarficationEmail?Email=${body.email}`,
      null,
      config
    )
      .then((res) => action(res.data))
      .catch((err) => error(err));
  }
  getUserSubscription(  
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    return HTTP.get(`/subscription/GetUserSubscription`,)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  
  getEmailIntegrationStatus(  
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    return HTTP.get(`/User/getEmailIntegrationStatus`,)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  GetUserTrial(  
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    return HTTP.get(`/Subscription/GetUserTrial`,)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
}
