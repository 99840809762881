import { SET_RESEARCH_ID } from "../actions/actions_type";
import { ResearchAction } from "../actions/research_action";
// import { UserRoleState } from "../actions/user_action";

const ResearchId: {label:string , value : number} = { value: 0, label: "All Projects" };

const reducer = (
  state: {label:string , value : number} = ResearchId,
  action: ResearchAction
): {label:string , value : number} => {
  switch (action.type) {
    case SET_RESEARCH_ID:
      return action.payload;
  }
  return state;
};

export default reducer;