import { HTTP, handleUnauthorizedRequest } from "../../../core/http_common";
import { SortType } from "../../../core/number_extentions";
import { ClientsRes } from "../../models/responses/admin/clients_res";
import { DashboardReports } from "../../models/responses/admin/dashboard_report_res";
import { ListMemberUserRes } from "../../models/responses/admin/list_member_user_res";
import { PaymentsRes } from "../../models/responses/admin/payments_res";
import { GetAllDiscusstionRes } from "../../models/responses/discussion/get_all_discusstion_res";

export class RemoteAdmin {
  getDashboardReport(
    body: {
      fromDate: Date;
      untilDate: Date;
      excludedClients?: any[];
    },
    action: (res: DashboardReports) => any,
    error: (res: any) => any
  ) {
    return HTTP.post(`Dashboard/Reports`, body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getAllDiscussion(
    body: {
      PageNumber: number;
      PageSize: number;
      ticket: boolean;
      UserId?: string | null;
      Search?: string;
      columnHeaderName?: string;
      sortDirection?: SortType;
    },
    action: (res: GetAllDiscusstionRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(
      `/Discussion/GetListTicket?PageSize=${body.PageSize}&PageNumber=${body.PageNumber
      }&Ticket=${body.ticket}${body.UserId ? `&UserId=${body.UserId}` : ""
      }&SearchParameter=${body.Search}&ColumnName=${body.columnHeaderName}&SortDirection=${body.sortDirection}`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getClients(
    body: {
      searchParameter: string;
      pageSize: number;
      pageNumber: number;
      selectedClients: string | null;
    },
    action: (res: ClientsRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post(`Dashboard/ListUsers`, body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  getTotalSubscription(
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(`Subscription/GetSubscriptionTotal`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  listL1Clients(
    action: (res: ClientsRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post(`Dashboard/ListL1Clients`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data); // Replace this with the correct action
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  
  getListMember(
    body: {
      // searchParameter: string;
      // userId: string;
      // pageSize: number;
      // pageNumber: number;
      searchParameter: string;
      pageSize: number;
      pageNumber: number;
      subscriptionId: number | null;
      role: number | null;
    },
    action: (res: ListMemberUserRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post(`Dashboard/ListMembers`, body)
      .then((res) => {
        
        action(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getPayments(
    body: {
      pageNumber: number;
      pageSize: number;
      userId?: string;
    },
    action: (res: PaymentsRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post(`Payment/Filter`, body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  updateTicket(
    body: {
      id: number;
      categoryId?: number | null;
      subject: string;
      priority: number;
      ticketStatus: number; // 1 | 2;
    },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.post(`Discussion/UpdateTicket`, body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  createClientUser(
    body: {
      firstName: string;
      lastName: string;
      email: string;
      invitationNote: string;
      subscriptionPlanId: number;
      expirationDate: Date;
    },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    
    return HTTP.post(`User/CreateL1Clien`, body)
      .then((res) => {
        
        action(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  updateUserSubscription(
    body: {
      userId: string;
      subscriptionSettingId: number;
      trialExpiration?: Date;
      provideTrial?: boolean;
      newSeats?: number;
      notTrial?: boolean;
    },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.post(`Subscription/UpdateClientSubscription`, body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  updateEnterprizeSubscription(
    body: {
      userId: string;
      subscriptionSettingId: number;
      billingDate?: Date;
      provideTrial?: boolean;
      newSeats?: number;
      notTrial?: boolean;
    },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    return HTTP.post(`Subscription/UpdateEnterprizeSubscription`, body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  deleteClient(
    body: { Id: string | null },
    action: (res: any) => any,
    error: (res: any) => any
  ) {;
    return HTTP.delete(`User/DeleteClient?id=${body.Id}`)
      .then((res) => {
        
        action(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
}
