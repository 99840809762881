import { formattedCurrency } from '../../../../core/utils';

type GrantProgressProps = {
  amount: number;
  totalAmount: number;
};

export default function GrantProgress({ amount, totalAmount }: GrantProgressProps) {
  const percentage = (amount * 100) / totalAmount;

  return (
    <div className='py-px-12 border-top border-gray-400 d-flex align-items-center gap-2'>
      <div
        className='flex-grow-1 position-relative rounded-pill'
        style={{ background: '#EBEBEF', height: '12px' }}
      >
        <div
          className='position-absolute left-0 top-0 h-100 rounded-pill'
          style={{ width: `${percentage}%`, background: '#299764' }}
        ></div>
      </div>
      <div className='d-flex align-items-center'>
        <p className='mb-0 small'>
          {formattedCurrency(amount)} <b>/ {formattedCurrency(totalAmount)}</b>
        </p>
      </div>
    </div>
  );
}
