import React, { useEffect, useState } from "react";

import {
  ScheduleComponent,
  // WorkWeek,
  Week,
  Month,
  Inject,
  // ViewsDirective,
  // ViewDirective,
  // Agenda,
  Day,
  // EventRenderedArgs,
} from "@syncfusion/ej2-react-schedule";
// import { ImageWithToken } from "../../components/box_list_scroll";
import {
  // AppConstants,
  RESERVATION_STATUS_CLASSNAME_EVENT,
  RESERVATION_STATUS_LABELS,
  RESERVATION_STATUS_STYLES,
} from "../../../core/constants";
// import { getUserTitle } from "../../../core/utils";
import moment from "moment";
import SpinnerLoader from "../../components/spinnerLoader";
import ScheduleToolbar from "./scheduleToolbar";
import EquipmentReservationContent from "./equipmentReservationContent";
import { BsZoomIn, BsZoomOut } from "react-icons/bs";
// import { IMAGE_BASE_URL } from "../../../core/http_common";
// import { IMAGE_BASE_URL } from "../../../core/http_common";
type Props = {
  data: any;
  filterData: any;
  loading?: boolean;
  onChangeReservationStatus?: () => void;
  dateComeFromProps?: string | null;
  onTodayClick: () => void;
  onZoomInClick: () => void;
  onZoomOutClick: () => void;
  onChangeDate?: ({
    fromDate,
    toDate,
  }: {
    fromDate: string;
    toDate: string;
  }) => void;
};

// const CustomEventUi = (props: any) => {
//   //   console.log(props);
//   const { data } = props;
//   const {
//     equipment,
//     laboratories,
//     user,
//     StartTime,
//     EndTime,
//     manager,
//     reservationId,
//     isUserManagerOfEquipmentsLaboratory,
//   } = data;

//   const _userName = getUserTitle(user);
//   return (
//     <div className="custom-event-schedule-inside d-flex flex-column align-items-start gap-2">
//       <span>{equipment.title}</span>
//       <span>
//         {laboratories.map((laboratory: any) => laboratory.title).join(", ")}
//       </span>
//       <div className="d-flex align-items-center">
//         <ImageWithToken
//           originalImage={user.image ? user.image?.name : ""}
//           src={
//             user.image
//               ? user.image.name
//                 ? IMAGE_BASE_URL + user.image.name
//                 : "/Images/images/img_avatar.png"
//               : "/Images/images/img_avatar.png"
//           }
//           alt="Avatar"
//           className="rounded-circle avatar pointer"
//           hasImage={user.image ? true : false}
//           style={{
//             height: 24,
//             width: 24,
//           }}
//         />
//         <span className="ms-2">{_userName}</span>
//       </div>
//       <span>Start: {moment(StartTime).format("HH:mm")}</span>
//       <span>End: {moment(EndTime).format("HH:mm")}</span>
//     </div>
//   );
// };

const CustomScheduleComponent = ({
  data,
  filterData,
  loading,
  onChangeReservationStatus,
  dateComeFromProps,
  onChangeDate,
}: Props) => {
  const [viewMode, setViewMode] = useState<"Day" | "Week" | "Month">("Week");
  const [selectedDate, setSelectedDate] = useState(new Date());
  //   console.log(data);
  const scheduleObjRef = React.useRef<ScheduleComponent | null>(null);
  // const _localSetting = JSON.parse(localStorage.getItem("setting") || "[]");
  
  function onEventRendered(args: any) {
    const { data, element } = args;
    // getBoundClientRect element
    // setTimeout(() => {
    //   element.style.top = `${element.offsetHeight * data.Index + 8}px`;

    //   if (data.Index > 0) {
    //     element.style.marginTop = "10px";
    //   }
    // }, 500);
    // use react-dom render CustomEventUi component into the event cell
    // console.log(args, element.clientHeight, data.Index + 1);

    // args.element.style.top = `${args.element.offsetHeight + 10}px`;
    // ReactDOM.render(<CustomEventUi {...args} />, args.element);

    // let categoryColor = args.data.CategoryColor;
    // if (!args.element || !categoryColor) {
    //   return;
    // }
    // if (this.scheduleObj.currentView === "Agenda") {
    //   args.element.firstChild.style.borderLeftColor = categoryColor;
    // } else {
    //   args.element.style.backgroundColor = categoryColor;
    // }

    const _status = data?.status;
    const _statusClassName = RESERVATION_STATUS_CLASSNAME_EVENT[_status];

    const _insideEl = args.element?.querySelector(".e-appointment-details");

    element.style.marginTop = `${data?.Index + 1 / 2}em`;

    if (_insideEl) {
      // create watermark element
      const _watermarkEl = document.createElement("div");
      _watermarkEl.className = "eventWatermark";
      _watermarkEl.innerHTML = RESERVATION_STATUS_LABELS[_status].shortText;
      _watermarkEl.style.color = RESERVATION_STATUS_STYLES[_status].color;
      _watermarkEl.style.opacity = RESERVATION_STATUS_STYLES[_status].opacity;

      _insideEl.className = `${_insideEl.className} ${_statusClassName}`;
      _insideEl.appendChild(_watermarkEl);
    }
  }

  useEffect(() => {
    if (scheduleObjRef.current && dateComeFromProps) {
      setSelectedDate(new Date(dateComeFromProps));

      scheduleObjRef.current.changeDate(new Date(dateComeFromProps));
      // go to next day
      // scheduleObjRef.current.scrollTo("2");
      // console.log(scheduleObjRef.current, dateComeFromProps);
    }
  }, [dateComeFromProps]);
  // console.log({ selectedDate, dateComeFromProps });
 const onZoomInClick=() => {
    setViewMode((prev) => {
      if (prev === "Week") {
        handleChangeDateZoom("week", "day");
        return "Day";
      }

      if (prev === "Month") {
        handleChangeDateZoom("month", "week");
        return "Week";
      }

      return prev;
    });
  }

  const onZoomOutClick=() => {
    setViewMode((prev) => {
      if (prev === "Day") {
        handleChangeDateZoom("day", "week");
        return "Week";
      }

      if (prev === "Week") {
        handleChangeDateZoom("week", "month");
        return "Month";
      }

      return prev;
    });
  }

  const onTodayClick=() => {
    setSelectedDate(new Date());
    handleChangeSpecificDate()
  }

  function content(props: any) {
    return (
      <EquipmentReservationContent
        {...props}
        onChangeReservationStatus={() => {
          onChangeReservationStatus && onChangeReservationStatus();
        }}
        closeQuickInfoPopup={() => {
          scheduleObjRef.current?.quickPopup.quickPopupHide();
        }}
      />
    );
  }

  // function handleChangeView(_type: "zoomIn" | "zoomOut") {
  //   if (_type === "zoomIn") {
  //     if (scheduleObjRef.current?.currentView === "Month") {
  //       // const currentViewDates = scheduleObjRef.current?.getCurrentViewDates();
  //     }
  //   } else {
  //   }
  // }

  function handleChangeDate(
    _e: "weeks" | "months" | "days",
    __e: "week" | "month" | "day",
    _func: "add" | "subtract",
    _value?: 0 | 1
  ) {
    const _nextDate = moment(selectedDate)
      [_func](_value || 1, _e)
      .toDate();
    const __nextDate = moment.utc(_nextDate);
    const startOfDate = __nextDate.startOf(__e).toISOString();
    const endOfDate = __nextDate.endOf(__e).toISOString();
    onChangeDate && onChangeDate({ fromDate: startOfDate, toDate: endOfDate });
  }

  function handleChangeDateZoom(
    from: "week" | "month" | "day",
    to: "week" | "month" | "day"
  ) {
    // const _myDate = moment.utc(selectedDate)
    const durationMap: { [key: string]: [moment.Moment, moment.Moment] } = {
      "week-day": [
        moment.utc(selectedDate).startOf("day"),
        moment.utc(selectedDate).endOf("day"),
      ],
      "week-month": [
        moment.utc(selectedDate).startOf("month"),
        moment.utc(selectedDate).endOf("month"),
      ],
      "month-day": [
        moment.utc(selectedDate).startOf("day"),
        moment.utc(selectedDate).endOf("day"),
      ],
      "month-week": [
        moment.utc(selectedDate).startOf("week"),
        moment.utc(selectedDate).endOf("week"),
      ],
      "day-week": [
        moment.utc(selectedDate).startOf("week"),
        moment.utc(selectedDate).endOf("week"),
      ],
      "day-month": [
        moment.utc(selectedDate).startOf("month"),
        moment.utc(selectedDate).endOf("month"),
      ],
    };

    const key = `${from}-${to}`;
    const [fromDate, toDate] = durationMap[key];

    onChangeDate &&
      onChangeDate({
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
      });
  }

  function handleChangeSpecificDate() {
    const _viewMode =
      viewMode === "Day" ? "day" : viewMode === "Month" ? "month" : "week";
    const _fromDate = moment.utc(new Date()).startOf(_viewMode);
    const _toDate = moment.utc(new Date()).endOf(_viewMode);

    onChangeDate &&
      onChangeDate({
        fromDate: _fromDate.toISOString(),
        toDate: _toDate.toISOString(),
      });
  }

  return (
    <div className="custom-schedule-wrapper position-relative">
      <div className="d-flex align-items-center border-bottom border-gray-400 py-3">
        <button
            onClick={() => (onZoomInClick())}
            className='secondary-btn'
          >
          <BsZoomIn size={16} />
        </button>

        <button
          onClick={() => (onZoomOutClick())}
          className='secondary-btn mx-px-12'
        >
          <BsZoomOut size={16} />
        </button>
  
        <button
          onClick={onTodayClick}
          className='secondary-btn'
        >
          Today
        </button>
      </div>

      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 9999,
            backgroundColor: "rgba(255,255,255,0.5)",
          }}
        >
          <SpinnerLoader size="2.5em" />
        </div>
      ) : null}
      <ScheduleComponent
        cssClass="virtual-scrolling"
        // rowAutoHeight={true}
        ref={(schedule) => (scheduleObjRef.current = schedule)}
        height="550px"
        
        timeScale={{ enable: false }}
        selectedDate={selectedDate}
        showHeaderBar={false}
        currentView={viewMode}
        workHours={{ highlight: false }}
        eventSettings={{
          dataSource: data,
          allowAdding: false,
          allowEditing: false,
          allowDeleting: false,
        }}
        enableAllDayScroll={true}
        // minDate={filterData.fromDate}
        // maxDate={filterData.toDate}
        dateHeaderTemplate={(props: any) => {
          const _currentYear = moment(props.date).format("YYYY");
          return (
            <div className="d-flex flex-column align-items-center">
              <span style={{ fontSize: "2em" }}>{props.date.getDate()}</span>
              <span className="textSecondary">
                {props.date.toLocaleString("default", { month: "short" })}{" "}
                <span className="dNoneSm">{_currentYear}</span>
              </span>
            </div>
          );
        }}
        quickInfoTemplates={{
          // @ts-ignore
          header: () => null,
          // @ts-ignore
          content: content,
          // @ts-ignore
          footer: () => null,
        }}
        eventRendered={onEventRendered}

        // eventRendered={onEventRendered}
      >
        {/* <ViewsDirective>
          <ViewDirective option="Day" allowVirtualScrolling={true} />
          <ViewDirective option="Week" allowVirtualScrolling={true} isSelected={true}/>
          <ViewDirective option="Month" allowVirtualScrolling={true} />
        </ViewsDirective> */}
        <Inject services={[Day, Week, Month]} />
      </ScheduleComponent>

      {/* <ScheduleToolbar
        onTodayClick={() => {
          setSelectedDate(new Date());
          handleChangeSpecificDate()
        }}
        onPrevClick={() => {
          if (viewMode === "Day") {
            handleChangeDate("days", "day", "subtract");
            return setSelectedDate(
              moment(selectedDate).subtract(1, "days").toDate()
            );
          }
          if (viewMode === "Week") {
            handleChangeDate("weeks", "week", "subtract");
            return setSelectedDate(
              moment(selectedDate).subtract(1, "weeks").toDate()
            );
          }
          if (viewMode === "Month") {
            handleChangeDate("months", "month", "subtract");
            return setSelectedDate(
              moment(selectedDate).subtract(1, "months").toDate()
            );
          }
        }}
        onNextClick={() => {
          // console.log({
          //   viewMode,
          //   selectedDate,
          //   next: moment(selectedDate).add(1, "weeks").toDate(),
          // });

          if (viewMode === "Day") {
            handleChangeDate("days", "day", "add");
            return setSelectedDate(
              moment(selectedDate).add(1, "days").toDate()
            );
          }
          if (viewMode === "Week") {
            handleChangeDate("weeks", "week", "add");
            return setSelectedDate(
              moment(selectedDate).add(1, "weeks").toDate()
            );
          }
          if (viewMode === "Month") {
            handleChangeDate("months", "month", "add");
            return setSelectedDate(
              moment(selectedDate).add(1, "months").toDate()
            );
          }
        }}
        onZoomInClick={() => {
          setViewMode((prev) => {
            if (prev === "Week") {
              handleChangeDateZoom("week", "day");
              return "Day";
            }

            if (prev === "Month") {
              handleChangeDateZoom("month", "week");
              return "Week";
            }

            return prev;
          });
        }}
        onZoomOutClick={() => {
          setViewMode((prev) => {
            if (prev === "Day") {
              handleChangeDateZoom("day", "week");
              return "Week";
            }

            if (prev === "Week") {
              handleChangeDateZoom("week", "month");
              return "Month";
            }

            return prev;
          });
        }}
        viewMode={viewMode}
      /> */}

      <span className="viewModeWaterMark">
        {data.length === 0 ? (
          <span className="me-2">No Reservation -</span>
        ) : (
          ""
        )}
        {viewMode}
      </span>
    </div>
  );
};
export default CustomScheduleComponent;
