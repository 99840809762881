import {
  Children,
  FC,
  // Fragment,
  // memo,
  ReactNode,
  // useEffect,
  useMemo,
  // useRef,
} from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

interface IStepper {
  children: ReactNode[];
  step?: number;
}

const Stepper: FC<IStepper> = ({ children, step = 0 }) => {
  const arrayChildren = useMemo(() => Children.toArray(children), [children]);
  // const swiper = useRef<any>(null);

  // useEffect(() => {
  //   const dom = document.querySelector("#main_swiper");
  //   if (dom) {
  //     swiper.current = (dom as any).swiper;
  //   }
  // }, []);

  // useEffect(() => {
  //   if (swiper.current !== null) swiper.current.slideTo(step);
  // }, [step]);
  return (
    <div className="stepper">
      <section className="stepper_conatiner">
        {Children.map(arrayChildren, (child, index) => {
          if (step !== index) return;
          return <div>{child}</div>;
        })}

        {/* <Swiper
          id="main_swiper"
          noSwiping
          className="register_swiper"
          spaceBetween={32}
          slidesPerView={1}
          allowTouchMove={false}
          slideToClickedSlide
          preventClicksPropagation={false}
          preventClicks={false}

          // autoHeight
        >
          {Children.map(arrayChildren, (child, index) => {
            return (
              <SwiperSlide key={index} draggable={false}>
                {({ isActive }) => (
                  <div
                    style={{
                      height: isActive ? "auto" : "0px",
                      overflow: isActive ? "visible" : "hidden",
                    }}
                  >
                    {child}
                  </div>
                )}
              </SwiperSlide>
            );
          })}
        </Swiper> */}
      </section>
    </div>
  );
};

export default Stepper;
