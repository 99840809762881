import { RocketIcon } from '@radix-ui/react-icons';
import { DropdownMenu } from '@radix-ui/themes';
import { useEffect, useState } from 'react';

const DashboardProjectDropdown = ({
  id,
  items,
  onSelect,
  selectedItems,
  svg,
  className,
}: {
  id: string | number;
  items: any[];
  onSelect: (selectedItem: any) => void;
  selectedItems: any;
  svg?: any;
  className?: string;
}) => {
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const handleItemClick = (item: any) => {
    setSelectedItem(item);
    onSelect(item); // Call the onSelect function with the selected item
  };
  useEffect(() => {
    if (items && items.length > 0) {
      // const autoSelectedItems = items.find((option: any) => option.value === 0);
      // handleItemClick(autoSelectedItems);
    }
  }, [items]);
  useEffect(() => {
    if (
      selectedItems == null ||
      (selectedItems.label == undefined && selectedItems.value == undefined)
    ) {
      setSelectedItem({ label: 'All Projects', value: 0 });
    } else {
      setSelectedItem(selectedItems);
    }

    //}
  }, [selectedItems]);
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        className={`dropdown-trigger dropdown-triggerNoHover dashboard-dropdown-trigger ${className}`}
      >
        <div className='d-flex justify-content-between' style={{}}>
          <div className='d-flex align-items-center text-truncate w-94'>
            {svg ? svg : <RocketIcon className='AccordionTriggerIcon'></RocketIcon>}
            <span
              style={{ marginLeft: '0.5rem' }}
              className='d-inline-block flex-1 projectDashboardDropdownContentSpan dropdown-triggerNoHover'
            >
              {selectedItem ? selectedItem.label : 'All Projects'}
            </span>
          </div>
          <DropdownMenu.TriggerIcon />
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className={`projectDashboardDropdown ${className}`}>
        {items.map((project) => (
          <DropdownMenu.Item
            key={project.value}
            id={project.value}
            textValue={selectedItem?.label}
            onClick={() => handleItemClick(project)}
          >
            <div className='dropdown-item'>
              {svg ? svg : <RocketIcon className='AccordionTriggerIcon'></RocketIcon>}

              <span className='projectDashboardDropdownContentSpan'>{project.label}</span>
            </div>
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default DashboardProjectDropdown;
